import { MailIntegrationActions } from './mail-integration.actions'
import { initialMailIntegrationState, MailIntegrationState } from './mail-integration.state'
import { MailIntegrationActionTypes } from './mail-integration.action-types'

export function mailIntegrationReducer(
  state = initialMailIntegrationState,
  action: MailIntegrationActions,
): MailIntegrationState {
  switch (action.type) {
    case MailIntegrationActionTypes.READ_ALL_INTEGRATIONS_REQUEST:
    case MailIntegrationActionTypes.CREATE_INTEGRATION_REQUEST:
    case MailIntegrationActionTypes.DELETE_INTEGRATION_REQUEST:
    case MailIntegrationActionTypes.READ_INTEGRATION_LISTS_REQUEST:
    case MailIntegrationActionTypes.CREATE_FORTUNE_WHEEL_INTEGRATION_REQUEST:
      return { ...state, error: null }

    case MailIntegrationActionTypes.CREATE_INTEGRATION_FAILURE:
    case MailIntegrationActionTypes.DELETE_INTEGRATION_FAILURE:
    case MailIntegrationActionTypes.READ_ALL_INTEGRATIONS_FAILURE:
    case MailIntegrationActionTypes.READ_INTEGRATION_LISTS_FAILURE:
    case MailIntegrationActionTypes.CREATE_FORTUNE_WHEEL_INTEGRATION_FAILURE:
      return { ...state, error: action.payload }

    case MailIntegrationActionTypes.READ_ALL_INTEGRATIONS_SUCCESS:
      return { ...state, list: action.payload }

    case MailIntegrationActionTypes.CREATE_INTEGRATION_SUCCESS:
      const filteredList = state.list.filter(item => item.id !== action.payload.id)
      return {
        ...state,
        list: [...filteredList, action.payload],
      }

    case MailIntegrationActionTypes.DELETE_INTEGRATION_SUCCESS:
      return { ...state, list: state.list.filter(item => item.id !== action.payload) }

    case MailIntegrationActionTypes.SET_SELECTED_INTEGRATION:
      return { ...state, selectedItem: action.payload }

    case MailIntegrationActionTypes.CLEAR_SELECTED_INTEGRATION:
      return { ...state, selectedItem: null }

    case MailIntegrationActionTypes.READ_INTEGRATION_LISTS_SUCCESS:
      return { ...state, selectedItemLists: action.payload }

    case MailIntegrationActionTypes.CREATE_FORTUNE_WHEEL_INTEGRATION_SUCCESS:
      return { ...state }

    default:
      return state
  }
}
