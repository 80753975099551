import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { SafeLocalStorageService } from '../../../../core/services/safe-local-storage.service'

@Injectable({
  providedIn: 'root',
})
export class SideNavService {
  readonly pinKey = 'one-sidenav-pinned'
  public pinnedSideNav$ = new BehaviorSubject<boolean>(this.safeLocalStorage.getItem(this.pinKey) === 'true')
  public toggleActive$ = new BehaviorSubject<boolean>(false)

  constructor(
    private safeLocalStorage: SafeLocalStorageService,
  ) {
  }

  setPinned(data: boolean) {
    this.pinnedSideNav$.next(data)
  }

  getPinned(): boolean {
    return this.pinnedSideNav$.getValue()
  }

  togglePinned() {
    const value = !this.getPinned()
    this.setPinned(value)
    this.safeLocalStorage.setItem(this.pinKey, `${value}`)
  }

  toggleActive(force?: boolean) {
    const value = force ?? !this.toggleActive$.getValue()
    this.toggleActive$.next(value)
  }
}
