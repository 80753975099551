import { Injectable } from '@angular/core'
import { CampaignTemplateError } from '../../store/campaign/campaign.state'

@Injectable()
export class ErrorsService {
  isTemplateValidationError(error: any): boolean {
    return error
      && error.error
      && error.error.error
      && error.error.error.message
      && error.error.error.position
  }

  extractTemplateValidationError(error: any): CampaignTemplateError {
    const { param } = error.error
    const { message, position } = error.error.error
    return { message, position, param }
  }

  extractCampaignError(error: any) {
    // Campaigns limit error
    if (error && error.error) {
      return error.error
    }
  }

  extractError(error: any): string {
    if (typeof error === 'string') {
      return error
    }
    if (error && error.error && typeof error.error === 'string') {
      return error.error
    }
    // Mail Integration Error
    if (error && error.error && error.error.error && typeof error.error.error === 'string') {
      return error.error.error
    }
    return error.statusText
      || error.message
      || error.exception
      || error && JSON.stringify(error)
      || 'Something went wrong. Please try again later.'
  }

  public stringify(error): string {
    if (typeof error === 'string') {
      return error
    }
    const extractedError =
      error.message && typeof error.message === 'string' && error.message
      || error.statusText && typeof error.statusText === 'string' && error.statusText
      || error.error && typeof error.error === 'string' && error.error
    if (extractedError.toLowerCase().indexOf('unknown') === -1) {
      return extractedError
    }
    return 'Oh no! An angry Ninja drop kicked your request. If problem persists, report it to our live chat or email us at support@prooffactor.com'
  }

  public stringifyBilling(error): string {
    if (typeof error === 'string') {
      return error
    }
    if (error) {
      return (error.error && error.error.message)
        || (error.error && error.error.error && error.error.error.message)
        || error.message
        || error.exception
        || JSON.stringify(error)
    }
    return 'Something went wrong'
  }
}
