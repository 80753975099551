const ar = {
  Quantity: 'كمية',
  Size: 'بحجم',
  Color: 'اللون',
  Material: 'مواد',
  Style: 'أسلوب',
  Savings: 'مدخرات',
  Subtotal: 'المجموع الفرعي',
  Shipping: 'شحن',
  Taxes: 'الضرائب',
  Total: 'المجموع',
  Free: 'مجاني',
  Buy: 'يشترى',
  'Pay Now': 'ادفع الآن',
  'Take the Deal': 'خذ الصفقة',
  'Add Now': 'إضافة الآن',
  'You will be charged immediately': 'سيتم محاسبتك على الفور',
  'Decline Offer': 'رفض العرض',
  'No Thanks': 'لا شكرا',
  '{price}': '{سعر}',
  'Sold Out': 'مباعة',
  'see more': 'شاهد المزيد',
  less: 'أقل',
};
export {ar};
