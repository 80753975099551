<div mat-dialog-content class="promotion">
  <div class="promotion-modal">
    <ng-container *ngFor="let details of promotions">
      <div class="promotion-item" *ngIf="details[data]">
        <div class="promotion-title">{{details[data].title}}</div>
        <div class="promotion-subtitle">{{details[data].subtitle}}</div>
        <div *ngIf="details[data].tags" class="promotion-tags">
          <div *ngFor="let tag of details[data].tags" class="pf-badge success mx-1">
            {{ tag }}
          </div>
        </div>
        <div class="promotion-image">
          <img src="/app_assets/{{details[data].image}}" alt="">
        </div>
        <div class="promotion-select" *ngIf="details[data].popupDropdown">
          <label class="promotion-label">Pop up type</label>
          <mat-form-field class="pf-form-field w-100">
            <mat-select [formControl]="popupSelect" class="form-control" title="Popups">
              <mat-option [value]="pluginTypes.FortuneWheel">Spin Wheel</mat-option>
              <mat-option [value]="pluginTypes.CouponBoxNotification">Pop up (With Reward)</mat-option>
              <mat-option [value]="pluginTypes.NewsLetterNotification">Pop up (No Reward)</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="promotion-select" *ngIf="details[data].emailDropdown">
          <label class="promotion-label">Email type</label>
          <mat-form-field class="pf-form-field w-100">
            <mat-select [formControl]="emailSelect" class="form-control" title="Emails">
              <mat-option [value]="emailTypes.broadcastCoupon">Newsletter Email (With Reward)</mat-option>
              <mat-option [value]="emailTypes.broadcastNewsletter">Newsletter Email (No Reward)</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <button (click)="details[data].action(data)" class="pf-button sm filled blue">{{ details[data].buttonText || 'Explore Themes' }}</button>
      </div>
    </ng-container>
  </div>
  <button class="mat-dialog-close" (click)="dialogRef.close()">
    <span>&times;</span>
  </button>
</div>
