const nl = {
  Quantity: 'Hoeveelheid',
  Size: 'Maat',
  Color: 'Kleur',
  Material: 'Materiaal',
  Style: 'Stijl',
  Savings: 'Besparingen',
  Subtotal: 'Subtotaal',
  Shipping: 'Verzenden',
  Taxes: 'Belastingen',
  Total: 'Totaal',
  Free: 'Vrij',
  Buy: 'Kopen',
  'Pay Now': 'Nu betalen',
  'Take the Deal': 'Neem de deal',
  'Add Now': 'Voeg nu toe',
  'You will be charged immediately': 'U wordt direct afgerekend',
  'Decline Offer': 'Aanbod weigeren',
  'No Thanks': 'Nee bedankt',
  '{price}': '{prijs}',
  'Sold Out': 'Uitverkocht',
  'see more': 'Bekijk meer',
  less: 'minder',
}

export {nl}
