import { CommonModule } from '@angular/common'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { OneIconComponent } from '../one-icon/one-icon.component'

@Component({
    selector: 'pf-message-box',
    templateUrl: './message-box.component.html',
    styleUrls: ['./message-box.component.scss'],
    standalone: true,
    imports: [
      CommonModule,
      OneIconComponent,
    ]
})
export class MessageBoxComponent implements OnInit {
  public dirtyHTML: SafeHtml
  @Input() messageType: 'error' | 'warning' | 'info' | 'success'
  @Input() messageTitle: string
  @Input() messageText: string
  @Input() messageHtml: string
  @Input() mobileLayout = false
  @Input() customStyle: { [klass: string]: any; } = null
  @Input() customClass: string = ''
  @Input() buttonText: string = null
  @Input() buttonClass: string = null
  @Input() closable: boolean = false
  @Output() buttonClick = new EventEmitter()
  @Output() closeClick = new EventEmitter()

  constructor(
    private sanitizer: DomSanitizer
  ) {
  }

  ngOnInit(): void {
    this.dirtyHTML = this.sanitizer.bypassSecurityTrustHtml(this.messageHtml)
  }

  onCloseClick() {
    this.closeClick.emit()
  }
}
