import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'

export enum EmbeddedAppNames {
  CALENDLY = 'calendly',
  UNLAYER = 'unlayer',
}

@Injectable()
export class EmbeddedAppsAvailabilityService {
  public statusEndpoint = {
    [EmbeddedAppNames.CALENDLY]: 'https://www.calendlystatus.com/api/v2/status.json',
    [EmbeddedAppNames.UNLAYER]: 'https://status.unlayer.com/api/v2/status.json',
  }
  public statusEndpointsArr = Object.values(this.statusEndpoint)

  constructor(
    private http: HttpClient
  ) {}

  getStatus(app: EmbeddedAppNames): Observable<any> {
    return this.http.get(this.statusEndpoint[app])
  }
}
