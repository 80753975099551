@switch(true) {
  <!-- MARK: Gallery -->
  @case ([MGView.gallery, MGView.delete].includes(viewState)) {
    <div class="l-mg" [class._aside-active]="selectedImg">
    <!-- <div class="l-mg" [class._aside-active]="selectedImg" [class._sidenav-present]="!modalMode"> -->
      <!-- @if (!modalMode) {
        <div class="l-mg-sidenav">
          <div class="mg-list">
            @for (imgFolder of galleryList; track imgFolder?.path) {
              <div class="mg-list-item" 
                (click)="switchActive(imgFolder.url)" 
                [class._active]="imgFolder?.active">
                <one-icon 
                  [name]="imgFolder?.active ? 'folder-open-outline' : 'folder-outline'"
                ></one-icon> {{ imgFolder?.url }} ({{ imgFolder?.items?.length ?? 0 }} files)
              </div>
            }
          </div>
        </div>
      } -->

      <div class="l-mg-body">
        <div class="l-mg-header">
          <div class="mg-dropzone"
            [class._dragover]="isDragOver"  
            (dragover)="onDragOver($event)" 
            (dragleave)="onDragLeave($event)" 
            (drop)="onDrop($event)">
            <div class="mg-dropzone-inner">
              <input type="file" 
                id="image-upload" 
                accept='image/*' 
                [(ngModel)]="file" 
                #ctrl="ngModel" 
                (change)="fileSelected($event)" 
                hidden>
              <one-icon class="fz48" style="color: #e0e0e0;" name="cloud-upload-outline"></one-icon>
              <div>Drag & drop files here</div>
              <label for class="one-button filled black sm mb-0 mt-2" for="image-upload">
                <one-icon class="fz20" name="arrow-up-circle-outline"></one-icon>
                Upload New Image
              </label>
            </div>
          </div>
    
          <!-- <div class="mg-header-controls">
            <div class="mg-header-search">
              <div class="settings-control pt-0 mb-0">
                <one-icon name="search-outline"></one-icon>
                <input type="text" placeholder="Search" [(ngModel)]="search" (input)="onSearch($event)">
                <one-icon *ngIf="search" (click)="onSearch($event, true)" name="close-circle-outline"></one-icon>
              </div>
            </div>
            <div class="mg-header-actions">
              <button class="one-button outline black sm">
                <one-icon class="fz22" [name]="viewGrid ? 'apps-sharp' : 'list-sharp'"></one-icon>
                <one-icon class="ml-1" name="chevron-down-outline"></one-icon>
              </button>
            </div>
          </div> -->
    
        </div>
    
        <div class="l-mg-content">
          <ng-container #previewContainer></ng-container>
          <div class="mg-content">
            @for (img of displayList; track img) {
              <div class="mg-content-img" *ngIf="!img._hidden" (click)="onImageClick(img)" [class._selected]="img._selected">
                <img [src]="img.url" alt="">
              </div>
            }
          </div>
        </div>
      </div>
    
      <div class="l-mg-aside">
        <div class="h-100 m-0">
          <div class="mg-preview">
            @if (selectedImg) {
            <div class="mg-preview-head" *ngIf="!modalMode">
              <one-icon 
                name="close-circle-outline" 
                (click)="selectedImg = null"
              ></one-icon>
            </div>
            <div class="mg-preview-img">
              <img [src]="selectedImg?.url" alt="">
              <div class="mg-arrows">
                <one-icon 
                  class="mg-arrow" 
                  name="chevron-back-circle-outline" 
                  (click)="onArrowClick('prev')"
                ></one-icon>
                <one-icon 
                  class="mg-arrow" 
                  name="chevron-forward-circle-outline" 
                  (click)="onArrowClick('next')"
                ></one-icon>
              </div>
            </div>
            }
            <div class="mg-preview-actions">
              @if (viewState === MGView.delete) {
                <h5 class="text-center">
                  Delete this image?
                </h5>
                <div class="d-flex">
                  <button 
                    class="one-button filled red xs w-100 mb-2 mx-1"
                    (click)="onDeleteImage()"
                  >Delete</button>
                  <button 
                    class="one-button outline black xs w-100 mb-2 mx-1"
                    (click)="viewState = MGView.gallery"
                  >Cancel</button>
                </div>
              } @else {
                <div class="d-flex">
                  <button 
                    *ngIf="selectedImg && modalMode"
                    class="one-button filled black xs w-100 mb-2 mx-1" 
                    (click)="onApplyImageClick()"
                  >Apply Image</button>
                  <button 
                    *ngIf="selectedImg"
                    class="one-button outline red xs w-100 mb-2 mx-1" 
                    (click)="onDeleteImageClick()"
                  >Delete</button>
                  <button 
                    *ngIf="selectedImg"
                    class="one-button outline black xs w-100 mb-2 mx-1" 
                    (click)="onEditImageClick()"
                  >Edit</button>
                </div>
              }
            </div>
            <div class="mg-preview-info">
              <div class="mg-preview-info-title">
                Info
              </div>
              <div class="mg-preview-info-item">
                <div class="mg-preview-info-label">Name</div>
                <div class="mg-preview-info-value _trim">{{ selectedImg?.name }}</div>
              </div>
              <div class="mg-preview-info-item">
                <div class="mg-preview-info-label">Size</div>
                <div class="mg-preview-info-value">
                  @if (selectedImg?.size > 1048576) {
                    <span class="text-danger" 
                    [oneTooltipPosition]="'top'"
                    [oneTooltipMaxWidth]="300"
                    [oneTooltipSkipUnderline]="true"
                    [oneTooltip]="'File size exceeds recommended limit of 1MB. Please consider optimizing the image.'">
                      <one-icon name="warning-outline"></one-icon>
                      File size {{ selectedImg?.size | fileSize }} exceeds recommended limit
                    </span>
                  } @else {
                    {{ selectedImg?.size | fileSize }}
                  }
                </div>
              </div>
              <div class="mg-preview-info-item">
                <div class="mg-preview-info-label">Type</div>
                <div class="mg-preview-info-value">{{ selectedImg?.contentType }}</div>
              </div>
              <div class="mg-preview-info-item">
                <div class="mg-preview-info-label">Uploaded</div>
                <div class="mg-preview-info-value">{{ selectedImg?.timeCreated | date: 'medium' }}</div>
              </div>
              <div class="mg-preview-info-item _no-border">
                <div class="mg-preview-info-label">Modified</div>
                <div class="mg-preview-info-value">{{ selectedImg?.updated | date: 'medium' }}</div>
              </div>
              <div class="mg-preview-info-title">
                Metadata
              </div>
              @for (item of selectedImg?.customMetadata | keyvalue; track item.key;) {
                <div class="mg-preview-info-item" [class._no-border]="$last">
                  <div class="mg-preview-info-label">{{item.key}}</div>
                  <div class="mg-preview-info-value _trim">{{item.value}}</div>
                </div>
              }
            </div>     
          </div>
        </div>
      </div>
    
      <!-- <div class="l-mg-footer">
        <div class="settings-card m-0">
        Footer
        </div>
      </div> -->
    </div>
  }
  @case (viewState === MGView.edit) {
    <!-- MARK: Editor -->
    <div class="l-mg-editor">
      <pintura-editor 
        #editor
        (process)="handleEditorProcess($event, true, true)"
        [options]="editorOptions" 
        [src]="selectedImg.url"
        [locale]="editorLocale"
        [willRenderToolbar]="willRenderToolbar"
      ></pintura-editor>
    </div>
  }
}


<pf-loading-overlay [isFixed]="false" [filter]="{only: ['MGUpload', 'MGFetch', 'MGDelete']}"></pf-loading-overlay>