<div class="message" [class._mobile]="mobileLayout" [ngClass]="messageType + ' ' + customClass" [ngStyle]="customStyle">
  <div class="message-icon-container">
    <img src="./app_assets/icons/message-{{messageType}}-icon.svg" alt="">
  </div>
  <div class="message-box">
    <div class="message-title">{{messageTitle}}</div>
    <ng-container *ngIf="!!messageText">
      <div class="message-text">{{messageText}}</div>
    </ng-container>
    <ng-container *ngIf="!!messageHtml">
      <div class="message-text" [innerHTML]="dirtyHTML"></div>
    </ng-container>
  </div>
  <div class="message-button" *ngIf="buttonText">
    <button [ngClass]="buttonClass || 'one-button sm blue filled'" (click)="buttonClick.emit()">{{ buttonText }}</button>
  </div>
  <div (click)="onCloseClick()" class="message-close" *ngIf="closable">
    <one-icon name="close"></one-icon>
  </div>
</div>
