import { HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import moment from 'moment'
import { Observable } from 'rxjs'
import { ApiService } from '../../../core/services/api/api.service'
import {
  DailyActivitiesResponse,
  DailyCapturesResponse, DailyOffersResponse,
  DailyUniqueResponse,
  TotalActivitiesResponse,
  TotalCapturesResponse, TotalOffersResponse,
  TotalUniqueResponse,
} from '../models/dashboard-alt-api.model'

@Injectable({
  providedIn: 'root'
})
export class DashboardAltApiService {
  constructor(
    private apiService: ApiService,
  ) { }

  public getTotalActivities(start_date: string, end_date: string): Observable<TotalActivitiesResponse> {
    return this.apiService.get(`/v1/dashboard/total_activities?start_date=${start_date}&end_date=${end_date}`)
  }

  public getDailyActivities(start_date: string, end_date: string, campaign_ids?: string[]): Observable<DailyActivitiesResponse> {
    let params = new HttpParams()
    params = params.append('start_date', start_date)
    params = params.append('end_date', end_date);
    (campaign_ids || []).forEach(campaignId => {
      params = params.append('campaign_ids[]', campaignId)
    })
    return this.apiService.get(`/v1/dashboard/daily_activities`, params)
  }

  public getTotalCaptures(start_date: string, end_date: string): Observable<TotalCapturesResponse> {
    if (!start_date) {
      start_date = '1971-11-03'
    }
    if (!end_date) {
      end_date = moment().format('YYYY-MM-DD')
    }
    return this.apiService.get(`/v1/dashboard/total_captures?start_date=${start_date}&end_date=${end_date}`)
  }

  public getDailyCaptures(start_date: string, end_date: string, campaign_ids?: string[]): Observable<DailyCapturesResponse> {
    let params = new HttpParams()
    params = params.append('start_date', start_date)
    params = params.append('end_date', end_date);
    (campaign_ids || []).forEach(campaignId => {
      params = params.append('campaign_ids[]', campaignId)
    })
    return this.apiService.get(`/v1/dashboard/daily_captures`, params)
  }

  public getTotalOffers(start_date: string, end_date: string): Observable<TotalOffersResponse> {
    if (!start_date) {
      start_date = '1971-11-03'
    }
    if (!end_date) {
      end_date = moment().format('YYYY-MM-DD')
    }
    return this.apiService.get(`/v1/dashboard/total_offers?start_date=${start_date}&end_date=${end_date}`)
  }

  public getDailyOffers(start_date: string, end_date: string, campaign_ids?: string[]): Observable<DailyOffersResponse> {
    let params = new HttpParams()
    params = params.append('start_date', start_date)
    params = params.append('end_date', end_date);
    (campaign_ids || []).forEach(campaignId => {
      params = params.append('campaign_ids[]', campaignId)
    })
    return this.apiService.get(`/v1/dashboard/daily_offers`, params)
  }

  public getTotalUnique(start_date: string, end_date: string, campaign_ids?: string[]): Observable<TotalUniqueResponse> {
    let params = new HttpParams()
    params = params.append('start_date', start_date)
    params = params.append('end_date', end_date);
    (campaign_ids || []).forEach(campaignId => {
      params = params.append('campaign_ids[]', campaignId)
    })
    return this.apiService.get(`/v1/dashboard/total_unique`, params)
  }

  public getDailyUnique(start_date: string, end_date: string): Observable<DailyUniqueResponse> {
    return this.apiService.get(`/v1/dashboard/daily_unique?start_date=${start_date}&end_date=${end_date}`)
  }

}
