const vi = {
  Quantity: 'Số lượng',
  Size: 'Kích thước',
  Color: 'Màu sắc',
  Material: 'Vật liệu',
  Style: 'Kiểu dáng',
  Savings: 'Tiết kiệm',
  Subtotal: 'Tạm tính',
  Shipping: 'Phí vận chuyển',
  Taxes: 'Thuế',
  Total: 'Tổng cộng',
  Free: 'Miễn phí',
  Buy: 'Mua',
  'Pay Now': 'Thanh toán ngay',
  'Take the Deal': 'Chấp nhận',
  'Add Now': 'Thêm vào ngay',
  'You will be charged immediately': 'Sản phẩm sẽ được thanh toán ngay lập tức',
  'Decline Offer': 'Từ chối',
  'No Thanks': 'Không, cám ơn',
  '{price}': '{Giá bán}',
  'Sold Out': 'Bán hết',
  'see more': 'xem thêm',
  less: 'ẩn bớt',
};
export {vi};
