export enum PlatformOnboardingStatus {
  Started = 'started',
  Completed = 'completed',
}

export enum PlatformOnboardingStage {
  Questionnaire = 'questionnaire',
  PhoneVerification = 'phone_verification',
  OnboardingCall = 'onboarding_call',
  PlanSelection = 'plan_selection',
  CampaignSetup = 'campaign_setup',
}

export enum PlatformOnboardingCall {
  Scheduled = 'scheduled',
  Rescheduled = 'rescheduled',
  Canceled = 'canceled',
  NoShow = 'no_show',
  Completed = 'completed',
}

export enum PlatformOnboardingUnlockedFlow {
  PlanSelection = 'plan_selection',
  SkipPlanSelection = 'plan_selection_skip',
}
