import { AfterViewInit, Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core'
import { Router } from '@angular/router';
import { RouteHeaderUrl } from '../../one-header/header-navigation.model';
import { SegmentAnalyticsService } from '../../../services/segment-analytics.service';
import moment from 'moment'
import { UserService } from '../../../../core/services/user.service'
import { PromoService } from '../../../modules/promo/services/promo.service'
import { ShopifyService } from '../../../../core/services/shopify.service'
import { forkJoin, Subscription } from 'rxjs'
import { GettingStartedService } from '../../../../core/services/getting-started.service'
import { keyValuePairKey } from '../../../models/key-value-pair.model'
import { PaymentService } from '../../../../core/services/payment.service'
import { Store } from '@ngrx/store'
import { StoreState } from '../../../../store/store.state'
import { HideLoading, ShowLoading } from '../../../../store/loading/loading.actions'
import { finalize, tap } from 'rxjs/operators'
import { CommonModule } from '@angular/common';
import { OneTooltipDirective } from '../../../directives/one-tooltip.directive';
import { PluralPipe } from '../../../pipes/plural.pipe';
import { LoadingOverlayComponent } from '../../loading-overlay/loading-overlay.component';
import { ActiveCampaignDropdownComponent } from '../../one-header/components/active-campaign-dropdown/active-campaign-dropdown.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'pf-pricing-updates-modal',
    templateUrl: './pricing-updates.modal.component.html',
    styleUrls: ['./pricing-updates.modal.component.scss'],
    standalone: true,
    imports: [
      CommonModule,
      OneTooltipDirective,
      ActiveCampaignDropdownComponent,
      PluralPipe,
      LoadingOverlayComponent,
    ]
})

export class PricingUpdatesModalComponent implements OnInit, AfterViewInit, OnDestroy {
  subscription$ = new Subscription()
  resizeTimeout: any
  popup: HTMLElement
  pHeight = 0
  @HostListener('window:resize', ['$event']) onResize(event: any) {
    this.adjustScale()
  }

  days = 5
  daysLeft = 0
  date = 'October 20, 2023'
  faqLink = 'https://help.one.store/en/article/pricing-plans-faq-10sjxll/?utm_source=onestore&utm_medium=app&utm_campaign=plan_notice'

  deadlines = [
    'October 05, 2023',
    'October 15, 2023',
    'October 20, 2023',
  ]

  discounts = [
    {
      text: `Upgrade before ${moment(this.deadlines[0]).format('MMMM D, YYYY')} for <b>50%</b> Lifetime Discount`,
      active: false
    },
    {
      text: `Upgrade before ${moment(this.deadlines[1]).format('MMMM D, YYYY')} for <b>25%</b> Lifetime Discount`,
      active: false
    },
    {
      text: `Upgrade before ${moment(this.deadlines[2]).format('MMMM D, YYYY')} for <b>10%</b> Lifetime Discount`,
      active: false
    },
  ]

  // by default 10% promo will be applied even for users past deadline
  applyPromo = 'LOYALTEN'

  couponCodes = [
    'GLASS_HALF_FULL',
    'LOYALTYQUARTER',
    'LOYALTEN',
  ]

  installDate = null

  extended = false
  forceUpdate = false

  passedDeadline = false

  firstSeenPayload: any = {
    extended: false,
    count: 0
  }
  deadLineApplicable = true

  constructor(
    public dialogRef: MatDialogRef<PricingUpdatesModalComponent>,
    private router: Router,
    private segmentAnalitycsService: SegmentAnalyticsService,
    private userService: UserService,
    private promoService: PromoService,
    private shopifyService: ShopifyService,
    private gettingStartedService: GettingStartedService,
    private paymentService: PaymentService,
    private store: Store<StoreState>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.dialogRef.addPanelClass(['_overflow-unset'])
    // show original modal & force update
    if (data?.version === 'original') {
      this.extended = false
      this.forceUpdate = true
    // this is a new user, they will be getting the newest deadlines
    } else if ((!this.userService.userHasSeenPricingModal && !this.userService.pricingModalDynamicFlow) || data?.version === 'current') {
      this.extended = true
      this.forceUpdate = data?.version === 'current'

      this.deadlines[0] = '2023-12-13'
      this.deadlines[1] = '2023-12-20'
      this.deadlines[2] = '2023-12-28'
      // no update to coupons for now
      this.couponCodes[0] = 'GLASS_HALF_FULL'
      this.couponCodes[1] = 'LOYALTYQUARTER'
      this.couponCodes[2] = 'LOYALTEN'

      this.firstSeenPayload = {
        extended: this.extended,
        first_deadline: this.deadlines[0],
        second_deadline: this.deadlines[1],
        third_deadline: this.deadlines[2],
        first_coupon: this.couponCodes[0],
        second_coupon: this.couponCodes[1],
        third_coupon: this.couponCodes[2],
      }

    // for users that saw extended deadlines populate it from saved data
    } else if (this.userService.firstSeenPricingData?.extended) {
      this.extended = true
      this.deadlines[0] = this.userService.firstSeenPricingData?.first_deadline || this.deadlines[0]
      this.deadlines[1] = this.userService.firstSeenPricingData?.second_deadline || this.deadlines[0]
      this.deadlines[2] = this.userService.firstSeenPricingData?.third_deadline || this.deadlines[0]
      this.couponCodes[0] = this.userService.firstSeenPricingData?.first_coupon || this.couponCodes[0]
      this.couponCodes[1] = this.userService.firstSeenPricingData?.second_coupon || this.couponCodes[0]
      this.couponCodes[2] = this.userService.firstSeenPricingData?.third_coupon || this.couponCodes[0]

      this.firstSeenPayload = {
        extended: this.extended,
        first_deadline: this.deadlines[0],
        second_deadline: this.deadlines[1],
        third_deadline: this.deadlines[2],
        first_coupon: this.couponCodes[0],
        second_coupon: this.couponCodes[1],
        third_coupon: this.couponCodes[2],
      }
    }

    const now = moment()
    if (moment(this.deadlines[2]).add(1, 'day').isAfter(now)) {
      this.discounts[2].text = `Upgrade before ${moment(this.deadlines[2]).format('MMMM D, YYYY')} for <b>10%</b> Lifetime Discount1`
      this.discounts[2].active = true
      this.applyPromo = this.couponCodes[2]
    }
    if (moment(this.deadlines[1]).add(1, 'day').isAfter(now)) {
      this.discounts[1].text = `Upgrade before ${moment(this.deadlines[1]).format('MMMM D, YYYY')} for <b>25%</b> Lifetime Discount2`
      this.discounts[1].active = true
      this.applyPromo = this.couponCodes[1]
    }
    if (moment(this.deadlines[0]).add(1, 'day').isAfter(now)) {
      this.discounts[0].text = `Upgrade before ${moment(this.deadlines[0]).format('MMMM D, YYYY')} for <b>50%</b> Lifetime Discount3`
      this.discounts[0].active = true
      this.applyPromo = this.couponCodes[0]
    }

    this.date = moment(this.deadlines[2]).format('MMMM D, YYYY')
    this.daysLeft = moment(this.deadlines[2]).startOf('day').diff(now.startOf('day'), 'days')
    this.passedDeadline = this.daysLeft < 0

    if (this.userService.pricingModalDynamicFlow?.third_coupon_deadline) {
      this.updateCouponLabels()
    }
  }

  ngOnInit() {
    this.segmentAnalitycsService.track('Viewed Modal - Notice of New Pricing')
    window.addEventListener('message', this.receiveIframeMessage)
    if (this.userService.userInfo?.created_at) {
      this.installDate = moment(this.userService.userInfo.created_at).format('MMMM Do YYYY')
    }

    // migrating count to modal data, so get historic value from here
    const shownCount = this.gettingStartedService.getKeyValuePairValue(keyValuePairKey.UpdatePricingModalShownCount) || 0

    this.store.dispatch(new ShowLoading('PricingModalUserUpdate'))
    this.subscription$.add(
      this.userService.updatePricingModalShown(true, {
        ...this.firstSeenPayload,
        count: shownCount
      }, this.forceUpdate).pipe(
        finalize(() => {
          this.store.dispatch(new HideLoading('PricingModalUserUpdate'));
        })
      ).subscribe()
    );

    // moving coupon application here for cases when user wants to reload the page instead of clicking "No thanks" btn
    this.promoService.definePromo({params: `?promo_code=${this.applyPromo}&promo_type=special_offer&promo_referrer=prooffactor&promo_view=special_offer1`}, this.userService.userInfo)
    this.promoService.togglePromoState(this.userService.userInfo, true)
  }

  ngAfterViewInit() {
    this.popup = document.body.querySelector(`#${this.dialogRef.id}`) as HTMLElement
    this.pHeight = this.popup.getBoundingClientRect().height
    setTimeout(() => {
      this.adjustScale()
    }, 500)
  }

  adjustScale() {
    if (!this.resizeTimeout) {
      this.resizeTimeout = setTimeout(() => {
        this.resizeTimeout = null
        if (this.popup) {
          if (this.pHeight > window.innerHeight) {
            const isMobile = window.innerWidth < 768
            let verticalSpacing = isMobile ? 0 : 50
            if (isMobile && this.shopifyService.isEmbedded) {
              verticalSpacing += 45
            }
            this.popup.style.transform = `scale(${window.innerHeight / (this.pHeight + verticalSpacing)})`
            this.popup.style.transformOrigin = 'top center'
            if (!isMobile) {
              this.popup.parentElement.style.padding = '20px 0'
            }
          } else {
            this.popup.style.transform = `none`
            this.popup.parentElement.style.padding = '0'
          }
        }
      }, 200)
    }
  }

  viewPlans() {
    this.segmentAnalitycsService.track('Clicked View Plans - Notice of New Pricing Modal')
    this.router.navigate([`/${RouteHeaderUrl.settings}/${RouteHeaderUrl.billing}/${RouteHeaderUrl.subscriptions}`]).then(() => {
      this.dialogRef.close()
    })
  }

  close(event) {
    event.preventDefault()
    this.segmentAnalitycsService.track('Clicked No Thanks - Notice of New Pricing Modal')
    this.router.navigate([`/${RouteHeaderUrl.dashboard}`]).then(() => {
      this.dialogRef.close()
    })
  }

  openFaqLink() {
    window.open(this.faqLink, '_blank')
    this.segmentAnalitycsService.track('Clicked FAQ Page Link - Notice of New Pricing Modal')
  }

  openCalendlyLink() {
    const userInfo = this.userService.userInfo
    const name = userInfo?.shop_user?.shopify_shop_user?.first_name ?
      `${userInfo?.shop_user?.shopify_shop_user?.first_name} ${userInfo?.shop_user?.shopify_shop_user?.last_name || ''}`
        : userInfo?.profile?.first_name ? `${userInfo?.profile?.first_name} ${userInfo?.profile?.last_name || ''}` : ''
    const email = userInfo?.shop_user?.shopify_shop_user?.email || userInfo?.email || ''
    const phone = userInfo?.shop_user?.shopify_shop_user?.phone || ''
    const storeUrl = userInfo?.shop?.store_url  || ''
    if (window && window['Calendly'] && window['Calendly'].initPopupWidget) {
      window['Calendly'].initPopupWidget({
        url: 'https://calendly.com/one-csm',
        utm: {
          utmCampaign: 'notice_of_new_pricing'
        },
        prefill: {
          name: name,
          email: email,
          customAnswers: {
            a1: phone,
            a2: storeUrl,
          }
        },
      })

      this.segmentAnalitycsService.track('Clicked Book a Call - Notice of New Pricing Modal')
    }
  }

  private receiveIframeMessage = (message: any) => {
    if (this.isCalendlyEvent(message) && message.data.event.indexOf('event_scheduled') !== -1) {
      this.segmentAnalitycsService.track('Booked a Call - Notice of New Pricing Modal')
    }
  }

  isCalendlyEvent(e) {
    return e.origin === "https://calendly.com" && e.data.event && e.data.event.indexOf('calendly.') === 0
  }

  updateCouponLabels() {
    this.store.dispatch(new ShowLoading('PricingModalData'))
    const calls = []
    for (let i = 0; i < 3; i++) {
      if (this.couponCodes[i] && this.discounts[i].active) {
        calls.push(this.paymentService.validateCoupon(this.couponCodes[i]).pipe(tap(
          (res) => {
            let couponLabel = ''
            if (res?.percent_off) {
              couponLabel = `${res?.percent_off}%`
            } else if (res?.amount_off) {
              couponLabel = `$${res?.amount_off}`
            }
            this.discounts[i].text = `Upgrade before ${moment(this.deadlines[i]).format('MMMM D, YYYY')} for <b>${couponLabel}</b> Lifetime Discount`
          }
        )))
      }
    }
    this.subscription$.add(forkJoin(calls).subscribe({
      error: () => {
        this.store.dispatch(new HideLoading('PricingModalData'))
      },
      complete: () => {
        this.store.dispatch(new HideLoading('PricingModalData'))
      }
    }))
  }

  ngOnDestroy() {
    window.removeEventListener('message', this.receiveIframeMessage)
    this.subscription$.unsubscribe()
  }
}
