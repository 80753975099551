export const languages = [
  { title: 'Albanian', value: 'sq' },
  { title: 'Arabic', value: 'ar' },
  { title: 'Basque', value: 'eu' },
  { title: 'Bengali', value: 'bn_IN' },
  { title: 'Belarusian', value: 'be' },
  { title: 'Bulgarian', value: 'bg' },
  { title: 'Burmese', value: 'my' },
  { title: 'Catalan', value: 'ca' },
  { title: 'Chinese - Simplified', value: 'zh_CN' },
  { title: 'Chinese - Traditional', value: 'zh_TW' },
  { title: 'Danish', value: 'da' },
  { title: 'Dutch', value: 'nl' },
  { title: 'English', value: 'en' },
  { title: 'Farsi', value: 'fa' },
  { title: 'Finnish', value: 'fi' },
  { title: 'French', value: 'fr' },
  { title: 'Galician', value: 'gl' },
  { title: 'Georgian', value: 'ka' },
  { title: 'German', value: 'de' },
  { title: 'Greek', value: 'el' },
  { title: 'Hebrew', value: 'he' },
  { title: 'Hindi', value: 'hi_IN' },
  { title: 'Hungarian', value: 'hu' },
  { title: 'Italian', value: 'it' },
  { title: 'Icelandic', value: 'is' },
  { title: 'Japanese', value: 'ja' },
  { title: 'Korean', value: 'ko' },
  { title: 'Lithuanian', value: 'lt' },
  { title: 'Malayalam', value: 'ml' },
  { title: 'Norwegian - Bokmål', value: 'nb_NO' },
  { title: 'Norwegian - Nynorsk', value: 'nn_NO' },
  { title: 'Polish', value: 'pl' },
  { title: 'Portuguese', value: 'pt_BR' },
  { title: 'Romanian', value: 'ro' },
  { title: 'Russian', value: 'ru' },
  { title: 'Serbian', value: 'sr' },
  { title: 'Spanish', value: 'es' },
  { title: 'Swedish', value: 'sv' },
  { title: 'Tamil', value: 'ta' },
  { title: 'Thai', value: 'th' },
  { title: 'Turkish', value: 'tr' },
  { title: 'Ukrainian', value: 'uk' },
  { title: 'Vietnamese', value: 'vi' },
]
