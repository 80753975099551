const fr = {
  Quantity: 'Quantité',
  Size: 'Taille',
  Color: 'Couleur',
  Material: 'Matériel',
  Style: 'Style',
  Savings: `d'économie supplémentaire`,
  Subtotal: 'Sous-total',
  Shipping: 'Livraison',
  Taxes: 'Taxes',
  Total: 'Total',
  Free: 'Offert',
  Buy: 'Acheter',
  'Pay Now': 'Payez maintenant',
  'Take the Deal': `Prendre l'affaire`,
  'Add Now': 'Ajouter maintenant',
  'You will be charged immediately': 'Vous serez débité immédiatement',
  'Decline Offer': `Refuser l'offre`,
  'No Thanks': 'Non merci',
  '{price}': '{le prix}',
  'Sold Out': 'Épuisé',
  'see more': 'voir plus',
  'N/A': 'Non applicable',
  less: 'moins',
};
export {fr};
