import {Injectable} from '@angular/core'
import {Actions, createEffect, ofType} from '@ngrx/effects'
import {ReadBillingFailure, ReadBillingSuccess} from './billing.actions'
import {catchError, map, switchMap} from 'rxjs/operators'
import {of} from 'rxjs'
import { BillingActionTypes } from './billing.action-types'
import { ApiPaymentService } from '../../core/services/api/api-payment.service'
import { ErrorsService } from '../../core/services/errors.service'

@Injectable()
export class BillingEffects {
  constructor(
    private actions$: Actions,
    private apiPaymentService: ApiPaymentService,
    private errorService: ErrorsService,
  ) {
  }

  readBillingRequest$ = createEffect(() => this.actions$.pipe(
    ofType(BillingActionTypes.READ_BILLING_REQUEST),
    switchMap(() =>
      this.apiPaymentService.getBilling().pipe(
        map(res => new ReadBillingSuccess(res)),
        catchError(err => of(new ReadBillingFailure(this.errorService.extractError(err)))),
      ),
    ),
  ))
}
