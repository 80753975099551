import { EnvironmentName } from './models/environment-name.model'

export const environment = {
  name: EnvironmentName.STAGING,
  production: false,
  server_running: false,
  apiBaseURL: 'https://api-staging.one.store',
  widgetURL: 'https://cdn-staging.one.store/javascript/dist/1.0/jcr-widget.js',
  widgetPreviewURL: 'https://cdn-staging.one.store/javascript/dist/1.0/jcr-widget-preview.js',
  adminPortalBaseURL: 'https://admin-staging.one.store',
  hostname: 'app-staging.one.store',
  title: 'Proof Factor | Increase Your Conversion',
  shopify: {
    clientId: '',
    embedded: false
  },
  // AngularFireModule.initializeApp(),
  // TODO: 
  // - Check if can be deleted
  // - check token interception to inject token to prooffactor-*-cdn-us requests
  firebase: {
    apiKey: 'AIzaSyCGIsGZ7F-XZwALeeq4GSKfFRqxxaPnZ5U',
    authDomain: 'proof-factor-dashboard-staging.firebaseapp.com',
    databaseURL: 'https://proof-factor-dashboard-staging.firebaseio.com',
    projectId: 'proof-factor-dashboard-staging',
    storageBucket: 'cdn-staging.prooffactor.com',
    messagingSenderId: '1096618791622',
    cdnUrl: 'cdn-staging.one.store',
  },
  // firebase
  firebase_prooffactor: {
    apiKey: "AIzaSyCGIsGZ7F-XZwALeeq4GSKfFRqxxaPnZ5U",
    authDomain: "proof-factor-dashboard-staging.firebaseapp.com",
    databaseURL: "https://proof-factor-dashboard-staging.firebaseio.com",
    projectId: "proof-factor-dashboard-staging",
    storageBucket: "prooffactor-staging-cdn-us",
    messagingSenderId: "1096618791622",
    appId: "1:1096618791622:web:983ba84d9c1a7b8246bdc7",
    cdnUrl: 'cdn-staging.prooffactor.com',
  },
  firebase_one: {
    apiKey: "AIzaSyA_pso3BFREdE5JMs735GPN1EQJHY378vk",
    authDomain: "one-store-staging.firebaseapp.com",
    projectId: "one-store-staging",
    storageBucket: "one-staging-cdn-us",
    messagingSenderId: "518609835403",
    appId: "1:518609835403:web:bad9b626275b8e41214b8e",
    measurementId: "G-JX4DGTYYHF",
    cdnUrl: 'cdn-staging.one.store',
  },
  firebase_morpheus: {
    apiKey: "AIzaSyB3CAfGXGdf0pMk3xSbxjBuBLlWdjOPfmU",
    authDomain: "morpheusone-staging.firebaseapp.com",
    projectId: "morpheusone-staging",
    storageBucket: "morpheus-staging-cdn-us",
    messagingSenderId: "943931608856",
    appId: "1:943931608856:web:f0278e8086f7906f7b586d",
    measurementId: "G-8N8YZYKSCF",
    cdnUrl: 'cdn-staging.morpheusone.com',
  },
  stripe: {
    publishableKey: 'pk_test_8h4Ha4Fb7REVxWp5JtvW2GF1',
  },

  signUp: {
    autoGenerateCredentials: false,
    baseEmail: 'staging@prooffactor.com',
    basePass: '123456',
  },

  gettingStarted: {
    active: true,
    detectPollingInterval: 4000,
  },
  // fullStoryOrg: '17DNWX',
  logRocketID: 'morpheus/one-develop',
  googleAnaltyicsToken: 'UA-123323891-2',
  segmentApiKey: 'mpAQfvC7cPh1g4stReE2jLuc0QfnFleW',
  hCaptchaSiteKey: 'cd20bb80-c302-4982-a259-f753cf14e77d',
  jotFormApiKey: 'adec81eea78a10f70896bdbd5521d53f',
  shopifyEmbedPixelId: '8c78e353-26a3-4e2f-9240-6d48b98d8dd9'
}
