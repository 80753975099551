export enum keyValuePairKey {
  PrePlansSurveyNewSubmissionId = 'pre_plans_survey_new_submission_id',
  PrePlansSurveyNewPrefilled4 = 'pre_plans_survey_new_prefilled_4',
  PrePlansSurveyNewPrefilled5 = 'pre_plans_survey_new_prefilled_5',
  PrePlansSurveyNewPrefilled6 = 'pre_plans_survey_new_prefilled_6',
  SlectedPlanTimestamp = 'selected_plan_timestamp',
  ApprovedPlanTimestamp = 'approved_plan_timestamp',
  WhaleDemoEventUri = 'whale_demo_event_uri',
  WhaleDemoInviteeUri = 'whale_demo_invitee_uri',
  WhaleDemoEventId = 'whale_demo_event_id',
  WhaleDemoInviteeId = 'whale_demo_invitee_id',
  PlansPageVersionSeen = 'plans_page_version',
  FreePlanMaxActiveKnownCustomers = 'free_plan_max_active_known_customers',
  FirstAppCampaignLaunched = 'first_app_campaign_launched',
  ApprovedPaidPlan = 'approved_paid_plan',
  UpdatePricingModalShownCount = 'update_pricing_modal_shown_count',
}
