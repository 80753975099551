const ua = {
  Quantity: 'Кількість',
  Size: 'Розмір',
  Color: 'Колір',
  Material: 'Матеріал',
  Style: 'Стиль',
  Savings: 'Економія',
  Subtotal: 'Підсумок',
  Shipping: 'Доставка',
  Taxes: 'Податки',
  Total: 'Всього',
  Free: 'Безкоштовно',
  Buy: 'Купити',
  'Pay Now': 'Платити зараз',
  'Take the Deal': 'Прийміть пропозицію',
  'Add Now': 'Додати зараз',
  'You will be charged immediately': 'З вас буде негайно стягнуто плату',
  'Decline Offer': 'Відхилити пропозицію',
  'No Thanks': 'Ні, дякую',
  '{price}': '{ціна}',
  'Sold Out': 'Розпродано',
  'see more': 'побачити більше',
  less: 'менше',
};
export {ua};
