import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FortuneWheelPreviewComponent } from './fortune-wheel-preview.component'
import { SafePipe } from '../../../../../shared/pipes/safe.pipe'

@NgModule({
  imports: [
    CommonModule,
    SafePipe,
  ],
  declarations: [
    FortuneWheelPreviewComponent
  ],
  exports: [
    FortuneWheelPreviewComponent
  ],
  providers: [],
})

export class FortuneWheelPreviewModule {}
