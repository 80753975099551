import { Injectable } from '@angular/core'

import { Router } from '@angular/router'
import { SavableModel } from '../../shared/models/savable.model'
import { Observable, Subject, Subscription } from 'rxjs'
import { ExternalSaveService } from '../services/external-save.service'
import { Actions, ofType } from '@ngrx/effects'
import { CampaignActionTypes } from '../../store/campaign/campaign.action-types'
import { ConfirmModalComponent, ConfirmModalConfig } from '../../shared/components/modals/confirm.modal/confirm.modal.component'
import { MatDialog } from '@angular/material/dialog'

@Injectable()
export class SavableGuard  {
  updateCampaignRequestSubscription: Subscription

  constructor(
    private dialog: MatDialog,
    private externalSaveService: ExternalSaveService,
    private actions$: Actions,
    private router: Router
  ) {}

  canDeactivate(component: SavableModel): boolean | Observable<boolean> {
    if (!component || !component.isChanged) {
      return true
    }

    const subject = new Subject<boolean>()
    // If we press skip button, than we pass skipStep bool to route extras and the text in modal should change
    const routeState = this.router.getCurrentNavigation().extras.state
    const dialogText =  routeState && routeState.skipStep ? 'Are you sure you want to skip the step without saving?'
      : 'Are you sure you want to see the next step without saving?'
    const dialogCancelButtonText =  routeState && routeState.skipStep ? 'Skip' : 'Continue'
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      width: '560px',
      data: {
        title: 'Plugin configuration has unsaved changes',
        text: dialogText,
        cancelButton: {
          text: `${dialogCancelButtonText} without saving`,
          classes: 'pf-button filled red'
        },
        acceptButton: {
          text: 'Save and continue',
          classes: 'pf-button filled green'
        }
      } as ConfirmModalConfig,
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.updateCampaignRequestSubscription = this.actions$.pipe(
          ofType(
            CampaignActionTypes.UPDATE_CAMPAIGN_SUCCESS,
            CampaignActionTypes.UPDATE_PLUGIN_CONFIG_SUCCESS
          )).subscribe(() => {
            this.updateCampaignRequestSubscription && this.updateCampaignRequestSubscription.unsubscribe()
            subject.next(result)
            subject.complete()
          })
        this.externalSaveService.set(true)
        component.isChanged = false;
      } else if (result === null || result === undefined) {
        subject.next(false)
      } else {
        this.externalSaveService.set(false);
        subject.next(true)
        subject.complete()
        component.isChanged = false;
      }
    })

    return subject.asObservable()
  }
}
