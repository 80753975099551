import { Injectable } from '@angular/core'
import {
  CouponCode, CouponCodeState,
  CouponCodeTargetSelection,
  CouponCodeType, CouponStatus,
  UICouponCodeTypeName,
} from '../../../../../models/coupon-code'
import moment from 'moment'
import { WooCommerceCoupon } from '../interfaces/woo-commerce-coupon.interface'
import { UIWooCommerceCouponCodeSummary } from '../interfaces/woo-commerce-coupon-summery.interface'
import { DiscountType } from '../enums/discount-type.enum'
import * as _ from 'lodash'

@Injectable({
  providedIn: 'root'
})
export class WooCommerceCouponService {
  summary: UIWooCommerceCouponCodeSummary

  constructor() { }

  updateSummary(value: WooCommerceCoupon, randomString: string, currency: string = ''): UIWooCommerceCouponCodeSummary {
    this.summary = {
      schedule: {
        start: this.summaryDate('Starting at', value.data.starts_at, 'LLL'),
        end: this.summaryDate('Ends at', value.data.ends_at, 'LLL'),
      },
      codeType: this._summaryCodeType(value.type),
      code: this._summaryCode(value, randomString),
      randomString: randomString,
      discount: this.summaryDiscount(value, currency) || null,
      discoutnRelatedTo: value.type !== CouponCodeType.manual_master ? this.summaryDiscountRelatedTo(value) : null,
      limit: this.summaryUsageLimit(value),
      minimumSpend: value.data.prerequisite_subtotal_min,
      maximumSpend: value.data.prerequisite_subtotal_max,
      freeShipping: value.data.free_shipping,
      excludeSaleItems: value.data.exclude_sale_items,
      stackableDiscountCode: value.data.individual_use,
      usageByOneUser: this.summaryUsageByOneUser(value),
      individualUsageItems: this.summaryIndividualUsageItems(value),
    }
    return this.summary
  }

  private summaryUsageByOneUser(value): string {
    if (value.type === CouponCodeType.woo_commerce_master) {
      if (value.data.usage_limit_per_customer > 0) {
        return `Limit of ${value.data.usage_limit_per_customer} per one customer`
      } else {
        return '';
      }
    }
  }

  private summaryIndividualUsageItems(value): string {
    if (value.type === CouponCodeType.woo_commerce_master) {
      if (value.data.limit_usage_to_x_items > 0) {
        return `Limit to ${value.data.limit_usage_to_x_items} number of products this discount can be applies to`
      } else {
        return '';
      }
    }
  }

  public summaryDate(prepend: string, date: string, format: string): string {
    if (!date || !date.length) {
      return null
    }
    return `${prepend} ${moment(date).format(format)}`
  }

  private _summaryCodeType(codeType: CouponCodeType): string {
    switch (codeType) {
      case CouponCodeType.woo_commerce_unique:
        return 'Custom code for each Pop Up'
      case CouponCodeType.woo_commerce_master:
        return 'Same code for all Pop Ups'
      case CouponCodeType.manual_master:
        return 'Same code for all Pop Ups'
      default:
        return null
    }
  }

  private _summaryCode(value: WooCommerceCoupon, randomString: string): string {
    switch (value.type) {
      case CouponCodeType.woo_commerce_master:
        return value.master_code
      case CouponCodeType.manual_master:
        return value.master_code
      case CouponCodeType.woo_commerce_unique:
        return `${value.prefix_code}${randomString}`
      default:
        return null
    }
  }

  public summaryDiscount(value: WooCommerceCoupon, currency?: string): string {
    if (value.type === CouponCodeType.manual_master) {
      return 'Manual'
    } else if ([CouponCodeType.woo_commerce_unique, CouponCodeType.woo_commerce_master].includes(value.type)) {
      switch (value.data.value_type) {
        case DiscountType.Percentage:
          return `${value.data.value}% off for`
        case DiscountType.FixedProduct:
          return `${currency ? currency + ' ' : ''}${value.data.value} off for`
        case DiscountType.FixedCart:
          return `${currency ? currency + ' ' : ''}${value.data.value} off for`
      }
    }
  }

  public summaryDiscountRelatedTo(value: WooCommerceCoupon): string {
    const includedTargetSelector = value.data.entitled_target_selection === CouponCodeTargetSelection.all ? 'all products' : 'specific products';
    const excludedTargetSelector = value.data.excluded_product_ids && value.data.excluded_product_ids.length > 0 ? ', excluding specific products' : '';
    return `${includedTargetSelector}${excludedTargetSelector}`;
  }

  public summaryUsageLimit(value: WooCommerceCoupon): string {
    let totalLimit;
    let perCustomerLimit;
    let itemsLimit;
    switch (value.type) {
      case CouponCodeType.manual_master:
        return null;
      case CouponCodeType.woo_commerce_master:
        if (value.data.usage_limit > 0) {
          totalLimit = `${value.data.usage_limit} use in total`;
        }
        if (value.data.usage_limit_per_customer > 0) {
          perCustomerLimit = `${value.data.usage_limit_per_customer} use per customer`
        }
        if (value.data.limit_usage_to_x_items) {
          itemsLimit = `${value.data.limit_usage_to_x_items} max individual items number`
        }
        if (totalLimit > 0 || perCustomerLimit > 0 || itemsLimit > 0) {
          return `Limit: ${[totalLimit, perCustomerLimit, itemsLimit].join(', ')}`;
        } else {
          return null
        }
      case CouponCodeType.woo_commerce_unique:
        return 'Limit of 1 use, per one customer'
      default:
        return null;
    }
  }

  public getDiscountText(coupon: CouponCode, currency?: string): string {
    let discount;
    let discountRelatedTo;
    switch (coupon.type) {
      case CouponCodeType.woo_commerce_master:
      case CouponCodeType.woo_commerce_unique:
        discount = this.summaryDiscount(coupon as WooCommerceCoupon, currency)
        discountRelatedTo = this.summaryDiscountRelatedTo(coupon as WooCommerceCoupon)
        return `${discount} ${discountRelatedTo}`
      default:
        return null
    }
  }

  public couponStatus(coupon: WooCommerceCoupon): string {
    if (coupon.type === CouponCodeType.manual_master || coupon.type === CouponCodeType.manual_unique) {
      return CouponCodeState.active
    }

    if (coupon.status === CouponStatus.disabled) {
      return CouponCodeState.disabled
    }

    if (coupon.status === CouponStatus.archived) {
      return CouponCodeState.archived
    }

    const today = moment()
    const startDate = _.get(coupon, 'data.starts_at')
    const endDate = _.get(coupon, 'data.ends_at')

    if (endDate && moment(endDate).isBefore(today)) {
      return CouponCodeState.expired
    }

    if (startDate && moment(startDate).isAfter(today)) {
      return CouponCodeState.scheduled
    }

    return CouponCodeState.active
  }

  public getCouponTypeName(type: CouponCodeType) {
    return UICouponCodeTypeName[type]
  }
}
