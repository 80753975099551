<div class="PfPhoneInput">

  <pf-country-codes
    (onCountrySelected)="onCountrySelected($event)"
    [countryCode]="countryCode"
    [disabled]="disabled"
  ></pf-country-codes>

  <div class="PfPhoneInput-Input-Wrap">
    <small class="PfPhoneInput-Example text-muted" *ngIf="example && phoneExample">Example: {{phoneExample}}</small>
    <input
      [class._v-align]="!example"
      (ngModelChange)="onNumberChanged($event)"
      [(ngModel)]="phoneNumber"
      [disabled]="disabled"
      [class._error]="invalid"
      class="PfPhoneInput-Input"/>

<!--    <small *ngIf="ngControl?.errors?.phoneInvalid && ngControl.dirty"-->
<!--      class="pl-2 text-danger">-->
<!--      {{patternErrorText || 'Phone number is not valid.'}}-->
<!--    </small>-->
<!--    <small *ngIf="ngControl?.errors?.required && ngControl.dirty"-->
<!--           class="pl-2 text-danger">-->
<!--     Phone Number can't be blank.-->
<!--    </small>-->
  </div>

</div>
