import { UserService } from './../../core/services/user.service';
import { Component, OnDestroy } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Logger } from '../../core/services/logger.service'
import { Subscription } from 'rxjs'
import { Store } from '@ngrx/store'
import { StoreState } from '../../store/store.state'
import { ShowLoading, HideLoading, ResetLoading } from '../../store/loading/loading.actions'
import { LoadingLabel } from '../../shared/models/loading/loading-label'
import { environment } from '../../../environments/environment'
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'
import { emailsServicesBlacklist } from '../email-services-blacklist'
import { PromoService } from '../../shared/modules/promo/services/promo.service'
import { AuthService } from '../../core/services/auth.service'
import { BrandingService } from '../../core/services/branding.service'
import { ShopifyService } from '../../core/services/shopify.service'
import { BrandingDomain } from '../../shared/models/branding-data.model'

@Component({
  styleUrls: ['./register.component.scss'],
  templateUrl: './register.component.html',
})
export class RegisterComponent implements OnDestroy {
  errors = []
  subscription: Subscription = new Subscription()
  isHidden = false
  hasSentEmail = false
  brandingName: string
  registerForm: UntypedFormGroup
  googleSigninAvailable = true

  constructor(
    private authService: AuthService,
    private logger: Logger,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<StoreState>,
    private brandingService: BrandingService,
    private fb: UntypedFormBuilder,
    private shopifyService: ShopifyService,
    private promoService: PromoService,
    private userService: UserService
  ) {
    this.brandingName = this.brandingService.getBrandingData().name
    this.googleSigninAvailable = this.brandingService.getBrandingData().google_login
    this.registerForm = this.fb.group({
      email: this.fb.control(''),
      password: this.fb.control(''),
    })
    this.subscription.add(
      this.route.queryParams.subscribe(params => {
        if (params['email']) {
          this.registerForm.patchValue({email: params['email']})
          this.router.navigate([], { queryParams: { email: null }, queryParamsHandling: 'merge' })
        }
      })
    )
    this.store.dispatch(new ResetLoading())

    if (environment.signUp && environment.signUp.autoGenerateCredentials) {
      const generatedEmail = environment.signUp.baseEmail.replace(/.+@/, (substring: string) => {
        return `${substring.split('@')[0]}+${Date.now()}@`
      })
      const generatedPassword = environment.signUp.basePass
      if (generatedEmail) {
        this.registerForm.patchValue({email: generatedEmail})
      }
      if (generatedPassword) {
        this.registerForm.patchValue({email: generatedPassword})
      }
    }
    this.promoService.definePromo({removeUrlParams: true}, this.userService.userInfo)
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }

  signUpWithEmail(event) {
    event.preventDefault()
    const emailDomain = this.registerForm.value.email.replace(/.*@/, '')
    if (emailsServicesBlacklist.includes(emailDomain)) {
      return false
    }
    this.errors = []
    this.isHidden = true
    this.store.dispatch(new ShowLoading(LoadingLabel.SignUpEmail))
    this.authService.register(this.registerForm.value.email, this.registerForm.value.password)
      .then(() => this.onRegisterSuccess())
      .catch((error) => this.onRegisterError(error))
      .finally(() => this.store.dispatch(new HideLoading(LoadingLabel.SignUpEmail)))
  }

  signUpWithShopify() {
    if (this.shopifyService.getShopifyAuthUrl('register')) {
      window.location.href = this.shopifyService.getShopifyAuthUrl('register')
    } else {
      this.router.navigate(['/register/shopify'])
    }
  }

  signUpWithEmailLink(event) {
    event.preventDefault()
    const emailDomain = this.registerForm.value.email.replace(/.*@/, '')
    if (emailsServicesBlacklist.includes(emailDomain)) {
      return false
    }

    this.errors = []
    this.isHidden = true
    this.store.dispatch(new ShowLoading(LoadingLabel.SignUpEmail))
    this.authService.signInWithEmailLink(this.registerForm.value.email)
      .then(() => {
        this.authService.setSignInEmail(this.registerForm.value.email)
        this.hasSentEmail = true
        this.isHidden = false
      })
      .catch((error) => this.onRegisterError(error))
      .finally(() => this.store.dispatch(new HideLoading(LoadingLabel.SignUpEmail)))
  }

  signUpWithGoogle() {
    this.errors = []
    this.isHidden = true
    this.store.dispatch(new ShowLoading(LoadingLabel.SignUpGoogle))
    this.authService.signInWithGoogle()
      .then(() => this.onRegisterSuccess())
      .catch((error) => this.onRegisterError(error))
      .finally(() => this.store.dispatch(new HideLoading(LoadingLabel.SignUpGoogle)))
  }

  private onRegisterSuccess() {
    this.isHidden = true
    this.router.navigateByUrl('/onboarding/signup/new/subscriptions')
  }

  private onRegisterError(error) {
    this.isHidden = false
    this.logger.error(null, `error: ${JSON.stringify(error)}`)
    this.errors = [error.message]
  }
}
