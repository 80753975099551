import { Route } from '@angular/router'
import { Campaign, CampaignPluginName } from '../../../models/campaign/campaign';
import { CouponBoxNotificationParams } from '../../../models/campaign/coupon-box-notification.model';
import { FortuneWheelNotification } from '../../../models/campaign/fortune-wheel-notification';
import { StepperMenu } from '../../stepper/models/stepper-menu.model'

// ID to see who is triggering the window.history.state
export const FLOW_ID = 'GiftCardOnboarding'

// Single source of step names. Change here if you need to edit URL routes
export enum GiftCardOnboardingStepName {
  welcome = 'welcome',
  education = 'education',
  compliance = 'compliance',
  contact = 'contact',
  permissions = 'permissions',
  setup = 'setup'
}


export enum GiftCardOnboardingLocalStorage {
  returnURL = 'pf_gift_card_onboarding_return_url',
  permissions = 'pf_gift_card_onboarding_permissions'
}

// Used to build a menu stepper and pass data between navigation
export interface GiftCardStepperStep extends StepperMenu {
  title: string
  validation: boolean
  url?: GiftCardOnboardingStepName
  index?: number
  flow_id?: string
  passed?: boolean
  clickable?: boolean
  disabled?: boolean
  flow?: GiftCardOnboardingFlow[GiftCardOnboardingStepName]
}

interface GiftCardForm {
  first_name?: string
  last_name?: string
  phone?: string
  email?: string
  understand_reward_differences?: boolean
  understand_gift_card_law?: boolean
  understand_multiple_gift_card_usage?: boolean
  agree_to_not_hold_gift_card_liability?: boolean
  agree_to_gift_card_tos?: boolean
}

export interface GiftCardFormRequest {
  gift_card_compliance_officer_profile: GiftCardForm
}

export interface GiftCardFormResponse extends GiftCardForm {
  gift_card_scopes_granted: boolean
  completed: boolean
}

// Describes ROUTE_STEPS which are used to define steps and their order
export interface GiftCardOnboardingRouteStep extends Route {
  data?: GiftCardStepperStep
}
// This class controls the flow and stores all the data
export class GiftCardOnboardingFlow {
  [GiftCardOnboardingStepName.welcome] = {
    valid: false,
    returnURL: null,
    showGiftCardOnboarding: false,
    openStep: null,
  };
  [GiftCardOnboardingStepName.education] = {
    understand_reward_differences: false,
    valid: false
  };
  [GiftCardOnboardingStepName.compliance] = {
    understand_gift_card_law: false,
    understand_multiple_gift_card_usage: false,
    agree_to_not_hold_gift_card_liability: false,
    valid: false
  };
  [GiftCardOnboardingStepName.contact] = {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    agree_to_gift_card_tos: false,
    valid: false
  };
  [GiftCardOnboardingStepName.permissions] = {
    gift_card_scopes_granted: false,
    valid: false
  };
  [GiftCardOnboardingStepName.setup] = {
    valid: false
  };
  completed = false;
}


export interface GiftCardOnboardinRouterState {
  valid: boolean
  pluginId: string
  returnURL: string
  showGiftCardOnboarding: boolean
  showCardSteps: boolean
  hideNextButtonOnLastStep: boolean
  openStep: GiftCardOnboardingStepName
}
export interface GiftCardOnboardinLocalCampaign {
  id: string
  name: string
  hasCoupon: boolean
  hasGiftCard: boolean
  popups: GiftCardOnboardinLocalPopup[]
  originalCampaign: Campaign
}

export interface GiftCardOnboardinLocalPopup {
  type: CampaignPluginName
  hasCoupon: boolean
  hasGiftCard: boolean
  params: CouponBoxNotificationParams | FortuneWheelNotification
}
