import { TutorialVideoItem } from "../../../../pages/tutorials/models/tutorials.model"
import { UserShopType } from "../../../models/user/user-shop-type.model"

export interface CampaignCard {
  img?: string
  title: string
  description: string
  selected?: boolean
  value?: CampaignCardValues
  badges?: any[]
  setup_mins?: number
  quickstart?: boolean
  disabled?: boolean
  isChild?: boolean
  needsSMS?: boolean
  locked?: boolean
  locked_text?: string,
  children?: CampaignCardChildren[]
  children_open?: boolean
  keywords?: string[]
  shopTypes?: UserShopType[],
  hidden?: boolean
  badges_visible?: boolean
  button_text?: string
  tcpa_required?: boolean,
  openUrl?: string,
  hide_title?: boolean,
  locked_value?: CampaignCardValues
  hasTutorial?: boolean,
  videoTutorial?: {
    tutorialType?: string,
    currentVideo?: TutorialVideoItem,
    segmentEvent?: string
  },
  viewOnly?: boolean,
}
interface CampaignCardChildren {
  type?: 'separator' | 'card',
  value?: CampaignCardValues,
  quickstart?: boolean,
  button_text?: string,
  badges?: any[],
  setup_mins?: number,
  badges_visible?: boolean,
  title?: string,
  img?: string,
  description?: string,
  shopTypes?: UserShopType[],
  locked?: boolean,
  locked_text?: string,
  locked_value?: CampaignCardValues,
  inToolbox?: boolean,
  tcpa_required?: boolean,
  toolboxOpen?: boolean,
  toolboxSectionName?: string,
  toolboxTitle?: string,
  toolboxChildren?: {
    badges: any[],
    badges_visible?: boolean,
    title: string,
    description: string,
    value: CampaignCardValues,
    img: string,
    tcpa_required?: boolean,
    locked?: boolean,
    locked_text?: string,
    locked_value?: CampaignCardValues,
    button_text?: string,
  }[],
  hasTutorial?: boolean,
  videoTutorial?: {
    tutorialType?: string,
    currentVideo?: TutorialVideoItem,
    segmentEvent?: string
  },
}


export enum CampaignCardValues {
  Gamification = 'gamification',
  FortuneWheel = 'fortune_wheel',

  SocialProof = 'social_proof',
  RecentSalesPop = 'recent_sales_pop',
  LiveVisitorCount = 'live_visitor_count',
  AggregateSalesPop = 'aggregate_sales_pop',
  AggregateVisitorCount = 'aggregate_visitor_count',

  EmailOnboarding = 'email_onboarding',

  EmailAutomation = 'email_automation',
  CampaignEmails = 'campaign_emails',
  BroadcastEmails = 'broadcast_emails',
  BroadcastCoupon = 'broadcast_coupon',
  BroadcastNewsletter = 'broadcast_newsletter',
  BirthdayEmails = 'birthday_emails',
  UpsellEmails = 'upsell_emails',
  WinbackEmails = 'winback_emails',
  CartRecoveryEmails = 'cart_recovery_emails',

  SMSBroadcastCoupon = 'sms_broadcast_coupon',
  SMSBroadcastNoCoupon = 'sms_broadcast_no_coupon',
  TCPACompliance = 'tcpa_compliance',
  CollectPhoneNumbers = 'collect_phone_numbers',
  SMSAutomation = 'sms_automation',
  SMSUpsell = 'sms_upsell',
  SMSWinback = 'sms_winback',
  SMSBirthday = 'sms_birthday',
  SMSCartRecovery = 'sms_cart_recovery',

  CaptureLeads = 'capture_leads',
  Announcement = 'announcement',
  NewsLetterNotification = 'newsletter_notification',
  CouponBoxNotification = 'coupon_box_notification',
  FreeShippingNotification = 'free_shipping_notification',
  SalesNotification = 'sales_announcement_notification',
  ProductPopup = 'products_announcement_notification',

  Discounts = 'discounts',
  GiftCards = 'gift_cards',
  GiftCardsUnique = 'gift_cards_unique',
  GiftCardsOnBoarding = 'gift_cards_on_boarding',

  UniqueCode = 'unique_code',
  GenericCode = 'generic_code',
  ManualMaster = 'manual_master',
  ManualUnique = 'manual_unique',

  FixedAmount = 'fixed_amount',
  Percentage = 'percentage',
  FreeShipping = 'free_shipping',
  BuyXGetY = 'buy_x_get_y',

  NeedsUpgrade = 'needs_upgrade',
  OpenCrisp = 'open_crisp',

  UpsellCard = 'upsell_card',
  GiftsCard = 'gifts_card',
  RecoveryCard = 'recovery_card',
  RecommendationsCard = 'recommendations_card',
  BirthdayCard = 'birthday_card',
  WinbackCard = 'winback_card',
  WelcomeNewSubscriberCard = 'welcome_new_subscriber',
  WelcomeNewCustomerCard = 'welcome_new_customer',
  WelcomeNewAccountsCard = 'welcome_new_account',
  Welcome = 'welcome',
  ThankYou = 'thank_you',

  UpsellCheckout = 'upsell_checkout',

  ChatGPT = 'chat_gpt',
}

export interface CampaignCardTag {
  label: string
  selected: boolean
  hover?: boolean
}
