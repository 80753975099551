import { CampaignPluginName } from '../../../../shared/models/campaign/campaign'

export enum RoutePluginName {
  LiveVisitorCount = 'live-visitor-count',
  AggregateVisitorCount = 'aggregate-visitor-count',
  AggregateActivity = 'aggregate-activity',
  RecentActivity = 'recent-activity',
  FortuneWheel = 'fortune-wheel',
  CouponBox = 'coupon-box',
  NewsLetterNotification = 'subscribe-box',
  FreeShipping = 'free-shipping',
  SalesPopup = 'sales',
  ProductPopup = 'product',
}

export const CampaignPluginNameMap: { [key in RoutePluginName]: string } = {
  [RoutePluginName.RecentActivity]: CampaignPluginName.RecentActivity,
  [RoutePluginName.AggregateActivity]: CampaignPluginName.AggregateActivity,
  [RoutePluginName.LiveVisitorCount]: CampaignPluginName.LiveVisitorCount,
  [RoutePluginName.AggregateVisitorCount]: CampaignPluginName.AggregateVisitorCount,
  [RoutePluginName.FortuneWheel]: CampaignPluginName.FortuneWheel,
  [RoutePluginName.CouponBox]: CampaignPluginName.CouponBoxNotification,
  [RoutePluginName.NewsLetterNotification]: CampaignPluginName.NewsLetterNotification,
  [RoutePluginName.FreeShipping]: CampaignPluginName.FreeShipping,
  [RoutePluginName.SalesPopup]: CampaignPluginName.SalesPopup,
  [RoutePluginName.ProductPopup]: CampaignPluginName.ProductPopup,
}

export const PluginTypePluginRouteMap: { [key in CampaignPluginName]: string } = {
  [CampaignPluginName.RecentActivity]: RoutePluginName.RecentActivity,
  [CampaignPluginName.AggregateActivity]: RoutePluginName.AggregateActivity,
  [CampaignPluginName.LiveVisitorCount]: RoutePluginName.LiveVisitorCount,
  [CampaignPluginName.AggregateVisitorCount]: RoutePluginName.AggregateVisitorCount,
  [CampaignPluginName.FortuneWheel]: RoutePluginName.FortuneWheel,
  [CampaignPluginName.CouponBoxNotification]: RoutePluginName.CouponBox,
  [CampaignPluginName.NewsLetterNotification]: RoutePluginName.NewsLetterNotification,
  [CampaignPluginName.FreeShipping]: RoutePluginName.FreeShipping,
  [CampaignPluginName.SalesPopup]: RoutePluginName.SalesPopup,
  [CampaignPluginName.ProductPopup]: RoutePluginName.ProductPopup,
}

export enum RoutePluginStepName {
  Appearance = 'design',
  Theme = 'theme',
  Slices = 'rewards',
  Text = 'text',
  Fields = 'fields',
  Design = 'design',
  Coupon = 'reward',
  Orders = 'orders',
  Triggers = 'triggers',
  MarketingEmails = 'marketing_emails',
  MarketingSMS = 'marketing_sms',
  Display = 'display',
  Advanced = 'advanced',
  Integrations = 'integrations',
  Subscribers = 'subscribers',
  Objectives = 'objectives',
  Products = 'products',
  Image = 'image',
  Language = 'language',
  BEHAVIOR = 'behavior',
  Collect = 'collect',
  Conversions = 'conversions'
}
