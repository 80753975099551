import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { MatDatepicker } from '@angular/material/datepicker'
import {
  BigCommerceCoupon,
  CouponCode,
  CouponCodeAllocationMethod,
  CouponCodeCustomerSelection,
  CouponCodeTargetSelection,
  CouponCodeTargetType,
  CouponCodeType,
  CouponCodeValueType,
  CouponStatus,
  ShopifyCoupon,
  TargetSelectionChangeEvent,
  UICouponCodeSummary,
  UICouponMinimumRequirements,
  UICouponTargetSelection,
  UIDiscountValue,
  UIProductSelectorType,
} from '../../models/coupon-code'
import { NewCouponCodesService } from '../../services/new-coupon-code.service'
import moment from 'moment'
import * as _ from 'lodash'
import { Logger } from '../../../../core/services/logger.service'
import { HttpErrorResponse } from '@angular/common/http'
import { filter } from 'rxjs/operators'
import { select, Store } from '@ngrx/store'
import { getUserInfo } from '../../../../store/user/user.selectors'
import { StoreState } from '../../../../store/store.state'
import { UserInfo } from '../../../../store/user/user.state'
import { UserShopType } from '../../../../shared/models/user/user-shop-type.model'
import { HideLoading, ResetLoading, ShowLoading } from '../../../../store/loading/loading.actions'
import {
  ImportExistingDiscountModalComponent,
  ImportResult,
} from '../import-existing-discount-modal/import-existing-discount-modal.component'
import { ProductsSelectorComponent } from '../../../../shared/modules/products-selector/products-selector.component'
import { Product, ProductsSelectorInterface } from '../../../../shared/modules/products-selector/models/Product'
import { ProductsListService } from '../../../../shared/modules/products-selector/services/products-list.service'
import { CouponsImportService } from '../../../../shared/modules/coupons-import/services/coupons-import.service'
import { Subscription } from 'rxjs'
import { CustomSnackbarService } from '../../../../shared/modules/custom-snackbar/custom-snackbar.service'
import { ApiRewardsService } from '../../../../core/services/api/api-rewards.service'
import { RewardsType } from '../../models/rewards-model'
import { RewardsService } from '../../../../core/services/rewards-service.service'
import { ApiCampaignService } from '../../../../core/services/api/api-campaign.service'
import { ApiService } from '../../../../core/services/api/api.service'
import { RedirectService } from '../../../../core/services/redirect.service'
import { LogLabel } from '../../../../shared/models/logger/log-label.model'
import { RouteHeaderUrl } from '../../../../shared/components/one-header/header-navigation.model'
import { CampaignPluginName } from '../../../../shared/models/campaign/campaign'
import { CampaignCardValues } from '../../../../shared/modules/campaign-cards/models/campaign-cards.model'
import { MatDialog } from '@angular/material/dialog'

interface Unit {
  value: UnitValue
  name: string
}
type UnitValue = 'minutes' | 'hours' | 'days' | 'years'
@Component({
  selector: 'pf-new-coupon-code',
  templateUrl: './new-coupon-code.component.html',
  styleUrls: ['./new-coupon-code.component.scss'],
})
export class NewCouponCodeComponent implements OnInit, OnDestroy {
  @ViewChild('formRef', {static: false}) formRef: ElementRef

  @Input() isOverlay: boolean = false
  @Output() closeOverlay = new EventEmitter<CouponCode>()
  @ViewChild('timeControlStart', { static: true }) timeControlStartRef: ElementRef
  @ViewChild('timeControlEnd', { static: true }) timeControlEndRef: ElementRef
  @ViewChild('dateInputStart', { static: true }) dateInputStartRef: ElementRef
  @ViewChild('dateInputEnd', { static: true }) dateInputEndRef: ElementRef
  @ViewChild('pickerStart', { static: true }) datePickerStartRef: MatDatepicker<Date>
  @ViewChild('pickerEnd', { static: true }) datePickerEndRef: MatDatepicker<Date>
  @ViewChild('masterCodeInput', { static: false }) masterCodeInput: ElementRef
  @ViewChild('timeInput') timeInput: ElementRef;

  minDate = new Date()

  formGroup: UntypedFormGroup
  couponCodeType = CouponCodeType
  couponCodeValueType = CouponCodeValueType
  couponCodeTargetType = CouponCodeTargetType
  couponCodeTargetSelection = CouponCodeTargetSelection
  couponCodeAllocationMethod = CouponCodeAllocationMethod
  couponCodeCustomerSelection = CouponCodeCustomerSelection
  uiProductSelectorType = UIProductSelectorType
  userShopType = UserShopType
  uiDiscountValue = UIDiscountValue
  uiCouponTargetSelection = UICouponTargetSelection
  uiCouponMinimumRequirements = UICouponMinimumRequirements

  summary: UICouponCodeSummary
  randomString: string

  isEditMode = false

  minDisountValue = 0.05

  errors: any[] = []
  currency: string

  // For most cases
  defaultProductsListService: ProductsListService
  productsList: string[] | number[] = []
  variantsList: string[] | number[] = []
  collectionsList: string[] | number[] = []

  // For "Buy X get Y" type seletion
  buyProductsListService: ProductsListService
  productsListBuys: string[] | number[] = []
  variantsListBuys: string[] | number[] = []
  collectionsListBuys: string[] | number[] = []

  getProductsListService: ProductsListService
  productsListGets: string[] | number[] = []
  variantsListGets: string[] | number[] = []
  collectionsListGets: string[] | number[] = []

  userInfo: UserInfo = null
  shopType: UserShopType = null

  createdCoupon: CouponCode

  defaultSelectorData: ProductsSelectorInterface = {
    type: this.uiCouponTargetSelection.all,
    ids: {},
    selectMultiple: true,
  }
  productsListByShopType: UIProductSelectorType = null
  subscription = new Subscription()

  maxSafeInt = Number.MAX_SAFE_INTEGER

  unitOptions:Unit[] = [
    { value: 'minutes', name: 'minute (s)' },
    { value: 'hours', name: 'hour (s)' },
    { value: 'days', name: 'day (s)' },
    { value: 'years', name: 'year (s)'}
  ]
  selectedTime = 3
  selectedUnit: UnitValue = 'days'
  showOldStyle = false
  editValue: number
  editOption: UnitValue

  routeHeaderUrl = RouteHeaderUrl
  popupType: CampaignPluginName = null
  onlyStaticCodeAllowed: boolean = false

  constructor(
    private fb: UntypedFormBuilder,
    private apiService: ApiService,
    private apiCampaignService: ApiCampaignService,
    private apiRewardsService: ApiRewardsService,
    private router: Router,
    private route: ActivatedRoute,
    private newCouponCodesService: NewCouponCodesService,
    private couponsImportService: CouponsImportService,
    private logger: Logger,
    private store: Store<StoreState>,
    private redirectService: RedirectService,
    public dialog: MatDialog,
    private rewardsService: RewardsService,
    private snackbarService: CustomSnackbarService,
  ) {
    this.defaultProductsListService = new ProductsListService(this.apiCampaignService)
    this.buyProductsListService = new ProductsListService(this.apiCampaignService)
    this.getProductsListService = new ProductsListService(this.apiCampaignService)
    this.popupType = this.route?.snapshot.parent?.data?.pluginType
    this.onlyStaticCodeAllowed = [CampaignPluginName.FreeShipping, CampaignPluginName.SalesPopup].includes(this.popupType)
  }

  ngOnInit() {
    this.subscription.add(this.store.pipe(
      select(getUserInfo),
      filter(next => !!next),
    ).subscribe((userInfo) => {
      this.userInfo = userInfo
      this.currency = _.get(userInfo, 'shop.profile.currency')
      this.shopType = _.get(userInfo, 'shop.type')
      this.newCouponCodesService.currency = this.currency
      this.setProductsListByShopType()
    }))

    this.subscription.add(this.route.data
      .subscribe((resolved: { data: ShopifyCoupon }) => {
        // do not have edit mode in sms route
        const isSMSRoute = this.router.url.includes('sms')
        if (resolved && resolved.data && !isSMSRoute) {
          this.isEditMode = true
          this.productsList = _.get(resolved, 'data.data.entitled_product_ids', [])
          this.variantsList = _.get(resolved, 'data.data.entitled_variant_ids', [])
          this.collectionsList = _.get(resolved, 'data.data.entitled_collection_ids', [])

          this.productsListBuys = _.get(resolved, 'data.data.customer_buy_product_ids', [])
          this.variantsListBuys = _.get(resolved, 'data.data.customer_buy_product_variant_ids', [])
          this.collectionsListBuys = _.get(resolved, 'data.data.customer_buy_collection_ids', [])

          this.productsListGets = _.get(resolved, 'data.data.customer_get_product_ids', [])
          this.variantsListGets = _.get(resolved, 'data.data.customer_get_product_variant_ids', [])
          this.collectionsListGets = _.get(resolved, 'data.data.customer_get_collection_ids', [])
          this.initForm(resolved.data)
        } else {
          this.initForm()

          if (this.shopType && this.popupType === CampaignPluginName.FreeShipping) {
            this.formGroup.get('data._local.discountType').patchValue((CouponCodeTargetType.shipping_line))
            this._updateEventValue(CouponCodeTargetType.shipping_line)
          }

        }
      }))

    if (_.get(this.route.snapshot, 'queryParams.type') &&
        [ CouponCodeValueType.buy_x_get_y,
          CouponCodeValueType.fixed_amount,
          CouponCodeValueType.percentage,
          CouponCodeTargetType.shipping_line ].includes(_.get(this.route.snapshot, 'queryParams.type'))) {
      this.formGroup.get('data._local.discountType').patchValue(_.get(this.route.snapshot, 'queryParams.type'))
      this._updateEventValue(_.get(this.route.snapshot, 'queryParams.type'))
      this.router.navigate([], { queryParams: { type: null }, queryParamsHandling: 'merge' });
    }

    // No used, but keep this in case we need it in the future
    if (_.get(this.route.snapshot, 'queryParams.type') &&
      [CampaignCardValues.GenericCode].includes(_.get(this.route.snapshot, 'queryParams.type'))) {
        this.formGroup.get('data._local.discountType').patchValue(CouponCodeValueType.percentage)
        this.formGroup.get('type').patchValue(CouponCodeType.shopify_master)
        this._updateEventValue(CouponCodeValueType.percentage)
      this.router.navigate([], { queryParams: { type: null }, queryParamsHandling: 'merge' });
    }
    if (_.get(this.route.snapshot, 'queryParams.type') &&
      [CampaignCardValues.ManualMaster].includes(_.get(this.route.snapshot, 'queryParams.type'))) {
        this.router.navigate([], { queryParams: { type: null }, queryParamsHandling: 'merge' });
    }

    this.subscription.add(
      this.formGroup.get('type').valueChanges.subscribe((type) => {
        const masterCodeControl = this.formGroup.get('master_code')
        if (![this.couponCodeType.shopify_unique, this.couponCodeType.big_commerce_unique].includes(type)) {
          masterCodeControl.setValidators(Validators.required)
        } else {
          masterCodeControl.setValidators(null)
        }
      }),
    )

    this.subscription.add(
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe(() => {
        window.scrollTo(0, 0);
      })
    )
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
    this.store.dispatch(new ResetLoading())
    this.snackbarService.dismiss()
  }

  setProductsListByShopType(): void {
    if (this.shopType === UserShopType.BigCommerceShop) {
      this.productsListByShopType = UIProductSelectorType.bigCommerce
    } else if (this.shopType === UserShopType.ShopifyShop) {
      this.productsListByShopType = UIProductSelectorType.default
    }
  }

  compareWith = (a: Product, b: Product) => {
    return a.unique_id === b.unique_id
  }

  groupValueFn = (selected: { label: string, value: Product }, children: any[]) => {
    this.logger.log(<LogLabel>'new-coupon-code', 'selected', selected, 'children', children)
    return selected.value
  }

  makeSelectorData(target: UICouponTargetSelection, productSelectorType: UIProductSelectorType): ProductsSelectorInterface {
    const data = {
      type: target,
      ids: {},
      selectMultiple: true,
    }

    switch (productSelectorType) {
      case this.uiProductSelectorType.buys:
        if (target === this.uiCouponTargetSelection.entitled_collections) {
          data.ids['collections'] = this.collectionsListBuys
        } else if (target === this.uiCouponTargetSelection.entitled_products) {
          data.ids['products'] = this.productsListBuys
          data.ids['variants'] = this.variantsListBuys
        }
        break
      case this.uiProductSelectorType.gets:
        if (target === this.uiCouponTargetSelection.entitled_collections) {
          data.ids['collections'] = this.collectionsListGets
        } else if (target === this.uiCouponTargetSelection.entitled_products) {
          data.ids['products'] = this.productsListGets
          data.ids['variants'] = this.variantsListGets
        }
        break
      case this.uiProductSelectorType.default:
        if (target === this.uiCouponTargetSelection.entitled_collections) {
          data.ids['collections'] = this.collectionsList
        } else if (target === this.uiCouponTargetSelection.entitled_products) {
          data.ids['products'] = this.productsList
          data.ids['variants'] = this.variantsList
        }
        break
    }
    return data
  }

  showProductSelector(target: UICouponTargetSelection, productSelectorType: UIProductSelectorType) {
    const data: ProductsSelectorInterface = this.makeSelectorData(target, productSelectorType)
    const dialogRef = this.dialog.open(ProductsSelectorComponent, {
      width: '530px',
      data: data,
    })
    this.subscription.add(dialogRef.afterClosed().pipe(filter((result) => !!result))
      .subscribe((result: ProductsSelectorInterface) => {
        if (result) {
          this.setProductsSelection(result, productSelectorType, target)
        }
      }))
  }

  setProductsSelection(data: ProductsSelectorInterface, productSelectorType: UIProductSelectorType, target: UICouponTargetSelection) {
    switch (_.get(this.formGroup, 'value.data._local.discountType')) {
      case this.couponCodeValueType.fixed_amount:
      case this.couponCodeValueType.percentage:
        this.defaultProductsListService.setSelection(data)
        if (target === this.uiCouponTargetSelection.entitled_products) {
          this.productsList = data.ids.products
          this.variantsList = data.ids.variants
        } else if (target === this.uiCouponTargetSelection.entitled_collections) {
          this.collectionsList = data.ids.collections
        }
        break
      case this.couponCodeValueType.buy_x_get_y:
        if (productSelectorType === this.uiProductSelectorType.buys) {
          this.buyProductsListService.setSelection(data)
          if (target === this.uiCouponTargetSelection.entitled_products) {
            this.productsListBuys = data.ids.products
            this.variantsListBuys = data.ids.variants
          } else if (target === this.uiCouponTargetSelection.entitled_collections) {
            this.collectionsListBuys = data.ids.collections
          }
        } else if (productSelectorType === this.uiProductSelectorType.gets) {
          this.getProductsListService.setSelection(data)
          if (target === this.uiCouponTargetSelection.entitled_products) {
            this.productsListGets = data.ids.products
            this.variantsListGets = data.ids.variants
          } else if (target === this.uiCouponTargetSelection.entitled_collections) {
            this.collectionsListGets = data.ids.collections
          }
        }
        break
      default:
        this.defaultProductsListService.setSelection(data)
    }
  }

  checkStyleState() {
    const enabled = this.formGroup.get('data.expires_after_enabled')
    const minutes = this.formGroup.get('data.expires_after_minutes')?.value
    const hours = this.formGroup.get('data.expires_after_hours')?.value
    const days = this.formGroup.get('data.expires_after_days')?.value
    const years = this.formGroup.get('data.expires_after_years')?.value
    if (enabled) {
      const zeroCount = [minutes, hours, days, years].filter(i => i === 0).length
      if (zeroCount !== 3) {
        this.showOldStyle = true
      } else {
        this.showOldStyle = false
        this.editValue = minutes || hours || days || years || 3
        const key = minutes ? 'minutes' : hours ? 'hours' : days ? 'days' : years ? 'years' : 'days'
        this.editOption = key
      }
    }
  }

  // hourOrDayRequired(group: FormGroup): { [s: string]: boolean } {
  //   const daysCtrl = group.controls['expires_after_days']
  //   const hoursCtrl = group.controls['expires_after_hours']
  //   const minutesCtrl = group.controls['expires_after_minutes']
  //   const yearsCtrl = group.controls['expires_after_years']
  //   const enabledCtrl = group.controls['expires_after_enabled']
  //   if (enabledCtrl.value) {
  //     if (daysCtrl.value === 0 && hoursCtrl.value === 0 && minutesCtrl.value === 0 && yearsCtrl.value === 0) {
  //       return { expiresError: true }
  //     } else {
  //       return null
  //     }
  //   } else {
  //     return null
  //   }
  // }

  initForm(coupon?: ShopifyCoupon) {
    let defaultType = null
    let valueType = null
    let minValueValidators = []
    const shopifyShop = this.shopType === UserShopType.ShopifyShop
    const bigCommerceShop = this.shopType === UserShopType.BigCommerceShop
    if (shopifyShop || bigCommerceShop) {
      if (this.onlyStaticCodeAllowed) {
        defaultType = _.get(coupon, 'type', this.couponCodeType[shopifyShop ? 'shopify_master' : 'big_commerce_master'])
      } else {
        defaultType = _.get(coupon, 'type', this.couponCodeType[shopifyShop ? 'shopify_unique' : 'big_commerce_unique'])
      }
      valueType = _.get(coupon, 'data.value_type', this.couponCodeValueType.percentage)
      minValueValidators = [Validators.min(this.minDisountValue), Validators.required]
      if (valueType === this.couponCodeValueType.percentage) {
        minValueValidators.push(Validators.max(100))
      }

      if (!coupon) {
        // If new coupon
        const data = this.makeSelectorData(this.uiCouponTargetSelection.all, this.uiProductSelectorType.default)
        this.defaultProductsListService.setSelection(data)
      } else {
        // If edit
        switch (valueType) {
          case this.couponCodeValueType.buy_x_get_y:
            const buysTargetSelection = this.initTargetSelection(coupon, this.uiProductSelectorType.buys)
            const buysData: ProductsSelectorInterface = this.makeSelectorData(buysTargetSelection, this.uiProductSelectorType.buys)
            this.buyProductsListService.setSelection(buysData)
            const getsTargetSelection = this.initTargetSelection(coupon, this.uiProductSelectorType.gets)
            const getsData: ProductsSelectorInterface = this.makeSelectorData(getsTargetSelection, this.uiProductSelectorType.gets)
            this.getProductsListService.setSelection(getsData)
            break
          case this.couponCodeValueType.percentage:
          case this.couponCodeValueType.fixed_amount:
          default:
            const targetSelection = this.initTargetSelection(coupon, this.uiProductSelectorType.default)
            const data: ProductsSelectorInterface = this.makeSelectorData(targetSelection, this.uiProductSelectorType.default)
            this.defaultProductsListService.setSelection(data)
        }
      }
    } else {
      defaultType = _.get(coupon, 'type', this.couponCodeType.manual_master)
      minValueValidators = null
    }

    let masterCodeValidator = null
    const couponType = _.get(coupon, 'type', defaultType)
    if (![this.couponCodeType.shopify_unique, this.couponCodeType.big_commerce_unique].includes(couponType)) {
      masterCodeValidator = Validators.required
    }

    this.formGroup = this.fb.group({
      id: _.get(coupon, 'id', null),
      title: [_.get(coupon, 'title', ''), Validators.required],
      type: [{
        value: defaultType,
        disabled: this.isEditMode,
      }],
      master_code: [{
        value: _.get(coupon, 'master_code', ''),
        disabled: this.isEditMode,
      }, masterCodeValidator],
      prefix_code: _.get(coupon, 'prefix_code', ''),
      data: this.fb.group({
        coupon_code_length: [_.get(coupon, 'data.coupon_code_length', 8), [Validators.min(6), Validators.max(16)]],
        expires_after_enabled: [{
          value: _.get(coupon, 'data.expires_after_enabled', false),
          disabled: this.isEditMode
        }],
        expires_after_days: [{
          value: _.get(coupon, 'data.expires_after_days', this.selectedTime),
          disabled: this.isEditMode,
        }],
        expires_after_minutes: [{
          value: _.get(coupon, 'data.expires_after_minutes', 0),
          disabled: this.isEditMode,
        }],
        expires_after_hours: [{
          value: _.get(coupon, 'data.expires_after_hours', 0),
          disabled: this.isEditMode,
        }],
        expires_after_years: [{
          value: _.get(coupon, 'data.expires_after_years', 0),
          disabled: this.isEditMode,
        }],
        starts_at: _.get(coupon, 'data.starts_at', moment().format()),
        ends_at: _.get(coupon, 'data.ends_at', ''),
        usage_limit: [{
          value: _.get(coupon, 'data.usage_limit', 1),
          disabled: this.isEditMode && (shopifyShop || bigCommerceShop),
        }, [Validators.min(0), Validators.max(99999999)]],
        once_per_customer: [{
          value: _.get(coupon, 'data.once_per_customer', true),
          disabled: this.isEditMode && (shopifyShop || bigCommerceShop),
        }],
        value: [{
          value: _.get(coupon, 'data.value', 0),
          disabled: this.isEditMode,
        }, minValueValidators],
        value_type: _.get(coupon, 'data.value_type', this.couponCodeValueType.percentage),
        target_type: _.get(coupon, 'data.target_type', this.couponCodeTargetType.line_item),
        target_selection: _.get(coupon, 'data.target_selection', this.couponCodeTargetSelection.all),

        customer_get_quantity: [{
          value: _.get(coupon, 'data.customer_get_quantity', 0),
          disabled: this.isEditMode,
        }, [Validators.min(0), Validators.max(99999999)]],
        customer_get_percentage: [{
          value: _.get(coupon, 'data.customer_get_percentage', 0),
          disabled: this.isEditMode,
        }],

        entitled_product_ids: [_.get(coupon, 'data.entitled_product_ids', [])],
        entitled_variant_ids: [_.get(coupon, 'data.entitled_variant_ids', [])],
        entitled_collection_ids: [_.get(coupon, 'data.entitled_collection_ids', [])],

        customer_buy_product_ids: [_.get(coupon, 'data.customer_buy_product_ids', [])],
        customer_buy_product_variant_ids: [_.get(coupon, 'data.customer_buy_product_variant_ids', [])],
        customer_buy_collection_ids: [_.get(coupon, 'data.customer_buy_collection_ids', [])],

        customer_get_product_ids: [_.get(coupon, 'data.customer_get_product_ids', [])],
        customer_get_product_variant_ids: [_.get(coupon, 'data.customer_get_product_variant_ids', [])],
        customer_get_collection_ids: [_.get(coupon, 'data.customer_get_collection_ids', [])],

        allocation_method: _.get(coupon, 'data.allocation_method', this.couponCodeAllocationMethod.across),
        customer_selection: _.get(coupon, 'data.customer_selection', this.couponCodeCustomerSelection.all),
        prerequisite_customer_ids: [_.get(coupon, 'data.prerequisite_customer_ids', [])],
        prerequisite_saved_search_ids: [_.get(coupon, 'data.prerequisite_saved_search_ids', [])],
        entitled_country_ids: [_.get(coupon, 'data.entitled_country_ids', [])],
        prerequisite_subtotal_min: [{
          value: _.get(coupon, 'data.prerequisite_subtotal_min', 0),
          disabled: this.isEditMode,
        }, [Validators.min(0), Validators.max(Number.MAX_SAFE_INTEGER)]],
        prerequisite_shipping_price_max: [{
          value: _.get(coupon, 'data.prerequisite_shipping_price_max', 0),
          disabled: this.isEditMode,
        }, [Validators.min(0), Validators.max(Number.MAX_SAFE_INTEGER)]],
        prerequisite_quantity_range_min: [{
          value: _.get(coupon, 'data.prerequisite_quantity_range_min', 0),
          disabled: this.isEditMode,
        }, [Validators.min(0), Validators.max(Number.MAX_SAFE_INTEGER)]],
        usage_per_order: _.get(coupon, 'data.usage_per_order', null),
        // for UI purposes
        _local: this.fb.group({
          maxUsesNumberEnabled: !!_.get(coupon, 'data.usage_per_order'),
          defaultTargetSelection: [{
            value: this.initTargetSelection(coupon, this.uiProductSelectorType.default),
            disabled: this.isEditMode,
          }],
          buysTargetSelection: [{
            value: this.initTargetSelection(coupon, this.uiProductSelectorType.buys),
            disabled: this.isEditMode,
          }],
          getsTargetSelection: [{
            value: this.initTargetSelection(coupon, this.uiProductSelectorType.gets),
            disabled: this.isEditMode,
          }],
          discountValue: [{
            value: this.initDiscountValueRadio(coupon),
            disabled: this.isEditMode,
          }],
          buyXgetYDiscount: [{
            value: this.initDiscountValue(coupon),
            disabled: this.isEditMode,
          }],
          setUsageLimit: [{
            value: this.initUsageLimit(coupon),
            disabled: this.isEditMode && (shopifyShop || bigCommerceShop),
          }],
          setEndDate: false,
          discountType: [{
            value: this.initDiscountType(coupon),
            disabled: this.isEditMode || this.popupType === CampaignPluginName.FreeShipping,
          }],
          allocationMethod: [{
            value: this.initAllocationMethod(coupon),
            disabled: this.isEditMode && (shopifyShop || bigCommerceShop),
          }],
          shippingRates: [{
            value: false,
            disabled: this.isEditMode,
          }],
          minimumRequirements: [{
            value: this.uiCouponMinimumRequirements.none,
            disabled: this.isEditMode,
          }],
        }),
      },
        // { validator: this.hourOrDayRequired }
      ),
    })
    this.checkStyleState()
    this._updateRandomString(_.get(this.formGroup.getRawValue(), 'data.coupon_code_length', 8))

    if (this.isEditMode) {
      if (this.datePickerStartRef) this.datePickerStartRef.select(moment(coupon.data.starts_at).toDate())
      if (this.dateInputStartRef) this.dateInputStartRef.nativeElement.value = moment().startOf('day').format('M/D/YYYY')
      if (coupon.data) {
        // Usage Limit
        if (coupon.data.usage_limit && coupon.data.usage_limit > 0) {
          this.formGroup.get('data._local.setUsageLimit').patchValue(true)
        }
        // End date
        if (coupon.data.ends_at) {
          this.formGroup.get('data._local.setEndDate').patchValue(true)
          if (this.datePickerEndRef) this.datePickerEndRef.select(moment(coupon.data.ends_at).toDate())
          if (this.dateInputEndRef) this.dateInputEndRef.nativeElement.value = moment(coupon.data.ends_at).startOf('day').format('M/D/YYYY')
        }
        // Minimum requirements
        if (coupon.data.prerequisite_quantity_range_min > 0) {
          this.formGroup.get('data._local.minimumRequirements').patchValue(this.uiCouponMinimumRequirements.quantity)
        }
        if (coupon.data.prerequisite_subtotal_min > 0) {
          this.formGroup.get('data._local.minimumRequirements').patchValue(this.uiCouponMinimumRequirements.price)
        }
        // Shipping rates
        if (coupon.data.prerequisite_shipping_price_max > 0) {
          this.formGroup.get('data._local.shippingRates').patchValue(true)
        }
      }
    } else {
      if (this.datePickerStartRef) this.datePickerStartRef.select(moment().toDate())
      if (this.dateInputStartRef) this.dateInputStartRef.nativeElement.value = moment().startOf('day').format('M/D/YYYY')
      if (this.datePickerEndRef) this.datePickerEndRef.select(moment().add(6, 'hours').toDate())
      if (this.dateInputEndRef) this.dateInputEndRef.nativeElement.value = moment().add(6, 'hours').startOf('day').format('M/D/YYYY')
    }

    this.subscription.add(this.formGroup.valueChanges.subscribe((value: ShopifyCoupon | BigCommerceCoupon) => {
      this.logger.log(<LogLabel>'new-coupon-code', 'updated_form:', value)
      this.updateSummary(this.randomString)
    }))

    setTimeout(() => {
      this.updateSummary(this.randomString)
    }, 0)

    this.subscription.add(this.formGroup.get('type').valueChanges.subscribe((type: CouponCodeType) => {
      if ([this.couponCodeType.shopify_master, this.couponCodeType.big_commerce_master].includes(type) || type === this.couponCodeType.manual_master) {
        this.formGroup.get('data.usage_limit').patchValue(0)
        this.formGroup.get('prefix_code').setValidators(null)
        if (type === this.couponCodeType.big_commerce_master) {
          this.formGroup.get('master_code').setValidators([Validators.required, Validators.maxLength(50)])
        } else if (type === this.couponCodeType.shopify_master) {
          this.formGroup.get('master_code').setValidators(Validators.required)
        }
      } else if ([this.couponCodeType.shopify_unique, this.couponCodeType.big_commerce_unique].includes(type)) {
        this.formGroup.get('data.usage_limit').patchValue(1)
        this.formGroup.get('prefix_code').setValidators(null)
        this.formGroup.get('master_code').setValidators(null)
      }
      if (type === this.couponCodeType.manual_master) {
        this.formGroup.get('data.value').setValidators(null)
      } else {
        const dataType = this.formGroup.get('data._local.discountType').value
        switch (dataType) {
          case this.couponCodeValueType.fixed_amount:
            this.formGroup.get('data.value').setValidators([Validators.min(this.minDisountValue), Validators.required])
            break
          case this.couponCodeValueType.percentage:
            this.formGroup.get('data.value').setValidators([
              Validators.min(this.minDisountValue),
              Validators.max(100),
              Validators.required])
            break
          case this.couponCodeTargetType.shipping_line:
            this.formGroup.get('data.value').setValidators(null)
            break
        }
      }
      this.formGroup.get('prefix_code').updateValueAndValidity()
      this.formGroup.get('master_code').updateValueAndValidity()
      this.formGroup.get('data.value').updateValueAndValidity()
    }))

    // Discount value validation
    this.subscription.add(this.formGroup.get('data._local.discountType').valueChanges
      .subscribe((type: CouponCodeValueType | CouponCodeTargetType.shipping_line) => {
        if (this.formGroup.get('type').value === this.couponCodeType.manual_master) {
          this.formGroup.get('data.value').setValidators(null)
        } else {
          switch (type) {
            case this.couponCodeValueType.fixed_amount:
            case this.couponCodeValueType.percentage:
              this.formGroup.get('data.value').setValidators([Validators.min(this.minDisountValue), Validators.required])
              break
            case this.couponCodeTargetType.shipping_line:
              this.formGroup.get('data.value').setValidators(null)
              break
          }
        }
        this.formGroup.get('data.value').updateValueAndValidity()
      }))
  }

  onTimeFocus(unit: UnitValue) {
    this.formGroup.get(`data.expires_after_${unit}`).markAsTouched()
  }

  onUnitChange(unit) {
    const time = this.timeInput.nativeElement.value
    this.onTimeChange(unit, time)
  }

  onTimeChange(unit: UnitValue, value: any) {
    const input = parseInt(value, 10)
    if (input === 0) {
      this.timeInput.nativeElement.value = 0
      this.setExpiresError({ empty: true }, null, null, null)
      return
    }
    if (!input || input < 0) {
      this.timeInput.nativeElement.value = ''
      this.setExpiresError({ empty: true }, null, null, null)
      return
    }
    if (unit === 'minutes') {
      if (input > 1051200) {
        this.setExpiresError({ max: true }, null, null, null)
      } else if (input < 8) {
        this.setExpiresError({ min: true }, null, null, null)
      } else {
        this.patchExpiresValue(input, 0, 0, 0)
      }
    }
    if (unit === 'hours') {
      if (input > 17520) {
        this.setExpiresError(null, { max: true }, null, null)
      } else if (input < 1) {
        this.setExpiresError(null, { min: true }, null, null)
      } else {
        this.patchExpiresValue(0, input, 0, 0)
      }
    }
    if (unit === 'days') {
      if (input > 730) {
        this.setExpiresError(null, null, { max: true }, null)
      } else if (input < 1) {
        this.setExpiresError(null, null, { min: true }, null)
      } else {
        this.patchExpiresValue(0, 0, input, 0)
      }
    }
    if (unit === 'years') {
      if (input > 2) {
        this.setExpiresError(null, null, null, { max: true })
      } else if (input < 1) {
        this.setExpiresError(null, null, null, { min: true })
      } else {
        this.patchExpiresValue(0, 0, 0, input)
      }
    }
  }

  setExpiresError(mins: object | null, hours: object | null, days: object | null, years: object | null) {
    this.formGroup.get('data.expires_after_minutes').setErrors(mins)
    this.formGroup.get('data.expires_after_hours').setErrors(hours)
    this.formGroup.get('data.expires_after_days').setErrors(days)
    this.formGroup.get('data.expires_after_years').setErrors(years)
  }

  patchExpiresValue(mins: number, hours: number, days: number, years: number) {
    this.formGroup.get('data.expires_after_minutes').patchValue(mins)
    this.formGroup.get('data.expires_after_hours').patchValue(hours)
    this.formGroup.get('data.expires_after_days').patchValue(days)
    this.formGroup.get('data.expires_after_years').patchValue(years)
  }

  updateSummary(randomString) {
    const raw = this.formGroup.getRawValue()
    this.summary = this.newCouponCodesService.updateSummary(raw, randomString, this.currency)
  }

  initDiscountValueRadio(coupon: ShopifyCoupon | BigCommerceCoupon) {
    const value = _.get(coupon, 'data.customer_get_percentage', 0)
    if (value === 1.0) {
      return this.uiDiscountValue.free
    } else {
      return this.uiDiscountValue.percentage
    }
  }

  initDiscountValue(coupon: ShopifyCoupon | BigCommerceCoupon) {
    const value = _.get(coupon, 'data.customer_get_percentage', 0)
    return (value * 100).toFixed(0)
  }

  updateDiscountValue(value: number) {
    this.formGroup.get('data._local.buyXgetYDiscount').patchValue(value)
    this.formGroup.get('data.customer_get_percentage').patchValue(value * 0.01)
  }

  initTargetSelection(coupon: ShopifyCoupon | BigCommerceCoupon, productSelectorType: UIProductSelectorType): UICouponTargetSelection {
    switch (productSelectorType) {
      case this.uiProductSelectorType.default:
        if (!coupon) {
          return this.uiCouponTargetSelection.all
        }
        if (coupon.data.target_selection === this.couponCodeTargetSelection.all) {
          return this.uiCouponTargetSelection.all
        } else {
          if (_.get(coupon, 'data.entitled_collection_ids.length')) {
            return this.uiCouponTargetSelection.entitled_collections
          } else if (_.get(coupon, 'data.entitled_product_ids.length')) {
            return this.uiCouponTargetSelection.entitled_products
          } else if (_.get(coupon, 'data.entitled_variant_ids.length')) {
            return this.uiCouponTargetSelection.entitled_products
          } else {
            return this.uiCouponTargetSelection.all
          }
        }
      case this.uiProductSelectorType.buys:
        if (_.get(coupon, 'data.customer_buy_collection_ids.length')) {
          return this.uiCouponTargetSelection.entitled_collections
        } else if (_.get(coupon, 'data.customer_buy_product_ids.length')) {
          return this.uiCouponTargetSelection.entitled_products
        } else if (_.get(coupon, 'data.customer_buy_product_variant_ids.length')) {
          return this.uiCouponTargetSelection.entitled_products
        } else {
          return this.uiCouponTargetSelection.entitled_products
        }
      case this.uiProductSelectorType.gets:
        if (_.get(coupon, 'data.customer_get_collection_ids.length')) {
          return this.uiCouponTargetSelection.entitled_collections
        } else if (_.get(coupon, 'data.customer_get_product_ids.length')) {
          return this.uiCouponTargetSelection.entitled_products
        } else if (_.get(coupon, 'data.customer_get_product_variant_ids.length')) {
          return this.uiCouponTargetSelection.entitled_products
        } else {
          return this.uiCouponTargetSelection.entitled_products
        }
    }
  }

  initAllocationMethod(coupon?: ShopifyCoupon | BigCommerceCoupon): boolean {
    if (!coupon) {
      return true
    }
    const allocation = _.get(coupon, 'data.allocation_method', this.couponCodeAllocationMethod.across)
    return allocation === this.couponCodeAllocationMethod.across
  }

  initDiscountType(coupon?: ShopifyCoupon | BigCommerceCoupon): CouponCodeValueType | CouponCodeTargetType.shipping_line {
    if (!coupon) {
      return this.couponCodeValueType.percentage
    }
    if (coupon.data.value_type === CouponCodeValueType.buy_x_get_y) {
      return CouponCodeValueType.buy_x_get_y
    } else {
      if (coupon.data.target_type === this.couponCodeTargetType.shipping_line) {
        return this.couponCodeTargetType.shipping_line
      } else {
        if (coupon.data.value_type === this.couponCodeValueType.percentage) {
          return this.couponCodeValueType.percentage
        } else if (coupon.data.value_type === this.couponCodeValueType.fixed_amount) {
          return this.couponCodeValueType.fixed_amount
        } else {
          // shouldn't happen, returning default just in case
          return this.couponCodeValueType.percentage
        }
      }
    }
  }

  initUsageLimit(coupon?: ShopifyCoupon | BigCommerceCoupon): boolean {
    if (!coupon) {
      return false
    }
    return coupon.data.usage_limit && coupon.data.usage_limit > 0
  }

  public updateValueType(e: Event) {
    const val = (<HTMLInputElement>e.target).value
    this._updateEventValue(val)
  }

  public onMaxUsesNumberEnabled(e: any) {
    if (!e.value) {
      this.formGroup.get('data.usage_per_order').patchValue(null)
    }
  }

  public updateTargetSelection(e: TargetSelectionChangeEvent) {
    if ([this.uiProductSelectorType.default, this.uiProductSelectorType.bigCommerce].includes(e.selectorType)) {
      switch (e.targetSelection) {
        case this.uiCouponTargetSelection.all:
          this.formGroup.get('data.target_selection').patchValue(this.couponCodeTargetSelection.all)
          break
        case this.uiCouponTargetSelection.entitled_collections:
          this.formGroup.get('data.target_selection').patchValue(this.couponCodeTargetSelection.entitled)
          break
        case this.uiCouponTargetSelection.entitled_products:
          this.formGroup.get('data.target_selection').patchValue(this.couponCodeTargetSelection.entitled)
          break
      }
      this.productsListBuys = []
      this.variantsListBuys = []
      this.collectionsListBuys = []
      this.productsListGets = []
      this.variantsListGets = []
      this.collectionsListGets = []
      this.formGroup.get('data').patchValue({
        customer_buy_collection_ids: [],
        customer_buy_product_ids: [],
        customer_buy_product_variant_ids: [],
        customer_get_collection_ids: [],
        customer_get_product_ids: [],
        customer_get_product_variant_ids: [],
      })
      this.defaultProductsListService.setSelection(this.defaultSelectorData)
      this.buyProductsListService.setSelection(this.defaultSelectorData)
      this.getProductsListService.setSelection(this.defaultSelectorData)
    } else if (e.selectorType === this.uiProductSelectorType.buys) {
      switch (e.targetSelection) {
        case this.uiCouponTargetSelection.entitled_collections:
          this.productsListBuys = []
          this.variantsListBuys = []
          this.formGroup.get('data').patchValue({
            customer_buy_product_ids: [],
            customer_buy_product_variant_ids: [],
          })
          break
        case this.uiCouponTargetSelection.entitled_products:
          this.collectionsListBuys = []
          this.formGroup.get('data').patchValue({
            customer_buy_collection_ids: [],
          })
          break
      }
      this.productsList = []
      this.variantsList = []
      this.collectionsList = []
      this.formGroup.get('data').patchValue({
        entitled_collection_ids: [],
        entitled_product_ids: [],
        entitled_variant_ids: [],
      })
      this.buyProductsListService.setSelection(this.defaultSelectorData)
      this.defaultProductsListService.setSelection(this.defaultSelectorData)
    } else if (e.selectorType === this.uiProductSelectorType.gets) {
      switch (e.targetSelection) {
        case this.uiCouponTargetSelection.entitled_collections:
          this.productsListGets = []
          this.variantsListGets = []
          this.formGroup.get('data').patchValue({
            customer_get_product_ids: [],
            customer_get_product_variant_ids: [],
          })
          break
        case this.uiCouponTargetSelection.entitled_products:
          this.collectionsListGets = []
          this.formGroup.get('data').patchValue({
            customer_get_collection_ids: [],
          })
          break
      }
      this.productsList = []
      this.variantsList = []
      this.collectionsList = []
      this.formGroup.get('data').patchValue({
        entitled_collection_ids: [],
        entitled_product_ids: [],
        entitled_variant_ids: [],
      })
      this.getProductsListService.setSelection(this.defaultSelectorData)
      this.defaultProductsListService.setSelection(this.defaultSelectorData)
    }
    this.updateAllocationMethod()
  }

  public updateMinRequirements() {
    this.formGroup.get('data.prerequisite_subtotal_min').patchValue(0)
    this.formGroup.get('data.prerequisite_quantity_range_min').patchValue(0)
  }

  public updateStartDate() {
    const date = moment(this.dateInputStartRef.nativeElement.value, 'M/D/YYYY').startOf('day')
    date.add(this.timeControlStartRef.nativeElement.value, 'hour')
    const result = date.format()
    this.formGroup.get('data.starts_at').patchValue(result)
  }

  public toggleEndDate(enabled: boolean) {
    if (enabled) {
      if (!this.formGroup.value.data.ends_at) {
        this.datePickerEndRef.select(moment().add(6, 'hours').toDate())
        this.dateInputEndRef.nativeElement.value = moment().add(6, 'hours').startOf('day').format('M/D/YYYY')
      }
      this.updateEndDate()
    } else {
      this.formGroup.get('data.ends_at').patchValue('')
    }
  }

  public updateEndDate() {
    const date = moment(this.dateInputEndRef.nativeElement.value, 'M/D/YYYY').startOf('day')
    date.add(this.timeControlEndRef.nativeElement.value, 'hour')
    const result = date.format()
    this.formGroup.get('data.ends_at').patchValue(result)
  }

  public generateCode(e: Event, formControlName: string) {
    e.preventDefault()
    this.formGroup.patchValue({
      master_code: '',
      prefix_code: '',
    })
    const code = this.newCouponCodesService.generateRandomCode()
    this.formGroup.get(formControlName).patchValue(code)
    this.formGroup.markAsDirty()
  }

  public updateAllocationMethod() {
    const localData = this.formGroup.getRawValue().data._local
    const allocationSwitch = localData.allocationMethod
    const discountTypeNotFixed = localData.discountType !== this.couponCodeValueType.fixed_amount
    const targetSelectionIsAll = localData.defaultTargetSelection === this.uiCouponTargetSelection.all
    const isShipping = localData.discountType === this.couponCodeTargetType.shipping_line
    // discountType and targetSelection can affect visibility of this control
    // if it's hidden should be set to default = `across`
    // if it's a shipping it should be `each`
    if (isShipping) {
      this.formGroup.get('data.allocation_method').patchValue(this.couponCodeAllocationMethod.each)
    } else {
      if (discountTypeNotFixed || targetSelectionIsAll) {
        this.formGroup.get('data.allocation_method').patchValue(this.couponCodeAllocationMethod.across)
      } else {
        if (allocationSwitch) {
          this.formGroup.get('data.allocation_method').patchValue(this.couponCodeAllocationMethod.across)
        } else {
          this.formGroup.get('data.allocation_method').patchValue(this.couponCodeAllocationMethod.each)
        }
      }
    }
  }

  public updateMinimumRequirements() {
    this.formGroup.get('data.prerequisite_quantity_range_min').patchValue(0)
    this.formGroup.get('data.prerequisite_subtotal_min').patchValue(0)
  }

  public updateShippingRates() {
    const value = this.formGroup.value.data._local.shippingRates
    if (!value) {
      this.formGroup.get('data.prerequisite_shipping_price_max').patchValue(0)
    }
  }

  public onValueChange(controlName: string, event: Event, max = Number.MAX_SAFE_INTEGER, min = 0, isInt = true) {
    const value = (event.target as HTMLInputElement).value
    const _val = parseInt(value, 10)
    const control = this.formGroup.get(`data.${controlName}`)
    if (_val !== _val) {
      control.patchValue(min)
    } else if (_val > max) {
      control.patchValue(max)
    } else if (_val < min) {
      control.patchValue(min)
    } else if (isInt) {
      control.patchValue(_val)
    }
  }

  private _updateRandomString(val) {
    this.randomString = NewCouponCodesService.randomString(parseInt(val, 10))
    this.updateSummary(this.randomString)
  }

  public touchControl(controlName: string) {
    this.formGroup.get(`data.${controlName}`).markAsTouched()
  }

  public onCodeLengthChange(event: Event) {
    const val = (event.target as HTMLInputElement).value
    const _val = parseInt(val, 10)
    if (_val !== _val || _val < 6) {
      this._updateRandomString(6)
      this.formGroup.get('data.coupon_code_length').patchValue(6)
    } else if (_val > 16) {
      this._updateRandomString(16)
      this.formGroup.get('data.coupon_code_length').patchValue(16)
    } else {
      this._updateRandomString(val)
    }
  }

  private _updateEventValue(val) {
    switch (val) {
      case this.couponCodeTargetType.shipping_line:
        this.formGroup.get('data.target_type').patchValue(val)
        this.formGroup.get('data.value_type').patchValue(this.couponCodeValueType.percentage)
        this.formGroup.get('data.value').patchValue(100)
        this.formGroup.get('data.allocation_method').patchValue(this.couponCodeAllocationMethod.each)
        this.formGroup.get('data._local.minimumRequirements').patchValue(this.uiCouponMinimumRequirements.none)
        this.formGroup.get('data.usage_per_order').patchValue(null)
        break
      case this.couponCodeValueType.buy_x_get_y:
        this.formGroup.get('data.value_type').patchValue(val)
        this.formGroup.get('data.target_selection').patchValue(this.couponCodeTargetSelection.all)
        this.formGroup.get('data.target_type').patchValue(this.couponCodeTargetType.line_item)
        this.formGroup.get('data.value').patchValue(0)
        this.formGroup.get('data._local.minimumRequirements').patchValue(this.uiCouponMinimumRequirements.quantity)
        this.updateMinimumRequirements()
        break
      default:
        this.formGroup.get('data.value_type').patchValue(val)
        this.formGroup.get('data.target_type').patchValue(this.couponCodeTargetType.line_item)
        this.formGroup.get('data.value').patchValue(0)
        this.formGroup.get('data._local.minimumRequirements').patchValue(this.uiCouponMinimumRequirements.none)
        this.formGroup.get('data.usage_per_order').patchValue(null)
    }
    this.updateShippingRates()
    this.updateAllocationMethod()
  }

  private _removeUnrelatedProducts(value) {
    const defaultTargetSelection = value.data._local.defaultTargetSelection
    const buysTargetSelection = value.data._local.buysTargetSelection
    const getsTargetSelection = value.data._local.getsTargetSelection
    const valueType = value.data.value_type

    delete value.data._local

    if ([UserShopType.ShopifyShop, UserShopType.BigCommerceShop].includes(this.shopType) && value.data.target_type === CouponCodeTargetType.line_item) {
      switch (valueType) {
        case this.couponCodeValueType.buy_x_get_y:
          if (buysTargetSelection === this.uiCouponTargetSelection.all) {
            value.data.customer_buy_collection_ids = []
            value.data.customer_buy_product_ids = []
            value.data.customer_buy_product_variant_ids = []
          } else if (buysTargetSelection === this.uiCouponTargetSelection.entitled_products) {
            value.data.customer_buy_collection_ids = []
            value.data.customer_buy_product_ids = this.productsListBuys
            value.data.customer_buy_product_variant_ids = this.variantsListBuys
          } else if (buysTargetSelection === this.uiCouponTargetSelection.entitled_collections) {
            value.data.customer_buy_collection_ids = this.collectionsListBuys
            value.data.customer_buy_product_ids = []
            value.data.customer_buy_product_variant_ids = []
          }
          if (getsTargetSelection === this.uiCouponTargetSelection.all) {
            value.data.customer_get_collection_ids = []
            value.data.customer_get_product_ids = []
            value.data.customer_get_product_variant_ids = []
          } else if (getsTargetSelection === this.uiCouponTargetSelection.entitled_products) {
            value.data.customer_get_collection_ids = []
            value.data.customer_get_product_ids = this.productsListGets
            value.data.customer_get_product_variant_ids = this.variantsListGets
          } else if (getsTargetSelection === this.uiCouponTargetSelection.entitled_collections) {
            value.data.customer_get_collection_ids = this.collectionsListGets
            value.data.customer_get_product_ids = []
            value.data.customer_get_product_variant_ids = []
          }
          value.data.entitled_product_ids = []
          value.data.entitled_variant_ids = []
          value.data.entitled_collection_ids = []
          break
        case this.couponCodeValueType.percentage:
        case this.couponCodeValueType.fixed_amount:
        default:
          if (defaultTargetSelection === this.uiCouponTargetSelection.all) {
            value.data.entitled_product_ids = []
            value.data.entitled_variant_ids = []
            value.data.entitled_collection_ids = []
          } else if (defaultTargetSelection === this.uiCouponTargetSelection.entitled_products) {
            value.data.entitled_collection_ids = []
            value.data.entitled_product_ids = this.productsList
            value.data.entitled_variant_ids = this.variantsList
          } else if (defaultTargetSelection === this.uiCouponTargetSelection.entitled_collections) {
            value.data.entitled_collection_ids = this.collectionsList
            value.data.entitled_product_ids = []
            value.data.entitled_variant_ids = []
          }
          value.data.customer_buy_product_ids = []
          value.data.customer_buy_product_variant_ids = []
          value.data.customer_buy_collection_ids = []
          value.data.customer_get_product_ids = []
          value.data.customer_get_product_variant_ids = []
          value.data.customer_get_collection_ids = []
      }
    }
    if (value.type === CouponCodeType.manual_master) {
      delete value.data
    }

    if (value.data && value.type !== CouponCodeType.shopify_unique && value.type !== CouponCodeType.big_commerce_unique) {
      delete value.data.expires_after_enabled
      delete value.data.expires_after_days
      delete value.data.expires_after_minutes
      delete value.data.expires_after_hours
      delete value.data.expires_after_years
      delete value.data.coupon_code_length
    }

    return value
  }

  public submit() {
    this.snackbarService.dismiss()
    // Remove data.value validation for buy_x_get_y
    const discountType = _.get(this.formGroup, 'value.data._local.discountType')
    if (this.shopType === UserShopType.ShopifyShop || this.shopType === UserShopType.BigCommerceShop) {
      if (discountType === this.couponCodeValueType.buy_x_get_y) {
        this.formGroup.get('data.value').clearValidators()
        this.formGroup.get('data.value').updateValueAndValidity()
        // set discount percentage as float
        const percents = this.formGroup.get('data._local.buyXgetYDiscount').value
        this.formGroup.get('data.customer_get_percentage').patchValue(percents * 0.01)
      }
    }

    // Stop if form is invalid
    if (!this.formGroup.valid) {
      this.logger.log(<LogLabel>'new-coupon-code', 'form validation failed', this.formGroup.errors, 'form:', this.formGroup)
      this.formGroup.markAllAsTouched()
      this.formRef.nativeElement.dispatchEvent(new Event('scrollToInvalid'))
      return
    }

    // Adapt form for payload
    const clonedValue = _.cloneDeep(this.formGroup.getRawValue())
    const value = this._removeUnrelatedProducts(clonedValue)

    // Validate before hit Shopify Limits
    if (this.shopType === UserShopType.ShopifyShop) {
      if(value.data.customer_buy_product_ids.length > 100 ||
        value.data.customer_buy_product_variant_ids.length > 100 ||
        value.data.customer_get_product_ids.length > 100 ||
        value.data.customer_get_product_variant_ids.length > 100 ||
        value.data.entitled_product_ids.length > 100 ||
        value.data.entitled_variant_ids.length > 100
        ) {
        this.snackbarService.showError({ title: 'Exceeded Maximum of 100 Products', text: 'Reached Shopify limitation where a coupon can only be applied to 100 products/variants at a time. Select fewer products or choose Collections instead.' })
        return
      }
    }

    this.store.dispatch(new ShowLoading('new-coupon-code'))
    if ([CouponCodeType.shopify_master, CouponCodeType.big_commerce_master].includes(value.type) && !this.isEditMode) {
      this.subscription.add(this.apiService.get(`/v1/me/shop/coupon_lookup?code=${encodeURIComponent(value.master_code)}`)
        .subscribe((response) => {
          this.logger.log(<LogLabel>'new-coupon-code', 'response: ', response)
          if (response.error === 'not_found') {
            this.submitCouponCodeRequest(value)
          } else {
            this.store.dispatch(new HideLoading('new-coupon-code'))
            const dialogRef = this.dialog.open(ImportExistingDiscountModalComponent, { data: { shop: this.shopType } })
            this.subscription.add(dialogRef.afterClosed().subscribe((result: ImportResult) => {
              this.logger.log(<LogLabel>'new-coupon-code', 'dialog-result: ', result)
              if (_.get(result, 'import', false)) {
                this.store.dispatch(new ShowLoading('new-coupon-code-import'))
                this.subscription.add(this.couponsImportService.importCoupons([response.id])
                  .subscribe((res) => {
                    this.createdCoupon = res
                    this.logger.log(<LogLabel>'new-coupon-code', 'response: ', res)
                    this.navigateAfterSave()
                  }, (res: HttpErrorResponse) => {
                    this.errors.push(res.error)
                    this.showErrors()
                    this.logger.log(<LogLabel>'new-coupon-code', 'new_errors: ', this.errors)
                    this.store.dispatch(new HideLoading('new-coupon-code-import'))
                  }, () => {
                    this.store.dispatch(new HideLoading('new-coupon-code-import'))
                  }))
              } else {
                this.formGroup.patchValue({ master_code: null })
                this.masterCodeInput.nativeElement.focus()
              }
            }))
          }
        }, () => this.store.dispatch(new HideLoading('new-coupon-code'))))

    } else {
      this.submitCouponCodeRequest(value)
    }
  }

  showErrors() {
    let errHtml = ''
    for (let error of this.errors) {
      errHtml = `${errHtml}${errHtml.length > 0 ? '<br>' : ''}${this.convertErrorToText(error)}`
    }
    this.snackbarService.showError({html: errHtml})
  }

  adaptErrors(_errors) {
    // Shopify's GraphQL validation returns duplicate errors, we need to remove them
    return _.uniqWith(_errors, _.isEqual)
  }

  submitCouponCodeRequest(value: CouponCode) {
    if (this.isEditMode) {
      this.subscription.add(this.apiRewardsService.putRewards(value, RewardsType.coupon_code)
        .subscribe(res => {
            this.logger.log(<LogLabel>'new-coupon-code', 'response: ', res)
            this.navigateAfterSave()
            // this.updateCouponCodesList();
            this.snackbarService.showSuccess({title: 'Saved', text: 'Coupon Code'})
          },
          (res: HttpErrorResponse) => {
            window.scroll(0, 0)
            this.errors = this.adaptErrors(res.error.errors)
            this.showErrors()
            this.logger.log(<LogLabel>'edit-coupon-code', 'new_errors: ', this.errors)
            this.store.dispatch(new HideLoading('new-coupon-code'))
          }, () => {
            this.store.dispatch(new HideLoading('new-coupon-code'))
          }))
    } else {
      this.subscription.add(this.apiRewardsService.postRewards(value, RewardsType.coupon_code)
        .subscribe(res => {
            this.createdCoupon = res
            this.logger.log(<LogLabel>'new-coupon-code', 'response: ', res)
            this.navigateAfterSave()
            // this.updateCouponCodesList();
            this.snackbarService.showSuccess({title: 'Created', text: 'Coupon Code'})
          },
          (res: HttpErrorResponse) => {
            window.scroll(0, 0)
            this.errors = this.adaptErrors(res.error.errors)
            this.showErrors()
            this.logger.log(<LogLabel>'new-coupon-code', 'new_errors: ', this.errors)
            this.store.dispatch(new HideLoading('new-coupon-code'))
          }, () => {
            this.store.dispatch(new HideLoading('new-coupon-code'))
          }))
    }
  }

  cancel() {
    if (!this.isOverlay) {
      this.navigateAfterSave()
    } else {
      this.closeOverlay.emit(null)
    }
  }

  navigateAfterSave() {
    if (!this.isOverlay) {
      this.redirectService.confirmRoute()
      const redirectUrl = this.redirectService.check()
      if (redirectUrl) {
        this.router.navigate([redirectUrl])
      } else {
        this.router.navigate([`/${RouteHeaderUrl.rewards}/${RouteHeaderUrl.coupons}`])
      }
    } else {
      this.closeOverlay.emit(this.createdCoupon)
    }
  }

  updateCouponCodesList() {
    this.rewardsService.getCouponCodes({
      page: 1,
      limit: 25,
      status: CouponStatus.active,
    });
  }

  convertErrorToText(error) {
    // God bless whoever wants to edit this
    // For some errors validation messages are stored in `code` key, for other `code` is an error type
    if (error.code === 'Value must be between 0.0 and 1.0') {
      return 'Please provide a percentage between 0 and 100'
    }
    if (error.code === 'too_long') {
      return 'Minimum quantity of items can\'t be greater than 99999999'
    }
    // not every Buy X Get Y error has this 'bxgy' string in `field` key
    if (error.field.includes('bxgy')) {
      return error.code
    }
    // some errors doesn't say which field should has a value greater than 0, so we need to alter them
    if (error.field === 'customer_get_percentage') {
      return 'Discounted value should be greater than 0'
    }
    // Backend doesn't know which radio was selected, so it returns both errors for both cases
    if (error.field === 'prerequisite_subtotal_min' || error.field === 'prerequisite_quantity_range_min') {
      const selectedRadio = this.formGroup.get('data._local.minimumRequirements').value
      if (selectedRadio === this.uiCouponMinimumRequirements.price && error.field === 'prerequisite_subtotal_min') {
        return 'Minimum purchase amount in "Customer buys" should be greater than 0'
      } else if (selectedRadio === this.uiCouponMinimumRequirements.quantity && error.field === 'prerequisite_quantity_range_min') {
        return 'Minimum quantity of items in "Customer buys" should be greater than 0'
      }
    }
    if (error.field === 'customer_get_quantity') {
      return 'Minimum quantity of items in "Customer gets" should be greater than 0'
    }
    switch (error.code) {
      case 'no_entitled_collection_ids': {
        switch (this.formGroup.value.data._local.defaultTargetSelection) {
          case UICouponTargetSelection.entitled_products: {
            return 'No products selected'
          }
          case UICouponTargetSelection.entitled_collections: {
            return 'No collections selected'
          }
        }
        break
      }
      case 'end_date_without_start_date': {
        return 'End date without a start date'
      }
      case 'end_date_before_start_date': {
        return 'Please choose an end date that is after the start date'
      }
      default: {
        return _.get(error, 'message')
      }
    }
  }

  onNumberInputClick(event) {
    // when clicking on the number spinners in FF browser focus is not triggered, so need to do it here
    const isFirefoxBrowser = window.navigator.userAgent.toLowerCase().includes('firefox')
    if (isFirefoxBrowser) {
      event.target.focus()
    }
  }
}
