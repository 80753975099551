import { Injectable } from '@angular/core'

@Injectable()
export class GettingStartedEffects {
  constructor(
    // private actions$: Actions,
    // private store: Store<StoreState>,
  ) {
  }
}
