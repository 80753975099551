<div class="LogoutBox">
  <button *ngIf="!disableLogout" [ngClass]="{'Logout' : true, 'LogoutCenter' : position === 'center'}" (click)="logout()">Logout</button>
  <button [ngClass]="{'Logout' : true, '_as-admin' : true ,'LogoutCenter' : position === 'center'}"
          class="Logout _as-admin" *ngIf="loggedInAsAdmin" (click)="logoutAsAdmin()">Logout as this user
  </button>
</div>
<div class="LogoutBox" *ngIf="loggedInAsAdmin && !disableNavigation">
  <button [ngClass]="{'Logout' : true, '_as-admin' : true ,'LogoutCenter' : position === 'center'}"
    class="Logout _override-nav" (click)="overrideUninstalledBehavior()">
    Allow Navigation
  </button>
</div>
