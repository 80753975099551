<div class="ProductsList-Search">
  <input type="text" placeholder="Search" [(ngModel)]="searchQuery" class="form-control mb-0"
    (keyup)="onSearch($event)">
  <button class="pf-button outline gray xs" *ngIf="searchQuery" (click)="searchClear()">CLEAR</button>
</div>
<div *ngIf="showNoProductsError" class="text-danger pt-3 text-center">
  Please create at least 1 product in your shop.
</div>
<div *ngIf="showNoCollections" [ngClass]="{'text-danger': showNoCollectionsError}" class="pt-3 text-center">
  {{ noCollectionsMsg }} <br>
  <span class="_sync-text" (click)="syncCollections()">Click here to sync.</span>
</div>
<div *ngIf="collectionsHasSynced" class="pt-3 text-center d-flex align-items-center">
  <img src="/app_assets/checkmark-icon.png" alt="done" class="check-icon mr-1">
  Collections sync has started. Please refresh the page in a few minutes.
</div>
<div class="ProductsList-Head">
  <label class="checkbox" title="Select all items on this page" *ngIf="data.selectMultiple">
    <input type="checkbox" [(ngModel)]="selectAllChecked" (change)="onSelectAll($event)">
    <span>Select All</span>
  </label>

  <div class="text-muted">{{summaryText}}</div>
</div>

<div class="ProductsList" #productsList>
  <pf-loading-overlay [filter]="{only: ['ProductsSelector']}" [isFixed]="false"></pf-loading-overlay>
  <div *ngFor="let item of items; index as pi" class="ProductsList-Item" (click)="toggleCheckbox($event, true)">
    <div class="ProductsList-ItemRow">

      <label class="checkbox" *ngIf="data.type === uiCouponTargetSelection.entitled_collections">
        <input type="checkbox" [checked]="selectedCollections.has(item[idKey])"
          (change)="onSelectedCollection(item, $event)">
        <span></span>
      </label>

      <label class="checkbox" *ngIf="data.type === uiCouponTargetSelection.entitled_products">
        <input type="checkbox" [checked]="selectedProducts.has(item[idKey])" [indeterminate]="data.selectMultiple && selectedProducts.has(item[idKey]) && item.indeterminate"
          (change)="onSelectedProduct(item, $event)">
        <span></span>
      </label>
      <div class="ProductsList-ItemImage" [class._no-image]="!item.local_preview_image">
        <!-- Multiple images WIP in case we need it -->
        <!-- <ng-container *ngIf="item.images && item.images.length > 1; else oneImg">
          <ng-container *ngFor="let i of item.images">
            <img [attr.src]="i.compact_url" alt="">
          </ng-container>
        </ng-container>
        <ng-template #oneImg>
          <img [attr.src]="item.images && item.images.length > 0 && item.images[0].compact_url || item.image_url" alt="">
        </ng-template> -->
        <img [attr.src]="item.local_preview_image" alt="">
      </div>
      <span class="ProductsList-ItemTitle">
        {{item.name || item.title}}
      </span>

      <span class="ml-auto mr-0">{{item.price | currency:userCurrency:true:'1.2-2'}}</span>
    </div>

    <div *ngIf="item && item.variants && variantsSelectable" class="ProductVariants">
      <div *ngFor="let variant of item.variants" 
           class="ProductVariants-Item" 
           [class._no-checkbox]="!variantsSelectable"
           (click)="toggleCheckbox($event)">
        <label class="checkbox">
          <input type="checkbox" [checked]="selectedVariants.has(variant[idKey])"
            (change)="onSelectedVariant(variant, $event, pi)">
          <span></span>
        </label>
        <div class="ProductVariants-ItemImage" [class._no-image]="!variant?.local_preview_image">
          <img [attr.src]="variant?.local_preview_image" alt="">
        </div>
        <span>{{variant.name}}</span>
        <span class="ml-auto mr-0">{{variant.price | currency:'USD':true:'1.2-2'}}</span>
      </div>
    </div>

  </div>
  <ng-container *ngIf="!items?.length">
    <pf-no-table-data
      [text]="paginator?.pageIndex === 0 ? 'No products found' : 'No more products found'">
    </pf-no-table-data>
  </ng-container>
</div>
<div
  class="text-danger pt-2 pl-2"
  *ngIf="selectedCollections && selectedCollections.size > 100">
  The number of selected collections cannot be more than 100
</div>
<mat-paginator
  #paginator
  [class._last-btn-hidden]="data.type === uiCouponTargetSelection.entitled_products"
  [pageSize]="itemsPerPage"
  [pageSizeOptions]="pageSizeOptions"
  [pageIndex]="pageIndex"
  [length]="totalItems"
  (page)="loadPage($event)">
</mat-paginator>

<ng-template #noProducts>
  <div class="ProductsList p-3">
    <p>You have no products in your store</p>

    <button class="pf-button outline gray xs" (click)="gotoProductsSettings()">
      GO TO STORE PRODUCTS PAGE
    </button>
  </div>
</ng-template>
