import { CommonModule } from '@angular/common'
import {Component, EventEmitter, forwardRef, Input, Output} from '@angular/core'
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms'

@Component({
    selector: 'pf-switch-box',
    templateUrl: './switch-box.component.html',
    styleUrls: ['./switch-box.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SwitchBoxComponent),
            multi: true,
        },
    ],
    standalone: true,
    imports: [
      CommonModule,
    ]
})
export class SwitchBoxComponent implements ControlValueAccessor {
  @Input() labelOff: string
  @Input() labelOn: string
  @Input() labelInside = false
  @Input() readonly: any
  @Input() danger: boolean = false
  @Output() change = new EventEmitter<{value: boolean}>()

  private _onChange: any
  isDisabled = false
  value: boolean

  // private _onTouched: any

  constructor() {
  }

  writeValue(value) {
    this.value = value
  }

  registerOnChange(fn) {
    this._onChange = fn
  }

  registerOnTouched(fn) {
    // this._onTouched = fn
  }

  setDisabledState(bool: boolean) {
    this.isDisabled = bool
  }

  setValue(event, value: boolean) {
    event.stopPropagation()
    if (!this.isDisabled) {
      if (this.readonly === undefined || this.readonly === false) {
        this.value = value
        this._onChange && this._onChange(value)
      }
      this.change.emit({value})
    }
  }
}
