import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { DateRange, MatCalendar } from '@angular/material/datepicker'
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core'
import moment from 'moment'
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter'
import { MatDaterangeService } from '../../services/mat-daterange.service'
import { BehaviorSubject, Subscription } from 'rxjs'
import { MatDaterangeHeaderRightComponent } from '../mat-daterange-header-right/mat-daterange-header-right.component'
import { MatDaterangeHeaderLeftComponent } from '../mat-daterange-header-left/mat-daterange-header-left.component'
import { DateRangeLabel, MatDatepickerRange } from '../../models/mat-daterange.model'
import { MAT_DATERANGE_OVERLAY_REF } from '../../services/mat-daterange-overlay-token'
import { OverlayRef } from '@angular/cdk/overlay'
import _ from 'lodash'

const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
}

@Component({
  selector: 'pf-mat-daterange-picker',
  templateUrl: './mat-daterange-picker.component.html',
  styleUrls: ['./mat-daterange-picker.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})

export class MatDaterangePickerComponent implements OnInit, OnDestroy {
  private subscription = new Subscription()
  public maxDate = moment()
  // Control the Apply button disabled state
  public applyDisabled = false // FIXME: Temporary disabled due to UTC issue
  // Refers to the datepicker in the left calendar
  @ViewChild('startCalendar', {static: true}) startCalendar: MatCalendar<moment.Moment>
  // Refers to the datepicker in the right calendar
  @ViewChild('endCalendar', {static: true}) endCalendar: MatCalendar<moment.Moment>
  // Local date range used to store the selected range until apply is clicked
  public selectedLocalRange$ = new BehaviorSubject<DateRange<moment.Moment> | undefined>(undefined)
  // Custom header components, so we can have custom buttons
  public customHeaderLeft = MatDaterangeHeaderLeftComponent
  public customHeaderRight = MatDaterangeHeaderRightComponent
  // Range buttons are stored here. The `this.matDaterangeService.ranges` is a get method that triggers range modifications
  public ranges = this.matDaterangeService.ranges
  // Include today checkbox visibility
  public includeTodayHidden = false
  // Include today checkbox value
  public includeTodayChecked = this.matDaterangeService.getIncludeToday()
  // The daterange that was selected before the picker was opened
  private initialDateRange: DateRange<moment.Moment> | undefined
  // ngModel for a mobile <select> element
  public selectValue

  constructor(
    // Main service
    public matDaterangeService: MatDaterangeService,
    // Overlay ref
    @Inject(MAT_DATERANGE_OVERLAY_REF) private overlayRef: OverlayRef,
  ) { }

  ngOnInit(): void {
    const range = this.matDaterangeService.selectedRange$.value
    // Set the left calendar to the previous month
    this.startCalendar.startAt = moment().utc().subtract(1, 'month').startOf('month')
    // Set the initial date range, so we can compare it to the selected range in the future
    this.initialDateRange = range
    // Set the value for mobile select
    this.selectValue = this.matDaterangeService.selectedRangeLabel$.value
    // Set local range to the selected range
    if (range?.start && range?.end) {
      this.selectedLocalRange$.next(new DateRange<moment.Moment>(range.start, range.end))
      this.setActiveRangeBtn()
    }
    // Subscribe to the local range changes
    this.subscription.add(
      this.selectedLocalRange$.subscribe((range) => {
        this.setActiveRangeBtn()
      })
    )
    // Apply include today
    this.includeTodayChange()
  }

  toggleApplyBtn() {
    // FIXME: Temporary disabled due to UTC issue
    // const range = this.selectedLocalRange$.value
    // if (this.initialDateRange?.start?.format('DD MMM YYYY') === range?.start?.format('DD MMM YYYY') &&
    //    this.initialDateRange?.end?.format('DD MMM YYYY') === range?.end?.format('DD MMM YYYY')) {
    //   this.applyDisabled = true
    // } else {
    //   this.applyDisabled = false
    // }
  }

  onSelectedChange(m: any) {
    if (!this.selectedLocalRange$.value?.start || this.selectedLocalRange$.value?.end) {
        this.selectedLocalRange$.next(new DateRange<moment.Moment>(m, null))
    } else {
        const start = this.selectedLocalRange$.value.start
        const end = m
        if (end < start) {
            this.selectedLocalRange$.next(new DateRange<moment.Moment>(end, start))
        } else {
            this.selectedLocalRange$.next(new DateRange<moment.Moment>(start, end))
        }
    }
    this.toggleApplyBtn()
    this.setActiveRangeBtn()
  }

  setActiveRangeBtn(button?: MatDatepickerRange) {
    let activeRangeBtn
    let rangeMatched = false // Need to keep track if a match was already found, otherwise there might be cases where 2 active buttons are shown
    if (button) {
      this.ranges.forEach((r: MatDatepickerRange) => {r.active = false})
      activeRangeBtn = button
      button.active = true
      this.matDaterangeService.setLocalLabel(button.title)
    } else {
      this.ranges.forEach((r: MatDatepickerRange, i: number) => {
        const startMatches = r.range[0].format('DD MMM YYYY') === this.selectedLocalRange$.value?.start?.format('DD MMM YYYY')
        const endMatched  = r.range[1].format('DD MMM YYYY') === this.selectedLocalRange$.value?.end?.format('DD MMM YYYY')
        if (this.selectedLocalRange$.value?.start && this.selectedLocalRange$.value?.end) {
          r.active = startMatches && endMatched && !rangeMatched
        }
        if (r.active) {
          rangeMatched = true
          activeRangeBtn = r
          this.matDaterangeService.setLocalLabel(r.title)
        }
      })
      if (!rangeMatched) {
        // Set initial label as dates
        let label
        if (this.selectedLocalRange$.value?.start?.isSame(this.selectedLocalRange$.value?.end, 'year')) {
          if (this.selectedLocalRange$.value?.start?.isSame(this.selectedLocalRange$.value?.end, 'day')) {
            label = this.selectedLocalRange$.value?.start?.format('DD MMM, YYYY')
          } else {
            label = this.selectedLocalRange$.value?.start?.format('DD MMM') + ' - ' + this.selectedLocalRange$.value?.end?.format('DD MMM, YYYY')
          }
        } else if (!this.selectedLocalRange$.value?.start?.isSame(this.selectedLocalRange$.value?.end, 'year')) {
          label = this.selectedLocalRange$.value?.start?.format('DD MMM, YYYY') + ' - ' + this.selectedLocalRange$.value?.end?.format('DD MMM, YYYY')
        }
        this.matDaterangeService.setLocalLabel(label)
      }
    }
    // Toggle include today visibility
    if (activeRangeBtn && activeRangeBtn.rangeWithToday) {
      this.includeTodayHidden = false
    } else {
      this.includeTodayHidden = true
    }
  }

  rangeBtnClick(range: moment.Moment[], button?: MatDatepickerRange) {
    this.selectedLocalRange$.next(new DateRange<moment.Moment>(range[0], range[1]))
    this.setActiveRangeBtn(button)
    this.toggleApplyBtn()
  }

  rangeSelectChange(event: any) {
    const range = this.ranges.find((r: MatDatepickerRange) => r.title === event.target.value).range
    this.rangeBtnClick(range)
  }

  includeTodayChange(event?: any) {
    const includeToday = event ? event.target.checked : this.includeTodayChecked
    this.matDaterangeService.includeTodayLocal$.next(includeToday)
    const currentLabel = this.ranges.find((r: MatDatepickerRange) => r.active)?.title as DateRangeLabel
    this.ranges = this.matDaterangeService.ranges
    const updatedRange = this.matDaterangeService.getDateRangeByLabel(currentLabel, true)
    if (updatedRange) {
      this.rangeBtnClick([updatedRange.start, updatedRange.end])
    }
  }

  applyRange() {
    const start = this.selectedLocalRange$.value?.start
    const end = this.selectedLocalRange$.value?.end
    const includeToday = this.matDaterangeService.includeTodayLocal$.value
    if (start && end) {
      this.matDaterangeService.selectedRange$.next(this.selectedLocalRange$.value)
    } else if (start && !end) {
      this.selectedLocalRange$.next(new DateRange<moment.Moment>(start, start))
      this.matDaterangeService.selectedRange$.next(this.selectedLocalRange$.value)
    }
    // this.setLabel()
    const label = this.matDaterangeService.selectedRangeLabelLocal$.value
    this.matDaterangeService.selectedRangeLabel$.next(label)
    this.matDaterangeService.setIncludeToday(includeToday)
    this.overlayRef.detach()
  }

  cancelClick() {
    this.overlayRef.detach()
  }

  ngOnDestroy(): void {
    this.matDaterangeService.monthChange$.next({
      direction: null,
      origin: null
    })
    this.subscription.unsubscribe()
  }

}
