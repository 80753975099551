import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Store } from '@ngrx/store'
import _ from 'lodash'
import { forkJoin, Subscription } from 'rxjs'
import { finalize } from 'rxjs/operators'
import { ApiJotformService } from '../../../core/services/api/api-jotform.service'
import { GettingStartedService } from '../../../core/services/getting-started.service'
import { UserService } from '../../../core/services/user.service'
import { keyValuePairKey } from '../../../shared/models/key-value-pair.model'
import { PlatformOnboardingCall, PlatformOnboardingStage, PlatformOnboardingStatus } from '../../../shared/models/platform-onboarding.model'
import { CustomSnackbarService } from '../../../shared/modules/custom-snackbar/custom-snackbar.service'
import { SegmentAnalyticsService } from '../../../shared/services/segment-analytics.service'
import { HideLoading, ShowLoading } from '../../../store/loading/loading.actions'
import { StoreState } from '../../../store/store.state'
import { UserInfo } from '../../../store/user/user.state'
import { ShopifySubscribersApiService } from '../../subscribers/services/shopify-subscribers-api.service'

declare const Calendly

@Component({
  selector: 'pf-vip-onboarding',
  templateUrl: './vip-onboarding.component.html',
  styleUrls: ['./vip-onboarding.component.scss'],
})
export class VipOnboardingComponent implements OnInit, OnDestroy {
  @ViewChild('container', { static: true }) container: ElementRef
  subscription$: Subscription = new Subscription()
  userType = 'new'
  hasNavbar = false
  userInfo: UserInfo
  total: any = {}
  alreadyScheduled = false
  scheduleSubmitted = false
  iframeCount = 0
  pollCount = 0
  calendlyDefaultUrl = 'https://calendly.com/one-csm'
  inviteeId

  constructor(
    private store: Store<StoreState>,
    private route: ActivatedRoute,
    private userService: UserService,
    private shopifySubscribersApiService: ShopifySubscribersApiService,
    private gettingStartedService: GettingStartedService,
    private apiJotformService: ApiJotformService,
    private segmentAnalitycsService: SegmentAnalyticsService,
    private snackbarService: CustomSnackbarService,
  ) {
    this.userType = _.get(this.route, 'snapshot.data.userType') || 'new'
    this.hasNavbar = !_.get(this.route, 'snapshot.data.hide_navbar')
  }

  ngOnInit(): void {
    this.store.dispatch(new ShowLoading('FetchOnboardingData'))
    this.subscription$.add(forkJoin([this.userService.readUser(), this.shopifySubscribersApiService.getContactsStatisticsField('active_known_customers_total')])
    .pipe(finalize(() => {
      this.store.dispatch(new HideLoading('FetchOnboardingData'))
    })).subscribe(([user, akcs]) => {
      this.userInfo = user
      this.total = akcs
      this.inviteeId = this.gettingStartedService.getKeyValuePairValue(keyValuePairKey.WhaleDemoInviteeId)
      if ([PlatformOnboardingCall.Scheduled, PlatformOnboardingCall.Rescheduled].includes(this.userInfo.platform_onboarding_call)) {
        this.alreadyScheduled = true
      } else {
        this.loadCalendly()
      }
      this.updatePlatformOnboardingStatus()
    }, err => {
      if ((err?.status === 429 || err?.status === 400) && err?.error?.message) {
        this.snackbarService.showError({ title: err.error?.title, text: err.error?.message })
      }
    }))
    this.segmentAnalitycsService.track('Viewed Book Demo Page (Whale VIP Onboarding)')
  }w

  reschedule() {
    this.segmentAnalitycsService.track('Clicked Reschedule Button (Whale VIP Onboarding)')
    this.alreadyScheduled = false
    if (this.inviteeId) {
      this.loadCalendly(`https://calendly.com/reschedulings/${this.inviteeId}`)
    } else {
      this.loadCalendly()
    }
  }

  cancel(e) {
    e.preventDefault()
    this.segmentAnalitycsService.track('Clicked Cancel Button (Whale VIP Onboarding)')
    this.alreadyScheduled = false
    if (this.inviteeId) {
      this.loadCalendly(`https://calendly.com/cancellations/${this.inviteeId}`)
    } else {
      this.loadCalendly()
    }
  }

  details(e) {
    e.preventDefault()
    this.segmentAnalitycsService.track('Clicked Cancel Button (Whale VIP Onboarding)')
    this.alreadyScheduled = false
    if (this.inviteeId) {
      this.loadCalendly(`${this.calendlyDefaultUrl}/vip-english/invitees/${this.inviteeId}`)
    } else {
      this.loadCalendly()
    }
  }

  loadCalendly(url = this.calendlyDefaultUrl) {
    if (Calendly?.initInlineWidget) {
      this.store.dispatch(new ShowLoading('LoadCalendly'))
      const name = this.userInfo?.shop_user?.shopify_shop_user?.first_name ? `${this.userInfo?.shop_user?.shopify_shop_user?.first_name} ${this.userInfo?.shop_user?.shopify_shop_user?.last_name || ''}` : this.userInfo?.profile?.first_name ? `${this.userInfo?.profile?.first_name} ${this.userInfo?.profile?.last_name || ''}` : ''
      const email = this.userInfo?.shop_user?.shopify_shop_user?.email || this.userInfo?.email || ''
      const phone = this.userInfo?.shop_user?.shopify_shop_user?.phone  || ''
      const storeUrl = this.userInfo?.shop?.store_url  || ''

      Calendly.initInlineWidget({
        url: `${url}?hide_gdpr_banner=1`,
        parentElement: this.container.nativeElement,
        prefill: {
          name: name,
          email: email,
          customAnswers: {
            a1: phone,
            a2: storeUrl,
          }
        },
        utm: {
          utmCampaign: 'vip_onboarding'
        }
        })
        this.checkIfLoaded()
    }
    window.addEventListener('message', this.receiveIframeMessage)
  }

  checkIfLoaded() {
    // if it's been loading for 10+ secs just stop
    if (this.pollCount > 50) {
      this.store.dispatch(new HideLoading('LoadCalendly'))
      this.iframeCount = 0
      this.pollCount = 0
      return
    }
    this.pollCount++
    if (!!document.querySelector('.calendly-embed iframe')) {
      // this is needed because the actual ui of calendly is visible
      // with slight delay after iframe is spotted by the query
      if (this.iframeCount > 2) {
        this.store.dispatch(new HideLoading('LoadCalendly'))
        return
      }
      this.iframeCount++
    }
    setTimeout(() => this.checkIfLoaded(), 200)
  }

  updatePlatformOnboardingStatus(scheduled = false) {
    if (this.userInfo?.platform_onboarding_status !== PlatformOnboardingStatus.Completed) {
      const payload: any = {
        status: PlatformOnboardingStatus.Started,
        stage: PlatformOnboardingStage.OnboardingCall,
        // locked: true, // no more locking
        locked_reason: this.userInfo?.platform_onboarding_locked_reason || null,
        call: this.userInfo?.platform_onboarding_call || null,
        unlocked_flow: this.userInfo?.platform_onboarding_unlocked_flow || null
      }
      const jotformPayload: any = {
        field_1: PlatformOnboardingStatus.Started,
        field_2: PlatformOnboardingStage.OnboardingCall,
        field_3: payload.call || '',
        field_4: payload.locked ? 'True' : 'False',
        field_5: payload.locked_reason || '',
        field_6: payload.unlocked_flow || ''
      }
      if (scheduled) {
        if (payload.call) {
          payload.call = PlatformOnboardingCall.Rescheduled
          jotformPayload.field_3 = PlatformOnboardingCall.Rescheduled
        } else {
          payload.call = PlatformOnboardingCall.Scheduled
          jotformPayload.field_3 = PlatformOnboardingCall.Scheduled
        }
      }
      if (!payload.locked_reason) {
        let lockedReason = 'above_6500_active_profiles'
        if (this.total?.active_known_customers_total > 100000000) {
          lockedReason = 'above_100000_active_profiles'
        } else if (this.total?.active_known_customers_total > 50000){
          lockedReason = 'above_50000_active_profiles'
        } else if (this.total?.active_known_customers_total > 25000){
          lockedReason = 'above_25000_active_profiles'
        } else if (this.total?.active_known_customers_total > 10000){
          lockedReason = 'above_10000_active_profiles'
        }
        payload.locked_reason = lockedReason
        jotformPayload.field_5 = lockedReason
      }
      this.subscription$.add(this.userService.updatePlatformOnboarding(payload).subscribe())
      this.updateJotformIfNeeded(jotformPayload)
      this.segmentAnalitycsService.identify(this.userInfo?.id, {
        onboarding_status: PlatformOnboardingStatus.Started,
        onboarding_stage: PlatformOnboardingStage.OnboardingCall
      })
    }
  }

  updateJotformIfNeeded(payload) {
    // if this is phone verification on signup flow send data to jotform
    const submissionId = this.gettingStartedService.getKeyValuePairValue(keyValuePairKey.PrePlansSurveyNewSubmissionId)
    const prefilled6Id = this.gettingStartedService.getKeyValuePairValue(keyValuePairKey.PrePlansSurveyNewPrefilled6)
    if (submissionId && prefilled6Id) {
      const jotFormPayload: any = {
        [prefilled6Id]: payload,
        new: '1',
      }
      // wrap in try catch to make sure this doesn't block anything
      try {
        this.subscription$.add(this.apiJotformService.editSubmission(submissionId, jotFormPayload).subscribe())
      } catch (e) {
      }
    }
  }

  private receiveIframeMessage = (message: any) => {
    if (this.isCalendlyEvent(message)) {
      if (message.data.event.indexOf('event_scheduled') !== -1) {
        this.scheduleSubmitted = true
        this.updatePlatformOnboardingStatus(true)
        let text = 'Scheduled Event (Whale VIP Onboarding)'
        if ([PlatformOnboardingCall.Scheduled, PlatformOnboardingCall.Rescheduled].includes(this.userInfo.platform_onboarding_call)) {
          text = 'Rescheduled Event (Whale VIP Onboarding)'
        }

        const pairs: any = {}
        if (message.data.payload?.event?.uri) {
          pairs[keyValuePairKey.WhaleDemoEventUri] = message.data.payload?.event?.uri
          const tmp = message.data.payload?.event?.uri?.split('/')
          pairs[keyValuePairKey.WhaleDemoEventId] = tmp?.[tmp.length - 1]
        }
        if (message.data.payload?.invitee?.uri) {
          pairs[keyValuePairKey.WhaleDemoInviteeUri] = message.data.payload?.invitee?.uri
          const tmp = message.data.payload?.invitee?.uri?.split('/')
          this.inviteeId = tmp?.[tmp.length - 1]
          pairs[keyValuePairKey.WhaleDemoInviteeId] = this.inviteeId
        }
        if (!_.isEmpty(pairs)) {
          this.subscription$.add(this.gettingStartedService.updateKeyValuePairs(pairs).subscribe())
          this.segmentAnalitycsService.track(text, pairs)
        } else {
          this.segmentAnalitycsService.track(text)
        }
      } else if (this.inviteeId && message.data.event.indexOf('date_and_time_selected') !== -1) {
        // we don't get final event on reschedule, this is the last message that iframe sends
        // so if user selects date and time let's assume they have rescheduled
        // and nullify invitee id so as not to load empty embed if they click it again
        this.segmentAnalitycsService.track('Rescheduled Event (Whale VIP Onboarding)')
        this.updatePlatformOnboardingStatus(true)
        this.subscription$.add(this.gettingStartedService.updateKeyValuePair(keyValuePairKey.WhaleDemoInviteeId, null).subscribe())
      }
    }
  }

  isCalendlyEvent(e) {
    return e.origin === "https://calendly.com" && e.data.event && e.data.event.indexOf('calendly.') === 0
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe()
  }
}
