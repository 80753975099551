<div class="PlansPage">
  <ng-container *ngIf="plans && plans.length && billing">
    <h1 class="Title" *ngIf="pageTitle">{{ pageTitle }}</h1>
    <div class="ErrorBox alert alert-danger" *ngIf="errors.length">
      <div *ngFor="let error of errors">{{error}}</div>
    </div>

    <div *ngIf="!!userPromo" class="my-2">
      <pf-promo
        [userPromo]="userPromo"
        [userInfo]="currentUserInfo"
        (resultEmitter)="onCouponApplied($event)">
      </pf-promo>

      <!-- <pf-message-box *ngIf="!!userPromo"
                      messageType="info"
                      [customStyle]="{'border-radius': '5px', 'margin-bottom': '0', 'margin-top': '40px'}"
                      messageText="This is a sample text for listing requirements of scholarships"
                      buttonText="Contact Support" (buttonClick)="openCrisp()"></pf-message-box> -->
    </div>

    <div class="Plans-Labels">
      <h1 class="Label">
        ONE
        <span class="_small" [class._xs]="!isUsingShopifyBilling()">(Live Chat Support)</span>
      </h1>
      <h1 class="Label _pro">
        ONE+ <span class="_small" [class._xs]="!isUsingShopifyBilling()">(Dedicated Support Manager)</span>
      </h1>
      <ng-container *ngIf="!isUsingShopifyBilling()">
        <div class="Switcher">
          <!-- <span class="Switcher-Descr">Billing period</span> -->
          <div class="Switcher-Box">
            <button class="btn btn-sm" [class._active]="!isIntervalAnnual"
                    (click)="changeInterval(false)">
              Monthly
            </button>
            <button class="btn btn-sm" [class._active]="isIntervalAnnual"
                    (click)="changeInterval(true)">
              Yearly <small>(2 months off + 2x limits)</small>
            </button>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="Plans">
      <div class="Plan _free" [class._active]="isCurrentPlan(freePlan)">
        <pf-plans-item-alt-1
          [data]="freePlan"
          [user]="currentUserInfo"
          [smsEligible]="userSMSEligible"
          [shopify]="true"
          [hideInactive]="true"
          [shopify]="true"
          [isCurrentPlan]="isCurrentPlan(freePlan)"
          (onSelect)="onSelectPlanClick(freePlan)">
        </pf-plans-item-alt-1>
      </div>
      <ng-container *ngFor="let plan of proPlans; index as i">
        <div class="Plan" [class._active]="isCurrentPlan(plan)">
          <pf-plans-item-alt-1
            [data]="plan"
            [user]="currentUserInfo"
            [smsEligible]="userSMSEligible"
            [shopify]="true"
            [mini]="true"
            [isCurrentPlan]="isCurrentPlan(plan)"
            [buttonText]="plan.plan_cta_label"
            (onSelect)="onSelectPlanClick(plan, $event)">
          </pf-plans-item-alt-1>
        </div>
      </ng-container>
    </div>

    <div class="Testimonial">
      <div class="Testimonial__Box">
        <div class="Testimonial__Photo"><img src="/app_assets/images/peter.jpeg" alt=""></div>
        <div class="Testimonial__Body">
          <div class="Testimonial__Name">Peter Friis</div>
          <div class="Testimonial__Position">CEO,
            <a style="text-decoration: none; color: #29334e;" href="https://prvolt.com/">PRVolt.com</a></div>
          <div class="Testimonial__Text">"{{brandingName}} makes sure that I get the highest ROI on my marketing campaigns
            by encouraging as many customers to purchase. I wouldn’t market without it."
          </div>
        </div>
      </div>
    </div>

    <div class="QuestionList">
      <div class="QuestionItem">
        <p class="QuestionText">
          How does the free trial work?
        </p>
        <p class="QuestionAnswer">
          If you cancel a paying plan within the free trial period you won’t be charged a thing.
        </p>
      </div>
      <div class="QuestionItem" *ngIf="shopType!=='ShopifyShop'">
        <p class="QuestionText">
          Do I get a free trial if I upgrade?
        </p>
        <p class="QuestionAnswer">
          If you upgrade from a free account to a paid account you are not eligible for the free trial. The free trial
          on paying plans only applies if you sign up for a paying plan now. You can always downgrade later if you
          choose.
        </p>
      </div>
      <div class="QuestionItem">
        <p class="QuestionText">
          Do I have to sign a long term contract?
        </p>
        <p class="QuestionAnswer">
          No. {{brandingName}} is a pay-as-you-go service. There are no long term contracts or commitments on your part. You
          simply pay
          month-to-month.
        </p>
      </div>
      <div class="QuestionItem">
        <p class="QuestionText">
          Can I change plans at any time?
        </p>
        <p class="QuestionAnswer">
          Yep. Simply click on the “Account” tab on your dashboard and you’ll see your options.
        </p>
      </div>
      <div class="QuestionItem">
        <p class="QuestionText">
          What types of payment do you accept?
        </p>
        <p *ngIf="shopType!=='ShopifyShop'" class="QuestionAnswer">
          We use Stripe to process your payments and currently accept Visa, Mastercard, and American Express. We do not
          accept PayPal. At this
          time we only accept payments online so
          we will not be able to accept a P.O., invoice you, nor take an order over the phone.
        </p>
        <p *ngIf="shopType==='ShopifyShop'" class="QuestionAnswer">
          We use Shopify to process your payments. All payments go through the credit card you have on file with
          Shopify.
        </p>
      </div>
      <div class="QuestionItem">
        <p class="QuestionText">
          Are there per-site fees?
        </p>
        <p class="QuestionAnswer">
          No. The prices you see above are all inclusive. For example, the High Traffic Site plan is $79/month for
          50,000 unique visitors per
          month. That means you pay
          $79/month total no matter how many sites you have as long as it’s 50,000 unique visitors per month or less. If
          you don’t see a plan
          large enough for your site
          here, we have other plans available. <a href="mailto:enterprise@prooffactor.com">Please contact us.</a>
        </p>
      </div>
    </div>
  </ng-container>

  <pf-loading-overlay [filter]="{ignore: ['addCard', 'savePaymentToken', 'stripeLoading']}"></pf-loading-overlay>
</div>
