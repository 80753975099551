export enum SMSOptInLevel {
  PendingConfirmedOptIn = 'pending_confirmed_opt_in',
  ConfirmedOptIn = 'confirmed_opt_in',
  SingleOptIn = 'single_opt_in',
  SingleOptInOrConfirmedOptIn = 'single_opt_in_or_confirmed_opt_in',
  SingleOptInOrPendingConfirmedOptIn = 'single_opt_in_or_pending_confirmed_opt_in',
  AnyOptIn = 'any_opt_in',
}

export enum SMSListName {
  AllSubscribers = 'All Subscribers | on Shopify & ONE',
  OnlySubscribers = 'Only Subscribers | on ONE'
}

export enum SMSPhoneNumberVerificationStatus {
  Denied = 'denied',
  Pending = 'pending',
  Verified = 'verified'
}
