import { Injectable } from '@angular/core'
import { RouteHeaderUrl } from '../../shared/components/one-header/header-navigation.model'

export enum CampaignRouteStep {
  APPS = 'apps',
  LANGUAGE = 'language',
  DESIGN = 'design',
  BEHAVIOR = 'behavior',
  DISPLAY = 'display',
  CONVERSIONS = 'conversions',
  REVIEW_AND_LAUNCH = 'review-and-launch',

  CAPTURE = 'capture',
  CAPTURE_AUTO = 'auto',
  CAPTURE_ZAPIER = 'zapier',
  CAPTURE_WEBHOOK = 'webhook',
  CAPTURE_CLICKFUNNELS = 'clickfunnels',
  CAPTURE_KARTRA = 'kartra',
}

@Injectable()
export class CampaignUrlService {

  campaignList(): string {
    return `/${RouteHeaderUrl.popups}/${RouteHeaderUrl.campaigns}/`
  }

  campaign(id: string): string {
    return `/${RouteHeaderUrl.popups}/${RouteHeaderUrl.campaigns}/${id}`
  }

  campaignReview(id: string): string {
    return `/${RouteHeaderUrl.popups}/${RouteHeaderUrl.campaigns}/${id}/review-and-launch`
  }

  campaignStep(id: string, step: CampaignRouteStep) {
    return `/${RouteHeaderUrl.popups}/${RouteHeaderUrl.campaigns}/${id}/${step}`
  }

  campaignConfigurePluginStep(id: string, plugin: string, step: string) {
    return `/${RouteHeaderUrl.popups}/${RouteHeaderUrl.campaigns}/${id}/configure/${plugin}/${step}`
  }
}
