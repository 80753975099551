import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core'
import { AutomationTypes, HolidayTypes } from '../../models/promotion.models'
import { SegmentAnalyticsService } from '../../../../services/segment-analytics.service'
import { CommonModule } from '@angular/common'
import { OneIconComponent } from '../../../one-icon/one-icon.component'
import { MatTooltipModule } from '@angular/material/tooltip'

@Component({
    selector: 'pf-holiday-cards-month',
    templateUrl: './holiday-cards-month.component.html',
    styleUrls: ['./holiday-cards-month.component.scss'],
    standalone: true,
    imports: [
      CommonModule,
      OneIconComponent,
      MatTooltipModule
    ]
})
export class HolidayCardsMonthComponent implements OnInit {
  @HostListener('window:resize', ['$event']) onResize() {
    clearTimeout(this.debounceTimeout)
    this.debounceTimeout = setTimeout(
      () => {
        this.updateShownCards()
      },100)
  }
  @ViewChild('cardRef', {static: true}) cardRef: ElementRef

  @Output() onShowModal = new EventEmitter<HolidayTypes | AutomationTypes >()
  @Input() cards = []
  @Input() month = ''
  @Input() currentMonth = ''
  @Input() nextMonth = ''
  maxHolidayCardsShown = 4
  holidayCardsCounter = 0
  cardsToShow = []
  cardWidth = 400
  holidayCardsToPick = 1
  debounceTimeout: any

  constructor(
    private segmentAnalyticsService: SegmentAnalyticsService,
  ) { }

  ngOnInit(): void {
    this.setShownCards()
    this.updateShownCards()

  }

  updateShownCards() {
    const cardGap = 15
    this.maxHolidayCardsShown = Math.floor(this.cardRef.nativeElement.offsetWidth / (this.cardWidth + cardGap)) || 1
    this.setShownCards()
  }

  sliceHolidayCards() {
    return this.cards.slice(this.holidayCardsCounter * this.maxHolidayCardsShown, (this.holidayCardsCounter + 1) * this.maxHolidayCardsShown)
  }

  setShownCards(){
    this.cardsToShow = this.sliceHolidayCards()
  }

  previousHolidayCards() {
    if (this.holidayCardsCounter != 0) {
      this.holidayCardsCounter--
      this.setShownCards()
    }
  }

  nextHolidayCards() {
    if (((this.holidayCardsCounter + 1) * this.maxHolidayCardsShown) < this.cards.length) {
      this.holidayCardsCounter++
      this.setShownCards()
    }
  }

  showHolidayPromotionModal(id: HolidayTypes | AutomationTypes ) {
    this.segmentAnalyticsService.track('Clicked Holiday Card', {
      metadata: {
        card: this.cards?.find(card => card.id === id)
      }
    })
    this.onShowModal.emit(id)
  }

}
