import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router'
import { select, Store } from '@ngrx/store'
import { filter, finalize, first, map, tap } from 'rxjs/operators'
import { forkJoin, Observable } from 'rxjs'
import { LoadingLabel } from '../../shared/models/loading/loading-label'
import {
  getUserShopType,
  getUserChoseInitialPlanCompleted,
} from '../../store/user/user.selectors'
import { HideLoading, ShowLoading } from '../../store/loading/loading.actions'
import { StoreState } from '../../store/store.state'
import { UserShopType } from '../../shared/models/user/user-shop-type.model'
import { Logger } from '../services/logger.service'
import { GettingStartedStatus } from '../../shared/models/getting-started/getting-started-status.model'
import { LogLabel } from '../../shared/models/logger/log-label.model'
import { getGettingStartedStatus } from '../../store/getting-started/getting-started.selectors'
import _ from 'lodash'

@Injectable()
export class OnboardingAppsUndoneGuard  {
  constructor(
    private router: Router,
    private store: Store<StoreState>,
    private logger: Logger,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.check(route, state)
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.check(route, state)
  }

  check(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    this.store.dispatch(new ShowLoading(LoadingLabel.OnboardingAppsUndone))
    return forkJoin([
      this.isShopifyShop$,
      this.gettingStartedStatus$,
      this.getUserChoseInitialPlanCompleted$,
    ]).pipe(
      map(([isShopifyShop, status, planSelected]) => {
        this.logger.log(<LogLabel>'onboarding-apps-undone-guard', 'isShopifyShop: ', isShopifyShop, 'status: ', status, 'planSelected: ', planSelected)
        if (!isShopifyShop) {
          this.router.navigateByUrl('/')
          return false
        }

        // if (_.get(status, 'apps.completed', false)) {
        //   this.router.navigateByUrl(`/${RouteHeaderUrl.apps}`)
        //   return false
        // }

        return true
      }),
      finalize(() => this.store.dispatch(new HideLoading(LoadingLabel.OnboardingAppsUndone))),
    )
  }

  get gettingStartedStatus$(): Observable<GettingStartedStatus> {
    return this.store.pipe(
      select(getGettingStartedStatus),
      filter(next => next !== undefined),
      filter(status => !!status),
      first(),
      tap((status) => this.logger.log(<LogLabel>'onboarding-apps-undone-guard', 'gettingStartedStatus$: ', status)),
    )
  }

  get isShopifyShop$(): Observable<boolean> {
    return this.store.pipe(
      select(getUserShopType),
      filter(next => next !== undefined),
      first(),
      map(next => (next === UserShopType.ShopifyShop)),
      tap((type) => this.logger.log(<LogLabel>'onboarding-apps-undone-guard', 'isShopifyShop: ', type)),
    )
  }

  get getUserChoseInitialPlanCompleted$(): Observable<boolean> {
    return this.store.pipe(
      select(getUserChoseInitialPlanCompleted),
      filter(completed => completed !== undefined),
      first(),
      tap((completed) => this.logger.log(<LogLabel>'onboarding-apps-undone-guard', 'getUserChoseInitialPlanCompleted: ', completed)),
    )
  }
}
