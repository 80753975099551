<div class="AcDropdown-Section" *ngFor="let section of sections" (click)="onLinkClick(section.url)" [class._is-first]="section.isFirst">
  <div class="AcDropdown-Section-Title">
    <div>
      <span *ngIf="section?.badge?.text"
        class="pf-badge white-warning xs mb-1"
      >{{ section.badge.text }}</span>
      {{section.title}}
    </div>
    <ng-container *ngIf="section.isFirst">
      <span>Active</span>
      <span>Complete</span>
    </ng-container>
  </div>
  <ul class="AcDropdown-Items">
    <ng-container *ngFor="let item of section.items">
      <li class="AcDropdown-Item" *ngIf="!item.hidden" [ngClass]="item.class">
        <div class="AcDropdown-Item-Title">
          {{item.title}}
        </div>
        <div class="AcDropdown-Item-Stats">
          <span>
            <ng-container *ngIf="item.active > -1; else noData">
              <one-icon [style.color]="item.active > 0 || section.isTotal ? '#48bf0e' : '#c6c6c6'" name="ellipse"></one-icon>
              {{item.active}}
            </ng-container>
          </span>
          <span *ngIf="item.showCompleted">
            <one-icon [style.color]="item.completed > 0 || section.isTotal ? '#48bf0e' : '#c6c6c6'" name="checkmark-circle-outline"></one-icon>
            {{item.completed}}
          </span>
        </div>
      </li>
    </ng-container>
  </ul>
  <div class="AcDropdown-ActionButton" *ngIf="section.upgradeButton">
    <button class="pf-button yellow outline xs" (click)="goToPlansPage($event)">
      Upgrade Plan
    </button>
  </div>
</div>

<div class="AcDropdown-Revenue-Line"></div>

<div class="AcDropdown-Revenue" [routerLink]="[RouteHeaderUrl.dashboard]">
  <div class="AcDropdown-Revenue-Title" oneFittext [maxFontSize]="16" [minFontSize]="10">
    ONE made your store
    <span>{{totalRevenue | currency : userCurrency : 'symbol' : '1.2-2'}}</span>
  </div>
  <!-- <div class="AcDropdown-Revenue-Percentage">
    <strong>{{((totalRevenue / storeTotalRevenue) * 100 | number : '1.0-1') || 0}}</strong>% of total revenue
  </div> -->
  <span class="AcDropdown-Revenue-Daterange">({{defaultDateRange}})</span>
</div>

<ng-template #noData>
  —
</ng-template>
