<h1 mat-dialog-title class="_local-main-title">
  <img src="/app_assets/one-logo-rounded.svg" alt="">
  Access Denied
  <button class="mat-dialog-close" (click)="dialogRef.close(null)">
    <span>&times;</span>
  </button>
</h1>

<div class="PU-Body mb-2 mt-1">
  <p class="PU-Text">
    Sorry, you do not have permission to access this store or perform this action.
  </p>
</div>

<div class="PU-Footer mb-0">
  <button (click)="contactSupport($event)" class="pf-button fw black filled my-2">Chat with Us</button>
  <!-- <button (click)="openCalendlyWidget()" class="pf-button fw black outline">Book a Call</button> -->
</div>
