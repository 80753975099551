import { Injectable } from '@angular/core'
import { environment } from '../../../environments/environment'
import { CampaignUrlService } from './campaign-url.service'

@Injectable()
export class UrlService {

  constructor(
    private campaignUrlService: CampaignUrlService,
  ) {
  }

  /** @deprecated */
  campaignList(): string {
    return this.campaignUrlService.campaignList()
  }

  /** @deprecated */
  campaign(id: string): string {
    return this.campaignUrlService.campaign(id)
  }

  getShopifyShopLink(shop: string): string {
    let shopifyStoreName = shop.toLowerCase().replace('.myshopify.com', '')
    shopifyStoreName = shopifyStoreName.replace('https://', '')
    shopifyStoreName = shopifyStoreName.replace('http://', '')
    return `${environment.apiBaseURL}/auth/shopify?shop=${shopifyStoreName}.myshopify.com`
  }
}
