import { Injectable } from '@angular/core'
import _ from 'lodash'
import { PubSub } from '../../../../core/services/pub-sub'
import { RoutePluginName, RoutePluginStepName } from '../models/plugin-configure-navigation.model'

const navigation = {
  [RoutePluginName.LiveVisitorCount]: [
    {
      url: RoutePluginStepName.Appearance,
      label: 'Design',
      required: false,
      new: false,
    },
    {
      url: RoutePluginStepName.Text,
      label: 'Text',
      required: false,
      new: false,
    },
    {
      url: RoutePluginStepName.BEHAVIOR,
      label: 'Triggers',
      required: false,
      new: false,
    },
    {
      url: RoutePluginStepName.Image,
      label: 'Image',
      required: false,
      new: false,
    },
    {
      url: RoutePluginStepName.Objectives,
      label: 'Objectives',
      required: false,
      new: false,
    },
    {
      url: RoutePluginStepName.Advanced,
      label: 'Advanced',
      required: false,
      new: false,
    },
  ],
  [RoutePluginName.AggregateVisitorCount]: [
    {
      url: RoutePluginStepName.Appearance,
      label: 'Design',
      required: false,
      new: false,
    },
    {
      url: RoutePluginStepName.Text,
      label: 'Text',
      required: false,
      new: false,
    },
    {
      url: RoutePluginStepName.BEHAVIOR,
      label: 'Triggers',
      required: false,
      new: false,
    },
    {
      url: RoutePluginStepName.Image,
      label: 'Image',
      required: false,
      new: false,
    },
    {
      url: RoutePluginStepName.Objectives,
      label: 'Objectives',
      required: false,
      new: false,
    },
    {
      url: RoutePluginStepName.Advanced,
      label: 'Advanced',
      required: false,
      new: false,
    },
  ],
  [RoutePluginName.AggregateActivity]: [
    {
      url: RoutePluginStepName.Collect,
      label: 'Collect',
      required: false,
      new: false,
    },
    {
      url: RoutePluginStepName.Conversions,
      label: 'Conversions',
      required: false,
      new: false,
    },
    {
      url: RoutePluginStepName.Products,
      label: 'Products',
      required: false,
      new: false,
    },
    {
      url: RoutePluginStepName.Appearance,
      label: 'Design',
      required: false,
      new: false,
    },
    {
      url: RoutePluginStepName.Text,
      label: 'Text',
      required: false,
      new: false,
    },
    {
      url: RoutePluginStepName.BEHAVIOR,
      label: 'Triggers',
      required: false,
      new: false,
    },
    {
      url: RoutePluginStepName.Image,
      label: 'Image',
      required: false,
      new: false,
    },
    {
      url: RoutePluginStepName.Objectives,
      label: 'Objectives',
      required: false,
      new: false,
    },
    {
      url: RoutePluginStepName.Advanced,
      label: 'Advanced',
      required: false,
      new: false,
    },
  ],
  [RoutePluginName.RecentActivity]: [
    {
      url: RoutePluginStepName.Collect,
      label: 'Collect',
      required: false,
      new: false,
    },
    {
      url: RoutePluginStepName.Conversions,
      label: 'Conversions',
      required: false,
      new: false,
    },
    {
      url: RoutePluginStepName.Orders,
      label: 'Orders',
      required: false,
      new: false,
    },
    {
      url: RoutePluginStepName.Products,
      label: 'Products',
      required: false,
      new: false,
    },
    {
      url: RoutePluginStepName.Appearance,
      label: 'Design',
      required: false,
      new: false,
    },
    {
      url: RoutePluginStepName.Text,
      label: 'Text',
      required: false,
      new: false,
    },
    {
      url: RoutePluginStepName.BEHAVIOR,
      label: 'Triggers',
      required: false,
      new: false,
    },
    {
      url: RoutePluginStepName.Image,
      label: 'Image',
      required: false,
      new: false,
    },
    {
      url: RoutePluginStepName.Objectives,
      label: 'Objectives',
      required: false,
      new: false,
    },
    {
      url: RoutePluginStepName.Advanced,
      label: 'Advanced',
      required: false,
      new: false,
    },
  ],
  [RoutePluginName.FortuneWheel]: [
    {
      url: RoutePluginStepName.Theme,
      label: 'Theme',
      required: false,
      new: false,
      quickStep: true,
    },
    {
      url: RoutePluginStepName.Fields,
      label: 'Form',
      required: true,
      new: false,
      quickStep: true,
    },
    {
      url: RoutePluginStepName.Slices,
      label: 'Rewards',
      required: true,
      new: false,
      quickStep: true,
    },
    {
      url: RoutePluginStepName.Design,
      label: 'Design',
      required: false,
      new: false,
    },
    {
      url: RoutePluginStepName.Triggers,
      label: 'Triggers',
      required: true,
      new: false,
    },
    {
      url: RoutePluginStepName.MarketingEmails,
      label: 'Emails',
      required: true,
      new: false,
    },
    {
      url: RoutePluginStepName.MarketingSMS,
      label: 'SMS',
      required: true,
      new: false,
    },
  ],
  [RoutePluginName.CouponBox]: [
    {
      url: RoutePluginStepName.Theme,
      label: 'Theme',
      required: false,
      new: false,
      quickStep: true,
    },
    {
      url: RoutePluginStepName.Fields,
      label: 'Form',
      required: true,
      new: false,
      quickStep: true,
    },
    {
      url: RoutePluginStepName.Design,
      label: 'Design',
      required: false,
      new: false,
    },
    {
      url: RoutePluginStepName.Coupon,
      label: 'Reward',
      required: true,
      new: false,
      quickStep: true,
    },
    {
      url: RoutePluginStepName.Triggers,
      label: 'Triggers',
      required: true,
      new: false,
    },
    {
      url: RoutePluginStepName.MarketingEmails,
      label: 'Emails',
      required: true,
      new: false,
    },
    {
      url: RoutePluginStepName.MarketingSMS,
      label: 'SMS',
      required: true,
      new: false,
    },
  ],
  [RoutePluginName.NewsLetterNotification]: [
    {
      url: RoutePluginStepName.Theme,
      label: 'Theme',
      required: false,
      new: false,
      quickStep: true,
    },
    {
      url: RoutePluginStepName.Fields,
      label: 'Form',
      required: true,
      new: false,
      quickStep: true,
    },
    {
      url: RoutePluginStepName.Design,
      label: 'Design',
      required: false,
      new: false,
    },
    {
      url: RoutePluginStepName.Triggers,
      label: 'Triggers',
      required: true,
      new: false,
    },
    {
      url: RoutePluginStepName.MarketingEmails,
      label: 'Emails',
      required: true,
      new: false,
    },
    {
      url: RoutePluginStepName.MarketingSMS,
      label: 'SMS',
      required: true,
      new: false,
    },
  ],
  [RoutePluginName.FreeShipping]: [
    {
      url: RoutePluginStepName.Theme,
      label: 'Theme',
      required: false,
      new: false,
    },
    {
      url: RoutePluginStepName.Design,
      label: 'Design',
      required: false,
      new: false,
    },
    {
      url: RoutePluginStepName.Coupon,
      label: 'Reward',
      required: true,
      new: false,
    },
    {
      url: RoutePluginStepName.Triggers,
      label: 'Triggers',
      required: true,
      new: false,
    },
  ],
  [RoutePluginName.SalesPopup]: [
    {
      url: RoutePluginStepName.Theme,
      label: 'Theme',
      required: false,
      new: false,
    },
    {
      url: RoutePluginStepName.Design,
      label: 'Design',
      required: false,
      new: false,
    },
    {
      url: RoutePluginStepName.Coupon,
      label: 'Reward',
      required: true,
      new: false,
    },
    {
      url: RoutePluginStepName.Triggers,
      label: 'Triggers',
      required: true,
      new: false,
    },
  ],
  [RoutePluginName.ProductPopup]: [
    {
      url: RoutePluginStepName.Theme,
      label: 'Theme',
      required: false,
      new: false,
    },
    {
      url: RoutePluginStepName.Products,
      label: 'Products',
      required: true,
      new: false,
    },
    {
      url: RoutePluginStepName.Design,
      label: 'Design',
      required: false,
      new: false,
    },
    {
      url: RoutePluginStepName.Triggers,
      label: 'Triggers',
      required: true,
      new: false,
    },
  ],
}

@Injectable()
export class PluginConfigureNavigationService extends PubSub<any> {
  public defaultNavigation = _.cloneDeep(navigation)
  constructor() {
    super(navigation)
  }
}
