import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { SwiperOptions } from 'swiper';
import { SegmentAnalyticsService } from '../../services/segment-analytics.service';
import { SwiperModule } from 'swiper/angular';
import { OneIconComponent } from '../one-icon/one-icon.component';

enum SwiperType {
  Review = 'review',
}

@Component({
    selector: 'pf-swiper',
    templateUrl: './swiper.component.html',
    styleUrls: ['./swiper.component.scss'],
    standalone: true,
    imports: [
      CommonModule,
      SwiperModule,
      OneIconComponent,
    ]
})
export class SwiperComponent implements OnInit {
  @Input() type: SwiperType = SwiperType.Review
  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;

  public reviewSwipeConfig: SwiperOptions = {
    autoHeight: true,
    slidesPerView: 1,
    spaceBetween: 20,
    navigation: false,
    breakpoints: {
      640: {
        slidesPerView: 1,
      },
      800: {
        slidesPerView: 2,
      },
      1300: {
        slidesPerView: 3,
      },
    },
  };

  public reviewsData = [
    {
      date: '',
      stars: 5,
      review: 'I\'m so pleased to have found this app! This app has countless marketing tools and actually consider customer needs. They\'re always willing to help and might I add that this they\'re response is close to immediate. I would recommend this app to any business, big/small, for all marketing tools such as newsletters, email automation, popups and so many others...',
      store_name: 'Gardenvale',
      store_location: 'South Africa',
      redirect_url: '',
      image: '/app_assets/shopify_glyph.svg'
    },
    {
      date: '',
      stars: 5,
      review: 'They are amazing here. The customer service is always top notch here besides a great product. I always tell anybody to use this app for their store. The people here go over and beyond to assist you and product does more for you than you would expect. Again, thank you for everything you all are doing at One. I can\'t express how much you would really like their service. This is a must have for any business.',
      store_name: '177JAN6 Company',
      store_location: 'United States',
      redirect_url: '',
      image: '/app_assets/shopify_glyph.svg'
    },
    {
      type: 'case_study',
      title: 'Case Study',
      body: 'Over <b>$50,000+</b> of additional sales within 30 days of installing ONE',
      industry: 'Industry: Boutique Apparel Reseller',
      apps: 'Apps: Spin Wheel Pop Up, Cart Recovery, Post-Purchase Upsell',
      image: 'images/apparel.png',
      redirect_url: 'https://share.getcloudapp.com/8LugyO8K',
    },
    {
      date: '',
      stars: 5,
      review: 'This is one of the best apps on Shopify. I am just sorry I did not find it sooner. Honestly, I find it easier to use than Klaviyo and even though I just installed it, I am in love with it and what it can do. Everyone should check it out. The email templates and the spinning wheels have incredible designs. I can\'t wait to move on to implement more of its functions in my store. Oh, and the customer service is the best...',
      store_name: 'Pinaglare Collection',
      store_location: 'United States',
      redirect_url: '',
      image: '/app_assets/shopify_glyph.svg'
    },
    {
      date: '',
      stars: 5,
      review: 'I\'ve just started to use this app after searching high and low for an easy to use, shopify integrated newsletter, subscriber pop up and landing page app. The UI seems intuitive, which is a relief as I\'m not very techie. I have a great feeling about using ONE for all my ecommerce marketing needs. Thanks for being there for small businesses - we really need this kind of support to get our businesses off the ground...',
      store_name: 'B3KI',
      store_location: 'Spain',
      redirect_url: '',
      image: '/app_assets/shopify_glyph.svg'
    },
    {
      type: 'case_study',
      title: 'Case Study',
      body: 'Collected <b>120,000 contacts</b> and made <b>$13,000</b> of additional upsold GMV',
      industry: 'Industry: Handmade Jewelry',
      apps: 'Apps: Spin Wheel Pop Up, Post-Purchase Upsell',
      image: 'images/jewelry.png',
      redirect_url: 'https://share.getcloudapp.com/d5uyY7oD',
    },
  ]

  swiperType = SwiperType

  constructor(private segmentAnalyticsService: SegmentAnalyticsService) { }

  ngOnInit(): void {
  }

  getBgImage(image) {
    return `url(/app_assets/${image})`
  }

  redirectUrl(item) {
    const type = item.type
    const industry = item.industry?.replace('Industry: ', '')
    const url = item.redirect_url
    if (type === 'case_study' && industry) {
      this.segmentAnalyticsService.track(`Clicked Case Study_${industry}`)
    }
    url && window.open(url, '_blank')
  }

  swipePrev() {
    (this.swiper as any)?.swiperRef?.slidePrev();
  }
  swipeNext() {
    (this.swiper as any)?.swiperRef?.slideNext();
  }

  onSlideChange([swiper]) {
    if(swiper?.isBeginning) {
      document.querySelector('._swiper-prev')?.classList.add('swiper-button-disabled')
    } else {
      document.querySelector('._swiper-prev')?.classList.remove('swiper-button-disabled')
    }
    if(swiper?.isEnd) {
      document.querySelector('._swiper-next')?.classList.add('swiper-button-disabled')
    } else {
      document.querySelector('._swiper-next')?.classList.remove('swiper-button-disabled')
    }
  }

}
