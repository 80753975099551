const ka = {
  Quantity: 'რაოდენობა',
  Size: 'ზომა',
  Color: 'ფერი',
  Material: 'მასალა',
  Style: 'სტილი',
  Savings: 'ფასდაკლება',
  Subtotal: 'ფასი',
  Shipping: 'ტრანსპორტირება',
  Taxes: 'გადასახადი',
  Total: 'ჯამი',
  Free: 'უფასო',
  Buy: 'იყიდე',
  'Pay Now': 'გადაიხადე',
  'Take the Deal': 'დათანხმდი შემოთავაზებას',
  'Add Now': 'დაამატე',
  'You will be charged immediately': 'თანხა ჩამოგეჭრებათ ახლავე',
  'Decline Offer': 'უარი შემოთავაზებაზე',
  'No Thanks': 'არა, მადლობა',
  '{price}': '{ფასი}',
  'Sold Out': 'ამოწურულია',
  'see more': 'მეტი',
  less: 'ნაკლები',
};
export {ka};
