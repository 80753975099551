export enum GettingStartedEventName {
  // General
  Back = 'back',
  Skip = 'skip',
  Continue = 'continue',
  Navigate = 'navigate',
  // Install
  SelectPlatform = 'select_platform',
  RequestSupport = 'request_support',
  Close = 'close',
  CopyCode = 'copy_code',
  CopyApiKey = 'copy_api_key',
  CopyUserId = 'copy_user_id',
  // Detect
  BackModal = 'back_modal',
  ContinueModal = 'continue_modal',
  OpenURL = 'open_url',
  EditURL = 'edit_url',
  SaveURL = 'save_url',
  // Log Out
  LogOut = 'log_out'
}
