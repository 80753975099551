import { BillingActions } from './billing.actions'
import { BillingActionTypes } from './billing.action-types'
import { initialBillingState } from './billing.state'


export function billingReducer(state = initialBillingState, action: BillingActions) {
  switch (action.type) {

    case BillingActionTypes.READ_BILLING_REQUEST:
      return { ...state, error: null }
    case BillingActionTypes.READ_BILLING_FAILURE:
      return { ...state, error: action.payload }
    case BillingActionTypes.READ_BILLING_SUCCESS:
      return { ...state, valid: action.payload.valid, source: action.payload.source }

    default:
      return state

  }
}
