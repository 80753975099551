import { CampaignState, initialCampaignState } from './campaign.state'
import { CampaignActions } from './campaign.actions'
import { CampaignActionTypes } from './campaign.action-types'
import { CampaignPluginName, CampaignTypeList } from '../../shared/models/campaign/campaign'

export function campaignReducer(state = initialCampaignState, action: CampaignActions): CampaignState {
  switch (action.type) {
    case CampaignActionTypes.CLEAR_SELECTED_CAMPAIGN:
      return { ...state, selectedCampaign: null }
    case CampaignActionTypes.SET_SEARCH_QUERY:
      return { ...state, searchQuery: action.payload }

    case CampaignActionTypes.READ_CAMPAIGN_REQUEST:
    case CampaignActionTypes.READ_CAMPAIGN_LIST_REQUEST:
    case CampaignActionTypes.UPDATE_CAMPAIGN_REQUEST:
    case CampaignActionTypes.UPDATE_CAMPAIGN_ACTIVE_REQUEST:
    case CampaignActionTypes.UPDATE_DISPLAY_CONFIGS_REQUEST:
    case CampaignActionTypes.CREATE_CAMPAIGN_REQUEST:
    case CampaignActionTypes.UPDATE_PLUGIN_CONFIG_REQUEST:
    case CampaignActionTypes.DELETE_CAMPAIGN_REQUEST:
    case CampaignActionTypes.DUPLICATE_CAMPAIGN_REQUEST:
    case CampaignActionTypes.CREATE_WEBHOOK_REQUEST:
    case CampaignActionTypes.READ_DISPLAY_CONFIGS_REQUEST:
    case CampaignActionTypes.READ_EVENTS_REQUEST:
      return {
        ...state,
        error: null,
        templateError: null,
        limitError: null,
        requests: {
          ...state.requests,
          updateTypeConfig: null,
        },
      }

    case CampaignActionTypes.UPDATE_CAMPAIGN_ACTIVE_FAILURE:
    case CampaignActionTypes.CREATE_CAMPAIGN_FAILURE:
    case CampaignActionTypes.DELETE_CAMPAIGN_FAILURE:
    case CampaignActionTypes.DUPLICATE_CAMPAIGN_FAILURE:
      return { ...state, error: action.payload, limitError: action.payload }

    case CampaignActionTypes.READ_CAMPAIGN_FAILURE:
    case CampaignActionTypes.READ_CAMPAIGN_LIST_FAILURE:
    case CampaignActionTypes.UPDATE_DISPLAY_CONFIGS_FAILURE:
    case CampaignActionTypes.UPDATE_PLUGIN_CONFIG_FAILURE:
    case CampaignActionTypes.CREATE_WEBHOOK_FAILURE:
    case CampaignActionTypes.READ_DISPLAY_CONFIGS_FAILURE:
    case CampaignActionTypes.READ_EVENTS_FAILURE:
      return { ...state, error: action.payload }

    case CampaignActionTypes.SET_TEMPLATE_ERROR:
      return {
        ...state,
        templateError: action.payload,
        requests: {
          ...state.requests,
          updateTypeConfig: false,
        },
      }

    case CampaignActionTypes.CLEAR_ERROR:
      return {
        ...state,
        error: null,
      }

    case CampaignActionTypes.READ_CAMPAIGN_WHEEL_PATTERNS_SUCCESS: {
      return { ...state, wheel: { ...state.wheel, patterns: action.payload } }
    }

    case CampaignActionTypes.READ_CAMPAIGN_WHEEL_THEMES_SUCCESS: {
      return { ...state, wheel: { ...state.wheel, themes: action.payload } }
    }

    case CampaignActionTypes.READ_CAMPAIGN_CB_THEMES_SUCCESS: {
      return { ...state, cb: { ...state.cb, themes: action.payload } }
    }

    case CampaignActionTypes.READ_CAMPAIGN_NL_THEMES_SUCCESS: {
      return { ...state, newsletter: { ...state.newsletter, themes: action.payload } }
    }

    case CampaignActionTypes.READ_CAMPAIGN_SUCCESS:
      return { ...state, selectedCampaign: { ...action.payload } }

    case CampaignActionTypes.READ_DISPLAY_CONFIGS_SUCCESS:
      return { ...state, selectedCampaign: { ...state.selectedCampaign, display_configs: action.payload } }

    case CampaignActionTypes.READ_CAMPAIGN_LIST_SUCCESS:
      return {
        ...state,
        list: [...action.payload].sort(function(a, b) {
          if (a.name < b.name) {
            return -1
          }
          if (a.name > b.name) {
            return 1
          }
          return 0
        }),
      }

    case CampaignActionTypes.UPDATE_CAMPAIGN_SUCCESS:
    case CampaignActionTypes.UPDATE_CAMPAIGN_ACTIVE_SUCCESS:
      return {
        ...state,
        list: state.list
          .map(item => (item.id === action.payload.id ? { ...item, ...action.payload } : item))
          .sort(function(a, b) {
            if (a.name < b.name) {
              return -1
            }
            if (a.name > b.name) {
              return 1
            }
            return 0
          }),
        selectedCampaign: action.payload,
      }

    case CampaignActionTypes.UPDATE_DISPLAY_CONFIGS_SUCCESS:
      return { ...state, selectedCampaign: { ...state.selectedCampaign, display_configs: action.payload } }

    case CampaignActionTypes.CREATE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        list: [...state.list, action.payload].sort(function(a, b) {
          if (a.name < b.name) {
            return -1
          }
          if (a.name > b.name) {
            return 1
          }
          return 0
        }),
      }

    case CampaignActionTypes.DELETE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        list: state.list.filter(item => item.id !== action.payload).sort(function(a, b) {
          if (a.name < b.name) {
            return -1
          }
          if (a.name > b.name) {
            return 1
          }
          return 0
        }),
      }

    case CampaignActionTypes.DUPLICATE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        list: [action.payload, ...state.list],
      }

    case CampaignActionTypes.UPDATE_PLUGIN_CONFIG_SUCCESS:
      const type = CampaignTypeList.filter(prop => state.selectedCampaign[prop]['id'] === action.payload.id)[0]
      const notificationConfig = action.payload.config['v1']
        ? {
          ...action.payload,
          config: { ...action.payload.config['v1'] } /* map v1 to config*/,
        }
        : action.payload
      const selectedCampaign = { ...state.selectedCampaign, [type]: notificationConfig }
      return {
        ...state,
        selectedCampaign: selectedCampaign,
        requests: {
          ...state.requests,
          updateTypeConfig: true,
        },
      }

    case CampaignActionTypes.READ_EVENTS_SUCCESS:
      return {
        ...state,
        selectedCampaignEventsResult: { events: action.payload.events || action.payload.orders, status: true },
      }

    case CampaignActionTypes.RESET_EVENTS:
      return { ...state, selectedCampaignEventsResult: { events: [], status: false } }

    case CampaignActionTypes.CREATE_WEBHOOK_SUCCESS:
      return {
        ...state,
        selectedCampaign: {
          ...state.selectedCampaign,
          [CampaignPluginName.RecentActivity]: {
            ...state.selectedCampaign[CampaignPluginName.RecentActivity],
            webhook: action.payload.res,
          },
        },
      }

    case CampaignActionTypes.SET_CAPTURE_CONFIGS:
      return {
        ...state,
        selectedCampaign: {
          ...state.selectedCampaign,
          [CampaignPluginName.RecentActivity]: {
            ...state.selectedCampaign[CampaignPluginName.RecentActivity],
            capture_configs: action.payload,
          },
        },
      }

    case CampaignActionTypes.SET_DATA_SOURCES:
      const currentItemUserActivityNotification = state.selectedCampaign[CampaignPluginName.RecentActivity]
      const newUserActivityNotification = {
        ...currentItemUserActivityNotification,
        data_sources: action.payload,
      }
      const newCurrentItem = {
        ...state.selectedCampaign,
        [CampaignPluginName.RecentActivity]: newUserActivityNotification,
      }

      return {
        ...state,
        selectedCampaign: newCurrentItem,
      }

    default:
      return state
  }
}
