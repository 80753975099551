/* routeHeaderUrl = RouteHeaderUrl */
/* [routerLink]="routeHeaderUrl.key" */
/* this.router.navigate([this.routeHeaderUrl.key]) */

export enum RouteHeaderUrl {
  admin = 'admin',
  ai_agents = 'ai-agents',
  ai_copilot = 'ai-copilot',
  ai_sidekick = 'ai-sidekick',
  announcements = 'announcements',
  assets = 'assets',
  apps = 'apps',
  automations = 'automations',
  billing = 'billing',
  brandkit = 'brand-kit',
  brandkits = 'brand-kits',
  broadcasts = 'broadcasts',
  campaigns = 'campaigns',
  cart = 'cart',
  cartRecovery = 'cart-recovery',
  checkout = 'checkout',
  choose_plan = 'choose-plan',
  configure = 'configure',
  contacts = 'contacts',
  coupons = 'coupons',
  csm = 'csm',
  custom = 'custom',
  customer_data = 'cdp',
  dashboard = 'dashboard',
  dns = 'dns',
  email_automation_builder = 'email-automation-builder',
  email_compliance = 'compliance',
  email_editor = 'email-editor',
  emails = 'emails',
  gift_cards = 'gift-cards',
  history = 'history',
  holidays = 'holidays',
  images = 'images',
  install = 'install',
  integrations = 'integrations',
  marketing_emails = 'marketing_emails',
  marketing_sms = 'marketing_sms',
  messaging = 'messaging',
  onboarding = 'onboarding',
  oneGPT = 'one-gpt',
  orders = 'orders',
  photoReviews = 'photo-reviews',
  placed_orders = 'placed-orders',
  popups = 'pop-ups',
  products = 'products',
  products_collections = 'products/collections',
  profiles = 'profiles',
  reward = 'reward',
  rewards = 'rewards',
  sales_channels = 'sales-channels',
  settings = 'settings',
  shopify = 'shopify',
  sms = 'sms',
  sms_credits_page = '/settings/billing/sms',
  social_proof = 'social-proof',
  subscribe_forms = 'subscribe-forms',
  subscribers = 'subscribers',
  subscriptions = 'subscriptions',
  success = 'success',
  textReviews = 'text-reviews',
  tiktok = 'tiktok',
  tutorials = 'tutorials',
  upsell = 'upsell',
  videoReviews = 'video-reviews',
  welcomeEmail = 'welcome-email',
  winback = 'winback',
}

export enum SideNavigationTitle {
  advertising = 'Advertising',
  ai_assistans = 'AI Assistants',
  ai_co_pilot = 'AI Co-Pilot',
  ai_sidekick = 'AI Sidekick',
  amazon_store = 'Amazon Store',
  analytics = 'Analytics',
  announcements = 'Announcements',
  app_integrations = 'App Integrations',
  app_library = 'App Library',
  apps = 'Apps',
  articles = 'Articles',
  assets = 'Assets',
  bing_store = 'Bing Store',
  brand_kits = 'Brand Kits',
  cart = 'Your Plan Summary',
  cdp = 'CDP',
  contacts = 'Contacts',
  coupons = 'Coupons',
  creatives = 'Creatives',
  customer_platform = 'Customer Platform',
  documents = 'Documents',
  download_links = 'Download Links',
  ebay_store = 'eBay Store',
  email_settings = 'Email Settings',
  emails = 'Emails',
  fonts = 'Fonts',
  gift_cards = 'Gift Cards',
  google_store = 'Google Store',
  holidays = 'Holidays',
  icons = 'Icons',
  images = 'Images',
  instagram_store = 'Instagram Store',
  loyalty_points = 'Loyalty Points',
  marketing = 'Marketing',
  messaging = 'Messaging',
  orders = 'Orders',
  photo_reviews = 'Photo Reviews',
  placed_orders = 'Placed Orders',
  popups = 'Pop Ups',
  product_catalog = 'Product Catalog',
  product_collections = 'Product Collections',
  products = 'Products',
  profiles = 'Profiles',
  push_notifications = 'Push Notifications',
  reviews = 'Reviews',
  rewards= 'Rewards',
  segments = 'Segments',
  settings = 'Settings',
  shopify_store = 'Shopify Store',
  sms = 'SMS',
  sms_history = 'SMS History',
  sms_settings = 'SMS Settings',
  social_proof = 'Social Proof',
  store_credits = 'Store Credits',
  storefronts = 'Storefronts',
  subscribe_forms = 'Subscribe Forms',
  subscribers = 'Subscribers',
  templates = 'Templates',
  text_reviews = 'Text Reviews',
  tiktok_store = 'TikTok Store',
  tutorials = 'Tutorials',
  upsell = 'Upsell',
  upsell_checkout = 'Upsell Checkout Page',
  upsell_email = 'Upsell Email',
  upsell_sms= 'Upsell SMS',
  video_reviews = 'Video Reviews',
  videos = 'Videos',
  whatsapp = 'WhatsApp',
  youtube_store = 'YouTube Store',
}
// TODO: put requestFeature string as enum
