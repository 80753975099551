<mat-dialog-content class="NU">
  <div class="NU-Title">Post Purchase Upsell</div>
  <div class="NU-Body">
    <div *ngFor="let preset of presets"
        class="NU-Promo">
      <div class="NU-Promo-Image"
          [style.background]="getBgImage(preset.image)"
          [style.backgroundSize]="'cover'"
          [style.backgroundPosition]="'center'">
      </div>
      <span class="NU-Promo-Text">
        <h4>{{ preset.name }}</h4>
        <p>{{ preset.description }}</p>
        <button class="pf-button filled blue"
                (click)="select(preset)">
          Create
        </button>
      </span>
    </div>
  </div>
</mat-dialog-content>
