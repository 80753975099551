import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'
import { CampaignPluginName } from '../../models/campaign/campaign'
import { CampaignDesignVariables } from '../../modules/social-proof-design-step/models/campaign-design.models'

@Injectable()
export class ScalePreviewsService {
  private calculated: Subject<any>
  private pluginsList: any
  private collectedScales: any[] = []

  initPluginsList( list: CampaignPluginName[] ) {
    this.calculated = new Subject<any>()
    this.pluginsList = list || []
  }

  getScale() {
    return this.calculated.asObservable()
  }

  update(scale: number, name: CampaignPluginName, resize: boolean) {
    const matchedPlugins = this.collectedScales.filter(item => item.name === name)
    if (!matchedPlugins.length) {
      this.collectedScales.push({
        name: name,
        scale: scale
      })
    } else {
      matchedPlugins[0] = {
        name: name,
        scale: scale
      }
    }

    if (this.pluginsList.length === this.collectedScales.length) {
      const globalScale = {
        scale: this.getMinScale(this.collectedScales)
      }
      this.calculated.next(globalScale)
    }
  }

  private getMinScale(data) {
    return data.reduce((min, b) => Math.min(min, b.scale), data[0].scale);
  }

  setScale( hostElement: any, previewElement: any, variables: CampaignDesignVariables, pluginName: CampaignPluginName, resize) {
    const hostW = hostElement.offsetWidth
    const hostH = hostElement.offsetHeight
    const elH = previewElement.children[0].offsetHeight
    const scale = Math.min(
      hostW / parseInt(variables.notification_width, 10),
      hostH / parseInt(elH, 10),
    )
    this.update(scale, pluginName, resize)
  }

  destroy() {
    this.collectedScales = []
    this.pluginsList = null
    this.calculated && this.calculated.complete()
  }
}
