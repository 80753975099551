

<div class="PlansPage">
  <ng-container *ngIf="plans && plans.length">
    <h1 class="PlansPage-Title" [class._promo]="!!userPromo" *ngIf="pageTitle">{{ pageTitle }}</h1>

    <div class="PlansPage-Promo" *ngIf="!!userPromo">
      <pf-promo
        [userPromo]="userPromo"
        [userInfo]="userInfo"
        (resultEmitter)="onCouponApplied($event)">
      </pf-promo>
    </div>

    <div class="PlansPage-Subtitle">
      <ng-container [ngSwitch]="usagePlanMetric">
        <ng-container *ngSwitchCase="UsagePlanMetricType.Customers">
          How many
          <strong class="Tooltip-Dashed"
            matTooltipClass="Tooltip-Box"
            [oneTooltip]="totalCustomersTooltip">customer profiles
          </strong>
          do you have?
        </ng-container>
        <ng-container *ngSwitchCase="UsagePlanMetricType.Subscribers">
          How many
          <strong class="Tooltip-Dashed"
            matTooltipClass="Tooltip-Box"
            [oneTooltip]="totalSubscribersTooltip">subscribers
          </strong>
          do you have?
        </ng-container>
      </ng-container>
    </div>

    <ng-template #totalCustomersTooltip>
      Customer Profiles: <strong>{{metricTotalCount?.toLocaleString()}}</strong> (Current)<br>
      <strong>{{metricAddedCount?.toLocaleString()}}</strong> (added in last 30 days)
    </ng-template>

    <ng-template #totalSubscribersTooltip>
      Subscribers: <strong>{{metricTotalCount?.toLocaleString()}}</strong> (Current)<br>
      <strong>{{metricAddedCount?.toLocaleString()}}</strong> (added in last 30 days)
    </ng-template>

    <div class="PlansPage-Selector">
      <select class="form-control ml-3"
        [(ngModel)]="contactsCountSelect"
        (click)="contactsOpen()"
        (change)="contactsOptionChanged()"
        [class.disabled]="!totalsLoaded">
        <option *ngFor="let option of contactsCountOptions" [ngValue]="option" [disabled]="option?.disabled">
          {{ option.title }}
        </option>
      </select>
    </div>


    <div class="Plans">
      <ng-container *ngFor="let plan of visiblePlans; index as i">
        <div class="Plan">
          <!-- TODO move plan to separate pf-plans-item-new-1 component in case we need to share it -->
          <div class="Plan-Header">
            <div class="Plan-Header-Title">{{ plan.title}}</div>
            <div class="Plan-Header-Subtitle">{{ plan.subtitle }}&nbsp;</div>
          </div>
          <div class="Plan-Details">
            <div class="Plan-Pricing">
              <ng-container *ngIf="plan.price?.custom; else displayPrice">
                <div class="Plan-Pricing-Price">
                  {{ plan.price.custom }}
                </div>
              </ng-container>
              <ng-template #displayPrice>
                <div class="Plan-Pricing-Superscript">
                  Starting at
                </div>
                <!-- If Plan is selected -->
                <ng-container *ngIf="plan.selected && currentPlan; else pricingPrice">
                  <div class="Plan-Pricing-Price">
                    <div *ngIf="currentPlan?.price.amount === '0'" class="_amount">FREE</div>
                    <div *ngIf="currentPlan?.price.amount !== '0'">
                      <div class="d-flex align-items-center flex-wrap" *ngIf="currentPlan?.old_display_price && currentPlan?.old_display_price !== '0'; else staticPrice">
                        <span class="_old">${{ currentPlan?.old_display_price }}</span>
                        <span class="_new">${{ currentPlan?.price?.amount }}<span class="Plan-Pricing-Period">/ {{ plan.price.period }}</span></span>
                      </div>
                      <ng-template #staticPrice>${{ currentPlan?.price?.amount }}<span class="Plan-Pricing-Period">/ {{ plan.price.period }}</span></ng-template>
                    </div>
                  </div>
                </ng-container>
                <!-- If plan is not selected yet -->
                <ng-template #pricingPrice>
                  <div class="Plan-Pricing-Price" [class._discounted]="plan.old_display_price && plan.old_display_price !== '$0'">
                    <ng-container *ngIf="!plan.misconfigured; else misconfigured">
                      <div *ngIf="plan.price.amount === '$0'" class="_amount">FREE</div>
                      <div *ngIf="plan.price.amount !== '$0'">
                        <div class="d-flex align-items-center flex-wrap" *ngIf="plan.old_display_price && plan.old_display_price !== '$0'; else onlyPrice">
                          <span class="_old">{{ plan.old_display_price }}</span>
                          <span class="_new">{{ plan.price.amount }}<span class="Plan-Pricing-Period">/ {{ plan.price.period }}</span></span>
                        </div>
                        <ng-template #onlyPrice>{{ plan.price.amount }}<span class="Plan-Pricing-Period">/ {{ plan.price.period }}</span></ng-template>
                      </div>
                    </ng-container>
                    <ng-template #misconfigured>
                      <span class="_amount">Invalid</span>
                    </ng-template>
                  </div>
                </ng-template>
              </ng-template>
            </div>
            <div class="Plan-Buttons">
              <button class="pf-button fw"
                [ngClass]="{
                  'black outline': plan.plan_cta_color === 'black',
                  'shopify-green filled': plan.plan_cta_color !== 'black'
                }"
                (click)="onSelectPlanClick(plan)"
                [class._disabled]="plan.selected || plan.disabled">
                  <ng-container *ngIf="plan.misconfigured; else buttonText">
                    Contact Support
                  </ng-container>
                  <ng-template #buttonText>
                    {{ plan.plan_cta_label_override || plan.plan_cta_label || 'Choose Plan' }}
                  </ng-template>
              </button>
              <button class="pf-button fw black outline mt-2"
                      *ngIf="plan.isCustom || (plan.isCustomPriceTier && !plan.isFree)"
                      (click)="openCalendlyWidget()">
                Book a Call
              </button>
              <small *ngIf="plan.subtext" class="Plan-Buttons-Text text-center text-muted">{{ plan.subtext }}</small>
            </div>
          </div>
          <div class="Plan-Content">
            <div class="Plan-Content-Item" *ngFor="let item of plan.content">
              {{ item.title }}
              <div class="Plan-Content-Item-Points">
                <div class="Plan-Content-Item-Point" *ngFor="let point of item.points" [class._inactive]="!point.active">
                  <ng-container *ngIf="point.withTooltip; else noTooltip">
                    <div class="Tooltip-Dashed" [oneTooltip]="point.tooltipText">
                      {{ point.title }}
                    </div>
                  </ng-container>
                  <ng-template #noTooltip>
                    {{ point.title }}
                  </ng-template>
                </div>
              </div>
            </div>
          </div>

          <!-- <pf-plans-item-alt-1
            [data]="plan"
            [user]="userInfo"
            [smsEligible]="userSMSEligible"
            [shopify]="true"
            [mini]="true"
            [buttonText]="plan.plan_cta_label"
            (onSelect)="onSelectPlanClick(plan, $event)">
          </pf-plans-item-alt-1> -->
        </div>
      </ng-container>
    </div>

    <pf-support-boxs [hasRequestFeature]="false"></pf-support-boxs>


    <div class="FeatureCards">
      <h1 class="FeatureCards-Title" *ngIf="pageTitle">20+ ONE Apps Seamlessly Integrated</h1>
      <div class="FeatureCard" *ngFor="let card of featureCards">
        <div class="FeatureCard-Image">
          <img [src]="card.image" alt="">
        </div>
        <div class="FeatureCard-Content">
          <div class="FeatureCard-Title">{{card.title}}</div>
          <ul class="FeatureCard-Points">
            <li class="FeatureCard-Point" *ngFor="let point of card.points">
              {{ point }}
            </li>
          </ul>
        </div>
        <div class="FeatureCard-Pricing">
          <div class="FeatureCard-Pricing-Superscript" *ngIf="card.price.superscript">
            {{ card.price.superscript }}
          </div>
          <div class="FeatureCard-Pricing-Price">
            {{ card.price.amount === 'Free' ? 'Unlimited' : card.price.currency + card.price.amount }}
          </div>
          <div class="FeatureCard-Pricing-Period" *ngIf="card.price.period">
            {{ card.price.period }}
          </div>
        </div>
      </div>
    </div>
<!--
    <div class="Testimonial">
      <div class="Testimonial__Box">
        <div class="Testimonial__Photo"><img src="/app_assets/images/peter.jpeg" alt=""></div>
        <div class="Testimonial__Body">
          <div class="Testimonial__Name">Peter Friis</div>
          <div class="Testimonial__Position">CEO,
            <a style="text-decoration: none; color: #29334e;" href="https://prvolt.com/">PRVolt.com</a></div>
          <div class="Testimonial__Text">"{{brandingName}} makes sure that I get the highest ROI on my marketing campaigns
            by encouraging as many customers to purchase. I wouldn’t market without it."
          </div>
        </div>
      </div>
    </div> -->

    <div class="QuestionList">
      <div class="QuestionItem">
        <p class="QuestionText">
          How does the free trial work?
        </p>
        <p class="QuestionAnswer">
          If you cancel a paying plan within the free trial period you won’t be charged a thing.
        </p>
      </div>
      <div class="QuestionItem" *ngIf="shopType!=='ShopifyShop'">
        <p class="QuestionText">
          Do I get a free trial if I upgrade?
        </p>
        <p class="QuestionAnswer">
          If you upgrade from a free account to a paid account you are not eligible for the free trial. The free trial
          on paying plans only applies if you sign up for a paying plan now. You can always downgrade later if you
          choose.
        </p>
      </div>
      <div class="QuestionItem">
        <p class="QuestionText">
          Do I have to sign a long term contract?
        </p>
        <p class="QuestionAnswer">
          No. {{brandingName}} is a pay-as-you-go service. There are no long term contracts or commitments on your part. You
          simply pay
          month-to-month.
        </p>
      </div>
      <div class="QuestionItem">
        <p class="QuestionText">
          Can I change plans at any time?
        </p>
        <p class="QuestionAnswer">
          Yep. Simply click on the “Customer Profiles” tab on your dashboard and you’ll see your options.
        </p>
      </div>
      <div class="QuestionItem">
        <p class="QuestionText">
          What types of payment do you accept?
        </p>
        <p *ngIf="shopType!=='ShopifyShop'" class="QuestionAnswer">
          We use Stripe to process your payments and currently accept Visa, Mastercard, and American Express. We do not
          accept PayPal. At this
          time we only accept payments online so
          we will not be able to accept a P.O., invoice you, nor take an order over the phone.
        </p>
        <p *ngIf="shopType==='ShopifyShop'" class="QuestionAnswer">
          We use Shopify to process your payments. All payments go through the credit card you have on file with
          Shopify.
        </p>
      </div>
      <!-- <div class="QuestionItem">
        <p class="QuestionText">
          Are there per-site fees?
        </p>
        <p class="QuestionAnswer">
          No. The prices you see above are all inclusive. For example, the High Traffic Site plan is $79/month for
          50,000 unique visitors per
          month. That means you pay
          $79/month total no matter how many sites you have as long as it’s 50,000 unique visitors per month or less. If
          you don’t see a plan
          large enough for your site
          here, we have other plans available. <a href="mailto:enterprise@prooffactor.com">Please contact us.</a>
        </p>
      </div> -->
    </div>
  </ng-container>

  <pf-loading-overlay [filter]="{ignore: ['addCard', 'savePaymentToken', 'stripeLoading']}"></pf-loading-overlay>
</div>
