<div [class._floating-ai]="!isQuillEditor" class="w-100">
  <div *ngIf="showPreview && !isWritingAI" class="ai-preview">
    <div class="py-2 ai-title">
      Preview
    </div>
    <ng-container *ngIf="isQuillEditor; else textPreview">
      <div [innerHTML]="previewHTML"></div>
    </ng-container>
    <ng-template #textPreview>
      {{ previewText }}
    </ng-template>
  </div>
  <div class="ai-dropdown" #aiDropdown>
      <div class="ai-close" *ngIf="!isWritingAI" (click)="closeAI()">
        <one-icon name="close" class="mr-1"></one-icon>
        <span>Close</span>
      </div>
      <ng-container *ngIf="isUndo">
        <div class="py-2 d-flex align-items-center justify-content-between">
          <div class="ai-title">
            Action ({{actionText}})
          </div>
        </div>
        <ul>
          <li>
            <div class="d-flex align-items-center" (click)="setUndoAction(actionText)">
              <img width="14" height="14" src="/app_assets/ai/undo.svg" class="mr-2" alt="">
              <span>Undo</span>
            </div>
          </li>
        </ul>
      </ng-container>
      <ng-container *ngIf="isWritingAI">
        <div class="ai-writing">
          <img width="14" height="14" src="/app_assets/ai/ai.svg" class="mr-2" alt="">
          <span class="ai-loading">AI is Writing</span>
        </div>
      </ng-container>
      <ng-container *ngIf="previewText && !isWritingAI && !isUndo">
        <div class="py-2 ai-title">
          Action
        </div>
        <ul>
          <li>
            <div class="d-flex align-items-center" (click)="setAction(aiAction.replace)">
              <img width="14" height="14" src="/app_assets/ai/replace.svg" class="mr-2" alt="">
              <span>Replace</span>
            </div>
          </li>
          <li>
            <div class="d-flex align-items-center" (click)="setAction(aiAction.insertBelow)">
              <img width="14" height="14" src="/app_assets/ai/insert-below.svg" class="mr-2" alt="">
              <span>Insert below</span>
            </div>
          </li>
          <li>
            <div class="d-flex align-items-center" (click)="setAction(aiAction.discard)">
              <img width="14" height="14" src="/app_assets/ai/discard.svg" class="mr-2" alt="">
              <span>Discard</span>
            </div>
          </li>
        </ul>
      </ng-container>
      <ng-container *ngIf="!isWritingAI && !expanded && !isUndo">
        <div class="py-2 d-flex align-items-center justify-content-between">
          <div *ngIf="!showPreview" class="ai-title">Ask ONE AI</div>
          <div *ngIf="showPreview" class="ai-title">Try again</div>
        </div>
        <ul>
          <li class="ai-options" *ngFor="let option of options">
              <ng-container *ngIf="!option.expandable">
                  <div class="d-flex align-items-center" (click)="onSelect(option)">
                    <img *ngIf="option.svg" width="14" height="14" [src]="option.svg" class="mr-2" alt="">
                    <span>{{option.label}}</span>
                  </div>
              </ng-container>
              <ng-container *ngIf="option.expandable">
                  <div class="d-flex align-items-center justify-content-between" (click)="toggleExpandOption(option.value, true)">
                      <div class="d-flex align-items-center">
                        <img *ngIf="option.svg" width="14" height="14" [src]="option.svg" class="mr-2" alt="">
                        <span>{{option.label}}</span>
                      </div>
                      <one-icon name="chevron-forward-outline"></one-icon>
                  </div>
              </ng-container>
          </li>
        </ul>
      </ng-container>
      <ng-container
        *ngIf="!isWritingAI && optionExpanded[expandableOptions.changeTone] && !isUndo"
        [ngTemplateOutlet]="expandedOptionTemplate"
        [ngTemplateOutletContext]="{
         title: 'Change tone',
         options: toneOptions,
         expandableOption: expandableOptions.changeTone
        }"
      >
      </ng-container>
      <ng-container
        *ngIf="!isWritingAI && optionExpanded[expandableOptions.translate] && !isUndo"
        [ngTemplateOutlet]="expandedOptionTemplate"
        [ngTemplateOutletContext]="{
         title: 'Translate',
         options: languageOptions,
         expandableOption: expandableOptions.translate
        }"
      >
      </ng-container>
  </div>
</div>
<ng-template #expandedOptionTemplate  let-title="title" let-options="options" let-expandableOption="expandableOption">
  <div class="py-2 d-flex align-items-start justify-content-between">
    <div class="ai-title" [innerHTML]="title"></div>
    <div
      class="d-flex align-items-center cursor-pointer"
      (click)="toggleExpandOption(expandableOption, false)">
      <div class="ai-back">
        <one-icon name="arrow-back-outline" class="mr-1"></one-icon>
        <span>Back</span>
      </div>
    </div>
  </div>
  <ul>
    <li
      *ngFor="let option of options; let isFirst = first"
      [class._priority-language]="expandableOption === expandableOptions.translate && isFirst && showPriorityLanguage"
      (click)="onSelect(option)">
      {{option.label}}
    </li>
  </ul>
</ng-template>
