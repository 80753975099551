<input
  type="file"
  accept='image/*'
  id="upload-image"
  [(ngModel)]="file"
  #ctrl="ngModel"
  (change)="fileSelected($event)"
  hidden>

<div class="ImageUpload-Preview-Row">
  <div class="ImageUpload-Preview" *ngFor="let image of uploadedImages; let i = index">
    <img [src]="image.path" *ngIf="image.path" alt="">
    <div class="ImageUpload-Preview-Hover">
      <div class="ImageUpload-Preview-Title">{{ image.name }}</div>
      <div class="ImageUpload-Preview-Size">{{ image.size | fileSize }}</div>
    </div>
    <div class="ImageUpload-Preview-Delete" (click)="removeImage(i)">&times;</div>
  </div>
</div>

<div>
  <label for="upload-image" class="pf-button filled blue mt-2">{{ buttonText }}</label>
</div>

<pf-loading-overlay
  [isFixed]="false"
  [filter]="{only: ['uploadFile']}"
></pf-loading-overlay>
