export enum ActiveAppCampaigns {
  Popup = 'active_app_campaigns_popup',
  PostPurchaseUpsell = 'active_app_campaigns_page_post_purchase_upsell',
  EmailBroadcast = 'active_app_campaigns_email_broadcast',
  EmailAutomation = 'active_app_campaigns_email_automation',
  SmsBroadcast = 'active_app_campaigns_sms_broadcast',
  SmsAutomation = 'active_app_campaigns_sms_automation',
  Total = 'active_app_campaigns_total',

  LinkedEmailAutomation = 'active_linked_email_automations',
  LinkedSmsAutomation = 'active_linked_sms_automations',

  CompletedEmailBroadcast = 'completed_email_broadcasts',
  CompletedSmsBroadcast = 'completed_sms_broadcasts',

  LinkedCoupons = 'active_linked_coupons',
  LinkedGiftCards = 'active_linked_gift_cards',
}

export const appCampaignName = {
  [ActiveAppCampaigns.Popup]: 'Popup',
  [ActiveAppCampaigns.PostPurchaseUpsell]: 'Post Purchase Upsell',
  [ActiveAppCampaigns.EmailAutomation]: 'Email Automation',
  [ActiveAppCampaigns.EmailBroadcast]: 'Email Broadcast',
  [ActiveAppCampaigns.SmsAutomation]: 'SMS Automation',
  [ActiveAppCampaigns.SmsBroadcast]: 'SMS Broadcast',
}
