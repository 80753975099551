import { Injectable } from '@angular/core'
import moment from 'moment'
import { BehaviorSubject } from 'rxjs'
import { EmailPageDateRange } from '../../emails/enums/email-page.models'
import { CampaignDisplayStatusToFilterStatus } from '../../../shared/models/campaign/campaign'
import { finalize, map } from 'rxjs/operators'
import { ApiService } from '../../../core/services/api/api.service'
import { HideLoading } from '../../../store/loading/loading.actions'
import { Store } from '@ngrx/store'
import { StoreState } from '../../../store/store.state'

@Injectable()
export class SmsService {
  private startDate = moment().utc().subtract(29, 'days').format('YYYY-MM-DD')
  private endDate = moment().utc().format('YYYY-MM-DD')
  // private endDate = moment().utc().subtract(1, 'day').format('YYYY-MM-DD')
  public onboardingCompleted$: BehaviorSubject<boolean> = new BehaviorSubject(false)
  public dateRange$: BehaviorSubject<EmailPageDateRange> = new BehaviorSubject({
    start_date: this.startDate,
    end_date: this.endDate,
  })

  constructor(
    private apiService: ApiService,
    private store: Store<StoreState>
  ) {
  }

  checkOnboardingCompleted() {
    return this.apiService.get('/v1/me/compliance_officer_profile').pipe(
      map((response) => {
        const onboardingCompleted = response?.single_opt_in_terms_completed || response?.completed
        this.onboardingCompleted$.next(onboardingCompleted ? true : false)
      })
    )
  }

  getSmsStats(start_date: string, end_date: string) {
    return this.apiService.get(`/v1/me/stats/messaging/sms?start_date=${start_date}&end_date=${end_date}`)
      .pipe(
        finalize(() => this.store.dispatch(new HideLoading('SmsMessageStats')))
      )
  }

  getLinkedSms(params: { page: number, limit: number, status: any, campaign_status?: string, start_date?: string, end_date?: string }) {
    let filterQuery = ''
    if (params.campaign_status) {
      const filterStatus = CampaignDisplayStatusToFilterStatus[params.campaign_status]
      filterQuery = `&campaign_status=${filterStatus}`
    }
    if (params.start_date && params.end_date) {
      filterQuery += `&start_date=${params.start_date}&end_date=${params.end_date}`
    }
    return this.apiService.get(`/v1/me/sms_marketing_messages?page=${params.page}&limit=${params.limit}${filterQuery}`)
  }
}
