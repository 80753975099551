const sv = {
  Quantity: 'Antal',
  Size: 'Storlek',
  Color: 'Färg',
  Material: 'Material',
  Style: 'Stil',
  Savings: 'Besparingar',
  Subtotal: 'Delsumma',
  Shipping: 'Leverans',
  Taxes: 'Moms',
  Total: 'Totalt',
  Free: 'Gratis',
  Buy: 'Köp',
  'Pay Now': 'Betala nu',
  'Take the Deal': 'Ta erbjudandet',
  'Add Now': 'Lägg till nu',
  'You will be charged immediately': 'Du kommer att debiteras omedelbart',
  'Decline Offer': 'Avslå erbjudandet',
  'No Thanks': 'Nej tack',
  '{price}': '{pris}',
  'Sold Out': 'Utsåld',
  'see more': 'se mer',
  less: 'mindre',
};
export {sv};
