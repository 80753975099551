import { Action } from '@ngrx/store'
import { CampaignActionTypes } from './campaign.action-types'
import { FortuneWheelNotification } from '../../shared/models/campaign/fortune-wheel-notification'
import { CampaignTemplateError } from './campaign.state'
import { RecentActivityNotification } from '../../shared/models/campaign/recent-activity-notification'
import { CampaignWebhook } from '../../shared/models/campaign/campaign-webhook'
import { AsyncAction } from '../../shared/models/store/async-action.model'
import { UpdatePluginConfigPayload } from '../../shared/models/campaign/update-plugin-config-payload.model'
import { LiveVisitorCountNotification } from '../../shared/models/campaign/live-visitor-count-notification.model'
import { CouponBoxNotificationParams, NewsLetterNotificationParams } from '../../shared/models/campaign/coupon-box-notification.model'
import { AggregateActivityNotification } from '../../shared/models/campaign/aggregate-user-count-notification.model'
import { CaptureRule } from '../../shared/models/campaign/capture-rules.model'
import { DisplayRule } from '../../shared/models/campaign/display-rules.model'
import { Campaign, DataSourceOutput } from '../../shared/models/campaign/campaign'

// ACTIONS
export class SetSearchQuery implements Action {
  readonly type = CampaignActionTypes.SET_SEARCH_QUERY

  constructor(public payload: string) {}
}

export class ClearError implements Action {
  readonly type = CampaignActionTypes.CLEAR_ERROR
}

export class SetTemplateError implements AsyncAction {
  readonly type = CampaignActionTypes.SET_TEMPLATE_ERROR
  readonly loading = { id: CampaignActionTypes.UPDATE_PLUGIN_CONFIG_REQUEST, active: false }

  constructor(public payload: CampaignTemplateError) {}
}

export class ResetEvents implements Action {
  readonly type = CampaignActionTypes.RESET_EVENTS
}

export class SetCaptureConfigs implements Action {
  readonly type = CampaignActionTypes.SET_CAPTURE_CONFIGS

  constructor(public payload: CaptureRule[]) {}
}

export class SetDataSources implements Action {
  readonly type = CampaignActionTypes.SET_DATA_SOURCES

  constructor(public payload: DataSourceOutput[]) {}
}

export class ClearSelectedCampaign implements Action {
  readonly type = CampaignActionTypes.CLEAR_SELECTED_CAMPAIGN
}

export class ReadCampaignRequest implements AsyncAction {
  readonly type = CampaignActionTypes.READ_CAMPAIGN_REQUEST
  readonly loading = { id: CampaignActionTypes.READ_CAMPAIGN_REQUEST, active: true }

  constructor(public payload: string) {}
}

export class ReadCampaignSuccess implements Action {
  readonly type = CampaignActionTypes.READ_CAMPAIGN_SUCCESS
  readonly loading = { id: CampaignActionTypes.READ_CAMPAIGN_REQUEST, active: false }

  constructor(public payload: Campaign) {}
}

export class ReadCampaignFailure implements Action {
  readonly type = CampaignActionTypes.READ_CAMPAIGN_FAILURE
  readonly loading = { id: CampaignActionTypes.READ_CAMPAIGN_REQUEST, active: false }

  constructor(public payload: string) {}
}

export class ReadDisplayConfigsRequest implements AsyncAction {
  readonly type = CampaignActionTypes.READ_DISPLAY_CONFIGS_REQUEST
  readonly loading = { id: CampaignActionTypes.READ_DISPLAY_CONFIGS_REQUEST, active: true }

  constructor(public payload: string) {}
}

export class ReadDisplayConfigsSuccess implements AsyncAction {
  readonly type = CampaignActionTypes.READ_DISPLAY_CONFIGS_SUCCESS
  readonly loading = { id: CampaignActionTypes.READ_DISPLAY_CONFIGS_REQUEST, active: false }

  constructor(public payload: any) {}
}

export class ReadDisplayConfigsFailure implements AsyncAction {
  readonly type = CampaignActionTypes.READ_DISPLAY_CONFIGS_FAILURE
  readonly loading = { id: CampaignActionTypes.READ_DISPLAY_CONFIGS_REQUEST, active: false }

  constructor(public payload: string) {}
}

export class UpdateDisplayConfigsRequest implements AsyncAction {
  readonly type = CampaignActionTypes.UPDATE_DISPLAY_CONFIGS_REQUEST
  readonly loading = { id: CampaignActionTypes.UPDATE_DISPLAY_CONFIGS_REQUEST, active: true }

  constructor(public payload: { campaignId: string; items: DisplayRule[] }) {}
}

export class UpdateDisplayConfigsSuccess implements AsyncAction {
  readonly type = CampaignActionTypes.UPDATE_DISPLAY_CONFIGS_SUCCESS
  readonly loading = { id: CampaignActionTypes.UPDATE_DISPLAY_CONFIGS_REQUEST, active: false }

  constructor(public payload: any) {}
}

export class UpdateDisplayConfigsFailure implements AsyncAction {
  readonly type = CampaignActionTypes.UPDATE_DISPLAY_CONFIGS_FAILURE
  readonly loading = { id: CampaignActionTypes.UPDATE_DISPLAY_CONFIGS_REQUEST, active: false }

  constructor(public payload: string) {}
}

export class ReadCampaignListRequest implements AsyncAction {
  readonly type = CampaignActionTypes.READ_CAMPAIGN_LIST_REQUEST
  readonly loading = { id: CampaignActionTypes.READ_CAMPAIGN_LIST_REQUEST, active: true }
}

export class ReadCampaignListSuccess implements AsyncAction {
  readonly type = CampaignActionTypes.READ_CAMPAIGN_LIST_SUCCESS
  readonly loading = { id: CampaignActionTypes.READ_CAMPAIGN_LIST_REQUEST, active: false }

  constructor(public payload: Campaign[]) {}
}

export class ReadCampaignListFailure implements AsyncAction {
  readonly type = CampaignActionTypes.READ_CAMPAIGN_LIST_FAILURE
  readonly loading = { id: CampaignActionTypes.READ_CAMPAIGN_LIST_REQUEST, active: false }

  constructor(public payload: string) {}
}

export class UpdateCampaignRequest implements AsyncAction {
  readonly type = CampaignActionTypes.UPDATE_CAMPAIGN_REQUEST
  readonly loading = { id: CampaignActionTypes.UPDATE_CAMPAIGN_REQUEST, active: true }

  constructor(public payload: Campaign) {}
}

export class UpdateCampaignSuccess implements AsyncAction {
  readonly type = CampaignActionTypes.UPDATE_CAMPAIGN_SUCCESS
  readonly loading = { id: CampaignActionTypes.UPDATE_CAMPAIGN_REQUEST, active: false }

  constructor(public payload: Campaign) {}
}

export class UpdateCampaignFailure implements AsyncAction {
  readonly type = CampaignActionTypes.UPDATE_CAMPAIGN_FAILURE
  readonly loading = { id: CampaignActionTypes.UPDATE_CAMPAIGN_REQUEST, active: false }

  constructor(public payload: string) {}
}

export class UpdateCampaignActiveRequest implements AsyncAction {
  readonly type = CampaignActionTypes.UPDATE_CAMPAIGN_ACTIVE_REQUEST
  readonly loading = { id: CampaignActionTypes.UPDATE_CAMPAIGN_ACTIVE_REQUEST, active: true }

  constructor(public payload: { id: string; state: string }) {}
}

export class UpdateCampaignActiveSuccess implements AsyncAction {
  readonly type = CampaignActionTypes.UPDATE_CAMPAIGN_ACTIVE_SUCCESS
  readonly loading = { id: CampaignActionTypes.UPDATE_CAMPAIGN_ACTIVE_REQUEST, active: false }

  constructor(public payload: Campaign) {}
}

export class UpdateCampaignActiveFailure implements AsyncAction {
  readonly type = CampaignActionTypes.UPDATE_CAMPAIGN_ACTIVE_FAILURE
  readonly loading = { id: CampaignActionTypes.UPDATE_CAMPAIGN_ACTIVE_REQUEST, active: false }

  constructor(public payload: any) {}
}

export class CreateCampaignRequest implements AsyncAction {
  readonly type = CampaignActionTypes.CREATE_CAMPAIGN_REQUEST
  readonly loading = { id: CampaignActionTypes.CREATE_CAMPAIGN_REQUEST, active: true }

  constructor(public payload: Campaign) {}
}

export class CreateCampaignSuccess implements AsyncAction {
  readonly type = CampaignActionTypes.CREATE_CAMPAIGN_SUCCESS
  readonly loading = { id: CampaignActionTypes.CREATE_CAMPAIGN_REQUEST, active: false }

  constructor(public payload: Campaign) {}
}

export class CreateCampaignFailure implements AsyncAction {
  readonly type = CampaignActionTypes.CREATE_CAMPAIGN_FAILURE
  readonly loading = { id: CampaignActionTypes.CREATE_CAMPAIGN_REQUEST, active: false }

  constructor(public payload: any) {}
}

export class DeleteCampaignRequest implements AsyncAction {
  readonly type = CampaignActionTypes.DELETE_CAMPAIGN_REQUEST
  readonly loading = { id: CampaignActionTypes.DELETE_CAMPAIGN_REQUEST, active: true }

  constructor(public payload: string) {}
}

export class DeleteCampaignSuccess implements AsyncAction {
  readonly type = CampaignActionTypes.DELETE_CAMPAIGN_SUCCESS
  readonly loading = { id: CampaignActionTypes.DELETE_CAMPAIGN_REQUEST, active: false }

  constructor(public payload) {}
}

export class DeleteCampaignFailure implements AsyncAction {
  readonly type = CampaignActionTypes.DELETE_CAMPAIGN_FAILURE
  readonly loading = { id: CampaignActionTypes.DELETE_CAMPAIGN_REQUEST, active: false }

  constructor(public payload: any) {}
}
export class DuplicateCampaignRequest implements AsyncAction {
  readonly type = CampaignActionTypes.DUPLICATE_CAMPAIGN_REQUEST
  readonly loading = { id: CampaignActionTypes.DUPLICATE_CAMPAIGN_REQUEST, active: true }

  constructor(public payload: { id: string; name: string }) {}
}

export class DuplicateCampaignSuccess implements AsyncAction {
  readonly type = CampaignActionTypes.DUPLICATE_CAMPAIGN_SUCCESS
  readonly loading = { id: CampaignActionTypes.DUPLICATE_CAMPAIGN_REQUEST, active: false }

  constructor(public payload: Campaign) {}
}

export class DuplicateCampaignFailure implements AsyncAction {
  readonly type = CampaignActionTypes.DUPLICATE_CAMPAIGN_FAILURE
  readonly loading = { id: CampaignActionTypes.DUPLICATE_CAMPAIGN_REQUEST, active: false }

  constructor(public payload: any) {}
}

export class UpdatePluginConfigRequest implements AsyncAction {
  readonly type = CampaignActionTypes.UPDATE_PLUGIN_CONFIG_REQUEST
  readonly loading = { id: CampaignActionTypes.UPDATE_PLUGIN_CONFIG_REQUEST, active: true }

  constructor(public payload: UpdatePluginConfigPayload) {}
}

export class UpdatePluginConfigSuccess implements AsyncAction {
  readonly type = CampaignActionTypes.UPDATE_PLUGIN_CONFIG_SUCCESS
  readonly loading = { id: CampaignActionTypes.UPDATE_PLUGIN_CONFIG_REQUEST, active: false }

  constructor(
    public payload:
      | LiveVisitorCountNotification
      | RecentActivityNotification
      | AggregateActivityNotification
      | FortuneWheelNotification
      | CouponBoxNotificationParams
      | NewsLetterNotificationParams
      | any,
  ) {}
}

export class UpdatePluginConfigFailure implements AsyncAction {
  readonly type = CampaignActionTypes.UPDATE_PLUGIN_CONFIG_FAILURE
  readonly loading = { id: CampaignActionTypes.UPDATE_PLUGIN_CONFIG_REQUEST, active: false }

  constructor(public payload: string) {}
}

export class CreateWebhookRequest implements AsyncAction {
  readonly type = CampaignActionTypes.CREATE_WEBHOOK_REQUEST
  readonly loading = { id: CampaignActionTypes.CREATE_WEBHOOK_REQUEST, active: true }

  constructor(public payload: string) {}
}

export class CreateWebhookSuccess implements AsyncAction {
  readonly type = CampaignActionTypes.CREATE_WEBHOOK_SUCCESS
  readonly loading = { id: CampaignActionTypes.CREATE_WEBHOOK_REQUEST, active: false }

  constructor(public payload: { res: CampaignWebhook; campaignId: string }) {}
}

export class CreateWebhookFailure implements AsyncAction {
  readonly type = CampaignActionTypes.CREATE_WEBHOOK_FAILURE
  readonly loading = { id: CampaignActionTypes.CREATE_WEBHOOK_REQUEST, active: false }

  constructor(public payload: string) {}
}

export class ReadEventsRequest implements AsyncAction {
  readonly type = CampaignActionTypes.READ_EVENTS_REQUEST
  readonly loading = { id: CampaignActionTypes.READ_EVENTS_REQUEST, active: true }
  noLoad: boolean

  constructor(public payload: { campaignId: string; shop: string; locale?: string }) {}
}

export class ReadEventsSuccess implements AsyncAction {
  readonly type = CampaignActionTypes.READ_EVENTS_SUCCESS
  readonly loading = { id: CampaignActionTypes.READ_EVENTS_REQUEST, active: false }

  constructor(public payload: any) {}
}

export class ReadEventsFailure implements AsyncAction {
  readonly type = CampaignActionTypes.READ_EVENTS_FAILURE
  readonly loading = { id: CampaignActionTypes.READ_EVENTS_REQUEST, active: false }

  constructor(public payload: string) {}
}

export class ReadCampaignWheelPatternsRequest implements AsyncAction {
  readonly type = CampaignActionTypes.READ_CAMPAIGN_WHEEL_PATTERNS_REQUEST
  readonly loading = { id: CampaignActionTypes.READ_CAMPAIGN_WHEEL_PATTERNS_REQUEST, active: false }
  noLoad: boolean
}

export class ReadCampaignWheelPatternsSuccess implements AsyncAction {
  readonly type = CampaignActionTypes.READ_CAMPAIGN_WHEEL_PATTERNS_SUCCESS
  readonly loading = { id: CampaignActionTypes.READ_CAMPAIGN_WHEEL_PATTERNS_REQUEST, active: false }

  constructor(public payload: any) {}
}

export class ReadCampaignWheelPatternsError implements AsyncAction {
  readonly type = CampaignActionTypes.READ_CAMPAIGN_WHEEL_PATTERNS_ERROR
  readonly loading = { id: CampaignActionTypes.READ_CAMPAIGN_WHEEL_PATTERNS_REQUEST, active: false }

  constructor(public payload: any) {}
}

export class ReadCampaignWheelThemesRequest implements AsyncAction {
  readonly type = CampaignActionTypes.READ_CAMPAIGN_WHEEL_THEMES_REQUEST
  readonly loading = { id: CampaignActionTypes.READ_CAMPAIGN_WHEEL_THEMES_REQUEST, active: false }
  noLoad: boolean
}

export class ReadCampaignWheelThemesSuccess implements AsyncAction {
  readonly type = CampaignActionTypes.READ_CAMPAIGN_WHEEL_THEMES_SUCCESS
  readonly loading = { id: CampaignActionTypes.READ_CAMPAIGN_WHEEL_THEMES_REQUEST, active: false }

  constructor(public payload: any) {}
}

export class ReadCampaignWheelThemesError implements AsyncAction {
  readonly type = CampaignActionTypes.READ_CAMPAIGN_WHEEL_THEMES_ERROR
  readonly loading = { id: CampaignActionTypes.READ_CAMPAIGN_WHEEL_THEMES_REQUEST, active: false }

  constructor(public payload: any) {}
}

export class ReadCampaignCBThemesRequest implements AsyncAction {
  readonly type = CampaignActionTypes.READ_CAMPAIGN_CB_THEMES_REQUEST
  readonly loading = { id: CampaignActionTypes.READ_CAMPAIGN_CB_THEMES_REQUEST, active: false }
  noLoad: boolean
}

export class ReadCampaignCBThemesSuccess implements AsyncAction {
  readonly type = CampaignActionTypes.READ_CAMPAIGN_CB_THEMES_SUCCESS
  readonly loading = { id: CampaignActionTypes.READ_CAMPAIGN_CB_THEMES_REQUEST, active: false }

  constructor(public payload: any) {}
}

export class ReadCampaignCBThemesError implements AsyncAction {
  readonly type = CampaignActionTypes.READ_CAMPAIGN_CB_THEMES_ERROR
  readonly loading = { id: CampaignActionTypes.READ_CAMPAIGN_CB_THEMES_REQUEST, active: false }

  constructor(public payload: any) {}
}

export class ReadCampaignNLThemesRequest implements AsyncAction {
  readonly type = CampaignActionTypes.READ_CAMPAIGN_NL_THEMES_REQUEST
  readonly loading = { id: CampaignActionTypes.READ_CAMPAIGN_NL_THEMES_REQUEST, active: false }
  noLoad: boolean
}

export class ReadCampaignNLThemesSuccess implements AsyncAction {
  readonly type = CampaignActionTypes.READ_CAMPAIGN_NL_THEMES_SUCCESS
  readonly loading = { id: CampaignActionTypes.READ_CAMPAIGN_NL_THEMES_REQUEST, active: false }

  constructor(public payload: any) {}
}

export class ReadCampaignNLThemesError implements AsyncAction {
  readonly type = CampaignActionTypes.READ_CAMPAIGN_NL_THEMES_ERROR
  readonly loading = { id: CampaignActionTypes.READ_CAMPAIGN_NL_THEMES_REQUEST, active: false }

  constructor(public payload: any) {}
}

export type CampaignActions =
  | SetSearchQuery
  | ReadCampaignWheelPatternsRequest
  | ReadCampaignWheelPatternsSuccess
  | ReadCampaignWheelPatternsError
  | ReadCampaignWheelThemesRequest
  | ReadCampaignWheelThemesSuccess
  | ReadCampaignWheelThemesError
  | ReadCampaignCBThemesRequest
  | ReadCampaignCBThemesSuccess
  | ReadCampaignCBThemesError
  | ReadCampaignNLThemesRequest
  | ReadCampaignNLThemesSuccess
  | ReadCampaignNLThemesError
  | ReadCampaignListRequest
  | ReadCampaignListSuccess
  | ReadCampaignListFailure
  | UpdateCampaignRequest
  | UpdateCampaignSuccess
  | UpdateCampaignFailure
  | UpdateCampaignActiveRequest
  | UpdateCampaignActiveSuccess
  | UpdateCampaignActiveFailure
  | ReadDisplayConfigsRequest
  | ReadDisplayConfigsSuccess
  | ReadDisplayConfigsFailure
  | UpdateDisplayConfigsRequest
  | UpdateDisplayConfigsSuccess
  | UpdateDisplayConfigsFailure
  | ClearSelectedCampaign
  | ReadCampaignRequest
  | ReadCampaignSuccess
  | ReadCampaignFailure
  | CreateCampaignRequest
  | CreateCampaignSuccess
  | CreateCampaignFailure
  | DeleteCampaignRequest
  | DeleteCampaignSuccess
  | DeleteCampaignFailure
  | DuplicateCampaignRequest
  | DuplicateCampaignSuccess
  | DuplicateCampaignFailure
  | UpdatePluginConfigRequest
  | UpdatePluginConfigSuccess
  | UpdatePluginConfigFailure
  | CreateWebhookRequest
  | CreateWebhookSuccess
  | CreateWebhookFailure
  | ReadEventsRequest
  | ReadEventsSuccess
  | ReadEventsFailure
  | ResetEvents
  | SetTemplateError
  | SetCaptureConfigs
  | SetDataSources
  | ClearError
