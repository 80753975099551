import { NgModule } from '@angular/core'
import { PlansComponent } from './plans.component'
import { AddCardModal } from './add-card-modal/add-card.modal'
import { PromoModule } from '../../shared/modules/promo/promo.module'
import { PlansRoutingModule } from './plans-routing.module'
import { CommonModule } from '@angular/common'
import { DateFormatPipe, MomentModule } from 'ngx-moment'
import { ShopifySubscribersApiService } from '../../pages/subscribers/services/shopify-subscribers-api.service'
import { ReactiveFormsModule } from '@angular/forms'
import { OneIconComponent } from '../../shared/components/one-icon/one-icon.component'
import { PlansViewAlt2Component } from '../../shared/components/plans-view/alt-2/plans.component'
import { PlansViewNew1Component } from '../../shared/components/plans-view/new-1/plans.component'
import { PlansViewNew2Component } from '../../shared/components/plans-view/new-2/plans-2.component'
import { PlansViewNew3Component } from '../../shared/components/plans-view/new-3/plans-3.component'
import { PlansVersion3View1 } from '../../shared/components/plans-view/version-3-view-1/plans-v3-1.component'
import { LogOutComponent } from '../../shared/components/log-out/log-out.component'
import { LoadingOverlayComponent } from '../../shared/components/loading-overlay/loading-overlay.component'
import { ShopifyCheckoutSuccessComponent } from '../../pages/billing/shopify-checkout-success/shopify-checkout-success.component'

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PromoModule,
    PlansRoutingModule,
    OneIconComponent,
    PlansVersion3View1,
    PlansViewNew1Component,
    PlansViewAlt2Component,
    PlansViewNew2Component,
    PlansViewNew3Component,
    LogOutComponent,
    LoadingOverlayComponent,
    ShopifyCheckoutSuccessComponent,
  ],
  declarations: [
    PlansComponent,
    AddCardModal,
  ],
  providers: [
    MomentModule,
    DateFormatPipe,
    ShopifySubscribersApiService
  ],
})
export class PlansModule {
}
