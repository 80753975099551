<div class="modal-header">
  <div class="modal-title">Connect more stores</div>
  <button class="close" (click)="dialogRef.close()">
    <span>&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>
    Looks like someone uninstalled, please install and log in once from the store into the one app. After this one-time process, the store will be accessible in the one store switcher dropdown.
  </p>
  <div class="ContactButtonList mt-0">
    <div class="ContactButton">
      <a class="pf-button sm outline black"
         target="_blank"
         href="https://apps.shopify.com/one?utm_source=onestore&utm_medium=switch_stores&utm_campaign=switch_stores_re_install_shopify_app_store_listing&utm_content=button_link&show_store_picker=1">
        <one-icon src="/app_assets/one-logo-rounded.svg"></one-icon> Re-Install
      </a>
      <span class="ContactButton__Recommended">on {{data?.shop?.shopify_store_subdomain}}</span>
    </div>
    <div class="ContactButton">
      <a class="pf-button sm outline blue"
         href="#" (click)="onLiveChatClick($event)">
        <one-icon name="chatbubbles-outline"></one-icon> Request Help
      </a>
      <span class="ContactButton__Recommended">have a question or feedback</span>
    </div>
  </div>

</div>
