<h1 mat-dialog-title class="_local-main-title">
  <img src="/app_assets/one-logo-rounded.svg" alt="">APP TO REPLACE THEM ALL
</h1>
<div class="PU-Body mb-2 mt-1">
  <h2 class="PU-Title">
    ONE's pricing is increasing for the first time
  </h2>
  <p class="PU-Text">
    Thank you for a being a loyal Shopify ONE store since {{installDate}}.
    <br>
    <br>
    Nearly 4 years as a FREE app since launching ONE, we've released thousands of themes, features,
    and innovations that help small businesses like you increase sales through ONE super app.
    <br>
    <br>
    To ensure we can continue investing in adding more apps in ONE, we are announcing our new paid plans.
  </p>


  <h6>What's Changing?</h6>
  <div class="PU-Whats-Changing">
    <ul>
      <li>
        Plans based on
        <ul>
          <li># of <span class="Tooltip-Dashed _active-campaigns-tooltip">
            active campaigns<one-active-campaign-dropdown></one-active-campaign-dropdown></span>
          </li>
          <li># of <span class="Tooltip-Dashed"
            oneTooltipPosition="bottom"
            [oneTooltip]="activeKnownCustomersTooltip">active customers</span>
          </li>
        </ul>
      </li>
    </ul>

    <ul>
      <li>
        Access to
        <ul>
          <li>campaign manager</li>
          <li>advanced features</li>
        </ul>
      </li>
    </ul>
  </div>

  <h6 *ngIf="deadLineApplicable">When is it Changing?</h6>


  <div class="text-center" *ngIf="deadLineApplicable">
    <h2 class="text-center mt-0 mb-0">
      {{date}}
    </h2>
    <div class="pf-badge xs green my-2" *ngIf="!passedDeadline; else deadlineBadge">
      <ng-container *ngIf="daysLeft === 0; else showDays">
        Last Day
      </ng-container>
      <ng-template #showDays>
        {{daysLeft | pfxPlural:'Day':'Days'}} Left
      </ng-template>
    </div>
    <ng-template #deadlineBadge>
      <div class="pf-badge xs red my-2" *ngIf="passedDeadline">
        Deadline Passed
      </div>
    </ng-template>
  </div>

  <p class="text-center" *ngIf="!passedDeadline; else deadlineText">Your active campaigns will be paused</p>

  <ng-template #deadlineText>
    <p *ngIf="deadLineApplicable" class="text-center mb-1">Your active campaigns will be paused soon (grace period).</p>
    <p class="text-center mb-1">To edit or publish new campaigns please upgrade.</p>
    <p class="text-center">You may pause or turn off campaigns freely.</p>
    <p *ngIf="deadLineApplicable" class="text-center">Contact support for custom pricing.</p>
  </ng-template>

  <div *ngIf="!passedDeadline">
    <h6>Loyalty Discounts</h6>
    <p>
      As a reward for your loyalty, take advantage of these exclusive discounts:
    </p>
    <ul>
      <ng-container *ngFor="let discount of discounts">
        <li *ngIf="discount.active" [innerHTML]="discount.text"></li>
      </ng-container>
    </ul>
  </div>
</div>

<div class="PU-Footer mb-0">
  <button class="pf-button filled blue mb-3"
    (click)="viewPlans()">
    {{ passedDeadline ? 'Upgrade Plan' : 'View Plans' }}
  </button>
  <div *ngIf="!passedDeadline">
    <a href="#" (click)="close($event)">No Thanks, I Will Pay Full Price Later</a>
  </div>

  <div class="PU-Questions" *ngIf="!passedDeadline">
    <h6 class="PU-Questions-Title">Questions?</h6>
    <div class="d-flex justify-content-around mb-3 px-4">
      <button class="pf-button outline black sm" (click)="openCalendlyLink()">Book Call</button>
      <button (click)="openFaqLink()" class="pf-button outline black sm">FAQ Page</button>
    </div>
  </div>
</div>

<ng-template #activeKnownCustomersTooltip>
  <div style="max-width: 400px; font-size: 18px; line-height: 1.2;">
    <p>Any customer with a <strong>known profile</strong> (specifically identified by either phone and/or email) and has placed an order,
    abandoned a cart, viewed a product or has explicitly consented to receive marketing.</p>
    <p>Although ONE tracks, stores, and manages all your customers including anonymous profiles
    and profiles that do not accept marketing ONE bills for only <strong>known customers who are active</strong>.</p>
  </div>
</ng-template>

<pf-loading-overlay></pf-loading-overlay>
