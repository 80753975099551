import { Component, Inject } from '@angular/core'
import { UserShopType } from '../../../../shared/models/user/user-shop-type.model'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface ImportResult {
  import: boolean
}

@Component({
  selector: 'pf-import-existing-discount-modal',
  templateUrl: './import-existing-discount-modal.component.html',
  styleUrls: ['./import-existing-discount-modal.component.scss'],
})
export class ImportExistingDiscountModalComponent {

  get shopName() {
    return this.data.shop === UserShopType.ShopifyShop ? 'Shopify' : 'BigCommerce';
  }

  constructor(
    public dialogRef: MatDialogRef<ImportExistingDiscountModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  onImportCodeClicked(): void {
    this.dialogRef.close({ import: true } as ImportResult)
  }

}
