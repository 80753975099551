import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { CouponBoxPreviewComponent } from './coupon-box-preview.component'
import { SafePipe } from '../../../../../shared/pipes/safe.pipe'

@NgModule({
  imports: [
    CommonModule,
    SafePipe,
  ],
  declarations: [
    CouponBoxPreviewComponent
  ],
  exports: [
    CouponBoxPreviewComponent
  ],
  providers: [],
})

export class CouponBoxPreviewModule {}
