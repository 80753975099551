import { Component, OnDestroy } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Logger } from '../../../../core/services/logger.service'
import { Store } from '@ngrx/store'
import { StoreState } from '../../../../store/store.state'
import { catchError, map, tap } from 'rxjs/operators'
import { HideLoading, ResetLoading, ShowLoading } from '../../../../store/loading/loading.actions'
import {
  CreateIntegrationFailure,
  CreateIntegrationSuccess,
} from '../../../../store/mail-integration/mail-integration.actions'
import { of, Subscription } from 'rxjs'
import { IntegrationsService } from '../../../../core/services/integrations.service'
import { ErrorsService } from '../../../../core/services/errors.service'
import { IntegrationType } from '../../../../shared/models/mail-integration.model'
import { LogLabel } from '../../../../shared/models/logger/log-label.model'
import { RouteHeaderUrl } from '../../../../shared/components/one-header/header-navigation.model'
import { MatDialog } from '@angular/material/dialog'

@Component({
  templateUrl: './constantcontact.component.html',
  styleUrls: ['./constantcontact.component.scss'],
})
export class ConstantContactComponent implements OnDestroy {
  errors: string[] = []
  messages: string[] = []
  isLoading = false
  subscription = new Subscription()

  constructor(
    private activatedRoute: ActivatedRoute,
    private logger: Logger,
    private store: Store<StoreState>,
    protected router: Router,
    private integrationService: IntegrationsService,
    private errorService: ErrorsService,
    private dialog: MatDialog,
  ) {
    this.subscription.add(this.activatedRoute.queryParams
      .subscribe(queryParams => {
        this.logger.log(LogLabel.Default, `queryParams: ${JSON.stringify(queryParams)}`)
        if (queryParams.code) {
          this.store.dispatch(new ShowLoading('ADD_CONSTANT_CONTACT_INTEGRATION'))

          this.subscription.add(this.integrationService
            .createIntegration({
              type: IntegrationType.ConstantContact,
              title: 'Constant Contact',
              oauth_code: queryParams.code,
            }).pipe(
              map(data => new CreateIntegrationSuccess(data)),
              tap(() => this.dialog.closeAll()),
              catchError(err => of(new CreateIntegrationFailure(this.errorService.extractError(err)))),
            ).subscribe(() => {
              this.router.navigateByUrl(`/${RouteHeaderUrl.messaging}/${RouteHeaderUrl.integrations}`)
                .finally(() => {
                  this.store.dispatch(new HideLoading('ADD_CONSTANT_CONTACT_INTEGRATION'))
                })
            }))

        }
      }))
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
    this.store.dispatch(new ResetLoading())
  }
}
