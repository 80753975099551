import { OnInit, Input, Output, EventEmitter, Component, OnDestroy } from '@angular/core'
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms'
import { ValidateCouponResponse } from '../../../models/validate-coupon-response'
import { Promo } from '../models/promo.model'
import { Subscription } from 'rxjs'
import { ApiPaymentService } from '../../../../core/services/api/api-payment.service'

@Component({template: ''})
export abstract class BasePromoComponent implements OnInit, OnDestroy {
  @Output() submitEmitter: EventEmitter<any> = new EventEmitter()
  @Output() couponApplied: EventEmitter<ValidateCouponResponse> = new EventEmitter()

  @Input() data: Promo
  couponInvalid = false
  loading = false

  private subscription = new Subscription()
  coupon: ValidateCouponResponse
  couponValidityMessage: string
  form: UntypedFormGroup

  constructor(
    public apiPaymentService: ApiPaymentService,
    public fb: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      code: [decodeURIComponent(this.data.promo_code) || '', Validators.required]
    })
    if (this.data.promo_code) {
      this.onSubmit()
    }
  }

  onSubmit() {
    if (!this.form.valid) {
      return false
    }
    this.form.disable()
    this.loading = true
    this.subscription.add(
      this.apiPaymentService.postBillingValidCoupon(this.form.value.code)
          .subscribe( (coupon: ValidateCouponResponse) => {
        if (coupon.valid) {
          this.coupon = coupon
          this.couponInvalid = false
          this.couponApplied.emit(coupon)
        } else {
          this.coupon = null
          this.couponApplied.emit(null)
          this.form.markAsPristine()
          this.couponInvalid = true
        }
        this.form.enable()
        this.loading = false
      })
    )
  }

  changeCode() {
    if (this.coupon?.field_button_label === 'Applied!') {
      this.coupon.field_button_label = ''
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }
}
