import { Campaign } from '../../shared/models/campaign/campaign'
import { GettingStartedEvent } from '../../shared/models/getting-started/getting-started-event'
import { GettingStartedStatus, ScholarshipStatus } from '../../shared/models/getting-started/getting-started-status.model'

export interface GettingStartedState {
  events: GettingStartedEvent[],
  status: GettingStartedStatus,
  completed: boolean,
  firstCampaignLaunched: boolean,
  firstCampaignSkipped?: boolean,
  firstCampaignId: string,
  campaign: Campaign,
  scholarship: ScholarshipStatus,
  pre_plans_survey_new: {
    visited: boolean,
    completed: boolean,
  },
  pre_plans_survey_returning: {
    visited: boolean,
    completed: boolean,
  }
  apps: {
    visited: boolean,
    completed: boolean,
  },
  shopify_sms_access_form: {
    visited: boolean,
    completed: boolean,
  },
  upsell_access_form: {
    visited: boolean,
    completed: boolean,
  },
}


export const initialGettingStartedState: GettingStartedState = {
  events: undefined,
  completed: undefined,
  firstCampaignLaunched: undefined,
  firstCampaignSkipped: undefined,
  firstCampaignId: undefined,
  status: undefined,
  campaign: undefined,
  scholarship: {
    plan_id: undefined,
    plan_name: undefined,
    popup_seen: undefined,
    application: {
      status: undefined,
      status_last_updated: undefined,
      submission_id: undefined,
      submission_ip: undefined,
      submission_date: undefined,
      requested_plan_id: undefined,
      requested_plan_name: undefined,
      submitter: {
        name: undefined,
        email: undefined,
        phone: undefined,
        store_role: undefined,
        country: undefined,
      }
    }
  },
  pre_plans_survey_new: {
    visited: undefined,
    completed: undefined,
  },
  pre_plans_survey_returning: {
    visited: undefined,
    completed: undefined,
  },
  apps: {
    visited: undefined,
    completed: undefined,
  },
  shopify_sms_access_form: {
    visited: undefined,
    completed: undefined,
  },
  upsell_access_form: {
    visited: undefined,
    completed: undefined,
  },
}
