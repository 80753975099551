import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { select, Store } from '@ngrx/store'
import { forkJoin, Observable } from 'rxjs'
import { StoreState } from '../../store/store.state'
import { getUserChoseInitialPlanCompleted, getUserInfo } from '../../store/user/user.selectors'
import { filter, finalize, map } from 'rxjs/operators'
import { first } from 'rxjs/operators'
import { HideLoading, ShowLoading } from '../../store/loading/loading.actions'
import { LoadingLabel } from '../../shared/models/loading/loading-label'
import { Logger } from '../services/logger.service'
import { LogLabel } from '../../shared/models/logger/log-label.model'
import _ from 'lodash'
import { UserInfo } from '../../store/user/user.state'
import { GettingStartedStatus } from '../../shared/models/getting-started/getting-started-status.model'
import { getGettingStartedStatus } from '../../store/getting-started/getting-started.selectors'
import { RouteHeaderUrl } from '../../shared/components/one-header/header-navigation.model'

@Injectable()
export class PlatformOnboardingUndoneGuard  {
  initialPaths = `/${RouteHeaderUrl.apps}`

  constructor(
    private store: Store<StoreState>,
    private logger: Logger,
    private router: Router,
  ) {
  }

  check() {
    this.store.dispatch(new ShowLoading(LoadingLabel.PlatformOnboardingUndoneGuard))
    return forkJoin([this.userInfo$, this.gettingStartedStatus$, this.userChoseInitialPlanCompleted$]).pipe(
      map(([userInfo, status, choseInitialPlan]) => {
        this.logger.log(<LogLabel>'platform-onboarding-undone-guard', 'userInfo: ', userInfo)
        const isShopifyShop = userInfo.shop?.type === 'ShopifyShop'
        const onboardingLocked = userInfo?.platform_onboarding_locked !== false
        // If plan is not chosen and onboarding status is completed - user is returning
        const userType = !_.get(status, 'completed', false) ? 'new' : 'returning'
        const isShopifyStaff = _.get(userInfo, 'shop.profile.plan_name', '') === 'staff'

        // if a new shopify user hasn't selected a plan, has many active customers & onboarding is either locked or undefined allow to go to vip onboarding
        if (!choseInitialPlan && !isShopifyStaff && userType === 'new' && isShopifyShop && onboardingLocked) {
          return true
        } else {
          // otherwise redirect them to the apps & the other guards will take care of the rest
          if (userType === 'returning') {
            this.router.navigateByUrl(this.initialPaths).then(() => {
              this.store.dispatch(new HideLoading(LoadingLabel.PlatformOnboardingUndoneGuard))
            })
          } else {
            this.router.navigate([this.initialPaths], { queryParams: { onboarding: true } }).then(() => {
              this.store.dispatch(new HideLoading(LoadingLabel.PlatformOnboardingUndoneGuard))
            })
          }
          return false
        }
      }),
      finalize(() => this.store.dispatch(new HideLoading(LoadingLabel.PlatformOnboardingUndoneGuard))),
    )
  }

  get userInfo$(): Observable<UserInfo> {
    return this.store.pipe(
      select(getUserInfo),
      filter(info => info !== undefined && !!info.created_at),
      first(),
    )
  }

  get gettingStartedStatus$(): Observable<GettingStartedStatus> {
    return this.store.pipe(
      select(getGettingStartedStatus),
      filter(next => next !== undefined),
      filter(status => !!status),
      first(),
    )
  }

  get userChoseInitialPlanCompleted$(): Observable<boolean> {
    return this.store.pipe(
      select(getUserChoseInitialPlanCompleted),
      filter(completed => completed !== undefined),
      first(),
    )
  }

  canActivate(): Observable<boolean> {
    return this.check()
  }

  canActivateChild(): Observable<boolean> {
    return this.check()
  }
}
