import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { NewsletterPreviewComponent } from './newsletter-preview.component'
import { SafePipe } from '../../../../../shared/pipes/safe.pipe'

@NgModule({
  imports: [
    CommonModule,
    SafePipe,
  ],
  declarations: [
    NewsletterPreviewComponent
  ],
  exports: [
    NewsletterPreviewComponent
  ],
  providers: [],
})

export class NewsletterPreviewModule {}
