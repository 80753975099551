import { CommonModule } from '@angular/common'
import { Component, Input, OnDestroy } from '@angular/core'
import { Subscription } from 'rxjs'
import { LoadingOverlayService } from '../../../core/services/loading-overlay.service'

@Component({
    selector: 'pf-loading-spinner',
    templateUrl: './loading-spinner.component.html',
    styleUrls: ['./loading-overlay.component.scss'],
    standalone: true,
    imports: [
      CommonModule,
    ]
})
export class LoadingSpinnerComponent implements OnDestroy {
  @Input() text: string
  title: string
  subtitle: string
  subscription = new Subscription()
  constructor(
    private overlayService: LoadingOverlayService
  ) {
    this.subscription.add(
      this.overlayService.getOverlaySubTitle().subscribe(value => this.subtitle = value)
    )
    this.subscription.add(
      this.overlayService.getOverlayTitle().subscribe(value => this.title = value)
    )
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }

}
