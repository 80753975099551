import { GettingStartedStepName } from './getting-started-step-name.model'

export const GettingStartedCampaignSchemas: { [key: string]: GettingStartedStepName[] } = {
  default: [
    GettingStartedStepName.CampaignName,
    // GettingStartedStepName.DisplayRules,
    // GettingStartedStepName.Language,
    // GettingStartedStepName.LiveVisitorCount,
    // GettingStartedStepName.RecentActivity,
    // GettingStartedStepName.CaptureRules,
    // GettingStartedStepName.CampaignConfirm,
  ],
}

export enum GettingStartedCampaignSectionName {
  Common = 'common',
  // Notification = 'notification',
  // Complete = 'complete',
}

export const GettingStartedCampaignSections: { [key in GettingStartedCampaignSectionName]: GettingStartedStepName[] } = {
  [GettingStartedCampaignSectionName.Common]: [
    GettingStartedStepName.CampaignName,
    // GettingStartedStepName.DisplayRules,
    // GettingStartedStepName.Language,
  ],
  // [GettingStartedCampaignSectionName.Notification]: [
  //   GettingStartedStepName.LiveVisitorCount,
  //   GettingStartedStepName.RecentActivity,
  //   GettingStartedStepName.CaptureRules,
  // ],
  // [GettingStartedCampaignSectionName.Complete]: [
  //   GettingStartedStepName.CampaignConfirm,
  // ],
}
