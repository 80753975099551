import {
  AfterContentChecked,
  AfterContentInit,
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input, OnInit,
  Output,
} from '@angular/core'
import { SideNavigationStatusColor } from './enums/side-navigation-status-color.enum'
import { Step } from '../steps-panel/interfaces/step.interface'
import { animate, state, style, transition, trigger } from '@angular/animations'
import { SMSStepperStep } from '../sms-onboarding/models/sms-onboarding.model'
import { SidebarStep } from '../../../core/modules/sidebar-stepper/sidebar-stepper.interface'
import { SafeLocalStorageService } from '../../../core/services/safe-local-storage.service'


@Component({
  selector: 'pf-side-navigation-bar',
  templateUrl: './side-navigation-bar.component.html',
  styleUrls: ['./side-navigation-bar.component.scss'],
  animations: [
    trigger('expand', [
      state('false', style({ width: '100px' })),
      state('true', style({ width: '200px'})),
      transition('false => true', animate(300 + 'ms ease-in')),
      transition('true => false', animate(300 + 'ms ease-out'))
    ])
  ]
})
export class SideNavigationBarComponent implements OnInit, AfterViewInit {
  @HostListener('window:resize', ['$event'])
  expandSideBarIfNeeded() {
    if (window.innerWidth < this.mobileBreakpoint) {
      this.expanded = true
    }
  }
  // Property for headline
  @Input() headline: string;

  // Property for headline
  @Input() subHeadline: string;
  @Input() tooltipText: string;

  // Props for status
  @Input() statusLabel: string;
  @Input() statusColor: SideNavigationStatusColor;

  // Props for back button
  @Input() backButtonLabel: string
  @Input() backButtonUrl: string

  @Input() steps: Step[] | SMSStepperStep[] | SidebarStep[] | any;
  @Input() stepsClickable: boolean = true;
  @Input() multiline: boolean = false;
  @Input() nonSequentialNav: boolean = false;

  @Output() sideBarPinChanged = new EventEmitter<boolean>()
  @Output() backButtonClicked = new EventEmitter<any>()

  expanded = false;
  sideBarPinned = false;
  readonly mobileBreakpoint = 600

  constructor(
    private safeLocalStorageService: SafeLocalStorageService,
  ) {
    const pinStatus = this.safeLocalStorageService.getItem('pf-side-navigation-bar')
    if (pinStatus) {
      this.sideBarPinned = pinStatus === 'true'
      this.expanded = this.sideBarPinned
    }
  }

  ngOnInit() {
    this.expandSideBarIfNeeded()
  }

  ngAfterViewInit() {
    this.sideBarPinChanged.emit(this.sideBarPinned)

  }

  toggleExpanded(val: boolean): void {
    if (window.innerWidth < this.mobileBreakpoint) {
      this.expanded = true
      return
    }
    if (this.sideBarPinned) {
      return
    }
    this.expanded = val
  }

  pinSideBar(): void {
    this.sideBarPinned = !this.sideBarPinned;
    this.sideBarPinChanged.emit(this.sideBarPinned);
    this.safeLocalStorageService.setItem('pf-side-navigation-bar', `${this.sideBarPinned}`)
  }
}
