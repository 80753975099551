import { Injectable } from '@angular/core'
import { ApiService } from './api.service'
import { Observable } from 'rxjs'
import { GettingStartedEvent } from '../../../shared/models/getting-started/getting-started-event'
import { GettingStartedStatus } from '../../../shared/models/getting-started/getting-started-status.model'


@Injectable()
export class ApiGettingStartedService {
  constructor(
    private apiService: ApiService,
  ) {
  }

  postInteractionsEvent(event: GettingStartedEvent): Observable<GettingStartedEvent[]> {
    return this.apiService.post('/v1/me/interactions/onboarding/events', { event })
  }
  postInteractionsStatus(status: GettingStartedStatus): Observable<GettingStartedStatus> {
    return this.apiService.post('/v1/me/interactions/onboarding/status', { status })
  }
}
