<div class="AddCardModal">
  <button class="Close" (click)="dialogRef.close()">
    <span>&times;</span>
  </button>

  <div class="Title">Selected Plan</div>
  <div class="Plan">
    <div class="Plan__Name">{{plan.name}}</div>
    <div class="Plan__Price">
      <span class="Plan__Amount _old"
            *ngIf="couponResponse && couponResponse.valid">
        ${{getPriceAmount(plan)}}
      </span>

      <span class="Plan__Amount"
            [class._new]="couponResponse && couponResponse.valid">
        ${{getPriceAmountWithDiscount(plan)}}
      </span>
      /
      <span class="Plan__Period">
        {{plan.interval}}
      </span>
    </div>
  </div>

  <div class="Coupon">
    <ng-container *ngIf="couponResponse && couponResponse.valid; then couponResult else couponInput"></ng-container>
    <ng-template #couponResult>
      <p class="alert alert-success">Your coupon "{{couponResponse.name}}" is applied.</p>
    </ng-template>
    <ng-template #couponInput>
      <!--<button *ngIf="!isCouponBodyVisible"-->
      <!--type="button"-->
      <!--class="Coupon__Toggler"-->
      <!--(click)="isCouponBodyVisible = true">-->
      <!--Have a coupon?-->
      <!--</button>-->
      <div *ngIf="isCouponBodyVisible"
           class="Coupon__Body">
        <div class="Title">Apply coupon</div>
        <div class="Coupon__Form">
          <input type="text"
                 class="Coupon__Control form-control"
                 [formControl]="couponControl"
                 placeholder="Coupon">
          <button type="button"
                  class="Coupon__Apply pf-button filled sm blue uppercase"
                  [disabled]="couponControl.invalid"
                  (click)="onCouponApplyClick($event)">
            Apply
          </button>
        </div>
        <p *ngIf="showCouponFailMessage" class="alert alert-danger mt-2">Your coupon "{{lastValidatedCoupon}}" is not
          valid.</p>
      </div>
    </ng-template>
  </div>

  <div class="Title">Add Card</div>
  <p class="Description">You will not be charged until your free trial ends.</p>


  <form novalidate (ngSubmit)="addCard()" [formGroup]="stripeForm">
    <fieldset [disabled]="stripeForm.disabled || stripeForm.invalid">
      <div class="InputBox">
        <div class="row">
          <div class="col-12">
            <div class="Input">
              <div class="Label">Card Number</div>
              <div class="Control" id="cardNumber"></div>
            </div>
          </div>
        </div>
        <div class="alert alert-danger mb-4 mt-2" *ngIf="errors.length">
          <div *ngFor="let error of errors">{{error}}</div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <button type="submit" class="pf-button fw filled blue">Next</button>
        </div>
      </div>
    </fieldset>
    <div class="row" *ngIf="plan.payment_gateway_plan_identifier === 'budding'">
      <div class="col-12 text-center">
        <button (click)="dialogRef.close()" type="button" class="AddLaterButton">Add card later</button>
      </div>
    </div>
  </form>


</div>

<pf-loading-overlay [filter]="{only: ['addCard', 'savePaymentToken', 'validateCoupon', 'stripeLoading']}"></pf-loading-overlay>
