import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DateAdapter, MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatCalendar } from '@angular/material/datepicker';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDaterangeService } from '../../services/mat-daterange.service';
import moment from 'moment';
@Component({
  templateUrl: './mat-daterange-header-right.component.html',
  styleUrls: ['./mat-daterange-header-right.component.scss']
})
export class MatDaterangeHeaderRightComponent <D> implements OnDestroy {
  private _destroyed = new Subject<void>()
  private subscription = new Subscription()

  constructor(
    private _calendar: MatCalendar<D>,
    private _dateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats,
    cdr: ChangeDetectorRef,
    private matDaterangeService: MatDaterangeService,
  ) {
    this.subscription.add(
      _calendar.stateChanges.pipe(takeUntil(this._destroyed)).subscribe(() => cdr.markForCheck())
    )
    this.subscription.add(
      this.matDaterangeService.monthChange$.asObservable().subscribe(change => {
        if (change.direction === 'prev') {
          this._calendar.activeDate = this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1)
        } else if (change.direction === 'next') {
          this._calendar.activeDate = this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1)
        }
      })
    )
  }

  clicked() {
    if (!moment(this._calendar.activeDate).isSame(this._calendar.maxDate, 'month')) {
      this.matDaterangeService.monthChange$.next({
        direction: 'next',
        origin: 'right'
      })
    }
  }

  get periodLabel() {
    return this._dateAdapter
      .format(this._calendar.activeDate, this._dateFormats.display.monthYearA11yLabel)
      .toLocaleUpperCase()
  }

  ngOnDestroy() {
    this._destroyed.next()
    this._destroyed.complete()
    this.subscription.unsubscribe()
  }
}
