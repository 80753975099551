import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { FilterSelectComponent } from './filter-select.component'



@NgModule({
  declarations: [FilterSelectComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  exports: [FilterSelectComponent]
})
export class FilterSelectModule { }
