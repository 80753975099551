import { AsyncValidatorFn, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms'
import { Observable } from 'rxjs'
import { debounceTime, distinctUntilChanged, first, map } from 'rxjs/operators'
import * as _ from 'lodash'
import { MarketingEmailSchedulingMethod } from '../models/campaign/marketing-email'
import moment from 'moment'

export const PFValidators = {

  dependantMinMax: (
    dependsOn: string,
    params: {[key: string]: {min: number, max: number}}
  ): ValidatorFn => {
    return function validate(control: UntypedFormControl) {
      if (!control.parent || !control.parent.value) {
        return null
      }

      const value = +control.value
      const dependant = control.root.get(dependsOn).value

      if (params[dependant]) {
        if (isNaN(value)) {
          return {
            invalid: true
          }
        }

        if (value < params[dependant].min) {
          return {
            min: true,
            minValue: params[dependant].min,
            maxValue: params[dependant].max
          }
        }

        if (value > params[dependant].max) {
          return {
            max: true,
            minValue: params[dependant].min,
            maxValue: params[dependant].max
          }
        }
      }

      return null
    }
  },

  requiredFor: <T>(typeControlName: string, valueTypes: T[]): ValidatorFn => {
    return function validate(control: UntypedFormControl) {
      if (!control.parent) {
        return null
      }
      const currentTypeValue = control.root.get(typeControlName).value as T
      if (valueTypes.includes(currentTypeValue) && (
        control.value === null ||
        !`${control.value}`.length ||
        typeof control.value === 'undefined'
      )) {
        return {
          required: true
        }
      }
      return null
    }
  },

  dependantDateRangeValidator(
    dependsOn: string,
    params: {[key: string]: {min?: Date, max?: Date}}
  ): ValidatorFn {
    return function validate(control: UntypedFormControl) {
      if (!control.parent || !control.parent.value) {
        return null
      }

      const dateValue = new Date(control.value)
      const dependant = control.root.get(dependsOn).value

      if (params[dependant]) {
        // if (dateValue instanceof Date) {
        //   return {
        //     invalid: true
        //   }
        // }

        if (!(dateValue instanceof Date) || isNaN(dateValue.getTime())) {
          return { invalidDate: true };
        }

        if (typeof params[dependant].min !== 'undefined' && dateValue < params[dependant].min) {
          const errMsg = { min: true, minDate: params[dependant].min }
          if (typeof params[dependant].max !== 'undefined') {
            errMsg['maxDate'] = params[dependant].max
          }
          return errMsg
        }

        if (typeof params[dependant].max !== 'undefined' && dateValue > params[dependant].max) {
          const errMsg = { max: true, maxDate: params[dependant].max }
          if (typeof params[dependant].min !== 'undefined') {
            errMsg['minDate'] = params[dependant].min
          }
          return errMsg
        }
      }

      return null
    }
  },

  noSpaceValidator(): ValidatorFn {
    return (control: UntypedFormControl) => {
      if (control && control.value && control.value.trim() === '') {
        return { space_only: true }
      }
      return null
    }
  },

  // maxlength for html editor text
  maxTextLength(length = 250): ValidatorFn {
    return (control: UntypedFormControl) => {
      if (control && control.value && control.value.length > length && _.unescape(control.value.replace(/<[^>]+>/g, '')).length > length) {
        return { maxlength: { requiredLength: length } }
      }
      return null
    }
  },

  maxByteSizeValidator(size): ValidatorFn {
    return (control: UntypedFormControl) => {
      if (control && control.value && new Blob([control.value]).size > size) {
        return { max_byte_size: true }
      }
      return null
    }
  },

  emailValidator(): ValidatorFn {
    return (control: UntypedFormControl) => {
      if (control && control.value) {
        const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
        if (!regex.test(control.value)) {
          return { invalid_email: true }
        }
      }
      return null
    }
  },

  urlValidatorAsync(): AsyncValidatorFn {
    return (control: UntypedFormControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => control.valueChanges
    .pipe(
      debounceTime(400),
      distinctUntilChanged(),
      map((value) => {
        if (value) {
          if (value.length < 3 || value.indexOf('.') === -1) {
            return { invalid_url: true }
          }
          const regex = /^(https?:\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,6})(\/*)$/i
          if (!regex.test(value)) {
            return { invalid_url: true }
          }
        }
        return null
      }),
      first());
  },

  urlWithQueryValidatorAsync(): AsyncValidatorFn {
    return (control: UntypedFormControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => control.valueChanges
    .pipe(
      debounceTime(400),
      distinctUntilChanged(),
      map((value) => {
        if (value) {
          if (value.length < 3 || value.indexOf('.') === -1) {
            return { invalid_url: true }
          }
          const regex = /^(\s*)(https?:\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,6})(\/*)(\?.([a-z0-9=_&])*)?(\s*)$/i
          if (!regex.test(value)) {
            return { invalid_url: true }
          }
        }
        return null
      }),
      first());
  },

  urlOrEmailValidator(): ValidatorFn {
    return (control: UntypedFormControl) => {
      if (control && control.value) {
        const regex = new RegExp('^(https?:\\/\\/)?' +  // optional protocol
                                 '(([\\da-z._-]+@))?' + // optional email name
                                 '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,6})' // domain name
                      , 'i')
        if (!regex.test(control.value)) {
          return { invalid_url: true }
        }
      }
      return null
    }
  },

  urlWitProtocolValidator(): ValidatorFn {
    return (control: UntypedFormControl) => {
      if (control && control.value) {
        const regex =  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#()?&//=]*)/igm
        if (!regex.test(control.value)) {
          return { invalid_url: true }
        }
      }
      return null
    }
  },

  noPastDateValidator(group: UntypedFormGroup) {
    if (group) {
      if (group.get('date') && (group.get('time') && !group.get('is_auto_responder').value)) {
        if (group.get('date').value && (group.get('time').value || group.get('time').value === 0)) {
          const date = moment(group.get('date').value, 'M/D/YYYY').startOf('day').hour(0).minute(0).add(group.get('time').value, 'hour')
          if (!date.isAfter(moment()) && group.get('scheduling_method').value === MarketingEmailSchedulingMethod.Fixed) {
            group.get('date').setErrors({ pastDate: true })
            group.get('time').setErrors({ pastDate: true })
            return { pastDate: true }
          } else {
            group.get('date').setErrors(null)
            group.get('time').setErrors(null)
          }
        }
      } else {
        group.get('date')?.setErrors(null)
        group.get('time')?.setErrors(null)
        return null
      }
    }
  },
}
