export enum GettingStartedGroupName {
  Install = 'install',
  Detect = 'detect',
  Profile = 'profile',
  Campaign = 'campaign',
  Support = 'support',
  PrePlansSurveyNew = 'pre_plans_survey_new',
  PrePlansSurveyReturning = 'pre_plans_survey_returning',
  Apps = 'apps',
  ShopifySmsAccessForm = 'shopify_sms_access_form',
  UpsellAccessForm = 'upsell_access_form',
}
