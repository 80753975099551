import {Injectable} from '@angular/core'
import {Observable} from 'rxjs'
import {Billing} from '../../../shared/models/billing.model'
import { SubscriptionPlan } from '../../../shared/models/subscription-plan.model'
import {ValidateCouponResponse} from '../../../shared/models/validate-coupon-response'
import { ApiService } from './api.service'
import { HttpParams } from '@angular/common/http'
import { PlansUsageLimit } from '../../../shared/components/plans-view/models/plans-version.model'

@Injectable()
export class ApiPaymentService {
  constructor(
    private apiService: ApiService,
  ) {
  }

  getBilling(): Observable<Billing> {
    return this.apiService.get('/v1/billing')
  }

  putBillingStripeToken(token: string): Observable<Billing> {
    return this.apiService.put('/v1/billing', {stripe_token: token})
  }

  getPlans(version: number = null, includes: PlansUsageLimit = PlansUsageLimit.usage_plans_limited): Observable<{ plans: SubscriptionPlan[] }> {
    let params = new HttpParams()
    if (version !== null) {
      params = params.append('version', version)
    }

    params = params.append('includes', includes)

    return this.apiService.get('/v1/plans', params)
  }

  postBillingValidCoupon(coupon: string): Observable<ValidateCouponResponse> {
    return this.apiService.post('/v1/billing/valid_coupon', {coupon})
  }

  putSubscriptions(planId: string, coupon: string, redirectUrl = window.location.href, usage_plan_id?: string): Observable<any> { // todo return type
    return this.apiService.put('/v1/subscriptions', {
      payment_gateway_plan_identifier: planId,
      coupon,
      redirect_url: redirectUrl,
      usage_plan_id: usage_plan_id
    })
  }
}
