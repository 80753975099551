import { Injectable } from '@angular/core'
import { HttpErrorResponse, HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http'
import * as _ from 'lodash'
import { UserService } from './user.service'


export enum SlackChannelName {
  Support = 'support',
  Stripe = 'stripe',
  EmailLinksVerification = 'email_links_verification',
  EnabledPopUpExtension = 'enabled_pop_up_extension',

}

@Injectable()
export class SlackSupporService {

  private httpClient: HttpClient

  constructor(
    handler: HttpBackend,
    private userService: UserService,
  ) {
    this.httpClient = new HttpClient(handler)
  }

  channels = {
    [SlackChannelName.Support]: {
      url: 'TAJUQMW9M/BLQCW2YK0/cTNrIlos1rLtjryYvKltwUuR',
      conditions: 'is asking for help via the Crisp chat, with error : ',
    },
    [SlackChannelName.Stripe]: {
      url: 'TAJUQMW9M/BLWDU1PR7/TmzF8UyY8O7V1xT4zU2qW7zA',
      conditions: 'is having a subscription error : ',
    },
    [SlackChannelName.EmailLinksVerification]: {
      url: 'TAJUQMW9M/B06K7HWMWF4/7Ht2693ZfQ79RmqWvTSosND9',
      conditions: 'need to verify the following links: ',
    },
    [SlackChannelName.EnabledPopUpExtension]: {
      url: 'TAJUQMW9M/B06K7GXBLAE/AcjTCI3qywA2kRoheg7oq8Ad',
      conditions: '',
    },
  }

  sendMessage(msg: HttpErrorResponse | string[], channel: SlackChannelName) {
    const payload = {
      text: this.getTextForSlack(channel, msg),
    }
    this.httpClient.post(
      `https://hooks.slack.com/services/${this.channels[channel].url}`,
      JSON.stringify(payload), {
        responseType: 'text',
        headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
      },
    ).subscribe()
  }

  private getTextForSlack(channel: SlackChannelName, msg: HttpErrorResponse | string[]) {
    const info = this.userService.userInfo
    const first_name = _.get(info, 'profile.first_name')
    const last_name = _.get(info, 'profile.last_name')
    switch (channel) {
      case SlackChannelName.Support:
      case SlackChannelName.Stripe:
        return `
- user: *${first_name} ${last_name}*
- id: *${info.id}*
- email: *${info.email}*
${this.channels[channel].conditions}\`\`\`${JSON.stringify(msg)}\`\`\`
`
      case SlackChannelName.EmailLinksVerification:
        return `
- user: *${first_name} ${last_name}*
- id: *${info.id}*
- email: *${info.email}*
${this.channels[channel].conditions} \`\`\`${(msg as []).join('\n')}\`\`\`
`
      case SlackChannelName.EnabledPopUpExtension:
        return `
- user: *${first_name} ${last_name}*
- id: *${info.id}*
- email: *${info.email}*
- shop: *https://${info.shop.domain}*
`
    }
  }
}
