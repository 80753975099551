import { Injectable } from '@angular/core'
import { UserService } from '../../../../core/services/user.service'
import * as _ from 'lodash'
import { SafeLocalStorageService } from '../../../../core/services/safe-local-storage.service'

@Injectable()
export class ABTestingPromoService {

  readonly LS_POPUP_SHOWN_KEY = 'ls_popup_shown'
  readonly LS_POPUP_SHOWN_COUNT_KEY = 'ls_popup_shown_count'

  constructor(
    private userService: UserService,
    private safeLocalStorageService: SafeLocalStorageService,
  ) { }

  get userEligible(): boolean {
    const info = this.userService.userInfo
    const plan = _.get(info, 'subscription.plan.payment_gateway_plan_identifier', '')
    return plan !== 'budding'
  }

  get userSeenThePopup(): boolean {
    const popupWasShown = this.safeLocalStorageService.getItem(this.LS_POPUP_SHOWN_KEY)
    return !!JSON.parse(popupWasShown)
  }

  public get popupSeenCounter() {
    return parseInt(this.safeLocalStorageService.getItem(this.LS_POPUP_SHOWN_COUNT_KEY), 10) || 0
  }

  markPopupAsSeen() {
    this.safeLocalStorageService.setItem(this.LS_POPUP_SHOWN_KEY, 'true')
    this.safeLocalStorageService.setItem(this.LS_POPUP_SHOWN_COUNT_KEY, `${this.popupSeenCounter + 1}`)
  }
}
