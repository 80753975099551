import { CommonModule } from '@angular/common'
import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { MediaGalleryModalData } from '../../models/media-gallery-models'
import { MediaGalleryComponent } from '../../media-gallery.component'
import { OneIconComponent } from '../../../one-icon/one-icon.component'

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MediaGalleryComponent,
    OneIconComponent,
  ],
  templateUrl: './media-gallery-modal.component.html',
  styleUrl: './media-gallery-modal.component.scss',
})
export class MediaGalleryModalComponent {

  constructor(
    public dialogRef: MatDialogRef<MediaGalleryModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MediaGalleryModalData,
  ) { }

  onImageSelected(img: string) {
    this.dialogRef.close(img)
  }

  close() {
    this.dialogRef.close()
  }
}
