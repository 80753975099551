import { Injectable } from '@angular/core'
import { PubSub } from './pub-sub'

interface FullScreenState {
  enabled: boolean
}

@Injectable()
export class FullScreenService extends PubSub<FullScreenState> {
  constructor() {
    super({enabled: false})
  }
}
