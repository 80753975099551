import {CampaignPluginName} from '../../../shared/models/campaign/campaign'
import {WooCommerceCoupon} from '../components/new-coupon-code/components/woo-commerce-coupon/interfaces/woo-commerce-coupon.interface'
import {CampaignLocal} from '../../popups/services/campaigns-page.service'
import { RewardsType } from './rewards-model';
import { GiftCard } from '../../gift-cards/models/gift-card';

export interface CouponsResponse {
  rewards: CouponCode[]
  stats: {
    num_awarded: number
    num_redeemed: number
    total_revenue: number
  }
  total_count: number
  total_pages: number
}

export interface CouponCode {
  id?: string,
  title: string,
  type: CouponCodeType,
  master_code: string,
  prefix_code: string,

  num_awarded: number
  num_redeemed: number
  num_redemption_rate: number

  total_revenue: number

  created_at: string
  updated_at: string

  status?: CouponStatus
  disabled_reason?: string

  reward_type: RewardsType

  data?: any
}

export interface DetailedCouponCodeStats {
  date: string
  num_awarded: number
  num_redeemed: number
  num_redemption_rate: number
  total_revenue: number

  email_automation?: {
    id: string
    name: string
    status: string
    type: string
  }

  sms_automation?: {
    id: string
    name: string
    active: boolean
    type: string
  }

  notification?: {
    id: string
    type: string
  }
  campaign?: CampaignLocal
}

export interface DetailedCouponCode extends CouponCode {
  stats: DetailedCouponCodeStats[]
}

export interface CouponCodeCampaign {
  campaign_id: string,
  campaign_name: string,
  plugin_type: CampaignPluginName.CouponBoxNotification | CampaignPluginName.FortuneWheel | CampaignPluginName.FreeShipping,
  rewards: CouponCode[],
}

// TODO: move below into reward type
export interface CouponCodeCampaignTableItem {
  id: string,
  campaign_status: string,
  name: string,
  edit_link: string,
  plugin_type: CampaignPluginName,
  rewards: CouponCode[] | ShopifyCoupon[] | BigCommerceCoupon[],
  total_stats: { num_awarded: number, num_redeemed: number, num_redemption_rate: number, total_revenue: number}[]
}

export interface ShopCouponCodeData {

  // hours and days should be removed if not used
  import?: boolean
  expires_after_enabled: boolean,
  days: number,
  hours: number,

  starts_at: string,
  ends_at: string,
  usage_limit: number,
  once_per_customer: boolean,
  /**
   * `value`: The value of the price rule. If if the value of target_type is shipping_line, then only -100 is accepted. The value must be negative.
   */
  value: number,
  /**
   * - `fixed_amount`: Applies a discount of value as a unit of the store's currency. For example, if value is -30 and the store's currency is USD, then $30 USD is deducted when the discount is applied.
   * - `percentage`: Applies a percentage discount of value. For example, if value is -30, then 30% will be deducted when the discount is applied.
   * - default: 'percentage'
   */
  value_type: CouponCodeValueType,
  /**
   * - `line_item`: The price rule applies to the cart's line items.
   * - `shipping_line`: The price rule applies to the cart's shipping lines.
   * - default: 'line_item'
   */
  target_type: CouponCodeTargetType,
  /**
   * - `all`: The price rule applies the discount to all line items in the checkout.
   * - `entitled`: The price rule applies the discount to selected entitlements only.
   */
  target_selection: CouponCodeTargetSelection,
  /**
   * A list of IDs of products that will be entitled to the discount.
   * It can be used only with target_type set to line_item and target_selection set to entitled
   */
  entitled_product_ids: number[],
  /**
   * A list of IDs of product variants that will be entitled to the discount.
   * It can be used only with target_type set to line_item and target_selection set to entitled
   */
  entitled_variant_ids: number[],
  /**
   * A list of IDs of collections whose products will be eligible to the discount.
   * It can be used only with target_type set to line_item and target_selection set to entitled.
   * It can't be used in combination with entitled_product_ids or entitled_variant_ids
   */
  entitled_collection_ids: number[],
  /**
   * - `each`: The discount is applied to each of the entitled items.
   * For example, for a price rule that takes $15 off, each entitled line item in a checkout will be discounted by $15.
   * - `across`: The calculated discount amount will be applied across the entitled items.
   * For example, for a price rule that takes $15 off, the discount will be applied across all the entitled items.
   */
  allocation_method: CouponCodeAllocationMethod,
  /**
   * - `all`: The price rule is valid for all customers.
   * - `prerequisite`: The customer must either belong to one of the customer saved searches specified by `prerequisite_saved_search_ids`,
   * or be one of the customers specified by `prerequisite_customer_ids`
   * If `prerequisite_customer_ids` is populated, then `prerequisite_saved_search_ids` must be empty.
   */
  customer_selection: CouponCodeCustomerSelection,
  prerequisite_customer_ids: string[] | [string[]],
  prerequisite_saved_search_ids: string[] | [string[]],
  /**
   * A list of IDs of shipping countries that will be entitled to the discount.
   * It can be used only with target_type set to shipping_line and target_selection set to entitled
   */
  entitled_country_ids: number[],
  /**
   * prerequisite_subtotal_range -> greater_than_or_equal_to
   */
  prerequisite_subtotal_min: number,
  /**
   * prerequisite_shipping_price_range -> less_than_or_equal_to
   */
  prerequisite_shipping_price_max: number,
  /**
   * prerequisite_quantity_range -> greater_than_or_equal_to
   */

  prerequisite_quantity_range_min: number,
  customer_get_percentage: number,

  customer_get_quantity: number,

  customer_buy_product_ids: number[],
  customer_buy_product_variant_ids: number[],
  customer_buy_collection_ids: number[],

  customer_get_product_ids: number[],
  customer_get_product_variant_ids: number[],
  customer_get_collection_ids: number[],

  usage_per_order?: number,

  /* Used for UI */
  _local?: {
    defaultTargetSelection: UICouponTargetSelection,
    buysTargetSelection: UICouponTargetSelection,
    getsTargetSelection: UICouponTargetSelection,
    setUsageLimit: boolean,
    discountType: CouponCodeValueType | CouponCodeTargetType.shipping_line,
    setEndDate: boolean,
    allocationMethod: boolean,
    buyXgetYDiscount: number,
    texts: any,
    discountValue: UIDiscountValue,
    shippingRates: boolean,
    minimumRequirements: UICouponMinimumRequirements,
  }
}

export enum CouponCodeType {
  shopify_master = 'ShopifyMasterCoupon',
  shopify_unique = 'ShopifyUniqueCoupon',
  big_commerce_master = 'BigCommerceMasterCoupon',
  big_commerce_unique = 'BigCommerceUniqueCoupon',
  woo_commerce_master = 'WooCommerceMasterCoupon',
  woo_commerce_unique = 'WooCommerceUniqueCoupon',
  manual_master = 'ManualMasterCoupon',
  manual_unique = 'ManualUniqueCoupon',
  shopify_gift_card = 'ShopifyShop::GiftCard',
  shopify_gift_card_unique = 'ShopifyShop::GiftCardUnique'
}

export enum RewardCodeTypeName {
  Unique = 'Unique',
  Generic = 'Generic',
  GenericGiftCard = 'Generic Gift Card',
}

export enum CouponCodeValueType {
  percentage = 'percentage',
  fixed_amount = 'fixed_amount',
  buy_x_get_y = 'buy_x_get_y',
}

export enum CouponCodeTargetType {
  line_item = 'line_item',
  shipping_line = 'shipping_line',
}

export enum CouponCodeTargetSelection {
  all = 'all',
  entitled = 'entitled',
}

export enum CouponCodeAllocationMethod {
  each = 'each',
  across = 'across',
}

export enum CouponCodeCustomerSelection {
  all = 'all',
  prerequisite = 'prerequisite',
}

export interface ShopifyCoupon extends CouponCode {
  data: ShopCouponCodeData,
}

export interface ShopifyMasterCoupon extends ShopifyCoupon {
  type: CouponCodeType.shopify_master
}

export interface ShopifyUniqueCoupon extends ShopifyCoupon {
  type: CouponCodeType.shopify_unique
}

export interface BigCommerceCoupon extends CouponCode {
  data: ShopCouponCodeData,
}

export interface BigCommerceMasterCoupon extends ShopifyCoupon {
  type: CouponCodeType.big_commerce_master
}

export interface BigCommerceUniqueCoupon extends ShopifyCoupon {
  type: CouponCodeType.big_commerce_unique
}

export interface GiftCardUniqueCoupon extends GiftCard {
  type: CouponCodeType.shopify_gift_card_unique
}
export interface GiftCardCoupon extends GiftCard {
  type: CouponCodeType.shopify_gift_card
}

export type CommonShopCoupon = ShopifyCoupon | BigCommerceCoupon | WooCommerceCoupon;

export interface ManualMasterCoupon extends CouponCode {
  type: CouponCodeType.manual_master
}

export interface ManualUniqueCoupon extends CouponCode {
  type: CouponCodeType.manual_unique
}

/* Local models that are used for UI purpose */
export enum UICouponTargetSelection {
  all = 'all',
  entitled_collections = 'entitled_collections',
  entitled_products = 'entitled_products',
}

export interface UICouponCodeSummary {
  schedule: {
    start: string,
    end: string,
  },
  codeType: string,
  code: string,
  randomString?: string,
  discount: string,
  discoutnRelatedTo: string,
  limit: string,
}

export interface ShopifyCouponListItem {
  created_at: string,
  title: string,
  id: string,
  code: string,
  discount: string,
  index: number,
  active: string,
  _originalCoupon: CouponCode,
  _type: string,
}

export enum CouponCodeState {
  active = 'active',
  scheduled = 'scheduled',
  expired = 'expired',
  archived = 'archived',
  disabled = 'disabled',
}

export enum CouponStatus {
  all = 'all',
  allExceptArchived = 'all_except_archived',
  archived = 'archived',
  active = 'active',
  disabled = 'disabled',
  expired = 'expired',
  scheduled = 'scheduled',
}

export enum UICouponMinimumRequirements {
  none = 'none',
  price = 'price',
  quantity = 'quantity',
}

export enum UICouponCodeTypeName {
  ShopifyMasterCoupon = 'Generic Code',
  ShopifyUniqueCoupon = 'Unique Code',
  BigCommerceMasterCoupon = 'Generic Code',
  BigCommerceUniqueCoupon = 'Unique Code',
  WooCommerceMasterCoupon = 'Generic Code',
  WooCommerceUniqueCoupon = 'Unique Code',
  ManualMasterCoupon = 'Manual Code',
  ManualUniqueCoupon = 'Manual Code',
  ShopifyUniqueGiftCard = 'Unique Code',
}

export enum UIProductSelectorType {
  default = 'default',
  buys = 'buys',
  gets = 'gets',
  bigCommerce = 'bigCommerce',
  wooCommerceInclude = 'wooCommerceInclude',
  wooCommerceExclude = 'wooCommerceExclude',
}

export enum UIDiscountValue {
  percentage = 'percentage',
  free = 'free',
}

export interface TargetSelectionChangeEvent {
  targetSelection: UICouponTargetSelection,
  selectorType: UIProductSelectorType
}

export enum CouponSourceType {
  one = 'one',
  shopify = 'shopify'
}
