import { NgModule } from '@angular/core'
import { PhoneInputComponent } from './components/phone-input/phone-input.component'
import { CountryCodesComponent } from './components/country-codes/country-codes.component'
import { NgSelectModule } from '@ng-select/ng-select'
import { PhoneModalComponent } from './components/phone.modal/phone.modal.component'
import { NgHcaptchaModule } from 'ng-hcaptcha'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { LoadingOverlayComponent } from '../../components/loading-overlay/loading-overlay.component'


@NgModule({
  imports: [
    CommonModule,
    NgSelectModule,
    NgHcaptchaModule,
    ReactiveFormsModule,
    FormsModule,
    LoadingOverlayComponent
  ],
  declarations: [
    PhoneInputComponent,
    CountryCodesComponent,
    PhoneModalComponent
  ],
  exports: [
    PhoneInputComponent,
    CountryCodesComponent,
    PhoneModalComponent
  ],
})
export class PhoneInputModule { }
