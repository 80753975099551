<div class="_close" (click)="close()">
  <one-icon name="close-outline"></one-icon>
</div>
<one-media-gallery
  [modalMode]="true"
  [modalPath]="data.path"
  [namePrefix]="data.namePrefix"
  [currentImage]="data.currentImage"
  (imageSelected)="onImageSelected($event)"
></one-media-gallery>
