import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { OneIconComponent } from '../../../../components/one-icon/one-icon.component'
import { CommonModule } from '@angular/common'
import { RouteHeaderUrl } from '../../../../components/one-header/header-navigation.model'
import { CampaignCard } from '../../models/campaign-cards.model'
import { SegmentAnalyticsService } from '../../../../services/segment-analytics.service'
import { Router } from '@angular/router'

@Component({
  selector: 'one-quick-setup-card',
  templateUrl: './quick-setup-card.component.html',
  styleUrls: ['./quick-setup-card.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    OneIconComponent
  ]
})

export class QuickSetupCardComponent implements OnInit {
  @Input({ required: true }) card: CampaignCard
  @Input() viewOnlyCard = false
  @Output() openCard = new EventEmitter<CampaignCard>()
  @Output() openCrisp = new EventEmitter<CampaignCard>()
  cardButtonText = ''

    constructor(
      private segmentAnalyticsService: SegmentAnalyticsService,
      private router: Router
    ) { }

    ngOnInit() {
      this.cardButtonText =  this.viewOnlyCard ? 'View' : 'Setup'
    }

  onCardClick(card: CampaignCard) {
    this.openCard.emit(card)
  }

  navigateToTutorials(card: CampaignCard, $event: MouseEvent) {
    $event.stopPropagation()
    const video = card.videoTutorial.currentVideo
    if (video) {
      this.trackTutorialClick(card)
      this.router.navigate([RouteHeaderUrl.tutorials, video.parentID, video.id || video.cloudflare.id])
    } else {
      console.error('No video found for this card', card)
    }
  }

  trackTutorialClick(preset: CampaignCard) {
    this.segmentAnalyticsService.track(`Clicked Watch Tutorials btn - Start Here - ${preset?.videoTutorial?.segmentEvent}`)
    // if (preset?.videoTutorial?.tutorialSegment === VideoTutorialSegment.Email) {
    //   this.segmentAnalyticsService.track(`Clicked Email Overview Tutorial button`)
    // }
  }

  onContactUsClick(card: any) {
    this.openCrisp.emit(card)
  }
}
