import { Component, Injectable } from '@angular/core'
import { Location } from '@angular/common'
import { MultiSaveDetectorService } from './multi-save-detector.service'
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router'
import { ConfirmModalComponent, ConfirmModalConfig } from '../../modals/confirm.modal/confirm.modal.component'
import { OneHeaderService } from './one-header.service'
import { Observable, Subject, Subscription } from 'rxjs'
import { MatDialog } from '@angular/material/dialog'

@Injectable()
export class MultiSaveGuard {
  private subscription: Subscription

  constructor(
    private dialog: MatDialog,
    private multiSaveDetectorService: MultiSaveDetectorService,
    private oneHeaderService: OneHeaderService,
    private readonly location: Location,
    private readonly router: Router,
  ) {}

  private _warningDialog() {
    return this.dialog.open(ConfirmModalComponent, {
      width: '370px',
      data: {
        title: 'Page has unsaved changes',
        text: 'Are you sure you want exit without saving?',
        headerClass: 'text-left',
        contentClass: 'text-left',
        footerClass: 'mb-0 justify-content-start',
        cancelButton: {
          text: `Continue without saving`,
          classes: 'pf-button filled red sm'
        },
        acceptButton: {
          text: 'Stay',
          classes: 'pf-button filled green sm'
        }
      } as ConfirmModalConfig,
    })
  }

  canDeactivate(
    component: Component,
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
  ): boolean | Observable<boolean> {
    const needsSaving = this.multiSaveDetectorService.hasUnsavedForms()
    if (!needsSaving) {
      return true
    } else {
      const subject = new Subject<boolean>()
      // Workaround to prevent url changes when clicking browser's back btn multiple times
      const currentUrlTree = this.router.createUrlTree([], _route);
      const currentUrl = currentUrlTree.toString();
      this.location.go(currentUrl)

      const dialogRef = this._warningDialog()
      this.subscription = new Subscription()
      this.subscription.add(
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this._completeSubj(subject, false)
            return false
          } else if (result === null || result === undefined) {
            this._completeSubj(subject, false)
            return false
          } else if (result === false) {
            this.multiSaveDetectorService.discardChanges()
            this._completeSubj(subject)
          }
        })
      )
      return subject.asObservable()
    }
  }

  private _completeSubj(subject: Subject<boolean>, val: boolean = true) {
    setTimeout(() => {
      subject.next(val)
      subject.complete()
      this.subscription.unsubscribe()
    }, 500)
  }
}
