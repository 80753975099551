<ng-select
  [(ngModel)]="selectedCountry"
  [items]="countries"
  [clearable]="false"
  [disabled]="disabled"
  [class.disabled]="disabled"
  [searchFn]="customSearchFn"
  (change)="onCountrySelect()"
  class="CountryCode"
  appendTo="body"
>
  <ng-template ng-option-tmp let-item="item">
    <div [ngClass]="'fflag-' + item.iso2" class="fflag ff-sm ml-2 mr-2"></div>
    {{item.name}}
  </ng-template>

  <ng-template ng-label-tmp let-item="item">
    <div class="_local-dial-code">
      +{{item.dialCode}}
    </div>
  </ng-template>
</ng-select>
