import { Component, Input, OnInit } from '@angular/core'
import { BrandingService } from '../../../../core/services/branding.service'
import { UserService } from '../../../../core/services/user.service'
import { UpsellStats } from '../../models/upsell-page.models'

@Component({
  selector: 'pf-upsell-cards',
  templateUrl: './upsell-cards.component.html',
  styleUrls: ['./upsell-cards.component.scss']
})

export class UpsellCardsComponent implements OnInit {
  private _stats: UpsellStats | any
  @Input() set stats(value: UpsellStats | any) {
    this._stats = value
  }
  get stats() {
    return this._stats
  }
  brandingName = this.brandingService.getBrandingData().name
  isMobile = /iPhone|iPod|Android/i.test(navigator.userAgent)
  userCurrency = this.userService.userInfo?.shop?.profile?.currency || 'USD'

  constructor(
    private brandingService: BrandingService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
  }

}
