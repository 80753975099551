<section class="main" [class.sidebar-pinned]="isSideBarPinned">

  <div class="main-sidenav">
    <pf-side-navigation-bar
      [headline]="headline"
      [subHeadline]="subHeadline"
      [statusLabel]="statusLabel"
      [statusColor]="statusColor"
      [backButtonLabel]="backButtonLabel"
      [backButtonUrl]="backButtonUrl"
      [steps]="steps$ | async"
      (sideBarPinChanged)="isSideBarPinned = $event"
    ></pf-side-navigation-bar>
  </div>

  <div class="main-content">
    <div class="main-content-wrapper">
      <div class="main-content-body">
        <router-outlet></router-outlet>
      </div>
    </div>


    <div class="main-content-footerbar">
      <pf-footerbar-navigation></pf-footerbar-navigation>
    </div>
  </div>

</section>
<!-- <one-video-tutorial-trigger-button
  videoId="WZYrIeaZpcY"
></one-video-tutorial-trigger-button> -->
