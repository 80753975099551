<section>
  <ng-container *ngIf="shopType === userShopType.WooCommerceShop; else ShopifyAndDefaultCoupons">
    <pf-woo-commerce-coupon
      [isOverlay]="isOverlay"
      (closeOverlay)="closeOverlay.emit($event)">
    </pf-woo-commerce-coupon>
  </ng-container>
  <ng-template #ShopifyAndDefaultCoupons>
    <div class="L-CouponCode">
      <div class="L-CouponCode-Content Card">
        <h4 *ngIf="!isEditMode" class="L-CouponCode-Content-Title">Create New Coupon</h4>

        <form
          class="CouponCode-Form"
          [class._is-edit]="isEditMode"
          [formGroup]="formGroup"
          pfScrollInvalidInput
          [labelOffset]="100"
          #formRef>
          <h5 class="CouponCode-Form-Subtitle"
          >
            Title
            <span class="Tooltip mt-1 mr-auto"
                  matTooltip="Title is for your own reference. It can contain any value."
                  matTooltipClass="Tooltip-Box" matTooltipPosition="above"
            ></span>
          </h5>

          <div class="PluginConfig-Line">
            <input type="text" class="form-control" formControlName="title"
                   placeholder="e.g. 30% OFF, Free Shipping">
            <div class="invalid-feedback">
              Please provide a Title
            </div>
          </div>

        <h5 class="CouponCode-Form-Subtitle">Code</h5>
        <div class="CouponCode-Config">
          <div *ngIf="!isEditMode">
            <label>Type</label>
            <div class="CouponCode-Radio">
              <!--template for Shopify-->
              <ng-container *ngIf="shopType === userShopType.ShopifyShop">
                <div *ngIf="!onlyStaticCodeAllowed" class="CouponCode-Radio-Item _is-recommended">
                  <input type="radio" formControlName="type" id="{{'radio' + couponCodeType.shopify_unique}}"
                         [value]="couponCodeType.shopify_unique">
                  <label for="{{'radio' + couponCodeType.shopify_unique}}">
                    <span class="checkmark"></span>
                    Unique Code
                    <span class="_recommended-tip">Recommended</span>
                    <small>Custom One-Time Use Codes</small>
                  </label>
                </div>
                <div class="CouponCode-Radio-Item">
                  <input type="radio" formControlName="type" id="{{'radio' + couponCodeType.shopify_master}}"
                         [value]="couponCodeType.shopify_master">
                  <label for="{{'radio' + couponCodeType.shopify_master}}">
                    <span class="checkmark"></span>
                    Generic Code
                    <small>Same Code for All Subscribers</small>
                  </label>
                </div>
              </ng-container>

              <!--template for BigCommerce-->
              <ng-container *ngIf="shopType === userShopType.BigCommerceShop">
                <div *ngIf="!onlyStaticCodeAllowed" class="CouponCode-Radio-Item _is-recommended">
                  <input type="radio" formControlName="type" id="{{'radio' + couponCodeType.big_commerce_unique}}"
                         [value]="couponCodeType.big_commerce_unique">
                  <label for="{{'radio' + couponCodeType.big_commerce_unique}}">
                    <span class="checkmark"></span>
                    Unique Code
                    <span class="_recommended-tip">Recommended</span>
                    <small>Custom One-Time Use Codes</small>
                  </label>
                </div>
                <div class="CouponCode-Radio-Item">
                  <input type="radio" formControlName="type" id="{{'radio' + couponCodeType.big_commerce_master}}"
                         [value]="couponCodeType.big_commerce_master">
                  <label for="{{'radio' + couponCodeType.big_commerce_master}}">
                    <span class="checkmark"></span>
                    Generic Coupon
                    <small>Same Code for All Subscribers</small>
                  </label>
                </div>
              </ng-container>

              <div class="CouponCode-Radio-Item" *ngIf="![userShopType.ShopifyShop, userShopType.BigCommerceShop].includes(shopType)">
                <input type="radio" formControlName="type" id="{{'radio' + couponCodeType.manual_master}}"
                       [value]="couponCodeType.manual_master">
                <label for="{{'radio' + couponCodeType.manual_master}}">
                  Manual Coupon
                  <small>Same Code for All Subscribers</small>
                </label>
              </div>
            </div>
          </div>

          <div class="PluginConfig-Line" *ngIf="isEditMode">
            <label>Type</label>
            <div class="CouponCode-Radio _is-edit">
              <div class="CouponCode-Radio-Item">
                <ng-container [ngSwitch]="true" *ngIf="formGroup.getRawValue().type as Type">
                  <label *ngSwitchCase="[couponCodeType.shopify_master, couponCodeType.big_commerce_master].includes(Type)">
                    Generic Coupon
                  </label>
                  <label *ngSwitchCase="Type === couponCodeType.manual_master">
                    Manual Coupon
                  </label>
                  <label *ngSwitchCase="[couponCodeType.shopify_unique, couponCodeType.big_commerce_unique].includes(Type)">
                    Unique Coupon
                  </label>
                  <label *ngSwitchCase="Type === couponCodeType.manual_unique">
                    Manual Coupon
                  </label>
                </ng-container>
              </div>
            </div>
          </div>

          <ng-container [ngSwitch]="true" *ngIf="formGroup.getRawValue().type as Type">

            <div *ngSwitchCase="[couponCodeType.shopify_master, couponCodeType.big_commerce_master].includes(Type)" class="PluginConfig-Line">
              <label class="d-flex justify-content-between">
                Coupon Code
                <span class="Tooltip mt-1 ml-1 mr-auto"
                      matTooltip="All customers will enter this discount code at checkout."
                      matTooltipClass="Tooltip-Box" matTooltipPosition="above"
                ></span>
              </label>
              <div class="CouponCode-Generator">
                <input type="text" class="form-control _local-code-input" formControlName="master_code" maxlength="255"
                       placeholder="Enter coupon code here"
                       #masterCodeInput>
                <a *ngIf="!isEditMode" href="#" (click)="generateCode($event, 'master_code')">Generate Code</a>
                <ng-container *ngIf="formGroup.get('master_code').errors; let errors">
                  <div class="invalid-feedback" *ngIf="errors.required">
                    Please provide a Code
                  </div>
                  <div class="invalid-feedback" *ngIf="errors.maxlength">
                    Code must be less than 50 characters
                  </div>
                </ng-container>
              </div>
            </div>

            <ng-container *ngSwitchCase="[couponCodeType.shopify_unique, couponCodeType.big_commerce_unique].includes(Type)">
              <div class="PluginConfig-Line">
                <label class="d-flex justify-content-between">
                  Code Prefix (Optional)
                  <span class="Tooltip mt-1 ml-1 mr-auto"
                        matTooltip="Add a prefix to the customer’s uniquely generated code (8 random letters and numbers). "
                        matTooltipClass="Tooltip-Box" matTooltipPosition="above"
                  ></span>
                </label>
                <div class="CouponCode-Prefix" >
                  <input type="text" class="form-control" formControlName="prefix_code" maxlength="16"
                         placeholder="e.g. WELCOME-, 20OFF-">
                  <div class="CouponCode-Prefix-Code-Example">
                    <span *ngIf="summary">{{summary.randomString}}</span>
                  </div>
                </div>
                <small class="CouponCode-Tip">(Keep it short, memorable, and sweet.)</small>
              </div>

              <div class="PluginConfig-Line" formGroupName="data">
                <label class="d-flex justify-content-between">
                  Code Length
                </label>
                <div>
                  <input type="number" min="6" max="16" class="form-control _small mt-3" [positiveIntegers]="true"
                         (click)="onNumberInputClick($event)"
                         (focusout)="onCodeLengthChange($event)"
                         (focus)="touchControl('coupon_code_length')" formControlName="coupon_code_length">
                </div>
                <small class="CouponCode-Tip">
                  (Up to {{formGroup.getRawValue().data.coupon_code_length}} random letters and numbers.)
                </small>
                <div class="invalid-feedback">
                  Please use a coupon code with length between 6 and 16 characters to minimize the chance that two customers receive the same coupon code.
                </div>
              </div>

            </ng-container>
            <!-- <ng-container *ngIf="!isEditMode">
              <div class="CouponCode-Manual-Code-Separator">
                <span>OR</span>
              </div>
              <div class="CouponCode-Radio-Item">
                <input type="radio" formControlName="type" id="{{'radio' + couponCodeType.manual_master}}"
                       [value]="couponCodeType.manual_master">
                <label for="{{'radio' + couponCodeType.manual_master}}">
                  <span class="checkmark"></span>
                  Use Manual Code
                </label>
              </div>
            </ng-container> -->
            <div *ngSwitchCase="Type === couponCodeType.manual_master" class="PluginConfig-Line">
              <label class="d-flex justify-content-between">
                Coupon Code
                <span class="Tooltip mt-1 ml-1 mr-auto"
                      matTooltip="{{isEditMode ? 'All customers will enter this discount code at checkout.' :
                      'Paste your existing code from other platforms.'}}"
                      matTooltipClass="Tooltip-Box" matTooltipPosition="above"
                ></span>
              </label>
              <div class="CouponCode-Generator">
                <input type="text" class="form-control _local-code-input" formControlName="master_code" maxlength="255"
                       placeholder="Enter coupon code here">
                <a *ngIf="!isEditMode" href="#" (click)="generateCode($event, 'master_code')">Generate Code</a>
                <ng-container *ngIf="formGroup.get('master_code').errors; let errors">
                  <div class="invalid-feedback" *ngIf="errors.required">
                    Please provide a Code
                  </div>
                  <div class="invalid-feedback" *ngIf="errors.maxlength">
                    Code must be less than 50 characters
                  </div>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </div>

          <!-- [data] -->
          <ng-container formGroupName="data">
            <ng-container *ngIf="formGroup.getRawValue().type === couponCodeType.shopify_master || formGroup.getRawValue().type === couponCodeType.big_commerce_master">
              <h5 class="CouponCode-Form-Subtitle">Usage Limits</h5>

              <div class="PluginConfig-Line py-0">
                <label class="PluginConfig-Control-Label _black _with-switch-fw" formGroupName="_local">
                  Limit number of times this discount can be used in total
                  <pf-switch-box
                    [labelInside]="true"
                    labelOff="Off"
                    formControlName="setUsageLimit">
                  </pf-switch-box>
                </label>

                <div class="form-group mt-3" [class.is-hidden]="!formGroup.getRawValue().data._local.setUsageLimit">
                  <div class="input-group">
                    <input type="number" min="0" max="99999999" step="1" formControlName="usage_limit" class="form-control"
                          (focus)="touchControl('usage_limit')"
                           (click)="onNumberInputClick($event)"
                           (focusout)="onValueChange('usage_limit', $event, 99999999, 0)">
                    <div class="invalid-feedback">
                      Please provide a value between 0 and 99999999
                    </div>
                  </div>
                </div>
              </div>

              <div class="PluginConfig-Line py-0">
                <label class="PluginConfig-Control-Label _black _with-switch-fw">
                  Limit to one use per customer
                  <pf-switch-box
                    [labelInside]="true"
                    labelOff="Off"
                    formControlName="once_per_customer">
                  </pf-switch-box>
                </label>
              </div>
            </ng-container>
            <!-- Behavior -->
            <div [class.is-hidden]="formGroup.getRawValue().type !== couponCodeType.shopify_unique && formGroup.getRawValue().type !== couponCodeType.big_commerce_unique">
              <h5 class="CouponCode-Form-Subtitle">Behavior</h5>

              <div class="PluginConfig-Line">
                <label class="PluginConfig-Control-Label _black _with-switch-fw">
                  Expires after a certain number of years/days/hours/minutes
                  <pf-switch-box
                    [labelInside]="true"
                    labelOff="Off"
                    formControlName="expires_after_enabled">
                  </pf-switch-box>
                </label>

                <!--
                <div class="text-danger Expires-validity"
                     [class._invalid]="formGroup.controls['data'].hasError('expiresError')">
                  All fields can't be zero
                </div>
                -->

                <div class="form-inline" [class.is-hidden]="!formGroup.getRawValue().data.expires_after_enabled">
                  <label class="mr-3">Expires After</label>
                  <ng-container *ngIf="!isEditMode">
                    <div class="config-control _double _local-behavior-options">
                      <input
                        [class.is-invalid]="
                          formGroup.get('data.expires_after_years').errors ||
                          formGroup.get('data.expires_after_days').errors ||
                          formGroup.get('data.expires_after_hours').errors ||
                          formGroup.get('data.expires_after_minutes').errors"
                        type="number"
                        #timeInput
                        [value]="selectedTime"
                        step="1"
                        min="1"
                        placeholder="0"
                        class="form-control  _medium"
                        (focus)="onTimeFocus(selectedUnit)"
                        (click)="onNumberInputClick($event)"
                        (focusout)="onTimeChange(selectedUnit, $any($event.target).value)">
                        <mat-form-field class="pf-form-field ml-2">
                          <mat-select [(value)]="selectedUnit" class="form-control _large" (selectionChange)="onUnitChange(selectedUnit)">
                            <mat-option *ngFor="let option of unitOptions" [value]="option.value">
                              {{option.name}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                    </div>
                    <br>
                    <div class="error-text">
                      <small class="text-danger mb-1" *ngIf="formGroup.get('data.expires_after_years').errors?.max">
                        Expiration value over limit (max 2 years).
                      </small>
                      <small class="text-danger mb-1" *ngIf="formGroup.get('data.expires_after_years').errors?.min">
                        Expiration value under limit (min 8 minutes).
                      </small>
                      <small class="text-danger mb-1" *ngIf="formGroup.get('data.expires_after_days').errors?.max">
                        Expiration value over limit (max 2 years).
                      </small>
                      <small class="text-danger mb-1" *ngIf="formGroup.get('data.expires_after_days').errors?.min">
                        Expiration value under limit (min 8 minutes).
                      </small>
                      <small class="text-danger mb-1" *ngIf="formGroup.get('data.expires_after_hours').errors?.max">
                        Expiration value over limit (max 2 years).
                      </small>
                      <small class="text-danger mb-1" *ngIf="formGroup.get('data.expires_after_hours').errors?.min">
                        Expiration value under limit (min 8 minutes).
                      </small>
                      <small class="text-danger mb-1" *ngIf="formGroup.get('data.expires_after_minutes').errors?.max">
                        Expiration value over limit (max 2 years).
                      </small>
                      <small class="text-danger mb-1" *ngIf="formGroup.get('data.expires_after_minutes').errors?.min">
                        Expiration value under limit (min 8 minutes).
                      </small>
                      <small class="text-danger mb-1" *ngIf="formGroup.get('data.expires_after_minutes').errors?.empty">
                        Please provide a value greater than 0.
                      </small>
                    </div>

                    <input type="hidden" class="form-control" formControlName="expires_after_years">
                    <input type="hidden" class="form-control" formControlName="expires_after_days">
                    <input type="hidden" class="form-control" formControlName="expires_after_hours">
                    <input type="hidden" class="form-control" formControlName="expires_after_minutes">
                  </ng-container>

                  <ng-container *ngIf="isEditMode">
                    <div [hidden]="showOldStyle" class="config-control _double">
                      <input type="number" [value]="editValue" class="form-control _medium" disabled>
                      <mat-form-field class="pf-form-field ml-2">
                        <mat-select [(value)]="editOption" class="form-control _large disabled" disabled="true">
                          <mat-option *ngFor="let option of unitOptions" [value]="option.value">
                            {{option.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div [hidden]="!showOldStyle" class="form-inline">
                      <input type="number" class="form-control _small" formControlName="expires_after_years">
                      <label class="ml-3 mr-3">years</label>
                      <input type="number" class="form-control _small" formControlName="expires_after_days">
                      <label class="ml-3 mr-3">days</label>
                      <input type="number" class="form-control _small" formControlName="expires_after_hours">
                      <label class="ml-3 mr-3">hours</label>
                      <input type="number" class="form-control _small" formControlName="expires_after_minutes">
                      <label class="ml-3">minutes</label>
                    </div>
                  </ng-container>
                </div>

              </div>
            </div>
          </ng-container>
          <!-- [ end of data] -->

          <div
            [class.is-hidden]="formGroup.getRawValue().type === couponCodeType.manual_master || formGroup.getRawValue().type === couponCodeType.manual_unique">
            <h5 class="CouponCode-Form-Subtitle">Discount Rules</h5>
            <!-- [data] -->
            <div class="CouponCode-Config">
            <ng-container formGroupName="data">
              <input type="hidden" formControlName="value_type">
              <input type="hidden" formControlName="target_type">
              <div class="PluginConfig-Line" formGroupName="_local">
                <label>Discount Type</label>
                <select class="form-control" #discountType formControlName="discountType"
                        (change)="updateValueType($event)">
                  <option [value]="couponCodeValueType.fixed_amount">Fixed Amount</option>
                  <option [value]="couponCodeValueType.percentage">Percentage</option>
                  <option [value]="couponCodeTargetType.shipping_line">Free Shipping</option>
                  <option [value]="couponCodeValueType.buy_x_get_y" *ngIf="this.shopType !== userShopType.BigCommerceShop">Buy X get Y Free</option>
                </select>
              </div>

              <ng-container *ngIf="discountType.value !== couponCodeTargetType.shipping_line">
                <ng-container [ngSwitch]="discountType.value">
                  <div class="PluginConfig-Line" *ngSwitchCase="couponCodeValueType.fixed_amount">
                    <label>Discount Value</label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">{{currency}}</span>
                      </div>
                      <input type="number" min="0" class="form-control" formControlName="value">
                      <div class="invalid-feedback">
                        Please provide a value greater than 0
                      </div>
                    </div>
                  </div>
                  <div class="PluginConfig-Line" *ngSwitchCase="couponCodeValueType.percentage">
                    <label>Discount Percentage</label>
                    <div class="input-group">
                      <input type="number" min="0" max="100" class="form-control border-right-0" formControlName="value"
                             (click)="onNumberInputClick($event)"
                             (keydown)="touchControl('value')"
                             (focusout)="onValueChange('value', $event, 100, 1)">
                      <div class="input-group-append">
                        <span class="input-group-text">%</span>
                      </div>
                      <div class="invalid-feedback">
                        Please provide a percentage between 1 and 100
                      </div>
                    </div>
                  </div>

                  <ng-container *ngSwitchCase="couponCodeValueType.buy_x_get_y">
                    <div class="PluginConfig-Line">
                      <h5 class="mb-0">Customer buys</h5>
                    </div>
                    <div class="PluginConfig-Line" formGroupName="_local">
                      <label class="radio">
                        <input type="radio"
                               [value]="uiCouponMinimumRequirements.quantity"
                               (change)="updateMinimumRequirements()"
                               *ngIf="this.shopType !== userShopType.BigCommerceShop"
                               formControlName="minimumRequirements"> Minimum quantity of items
                      </label>
                      <br>
                      <label class="radio mb-0">
                        <input type="radio"
                               [value]="uiCouponMinimumRequirements.price"
                               (change)="updateMinimumRequirements()"
                               formControlName="minimumRequirements"> Minimum purchase amount
                      </label>
                    </div>

                    <pf-coupon-code-products
                      controlName="buysTargetSelection"
                      [productSelectorType]="uiProductSelectorType.buys"
                      label="Any items from"
                      [minimumOption]="formGroup.getRawValue().data._local.minimumRequirements"
                      [formGroup]="formGroup"
                      [isEditMode]="isEditMode"
                      [productsListService]="buyProductsListService"
                      (updateTargetSelectionChange)="updateTargetSelection($event)"
                      (showProductSelectorClick)="showProductSelector($event, uiProductSelectorType.buys)"
                    ></pf-coupon-code-products>

                    <div class="PluginConfig-Line">
                      <h5 class="mb-0">Customer gets</h5>
                    </div>

                    <pf-coupon-code-products
                      controlName="getsTargetSelection"
                      [productSelectorType]="uiProductSelectorType.gets"
                      label="Any items from"
                      [formGroup]="formGroup"
                      [isEditMode]="isEditMode"
                      [productsListService]="getProductsListService"
                      (updateTargetSelectionChange)="updateTargetSelection($event)"
                      (showProductSelectorClick)="showProductSelector($event, uiProductSelectorType.gets)"
                    ></pf-coupon-code-products>

                    <div class="PluginConfig-Line" formGroupName="_local">
                      <label class="PluginConfig-Control-Label _black _with-switch-fw">
                        Set a maximum number of uses per order
                        <pf-switch-box
                          [labelInside]="true"
                          labelOff="Off"
                          #maxUsesNumberEnabled
                          (change)="onMaxUsesNumberEnabled($event)"
                          formControlName="maxUsesNumberEnabled">
                        </pf-switch-box>
                      </label>
                    </div>

                    <div class="PluginConfig-Line form-inline" [class.is-hidden]="!$any(maxUsesNumberEnabled).value">
                      <label class="d-flex justify-content-between">
                        Number of uses
                      </label>
                      <input
                        type="number"
                        step="1" min="1" max="2147483647"
                        class="form-control ml-3"
                        (click)="onNumberInputClick($event)"
                        (focus)="touchControl('usage_per_order')"
                        (focusout)="onValueChange('usage_per_order', $event, 2147483647, 1)"
                        formControlName="usage_per_order">
                      <div class="invalid-feedback">
                        Please use a coupon code with length between 6 and 16 characters to minimize the chance that two customers receive the same coupon code.
                      </div>
                      <!-- <input type="text" class="form-control" formControlName="usage_per_order"> -->
                    </div>

                    <div class="PluginConfig-Line">
                      <h5 class="mb-0">At a discounted value</h5>
                    </div>

                    <div class="PluginConfig-Line" formGroupName="_local">
                      <label class="radio mb-0">
                        <input type="radio"
                               [value]="uiDiscountValue.free"
                               (change)="updateDiscountValue(100)"
                               formControlName="discountValue"> Free
                      </label>
                      <br>
                      <label class="radio">
                        <input type="radio"
                               [value]="uiDiscountValue.percentage"
                               (change)="updateDiscountValue(0)"
                               formControlName="discountValue"> Percentage
                      </label>

                      <div
                        class="input-group"
                        style="max-width: 250px;"
                        *ngIf="formGroup.getRawValue().data._local.discountValue === uiDiscountValue.percentage">
                        <input
                          type="number"
                          min="0"
                          max="100"
                          step="1"
                          class="form-control"
                          formControlName="buyXgetYDiscount"
                          (input)="updateDiscountValue($any($event.target).value)">
                        <div class="input-group-append">
                          <span class="input-group-text">%</span>
                        </div>
                      </div>
                    </div>

                  </ng-container>

                </ng-container>

                <input type="hidden" formControlName="target_selection">

                <input type="hidden" formControlName="allocation_method">
                <div class="PluginConfig-Line" [class.is-hidden]="discountType.value !== couponCodeValueType.fixed_amount ||
                 formGroup.getRawValue().data._local.defaultTargetSelection === uiCouponTargetSelection.all">
                  <label class="PluginConfig-Control-Label _black _with-switch-fw mt-1" formGroupName="_local">
                    <span>Only apply discount once per order</span>
                    <pf-switch-box labelOff="Off" formControlName="allocationMethod"
                                   (change)="updateAllocationMethod()" [labelInside]="true">
                    </pf-switch-box>
                  </label>
                  <small>If unchecked, this discount will apply to each eligible product in an order.</small>
                </div>

                <pf-coupon-code-products
                  *ngIf="productsListByShopType"
                  controlName="defaultTargetSelection"
                  [productSelectorType]="productsListByShopType"
                  [class.is-hidden]="formGroup.getRawValue().data._local.discountType === couponCodeValueType.buy_x_get_y"
                  label="Applies to"
                  [formGroup]="formGroup"
                  [isEditMode]="isEditMode"
                  [productsListService]="defaultProductsListService"
                  (updateTargetSelectionChange)="updateTargetSelection($event)"
                  (showProductSelectorClick)="showProductSelector($event, uiProductSelectorType.default)"
                ></pf-coupon-code-products>

              </ng-container>

              <div class="PluginConfig-Line"
                   [class.is-hidden]="formGroup.getRawValue().data.target_type !== couponCodeTargetType.shipping_line">
                <label class="mb-0">Shipping Rates</label>
                <label class="PluginConfig-Control-Label _black _with-switch-fw mt-1" formGroupName="_local">
                  <span>Exclude shipping rates over a certain price</span>
                  <pf-switch-box labelOff="Off" formControlName="shippingRates"
                                 (change)="updateShippingRates()" [labelInside]="true">
                  </pf-switch-box>
                </label>
                <div class="form-group mt-3" [class.is-hidden]="!formGroup.getRawValue().data._local.shippingRates">
                  <input type="number" min="0" class="form-control" formControlName="prerequisite_shipping_price_max" [positiveIntegers]="true">
                  <div class="invalid-feedback visible" *ngIf="formGroup.get('data.prerequisite_shipping_price_max').hasError('min')">
                    Please provide a value greater than 0
                  </div>
                  <div class="invalid-feedback visible" *ngIf="formGroup.get('data.prerequisite_shipping_price_max').hasError('max')">
                    Please provide a valid value
                  </div>
                </div>
              </div>

              <div class="PluginConfig-Line" [class.is-hidden]="discountType.value === couponCodeValueType.buy_x_get_y">
                <ng-container formGroupName="_local">
                  <label>Minimum Requirements</label>
                  <select class="form-control" formControlName="minimumRequirements" #minimumRequirements
                          (change)="updateMinimumRequirements()">
                    <option [value]="uiCouponMinimumRequirements.none">None</option>
                    <option [value]="uiCouponMinimumRequirements.price">Minimum purchase amount</option>
                    <option [value]="uiCouponMinimumRequirements.quantity" *ngIf="this.shopType !== userShopType.BigCommerceShop">Minimum quantity of items</option>
                  </select>
                </ng-container>

                <div class="form-inline mt-3" [ngSwitch]="minimumRequirements.value"
                     [class.is-hidden]="minimumRequirements.value === uiCouponMinimumRequirements.none">
                  <ng-container *ngSwitchCase="uiCouponMinimumRequirements.price">
                    <label class="mr-3">Minimum purchase amount</label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">{{currency}}</span>
                      </div>
                      <input type="number" min="0" class="form-control" formControlName="prerequisite_subtotal_min"
                             (click)="onNumberInputClick($event)"
                             (focus)="touchControl('prerequisite_subtotal_min')"
                             (focusout)="onValueChange('prerequisite_subtotal_min', $event, maxSafeInt, 0, false)">
                    </div>
                    <div class="invalid-feedback visible" *ngIf="formGroup.get('data.prerequisite_subtotal_min').hasError('min')">
                      Please provide a value greater than 0
                    </div>
                    <div class="invalid-feedback visible" *ngIf="formGroup.get('data.prerequisite_subtotal_min').hasError('max')">
                      Please provide a valid value
                    </div>
                  </ng-container>
                  <ng-container *ngSwitchCase="uiCouponMinimumRequirements.quantity">
                    <label>Minimum quantity of items</label>
                    <input type="number" min="0" max="99999999" step="1" class="form-control ml-3"
                           formControlName="prerequisite_quantity_range_min"
                           (click)="onNumberInputClick($event)"
                           (focus)="touchControl('prerequisite_quantity_range_min')"
                           (focusout)="onValueChange('prerequisite_quantity_range_min', $event, 99999999)">
                    <div class="invalid-feedback">
                      Please provide a value between 0 and 99999999
                    </div>
                  </ng-container>
                </div>
              </div>

            </ng-container>
            </div>

            <!-- [ end of data] -->
          </div>
        </form>
      </div>

      <div class="L-CouponCode-Aside">
        <div class="CouponCode-Summary">
          <div class="text-dark" *ngIf="summary">

            <!-- Schedule -->
            <!-- <p>{{summary.schedule.start}}</p>
            <p *ngIf="summary.schedule.end">{{summary.schedule.end}}</p> -->

            <!-- Code Type -->
            <!-- <p>{{summary.codeType}}</p> -->

            <!-- Code -->
            <div class="CouponCode-Code">
              <ng-container [ngSwitch]="true" *ngIf="formGroup.getRawValue().type as Type">
                <ng-container *ngSwitchCase="Type === couponCodeType.manual_master">
                  <h5>Master Code Preview</h5>
                  <small class="text-danger" *ngIf="!formGroup.pristine && summary.code">
                    Coupon valid after save
                  </small>
                  <pre>{{summary.code}} </pre>
                </ng-container>
                <ng-container *ngSwitchCase="[couponCodeType.shopify_master, couponCodeType.big_commerce_master].includes(Type)">
                  <h5>Master Code Preview</h5>
                  <small class="text-danger" *ngIf="!formGroup.pristine && summary.code">
                    Coupon valid after save
                  </small>
                  <pre>{{summary.code}} </pre>
                </ng-container>
                <ng-container *ngSwitchCase="Type === couponCodeType.manual_unique">
                  <h5>Unique Code Preview</h5>
                  <small class="CouponCode-Code-Warning-Text">
                    [Sample] Real Code Generated on Pop up
                  </small>
                  <pre>{{summary.code}} </pre>
                </ng-container>
                <ng-container *ngSwitchCase="[couponCodeType.shopify_unique, couponCodeType.big_commerce_unique].includes(Type)">
                  <h5>Unique Code Preview</h5>
                  <small class="CouponCode-Code-Warning-Text">
                    [Sample] Real Code Generated on Pop up
                  </small>
                  <pre>{{summary.code}} </pre>
                </ng-container>
              </ng-container>
            </div>

            <ul>
              <!-- Discount -->
              <li *ngIf="summary.discount">{{summary.discount}} {{summary.discoutnRelatedTo}}</li>
              <!-- Limit -->
              <li *ngIf="summary.limit">{{summary.limit}}</li>
            </ul>

            <div class="d-flex justify-content-around mt-4">
              <button class="pf-button md outline gray" (click)="cancel()">CANCEL</button>
              <button class="pf-button md filled blue" (click)="submit()">SAVE</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</section>



<pf-loading-overlay [filter]="{only: ['new-coupon-code']}" class="loading-overlay"></pf-loading-overlay>
