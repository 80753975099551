const de = {
  Quantity: 'Menge',
  Size: 'Größe',
  Color: 'Color',
  Material: 'Material',
  Style: 'Stil',
  Savings: 'Ersparnisse',
  Subtotal: 'Zwischensumme',
  Shipping: 'Versand',
  Taxes: 'Steuern',
  Total: 'Gesamt',
  Free: 'Frei',
  Buy: 'Besorgen',
  'Pay Now': 'Zahlen Sie jetzt',
  'Take the Deal': 'Nimm den Deal',
  'Add Now': 'Jetzt hinzufügen',
  'You will be charged immediately': 'Sie werden sofort belastet',
  'Decline Offer': 'Angebot ablehnen',
  'No Thanks': 'Nein Danke',
  '{price}': '{preis}',
  'Sold Out': 'Ausverkauft',
  'see more': 'mehr sehen',
  less: 'weniger',
};
export {de};
