import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs'
import { filter, take } from 'rxjs/operators'
import { RewardsType } from '../../pages/coupon-codes/models/rewards-model';
import { ApiRewardsService, RewardsRequestParams, RewardsResponse } from './api/api-rewards.service'

@Injectable({
  providedIn: 'root'
})

export class RewardsService {
  public rewardsResponseRow$ = new BehaviorSubject<RewardsResponse>(null)
  public couponsResponseRow$ = new BehaviorSubject<RewardsResponse>(null)
  public giftCardsResponseRow$ = new BehaviorSubject<RewardsResponse>(null)

  get rewardsCodesResponse$(): Observable<RewardsResponse> {
    return this.rewardsResponseRow$.pipe(filter(data => data !== null))
  }
  get couponCodesResponse$(): Observable<RewardsResponse> {
    return this.couponsResponseRow$.pipe(filter(data => data !== null))
  }
  get giftCardCodesResponse$(): Observable<RewardsResponse> {
    return this.giftCardsResponseRow$.pipe(filter(data => data !== null))
  }

  constructor(public apiRewardsService: ApiRewardsService) { }

  getRewardCodes(params: RewardsRequestParams): void {
    this.apiRewardsService.getRewards(params).pipe(take(1)).subscribe(data => {
      this.rewardsResponseRow$.next(data)
    })
  }

  getCouponCodes(params: RewardsRequestParams): void {
    params = { ...params, reward_type: RewardsType.coupon_code }
    this.apiRewardsService.getRewards(params).pipe(take(1)).subscribe(data => {
      this.couponsResponseRow$.next(data)
    })
  }

  getGiftCardCodes(params: RewardsRequestParams): void {
    params = { ...params, reward_type: RewardsType.gift_card }
    this.apiRewardsService.getRewards(params).pipe(take(1)).subscribe(data => {
      this.giftCardsResponseRow$.next(data)
    })
  }
}
