export const newsLetterCustomCssTemplate =
  `/* Customize your styles using CSS and class selectors below */
.overlay {}
.prompt-content {}
.prompt-close {}
.prompt-header {}
.header-title {}
.header-subtitle {}
.prompt-opt-in {}
.prompt-footer {}
.prompt-input-wrap {}
.prompt-input {}
.subscribe-button {}
.result-content {}
.result-close {}
.result-header {}
.result-title {}
.result-button {}
.result-footer {}
`
