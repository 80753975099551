<pf-auth-layout>
  <ng-container class="auth-title">Reset your password</ng-container>
  <ng-container class="auth-content">
    <div *ngIf="!hasSentEmail">
      <p>Enter your email to reset your password</p>
      <div class="form-horizontal">
        <div class="form-group row">
          <label class="col-sm-3 form-control-label text-sm-right mt-sm-2 pr-sm-0">Email</label>
          <div class="col-sm-9">
            <input name="email" type="email" [(ngModel)]="email" placeholder="user@gmail.com" required="required"
                   autocomplete="email" class="form-control">
          </div>
        </div>
      </div>
      <div *ngIf="errors.length">
        <div class="alert alert-danger">
          <div *ngFor="let error of errors">{{ error }}</div>
        </div>
      </div>
      <button class="pf-button fw filled blue mb-4"
              (click)="requestPass()" [disabled]="!email">Reset Password
      </button>
    </div>
    <div *ngIf="hasSentEmail">
      <p>Check your mailbox</p>
    </div>
    <p>Remember password? <a routerLink="/login/with-password">Login here</a></p>
  </ng-container>
</pf-auth-layout>
