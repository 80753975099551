const zh_TW = {
  Quantity: '數量',
  Size: '尺寸',
  Color: '顏色',
  Material: '材質',
  Style: '款式',
  Savings: '省下',
  Subtotal: '商品小計',
  Shipping: '運費',
  Taxes: '稅',
  Total: '總計',
  Free: '免費',
  Buy: '購買',
  'Pay Now': '馬上結帳',
  'Take the Deal': '接受優惠',
  'Add Now': '立即添加',
  'You will be charged immediately': '您將立即被收取費用',
  'Decline Offer': '婉謝優惠',
  'No Thanks': '不用了，謝謝',
  '{price}': '{價格}',
  'Sold Out': '售罄',
  'see more': '看更多',
  less: '較少的',
};
export {zh_TW};
