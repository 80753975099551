import { Injectable } from '@angular/core'
import * as _ from 'lodash'
import { Observable } from 'rxjs'
import { ApiService } from '../../../../core/services/api/api.service'
import { GiftCardFormRequest, GiftCardFormResponse, GiftCardOnboardingFlow } from '../models/giftcard-onboarding.model'

@Injectable()
export class GiftCardOnboardingApiService {

  constructor(
    private apiService: ApiService,
  ) { }

  getFlow(): Observable<GiftCardOnboardingFlow> {
    return this.apiService.get('/v1/me/interactions/onboarding_gift_card')
  }

  saveFlow(flowData: GiftCardOnboardingFlow): Observable<GiftCardOnboardingFlow> {
    return this.apiService.post('/v1/me/interactions/onboarding_gift_card', { onboarding_gift_card: flowData })
  }

  saveComplianceOfficer(form: GiftCardFormRequest): Observable<GiftCardFormResponse> {
    return this.apiService.post('/v1/me/gift_card_compliance_officer_profile', form)
  }

  getComplianceOfficer(): Observable<GiftCardFormResponse> {
    return this.apiService.get('/v1/me/gift_card_compliance_officer_profile')
  }
}
