import { Injectable } from '@angular/core'
import { UntypedFormGroup } from '@angular/forms'
import { Observable, Subscription, finalize, filter, from, reduce } from 'rxjs'
import { isEqual } from 'lodash'
import { OneHeaderService } from './one-header.service'

export interface MultiSaveTrackRecord {
  id: string
  formRef: UntypedFormGroup
  initialValue: any
  currentValue: any
  hasChanged: boolean
}

@Injectable({providedIn: 'root'})
export class MultiSaveDetectorService {
  private subscription = new Subscription()
  /**
   * A map of all forms that are currently being tracked
   */
  public trackList = new Map<string, MultiSaveTrackRecord>()

  constructor(
    private oneHeaderService: OneHeaderService,
  ) {
    // this.subscription.add(
    //   this.oneHeaderService.saveSubject$.subscribe(() => {
    //   })
    // )
    // Discard changes when discard button is clicked
    this.subscription.add(
      this.oneHeaderService.discardSubject$.subscribe(() => {
        this.discardChanges()
      })
    )
  }

  /**
   * Checks if a form with the given id has changes
   * @param id - unique id of the form
   * @returns - true if the form has changes
   */
  public needsSave(id: string): boolean {
    const record = this.trackList.get(id)
    return record ? record?.hasChanged : false
  }

  /** 
   * Creates a form tracking record, watches for changes and returns a subscription
   * @param id - unique id of the form must match the this.store.dispatch(new ShowLoading('id'))
   * @param formRef - form reference
   * @returns - subscription that watches for changes
   * @example
   * const form = new FormGroup({
   *  name: new FormControl(''),
   *  email: new FormControl(''),
   * })
   * 
   * this.subscription.add(
   *   this.multiSaveDetectorService.track('myForm', form).subscribe(() => {
   *     // Save the form
   *     this.apiService.post(...).subscribe(res => {
   *       // Mark as saved once the save is complete
   *       this.multiSaveDetectorService.markAsSaved('myForm')
   *     })
   *   })
   * )
   * 
   */
  public track(id: string, formRef: UntypedFormGroup): Observable<any> {
    // Create a tracking record
    this.trackList.set(id, {
      id: id,
      formRef: formRef,
      initialValue: formRef.getRawValue(),
      currentValue: formRef.getRawValue(),
      hasChanged: false,
    })
    
    // Create a subscription that watches form changes
    const formChangesSub = formRef.valueChanges.pipe(
      // When subscription is being unsubscribed, remove the tracking record
      finalize(() => {
        this.trackList.delete(id)
      })
    ).subscribe(() => {
      // Find the record and update it's values
      const record = this.trackList.get(id)
      if (record) {
        record.currentValue = formRef.getRawValue()
        // Check for changes
        record.hasChanged = !isEqual(record.initialValue, record.currentValue)
        // Show save button if needed
        this._showSaveButtonIfNeeded()
      }
    })
    // Create a subscription that watches for save button click
    return this.oneHeaderService.saveSubject$.pipe(
      // Filter only relevant save button clicks
      filter(() => this.needsSave(id)),
      // When subscription is being unsubscribed, remove the form changes subscription
      finalize(() => {
        formChangesSub.unsubscribe()
      })
    )
  }

  // Checks all the tracked forms and shows save button if needed
  private _showSaveButtonIfNeeded(): void {
    // Check if any form has changes
    const hasChanges = Array.from(this.trackList.values()).some(record => record.hasChanged)
    if (hasChanges) {
      this.oneHeaderService.showSaveView()
    } else {
      this.oneHeaderService.reset()
    }
  }

  /**
   * Used by MultiSaveGuard to check if any form has changes
   * @returns - true if any form has changes
   */
  public hasUnsavedForms(): boolean {
    return Array.from(this.trackList.values()).some(record => record.hasChanged)
  }

  /**
   * Used to remove the hasChanged flag from the tracking record and update the initial value
   * @param id - unique id of the form
   * @example
   * this.multiSaveDetectorService.markAsSaved('myForm')
   */
  public markAsSaved(id: string): void {
    const record = this.trackList.get(id)
    if (record) {
      record.initialValue = record.currentValue
      record.hasChanged = false
      // Check if any form has changes
      this._showSaveButtonIfNeeded()
    }
  }

  /**
   * Used to discard all the changes and reset the forms to their initial values
   * @example
   * this.multiSaveDetectorService.discardChanges()
   */
  public discardChanges(): void {
    // Reset all the forms
    Array.from(this.trackList.values()).forEach(record => {
      record.formRef.reset(record.initialValue)
      record.currentValue = record.initialValue
      record.hasChanged = false
    })
    // Check if any form has changes
    this._showSaveButtonIfNeeded()
  }
}
