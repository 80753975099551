import { inject, Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { tap } from 'rxjs/operators'
import { TotalUniqueResponse } from '../../../../../../pages/dashboard-alt/models/dashboard-alt-api.model'
import { RewardsType } from '../../../../../../pages/coupon-codes/models/rewards-model'
import { ApiRewardsService, RewardStatsResponse } from '../../../../../../core/services/api/api-rewards.service'
import { UpsellService } from '../../../../../../pages/upsell/services/upsell.service'
import { DashboardAltApiService } from '../../../../../../pages/dashboard-alt/services/dashboard-alt-api.service'


@Injectable({
  providedIn: 'root'
})
export class ActiveCampaignDropdownService {
  private cachedTotalUnique$: BehaviorSubject<TotalUniqueResponse> = new BehaviorSubject<TotalUniqueResponse>(null)
  private cachedUpsellData$: BehaviorSubject<any> = new BehaviorSubject<any>(null)
  private cachedRewardData$ = {
    [RewardsType.coupon_code]: new BehaviorSubject<RewardStatsResponse>(null),
    [RewardsType.gift_card]: new BehaviorSubject<RewardStatsResponse>(null)
  }
  dashboardAltApiService = inject(DashboardAltApiService)
  constructor(
    private upsellService: UpsellService,
    private apiRewardsService: ApiRewardsService
  ) {
  }


  getRewardStats(params, rewardType) {
    if (!this.cachedRewardData$[rewardType].value) {
      return this.apiRewardsService.getRewardStats(params, rewardType)
        .pipe(
          tap((rewardStats: RewardStatsResponse) => {
            this.cachedRewardData$[rewardType].next(rewardStats)
          }),
        )
    }
    return this.cachedRewardData$[rewardType].asObservable()
  }

  getUpsellStats(startDate, endDate) {
    if (!this.cachedTotalUnique$.value) {
      return this.upsellService.getUpsellStats(startDate, endDate)
        .pipe(
          tap((totalUnique: TotalUniqueResponse) => {
            this.cachedUpsellData$.next(totalUnique)
          })
        )
    }
    return this.cachedUpsellData$.asObservable()
  }

  getTotalUnique(startDate, endDate): Observable<TotalUniqueResponse> {
    if (!this.cachedTotalUnique$.value) {
     return this.dashboardAltApiService.getTotalUnique(startDate, endDate)
        .pipe(
          tap((totalUnique: TotalUniqueResponse) => {
            this.cachedTotalUnique$.next(totalUnique)
          })
        )
    }
    return this.cachedTotalUnique$.asObservable()
  }
}
