import { SegmentAnalyticsService } from './../../services/segment-analytics.service';
import { CommonModule } from '@angular/common'
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core'
import { ApiService } from '../../../core/services/api/api.service';
import { Subscription } from 'rxjs';
import { OneIconComponent } from '../one-icon/one-icon.component';
import { ConfirmModalComponent } from '../modals/confirm.modal/confirm.modal.component';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import _ from 'lodash'
import { UserService } from '../../../core/services/user.service';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';

enum AIAction {
  replace = 'replace',
  insertBelow = 'insert-below',
  discard = 'discard',
}

enum AIActionText {
  replace = 'Replace',
  insertBelow = 'Insert below',
  discard = 'Discard',
}

enum ExpandableOptions {
  translate = 'translate',
  changeTone = 'change-tone',
}

interface AIOption {
  label: string;
  value: string | ExpandableOptions;
  svg: string;
  expandable?: boolean;
}

@Component({
  templateUrl: './ai-dropdown-component.html',
  styleUrls: ['./ai-dropdown-component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    OneIconComponent,
    ConfirmModalComponent,
    MatDialogModule,
  ],
})
export class AIDropdownComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('aiDropdown') aiDropdown: ElementRef;

  @Input() inputValue;
  @Input() previewText;
  @Input() isQuillEditor = false;
  @Output() loadingAI = new EventEmitter<boolean>();
  @Output() optionSelected = new EventEmitter<string>();
  @Output() hasErrorAI = new EventEmitter<boolean>();
  @Output() isLeaveAI = new EventEmitter<any>();
  @Output() previewTextChanged = new EventEmitter<string>();

  previewHTML: SafeHtml;
  subscription = new Subscription()
  isWritingAI = false;
  isUndo = false;
  undoBackup = '';
  showPreview = false;
  aiAction = AIAction;
  actionText = AIActionText.replace
  expanded = false;
  expandableOptions = ExpandableOptions;
  optionExpanded = {
    [ExpandableOptions.changeTone]: false,
    [ExpandableOptions.translate]: false,
  }
  showPriorityLanguage = false;
  previewOptions = [
    { label: AIActionText.replace, value: 'replace', svg: '/app_assets/ai/replace.svg' },
    { label: AIActionText.insertBelow, value: 'insert-below', svg: '/app_assets/ai/insert-below.svg' },
    { label: AIActionText.discard, value: 'discard', svg: '/app_assets/ai/discard.svg' },
  ]

  options: AIOption[] = [
    { label: 'Improve Writing', value: 'improve-writing', svg: '/app_assets/ai/improve-writing.svg', expandable: false },
    { label: 'Fix spelling & grammar', value: 'fix-spelling-grammar', svg: '/app_assets/ai/fix-spelling.svg', expandable: false },
    { label: 'Make shorter', value: 'make-shorter', svg: '/app_assets/ai/shorter.svg' , expandable: false},
    { label: 'Make longer', value: 'make-longer', svg: '/app_assets/ai/longer.svg', expandable: false },
    { label: 'Translate', value: ExpandableOptions.translate, svg: '/app_assets/ai/language.svg', expandable: true },
    { label: 'Change tone', value: ExpandableOptions.changeTone, svg: '/app_assets/ai/change-tone.svg', expandable: true },
    { label: 'Simplify Language', value: 'simplify-language', svg: '/app_assets/ai/simplify.svg', expandable: false },
  ]

  toneOptions = [
    { label: 'Professional', value: 'change-tone-professional' },
    { label: 'Casual', value: 'change-tone-casual' },
    { label: 'Straightforward', value: 'change-tone-straightforward' },
    { label: 'Confident', value: 'change-tone-confident' },
    { label: 'Friendly', value: 'change-tone-friendly' },
  ]

  languageOptions = [
    { label: 'Arabic', value: 'translate', language: 'arabic', locale: 'ar' },
    { label: 'Chinese (Simplified)', value: 'translate', language: 'chinese (Simplified)', locale: 'zh' },
    { label: 'Chinese (Traditional)', value: 'translate', language: 'chinese (Traditional))', locale: 'zh-tw' },
    { label: 'Danish', value: 'translate', language: 'danish', locale: 'da' },
    { label: 'Dutch', value: 'translate', language: 'dutch', locale: 'nl' },
    { label: 'English', value: 'translate', language: 'english', locale: 'en' },
    { label: 'Finnish', value: 'translate', language: 'finnish', locale: 'fi' },
    { label: 'French', value: 'translate', language: 'french', locale: 'fr' },
    { label: 'German', value: 'translate', language: 'german', locale: 'de' },
    { label: 'Hebrew', value: 'translate', language: 'hebrew', locale: 'he' },
    { label: 'Hindi', value: 'translate', language: 'hindi', locale: 'hi' },
    { label: 'Italian', value: 'translate', language: 'italian', locale: 'it' },
    { label: 'Japanese', value: 'translate', language: 'japanese', locale: 'ja' },
    { label: 'Polish', value: 'translate', language: 'polish', locale: 'pl' },
    { label: 'Portuguese (Brazil)', value: 'translate', language: 'portuguese (Brazil)', locale: 'pt-br' },
    { label: 'Portuguese (Portugal)', value: 'translate', language: 'portuguese (Portugal)', locale: 'pt-pt' },
    { label: 'Spanish', value: 'translate', language: 'spanish', locale: 'es' },
    { label: 'Swedish', value: 'translate', language: 'swedish', locale: 'sv' },
    { label: 'Turkish', value: 'translate', language: 'turkish', locale: 'tr' },
    { label: 'Ukrainian', value: 'translate', language: 'ukrainian', locale: 'uk' },
    { label: 'Urdu', value: 'translate', language: 'urdu', locale: 'ur' },
  ]

  constructor(
    private apiService: ApiService,
    private segmentAnalyticsService: SegmentAnalyticsService,
    public dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private userService: UserService,
    ) {
    this.adjustLanguageOptions()
  }

  get preFilled(): string {
    let words;
    if (this.inputValue.includes('{{') && this.inputValue.includes('}}')) {
      words = `Rewrite the following. No annotation. No explanation. No feedback. Keep words with {{}}. Do not remove {{}}. Do not add additional {{}}. Do not capitalize {{}}. Do not change {{}}. `
    } else {
      words = `Rewrite the following. No annotation. No explanation. No feedback. `
    }
    if (this.inputValue.includes('*') && this.inputValue.charAt(0) === '*') {
      words += `Keep asterisk (*) at the beginning. `
    }
    if (this.inputValue.includes('*') && this.inputValue.slice(-1) === '*') {
      words += `Keep asterisk (*) at the end. `
    }
    return words;
  }

  adjustLanguageOptions() {
    const locale = this.userService.userInfo?.shop_user?.shopify_shop_user?.locale
    if (!locale) {
      return
    }
    const index = this.languageOptions.findIndex((option) => option.locale === locale.toLowerCase())
    if (index > -1 && locale.slice(0, 2) !== 'en') {
      this.showPriorityLanguage = true
      const priorityLanguage = this.languageOptions.splice(index, 1)
      this.languageOptions = [priorityLanguage[0], ...this.languageOptions]
    }
  }

  getPrefilledTranslation(language): string {
    let words;
    if (this.inputValue.includes('{{') && this.inputValue.includes('}}')) {
      words = `Translate the following text to ${language} language. No annotation. No explanation. No feedback. Keep words with {{}}. Do not translate text inside {{}}. Do not remove {{}}. Do not add additional {{}}. Do not capitalize {{}}. Do not change {{}}. `
    } else {
      words = `Translate the following text to ${language} language. No annotation. No explanation. No feedback. `
    }
    if (this.inputValue.includes('*') && this.inputValue.charAt(0) === '*') {
      words += `Keep asterisk (*) at the beginning. `
    }
    if (this.inputValue.includes('*') && this.inputValue.slice(-1) === '*') {
      words += `Keep asterisk (*) at the end. `
    }
    return words;
  }

  onSelect(event) {
    const prompt = this.inputValue;
    if (!prompt || typeof prompt !== 'string' || this.isWritingAI) {
      return;
    }
    switch (event.value) {
      case 'translate':
        this.aiGeneratedContent(`${this.getPrefilledTranslation(event.language)}\n\n${prompt}`)
        this.segmentAnalyticsService.track('AI - Translate - ' + event.label)
        break
      case 'improve-writing':
        this.aiGeneratedContent(`${this.preFilled}Improve writing.\n\n${prompt}`)
        this.segmentAnalyticsService.track('AI - Improve Writing')
        break;
      case 'fix-spelling-grammar':
        this.aiGeneratedContent(`${this.preFilled}Fix spelling and grammar.\n\n${prompt}`)
        this.segmentAnalyticsService.track('AI - Fix Spelling & Grammar')
        break;
      case 'make-shorter':
        this.aiGeneratedContent(`${this.preFilled}Make it shorten.\n\n${prompt}`)
        this.segmentAnalyticsService.track('AI - Make Shorter')
        break;
      case 'make-longer':
        this.aiGeneratedContent(`${this.preFilled}Expand writing slightly.\n\n${prompt}`)
        this.segmentAnalyticsService.track('AI - Make Longer')
        break;
      case 'change-tone-professional':
        this.aiGeneratedContent(`${this.preFilled}In a professional tone.\n\n${prompt}`)
        this.segmentAnalyticsService.track('AI - Change Tone - Professional')
        break;
      case 'change-tone-casual':
        this.aiGeneratedContent(`${this.preFilled}In a casual tone.\n\n${prompt}`)
        this.segmentAnalyticsService.track('AI - Change Tone - Casual')
        break;
      case 'change-tone-straightforward':
        this.aiGeneratedContent(`${this.preFilled}In a straightforward tone.\n\n${prompt}`)
        this.segmentAnalyticsService.track('AI - Change Tone - Straightforward')
        break;
      case 'change-tone-confident':
        this.aiGeneratedContent(`${this.preFilled}In a confident tone.\n\n${prompt}`)
        this.segmentAnalyticsService.track('AI - Change Tone - Confident')
        break;
      case 'change-tone-friendly':
        this.aiGeneratedContent(`${this.preFilled}In a friendly tone.\n\n${prompt}`)
        this.segmentAnalyticsService.track('AI - Change Tone - Friendly')
        break;
      case 'simplify-language':
        this.aiGeneratedContent(`${this.preFilled}Simplify the language.\n\n${prompt}`)
        this.segmentAnalyticsService.track('AI - Simplify Language')
      default:
        break;
    }
  }

  private aiGeneratedContent(prompt: string) {
    this.hasErrorAI.emit(false);
    this.loadingAI.emit(true);
    this.isWritingAI = true;
    this.subscription.add(
      this.apiService.post('/v2/experiment/ai_generated_content/short_completion', {
        prompt
      }).subscribe(res => {
        if(res?.completion) {
          const newLine = res.completion.trim()
          this.loadingAI.emit(false);
          this.isWritingAI = false;
          this.showPreview = true;
          this.previewTextChanged.emit(newLine);
          this.scrollToAI()
          if (this.isQuillEditor) {
            const html = this.previewText.replaceAll(/{{([^}]+)}}/g, '<span style="border-radius: 6px; background-color: #d3e1eb; line-height: 1em; padding:2px; margin-right: 2px; display: inline-block;">#$1</span>');
            const safeHtml = this.sanitizer.bypassSecurityTrustHtml(html)
            this.previewHTML = _.cloneDeep(safeHtml)
          }
        } else {
          this.loadingAI.emit(false);
          this.isWritingAI = false
          this.hasErrorAI.emit(true);
        }
      }, _err => {
        this.loadingAI.emit(false);
        this.isWritingAI = false
        this.hasErrorAI.emit(true)
      })
    )
  }

  setAction(action: AIAction) {
    this.showPreview = false
    this.isUndo = true
    // backup the current input value
    this.undoBackup = this.inputValue
    if (action === AIAction.replace) {
      this.actionText = AIActionText.replace
      this.optionSelected.emit(this.previewText);
    }
    if (action === AIAction.insertBelow) {
      this.actionText = AIActionText.insertBelow
      this.optionSelected.emit(this.inputValue+'\n'+this.previewText);
    }
    if (action === AIAction.discard) {
      this.actionText = AIActionText.discard
    }
  }

  setUndoAction(actionText: AIActionText) {
    this.showPreview = true
    this.isUndo = false
    if (actionText === AIActionText.replace || actionText === AIActionText.insertBelow) {
      this.optionSelected.emit(this.undoBackup);
    }
    this.scrollToAI()
  }

  closeAI() {
    if (this.previewText && !this.isUndo) {
      const dialogRef = this.dialog.open(ConfirmModalComponent, {
        width: '460px',
        data: {
          headerClass: 'text-center mt-4',
          title: `Do you want to discard the AI response?`,
          cancelButton: {
            text: 'Cancel',
            classes: 'pf-button outline gray md',
          },
          acceptButton: {
            text: 'Discard',
            classes: 'pf-button filled red md',
          },
        },
      })
      this.subscription.add(
        dialogRef.afterClosed().subscribe(res => {
          if (res) {
            this.isLeaveAI.emit()
            this.previewText = null
            this.previewHTML = null
            this.undoBackup = ''
          }
        })
      )
    } else {
      this.isLeaveAI.emit()
      this.previewText = null
      this.previewHTML = null
      this.undoBackup = ''
    }
  }

  toggleExpandOption(value: ExpandableOptions | string, expand: boolean) {
    this.expanded = expand
    this.optionExpanded[value] = expand
    this.trackAiTranslateClickIfNeeded(value, expand)
  }

  trackAiTranslateClickIfNeeded(value: ExpandableOptions | string, expand: boolean) {
    if (value === ExpandableOptions.translate && expand) {
      this.segmentAnalyticsService.track('AI - Translate')
    }
  }

  private scrollToAI() {
    setTimeout(() => {
      this.aiDropdown.nativeElement?.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }, 100)
  }

  ngOnInit(): void {
    this.segmentAnalyticsService.track('AI - Open Assist Options')
  }

  ngAfterViewInit(): void {
    this.scrollToAI()
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }
}
