import { Injectable } from '@angular/core'
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber'

@Injectable()
export class PhoneNumberService {
  readonly phoneUtil = PhoneNumberUtil.getInstance()
  readonly PNF = PhoneNumberFormat

  format(phoneNumber: string, region: string = 'US') {
    // wrapping this in try-catch in case invalid phoneNumber gets here
    try {
      const phone = this.phoneUtil.parse(phoneNumber, region)
      return this.phoneUtil.format(phone, this.PNF.INTERNATIONAL)
    } catch {
      return ''
    }
  }
}
