export enum PreviewDeviceTitle {
  desktop = 'Desktop',
  mobile = 'Mobile',
  slices = 'Slices',
}

export interface DevicePreviewButton {
  title: PreviewDeviceTitle,
  active: boolean,
  icon: string,
  height: string,
  width: string,
  isMobile: boolean,
  isSlicesPreview?: boolean,
  minHeight?: string,
  minWidth?: string,
}

export const MobileDevicePreviewButton = {
  title: PreviewDeviceTitle.mobile,
  icon: '/app_assets/icons/mobile-icon.svg',
  active: false,
  width: '390px',
  height: 'calc(100% - 40px)',
  minHeight: 'calc(100% - 40px)',
  isMobile: true,
}

export const DesktopDevicePreviewButton = {
  title: PreviewDeviceTitle.desktop,
  icon: '/app_assets/icons/desktop-icon.svg',
  active: false,
  width: '100.0%',
  height: 'calc(100% - 40px)',
  minWidth: '100%',
  minHeight: 'calc(100% - 40px)',
  isMobile: false,
}

export const WheelSlicesPreviewButton = {
  title: PreviewDeviceTitle.slices,
  icon: '/app_assets/campaigns-list/icons/wheel.svg',
  active: false,
  width: '100.0%',
  height: 'calc(100% - 40px)',
  minWidth: '100%',
  minHeight: 'calc(100% - 40px)',
  isMobile: false,
  isSlicesPreview: true,
}

export interface PreviewSizes {
  width?: string,
  height?: string,
  'min-height'?: string,
  'min-width'?: string
}
