import { Pipe, PipeTransform } from '@angular/core';
import { de } from './i18n/de'
import { es } from './i18n/es'
import { fr } from './i18n/fr'
import { it } from './i18n/it'
import { ka } from './i18n/ka'
import { ua } from './i18n/ua'
import { vi } from './i18n/vi'
import { ar } from './i18n/ar'
import { tr } from './i18n/tr'
import { sv } from './i18n/sv'
import { da } from './i18n/da'
import { zh_CN } from './i18n/zh_CN'
import { zh_TW } from './i18n/zh_TW'
import { hi_IN } from './i18n/hi_IN'
import { pt_BR } from './i18n/pt_BR'
import { nl } from './i18n/nl'
import { lt } from './i18n/lt'
import { is } from './i18n/is'
import { he } from './i18n/he'
import { pl } from './i18n/pl'
import { fi } from './i18n/fi'
import { nb_NO } from './i18n/nb_NO'

const i18nMap: any = {
  ar,
  tr,
  es,
  de,
  fr,
  it,
  is,
  ka,
  ua,
  vi,
  sv,
  lt,
  da,
  zh_CN,
  zh_TW,
  hi_IN,
  pt_BR,
  nl,
  he,
  pl,
  fi,
  nb_NO
}

export const supportedLanguages = ['en', ...Object.keys(i18nMap)]

const getMap = (locale = 'en') => {
  return i18nMap?.[locale] || null
}

const translate = (value, locale) => {
  const map = getMap(locale)
  return map?.[value] || value || ''
}

@Pipe({name: 'translateUpsell'})
export class TranslateUpsellPipe implements PipeTransform {
  transform(value: string, locale = 'en'): string {
    if (!locale || locale === 'en') {
      return value;
    } else if (value.indexOf(' · ')) {
      const words = value.split(' · ')
      const translatedWords: string[] = [];
      words.forEach((w) => {
        translatedWords.push(translate(w, locale));
      });
      return translatedWords.join(' · ')
    } else {
      return translate(value, locale)
    }
  }
}
