import { CampaignRouteStep } from '../../../core/services/campaign-url.service'
import { PluginConfigureRoutePluginNames } from '../../../pages/campaign-page/plugin-configure/models/plugin-configure.models'
import { CampaignPluginAppName, CampaignPluginName } from '../../models/campaign/campaign'

export const pluginMap = {
  [CampaignPluginName.RecentActivity]: { name: CampaignPluginAppName.RecentActivity, url: PluginConfigureRoutePluginNames.RecentActivity, icon: 'social-proof-icon'  },
  [CampaignPluginName.AggregateActivity]: { name: CampaignPluginAppName.AggregateActivity, url: PluginConfigureRoutePluginNames.AggregateActivity, icon: 'social-proof-icon'  },
  [CampaignPluginName.LiveVisitorCount]: { name: CampaignPluginAppName.LiveVisitorCount, url:  PluginConfigureRoutePluginNames.LiveVisitorCount, icon: 'social-proof-icon'  },
  [CampaignPluginName.AggregateVisitorCount]: { name: CampaignPluginAppName.AggregateVisitorCount, url: PluginConfigureRoutePluginNames.AggregateVisitorCount, icon: 'social-proof-icon'  },
  [CampaignPluginName.FortuneWheel]: { name: CampaignPluginAppName.FortuneWheel, url:  PluginConfigureRoutePluginNames.FortuneWheel, icon: 'fortune-wheel-icon' },
  [CampaignPluginName.CouponBoxNotification]: { name: CampaignPluginAppName.CouponBoxNotification, url:  PluginConfigureRoutePluginNames.CouponBox, icon: 'coupon-box-icon' },
  [CampaignPluginName.NewsLetterNotification]: { name: CampaignPluginAppName.NewsLetterNotification, url:  PluginConfigureRoutePluginNames.NewsLetterNotification, icon: 'subscribe-box-icon' },
  [CampaignPluginName.FreeShipping]: { name: CampaignPluginAppName.FreeShippingPopup, url:  PluginConfigureRoutePluginNames.FreeShippingNotification, icon: 'free_shipping_icon' },
  [CampaignPluginName.SalesPopup]: { name: CampaignPluginAppName.SalesPopup, url:  PluginConfigureRoutePluginNames.SalesPopup, icon: 'sales_pop_icon' },
  [CampaignPluginName.ProductPopup]: { name: CampaignPluginAppName.ProductPopup, url:  PluginConfigureRoutePluginNames.ProductPopup, icon: 'product_pop_icon' },
}
export const pluginNameMap = {
  [PluginConfigureRoutePluginNames.RecentActivity]: { name: CampaignPluginAppName.RecentActivity, icon: 'social-proof-icon' },
  [PluginConfigureRoutePluginNames.AggregateActivity]: { name: CampaignPluginAppName.AggregateActivity, icon: 'social-proof-icon' },
  [PluginConfigureRoutePluginNames.LiveVisitorCount]: { name: CampaignPluginAppName.LiveVisitorCount, icon: 'social-proof-icon' },
  [PluginConfigureRoutePluginNames.AggregateVisitorCount]: { name: CampaignPluginAppName.AggregateVisitorCount, icon: 'social-proof-icon' },
  [PluginConfigureRoutePluginNames.FortuneWheel]: { name: CampaignPluginAppName.FortuneWheel, icon: 'fortune-wheel-icon' },
  [PluginConfigureRoutePluginNames.CouponBox]: { name: CampaignPluginAppName.CouponBoxNotification, icon: 'coupon-box-icon' },
  [PluginConfigureRoutePluginNames.NewsLetterNotification]: { name: CampaignPluginAppName.NewsLetterNotification, icon: 'subscribe-box-icon' },
  [PluginConfigureRoutePluginNames.FreeShippingNotification]: { name: CampaignPluginAppName.FreeShippingPopup, icon: 'free_shipping_icon' },
  [PluginConfigureRoutePluginNames.SalesPopup]: { name: CampaignPluginAppName.SalesPopup, icon: 'sales_pop_icon' },
  [PluginConfigureRoutePluginNames.ProductPopup]: { name: CampaignPluginAppName.ProductPopup, icon: 'product_pop_icon' },
}
export const campaignStepsMap = {
  [CampaignRouteStep.APPS]: 'Apps',
  [CampaignRouteStep.LANGUAGE]: 'Language',
  [CampaignRouteStep.DESIGN]: 'Design',
  [CampaignRouteStep.BEHAVIOR]: 'Timing',
  [CampaignRouteStep.DISPLAY]: 'Display Rules',
  [CampaignRouteStep.CAPTURE]: 'Capture Rules',
  [CampaignRouteStep.CONVERSIONS]: 'Captured Conversions',
  [CampaignRouteStep.REVIEW_AND_LAUNCH]: 'Review & Publish',
}
