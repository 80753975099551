<div class="box" [class.disabled]="isDisabled">
  <div
    *ngIf="!labelInside"
    class="label label-a"
    [ngClass]="{'label-on': value === false}"
    (click)="setValue($event,false)">
    {{labelOff}}
  </div>
  <div class="switch-box" [class.danger]="danger" [class._text]="labelInside" (click)="setValue($event, !value)">
    <div class="switch" [ngClass]="{'switch-on': value === true}">
      <small *ngIf="labelInside && labelOff && !value" class="inner-label _off">{{labelOff}}</small>
      <small *ngIf="labelInside && labelOn && value" class="inner-label _on">{{labelOn}}</small>
    </div>
  </div>
  <div
    *ngIf="!labelInside"
    class="label label-b"
    [ngClass]="{'label-on': value === true}"
    (click)="setValue($event, true)">
    {{labelOn}}
  </div>
</div>
