<ng-container [formGroup]="formGroup">
<ng-container formGroupName="data">

  <!-- If used within buy_x_get_y -->
  <div [ngClass]="{'_local-split-controls': minimumOption || productSelectorType === uiProductSelectorType.gets}">

    <!-- If "Customer buys" section -->
    <ng-template #customerBuysTpl>
      <!-- Switch between Amount and Quantity -->
      <ng-container [ngSwitch]="minimumOption">
        <div class="PluginConfig-Line" *ngSwitchCase="uiCouponMinimumRequirements.price">
          <label class="mr-3">Amount</label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">{{newCouponCodesService.currency}}</span>
            </div>
            <input type="number" min="0" class="form-control" formControlName="prerequisite_subtotal_min">
            <div class="invalid-feedback">
              Please provide a valid value
            </div>
          </div>
        </div>
        <div class="PluginConfig-Line" *ngSwitchCase="uiCouponMinimumRequirements.quantity">
          <label>Quantity</label>
          <input type="number" min="0" max="99999999" class="form-control"
            formControlName="prerequisite_quantity_range_min">
            <div class="invalid-feedback">
              Please provide a value between 0 and 99999999
            </div>
        </div>
      </ng-container>
    </ng-template>

    <!-- If "Customer gets" section -->
    <ng-container *ngIf="productSelectorType === uiProductSelectorType.gets; else customerBuysTpl">
      <div class="PluginConfig-Line">
        <label>Quantity</label>
        <input type="number" min="0" max="99999999" class="form-control" formControlName="customer_get_quantity">
        <div class="invalid-feedback">
          Please provide a value between 0 and 99999999
        </div>
      </div>
    </ng-container>

    <div class="PluginConfig-Line" formGroupName="_local">
      <label>{{label}}</label>
      <select class="form-control" [formControlName]="controlName" #targetSelection
        (change)="onUpdateTargetSelection($event)">
        <option *ngFor="let option of targetSelectionItems" [value]="option.value">{{option.title}}</option>
      </select>
    </div>
  </div>

  <ng-container [ngSwitch]="formGroup.getRawValue().data._local[controlName]">
    <div *ngSwitchCase="uiCouponTargetSelection.entitled_collections" class="PluginConfig-Line">
      <div class="d-flex align-items-center">
        <button class="pf-button filled blue mb-2" [class.is-hidden]="isEditMode"
                (click)="onShowProductSelectorClick(uiCouponTargetSelection.entitled_collections)">
          Select Collections
        </button>
        <div *ngIf="customError" class="text-danger ml-2 mb-2">
          {{customError}}
        </div>
      </div>
      <pf-products-output
        [productsListService]="productsListService"
        [emptyText]="'No Collection(s) Selected'"
        [noValidate]="noValidate"
      ></pf-products-output>
    </div>
    <div *ngSwitchCase="uiCouponTargetSelection.entitled_products" class="PluginConfig-Line">
      <div class="d-flex align-items-center">
        <button class="pf-button filled blue mb-2" [class.is-hidden]="isEditMode"
                (click)="onShowProductSelectorClick(uiCouponTargetSelection.entitled_products)">
          Select Products
        </button>
        <div *ngIf="customError" class="text-danger ml-2 mb-2">
          {{customError}}
        </div>
      </div>
      <pf-products-output
        [productsListService]="productsListService"
        [emptyText]="'No Product(s) Selected'"
        [noValidate]="noValidate"
      ></pf-products-output>
    </div>
  </ng-container>

</ng-container>
</ng-container>
