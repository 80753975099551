import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { StoreState } from '../../../store/store.state'
import { select, Store } from '@ngrx/store'
import { LoadingFilter } from '../../models/loading/loading-filter'
import { Logger } from '../../../core/services/logger.service'
import { getLoadingPendingList } from '../../../store/loading/loading.selectors'
import { Subscription, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { loadingLayout } from '../../animations/loadingLayout.animation'
import { LogLabel } from '../../models/logger/log-label.model'
import { CommonModule } from '@angular/common'
import { LoadingSpinnerComponent } from '../loading-spinner/loading-spinner.component'

@Component({
    selector: 'pf-loading-overlay',
    templateUrl: './loading-overlay.component.html',
    styleUrls: ['./loading-overlay.component.scss'],
    animations: [loadingLayout],
    standalone: true,
    imports: [
      CommonModule,
      LoadingSpinnerComponent
    ]
})
export class LoadingOverlayComponent implements OnInit, OnDestroy {
  @Input() filter: LoadingFilter = null
  @Input() isFixed: boolean = true
  @Input() text: string = null

  subscription = new Subscription()
  isLoading: boolean = true
  constructor(
    private store: Store<StoreState>,
    private logger: Logger,
  ) {
  }

  isLoading$: Observable<boolean>

  ngOnInit() {
    const globalIgnore = ['CampaignsAnalytics']
    if (this.filter?.ignore?.length > 0) {
      this.filter = {
        ...this.filter,
        ignore: [...new Set([...this.filter.ignore, ...globalIgnore])],
      }
    } else {
      this.filter = {
        ...this.filter,
        ignore: globalIgnore,
      }
    }

    this.subscription.add(
      this.store.pipe(
        select(getLoadingPendingList),
        map((next) => {
          let list = [...next]
          if (this.filter) {
            if (this.filter.only) {
              list = list.filter(pendingValue => this.filter.only.includes(pendingValue))
            } else if (this.filter.ignore) {
              list = list.filter(pendingValue => !this.filter.ignore.includes(pendingValue))
            }
          }
          this.logger.log(LogLabel.LoadingComponent, `Pending List (${list.length}):`, [...list])
          return list.length > 0
        }),
      ).subscribe(result => {
        setTimeout(() => {
          this.isLoading = result
        })
      })
    )
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }
}
