<div class="PlansPromo">
  <div class="PlansPromo-Content">
    <form class="PlansPromo-Form" [formGroup]="form" (submit)="onSubmit()">
      <div class="PlansPromo-Label">
        {{coupon && coupon.field_label || 'Cyber Week Sale'}}
        <div class="PlansPromo-Error" *ngIf="couponInvalid && form.pristine">
          {{ couponValidityMessage || 'Code is invalid' }}
        </div>
      </div>
      <div class="PlansPromo-InputGroup">
        <div class="PlansPromo-Result" *ngIf="coupon">{{coupon && coupon.name}}</div>
        <input type="text" class="PlansPromo-Input" formControlName="code" (input)="changeCode()" [placeholder]="coupon && coupon.field_placeholder || 'Enter your Coupon Code'">
        <button type="submit" class="PlansPromo-Button" [disabled]="loading">
          <ng-container *ngIf="loading; else submit">
            <div class="Circular-Loader"></div>
          </ng-container>
          <ng-template #submit>{{coupon && coupon.field_button_label || 'Apply'}}</ng-template>
        </button>
      </div>
    </form>
  </div>
</div>
