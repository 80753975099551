import { StoreState } from '../store.state'
import { CouponBoxThemeConfigDefault, NewsletterThemeConfigDefault } from '../../shared/models/campaign/coupon-box-notification.model'
import { Campaign } from '../../shared/models/campaign/campaign'

export const getSelectedCampaign = (state: StoreState): Campaign => {
  return state.campaign.selectedCampaign
}
export const getSelectedCampaignName = (state: StoreState): string => {
  return state.campaign.selectedCampaign && state.campaign.selectedCampaign.name
}
export const getCampaignList = (state: StoreState) => {
  return state.campaign.list
}

export const getCampaignWheelThemes = (state: StoreState) => {
  return state.campaign.wheel.themes
}

export const getCampaignCBThemes = (state: StoreState): CouponBoxThemeConfigDefault[] => {
  return state.campaign.cb.themes
}

export const getCampaignNewsletterThemes = (state: StoreState): NewsletterThemeConfigDefault[] => {
  return state.campaign.newsletter.themes
}

export const getCampaignWheelPatterns = (state: StoreState) => {
  return state.campaign.wheel.patterns
}
export const getSelectedCampaignEvents = (state: StoreState) => {
  return state.campaign.selectedCampaignEventsResult
}
export const getSelectedCampaignEventsList = (state: StoreState) => {
  return state.campaign.selectedCampaignEventsResult.events
}
export const getSearchedCampaignList = (state: StoreState) => {
  const { searchQuery, list } = state.campaign
  return searchQuery.length ?
    list.filter(
      campaign => campaign.name
        .toLowerCase()
        .indexOf(searchQuery.toLowerCase()) > -1)
    : list
}
export const getSearchQuery = (state: StoreState) => {
  return state.campaign.searchQuery
}
export const getCampaignError = (state: StoreState) => {
  return state.campaign.error
}
export const getCampaignLimitError = (state: StoreState) => {
  return state.campaign.limitError
}
export const getCampaignTemplateError = (state: StoreState) => {
  return state.campaign.templateError
}
export const getCampaignUpdateTypeConfigRequestStatus = (state: StoreState) => {
  return state.campaign.requests.updateTypeConfig
}
