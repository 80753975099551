export const newsLetterCustomJsTemplate =
`/* Add custom script to Proof Factor Wheel Events listeners below */

// window.addEventListener('prooffactor:newsletter:open', (e) => {
//     console.log('prooffactor:newsletter:open', {
//         id: e.detail.id
//     });
// });

// window.addEventListener('prooffactor:newsletter:close', (e) => {
//     console.log('prooffactor:newsletter:close', {
//         id: e.detail.id,
//         email: e.email,
//         phone: e.formattedPhoneNumber,
//         first_name: e.firstName,
//         last_name: e.lastName,
//         birthday: e.birthday,
//         optin: this.optin
//     });
// });

// window.addEventListener('prooffactor:newsletter:submit', (e) => {
//     console.log('prooffactor:newsletter:submit', {
//         id: e.detail.id,
//         email: e.email,
//         phone: e.formattedPhoneNumber,
//         first_name: e.firstName,
//         last_name: e.lastName,
//         birthday: e.birthday,
//         optin: this.optin
//     });
// });
`
