<div class="Spinner">
  <div class="Spinner__Blob"></div>
  <div class="Spinner__Blob"></div>
  <div class="Spinner__Blob"></div>

  <div class="Spinner__Title" *ngIf="title" [innerHTML]="title"></div>
  <div class="Spinner__Subtitle" *ngIf="subtitle" [innerHTML]="subtitle"></div>
  <div class="Spinner__Text" *ngIf="text" [innerHTML]="text"></div>

</div>
