import { Injectable, OnDestroy } from '@angular/core'
import { FeatureTypeModal, VoteFeatureModalComponent } from '../../../../modals/vote-feature.modal/vote-feature.modal.component'
import { GettingStartedService } from '../../../../../../core/services/getting-started.service'
import { ApiService } from '../../../../../../core/services/api/api.service'
import { Store } from '@ngrx/store'
import { StoreState } from '../../../../../../store/store.state'
import { Subscription, finalize } from 'rxjs'
import { ReceiveModalType, ReceiveRequestModalComponent } from '../../../../modals/receive-request.modal/receive-request.modal.component'
import { HideLoading, ShowLoading } from '../../../../../../store/loading/loading.actions'
import { MatDialog } from '@angular/material/dialog'

@Injectable()
export class OneMainNavService implements OnDestroy {
  private subscription = new Subscription()

  constructor(
    private gettingStartedService: GettingStartedService,
    private apiService: ApiService,
    private dialog: MatDialog,
    private store: Store<StoreState>,
  ) { }

  public requestFeature(type: FeatureTypeModal, isWaitingList = false) {
    const status = this.gettingStartedService.getFeatureVoteStatus()
    if (status[type]?.completed && status[type]?.url) {
      this.openVotedModal(status[type].url, status[type].voteCount || 0, isWaitingList, type)
      return
    }
    const dialog = this.dialog.open(VoteFeatureModalComponent, {
      width: '440px',
      data: { featureType: type, isWaitingList: isWaitingList },
    })
    this.subscription.add(
      dialog.afterClosed().subscribe((data) => {
        if (data && data.request) {
          const req = data.request
          const { type, description } = req
          const payload = {
            value: description
          }
          this.store.dispatch(new ShowLoading('voteAndCommentToCanny'))
          this.subscription.add(this.apiService.post(`/v1/me/canny/feature_requests/${type}/vote_and_comment`, payload)
            .pipe(finalize(() => this.store.dispatch(new HideLoading('voteAndCommentToCanny'))))
            .subscribe(
              res => {
                if (res && res.created === true) {
                  const url = res?.data?.url
                  const voteCount = res?.data?.score
                  this.openVotedModal(url, voteCount, isWaitingList, type)
                  this.updateVoteStatus(type, url, voteCount)
                } else {
                  console.error('Something went wrong to post request to canny.')
                }
              },
              error => {
                console.error('Unable post request to canny', error)
              }
            ))
        }
      })
    )
  }

  updateVoteStatus(key, url, voteCount) {
    this.subscription.add(
      this.gettingStartedService.updateFeatureVoteStatus({[key]: {
        completed: true,
        voteCount,
        url
      }}).subscribe()
    )
  }

  openVotedModal(url, voteCount, isWaitingList, featureType) {
    this.dialog.open(ReceiveRequestModalComponent, {
      width: '560px',
      data: {
        type: isWaitingList ? ReceiveModalType.WaitingList : ReceiveModalType.Vote,
        url,
        voteCount,
        featureType
      }
    })
  }

  ngOnDestroy(): void {
    this.dialog.closeAll()
    this.subscription.unsubscribe()
  }
}
