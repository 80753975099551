import {BehaviorSubject, Observable} from 'rxjs'

export class PubSub<T> {
  private subject: BehaviorSubject<T>

  constructor(value: T) {
    this.subject = new BehaviorSubject<any>(value)
  }

  set(data: T) {
    this.subject.next(data)
  }

  get(): T {
    return this.subject.getValue()
  }

  asObservable(): Observable<T> {
    return this.subject.asObservable()
  }
}
