import { CommonModule } from '@angular/common'
import { Component, CUSTOM_ELEMENTS_SCHEMA, Input } from '@angular/core'

@Component({
    selector: 'one-icon',
    templateUrl: './one-icon.component.html',
    styleUrls: ['./one-icon.component.scss'],
    standalone: true,
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
      CommonModule,
    ]
})

export class OneIconComponent {

  @Input() name: string = ''
  @Input() src: string = ''
  @Input() title: string = ''
  @Input() alignment: 'left' | 'right' | 'center' = null

  get alignmentClass() {
    if (this.alignment) {
      return `_${this.alignment}`
    }
  }

  constructor() { }

}
