import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core'
import * as _ from 'lodash'
import { UserInfo } from '../../../../store/user/user.state'
import { SubscriptionPlan } from '../../../models/subscription-plan.model';
import { SegmentAnalyticsService } from '../../../services/segment-analytics.service'
import { LoadingOverlayComponent } from '../../loading-overlay/loading-overlay.component';
import { OneIconComponent } from '../../one-icon/one-icon.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface UsageChargePlanModalData {
  userInfo: UserInfo,
  plan: SubscriptionPlan,
  trial: number
}

@Component({
    templateUrl: './plan-selected.modal.component.html',
    styleUrls: ['./plan-selected.modal.component.scss'],
    standalone: true,
    imports: [
      CommonModule,
      OneIconComponent,
      MatTooltipModule,
      LoadingOverlayComponent,
    ]
})
export class PlanSelectedModalComponent {
  showFreeDetails = false
  showPayDetails = false
  userAgreed = false
  freeCount: number
  paidCount: number
  allottedCount: number
  tierSize: number
  tierPrice: number
  cappedPrice: number
  totalTiers: number
  tiers: Array<any> = []
  visibleTiers = 6

  constructor(public dialogRef: MatDialogRef<PlanSelectedModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: UsageChargePlanModalData,
              private segmentAnalyticsService: SegmentAnalyticsService,
  ) {
    const shopInfo = this.data.userInfo.shop
    const subscrInfo = this.data.userInfo.subscription
    const plan = this.data.plan
    this.freeCount = _.max([plan.max_unique_vists, subscrInfo.max_unique_visits_override]) + subscrInfo.rewarded_visitor_count
    this.paidCount = shopInfo.usage_charge_capped_visitors
    this.allottedCount = _.max([subscrInfo.unique_visits_limit, this.freeCount + this.paidCount])
    this.tierSize = shopInfo.usage_charge_increment
    this.tierPrice = shopInfo.usage_charge_increment_price
    this.cappedPrice = shopInfo.usage_charge_capped_amount
    this.totalTiers = 1 + _.ceil(this.paidCount / this.tierSize)
    this.tiers[0] = {
      start: 0,
      end: this.freeCount,
      price: 'FREE',
      total: 'FREE'
    }
    let prevViews = this.freeCount
    let prevTotal = 0;
    for (let i = 1; i < this.totalTiers; i++) {
      const curTierSize = prevViews + this.tierSize <= this.allottedCount ? this.tierSize : this.allottedCount - prevViews
      const curTierPrice = this.tierPrice * i < this.cappedPrice ? this.tierPrice : this.cappedPrice - this.tierPrice * (i - 1)

      prevTotal += curTierPrice
      this.tiers[i] = {
        start: prevViews,
        end: prevViews + curTierSize,
        price: curTierPrice,
        total: prevTotal
      }
      prevViews += curTierSize
    }
    this.tiers.push({
      start: prevViews,
      price: 'Upgrade',
      total: 'Upgrade'
    })
  }

  agree() {
    // this.userAgreed = true
    this.segmentAnalyticsService.track('Agree to Plan', {
      state: {
        onboarding: true,
        step: 'agree_to_plan',
        group: 'choose_plan',
      },
    })
    this.dialogRef.close({ purchase: true })
  }

  back() {
    this.userAgreed = false
  }

  letsGo() {
    this.dialogRef.close({ purchase: true })
  }

  isNumber(num) {
    return Number(num) === num
  }
}
