import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { filter, finalize, map, switchMap, tap } from 'rxjs/operators'
import { Logger } from './logger.service'
import { StoreState } from '../../store/store.state'
import { select, Store } from '@ngrx/store'
import { Router } from '@angular/router'
import { LoadingOverlayService } from './loading-overlay.service'
import {
  allowedProofPlugins,
  ProofPlugin,
  ProofPluginError,
  ProofPluginPreview,
} from '../../shared/models/proof-plugin.model'
import moment from 'moment'
import { WheelPattern } from '../../shared/models/wheel-pattern.model'
import { UserService } from './user.service'
import { TemplateHelper, templateHelper } from '../../shared/helpers/template.helper'
import { HideLoading, ResetLoading, ShowLoading } from '../../store/loading/loading.actions'
import { ReadCampaignRequest, UpdateCampaignSuccess } from '../../store/campaign/campaign.actions'
import { getUserShopType } from '../../store/user/user.selectors'
import {
  getCampaignCBThemes,
  getCampaignNewsletterThemes,
  getCampaignWheelThemes,
  getSelectedCampaignEventsList,
} from '../../store/campaign/campaign.selectors'
import { CampaignCaptureSource } from '../../shared/models/campaign/campaign-capture-source'
import { CampaignMatchingListItem } from '../../shared/models/campaign/campaign-matching-list-item'
import { CampaignUrlService } from './campaign-url.service'
import {
  PluginConfigureRoutePluginNames,
  PluginConfigureStepName,
} from '../../pages/campaign-page/plugin-configure/models/plugin-configure.models'

import * as _ from 'lodash'
import { ErrorsService } from './errors.service'
import { UserShopType } from '../../shared/models/user/user-shop-type.model'
import { DisplayMatchType, DisplayRule } from '../../shared/models/campaign/display-rules.model'
import { CaptureMatchType, CaptureRule } from '../../shared/models/campaign/capture-rules.model'
import {
  CouponBoxThemeConfigDefault,
  NewsletterThemeConfigDefault,
} from '../../shared/models/campaign/coupon-box-notification.model'
import { BrandingService } from './branding.service'
import { AggregateActivity } from '../interfaces/campaign/aggregate-activity.interface'
import { Product, ProductVariant } from '../../shared/modules/products-selector/models/Product'
import { SMSKeyword } from '../../shared/models/sms/keyword.model'
import {
  FortuneWheelPreviewParams,
  FortuneWheelThemeConfig,
} from '../../pages/campaign-page/plugins/fortune-wheel/models/fortune-wheel.model'
import { CustomSnackbarService } from '../../shared/modules/custom-snackbar/custom-snackbar.service'
import {
  Campaign,
  CampaignCaptureType,
  CampaignDisplayStatus,
  CampaignPluginAppName,
  CampaignPluginName,
  CampaignState,
  CampaignValidityMessage,
  CampaignValidityMessageName,
} from '../../shared/models/campaign/campaign'
import { ApiCampaignService } from './api/api-campaign.service'
import { LogLabel } from '../../shared/models/logger/log-label.model'
import { WidgetFortuneWheelTheme, WidgetFortuneWheelThemeExample } from '../../shared/models/widget/fortune-wheel.model'
import { WidgetNotificationParams, WidgetNotificationViewType } from '../../shared/models/widget/notification.model'
import {
  InfoPopupNotificationParams,
  ProductPopupProductsConfig,
} from '../../shared/models/campaign/info-popup-notification.model'
import { RouteHeaderUrl } from '../../shared/components/one-header/header-navigation.model'
import { COUNTRIES_LIST } from '../../shared/countriesList'

@Injectable()
export class CampaignService {
  userHasShop = null
  shopType: string
  isPlatformShop: boolean

  public defaultWheelThemes: WidgetFortuneWheelTheme[] = [<WidgetFortuneWheelTheme>WidgetFortuneWheelThemeExample]
  public defaultWheelPatterns: WheelPattern[] = []

  exampleCount = Math.round(Math.random() * 100)
  capturedEvent: any

  bcbThemesList: CouponBoxThemeConfigDefault[] = []
  nlThemesList: NewsletterThemeConfigDefault[] = []
  fwThemesList: FortuneWheelThemeConfig[] = []
  brandingName: string

  readonly allowedEventProperties = [
    'first_name',
    'created_at',
    'city',
    'state',
    'state_code',
    'country',
    'country_code',
    'image_url',
    'rendered_content',
    'product_name',
    'variant_name',
    'render_event_image_url',
    'render_event_target_url',
    'render_event_data',
  ]
  readonly forbiddenEventCustomDataProperties = ['visitor_id', 'page']

  constructor(
    private apiCampaignService: ApiCampaignService,
    private logger: Logger,
    private userService: UserService,
    private errorsService: ErrorsService,
    private loadingService: LoadingOverlayService,
    private store: Store<StoreState>,
    private router: Router,
    private campaignUrlService: CampaignUrlService,
    private brandingService: BrandingService,
    private snackbarService: CustomSnackbarService,
    private loadingOverlayService: LoadingOverlayService,
  ) {

    this.brandingName = this.brandingService.getBrandingData().name

    this.store.pipe(
      select(getCampaignCBThemes),
      filter(next => !!next),
    ).subscribe((themes) => {
      this.bcbThemesList = themes
    })

    this.store.pipe(
      select(getCampaignWheelThemes),
      filter(next => !!next),
    ).subscribe(themes => {
      this.fwThemesList = themes
    })

    this.store.pipe(
      select(getCampaignNewsletterThemes),
      filter(next => !!next),
    ).subscribe(themes => {
      this.nlThemesList = themes
    })

    this.store.pipe(select(getUserShopType)).subscribe(next => {
      this.shopType = next
      this.isPlatformShop = next === UserShopType.ShopifyShop || next === UserShopType.BigCommerceShop
      this.userHasShop = this.shopType !== null
    })

    this.store.pipe(select(getSelectedCampaignEventsList)).subscribe(next => {
      this.capturedEvent = next && next.length > 0 ? this.prepareEvent(next[0]) : null
    })

    this.store.pipe(select(getCampaignWheelThemes), filter(next => !!next)).subscribe(next => {
      this.defaultWheelThemes = next
    })
  }

  fetchEvents(params: { campaignId: string; shop: string; locale?: string }): Observable<object> {
    return params.shop !== undefined && params.shop && params.shop.length
      ? this.previewOrdersOrRandomProduct(params.campaignId, params.locale)
      : this.apiCampaignService.getUserActivityPreviewEvents(params.campaignId)
  }

  previewOrdersOrRandomProduct(campaignId: string, locale?: string): Observable<object> {
    return this.apiCampaignService.getUserActivityPreviewOrders(campaignId, locale).pipe(
      switchMap(res => {
        // If no orders
        if (!_.get(res, 'orders.length')) {
          // return a fake order with random product
          return this.apiCampaignService.getShopProducts(1).pipe(
            map(result => {
              const products = _.get(result, 'products', []) as Product[]
              if (products.length) {
                const productsWithImages = products.filter(_product => {
                  return typeof _product.images && _product.images.length > 0 && _product.images[0].compact_url === 'string' && _product.images[0].compact_url.length
                })
                if (!productsWithImages.length) {
                  return { orders: [] }
                }
                const product: Product = _.sample(productsWithImages)
                const variant: ProductVariant = _.get(product, 'variants[0]')
                return {
                  orders: [
                    {
                      created_at: moment().format(),
                      line_item: {
                        id: product.id,
                        unique_id: product.unique_id,
                        name: product.name,
                        created_at: moment().format(),
                      },
                      event_data: {
                        first_name: 'Jessica',
                        last_name: 'Alba',
                        company: 'Proof Factor LLC',
                        city: 'Los Angeles',
                        state: 'California',
                        state_code: 'CA',
                        country: 'US',
                        product_name: product.name,
                        product_title: product.name,
                        variant_name: variant ? variant.name : product.name,
                        image_url: product.images && product.images.length > 0 && product.images[0].compact_url || '',
                      }
                    },
                  ],
                }
              } else {
                return { orders: [] }
              }
            }),
          )
        } else {
          return of(res)
        }
      }),
    )
  }

  isCampaignsLimitReached(err) {
    let reached = false
    const failure = this.errorsService.extractCampaignError(err)
    this.logger.log(LogLabel.CampaignService, 'isCampaignsLimitReached: ', failure)
    if (failure && failure.errors && Array.isArray(failure.errors)) {
      const campaignsLimitReached = failure.errors.filter(error => error.code === 'over_active_campaigns_limit')
      if (campaignsLimitReached && campaignsLimitReached.length > 0) {
        reached = true
      }
    }
    return reached
  }

  isUserActivityNotificationActive(campaign: Campaign) {
    return this.isPluginActive(campaign, CampaignPluginName.RecentActivity)
  }

  isLiveUserCountNotificationActive(campaign: Campaign) {
    return this.isPluginActive(campaign, CampaignPluginName.LiveVisitorCount)
  }

  isAggregateUserCountNotificationActive(campaign: Campaign) {
    return this.isPluginActive(campaign, CampaignPluginName.AggregateVisitorCount)
  }

  isAggregateActivityNotificationActive(campaign: Campaign) {
    return this.isPluginActive(campaign, CampaignPluginName.AggregateActivity)
  }

  isPluginActive(campaign: Campaign, type: CampaignPluginName) {
    return campaign && campaign[type] && campaign[type].active
  }

  getURLPattern(): string {
    return '(?:http(s)?:\\/\\/)?(www)?([\\w.-]+(?:\\.[\\w\\.-]+)+|localhost)[\\w\\-\\._~:\\/?#[\\]@!\\$&\'\\(\\)\\*\\+,;=.]*'
  }

  // API calls
  getAllCampaigns() {
    return this.apiCampaignService
      .getCampaigns()
      .pipe(map(data => this._sortCampaignsByDate(data.campaigns, 'updated_at')))
  }

  getActiveCampaigns() {
    return this.apiCampaignService
      .getActiveCampaigns()
      .pipe(map(data => this._sortCampaignsByDate(data.campaigns, 'updated_at')))
  }

  getCampaignsByStatus(
    status: CampaignDisplayStatus,
    full: boolean = false,
    show_connections: boolean = false,
    page = 1,
  ) {
    return this.apiCampaignService
      .getCampaigns(status, full, show_connections, page)
      .pipe(
        finalize(() => this.store.dispatch(new HideLoading('FetchCampaigns'))),
        map(data => this._sortCampaignsByDate(data.campaigns, 'updated_at')))
  }

  getCampaignsByStatusPaginated(
    status: CampaignDisplayStatus,
    full: boolean = false,
    show_connections: boolean = false,
    page = 1,
    limit = 25,
    searchTerm = '',
  ) {
    return this.apiCampaignService
      .getCampaigns(status, full, show_connections, page, limit, searchTerm)
      .pipe(
        finalize(() => this.store.dispatch(new HideLoading('FetchCampaigns'))),
        map(data => {
          return {
            ...data,
            campaigns: this._sortCampaignsByDate(data.campaigns, 'updated_at'),
          }
        }),
      )
  }

  getCampaignById(id: string) {
    this.logger.log(LogLabel.CampaignService, 'getCampaignById: ', id)
    return this.apiCampaignService.getCampaign(id).pipe(
      map(campaign => {
        this.logger.log(LogLabel.CampaignService, 'got campaign: ', campaign)
        return this._normalizeCampaign(campaign)
      }),
    )
  }

  getAggregateActivityByCampaignId(campaign_id: string): Observable<AggregateActivity> {
    this.logger.log(LogLabel.CampaignService, 'getAggregateActivityByCampaignId: ', campaign_id)
    return this.apiCampaignService.getAggregateActivityByCampaignId(campaign_id, CampaignPluginName.AggregateActivity)
  }

  getCampaignDisplayConfigs(id: string) {
    return this.apiCampaignService.getDisplayConfigs(id)
  }

  createCampaign(campaign: Campaign): Observable<Campaign> {
    return this.apiCampaignService.postCampaign(campaign)
  }

  saveCampaignDisplayConfigs(campaignId, displayItemsConfig: DisplayRule[]): Observable<Campaign> {
    return this.apiCampaignService.postDisplayConfigs(campaignId, displayItemsConfig)
  }

  saveCampaign(_campaign: Campaign): Observable<Campaign> {
    const campaign = _.cloneDeep(_campaign)
    const {
      display_configs_attributes,
      branding_wording,
      branding_name,
      remove_branding,
      disable_powered_by_link,
      name,
      state,
      id,
      excluded_countries,
      targeted_countries,
      filter_by_countries,
      schedule,
      filter_by_schedule,
    } = campaign
    const config = campaign.config ? this._normalizeConfig(campaign.config) : {}
    campaign.display_configs_attributes = []
    const preparedCampaign = {
      id,
      config,
      state,
      name,
      display_configs_attributes,
      branding_wording,
      branding_name,
      remove_branding,
      disable_powered_by_link,
      excluded_countries,
      targeted_countries,
      filter_by_countries,
      schedule,
      filter_by_schedule
    } as Campaign

    if (Object.keys(config).length === 0) {
      delete preparedCampaign.config
    }

    this.logger.info(LogLabel.CampaignService, `/v1/campaigns/${campaign.id}`)
    this.logger.info(LogLabel.CampaignService, `body: ${JSON.stringify(preparedCampaign)}`)
    return this.apiCampaignService.putCampaign(preparedCampaign)
      .pipe(
        map(next => {
          this.logger.log(LogLabel.CampaignService, 'saved_campaign_raw: ', { ...next })
          return this._normalizeCampaign(next)
        }),
        tap((next) => {
          this.logger.log(LogLabel.CampaignService, 'saved_campaign: ', { ...next })
          this.snackbarService.showSuccess({ title: 'Saved', text: 'Campaign Configuration' })
          this.store.dispatch(new UpdateCampaignSuccess(next))
        }),
      )
  }

  renameCampaign(campaignId: string, name: string) {
    this.logger.info(LogLabel.CampaignService, `Rename campaign (${campaignId}) to "${name}"`)
    return this.apiCampaignService
      .putCampaign({ id: campaignId, name } as Campaign)
      .pipe(
        map(next => this._normalizeCampaign(next)),
        tap((next) => {
          this.logger.log(LogLabel.CampaignService, 'Rename campaign Done: ', { ...next })
          this.snackbarService.showSuccess({ title: 'Renamed', text: 'Campaign' })
          this.store.dispatch(new UpdateCampaignSuccess(next))
        }),
      )
  }

  setCampaignActive(id: string, state: string = 'on', quiet: boolean = false) {
    return this.apiCampaignService
      .patchCampaign({ id, state: state } as Campaign)
      .pipe(
        map(next => this._normalizeCampaign(next)),
        tap((next) => {
          this.logger.log(LogLabel.CampaignService, 'saved_campaign: ', { ...next })
          if (!quiet) {
            // this causes selectedCampaign to change
            // so need quiet version for some cases
            this.store.dispatch(new UpdateCampaignSuccess(next))
            this.snackbarService.showSuccess({
              title: state === CampaignState.On ? 'Published' : state === CampaignState.Archived ? 'Archived' : 'Unpublished',
              text: 'Campaign',
            })
          }
        }),
      )
  }

  updatePluginConfig(
    campaignId: string,
    pluginName: CampaignPluginName,
    config: any,
    active: boolean,
    capture_configs_attributes,
    capture_source: CampaignCaptureSource,
    data_sources_attributes,
    capture_type: CampaignCaptureType,
    sms_keyword: SMSKeyword,
    products?: Product[] | ProductPopupProductsConfig,
    sync_with_shopify?,
    product_filter?,
    unlayer_email_template?,
    slices?,
    reward?,
    coupon_required?
  ) {
    const data = {
      config,
      active,
      capture_source,
      data_sources_attributes,
      capture_type,
    }
    if (capture_configs_attributes) {
      data['capture_configs_attributes'] = capture_configs_attributes
    }
    if (reward !== undefined) {
      data['reward'] = reward
    }
    if (sms_keyword !== undefined) {
      data['sms_keyword'] = sms_keyword
    }
    if (products !== undefined) {
      data['products'] = products
    }
    if (sync_with_shopify !== undefined) {
      data['sync_with_shopify'] = sync_with_shopify
    }
    if (product_filter !== undefined) {
      data['product_filter'] = product_filter
    }
    if (unlayer_email_template !== undefined) {
      data['unlayer_email_template'] = unlayer_email_template
    }
    if (slices !== undefined) {
      data['slices'] = slices
    }
    if (coupon_required !== undefined) {
      data['coupon_required'] = coupon_required
    }
    return this.apiCampaignService.putPlugin(campaignId, pluginName, data)
  }

  removeCampaign(campaignId: string) {
    return this.apiCampaignService.deleteCampaign(campaignId)
      .pipe(
        tap(() => {
          this.snackbarService.showSuccess({ title: 'Deleted', text: 'Campaign' })
        }),
      )
  }

  duplicateCampaign(campaignId: string, campaignName: string) {
    return this.apiCampaignService.duplicateCampaign(campaignId, campaignName)
      .pipe(
        tap(() => {
          this.snackbarService.showSuccess({ title: 'Duplicated', text: 'Campaign' })
        }),
      )
  }

  createCaptureWebhook(campaignId) {
    return this.apiCampaignService.postUserActivityWebhook(campaignId, 'default')
  }

  getPluginEmails(id, page = 1, limit: number, pluginName: CampaignPluginName) {
    return this.loadingService.wrap(this.apiCampaignService.getPluginEmails(id, page, limit, pluginName), pluginName)
  }

  exportAllSubscriersToEmail(email) {
    return this.loadingService.wrap(this.apiCampaignService.postAllEmailsExport(email))
  }

  exportPluginEmails(id: string, email: string, pluginName: CampaignPluginName) {
    return this.loadingService.wrap(this.apiCampaignService.postPluginEmailsExport(id, email, pluginName))
  }

  navigateToCampaign(id: string) {
    this.store.dispatch(new ResetLoading())
    this.store.dispatch(new ReadCampaignRequest(id))
    this.router.navigateByUrl(`/${RouteHeaderUrl.popups}/${RouteHeaderUrl.campaigns}/${id}`)
  }

  // Private methods
  private _normalizeCampaign(campaign) {
    this.logger.log(LogLabel.CampaignService, '_normalizeCampaign: ', campaign)
    if (campaign && campaign.config) {
      campaign.config = this._normalizeConfig(campaign.config)
      Object.values(CampaignPluginName).forEach(type => {
        if (campaign[type]) {
          campaign[type].config = this._normalizeConfig(campaign[type].config)
        }
      })
    }
    return campaign
  }

  private _normalizeConfig(config) {
    return _.get(config, 'v1', config)
  }

  private _sortCampaignsByDate(campaigns, dateField) {
    return campaigns.sort((a, b) => {
      const parseA = Date.parse(a[dateField])
      const parseB = Date.parse(b[dateField])
      return parseA > parseB ? -1 : parseA < parseB ? 1 : 0
    })
  }

  getPluginTitle(type: CampaignPluginName | string): string {
    switch (type) {
      case CampaignPluginName.RecentActivity:
        return this.userHasShop ? CampaignPluginAppName.RecentSalesPop : CampaignPluginAppName.RecentActivity
      case CampaignPluginName.AggregateActivity:
        return this.userHasShop ? CampaignPluginAppName.AggregateSalesPop : CampaignPluginAppName.AggregateActivity
      case CampaignPluginName.LiveVisitorCount:
        return CampaignPluginAppName.LiveVisitorCount
      case CampaignPluginName.AggregateVisitorCount:
        return CampaignPluginAppName.AggregateVisitorCount
      case CampaignPluginName.FortuneWheel:
        return CampaignPluginAppName.FortuneWheel
      case CampaignPluginName.CouponBoxNotification:
        return CampaignPluginAppName.CouponBoxNotification
      case CampaignPluginName.NewsLetterNotification:
        return CampaignPluginAppName.NewsLetterNotification
      case CampaignPluginName.FreeShipping:
        return CampaignPluginAppName.FreeShippingPopup
      case CampaignPluginName.SalesPopup:
        return CampaignPluginAppName.SalesPopup
      case CampaignPluginName.ProductPopup:
        return CampaignPluginAppName.ProductPopup
    }
    return 'Untitled'
  }

  getPluginRoute(type: CampaignPluginName | string): string {
    switch (type) {
      case CampaignPluginName.RecentActivity:
        return PluginConfigureRoutePluginNames.RecentActivity
      case CampaignPluginName.AggregateActivity:
        return PluginConfigureRoutePluginNames.AggregateActivity
      case CampaignPluginName.LiveVisitorCount:
        return PluginConfigureRoutePluginNames.LiveVisitorCount
      case CampaignPluginName.AggregateVisitorCount:
        return PluginConfigureRoutePluginNames.AggregateVisitorCount
      case CampaignPluginName.FortuneWheel:
        return PluginConfigureRoutePluginNames.FortuneWheel
      case CampaignPluginName.CouponBoxNotification:
        return PluginConfigureRoutePluginNames.CouponBox
      case CampaignPluginName.NewsLetterNotification:
        return PluginConfigureRoutePluginNames.NewsLetterNotification
      case CampaignPluginName.FreeShipping:
        return PluginConfigureRoutePluginNames.FreeShippingNotification
      case CampaignPluginName.SalesPopup:
        return PluginConfigureRoutePluginNames.SalesPopup
      case CampaignPluginName.ProductPopup:
        return PluginConfigureRoutePluginNames.ProductPopup
    }
  }

  getPluginPreview(type: CampaignPluginName): ProofPluginPreview {
    switch (type) {
      case CampaignPluginName.RecentActivity:
        return ProofPluginPreview.Notification
      case CampaignPluginName.LiveVisitorCount:
        return ProofPluginPreview.Notification
      case CampaignPluginName.AggregateVisitorCount:
        return ProofPluginPreview.Notification
      case CampaignPluginName.FortuneWheel:
        return ProofPluginPreview.Wheel
      case CampaignPluginName.CouponBoxNotification:
        return ProofPluginPreview.Coupon
      case CampaignPluginName.NewsLetterNotification:
        return ProofPluginPreview.Newsletter
      case CampaignPluginName.FreeShipping:
      case CampaignPluginName.SalesPopup:
      case CampaignPluginName.ProductPopup:
        return ProofPluginPreview.InfoPopup
    }
    return ProofPluginPreview.Notification
  }

  getPluginErrors(name: CampaignPluginName, campaign: Campaign): ProofPluginError[] {
    switch (name) {
      case CampaignPluginName.FortuneWheel:
        return this.getWheelPluginErrors(campaign)
      case CampaignPluginName.LiveVisitorCount:
        return this.getLiveUserCountPluginErrors(campaign)
      case CampaignPluginName.RecentActivity:
        return this.getUserActivityPluginErrors(campaign)
      case CampaignPluginName.AggregateActivity:
        return this.getAggregateActivityPluginErrors(campaign)
      case CampaignPluginName.CouponBoxNotification:
        return this.getCouponBoxPluginErrors(campaign)
      case CampaignPluginName.NewsLetterNotification:
        return this.getNewsletterPluginErrors(campaign)
      case CampaignPluginName.FreeShipping:
      case CampaignPluginName.ProductPopup:
      case CampaignPluginName.SalesPopup:
        return this.getInfoPopupPluginErrors(campaign, name)
    }
    return []
  }

  private getWheelPluginErrors(campaign: Campaign): ProofPluginError[] {
    const validity = campaign[CampaignPluginName.FortuneWheel].validity
    const errors: ProofPluginError[] = []
    validity.messages
      .filter((message: CampaignValidityMessage) => message.type === 'error')
      .forEach((message: CampaignValidityMessage) => {
        switch (message.name) {
          case CampaignValidityMessageName.NoSMSKeywordSet: {
            errors.push({
              text: 'SMS Keyword is not set',
              resolveLink: this.campaignUrlService.campaignConfigurePluginStep(
                campaign.id,
                PluginConfigureRoutePluginNames.FortuneWheel,
                PluginConfigureStepName.Fields,
              ),
            })
            break
          }
          case CampaignValidityMessageName.InvalidSlicesConfig: {
            errors.push({
              text: 'Invalid coupon slices configuration',
              resolveLink: this.campaignUrlService.campaignConfigurePluginStep(
                campaign.id,
                PluginConfigureRoutePluginNames.FortuneWheel,
                PluginConfigureStepName.Slices,
              ),
            })
            break
          }
          case CampaignValidityMessageName.FWNoAwardableSlice: {
            errors.push({
              text: 'Invalid coupon slices configuration',
              resolveLink: this.campaignUrlService.campaignConfigurePluginStep(
                campaign.id,
                PluginConfigureRoutePluginNames.FortuneWheel,
                PluginConfigureStepName.Slices,
              ),
            })
            break
          }
          case CampaignValidityMessageName.FWCouponCodeExpired: {
            errors.push({
              text: 'Reward is expired',
              resolveLink: this.campaignUrlService.campaignConfigurePluginStep(
                campaign.id,
                PluginConfigureRoutePluginNames.FortuneWheel,
                PluginConfigureStepName.Slices,
              ),
            })
            break
          }
          case CampaignValidityMessageName.FWCouponCodeDisabled: {
            errors.push({
              text: 'Coupon code is disabled',
              resolveLink: this.campaignUrlService.campaignConfigurePluginStep(
                campaign.id,
                PluginConfigureRoutePluginNames.FortuneWheel,
                PluginConfigureStepName.Slices,
              ),
            })
            break
          }
          case CampaignValidityMessageName.FWCouponCodeWithoutAutoResponder:
          case CampaignValidityMessageName.FWGiftCardCodeWithoutAutoResponder: {
            errors.push({
              text: 'Email Auto Responder is not enabled',
              resolveLink: this.campaignUrlService.campaignConfigurePluginStep(
                campaign.id,
                PluginConfigureRoutePluginNames.FortuneWheel,
                PluginConfigureStepName.MarketingEmails,
              ),
            })
            break
          }
          case CampaignValidityMessageName.MarketingEmailsWithoutEmailCredits: {
            errors.push({
              text: 'Out of Email Credits. Please upgrade your account to send more emails.',
              resolveLink: '/choose-plan',
            })
            break
          }
          case CampaignValidityMessageName.MarketingEmailsWithoutEmailField: {
            errors.push({
              text: 'Marketing Emails enabled without email field',
              resolveLink: this.campaignUrlService.campaignConfigurePluginStep(
                campaign.id,
                PluginConfigureRoutePluginNames.FortuneWheel,
                PluginConfigureStepName.Fields,
              ),
            })
            break
          }
          case CampaignValidityMessageName.MarketingSMSWithoutSMSCredits: {
            errors.push({
              text: 'Out of SMS Credits. Please purchase SMS credits to send more texts.',
              resolveLink: this.campaignUrlService.campaignConfigurePluginStep(
                campaign.id,
                PluginConfigureRoutePluginNames.FortuneWheel,
                PluginConfigureStepName.MarketingSMS,
              ),
            })
            break
          }
          case CampaignValidityMessageName.MarketingSMSWithoutPhoneField: {
            errors.push({
              text: 'Marketing SMS enabled without phone field',
              resolveLink: this.campaignUrlService.campaignConfigurePluginStep(
                campaign.id,
                PluginConfigureRoutePluginNames.FortuneWheel,
                PluginConfigureStepName.Fields,
              ),
            })
            break
          }
          case CampaignValidityMessageName.MarketingSMSWithoutTcpaEnabled: {
            errors.push({
              text: 'Marketing SMS without Double Opt-in Enabled',
              resolveLink: this.campaignUrlService.campaignConfigurePluginStep(
                campaign.id,
                PluginConfigureRoutePluginNames.FortuneWheel,
                PluginConfigureStepName.Fields,
              ),
            })
            break
          }
          case CampaignValidityMessageName.FWDoubleOptInWithoutActiveEmail: {
            errors.push({
              text: 'Double Opt-in requires active email',
              resolveLink: this.campaignUrlService.campaignConfigurePluginStep(
                campaign.id,
                PluginConfigureRoutePluginNames.FortuneWheel,
                PluginConfigureStepName.MarketingEmails,
              ),
            })
            break
          }
        }
      })
    if (validity.messages.findIndex((message: CampaignValidityMessage) => message.name === CampaignValidityMessageName.FWCouponCodeExpired) === -1) {
      const campaignValidity = campaign.validity
      campaignValidity.messages
        .filter((message: CampaignValidityMessage) => message.name === CampaignValidityMessageName.FWCouponCodeExpired)
        .forEach((message) => {
          errors.push({
            text: 'Reward is expired',
            resolveLink: this.campaignUrlService.campaignConfigurePluginStep(
              campaign.id,
              PluginConfigureRoutePluginNames.FortuneWheel,
              PluginConfigureStepName.Slices,
            ),
          })
        })
    }
    return errors
  }

  private getNewsletterPluginErrors(campaign: Campaign): ProofPluginError[] {
    const validity = campaign[CampaignPluginName.NewsLetterNotification].validity
    const errors: ProofPluginError[] = []

    validity.messages
      .filter((message: CampaignValidityMessage) => message.type === 'error')
      .forEach((message: CampaignValidityMessage) => {
        switch (message.name) {
          case CampaignValidityMessageName.NoSMSKeywordSet: {
            errors.push({
              text: 'SMS Keyword is not set',
              resolveLink: this.campaignUrlService.campaignConfigurePluginStep(
                campaign.id,
                PluginConfigureRoutePluginNames.NewsLetterNotification,
                PluginConfigureStepName.Fields,
              ),
            })
            break
          }
          case CampaignValidityMessageName.MarketingEmailsWithoutEmailCredits: {
            errors.push({
              text: 'Out of Email Credits. Please upgrade your account to send more emails.',
              resolveLink: '/choose-plan',
            })
            break
          }
          case CampaignValidityMessageName.MarketingEmailsWithoutEmailField: {
            errors.push({
              text: 'Marketing Emails enabled without email field',
              resolveLink: this.campaignUrlService.campaignConfigurePluginStep(
                campaign.id,
                PluginConfigureRoutePluginNames.NewsLetterNotification,
                PluginConfigureStepName.Fields,
              ),
            })
            break
          }
          case CampaignValidityMessageName.MarketingSMSWithoutPhoneField: {
            errors.push({
              text: 'Marketing SMS enabled without phone field',
              resolveLink: this.campaignUrlService.campaignConfigurePluginStep(
                campaign.id,
                PluginConfigureRoutePluginNames.NewsLetterNotification,
                PluginConfigureStepName.Fields,
              ),
            })
            break
          }
          case CampaignValidityMessageName.MarketingSMSWithoutSMSCredits: {
            errors.push({
              text: 'Out of SMS Credits. Please purchase SMS credits to send more texts.',
              resolveLink: this.campaignUrlService.campaignConfigurePluginStep(
                campaign.id,
                PluginConfigureRoutePluginNames.NewsLetterNotification,
                PluginConfigureStepName.MarketingSMS,
              ),
            })
            break
          }
          case CampaignValidityMessageName.MarketingSMSWithoutTcpaEnabled: {
            errors.push({
              text: 'Marketing SMS without Double Opt-in Enabled',
              resolveLink: this.campaignUrlService.campaignConfigurePluginStep(
                campaign.id,
                PluginConfigureRoutePluginNames.NewsLetterNotification,
                PluginConfigureStepName.Fields,
              ),
            })
            break
          }
          case CampaignValidityMessageName.BSBDoubleOptInWithoutActiveEmail: {
            errors.push({
              text: 'Double Opt-in requires active email',
              resolveLink: this.campaignUrlService.campaignConfigurePluginStep(
                campaign.id,
                PluginConfigureRoutePluginNames.NewsLetterNotification,
                PluginConfigureStepName.MarketingEmails,
              ),
            })
            break
          }
        }
      })

    return errors
  }

  private getCouponBoxPluginErrors(campaign: Campaign): ProofPluginError[] {
    const validity = campaign[CampaignPluginName.CouponBoxNotification].validity
    const errors: ProofPluginError[] = []

    validity.messages
      .filter((message: CampaignValidityMessage) => message.type === 'error')
      .forEach((message: CampaignValidityMessage) => {
        switch (message.name) {
          case CampaignValidityMessageName.NoSMSKeywordSet: {
            errors.push({
              text: 'SMS Keyword is not set',
              resolveLink: this.campaignUrlService.campaignConfigurePluginStep(
                campaign.id,
                PluginConfigureRoutePluginNames.CouponBox,
                PluginConfigureStepName.Fields,
              ),
            })
            break
          }
          case CampaignValidityMessageName.NoRewardSet: {
            errors.push({
              text: 'Reward is not set',
              resolveLink: this.campaignUrlService.campaignConfigurePluginStep(
                campaign.id,
                PluginConfigureRoutePluginNames.CouponBox,
                PluginConfigureStepName.Coupon,
              ),
            })
            break
          }
          case CampaignValidityMessageName.BCBRewardExpired:
          case CampaignValidityMessageName.BCBCouponCodeExpired: {
            errors.push({
              text: 'Reward is expired',
              resolveLink: this.campaignUrlService.campaignConfigurePluginStep(
                campaign.id,
                PluginConfigureRoutePluginNames.CouponBox,
                PluginConfigureStepName.Coupon,
              ),
            })
            break
          }
          case CampaignValidityMessageName.BCBRewardDisabled:
          case CampaignValidityMessageName.BCBCouponCodeDisabled: {
            errors.push({
              text: 'Coupon code is disabled',
              resolveLink: this.campaignUrlService.campaignConfigurePluginStep(
                campaign.id,
                PluginConfigureRoutePluginNames.CouponBox,
                PluginConfigureStepName.Coupon,
              ),
            })
            break
          }
          case CampaignValidityMessageName.BCBCouponCodeWithoutAutoResponder: {
            errors.push({
              text: 'Email Auto Responder is not enabled',
              resolveLink: this.campaignUrlService.campaignConfigurePluginStep(
                campaign.id,
                PluginConfigureRoutePluginNames.CouponBox,
                PluginConfigureStepName.MarketingEmails,
              ),
            })
            break
          }
          case CampaignValidityMessageName.MarketingEmailsWithoutEmailCredits: {
            errors.push({
              text: 'Out of Email Credits. Please upgrade your account to send more emails.',
              resolveLink: '/choose-plan',
            })
            break
          }
          case CampaignValidityMessageName.MarketingEmailsWithoutEmailField: {
            errors.push({
              text: 'Marketing Emails enabled without email field',
              resolveLink: this.campaignUrlService.campaignConfigurePluginStep(
                campaign.id,
                PluginConfigureRoutePluginNames.CouponBox,
                PluginConfigureStepName.Fields,
              ),
            })
            break
          }
          case CampaignValidityMessageName.MarketingSMSWithoutSMSCredits: {
            errors.push({
              text: 'Out of SMS Credits. Please purchase SMS credits to send more texts.',
              resolveLink: this.campaignUrlService.campaignConfigurePluginStep(
                campaign.id,
                PluginConfigureRoutePluginNames.CouponBox,
                PluginConfigureStepName.MarketingSMS,
              ),
            })
            break
          }
          case CampaignValidityMessageName.MarketingSMSWithoutPhoneField: {
            errors.push({
              text: 'Marketing SMS enabled without phone field',
              resolveLink: this.campaignUrlService.campaignConfigurePluginStep(
                campaign.id,
                PluginConfigureRoutePluginNames.CouponBox,
                PluginConfigureStepName.Fields,
              ),
            })
            break
          }
          case CampaignValidityMessageName.MarketingSMSWithoutTcpaEnabled: {
            errors.push({
              text: 'Marketing SMS without Double Opt-in Enabled',
              resolveLink: this.campaignUrlService.campaignConfigurePluginStep(
                campaign.id,
                PluginConfigureRoutePluginNames.CouponBox,
                PluginConfigureStepName.Fields,
              ),
            })
            break
          }
          case CampaignValidityMessageName.BCBDoubleOptInWithoutActiveEmail: {
            errors.push({
              text: 'Double Opt-in requires active email',
              resolveLink: this.campaignUrlService.campaignConfigurePluginStep(
                campaign.id,
                PluginConfigureRoutePluginNames.CouponBox,
                PluginConfigureStepName.MarketingEmails,
              ),
            })
            break
          }
        }
      })

    const isExpiredMessage = function(message: CampaignValidityMessage) {
      return CampaignValidityMessageName.BCBCouponCodeExpired === message.name || CampaignValidityMessageName.BCBRewardExpired === message.name
    }

    if (!validity.messages.some(isExpiredMessage)) {
      const campaignValidity = campaign.validity
      campaignValidity.messages
        .filter(isExpiredMessage)
        .forEach((message) => {
          errors.push({
            text: 'Reward is expired',
            resolveLink: this.campaignUrlService.campaignConfigurePluginStep(
              campaign.id,
              PluginConfigureRoutePluginNames.CouponBox,
              PluginConfigureStepName.Coupon,
            ),
          })
        })
    }

    return errors
  }

  getInfoPopupPluginErrors(campaign: Campaign, pluginName: CampaignPluginName): ProofPluginError[] {
    const campaignPlugin = campaign[pluginName] as InfoPopupNotificationParams
    const validity = campaignPlugin.validity
    const errors: ProofPluginError[] = []
    let pluginRoute: PluginConfigureRoutePluginNames

    switch (pluginName) {
      case CampaignPluginName.SalesPopup:
        pluginRoute = PluginConfigureRoutePluginNames.SalesPopup
        break
      case CampaignPluginName.FreeShipping:
        pluginRoute = PluginConfigureRoutePluginNames.FreeShippingNotification
        break
      case CampaignPluginName.ProductPopup:
        pluginRoute = PluginConfigureRoutePluginNames.ProductPopup
    }

    validity.messages
      .filter((message: CampaignValidityMessage) => message.type === 'error')
      .forEach((message: CampaignValidityMessage) => {
        switch (message.name) {
          case CampaignValidityMessageName.NoRewardSet: {
            errors.push({
              text: 'Reward is not set',
              resolveLink: this.campaignUrlService.campaignConfigurePluginStep(
                campaign.id,
                pluginRoute,
                PluginConfigureStepName.Coupon,
              ),
            })
            break
          }
          case CampaignValidityMessageName.NoProducts: {
            errors.push({
              text: 'Product is not set',
              resolveLink: this.campaignUrlService.campaignConfigurePluginStep(
                campaign.id,
                pluginRoute,
                PluginConfigureStepName.Products,
              ),
            })
            break
          }
        }
      })
    return errors
  }

  private getLiveUserCountPluginErrors(campaign: Campaign): ProofPluginError[] {
    return []
  }

  private getAggregateActivityPluginErrors(campaign: Campaign): ProofPluginError[] {
    return []
  }

  private getUserActivityPluginErrors(campaign: Campaign): ProofPluginError[] {
    const validity = campaign[CampaignPluginName.RecentActivity].validity
    const errors: ProofPluginError[] = []

    const noContainsCaptureConfigs = validity.messages.some(
      message => message.name === CampaignValidityMessageName.NoContainsCaptureConfigs,
    )
    if (noContainsCaptureConfigs) {
      errors.push({
        text: 'You must have at least one CONTAINS capture rule',
        resolveLink: this.getConfigureLink(campaign.id, CampaignPluginName.RecentActivity),
      })
      return errors
    }

    const noEvents = validity.messages.some(message => message.name === CampaignValidityMessageName.NoEvents)
    if (noEvents) {
      errors.push({
        text: this.getUserActivityWarningText(this.shopType),
        tooltip: this.getUserActivityWarningTooltip(this.shopType),
        modalName: 'userActivityWarningModal',
        isWarning: true,
      })
    }
    return errors
  }

  getUserActivityWarningText(shopType = null): string {
    if (!shopType) {
      return 'No Recent Signups / Orders Captured'
    }
    const shopName = this.userService.getShopName(shopType)
    return `No Recent Orders in ${shopName}`
  }

  getUserActivityWarningTooltip(shopType = null): string {
    if (!shopType) {
      return `We automatically import all your signups / orders as they happen after you installed our plugin.
      <br><br>Currently, there no signups / orders captured from your website.`
    }
    const shopName = this.userService.getShopName(shopType)
    return `We automatically import all your orders from ${shopName} and continue to import as they happen in real-time.
        <br><br>If no orders in our system please resolve on launch page.`
  }

  getStepLink(id: string, step: 'apps' | 'display' | 'review-and-launch'): string {
    return `/${RouteHeaderUrl.popups}/${RouteHeaderUrl.campaigns}/${id}/${step}`
  }

  getConfigureLink(id: string, type: CampaignPluginName): string {
    const typeMap = {
      [CampaignPluginName.FortuneWheel]: PluginConfigureRoutePluginNames.FortuneWheel,
      [CampaignPluginName.LiveVisitorCount]: PluginConfigureRoutePluginNames.LiveVisitorCount,
      [CampaignPluginName.AggregateVisitorCount]: PluginConfigureRoutePluginNames.AggregateVisitorCount,
      [CampaignPluginName.AggregateActivity]: PluginConfigureRoutePluginNames.AggregateActivity,
      [CampaignPluginName.RecentActivity]: PluginConfigureRoutePluginNames.RecentActivity,
      [CampaignPluginName.CouponBoxNotification]: PluginConfigureRoutePluginNames.CouponBox,
      [CampaignPluginName.NewsLetterNotification]: PluginConfigureRoutePluginNames.NewsLetterNotification,
      [CampaignPluginName.FreeShipping]: PluginConfigureRoutePluginNames.FreeShippingNotification,
      [CampaignPluginName.SalesPopup]: PluginConfigureRoutePluginNames.SalesPopup,
      [CampaignPluginName.ProductPopup]: PluginConfigureRoutePluginNames.ProductPopup,
    }
    return `/${RouteHeaderUrl.popups}/${RouteHeaderUrl.campaigns}/${id}/${RouteHeaderUrl.configure}/${typeMap[type]}`
  }

  getPixelErrorsFromCampaign(campaign: Campaign): ProofPluginError[] {
    const noPixelDetected = !!campaign.validity.messages.some(
      message => message.name === CampaignValidityMessageName.NoPixelDetected,
    )
    if (noPixelDetected) {
      return [
        {
          text: 'ProofFactor Pixel (Install Code) not located on your website.\n',
          resolveLink: '/install',
        },
      ]
    }
    return []
  }

  makePluginList(campaign: Campaign): ProofPlugin[] {
    const list = []
    if (!campaign) {
      return list
    }
    for (const pluginName of allowedProofPlugins) {
      if (campaign[pluginName] && campaign[pluginName].active) {
        list.push(this.makePlugin(pluginName, campaign))
      }
    }
    return list
  }

  private makePlugin(type: CampaignPluginName, campaign: Campaign): ProofPlugin {
    return {
      type: type,
      name: this.getPluginTitle(type),
      preview: this.getPluginPreview(type),
      errors: this.getPluginErrors(type, campaign),
    }
  }

  makeDisplayList(campaign: Campaign): CampaignMatchingListItem[] {
    return campaign.display_configs
      .filter(config => config.type !== 'blacklist')
      .map(config => this.prepareMatchingDisplayConfigToMatchingListItem(config, campaign.id))
  }

  makeExclusionList(campaign: Campaign): CampaignMatchingListItem[] {
    return campaign.display_configs
      .filter(config => config.type === 'blacklist')
      .map(config => this.prepareMatchingDisplayConfigToMatchingListItem(config, campaign.id, 'Hide'))
  }

  makeCaptureList(campaign: Campaign): CampaignMatchingListItem[] {
    return campaign[CampaignPluginName.RecentActivity].capture_configs
      .filter(config => config.type !== 'blacklist')
      .map(config => this.prepareMatchingCaptureConfigToMatchingListItem(config, campaign.id))
  }

  makeCaptureExclusionList(campaign: Campaign): CampaignMatchingListItem[] {
    return campaign[CampaignPluginName.RecentActivity].capture_configs
      .filter(config => config.type === 'blacklist')
      .map(config => this.prepareMatchingCaptureConfigToMatchingListItem(config, campaign.id))
  }

  makeCountryList(campaign: Campaign, type: string = 'targeted_countries'): any[] {
    const countryCodeList = _.get(campaign, type)
    if (countryCodeList.length > 0) {
      return COUNTRIES_LIST.filter((country: any[]) =>
        countryCodeList.includes(`${country[1]}`.toUpperCase()),
      ).map((country: any[]) => ({
        name: country[0] as string,
        code: `${country[1]}`.toUpperCase(),
      }))
    }
    return []
  }

  private prepareMatchingDisplayConfigToMatchingListItem(
    config: DisplayRule,
    campaignId: string,
    prefix: string = 'Show',
  ): CampaignMatchingListItem {

    let name, value, errors

    switch (config.match_type) {
      case DisplayMatchType.ProductPages: {
        name = `${prefix} on All Product pages`
        value = ''
        errors = []
        break
      }
      case DisplayMatchType.AllPages: {
        name = `${prefix} on All pages`
        value = ''
        errors = []
        break
      }
      case DisplayMatchType.HomePage: {
        name = `${prefix} on Home Page`
        value = ''
        errors = []
        break
      }
      case DisplayMatchType.PasswordPage: {
        name = `${prefix} on Password Page`
        value = ''
        errors = []
        break
      }
      case DisplayMatchType.ThankYouPage: {
        name = `${prefix} on Thank You Page`
        value = ''
        errors = []
        break
      }
      case DisplayMatchType.OrderStatusPage: {
        name = `${prefix} on Order Status Page`
        value = ''
        errors = []
        break
      }
      case DisplayMatchType.CheckoutPage: {
        name = `${prefix} on Checkout Page`
        value = ''
        errors = []
        break
      }
      case DisplayMatchType.CheckoutShippingPage: {
        name = `${prefix} on Checkout Shipping Page`
        value = ''
        errors = []
        break
      }
      case DisplayMatchType.CheckoutPaymentPage: {
        name = `${prefix} on Checkout Payment Page`
        value = ''
        errors = []
        break
      }
      default: {
        name = 'Custom'
        value = config.match_input
        errors = this.getMatchingUrlErrors(value, campaignId)
        break
      }
    }
    return {
      name,
      value,
      errors,
    }
  }

  private prepareMatchingCaptureConfigToMatchingListItem(
    config: CaptureRule,
    campaignId: string,
    baseUrl?: string,
  ): CampaignMatchingListItem {
    const name = config.match_type === CaptureMatchType.Contains ? 'Contains' : 'Exact Match'
    const value = config.match_input
    return {
      name,
      value,
      errors: this.getMatchingUrlErrors(value, campaignId),
    }
  }

  private getMatchingUrlErrors(url: string, id: string): ProofPluginError[] {
    const errors: ProofPluginError[] = []
    if (url.length === 0) {
      errors.push({
        text: 'URL is Missing',
        resolveLink: this.getStepLink(id, 'display'),
      })
    }
    return errors
  }

  trimTitle(text: string): string {
    return this.trimText(text, 120)
  }

  trimSubtitle(text: string): string {
    return this.trimText(text, 87)
  }

  trimText(text: string, maxLength: number) {
    if (text.length > maxLength) {
      text = text.substring(0, maxLength - 3) + '…'
    }
    return text
  }

  makeNotificationPreviewParamsByCampaign(
    campaign: Campaign,
  ): { [key in CampaignPluginName]?: WidgetNotificationParams } {
    return {
      [CampaignPluginName.LiveVisitorCount]: this.makeLiveVisitorCountPreviewParams(campaign, true),
      [CampaignPluginName.AggregateVisitorCount]: this.makeAggregateVisitorCountPreviewParams(campaign, {}, true),
      [CampaignPluginName.RecentActivity]: this.makeRecentActivityPreviewParams(campaign, true),
      [CampaignPluginName.AggregateActivity]: this.makeAggregateActivityPreviewParams(campaign, true),
    }
  }

  makePopupPreviewParamsByCampaign(campaign: Campaign): { [key in CampaignPluginName]?: any } {
    return {
      [CampaignPluginName.FortuneWheel]: this.makeFortuneWheelPreviewParams(campaign),
      [CampaignPluginName.CouponBoxNotification]: this.makeCouponBoxPreviewParams(campaign),
      [CampaignPluginName.NewsLetterNotification]: this.makeNewsLetterPreviewParams(campaign),
      [CampaignPluginName.FreeShipping]: this.makeInfoPopupPreviewParams(campaign, CampaignPluginName.FreeShipping),
      [CampaignPluginName.SalesPopup]: this.makeInfoPopupPreviewParams(campaign, CampaignPluginName.SalesPopup),
      [CampaignPluginName.ProductPopup]: this.makeInfoPopupPreviewParams(campaign, CampaignPluginName.ProductPopup),
    }
  }

  makeRecentActivityPreviewParams(
    campaign: Campaign,
    previewMode: boolean | 'playground' = true,
  ): WidgetNotificationParams {
    if (!campaign || !campaign.config) {
      return null
    }
    const appearance = campaign.config.appearance
    const notificationConfig = campaign.user_activity_notification.config
    const stub = this.getSampleEvent()

    const titleText = templateHelper.parse(notificationConfig['title_message'].text, stub)
    const subtitleText = templateHelper.parse(notificationConfig['message'].text, stub)
    const createdMoment = (this.capturedEvent && this.capturedEvent.created_at) ? moment(this.capturedEvent.created_at) : moment()

    let detailSubtitle = null
    const language = campaign.config.language
    if (language && language.locale) {
      detailSubtitle = createdMoment
        .locale(language.locale)
        .fromNow()
    } else {
      detailSubtitle = createdMoment
        .fromNow()
    }

    return <WidgetNotificationParams>{
      view: {
        type: WidgetNotificationViewType.RecentActivity,
        previewMode: previewMode,
        shop: _.get(this.userService.userInfo, 'shop.domain') || _.get(this.userService.userInfo, 'shop.store_url'),
        branding_name: campaign?.branding?.branding_name || campaign.branding_name,
        branding_wording: campaign?.branding?.branding_wording || campaign.branding_wording,
        hide_powered_by: campaign.remove_branding,
        disable_powered_by_link: campaign.disable_powered_by_link,
        desktop: {
          visible: appearance.desktop.visible,
          theme: appearance.desktop.theme,
          align: appearance.desktop.horizontal_position,
          verticalAlign: appearance.desktop.vertical_position,
          animation: appearance.desktop.animation,
        },
        mobile: {
          theme: appearance.mobile.theme,
          visible: appearance.mobile.visible,
          verticalAlign: appearance.mobile.vertical_position,
          animation: appearance.mobile.animation,
        },
        image: {
          src: _.get(stub, 'render_event_image_url') || _.get(stub, 'image_url') || _.get(notificationConfig, 'image.url'),
        },
      },
      params: {
        notification_id: null,
        unique_id: null,
        event_type: null,
        url: notificationConfig['objectives'].click_url,
        urlTarget: notificationConfig['objectives'].click_url_target,
        title: this.trimTitle(titleText),
        subtitle: this.trimSubtitle(subtitleText),
        detailSubtitle: notificationConfig['display'].hide_creation_date ? null : detailSubtitle,
        lang: language && language.locale,
      },
    }
  }

  makeLiveVisitorCountPreviewParams(
    campaign: Campaign,
    previewMode: boolean | 'playground' = true,
  ): WidgetNotificationParams {
    if (!campaign || !campaign[CampaignPluginName.LiveVisitorCount]) {
      return null
    }
    const config = campaign[CampaignPluginName.LiveVisitorCount].config
    const appearance = campaign.config.appearance
    const display = config.display
    let detailSubtitle = null
    const language = campaign.config.language
    if (language && language.locale) {
      detailSubtitle = moment()
        .locale(language.locale)
        .startOf('hour')
        .fromNow()
    } else {
      detailSubtitle = moment()
        .startOf('hour')
        .fromNow()
    }

    const stub = this.getSampleEvent()
    let titleText = display.title_message_page || 'are viewing this page'
    try {
      titleText = templateHelper.parse(display.title_message_page, stub)
    } catch (e) {
    }
    const res = {
      view: {
        type: WidgetNotificationViewType.LiveVisitorCount,
        previewMode: previewMode,
        shop: _.get(this.userService.userInfo, 'shop.domain') || _.get(this.userService.userInfo, 'shop.store_url'),
        branding_wording: campaign?.branding?.branding_wording || campaign.branding_wording,
        branding_name: campaign?.branding?.branding_name || campaign.branding_name,
        hide_powered_by: campaign.remove_branding,
        disable_powered_by_link: campaign.disable_powered_by_link,
        desktop: {
          visible: appearance.desktop.visible,
          theme: appearance.desktop.theme,
          align: appearance.desktop.horizontal_position,
          verticalAlign: appearance.desktop.vertical_position,
          animation: appearance.desktop.animation,
        },
        mobile: {
          visible: appearance.mobile.visible,
          theme: appearance.mobile.theme,
          verticalAlign: appearance.mobile.vertical_position,
          animation: appearance.mobile.animation,
        },
        image: {
          src: config['image'] ? config['image'].url : '',
        },
      },
      params: {
        notification_id: null,
        unique_id: null,
        event_type: null,
        url: config.objectives.click_url,
        urlTarget: config.objectives.click_url_target,
        title: this.trimTitle(titleText),
        subtitle: null,
        detailSubtitle: null,
        userCount: '23',
        userName: display.visitor_name,
        lang: language && language.locale,
      },
    }
    return res
  }

  makeAggregateActivityPreviewParams(
    campaign: Campaign,
    previewMode: boolean | 'playground' = true,
  ): WidgetNotificationParams {
    if (!campaign || !campaign[CampaignPluginName.AggregateActivity]) {
      return null
    }
    const config = campaign[CampaignPluginName.AggregateActivity].config
    const appearance = campaign.config.appearance
    const language = campaign.config.language

    const stub = this.getSampleEvent()
    stub['count'] = _.random(11, 80)
    const titleText = TemplateHelper.highlightText(templateHelper.parse(config.title_message.text, stub))

    return {
      view: {
        shop: _.get(this.userService.userInfo, 'shop.domain') || _.get(this.userService.userInfo, 'shop.store_url'),
        branding_wording: campaign.branding?.branding_wording || campaign.branding_wording,
        branding_name: campaign.branding?.branding_name || campaign.branding_name,
        hide_powered_by: campaign.remove_branding,
        disable_powered_by_link: campaign.disable_powered_by_link,
        previewMode,
        type: WidgetNotificationViewType.AggregateActivity,
        desktop: {
          visible: appearance.desktop.visible,
          theme: appearance.desktop.theme,
          align: appearance.desktop.horizontal_position,
          verticalAlign: appearance.desktop.vertical_position,
          animation: appearance.desktop.animation,
        },
        mobile: {
          visible: appearance.mobile.visible,
          verticalAlign: appearance.mobile.vertical_position,
          theme: appearance.mobile.theme,
          animation: appearance.mobile.animation,
        },
        image: {
          src: _.get(stub, 'render_event_image_url') || _.get(stub, 'image_url') || config['image'].url,
        },
      },
      params: {
        notification_id: '',
        unique_id: '',
        event_type: '',
        url: config.objectives.click_url,
        urlTarget: config.objectives.click_url_target,
        title: titleText,
        lang: language && language.locale,
        aggregateDuration: config.grouping.duration,
      },
    }
  }

  makeAggregateVisitorCountPreviewParams(
    campaign: Campaign,
    formValue?: any,
    previewMode: boolean | 'playground' = true,
  ): WidgetNotificationParams {
    if (!campaign || !campaign[CampaignPluginName.AggregateVisitorCount]) {
      return null
    }
    const config = campaign[CampaignPluginName.AggregateVisitorCount].config
    const appearance = campaign.config.appearance
    const language = campaign.config.language

    let title =
      config.display.context === 'global' ? config.display.title_message_global : config.display.title_message_page
    if (title === null) {
      title = config.display.context === 'global' ? 'viewed this site' : 'viewed this page'
    }
    if (formValue && formValue.context) {
      title = formValue.context === 'global' ? formValue.titleMessageGlobal : formValue.titleMessagePage
    }

    return {
      view: {
        shop: _.get(this.userService.userInfo, 'shop.domain') || _.get(this.userService.userInfo, 'shop.store_url'),
        branding_wording: campaign?.branding?.branding_wording || campaign.branding_wording,
        branding_name: campaign?.branding?.branding_name || campaign.branding_name,
        hide_powered_by: campaign.remove_branding,
        disable_powered_by_link: campaign.disable_powered_by_link,
        previewMode,
        type: WidgetNotificationViewType.AggregateVisitorCount,
        desktop: {
          visible: appearance.desktop.visible,
          theme: appearance.desktop.theme,
          align: appearance.desktop.horizontal_position,
          verticalAlign: appearance.desktop.vertical_position,
          animation: appearance.desktop.animation,
        },
        mobile: {
          visible: appearance.mobile.visible,
          verticalAlign: appearance.mobile.vertical_position,
          theme: appearance.mobile.theme,
          animation: appearance.mobile.animation,
        },
        image: {
          src: config['image'] ? config['image'].url : '',
        },
      },
      params: {
        notification_id: '',
        unique_id: '',
        event_type: '',
        url: config.objectives.click_url,
        urlTarget: config.objectives.click_url_target,
        title,
        subtitle: '',
        detailSubtitle: null,
        userCount: (config.display.context === 'global' ? this.exampleCount + 123 : this.exampleCount).toString(),
        userName: (formValue && formValue.visitorName) || config.display.visitor_name || 'people',
        lang: language && language.locale,
        aggregateDuration: config.grouping.duration,
      },
    }
  }

  makeNotificationDummyPreviewParams(type: CampaignPluginName, order = null) {
    if (type === CampaignPluginName.RecentActivity) {
      let title = 'Michael from Los Angeles, California'
      let subtitle = 'Recently bought a T-Shirt!'
      let imageUrl = null

      const eventData = order && order.event_data
      if (eventData) {
        if (eventData.first_name && eventData.city) {
          title = `${eventData.first_name} from ${eventData.city}, ${eventData.state || eventData.country}`
        }
        if (eventData.image_url) {
          imageUrl = eventData.image_url
        }
        if (eventData.product_name) {
          subtitle = `Recently bought ${eventData.product_name}`
        }
      }

      return this.makeRecentActivityPreviewParams({
        config: {
          appearance: {
            desktop: {},
            mobile: {},
          },
          language: { locale: 'en' },
        },
        [CampaignPluginName.RecentActivity]: {
          config: {
            title_message: {
              text: title,
            },
            message: {
              text: subtitle,
            },
            display: {
              hide_creation_date: true,
            },
            image: {
              url: imageUrl,
            },
            objectives: {},
          },
        },
      } as Campaign, true)
    } else if (type === CampaignPluginName.LiveVisitorCount) {
      return this.makeLiveVisitorCountPreviewParams({
        config: {
          appearance: {
            desktop: {},
            mobile: {},
          },
          language: { locale: 'en' },
        },
        [CampaignPluginName.LiveVisitorCount]: {
          config: {
            display: {
              title_message_page: null,
              visitor_name: 'people',
            },
            objectives: {},
          },
        },
      } as Campaign, true)
    } else if (type === CampaignPluginName.AggregateActivity) {
      let title = '**14 people** bought a jacket'
      let imageUrl = null

      const eventData = order && order.event_data
      if (eventData) {
        if (eventData.product_name) {
          title = `**14 people** bought ${eventData.product_name}`
        }
        if (eventData.image_url) {
          imageUrl = eventData.image_url
        }
      }

      return this.makeAggregateActivityPreviewParams({
        config: {
          appearance: {
            desktop: {},
            mobile: {},
          },
          language: { locale: 'en' },
        },
        [CampaignPluginName.AggregateActivity]: {
          config: {
            title_message: {
              text: title,
              type: 'template',
            },
            image: {
              url: imageUrl,
            },
            objectives: {},
            grouping: {
              duration: 'hour',
            },
          },
        },
      } as Campaign, true)
    } else if (type === CampaignPluginName.AggregateVisitorCount) {
      return this.makeAggregateVisitorCountPreviewParams({
        config: {
          appearance: {
            desktop: {},
            mobile: {},
          },
          language: { locale: 'en' },
        },
        [CampaignPluginName.AggregateVisitorCount]: {
          config: {
            display: {
              title_message_page: null,
              visitor_name: 'people',
            },
            objectives: {},
            grouping: {
              duration: 'hour',
            },
          },
        },
      } as Campaign, true)
    }
    return null
  }

  makeCouponBoxPreviewParams(campaign: Campaign) {
    if (!campaign || !campaign.config) {
      return null
    }
    const params = {
      ...campaign[CampaignPluginName.CouponBoxNotification],
      previewMode: true,
    }

    return params
  }

  makeInfoPopupPreviewParams(campaign: Campaign, pluginType: CampaignPluginName): InfoPopupNotificationParams {
    if (!campaign || !campaign.config) {
      return null
    }
    const params = {
      ...campaign[pluginType],
      previewMode: true,
    }

    return params as InfoPopupNotificationParams
  }

  makeNewsLetterPreviewParams(campaign: Campaign) {
    if (!campaign || !campaign.config) {
      return null
    }
    const params = {
      ...campaign[CampaignPluginName.NewsLetterNotification],
      previewMode: true,
    }

    return params
  }

  makeFortuneWheelPreviewParams(campaign: Campaign): FortuneWheelPreviewParams {
    if (!campaign || !campaign.config) {
      return null
    }
    const previewParams = {
      previewMode: true,
      slices: campaign.fortune_wheel_notification.slices,
      texts: campaign.fortune_wheel_notification.config.texts,
      branding: campaign.fortune_wheel_notification.config.branding,
      form_config: campaign.fortune_wheel_notification.config.form_config,
      visible: true,
      display: campaign.fortune_wheel_notification.config.display,
      error: null,
      advanced: campaign.fortune_wheel_notification.config.advanced,
      theme: campaign.fortune_wheel_notification.config.theme,
      themes: this.defaultWheelThemes,
      resultId: null,
      elements_styling: campaign.fortune_wheel_notification.config?.elements_styling,
      onSpin: () => {
      },
      onClose: () => {
      },
    }

    if (previewParams.theme.identifier) {
      const theme = this.fwThemesList.find((n) => n.identifier === previewParams.theme.identifier)
      if (theme) {
        previewParams.theme.css = theme.css
      }
    }

    return previewParams
  }

  getSampleEvent() {
    return (
      this.capturedEvent || {
        first_name: 'Jessica',
        last_name: 'Alba',
        company: 'Proof Factor LLC',
        city: 'Los Angeles',
        state: 'California',
        state_code: 'CA',
        country: 'US',
        product_name: 'jacket',
      }
    )
  }

  prepareEvent(event: any, filteredKeys = []): object {
    const preparedEvent = {}

    if (event && event.event_data) {
      event = {
        ...event.event_data,
        created_at: event.created_at,
      }
    }

    // Other
    this.allowedEventProperties.forEach(key => {
      if (!filteredKeys.includes(key)) {
        preparedEvent[key] = event[key]
      }
    })

    const custom_data = event['custom_data']
    if (custom_data) {
      for (const key in custom_data) {
        if (custom_data.hasOwnProperty(key)) {
          if (custom_data[key] && !this.forbiddenEventCustomDataProperties.includes(key)) {
            preparedEvent[key] = custom_data[key]
          }
        }
      }
    }

    return preparedEvent
  }

  public createCampaignWithPlugin(type: CampaignPluginName, campaignName = null, queryParams = null, tags = null) {
    this.store.dispatch(new ShowLoading('CreateCampaignWithPlugin'))
    this.loadingOverlayService.overlaySubTitle = 'Generating Campaign...'

    const name = campaignName || {
      [CampaignPluginName.FortuneWheel]: 'Gamified Campaign',
      [CampaignPluginName.CouponBoxNotification]: 'Reward Campaign',
      [CampaignPluginName.NewsLetterNotification]: 'Subscription Campaign',
    }[type] || 'My Campaign'
    const payload = { name } as Campaign
    if (tags) {
      payload.tags = tags
    }
    return this.createCampaign(payload).pipe(
      switchMap((next: Campaign) => {
        return this._createPluginAndGetUrl(next, type)
      }),
      tap((url: string) => {
        const params = queryParams ? queryParams : null
        this.router.navigate([url], { queryParams }).then(() => {
            this.store.dispatch(new HideLoading('CreateCampaignWithPlugin'))
            this.loadingOverlayService.overlaySubTitle = null
          },
          (err) => {
            this.store.dispatch(new HideLoading('CreateCampaignWithPlugin'))
            this.loadingOverlayService.overlaySubTitle = null
          },
        )
      }),
    )
  }

  private _createPluginAndGetUrl(campaign: Campaign, pluginType: CampaignPluginName) {
    const config = _.get(campaign[pluginType].config, 'v1', campaign[pluginType].config)
    const capture_configs = pluginType === CampaignPluginName.RecentActivity && campaign[pluginType] ? campaign[pluginType].capture_configs : undefined
    const data = { config, active: true, capture_configs_attributes: capture_configs }
    return this.apiCampaignService.putPlugin(campaign.id, pluginType, data).pipe(
      switchMap(() => of(this.getConfigureLink(campaign.id, pluginType))),
    )
  }

  campaignWithSMSorTCPAExists(): Observable<boolean> {
    return this.apiCampaignService.campaignsWithSMSorTCPA()
      .pipe(
        map(value => value.result)
      )
  }

}
