// ACTION TYPES
export enum CampaignActionTypes {

  SET_SEARCH_QUERY = 'Campaign.SET_SEARCH_QUERY',
  SET_TEMPLATE_ERROR = 'Campaign.SET_TEMPLATE_ERROR',
  SET_CAPTURE_CONFIGS = 'Campaign.SET_CAPTURE_CONFIGS',
  SET_DATA_SOURCES = 'Campaign.SET_DATA_SOURCES',

  RESET_EVENTS = 'Campaign.RESET_EVENTS',
  CLEAR_SELECTED_CAMPAIGN = 'Campaign.CLEAR_SELECTED_CAMPAIGN',
  CLEAR_ERROR = 'Campaign.CLEAR_ERROR',

  /* Requests */

  READ_CAMPAIGN_WHEEL_PATTERNS_REQUEST = 'Campaign.READ_CAMPAIGN_WHEEL_PATTERNS_REQUEST',
  READ_CAMPAIGN_WHEEL_PATTERNS_SUCCESS = 'Campaign.READ_CAMPAIGN_WHEEL_PATTERNS_SUCCESS',
  READ_CAMPAIGN_WHEEL_PATTERNS_ERROR = 'Campaign.READ_CAMPAIGN_WHEEL_PATTERNS_ERROR',


  READ_CAMPAIGN_WHEEL_THEMES_REQUEST = 'Campaign.READ_CAMPAIGN_WHEEL_THEMES_REQUEST',
  READ_CAMPAIGN_WHEEL_THEMES_SUCCESS = 'Campaign.READ_CAMPAIGN_WHEEL_THEMES_SUCCESS',
  READ_CAMPAIGN_WHEEL_THEMES_ERROR = 'Campaign.READ_CAMPAIGN_WHEEL_THEMES_ERROR',

  READ_CAMPAIGN_CB_THEMES_REQUEST = 'Campaign.READ_CAMPAIGN_CB_THEMES_REQUEST',
  READ_CAMPAIGN_CB_THEMES_SUCCESS = 'Campaign.READ_CAMPAIGN_CB_THEMES_SUCCESS',
  READ_CAMPAIGN_CB_THEMES_ERROR = 'Campaign.READ_CAMPAIGN_CB_THEMES_ERROR',

  READ_CAMPAIGN_NL_THEMES_REQUEST = 'Campaign.READ_CAMPAIGN_NL_THEMES_REQUEST',
  READ_CAMPAIGN_NL_THEMES_SUCCESS = 'Campaign.READ_CAMPAIGN_NL_THEMES_SUCCESS',
  READ_CAMPAIGN_NL_THEMES_ERROR = 'Campaign.READ_CAMPAIGN_NL_THEMES_ERROR',

  READ_CAMPAIGN_LIST_REQUEST = 'Campaign.READ_CAMPAIGN_LIST_REQUEST',
  READ_CAMPAIGN_LIST_SUCCESS = 'Campaign.READ_CAMPAIGN_LIST_SUCCESS',
  READ_CAMPAIGN_LIST_FAILURE = 'Campaign.READ_CAMPAIGN_LIST_FAILURE',

  CREATE_CAMPAIGN_REQUEST = 'Campaign.CREATE_CAMPAIGN_REQUEST',
  CREATE_CAMPAIGN_SUCCESS = 'Campaign.CREATE_CAMPAIGN_SUCCESS',
  CREATE_CAMPAIGN_FAILURE = 'Campaign.CREATE_CAMPAIGN_FAILURE',

  READ_CAMPAIGN_REQUEST = 'Campaign.READ_CAMPAIGN_REQUEST',
  READ_CAMPAIGN_SUCCESS = 'Campaign.READ_CAMPAIGN_SUCCESS',
  READ_CAMPAIGN_FAILURE = 'Campaign.READ_CAMPAIGN_FAILURE',

  UPDATE_CAMPAIGN_ACTIVE_REQUEST = 'Campaign.UPDATE_CAMPAIGN_ACTIVE_REQUEST',
  UPDATE_CAMPAIGN_ACTIVE_SUCCESS = 'Campaign.UPDATE_CAMPAIGN_ACTIVE_SUCCESS',
  UPDATE_CAMPAIGN_ACTIVE_FAILURE = 'Campaign.UPDATE_CAMPAIGN_ACTIVE_FAILURE',

  UPDATE_CAMPAIGN_REQUEST = 'Campaign.UPDATE_CAMPAIGN_REQUEST',
  UPDATE_CAMPAIGN_SUCCESS = 'Campaign.UPDATE_CAMPAIGN_SUCCESS',
  UPDATE_CAMPAIGN_FAILURE = 'Campaign.UPDATE_CAMPAIGN_FAILURE',

  DELETE_CAMPAIGN_REQUEST = 'Campaign.DELETE_CAMPAIGN_REQUEST',
  DELETE_CAMPAIGN_SUCCESS = 'Campaign.DELETE_CAMPAIGN_SUCCESS',
  DELETE_CAMPAIGN_FAILURE = 'Campaign.DELETE_CAMPAIGN_FAILURE',

  DUPLICATE_CAMPAIGN_REQUEST = 'Campaign.DUPLICATE_CAMPAIGN_REQUEST',
  DUPLICATE_CAMPAIGN_SUCCESS = 'Campaign.DUPLICATE_CAMPAIGN_SUCCESS',
  DUPLICATE_CAMPAIGN_FAILURE = 'Campaign.DUPLICATE_CAMPAIGN_FAILURE',

  READ_DISPLAY_CONFIGS_REQUEST = 'Campaign.READ_DISPLAY_CONFIGS_REQUEST',
  READ_DISPLAY_CONFIGS_SUCCESS = 'Campaign.READ_DISPLAY_CONFIGS_SUCCESS',
  READ_DISPLAY_CONFIGS_FAILURE = 'Campaign.READ_DISPLAY_CONFIGS_FAILURE',

  UPDATE_DISPLAY_CONFIGS_REQUEST = 'Campaign.UPDATE_DISPLAY_CONFIGS_REQUEST',
  UPDATE_DISPLAY_CONFIGS_SUCCESS = 'Campaign.UPDATE_DISPLAY_CONFIGS_SUCCESS',
  UPDATE_DISPLAY_CONFIGS_FAILURE = 'Campaign.UPDATE_DISPLAY_CONFIGS_FAILURE',

  UPDATE_PLUGIN_CONFIG_REQUEST = 'Campaign.UPDATE_PLUGIN_CONFIG_REQUEST',
  UPDATE_PLUGIN_CONFIG_SUCCESS = 'Campaign.UPDATE_PLUGIN_CONFIG_SUCCESS',
  UPDATE_PLUGIN_CONFIG_FAILURE = 'Campaign.UPDATE_PLUGIN_CONFIG_FAILURE',

  CREATE_WEBHOOK_REQUEST = 'Campaign.CREATE_WEBHOOK_REQUEST',
  CREATE_WEBHOOK_SUCCESS = 'Campaign.CREATE_WEBHOOK_SUCCESS',
  CREATE_WEBHOOK_FAILURE = 'Campaign.CREATE_WEBHOOK_FAILURE',

  READ_EVENTS_REQUEST = 'Campaign.READ_EVENTS_REQUEST',
  READ_EVENTS_SUCCESS = 'Campaign.READ_EVENTS_SUCCESS',
  READ_EVENTS_FAILURE = 'Campaign.READ_EVENTS_FAILURE',
}
