import { BrandingNameResponse } from './../branding-data.model'
import { AggregateUserCountNotificationConfigGroupingDuration } from '../campaign/aggregate-user-count-notification.model'
import { CampaignAppearanceTheme, CampaignAppearanceHorizontalPosition, CampaignAppearanceVerticalPosition, CampaignConfigAppearanceAnimation } from '../campaign/campaign'

export interface WidgetNotificationParams {
  device?: 'desktop' | 'mobile'
  view: {
    hide_powered_by: boolean
    disable_powered_by_link?: boolean
    shop: string
    branding_wording: 'verified' | 'verified_by'
    branding_name: BrandingNameResponse
    type: WidgetNotificationViewType | string
    previewMode:  boolean | 'playground'
    desktop: {
      visible: boolean,
      theme: CampaignAppearanceTheme,
      align: CampaignAppearanceHorizontalPosition | string,
      verticalAlign: CampaignAppearanceVerticalPosition | string,
      animation: CampaignConfigAppearanceAnimation
    },
    mobile: {
      visible: boolean,
      theme: CampaignAppearanceTheme,
      verticalAlign: CampaignAppearanceVerticalPosition | string,
      animation: CampaignConfigAppearanceAnimation
    },
    image: {
      uploaded?: boolean,
      src: string
    },
  },
  params: NotificationComponentDataParams
}
export interface NotificationComponentDataParams {
  notification_id: string;
  unique_id: string;
  event_type: string;
  url: string;
  urlTarget: NotificationComponentDataParamsUrlTarget | string;
  title: string;
  subtitle?: string;
  detailSubtitle?: string;
  userCount?: string;
  userName?: string;
  isCloseable?: boolean;
  onClose?: () => void;
  onMouseEnter?: (res: any) => void;
  onMouseLeave?: (res: any) => void;
  onClick?: (res: any) => void;
  lang?: string;
  aggregateDuration?: AggregateUserCountNotificationConfigGroupingDuration;
}

export enum WidgetNotificationViewDesktopAlign {
  Left = 'left',
  Right = 'right',
}

export enum WidgetNotificationViewDesktopVerticalAlign {
  Top = 'top',
  Bottom = 'bottom',
}
export enum WidgetNotificationViewMobileVerticalAlign {
  Top = 'top',
  Bottom = 'bottom',
}

export enum WidgetNotificationViewImageMask {
  None = 'none',
  Circle = 'circle',
  RoundedSquare = 'rounded_square',
}

export enum WidgetNotificationViewTheme {
  Rounded = 'rounded',
  Squared = 'squared',
}

export enum WidgetNotificationViewType {
  LiveVisitorCount = 'LiveVisitorCount',
  AggregateVisitorCount = 'AggregateVisitorCount',
  AggregateActivity = 'AggregateActivity',
  RecentActivity = 'RecentActivity'
}

export enum NotificationComponentDataParamsUrlTarget {
  Blank = '_blank',
  Self = '_self',
}
