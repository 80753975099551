export const featureCardsMockData = [
    {
      title: 'One-Click Upsell (Checkout)',
      points: [
        'One-Click Checkout - Post-Purchase Checkout Page',
        'Urgency - Countdown Timer Bar / Cart Timer ',
        'Upsell Bundles - Discounted Upsell Offers',
        'Cross Sell - You Might Also Like / Frequently Bought Together',
        'Internationalization, Fullfilment Override, Image Carousel & More',
      ],
      price: {
        // superscript: 'Starting at',
        amount: 'Free',
      },
      image: '/app_assets/apps/upsell.svg'
    },
    {
      title: 'Email Marketing',
      points: [
        'Newsletters - Sales Promotion, Product Drops, CTA',
        'Automations - Winback / Upsell / Cart Recovery / Thank You Page',
        'Advance Builder - Countdown Timer, Asset Library, Product Picker',
        'Double Opt-In, Consent Management, Email Validation & More',
      ],
      price: {
        // superscript: 'Starting at',
        amount: '10X Contacts',
        currency: '',
      },
      image: '/app_assets/apps/email-marketing.svg'
    },
    {
      title: 'SMS Marketing',
      points: [
        'Bulk SMS Text Message - GDPR / TCPA',
        'Automations - Cart Recovery / Order Updates / Back-in-Stock',
        'SMS Keywords - Text to Join (Text to Subscribe)',
        'One-Click Subscribe - Quick Subscribe Mobile Forms',
      ],
      price: {
        superscript: 'Starting with',
        amount: '250',
        currency: '',
        period: 'credits'
      },
      image: '/app_assets/apps/sms-marketing.svg'
    },
    {
      title: 'Traditional Lead Capture (Pop Ups)',
      points: [
        'Opt-In - Capture Leads / Birthday / Phone Number / Email Contacts',
        'Exit Intent - A/B Split Test Ultimate Special Offers',
        'Complex Validation Rules - Subscribe Frequency / 1st Order / Coupon Redemption',
        'Smart Link - Multiple Pop Up Journey',
      ],
      price: {
        // superscript: 'Always',
        amount: 'Free',
      },
      image: '/app_assets/apps/optin-popups.svg'
    },
    {
      title: 'Gamified Lead Capture (Pop Ups)',
      points: [
        'Spin to Win Wheel Game',
        'Raffle & Giveaway Products / Coupons / Giftcards',
      ],
      price: {
        // superscript: 'Always',
        amount: 'Free',
      },
      image: '/app_assets/apps/spin-wheel.svg'
    },
    {
      title: 'Social Proof',
      points: [
        'Sales Pop - Real-Time Recent Sales History / Trust Badges',
        'Recently Viewed Product - Live Visitor Counter',
        'Ultimate Sales Boost'
      ],
      price: {
        // superscript: 'Starting at',
        amount: 'Free',
      },
      image: '/app_assets/apps/social-proof.svg'
    },
    {
      title: 'Checkout Abandonment Protector',
      points: [
        'Abandoned Cart Recovery',
        'Abandoned Checkout Recovery',
        'Abandoned Browse Recovery (Beta)'
      ],
      price: {
        // superscript: 'Starting at',
        amount: 'Free',
      },
      image: '/app_assets/apps/cart.svg'
    },
    {
      title: 'Discount Manager',
      points: [
        'Discount Codes',
        'Unique Codes / Buy X Get Y / Buy One Get One',
        'Integrates with Pop Ups, Emails & SMS'
      ],
      price: {
        // superscript: 'Always',
        amount: 'Free',
      },
      image: '/app_assets/apps/discounts.svg'
    },
    {
      title: 'Gift Card Manager',
      points: [
        'Gift Card Codes',
        'Integrates with Pop Ups, Emails & SMS',
      ],
      price: {
        // superscript: 'Always',
        amount: 'Free',
      },
      image: '/app_assets/apps/gift-cards.svg'
    },
    {
      title: 'Product Recommendations',
      points: [
        'Create personalized recommendations based on customer purchase information',
        'Send through Email or SMS'
      ],
      price: {
        // superscript: 'Always',
        amount: 'Free',
      },
      image: '/app_assets/apps/recommendations.svg'
    },
    {
      title: 'Winback / Loyalty',
      points: [
        'Automatically create campaigns to bring customers back to your store',
        'Send through Email or SMS'
      ],
      price: {
        // superscript: 'Starting at',
        amount: 'Free',
      },
      image: '/app_assets/apps/winback.svg'
    },
    {
      title: 'Thank You',
      points: [
        'Automatically send personal notes to your customers after they subscribe or make a purchase',
        'Send through Email or SMS'
      ],
      price: {
        // superscript: 'Always',
        amount: 'Free',
      },
      image: '/app_assets/apps/thank_you.svg'
    },
    {
      title: 'Welcome',
      points: [
        'Automatically send welcome notes whenever a customer creates a new account',
        'Send through Email or SMS'
      ],
      price: {
        // superscript: 'Always',
        amount: 'Free',
      },
      image: '/app_assets/apps/welcome.svg'
    },
    {
      title: 'Birthday',
      points: [
        'Automatically send emails to your customers on their birthday',
        'Integrate with rewards to offer them a birthday present'
      ],
      price: {
        // superscript: 'Always',
        amount: 'Free',
      },
      image: '/app_assets/apps/birthday.svg'
    },
  ]
