export enum EmailAutomationTypeEnum {
  Birthday = 'EmailAutomation::Birthday',
  Upsell = 'EmailAutomation::Upsell',
  WinBack = 'EmailAutomation::WinBack',
  CartRecovery = 'EmailAutomation::CartRecovery',
  WelcomeSubscribers = 'EmailAutomation::Welcome::Subscriber',
  WelcomeCustomers = 'EmailAutomation::Welcome::Customer',
  WelcomeAccounts = 'EmailAutomation::Welcome::Account',
  ThankYou = 'EmailAutomation::Welcome::ThankYou',
}
