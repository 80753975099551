import { GiftCardOnboardingApiService } from './../gift-card-onboarding/services/gift-card-onboarding-api.service';
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { AddressModule } from '../address/address.module'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { PhoneInputModule } from '../phone-input/phone-input.module'
import { CampaignCardsHelperService } from './services/campaign-cards-helper.service'
import { CampaignCardsComponent } from './components/campaign-cards.component'
import { SMSOnboardingApiService } from '../sms-onboarding/services/sms-onboarding-api.service'
import { CardQuickstartModalComponent } from './components/modals/card-quickstart-modal/card-quickstart-modal.component'
import { ScholarshipApplicationModalComponent } from './components/modals/scholarship-application.modal/scholarship-application.modal.component'
import { CardNewModalComponent } from './components/modals/card-new-modal/card-new-modal.component'
import { UpsellPageModule } from '../../../pages/upsell/upsell-page.module'
import { QuickSetupModule } from '../../../pages/campaign-page/quick-setup/quick-setup.module'
import { RouterModule } from '@angular/router';
import { TutorialsService } from '../../../pages/tutorials/services/tutorials.service';
import { OneIconComponent } from '../../components/one-icon/one-icon.component';
import { PromotionCardsComponent } from '../../components/promotion-cards/promotion-cards/promotion-cards.component';
import { SupportBoxsComponent } from '../../components/support-boxs/support-boxs.component';
import { LoadingOverlayComponent } from '../../components/loading-overlay/loading-overlay.component';
import { LogOutComponent } from '../../components/log-out/log-out.component';
import { PreviewNotificationComponent } from '../../components/preview-notification/preview-notification.component';
import { MessageBoxComponent } from '../../components/message-box/message-box.component';
import { QuickSetupCardComponent } from './components/quick-setup-card/quick-setup-card.component'
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  imports: [
    CommonModule,
    AddressModule,
    OneIconComponent,
    RouterModule,
    PhoneInputModule,
    MatProgressSpinnerModule,
    UpsellPageModule,
    QuickSetupModule,
    PromotionCardsComponent,
    SupportBoxsComponent,
    MatDialogModule,
    LoadingOverlayComponent,
    LogOutComponent,
    PreviewNotificationComponent,
    MessageBoxComponent,
    QuickSetupCardComponent
  ],
  declarations: [
    CampaignCardsComponent,
    CardQuickstartModalComponent,
    ScholarshipApplicationModalComponent,
    CardNewModalComponent,
  ],

  exports: [
    CampaignCardsComponent,
  ],
  providers: [
    CampaignCardsHelperService,
    SMSOnboardingApiService,
    TutorialsService,
    GiftCardOnboardingApiService,
  ]
})

export class CampaignCardsModule { }
