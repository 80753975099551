export enum UserActionTypes {
  SET_SIGN_UP_FLOW_COMPLETED = 'User.SET_SIGN_UP_FLOW_COMPLETED',

  SET_USER_CHOSE_PLAN = 'User.SET_USER_CHOSE_PLAN',

  LOGOUT = 'User.LOGOUT',

  SET_PLUGIN_INSTALLED = 'User.SET_PLUGIN_INSTALLED',
  SET_INFO = 'User.SET_INFO',
  RESET_INFO = 'User.RESET_INFO',
  SET_PROMO = 'User.SET_PROMO',

  GET_SITES_REQUEST = 'User.GET_SITES_REQUEST',
  GET_SITES_SUCCESS = 'User.GET_SITES_SUCCESS',
  GET_SITES_FAILURE = 'User.GET_SITES_FAILURE',

  GET_COUPONS_REQUEST = 'User.GET_COUPONS_REQUEST',
  GET_COUPONS_SUCCESS = 'User.GET_COUPONS_SUCCESS',
  GET_COUPONS_FAILURE = 'User.GET_COUPONS_FAILURE',

  GET_ORDERS_REQUEST = 'User.GET_ORDERS_REQUEST',
  GET_ORDERS_SUCCESS = 'User.GET_ORDERS_SUCCESS',
  GET_ORDERS_FAILURE = 'User.GET_ORDERS_FAILURE',

  GET_USER_SHOP_REQUEST = 'User.GET_USER_SHOP_REQUEST',
  GET_USER_SHOP_SUCCESS = 'User.GET_USER_SHOP_SUCCESS',
  GET_USER_SHOP_FAILURE = 'User.GET_USER_SHOP_FAILURE',

  GET_USER_ONBOARDING_REQUEST = 'User.GET_USER_ONBOARDING_REQUEST',
  GET_USER_ONBOARDING_SUCCESS = 'User.GET_USER_ONBOARDING_SUCCESS',
  GET_USER_ONBOARDING_FAILURE = 'User.GET_USER_ONBOARDING_FAILURE'
}
