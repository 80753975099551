import { ConnectedPosition, Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay'
import { ComponentPortal } from '@angular/cdk/portal'
import { Directive, ElementRef, HostListener, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core'
import { OneTooltipComponent } from '../components/one-tooltip/one-tooltip.component'

@Directive({
    selector: '[oneTooltip]',
    standalone: true
})
export class OneTooltipDirective implements OnInit, OnDestroy {
  @Input('oneTooltip') content: string | TemplateRef<any>
  @Input('oneTooltipPosition') position: 'top' | 'bottom'
  @Input('oneTooltipSkipUnderline') skipUnderline: boolean
  @Input('oneTooltipMaxWidth') maxWidth: number
  private overlayRef: OverlayRef
  private tooltipLeaveListener: any
  constructor(
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef,
    private overlay: Overlay
  ) { }

  ngOnInit() {
    if (this.content) {
      const positionStrategy = this.overlayPositionBuilder
        .flexibleConnectedTo(this.elementRef)
        .withPositions([this.topPosition])
      this.overlayRef = this.overlay.create({ positionStrategy })
      this.elementRef.nativeElement.style.cursor = 'pointer'
      if (!this.skipUnderline) {
        this.elementRef.nativeElement.style.borderBottom = '2px dotted #c4cdd5'
      }
    }
  }

  @HostListener('mouseenter') show() {
    if (!this.content) return

    if (this.overlayRef.hasAttached()) {
      this.close()
    }
    const tooltipRef = this.overlayRef.attach(new ComponentPortal(OneTooltipComponent))
    tooltipRef.instance.text = this.content
    if (this.maxWidth) {
      tooltipRef.instance.maxWidth = this.maxWidth
    }
    tooltipRef.location.nativeElement.style.opacity = 0 // don't display tooltip until position is set below

    setTimeout(() => {
      const viewTopPos = this.elementRef.nativeElement.getBoundingClientRect()[this.position || 'top']
      let positions
      if (this.position === 'top') {
        positions = this.topPosition
      } else if (this.position === 'bottom') {
        positions = this.bottomPosition
      } else {
        positions = this.overlayRef.overlayElement.offsetHeight >= viewTopPos ? this.bottomPosition : this.topPosition
      }
      const newPositionStrategy = this.overlay.position()
        .flexibleConnectedTo(this.elementRef)
        .withPositions([positions])
      this.overlayRef.updatePositionStrategy(newPositionStrategy)
      tooltipRef.location.nativeElement.style.opacity = 1
    })

  }

  @HostListener('mouseout', ['$event']) hide(event: any) {
    if (!this.content) return

    if (event?.toElement?.className !== 'cdk-overlay-pane') {
      this.close()
    } else {
      this.tooltipLeaveListener = function () {
        this.overlayRef.overlayElement.removeEventListener('mouseleave', this.tooltipLeaveListener)
        this.close()
      }
      .bind(this)
      this.overlayRef.overlayElement.addEventListener('mouseleave', this.tooltipLeaveListener)
    }
  }

  @HostListener('window:scroll', ['$event']) onScroll(event: any) {
    this.close()
  }

  get topPosition(): ConnectedPosition {
    return {
      originX: 'center',
      originY: 'top',
      overlayX: 'center',
      overlayY: 'bottom',
    }
  }

  get bottomPosition(): ConnectedPosition {
    return {
      originX: 'center',
      originY: 'bottom',
      overlayX: 'center',
      overlayY: 'top',
    }
  }

  close() {
    this.overlayRef?.detach()
  }

  ngOnDestroy() {
    this.close()
  }
}
