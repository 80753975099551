import { GettingStartedActions } from './getting-started.actions'
import { initialGettingStartedState, GettingStartedState } from './getting-started.state'
import { GettingStartedActionTypes } from './getting-started.action-types'


export function gettingStartedReducer(state = initialGettingStartedState, action: GettingStartedActions): GettingStartedState {
  switch (action.type) {
    case GettingStartedActionTypes.SET_CAMPAIGN:
      return { ...state, campaign: action.payload }

    case GettingStartedActionTypes.SET_STATUS:
      return { ...state, status: action.payload }

    case GettingStartedActionTypes.SET_COMPLETED:
      return { ...state, completed: action.payload }

    case GettingStartedActionTypes.SET_EVENTS:
      return { ...state, events: action.payload }

    case GettingStartedActionTypes.SET_FIRSTCAMPAIGN_LAUNCHED:
      return { ...state, firstCampaignLaunched: action.payload }

    case GettingStartedActionTypes.SET_FIRSTCAMPAIGN_SKIPPED:
      return { ...state, firstCampaignSkipped: action.payload }

    case GettingStartedActionTypes.SET_FIRSTCAMPAIGN_ID:
      return { ...state, firstCampaignId: action.payload }

    default:
      return state

  }
}
