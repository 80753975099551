import { Component } from '@angular/core'
import { BasePromoComponent } from '../base-promo.component'
import { ApiPaymentService } from '../../../../../core/services/api/api-payment.service'
import { UntypedFormBuilder } from '@angular/forms'

@Component({
  selector: 'pf-scholarship-promo',
  templateUrl: './scholarship-promo.component.html',
  styleUrls: ['./scholarship-promo.component.scss']
})

export class ScholarshipPromoComponent extends BasePromoComponent {
  constructor(
    public apiPaymentService: ApiPaymentService,
    public fb: UntypedFormBuilder
  ) {
    super(apiPaymentService, fb)
  }

}

