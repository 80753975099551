import { CommonModule } from '@angular/common'
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core'
import * as _ from 'lodash'
import { CrispService } from '../../../../core/services/crisp.service'
import { UserInfo } from '../../../../store/user/user.state'
import { OneTooltipDirective } from '../../../directives/one-tooltip.directive'
import { SubscriptionPlan, SubscriptionPlanData, SubscriptionPlanMaker, SubscriptionPlanLimit, SubscriptionPlanFeature } from '../../../models/subscription-plan.model'

@Component({
    selector: 'pf-plans-item-alt-1',
    templateUrl: './plans-item.component.html',
    styleUrls: ['./plans-item.component.scss'],
    standalone: true,
    imports: [
      CommonModule,
      OneTooltipDirective,
    ]
})
export class PlansItemAlt1Component implements OnChanges, OnInit, OnDestroy {
  @Input() data: SubscriptionPlan
  @Input() user: UserInfo
  @Input() mini: boolean = false
  @Input() shopify: boolean = false
  @Input() hideInactive: boolean = false
  @Input() buttonText: string
  @Input() isCurrentPlan: boolean = false
  @Input() smsEligible: boolean = false
  @Output() onSelect = new EventEmitter()
  public planData: SubscriptionPlanData
  private oldData: SubscriptionPlan
  private changeInterval

  constructor(
    private crispService: CrispService,
  ) {
  }

  ngOnInit() {
    this.changeInterval = setInterval(() => {
      if (!_.isEqual(this.data, this.oldData)) {
        this.oldData = {...this.data}
        this.initPlanData()
      }
    }, 1000)
  }

  ngOnChanges() {
    this.initPlanData()
  }

  ngOnDestroy() {
    clearInterval(this.changeInterval)
  }

  initPlanData() {
    let trialAvailable = this.data?.trial_period
    // if user has available trial and is currently on trial, or has used trial period then set available trial to remaining days
    if (trialAvailable !== 0 && (_.get(this.user, 'subscription.is_trial') || _.get(this.user, 'subscription.trial_period_start'))) {
      trialAvailable = _.get(this.user, 'subscription.trial_days_remaining')
    }

    this.planData = {
      title: this.data.name,
      subtitle: this.getSubtitle(),
      old_display_price: this.data.old_display_price ? '$' + this.data.old_display_price : null,
      price: {
        amount: '$' + this.getPriceAmount(),
        period: '/' + this.data.interval,
      },
      limits: this.getLimits(),
      trial: {
        period: this.getTrialPeriod(trialAvailable)
      },
      isBudding: this.data.payment_gateway_plan_identifier === 'budding',
      trial_period: trialAvailable,
      features: this.shopify || this.mini ? this.getCampaignFeature() : this.getFeatures(),
      trial_extension_text: this.data.trial_extension_text,
      trial_period_text: this.data.trial_period_text
    }

    if (_.get(this.user, 'subscription.plan')) {
      if (this.data.payment_gateway_plan_identifier === 'budding' && this.user.subscription.plan.payment_gateway_plan_identifier === 'budding') {
        const calcUniqueVisits = _.max([this.user.subscription.plan.max_unique_vists, this.user.subscription.max_unique_visits_override]) + this.user.subscription.rewarded_visitor_count
        if (calcUniqueVisits > this.user.subscription.plan.max_unique_vists) {
          this.planData.limits[0].description = calcUniqueVisits.toLocaleString('en-US');
          const maxVisits = _.max([this.user.subscription.plan.max_unique_vists, this.user.subscription.max_unique_visits_override])
          if (maxVisits !== calcUniqueVisits) {
            this.planData.limits[0].sub_description = maxVisits.toLocaleString('en-US');
          }
        }
      } else if (_.get(this.user, 'subscription.rewarded_visitor_count')) {
        this.planData.limits[0].sub_description = this.planData.limits[0].description
        this.planData.limits[0].description = (this.data.max_unique_vists + this.user.subscription.rewarded_visitor_count).toLocaleString('en-US');
      }
    }

    if (this.data.altButton) {
      this.planData.altButton = this.data.altButton
    }
  }

  getSubtitle(): string {
    return SubscriptionPlanMaker.getSubtitle(this.data)
  }

  getPriceAmount(): number {
    return +this.data.price.fractional / this.data.price.currency.subunit_to_unit
  }

  getLimits(): SubscriptionPlanLimit[] {
    return SubscriptionPlanMaker.getLimits(this.data)
  }

  getTrialPeriod(availableTrialDays): string {
    return SubscriptionPlanMaker.getTrialPeriod(this.data, this.shopify, availableTrialDays)
  }

  getFeatures(): SubscriptionPlanFeature[] {
    return SubscriptionPlanMaker.getFeatures(this.data, this.user)
  }

  getCampaignFeature(): SubscriptionPlanFeature[] {
    return SubscriptionPlanMaker.getActiveCampaignFeatures(this.data, this.user, this.smsEligible)
  }

  onSelectPlanClick(data = {}) {
    this.onSelect.emit(data)
  }

  openCrisp(message = 'Hello Team, need help!') {
    const crisp = this.crispService.getCrisp()
    if (crisp) {
      crisp.push(['do', 'chat:open'])
      crisp.push(['do', 'message:send', ['text', message]])
    }
  }
}
