import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnDestroy,
} from '@angular/core'
import { UserInfo } from '../../../store/user/user.state'
import { ValidateCouponResponse } from '../../models/validate-coupon-response'
import { PromoDirective } from './promo.directive'
import { PromoItem } from './promo-views/promo-item'
import { Subscription } from 'rxjs'
import { Promo, PromoType, PromoView } from './models/promo.model'
import { BlackFridayViewComponent } from './promo-views/black_friday/black-friday-view.component'
import { CyberMondayViewComponent } from './promo-views/cyber_monday/cyber-monday-view.component'
import { CyberWeekViewComponent } from './promo-views/cyber-week/cyber-week-view.component'
import { PartnerViewComponent } from './promo-views/partner/partner-view.component'
import { BFCMViewComponent } from './promo-views/bfcm/bfcm-view.component'
import { ScholarshipPromoComponent } from './promo-views/scholarship-promo/scholarship-promocomponent'

@Component({
  selector: 'pf-promo',
  templateUrl: './promo.component.html',
  styleUrls: ['./promo.component.scss'],
})

export class PromoComponent implements OnInit, OnDestroy {
  @ViewChild(PromoDirective, { static: true }) pfPromo: PromoDirective
  @Output() resultEmitter: EventEmitter<any> = new EventEmitter()
  @Input() userInfo: UserInfo
  @Input() userPromo: Promo

  // Promo Templates
  promoComponents = {
    [PromoView.partner]: new PromoItem(PartnerViewComponent, {}, null),
    [PromoView.bfcm]: new PromoItem(BFCMViewComponent, {}, null),
    [PromoView.black_friday]: new PromoItem(BlackFridayViewComponent, {}, null),
    [PromoView.cyber_week]: new PromoItem(CyberWeekViewComponent, {}, null),
    [PromoView.cyber_monday]: new PromoItem(CyberMondayViewComponent, {}, null),
    [PromoView.scholarship]: new PromoItem(ScholarshipPromoComponent, {}, null),
    [PromoView.special_offer]: new PromoItem(PartnerViewComponent, {}, null), // tmp
    [PromoView.spin_to_win]: new PromoItem(PartnerViewComponent, {}, null), // tmp
  }

  showPromo = false
  subscription = new Subscription()

  constructor(
  ) {
  }

  ngOnInit(): void {
    if (this.userPromo) {
      this.showPromo = true
      this.loadComponent(this.userPromo)
    } else {
      this.showPromo = false
    }
  }

  loadComponent(promo: Promo) {
    let activeComponent
    // if promo_view defined it should override promo_type option
    if (promo.promo_view && this.promoComponents[promo.promo_view]) {
      activeComponent = this.promoComponents[promo.promo_view]
    } else {
      // promo_type defines which promo template should be shown only if promo_view is not set
      switch (promo.promo_type) {
        case PromoType.partner:
        case PromoType.partner_promo:
        case PromoType.partner_client:
        case PromoType.partner_list:
          activeComponent = this.promoComponents[PromoView.partner]
          break

        case PromoType.bfcm:
        case PromoType.partner_bfcm_promo:
          activeComponent = this.promoComponents[PromoView.bfcm]
          break

        case PromoType.black_friday:
        case PromoType.partner_black_friday_promo:
          activeComponent = this.promoComponents[PromoView.black_friday]
          break

        case PromoType.cyber_week:
        case PromoType.partner_cyber_week_promo:
          activeComponent = this.promoComponents[PromoView.cyber_week]
          break

        case PromoType.cyber_monday:
        case PromoType.partner_cyber_monday_promo:
          activeComponent = this.promoComponents[PromoView.cyber_monday]
          break

        case PromoType.live_preview:
        case PromoType.live_preview_subscribe_box:
        case PromoType.live_preview_social_proof:
          activeComponent = this.promoComponents[PromoView.special_offer]
          break

        case PromoType.live_preview_wheel:
          activeComponent = this.promoComponents[PromoView.spin_to_win]
          break

        case PromoType.startup_program:
          activeComponent = this.promoComponents[PromoView.scholarship]
          break

        default:
          activeComponent = this.promoComponents[PromoView.partner]
      }
    }
    const viewContainerRef = this.pfPromo.viewContainerRef
    viewContainerRef.clear()

    const componentRef = viewContainerRef.createComponent(activeComponent.component);
    (<PromoItem>componentRef.instance).data = promo
    this.subscription.add(
      (<PromoItem>componentRef.instance).couponApplied.subscribe((res: ValidateCouponResponse) => {
        this.resultEmitter.emit(res)
      }),
    )
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }
}
