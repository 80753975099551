/* eslint-disable @angular-eslint/directive-selector */
import {Directive, ElementRef, HostListener, Input} from '@angular/core'

/* previous selector: '[pfNumberInput]' */
@Directive({
    selector: 'input[type="number"]',
    standalone: true
})
export class NumberInputDirective {

  @Input() positiveIntegers = false
  @Input() wheelScroll = false

  constructor(private elementRef: ElementRef) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(_event: KeyboardEvent) {
    if (this.positiveIntegers) {
      this.elementRef.nativeElement.oninput = function () {
        this.value = this.value.replace(/[^0-9]/g, '')
      }
    }
  }

  @HostListener('wheel', ['$event'])
  onScroll(_event: WheelEvent) {
    if (!this.wheelScroll) {
      this.elementRef.nativeElement.blur();
    }
  }
}
