import {RecentActivityNotification} from './recent-activity-notification'
import {
  AggregateActivityNotification,
  AggregateUserCountNotification,
} from './aggregate-user-count-notification.model'
import {CampaignCaptureSource} from './campaign-capture-source'
import {LiveVisitorCountNotification} from './live-visitor-count-notification.model'
import {CouponBoxNotificationParams, NewsLetterNotificationParams} from './coupon-box-notification.model'
import {CaptureRule} from './capture-rules.model'
import {DisplayRule} from './display-rules.model'
import {PluginConfigureRoutePluginNames} from '../../../pages/campaign-page/plugin-configure/models/plugin-configure.models'
import { FortuneWheelParams } from '../../../pages/campaign-page/plugins/fortune-wheel/models/fortune-wheel.model'
import { BrandingNameResponse, PopupBrandingWordResponse, SpBrandingWordResponse } from '../branding-data.model'
import { InfoPopupNotificationParams } from './info-popup-notification.model'

export enum CampaignPluginName {
  LiveVisitorCount = 'live_user_count_notification',
  RecentActivity = 'user_activity_notification',
  FortuneWheel = 'fortune_wheel_notification',
  AggregateVisitorCount = 'aggregate_user_count_notification',
  AggregateActivity = 'aggregate_user_activity_notification',
  CouponBoxNotification = 'coupon_box_notification',
  NewsLetterNotification = 'newsletter_notification',
  FreeShipping = 'free_shipping_notification',
  ProductPopup = 'products_announcement_notification',
  SalesPopup = 'sales_announcement_notification',
}

export enum PopupPlugins {
  CouponBoxNotification = 'CouponBoxNotification',
  NewsLetterNotification = 'NewsLetterNotification',
  FreeShipping = 'FreeShipping',
  FortuneWheel = 'FortuneWheel',
  SalesPopup = 'SalesPopup',
  ProductPopup = 'ProductPopup'
}

export enum CampaignPluginAppName {
  LiveVisitorCount = 'Live Visitor Count',
  RecentActivity = 'Recent Activity',
  RecentSalesPop = 'Recent Sales Pop',  // userHasShop
  FortuneWheel = 'Spin Wheel',
  AggregateVisitorCount = 'Aggregate Visitor Count',
  AggregateActivity = 'Aggregate Activity',
  AggregateSalesPop = 'Aggregate Sales Pop', // userHasShop
  CouponBoxNotification = 'Pop up with Reward',
  NewsLetterNotification = 'Pop up without Reward',
  FreeShippingPopup = 'Free Shipping Announcement',
  SalesPopup = 'Sales Announcement',
  ProductPopup = 'Product Announcement'
}

export enum CampaignPluginRouteByConfigName {
  'live_user_count_notification' = PluginConfigureRoutePluginNames.LiveVisitorCount,
  'user_activity_notification' = PluginConfigureRoutePluginNames.RecentActivity,
  'fortune_wheel_notification' = PluginConfigureRoutePluginNames.FortuneWheel,
  'aggregate_user_count_notification' = PluginConfigureRoutePluginNames.AggregateVisitorCount,
  'aggregate_user_activity_notification' = PluginConfigureRoutePluginNames.AggregateActivity,
  'coupon_box_notification' = PluginConfigureRoutePluginNames.CouponBox,
  'newsletter_notification' = PluginConfigureRoutePluginNames.NewsLetterNotification,
  'free_shipping_notification' = PluginConfigureRoutePluginNames.FreeShippingNotification,
  'sales_announcement_notification' = PluginConfigureRoutePluginNames.SalesPopup,
  'products_announcement_notification' = PluginConfigureRoutePluginNames.ProductPopup
}

export const CampaignTypeList = [
  CampaignPluginName.AggregateActivity,
  CampaignPluginName.LiveVisitorCount,
  CampaignPluginName.AggregateVisitorCount,
  CampaignPluginName.RecentActivity,
  CampaignPluginName.FortuneWheel,
  CampaignPluginName.CouponBoxNotification,
  CampaignPluginName.NewsLetterNotification,
  CampaignPluginName.FreeShipping,
  CampaignPluginName.SalesPopup,
  CampaignPluginName.ProductPopup,
]

export enum CampaignStatusReason {
  Validity = 'validity',
  State = 'state',
  OverLimit = 'over_limit',
  Billing = 'billing',
}

export enum CampaignStatusCode {
  Paused = 'paused',
  Inactive = 'inactive',
  Active = 'active',
}

export enum CampaignState {
  Off = 'off',
  On = 'on',
  Archived = 'archived',
  Draft = 'draft',
}

export enum CampaignDisplayStatus {
  AllExceptArchived = 'all_except_archived',
  All = 'all',
  Off = 'off',
  Draft = 'draft',
  Live = 'live',
  Paused = 'paused',
  Invalid = 'broken',
  Archived = 'archived'
}

export const CampaignDisplayStatusToFilterStatus = {
  [CampaignDisplayStatus.AllExceptArchived]: 'all_except_archived',
  [CampaignDisplayStatus.All]: 'all',
  [CampaignDisplayStatus.Off]: 'off',
  [CampaignDisplayStatus.Live]: 'on',
  [CampaignDisplayStatus.Draft]: 'draft',
  [CampaignDisplayStatus.Paused]: 'paused',
  [CampaignDisplayStatus.Invalid]: 'invalid',
  [CampaignDisplayStatus.Archived]: 'archived',
}

export enum EmailSendingStatus {
  Sending = 'Sending',
  Off = 'Off',
  Paused = 'Paused',
  Expired = 'Expired',
  Failed = 'Failed',
}

export interface AppConnections {
  coupon_required?: boolean
  coupons: boolean
  rewards: boolean
  email_automations: boolean
  sms_automations: boolean
  integrations: boolean
}

export interface FieldsConfig {
  email: boolean
  phone: boolean
}

export interface Campaign {
  id?: string,
  uid?: string,
  active: boolean,
  paused?: boolean,
  name: string,

  validity: {
    status: boolean,
    messages?: CampaignValidityMessage[]
  }

  config: CampaignConfig,
  display_configs: DisplayRule[],
  display_configs_attributes?: DisplayRule[],
  state?: CampaignState
  status?: {
    code?: CampaignStatusCode
    reason?: CampaignStatusReason
  }
  filter_by_countries: boolean
  targeted_countries: Array<string>
  excluded_countries: Array<string>
  filter_by_schedule: boolean
  schedule: CampaignSchedule
  plugin_types?: {
    [CampaignPluginName.AggregateActivity]: boolean,
    [CampaignPluginName.LiveVisitorCount]: boolean,
    [CampaignPluginName.RecentActivity]: boolean,
    [CampaignPluginName.FortuneWheel]: boolean,
    [CampaignPluginName.AggregateVisitorCount]: boolean,
    [CampaignPluginName.CouponBoxNotification]: boolean,
    [CampaignPluginName.NewsLetterNotification]: boolean,
    [CampaignPluginName.FreeShipping]: boolean,
    [CampaignPluginName.SalesPopup]: boolean,
    [CampaignPluginName.ProductPopup]: boolean,
  },
  [CampaignPluginName.LiveVisitorCount]: LiveVisitorCountNotification,
  [CampaignPluginName.RecentActivity]: RecentActivityNotification,
  [CampaignPluginName.FortuneWheel]: FortuneWheelParams,
  [CampaignPluginName.AggregateVisitorCount]: AggregateUserCountNotification,
  [CampaignPluginName.AggregateActivity]: AggregateActivityNotification,
  [CampaignPluginName.CouponBoxNotification]: CouponBoxNotificationParams,
  [CampaignPluginName.FreeShipping]: InfoPopupNotificationParams,
  [CampaignPluginName.SalesPopup]: InfoPopupNotificationParams,
  [CampaignPluginName.ProductPopup]: InfoPopupNotificationParams,
  [CampaignPluginName.NewsLetterNotification]: NewsLetterNotificationParams,

  connections?: {
    // [CampaignPluginName.AggregateActivity]?: AppConnections,
    // [CampaignPluginName.LiveVisitorCount]?: AppConnections,
    // [CampaignPluginName.RecentActivity]?: AppConnections,
    // [CampaignPluginName.AggregateVisitorCount]?: AppConnections,
    [CampaignPluginName.FortuneWheel]?: AppConnections,
    [CampaignPluginName.CouponBoxNotification]?: AppConnections,
    [CampaignPluginName.NewsLetterNotification]?: AppConnections,
    [CampaignPluginName.FreeShipping]?: AppConnections,
    [CampaignPluginName.SalesPopup]?: AppConnections,
    [CampaignPluginName.ProductPopup]?: AppConnections,
  }

  input_fields?: {
    [CampaignPluginName.NewsLetterNotification]: FieldsConfig
    [CampaignPluginName.FortuneWheel]: FieldsConfig
    [CampaignPluginName.CouponBoxNotification]: FieldsConfig
  }

  updated_at: string,
  launched_at: string,
  remove_branding?: boolean,
  disable_powered_by_link?: boolean
  branding_name?: BrandingNameResponse // legacy sp
  branding_wording?: 'verified' | 'verified_by' // legacy sp
  branding?: {
    branding_name: BrandingNameResponse, // new sp
    branding_wording: SpBrandingWordResponse, // new sp
    popup_branding_name: PopupBrandingWordResponse,
    popup_branding_wording: PopupBrandingWordResponse
  }
  tags?: string[][]
}

export interface CampaignSchedule {
  timezone: string,
  timezone_policy: 'local' | 'global',
  periods: {
    type: CampaignSchedulePeriodType,
    starts_at: {
      date: string,
      time: string
    },
    ends_at: {
      date: string,
      time: string
    }
    rules?: any[]
  }[]
}

export enum CampaignSchedulePeriodType {
  On = 'on',
  Between = 'between',
  After = 'after',
  Before = 'before',
}

export enum CampaignConfigAppearanceAnimation {
  Fade = 'fade',
  SlideInHorizontal = 'slide-in-horizontal',
  SlideInVertical = 'slide-in-vertical'
}

export interface CampaignConfig {
  appearance: {
    desktop: {
      visible: boolean,
      animation: CampaignConfigAppearanceAnimation,
      vertical_position: CampaignAppearanceVerticalPosition,
      horizontal_position: CampaignAppearanceHorizontalPosition,
      theme: CampaignAppearanceTheme,
    },
    mobile: {
      visible: boolean
      animation: CampaignConfigAppearanceAnimation,
      vertical_position: CampaignAppearanceVerticalPosition,
      horizontal_position?: CampaignAppearanceHorizontalPosition,
      theme: CampaignAppearanceTheme,
    }
  },
  timing: {
    display_duration: number,
    delay_between_notifications: number,
    delay_before_initial_notification: number,
    random_delay: boolean,
    min_random_delay: number,
    max_random_delay: number,
  },
  css: {
    style: string,
    active: boolean,
    variables?: any
  },
  language?: {
    locale?: string
  }
}


export interface CampaignValidityMessage {
  name: CampaignValidityMessageName,
  plugin?: CampaignPluginName,
  type: 'error' | 'warning'
}

export enum CampaignValidityMessageName {
  ScheduleDateInValid = 'schedule_date_invalid',
  NoPixelDetected = 'no_pixel_detected',
  NoCaptureConfigs = 'no_capture_configs',
  NoCaptureSource = 'no_capture_source',
  NoContainsCaptureConfigs = 'no_contains_capture_configs',
  NoEvents = 'no_events',
  InvalidSlicesConfig = 'invalid_slices_config',
  NoDisplayConfigs = 'no_display_configs',
  NoWhitelistCaptureConfigs = 'no_whitelist_capture_configs',
  NoWhitelistDisplayConfigs = 'no_whitelist_display_configs',
  DisplayConfigConflict = 'display_config_conflict',
  EmptyDisplayConfig = 'empty_display_config',
  NoRewardSet = 'blank_reward',
  NoSMSKeywordSet = 'tcpa_quick_subscribe_sms_keyword_missing',
  BCBCouponCodeExpired = 'bcb_coupon_code_expired',
  BCBCouponCodeDisabled = 'bcb_coupon_code_disabled',
  BCBRewardExpired = 'bcb_reward_expired',
  BCBRewardDisabled = 'bcb_reward_disabled',
  BCBCouponCodeWithoutAutoResponder = 'bcb_send_coupon_code_without_auto_responder_email',
  MarketingEmailsWithoutEmailField = 'marketing_emails_without_email_field',
  MarketingEmailsWithoutEmailCredits = 'marketing_emails_without_email_credits',
  MarketingSMSWithoutPhoneField = 'marketing_sms_without_phone_field',
  MarketingSMSWithoutTcpaEnabled = 'marketing_sms_without_tcpa_enabled',
  MarketingSMSWithoutSMSCredits = 'marketing_sms_without_sms_credits',
  FWCouponCodeWithoutAutoResponder = 'fw_send_coupon_code_without_auto_responder_email',
  FWGiftCardCodeWithoutAutoResponder = 'fw_send_gift_card_code_without_auto_responder_email',
  FWNoAwardableSlice = 'fw_no_awardable_slice',
  FWAllSlicesZeroGravity = 'fw_all_slices_zero_gravity',
  FWCouponCodeExpired = 'fw_coupon_code_expired',
  FWCouponCodeDisabled = 'fw_coupon_code_disabled',
  NoPopupSelected = 'blank_popup_field',
  FWDoubleOptInWithoutActiveEmail = 'fw_double_opt_in_without_active_email',
  BCBDoubleOptInWithoutActiveEmail = 'bcb_double_opt_in_without_active_email',
  BSBDoubleOptInWithoutActiveEmail = 'bsb_double_opt_in_without_active_email',
  NoPlugin = 'no_plugin',
  NoProducts = 'blank_items',
  RuleConflict = 'rule_conflict',
}

export interface DataSourceOutput {
  id: string,
  campaign_id?: string,
  campaign_name?: string,
  _destroy?: boolean,
}

export interface DataSourceLocal {
  campaignId: string,
  userActivityNotificationId: string,
  name: string,
  collapsed: boolean,
  type: string,
  _destroy?: boolean,
  captureSource: CampaignCaptureSource,
  captureConfigs: CaptureRule[],
  webhook: object
}

export enum CampaignCaptureType {
  Form = 'form',
  Webhook = 'webhook',
}

export enum CampaignPluginDataType {
  Phone = 'phone',
  Email = 'email',
}

export enum CampaignAppearanceTheme {
  Rounded = 'rounded',
  Squared = 'squared',
}

export enum CampaignAppearanceHorizontalPosition {
  Right = 'right',
  Left = 'left',
}

export enum CampaignAppearanceVerticalPosition {
  Top = 'top',
  Bottom = 'bottom',
}

export enum CampaignObjectivesClickUrlTarget {
  Blank = '_blank',
  Self = '_self',
}


export enum ConversionEventStatusCode {
  Displayed = 'displayed',
  Ignored = 'ignored',
  Filtered = 'filtered',
  Removed = 'removed',
}

export interface ConversionEvent {
  metadata: any
  id: string
  type: CampaignCaptureType
  email: string
  first_name: string
  image_url: string
  city: string
  state: string
  country: string
  ip: string
  custom_data: any
  user_activity_notification_id: string
  created_at: string
  updated_at: string
  target_url: string
  unique_identifier: string
  proof_factor_created_date: string
  latitude: string
  longitude: string
  render_event_data: {}
  render_event_image_url: string
  status: {
    code: ConversionEventStatusCode
    reason?: string
    message?: string
    info?: {
      error?: string
    }
  }
  map_image_path: string
}

export enum CampaignNotificationType {
  UserActivityNotification = 'Campaign::UserActivityNotification',
  AggregateUserActivityNotification = 'Campaign::AggregateUserActivityNotification',
  LiveUserCountNotification = 'Campaign::LiveUserCountNotification',
  FortuneWheelNotification = 'Campaign::FortuneWheelNotification',
  AggregateUserCountNotification = 'Campaign::AggregateUserCountNotification',
  CouponBoxNotification = 'Campaign::CouponBoxNotification',
  NewsletterNotification = 'Campaign::NewsletterNotification',
}

export const CampaingNotificationTypeToPluginName = {
  [CampaignNotificationType.LiveUserCountNotification]: CampaignPluginName.LiveVisitorCount,
  [CampaignNotificationType.UserActivityNotification]: CampaignPluginName.RecentActivity,
  [CampaignNotificationType.AggregateUserCountNotification]: CampaignPluginName.AggregateVisitorCount,
  [CampaignNotificationType.AggregateUserActivityNotification]: CampaignPluginName.AggregateActivity,
  [CampaignNotificationType.FortuneWheelNotification]: CampaignPluginName.FortuneWheel,
  [CampaignNotificationType.CouponBoxNotification]: CampaignPluginName.CouponBoxNotification,
  [CampaignNotificationType.NewsletterNotification]: CampaignPluginName.NewsLetterNotification,
}

export const PluginNameToCampaignNotificationType = {
  [CampaignPluginName.LiveVisitorCount]: CampaignNotificationType.LiveUserCountNotification,
  [CampaignPluginName.RecentActivity]: CampaignNotificationType.UserActivityNotification,
  [CampaignPluginName.AggregateVisitorCount]: CampaignNotificationType.AggregateUserCountNotification,
  [CampaignPluginName.AggregateActivity]: CampaignNotificationType.AggregateUserActivityNotification,
  [CampaignPluginName.FortuneWheel]: CampaignNotificationType.FortuneWheelNotification,
  [CampaignPluginName.CouponBoxNotification]: CampaignNotificationType.CouponBoxNotification,
  [CampaignPluginName.NewsLetterNotification]: CampaignNotificationType.NewsletterNotification,
}
