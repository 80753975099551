<section>
    <div class="L-GiftCard">
      <div class="L-GiftCard-Content Card">
        <h4 *ngIf="!isEditMode" class="L-GiftCard-Content-Title">Create New Gift Card</h4>

        <form class="GiftCard-Form" [class._is-edit]="isEditMode" [formGroup]="formGroup">
          <h5 class="GiftCard-Form-Subtitle"
          >
            Title
            <span class="Tooltip mt-1 mr-auto"
                  matTooltip="Title is for your own reference. It can contain any value."
                  matTooltipClass="Tooltip-Box" matTooltipPosition="above"
            ></span>
          </h5>

          <div class="PluginConfig-Line">
            <input type="text" class="form-control" maxlength="32" formControlName="title"
                   placeholder="e.g. $5 OFF">
            <div class="invalid-feedback">
              Please provide a Title
            </div>
          </div>
          <h5 class="GiftCard-Form-Subtitle">Unique Code</h5>
          <div class="GiftCard-Config">
            <div class="PluginConfig-Line">
              <label class="d-flex justify-content-between">
                Code Prefix (Optional)
                <span class="Tooltip mt-1 ml-1 mr-auto"
                      matTooltip="Add a prefix to the customer’s uniquely generated code."
                      matTooltipClass="Tooltip-Box" matTooltipPosition="above"
                ></span>
              </label>
              <div class="GiftCard-Prefix" [class._error]="formGroup.hasError('codeLengthError') || codeSpecialError">
                <input type="text" class="form-control" formControlName="prefix_code"
                       [maxlength]="(maxGiftCardChars - formGroup.getRawValue().code_length).toString()"
                       (keydown)="validateInput($event)"
                       (focus)="onPrefixCodeFocus()"
                       (focusout)="onPrefixCodeFocusOut()"
                       placeholder="e.g. WELCOME">
                <div class="GiftCard-Prefix-Code-Example">
                  <span *ngIf="randomCodeString">{{ randomCodeString }}</span>
                </div>
              </div>
              <small
                class="GiftCard-Tip"
                [class._text-danger]="formGroup.hasError('codeLengthError')"
                *ngIf="prefixInputFocused; else prefixTip">
                {{ summary.code.length }} / 20 characters
              </small>
              <small class="_text-danger" *ngIf="formGroup.hasError('codeLengthError') && !prefixInputFocused">
                The total code length should be between 8 to 20 characters.
              </small>
              <small class="_text-danger" *ngIf="codeSpecialError && !prefixInputFocused">
                Remove special characters
              </small>
              <ng-template #prefixTip>
                <small *ngIf="!prefixInputFocused && !formGroup.hasError('codeLengthError') && !codeSpecialError" class="GiftCard-Tip">
                  (Keep it short, memorable, and sweet.)
                </small>
              </ng-template>
            </div>
            <div class="PluginConfig-Line">
              <label class="d-flex justify-content-between">
                Generated Code Length
              </label>
              <div>
                <input type="number" min="6" max="16" class="form-control _small mt-3" pfNumberInput
                       (change)="onCodeLengthChange($event)"
                       formControlName="code_length">
              </div>
              <small class="GiftCard-Tip">
                (Up to {{formGroup.getRawValue().code_length}} random letters and numbers.)
              </small>
              <div class="invalid-feedback">
                Please use a coupon code with length between 6 and 16 characters to minimize the chance that two
                customers receive the same coupon code.
              </div>
            </div>
          </div>

          <div>
            <h5 class="GiftCard-Form-Subtitle">Behavior</h5>

            <div class="PluginConfig-Line">
              <label class="PluginConfig-Control-Label _black _bold _with-switch-fw">
                Expires after a certain number of years/days
                <pf-switch-box
                  [labelInside]="true"
                  labelOff="Off"
                  formControlName="expires_after_enabled">
                </pf-switch-box>
              </label>

              <p [class.is-hidden]="!formGroup.getRawValue().expires_after_enabled">
                Countries have different laws for gift card expiry dates (ex: 5 years minimum in the US). Check the laws for your
                country before changing this date.
              </p>


              <div class="form-inline" [class.is-hidden]="!formGroup.getRawValue().expires_after_enabled">
                <label class="mr-3">Expires After</label>
                <ng-container *ngIf="!isEditMode">
                  <div class="config-control _double _local-behavior-options">
                    <input [class.is-invalid]="
                      formGroup.get('expires_after_years').errors ||
                      formGroup.get('expires_after_days').errors" type="number" #timeInput
                      [value]="selectedTime" step="1" min="1" placeholder="0" class="form-control _medium"
                      (focus)="onTimeFocus(selectedUnit)"
                      (click)="onNumberInputClick($event)"
                      (focusout)="onTimeChange(selectedUnit, $any($event.target).value)">
                      <mat-form-field class="pf-form-field ml-2">
                        <mat-select [(value)]="selectedUnit" class="form-control _large" (selectionChange)="onUnitChange(selectedUnit)">
                          <mat-option *ngFor="let option of unitOptions" [value]="option.value">
                            {{option.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                  </div>
                  <br>
                    <div class="error-text">
                      <small class="text-danger mb-1" *ngIf="
                      formGroup.get('expires_after_years').errors?.min ||
                      formGroup.get('expires_after_days').errors?.min">
                        Please provide a value greater than 0.
                      </small>
                    </div>
                  <input type="hidden" class="form-control" formControlName="expires_after_years">
                  <input type="hidden" class="form-control" formControlName="expires_after_days">
                </ng-container>
                <ng-container *ngIf="isEditMode">
                  <div class="config-control _double">
                    <input type="number" [value]="editValue" class="form-control _medium" disabled>
                    <mat-form-field class="pf-form-field ml-2">
                      <mat-select [(value)]="editOption" class="form-control _large disabled" disabled="true">
                        <mat-option *ngFor="let option of unitOptions" [value]="option.value">
                          {{option.name}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>

          <div>
            <h5 class="GiftCard-Form-Subtitle">Gift Card Rules</h5>
            <div class="GiftCard-Config">
              <ng-container>
                <div class="PluginConfig-Line">
                  <label>Gift Card Type</label>
                  <select disabled class="form-control">
                    <option>Reward / Incentive</option>
                  </select>
                </div>

                <div class="PluginConfig-Line">
                  <label>Gift Card Value</label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">{{currency}}</span>
                    </div>
                    <input type="number" min="0" class="form-control" formControlName="value">
                    <ng-container *ngIf="formGroup.get('value').errors; let errors">
                      <div *ngIf="errors?.required || errors?.min" class="invalid-feedback">
                        Please provide a value greater than 0
                      </div>
                      <div *ngIf="errors?.maxAmount" class="invalid-feedback">
                        Enter an amount below {{formattedMaxValue}} {{currency}}
                      </div>
                    </ng-container>

                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </form>
      </div>
      <div class="L-GiftCard-Aside">
        <div class="GiftCard-Summary">
          <div class="text-dark" >
            <div class="GiftCard-Code">
                <h5>Gift Card Code Preview</h5>
                <small class="GiftCard-Code-Warning-Text">
                  [Sample] Real Code Generated on Popup
                </small>
                <pre>{{summary.code}} </pre>
            </div>
            <ul class="mt-4">
              <li>{{summary.discount}} {{summary.currency}} of all products</li>
            </ul>
            <div class="d-flex justify-content-around mt-4">
              <button class="pf-button md outline gray" (click)="cancel()">CANCEL</button>
              <button class="pf-button md filled blue" (click)="submit()">SAVE</button>
            </div>
          </div>
        </div>
      </div>
    </div>
</section>
<pf-loading-overlay [filter]="{only: ['new-gift-card-code']}" class="loading-overlay"></pf-loading-overlay>
