import { Component, Input, OnDestroy, OnInit, Renderer2, inject } from '@angular/core'
import { StoreState } from '../../../../store/store.state'
import { select, Store } from '@ngrx/store'
import { ActivatedRoute, Router } from '@angular/router'
import { HideLoading, ResetLoading, ShowLoading } from '../../../../store/loading/loading.actions'
import { ValidateCouponResponse } from '../../../models/validate-coupon-response'
import { getSignUpFlowCompleted, getUserPromo } from '../../../../store/user/user.selectors'
import * as _ from 'lodash'
import { UserInfo } from '../../../../store/user/user.state'
import { combineLatest, forkJoin, Subscription } from 'rxjs'
import { SMSService } from '../../../services/sms.service'
import { SegmentAnalyticsService } from '../../../services/segment-analytics.service'
import { GettingStartedService } from '../../../../core/services/getting-started.service'
import { UserService } from '../../../../core/services/user.service'
import * as mustache from 'mustache'
import { BrandingService } from '../../../../core/services/branding.service'
import {
  PlansV3Identifier,
  SubscriptionPlan,
  SubscriptionPlanData,
  UsagePlan,
} from '../../../models/subscription-plan.model'
import { ConfirmModalComponent, ConfirmModalConfig } from '../../modals/confirm.modal/confirm.modal.component'
import { Promo } from '../../../modules/promo/models/promo.model'
import { ShopifySubscribersApiService } from '../../../../pages/subscribers/services/shopify-subscribers-api.service'
import { featureCardsMockData } from './data/feature-cards-mock-data'
import { ShopifyService } from '../../../../core/services/shopify.service'
import { PaymentService } from '../../../../core/services/payment.service'
import { ChargeResponse } from '../../../models/charge-response.model'
import { LogLabel } from '../../../models/logger/log-label.model'
import { Logger } from '../../../../core/services/logger.service'
import { Redirect } from '@shopify/app-bridge/actions'
import { SetCurrentUserInfo, SetUserChosePlan } from '../../../../store/user/user.actions'
import { CustomSnackbarService } from '../../../modules/custom-snackbar/custom-snackbar.service'
import { filter, finalize, take } from 'rxjs/operators'
import { UsagePlanMetricType } from '../models/usage-plan-metric.model'
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms'
import { ApiJotformService } from '../../../../core/services/api/api-jotform.service'
import { keyValuePairKey } from '../../../models/key-value-pair.model'
import { PlansVersion } from '../models/plans-version.model'
import { PlatformOnboardingStatus } from '../../../models/platform-onboarding.model'
import { ActiveAppCampaignService } from '../../../../core/services/active-app-campaign.service'
import { includedFeaturesMockData } from './data/included-features-mock-data'
import { SafeLocalStorageService } from '../../../../core/services/safe-local-storage.service'
import { CrispService } from '../../../../core/services/crisp.service'
import { CommonModule } from '@angular/common'
import { FloatingTabModule } from '../../../modules/floating-tab/floating-tab.module'
import { PromoModule } from '../../../modules/promo/promo.module'
import { OneTooltipDirective } from '../../../directives/one-tooltip.directive'
import { NgSelectModule } from '@ng-select/ng-select'
import { ShortNumberPipe } from '../../../pipes/short-number.pipe'
import { SwiperComponent } from '../../swiper/swiper.component'
import { SupportBoxsComponent } from '../../support-boxs/support-boxs.component'
import { LoadingOverlayComponent } from '../../loading-overlay/loading-overlay.component'
import { OneIconComponent } from '../../one-icon/one-icon.component'
import {
  ActiveCampaignDropdownComponent,
} from '../../one-header/components/active-campaign-dropdown/active-campaign-dropdown.component'
import { RouteHeaderUrl } from '../../one-header/header-navigation.model'
import moment from 'moment'
import { AllowedFreePlanUsers } from '../allowed-free-plan-users'
import { ShopSwitchService } from '../../one-side-nav/one-shop-switch/services/one-shop-switch.service'
import {
  CustomPricePlanModalType,
  CustomPricePlanReminderModal,
} from '../../modals/custom-price-plan-reminder-modal/custom-price-plan-reminder-modal.component'
import { AdminUserTokenService } from '../../../../core/services/admin-user-token.service'
import { ChosePlanCompleteGuard } from '../../../../core/guards/chose-plan-complete.guard'
import { MatDialog } from '@angular/material/dialog'
import { CartService } from '../../../../pages/cart/services/cart.service'
import { ProductPageConfig } from '../../../../pages/cart/services/products-page-config'
// import { ProductPageConfigId } from '../../../../pages/cart/models/cart-models'
import { CartApiService } from '../../../../pages/cart/services/cart-api.service'

@Component({
    selector: 'pf-plans-version-3-view-1',
    templateUrl: './plans-v3-1.component.html',
    styleUrls: ['./plans-v3-1.component.scss'],
    standalone: true,
    imports: [
      CommonModule,
      FloatingTabModule,
      PromoModule,
      OneTooltipDirective,
      ActiveCampaignDropdownComponent,
      NgSelectModule,
      ReactiveFormsModule,
      ShortNumberPipe,
      SwiperComponent,
      SupportBoxsComponent,
      LoadingOverlayComponent,
      OneIconComponent,
    ]
})

// Version 3 represents the fact that this page uses plan.version === 3 plans
// View 1 is for future in case we add another page that gets plan.version === 3 data
export class PlansVersion3View1 implements OnDestroy, OnInit {
  @Input() pageTitle = ''
  @Input() userType = 'new'
  @Input() noCurrentPlan // A dirty temporary hack
  activeCampaignDropdownComponent = ActiveCampaignDropdownComponent
  plans: SubscriptionPlan[] = []
  visiblePlans = []
  
  forbiddenShopifyPlans = inject(ChosePlanCompleteGuard).hideStripePlansFrom
  stripePriceVisible = false

  isShopify: boolean
  shopType: string
  userInfo: UserInfo
  userDomain: string
  currentPlan: SubscriptionPlanData = null
  currentUsagePlan: UsagePlan = null
  currentPlanIdentifier = null
  coupon: ValidateCouponResponse = null
  staticPromo: Promo = {
    promo_code: 'ONE STORE BFCM22',
    promo_type: 'bfcm',
    promo_referrer: 'onestore',
    promo_view: 'bfcm',
  }
  userPromo: Promo = this.userType !== 'existing' ? this.staticPromo : null
  brandingName: string
  subscription = new Subscription()
  // initialPaths = '/onboarding/signup/new/apps/view/standard'
  initialPaths = `/${RouteHeaderUrl.apps}`

  /** match with the default null value in BehaviorSubject
  /* contactsCount$ --> knownCustomers
  /* activeKnownCustomersCount$ --> activeKnownCustomers
  /* activeCampaignCount$ --> activeCampaignCount
  **/
  knownCustomers = null
  activeKnownCustomers = null
  activeCampaignCount = null

  totalsLoaded = false

  hideQuestions = true

  readonly usagePlanMetric: UsagePlanMetricType = UsagePlanMetricType.ActiveKnownCustomers

  featureCards = featureCardsMockData

  plansIdentifier = PlansV3Identifier
  plansOrder: PlansV3Identifier[] = [
    PlansV3Identifier.NewCustomFree,
    PlansV3Identifier.NewCustomPaid,
    PlansV3Identifier.Free,
    PlansV3Identifier.Starter,
    PlansV3Identifier.Basic,
    PlansV3Identifier.Pro,
    PlansV3Identifier.Custom,
    PlansV3Identifier.Enterprise,
  ]

  freePlanDisabled = false
  defaultPromoApplied = false

  paidUserPlan = false

  floatingTabDiscountHidden = true
  floatingTabDiscountCode: string
  floatingTabDiscountValue: number

  smallSizeStore: boolean
  midSizeStore: boolean
  largeSizeStore: boolean

  selectControls = {}

  lowestTierMax = 250
  highestTierMin = 6500 // It seems like we don't have enterprise/custom plan for now
  plansVersion = PlansVersion.V3
  isDisablingOn = [true, true, true, true]

  calendlySchedulingForPlan = null

  uninstalledApp = false

  popularPlanId = null
  recommendedPlanId = null
  userOverCurrentCampaignLimit = false
  tryingToActivateOverLimit = 0

  customPlanInvoice = false

  pricingModalPromos = ['GLASS_HALF_FULL', 'LOYALTYQUARTER', 'LOYALTEN']

  loadingOverlayText = 'Please wait, we are syncing with Shopify...'

  includedFeatures = includedFeaturesMockData
  activeCampaignDropdownVisible = {}
  maxShownUsage = 5000

  isOnboardingPage = true

  productPageConfig = new ProductPageConfig()
  // plansThatCantSeeStripe = inject(ChosePlanCompleteGuard).hideStripePlansFrom
  forceStripePricing = inject(CartService).forceStripePricing
  private cartApiService = inject(CartApiService)
  private stripePrice

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private store: Store<StoreState>,
    private brandingService: BrandingService,
    private renderer: Renderer2,
    private smsService: SMSService,
    private segmentAnalyticsService: SegmentAnalyticsService,
    private shopifySubscribersApiService: ShopifySubscribersApiService,
    private gettingStartedService: GettingStartedService,
    private userService: UserService,
    private safeLocalStorageService: SafeLocalStorageService,
    private shopifyService: ShopifyService,
    private route: ActivatedRoute,
    private crispService: CrispService,
    private paymentService: PaymentService,
    private logger: Logger,
    private snackbarService: CustomSnackbarService,
    private apiJotformService: ApiJotformService,
    private adminUserTokenService: AdminUserTokenService,
    private activeAppCampaignService: ActiveAppCampaignService,
    private shopSwitchService: ShopSwitchService
    ) {
    this.brandingName = this.brandingService.getBrandingData().name

    this.subscription.add(this.store.pipe(select(getSignUpFlowCompleted)).subscribe())

    // Global Event
    this.segmentAnalyticsService.track('Platform | Subscriptions | Viewed')
    // Funnel Event: Onboarding Flow
    this.segmentAnalyticsService.track('Onboarding | Platform | Subscriptions | Viewed')
  }

  get disablingOn() {
    return this.isDisablingOn[this.plansVersion]
  }

  ngOnInit() {
    this.isOnboardingPage = window.location.pathname.includes('onboarding/signup')
    this.renderer.addClass(document.body, '_white-bg')
    this.renderer.addClass(document.body, '_no-content-top-padding')
    this.userInfo = this.userService.userInfo
    this.shopType = this.userInfo.shop?.type
    this.isShopify = this.userService.isShopifyShop
    this.userDomain = this.userInfo.shop?.domain
    this.uninstalledApp = !this.userInfo?.shop?.is_installed
    this.userOverCurrentCampaignLimit = this.userInfo.subscription?.campaigns_limit?.is_over_limit
    this.customPlanInvoice = this.userInfo?.custom_plan_invoice
    this.store.dispatch(new ShowLoading('LoadingPlans'))

    if (this.userType === 'returning' && this.userService.invoiceDueDatePassed) {
      this.dialog.closeAll()
      this.dialog.open(CustomPricePlanReminderModal, {
        width: '410px',
        disableClose: true,
        data: {
          type: CustomPricePlanModalType.invoiceReminder,
          accountLocked: true
        },
      })

    }
    // FIXME: Shouldn't fetch it if stripe pricing is not visible to the user
    // let stripePriceId
    // this.productPageConfig.config.forEach(card => {
    //   card.sections.forEach(section => {
    //     if (section.id === ProductPageConfigId.shopify) {
    //       stripePriceId = section.products[0].priceId
    //     }
    //   })
    // })

    const currentShopifyPlan = this.userService.shopifyPlanName
    this.stripePriceVisible = (!this.forbiddenShopifyPlans.includes(currentShopifyPlan) && this.userService.planIdentifier === 'budding') || this.forceStripePricing
  
    this.subscription.add(
      combineLatest([
        this.shopifyService.activeKnownCustomersCount$,
        this.paymentService.getPlansRequest(this.plansVersion),
        this.activeAppCampaignService.activeCampaignCount$.pipe(filter(next =>next !== null), take(1)),
        // this.cartApiService.getExpandedProductWithPrice(stripePriceId)
      ]).pipe(
        finalize(() => this.store.dispatch(new HideLoading('LoadingPlans')))
      ).subscribe(([akcs, plansData, activeCampaignCount]) => {
        this.currentPlanIdentifier = _.get(this.userInfo, 'subscription.plan.payment_gateway_plan_identifier', false)
        const visiblePlans = []
        // MARK: Stripe toggle
        if (!this.stripePriceVisible) {
          visiblePlans.push(PlansV3Identifier.Free)
          visiblePlans.push(PlansV3Identifier.Basic)
          visiblePlans.push(PlansV3Identifier.Custom)
        } else {
          if (akcs < 1000) {
            visiblePlans.push(PlansV3Identifier.Basic)
            if (this.forceStripePricing) {
              visiblePlans.push(PlansV3Identifier.NewCustomFree)
            }
          } else {
            visiblePlans.push(PlansV3Identifier.Basic)
            visiblePlans.push(PlansV3Identifier.NewCustomFree)
          }
        }
        this.store.dispatch(new HideLoading('LoadingPlans'))
        this.plans = plansData.plans
          .filter(plan => plan.payment_gateway_plan_identifier !== 'budding')
          .filter((plan) => (plan.payment_gateway_plan_identifier === this.currentPlanIdentifier) || visiblePlans.includes(plan.payment_gateway_plan_identifier as PlansV3Identifier))
          .sort((a, b) => +a.price?.fractional - +b.price?.fractional)

        this.activeKnownCustomers = akcs || 0
        this.activeCampaignCount = activeCampaignCount

        // this.stripePrice = stripePriceResponse

        // for new and legacy users that land on this page show 'popular plan'
        if (this.userType === 'new' || !this.userService.isNewPlanUser) {
          this.popularPlanId = PlansV3Identifier.Basic
        } else {
          let targetCount = activeCampaignCount
          const starterPlanCampaignCount = this.plans.find(p => p.payment_gateway_plan_identifier === PlansV3Identifier.Starter)?.max_active_campaigns || 2
          const basicPlanCampaignCount = this.plans.find(p => p.payment_gateway_plan_identifier === PlansV3Identifier.Basic)?.max_active_campaigns || 10
          if (this.route.snapshot?.queryParams?.hit_limit) {
            targetCount++
            this.router.navigate([], {
              queryParams: {
                'hit_limit': null,
              },
              queryParamsHandling: 'merge'
            })
          }
          if (targetCount > starterPlanCampaignCount) {
            this.recommendedPlanId = targetCount > basicPlanCampaignCount ? PlansV3Identifier.Pro : PlansV3Identifier.Basic
          } else {
            this.popularPlanId = PlansV3Identifier.Basic
          }
        }
        // If the plan is already selected, we need to create a static plan object
        // which will be used in card, so the plan usage selection will not affect the card
        if (!this.customPlanInvoice) {
          this.createCurrentPlanIfNeeded()
        }

        // Decide if visible, discount amount and code for floating tab
        // disable floating discount button for now
        // if (this.activeKnownCustomers >= 1501 && this.activeKnownCustomers <= 10000) {
        //   this.floatingTabDiscountValue = 25
        //   this.floatingTabDiscountCode ='oneapp'
        //   this.floatingTabDiscountHidden = false
        //   this.userPromo = {
        //     promo_code: '',
        //     promo_type: 'ShopifyCoupon',
        //     promo_referrer: 'onestore',
        //     promo_view: 'bfcm',
        //   }
        // } else if (this.activeKnownCustomers >= 10001 && this.activeKnownCustomers <= 150000) {
        //   this.floatingTabDiscountValue = 35
        //   this.floatingTabDiscountCode = 'onestore'
        //   this.floatingTabDiscountHidden = false
        //   this.userPromo = {
        //     promo_code: '',
        //     promo_type: 'ShopifyCoupon',
        //     promo_referrer: 'onestore',
        //     promo_view: 'bfcm',
        //   }
        // }

        if (this.plans.length) {
          this.updateVisiblePlans()

          this.totalsLoaded = true

          // Generate ranges based on what we have in plans response
          this.plans.forEach((plan) => {
            this.selectControls[plan.payment_gateway_plan_identifier] = new UntypedFormControl(null)
            if (plan.usage_plans) {

              plan.usage_plans = plan.usage_plans.filter(p => p.metric === this.usagePlanMetric && p.max <= this.maxShownUsage).sort((a, b) => +a.min - +b.min)
              if (plan.usage_plans?.[0]?.max) {
                this.lowestTierMax = plan.usage_plans?.[0]?.max
              }

              // generate dummy usage plans for custom price tier based on highest available usage plan
              const highestAvailableUsage = plan.usage_plans[plan.usage_plans.length - 1]
              const customPriceTier = {
                id: 'custom_price_tier',
                title: `${highestAvailableUsage?.max?.toLocaleString('en-US')}+`,
                customPriceTier: true,
                min: highestAvailableUsage?.max + 1,
                max: Number.MAX_SAFE_INTEGER,
                metric: this.usagePlanMetric,
              } as UsagePlan
              plan.usage_plans.push(customPriceTier)



              // check the store size after lower tier max is set
              this.storeSizeChecked(this.activeKnownCustomers)
              // preselected dropdown value should be either active known customers, or whatever usage plan the user has preselected
              let targetKnownCustomers = this.activeKnownCustomers
              if (this.userType === 'existing' && this.currentUsagePlan && this.currentUsagePlan?.min && this.currentUsagePlan?.min !== 0) {
                targetKnownCustomers = this.currentUsagePlan?.min
              }

              // loop through usage plans
              plan.usage_plans.forEach((option, index, arr) => {
                if (this.currentUsagePlan?.id === option?.id) {
                  option.selected = true
                }
                // if user was issued invoice disable all options except custom price tier
                if (this.userInfo?.custom_plan_invoice) {
                  option.disabled = !option?.customPriceTier
                } else if (targetKnownCustomers >= option.min && targetKnownCustomers <= option.max && this.activeKnownCustomers < highestAvailableUsage.max) {
                  this.selectControls[plan.payment_gateway_plan_identifier].patchValue(option)
                  this.changeUsagePlan(plan.payment_gateway_plan_identifier)
                  if (this.activeKnownCustomers > option.max) {
                    option.disabled = true
                  }
                  this.segmentAnalyticsService.track('Plans - Preselect Number of Profiles Field', {value: option.min})
                } else if (this.disablingOn && this.activeKnownCustomers > option.max) {
                  option.disabled = true
                  // disabled options if store size is above the max and has promo code applied
                  if (this.userPromo) {
                    option.disabled = true
                  }
                }
              })

              if (this.disablingOn && this.activeKnownCustomers >= this.lowestTierMax) {
                this.freePlanDisabled = !(this.userType === 'existing' && AllowedFreePlanUsers.includes(this.userInfo?.id))
              }
              // Toggle demo button visibility
              const allowedDemoShopifyPlans = ['staff', 'staff_business', 'plus_partner_sandbox', 'unlimited', 'shopify_plus']
              const currentShopifyPlan = _.get(this.userInfo, 'shop.profile.plan_name')
              this.paidUserPlan = allowedDemoShopifyPlans.includes(currentShopifyPlan) || this.activeKnownCustomers > this.lowestTierMax

              // If contactsTotalCount is out of bounds, select the last option
              const lastOption = plan.usage_plans[plan.usage_plans.length - 1]
              if (lastOption && this.activeKnownCustomers > lastOption?.max) {
                this.selectControls[plan.payment_gateway_plan_identifier].patchValue(lastOption.id)
                this.changeUsagePlan(plan.payment_gateway_plan_identifier)
                this.segmentAnalyticsService.track('Plans - Preselect Number of Profiles Field', {value: lastOption.min})
              }

              // preselect custom price tier if store has more akcs than the highest available usage plan or if user has custom plan invoice
              if (this.activeKnownCustomers > highestAvailableUsage?.max || this.userInfo?.custom_plan_invoice) {
                this.selectControls[plan.payment_gateway_plan_identifier].patchValue(customPriceTier)
              }

              this.updateVisiblePlans()
            }
          })
        }




        if (this.lowestTierMax) {
          this.subscription.add(this.gettingStartedService.updateKeyValuePair(keyValuePairKey.FreePlanMaxActiveKnownCustomers, this.lowestTierMax).subscribe())
        }

        // disable static promo
        // this.applyStaticPromoCodeIfNeeded()
        // this.store.dispatch(new HideLoading('LoadingPlans'))

        if (_.has(this.userInfo, 'sms_marketing_account.cost_per_sms_credit')) {
          const smsCostFeature = this.includedFeatures.find(f => f.id === 'sms-cost')
          if (smsCostFeature?.price) {
            smsCostFeature.price.new = this.userInfo.sms_marketing_account.cost_per_sms_credit
          }
        }
      })
    )

    // split contacts_statistic into another request
    this.subscription.add(
      this.shopifyService.contactsCount$.subscribe((total: number) => {
        this.knownCustomers = total
      })
    )

    this.subscription.add(this.store.select(getUserPromo).subscribe((promo: Promo) => {
      this.userPromo = promo
    }))
  }

  applyStaticPromoCodeIfNeeded() {
    // experiment - might need to be removed in future
    if (this.userPromo || this.userType === 'existing') {
      return
    }
    const contacts = this.activeKnownCustomers

    if (contacts >= 1501 && contacts <= 10000) {
      this.userPromo = {
        promo_code: 'ONE APP BFCM22',
        promo_type: 'ShopifyCoupon',
        promo_referrer: 'onestore',
        promo_view: 'bfcm',
      }
    }

    if (contacts >= 10001 && contacts <= 150000) {
      this.userPromo = {
        promo_code: 'ONE SHOPIFY APP BFCM22',
        promo_type: 'ShopifyCoupon',
        promo_referrer: 'onestore',
        promo_view: 'bfcm',
      }
    }
  }

  createCurrentPlanIfNeeded() {
    let currentPlan = _.get(this.userInfo, 'subscription.plan')
    let currentUsagePlan = _.get(this.userInfo, 'subscription.usage_plan')

    if (currentPlan && currentUsagePlan) {
      currentPlan = {
        ...currentPlan,
        price: {...currentUsagePlan.price as any},
      }
      const currentlyAppliedCoupon = _.get(this.userInfo, 'subscription.coupon')
      if (currentlyAppliedCoupon) {
        this.modifyPlan(currentPlan as any, currentlyAppliedCoupon)
      }
      const calculatedPrice = +currentPlan.price.fractional / currentPlan.price.currency.subunit_to_unit
      const displayPrice = {
        amount: `${calculatedPrice}`,
        cents: null,
        class: ''
      }
      if (calculatedPrice > 100) {
        if (`${calculatedPrice}`.includes('.')) {
          // if price contains decimal point round up
          displayPrice.amount = `${Math.ceil(calculatedPrice)}`
        }
        displayPrice.class = calculatedPrice > 1000 ? '_four-digits' : '_three-digits'
      } else if (calculatedPrice > 10 && `${calculatedPrice}`.includes('.')) {
        displayPrice.amount = `${calculatedPrice}`.split('.')[0]
        displayPrice.cents = `${calculatedPrice}`.split('.')[1]
      }
      currentPlan = {
        ...currentPlan,
        price: {
          ...currentPlan.price,
          ...displayPrice,
        }
      }
      this.currentPlan = currentPlan as any
      this.currentUsagePlan = currentUsagePlan
      this.checkIfPlanChanged()
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
    this.renderer.removeClass(document.body, '_white-bg')
    this.renderer.removeClass(document.body, '_no-content-top-padding')
    this.store.dispatch(new ResetLoading())
  }

  contactsOpen() {
    this.segmentAnalyticsService.track('Plans - Click Number of Profiles Field')
  }

  contactsOptionChanged(planId) {
    Object.keys(this.selectControls).forEach(key => {
      const shouldUpdate = true
      if (shouldUpdate) {
        const plan = this.plans.find(p => p.payment_gateway_plan_identifier === key)
        const usagePlan = plan.usage_plans?.find(up => up.min === this.selectControls[planId].value?.min)
        if (usagePlan) {
          this.selectControls[key].patchValue(usagePlan)
        }
        this.changeUsagePlan(key)
      }
    })
    this.updateVisiblePlans()
  }

  changeUsagePlan(planId) {
    const plan = this.plans.find(p => p.payment_gateway_plan_identifier === planId)
    plan.misconfigured = false
    if (plan.usage_plans) {
      const usagePlan = plan.usage_plans.find(option => option.id === this.selectControls[plan.payment_gateway_plan_identifier]?.value?.id)
      if (usagePlan) {
        delete plan.price
        plan.price = {
          ...usagePlan.price,
        }
        // Need to reset initial price to be able to calculate coupon properly
        if (plan?.initial_price) {
          delete plan.initial_price
        }
        plan.usage_plan = usagePlan
        this.segmentAnalyticsService.track('Plans - Select Number of Profiles Field', {min: usagePlan.min})
      } else {
        // If we don't have a usage plan for this plan, alert the user
        // const allowedNoUsagePlans = [PlansV3Identifier.Free, PlansV3Identifier.Enterprise, PlansV3Identifier.Custom]
        // if (!allowedNoUsagePlans.includes(plan.payment_gateway_plan_identifier as PlansV3Identifier)) {
        //   plan.misconfigured = true
        // }
      }
    }
    if (this.coupon) {
      this.applyPlansDiscount(this.coupon)
    }
  }

  storeSizeChecked(active_customers: number) {
    this.largeSizeStore = false
    this.midSizeStore = false
    this.smallSizeStore = false
    if(active_customers >= this.highestTierMin) {
      this.largeSizeStore = true
      this.pageTitle = 'All-in-One. Easy to Use. Customizable.'
    } else if(active_customers > this.lowestTierMax && active_customers < this.highestTierMin) {
      this.midSizeStore = true
      this.pageTitle = 'All-in-One. Flexible. Powerful.'
    } else if(active_customers <= this.lowestTierMax) {
      this.smallSizeStore = true
      this.pageTitle = 'All-in-One. Easy to Use. Flexible.'
    }
  }

  updateVisiblePlans() {
    /* Make sure plans are set */
    if (!this.plans) {
      return
    }

    this.visiblePlans = this.plans.map(plan => this.generateVisiblePlan(plan))
    this.visiblePlans = this.visiblePlans.sort((a, b) => a.order - b.order)
  }

  generateVisiblePlan(plan: SubscriptionPlan) {
    const planId = plan.payment_gateway_plan_identifier
    const isFreePlan = PlansV3Identifier.Free === planId
    const isCustomPlan = [PlansV3Identifier.Enterprise, PlansV3Identifier.Custom].includes(planId as PlansV3Identifier)
    let isCurrentPlan = this.currentPlanIdentifier === planId && !(isFreePlan && this.freePlanDisabled)
    if (this.customPlanInvoice) {
      const tier = this.userInfo?.custom_plan_invoice_tier
      isCurrentPlan = tier && planId.includes(tier)
    } else {
      isCurrentPlan = this.currentPlanIdentifier === planId && !(isFreePlan && this.freePlanDisabled)
    }
    let lowerPlanSelected = false
    let higherPlanSelected = false
    let customPriceTier = this.selectControls[planId]?.value?.customPriceTier
    let showCustomPrice = customPriceTier
    let isUmbrellaOrg = false
    const isAboveHighestAvailableTier = this.maxShownUsage < this.activeKnownCustomers && !isFreePlan
    if (this.currentPlanIdentifier && this.plansOrder.includes(this.currentPlanIdentifier)) {
      lowerPlanSelected = this.plansOrder.indexOf(this.currentPlanIdentifier) < this.plansOrder.indexOf(planId as PlansV3Identifier)
      higherPlanSelected = this.plansOrder.indexOf(this.currentPlanIdentifier) > this.plansOrder.indexOf(planId as PlansV3Identifier)
    }
    // don't show recommended & popular plans if user is on higher plan
    const isPopularPlan = this.popularPlanId === planId && !higherPlanSelected
    const isRecommendedPlan = this.recommendedPlanId === planId && !higherPlanSelected
    const calculatedPrice = +plan?.price?.fractional / plan?.price?.currency?.subunit_to_unit
    const displayPrice = {
      amount: `${calculatedPrice}`,
      cents: null,
      class: ''
    }
    if (calculatedPrice > 100) {
      if (`${calculatedPrice}`.includes('.')) {
        // if price contains decimal point round up
        displayPrice.amount = `${Math.ceil(calculatedPrice)}`
      }
      displayPrice.class = calculatedPrice > 1000 ? '_four-digits' : '_three-digits'
    } else if (calculatedPrice > 10 && `${calculatedPrice}`.includes('.')) {
      displayPrice.amount = `${calculatedPrice}`.split('.')[0]
      displayPrice.cents = `${calculatedPrice}`.split('.')[1]
    }

    // show their current plan price for edge case when existing user has more akcs then our highest usage plan
    // last usage plan is always a custom price tier, so here we need to check the second to last usage plan
    if (isAboveHighestAvailableTier && isCurrentPlan && this.userInfo?.subscription?.price?.fractional) {
      displayPrice.amount = `${this.userInfo?.subscription?.price.fractional / this.userInfo?.subscription?.price.currency.subunit_to_unit}`
      showCustomPrice = false
    }

    // check if umbrella org to show pick plan later
    const shops = this.shopSwitchService.userShops$.getValue()
    const currentShop = shops?.flatMap(el => el.shops)?.find(s => s?.shopify_store_subdomain === this.userInfo.shop?.profile?.myshopify_domain)
    if (currentShop?.one_organization_umbrella_name) {
      isUmbrellaOrg = true
    }

    const isAdminAsUser = this.adminUserTokenService.getValue()

    const populatedPlan: SubscriptionPlanData = {
      ...plan,
      title: plan.name,
      subtitle: '',
      price: {
        ...plan.price,
        period: plan.interval,
        ...displayPrice
      },
      plan_cta_action: 'choose_plan',
      plan_cta_label: 'Choose Plan',
      customSuperScript: `Limited to stores with 0-${this.lowestTierMax} customers`,
      isFree: isFreePlan,
      isCustom: isCustomPlan,
      isCustomPriceTier: customPriceTier ,
      isPopular: isPopularPlan,
      isRecommended: isRecommendedPlan,
      limits: [],
      features: [],
      order: this.plansOrder.indexOf(planId as PlansV3Identifier),
      current: isCurrentPlan,
      akcAbove5k: this.activeKnownCustomers > 5000,
      pickPlanLaterEligible: (isUmbrellaOrg && ['new', 'returning'].includes(this.userType)) || isAdminAsUser,
      // selected: this.currentPlanIdentifier === planId
      //           && (plan.usage_plan?.id === this.currentUsagePlan?.id || planId === PlansV3Identifier.Free),
      selected: this.currentPlanIdentifier === planId
        && (!!this.currentUsagePlan?.id || planId === PlansV3Identifier.Free),
      disabled: false,
      content: [
        {
          id: 'apps',
          title: 'AI-Powered Customer Platform',
          order: 1,
          points: [
            {
              title: 'Access to All Apps',
              withTooltip: true,
              tooltipText: `<div class="maxw-400">
                AI, Email, SMS, Popups, Upsell, Rewards, Cart Recovery and more
              </div>`,
              active: true,
            },
            {
              title: `${isCustomPlan ? 'Custom' : plan.max_active_campaigns} Active Campaigns`,
              class: isRecommendedPlan && !isCurrentPlan ? 'text-primary' : '',
              active: true,
              activeCampaignsDropdown: true,
              // withTooltip: this.userType !== 'existing',
              // tooltipText: `<div class="maxw-400"">
              //     Active Campaign is anything that can be published on the ONE app such as pop up campaigns,
              //     email/sms automations, or one-click upsell campaigns.
              //     For ex: 1 pop up + 2 email automations + 1 sms automations + 1 one-click upsell = 5 active campaigns.
              //     Drafts, Email/SMS Broadcasts, and Pop Up Autoresponders do NOT count towards your active campaign limit.
              //   </div>`
            },
            {
              title: `${isCustomPlan ? 'Custom' : 'Remove'} Branding`,
              active: plan.remove_branding,
            },
            {
              title: 'A/B Testing',
              active: false,
            },
            // {
            //   title: 'Custom Campaigns',
            //   active: false,
            // },
            // {
            //   title: 'Custom App Access',
            //   active: false,
            // }
          ]
        },
        // {
        //   id: 'cdp',
        //   title: 'Customer Data Platform (CDP)',
        //   order: 2,
        //   points: [
        //     {
        //       title: 'Customer Profile Sync',
        //       active: true,
        //     },
        //     // {
        //     //   title: 'Marketing Consent Management',
        //     //   active: false,
        //     // },
        //     {
        //       title: 'Cart / Checkout / Events',
        //       active: false,
        //     },
        //   ]
        // },
        {
          id: 'support',
          title: 'Support',
          order: 3,
          points: [
            {
              title: 'Live Chat',
              active: true,
            },
            {
              title: 'Email',
              active: true,
            },
            {
              title: 'Phone',
              active: false,
            },
            // {
            //   title: 'Dedicated Campaign Manager',
            //   active: false,
            // },
            // {
            //   title: '99.99% Guaranteed Uptime',
            //   active: false,
            // },
          ]
        },

        // {
          // id: 'pdp',
          // title: 'Product Data Platform (PDP)',
          // order: 3,
          // points: [
          //   {
          //     title: 'Product Catalog Sync',
          //     active: true,
          //   },
          //   {
          //     title: 'Product Editor',
          //     active: true,
          //   },
          // ]
        // },
      ]
    }

    const cdp = populatedPlan.content.find(c => c.id === 'cdp')
    const apps = populatedPlan.content.find(c => c.id === 'apps')
    const support = populatedPlan.content.find(c => c.id === 'support')

    let trialAvailable = 0
    if (this.userType === 'new' || this.userType === 'returning') {
      trialAvailable = plan.trial_period
      // if user was on trial only allow them trial days remaining
      if (this.userInfo?.subscription?.is_trial !== null) {
        trialAvailable = this.userInfo?.subscription?.trial_days_remaining || 0
      }
    } else {
      // if this an existing customer only show remaining trial days (if they are on trial) or 0
      trialAvailable = this.userInfo?.subscription?.trial_days_remaining || 0
    }
    // if trial is added through coupon add it here
    if (plan.trial_period_override) {
      trialAvailable = plan.trial_period_override
    }

    // NOTE: hide money back guarantee logic for now
    // const creationDate = moment(this.userInfo?.created_at)
    // if this is on plans page or on billing page & user doesn't have trial & is within 30 days from install show money back text
    // if  (trialAvailable === 0 && (this.userType === 'new' || (this.userInfo.subscription?.is_trial === null && moment().isBefore(creationDate.add(30, 'day'))))) {
    //   populatedPlan.subtext = '30-Day Money Back Guarantee'
    // }

    // if this is a budding user and they got promo from pricing modal show money back guarantee
    const eligibleForMoneyBack = this.currentPlanIdentifier === 'budding' && this.pricingModalPromos.includes(this.userPromo?.promo_code)
    // set user custom plan invoice tier to show with monthly price
    const setCustomPlanInvoice = this.userInfo?.custom_plan_invoice && planId.includes(this.userInfo?.custom_plan_invoice_tier) && this.userInfo?.custom_plan_invoice_price
    const hasPaidCustomPlanInvoice = !!this.userInfo?.custom_plan_invoice_due_date && !this.userInfo?.custom_plan_invoice_paid
    switch (planId) {
      // MARK: Free Plan
      case PlansV3Identifier.Free:
        populatedPlan.title = 'FREE (All-in-One)'
        populatedPlan.subtitle = 'Set Your Pace, Start Free'
        populatedPlan.price.custom = 'Free'
        populatedPlan.disabled = this.freePlanDisabled
        populatedPlan.plan_cta_label_override = 'Choose Plan'
        populatedPlan.subtext = ''
        populatedPlan.isFree = true
        if (this.freePlanDisabled) {
          populatedPlan.price.custom = 'Unqualified'
          populatedPlan.price.size = 40
          populatedPlan.disabled = true
          // populatedPlan.subtext = `Limited to stores with 0-${this.lowestTierMax} active customers`
          populatedPlan.subtext = 'Not what you’re looking for? Let’s chat!'
        }
        if (higherPlanSelected) {
          populatedPlan.plan_cta_label_override = 'Downgrade Plan'
        }
        if (support?.points?.[0]) {
          support.points[0].title = 'Live Chat'
        }
        if (customPriceTier) {
          populatedPlan.plan_cta_label_override = 'Schedule Pricing Call'
          populatedPlan.plan_cta_action_override = 'book_demo'
          populatedPlan.plan_cta_color= 'purple'
        }
        break
      // MARK: Starter Plan
      case PlansV3Identifier.Starter:
        if (lowerPlanSelected) {
          populatedPlan.plan_cta_label = 'Upgrade Plan'
          if (trialAvailable) {
            populatedPlan.subtext = `${trialAvailable}-Day Free Trial`
          }
        } else if (higherPlanSelected) {
          populatedPlan.plan_cta_label = 'Downgrade Plan'
          if (trialAvailable) {
            populatedPlan.subtext = `${trialAvailable}-Day Free Trial`
          }
        } else if (trialAvailable) {
          populatedPlan.plan_cta_label = `Start ${trialAvailable}-Day Free Trial`
          // populatedPlan.subtext = !customPriceTier ? `You won't be charged until the next billing cycle.` : ''
        }

        if (eligibleForMoneyBack) {
          populatedPlan.subtext = '30-Day Money Back Guarantee'
        }
        populatedPlan.subtitle = 'Save time & money with ONE App'
        if (customPriceTier) {
          if (showCustomPrice) {
            populatedPlan.price.custom = 'Custom Price'
            populatedPlan.price.class = 'font-42'
          }
          populatedPlan.plan_cta_label_override = 'Request Custom Price'
          populatedPlan.plan_cta_action_override = 'send_crisp_message'
          populatedPlan.selected = false
        }
        if (setCustomPlanInvoice) {
          populatedPlan.price.custom_with_price_and_period = true
          populatedPlan.price.custom = `${this.userInfo?.custom_plan_invoice_price}`
          if (hasPaidCustomPlanInvoice) {
            populatedPlan.disabled = false
            populatedPlan.plan_cta_color = 'black'
            populatedPlan.plan_cta_label_override = 'Pay Invoice'
            populatedPlan.plan_cta_label_due_date = moment(this.userInfo.custom_plan_invoice_due_date).format('MMM Do')
            populatedPlan.plan_cta_action_override = 'pay_custom_plan_invoice'
          }
        }
        if (cdp) {
          cdp.points = cdp.points.map((point) => ({
            ...point,
            active: point.active
          }))
        }
        if (apps) {
          apps.points = apps.points.map((point) => ({
            ...point,
            active: point.active
          }))
        }
        if (support) {
          support.points = support.points.map((point) => ({
            ...point,
            active: point.active
          }))
          if (support.points?.[0]) {
            support.points[0].title = 'Live Chat'
          }
        }
        break
      // MARK: Basic Plan
      case PlansV3Identifier.Basic:
        populatedPlan.title = 'Basic (All-in-One)'
        if (lowerPlanSelected) {
          populatedPlan.plan_cta_label = 'Upgrade Plan'
          if (trialAvailable) {
            populatedPlan.subtext = `${trialAvailable}-Day Free Trial`
          }
        } else if (higherPlanSelected) {
          populatedPlan.plan_cta_label = 'Downgrade Plan'
          if (trialAvailable) {
            populatedPlan.subtext = `${trialAvailable}-Day Free Trial`
          }
        } else if (trialAvailable) {
          populatedPlan.plan_cta_label = `Start ${trialAvailable}-Day Free Trial`
          // populatedPlan.subtext = !customPriceTier ? `You won't be charged until the next billing cycle.` : ''
        }

        if (eligibleForMoneyBack) {
          populatedPlan.subtext = '30-Day Money Back Guarantee'
        }
        populatedPlan.subtitle = 'Scale Your Store, Simplify Pricing'
        if (customPriceTier) {
          if (showCustomPrice) {
            populatedPlan.price.custom = 'Custom Price'
            populatedPlan.price.class = 'font-42'
          }
          populatedPlan.plan_cta_label_override = 'Request Custom Price'
          populatedPlan.plan_cta_action_override = 'send_crisp_message'
          populatedPlan.selected = false
        }
        if (setCustomPlanInvoice) {
          populatedPlan.price.custom_with_price_and_period = true
          populatedPlan.price.custom = `${this.userInfo?.custom_plan_invoice_price}`
          if (hasPaidCustomPlanInvoice) {
            populatedPlan.disabled = false
            populatedPlan.plan_cta_color = 'black'
            populatedPlan.plan_cta_label_override = 'Pay Invoice'
            populatedPlan.plan_cta_label_due_date = moment(this.userInfo.custom_plan_invoice_due_date).format('MMM Do')
            populatedPlan.plan_cta_action_override = 'pay_custom_plan_invoice'
          }
        }
        if (cdp) {
          cdp.points = cdp.points.map((point) => ({
            ...point,
            active: point.active
          }))
        }
        if (apps) {
          apps.points = apps.points.map((point) => ({
            ...point,
            active: point.active
          }))
        }

        if (support) {
          support.points = support.points.map((point) => ({
            ...point,
            active: point.active || [
              'Email',
              'Phone',
            ].includes(point.title)
          }))
          if (support.points?.[0]) {
            support.points[0].title = 'Live Chat'
          }
        }
        break
      // MARK: Pro Plan
      case PlansV3Identifier.Pro:
        if (lowerPlanSelected) {
          populatedPlan.plan_cta_label = 'Upgrade Plan'
          if (trialAvailable) {
            populatedPlan.subtext = `${trialAvailable}-Day Free Trial`
          }
        } else if (higherPlanSelected) {
          populatedPlan.plan_cta_label = 'Downgrade Plan'
          if (trialAvailable) {
            populatedPlan.subtext = `${trialAvailable}-Day Free Trial`
          }
        } else if (trialAvailable) {
          populatedPlan.plan_cta_label = `Start ${trialAvailable}-Day Free Trial`
          // populatedPlan.subtext = !customPriceTier ? `You won't be charged until the next billing cycle.` : ''
        }
        if (eligibleForMoneyBack) {
          populatedPlan.subtext = '30-Day Money Back Guarantee'
        }
        populatedPlan.subtitle = 'Scale your customer experience'
        if (customPriceTier) {
          if (showCustomPrice) {
            populatedPlan.price.custom = 'Custom Price'
            populatedPlan.price.class = 'font-42'
          }
          populatedPlan.plan_cta_label_override = 'Request Custom Price'
          populatedPlan.plan_cta_action_override = 'send_crisp_message'
          populatedPlan.selected = false
        }
        if (setCustomPlanInvoice) {
          populatedPlan.price.custom_with_price_and_period = true
          populatedPlan.price.custom = `${this.userInfo?.custom_plan_invoice_price}`
          if (hasPaidCustomPlanInvoice) {
            populatedPlan.disabled = false
            populatedPlan.plan_cta_color = 'black'
            populatedPlan.plan_cta_label_override = 'Pay Invoice'
            populatedPlan.plan_cta_label_due_date = moment(this.userInfo.custom_plan_invoice_due_date).format('MMM Do')
            populatedPlan.plan_cta_action_override = 'pay_custom_plan_invoice'
          }
        }
        if (cdp) {
          cdp.points = cdp.points.map((point) => ({
            ...point,
            active: point.active || [
              'Cart / Checkout / Events',
            ].includes(point.title)
          }))
        }
        if (apps) {
          apps.points = apps.points.map((point) => ({
            ...point,
            active: point.active || [
              'A/B Testing',
            ].includes(point.title)
          }))
        }
        if (support) {
          support.points = support.points.map((point) => ({
            ...point,
            active: point.active || [
              'Email',
              'Dedicated Campaign Manager',
            ].includes(point.title)
          }))
        }
        break
      // MARK: Enterprise Plan
      case PlansV3Identifier.Enterprise:
      // MARK: Custom Plan
      case PlansV3Identifier.Custom:
        populatedPlan.title = 'Custom'
        populatedPlan.subtitle = 'Request pricing that lets us grow together!'
        populatedPlan.price.custom = 'Custom Price'
        populatedPlan.price.class = 'font-42'
        populatedPlan.plan_cta_label_override = 'Request Custom Price'
        // if (this.paidUserPlan) {
        //   populatedPlan.price.custom = 'Contact Us!'
        // }
        // if (this.largeSizeStore) {
        //   populatedPlan.plan_cta_color = 'black'
        //   populatedPlan.subtext = `Tell us what pricing you’re looking for.`
        // }
        // if (this.midSizeStore) {
        //   populatedPlan.plan_cta_color = 'black'
        //   populatedPlan.plan_cta_label_override = 'Let\'s Chat'
        //   populatedPlan.subtext = `Tell us what pricing you’re looking for.`
        // }
        populatedPlan.plan_cta_action_override = 'send_crisp_message'
        populatedPlan.plan_cta_payload_override = `Hi ONE team, we currently have\n\n Active Customers: ${this.activeKnownCustomers} \nKnown Customers: ${this.knownCustomers}\n\n and we\'d like to request a custom price.`
        if (setCustomPlanInvoice) {
          populatedPlan.price.custom_with_price_and_period = true
          populatedPlan.price.custom = `${this.userInfo?.custom_plan_invoice_price}`
          if (hasPaidCustomPlanInvoice) {
            populatedPlan.disabled = false
            populatedPlan.plan_cta_color = 'black'
            populatedPlan.plan_cta_label_override = 'Pay Invoice'
            populatedPlan.plan_cta_label_due_date = moment(this.userInfo.custom_plan_invoice_due_date).format('MMM Do')
            populatedPlan.plan_cta_action_override = 'pay_custom_plan_invoice'
          }
        }
        if (cdp) {
          cdp.points = cdp.points.map((point) => ({
            ...point,
            active: point.active || [
              'Cart / Checkout / Events',
              'ONE App Events',
              'Data Syncs Every 500 ms',
            ].includes(point.title)
          }))
        }
        if (apps) {
          apps.points = apps.points.map((point) => ({
            ...point,
            active: point.active || [
              'A/B Testing',
              'Custom Reports',
            ].includes(point.title)
          }))
        }
        if (support) {
          support.points = support.points.map((point) => ({
            ...point,
            active: point.active || [
              'Email',
              'Dedicated Campaign Manager',
              '99.99% Guaranteed Uptime',
            ].includes(point.title)
          }))
        }
        this.segmentAnalyticsService.track('Subscription List | Custom (Contact Sales) | Viewed')
        break
      // MARK: Stripe plan
      case PlansV3Identifier.NewCustomFree:
        populatedPlan.title = 'Custom (Build One)'
        populatedPlan.subtitle = 'Segment Your Plan, Control Costs'
        populatedPlan.price.custom = 'Custom Price'
        populatedPlan.price.class = 'font-42'
        populatedPlan.price.custom_with_price_and_period = true
        // populatedPlan.price.custom = `${+this.stripePrice.tiers[0].unit_amount_decimal / 100}`
        populatedPlan.price.custom = '0'
        populatedPlan.plan_cta_label_override = 'Customize Plan'
        populatedPlan.plan_cta_action_override = 'stripe_checkout'
        populatedPlan.customSuperScript = 'Starting at'
        populatedPlan.isStripePlan = true
        populatedPlan.isCustom = true

        if (apps) {
          apps.points = apps.points.map((point) => {
            if (point.title === 'Access to All Apps') {
              point.title = 'Custom App Access'
            }
            if (point.title === '2 Active Campaigns') {
              point.title = 'Custom Campaigns'
            }
            if (point.title === 'Remove Branding') {
              point.title = 'Custom Branding'
            }
            return point
          })
        }
        if (support) {
          support.points = support.points.map((point) => ({
            ...point,
            active: point.active || [
              'Email',
              'Phone',
            ].includes(point.title)
          }))
        }
        this.segmentAnalyticsService.track('Subscription List | Custom (Self-Serve) | Viewed')
        break
    }
    if (populatedPlan.selected && !populatedPlan.isCustomPriceTier) {
      populatedPlan.plan_cta_label_override = 'Current Plan'
      if (this.userInfo?.subscription?.is_trial === true && this.userInfo?.subscription?.trial_days_remaining && planId !== PlansV3Identifier.Free) {
        populatedPlan.subtext = `${this.userInfo?.subscription?.trial_days_remaining} Days Remaining`
        populatedPlan.chip = 'Free Trial'
      }
    }
    if (populatedPlan.current && !populatedPlan.isCustomPriceTier && !isFreePlan) {
      if (plan.usage_plan?.min < this.currentUsagePlan?.min) {
        populatedPlan.plan_cta_label_override = 'Downgrade Plan'
        populatedPlan.selected = false
      } else if (plan.usage_plan?.min > this.currentUsagePlan?.min) {
        populatedPlan.plan_cta_label_override = 'Upgrade Plan'
        populatedPlan.selected = false

      }
    }

    if (isFreePlan && plan?.sms_credits) {
      const smsFeature = this.includedFeatures.find(f => f.id === 'sms')
      if (smsFeature) {
        smsFeature.title = `FREE - ${plan.sms_credits} SMS Credits`
      }
    }
    this.afterPlanPopulated()
    return populatedPlan;
  }

  onSelectPlanClick(plan: SubscriptionPlan) {
    if (plan.misconfigured) {
      this.sendMisconfiguredPlanCrispMessage(plan)
      return
    }
    const action = plan.plan_cta_action_override || plan.plan_cta_action
    switch (action) {
      case 'book_demo':
        this.openCalendlyWidget(plan.payment_gateway_plan_identifier)
        return
      case 'send_crisp_message':
        this.sendCrispMessage(plan)
        return
      case 'pay_custom_plan_invoice':
        this.payCustomPlanInvoice()
        return
      case 'redirect_page':
        this.subscription.add(
          this.gettingStartedService.updateScholarshipStatus({ plan_id: plan.payment_gateway_plan_identifier, plan_name: plan.name }).subscribe()
        )
        let url = plan.plan_cta_payload_override || plan.plan_cta_payload
        url += `?selectedPlan=${plan.name}`
        url += `&onePlanid=${plan.payment_gateway_plan_identifier}`
        this.userInfo = this.userService.userInfo
        if (_.get(this.userInfo, 'shop.store_url')) {
          url += `&shopifyUrl=${_.get(this.userInfo, 'shop.store_url')}`
          url += `&oneShopurl=${_.get(this.userInfo, 'shop.store_url')}`
        }
        if (_.get(this.userInfo, 'shop.profile.name')) {
          url += `&shopName=${_.get(this.userInfo, 'shop.profile.name')}`
          url += `&oneShopname=${_.get(this.userInfo, 'shop.profile.name')}`
        }
        if (_.get(this.userInfo, 'shop.profile.plan_name')) {
          url += `&oneShopifyplan=${_.get(this.userInfo, 'shop.profile.plan_name')}`
        }
        if (_.get(this.userInfo, 'id')) {
          url += `&oneUserid=${_.get(this.userInfo, 'id')}`
        }
        if (_.get(this.userInfo, 'address.country.code')) {
          url += `&oneCountry=${_.get(this.userInfo, 'address.country.code')}`
        }
        if (this.userPromo) {
          if (_.get(this.userPromo, 'promo_code')) {
            url += `&onePromocode=${_.get(this.userPromo, 'promo_code')}`
          }
          if (_.get(this.userPromo, 'promo_type')) {
            url += `&onePromotype=${_.get(this.userPromo, 'promo_type')}`
          }
          if (_.get(this.userPromo, 'promo_referrer')) {
            url += `&onePromoreferrer=${_.get(this.userPromo, 'promo_referrer')}`
          }
          if (_.get(this.userPromo, 'promo_view')) {
            url += `&onePromoview=${_.get(this.userPromo, 'promo_view')}`
          }
        }
        if (window && window.location) {
          url += `&referrer=${window.location.origin + window.location.pathname}`
        }
        if (_.get(navigator, 'language')) {
          url += `&language=${_.get(navigator, 'language')}`
        }
        if (this.isValidHttpUrl(url)) {
          window.open(url, '_blank')
        } else {
          // if plan cta payload is not a valid url open default crisp message
          this.sendCrispMessage({...plan, plan_cta_payload: ''})
        }
        break
      case 'open_popup':
        const dialogRef = this.dialog.open(ConfirmModalComponent, {
          width: '560px',
          data: {
            title: plan.name,
            html: plan.plan_cta_payload_override || plan.plan_cta_payload,
            footerClass: 'justify-content-end mb-0',
            cancelButton: {
              text: 'Cancel',
              classes: 'pf-button outline gray'
            },
            acceptButton: {
              text: 'Continue',
              classes: 'pf-button filled blue'
            }
          } as ConfirmModalConfig,
        })
        this.subscription.add(dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.choosePlan(plan)
          }
        }))
        break
      case 'stripe_checkout':
        if (this.shopifyService.isEmbedded) {
          window.open(`${window.location.href}/custom?redirectForStandalone` , '_blank')
        } else {
          this.router.navigate([RouteHeaderUrl.custom], {relativeTo: this.route})
        }
        return
      case 'choose_plan':
      default:
        this.choosePlan(plan)
    }
  }

  payCustomPlanInvoice() {
    const url = this.userInfo?.custom_plan_invoice_link
    if (url) {
      window.open(url, '_blank')
    }
  }

  openCalendlyWidget(plan_id: string) {
    if (window && window['Calendly'] && window['Calendly'].initPopupWidget) {
      const name = this.userInfo?.shop_user?.shopify_shop_user?.first_name ? `${this.userInfo?.shop_user?.shopify_shop_user?.first_name} ${this.userInfo?.shop_user?.shopify_shop_user?.last_name || ''}` : this.userInfo?.profile?.first_name ? `${this.userInfo?.profile?.first_name} ${this.userInfo?.profile?.last_name || ''}` : ''
      const email = this.userInfo?.shop_user?.shopify_shop_user?.email || this.userInfo?.email || ''
      const phone = this.userInfo?.shop_user?.shopify_shop_user?.phone || ''
      const storeUrl = this.userInfo?.shop?.store_url  || ''
      window['Calendly'].initPopupWidget({
        url: 'https://calendly.com/one-csm',
        utm: {
          utmCampaign: plan_id === PlansV3Identifier.Free ? 'demo_free_plan' : `demo_${plan_id}_plan`
        },
        prefill: {
          name: name,
          email: email,
          customAnswers: {
            a1: phone,
            a2: storeUrl,
          }
        },
      })
      if (plan_id === PlansV3Identifier.Enterprise) {
        this.segmentAnalyticsService.track('Start Plan - Enterprise Book Demo')
      }
      if (plan_id = PlansV3Identifier.Free) {
        this.segmentAnalyticsService.track('Book Demo - Free Plan')
      }
      this.calendlySchedulingForPlan = plan_id
      window.addEventListener('message', this.receiveIframeMessage)
    }
  }

  private receiveIframeMessage = (message: any) => {
    if (this.isCalendlyEvent(message)) {
      if (message.data.event.indexOf('event_scheduled') !== -1) {
        let eventName = `Scheduled Event (${this.calendlySchedulingForPlan})`
        if (this.calendlySchedulingForPlan === PlansV3Identifier.Enterprise) {
          eventName = 'Scheduled Event (Enterprise Plan)'
        } else if (this.calendlySchedulingForPlan === PlansV3Identifier.Free) {
          eventName = 'Scheduled Event (Free Plan)'
        }
        this.segmentAnalyticsService.track(eventName)
      }
    }
  }

  isCalendlyEvent(e) {
    return e.origin === "https://calendly.com" && e.data.event && e.data.event.indexOf('calendly.') === 0
  }

  isValidHttpUrl(string) {
    let url

    try {
      url = new URL(string)
    } catch (_) {
      return false
    }

    return url.protocol === 'http:' || url.protocol === 'https:'
  }

  choosePlan(plan) {
    const usagePlanId = this.selectControls[plan.payment_gateway_plan_identifier]?.value?.id
    const planWithoutUsagePlans = [PlansV3Identifier.Free, PlansV3Identifier.Enterprise].includes(plan?.payment_gateway_plan_identifier)
    if (!usagePlanId && !planWithoutUsagePlans) {
      console.error('Usage plan is undefined')
      return
    }
    const planName = plan?.title?.replace(/\(.*?\)/g, '').trim()
    // Checkout starts on the Subscriptions Page
    // Global Event
    this.segmentAnalyticsService.track('Platform | Subscriptions | Checkout | Started')
    // Funnel Event: Onboarding Flow
    this.segmentAnalyticsService.track('Onboarding | Platform | Subscriptions | Checkout | Started')
    // Funnel Event: Shopify Checkout & All-in-One Plan Flow
    this.segmentAnalyticsService.track(`Subscription ${planName} | Checkout | Started`)
    // Global Event: To Be Deprecated
    this.segmentAnalyticsService.track('Choose Plan', {
      state: {
        onboarding: true,
        step: 'click_plan',
        group: 'choose_plan',
      },
      metadata: { plan },
    })
    this.updateJotformIfNeeded(plan)
    this.trackStartPlanEvent(plan)
    this.store.dispatch(new ShowLoading('submitSubscriptionChanges'))
    // let redirectUrl = this.shopifyService.isEmbedded ? this.userService.getEmbeddedRedirectUrl() : window.location.href
    // let successUrl = this.userService.getShopifySuccessUrl(this.userType as any)

    let successUrl = this.shopifyService.isEmbedded ? 
    this.userService.getEmbeddedSuccessUrl(this.userType as any) : 
    this.userService.getShopifySuccessUrl(this.userType as any)
    
    this.subscription.add(
      this.paymentService.changeSubscriptionPlan(plan, this.coupon ? this.coupon.code : null, successUrl, usagePlanId)
      .subscribe((res: ChargeResponse) => {
          this.subscription.add(
            this.gettingStartedService.completeStatus().subscribe(() => {
              this.afterPlanChangeRequest(res, plan)
            })
          )
        },
        err => {
          this.segmentAnalyticsService.track('Plan Error', {
            state: {
              onboarding: true,
              step: 'error',
              group: 'choose_plan',
              status: 'error',
            },
            metadata: {
              error: err,
            },
          })
          this.store.dispatch(new HideLoading('submitSubscriptionChanges'))
          const message = err.message || err.exception || err.error || 'Something went wrong'
          this.snackbarService.showError({text: message})
          this.logger.error(LogLabel.PlansComponent, message)
        },
      ))
  }

  updateJotformIfNeeded(plan) {
    if (this.userInfo.platform_onboarding_status !== PlatformOnboardingStatus.Completed) {
      // also store selection time to calculate time spent till approval
      this.subscription.add(this.gettingStartedService.updateKeyValuePair(keyValuePairKey.SlectedPlanTimestamp, Date.now()).subscribe())
      const submissionId = this.gettingStartedService.getKeyValuePairValue(keyValuePairKey.PrePlansSurveyNewSubmissionId)
      const prefilled5Id = this.gettingStartedService.getKeyValuePairValue(keyValuePairKey.PrePlansSurveyNewPrefilled5)
      if (submissionId && prefilled5Id) {
        const jotFormPayload = {
          [prefilled5Id]: {
            field_1: plan.name || '', // plan name
            field_2: `${+plan.price.fractional / plan.price.currency.subunit_to_unit}` || plan.price?.amount || '', // plan price
            field_3: plan.name === 'Free' ? 'True' : 'False', // free plan doesn't need approval
            field_4: '0', // initialize to 0, will be updated later
            field_5: 'Chose on plans page'
          },
          new: '1',
        }
        // wrap in try catch to make sure this doesn't block anything
        try {
            this.subscription.add(this.apiJotformService.editSubmission(submissionId, jotFormPayload).subscribe())
        } catch (e) {
        }
      }
    }
  }

  // After plan request is successful, we should redirect to the shopify checkout page
  afterPlanChangeRequest(res, plan) {
    this.logger.log(LogLabel.PlansComponent, JSON.stringify(res))
    this.safeLocalStorageService.setItem('pf-plan-change-data', JSON.stringify({
      payment_gateway_plan_identifier: plan.payment_gateway_plan_identifier,
      usage_plan_id: plan.usage_plan?.id,
      plan_cta_label: plan.plan_cta_label_override || plan.plan_cta_label,
    }))
    if (res.result && res.result.type === 'shopify' && res.result.confirmation_url) {
      // save this to show success when user is redirected back
      this.segmentAnalyticsService.track('Plan Checkout', {
        state: {
          onboarding: true,
          step: 'plan_checkout',
          group: 'choose_plan',
        },
        metadata: {
          gateway: 'shopify',
          plan: plan,
        },
      })
      if (this.shopifyService.isEmbedded) {
        const url = res.result.confirmation_url
        const chargesUrl = url.slice(url.indexOf('/charges'), url.length)
        this.shopifyService.redirect.dispatch(Redirect.Action.ADMIN_PATH, { path: chargesUrl })
      } else {
        window.location.href = res.result.confirmation_url
      }
    } else {
      const queryParams = !this.userInfo?.onboarding.chose_plan ? { onboarding: true } : {}
      this.userInfo = this.userService.userInfo
      this.store.dispatch(new SetCurrentUserInfo({
        ...this.userInfo,
        subscription: res.result.subscription,
      }))
      this.store.dispatch(new SetUserChosePlan(true))
      if (this.userType !== 'existing') {
        this.router.navigate([this.initialPaths],{ queryParams }).then(() => {
          this.store.dispatch(new HideLoading('submitSubscriptionChanges'))
          if (!this.shopifyService.isEmbedded) {
            window.location.reload()
          } else {
            // reload user data
            this.subscription.add(this.userService.getUserSubscription().subscribe())
          }
        })
      } else {
        this.currentPlan = plan
        this.currentPlanIdentifier = plan.payment_gateway_plan_identifier
        this.currentUsagePlan = plan?.usage_plan
        this.updateVisiblePlans()
        this.checkIfPlanChanged()
        // reload user data
        this.subscription.add(this.userService.getUserSubscription().subscribe({
          complete: () => {
            this.store.dispatch(new HideLoading('submitSubscriptionChanges'))
          }
        }))
      }
    }
  }

  trackStartPlanEvent(plan) {
    this.segmentAnalyticsService.track(`Start Plan - ${plan?.name}`)
  }

  sendCrispMessage(plan: SubscriptionPlan) {
    const crisp = this.crispService.getCrisp()
    if (crisp) {

      let message = `Hi team, what can I do to activate ${plan.name} for ${this.shopType}?`

      // if there is a payload parse it with mustache
      const payload = plan.plan_cta_payload_override || plan.plan_cta_payload
      if (payload) {
        const mustacheData = {...plan, shop_type: this.shopType}
        message = mustache.render(payload,  mustacheData)
      }

      crisp.push(['do', 'chat:open'])
      crisp.push(['do', 'message:send', ['text', message]])
    }
  }

  sendNumbersOffCrispMessage() {
    const crisp = this.crispService.getCrisp()
    if (crisp) {
      let message = `Hi ONE team, we currently have\n\n Active Customers: ${this.activeKnownCustomers} \nKnown Customers: ${this.knownCustomers}\n\n and we\'d like to request a custom price. The customers number seems to be off. Can you help me?`
      crisp.push(['do', 'chat:open'])
      crisp.push(['do', 'message:send', ['text', message]])
    }
  }

  sendMisconfiguredPlanCrispMessage(plan: SubscriptionPlan) {
    const crisp = this.crispService.getCrisp()
    if (crisp) {

      let message = `Hi team, please help me activate ${plan.name} for ${this.selectControls[plan.payment_gateway_plan_identifier]?.value?.title} range.`

      crisp.push(['do', 'chat:open'])
      crisp.push(['do', 'message:send', ['text', message]])
    }
  }

  onCouponApplied(coupon: ValidateCouponResponse) {
    if (coupon) {
      if (!coupon.field_button_label) {
        coupon.field_button_label = 'Applied!'
      }
      this.coupon = coupon
      this.applyPlansDiscount(coupon)
      if (this.userType !== 'existing' && !this.defaultPromoApplied) {
        this.defaultPromoApplied = true
      }
      this.segmentAnalyticsService.track('Plans - Apply Sale', {coupon})
    } else {
      if (this.userType !== 'existing' && !this.defaultPromoApplied) {
        // if promo defined in src/app/auth/plans/plans.component.ts is invalid
        // fallback to static promo for new users
        this.userPromo = null
        setTimeout(() => {
          this.userPromo = this.staticPromo
        })
        this.defaultPromoApplied = true
      }
      this.coupon = null
      this.removePlansDiscount()
    }
  }

  applyPlansDiscount(coupon: ValidateCouponResponse, planId = null) {
    const couponPlans = coupon.plans || []
    let plans = this.plans
    if (planId) {
      plans = plans.filter((plan) => plan.payment_gateway_plan_identifier === planId)
    }
    if (plans) {
      plans.map((plan: SubscriptionPlan) => {
        // Skip plan if coupon does not applies to it
        // if no coupon plans are set apply to all
        if (couponPlans && couponPlans.length > 0 && !couponPlans.some(_plan => _plan.payment_gateway_plan_identifier === plan.payment_gateway_plan_identifier)) {
          return
        }
        this.modifyPlan(plan, coupon)
      })
    }

    this.updateVisiblePlans()
  }

  modifyPlan(plan: SubscriptionPlan, coupon: ValidateCouponResponse) {
    const isPlanWithNoPrice = [PlansV3Identifier.Free, PlansV3Identifier.Enterprise].includes(plan?.payment_gateway_plan_identifier as PlansV3Identifier)
    // Don't apply coupons to free & custom plans
    // also don't apply to currently selected plan unless otherwise specified
    // don't apply to custom price usage plan
    if (!plan || isPlanWithNoPrice || plan?.usage_plan?.customPriceTier) {
      return
    }
    // PERCENT OFF
    if (coupon.percent_off_precise) {
      if (plan.initial_price) {
        plan.price = _.cloneDeep(plan.initial_price)
      }
      plan.old_display_price = `${+plan.price.fractional / plan.price.currency.subunit_to_unit}`
      plan.initial_price = _.cloneDeep(plan.price)
      plan.price.fractional = `${Math.ceil((+plan.price.fractional / 100.0) * (100.0 - coupon.percent_off_precise))}`

    // AMOUNT OFF
    } else if (coupon.amount_off) {
      if (plan.initial_price) {
        plan.price = _.cloneDeep(plan.initial_price)
      }
      plan.old_display_price = `${+plan.price.fractional / plan.price.currency.subunit_to_unit}`
      plan.initial_price = _.cloneDeep(plan.price)
      plan.price.fractional = `${Math.ceil(+plan.price.fractional - (coupon.amount_off * 100))}`

    // TRIAL DAYS
    } else if (coupon.extended_trial_days) {
      plan.trial_period_override = coupon.extended_trial_days
      plan.plan_cta_label_override = `Start ${coupon.extended_trial_days}-Day Trial`
    }

    if (coupon.disable_trial) {
      plan.trial_period = 0
    }
    if (coupon.plan_cta_action) {
      plan.plan_cta_action_override = coupon.plan_cta_action
    }
    if (coupon.plan_cta_label) {
      plan.plan_cta_label_override = coupon.plan_cta_label
    }
    if (coupon.plan_cta_payload) {
      plan.plan_cta_payload_override = coupon.plan_cta_payload
    }
  }

  removePlansDiscount() {
    const currentPlanId = _.get(this.userInfo, 'subscription.plan.payment_gateway_plan_identifier')
    this.plans.map((plan: SubscriptionPlan) => {
      if (plan.payment_gateway_plan_identifier !== currentPlanId) {
        if (plan.initial_price) {
          delete plan.old_display_price
          plan.price = _.cloneDeep(plan.initial_price)
          delete plan.initial_price
        }
        delete plan.plan_cta_label_override
        delete plan.plan_cta_action_override
        delete plan.plan_cta_payload_override
      }
    })
    this.updateVisiblePlans()
  }

  toggleMoreReviews(opened: boolean) {
    this.hideQuestions = !opened
  }

  checkIfPlanChanged() {
    if (this.safeLocalStorageService.getItem('pf-plan-change-data')) {
      const storedPlanData = JSON.parse(this.safeLocalStorageService.getItem('pf-plan-change-data'))
      this.safeLocalStorageService.removeItem('pf-plan-change-data')
      if (storedPlanData?.payment_gateway_plan_identifier === this.currentPlanIdentifier
          && (!storedPlanData?.usage_plan_id || storedPlanData?.usage_plan_id === this.currentUsagePlan.id)) {
        let successText = 'You have successfully chosen a plan'
        if (storedPlanData.plan_cta_label?.includes('Upgrade')) {
          successText = 'You have successfully upgraded your plan'
        } else if (storedPlanData.plan_cta_label?.includes('Downgrade')) {
          successText = 'You have successfully downgraded your plan'
        }
        this.snackbarService.showSuccess({title: 'Congratulations!', text: successText, duration: 10000})
      }
    }
  }

  afterPlanPopulated() {
    // If it's an admin that choose to override navigation and doesn't have a usage plan, redirect to onboarding
    if (this.getAdminNavOverride(this.userInfo) && !this.currentUsagePlan) {
      this.safeLocalStorageService.removeItem('one-override-uninstalled-behavior')
      this.router.navigateByUrl(`/onboarding/signup/${this.userType}/subscriptions`)
    }
  }

  // If it's an admin that choose to override navigation for this specific user, let them go
  getAdminNavOverride(userInfo): boolean {
    const overrideForAdmin = this.safeLocalStorageService.getItem('one-override-uninstalled-behavior')
    return overrideForAdmin === userInfo.id
  }

  renderActiveCampaignDropdown(field: string) {
    this.activeCampaignDropdownVisible[field] = true
  }

  getButtonClasses(planColor: string): { [key: string]: boolean } {
    return {
      'black': planColor === 'black',
      'purple': planColor === 'purple',
      'shopify-green': planColor !== 'purple' && planColor !== 'black',
    };
  }

  pickPlanLater(e: MouseEvent) {
    e.preventDefault()
    // don't prolong trial end date if it's already set
    const trialEnds = this.userInfo?.custom_plan_trial_end_date || moment().add(5, 'days').toISOString()
    const payment_gateway_plan_identifier = PlansV3Identifier.Custom
    this.store.dispatch(new ShowLoading('submitSubscriptionChanges'))
    this.subscription.add(
      forkJoin([
        this.paymentService.changeSubscriptionPlan({payment_gateway_plan_identifier} as any, null),
        this.shopifySubscribersApiService.putCustomPlanTrial(true, trialEnds),
        this.gettingStartedService.completeStatus(),
      ]).pipe(finalize(() => this.store.dispatch(new HideLoading('submitSubscriptionChanges'))))
        .subscribe(([res]) => {
          this.userInfo = this.userService.userInfo
          this.store.dispatch(new SetCurrentUserInfo({
            ...this.userInfo,
            subscription: res.result.subscription,
          }))
          const queryParams = {} as any
          if (this.userType !== 'returning') {
            queryParams.onboarding = true
          }
          this.store.dispatch(new SetUserChosePlan(true))
          // Global Event
          this.segmentAnalyticsService.track('Platform | Subscriptions | Checkout | Skipped')
          // Funnel Event: Onboarding Flow
          this.segmentAnalyticsService.track('Onboarding | Platform | Subscriptions | Checkout | Skipped')
          this.router.navigate([this.initialPaths], { queryParams })
        }),
    )
  }
}
