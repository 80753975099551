import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { PrePlansSurveyComponent } from './pre-plans-survey.component'
import { JotFormModule } from '../../shared/modules/jotform/jotform.module'
import { ShopifySubscribersApiService } from '../../pages/subscribers/services/shopify-subscribers-api.service'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { OneIconComponent } from '../../shared/components/one-icon/one-icon.component'
import { SimpleLoadingOverlayComponent } from '../../shared/components/simple-loading-overlay/simple-loading-overlay.component'
import { LogOutComponent } from '../../shared/components/log-out/log-out.component'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    JotFormModule,
    OneIconComponent,
    SimpleLoadingOverlayComponent,
    LogOutComponent
  ],
  providers: [
    ShopifySubscribersApiService,
  ],
  declarations: [
    PrePlansSurveyComponent,
  ],
})
export class PrePlansSurveyModule {
}
