<div class="modal-header">
  <div class="modal-title">Message Us</div>
  <button class="close" (click)="dialogRef.close()">
    <span>&times;</span>
  </button>
</div>
<div class="modal-body">

  <div class="ContactButtonList mt-0">
    <div class="ContactButton _withIcon">
      <a class="pf-button sm outline blue"
         href="#" (click)="onLiveChatClick($event)">
        <span class="Icon _crisp"></span>
        Live Chat
      </a>
      <span class="ContactButton__Recommended">(Recommended)</span>
    </div>
    <div class="ContactButton _withIcon">
      <a class="pf-button sm outline gray"
         target="_blank"
         href="mailto:support@prooffactor.com?subject=Support - No Recent Activity - Import Request">
        <span class="Icon _email fa fa-envelope"></span>
        Email
      </a>
    </div>
    <div class="ContactButton _withIcon">
      <a class="pf-button outline blue sm"
         target="_blank"
         href="https://m.me/prooffactor">
                <span class="Icon _messenger"
                      [ngStyle]="{'background-image': 'url(/app_assets/messenger-icon.png)'}"></span>
        Messenger
      </a>
      <span class="ContactButton__Recommended">(Recommended)</span>
    </div>
  </div>
  <!-- <p class="small text-muted mb-2 text-center">*Average response time is 2 minutes. We are online 24/7 (except 2 hours/day, 8 to 10 PM EST).</p> -->


</div>
