export enum PreviewState {
  default = 'DEFAULT',
  quickSubscribe = 'QUICK_SUBSCRIBE',
  smsWaiting = 'SMS_WAITING',
  emailWaiting = 'EMAIL_WAITING',
  finished = 'FINISHED',
  teaser = 'TEASER',
  reminder = 'REMINDER',
}

export interface PreviewStateButton {
  title: string
  value: PreviewState
  active: boolean
  hidden?: boolean
  order?: number
  controls?: boolean,
  index?: number,
  separate?: boolean
  stepLabel?: boolean
  enabled?: boolean
}
