import { Injectable } from '@angular/core'
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http'
import { Observable, throwError } from 'rxjs'
import { ShopifyShopTokenService } from '../services/shopify-shop-token.service'
import { ShopSwitchService } from '../../shared/components/one-side-nav/one-shop-switch/services/one-shop-switch.service'
import { UserService } from '../services/user.service'

export class RoleBasedAccessDeniedError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'RoleBasedAccessDeniedError'
  }
}

export class InvoiceNotPaidError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'InvoiceNotPaidError'
  }
}

@Injectable()
export class RoleBasedInterceptor implements HttpInterceptor {
  allowedRolesForRequest = {
    GET: ['editor', 'viewer'],
    POST: ['editor'],
    PUT: ['editor'],
    PATCH: ['editor'],
    DELETE: ['editor'],
  }

  whitelistedPostEndpoints = [
    'https://api.one.store/v1/me/sessions',

    'https://api.one.store/v1/me/shop/fetch_products',
    'https://api.one.store/v1/me/shop/fetch_collections',

    'https://api.one.store/v1/default_wheel_themes',
    'https://api.one.store/v1/default_cb_themes',
    'https://api.one.store/v1/default_newsletter_themes',

    'https://api.one.store/v1/default_free_shipping_themes',
    'https://api.one.store/v1/default_sales_announcement_themes',
    'https://api.one.store/v1/default_products_announcement_themes',

    'https://api.one.store/v1/me/interactions/onboarding/status',
    'https://api.one.store/v1/me/interactions/onboarding/events',

    'https://api.one.store/v1/unlayer_email_templates/search',
  ]

  whitelistedGetEndpoints = [
    'https://api.one.store/v1/dashboard_settings',
    'https://api.one.store/v1/me/sessions',
    'https://api.one.store/v1/me',
    'https://api.one.store/v1/billing',
    'https://api.one.store/v1/me/subscription',
    'https://api.one.store/v1/onboarding',
    'https://api.one.store/v1/me/interactions',
    'https://api.one.store/v1/me/usage',
    'https://api.one.store/v1/me/stores/*',
  ]

  constructor(
    private shopifyShopTokenService: ShopifyShopTokenService,
    private shopSwitchService: ShopSwitchService,
    private userService: UserService
  ) {}

  private isUserAuthorized(role: string, method: string): boolean {
    return this.allowedRolesForRequest[method]?.includes(role)
  }

  private isWhitelisted(url: string, method: string): boolean {
    let whitelistedEndpoints = []

    if (['POST', 'PUT', 'PATCH', 'DELETE'].includes(method)) {
      whitelistedEndpoints = this.whitelistedPostEndpoints
    } else if (method === 'GET') {
      whitelistedEndpoints = this.whitelistedGetEndpoints
    }

    return whitelistedEndpoints.some(endpoint =>
      endpoint.includes('*') ? url.startsWith(endpoint.split('*')[0]) : url === endpoint
    )
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const invoiceDueDatePassed = this.userService.invoiceDueDatePassed
    // Check if user is currently using a shop token
    const shopToken = this.shopifyShopTokenService.getValue()
    if (shopToken || invoiceDueDatePassed) {
      // If the user is using a shop token, find the corresponding store
      const shop = this.shopSwitchService.getShop()
      // set role to view only if invoice not paid
      const userRole = invoiceDueDatePassed ? 'viewer' : shop?.one_store_role

      // If role is not defined, proceed with the request
      if (!userRole) {
        return next.handle(req)
      }

      // Check if the endpoint is whitelisted
      if (this.isWhitelisted(req.url, req.method)) {
        return next.handle(req)
      }

      // Role-based access control
      if (this.isUserAuthorized(userRole, req.method)) {
        return next.handle(req)
      }

      // show invoice reminder modal if invoice is not paid
      if (invoiceDueDatePassed) {
        return throwError(() => new InvoiceNotPaidError('Invoice is past due date'))
      }

      const msg = `Unauthorized access to ${req.method} ${req.url} with role ${userRole}`
      return throwError(() => new RoleBasedAccessDeniedError(msg))
    } else {
      // if the user is not using a shop token let them proceed with the request
      return next.handle(req)
    }
  }
}
