import { Injectable } from '@angular/core'
import _ from 'lodash'
import {
  NewsletterThemeConfigDefault,
  NewsletterThemeConfigLocal
} from '../../../../../shared/models/campaign/coupon-box-notification.model'

@Injectable()
export class NewsletterService {
  public themesList: NewsletterThemeConfigDefault[]
  public isOnboarding: boolean = false

  constructor(
  ) {}

  extendThemeToLocal(themesList: NewsletterThemeConfigLocal[]): NewsletterThemeConfigLocal[] {
    const newThemesList = []

    themesList.forEach(theme => {
      newThemesList.push({
        ...theme,
        selected: false,
        loaded: false,
        resetable: false,
      })
    })
    return newThemesList.sort((a, b) => b.list_order - a.list_order)
  }

}
