import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { CommonModule } from '@angular/common'
import { ImageUploadModalComponent } from './components/modal/image-upload-modal.component'
import { ImageUploadService } from './services/image-upload.service'
import { ImageUploadComponent } from './components/upload/image-upload.component'
import { ImageUploadLibComponent } from './components/lib/image-upload-lib.component'
import { MultiUploadComponent } from './components/multi-upload/multi-upload.component'
import { LoadingOverlayComponent } from '../../components/loading-overlay/loading-overlay.component'
import { FileSizePipe } from '../../pipes/file-size.pipe'

@NgModule({
  declarations: [
    ImageUploadModalComponent,
    ImageUploadComponent,
    MultiUploadComponent,
    ImageUploadLibComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    LoadingOverlayComponent,
    FileSizePipe,
  ],
  exports: [
    ImageUploadComponent,
    MultiUploadComponent,
    ImageUploadModalComponent,
    ImageUploadLibComponent,
  ],
  providers: [
    ImageUploadService,
  ],
})

export class ImageUploadModule {}
