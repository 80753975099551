import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'one-floating-tab',
  templateUrl: './floating-tab.component.html',
  styleUrls: ['./floating-tab.component.scss']
})
export class FloatingTabComponent implements OnInit {
  @Input() closeButton = true
  @Input() tabText: string
  showTab = false

  constructor() { }

  ngOnInit(): void {
  }

}
