import { ChangeDetectionStrategy, Component, Input } from '@angular/core'

@Component({
  selector: 'pf-side-navigation-bar-back-button',
  templateUrl: './side-navigation-bar-back-button.component.html',
  styleUrls: ['./side-navigation-bar-back-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideNavigationBarBackButtonComponent {
  @Input() label: string = 'Temp label';
  @Input() url: string = '#';
  @Input() expanded: boolean = false;
}
