import { Injectable } from '@angular/core'
import { LogLabelType } from '../../shared/models/logger/log-label-type.model'
import { LogLabel } from '../../shared/models/logger/log-label.model'
import { SafeLocalStorageService } from './safe-local-storage.service'


@Injectable({
  providedIn: 'root',
})
export class Logger {

  isLoggerActive

  readonly labelTypes: { [key in LogLabelType]: LogLabel[] } = {
    [LogLabelType.Service]: [
      LogLabel.CampaignService,
      LogLabel.PaymentService,
      LogLabel.AuthService,
      LogLabel.MenuService,
      LogLabel.SnackbarService,
      LogLabel.UserService,
      LogLabel.LoadingService,
      LogLabel.GettingStartedService,
    ],
    [LogLabelType.Component]: [
      LogLabel.LoadingComponent,
      LogLabel.SavableComponent,
      LogLabel.FortuneWheelComponent,
      LogLabel.AppComponent,
      LogLabel.LoginComponent,
      LogLabel.ShopifyComponent,
      LogLabel.SettingsComponent,
      LogLabel.PlansComponent,
      LogLabel.ChoosePlanComponent,
      LogLabel.DisplayComponent,
      LogLabel.RecentActivityComponent,
      LogLabel.CaptureComponent,
      LogLabel.AdvancedComponent,
      LogLabel.ShopifyOauthService,
      LogLabel.AppearanceComponent,
      LogLabel.GettingStartedComponent,
      LogLabel.DetectComponent,
    ],
  }
  readonly labelColors: { [key in LogLabel]: string } = {
    [LogLabel.Default]: '#888888',

    [LogLabel.AuthService]: '#1f7099',
    [LogLabel.SaveService]: '#1f7099',
    [LogLabel.LoadingService]: '#204899',
    [LogLabel.PaymentService]: '#202099',
    [LogLabel.OauthFlowService]: '#202099',
    [LogLabel.MenuService]: '#482099',
    [LogLabel.SnackbarService]: '#cccccc',
    [LogLabel.UserService]: '#712099',
    [LogLabel.CampaignService]: '#992099',
    [LogLabel.GettingStartedService]: '#992071',
    [LogLabel.MailIntegrations]: '#02a1ff',
    [LogLabel.AggregateActivityComponent]: '#992071',

    [LogLabel.AppComponent]: '#c298d9',
    [LogLabel.ShopifyOauthService]: '#d998d9',
    [LogLabel.SavableComponent]: '#d998c3',
    [LogLabel.FortuneWheelComponent]: '#d998ad',
    [LogLabel.LoginComponent]: '#d99898',
    [LogLabel.ShopifyComponent]: '#d9ac98',
    [LogLabel.SettingsComponent]: '#d9c298',
    [LogLabel.PlansComponent]: '#d9d998',
    [LogLabel.ChoosePlanComponent]: '#c4d998',
    [LogLabel.DisplayComponent]: '#add998',
    [LogLabel.RecentActivityComponent]: '#98d998',
    [LogLabel.CaptureComponent]: '#98d9ac',
    [LogLabel.AdvancedComponent]: '#98d9c3',
    [LogLabel.AppearanceComponent]: '#98d9d9',
    [LogLabel.LoadingComponent]: '#98c3d9',
    [LogLabel.GettingStartedComponent]: '#9a9ad9',
    [LogLabel.DetectComponent]: '#af9ad9',

    [LogLabel.SegmentAnalyticsService]: '#43b78b',
  }

  constructor(
    private safeLocalStorageService: SafeLocalStorageService,
  ) {
    window['enableOneConsoleLog'] = (): string => {
      this.safeLocalStorageService.setItem('isLoggerActive', 'true')
      this.isLoggerActive = true
      if (this.safeLocalStorageService.localStorageAvailable) {
        return 'Logger enabled'
      } else {
        return 'Logger enabled (cookies)'
      }
    }

    window['disableOneConsoleLog'] = (): string => {
      this.safeLocalStorageService.setItem('isLoggerActive', 'false')
      this.isLoggerActive = false
      if (this.safeLocalStorageService.localStorageAvailable) {
        return 'Logger disabled'
      } else {
        return 'Logger disabled (cookies)'
      }
    }
  
    this.isLoggerActive = this.safeLocalStorageService.getItem('isLoggerActive') === 'true'
  }


  public logAsync(label: LogLabel, argsCreator: () => any[]): void {
    if (this.isLoggerActive) {
      const args = argsCreator()
      this.consoleLog.apply(this, ['log', label, ...args])
    }
  }

  public log(label: LogLabel, ...args) {
    this.consoleLog.apply(this, ['log', label, ...args])
  }

  public warn(label: LogLabel, ...args) {
    this.consoleLog.apply(this, ['warn', label, ...args])
  }

  public info(label: LogLabel, ...args) {
    this.consoleLog.apply(this, ['info', label, ...args])
  }

  public error(label: LogLabel, ...args) {
    this.consoleLog.apply(this, ['error', label, ...args])
  }

  public group(label: LogLabel, ...args) {
    this.consoleLog.apply(this, ['group', label, ...args])
  }

  public groupCollapsed(label: LogLabel, ...args) {
    this.consoleLog.apply(this, ['groupCollapsed', label, ...args])
  }

  public groupEnd(label: LogLabel, ...args) {
    this.consoleLog.apply(this, ['groupEnd', label, ...args])
  }


  private findLabelType(label: LogLabel): LogLabelType {
    for (const type in this.labelTypes) {
      if (this.labelTypes.hasOwnProperty(type)) {
        const isFound = this.labelTypes[type].some(item => item === label)
        if (isFound) {
          return type as LogLabelType
        }
      }
    }
    return null
  }

  private consoleLog(methodName: string, label: LogLabel, ...args) {
    if (this.isLoggerActive) {
      const color = this.labelColors[label] || this.labelColors[LogLabel.Default]
      const type = this.findLabelType(label)
      let text = ''
      const styles = []
      if (label) {
        text += `%c${label}`
        styles.push(`font-size: 11px; line-height: 15px; color: #fff; background-color:${color}; border: 1px solid ${color}; padding: 2px 4px; border-radius: 0; margin: 2.5px 5px 7.5px;font-family:sans-serif`)
      }
      if (type) {
        text += `%c${type}`
        styles.push(`font-size: 11px; line-height: 15px; color: ${color}; border: 1px solid ${color}; padding: 2px 4px; border-radius: 0; margin: 2.5px 5px 7.5px -5px; font-family:sans-serif`)
      }
      console[methodName].apply(this, [text, ...styles, `\n`, ...args])
    }
  }
}
