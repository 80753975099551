import { Injectable, inject } from '@angular/core'
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router'
import { Subject } from 'rxjs'
import { ConfirmModalComponent, ConfirmModalConfig } from '../../shared/components/modals/confirm.modal/confirm.modal.component'
import { CampaignDataService } from '../services/data-services/campaign-data.service'
import { isEqual } from 'lodash'
import { scan, takeUntil } from 'rxjs/operators'
import diff from 'variable-diff'
import * as Sentry from '@sentry/browser'
import { UntypedFormGroup } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'

export class UnsavedDataDetector {
  /** Form Type */
  form: UntypedFormGroup
  /** Being called when Save happens to clear the `watchUnsaved` */
  private _clearWatcher$: Subject<void> = new Subject<void>()
  /** Subject that stores all next'd values */
  public watchUnsaved$: Subject<any> = new Subject()
  /** Main flag */
  public dataHasChanged: boolean = false
  /** Component's form validity flag */
  public formIsValid: boolean = true
  public campaignDataService: CampaignDataService = inject(CampaignDataService)

  constructor(
  ) {
    this._onDataChange()
    this._onDataSave()
  }

  /** Watch `campaignDataService.saved$` subject */
  private _onDataSave() {
    this.campaignDataService.saved$.asObservable().subscribe(() => {
      // If config was saved we want to trigger a reset for `watchUnsaved$`
      this._clearWatcher$.next()
      // Set the flag
      this.dataHasChanged = false
      // Restart watching the `watchUnsaved$`
      this._onDataChange()
    })
  }

  /** Start watching `watchUnsaved$` */
  private _onDataChange() {
    /**
     * Whenever a new value is being set we want to compare it with initial value
     * If there are differences, then `dataHasChanged`
    */
    this.watchUnsaved$.pipe(
      scan((acc, curr) => [...acc, curr], []),
      takeUntil(this._clearWatcher$)
    ).subscribe(changes => {
      if (changes.length && changes.length > 1) {
        const initialData = changes[0]
        const lastChanges = changes[changes.length - 1]
        this.dataHasChanged = !isEqual(initialData, lastChanges)
        this.formIsValid = typeof this.form === 'undefined' ? true : this.form.valid
        if (this.dataHasChanged) {
          Sentry.addBreadcrumb({
            category: 'savable-component',
            message: `compareSavableData: ${JSON.stringify(diff(initialData, lastChanges))}`,
            level: 'info',
          })
        }
      }
    })
  }

  canDeactivate(): boolean {
    return !this.dataHasChanged
  }
}



@Injectable({providedIn: 'root'})
export class UnsavedDataGuardService  {

  constructor(
    private dialog: MatDialog,
    private router: Router
  ) {}

  private _warningDialog(formValid) {
    const routeState = this.router.getCurrentNavigation()?.extras?.state
    const formValidDialogText =  routeState && routeState.skipStep ? 'Are you sure you want to skip the step without saving?'
      : 'Are you sure you want to see the next step without saving?'
    const formInvalidDialogText = 'Please make sure your plugin configuration has no errors before saving'
    const dialogText = formValid ? formValidDialogText : formInvalidDialogText
    const dialogCancelButtonText =  routeState && routeState.skipStep ? 'Skip' : 'Continue'
    const dialogTitle = formValid ? 'Plugin configuration has unsaved changes' : 'Plugin configuration is invalid'
    return this.dialog.open(ConfirmModalComponent, {
      width: '560px',
      data: {
        title: dialogTitle,
        text: dialogText,
        cancelButton: {
          text: `${dialogCancelButtonText} without saving`,
          classes: 'pf-button filled red'
        },
        acceptButton: {
          text: 'Save and continue',
          classes: `pf-button filled green ${formValid ? '' : '_disabled'}`
        }
      } as ConfirmModalConfig,
    })
  }

  canDeactivate(component: UnsavedDataDetector, _route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
    if (typeof component.canDeactivate === 'undefined' || component.canDeactivate()) {
      return true
    } else {
      const subject = new Subject<boolean>()
      this._warningDialog(component.formIsValid).afterClosed().subscribe(result => {
        if (result) {
          const saveSub = component.campaignDataService.saved$.subscribe(() => {
            setTimeout(() => {
              subject.next(result)
              subject.complete()
              saveSub.unsubscribe()
            }, 0)
          })
          component.campaignDataService.saveSubject$.next()
        } else if (result === null || result === undefined) {
          return
        } else {
          subject.next(true)
          subject.complete()
        }
      })

      return subject.asObservable()
    }
  }
}
