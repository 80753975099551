import { Component, NgZone, OnInit } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { environment } from '../../../environments/environment'
import { AuthService } from '../../core/services/auth.service'
import { BrandingService } from '../../core/services/branding.service'
import { Logger } from '../../core/services/logger.service'
import { LoadingLabel } from '../../shared/models/loading/loading-label'
import { HideLoading, ShowLoading } from '../../store/loading/loading.actions'
import { StoreState } from '../../store/store.state'

@Component({
  selector: 'pf-with-password',
  templateUrl: './with-password.component.html',
  styleUrls: ['./with-password.component.scss']
})

export class LoginWithPasswordComponent implements OnInit {
  loginForm: UntypedFormGroup
  brandingName: string
  errors = []
  captchaToken = null
  environment = environment

  constructor(
    protected router: Router,
    private authService: AuthService,
    private fb: UntypedFormBuilder,
    private logger: Logger,
    private ngZone: NgZone,
    private brandingService: BrandingService,
    private store: Store<StoreState>,
  ) {
    this.brandingName = this.brandingService.getBrandingData().name
  }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
      // captchaToken: ['', Validators.required],
    })
  }

  onVerify(token: string) {
    this.captchaToken = token
  }

  onExpired(response: any) {
    this.captchaToken = null
  }

  onError(error: any) {
    console.error('error verifying captcha:', error)
  }

  login(event) {
    event.preventDefault()
    this.errors = []
    this.store.dispatch(new ShowLoading(LoadingLabel.SignInEmail))
    this.authService.signInWithEmail(this.loginForm.value.email, this.loginForm.value.password)
      .then(() => {
        this.ngZone.run(() => {
          this.router.navigateByUrl('/')
        })
      })
      .catch((error) => {
        this.logger.error(null, `error: ${JSON.stringify(error)}`)
        this.errors = [error.message]
      })
      .finally(() => this.store.dispatch(new HideLoading(LoadingLabel.SignInEmail)))
  }
}
