import { CommonModule } from '@angular/common'
import { Component, Inject } from '@angular/core'
import { CrispService } from '../../../../core/services/crisp.service'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

@Component({
    templateUrl: './contact-us.modal.component.html',
    styleUrls: ['./contact-us.modal.component.scss'],
    standalone: true,
    imports: [
      CommonModule,
    ]
})
export class ContactUsModalComponent {
  shop: string

  constructor(
    private crispService: CrispService,
    public dialogRef: MatDialogRef<ContactUsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { shop: string },
  ) {
    this.dialogRef.addPanelClass(['_no-padding'])
    this.shop = this.data?.shop
  }

  onLiveChatClick(event) {
    event.preventDefault()
    const crisp = this.crispService.getCrisp()
    if (crisp) {
      crisp.push(['do', 'chat:open'])
      if (this.shop && this.shop.length) {
        crisp.push(['do', 'message:send', ['text', `Hi Team, I am a ${this.shop} and I need your help.`]])
      } else {
        crisp.push(['do', 'message:send', ['text', `Hi Team, I need your help.`]])
      }
    }
  }
}
