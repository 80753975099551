export enum HolidayTypes {
  DiaDeMuertos = 'dia_de_muertos',
  Mashujaa = 'mashujaa',
  Halloween = 'halloween',
  Hanukkah = 'hanukkah',
  BlackFriday = 'black_friday',
  CyberMonday = 'cyber_monday',
  CyberWeek = 'cyber_week',
  Christmas = 'christmas',
  Double11 = 'double_11',
  Thanksgiving = 'thanksgiving',
  NewYear = 'new_year',
  LunarNewYear = 'lunar_new_year',
  ValentinesDay = 'valentines_day',
  LanternFestival = 'lantern_festival',
  StPatricksDay = 'st_patricks_day',
  WomensDay = 'womens_day',
  HoliFestival = 'holi_festival',
  MothersDay = 'mothers_day',
  Easter = 'easter',
  Ramadan = 'ramadan',
  LaborDay = 'labor_day',
  FathersDay = 'fathers_day',
  MemorialDay = 'memorial_day',
  CincoDeMayo = 'cinco_de_mayo',
  ChildrensDay = 'childrens_day',
  FirstDayOfSummer = 'first_day_of_summer',
  IndependenceDay = 'independence_day',
  EidAlAdha = 'eid_al_adha',
  PrideMonth = 'pride_month',
  IndiaIndependenceDay = 'india_independence_day',
  PakistanIndependenceDay = 'pakistan_independence_day',
  RakshaBandhanDay = 'raksha_bandhan_day',
  UkraineIndependenceDay = 'ukraine_independence_day',
  MidAutumnFestival = 'mid_autumn_festival',
  NavratriFestival = 'navratri_festival',
  NigeriaIndependenceDay = 'nigeria_independence_day',
  EidMiladUnNabi = 'eid_milad_un_nabi',
  DoubleTenthDay = 'double_tenth_national_day',
  Diwali = 'diwali',
  SuperBowl = 'super_bowl',
}

export enum AutomationTypes {
  CartRecovery = 'cart_recovery',
  Upsell = 'upsell',
  CheckoutUpsell = 'checkout_upsell',
  ThankYou = 'welcome_thank_you',
}

export const promotionTypeMap = {
  [HolidayTypes.Halloween]: 'Halloween',
  [HolidayTypes.BlackFriday]: 'Black Friday',
  [HolidayTypes.Double11]: 'Double 11',
  [HolidayTypes.Thanksgiving]: 'Thanksgiving',
  [HolidayTypes.CyberMonday]: 'Cyber Monday',
  [HolidayTypes.CyberWeek]: 'Cyber Week',
  [HolidayTypes.Christmas]: 'Christmas',
  [HolidayTypes.Hanukkah]: 'Hanukkah',
  [HolidayTypes.NewYear]: 'New Year',
  [HolidayTypes.LunarNewYear]: 'Lunar New Year',
  [HolidayTypes.ValentinesDay]: `Valentine's Day`,
  [HolidayTypes.LanternFestival]: 'Lantern Festival',
  [HolidayTypes.StPatricksDay]: `St. Patrick's Day`,
  [HolidayTypes.WomensDay]: `Women's Day`,
  [HolidayTypes.HoliFestival]: `Holi Festival`,
  [HolidayTypes.MothersDay]: `Mother's Day`,
  [HolidayTypes.Easter]: `Easter`,
  [HolidayTypes.Ramadan]: `Ramadan`,
  [HolidayTypes.LaborDay]: `Labor Day`,
  [HolidayTypes.FathersDay]: `Father's Day`,
  [HolidayTypes.MemorialDay]: `Memorial Day`,
  [HolidayTypes.CincoDeMayo]: `Cinco de Mayo`,
  [HolidayTypes.ChildrensDay]: `Children's Day`,
  [HolidayTypes.FirstDayOfSummer]: `Summer`,
  [HolidayTypes.IndependenceDay]: `Independence Day`,
  [HolidayTypes.EidAlAdha]: `Eid al-Adha`,
  [HolidayTypes.PrideMonth]: `Pride Month`,
  [HolidayTypes.IndiaIndependenceDay]: `Independence Day (India)`,
  [HolidayTypes.PakistanIndependenceDay]: `Independence Day (Pakistan)`,
  [HolidayTypes.RakshaBandhanDay]: `Raksha Bandhan`,
  [HolidayTypes.UkraineIndependenceDay]: `Independence Day (Ukraine)`,
  [HolidayTypes.MidAutumnFestival]: `Mid-Autumn Festival`,
  [HolidayTypes.NavratriFestival]: `Navratri`,
  [HolidayTypes.NigeriaIndependenceDay]: `Independence Day (Nigeria)`,
  [HolidayTypes.EidMiladUnNabi]: `Eid Milad-un-Nabi`,
  [HolidayTypes.DoubleTenthDay]: `Double Tenth National Day`,
  [HolidayTypes.Diwali]: `Diwali`,
  [HolidayTypes.SuperBowl]: `Super Bowl`,
  [HolidayTypes.Mashujaa]: `Mashujaa Day`,
  [HolidayTypes.DiaDeMuertos]: `Día de Muertos`,
}

export const promotionTagMap = {
  [HolidayTypes.Halloween]: [['Halloween']],
  [HolidayTypes.BlackFriday]: [['Black Friday', 'Cyber Monday', 'Cyber Week']],
  [HolidayTypes.Double11]: [['Double 11']],
  [HolidayTypes.Thanksgiving]: [['Thanksgiving']],
  [HolidayTypes.CyberMonday]: [['Cyber Monday', 'Cyber Week']],
  [HolidayTypes.CyberWeek]: [['Cyber Week']],
  [HolidayTypes.Christmas]: [['Christmas']],
  [HolidayTypes.Hanukkah]: [['Hanukkah']],
  [HolidayTypes.NewYear]: [['New Year', 'Lunar New Year', 'Chinese New Year']],
  [HolidayTypes.LunarNewYear]: [['Lunar New Year']],
  [HolidayTypes.ValentinesDay]: [[`Valentine's Day`]],
  [HolidayTypes.LanternFestival]: [['Lantern Festival']],
  [HolidayTypes.StPatricksDay]: [[`St. Patrick's Day`]],
  [HolidayTypes.WomensDay]: [[`Women's Day`]],
  [HolidayTypes.HoliFestival]: [[`Holi Festival`]],
  [HolidayTypes.MothersDay]: [[`Mother's Day`]],
  [HolidayTypes.Easter]: [[`Easter`]],
  [HolidayTypes.Ramadan]: [[`Ramadan`]],
  [HolidayTypes.LaborDay]: [[`Labor Day`]],
  [HolidayTypes.FathersDay]: [[`Father's Day`]],
  [HolidayTypes.MemorialDay]: [[`Memorial Day`]],
  [HolidayTypes.CincoDeMayo]: [[`Cinco de Mayo`]],
  [HolidayTypes.ChildrensDay]: [[`Children's Day`]],
  [HolidayTypes.FirstDayOfSummer]: [[`Summer`]],
  [HolidayTypes.IndependenceDay]: [[`Independence Day`]],
  [HolidayTypes.EidAlAdha]: [[`Eid al-Adha`]],
  [HolidayTypes.PrideMonth]: [[`Pride Month`]],
  [HolidayTypes.IndiaIndependenceDay]: [[`Independence Day (India)`]],
  [HolidayTypes.PakistanIndependenceDay]: [[`Independence Day (Pakistan)`]],
  [HolidayTypes.RakshaBandhanDay]: [[`Raksha Bandhan`]],
  [HolidayTypes.UkraineIndependenceDay]: [[`Independence Day (Ukraine)`]],
  [HolidayTypes.MidAutumnFestival]: [[`Mid-Autumn Festival`]],
  [HolidayTypes.NavratriFestival]: [[`Navratri`]],
  [HolidayTypes.NigeriaIndependenceDay]: [[`Independence Day (Nigeria)`]],
  [HolidayTypes.EidMiladUnNabi]: [[`Eid Milad-un-Nabi`]],
  [HolidayTypes.DoubleTenthDay]: [[`Double Tenth National Day`]],
  [HolidayTypes.Diwali]: [[`Diwali`]],
  [HolidayTypes.SuperBowl]: [[`Super Bowl`]],
  [HolidayTypes.Mashujaa]: [[`Mashujaa Day`]],
  [HolidayTypes.DiaDeMuertos]: [[`Día de Muertos`]],
}
