import { Injectable, OnDestroy } from '@angular/core'
import { Subject, Subscription } from 'rxjs'
import { ImageUploadModalComponent } from '../components/modal/image-upload-modal.component'
import { MatDialog } from '@angular/material/dialog'

@Injectable()
export class ImageUploadService implements OnDestroy {
  public imageSelected$: Subject<any> = new Subject()
  public imageUploaded$: Subject<string> = new Subject()
  public selectedImage: string
  private subscription = new Subscription()
  private iframeRef: HTMLIFrameElement

  constructor(
    private dialog: MatDialog,
  ) {
    this.imageSelected$.subscribe(image => {
      this.selectedImage = image
    })
  }

  private onMessage = (message) => {
    // Iframe message coming from unlayer logo and product blocks
    if (message?.data?.name === 'image-upload-modal:open') {
      const dialogRef = this.dialog.open(ImageUploadModalComponent, {
        width: '665px',
        data: {
          path: 'images/logo',
          type: 'unlayer',
        }
      })
      dialogRef.afterClosed().subscribe(res => {
        if (res) {
          this.iframeRef.contentWindow.postMessage({
            name: message?.data?.toolName ? `image-upload-modal:${message?.data?.toolName}:selected` : 'image-upload-modal:selected',
            img: res,
          }, '*')
        }
      })
    }
  }

  public validateImageFileType(uploadEvent): boolean {
    const allowedFormats = ['jpg', 'jpeg', 'png', 'gif', 'svg', 'webp']
    const fileName = uploadEvent.target.files[0].name
    const idxDot = fileName.lastIndexOf('.') + 1
    const extFile = fileName.substr(idxDot, fileName.length).toLowerCase()
    const isFileTypeValid = allowedFormats.includes(extFile)
    return isFileTypeValid
  }

  addMessageListener(iframeRef?: any) {
    this.iframeRef = iframeRef?.editor?.frame?.iframe
    this.removeMessageListener()
    window.addEventListener('message', this.onMessage)
  }

  removeMessageListener() {
    window.removeEventListener('message', this.onMessage)
  }

  ngOnDestroy() {
    this.removeMessageListener()
    this.subscription.unsubscribe()
  }
}
