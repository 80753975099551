<div class="shop-select" (click)="checkEmbedded($event)">
  <div class="shop-select-value" 
    [class._opened]="isOpened"
    [cdkMenuTriggerFor]="shopListMenu"
    (cdkMenuOpened)="isOpened = true"
    (cdkMenuClosed)="isOpened = false">
    
    <!-- If shop selected -->
    <ng-container *ngIf="(selectedShop$ | async)?.shopify_store_name; else selectShopTpl">
      <one-avatar
        [username]="(selectedShop$ | async).shopify_store_name"
      ></one-avatar> 

      <div class="shop-select-value-text" [title]="(selectedShop$ | async).shopify_store_name">
        {{(selectedShop$ | async).shopify_store_name}}
      </div>
      
      <div class="shop-select-value-icon">
        <one-icon name="chevron-down"></one-icon>
      </div>
    </ng-container>

    <!-- If shop not selected -->
    <ng-template #selectShopTpl>
      <div class="w-100">
        <one-icon name="storefront-outline" class="_store-icon"></one-icon>
        <button class="pf-button xs filled black shop-select-value-btn"
          (click)="onSelectShopBtnClick()">
          Switch Stores
        </button>
      </div>
    </ng-template>

  </div>
</div>

<ng-template #shopListMenu>
  <div class="shop-select-dropdown" cdkMenu>
    <ng-container *ngFor="let shopGroup of (shopList$ | async)">
      <div class="shop-select-group" *ngIf="!shopGroup.hidden">
        <div class="shop-select-group-title" *ngIf="shopGroup.name">{{shopGroup.name}}</div>
        <ng-container *ngFor="let shop of shopGroup.shops">
          <div *ngIf="!shop.hidden"
            (click)="onShopChange(shop)"
            class="shop-select-item">
            <one-avatar [username]="shop.shopify_store_name"></one-avatar> {{shop.shopify_store_name}}
          </div>
        </ng-container>
      </div>
    </ng-container>
    <div *ngIf="!allShopsHidden" (click)="onSelectShopBtnClick(true)" class="shop-select-item _switch-stores">
      Connect Store
    </div>
  </div>
</ng-template>
