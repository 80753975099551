import { Injectable } from '@angular/core'
import { SafeLocalStorageService } from './safe-local-storage.service'
import { BehaviorSubject } from 'rxjs'
import { Shop } from '../../shared/components/one-side-nav/one-shop-switch/shop-switch.model'

@Injectable({providedIn: 'root'})
export class ShopifyShopTokenService {
  public key = 'one-shopify-shop-identity'
  // Shopify store id key which is set manually on admin page
  public adminSetIdKey = 'one_admin_set_shopify_store_id'

  // one_access_token
  private shopIdentity$: BehaviorSubject<Shop> = new BehaviorSubject<Shop>(null)

  constructor(
    private safeLocalStorageService: SafeLocalStorageService,
  ) { }

  getValue() {
    const subjData = this.shopIdentity$.getValue()
    const lsData = this.safeLocalStorageService.getItem(this.key) ? JSON.parse(this.safeLocalStorageService.getItem(this.key)) : null
    if (!subjData && lsData) {
      this.shopIdentity$.next(lsData)
    }
    return this.shopIdentity$.getValue()
  }

  setValue(data: Shop) {
    if (data) {
      this.safeLocalStorageService.setItem(this.key, JSON.stringify(data))
      this.shopIdentity$.next(data)
    } else {
      this.safeLocalStorageService.removeItem(this.key)
      this.shopIdentity$.next(null)
    }
  }

  clear() {
    this.safeLocalStorageService.removeItem(this.adminSetIdKey)
    this.safeLocalStorageService.removeItem(this.key)
    this.shopIdentity$.next(null)
  }

}
