import { CommonModule } from '@angular/common'
import { Component, Input } from '@angular/core'
import { loadingLayout } from '../../animations/loadingLayout.animation'
import { LoadingSpinnerComponent } from '../loading-spinner/loading-spinner.component'

@Component({
    selector: 'pf-simple-loading-overlay',
    templateUrl: './simple-loading-overlay.component.html',
    styleUrls: ['./simple-loading-overlay.component.scss'],
    animations: [loadingLayout],
    standalone: true,
    imports: [
      CommonModule,
      LoadingSpinnerComponent,
    ]
})
export class SimpleLoadingOverlayComponent {
  @Input() isLoading: boolean
  @Input() isFixed: boolean

  constructor(
  ) {
  }
}
