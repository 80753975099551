import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { map, switchMap, catchError, tap, mergeMap } from 'rxjs/operators'
import {
  CreateCampaignRequest,
  CreateCampaignFailure,
  CreateCampaignSuccess,
  CreateWebhookRequest,
  CreateWebhookFailure,
  CreateWebhookSuccess,
  ReadEventsRequest,
  ReadEventsFailure,
  ReadEventsSuccess,
  ReadCampaignRequest,
  ReadDisplayConfigsFailure,
  ReadDisplayConfigsSuccess,
  ReadCampaignFailure,
  ReadCampaignListFailure,
  ReadCampaignListSuccess,
  ReadCampaignSuccess,
  DeleteCampaignRequest,
  DeleteCampaignFailure,
  DeleteCampaignSuccess,
  UpdateCampaignRequest,
  UpdateCampaignFailure,
  UpdateCampaignSuccess,
  SetTemplateError,
  UpdatePluginConfigRequest,
  UpdatePluginConfigFailure,
  UpdatePluginConfigSuccess,
  UpdateDisplayConfigsRequest,
  UpdateDisplayConfigsSuccess,
  UpdateDisplayConfigsFailure,
  UpdateCampaignActiveRequest,
  UpdateCampaignActiveSuccess,
  UpdateCampaignActiveFailure,
  DuplicateCampaignRequest,
  DuplicateCampaignSuccess,
  DuplicateCampaignFailure,
  ReadCampaignWheelPatternsRequest,
  ReadCampaignWheelPatternsSuccess,
  ReadCampaignWheelPatternsError,
  ReadCampaignWheelThemesRequest,
  ReadCampaignWheelThemesSuccess,
  ReadCampaignWheelThemesError,
  ReadCampaignCBThemesRequest,
  ReadCampaignCBThemesSuccess,
  ReadCampaignCBThemesError,
  ReadCampaignNLThemesRequest,
  ReadCampaignNLThemesSuccess,
  ReadCampaignNLThemesError,
} from './campaign.actions'
import { of } from 'rxjs'
import { CampaignActionTypes } from './campaign.action-types'
import { Logger } from '../../core/services/logger.service'
import { CampaignDataService } from '../../core/services/data-services/campaign-data.service'
import { CampaignService } from '../../core/services/campaign.service'
import { ApiCampaignService } from '../../core/services/api/api-campaign.service'
import { ErrorsService } from '../../core/services/errors.service'
import { LogLabel } from '../../shared/models/logger/log-label.model'
import { Campaign } from '../../shared/models/campaign/campaign'
import { MatDialog } from '@angular/material/dialog'

@Injectable()
export class CampaignEffects {
  constructor(
    private actions$: Actions,
    private campaignService: CampaignService,
    private apiCampaignService: ApiCampaignService,
    private campaignDataService: CampaignDataService, // FIXME: This is temporary
    private errorService: ErrorsService,
    private dialogRef: MatDialog,
    private logger: Logger,
  ) {
  }

  readCampaignListRequest$ = createEffect(() => this.actions$.pipe(
    ofType(CampaignActionTypes.READ_CAMPAIGN_LIST_REQUEST),
    switchMap(() =>
      this.campaignService.getAllCampaigns().pipe(
        map(campaigns => new ReadCampaignListSuccess(campaigns)),
        catchError(err => of(new ReadCampaignListFailure(this.errorService.extractError(err)))),
      ),
    ),
  ))

  readCampaignRequest$ = createEffect(() => this.actions$.pipe(
    ofType(CampaignActionTypes.READ_CAMPAIGN_REQUEST),
    switchMap((action: ReadCampaignRequest) =>
      this.campaignService.getCampaignById(action.payload).pipe(
        tap(campaign => {
          // FIXME: In order to stop using store, we will first cooperate for a short time :)
          this.campaignDataService.set(campaign)
        }),
        map(campaign => new ReadCampaignSuccess(campaign)),
        catchError(err => of(new ReadCampaignFailure(this.errorService.extractError(err)))),
      ),
    ),
  ))

  readDisplayConfigsRequest$ = createEffect(() => this.actions$.pipe(
    ofType(CampaignActionTypes.READ_DISPLAY_CONFIGS_REQUEST),
    switchMap((action: ReadCampaignRequest) =>
      this.campaignService.getCampaignDisplayConfigs(action.payload).pipe(
        map(campaign => new ReadDisplayConfigsSuccess(campaign)),
        catchError(err => of(new ReadDisplayConfigsFailure(this.errorService.extractError(err)))),
      ),
    ),
  ))

  updateCampaignRequest$ = createEffect(() => this.actions$.pipe(
    ofType(CampaignActionTypes.UPDATE_CAMPAIGN_REQUEST),
    switchMap((action: UpdateCampaignRequest) =>
      this.campaignService.saveCampaign(action.payload).pipe(
        map(campaign => new UpdateCampaignSuccess(campaign)),
        catchError(err => of(new UpdateCampaignFailure(this.errorService.extractCampaignError(err)))),
      )),
  ))

  updateCampaignActiveRequest$ = createEffect(() => this.actions$.pipe(
    ofType(CampaignActionTypes.UPDATE_CAMPAIGN_ACTIVE_REQUEST),
    switchMap((action: UpdateCampaignActiveRequest) =>
      this.campaignService.setCampaignActive(action.payload.id, action.payload.state).pipe(
        map(campaign => new UpdateCampaignActiveSuccess(campaign)),
        tap(() => {
          this.logger.log(LogLabel.Default, `${action.type}:dialogRef: `, this.dialogRef)
          this.dialogRef.closeAll()
        }),
        catchError(err => of(new UpdateCampaignActiveFailure(this.errorService.extractCampaignError(err)))),
      )),
  ))

  createCampaignRequest$ = createEffect(() => this.actions$.pipe(
    ofType(CampaignActionTypes.CREATE_CAMPAIGN_REQUEST),
    switchMap((action: CreateCampaignRequest) => this.campaignService.createCampaign(action.payload).pipe(
      map(payload => new CreateCampaignSuccess(payload)),
      tap(() => this.dialogRef.closeAll()),
      tap((successAction: CreateCampaignSuccess) => this.campaignService.navigateToCampaign(successAction.payload.id)),
      catchError(err => of(new CreateCampaignFailure(this.errorService.extractCampaignError(err)))),
    )),
  ))

  updateDisplayConfigsRequest$ = createEffect(() => this.actions$.pipe(
    ofType(CampaignActionTypes.UPDATE_DISPLAY_CONFIGS_REQUEST),
    switchMap((action: UpdateDisplayConfigsRequest) => this.campaignService.saveCampaignDisplayConfigs(action.payload.campaignId, action.payload.items).pipe(
      mergeMap(payload => [new UpdateDisplayConfigsSuccess(payload), new ReadCampaignRequest(action.payload.campaignId)]),
      catchError(err => of(new UpdateDisplayConfigsFailure(this.errorService.extractError(err)))),
    )),
  ))

  deleteCampaignRequest$ = createEffect(() => this.actions$.pipe(
    ofType(CampaignActionTypes.DELETE_CAMPAIGN_REQUEST),
    map((action: DeleteCampaignRequest) => action.payload),
    switchMap(campaignId =>
      this.campaignService.removeCampaign(campaignId)
        .pipe(
          map(() => new DeleteCampaignSuccess(campaignId)),
          tap(() => this.dialogRef.closeAll()),
          catchError(err => of(new DeleteCampaignFailure(this.errorService.extractCampaignError(err)))),
        ),
    ),
  ))

  duplicateCampaignRequest$ = createEffect(() => this.actions$.pipe(
    ofType(CampaignActionTypes.DUPLICATE_CAMPAIGN_REQUEST),
    map((action: DuplicateCampaignRequest) => action.payload),
    switchMap(campaign =>
      this.campaignService.duplicateCampaign(campaign.id, campaign.name)
        .pipe(
          map((_campaign: Campaign) => new DuplicateCampaignSuccess(_campaign)),
          tap(() => this.dialogRef.closeAll()),
          catchError(err => of(new DuplicateCampaignFailure(this.errorService.extractCampaignError(err)))),
        ),
    ),
  ))

  updatePluginConfigRequest$ = createEffect(() => this.actions$.pipe(
    ofType(CampaignActionTypes.UPDATE_PLUGIN_CONFIG_REQUEST),
    map((action: UpdatePluginConfigRequest) => action.payload),
    switchMap(payload => this.campaignService.updatePluginConfig(
      payload.campaignId,
      payload.type,
      payload.config,
      payload.active,
      payload.capture_configs,
      payload.capture_source,
      payload.data_sources,
      payload.capture_type,
      payload.sms_keyword,
      payload.products,
      payload.sync_with_shopify,
      payload.product_filter,
      payload.unlayer_email_template,
      payload.slices,
      payload.reward,
      ).pipe(
      mergeMap(res => [new UpdatePluginConfigSuccess(res), new ReadCampaignRequest(payload.campaignId)]),
      catchError((err: any): any => this.errorService.isTemplateValidationError(err)
        ? of(new SetTemplateError(this.errorService.extractTemplateValidationError(err)))
        : of(new UpdatePluginConfigFailure(this.errorService.extractError(err))),
      )),
    ),
  ) as any)

  createWebhookRequest$ = createEffect(() => this.actions$.pipe(
    ofType(CampaignActionTypes.CREATE_WEBHOOK_REQUEST),
    switchMap((action: CreateWebhookRequest) => this.campaignService.createCaptureWebhook(action.payload).pipe(
      map(res => new CreateWebhookSuccess({ res, campaignId: action.payload })),
      catchError(err => of(new CreateWebhookFailure(this.errorService.extractError(err)))),
    )),
  ))

  readEventsRequest$ = createEffect(() => this.actions$.pipe(
    ofType(CampaignActionTypes.READ_EVENTS_REQUEST),
    switchMap((action: ReadEventsRequest) => this.campaignService.fetchEvents(action.payload).pipe(
      map(res => {
        return new ReadEventsSuccess(res)
      }),
      catchError(err => of(new ReadEventsFailure(this.errorService.extractError(err)))),
    )),
  ))

  readCampaignWheelPatternsRequest$ = createEffect(() => this.actions$.pipe(
    ofType(CampaignActionTypes.READ_CAMPAIGN_WHEEL_PATTERNS_REQUEST),
    switchMap((action: ReadCampaignWheelPatternsRequest) => this.apiCampaignService.getDefaultWheelPatterns().pipe(
      map(res => {
        return new ReadCampaignWheelPatternsSuccess(res)
      }),
      catchError(err => of(new ReadCampaignWheelPatternsError(this.errorService.extractError(err)))),
    )),
  ))

  readCampaignWheelThemesRequest$ = createEffect(() => this.actions$.pipe(
    ofType(CampaignActionTypes.READ_CAMPAIGN_WHEEL_THEMES_REQUEST),
    switchMap((action: ReadCampaignWheelThemesRequest) => this.apiCampaignService.getDefaultWheelThemes().pipe(
      map(res => {
        return new ReadCampaignWheelThemesSuccess(res)
      }),
      catchError(err => of(new ReadCampaignWheelThemesError(this.errorService.extractError(err)))),
    )),
  ))

  readCampaignCBThemesRequest$ = createEffect(() => this.actions$.pipe(
    ofType(CampaignActionTypes.READ_CAMPAIGN_CB_THEMES_REQUEST),
    switchMap((action: ReadCampaignCBThemesRequest) => this.apiCampaignService.getDefaultCBThemes().pipe(
      map(res => {
        return new ReadCampaignCBThemesSuccess(res)
      }),
      catchError(err => of(new ReadCampaignCBThemesError(this.errorService.extractError(err)))),
    )),
  ))

  readCampaignNLThemesRequest$ = createEffect(() => this.actions$.pipe(
    ofType(CampaignActionTypes.READ_CAMPAIGN_NL_THEMES_REQUEST),
    switchMap((action: ReadCampaignNLThemesRequest) => this.apiCampaignService.getDefaultNewsletterThemes().pipe(
      map(res => {
        return new ReadCampaignNLThemesSuccess(res)
      }),
      catchError(err => of(new ReadCampaignNLThemesError(this.errorService.extractError(err)))),
    )),
  ))

}
