import {Injectable} from '@angular/core'
import * as _ from 'lodash'
import {UserInfo} from '../../store/user/user.state'
import {UserShopType} from '../models/user/user-shop-type.model'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { UserService } from '../../core/services/user.service'
import { ApiService } from '../../core/services/api/api.service'

@Injectable()
export class SMSService {

  // eligibleCountryCodes = ['US', 'CA']

  constructor(
    private apiService: ApiService,
    private userService: UserService,
  ) { }

  /**  @deprecated use userIsEligible instead */
  userIsSMSEligible(): boolean {
    const userInfo = this.userService.userInfo
    return _.get(userInfo, 'sms_marketing_account.valid', false)
  }

  userIsEligible(userInfo: UserInfo): boolean {
    // const hasSMSAccount = _.get(userInfo, 'sms_marketing_account.valid', false)
    const isShopifyShop = _.get(userInfo, 'shop.type') === UserShopType.ShopifyShop
    // const isInEligibleCountry = this.eligibleCountryCodes.includes(_.get(userInfo, 'address.country.code'))
    // return isShopifyShop && isInEligibleCountry
    return isShopifyShop
  }

  onboardingPassed(): Observable<boolean> {
    return this.apiService.get('/v1/me/compliance_officer_profile').pipe(
      map(officerProfile => !!(officerProfile?.completed || officerProfile?.single_opt_in_terms_completed))
    )
  }
}
