import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterbarNavigationComponent } from './footerbar-navigation.component';



@NgModule({
  declarations: [FooterbarNavigationComponent],
  imports: [
    CommonModule
  ],
  exports: [FooterbarNavigationComponent]
})
export class FooterbarNavigationModule { }
