import {CouponCode} from '../../pages/coupon-codes/models/coupon-code'
import { UserShop } from '../../shared/models/user/user-shop.model'
import { UserSubscription } from '../../shared/models/user/user-subscription.model'
import { UserRole } from '../../shared/models/user/user.model'
import { EmailBrandingConfig } from '../../shared/modules/email-onboarding/models/email-onboarding.model'
import { ContactsResponse } from '../../pages/subscribers/models/subscribers-page.model'
import { PlatformOnboardingCall, PlatformOnboardingStage, PlatformOnboardingStatus, PlatformOnboardingUnlockedFlow } from '../../shared/models/platform-onboarding.model'
import { StripeSubscription, StripeSubscriptionItem } from '../../pages/cart/models/cart-models'
import { JotformAnswer, JotformSubmission } from '../../core/services/api/api-jotform.service'

export interface UserProfileChannel {
  name: string,
  url: string
}

export interface UserProfile {
  first_name: string
  last_name: string
  company: string
  phone: string
  channels?: UserProfileChannel[]
}

export interface UserInfoPredictedUsage {
  /* If we are in a trial these numbers will be for the duration of the trial */
  predicted_current_month_usage: number,

  /* While in a trial this number will be for the upcoming billing period. If not in a trial then this should be the same as predicted_current_month_usage*/
  paid_period_predicted_usage: number
}

export enum SMSAppSettingsShortURL {
  SiteName = 'site_name',
  Default = 'default'
}

export enum SMSAppSettingsNewCustomerTCPAFlowType {
  Disabled = 'disabled',
  AcceptsMarketing = 'accepts_marketing',
  AllCustomers = 'all'
}

export const smsAppAutoReloadIncrementOptions = [10, 20, 40, 80, 120, 200, 500, 1000, 2000, 4000, 6000, 8000, 10000]
export const smsAppAutoReloadThresholdOptions = [10, 20, 50, 100, 200, 500, 1000, 1500, 2000, 2500, 3000]
export const smsAppAutoReloadCappedAmountOptions = [100, 200, 500, 1000, 2000, 5000, 10000, 15000, 20000, 25000]

export interface SMSAppSettings {
  short_url: SMSAppSettingsShortURL
  timezone: string,
  new_customer_tcpa_flow_type: SMSAppSettingsNewCustomerTCPAFlowType

  automation_timeout_enabled: boolean
  automation_timeout_value: number
  automation_timeout_unit: 'minutes' | 'hours' | 'days'

  auto_reload_credits: boolean
  auto_reload_credits_capped_amount: number
  auto_reload_credits_increment: number
  auto_reload_credits_threshold: number
}

export interface UserUsageInfo {
  auth_provider: string,
  auth_provider_id: string,
  current_month_usage: number,
  email: string,
  id: string,
  over_account_usage_limit: boolean,
  subscription: UserSubscription
}

export interface UserInfo {
  id: string,
  email: string,
  metadata: {
    email_branding_preset: EmailBrandingConfig['email_branding_preset']
  }
  auth_provider: string,
  auth_provider_id: string
  // current_month_usage: number,
  country_code: string,
  owned_shopify_stores: string[],
  access_token: string
  promo_eligible?: boolean
  profile: UserProfile
  shop: UserShop
  shop_user: any,
  subscription: UserSubscription // TODO: this should be inside UserUsageInfo
  role: UserRole
  remove_branding: boolean,
  onboarding: {
    sign_up_flow_completed: boolean,
    plugin_sending_data: boolean,
    plugin_installed: boolean,
    created_at_least_one_campaign: boolean,
    chose_plan: boolean
  },
  sms_app_settings: SMSAppSettings
  sms_marketing_account?: {
    valid: boolean
    number: string
    toll_free_number: string
    long_code_number: string

    credits_used: number
    cost_per_sms_credit: number
    sms_credits_purchased_cost: number
    sms_credits_purchased: number
    sms_credits_included: number
  }
  sites?: {
    id: string
    type: string
    status: boolean
    domain: string
    created_at: string
    updated_at: string
  }[]
  created_at: string
  sample_orders_active?: boolean
  upsell_eligible?: boolean
  platform_onboarding_call?: PlatformOnboardingCall
  email_receipts?: boolean
  platform_onboarding_locked?: boolean
  platform_onboarding_locked_reason?: string
  platform_onboarding_stage?: PlatformOnboardingStage
  platform_onboarding_status?: PlatformOnboardingStatus
  platform_onboarding_unlocked_flow?: PlatformOnboardingUnlockedFlow
  contacts?: ContactsResponse
  show_pricing_update_modal?: boolean
  show_email_scheduled_modal?: boolean
  new_pricing_eligible?: boolean
  sent_large_broadcast_emails?: boolean
  allow_sending_sms_to_shopify_subscribers?: boolean
  custom_plan_invoice?: boolean
  custom_plan_invoice_paid?: boolean
  custom_plan_invoice_price?: number
  custom_plan_invoice_tier?: string
  custom_plan_invoice_link?: string
  custom_plan_invoice_due_date?: string
  custom_plan_trial?: boolean
  custom_plan_trial_end_date?: string
  custom_plan_price_request?: boolean
  custom_plan_price_request_created_at?: string
  stripe_billing?: {
    customer_id?: string
    subscription_id?: string
    subscription?: StripeSubscription
    // subscriptions?: {[key: string]: JotformAnswer}
  }
}

export interface UserOrders {
  orders: any[]
  total_count: number
  total_pages: number
}

export interface UserState {
  info: UserInfo,
  promo: object,
  usage: {
    percentage: number,
  },
  error: string,
  sites: string[]
  coupons: CouponCode[]
  orders: UserOrders
}

export const initialUserShopState = {
  id: undefined,
  store_url: undefined,
  type: undefined,
}

export const initialUserState: UserState = {
  promo: null,
  info: undefined,
  usage: {percentage: null},
  error: null,
  sites: [],
  coupons: [],
  orders: {
    orders: [],
    total_count: 0,
    total_pages: 0,
  },
}

