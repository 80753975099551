const pl = {
  Quantity: 'Ilość',
  Size: 'Rozmiar',
  Color: 'Kolor',
  Material: 'Materiał',
  Style: 'Styl',
  Savings: 'Oszczędności',
  Subtotal: 'Suma częściowa',
  Shipping: 'Wysyłka',
  Taxes: 'Podatki',
  Total: 'Łącznie',
  Free: 'Bezpłatny',
  Buy: 'Kup',
  'Pay Now': 'Zapłać teraz',
  'Take the Deal': 'Weź ofertę',
  'Add Now': 'Dodaj teraz',
  'You will be charged immediately': 'Obciążymy Cię natychmiast',
  'Decline Offer': 'Odrzuć ofertę',
  'No Thanks': 'Nie, dziękuję',
  '{price}': '{cena}',
  'Sold Out': 'Wyprzedane',
  'see more': 'zobacz więcej',
  less: 'mniej',
};
export {pl};
