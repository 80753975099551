import { Injectable } from "@angular/core"
import { CookieService } from "ngx-cookie-service"

@Injectable({
  providedIn: 'root',
})
export class SafeLocalStorageService {
  public localStorageAvailable: boolean

  constructor(
    private cookieService: CookieService,
  ) {
    try {
      localStorage.setItem('test', 'test')
      localStorage.removeItem('test')
      this.localStorageAvailable = true
    } catch (e) {
      this.localStorageAvailable = false
    }
  }

  public getItem(key: string): string {
    let item
    if (this.localStorageAvailable) {
      item = localStorage.getItem(key)
    } else {
      item = this.cookieService.get(key)
    }
    // Can't return undefined, otherwise JSON.parse will fail
    return item && typeof item !== 'undefined' && item !== 'undefined' ? item : null
  }

  public setItem(key: string, value: string): void {
    if (this.localStorageAvailable) {
      localStorage.setItem(key, value)
    } else {
      this.cookieService.set(key, value, 365)
    }
  }

  public removeItem(key: string): void {
    if (this.localStorageAvailable) {
      localStorage.removeItem(key)
    } else {
      this.cookieService.delete(key)
    }
  }

  public clear(): void {
    if (this.localStorageAvailable) {
      localStorage.clear()
    } else {
      // better not to do that with cookies
      // this.cookieService.deleteAll()
    }
  }

  public key(index: number): string {
    if (this.localStorageAvailable) {
      return localStorage.key(index)
    } else {
      // not applicable for cookies
      return null
    }
  }

  public get length(): number {
    if (this.localStorageAvailable) {
      return localStorage.length
    } else {
      // not applicable for cookies
      return null
    }
  }

}
