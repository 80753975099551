import { Injectable } from '@angular/core'
import { ConfirmModalComponent, ConfirmModalConfig } from '../../shared/components/modals/confirm.modal/confirm.modal.component'
import { SegmentAnalyticsService } from '../../shared/services/segment-analytics.service'
import { MatDialog } from '@angular/material/dialog'

@Injectable({providedIn: 'root'})

export class UpdateNotifierService {

  modalOpened = false

  constructor(
    private dialog: MatDialog,
    private segmentAnalyticsService: SegmentAnalyticsService,
  ) {
  }

  displayRefreshPrompt() {
    if (!this.modalOpened) {
      this.modalOpened = true
      this.segmentAnalyticsService.track('Displayed Refresh Required Modal')
      const dialogRef = this.dialog.open(ConfirmModalComponent, {
        width: '560px',
        data: {
          title: 'Refresh required',
          text: 'We have updated our app. Please refresh the page to continue.',
          acceptButton: {
            text: 'Refresh',
            classes: `pf-button filled green`
          }
        } as ConfirmModalConfig,
      })

      dialogRef.afterClosed().subscribe(reload => {
        this.modalOpened = false
        if (reload === true) {
          this.segmentAnalyticsService.track('Clicked Refresh Btn in Modal')
            .then(() => {
              window.location.reload()
            })
        }
      })
    }
  }

}
