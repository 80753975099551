import { Injectable, Injector, OnDestroy } from '@angular/core'
import { BehaviorSubject, Subscription } from 'rxjs'
import { Shop, ShopGroup } from '../shop-switch.model'
import { ShopifyShopTokenService } from '../../../../../core/services/shopify-shop-token.service'
import { SafeLocalStorageService } from '../../../../../core/services/safe-local-storage.service'
import { UserService } from '../../../../../core/services/user.service'
import { ApiService } from '../../../../../core/services/api/api.service'
// import { mockResponse } from './mock-response'

@Injectable({providedIn: 'root'})
export class ShopSwitchService implements OnDestroy {
  private subscription = new Subscription()
  // Key to store shopify's user id
  public idKey = 'one_shopify_store_user_id'
  // List of associated stores
  public userShops$ = new BehaviorSubject<ShopGroup[]>(null)
  // Currently active store
  public selectedShop$ = new BehaviorSubject<Shop>(null)
  // A workaround to get access to UserService without circular dependency
  private _userService: UserService

  constructor(
    private apiService: ApiService,
    private injector: Injector,
    private shopifyShopTokenService: ShopifyShopTokenService,
    private safeLocalStorageService: SafeLocalStorageService,
  ) {
  }

  private get userService(): UserService {
    if (!this._userService) {
      this._userService = this.injector.get(UserService);
    }
    return this._userService;
  }

  /**
   * Get currently active shop id based on shopifyShopTokenService token
   * @returns {string} one_store_id
   */
  public getShopId(): string | null {
    const shopIdentity = this.shopifyShopTokenService.getValue()
    const shops = this.userShops$.getValue()
    if (shops && shops.length && shopIdentity) {
      const shop = shops.flatMap(el => el.shops).find(s => s.one_access_token === shopIdentity.one_access_token)
      if (shop) {
        return shop.one_store_id
      }
    }
    return null
  }

  /**
   * Get currently active shop based on shopifyShopTokenService token
   * @returns {Shop} currently active shop
   */
  public getShop(): Shop | null {
    const shopIdentity = this.shopifyShopTokenService.getValue()
    const shops = this.userShops$.getValue()
    if (shops && shops.length && shopIdentity) {
      const shop = shops.flatMap(el => el.shops).find(s => s.one_access_token === shopIdentity.one_access_token)
      if (shop) {
        return shop
      }
    }
    return null
  }

  /**
   * Fetches associated stores into userShops$ BehaviorSubject
   * Sets currently active store into selectedShop$ BehaviorSubject
   * @returns void
   */
  public getStores(): void {
    // Check if shopify's user id was set
    const storeUserId = this.safeLocalStorageService.getItem(this.shopifyShopTokenService.adminSetIdKey) || this.safeLocalStorageService.getItem(this.idKey)
    // Can't proceed without shopify's user id
    if (!storeUserId) {
      return
    }
    // Get associated stores
    this.subscription.add(
      this.apiService.get(`/v1/me/stores/${storeUserId}`).subscribe(res => this.storesHandler(res))
    )

    // FIXME: MOCK DATA
    // this.storesHandler(mockResponse)
  }

  /**
   * Groups stores by one_organization_id
   * @param {Shop[]} stores associated stores
   * @returns {ShopGroup[]} grouped stores
   */
  private groupStores(stores: Shop[]): ShopGroup[] {
    const otherGroupName = 'Other'
    // group stored by one_organization_id and add name to each group from one_organization_name
    // all shores without one_organization_id will be grouped under 'otherGroupName' group
    const groupedShops = stores.reduce((acc, shop) => {
      const organizationId = shop.one_organization_id
      const organizationName = shop.one_organization_name
      const group = acc.find(g => g.id === organizationId)
      if (group) {
        group.shops.push(shop)
      } else {
        acc.push({
          name: organizationName || otherGroupName,
          id: organizationId,
          shops: [shop]
        })
      }
      return acc
    }, [])
    // sort shops in each group by one_store_name
    groupedShops.forEach(group => group.shops.sort((a, b) => a.shopify_store_name.localeCompare(b.shopify_store_name)))
    // keep Other group at the end
    groupedShops.sort((a, b) => a.name === otherGroupName ? 1 : b.name === otherGroupName ? -1 : 0)
    // If there are no other groups than 'otherGroupName', remove the name so it won't be rendered
    if (groupedShops.length === 1 && groupedShops[0].name === otherGroupName) {
      groupedShops[0].name = ''
    }

    return groupedShops
  }

  /**
   * Handles response from getStores() method
   * @param {Shop[]} res associated stores
   * @returns void
   */
  private storesHandler(res: Shop[]): void {
    if (res && res.length) {
      // get token for active store
      const shopIdentity = this.shopifyShopTokenService.getValue()
      // group stores by one_organization_id
      const groupedShops = this.groupStores(res)
      // set shops list
      this.userShops$.next(groupedShops)
      // find active store by token match
      const shop = res?.find(s => s?.one_access_token === shopIdentity?.one_access_token)
      // check if token was set to define currently active store
      if (shop) {
        this.setActiveShop(shop)
      }
      if (shopIdentity) {
        const shop = res.find(s => s?.one_access_token === shopIdentity?.one_access_token)
        if (shop) {
          shop.hidden = true
        }
      // If there is no active store, make sure the shop that user is currently on is set to hidden as well
      } else {
        const userInfoShopId = this.userService.userInfo?.shop?.id
        const shop = res.find(s => s.one_store_id === userInfoShopId)
        if (shop) {
          shop.hidden = true
        }
      }
      // hide groups that have only one store and that store is hidden
      groupedShops.forEach(group => {
        group.hidden = !group.shops.some(s => typeof s?.hidden === 'undefined')
      })
      // FIXME: temp
      // res.forEach(shop => {
      //   // shop.hidden = true
      //   if (['ihor-production-plan'].includes(shop.shopify_store_name)) {
      //     shop.one_store_role = 'edit'
      //   } else if (['m1designer-001'].includes(shop.shopify_store_name)) {
      //     shop.one_store_role = 'view'
      //   }
      // })
    }
  }

  /**
   * Sets currently active store into selectedShop$ BehaviorSubject
   * @param {Shop} _shop currently active store
   * @returns void
   */
  public setActiveShop(_shop: Shop) {
    const shops = this.userShops$.getValue()
    const shop = shops?.flatMap(el => el.shops)?.find(s => s.one_store_id === _shop.one_store_id)
    if (shop) {
      this.shopifyShopTokenService.setValue(shop)
      this.selectedShop$.next(shop)
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }
}
