import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { select, Store } from '@ngrx/store'
import { forkJoin, Observable } from 'rxjs'
import { StoreState } from '../../store/store.state'
import { getUserChoseInitialPlanCompleted, getUserInfo } from '../../store/user/user.selectors'
import { filter, finalize, map } from 'rxjs/operators'
import { first } from 'rxjs/operators'
import { HideLoading, ShowLoading } from '../../store/loading/loading.actions'
import { LoadingLabel } from '../../shared/models/loading/loading-label'
import { Logger } from '../services/logger.service'
import { LogLabel } from '../../shared/models/logger/log-label.model'
import _ from 'lodash'
import { UserInfo } from '../../store/user/user.state'
import { GettingStartedStatus } from '../../shared/models/getting-started/getting-started-status.model'
import { getGettingStartedStatus } from '../../store/getting-started/getting-started.selectors'
import { ShopifySubscribersApiService } from '../../pages/subscribers/services/shopify-subscribers-api.service'

@Injectable()
export class PhoneVerifiedGuard  {
  constructor(
    private store: Store<StoreState>,
    private logger: Logger,
    private router: Router,
    private shopifySubscribersApiService: ShopifySubscribersApiService,
  ) {
  }

  check() {
    this.store.dispatch(new ShowLoading(LoadingLabel.PhoneVerifiedGuard))
    return forkJoin([this.userInfo$, this.gettingStartedStatus$, this.userChoseInitialPlanCompleted$]).pipe(
      map(([userInfo, status, choseInitialPlan]) => {
        this.logger.log(<LogLabel>'phone-verified-guard', 'userInfo: ', userInfo)
        const isShopifyShop = userInfo.shop?.type === 'ShopifyShop'

        // If plan is not chosen and onboarding status is completed - user is returning
        const userType = !_.get(status, 'completed', false) ? 'new' : 'returning'
        // if new user hasn't completed pre plans survey redirect them there
        if (!choseInitialPlan && isShopifyShop && userType === 'new' && !_.get(status, `pre_plans_survey_${userType}.completed`, false)) {
          this.router.navigateByUrl(`/onboarding/signup/${userType}/welcome`).then(() => {
            this.store.dispatch(new HideLoading(LoadingLabel.ChoseInitialPlanCompleteGuard))
          })
          return false
        }
        // if account has shopify user id but is not verified/skipped redirect to phone verification page
        if (isShopifyShop && userInfo.shop_user?.id && !!userInfo.shop_user?.shopify_shop_user && !userInfo.shop_user?.shopify_shop_user?.sms_verified && !['skipped_by_frontend'].includes(userInfo.shop_user?.shopify_shop_user?.sms_verified_reason)) {
          if (userType === 'new') {
            this.router.navigateByUrl('/onboarding/signup/new/phone-verification')
            return false
          } else {
            this.router.navigateByUrl('/onboarding/phone-verification')
            return false
          }
        }
        return true
      }),
      finalize(() => this.store.dispatch(new HideLoading(LoadingLabel.PhoneVerifiedGuard))),
    )
  }

  get userInfo$(): Observable<UserInfo> {
    return this.store.pipe(
      select(getUserInfo),
      filter(info => info !== undefined && !!info.created_at),
      first(),
    )
  }

  get gettingStartedStatus$(): Observable<GettingStartedStatus> {
    return this.store.pipe(
      select(getGettingStartedStatus),
      filter(next => next !== undefined),
      filter(status => !!status),
      first(),
    )
  }

  get userChoseInitialPlanCompleted$(): Observable<boolean> {
    return this.store.pipe(
      select(getUserChoseInitialPlanCompleted),
      filter(completed => completed !== undefined),
      first(),
    )
  }

  canActivate(): Observable<boolean> {
    return this.check()
  }

  canActivateChild(): Observable<boolean> {
    return this.check()
  }
}
