<div class="ThemeCarousel-List conveythis-no-translate" [ngClass]="customClass">
  <div *ngFor="let item of themes"
       class="ThemeCarousel-ListItem"
       [class._selected]="item.selected"
       [class._saved]="item.identifier === savedTheme"
       (click)="selectTheme(item)">
    <div *ngIf="item.identifier !== savedTheme; else saveLabel"
         class="ThemeCarousel-ListItem-Title">{{item.name}}</div>
    <ng-template #saveLabel>
      <div class="ThemeCarousel-ListItem-Title">Current Theme</div>
    </ng-template>
    <div *ngIf="item.identifier === savedTheme" class="ThemeCarousel-ListItem-Saved">
      <one-icon name="checkmark"></one-icon>
    </div>
    <div *ngIf="item.premium" class="ThemeCarousel-ListItem-Premium">
        <span>PLUS</span>
        <img src="/app_assets/icons/lock-icon.svg" alt="">
    </div>
<!--    <div class="ThemeCarousel-ListItem-Icon" *ngIf="item.premium">PREMIUM</div>-->
    <img class="ThemeCarousel-ListItem-Image" [attr.src]="item.preview_image_url" alt="">
  </div>
</div>
