import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router'
import { Observable } from 'rxjs/internal/Observable'
import { ShopifyService } from '../services/shopify.service'
import useAppBridge from "@shopify/app-bridge"
import { Redirect } from '@shopify/app-bridge/actions'
import { environment } from '../../../environments/environment'
import { SafeLocalStorageService } from '../services/safe-local-storage.service'

@Injectable()
export class ShopifyLoginRedirectGuard  {
  constructor(
    private router: Router,
    private shopifyService: ShopifyService,
    private safeLocalStorageService: SafeLocalStorageService,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (
      window.location.hostname === 'shopify.one.store' ||
      window.location.hostname === 'shopify-staging.one.store' ||
      window.location.hostname === 'shopify-develop.one.store' ||
      window.location.hostname === 'prooffactor.local'
    ) {
      const storedHost = this.safeLocalStorageService.getItem('one_c_host')
      const storedToken = this.safeLocalStorageService.getItem('one_c_token')
      // Workaround to sign in user in incognito mode
      if (storedHost && storedToken && !this.shopifyService.isEmbedded) {
        if (!window.location.search.includes('redirectForStandalone')) {
          return true
        }
      }
      // app.prooffactor.com
      // shopify.one.store
      if (window.location.pathname.includes('register')) {
        this.router.navigate(['/register/shopify'])
      } else if (window.location.pathname.includes('login')) {
        this.router.navigate(['/login/shopify'])
      } else {
        if (this.shopifyService.isEmbedded) {
          // For embedded app in incognito mode when page refresh causes redirect to login page
          const hmac = new URLSearchParams(window.location.search).get('hmac')
          const shop = new URLSearchParams(window.location.search).get('shop')
          const url = `/api/auth/shopify?hmac=${hmac}&shop=${shop}&top_level=true`
          const hostUrl = `admin.shopify.com/store/${shop.replace('.myshopify.com', '')}`
          const host = window.btoa(hostUrl);

          const app = useAppBridge({
            apiKey: environment.shopify.clientId,
            host: host,
          })

          var normalizedLink = document.createElement('a');
          normalizedLink.href = url;

          Redirect.create(app).dispatch(Redirect.Action.REMOTE, normalizedLink.href);
        } else {
          if (window.location.search.includes('redirectForStandalone')) {
            this.router.navigate(['/login/shopify'], { queryParams: { redirectForStandalone: true}})
          } else {
            this.router.navigate(['/login/shopify'])
          }
        }
      }
      return false
    } else {
      return true
    }
  }
}
