import { CouponCodeType } from '../../coupon-codes/models/coupon-code'
import { RewardsType } from '../../coupon-codes/models/rewards-model';

export interface GiftCardSummary {
  code: string,
  randomString?: string,
  discount: string,
  currency: string
}

export enum GiftCardStatus {
  all = 'all',
  allExceptArchived = 'all_except_archived',
  archived = 'archived',
  active = 'active',
  disabled = 'disabled',
  expired = 'expired'
}

export interface GiftCard {
  code_length: number
  created_at: string
  expires_after_days: number
  expires_after_enabled: boolean
  expires_after_years: number
  id?: string
  prefix_code: string
  reward_type: RewardsType.gift_card
  status: GiftCardStatus
  title: string
  updated_at: string
  type: CouponCodeType
  value: 10,
  num_awarded?: number
  num_redeemed?: number
  num_redemption_rate?: number
  total_revenue?: number
  master_code?: string
}

