export enum JFHelperType {
    ControlHead = 'control_head',
    ControlText = 'control_text',
}

export enum JFQuestionType {
    ControlTextarea = 'control_textarea',
    ControlNumber = 'control_number',
    ControlCheckbox = 'control_checkbox',
    ControlTextbox = 'control_textbox',
    ControlFileUpload = 'control_fileupload',
    ControlFullname = 'control_fullname',
    ControlEmail = 'control_email',
    ControlWidget = 'control_widget',
    ControlPhone = 'control_phone',

    ControlRadio = 'control_radio',
    ControlImageChoice = 'control_imagechoice',

    ControlPrefilledUserData = 'control_prefilled_user_data',
}

export enum JFWidgetType {
    Field = 'field'
}
