import { PopupAutoTriggers, PopupButtonTriggers } from '../campaign/popup-triggers.model'
import { FormNameConfig } from '../campaign/coupon-box-notification.model';
import { RewardsType } from '../../../pages/coupon-codes/models/rewards-model';
import { CouponCodeType } from '../../../pages/coupon-codes/models/coupon-code';

export interface WidgetFortuneWheelComponentParams {
  visible: boolean,
  slices: WidgetFortuneWheelSlice[],
  texts: WidgetFortuneWheelTextsConfig,
  form_config: WidgetFortuneWheelFormConfig,
  display: WidgetFortuneWheelDisplayConfig,
  theme: WidgetFortuneWheelThemeConfig,
  advanced: WidgetFortuneWheelAdvancedConfig,
  themes: WidgetFortuneWheelTheme[],
  resultId: string,
  error: object,
  onSpin: Function,
  onClose: Function,
  previewMode?: boolean,
  previewIframe?: boolean,
  previewModeLoss?: boolean,
  detectedCountry?: string,
  stepName?: string,
  previewModeDimensions?: {
    width: number,
    height: number,
  }
  id?: string,
  reference_token?: string,
}

export interface WidgetFortuneWheelSlice {
  reward: {
    id: string,
    reward_type: RewardsType,
    type: CouponCodeType,
  }
  id: string
  gravity: string | number
  index: number
  label: string
  type: WidgetFortuneWheelSliceType
  percentage: string | number
}

export enum WidgetFortuneWheelSliceType {
  REWARD = 'coupon_code',
  NO_PRIZE = 'no_prize',
}

export enum WidgetFortuneWheelStatus {
  SPINNING = 'spinning',
  STOPPING = 'stopping',
  WAITING = 'waiting',
  FINISHED = 'finished',
  HIDDEN = 'hidden',
}

export interface WidgetFortuneWheelFormConfig {
  name?: FormNameConfig,
  email?: {
    order: number,
    placeholder: string,
    errors: {
      blank: string,
      invalid: string,
      duplicated: string
    },
    duplicate_submissions: {
      allow_duplicating: boolean,
      subscription_timeout: number
    }
  },
  phone?: {
    order: number,
    input_placeholder: {
      text: string,
      enabled: boolean
    },
    country: {
      placeholder: {
        text: string,
        enabled: boolean
      },
      default: string,
      flag_enabled: boolean
    },
    tcpa?: {
      enabled: boolean
      mob_quick_subscribe: boolean
      mob_quick_subscribe_text: string
    },
    errors: {
      blank: string,
      invalid: string,
      duplicated: string
    },
    duplicate_submissions: {
      allow_duplicating: boolean,
      subscription_timeout: number
    }
  }
}

export interface WidgetFortuneWheelTextsConfig {
  idle_state: {
    title: string,
    description: string,
    button: string,
  },
  sms_state: {
    title: string
    description: string
    back: string
  },
  coupon_state: {
    title: string,
    description: string,
    button: string,
    email_sent: string,
    footer: string,
  },
  no_prize_state: {
    title: string,
    description: string,
    button: string,
  },
  errors: {
    email_already_used: string,
    email_invalid: string,
    unknown_error: string
  },
  other: {
    email_placeholder: string,
    rules: {
      type: 'text' | 'html',
      value: string,
    },
    close_button: string,
    copied_message: string,
    fraud_protection: {
      fraud_protection_text: string
      show_fraud_protection: boolean
    }
  },
  terms: {
    text: string;
  }
}

export interface WidgetFortuneWheelDisplayConfig {
  desktop: {
    active: boolean,
    trigger_auto: PopupAutoTriggers
    trigger_button: PopupButtonTriggers
    reset_days: number
    reset_days_enabled: boolean
  },
  mobile: {
    active: boolean,
    trigger_auto: PopupAutoTriggers
    trigger_button: PopupButtonTriggers
    reset_days: number
    reset_days_enabled: boolean
  }
}

export enum WidgetFortuneWheelThemeIdentifier {
  default = 'default',
  beach_default = 'beach_default',
  beach_alt_1 = 'beach_alt_1',
  beach_alt_2 = 'beach_alt_2',
  black_friday_alt_1 = 'black_friday_alt_1',
  black_friday_alt_2 = 'black_friday_alt_2',
  black_friday_alt_3 = 'black_friday_alt_3',
  black_friday_extended = 'black_friday_extended',
  black_friday_extended_alt = 'black_friday_extended_alt',
  christmas = 'christmas',
  christmas_alt_1 = 'christmas_alt_1',
  christmas_alt_2 = 'christmas_alt_2',
  cyber_monday = 'cyber_monday',
  cyber_monday_alt_1 = 'cyber_monday_alt_1',
  cyber_week = 'cyber_week',
  cyber_week_alt_1 = 'cyber_week_alt_1',
  love_default = 'love_default',
  love_alt_1 = 'love_alt_1',
  love_alt_2 = 'love_alt_2',
  love_alt_3 = 'love_alt_3',
  lucky_default = 'lucky_default',
  lucky_alt_1 = 'lucky_alt_1',
  lucky_alt_2 = 'lucky_alt_2',
  moon_default = 'moon_default',
  moon_alt_1 = 'moon_alt_1',
  moon_alt_2 = 'moon_alt_2',
  ocean_alt_1 = 'ocean_alt_1',
  ocean_alt_2 = 'ocean_alt_2',
  ocean_alt_3 = 'ocean_alt_3',
  valentine_day_1 = 'valentine_day_1',
  valentine_day_2 = 'valentine_day_2',
}

export interface WidgetFortuneWheelThemeConfig {
  name?: string,
  identifier?: WidgetFortuneWheelThemeIdentifier,
  premium: boolean,
  variables: WidgetFortuneWheelThemeVariables,
  images: WidgetFortuneWheelThemeImages,
  css: string
}

export interface WidgetFortuneWheelAdvancedConfig {
  custom_js: string,
  custom_css: string,
}


export enum WidgetFortuneWheelThemeVariableName {
  animation_enabled = 'animation_enabled',
  overlay_bg = 'overlay_bg',
  wheel_bg = 'wheel_bg',

  general_font = 'general_font',

  popup_position = 'popup_position',

  title_color = 'title_color',
  title_size = 'title_size',
  title_bg_color = 'title_bg_color',
  title_weight = 'title_weight',

  subtitle_color = 'subtitle_color',
  subtitle_size = 'subtitle_size',
  subtitle_bg_color = 'subtitle_bg_color',
  subtitle_weight = 'subtitle_weight',

  text_color = 'text_color',
  text_size = 'text_size',
  text_bg_color = 'text_bg_color',
  text_weight = 'text_weight',

  slice_text_color = 'slice_text_color',
  slice_text_size = 'slice_text_size',
  slice_text_bg = 'slice_text_bg',
  slice_text_weight = 'slice_text_weight',
  slice_odd_text_color = 'slice_odd_text_color',
  slice_odd_text_size = 'slice_odd_text_size',
  slice_odd_text_bg = 'slice_odd_text_bg',
  slice_odd_text_weight = 'slice_odd_text_weight',

  error_color = 'error_color',
  error_background = 'error_background',

  input_field_color = 'input_field_color',
  input_field_size = 'input_field_size',
  input_field_background = 'input_field_background',
  input_field_weight = 'input_field_weight',
  input_placeholder_color = 'input_placeholder_color',
  input_placeholder_size = 'input_placeholder_size',

  button_color = 'button_color',
  button_size = 'button_size',
  button_bg = 'button_bg',
  button_weight = 'button_weight',
  button_disabled_color = 'button_disabled_color',
  button_hover_color = 'button_hover_color',
  button_disabled_bg = 'button_disabled_bg',
  button_hover_bg = 'button_hover_bg',
  close_button_color = 'close_button_color',
  close_button_bg_color = 'close_button_bg_color',
  close_button_style = 'close_button_style',
  close_button_size = 'close_button_size',

  cancel_link_color = 'cancel_link_color',
  background = 'background',
  wheel = 'wheel',
  pointer = 'pointer',
  wheel_logo = 'wheel_logo',
  wheel_logo_size = 'wheel_logo_size',
  background_size = 'background_size',
  logo_size = 'logo_size',

  result_title_color = 'result_title_color',
  result_title_size = 'result_title_size',
  result_title_bg_color = 'result_title_bg_color',
  result_title_weight = 'result_title_weight',

  result_footer_color = 'result_footer_color',
  result_footer_size = 'result_footer_size',
  result_footer_bg_color = 'result_footer_bg_color',
  result_footer_weight = 'result_footer_weight',

  description_color = 'description_color',
  description_size = 'description_size',
  description_bg_color = 'description_bg_color',
  description_weight = 'description_weight',

  code_field_color = 'code_field_color',
  code_field_size = 'code_field_size',
  code_field_weight = 'code_field_weight',
  code_field_background = 'code_field_background',

  copied_message_color = 'copied_message_color',
  copied_message_size = 'copied_message_size',
  copied_message_weight = 'copied_message_weight',
  copied_message_bg = 'copied_message_bg',

  fraud_protection_color = 'fraud_protection_color',
  fraud_protection_size = 'fraud_protection_size',
  fraud_protection_bg_color = 'fraud_protection_bg_color',
  fraud_protection_weight = 'fraud_protection_weight',
  fraud_protection_text_alignment = 'fraud_protection_text_alignment',

  fraud_protection_reference_id_color = 'fraud_protection_reference_id_color',
  fraud_protection_reference_id_size = 'fraud_protection_reference_id_size',
  fraud_protection_reference_id_bg_color = 'fraud_protection_reference_id_bg_color',
  fraud_protection_reference_id_weight = 'fraud_protection_reference_id_weight',
  fraud_protection_reference_id_case = 'fraud_protection_reference_id_case',

  email_sent_color = 'email_sent_color',
  email_sent_size = 'email_sent_size',
  email_sent_weight = 'email_sent_weight',
  email_sent_bg_color = 'email_sent_bg_color',

  email_title_color = 'email_title_color',
  email_title_size = 'email_title_size',
  email_title_bg_color = 'email_title_bg_color',
  email_title_weight = 'email_title_weight',
  email_description_color = 'email_description_color',
  email_description_size = 'email_description_size',
  email_description_bg_color = 'email_description_bg_color',
  email_description_weight = 'email_description_weight',
  email_back_text_color = 'email_back_text_color',
  email_back_text_size = 'email_back_text_size',
  email_back_text_bg_color = 'email_back_text_bg_color',
  email_back_text_weight = 'email_back_text_weight',

  sms_title_color = 'sms_title_color',
  sms_title_size = 'sms_title_size',
  sms_title_bg_color = 'sms_title_bg_color',
  sms_title_weight = 'sms_title_weight',
  sms_description_color = 'sms_description_color',
  sms_description_size = 'sms_description_size',
  sms_description_bg_color = 'sms_description_bg_color',
  sms_description_weight = 'sms_description_weight',
  sms_back_text_color = 'sms_back_text_color',
  sms_back_text_size = 'sms_back_text_size',
  sms_back_text_bg_color = 'sms_back_text_bg_color',
  sms_back_text_weight = 'sms_back_text_weight',

  result_button_color = 'result_button_color',
  result_button_size = 'result_button_size',
  result_button_bg = 'result_button_bg',
  result_button_weight = 'result_button_weight',
  result_button_hover_color = 'result_button_hover_color',
  result_button_hover_bg = 'result_button_hover_bg',

  wheel_border_color = 'wheel_border_color',
  wheel_border_size = 'wheel_border_size',
  pattern_position = 'pattern_position',

  mobile_bg_enabled = 'mobile_bg_enabled',
  mobile_background = 'mobile_background',
  mobile_background_size = 'mobile_background_size',

  mobile_close_button_enabled = 'mobile_close_button_enabled',
  mobile_close_button_style = 'mobile_close_button_style',
  mobile_close_button_color = 'mobile_close_button_color',
  mobile_close_button_bg_color = 'mobile_close_button_bg_color',
  mobile_close_button_size = 'mobile_close_button_size',

  decline_button_color = 'decline_button_color',
  decline_button_text_color = 'decline_button_text_color',
  decline_button_size = 'decline_button_size',
  decline_button_bg = 'decline_button_bg',
  decline_button_weight = 'decline_button_weight',
  decline_button_text_hover_color = 'decline_button_text_hover_color',
  decline_button_button_hover_color = 'decline_button_button_hover_color',
  decline_button_hover_bg = 'decline_button_hover_bg',
  decline_button_text_alignment = 'decline_button_text_alignment',
  decline_button_text_decoration = 'decline_button_text_decoration',
}

export type WidgetFortuneWheelThemeVariables = {
  [key in WidgetFortuneWheelThemeVariableName]?: string
}

export enum WidgetFortuneWheelThemeImageName {
  pattern = 'pattern',
  background = 'background',
  wheel = 'wheel',
  pointer = 'pointer',
  wheel_logo = 'wheel_logo',
  logo = 'logo',
  mobile_background = 'mobile_background',
}

export interface WidgetFortuneWheelThemeImage {
  url: string,
  uploaded?: boolean
}

export type WidgetFortuneWheelThemeImages = {
  [key in WidgetFortuneWheelThemeImageName]?: WidgetFortuneWheelThemeImage
}

export interface WidgetFortuneWheelTheme {
  name?: string,
  identifier?: string,
  images: WidgetFortuneWheelThemeImages,
  variables: WidgetFortuneWheelThemeVariables,
  css: string
}

export const WidgetFortuneWheelThemeExample: WidgetFortuneWheelTheme = {
  name: 'Default',
  identifier: 'default',
  images: {
    background: { url: '' },
    wheel: { url: 'https://cdn.one.store/plugin-assets%2Ffortune-wheel%2Fdefault%2Fwheel.png?alt=media' },
    pointer: { url: 'https://cdn.one.store/plugin-assets%2Ffortune-wheel%2Fdefault%2Fpointer.png?alt=media' },
  },
  variables: {
    background_size: 'cover',
    mobile_background_size: 'cover',
    overlay_bg: '#00000045',
    wheel_bg: '#252525',
    text_color: '#ffffff',
    title_color: '#ffffff',
    slice_text_color: '#ffffff',
    slice_odd_text_color: '#ffffff',
    error_color: '#ffffff',
    error_background: '#ff5151',
    button_bg: '#aa916c',
    button_color: '#ffffff',
    button_disabled_bg: '#aaa190',
    button_disabled_color: '#ffffff',
    button_hover_bg: '#d7b87a',
    button_hover_color: '#ffffff',
    cancel_link_color: '#ffffff',
  },
  css: '',
}
