import { Component, Input, OnDestroy } from '@angular/core'
import { Logger } from '../../../../core/services/logger.service'
import { StoreState } from '../../../../store/store.state'
import { select, Store } from '@ngrx/store'
import { ActivatedRoute, Router } from '@angular/router'
import { getBilling, getBillingError } from '../../../../store/billing/billing.selectors'
import { SetCurrentUserInfo, SetSignUpCompleted, SetUserChosePlan } from '../../../../store/user/user.actions'
import { HideLoading, ResetLoading, ShowLoading } from '../../../../store/loading/loading.actions'
import { Billing } from '../../../../shared/models/billing.model'
import { ChargeResponse } from '../../../../shared/models/charge-response.model'
import { filter, first } from 'rxjs/operators'
import { ValidateCouponResponse } from '../../../../shared/models/validate-coupon-response'
import {
  getSignUpFlowCompleted,
  getUserInfo,
  getUserPromo,
  getUserShopType,
} from '../../../../store/user/user.selectors'

import * as _ from 'lodash'
import { UserInfo } from '../../../../store/user/user.state'
import { forkJoin, Subscription } from 'rxjs'
import { SMSService } from '../../../../shared/services/sms.service'
import { SegmentAnalyticsService } from '../../../../shared/services/segment-analytics.service'
import { AddCardModal } from '../../../../auth/plans/add-card-modal/add-card.modal'
import { GettingStartedService } from '../../../../core/services/getting-started.service'
import { UserService } from '../../../../core/services/user.service'
import * as mustache from 'mustache'
import { BrandingService } from '../../../../core/services/branding.service'
import { LoadingOverlayService } from '../../../../core/services/loading-overlay.service'
import { PaymentService } from '../../../../core/services/payment.service'
import { getGettingStartedFirstCampaign } from '../../../../store/getting-started/getting-started.selectors'
import { LogLabel } from '../../../models/logger/log-label.model'
import { SubscriptionPlan } from '../../../models/subscription-plan.model'
import { PlanSelectedModalComponent } from '../../modals/plan-selected.modal/plan-selected.modal.component'
import { SelectPlanConfirmationModalComponent } from '../../modals/select-plan-confirmation.modal/select-plan-confirmation.modal.component'
import { ConfirmModalComponent, ConfirmModalConfig } from '../../modals/confirm.modal/confirm.modal.component'
import { ScholarshipApplicationStatusEnum, ScholarshipStatus } from '../../../models/getting-started/getting-started-status.model'
import { Promo } from '../../../modules/promo/models/promo.model'
import { ShopifyService } from '../../../../core/services/shopify.service'
import { Redirect } from '@shopify/app-bridge/actions'
import { CrispService } from '../../../../core/services/crisp.service'
import { CommonModule } from '@angular/common'
import { PromoModule } from '../../../modules/promo/promo.module'
import { LoadingOverlayComponent } from '../../loading-overlay/loading-overlay.component'
import { PlansItemAlt1Component } from '../../plans-item/alt-1/plans-item.component'
import { PlansVersion } from '../models/plans-version.model'
import { RouteHeaderUrl } from '../../one-header/header-navigation.model'
import { MatDialog } from '@angular/material/dialog'

@Component({
    selector: 'pf-plans-view-alt-2',
    templateUrl: './plans.component.html',
    styleUrls: ['./plans.component.scss'],
    standalone: true,
    imports: [
      CommonModule,
      PromoModule,
      LoadingOverlayComponent,
      PlansItemAlt1Component,
    ]
})
export class PlansViewAlt2Component implements OnDestroy {
  @Input() pageTitle = ''
  @Input() userType = 'returning'
  @Input() noCurrentPlan // A dirty temporary hack
  errors = []
  plans: SubscriptionPlan[]
  freePlan: SubscriptionPlan
  proPlans: SubscriptionPlan[]
  isIntervalAnnual = false
  currentPlan: SubscriptionPlan = {} as SubscriptionPlan

  signUpFlowCompleted: boolean
  shopType: string
  currentUserInfo: UserInfo
  billing: Billing
  coupon: ValidateCouponResponse = null
  userPromo: Promo = null
  firstCampaignLaunched: boolean = false
  brandingName: string
  subscription = new Subscription()
  userSMSEligible = false
  initialPaths = '/onboarding/signup/new/apps/view/standard'
  plansVersion = PlansVersion.V0

  constructor(
    private paymentService: PaymentService,
    private logger: Logger,
    private router: Router,
    private loadingOverlay: LoadingOverlayService,
    private crispService: CrispService,
    public dialog: MatDialog,
    private store: Store<StoreState>,
    private brandingService: BrandingService,
    private smsService: SMSService,
    private segmentAnalyticsService: SegmentAnalyticsService,
    private route: ActivatedRoute,
    private gettingStartedService: GettingStartedService,
    private userService: UserService,
    private shopifyService: ShopifyService
    ) {
    this.brandingName = this.brandingService.getBrandingData().name

    this.getCurrentPlan()

    this.subscription.add(this.store.pipe(select(getBillingError)).subscribe(
      next => next ? this.handleError({ message: next }) : null,
    ))
    this.subscription.add(this.store.pipe(
      select(getUserShopType),
      filter((next) => !!next),
    ).subscribe(next => {
      this.shopType = next
      this.updateVisiblePlans()
    }))

    this.subscription.add(this.store.pipe(select(getSignUpFlowCompleted)).subscribe(next => {
      this.signUpFlowCompleted = next
      if (next) {
        this.initialPaths = `/${RouteHeaderUrl.apps}`
      }
    }))

    this.subscription.add(this.store.select(getUserPromo).subscribe((promo: Promo) => {
      this.userPromo = promo
    }))

    this.subscription.add(this.store.pipe(
      select(getGettingStartedFirstCampaign),
      filter(launched => launched !== undefined),
    ).subscribe((launched) => {
      this.firstCampaignLaunched = launched
    }))


    this.segmentAnalyticsService.track('Viewed Plans', {
      state: {
        onboarding: true,
        step: 'view_plans',
        group: 'choose_plan',
      },
    })
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
    this.store.dispatch(new ResetLoading())
    this.dialog.closeAll()
  }

  isUsingShopifyBilling() {
    return this.billing.source === 'shopify'
  }

  getCurrentPlan() {
    const getPlansRequest = this.paymentService.getPlansRequest(this.plansVersion)
    const getBillingRequest = this.store.pipe(
      select(getBilling),
      filter((next) => !!next.source),
      first(),
    )
    const userInfoRequest = this.store.pipe(
      select(getUserInfo),
      filter((next) => !!next),
      first(),
    )

    this.subscription.add(
      this.loadingOverlay.wrap(forkJoin([getPlansRequest, getBillingRequest, userInfoRequest]), 'Plans-Joined-Request')
      .subscribe(([plansData, billingData, userInfo]) => {
        this.logger.log(<LogLabel>'alt_plans', `combineLatesPlansData: `, plansData)
        this.logger.log(<LogLabel>'alt_plans', `combineLatestBilling: `, billingData)

        // !!!!!????
        this.currentUserInfo = userInfo // WTF is going on here? Why it has a value of onboarding response when the user sees this page for the first time?

        // if urlparams have select=budding then skip plans page
        if (_.get(this.route.snapshot, 'queryParams.select') === 'budding') {
          this.autoSelectPlan()
          this.router.navigate([], { queryParams: { select: null }, queryParamsHandling: 'merge' })
        }

        // If user submitted scholarship application update scholarship status & set them on budding plan
        if (_.get(this.route.snapshot, 'queryParams.application_submitted')) {
          this.subscription.add(
            this.gettingStartedService.updateScholarshipStatusFromQueryParams(_.get(this.route, 'snapshot.queryParams', {})).subscribe()
          )
          if (!_.get(this.currentUserInfo, 'onboarding.chose_plan')) {
            this.autoSelectPlan(_.get(this.currentUserInfo, 'subscription.plan.payment_gateway_plan_identifier', 'budding'))
          }
        }

        this.userSMSEligible = this.smsService.userIsEligible(userInfo)

        this.currentPlan = _.get(userInfo, 'subscription.plan', {})
        this.plans = plansData.plans?.filter(plan => plan.version === this.plansVersion)
        this.billing = billingData

        if (_.get(this.currentUserInfo, 'subscription.coupon')) {
          this.applyPlansDiscount(this.currentUserInfo.subscription.coupon)
        }
        this.updateVisiblePlans()
      })
    )
  }

  autoSelectPlan(planIdentifier = 'budding') {
    this.store.dispatch(new ShowLoading('autoSelectPlansPage'))
    this.subscription.add(this.paymentService.changeSubscriptionPlan({payment_gateway_plan_identifier: planIdentifier} as SubscriptionPlan, null).subscribe((res)  => {
      this.store.dispatch(new SetCurrentUserInfo({
        ...this.currentUserInfo,
        subscription: res.result.subscription,
      }))
      this.store.dispatch(new SetUserChosePlan(true))
      this.store.dispatch(new SetSignUpCompleted(true))

      // tell segment that this user skipped plans page
      this.segmentAnalyticsService.identify(this.currentUserInfo.id, {
        plans_skipped: true,
        plans_skipped_reason: 'url_params'
      })
      this.router.navigate([this.initialPaths]).then(() => {
        this.store.dispatch(new HideLoading('autoSelectPlansPage'))
        if (!this.shopifyService.isEmbedded) {
          window.location.reload()
        }
      })
    }))
  }
  isCurrentPlan(plan) {
    if (this.noCurrentPlan) {
      // A dirty hack (temporary)
      return false
    }
    return this.currentPlan.payment_gateway_plan_identifier === plan.payment_gateway_plan_identifier
  }

  updateVisiblePlans() {
    /* Make sure plans are set */
    if (!this.plans) {
      return
    }
    this.freePlan = this.paymentService.getFreePlan(this.plans)
    this.freePlan.popular = true

    this.proPlans = this.paymentService.getVisiblePaidPlans(this.plans, this.isIntervalAnnual)
  }

  onSelectPlanClick(plan: SubscriptionPlan, event?: any) {
    if (event) {
      plan = {...plan, ...event}
    }
    switch (plan.plan_cta_action) {
      case 'send_crisp_message':
        this.sendCrispMessage(plan)
        return
      case 'redirect_page':
        this.subscription.add(
          this.gettingStartedService.updateScholarshipStatus({ plan_id: plan.payment_gateway_plan_identifier, plan_name: plan.name }).subscribe()
        )
        let url = _.get(plan, 'plan_cta_payload')
        url += `?selectedPlan=${plan.name}`
        url += `&onePlanid=${plan.payment_gateway_plan_identifier}`
        this.currentUserInfo = this.userService.userInfo
        if (_.get(this.currentUserInfo, 'shop.store_url')) {
          url += `&shopifyUrl=${_.get(this.currentUserInfo, 'shop.store_url')}`
          url += `&oneShopurl=${_.get(this.currentUserInfo, 'shop.store_url')}`
        }
        if (_.get(this.currentUserInfo, 'shop.profile.name')) {
          url += `&shopName=${_.get(this.currentUserInfo, 'shop.profile.name')}`
          url += `&oneShopname=${_.get(this.currentUserInfo, 'shop.profile.name')}`
        }
        if (_.get(this.currentUserInfo, 'shop.profile.plan_name')) {
          url += `&oneShopifyplan=${_.get(this.currentUserInfo, 'shop.profile.plan_name')}`
        }
        if (_.get(this.currentUserInfo, 'id')) {
          url += `&oneUserid=${_.get(this.currentUserInfo, 'id')}`
        }
        if (_.get(this.currentUserInfo, 'address.country.code')) {
          url += `&oneCountry=${_.get(this.currentUserInfo, 'address.country.code')}`
        }
        if (this.userPromo) {
          if (_.get(this.userPromo, 'promo_code')) {
            url += `&onePromocode=${_.get(this.userPromo, 'promo_code')}`
          }
          if (_.get(this.userPromo, 'promo_type')) {
            url += `&onePromotype=${_.get(this.userPromo, 'promo_type')}`
          }
          if (_.get(this.userPromo, 'promo_referrer')) {
            url += `&onePromoreferrer=${_.get(this.userPromo, 'promo_referrer')}`
          }
          if (_.get(this.userPromo, 'promo_view')) {
            url += `&onePromoview=${_.get(this.userPromo, 'promo_view')}`
          }
        }
        if (window && window.location) {
          url += `&referrer=${window.location.origin + window.location.pathname}`
        }
        if (_.get(navigator, 'language')) {
          url += `&language=${_.get(navigator, 'language')}`
        }
        if (this.isValidHttpUrl(url)) {
          window.open(url, '_blank')
        } else {
          // if plan cta payload is not a valid url open default crisp message
          this.sendCrispMessage({...plan, plan_cta_payload: ''})
        }
        break
      case 'open_popup':
        const dialogRef = this.dialog.open(ConfirmModalComponent, {
          width: '560px',
          data: {
            title: plan.name,
            html: plan.plan_cta_payload,
            footerClass: 'justify-content-end mb-0',
            cancelButton: {
              text: 'Cancel',
              classes: 'pf-button outline gray'
            },
            acceptButton: {
              text: 'Continue',
              classes: 'pf-button filled blue'
            }
          } as ConfirmModalConfig,
        })
        this.subscription.add(dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.choosePlan(plan)
          }
        }))
        break
      case 'choose_plan':
      default:
        this.choosePlan(plan)
    }
  }

  isValidHttpUrl(string) {
    let url

    try {
      url = new URL(string)
    } catch (_) {
      return false
    }

    return url.protocol === 'http:' || url.protocol === 'https:'
  }

  choosePlan(plan: SubscriptionPlan) {
    this.segmentAnalyticsService.track('Choose Plan', {
      state: {
        onboarding: true,
        step: 'click_plan',
        group: 'choose_plan',
      },
      metadata: { plan },
    })
    if (this.shopType === 'ShopifyShop' && plan.shopify_usage_charge) {
      this.currentUserInfo = this.userService.userInfo
      const dialogRef = this.dialog.open(PlanSelectedModalComponent, {
        width: '530px',
        panelClass: ['plan-dialog', 'increase-dialog-size'],
        data: {
          userInfo: this.currentUserInfo,
          trial: plan.trial_period,
          plan: plan,
        },
      })
      this.subscription.add(dialogRef.afterClosed().pipe(filter((result) => !!result))
        .subscribe(result => {
          this.logger.log(LogLabel.PlansComponent, 'plans-popup-result: ', result)
          if (result.purchase) {
            this._submitSelectPlanConfirmationModal(plan, this.coupon)
          }
        }))
    } else {
      if (this.needCardDetails(plan)) {
        this._openAddCardDetailsModal(plan)
      } else {
        this._openSelectPlanConfirmationModal(plan, this.coupon)
      }
    }
  }

  needCardDetails(plan: SubscriptionPlan) {
    this.logger.log(LogLabel.PlansComponent, `selected_plan: ${plan.payment_gateway_plan_identifier}`)
    if (this.isUsingShopifyBilling()) return false
    if (plan.payment_gateway_plan_identifier === 'budding') return false
    return !this.billing.valid
  }

  private _openSelectPlanConfirmationModal(plan, coupon?: ValidateCouponResponse) {
    if (this.needConfirmationModal) {
      const modalRef = this.dialog.open(SelectPlanConfirmationModalComponent, {
        width: '500px',
        data: {
          currentPlan: this.currentPlan,
          newPlan: plan
        }
      })

      this.subscription.add(
        modalRef.afterClosed().subscribe(result => {
            if (result) {
              this._submitSelectPlanConfirmationModal(plan, coupon)
            }
          })
      )
    } else {
      this._submitSelectPlanConfirmationModal(plan, coupon)
    }
  }

  private _submitSelectPlanConfirmationModal(plan, coupon?: ValidateCouponResponse) {
    this.store.dispatch(new ShowLoading('submitSubscriptionChanges'))
    const redirectUrl = this.shopifyService.isEmbedded ? this.shopifyService.shopifyAppUrl + this.router.url : window.location.href
    this.subscription.add(this.paymentService.changeSubscriptionPlan(plan, coupon ? coupon.code : null, redirectUrl)
      .subscribe(
        (res: ChargeResponse) => {
          this.logger.log(LogLabel.PlansComponent, JSON.stringify(res))
          if (res.result && res.result.type === 'shopify' && res.result.confirmation_url) {
            this.segmentAnalyticsService.track('Plan Checkout', {
              state: {
                onboarding: true,
                step: 'plan_checkout',
                group: 'choose_plan',
              },
              metadata: {
                gateway: 'shopify',
                coupon: coupon,
                plan: plan,
              },
            })
            if (this.shopifyService.isEmbedded) {
              const url = res.result.confirmation_url
              const chargesUrl = url.slice(url.indexOf('/charges'), url.length)
              this.shopifyService.redirect.dispatch(Redirect.Action.ADMIN_PATH, { path: chargesUrl })
            } else {
              window.location.href = res.result.confirmation_url
            }
          } else {
            this.currentUserInfo = this.userService.userInfo
            this.store.dispatch(new SetCurrentUserInfo({
              ...this.currentUserInfo,
              subscription: res.result.subscription,
            }))
            this.store.dispatch(new SetUserChosePlan(true))

            this.router.navigate([this.initialPaths]).then(() => {
              this.store.dispatch(new HideLoading('submitSubscriptionChanges'))
              if (!this.shopifyService.isEmbedded) {
                window.location.reload()
              }
            })
          }
        },
        err => {
          this.segmentAnalyticsService.track('Plan Error', {
            state: {
              onboarding: true,
              step: 'error',
              group: 'choose_plan',
              status: 'error',
            },
            metadata: {
              error: err,
            },
          })
          this.store.dispatch(new HideLoading('submitSubscriptionChanges'))
          this.handleError(err)
        },
      ))
  }

  private _openAddCardDetailsModal(plan: SubscriptionPlan) {
    const modalRef = this.dialog.open(AddCardModal, {
      width: '530px',
      data: plan
    })

    this.segmentAnalyticsService.track('Plan Checkout', {
      state: {
        onboarding: true,
        step: 'plan_checkout',
        group: 'choose_plan',
      },
      metadata: {
        gateway: 'stripe',
        plan: plan,
      },
    })

    this.subscription.add(
      modalRef.afterClosed()
        .subscribe(coupon => {
          if (coupon) {
            this._submitAddCardDetailsModal(plan, coupon)
            return
          }
          this.billing = {...this.billing, valid: false}
        })
    )
  }

  private _submitAddCardDetailsModal(plan: SubscriptionPlan, coupon: ValidateCouponResponse) {
    this.billing = {...this.billing, valid: true}
    this._openSelectPlanConfirmationModal(plan, coupon)
  }

  handleError(error) {
    const message = error.message || error.exception || error.error || 'Something went wrong'
    this.errors.push(message)
    this.logger.error(LogLabel.PlansComponent, message)
    this.store.dispatch(new HideLoading('change-plan-overlay'))
  }

  changeInterval(annual?: boolean) {
    this.isIntervalAnnual = annual
    this.updateVisiblePlans()
  }

  get needConfirmationModal() {
    return this.signUpFlowCompleted
  }

  applyPlansDiscount(coupon: ValidateCouponResponse) {
    this.currentUserInfo = this.userService.userInfo
    const currentlyAppliedCoupon = _.get(this.currentUserInfo, 'subscription.coupon')
    const currentPlanId = _.get(this.currentUserInfo, 'subscription.plan.payment_gateway_plan_identifier')
    const couponPlans = coupon.plans || []
    if (this.plans) {
      this.plans.map((plan: SubscriptionPlan) => {
        // Skip plan if coupon does not applies to it
        // if no coupon plans are set apply to all
        if (couponPlans && couponPlans.length > 0 && !couponPlans.some(_plan => _plan.payment_gateway_plan_identifier === plan.payment_gateway_plan_identifier)) {
          return
        }
        this.modifyPlan(plan, coupon)
      })
    }

    if (currentlyAppliedCoupon && currentPlanId) {
      const currentPlan = this.plans.find(_plan => _plan.payment_gateway_plan_identifier === currentPlanId)
      if (currentPlan) {
        this.modifyPlan(currentPlan, currentlyAppliedCoupon)
      }
    }

    this.updateVisiblePlans()
  }

  modifyPlan(plan: SubscriptionPlan, coupon: ValidateCouponResponse) {
    // Don't apply coupons to budding plans
    if (plan.payment_gateway_plan_identifier === 'budding') {
      return
    }
    // PERCENT OFF
    if (coupon.percent_off_precise) {
      if (plan.initial_price) {
        plan.price = _.cloneDeep(plan.initial_price)
      }
      if (coupon.disable_trial) {
        plan.trial_period = 0
      }
      plan.old_display_price = `${+plan.price.fractional / plan.price.currency.subunit_to_unit}`
      plan.initial_price = _.cloneDeep(plan.price)
      plan.price.fractional = `${Math.ceil((+plan.price.fractional / 100.0) * (100.0 - coupon.percent_off_precise))}`
      plan.trial_extension_text = null
      delete plan.trial_extension_text
      delete plan.plan_cta_label
      delete plan.plan_cta_action
      plan.forceGreen = false

    // AMOUNT OFF
    } else if (coupon.amount_off) {
      if (plan.initial_price) {
        plan.price = _.cloneDeep(plan.initial_price)
      }
      if (coupon.disable_trial) {
        plan.trial_period = 0
      }
      plan.old_display_price = `${+plan.price.fractional / plan.price.currency.subunit_to_unit}`
      plan.initial_price = _.cloneDeep(plan.price)
      plan.price.fractional = `${Math.ceil(+plan.price.fractional - (coupon.amount_off * 100))}`
      plan.trial_extension_text = null
      delete plan.trial_extension_text
      delete plan.plan_cta_label
      delete plan.plan_cta_action
      plan.forceGreen = false

    // TRIAL DAYS
    } else if (coupon.extended_trial_days) {
      // Not sure if we need all of this
      if (plan.initial_price) {
        plan.price = _.cloneDeep(plan.initial_price)
      }
      if (coupon.disable_trial) {
        plan.trial_period = 0
      }
      plan.old_display_price = `${+plan.price.fractional / plan.price.currency.subunit_to_unit}`
      plan.initial_price = _.cloneDeep(plan.price)
      plan.price.fractional = `${Math.ceil((+plan.price.fractional / 100.0) * (100.0 - coupon.percent_off_precise))}`

      // Hacking things together
      const convertibleToMonths = coupon.extended_trial_days % 30 === 0
      const isYear = coupon.extended_trial_days === 365
      plan.trial_extension_text = isYear ? 'ONE Year Free' : convertibleToMonths ? `${coupon.extended_trial_days / 30} Months Free` : `${coupon.extended_trial_days} Days Free`

      plan.forceGreen = true

      plan.plan_cta_label = coupon.plan_cta_label || 'Select Plan'
      plan.plan_cta_action = coupon.plan_cta_action || 'choose_plan'
      plan.plan_cta_payload = coupon.plan_cta_payload || `Hi team, I'm having issues with my plan, can you help me?`
      plan.trial_period_text = coupon.code_type === 'teaser' ? 'ONE+ Startup Scholarship Program' : ''

      // add alternative for plans with scholarship option
      if (!this.isCurrentPlan(plan) && coupon.code_type === 'teaser' && plan.payment_gateway_plan_identifier !== 'budding') {
        plan.altButton = {
          plan_cta_action: 'choose_plan'
        }
        if (plan.trial_period > 0) {
          plan.altButton.plan_cta_label = 'Start Free Trial'
          plan.altButton.trial_period_text = `${plan.trial_period}-Day Free Trial`
        } else {
          plan.altButton.plan_cta_label = 'Select Plan'
          plan.altButton.trial_period_text = ''
        }
      }
    }
  }

  trackByPriceChange(index: number, item: SubscriptionPlan) {
    return item.old_display_price ? item.old_display_price : item.id
  }

  removePlansDiscount() {
    const currentPlanId = _.get(this.currentUserInfo, 'subscription.plan.payment_gateway_plan_identifier')
    this.plans.map((plan: SubscriptionPlan) => {
      if (plan.payment_gateway_plan_identifier !== currentPlanId) {
        if (plan.initial_price) {
          delete plan.old_display_price
          delete plan.trial_extension_text
          delete plan.plan_cta_label
          delete plan.plan_cta_action
          delete plan.altButton
          delete plan.trial_period_text
          plan.price = _.cloneDeep(plan.initial_price)
          plan.forceGreen = false
          delete plan.initial_price
        }
      }
    })
    this.updateVisiblePlans()
  }

  onCouponApplied(coupon: ValidateCouponResponse) {
    if (coupon) {
      this.coupon = coupon
      this.applyPlansDiscount(coupon)
    } else {
      this.coupon = null
      this.removePlansDiscount()
    }
  }

  sendCrispMessage(plan: SubscriptionPlan) {
    const crisp = this.crispService.getCrisp()
    if (crisp) {

      let message = `Hi team, what cam I do to activate ${plan.name} for ${this.shopType}?`

      // if there is a payload parse it with mustache
      if (plan.plan_cta_payload) {
        const mustacheData = {...plan, shop_type: this.shopType}
        message = mustache.render(plan.plan_cta_payload,  mustacheData)
      }

      crisp.push(['do', 'chat:open'])
      crisp.push(['do', 'message:send', ['text', message]])
    }
  }
}
