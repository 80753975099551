import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot } from '@angular/router'

import { Logger } from './logger.service'
import { environment } from '../../../environments/environment'
import { LogLabel } from '../../shared/models/logger/log-label.model'

@Injectable()
export class ShopifyOauthGuard  {
  constructor(private logger: Logger) {
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    this.logger.log(LogLabel.ShopifyOauthService, `can_activate: ${JSON.stringify(route.queryParams)}`)
    const params = route.queryParams
    if (params?.hmac && params?.shop && !params.custom_token) {
      const oauthurl = `${environment.apiBaseURL}/auth/shopify?shop=${params.shop}&hmac=${params.hmac}`

      this.logger.log(LogLabel.ShopifyOauthService, `oauthurl: ${oauthurl}`)

      window.location.href = oauthurl

      return false
    }
    return true
  }
}
