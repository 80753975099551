import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule } from '@angular/forms'
import { VipOnboardingRoutingModule } from './vip-onboarding-routing.module'
import { VipOnboardingComponent } from './components/vip-onboarding.component'
import { VipButtonComponent } from './components/vip-button/vip-button.component'
import { LoadingOverlayComponent } from '../../shared/components/loading-overlay/loading-overlay.component'



@NgModule({
  declarations: [
    VipOnboardingComponent,
    VipButtonComponent,
  ],
  exports: [
    VipOnboardingComponent,
    VipButtonComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    VipOnboardingRoutingModule,
    LoadingOverlayComponent,
  ],
  providers: [
  ]
})

export class VipOnboardingModule { }
