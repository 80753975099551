import {Action} from '@ngrx/store'
import { LoadingActionTypes } from './loading.action-types'
import { LoadingLabel } from '../../shared/models/loading/loading-label'

export class ShowLoading implements Action {
  readonly type = LoadingActionTypes.SHOW

  constructor(public payload: LoadingLabel | string ) {
  }
}

export class HideLoading implements Action {
  readonly type = LoadingActionTypes.HIDE

  constructor(public payload: string) {
  }
}

export class ResetLoading implements Action {
  readonly type = LoadingActionTypes.RESET
}


export type LoadingActions =
  | ShowLoading
  | HideLoading
  | ResetLoading
