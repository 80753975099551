import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { PromoComponent } from './promo.component'
import { PromoDirective } from './promo.directive'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { PromoService } from './services/promo.service'
import { BlackFridayViewComponent } from './promo-views/black_friday/black-friday-view.component'
import { CyberMondayViewComponent } from './promo-views/cyber_monday/cyber-monday-view.component'
import { CyberWeekViewComponent } from './promo-views/cyber-week/cyber-week-view.component'
import { PartnerViewComponent } from './promo-views/partner/partner-view.component'
import { BFCMViewComponent } from './promo-views/bfcm/bfcm-view.component'
import { SpecialOfferViewComponent } from './promo-views/special-offer/special-offer-view.component'
import { ScholarshipPromoComponent } from './promo-views/scholarship-promo/scholarship-promocomponent'

@NgModule({
  declarations: [
    BlackFridayViewComponent,
    CyberMondayViewComponent,
    CyberWeekViewComponent,
    PartnerViewComponent,
    BFCMViewComponent,
    SpecialOfferViewComponent,
    ScholarshipPromoComponent,

    PromoComponent,
    PromoDirective,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    PromoComponent
  ],
  providers: [
    PromoService
  ],
})

export class PromoModule {

}
