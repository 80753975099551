import { Injectable } from "@angular/core"
import { BehaviorSubject } from "rxjs"
import { TutorialLibrary, TutorialPlaylist, TutorialVideoItem } from "../models/tutorials.model"

export enum TutorialId {
  Popup = 'popups',
  SocialProof = 'social-proof-notifications',
  AbTesting = 'ab-testing',
  Upsell = 'one-click-upsell',
  Other = 'other',
  Email = 'email',
}

@Injectable()
export class TutorialsService {
  public currentPlaylist$: BehaviorSubject<TutorialPlaylist> = new BehaviorSubject<TutorialPlaylist>(null)
  public currentVideo$: BehaviorSubject<TutorialVideoItem> = new BehaviorSubject<TutorialVideoItem>(null)
  public returnURL: string
  public readonly BLOG_POSTS = {
    [TutorialId.Popup]: [
      {
        title: 'Setting Up the Fortune Wheel or Spin Wheel',
        thumb: '',
        url: 'https://help.one.store/en/article/setting-up-the-fortune-wheel-or-spin-wheel-1hitxjt/',
      },
      {
        title: 'Setting up a Traditional Pop up (With Reward)',
        thumb: '',
        url: 'https://help.one.store/en/article/setting-up-a-traditional-popup-with-reward-uyvxds/',
      },
      {
        title: 'Setting up a Traditional Pop up (Without Reward)',
        thumb: '',
        url: 'https://help.one.store/en/article/setting-up-a-traditional-popup-without-reward-1kgujb2/',
      },
      {
        title: 'Display Rules for Pop up Campaigns',
        thumb: '',
        url: 'https://help.one.store/en/article/display-rules-for-popup-campaigns-nnqgki/',
      },
      {
        title: 'Opt-in Pop ups Triggers',
        thumb: '',
        url: 'https://help.one.store/en/article/opt-in-popups-triggers-ogn1dl/',
      },
    ],
    [TutorialId.Upsell]: [
      {
        title: 'One Click Upsell',
        thumb: '',
        url: 'https://help.one.store/en/article/one-click-upsell-tjronx/',
      },
      {
        title: 'How does the Priority work in the One Click Upsell?',
        thumb: '',
        url: 'https://help.one.store/en/article/how-does-the-priority-work-in-the-one-click-upsell-1j877yl/',
      }
    ],
    [TutorialId.SocialProof]: [
      {
        title: 'Using Functions in Templates',
        thumb: '',
        url: 'https://help.one.store/en/article/using-functions-in-templates-6huyvs/',
      },
      {
        title: 'Customize the Recent Activity Notification',
        thumb: '',
        url: 'https://help.one.store/en/article/customize-the-recent-activity-notification-15bdz0l/',
      },
    ],
    [TutorialId.Email]: [
      {
        title: 'Setting up a Custom Sending Email Address',
        thumb: '',
        url: 'https://help.one.store/en/article/setting-up-a-custom-sending-email-address-1hbq4k7/',
      },
      {
        title: 'Saving Email Blocks or Transferring Email Templates',
        thumb: '',
        url: 'https://help.one.store/en/article/saving-email-blocks-or-transferring-email-templates-lvwnd0/',
      },
      {
        title: 'Email Spam Complaint Rate and Why It Matters',
        thumb: '',
        url: 'https://help.one.store/en/article/email-spam-complaint-rate-and-why-it-matters-wpdq75/',
      },
      {
        title: 'Remove the Preview Text From Emails',
        thumb: '',
        url: 'https://help.one.store/en/article/remove-the-preview-text-from-emails-1hnulrp/',
      },
      {
        title: 'Adding or Customizing an Unsubscribe Link',
        thumb: '',
        url: 'https://help.one.store/en/article/adding-or-customizing-an-unsubscribe-link-13aotb0/',
      },
      {
        title: 'How Revenue Attribution works for Email Campaigns',
        thumb: '',
        url: 'https://help.one.store/en/article/how-revenue-attribution-works-for-email-campaigns-1f1lh6c/',
      },
      {
        title: 'Email Bounce Rate and Why It Matters',
        thumb: '',
        url: 'https://help.one.store/en/article/email-bounce-rate-and-why-it-matters-17gafl4/',
      },
      {
        title: 'Send Emails to Segments in Shopify',
        thumb: '',
        url: 'https://help.one.store/en/article/send-emails-to-segments-in-shopify-1dpsu5t/',
      },
    ],
    [TutorialId.AbTesting]: [
      {
        title: 'How to create A/B tests',
        thumb: '',
        url: 'https://help.one.store/en/article/how-to-create-ab-tests-with-opt-in-pop-ups-170brbh/',
      },
    ],
  }

  public library: TutorialLibrary = {
    playlists: [
      // POPUPS
      {
        title: 'Pop Ups',
        id: TutorialId.Popup,
        thumbNailSrc: '/app_assets/video-gallery/fw-thumbnail.png',
        videos: [
          {
            id: 'IvJ4nSIHrYA',
            title: 'Spin Wheel Pop Up Tutorial',
            thumbNailSrc: '/app_assets/video-gallery/fw-thumbnail.png',
            eventName: 'SpinWheel Tutorial',
            cloudflare: {
              link: '',
              id: ''
            }
          },
          {
            id: 'wePo85NHY7Q',
            title: 'Pop up without Reward Tutorial',
            thumbNailSrc: '/app_assets/video-gallery/bsb-thumbnail.png',
            eventName: 'PopupNoReward Tutorial',
            cloudflare: {
              link: '',
              id: ''
            }
          },
          {
            id: 'GZGpVpGLJLg',
            title: 'Opt-in Pop Up Tutorial',
            thumbNailSrc: '/app_assets/video-gallery/bcb-thumbnail.png',
            eventName: 'PopupReward Tutorial',
            cloudflare: {
              link: '',
              id: ''
            }
          },
          {
            id: 'cp2SB-FkaOs',
            title: 'Free Shipping Pop Up Tutorial',
            thumbNailSrc: '/app_assets/video-gallery/fsp-thumbnail.png',
            eventName: 'Free Shipping Popup Tutorial',
            cloudflare: {
              link: '',
              id: ''
            }
          },
          {
            id: 'VFrUNN8tyQI',
            title: 'Sales Announcement Pop Up Tutorial',
            thumbNailSrc: '/app_assets/video-gallery/sales-announcement-thumbnail.webp',
            eventName: 'Sales Popup Tutorial',
            cloudflare: {
              link: '',
              id: ''
            }
          },
          {
            id: 'hp0h_JpidII',
            title: 'Product Announcement Pop Up Tutorial',
            thumbNailSrc: '/app_assets/video-gallery/product-announcement-thumbnail.webp',
            eventName: 'Product Popup Tutorial',
            cloudflare: {
              link: '',
              id: ''
            }
          },
        ]
      },
      // SOCIAL PROOF NOTIFICATIONS
      {
        title: 'Social Proof Notifications',
        id: TutorialId.SocialProof,
        thumbNailSrc: '/app_assets/video-gallery/rsp-thumbnail.png',
        videos: [
          {
            id: 'tHP04-CWBXM',
            title: 'Recent Activity Pop Up | Social Proof',
            thumbNailSrc: '/app_assets/video-gallery/rsp-thumbnail.png',
            eventName: 'RecentActivity Tutorial',
            cloudflare: {
              link: '',
              id: ''
            }
          },
        ]
      },
      // AB TESTING
      {
        title: 'A/B Testing',
        id: TutorialId.AbTesting,
        thumbNailSrc: '/app_assets/video-gallery/upsell-thumbnail.png',
        videos: [
          {
            id: '',
            title: 'How To Create an A/B Test',
            thumbNailSrc: '/app_assets/video-gallery/email-thumbnail.jpg',
            eventName: 'A/B Testing Tutorial',
            cloudflare: {
              link: '',
              id: 'a06ef87d57a43b46bb37ebb64a1dd5ef'
            }
          },
          {
            id: '',
            title: 'Increase Your Conversion Rates',
            thumbNailSrc: '/app_assets/video-gallery/email-thumbnail.jpg',
            eventName: 'Increase Conversion Tutorial',
            cloudflare: {
              link: '',
              id: '8dd06157354f98c498c7a2f377185ff7'
            }
          },
        ]
      },
      // ONE CLICK UPSELL
      {
        title: 'One Click Upsell',
        id: TutorialId.Upsell,
        thumbNailSrc: '/app_assets/video-gallery/upsell-thumbnail.png',
        videos: [
          {
            id: 'WZYrIeaZpcY',
            title: 'One-Click Upsell Tutorial',
            thumbNailSrc: '/app_assets/video-gallery/upsell-thumbnail.png',
            eventName: 'OneClickUpsell Tutorial',
            cloudflare: {
              link: '',
              id: ''
            }
          },
        ]
      },
      // EMAIL
      {
        title: 'Email',
        id: TutorialId.Email,
        thumbNailSrc: '/app_assets/video-gallery/email-thumbnail.jpg',
        videos: [
          {
            id: 'RMdXZeiOynQ',
            title: 'Email Overview',
            thumbNailSrc: '/app_assets/video-gallery/email-thumbnail.jpg',
            eventName: 'Email Overview',
            cloudflare: {
              link: '',
              id: ''
            }
          },
        ]
      },
      // OTHER TUTORIALS
      {
        title: 'Other Tutorials',
        id: TutorialId.Other,
        thumbNailSrc: '/app_assets/video-gallery/upsell-thumbnail.png',
        videos: [
          {
            id: 'VgE7nM0r5dM',
            title: 'Overview',
            thumbNailSrc: '/app_assets/video-gallery/welcome-thumbnail.png',
            eventName: 'Overview',
            cloudflare: {
              id: '920e4956af7429ce4ceab750ddc71e54',
              link: 'https://customer-x07oc7fhgs0miebp.cloudflarestream.com/920e4956af7429ce4ceab750ddc71e54/iframe?poster=https%3A%2F%2Fcustomer-x07oc7fhgs0miebp.cloudflarestream.com%2F920e4956af7429ce4ceab750ddc71e54%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600'
            }
          },
        ]
      },
    ],
  }

  constructor() {}

  public getVideoById(id: string): TutorialVideoItem {
    for (const playlist of this.library.playlists) {
      for (const video of playlist.videos) {
        if (video.id === id) {
          return {
            parentID: playlist.id,
            ...video
          }
        }
        if (video.cloudflare.id === id) {
          return {
            parentID: playlist.id,
            ...video
          }
        }
      }
    }
  }
}
