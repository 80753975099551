import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, Router, RouterModule } from '@angular/router'
import { SegmentAnalyticsService } from '../../../shared/services/segment-analytics.service'
import { CommonModule, Location } from '@angular/common'
import confetti from 'canvas-confetti'
import { OneIconComponent } from '../../../shared/components/one-icon/one-icon.component'
import { OneMainLogoComponent } from '../../../shared/components/one-side-nav/one-main-logo/one-main-logo.component'


@Component({
  templateUrl: './shopify-checkout-success.component.html',
  styleUrls: ['./shopify-checkout-success.component.scss'],
  standalone: true,
  imports: [
    RouterModule,
    CommonModule,
    RouterModule,
    OneIconComponent,
    OneMainLogoComponent,
  ],
})

export class ShopifyCheckoutSuccessComponent implements OnInit, AfterViewInit {
  @ViewChild('canvasRef', { static: true }) canvasRef: ElementRef
  confettiDefaults = {
    spread: 360,
    ticks: 50,
    gravity: 0,
    decay: 0.94,
    startVelocity: 30,
    colors: ['FFE400', 'FFBD00', 'E89400', 'FFCA6C', 'FDFFB8']
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private segmentAnalyticsService: SegmentAnalyticsService,
    private location: Location,
  ) {
    const _paymentGateway: 'shopify' | 'stripe' = this.route.snapshot.queryParams?.payment_gateway
    const paymentGateway = _paymentGateway === 'stripe' ? 'Stripe' : 'Shopify'
    const isNew = window.location.href.includes('/new/')
    const isReturning = window.location.href.includes('/returning/')
    const userType = isNew ? 'New' : isReturning ? 'Returning' : 'Existing'
  }

  ngOnInit() {
    console.log('ngOnInit: ShopifyCheckoutSuccessComponent')
  }

  redirect() {
    const redirectUrl = this.route.snapshot.queryParams?.intent_url
    if (redirectUrl) {
      const url = new URL(redirectUrl)
      const pathWithQueryParams = url.pathname + url.search
      const fullPath = this.location.prepareExternalUrl(pathWithQueryParams)
      this.router.navigateByUrl(fullPath)
    } else {
      this.router.navigate(['/'])
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {this.shootConfetti()}, 500)
    setTimeout(() => {this.shootConfetti()}, 600)
    setTimeout(() => {this.shootConfetti()}, 700)
    setTimeout(() => {
      Promise.all([
        this.segmentAnalyticsService.track('Platform | Subscriptions | Checkout | Completed'),
        this.segmentAnalyticsService.track('Onboarding | Platform | Subscriptions | Checkout | Completed')
      ]).then((res) => {
        this.redirect()
      }).catch((error) => {
        this.redirect()
      })
    }, 1600)
  }

  shootConfetti() {
    confetti({
      ...this.confettiDefaults,
      particleCount: 40,
      scalar: 1.2,
      shapes: ['star']
    })
  
    confetti({
      ...this.confettiDefaults,
      particleCount: 10,
      scalar: 0.75,
      shapes: ['circle']
    })
  }

}
