<div class="back-button" [class.expanded]="expanded">
  <!-- <a [routerLink]="url" class="back-button-link">
    <one-icon name="caret-back" class="back-icon"></one-icon>
    <span class="label">Back<span *ngIf="expanded && label">{{' to ' + label}}</span></span>
  </a> -->
  <button class="pf-button outline black xs fw mt-2 overflow-hidden" [routerLink]="url">
    <img class="btn-icon exit-icon" src="/app_assets/icons/exit-icon.svg" alt="">
    <span class="ml-2"><span *ngIf="expanded && label">{{'Exit ' + label}}</span></span>
  </button>
</div>
