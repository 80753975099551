import { Injectable, inject } from '@angular/core'
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router'
import { select, Store } from '@ngrx/store'
import { forkJoin, Observable } from 'rxjs'
import { StoreState } from '../../store/store.state'
import { getUserChoseInitialPlanCompleted, getUserInfo } from '../../store/user/user.selectors'
import { filter, finalize, map } from 'rxjs/operators'
import { first } from 'rxjs/operators'
import { HideLoading, ShowLoading } from '../../store/loading/loading.actions'
import { LoadingLabel } from '../../shared/models/loading/loading-label'
import { Logger } from '../services/logger.service'
import { LogLabel } from '../../shared/models/logger/log-label.model'
import { SafeLocalStorageService } from '../services/safe-local-storage.service'
import { UserInfo } from '../../store/user/user.state'
import { RouteHeaderUrl } from '../../shared/components/one-header/header-navigation.model'
import { ChosePlanCompleteGuard } from './chose-plan-complete.guard'
import { CartService } from '../../pages/cart/services/cart.service'
import { ProductPageConfig } from '../../pages/cart/services/products-page-config'

@Injectable()
export class ChosePlanIncompleteGuard  {
  initialPaths = `/${RouteHeaderUrl.apps}`
  plansThatCantSeeStripe = inject(ChosePlanCompleteGuard)?.hideStripePlansFrom
  forceStripePricing = inject(CartService).forceStripePricing
  productPageConfig = new ProductPageConfig()

  constructor(
    private store: Store<StoreState>,
    private logger: Logger,
    private router: Router,
    private safeLocalStorageService: SafeLocalStorageService,
  ) {
  }

  check(next?: ActivatedRouteSnapshot, state?: RouterStateSnapshot) {
    this.store.dispatch(new ShowLoading(LoadingLabel.ChoseInitialPlanIncompleteGuard))
    return forkJoin([
      this.userChoseInitialPlanCompleted$,
      this.userInfo$,
    ]).pipe(
      map(([choseInitialPlan, userInfo]) => {
        this.logger.log(<LogLabel>'signup-incomplete-guard', 'choseInitialPlan: ', choseInitialPlan)
        const allowedPaths = Object.values(this.productPageConfig.stripePricingPaths)
        const isCustomStripePath = allowedPaths.some(p => state?.url?.includes(p))
        const canSeeStripePlans = !this.plansThatCantSeeStripe?.includes(userInfo?.shop?.profile?.plan_name)
        if ((isCustomStripePath && canSeeStripePlans) || this.forceStripePricing) {
          return true
        }
        if (choseInitialPlan) {
          if (next?.data?.userType === 'returning') {
            this.router.navigateByUrl(this.initialPaths)
          } else {
            this.router.navigate([this.initialPaths], { queryParams: { onboarding: true } })
          }
        }
        if (this.getAdminNavOverride(userInfo)) {
          return true
        } else {
          return !choseInitialPlan
        }
      }),
      finalize(() => this.store.dispatch(new HideLoading(LoadingLabel.ChoseInitialPlanIncompleteGuard))),
    )
  }

  get userChoseInitialPlanCompleted$(): Observable<boolean> {
    return this.store.pipe(
      select(getUserChoseInitialPlanCompleted),
      filter(completed => completed !== undefined),
      first(),
    )
  }

  get userInfo$(): Observable<UserInfo> {
    return this.store.pipe(
      select(getUserInfo),
      filter(info => info !== undefined && !!info.created_at),
      first(),
    )
  }

  // If it's an admin that choose to override navigation for this specific user, let them go
  getAdminNavOverride(userInfo): boolean {
    const overrideForAdmin = this.safeLocalStorageService.getItem('one-override-uninstalled-behavior')
    return overrideForAdmin === userInfo?.id
  }

  canActivate(route, state): Observable<boolean> {
    return this.check(route, state)
  }

  canActivateChild(route, state): Observable<boolean> {
    return this.check(route, state)
  }
}
