import { EmailAutomationTypeEnum } from "../../../pages/emails/components/email-automations/enums/email-automation-type.enum"
import { MarketingEmailType } from "../../models/campaign/marketing-email"
import { BroadcastNewsletterEmailType } from "../../../pages/email-builder/models/email-editor.models"

export const OneBackupKeyPrefix = 'one-email-backup-'

export const OneBackupInitialStructure: OneBackup = Object.freeze({
  messaging: {
    emails: {
      [EmailAutomationTypeEnum.Birthday]: [],
      [EmailAutomationTypeEnum.Upsell]: [],
      [EmailAutomationTypeEnum.WinBack]: [],
      [EmailAutomationTypeEnum.CartRecovery]: [],
      [EmailAutomationTypeEnum.WelcomeSubscribers]: [],
      [EmailAutomationTypeEnum.WelcomeCustomers]: [],
      [EmailAutomationTypeEnum.WelcomeAccounts]: [],
      [EmailAutomationTypeEnum.ThankYou]: [],

      [MarketingEmailType.AutoResponderMarketingEmail]: [],
      [MarketingEmailType.ScheduledMarketingEmail]: [],
      [MarketingEmailType.SubscriptionConfirmationEmail]: [],

      [BroadcastNewsletterEmailType.broadcastCoupon]: [],
      [BroadcastNewsletterEmailType.broadcastNewsletter]: [],
    }
  }
})

export interface OneBackupCheck {
  hasUnsavedData: boolean
  unsavedData: Array<{[key: string]: OneBackupRecord}>
}

export interface OneBackupRecord {
  saved_at: string
  type: EmailAutomationTypeEnum | MarketingEmailType | BroadcastNewsletterEmailType
  data: OneBackupRecordData
  temp_id?: string // Used for broadcast emails that has no id upon creation
  name: string
}

// ID is used to identify the backup record
export interface OneBackupRecordData {
  id?: string
  [key: string]: any
}

export interface OneBackup {
  messaging: {
    emails: {
      [EmailAutomationTypeEnum.Birthday]: OneBackupRecord[]
      [EmailAutomationTypeEnum.Upsell]: OneBackupRecord[]
      [EmailAutomationTypeEnum.WinBack]: OneBackupRecord[]
      [EmailAutomationTypeEnum.CartRecovery]: OneBackupRecord[]
      [EmailAutomationTypeEnum.WelcomeSubscribers]: OneBackupRecord[]
      [EmailAutomationTypeEnum.WelcomeCustomers]: OneBackupRecord[]
      [EmailAutomationTypeEnum.WelcomeAccounts]: OneBackupRecord[]
      [EmailAutomationTypeEnum.ThankYou]: OneBackupRecord[]
  
      [MarketingEmailType.AutoResponderMarketingEmail]: OneBackupRecord[]
      [MarketingEmailType.ScheduledMarketingEmail]: OneBackupRecord[]
      [MarketingEmailType.SubscriptionConfirmationEmail]: OneBackupRecord[]
  
      [BroadcastNewsletterEmailType.broadcastCoupon]: OneBackupRecord[]
      [BroadcastNewsletterEmailType.broadcastNewsletter]: OneBackupRecord[]
    }
  }
}
