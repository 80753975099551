import { UserShop } from './user-shop.model'
import { UserSubscription } from './user-subscription.model'

export interface CrispUser {
  email: string
  token: string
  username: string
  bio: string
  image: string
  shop?: UserShop,
  subscription: UserSubscription,
  current_month_usage: number,
}

// Match with crisp bot scenarios name so that we can easily track the event
export enum CrispSessionEvent {
  Resolve_First_App_Convos = 'onboarding:campaign:setup',
  First_Campaign_Published = 'onboarding:campaign:published',
  First_Campaign_Launched = 'onboarding:campaign:launched',
  Plan_Numbers_Look_Off = 'plan:numbers:look:off',
}
