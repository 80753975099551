export interface BrandingData {
  name: string,
  logo: string,
  class?: string,
  title?: string,
  domain?: BrandingDomain,
  canny?: string
  help?: string
  google_login?: boolean
}

export enum BrandingDomain {
  prooffactor = 'prooffactor.com',
  morpheusone = 'morpheusone.com',
  one = 'one.store'
}

export enum BrandingName {
  one = 'ONE',
  onestore = 'OneStore',
  prooffactor = 'Proof Factor',
  morpheus = 'Morpheus'
}

export enum BrandingNameResponse {
  one = 'one',
  onestore = 'onestore',
  prooffactor = 'prooffactor',
  morpheus = 'morpheus'
}

export enum BrandingWord {
  poweredby = 'Powered by',
  createdwith = 'Created with',
  madewith = 'Made with',
  verifiedby = 'Verified by',
}

export enum PopupBrandingWordResponse {
  poweredby = 'powered_by',
  createdwith = 'created_with',
  madewith = 'made_with',
  verifiedby = 'verified_by',
}

export enum SpBrandingWordResponse {
  verifiedby = 'verified_by',
  verified = 'verified',
}
