export enum PlansPageVersion {
  Old = 'old',
  New1 = 'new',
  New2 = 'new-2',
  New3 = 'new-3',
  // new plans naming convention, v3 represents the fact that this page uses plan.version === 3 plans
  // the view 1 at the end is in case we have several plans pages using the same plans version
  // this is to avoid cases like new-3 where plan.version used is 2 & it's a bit confusing
  // leaving old plans page versions as is for backward compatibility
  Version3View1 = 'v3-view-1',
}

export enum PlansVersion {
  V0 = 0,
  V1 = 1,
  V2 = 2,
  V3 = 3,
}

export enum PlansUsageLimit {
  usage_plans_limited = 'usage_plans_limited',
  usage_plans_all = 'usage_plans_all',
}
