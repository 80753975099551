import { PipeTransform } from '@angular/core';
import { CampaignDisplayStatus } from '../../../shared/models/campaign/campaign';
import { CouponCodeCampaign, CouponStatus } from './coupon-code'
import { GiftCardStatus } from '../../gift-cards/models/gift-card'

export interface FilterOption {
  value: CouponStatus | GiftCardStatus | CampaignDisplayStatus
  title: string
  tableTitle?: string
  shopOnly?: boolean
}

export interface CampaignFilterOption {
  value: CampaignDisplayStatus
  title: string
  tableTitle?: string
  shopOnly?: boolean
}

export interface RewardsTableConfig {
  name: string
  title: string
  type?: 'button'
  buttonText?: string
  tooltipText?: string
  class?: string
  thClass?: string
  shopOnly?: boolean
  pipe?: [PipeTransform, string | number]
  sticky?: boolean
}

export interface CouponCodeStats {
  num_awarded: number
  num_redeemed: number
  total_revenue: number
  redemption_rate: number
}

export interface LinkCouponCodesResponse {
  plugins: CouponCodeCampaign[],
  total_count: number,
  total_pages: number,
}

export interface LinkCouponCodeRequestParams {
  page: number,
  limit: number,
  status: CampaignDisplayStatus,
  start?: string,
  end?: string
}

export interface LinkGiftCardRequestParams {
  page: number,
  limit: number,
  status: CampaignDisplayStatus,
  start?: string,
  end?: string
}

export enum RewardsType {
  coupon_code = 'coupon_code',
  gift_card = 'gift_card',
  all = 'all'
}

export enum RewardsStatus {
  all = 'all',
  allExceptArchived = 'all_except_archived',
  archived = 'archived',
  active = 'active',
  disabled = 'disabled',
}
