export interface BillingState {
  valid: boolean,
  source: string,
  error: string,
}


export const initialBillingState: BillingState = {
  valid: false,
  source: null,
  error: null,
}
