import { Injectable, OnDestroy, inject } from '@angular/core'
import { BehaviorSubject, Observable, Subscription, concatMap, forkJoin, map, of, tap } from 'rxjs'
import { StripePriceTier, ProductTierNgSelectOption, PageConfigTier, PageConfigCardModel, StripeJotformCheckoutPayload, PageConfigSectionModel, jotformQidMap, JotformCartField, CartLocalPaymentStatus, CartLocalPayedStatuses, StripePromotion, PageConfigCartProduct } from '../models/cart-models'
import { UserService } from '../../../core/services/user.service'
import { ShortNumberPipe } from '../../../shared/pipes/short-number.pipe'
import { ProductTierFormGroup } from '../components/product-tiers-page/product-tiers-page.component'
import { CartApiService } from './cart-api.service'
import { JotformAnswer } from '../../../core/services/api/api-jotform.service'
import { PlansV3Identifier } from '../../../shared/models/subscription-plan.model'
import { SafeLocalStorageService } from '../../../core/services/safe-local-storage.service'
import { ApiService } from '../../../core/services/api/api.service'
import { GettingStartedInteractions } from '../../../shared/models/getting-started/getting-started-interactions.model'
import { Store } from '@ngrx/store'
import { StoreState } from '../../../store/store.state'
import { SetCurrentUserInfo, SetUserChosePlan } from '../../../store/user/user.actions'
import { ApiPaymentService } from '../../../core/services/api/api-payment.service'
import { GettingStartedService } from '../../../core/services/getting-started.service'
import { ProductPageConfig } from './products-page-config'
import { DecimalPipe } from '@angular/common'

@Injectable({providedIn: 'root'})
export class CartService implements OnDestroy {
  private productPageConfig = new ProductPageConfig()
  productsPageConfig$: BehaviorSubject<PageConfigCardModel[]> = new BehaviorSubject(this.productPageConfig.config)
  discountCode$: BehaviorSubject<string> = new BehaviorSubject(null)
  discountPromotion$: BehaviorSubject<StripePromotion> = new BehaviorSubject(null)
  shortNumberPipe = inject(ShortNumberPipe)
  decimalPipe = inject(DecimalPipe)
  freeStripeSubscription = false
  private subscription = new Subscription()
  constructor(
    private userService: UserService,
    private cartApiService: CartApiService,
    private apiService: ApiService,
    private store: Store<StoreState>,
    private apiPaymentService: ApiPaymentService,
    private gettingStartedService: GettingStartedService,
    private safeLocalStorageService: SafeLocalStorageService,
  ) {
  }

  setActiveData(product: any, tierFormGroup: ProductTierFormGroup, pageConfigTier: PageConfigTier) {
    const _product = structuredClone(product)
    _product.quantity = tierFormGroup.usagePlan
    _product.price = tierFormGroup.price
    _product.unit_amount = tierFormGroup.unit_amount
    _product.activeTier = structuredClone(pageConfigTier)
    return _product
  }

  makeNgSelectOptions(tiers: StripePriceTier[], suffix: string): ProductTierNgSelectOption[] {
    const options = []
    tiers?.forEach((t: StripePriceTier, i: number) => {
      if (t?.up_to) {
        let singularSuffix = suffix.slice(0, -1)
        let useSingular = t.up_to === 1 && suffix[suffix.length - 1] === 's'
        let suffixHasS = suffix[suffix.length - 1] === 's'
        options.push({
          value: t.up_to * t.unit_amount,
          unit_amount: t.unit_amount,
          from: 1 + tiers[i - 1]?.up_to || 0,
          up_to: t.up_to,
          // ¯\_(ツ)_/¯
          label: `${this.decimalPipe.transform(t.up_to, '1.0')} ${useSingular ? singularSuffix : suffixHasS ? suffix : suffix + 's'}`,
        })
      }
    })
    return tiers ? options : null
  }

  updatePurchasedProducts(): Observable<any> {
    // Clone the current page config value
    const pageConfigValue = structuredClone(this.productsPageConfig$.value)
    const subscribed = []
    const stripeSubscription = this.userService.userInfo?.stripe_billing?.subscription
    const priceRequests: Observable<any>[] = []
    stripeSubscription?.items?.data?.forEach((item) => {
      if (stripeSubscription.status === 'active') {
        subscribed.push({
          productId: item.price.product,
          priceId: item.price.id,
          quantity: item.quantity
        })
      }
    })
    // Local handling of free plan
    this.freeStripeSubscription = this.userService.userInfo?.subscription?.plan?.payment_gateway_plan_identifier === PlansV3Identifier.NewCustomFree
    // FIXME: Add logic where it would check if the current ONE plan is Free Stripe, then
    // it would place all the free products in the purchasedProduct.
    // If there is no plan and no stripe subscription, 
    // then it would place all the free products in the selectedProduct
    pageConfigValue?.forEach(card => {
      card?.sections?.forEach(section => {
        let match
        // Loop through the products in the section to find the one that was purchased
        section?.products?.forEach(product => {
          const purchased = subscribed?.find(p => p.productId === product.productId)
          if (purchased) {
            match = {
              ...structuredClone(product),
              quantity: purchased.quantity
            }
          }
        })
        // when pageConfigValue already has the selectedProduct, it should not be overwritten
        // (for cases when a free plan user selects something that is not free for the first time)

        // If matched use it, if not, use defaultSelectedProduct logic
        const _product: PageConfigCartProduct = match ? match : this.defaultSelectedProduct(section, subscribed)
        // If it's a user that has no plan yet add to selectedProduct
        let configKey = subscribed?.length || this.freeStripeSubscription ? 'purchasedProduct' : 'selectedProduct'
        section[configKey] = _product
        // Pull tiers and features from the API
        const request = this.cartApiService.getExpandedProductWithPrice(_product.priceId).pipe(
          tap((res) => {
            const unitLabel = res?.product?.unit_label ? `${res.product.unit_label}s` : ''
            section[configKey].tiers = this.makeNgSelectOptions(res?.tiers, unitLabel)
            section[configKey].marketing_features = res?.product?.marketing_features
            section[configKey].metadata = res?.product?.metadata
            section[configKey].unit_label = res?.product?.unit_label
            const tier = res?.tiers?.find(tier => tier?.up_to === null || _product?.quantity <= tier?.up_to)
            const costPerUnit = parseFloat(tier?.unit_amount_decimal)
            if (costPerUnit) {
              section[configKey].unit_amount = costPerUnit
            }
            if (_product?.quantity && costPerUnit) {
              section[configKey].price = _product.quantity * costPerUnit
            } else {
              section[configKey].price = 0
            }
            this.productsPageConfig$.next(pageConfigValue)
          })
        )
        priceRequests.push(request)
      })
    })
    return priceRequests?.length ? forkJoin(priceRequests) : of(null)
  }

  // Required products should be selected by default if they were not purchased yet
  defaultSelectedProduct(
    section: PageConfigSectionModel, 
    subscribed: {productId: string, priceId: string, quantity: number}[]
  ) {
    // If there is a selected product, use it
    if (section.selectedProduct) {
      return structuredClone(section.selectedProduct)
    }
    // If section is required and no product from that section was purchased, use the first product
    const requiredFulfilled = section.products.some(product => {
      return subscribed.find(p => p.productId === product.productId)
    })
    if (section.required && !requiredFulfilled) {
      const product = structuredClone(section.products[0])
      product.quantity = 1
      return product
    }
    // Otherwise just use the first product
    return structuredClone(section.products[0])
  }

  updateDiscountedPrice() {
    const pageConfigValue = structuredClone(this.productsPageConfig$.value)
    const coupon = this.discountPromotion$.value?.coupon
    const restrictions = this.discountPromotion$.value?.restrictions
    let amountOff = 0
    let percentOff = 0
    let minAmount = 0
    let firstTimeTransaction = true
    if (coupon && coupon.valid) {
      amountOff = coupon.amount_off
      percentOff = coupon.percent_off
      minAmount = restrictions.minimum_amount
      firstTimeTransaction = restrictions.first_time_transaction
    }
    pageConfigValue?.forEach(card => {
      card?.sections?.forEach(section => {
        if (section.selectedProduct?.price) {
          if (percentOff) {
            section.selectedProduct.discountedPrice = section.selectedProduct.price - (section.selectedProduct.price * (percentOff * 0.01))
          } else {
            section.selectedProduct.discountedPrice = null
          }
        }
      })
    })
    this.productsPageConfig$.next(pageConfigValue)
  }

  prepareJotformUpdatePayload(
    data: StripeJotformCheckoutPayload,
  ): {[key: string]: string} {
    const payload = {}
    Object.keys(data).forEach(key => {
      const mappedValue = jotformQidMap[key]
      if (mappedValue) {
        payload[mappedValue] = [data[key]]
      }
    })
    return payload
  }

  getPayedStatuses(answers: {[key: string]: JotformAnswer}): Partial<CartLocalPayedStatuses> {
    const results: Partial<CartLocalPayedStatuses> = {}
    const paymentStatusFields = [
      JotformCartField.emails_payed,
      JotformCartField.popups_subscribe_payed,
    ]
    paymentStatusFields.forEach(field => {
      switch (answers?.[field]?.answer?.text) {
        case 'true':
          results[field] = CartLocalPaymentStatus.success
          break
        case 'false':
          results[field] = CartLocalPaymentStatus.pending
          break
        default:
          results[field] = CartLocalPaymentStatus.none
      }
    })
    return results
  }

  public get forceStripePricing() {
    return this.safeLocalStorageService.getItem('forceStripePricing') === 'true'
  }

  clearLocalStoragePageConfig() {
    const localStoragePageConfig = this.safeLocalStorageService.getItem('stripePricingPageConfig')
    if (localStoragePageConfig) {
      this.safeLocalStorageService.removeItem('stripePricingPageConfig')
    }
  }

  setLocalStoragePageConfig() {
    this.safeLocalStorageService.setItem('stripePricingPageConfig', JSON.stringify(this.productsPageConfig$.value))
  }

  restoreLocalStoragePageConfig() {
    const localStoragePageConfig = this.safeLocalStorageService.getItem('stripePricingPageConfig')
    if (localStoragePageConfig) {
      this.productsPageConfig$.next(JSON.parse(localStoragePageConfig) ?? null)
      this.safeLocalStorageService.removeItem('stripePricingPageConfig')
    }
  }

  clearLocalStoragePromotion() {
    const localStoragePromotion = this.safeLocalStorageService.getItem('stripePricingPromotion')
    if (localStoragePromotion) {
      this.safeLocalStorageService.removeItem('stripePricingPromotion')
    }
  }

  setLocalStoragePromotion() {
    this.safeLocalStorageService.setItem('stripePricingPromotion', JSON.stringify(this.discountPromotion$.value))
  }

  restoreLocalStoragePromotion() {
    const localStoragePromotion = this.safeLocalStorageService.getItem('stripePricingPromotion')
    if (localStoragePromotion) {
      this.discountPromotion$.next(JSON.parse(localStoragePromotion) ?? null)
      this.safeLocalStorageService.removeItem('stripePricingPromotion')
    }
  }

  createCustomerUpdateInteractions(): Observable<GettingStartedInteractions> {
    return this.cartApiService.createCustomer({
      email: this.userService.userInfo.email,
      name: `${this.userService.userInfo?.profile?.first_name} ${this.userService.userInfo?.profile?.last_name}`,
    }).pipe(
      concatMap((res) => {
        // Update interactions with the customer id
        return this.apiService.patch('/v1/me', {
          interactions: {
            stripe_billing: {
              customer_id: res.id
            }
          }
        })
      }),
      concatMap((res) => {
        // Get the updated user info
        return this.apiService.get('/v1/me/interactions')
      }),
      tap((res) => {
        if (res?.stripe_billing) {
          // Update the user info in the store
          const userInfo = {
            ...structuredClone(this.userService?.userInfo),
            stripe_billing: res.stripe_billing,
          }
          this.store.dispatch(new SetCurrentUserInfo(userInfo))
        }
      })
    )
  }

  assignFreeStripePlan(): Observable<null> {
    return this.cartApiService.setFreeStripePlan().pipe(
      concatMap(res => this.gettingStartedService.completeStatus().pipe(
        map(() => (res))
      )),
      concatMap((res) => {
        const userInfo = this.userService.userInfo
        this.store.dispatch(new SetCurrentUserInfo({
          ...userInfo,
          subscription: res.result.subscription,
        }))
        this.store.dispatch(new SetUserChosePlan(true))
        return of(null)
      })
    )
  }

  switchStripeSubToFree(subscriptionId: string, lineItems: any[]) {
    return this.cartApiService.setFreeStripePlan().pipe(
      concatMap(() => this.updateSubscription(subscriptionId, lineItems))
    )
  }

  updateSubscription(subscriptionId: string, lineItems: any[]) {
    return this.cartApiService.updateSubscription(subscriptionId, {
      items: lineItems,
      // always_invoice - charges the prorated amount immediately
      // https://docs.stripe.com/billing/subscriptions/upgrade-downgrade#immediate-payment
      proration_behavior: 'always_invoice', 
    })
  }

  isFreePriceId(priceId: string) {
    const freePriceIds = Object.values(this.productPageConfig.freePlanPriceIds)
    return freePriceIds.includes(priceId)
  }
  
  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }
}
