import {Injectable} from '@angular/core'
import {environment} from '../../../../environments/environment'
import {HttpClient, HttpParams} from '@angular/common/http'

import {Observable} from 'rxjs'

@Injectable()
export class ApiService {
  constructor(private http: HttpClient) {
  }

  getObserve(path: string, params: HttpParams = new HttpParams(), observe: 'body' | 'events' | 'response' = 'body'): Observable<any> {
    return this.http.get(`${environment.apiBaseURL}${path}`, {params: params, observe: observe as 'body'})
  }

  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(`${environment.apiBaseURL}${path}`, {params})
  }

  put(path: string, body: Object = {}): Observable<any> {
    return this.http.put(`${environment.apiBaseURL}${path}`, JSON.stringify(body))
  }

  patch(path: string, body: Object = {}): Observable<any> {
    return this.http.patch(`${environment.apiBaseURL}${path}`, JSON.stringify(body))
  }

  post(path: string, body: Object = {}): Observable<any> {
    return this.http.post(`${environment.apiBaseURL}${path}`, JSON.stringify(body))
  }

  delete(path, params = {}): Observable<any> {
    return this.http.delete(`${environment.apiBaseURL}${path}`, {params})
  }

  getFile(path: string, options = {}): Observable<any> {
    return this.http.get(`${path}`, options)
  }
}
