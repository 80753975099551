import { Component, OnInit, ViewChild, ElementRef, Inject, OnDestroy } from '@angular/core'
import { ReactiveFormsModule, UntypedFormControl, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { LoadingOverlayComponent } from '../../loading-overlay/loading-overlay.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface RenameModalConfig {
  title: string
  text?: string
  cancelButton?: {
    text?: string
    classes?: string
  }
  acceptButton?: {
    text?: string
    classes?: string
  }
}

@Component({
    templateUrl: './rename.modal.component.html',
    styleUrls: ['./rename.modal.component.scss'],
    standalone: true,
    imports: [
      CommonModule,
      ReactiveFormsModule,
      LoadingOverlayComponent,
    ]
})
export class RenameModalComponent implements OnInit {
  @ViewChild('input', { static: true }) input: ElementRef

  acceptButton = {
    classes: 'pf-button md filled blue ml-3',
    text: 'Rename'
  }
  cancelButton = {
    classes: 'pf-button md outline gray',
    text: 'Cancel'
  }

  name: UntypedFormControl

  constructor(
    public dialogRef: MatDialogRef<RenameModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    // Set defaults
    if (data.acceptButton) {
      this.acceptButton.classes = data.acceptButton.classes ? data.acceptButton.classes : 'pf-button md filled blue ml-3'
      this.acceptButton.text = data.acceptButton.text ? data.acceptButton.text : 'Rename'
    }
    if (data.cancelButton) {
      this.cancelButton.classes = data.cancelButton.classes ? data.cancelButton.classes : 'pf-button md outline gray'
      this.cancelButton.text = data.cancelButton.text ? data.cancelButton.text : 'Cancel'
    }
  }

  ngOnInit() {
    this.name = new UntypedFormControl(this.data.name, [Validators.required])
    this.input.nativeElement.focus()
  }

  accept() {
    this.name.updateValueAndValidity()
    if (!this.name.errors) {
      this.dialogRef.close(this.name.value)
    }
  }
}
