import { Injectable } from '@angular/core'
import * as _ from 'lodash'
import { forkJoin, Observable } from 'rxjs'
import { ApiService } from '../../../../core/services/api/api.service'
import { SMSKeyword } from '../../../models/sms/keyword.model'
import {
OrganizationPayload,
OrganizationResponse,
SMSAccount,
SMSContactsForm,
SMSOnboardingFlow,
SMSOnboardingStepName,
SMSValidityResponse } from '../models/sms-onboarding.model'
import { map, pluck } from 'rxjs/operators'
import { UserInfo } from '../../../../store/user/user.state'
import { UserService } from '../../../../core/services/user.service'

@Injectable()
export class SMSOnboardingApiService {

  constructor(
    private apiService: ApiService,
    private userService: UserService,
  ) { }

  getOrganization(): Observable<OrganizationResponse> {
    return this.apiService.get('/v1/me/organization')
  }

  saveOrganization(form: OrganizationPayload): Observable<OrganizationResponse> {
    return this.apiService.post('/v1/me/organization', form)
  }

  createSMSAccount(): Observable<SMSAccount> {
    return this.apiService.post('/v1/me/sms_marketing/account')
  }

  getSMSAccount(): Observable<SMSAccount> {
    return this.apiService.get('/v1/me/sms_marketing/account')
  }

  createKeyword(word: string): Observable<SMSKeyword> {
    return this.apiService.post('/v1/me/sms_marketing/keywords', {text: word})
  }

  getKeywords(): Observable<string[]> {
    return this.apiService.get('/v1/me/sms_marketing/used_keywords')
  }

  getAllKeywords(): Observable<SMSKeyword[]> {
    return this.apiService.get('/v1/me/sms_marketing/keywords').pipe(pluck('keywords'))
  }

  getFlow(): Observable<SMSOnboardingFlow> {
    return this.apiService.get('/v1/me/interactions/onboarding_sms')
  }

  getSMSStatus(keywordId: string): Observable<SMSValidityResponse> {
    return this.apiService.get(`/v1/me/sms_marketing/keywords/${keywordId}/verify`)
  }

  assignKeyword(notification_id: string, keyword_id: string): Observable<SMSValidityResponse> {
    return this.apiService.put(`/v1/me/sms_marketing/keywords/${keyword_id}`, {notification_id: notification_id})
  }

  saveFlow(flowData: SMSOnboardingFlow): Observable<SMSOnboardingFlow> {
    const flowRequest = this.apiService.post('/v1/me/interactions/onboarding_sms', {onboarding_sms: flowData})
    // As we store various checkboxes in contacts form endpoint, it should be in sync with flow state
    const officerKeysToUpdate: SMSContactsForm = {}
    // Contacts Terms Of Service
    const pfTos = _.get(flowData[SMSOnboardingStepName.setup_contact], 'form.agree_to_proof_factor_sms_tos')
    if (typeof pfTos !== 'undefined') {
      officerKeysToUpdate.agree_to_proof_factor_sms_tos = pfTos
    }
    // compliance checkboxes
    const tcpa = _.get(flowData[SMSOnboardingStepName.tcpa], 'understand_sufficient_consent')
    if (typeof tcpa !== 'undefined') {
      officerKeysToUpdate.understand_sufficient_consent = tcpa
    }
    const penalties = _.get(flowData[SMSOnboardingStepName.tcpa], 'understand_penalties')
    if (typeof penalties !== 'undefined') {
      officerKeysToUpdate.understand_penalties = tcpa
    }
    const liability = _.get(flowData[SMSOnboardingStepName.tcpa], 'understand_liable_compliance')
    if (typeof liability !== 'undefined') {
      officerKeysToUpdate.understand_liable_compliance = tcpa
    }
    // Terms Of Service
    const tos = _.get(flowData[SMSOnboardingStepName.terms_of_service], 'updated_business_sms_tos')
    if (typeof tos !== 'undefined') {
      officerKeysToUpdate.updated_business_sms_tos = tos
    }

    // Save all if any checkbox exists
    const requests = [flowRequest]
    if (Object.keys(officerKeysToUpdate).length) {
      const officerContactsRequest = this.saveComplianceOfficer(officerKeysToUpdate)
      requests.push(officerContactsRequest)
    }
    // if user agrees to tos (basically finished onboarding)
    // set new_customer_tcpa_flow_type to accepts_marketing
    if (flowData[SMSOnboardingStepName.terms_of_service].valid === false
        && officerKeysToUpdate.agree_to_proof_factor_sms_tos
        && officerKeysToUpdate.understand_sufficient_consent
        && officerKeysToUpdate.understand_penalties
        && officerKeysToUpdate.understand_liable_compliance
        && officerKeysToUpdate.updated_business_sms_tos) {
      const updateUserRequest = this.userService.updateUserProfile({ sms_app_settings: { new_customer_tcpa_flow_type: 'accepts_marketing'} } as UserInfo)
      requests.push(updateUserRequest)
    }
    return forkJoin(requests).pipe(map(([flowResponse, ...rest]) => flowResponse))
  }

  saveComplianceOfficer(form: SMSContactsForm): Observable<SMSContactsForm> {
    return this.apiService.post('/v1/me/compliance_officer_profile', form)
  }

  getComplianceOfficer(): Observable<SMSContactsForm> {
    return this.apiService.get('/v1/me/compliance_officer_profile')
  }
}
