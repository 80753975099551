const is = {
  Quantity: 'Magn',
  Size: 'Stærð',
  Color: 'Litur',
  Material: 'Efni',
  Style: 'Stíll',
  Savings: 'Sparnaður',
  Subtotal: 'Millisamtala',
  Shipping: 'Sendingargjald',
  Taxes: 'Virðisaukaskattur',
  Total: 'Samtals',
  Free: 'Frítt',
  Buy: 'Kaupa',
  'Pay Now': 'Greiða núna',
  'Take the Deal': 'Taka tilboðinu',
  'Add Now': 'Bæta við núna',
  'You will be charged immediately':'Greiðslan fer fram strax',
  'Decline Offer': 'Hafna tilboðinu',
  'No Thanks':'Nei takk',
  '{price}': '{Verð}',
  'Sold Out': 'Uppselt',
  'see more': 'Lesa meira',
  less: 'minna'
};
export {is};
