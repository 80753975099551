<div class="Success-Container">
  <div class="Success-Logo">
    <img src="/app_assets/one-logo.svg" alt="">
  </div>
  <div class="Success-Body">
    <div class="Success-Body-Title">
      Checkout Success!
    </div>
    <div class="Success-Body-Subtitle">
      Your subscription has been updated. Redirecting...
    </div>
  </div>
</div>
