import { Injectable } from '@angular/core'
import {
  ProductsResponse,
  CollectionsResponse,
  ProductsSelectorInterface,
  Product,
  Collection,
} from '../models/Product'
import { BehaviorSubject, Observable, Subject } from 'rxjs'
import { ApiCampaignService } from '../../../../core/services/api/api-campaign.service'
import { UICouponTargetSelection } from '../../../../pages/coupon-codes/models/coupon-code'
import * as _ from 'lodash'
import { map } from 'rxjs/operators'

@Injectable()
export class ProductsListService {
  private _UICouponTargetSelection = UICouponTargetSelection
  public target: UICouponTargetSelection
  public productsOnly: boolean = false
  private selection = new BehaviorSubject<ProductsSelectorInterface>(null)
  private products = new Subject<ProductsResponse>()
  private collections = new Subject<CollectionsResponse>()

  public cachedProducts: Array<Product | Collection> = []
  public cachedCollections: Array<Product | Collection> = []

  constructor(
    private apiCampaignService: ApiCampaignService,
  ) {
  }

  public dataAsObservable(): Observable<ProductsResponse | CollectionsResponse> {
    if (this.target === this._UICouponTargetSelection.entitled_products) {
      return this.products.asObservable()
    } else {
      return this.collections.asObservable()
    }
  }

  public selectionAsObservable(): Observable<ProductsSelectorInterface> {
    return this.selection.asObservable()
  }

  public setSelection(selection: ProductsSelectorInterface) {
    this.selection.next(selection)
  }

  // Store all fetched items
  public updateCachedProducts(items: Array<Product | Collection>) {
    (items || []).map(item => {
      if (!this.cachedProducts.find(_item => _item.unique_id === item.unique_id)) {
        this.cachedProducts.push(item)
      }
    })
  }

  public updateCachedCollections(items: Array<Product | Collection>) {
    (items || []).map(item => {
      if (!this.cachedCollections.find(_item => _item.unique_id === item.unique_id)) {
        this.cachedCollections.push(item)
      }
    })
  }

  prepareCollectionsForSubmit(list: ProductsSelectorInterface): ProductsSelectorInterface {
    const selectedCollections: Set<string> = new Set(_.get(list, 'ids.collections', []))
    const collections: Collection[] = []

    selectedCollections.forEach(itemUniqueID => {
      const match = this.cachedCollections.find(_item => _item.unique_id === itemUniqueID)
      if (match) {
        collections.push(match as Collection)
      }
    })

    return {
      ...list,
      full: {
        ...list.full,
        collections: collections,
      },
    }
  }

  /**
   * Backend stores Products and Variants differently than it's displayed on the frontend,
   * on each select data being transformed according to rules described below
   */
  prepareProductsForSubmit(list: ProductsSelectorInterface): ProductsSelectorInterface {
    const productsToBeKept = [],
      variantsToBeKept = [],
      selectedProducts: Set<string> = new Set(_.get(list, 'ids.products', [])),
      selectedVariants: Set<string> = new Set(_.get(list, 'ids.variants', [])),
      products: Product[] = []

    selectedProducts.forEach(productUniqueID => {
      const match = this.cachedProducts.find(_item => _item.unique_id === productUniqueID)
      if (match) {
        products.push(match as Product)
      }
    })
    // If product has all variant selected -> only product.unique_id should be kept (variant.unique_id removed)
    // If product has some variants selected -> product.unique_id should be removed (variant.unique_id kept)
    for (const product of products) {
      const hasVariants = _.get(product, 'variants', []).length > 0

      // If no variants -> keep it
      if (!hasVariants) {
        productsToBeKept.push(product.unique_id)
      } else {
        const allVariantsChecked = product.variants.every(variant => selectedVariants.has(variant.unique_id))
        // If all variants are checked & variantsOnly is not set -> keep it but ignore variant.unique_id's
        // If no variants are checked -> select the product
        if ((allVariantsChecked && !list.variantsOnly) || selectedVariants.size === 0) {
          productsToBeKept.push(product.unique_id)
        } else {
          // Else keep variants but ignore product.unique_id
          variantsToBeKept.push(...product.variants.filter(variant => selectedVariants.has(variant.unique_id)).map(_variant => _variant.unique_id))
        }
      }
    }

    if (this.productsOnly) {
      return {
        type: list.type,
        productsOnly: true,
        selectMultiple: list.selectMultiple,
        ids: {
          products: Array.from(selectedProducts),
        },
        full: {
          products: products,
        },
      }
    } else {
      return {
        type: list.type,
        selectMultiple: list.selectMultiple,
        autoSelectFirstVariant: list.autoSelectFirstVariant,
        ids: {
          products: productsToBeKept,
          variants: variantsToBeKept,
        },
      }
    }
  }

  /**
   * Since backend stores selected Variants and Products differently,
   * we need to reverse this.prepareProductsForSubmit() function on each component init
   */
  public correctSelectedIfNeeded(
    _products: Set<string>,
    _variants: Set<string>,
    selectMultiple: boolean = true,
    selectMultipleVariants = true,
  ): { products: Set<string>, variants: Set<string> } {
    // Clone to avoid mutations
    const productsSet = new Set(_products)
    const variantsSet = new Set(_variants)
    // Add all Product variants to Set if Product exists in initial Set
    productsSet.forEach((productUniqueId) => {
      const product = this._findProductInCachedProducts(productUniqueId)
      if (product) {
        const everyVariantIsAbsent = product.variants.every(variant => !variantsSet.has(variant.unique_id))
        if (everyVariantIsAbsent && (selectMultiple || selectMultipleVariants)) {
          product.variants.map(variant => variantsSet.add(variant.unique_id))
        }
      }
    })
    // Add parent Product if its variant exists in initial Set
    variantsSet.forEach((variantUniqueId) => {
      const product = this._findProductByVariantUniqueId(variantUniqueId)
      if (product) {
        productsSet.add(product.unique_id)
      }
    })
    return { products: productsSet, variants: variantsSet }
  }

  protected _processVariants(res: ProductsResponse): ProductsResponse {
    if (this.productsOnly) {
      (res.products || []).map(product => {
        delete product.variants
      })
    } else {
      (res.products || []).map(product => {
        if (product.variants && product.variants.length) {

          // Add compare_at_price to products
          // FIXME: Not sure it should be here
          if (product.variants.length === 1 && product.variants[0].compare_at_price) {
            product.compare_at_price = product.variants[0].compare_at_price
          }

          product.variants = product.variants.filter(variant => {
            if (variant.name === 'Default Title') {
              product.inventory_policy = variant?.inventory_policy
              product.inventory_management = variant?.inventory_management
              return false
            }
            return true
          }).map(variant => ({...variant,
            inventory_policy: variant?.inventory_policy,
            inventory_management: variant?.inventory_management}))
        }
      })
    }
    return res
  }

  private _findProductInCachedProducts(unique_id: string): Product {
    return this.cachedProducts.find(product => product.unique_id === unique_id) as Product
  }

  private _findProductByVariantUniqueId(unique_id: string): Product {
    let matchedProduct: Product
    for (const product of this.cachedProducts as Product[]) {
      if (product.variants) {
        const variant = product.variants.find(_variant => _variant.unique_id === unique_id)
        if (variant && variant.product_id) {
          matchedProduct = this.cachedProducts.find(_product => _product.id === variant.product_id) as Product
          break
        }
      }
    }
    return matchedProduct
  }

  public getProductsByUniqueID(
    product_ids: Array<string>,
    variant_ids: Array<string> = [],
    page: number,
    limit: number,
  ): Observable<ProductsResponse> {
    return this.apiCampaignService.getShopProductsByUniqueID(product_ids, variant_ids, page, limit).pipe(map(res => {
      return this._processVariants(res)
    }))
  }

  public getProductsByID(
    product_ids: Array<string>,
    variant_ids: Array<string> = [],
    page: number,
    limit: number,
  ): Observable<ProductsResponse> {
    return this.apiCampaignService.getShopProductsByUniqueID(product_ids, variant_ids, page, limit).pipe(map(res => {
      return this._processVariants(res)
    }))
  }

  public getCollectionsByUniqueID(
    collection_ids: Array<string>,
    page: number,
    limit: number,
  ): Observable<CollectionsResponse> {
    return this.apiCampaignService.getShopCollectionsByUniqueID(collection_ids, page, limit)
  }

  public fetchPage(page: number, limit: number, query?: string) {
    if (this.target === this._UICouponTargetSelection.entitled_products) {
      this.apiCampaignService.getShopProducts(page + 1, limit, query).subscribe((res: ProductsResponse) => {
        res = this._processVariants(res)
        this.updateCachedProducts(res.products)
        this.products.next(res)
      })
    } else {
      this.apiCampaignService.getShopCollections(page + 1, limit, query).subscribe((res: CollectionsResponse) => {
        this.updateCachedCollections(res.collections)
        this.collections.next(res)
      })
    }
  }

  public preCacheProducts(page = 0, limit = 100) {
    this.apiCampaignService.getShopProducts(page + 1, limit).subscribe((res: ProductsResponse) => {
      res = this._processVariants(res)
      this.updateCachedProducts(res.products)
    })
  }

  public preCacheSpecificProducts(productIds, variantIds) {
    this.apiCampaignService.getShopProductsByUniqueID(productIds, variantIds, 1, 999).subscribe((res: ProductsResponse) => {
      res = this._processVariants(res)
      this.updateCachedProducts(res.products)
    })
  }
}
