import { GettingStartedStepName } from './getting-started-step-name.model'
import { GettingStartedCampaignStepConfig } from './getting-started-campaign-step-config'


export const GettingStartedCampaignSteps: { [key in GettingStartedStepName]?: GettingStartedCampaignStepConfig } = {
  [GettingStartedStepName.CampaignName]: {
    name: GettingStartedStepName.CampaignName,
    title: 'Campaign Name',
    route: 'name',
    dependencies: [],
  },
  [GettingStartedStepName.DisplayRules]: {
    name: GettingStartedStepName.DisplayRules,
    title: 'Display Rules',
    route: 'display-rules',
  },
  // [GettingStartedStepName.Language]: {
  //   name: GettingStartedStepName.Language,
  //   title: 'Language',
  //   route: 'language',
  // },
  [GettingStartedStepName.LiveVisitorCount]: {
    name: GettingStartedStepName.LiveVisitorCount,
    title: 'Add Live Visitor Count Notifications',
    route: 'live-visitor-count',
  },
  [GettingStartedStepName.RecentActivity]: {
    name: GettingStartedStepName.RecentActivity,
    title: 'Add Recent Activity Notifications',
    route: 'recent-activity',
    dependencies: [GettingStartedStepName.CaptureRules],
  },
  // [GettingStartedStepName.CaptureRules]: {
  //   name: GettingStartedStepName.CaptureRules,
  //   title: 'Capture rules',
  //   route: 'capture-rules',
  // },
  [GettingStartedStepName.CampaignConfirm]: {
    name: GettingStartedStepName.CampaignConfirm,
    title: 'Review',
    route: 'confirm',
  },
}

const getStepConfigValues = (field: string) => {
  return Object.keys(GettingStartedCampaignSteps).reduce((res, stepName: GettingStartedStepName) => {
    const config = GettingStartedCampaignSteps[stepName]
    res[stepName] = config[field]
    return res
  }, {})
}

export const GettingStartedTitles = getStepConfigValues('title')
export const GettingStartedRoutes = getStepConfigValues('route')
export const GettingStartedCampaignStepDependencies = getStepConfigValues('dependencies')
