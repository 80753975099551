import { CampaignPluginName } from './campaign/campaign'

export const allowedProofPlugins = [
  CampaignPluginName.RecentActivity,
  CampaignPluginName.AggregateActivity,
  CampaignPluginName.LiveVisitorCount,
  CampaignPluginName.AggregateVisitorCount,
  CampaignPluginName.FortuneWheel,
  CampaignPluginName.CouponBoxNotification,
  CampaignPluginName.NewsLetterNotification,
  CampaignPluginName.FreeShipping,
  CampaignPluginName.SalesPopup,
  CampaignPluginName.ProductPopup,
]

export interface ProofPlugin {
  name: string,
  type: CampaignPluginName,
  preview: ProofPluginPreview
  errors: ProofPluginError[],
}

export interface ProofPluginError {
  text: string
  resolveLink?: string
  modalName?: string
  tooltip?: string
  isWarning?: boolean
  fragment?: string
}

export enum ProofPluginPreview {
  Notification = 'notification',
  Wheel = 'wheel',
  Coupon = 'coupon',
  Newsletter = 'newsletter',
  InfoPopup = 'info_popup',
}
