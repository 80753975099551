import { GettingStartedGroupName } from '../../../models/getting-started/getting-started-group-name.model';
import { JFQuestionType, JFWidgetType } from './jotform.model';

export const upsellAccessFormPreset = {
    id: '221934287704460',
    group: GettingStartedGroupName.UpsellAccessForm,
    closeOnSubmit: true,
    header: {
      title: 'ONE | Upsell Access Form',
      subtitle: ''
    },
    description: '',
    questions: [
    ]
  }
