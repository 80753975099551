import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router'
import { PluginConfigureStepName } from '../../pages/campaign-page/plugin-configure/models/plugin-configure.models'
import { ShopifyService } from '../services/shopify.service'
import { HideLoading } from '../../store/loading/loading.actions'
import { Store } from '@ngrx/store'
import { StoreState } from '../../store/store.state'

@Injectable({providedIn: 'root'})
// Drag and drop doesn't work in some browsers on Mac when app is embedded in Shopify.
export class UnlayerDragAndDropGuard  {
  constructor(
    private router: Router,
    private shopifyService: ShopifyService,
    private store: Store<StoreState>,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.shopifyService.unlayerCanWork) {
      return true
    } else {
      // If we're on a Mac and are using one of the specified browsers unlayer's drag and drop will not work in embedded mode
      // So we redirect user to our website and undo the url change
      const marker = state.url.includes('?') ? '&redirectForStandalone' : '?redirectForStandalone'
      // Check only if we're on the configure step, but not on the editor with id
      // This will prevent the infinite window opening loop
      if (window.location.pathname.includes(PluginConfigureStepName.MarketingEmails) && !window.location.pathname.includes(`${PluginConfigureStepName.MarketingEmails}/`)) {
        window.open(`${window.location.origin}/app${state.url}${marker}` , '_blank')
        // Marketing emails loads editor differently, so we need to add a workaround for url change undo
        this.router.navigate([window.location.pathname.replace('/app/', '/')])
      } else {
        window.open(`${window.location.origin}/app${state.url}${marker}` , '_blank')
        this.router.navigate([`/messaging/emails/campaigns`])
      }
      this.store.dispatch(new HideLoading('Navigation'))
      return false
    }
  }
}
