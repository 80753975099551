import {Component} from '@angular/core'
import { AuthService } from '../../core/services/auth.service'
import {Logger} from '../../core/services/logger.service'

@Component({
  styleUrls: ['./reset.component.scss'],
  templateUrl: './reset.component.html',
})
export class ResetComponent {
  errors = []
  email: string
  hasSentEmail = false

  constructor(private authService: AuthService, private logger: Logger) {
  }

  requestPass() {
    this.errors = []
    this.authService.requestPass(this.email).then((response) => {
      this.email = ''
      this.hasSentEmail = true
    }).catch((error) => {
      this.logger.error(null, `error: ${JSON.stringify(error)}`)
      this.errors = [error.message]
    })
  }
}
