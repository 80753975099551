import { Injectable } from '@angular/core'
import { SafeLocalStorageService } from './safe-local-storage.service'

@Injectable({providedIn: 'root'})
export class CrispService {
  constructor(
    private safeLocalStorage: SafeLocalStorageService
  ) { }

  getCrisp() {
    // In case it's an incognito mode and the app is embedded we should not use the crisp because it breaks the app
    if (window['$crisp']?.push && this.safeLocalStorage.localStorageAvailable) {
      return window['$crisp']
    }
    return null
  }
  
}
