const zh_CN = {
  Quantity: '数量',
  Size: '尺寸',
  Color: '颜色',
  Material: '材质',
  Style: '款式',
  Savings: '省下',
  Subtotal: '商品小计',
  Shipping: '运费',
  Taxes: '税',
  Total: '总计',
  Free: '免费',
  Buy: '购买',
  'Pay Now': '马上结帐',
  'Take the Deal': '接受优惠',
  'Add Now': '立即添加',
  'You will be charged immediately': '您将立即被收取费用',
  'Decline Offer': '婉谢优惠',
  'No Thanks': '不用了，谢谢',
  '{price}': '{价格}',
  'Sold Out': '售罄',
  'see more': '看更多',
  less: '较少的',
};
export {zh_CN};
