import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'

@Component({
  templateUrl: './woocommerce.component.html',
  styleUrls: ['./woocommerce.component.scss'],
})
export class WoocommerceComponent implements OnInit {

  domain = ''

  errors: string[] = []
  messages: string[] = []

  isLoading = false

  constructor(protected router: Router) {
  }

  ngOnInit() {
  }

  submit() {
    const valid_domain = this.domain.toLowerCase().startsWith('http') ? this.domain : `http://${this.domain}`
    if (valid_domain.endsWith('/')) {
      window.location.href = `${valid_domain}wp-admin/plugin-install.php?s=prooffactor&tab=search&type=author`
    } else {
      window.location.href = `${valid_domain}/wp-admin/plugin-install.php?s=prooffactor&tab=search&type=author`
    }
  }
}
