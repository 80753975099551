import { Component, forwardRef, Input, OnInit } from '@angular/core'
import { ControlValueAccessor, UntypedFormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms'
import {
  CountryCode,
  getExampleNumber,
  parsePhoneNumberFromString,
  PhoneNumber
} from 'libphonenumber-js'
import examples from 'libphonenumber-js/examples.mobile.json'
import { PhoneInputCountry } from '../models/phone-input.model'
import { COUNTRIES_LIST } from '../../../../countriesList'

@Component({
  selector: 'pf-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PhoneInputComponent),
    multi: true,
  },
  {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => PhoneInputComponent),
    multi: true,
  }]
})

export class PhoneInputComponent implements ControlValueAccessor, OnInit {
  @Input() required = true
  @Input() patternErrorText = null
  @Input() example = true
  @Input() invalid = false
  @Input() set codeAutoSet(value: string) {
    if (value?.length > 0) {
      this.countryCode = value.toUpperCase()
      const country = this.countries.find(c => c.iso2.toUpperCase() === this.countryCode)
      this.onCountrySelected({ country, emitOnChange: false })
    }
  }
  public value: string
  public isValid: boolean = true
  public countryCode: string
  public phoneCode = ''
  public phoneNumber = ''
  public phoneExample: string
  public disabled: boolean = false
  countries: PhoneInputCountry[]
  onChange: Function
  onTouched: Function

  constructor() {

  }

  ngOnInit() {
    this.countries = COUNTRIES_LIST.map((country: any[], index: number) => ({
      name: country[0],
      index: index,
      iso2: `${country[1]}`.toUpperCase(),
      dialCode: country[2],
      priority: country[3] || 0,
      areaCodes: country[4] || null
    }))
    // this.ngControl = this.injector.get(NgControl)
  }

  private saveValue(number: string, emitOnChange = true) {
    if (this.onChange) {
      setTimeout(() => {
        try {
          const parsed = parsePhoneNumberFromString(number)
          this.countryCode = number ? parsed.country : 'US'
          this.phoneNumber = parsed.nationalNumber as string
          number = parsed.number
        } catch (e) {
        }
        // we don't want to make control dirty on init
        if (emitOnChange) {
          this.onChange(number)
        }
        this.writeValue(number)
      })
    }
  }

  public onNumberChanged(phoneNumber: string) {
    this.saveValue(this.phoneCode + phoneNumber)
  }

  public onCountrySelected( event: { country: PhoneInputCountry, emitOnChange: boolean }) {
    this.countryCode = event?.country.iso2.toUpperCase()
    this.phoneCode = `+${event?.country.dialCode}`
    const phoneNumber = getExampleNumber(this.countryCode as CountryCode, examples)
    this.phoneExample = phoneNumber ? phoneNumber.formatInternational() : null
    if (this.phoneExample) {
      this.phoneExample = this.phoneExample.replace(this.phoneCode, '')
    }
    if (!this.phoneNumber && this.value) {
      // use for prefilling phone number
      this.saveValue(this.value, event.emitOnChange)
    } else {
      this.saveValue(this.phoneCode + this.phoneNumber, event.emitOnChange)
    }
    // avoid making control dirty on country code select
    // setTimeout(() => {
    //   this.ngControl.control.markAsPristine()
    // })
  }

  validate(control: UntypedFormControl) {
    const phoneLength = this.phoneNumber.length
    if (!this.required && !phoneLength) {
      return null
    }
    if (this.required && !phoneLength) {
      return { required: true }
    }
    // had to use try/catch here, because if provided string is less than 3 chars long libphonenumber just
    // throws error and the code below crashes
    try {
      const result: PhoneNumber = parsePhoneNumberFromString(control.value)
      this.isValid = result.isValid()
    } catch (e) {
      this.isValid = false
    }
    return this.isValid && phoneLength ? null : { pattern: {actualValue: control.value}, }
  }

  writeValue(value: string) {
    if (value === undefined || value === null) {
      return
    }
    if (value !== this.value) {
      this.value = value
    }
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled
  }

  registerOnChange(fn) {
    this.onChange = fn
  }

  registerOnTouched(fn) {
    this.onTouched = fn
  }
}


