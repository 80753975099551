<div class="pin-button" (click)="pinSideBar.emit()" [class.expanded]="expanded">
  <img
    class="campaign-side-pin-icon"
    src="/app_assets/icons/pin-icon.svg"
    alt=""
    *ngIf="!sideBarPinned"
  >
  <img
    class="campaign-side-pin-icon"
    src="/app_assets/icons/pin-icon-active.svg"
    alt=""
    *ngIf="sideBarPinned"
  >
</div>
