
  export const includedFeaturesMockData = [
    {
      id: 'sms',
      title: 'FREE - 250 SMS Credits',
    },
    {
      id: 'sms-cost',
      title: ' per SMS credit',
      price: {
        old: 0.02,
        new: 0.01,
      }
    },
    {
      id: 'number',
      title: '/month - Toll-Free Number',
      price: {
        old: 2.5,
        new: 0,
      }
    },
    {
      id: 'number',
      title: '/month - 10DLC Number',
      price: {
        old: 1.5,
        new: 0,
      }
    },
    {
      title: 'Unlimited - Opt-In SMS Keywords',
    },
    {
      title: 'Unlimited - Contacts',
    },
    {
      title: '10X Contacts - Email Sends',
    },
    {
      title: 'Unlimited - Pop Up Views',
    },
    {
      title: 'Unlimited - Unique Visitors',
    },
    {
      title: 'Unlimited - Social Proof Notifications',
    },
    {
      title: 'Unlimited - Orders',
    },
    {
      title: 'Unlimited - Products',
    },
    {
      title: 'Unlimited - Unique Coupon Codes',
    },
    {
      title: 'Unlimited - Abandoned Carts Recovered',
    },
    {
      title: '3,000 Holiday, Style & Color Themes',
    },
  ]
