import { trigger, state, style, transition, animate } from '@angular/animations'
import { CurrencyPipe } from '@angular/common'
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import * as _ from 'lodash'
import { Subscription } from 'rxjs'
import { FooterbarNavigationService } from '../../../../../../core/modules/footerbar-navigation/footerbar-navigation.service'
import { ApiCampaignService } from '../../../../../../core/services/api/api-campaign.service'
import { GettingStartedService } from '../../../../../../core/services/getting-started.service'
import { UserService } from '../../../../../../core/services/user.service'
import { ConfirmModalComponent, ConfirmModalConfig } from '../../../../../../shared/components/modals/confirm.modal/confirm.modal.component'
import { SavableComponent } from '../../../../../../shared/components/savable/savable.component'
import { CustomSnackbarService } from '../../../../../../shared/modules/custom-snackbar/custom-snackbar.service'
import { Step, StepLabels } from '../../../../../../shared/modules/steps-panel/interfaces/step.interface'
import { FormsHelperService } from '../../../../../../shared/services/forms.service'
import { SegmentAnalyticsService } from '../../../../../../shared/services/segment-analytics.service'
import { PFValidators } from '../../../../../../shared/services/validators'
import { UpsellDiscountType, UpsellFormatType, UpsellPlacementType, UpsellShippingType, UpsellStatus } from '../../../../models/upsell-page.models'
import { UpsellBuilderStepsService } from '../../../../services/upsell-builder-steps.service'
import { UpsellService } from '../../../../services/upsell.service'
import { languages } from '../../../../../../shared/models/language'
import { supportedLanguages, TranslateUpsellPipe } from '../../../../pipes/translate-upsell.pipe'
import Quill from 'quill'
import { parse } from 'angular-html-parser';
import { UpsellCustomDescriptionObject } from '../../../../models/custom-description.model'
import { ActiveAppCampaignService } from '../../../../../../core/services/active-app-campaign.service'
import { CrispService } from '../../../../../../core/services/crisp.service'
import { MatDialog } from '@angular/material/dialog'

@Component({
  templateUrl: './upsell-design-step.component.html',
  styleUrls: ['./upsell-design-step.component.scss'],
  animations: [
    trigger('expandCollapse', [
      state('false', style({
        height: 0,
        paddingTop: 0,
        paddingBottom: 0,
      })),
      state('true', style({
        height: '*',
      })),
      transition('false <=> true', animate('0.2s ease')),
    ]),
  ],
  providers: [ FormsHelperService ]
})

export class UpsellDesignStepComponent extends SavableComponent implements OnInit, OnDestroy {
  @ViewChild('formRef', {static: false}) formRef: ElementRef

  upsellId = _.get(this.route, 'snapshot.data.upsell.upsellId', null)
  upsell = _.get(this.route, 'snapshot.data.upsell.upsell', null)
  upsellActive = this.upsell?.status ===  UpsellStatus.Live
  variantId = _.get(this.route, 'snapshot.data.upsell.variantId', null)
  variant = _.get(this.route, 'snapshot.data.upsell.variant', null)
  isRootVariant = _.get(this.variant, 'root_variant', true)

  steps: Step[] = [
    {
      label: StepLabels.Offer,
      url: 'offer',
      passed: true,
      active: true,
      disabled: false,
    },
    {
      label: StepLabels.Design,
      url: 'design',
      passed: false,
      active: true,
      disabled: false,
    }
  ]

  sectionOpen = {
    placement: true,
    language: true,
    fields: true,
    banner: true,
    title: false,
    description: false,
    shipping: false,
    countdown: !!this.variant?.timer,
    savings: false,
    quantity: false,
    buttons: false,
    hold_timer: false,
    image: true,
  }

  form: UntypedFormGroup

  placementOptions = [
    {
      value: UpsellPlacementType.ConfirmationPage,
      label: 'After Purchase / Before Order Confirmation'
    }
  ]

  descriptionFormatOptions = [
    {
      value: UpsellFormatType.Default,
      label: 'Original Description'
    },
    {
      value: UpsellFormatType.Truncated,
      label: 'Truncate Description'
    },
    {
      value: UpsellFormatType.Custom,
      label: 'Override Description'
    },
  ]

  upsellFormatType = UpsellFormatType

  shippingTypeOptions = [
    {
      value: UpsellShippingType.Free,
      label: 'Free'
    },
    {
      value: UpsellShippingType.Fixed,
      label: 'Charge for Shipping'
    },
  ]
  upsellShippingType = UpsellShippingType

  holdTimerOptions = [
    { value: 10, label: '10 mins' },
    { value: 15, label: '15 mins' },
    { value: 30, label: '30 mins' },
    { value: 45, label: '45 mins' },
  ]

  declineButtonOptions = [
    {
      value: 'Decline Offer',
      label: 'Decline Offer'
    },
    {
      value: 'No Thanks',
      label: 'No Thanks'
    },
  ]

  previewParams = null

  productPrice = null

  subscription$ = new Subscription()

  crispTimer = null

  postPurchaseAppInUse = true

  userCurrency = this.userService.userInfo?.shop?.profile?.currency || 'USD'
  userCurrencySymbol = this.currencyPipe.transform(1, this.userCurrency).replace(/\d*\.?\d*/g, '')

  paidAcceptButtonOptions = [
    {
      value: 'Pay Now',
      label:  'Pay Now · {price}'
    },
    {
      value: 'Take the Deal',
      label:  'Take the Deal · {price}'
    },
    {
      value: 'Buy',
      label:  'Buy · {price}'
    },
  ]
  freeAcceptButtonOptions = [
    {
      value: 'Add Now · Free',
      label:  'Add Now · Free'
    },
    {
      value: `Add Now · ${this.userCurrencySymbol}0`,
      label:  `Add Now · ${this.userCurrencySymbol}0`
    },
  ]
  acceptButtonOptions = this.paidAcceptButtonOptions
  upsellCrispKey = 'upsell_auto_message'

  languageOptions = languages.filter(lang => supportedLanguages.includes(lang.value))

  imageViewOptions = [
    {
      value: 'default',
      label:  'Main Image Only'
    },
    {
      value: 'arrows',
      label:  'Arrows Only'
    },
    {
      value: 'carousel',
      label:  'Carousel'
    },
  ]

  editorConfig = {
    toolbar: {
      container: [
        ['bold', 'strike', 'link'],
        [{ align: '' }, { align: 'center' }, { align: 'right' }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [
          { 'color': ['rgb(88,88,88)', 'rgb(119,119,119)', 'rgb(12,161,39)', 'rgb(184,135,0)', 'rgb(226,33,32)'] },
          { 'size': ['12px', false, '16px', '18px', '24px'] },
          'clean'
        ],
      ],
      handlers: {
        undo() {
          this.quill.history.undo()
        },
        redo() {
          this.quill.history.redo()
        },
      },
    },
    history: {
      delay: 2000,
      maxStack: 500,
      userOnly: true,
    },
  }
  editorLoaded = false

  constructor(
    private stepService: UpsellBuilderStepsService,
    private footerbarNavigationService: FooterbarNavigationService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private formsHelperService: FormsHelperService,
    private upsellService: UpsellService,
    private apiCampaignService: ApiCampaignService,
    private userService: UserService,
    public dialog: MatDialog,
    private snackbarService: CustomSnackbarService,
    private segmentAnalyticsService: SegmentAnalyticsService,
    private crispService: CrispService,
    public currencyPipe: CurrencyPipe,
    public translateUpsellPipe: TranslateUpsellPipe,
    private gettingStartedService: GettingStartedService,
    private activeAppCampaignService: ActiveAppCampaignService,
  ) {
    super()
    this.stepService.steps$.next(this.steps)
    this.setFooterbarButtons()
    var Size = Quill.import('attributors/style/size');
    Size.whitelist = ['12px', '14px', '16px', '18px', '24px'];
    Quill.register(Size, true);
    this.editorLoaded = true
  }

  ngOnInit(): void {
    this.initForm()
    this.scheduleCrispMessageIfNeeded()

    this.subscription$.add(this.userService.postPurchaseAppInUse().subscribe(res => {
      this.postPurchaseAppInUse = res
    }))
  }

  // if product price is 0, or it is discounted by 100%
  // or by amount that is greater than price
  // and if shipping is free
  // show different accept button options
  adjustAcceptButton() {
    if ((this.productPrice === 0 || (this.variant?.discounted
          && ((this.variant.discount_type === UpsellDiscountType.Percentage && this.variant.discount_value === 100)
           || (this.variant.discount_type === UpsellDiscountType.Fixed && this.variant.discount_value >= this.productPrice))))
        && this.form.get('shipping_type').value === UpsellShippingType.Free) {
      this.acceptButtonOptions = this.freeAcceptButtonOptions
      if (this.form.get('accept_button_text').value.indexOf('Add Now') === -1) {
        this.form.get('accept_button_text').patchValue('Add Now · Free')
        this.updatePreviewParams('accept_button_text')
      }
    } else {
      this.acceptButtonOptions = this.paidAcceptButtonOptions
      if (this.form.get('accept_button_text').value.indexOf('Add Now') !== -1) {
        this.form.get('accept_button_text').patchValue('Pay Now')
        this.updatePreviewParams('accept_button_text')
      }
    }
  }

  initForm() {
    let customDescription = ''
    if (this.variant.description_custom) {
      if (this.isJSON(this.variant.description_custom)) {
        customDescription = JSON.parse(this.variant.description_custom).string
      } else {
        // this is to support old custom descriptions that were saved as normal strings
        customDescription = this.variant.description_custom
      }
    }

    this.form = this.formBuilder.group({
      placement: { value: _.get(this.variant, 'placement', UpsellPlacementType.ConfirmationPage), disabled: true},
      banner_title: [_.get(this.variant, 'banner_title', 'Add this special offer to your order'), [Validators.required, Validators.maxLength(255), PFValidators.noSpaceValidator()]],
      timer_enabled: _.get(this.variant, 'timer', 0) > 0,
      timer: [this.variant?.timer || null, [Validators.min(1), Validators.max(10)]],
      timer_text: [this.variant?.timer_text || 'Exclusive offer expires in:', [Validators.required, Validators.maxLength(100), PFValidators.noSpaceValidator()]],
      label: _.get(this.variant, 'label', false),
      predefined_quantity: _.get(this.variant, 'predefined_quantity', true),
      quantity: [this.variant?.quantity || 1, [Validators.min(1), Validators.max(999)]],
      description_format: this.variant?.description_format || UpsellFormatType.Truncated,
      description_length: [_.get(this.variant, 'description_length', 240), [Validators.min(1), Validators.max(500)]],
      description_custom: [customDescription, [PFValidators.noSpaceValidator()]],
      shipping_type: this.variant?.shipping_type || UpsellShippingType.Free,
      shipping_amount: [+_.get(this.variant, 'shipping_amount', 5.99), [Validators.max(9999999)]], // this is max allowed value on our BE
      shipping_per_unit: this.variant?.shipping_per_unit,
      hold_timer_enabled: _.get(this.variant, 'hold_timer_enabled', false),
      hold_timer_value: this.variant.hold_timer_value || 10,
      accept_button_text: _.get(this.variant, 'accept_button_text', this.acceptButtonOptions[0].value),
      decline_button_text: _.get(this.variant, 'decline_button_text', this.declineButtonOptions[0]),
      locale: this.variant.locale || 'en',
      image_view: this.variant.image_view || 'default',
    })
    if (this.form.get('timer_enabled').value) {
      this.form.get('timer').addValidators(Validators.required)
    }
    if (this.form.get('predefined_quantity').value) {
      this.form.get('quantity').addValidators(Validators.required)
    }
    if (this.form.get('description_format').value === UpsellFormatType.Truncated) {
      this.form.get('description_length').addValidators(Validators.required)
    } else if (this.form.get('description_format').value === UpsellFormatType.Custom) {
      this.form.get('description_custom').addValidators([PFValidators.maxTextLength(500), Validators.required])
    }
    if (this.form.get('shipping_type').value === UpsellShippingType.Fixed) {
      this.form.get('shipping_amount').addValidators([Validators.required, Validators.min(0.00001)])
    }

    this.initPreview()
    this.setSavableData(this.form.getRawValue())
    this.subscription$.add(
      this.form.valueChanges.subscribe(() => {
        this.checkIfChanged()
      })
    )
    // watch for changes to description here
    this.subscription$.add(
      this.form.get('description_custom').valueChanges.subscribe(() => {
        this.updatePreviewParams('description_custom')
      })
    )
  }

  get messageHtml() {
    return `In Shopify Admin (<a href="${this.checkoutPostPurchaseUrl}" target="_blank">Settings</a> > <a href="${this.checkoutPostPurchaseUrl}" target="_blank">Checkout</a> > <a href="${this.checkoutPostPurchaseUrl}" target="_blank">Post-Purchase Page</a>),
    please <strong>manually select ONE app</strong> as the primary post-purchase app in order to show upsell offers page after checkout.
    <br>
    <a href="${this.checkoutPostPurchaseUrl}" class="pf-button red filled sm mt-3 float-right" target="_blank">Update Settings</a>`
  }

  get checkoutPostPurchaseUrl() {
    let storeUrl = this.userService.userInfo?.shop?.store_url
    if (!storeUrl.includes('http')) {
      storeUrl = `https://${storeUrl}`
    }
    return `${storeUrl}/admin/settings/checkout#post-purchase`
  }

  initPreview() {
    const values = this.form.getRawValue()
    this.previewParams = {
      banner_title: values.banner_title,
      timer_enabled: values.timer_enabled,
      timer: values.timer,
      timer_text: values.timer_text,
      label: values.label,
      predefined_quantity: values.predefined_quantity,
      quantity: values.quantity,
      description_format: values.description_format,
      description_length: values.description_length,
      description_custom: values.description_custom,
      shipping_type: values.shipping_type,
      shipping_amount: values.shipping_amount,
      shipping_per_unit: values.shipping_per_unit,
      accept_button_text: values.accept_button_text,
      decline_button_text: values.decline_button_text,
      currency: this.userService.userInfo?.shop?.profile?.currency || 'USD',
      options: [],
      locale: values.locale,
      image_view: values.image_view,
    }
    this.subscription$.add(this.upsellService.getUpsellPreview(this.upsell.id, this.variant.id).subscribe(data => {
      const baseProduct = data?.product_variants?.[0]
      let description = baseProduct?.product_description || '';
      description = _.unescape(description.replace(/<[^>]+>/g, ''))

      const variantOptions: any[] = []
      const isDefaultVariant = data.product_variants?.length === 1 && data.product_variants?.[0]?.product_name === 'Default Title'
        const productImages = data.product_images?.sort((a, b) => a.position - b.position) || []
      if (baseProduct && data.options?.length > 0 && !isDefaultVariant) {
        const availableOptions: any[] = [];
        data.product_variants.forEach((variant: any) => {
          availableOptions.push(...variant.product_name.split(' / '));
          let images = productImages
          if (variant.variant_images?.length > 0) {
            images = productImages.filter(img => img.unique_id !== variant.variant_images[0]?.unique_id)
            images.unshift(variant.variant_images[0])
          }
          variant.variant_images = images
        })
        const selected = baseProduct.product_name.split(' / ');
        for (let i = 0; i < selected.length; i++) {
          if (!data.options[i]) continue
          const optName = Object.keys(data.options[i])[0];
          variantOptions.push({
            name: Object.keys(data.options[i])[0],
            values: data.options[i][optName].map(option => ({
              label: option,
              value: option,
              disabled: !availableOptions.includes(option)
            })),
            selected: selected[i],
            disabled: data.options[i][optName].length === 1,
          });
        }
        this.productPrice = parseFloat(baseProduct?.discounted_price || baseProduct?.original_price || '0')
        this.adjustAcceptButton()
      } else {
        let images = productImages
        if (baseProduct.variant_images?.length > 0) {
          images = productImages.filter(img => img.unique_id !== baseProduct.variant_images[0]?.unique_id)
          images.unshift(baseProduct.variant_images[0])
        }
        baseProduct.variant_images = images
      }
      this.previewParams = {
        ...data,
        ...this.previewParams,
        product_title: baseProduct?.product_title || 'Sample Product',
        product_description: description,
        options: variantOptions,
        selectedVariant: baseProduct
      }
    }))
  }

  updatePreviewParams(key = 'timer') {
    this.previewParams[key] = this.form.get(key).value
  }

  onDescriptionFormatChange() {
    // clear validators first
    this.form.get('description_length').setValidators(null)
    this.form.get('description_custom').setValidators(null)
    switch (this.form.get(`description_format`).value) {
      case UpsellFormatType.Default:
        // do nothing
        break
      case UpsellFormatType.Truncated:
        this.form.get('description_length').patchValue(240)
        this.form.get('description_length').addValidators([Validators.required, Validators.min(1), Validators.max(500)])
        break
      case UpsellFormatType.Custom:
        this.form.get('description_length').patchValue(5)
        this.form.get('description_length').addValidators([Validators.required, Validators.min(1), Validators.max(6)])
        this.form.get('description_custom').addValidators([PFValidators.maxTextLength(500), Validators.required])
        if (!this.form.get('description_custom').value) {
          this.form.get('description_custom').patchValue((this.previewParams.product_description || ''))
          this.updatePreviewParams('description_custom')
        }
        break
    }
    // update validators last
    this.form.get('description_length').updateValueAndValidity()
    this.form.get('description_custom').updateValueAndValidity()
    this.updatePreviewParams(`description_length`)
    this.updatePreviewParams(`description_format`)
  }

  onShippingTypeChange() {
    if (this.form.get('shipping_type').value === UpsellShippingType.Fixed) {
      this.form.get('shipping_amount').addValidators([Validators.required, Validators.min(0.00001)])
    } else {
      this.form.get('shipping_amount').removeValidators([Validators.required, Validators.min(0.00001)])
      if (!this.form.get('shipping_amount').value) {
        this.form.get('shipping_amount').patchValue(5.99)
        this.updatePreviewParams('shipping_amount')
      }
      this.form.get('shipping_amount').updateValueAndValidity()
    }
    this.updatePreviewParams('shipping_type')
    this.adjustAcceptButton()
  }

  onCountdownTimerToggle(event) {
    if (!event.value) {
      this.form.get('timer').removeValidators(Validators.required)
      this.form.get('timer').patchValue(null)
      this.sectionOpen.countdown = false
      this.sectionOpen.hold_timer = true
    } else {
      this.form.get('timer').patchValue(5)
      this.form.get('timer').addValidators(Validators.required)
      this.form.get('hold_timer_enabled').patchValue(false)
      this.sectionOpen.countdown = true
    }
    this.updatePreviewParams('timer')
    this.updatePreviewParams('timer_enabled')
  }

  onSavingsLabelToggle(event) {
    if (event?.value && !this.variant?.discounted) {
      this.snackbarService.showWarning({
        title: 'No Discount Applied',
        text: 'To display how much the customer would save on the upsell offer, you must go to the previous step and set a discount.'
      })
      this.form.get('label').patchValue(false)
    }
    this.updatePreviewParams('label')
  }

  onPredefinedQuantityToggle(event) {
    if (!event?.value) {
      this.form.get('quantity').removeValidators(Validators.required)
      this.form.get('quantity').patchValue(1)
    } else {
      this.form.get('quantity').addValidators(Validators.required)
    }
    this.updatePreviewParams('predefined_quantity')
    this.updatePreviewParams('quantity')
  }

  showInfoModal() {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      width: '560px',
      data: {
        title: 'Enable ONE as your primary post-purchase app',
        html: `<strong>Important: </strong> You must manually select ONE to be your
              primary post-purchase app in order to offer upsells after checkout. Go to the
              <strong>Shopify Checkout Settings</strong> →
              <strong>"Post-purchase Page" section</strong> →
              then select the <strong>ONE</strong> app.`,
        acceptButton: {
          text: 'Enable',
          classes: 'pf-button filled blue',
        },
        footerClass: 'd-flex justify-content-end'
      } as ConfirmModalConfig,
    })

    this.subscription$.add(dialogRef.afterClosed().subscribe((result: string) => {
      if (result) {
        let url = this.userService.userInfo?.shop?.store_url
        if (!url.includes('http')) {
          url = `https://${url}`
        }
        window.open(`${url}/admin/settings/checkout#post-purchase`, '_blank')
      }
    }))
  }

  publish() {
    this.subscription$.add(this.userService.postPurchaseAppInUse().subscribe(res => {
      if (res) {
        this.submit(true)
      } else {
        // this.showInfoModal()
        this.snackbarService.showError({
          title: 'Required',
          html: this.messageHtml,
          persist: true,
        },);
        this.submit(false, true)
      }
    }))
  }

  save() {
    this.submit()
  }

  submit(publish = false, quiet = false): void {
    if (this.form.invalid) {
      this.formsHelperService.touchAll(this.form)
      for (let section of Object.keys(this.sectionOpen)) {
        this.sectionOpen[section] = true
      }
      setTimeout(() => {
        this.formRef.nativeElement.dispatchEvent(new Event('scrollToInvalid'))
      }, 100)
      this.snackbarService.showWarning({text: 'Please make sure all required fields are filled correctly before saving!'})
      return
    }

    const values = this.form.getRawValue()

    if (publish) {
      values.status = UpsellStatus.Live
    }
    if (values.description_custom) {
      values.description_custom = this.customDescriptionToJson(values.description_custom)
    }

    this.subscription$.add(
      this.upsellService.updateVariant(this.upsellId, this.variantId, {...this.variant, ...values}).subscribe(res => {
        if (publish) {
          this.publishAndExit()
        } else if (!quiet) {
          this.snackbarService.showSuccess({title: 'Saved', text: 'Upsell Configuration'})
        }
        this.segmentAnalyticsService.track(`Saved Upsell ${this.isRootVariant ? 'Offer' : 'Variant'}`, {
          upsell: {
            id: this.upsell.id,
            name: this.upsell.name,
          },
          variant: {
            id: res.id,
            name: res.name,
            root_variant: res.root_variant
          }
        })
        this.setSavableData(this.form.getRawValue())
        this.checkIfChanged()
      },
      err => {
        this.processError(err)
      }))
  }

  publishAndExit() {
    this.subscription$.add(this.upsellService.updateUpsellStatus(this.upsell, UpsellStatus.Live).subscribe(res => {
      this.snackbarService.showSuccess({title: 'Published', text: 'Checkout Upsell'})
      this.segmentAnalyticsService.track(`Published Upsell Offer`, {
        upsell: {
          id: this.upsell.id,
          name: this.upsell.name,
        }
      })

      this.activeAppCampaignService.refreshTotals()
      this.exit()
    },
    err => {
      this.snackbarService.showSuccess({title: 'Saved', text: 'Upsell Configuration'})
      this.processError(err)
    }))
  }

  processError(err) {
    if (err?.error?.error_code === 'exceeds_max_active_campaigns') {
      return
    }
    // TODO make error more verbose
    this.snackbarService.showError({text: 'Something went wrong'})
  }

  private navigateBackward() {
    if (this.isRootVariant) {
      this.router.navigate([
        `/upsell/${this.upsellId}/offer`
      ])
    } else {
      this.router.navigate([
        `/upsell/${this.upsellId}/variant/${this.variantId}/offer`
      ])
    }
  }

  private exit() {
    this.router.navigate([
      `/upsell`
    ])
  }

  customDescriptionToJson(htmlString) {
    if (!htmlString) {
      return ''
    }
    const { rootNodes } = parse(htmlString)
    if (!rootNodes?.length) {
      return ''
    }
    const descriptionObj = rootNodes.map(node => this.parseNode(node))
    return JSON.stringify({
      string: htmlString,
      object: descriptionObj
    })
  }

  parseNode(node, i = null) {
    if (node.type === 'text') {
      return {
        type: 'text',
        value: node.value
      }
    } else {
      const tmp: UpsellCustomDescriptionObject = {
        type: node.name,
      }
      if (node.attrs?.find(a => a.name === 'style')) {
        tmp.style = node.attrs?.find(a => a.name === 'style')?.value
      }
      if (node.attrs?.find(a => a.name === 'class')) {
        tmp.class = node.attrs?.find(a => a.name === 'class')?.value
      }
      if (node.attrs?.find(a => a.name === 'href')) {
        tmp.href = node.attrs?.find(a => a.name === 'href')?.value
      }
      // for children of ordered list add index
      if (node.name === 'ol') {
        tmp.children = node.children?.map((c, i) => this.parseNode(c, i + 1))
      } else {
        tmp.children = node.children?.map((c) => this.parseNode(c))
      }
      if (i !== null) {
        tmp.index = i
      }
      return tmp
    }
  }

  isJSON(str) {
    try {
      return (JSON.parse(str) && !!str);
    } catch (e) {
      return false;
    }
  }

  private setFooterbarButtons() {
    this.footerbarNavigationService.clearData()

    this.footerbarNavigationService.setPrimaryBtnText('Save')
    this.footerbarNavigationService.setPrimaryBtnDisabled(false)
    this.footerbarNavigationService.setPrimaryBtnAction(this.save.bind(this))

    this.footerbarNavigationService.setTertiaryBtnText('Save and Publish')
    this.footerbarNavigationService.setTertiaryBtnDisabled(false)
    this.footerbarNavigationService.setTertiaryBtnAction(this.publish.bind(this))

    this.footerbarNavigationService.setSecondaryBtnText('Back')
    this.footerbarNavigationService.setSecondaryBtnDisabled(false)
    this.footerbarNavigationService.setSecondaryBtnAction(this.navigateBackward.bind(this))

    this.footerbarNavigationService.setExitBtnText('Exit')
    this.footerbarNavigationService.setExitBtnDisabled(false)
    this.footerbarNavigationService.setExitBtnAction(this.exit.bind(this))
  }

  setSavableData(formValue) {
    this.storeSavableData(formValue)
  }

  checkIfChanged() {
    this.compareSavableData(this.form.getRawValue())
  }

  scheduleCrispMessageIfNeeded() {
    // If upsell automatic message hasn't triggered yet set timeout to show it
    if (!this.gettingStartedService.getKeyValuePairValue(this.upsellCrispKey)) {
      // If user is on this page for more than 5 minutes trigger crisp
      this.crispTimer = setTimeout(() => {
        const crisp = this.crispService.getCrisp()
        if (crisp) {
          crisp.push(['do', 'chat:open'])
          crisp.push(['do', 'message:show', ['text', `Hi there! Do you need any help with setting up your upsell offer?`]])
          // set upsell_auto_message to true so that this user doesn't get it in the future
          this.subscription$.add(this.gettingStartedService.updateKeyValuePair(this.upsellCrispKey, true).subscribe())
        }
      }, 5 * 60 * 1000)
    }
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe()
    this.footerbarNavigationService.clearData()
    if (this.crispTimer) {
      clearTimeout(this.crispTimer)
    }
  }
}
