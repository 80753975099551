import {CampaignEffects} from './campaign/campaign.effects'
import {LoadingEffects} from './loading/loading.effects'
import {BillingEffects} from './billing/billing.effects'
import {UserEffects} from './user/user.effects'
import {MailIntegrationEffects} from './mail-integration/mail-integration.effects'
import { GettingStartedEffects } from './getting-started/getting-started.effects'

export const storeEffects = [
  CampaignEffects,
  LoadingEffects,
  BillingEffects,
  UserEffects,
  MailIntegrationEffects,
  GettingStartedEffects,
]
