import { Injectable } from '@angular/core'
import { ApiService } from './api.service'
import { Observable } from 'rxjs'

@Injectable()
export class ApiSettingsService {
  constructor(
    private apiService: ApiService,
  ) {
  }

  getConfig(): Observable<any> {
    return this.apiService.get('/v1/dashboard_settings')
  }
}
