import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { tap } from 'rxjs/operators'
import { StoreState } from '../../store/store.state'
import { Store } from '@ngrx/store'
import { ShowLoading, HideLoading } from '../../store/loading/loading.actions'
import { Logger } from './logger.service'
import { LoadingLabel } from '../../shared/models/loading/loading-label'
import { LogLabel } from '../../shared/models/logger/log-label.model'

@Injectable()
export class LoadingOverlayService {
  private _overlayTitle = new BehaviorSubject<string>(null)
  private _overlaySubTitle = new BehaviorSubject<string>(null)

  constructor(
    private store: Store<StoreState>,
    private logger: Logger,
  ) {
  }

  set overlayTitle(value) {
    this._overlayTitle.next(value)
  }

  getOverlayTitle() {
    return this._overlayTitle.asObservable()
  }

  set overlaySubTitle(value) {
    this._overlaySubTitle.next(value)
  }

  getOverlaySubTitle() {
    return this._overlaySubTitle.asObservable()
  }


  public wrap(observable$: Observable<any>, label: string | LoadingLabel = 'wrap'): Observable<any> {
    this.logger.log(LogLabel.LoadingService, `Add: ${label}`)
    this.store.dispatch(new ShowLoading(label))
    return observable$.pipe(
      tap(() => {
        this.logger.log(LogLabel.LoadingService, `Remove: ${label}`)
        this.store.dispatch(new HideLoading(label))
      }),
    )
  }


}
