import { Action } from '@ngrx/store'
import { GettingStartedActionTypes } from './getting-started.action-types'
import { Campaign } from '../../shared/models/campaign/campaign'
import { GettingStartedEvent } from '../../shared/models/getting-started/getting-started-event'
import { GettingStartedStatus } from '../../shared/models/getting-started/getting-started-status.model'

export class SetGettingStartedEvents implements Action {
  readonly type = GettingStartedActionTypes.SET_EVENTS

  constructor(public payload: GettingStartedEvent[]) {
  }
}

export class SetGettingStartedStatus implements Action {
  readonly type = GettingStartedActionTypes.SET_STATUS

  constructor(public payload: GettingStartedStatus) {
  }
}

export class SetGettingStartedCompleted implements Action {
  readonly type = GettingStartedActionTypes.SET_COMPLETED

  constructor(public payload?: boolean) {
  }
}

export class SetGettingStartedCampaign implements Action {
  readonly type = GettingStartedActionTypes.SET_CAMPAIGN

  constructor(public payload: Campaign) {
  }
}

export class SetGettingStartedFirstCampaignLaunched implements Action {
  readonly type = GettingStartedActionTypes.SET_FIRSTCAMPAIGN_LAUNCHED

  constructor(public payload?: boolean) {
  }
}

export class SetGettingStartedFirstCampaignSkipped implements Action {
  readonly type = GettingStartedActionTypes.SET_FIRSTCAMPAIGN_SKIPPED

  constructor(public payload?: boolean) {
  }
}

export class SetGettingStartedFirstCampaignId implements Action {
  readonly type = GettingStartedActionTypes.SET_FIRSTCAMPAIGN_ID

  constructor(public payload?: string) {
  }
}

export type GettingStartedActions =
  | SetGettingStartedCompleted
  | SetGettingStartedFirstCampaignLaunched
  | SetGettingStartedFirstCampaignSkipped
  | SetGettingStartedFirstCampaignId
  | SetGettingStartedStatus
  | SetGettingStartedCampaign
  | SetGettingStartedEvents
