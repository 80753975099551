import { Injectable } from '@angular/core'
import { SafeLocalStorageService } from './safe-local-storage.service'

@Injectable()
export class RedirectService {

  tmpStorageLabel = 'pf-tmp-redirect-url'
  storageLabel = 'pf-redirect-url'

  constructor(
    private safeLocalStorageService: SafeLocalStorageService,
  ) {
  }

  public confirmRoute() {
    const redirect = this.safeLocalStorageService.getItem(this.tmpStorageLabel)
    if (redirect && redirect.length) {
      this._cleanupTemporaryRoute()
      this.safeLocalStorageService.setItem(this.storageLabel, redirect)
    }
  }

  public setTemporaryRoute(url: string) {
    this._cleanupRoute()
    this.safeLocalStorageService.setItem(this.tmpStorageLabel, url)
  }

  public check(): string {
    const redirect = this.safeLocalStorageService.getItem(this.storageLabel)
    this._cleanupTemporaryRoute()
    this._cleanupRoute()
    if (redirect && redirect.length) {
      return redirect
    } else {
      return null
    }
  }

  public get temporaryRoute() {
    return this.safeLocalStorageService.getItem(this.tmpStorageLabel)
  }

  private _cleanupTemporaryRoute() {
    this.safeLocalStorageService.removeItem(this.tmpStorageLabel)
  }

  private _cleanupRoute() {
    this.safeLocalStorageService.removeItem(this.storageLabel)
  }
}
