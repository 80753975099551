import {Injectable} from '@angular/core'
import {ApiService} from './api/api.service'

@Injectable()
export class SystemNotificationsService {

  constructor(private apiService: ApiService) {
  }

  getNotifications() {
    return this.apiService.get('/v1/me/system_notifications')
  }
}
