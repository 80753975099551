import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, map, switchMap } from 'rxjs/operators'
import { of } from 'rxjs'
import {
  GetUserCouponsFailure,
  GetUserCouponsSuccess,
  GetUserOnboardingFailure,
  GetUserOnboardingSuccess,
  GetUserShopSuccess,
  GetUserSitesFailure,
  GetUserSitesSuccess,
  GetUserOrdersSuccess,
  GetUserOrdersFailure,
} from './user.actions'
import { UserActionTypes } from './user.action-types'
import { UserService } from '../../core/services/user.service'
import { ApiUserService } from '../../core/services/api/api-user.service'
import { ApiCampaignService } from '../../core/services/api/api-campaign.service'
import { ErrorsService } from '../../core/services/errors.service'

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private userService: UserService,
    private apiUserService: ApiUserService,
    private apiCampaignService: ApiCampaignService,
    private errorService: ErrorsService,
  ) {
  }

  // deprecated, use rewardsService instead
  getUserCouponsRequest$ = createEffect(() => this.actions$.pipe(
    ofType(UserActionTypes.GET_COUPONS_REQUEST),
    switchMap(() =>
      this.apiUserService.getCouponCodes().pipe(
        map(data => new GetUserCouponsSuccess(data.rewards)),
        catchError(err => of(new GetUserCouponsFailure(this.errorService.stringify(err)))),
      ),
    ),
  ))

  getUserSitesRequest$ = createEffect(() => this.actions$.pipe(
    ofType(UserActionTypes.GET_SITES_REQUEST),
    switchMap(() =>
      this.userService.readSites().pipe(
        map(data => new GetUserSitesSuccess(data as any)),
        catchError(err => of(new GetUserSitesFailure(this.errorService.stringify(err)))),
      ),
    ),
  ))

  getUserOrdersRequest$ = createEffect(() => this.actions$.pipe(
    ofType(UserActionTypes.GET_ORDERS_REQUEST),
    switchMap(() =>
      this.apiCampaignService.getShopOrders().pipe(
        map(data => new GetUserOrdersSuccess(data)),
        catchError(err => of(new GetUserOrdersFailure(this.errorService.stringify(err)))),
      ),
    ),
  ))

  getUserShopRequest$ = createEffect(() => this.actions$.pipe(
    ofType(UserActionTypes.GET_USER_SHOP_REQUEST),
    switchMap(() =>
      this.apiUserService.getShop().pipe(
        map(data => new GetUserShopSuccess(data)),
        catchError(err => of(new GetUserSitesFailure(this.errorService.stringify(err)))),
      ),
    ),
  ))

  getUserOnboardingRequest$ = createEffect(() => this.actions$.pipe(
    ofType(UserActionTypes.GET_USER_ONBOARDING_REQUEST),
    switchMap(() =>
      this.apiUserService.getOnboarding().pipe(
        map(data => new GetUserOnboardingSuccess(data)),
        catchError(err => of(new GetUserOnboardingFailure(this.errorService.stringify(err)))),
      ),
    ),
  ))
}
