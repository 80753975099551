import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, Params, Router } from '@angular/router'
import { Logger } from '../../core/services/logger.service'
import { ShowLoading, HideLoading, ResetLoading } from '../../store/loading/loading.actions'
import { StoreState } from '../../store/store.state'
import { Store } from '@ngrx/store'
import { first } from 'rxjs/operators'
import { LoadingLabel } from '../../shared/models/loading/loading-label'
import { forkJoin, Subscription } from 'rxjs'
import { environment } from '../../../environments/environment'
import { PromoService } from '../../shared/modules/promo/services/promo.service'
import { AuthService } from '../../core/services/auth.service'
import { UrlService } from '../../core/services/url.service'
import { UserService } from '../../core/services/user.service'
import { LogLabel } from '../../shared/models/logger/log-label.model'
import {
  GiftCardOnboardingLocalStorage,
} from '../../shared/modules/gift-card-onboarding/models/giftcard-onboarding.model'
import { EnvironmentName } from '../../../environments/models/environment-name.model'
import { ProductPageLocalStorage } from '../../pages/products/models/products.model'
import { RouteHeaderUrl } from '../../shared/components/one-header/header-navigation.model'
import createApp from '@shopify/app-bridge'
import { ShopifyService } from '../../core/services/shopify.service'
import { LoadingOverlayService } from '../../core/services/loading-overlay.service'
import { AngularFireAuth } from '@angular/fire/compat/auth'
import { HttpClient } from '@angular/common/http'
import { SafeLocalStorageService } from '../../core/services/safe-local-storage.service'
import { ShopSwitchService } from '../../shared/components/one-side-nav/one-shop-switch/services/one-shop-switch.service'

@Component({
  templateUrl: './shopify.component.html',
  styleUrls: ['./shopify.component.scss'],
})
export class ShopifyComponent implements OnInit, OnDestroy {
  @ViewChild('shopifyForm', {static: false}) shopifyForm: HTMLFormElement
  domain = ''

  isRegisterPage = false
  isOldRegister = window.location.pathname.includes('register/shopify/old')

  errors: string[] = []
  messages: string[] = []

  isLoading = false
  subscription = new Subscription()
  environment = environment
  isLocalEnv = environment.name === EnvironmentName.DEVELOPMENT

  authForEmbedded = false

  showGoToButton = window.location.hostname !== 'shopify.one.store'

  shopifyLoginURL: string = null

  shopifyUserId: string = null

  constructor(
    private activatedRoute: ActivatedRoute,
    private logger: Logger,
    private authService: AuthService,
    private userService: UserService,
    private urlService: UrlService,
    private http: HttpClient,
    private shopifyService: ShopifyService,
    private store: Store<StoreState>,
    protected router: Router,
    private promoService: PromoService,
    private safeLocalStorageService: SafeLocalStorageService,
    private loadingOverlayService: LoadingOverlayService,
    private shopSwitchService: ShopSwitchService,
    protected afAuth: AngularFireAuth,
  ) {
    this.store.dispatch(new ResetLoading())
    this.subscription.add(this.activatedRoute
      .queryParams
      .subscribe(params => {
        this.logger.log(LogLabel.ShopifyComponent, `params`, params)
        this.handleUrlParams(params)
      }))

    this.subscription.add(this.activatedRoute.url.pipe(first())
      .subscribe(url => this.isRegisterPage = url[0].path === 'register'))

    this.promoService.definePromo({ removeUrlParams: true }, this.userService.userInfo)

    this.subscription.add(
      this.afAuth.authState.subscribe(user => {
        if (user) {
          this.logger.log(LogLabel.ShopifyComponent, 'user-is-logged')
          this.router.navigateByUrl('/')
        } else {
          this.logger.log(LogLabel.ShopifyComponent, 'user-is-anonymous')
        }
      })
    )
  }

  inputEnterPress() {
    if (this.isRegisterPage) {
      this.onRegisterSubmit()
    } else {
      this.onLoginSubmit()
    }
  }

  onLoginSubmit(e?: any) {
    if (e) {
      e.preventDefault()
    }
    this.submitForm()
  }

  submitForm() {
    this.shopifyLoginURL = this.urlService.getShopifyShopLink(this.domain)
    if (!this.domain) {
      return
    }
    this.domain = this.sanitizeDomain().toLowerCase()
    // This is awful
    const form = document.createElement('form')
    form.method = 'GET'
    form.action = this.shopifyLoginURL
    form.target = '_blank'
    form.style.display = 'none'

    const input = document.createElement('input')
    input.type = 'hidden'
    input.name = 'shop'
    if (this.domain.includes('.myshopify.com')) {
      input.value = this.domain
    } else {
      input.value = this.domain + '.myshopify.com'
    }
    form.appendChild(input)
    document.body.appendChild(form)
    form.submit()
  }

  handleUrlParams(_params: Params) {
    const params = Object.assign({}, _params)
    const storedHost = this.safeLocalStorageService.getItem('one_c_host')
    const storedToken = this.safeLocalStorageService.getItem('one_c_token')
    if (!params.custom_token && (!storedToken && !storedHost)) return

    // For cases when we don't have a custom_token and host in the url but we have one_c_host and one_c_token in the local storage (or cookies)
    // Added to support incognito mode in embedded app
    if (storedToken && storedHost && !params.custom_token && !params.host) {
      params['custom_token'] = storedToken
      params['host'] = storedHost
    }

    if (!params.custom_token) return

    let host = params.host
    let shopify_no_redirect = params.shopify_no_redirect
    let custom_token = params.custom_token
    this.shopifyUserId = params.shopify_user_id

    const storeUserId = params?.shopify_store_user_id
    if (storeUserId) {
      this.safeLocalStorageService.setItem(this.shopSwitchService.idKey, storeUserId)
    }

    this.safeLocalStorageService.setItem('one_c_host', params.host)
    this.safeLocalStorageService.setItem('one_c_token', params.custom_token)

    this.logger.log(LogLabel.ShopifyComponent, `host: ${host}`)
    this.logger.log(LogLabel.ShopifyComponent, `custom_token: ${custom_token}`)
    this.logger.log(LogLabel.ShopifyComponent, `shopify_no_redirect: ${shopify_no_redirect}`)

    // const redirectForStandalone = this.safeLocalStorageService.getItem('proof_before_login_url').includes('redirectForStandalone')
    const redirectForStandalone = window.location.search.includes('redirectForStandalone')

    if (environment.shopify?.embedded && !redirectForStandalone) {
      // if host is one.store then this is a login redirect
      if (!this.shopifyService.isEmbedded && (window.location.host.includes('one.store') || window.location.host.includes('prooffactor.local'))) {
        this.authForEmbedded = true
        this.loadingOverlayService.overlaySubTitle = 'Authenticating User'
        this.logger.log(LogLabel.ShopifyComponent, `shopify_embedded_state: Authenticating User`)
      } else if (this.shopifyService.isEmbedded) {
        this.loadingOverlayService.overlaySubTitle = 'Syncing Data'
      }
      if (environment.shopify.clientId && !redirectForStandalone) {
        const app = createApp({
          apiKey: environment.shopify.clientId,
          host: host,
        })
        this.logger.log(LogLabel.ShopifyComponent, `hostOrigin`, app.hostOrigin)
        this.logger.log(LogLabel.ShopifyComponent, `localOrigin`, app.localOrigin)
      }
    }

    this.handleCustomTokenFragment(params.custom_token)
    this.safeLocalStorageService.setItem('pf-shopify-shop-user-id', this.shopifyUserId)
  }

  onPaste(event: ClipboardEvent) {
    setInterval(() => {
      this.domain = this.sanitizeDomain(event.clipboardData.getData('text'))
    })
  }


  sanitizeDomain(_str?: string) {
    let domain = ''
    if (!_str) {
      domain = this.domain.replace('https://', '').replace('http://', '').replace('www.', '')
    } else {
      domain = _str.replace('https://', '').replace('http://', '').replace('www.', '')
    }
    if (domain.split('.myshopify.com').length > 1) {
      domain = domain.split('.myshopify.com')[0]
    }
    return domain
  }

  onRegisterSubmit(e?: any ) {
    if (e) {
      e.preventDefault()
    }
    if (this.isOldRegister) {
      this.submitForm()
    } else {
      window.open(`https://apps.shopify.com/one?utm_source=onestore&utm_medium=register_page&utm_campaign=sign_up&utm_content=button_link&utm_term=${this.domain}`, '_blank')
    }
  }

  handleCustomTokenFragment(token) {
    this.isLoading = true
    this.errors = []
    this.logger.log(LogLabel.ShopifyComponent, `custom_token: ${token}`)
    this.authService.customShopifyToken = token
    if (this.authService.getCurrentUser()) {
      this.store.dispatch(new ShowLoading(LoadingLabel.LogoutCurrentUser))
      this.authService.signOut()
        .finally(() => {
          this.performShopifySignIn(token)
            .finally(() => this.store.dispatch(new HideLoading(LoadingLabel.LogoutCurrentUser)))
        })
    } else {
      this.performShopifySignIn(token)
        .finally(() => this.store.dispatch(new HideLoading(LoadingLabel.LogoutCurrentUser)))
    }
  }

  performShopifySignIn(token: string) {
    this.store.dispatch(new ShowLoading(LoadingLabel.SignInShopify))
    return this.authService.signInWithCustomToken(token)
      .finally(() => {
        if (this.shopifyUserId) {
          this.subscription.add(
            forkJoin([
              this.userService.readUser(),
              this.userService.getShopifyShopUser(this.shopifyUserId)
            ]).subscribe({
              next: ([userInfo, shopifyUserInfo]) => {
                this.logger.log(LogLabel.ShopifyComponent, `info: ${JSON.stringify(userInfo)}`)
                if (shopifyUserInfo.shopify_shop_user?.id && !shopifyUserInfo.shopify_shop_user?.sms_verified) {
                  return this.router.navigateByUrl('/onboarding/phone-verification')
                    .finally(() => this.store.dispatch(new HideLoading(LoadingLabel.SignInShopify)))
                } else {
                  return this.navigateToUrl()
                    .finally(() => this.store.dispatch(new HideLoading(LoadingLabel.SignInShopify)))
                }
              },
              error: (error) => {
                this.store.dispatch(new HideLoading(LoadingLabel.SignInShopify))
                console.error(error)
              }
            })
          )
        } else {
          const userSubscription = this.userService.readUser()
            .subscribe((response) => {
              this.logger.log(LogLabel.ShopifyComponent, `info: ${JSON.stringify(response)}`)
              return this.navigateToUrl()
                .finally(() => this.store.dispatch(new HideLoading(LoadingLabel.SignInShopify)))
            }, (error) => {
              this.logger.error(null, `error: ${JSON.stringify(error)}`)
              this.errors = [error.message]
              this.isLoading = false
            })
          this.subscription.add(userSubscription)
          userSubscription.add(() => this.store.dispatch(new HideLoading(LoadingLabel.SignInShopify)))
        }
      })
  }

  private navigateToUrl() {
    // if this is a redirect from embedded app for login don't return a redirect
    if (this.authForEmbedded || this.shopifyService.isEmbedded) {
      // create an immediately resolved promise so performShopifySignIn will not throw an error trying to apply finally
      return Promise.resolve()
    }
    if (this.safeLocalStorageService.getItem(GiftCardOnboardingLocalStorage.permissions)) {
      return this.router.navigateByUrl(`/onboarding/${RouteHeaderUrl.gift_cards}/permissions`)
    } else if (this.safeLocalStorageService.getItem(ProductPageLocalStorage.redirectUrl)) {
      this.safeLocalStorageService.removeItem(ProductPageLocalStorage.redirectUrl)
      return this.router.navigateByUrl(this.safeLocalStorageService.getItem(ProductPageLocalStorage.redirectUrl) + '?permissions_granted=true')
    } else {
      return this.router.navigateByUrl('/')
    }
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
    this.store.dispatch(new ResetLoading())
    this.loadingOverlayService.overlaySubTitle = null
  }

  captchaToken = null

  onVerify(token: string) {
    this.captchaToken = token
  }

  onExpired(response: any) {
    this.captchaToken = null
  }

  onError(error: any) {
    console.error('error verifying captcha:', error)
  }

}
