import { Product } from "../../../shared/modules/products-selector/models/Product"

export interface LocalProduct extends Product {
  index: number
  variantsCount: number
  inventory_quantity: number
}

export interface LocalProductsResponse {
  products: LocalProduct[],
  total_count: number,
  total_pages: number,
}


export enum ProductTableCellType {
  text = 'text',
  image = 'image',
  link = 'link',
  actions = 'actions'
}

export enum ProductPageLocalStorage {
  redirectUrl = 'pf-products-page-redirect-url',
  productDetails = 'pf-products-page-form-details'
}
