import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import _ from 'lodash'
import { of } from 'rxjs'
import { map, switchMap, tap } from 'rxjs/operators'
import { CouponCodeTargetType, CouponCodeValueType } from '../../../../pages/coupon-codes/models/coupon-code'
import { AutomationType } from '../../../../pages/sms/modules/sms-automations/models/automation'
import { StoreState } from '../../../../store/store.state'
import { SMSService } from '../../../services/sms.service'
import { CampaignCard, CampaignCardValues } from '../models/campaign-cards.model'
import { UserShopType } from '../../../models/user/user-shop-type.model'
import { ApiCampaignService } from '../../../../core/services/api/api-campaign.service'
import { CampaignService } from '../../../../core/services/campaign.service'
import { GettingStartedService } from '../../../../core/services/getting-started.service'
import { UserService } from '../../../../core/services/user.service'
import { SetGettingStartedCampaign } from '../../../../store/getting-started/getting-started.actions'
import { CampaignPluginName, Campaign, CampaignState } from '../../../models/campaign/campaign'
import { GettingStartedGroupName } from '../../../models/getting-started/getting-started-group-name.model'
import { RouteHeaderUrl } from '../../../components/one-header/header-navigation.model'
import { TutorialsService } from '../../../../pages/tutorials/services/tutorials.service'

@Injectable()
export class CampaignCardsHelperService {

  smsAutomationForCard = {
    [CampaignCardValues.SMSUpsell]: AutomationType.upsell,
    [CampaignCardValues.SMSWinback]: AutomationType.winback,
    [CampaignCardValues.SMSBirthday]: AutomationType.birthday,
    [CampaignCardValues.SMSCartRecovery]: AutomationType.cart,
  }

  discountTypeForCard = {
    [CampaignCardValues.FixedAmount]: CouponCodeValueType.fixed_amount,
    [CampaignCardValues.Percentage]: CouponCodeValueType.percentage,
    [CampaignCardValues.FreeShipping]: CouponCodeTargetType.shipping_line,
    [CampaignCardValues.BuyXGetY]: CouponCodeValueType.buy_x_get_y,
  }

  pluginForCard = {
    [CampaignCardValues.FortuneWheel]: CampaignPluginName.FortuneWheel,
    [CampaignCardValues.RecentSalesPop]: CampaignPluginName.RecentActivity,
    [CampaignCardValues.LiveVisitorCount]: CampaignPluginName.LiveVisitorCount,
    [CampaignCardValues.AggregateSalesPop]: CampaignPluginName.AggregateActivity,
    [CampaignCardValues.AggregateVisitorCount]: CampaignPluginName.AggregateVisitorCount,
    [CampaignCardValues.NewsLetterNotification]: CampaignPluginName.NewsLetterNotification,
    [CampaignCardValues.CouponBoxNotification]: CampaignPluginName.CouponBoxNotification,
    [CampaignCardValues.FreeShippingNotification]: CampaignPluginName.FreeShipping,
    [CampaignCardValues.SalesNotification]: CampaignPluginName.SalesPopup,
    [CampaignCardValues.ProductPopup]: CampaignPluginName.ProductPopup,
    [CampaignCardValues.CollectPhoneNumbers]: CampaignPluginName.NewsLetterNotification,
    [CampaignCardValues.CampaignEmails]: CampaignPluginName.NewsLetterNotification,
  }

  videoTutorialAvailable = this.userService?.userInfo.country_code !== 'CN'

  constructor(
    private userService: UserService,
    private smsService: SMSService,
    private gettingStartedService: GettingStartedService,
    private campaignsService: CampaignService,
    private apiCampaignService: ApiCampaignService,
    private tutorialService: TutorialsService,
    private store: Store<StoreState>,
  ) {
  }

  // If shopTypes key not exist, then it will show on both non-shopify and shopify sections for non-shopify users
  // If shopTypes key exist, but is it empty array, then it will show on both non-shopify and shopify sections for shopify users
  // UserShopType.CustomShop is for non-shopify users only
  get campaignCards(): CampaignCard[] {
    return [
      {
        img: '/app_assets/apps/upsell.svg',
        badges: ['Upsell'],
        title: 'Post-Purchase Upsell & Cross Sell',
        description: 'Far more than a sales technique, upsell marketing is a crucial part of a customer engagement strategy  that prioritizes customer satisfaction and retention.',
        value: CampaignCardValues.UpsellCard,
        setup_mins: 5,
        keywords: ['increase customers'],
        shopTypes: [UserShopType.ShopifyShop],
        children_open: false,
        hasTutorial: this.videoTutorialAvailable,
        videoTutorial: {
          tutorialType: 'library',
          currentVideo: this.tutorialService.getVideoById('WZYrIeaZpcY'),
          segmentEvent: 'Upsell'
        },
        children: [
          {
            inToolbox: true,
            toolboxOpen: true,
            toolboxSectionName: 'upsellCheckout',
            toolboxTitle: 'After checkout is completed, immediately show:',
            toolboxChildren: [
              {
                badges: ['Automation'],
                title: 'Upsell Page (One-Click Checkout)',
                description: 'Customers can accept upsell offers with one-click (no need to re-enter payment information).',
                value: CampaignCardValues.UpsellCheckout,
                img: '/app_assets/apps/post-checkout-upsell.svg'
              },
            ],
            shopTypes: [UserShopType.ShopifyShop],
          },
          {
            inToolbox: true,
            toolboxOpen: true,
            toolboxSectionName: 'upsellMessage',
            toolboxTitle: 'After checkout is completed, schedule:',
            toolboxChildren: [
              {
                badges: ['Automation'],
                title: 'Upsell Message (Email)',
                description: 'Recommend products and offer discounts based on recent purchases.',
                value: CampaignCardValues.UpsellEmails,
                img: '/app_assets/themes/emails/presets/upsell.svg'
              },
              {
                badges: ['Automations'],
                title: 'Upsell Message (SMS)',
                description: 'Recommend products and offer discounts based on recent purchases.',
                value: CampaignCardValues.SMSUpsell,
                tcpa_required: true,
                img: '/app_assets/themes/sms/presets/upsell.svg'
              },
            ],
            shopTypes: [UserShopType.ShopifyShop],
          },
        ]
      },
      {
        img: '/app_assets/apps/chat-gpt.svg',
        title: 'ChatGPT-like AI Assistant (Sidekick)',
        description: '',
        value: CampaignCardValues.ChatGPT,
        openUrl: 'one-gpt',
        setup_mins: 1,
        keywords: ['increase customers'],
        shopTypes: [],
        children_open: false,
        children: [],
      },
      {
        img: '/app_assets/apps/cart.svg',
        badges: ['Cart Recovery'],
        title: 'Checkout Abandonment Protector',
        description: 'Send automated Emails and SMS to Reduce Cart Abandonment.',
        value: CampaignCardValues.RecoveryCard,
        setup_mins: 5,
        keywords: ['increase customers'],
        shopTypes: [UserShopType.ShopifyShop],
        children_open: false,
        children: [
          {
            badges: ['Automation'],
            title: 'Email Automation | Cart Recovery',
            description: 'Reduce cart abandonment and drive sales with a reminder email.',
            value: CampaignCardValues.CartRecoveryEmails,
            img: '/app_assets/themes/emails/presets/cart.svg',
            shopTypes: [UserShopType.ShopifyShop],
          },
          {
            badges: ['Automations'],
            title: 'SMS Automation | Cart Recovery',
            description: 'Recover abandon carts more effectively with 98% text message open rates.',
            value: CampaignCardValues.SMSCartRecovery,
            tcpa_required: true,
            img: '/app_assets/themes/sms/presets/cart.svg',
            shopTypes: [UserShopType.ShopifyShop],
          },
        ]
      },
      {
        img: '/app_assets/apps/optin-popups.svg',
        badges: ['Capture Leads', 'Exit Intent', 'Gift Box Teaser'],
        title: 'Opt-in Pop ups',
        description: 'Skyrocket SMS, Email, Birthday lists with 60+ beautifully designed pop up themes, customizable triggers, CSS, JS, teasers, and more.',
        value: CampaignCardValues.CaptureLeads,
        setup_mins: 5,
        keywords: ['grow email list', 'increase customers'],
        shopTypes: [],
        children_open: false,
        hide_title: true,
        hasTutorial: this.videoTutorialAvailable,
        videoTutorial: {
          tutorialType: 'library',
          currentVideo: this.tutorialService.getVideoById('IvJ4nSIHrYA'),
          segmentEvent: 'Popups'
        },
        children: [
          {
            title: 'Gamified Pop ups',
            type: 'separator',
            hasTutorial: this.videoTutorialAvailable,
            videoTutorial: {
              tutorialType: 'library',
              currentVideo: this.tutorialService.getVideoById('IvJ4nSIHrYA'),
              segmentEvent: 'Popups'
            },
            shopTypes: [],
          },
          {
            badges: [{text: '9.3% Opt-In Rate', color: 'purple'}, {text: 'Recommended', color: 'success'}],
            title: 'Spin Wheel',
            description: 'Offer a chance to win prizes and discounts.',
            value: CampaignCardValues.FortuneWheel,
            quickstart: true,
            badges_visible: true,
            img: '/app_assets/themes/plugins/presets/wheel.svg',
            shopTypes: [],
          },
          {
            title: 'Traditional Pop ups',
            type: 'separator',
            shopTypes: [],
          },
          {
            badges: [{text: '5.2% Opt-In Rate', color: 'purple'}, {text: 'Recommended', color: 'success'}],
            title: 'Pop up (With Reward)',
            description: 'Incentives help capture leads and drive higher conversion rates.',
            value: CampaignCardValues.CouponBoxNotification,
            quickstart: true,
            badges_visible: true,
            img: '/app_assets/themes/plugins/presets/bcb.svg',
            shopTypes: [],
          },
          {
            badges: [{text: '3.5% Opt-In Rate', color: 'purple'}],
            title: 'Pop up (No Reward)',
            description: 'Subscribe to newsletters of product updates, news, and VIP promotions.',
            value: CampaignCardValues.NewsLetterNotification,
            quickstart: true,
            badges_visible: true,
            img: '/app_assets/themes/plugins/presets/bsb.svg',
            shopTypes: [],
          },
        ]
      },
      {
        img: '/app_assets/apps/announcement-pop-ups.svg',
        badges: ['Free Shipping', 'Count Down'],
        title: 'Announcement Pop ups',
        description: 'Announcement notifications',
        value: CampaignCardValues.Announcement,
        setup_mins: 5,
        keywords: [],
        shopTypes: [],
        children_open: false,
        hide_title: true,
        hasTutorial: this.videoTutorialAvailable,
        videoTutorial: {
          tutorialType: 'library',
          currentVideo: this.tutorialService.getVideoById('cp2SB-FkaOs'),
          segmentEvent: 'Announcement Popup'
        },
        children: [
          {
            title: 'Announcement Pop ups',
            type: 'separator',
            hasTutorial: this.videoTutorialAvailable,
            videoTutorial: {
              tutorialType: 'library',
              currentVideo: this.tutorialService.getVideoById('cp2SB-FkaOs'),
              segmentEvent: 'Announcement Popup'
            },
          },
          {
            badges: [{text: 'Recommended', color: 'success'}],
            title: 'Free Shipping',
            description: 'Motivate customers to shop by offering free shipping.',
            value: CampaignCardValues.FreeShippingNotification,
            badges_visible: false,
            img: '/app_assets/themes/plugins/presets/free-shipping-pop-up.svg',
            shopTypes: [],
          },
          {
            badges: [{text: 'Recommended', color: 'success'}],
            title: 'Sales Announcement',
            description: 'Notify shoppers about flash sales and upcoming sales',
            value: CampaignCardValues.SalesNotification,
            badges_visible: false,
            img: '/app_assets/themes/plugins/presets/sales-popup.svg',
            shopTypes: [],
          },
          {
            badges: [{text: 'Recommended', color: 'success'}],
            title: 'Product Announcement',
            description: 'Showcase new product drops, restocks, and pre-orders',
            value: CampaignCardValues.ProductPopup,
            badges_visible: false,
            img: '/app_assets/apps/recommendations.svg',
            shopTypes: [UserShopType.ShopifyShop],
          },
        ]
      },
      {
        img: '/app_assets/apps/email-marketing.svg',
        badges: ['Cart Recovery', 'Birthday', 'Recommendations'],
        title: 'Email Marketing',
        description: 'Welcome series, birthdays, cart abandonment, reward reminders, product recommendations, autoresponders and more!',
        value: CampaignCardValues.EmailAutomation,
        setup_mins: 5,
        keywords: ['marketing', 'announcements', 'automation', 'broadcast'],
        shopTypes: [],
        children_open: false,
        hide_title: true,
        hasTutorial: this.videoTutorialAvailable,
        videoTutorial: {
          tutorialType: 'modal',
          currentVideo: this.tutorialService.getVideoById('RMdXZeiOynQ'),
          segmentEvent: 'Email Overview'
        },
        children: [
          // {
          //   title: 'Email Onboarding',
          //   type: 'separator',
          //   hasTutorial: this.videoTutorialAvailable,
          //   videoTutorial: {
          //     tutorialType: 'modal',
          //     currentVideo: this.tutorialService.getVideoById('RMdXZeiOynQ'),
          //     segmentEvent: 'Email Overview'
          //   }
          // },
          // {
          //   badges: [],
          //   title: 'Email Onboarding',
          //   description: 'Initial setup, branding, compliance, and quotas.',
          //   value: CampaignCardValues.EmailOnboarding,
          //   img: '/app_assets/email-onboarding/email-onboarding.svg',
          //   button_text: 'Start',
          // },
          {
            title: 'Email Broadcasts',
            type: 'separator',
            shopTypes: [],
          },
          {
            inToolbox: true,
            toolboxOpen: true,
            toolboxSectionName: 'broadcasts',
            toolboxTitle: 'One-time email blast, schedule:',
            toolboxChildren: [
              {
                badges: ['Automation'],
                title: 'Newsletter Email (With Reward)',
                description: 'Schedule bulk emails with rewards to subscribers.',
                value: CampaignCardValues.BroadcastCoupon,
                img: '/app_assets/themes/emails/presets/discount.svg',
              },
              {
                badges: ['Automation'],
                title: 'Newsletter Email (No Reward)',
                description: 'Schedule bulk emails to subscribers.',
                value: CampaignCardValues.BroadcastNewsletter,
                img: '/app_assets/themes/emails/presets/newsletter.svg',
              },
            ],
            shopTypes: [],
          },
          {
            title: 'Email Automations',
            type: 'separator',
            shopTypes: [UserShopType.ShopifyShop],
          },
          {
            inToolbox: true,
            toolboxOpen: true,
            toolboxSectionName: 'abandonment',
            toolboxTitle: 'When customer abandons cart or checkout, send:',
            toolboxChildren: [
              {
                badges: ['Automation'],
                title: 'Cart Recovery Email',
                description: 'Reduce cart abandonment and drive sales with a reminder email.',
                value: CampaignCardValues.CartRecoveryEmails,
                img: '/app_assets/themes/emails/presets/cart.svg',
              },
            ],
            shopTypes: [UserShopType.ShopifyShop],
          },
          {
            inToolbox: true,
            toolboxOpen: true,
            toolboxSectionName: 'upsell',
            toolboxTitle: 'When customer makes a purchase, send:',
            toolboxChildren: [
              {
                badges: ['Automation'],
                title: 'Upsell Email',
                description: 'Recommend a product to the customer based on what they purchased.',
                value: CampaignCardValues.UpsellEmails,
                img: '/app_assets/themes/emails/presets/upsell.svg',
              },
              {
                badges: ['Automation'],
                title: 'Thank You Email (1st Purchase)',
                description: 'Thank first-time customers with a reward to encourage more purchases and brand loyalty.',
                value: CampaignCardValues.WelcomeNewCustomerCard,
                img: '/app_assets/themes/emails/presets/welcome_customers.svg',
              },
            ],
            shopTypes: [UserShopType.ShopifyShop],
          },
          {
            inToolbox: true,
            toolboxOpen: true,
            toolboxSectionName: 'loyalty',
            toolboxTitle: 'When customer hasn’t bought recently, send:',
            toolboxChildren: [
              {
                badges: ['Automation'],
                title: 'Birthday Email',
                description: 'Build customer loyalty with Happy Birthday gift or discount offer.',
                value: CampaignCardValues.BirthdayEmails,
                img: '/app_assets/themes/emails/presets/birthday.svg',
              },
              {
                badges: ['Automation'],
                title: 'Winback Email',
                description: 'Target customers who have not purchased anything in the specified period.',
                value: CampaignCardValues.WinbackEmails,
                img: '/app_assets/themes/emails/presets/winback.svg',
              },
            ],
            shopTypes: [UserShopType.ShopifyShop],
          },
          {
            inToolbox: true,
            toolboxOpen: true,
            toolboxSectionName: 'welcome',
            toolboxTitle: 'When new customer signs up, send:',
            toolboxChildren: [
              {
                badges: ['Automation'],
                title: 'Thank You Email (Shopify Subscribers)',
                description: 'Thank new customers who subscribed through Shopify footer section, checkout, password, or coming soon pages.',
                value: CampaignCardValues.ThankYou,
                img: '/app_assets/themes/emails/presets/welcome_customers.svg',
              },
              {
                badges: ['Automation'],
                title: 'Welcome Email (All Subscribers)',
                description: 'Welcome and introduce your brand to new customers who subscribed through either ONE+ or Shopify.',
                value: CampaignCardValues.WelcomeNewSubscriberCard,
                img: '/app_assets/themes/emails/presets/welcome_sub.svg',
              },
              {
                badges: ['Automation'],
                title: 'Welcome Email (Shopify Accounts)',
                description: 'Welcome newly created accounts and encourage conversions.',
                value: CampaignCardValues.WelcomeNewAccountsCard,
                img: '/app_assets/themes/emails/presets/welcome_accounts.svg',
              },
            ],
            shopTypes: [UserShopType.ShopifyShop],
          },
          // {
          //   badges: ['Automation'],
          //   title: 'Email Automation | Welcome',
          //   description: 'Send thank you emails & reward redemption reminders.',
          //   value: CampaignCardValues.CampaignEmails,
          //   img: '/app_assets/themes/emails/presets/welcome_sub.svg'
          // },
        ]
      },
      {
        img: '/app_assets/apps/sms-marketing.svg',
        badges: ['Cart Recovery', 'Upsell', 'Recommendations'],
        title: 'SMS Marketing',
        description: 'Has open rates as high as 98%, compared to just 20% for emails! A must have marketing channel at least for your North American customers.',
        value: CampaignCardValues.SMSAutomation,
        setup_mins: 12,
        keywords: [
          'sales', 'sms', 'automation', 'tcpa', 'upsell', 'text', 'subscriptions', 'marketing', 'sms marketing'
        ],
        shopTypes: [UserShopType.ShopifyShop],
        children_open: false,
        hide_title: true,
        children: [
          {
            title: 'SMS Onboarding',
            type: 'separator',
            shopTypes: [UserShopType.ShopifyShop],
          },
          {
            inToolbox: true,
            toolboxOpen: true,
            toolboxSectionName: 'smsOnboardingStart',
            toolboxTitle: 'Required SMS onboarding, start:',
            toolboxChildren: [
              {
                badges: ['TCPA', 'Keywords'],
                title: 'SMS Onboarding',
                description: 'TCPA & GDPR compliance, dedicated SMS phone number, and keywords.',
                value: CampaignCardValues.TCPACompliance,
                button_text: 'Start',
                img: '/app_assets/apps/sms-onboarding.svg',
              },
              {
                badges: ['Pop Ups'],
                title: 'Collect Numbers',
                description: 'Checkout, pop ups, and/or text keyword.',
                value: CampaignCardValues.CollectPhoneNumbers,
                tcpa_required: true,
                img: '/app_assets/apps/collect-numbers.svg',
              },
            ],
            shopTypes: [UserShopType.ShopifyShop],
          },
          {
            title: 'SMS Broadcasts',
            type: 'separator',
            shopTypes: [UserShopType.ShopifyShop],
          },
          {
            inToolbox: true,
            toolboxOpen: true,
            toolboxSectionName: 'broadcasts',
            toolboxTitle: 'One-time SMS blast, schedule:',
            toolboxChildren: [
              {
                badges: ['Broadcasts'],
                title: 'Newsletter SMS (With Reward)',
                description: this.isShopifyShop ? 'Offer discount or gift card to incentivize customers and leads to buy!' : this.isOtherShop ? 'Offer discount to incentivize customers and leads to buy!' : 'Offer discount or download link to incentivize customers and leads to take action!',
                value: CampaignCardValues.SMSBroadcastCoupon,
                tcpa_required: true,
                img: '/app_assets/presets/sms_broadcast_coupon.svg'
              },
              {
                badges: ['Broadcasts'],
                title: 'Newsletter SMS (No Reward)',
                description: 'Give subscribers product and other business updates!',
                value: CampaignCardValues.SMSBroadcastNoCoupon,
                tcpa_required: true,
                img: '/app_assets/presets/sms_broadcast_newsletter.svg'
              },
            ],
            shopTypes: [UserShopType.ShopifyShop],
          },
          {
            title: 'SMS Automations',
            type: 'separator',
            shopTypes: [UserShopType.ShopifyShop],
          },
          {
            inToolbox: true,
            toolboxOpen: true,
            toolboxSectionName: 'abandonment',
            toolboxTitle: 'When customer abandons cart or checkout, send:',
            toolboxChildren: [
              {
                badges: ['Automations'],
                title: 'Cart Recovery SMS',
                description: 'Reduce cart abandonment with 98% text message open rates.',
                value: CampaignCardValues.SMSCartRecovery,
                tcpa_required: true,
                img: '/app_assets/themes/sms/presets/cart.svg'
              },
            ],
            shopTypes: [UserShopType.ShopifyShop],
          },
          {
            inToolbox: true,
            toolboxOpen: true,
            toolboxSectionName: 'upsell',
            toolboxTitle: 'When customer makes a purchase, send:',
            toolboxChildren: [
              {
                badges: ['Automations'],
                title: 'Upsell SMS',
                description: 'Recommend a product to the customer based on what they purchased.',
                value: CampaignCardValues.SMSUpsell,
                tcpa_required: true,
                img: '/app_assets/themes/sms/presets/upsell.svg'
              },
            ],
            shopTypes: [UserShopType.ShopifyShop],
          },
          {
            inToolbox: true,
            toolboxOpen: true,
            toolboxSectionName: 'loyalty',
            toolboxTitle: 'When customer hasn’t bought recently, send:',
            toolboxChildren: [
              {
                badges: ['Automations'],
                title: 'Birthday SMS',
                description: 'Build customer loyalty with Happy Birthday gift or discount offer.',
                value: CampaignCardValues.SMSBirthday,
                tcpa_required: true,
                img: '/app_assets/themes/sms/presets/birthday.svg'
              },
              {
                badges: ['Automations'],
                title: 'WinBack SMS',
                description: 'Win-back customers who have not purchased anything in the specified period',
                value: CampaignCardValues.SMSWinback,
                tcpa_required: true,
                img: '/app_assets/themes/sms/presets/winback.svg'
              },
            ],
            shopTypes: [UserShopType.ShopifyShop],
          },
        ]
      },
      {
        img: '/app_assets/apps/social-proof.svg',
        badges: ['Recent Sales Notifications', 'Live Visitor Count'],
        title: 'Social Proof',
        description: 'Notifications are a genuine way to capture visitor attention, establish trust, and create urgency to buy!',
        value: CampaignCardValues.SocialProof,
        setup_mins: 1,
        keywords: ['marketing', 'socialproof'],
        shopTypes: [],
        children_open: false,
        hasTutorial: this.videoTutorialAvailable,
        videoTutorial: {
          tutorialType: 'modal',
          currentVideo: this.tutorialService.getVideoById('tHP04-CWBXM'),
          segmentEvent: 'Social Proof'
        },
        children: [
          {
            badges: ['Social Proof'],
            title: 'Sales Pop',
            description: 'Show off recent sales history.',
            value: CampaignCardValues.RecentSalesPop,
            quickstart: true,
            setup_mins: 4,
            img: '/app_assets/apps/sales-pop.svg',
            shopTypes: [],
          },
          {
            badges: ['Social Proof'],
            title: 'Aggregate Sales Pop',
            description: 'Show off sales over a time period.',
            value: CampaignCardValues.AggregateSalesPop,
            quickstart: true,
            setup_mins: 4,
            locked_text: 'Upgrade to Use',
            img: '/app_assets/apps/aggregate-sales-pop.svg',
            shopTypes: [],
          },
          {
            badges: ['Social Proof'],
            title: 'Visitor Counter',
            description: 'Real-time count of visitors on your shop.',
            value: CampaignCardValues.LiveVisitorCount,
            quickstart: true,
            setup_mins: 4,
            img: '/app_assets/apps/visitor-counter.svg',
            shopTypes: [],
          },
          {
            badges: ['Social Proof'],
            title: 'Aggregate Visitor Counter',
            description: 'Show off visitors over a time period.',
            value: CampaignCardValues.AggregateVisitorCount,
            quickstart: true,
            setup_mins: 4,
            locked_text: 'Upgrade to Use',
            img: '/app_assets/apps/aggregate-visitor-counter.svg',
            shopTypes: [],
          },
        ],
      },
      {
        img: '/app_assets/apps/spin-wheel.svg',
        badges: ['Gamification'],
        title: 'Gamification',
        description: 'Don’t annoy, but excite customers to opt-in and redeem offers. Over 35,000 ONE+ brands we see an average of 9.3% opt-in rate!',
        value: CampaignCardValues.Gamification,
        setup_mins: 5,
        keywords: ['gamification', 'marketing', 'pop ups'],
        shopTypes: [],
        children_open: false,
        hasTutorial: this.videoTutorialAvailable,
        videoTutorial: {
          tutorialType: 'modal',
          currentVideo: this.tutorialService.getVideoById('IvJ4nSIHrYA'),
          segmentEvent: 'Gamification'
        },
        children: [
          {
            badges: [{text: '9.3% Opt-In Rate', color: 'purple'}, {text: 'Recommended', color: 'success'}],
            title: 'Spin Wheel',
            description: 'Offer a chance to win prizes and discounts.',
            quickstart: true,
            value: CampaignCardValues.FortuneWheel,
            badges_visible: true,
            img: '/app_assets/themes/plugins/presets/wheel.svg',
            shopTypes: [],
          },
        ]
      },
      {
        img: '/app_assets/apps/thank_you.svg',
        badges: ['Thank You'],
        title: 'Thank You',
        description: 'Thank you messages are a great way to get your customers to return to your shop.',
        value: CampaignCardValues.ThankYou,
        setup_mins: 5,
        keywords: ['Thank You'],
        shopTypes: [UserShopType.ShopifyShop],
        children_open: false,
        children: [
          {
            badges: ['Automation'],
            title: 'Thank You | 1st Purchase',
            description: 'Thank first-time customers with a reward to encourage more purchases and brand loyalty.',
            value: CampaignCardValues.WelcomeNewCustomerCard,
            img: '/app_assets/themes/emails/presets/welcome_customers.svg',
            shopTypes: [UserShopType.ShopifyShop],
          },
          {
            badges: ['Automation'],
            title: 'Thank You Email (Shopify Subscribers)',
            description: 'Thank new customers who subscribed through Shopify footer section, checkout, password, or coming soon pages.',
            value: CampaignCardValues.ThankYou,
            img: '/app_assets/themes/emails/presets/welcome_sub.svg',
            shopTypes: [UserShopType.ShopifyShop],
          },
        ]
      },
      {
        img: '/app_assets/apps/welcome.svg',
        badges: ['Welcome'],
        title: 'Welcome',
        description: 'Give your new subscribers, customers, and accounts a warm welcome when they sign up.',
        value: CampaignCardValues.Welcome,
        setup_mins: 5,
        keywords: ['welcome'],
        shopTypes: [],
        children_open: false,
        children: [
          {
            badges: ['Automation'],
            title: 'Welcome | New Subscribers',
            description: 'Welcome new subscribers with an introduction to your brand and encourage conversions.',
            value: CampaignCardValues.WelcomeNewSubscriberCard,
            img: '/app_assets/themes/emails/presets/welcome_sub.svg',
            shopTypes: [],
          },
          {
            badges: ['Automation'],
            title: 'Welcome | New Accounts',
            description: 'Welcome newly created accounts and encourage conversions.',
            value: CampaignCardValues.WelcomeNewAccountsCard,
            img: '/app_assets/themes/emails/presets/welcome_accounts.svg',
            shopTypes: [UserShopType.ShopifyShop],
          },
        ]
      },
      {
        img: '/app_assets/apps/discounts.svg',
        badges: ['Buy X Get Y', 'Unique Codes', 'Buy One Get One'],
        title: 'Discount Codes',
        description: 'Generate, manage, and measure analytics of personalized & unique reward codes.',
        value: CampaignCardValues.Discounts,
        setup_mins: 1,
        keywords: ['sales', 'increase customers', 'coupons', 'rewards'],
        shopTypes: [],
        children_open: false,
        children: [
          {
            badges: ['Discount'],
            title: 'Buy X get Y',
            description: 'Rewards with free gift for buying X.',
            value: CampaignCardValues.BuyXGetY,
            img: '/app_assets/apps/buy-x-get-y.svg',
            shopTypes: [UserShopType.ShopifyShop],
          },
          {
            badges: ['Discount'],
            title: 'Free Shipping',
            description: 'Rewards with free shipping.',
            value: CampaignCardValues.FreeShipping,
            img: '/app_assets/apps/free-shipping.svg',
            shopTypes: [UserShopType.ShopifyShop],
          },
          {
            badges: ['Discount'],
            title: 'Percentage',
            description: 'Rewards with percentage off.',
            value: CampaignCardValues.Percentage,
            img: '/app_assets/apps/percentage-off.svg',
            shopTypes: [UserShopType.ShopifyShop],
          },
          {
            badges: ['Discount'],
            title: 'Fixed Amount',
            description: 'Rewards with fixed amount off.',
            value: CampaignCardValues.FixedAmount,
            img: '/app_assets/apps/amount-off.svg',
            shopTypes: [UserShopType.ShopifyShop],
          },
          {
            badges: ['Discount'],
            title: 'Coupon Code',
            description: 'Use your existing code from other platforms.',
            value: CampaignCardValues.ManualMaster,
            img: '/app_assets/apps/percentage-off.svg',
            shopTypes: [UserShopType.CustomShop],
          },
        ]
      },
      {
        img: '/app_assets/apps/recommendations.svg',
        badges: ['Recommendations'],
        title: 'Product Recommendations',
        description: 'This can meet a visitor based on where they currently are in their buying cycle, and guide them to the right product at the right time.',
        value: CampaignCardValues.RecommendationsCard,
        setup_mins: 5,
        keywords: ['increase customers', 'increase revenue'],
        shopTypes: [UserShopType.ShopifyShop],
        children_open: false,
        children: [
          {
            badges: ['Automation'],
            title: 'Email Automation | Upsell',
            description: 'Recommend a product to the customer based on what they purchased.',
            value: CampaignCardValues.UpsellEmails,
            img: '/app_assets/themes/emails/presets/upsell.svg',
            shopTypes: [UserShopType.ShopifyShop],
          },
          {
            badges: ['Automations'],
            title: 'SMS Automation | Upsell',
            description: 'Recommend products based on recent purchases.',
            value: CampaignCardValues.SMSUpsell,
            tcpa_required: true,
            img: '/app_assets/themes/sms/presets/upsell.svg',
            shopTypes: [UserShopType.ShopifyShop],
          },
        ]
      },
      {
        img: '/app_assets/apps/birthday.svg',
        badges: ['Birthday'],
        title: 'Birthday',
        description: 'If you do it right, your birthday emails can be another great part of your customer\'s amazing day.',
        value: CampaignCardValues.BirthdayCard,
        setup_mins: 5,
        keywords: ['increase customers', 'increase revenue'],
        shopTypes: [],
        children_open: false,
        children: [
          {
            badges: ['Automation'],
            title: 'Email Automation | Birthday',
            description: 'Build customer loyalty with Happy Birthday gift or discount offer.',
            value: CampaignCardValues.BirthdayEmails,
            img: '/app_assets/themes/emails/presets/birthday.svg',
            shopTypes: [],
          },
          {
            badges: ['Automations'],
            title: 'SMS Automation | Birthday',
            description: 'Build customer loyalty with Happy Birthday wishes, gifts or discount offers.',
            value: CampaignCardValues.SMSBirthday,
            tcpa_required: true,
            img: '/app_assets/themes/sms/presets/birthday.svg',
            shopTypes: [UserShopType.ShopifyShop],
          },
        ]
      },
      {
        img: '/app_assets/apps/winback.svg',
        badges: ['Winback', 'Loyalty'],
        title: 'Winback / Loyalty',
        description: 'It can help any marketer regain potential customers and ensure their email list isn\'t being filled up by subscribers who aren\'t interested in the content.',
        value: CampaignCardValues.WinbackCard,
        setup_mins: 2,
        keywords: ['increase customers', 'increase revenue'],
        shopTypes: [UserShopType.ShopifyShop],
        children_open: false,
        children: [
          {
            badges: ['Automation'],
            title: 'Email Automation | Winback',
            description: 'Target customers who have not purchased anything in the specified period.',
            value: CampaignCardValues.WinbackEmails,
            img: '/app_assets/themes/emails/presets/winback.svg',
            shopTypes: [UserShopType.ShopifyShop],
          },
          {
            badges: ['Automations'],
            title: 'SMS Automation | Win-back',
            description: 'Win-back customers who have not purchased anything in the specified period',
            value: CampaignCardValues.SMSWinback,
            tcpa_required: true,
            img: '/app_assets/themes/sms/presets/winback.svg',
            shopTypes: [UserShopType.ShopifyShop],
          },
        ]
      },
      // TODO update after implementing integrations redirects
      // {
      //   img: '/app_assets/apps/sync.svg',
      //   badges: ['Subscriber'],
      //   title: 'Subscriber Sync',
      //   description: 'If you do it right, your marketing emails can be another great part of your customer\'s amazing day.',
      //   value: CampaignCardValues.WinbackCard,
      //   setup_mins: 2,
      //   keywords: ['increase customers', 'increase subscribers'],
      //   children_open: false,
      //   children: [
      //     {
      //       badges: ['Automation'],
      //       title: 'Automation | Winback Email',
      //       description: 'Target customers who have not purchased anything in the specified period.',
      //       value: CampaignCardValues.WinbackEmails,
      //     },
      //   ]
      // },
      {
        img: '/app_assets/apps/gift-cards.svg',
        badges: ['Gift Cards', 'Unique Codes'],
        title: 'Gift Card Codes',
        description: 'Offer e-gift cards to boost conversion rates and customer loyalty.',
        value: CampaignCardValues.GiftCards,
        setup_mins: 1,
        keywords: ['gift cards', 'unique codes', 'giftcards', 'rewards'],
        shopTypes: [UserShopType.ShopifyShop],
        children_open: false,
        children: [
          {
            badges: ['Discount'],
            title: 'Gift Card Onboarding',
            description: 'Educational content about the gift cards and compliance.',
            value: CampaignCardValues.GiftCardsOnBoarding,
            button_text: 'Start',
            locked_text: 'Request Access',
            img: '/app_assets/apps/gift-card-onboarding.svg',
            shopTypes: [UserShopType.ShopifyShop],
          },
          {
            badges: ['Discount'],
            title: 'Fixed Cash Amount',
            description: 'e-Gift card with preloaded cash value',
            value: CampaignCardValues.GiftCardsUnique,
            locked_text: 'Request Access',
            img: '/app_assets/apps/gift-card-fixed.svg',
            shopTypes: [UserShopType.ShopifyShop],
          }
        ]
      },
    ]
  }

  get isShopifyShop(): boolean {
    return _.get(this.userService.userInfo, 'shop.type') === UserShopType.ShopifyShop
  }

  get isOtherShop(): boolean {
    return !!_.get(this.userService.userInfo, 'shop.type')
  }

  get userPremiumEligible(): boolean {
    const isShopifyBudding = this.isShopifyShop && _.get(this.userService.userInfo, 'subscription.plan.payment_gateway_plan_identifier') === 'budding'
    // TODO: check this logic again if [Access to All Apps] is not suitable for free plan users
    const isShopifyNewPremiumUser = this.isShopifyShop && (this.userService.isNewPlanUser || this.userService.isNewPremiumPlanUser)
    return _.get(this.userService.userInfo, 'subscription.premium_themes', false) || isShopifyBudding || isShopifyNewPremiumUser
  }

  get userSMSEligible(): boolean {
    const userInfo = this.userService.userInfo
    return this.smsService.userIsEligible(userInfo)
  }

  get userOverCampaignLimit(): boolean {
    const userInfo = this.userService.userInfo
    const userSubscription = userInfo.subscription
    const isShopifyBudding = _.get(userInfo, 'shop.type') === 'ShopifyShop' && _.get(userInfo, 'subscription.plan.payment_gateway_plan_identifier') === 'budding'
    if (userSubscription && userSubscription.campaigns_limit) {
      return !isShopifyBudding && (userSubscription.campaigns_limit.active > userSubscription.campaigns_limit.limit)
    }
    return false
  }

  getPreviewOrder() {
    return this.apiCampaignService.getShopOrders(5).pipe(
      map(res => {
        if (res.total_count >= 5) {
          return _.sample(res.orders)
        } else {
          return null
        }
      })
    )
  }

  getRedirectUrl(value: CampaignCardValues) {
    switch (value) {
      case CampaignCardValues.BroadcastEmails:
      case CampaignCardValues.BroadcastCoupon:
      case CampaignCardValues.BroadcastNewsletter:
        return `/email-editor/broadcast`
      case CampaignCardValues.BirthdayEmails:
        return `/email-editor/birthday`
      case CampaignCardValues.UpsellEmails:
        return '/email-automation-builder/upsell/theme'
      case CampaignCardValues.WinbackEmails:
        return '/email-automation-builder/winback/theme'
      case CampaignCardValues.CartRecoveryEmails:
        return '/email-automation-builder/cart-recovery/theme'
      case CampaignCardValues.WelcomeNewAccountsCard:
        return `/email-automation-builder/welcome-email/accounts`
      case CampaignCardValues.WelcomeNewCustomerCard:
        return `/email-automation-builder/welcome-email/customers`
      case CampaignCardValues.WelcomeNewSubscriberCard:
        return `/email-automation-builder/welcome-email/subscribers`
      case CampaignCardValues.ThankYou:
        return `/email-automation-builder/welcome-email/thank-you`
      case CampaignCardValues.SMSBroadcastNoCoupon:
        return `/${RouteHeaderUrl.messaging}/${RouteHeaderUrl.sms}/broadcasts/new?type=broadcast`
      case CampaignCardValues.SMSBroadcastCoupon:
        return `/${RouteHeaderUrl.messaging}/${RouteHeaderUrl.sms}/broadcasts/new?type=coupon`
      case CampaignCardValues.SMSUpsell:
      case CampaignCardValues.SMSWinback:
      case CampaignCardValues.SMSBirthday:
      case CampaignCardValues.SMSCartRecovery:
        return `/${RouteHeaderUrl.messaging}/${RouteHeaderUrl.sms}/automations/new?type=${this.smsAutomationForCard[value]}`
      case CampaignCardValues.FixedAmount:
      case CampaignCardValues.Percentage:
      case CampaignCardValues.FreeShipping:
      case CampaignCardValues.BuyXGetY:
        return `/${RouteHeaderUrl.rewards}/${RouteHeaderUrl.coupons}/new?type=${this.discountTypeForCard[value]}`
      case CampaignCardValues.GenericCode:
      case CampaignCardValues.ManualMaster:
        return `/${RouteHeaderUrl.rewards}/${RouteHeaderUrl.coupons}/new?type=${value}`
      case CampaignCardValues.GiftCardsUnique:
        return `/${RouteHeaderUrl.rewards}/${RouteHeaderUrl.gift_cards}/new`
    }
  }

  getSkipCampaignObservable(isGettingStarted: boolean = false) {
    if (isGettingStarted) {
      return this.gettingStartedService.completeStatusAndSkipFirstCampaign()
    } else {
      return of({})
    }
  }

  getCreateCampaignObservable(campaignName: string = '', cardValue: CampaignCardValues, isGettingStarted: boolean = false, launchCampaign: boolean = false) {
    let campaign: Campaign
    let redirectUrl = ''
    return this.campaignsService.createCampaign({ name: campaignName || 'My campaign' } as Campaign).pipe(
      tap((next: Campaign) => {
        campaign = next
        if (isGettingStarted) {
          this.store.dispatch(new SetGettingStartedCampaign(campaign))
          this.gettingStartedService.completeStatusGroups({
            [GettingStartedGroupName.Campaign]: true,
          })
        }
      }),
      switchMap(() => isGettingStarted ? this.gettingStartedService.linkCampaign(campaign) : of({})),
      switchMap(() => this._createPluginIfNeeded(campaign, cardValue, isGettingStarted)),
      switchMap((url) => {
        redirectUrl = launchCampaign ? `/${RouteHeaderUrl.popups}/${RouteHeaderUrl.campaigns}/` : url
        if (isGettingStarted) {
          return launchCampaign ? this.gettingStartedService.completeStatusAndFirstCampaign(campaign.id) : this.gettingStartedService.completeStatus(true)
        }
        return of({})
      }),
      switchMap(() => launchCampaign ? this.campaignsService.setCampaignActive(campaign.id, CampaignState.On) : of({})),
      switchMap(() => of(redirectUrl))
      )
  }

  private _createPluginIfNeeded(campaign: Campaign, cardValue: CampaignCardValues, isGettingStarted: boolean = false) {
    const pluginType = this.pluginForCard[cardValue]
    let redirectUrl = ''
    if (pluginType) {
      redirectUrl = this.campaignsService.getConfigureLink(campaign.id, pluginType)

      const config = _.get(campaign[pluginType].config, 'v1', campaign[pluginType].config)
      const capture_configs = pluginType === CampaignPluginName.RecentActivity && campaign[pluginType] ? campaign[pluginType].capture_configs : undefined

      if (cardValue === CampaignCardValues.CollectPhoneNumbers) {
        redirectUrl += '/fields'
        delete config.form_config.email
        config.form_config.phone = {
          order: 1,
          country: {
            placeholder: {
              text: 'Country',
              enabled: true
            },
            default: 'US',
            flag_enabled: false
          },
          input_placeholder: {
            text: 'Phone',
            enabled: true
          },
          errors: {
            blank: 'Required',
            invalid: 'Phone is invalid',
            duplicated: 'Phone already used'
          },
          tcpa: {
            enabled: true,
            mob_quick_subscribe: false,
            mob_quick_subscribe_text: ''
          },
          duplicate_submissions: {
            allow_duplicating: false
          }
        }
      } else if (cardValue === CampaignCardValues.CampaignEmails) {
        redirectUrl += '/marketing_emails'
      }

      const data = { config, active: true, capture_configs_attributes: capture_configs }
      return this.apiCampaignService.putPlugin(campaign.id, pluginType, data).pipe(
        switchMap(() => of(redirectUrl))
      )
    } else {
      if (cardValue === CampaignCardValues.NeedsUpgrade) {
        redirectUrl = '/choose-plan'
      } else {
        redirectUrl = `/${RouteHeaderUrl.popups}/${RouteHeaderUrl.campaigns}/${campaign.id}/plugin`
      }
      return of(redirectUrl)
    }
  }
}
