<section class="steps-wrapper" [class.expanded]="expanded">
  <div class="steps-desktop">
    <a
      class="step"
      *ngFor="let step of $any(steps); let index = index"
      [ngClass]="{
        'mb-8': index < steps.length - 1,
        'passes': step.passed,
        '_is-invalid': step.invalid,
        'disabled': step.disabled || !stepsClickable,
        'expanded': expanded,
        'multiline': multiline
      }"
      [matTooltip]="step.invalid && step.invalid_message ? step.invalid_message : null"
      matTooltipClass="Tooltip-Box"
      matTooltipPosition="above"
      [routerLink]="step.url"
      [state]="{data: step}"
    >
      <span class="step-index">
        <span class="step-index-wrapper" routerLinkActive="background-blue" [class.background-black]="step.passed">
          <ng-container *ngIf="!step.invalid">
            <span class="step-index-number" *ngIf="!step.passed">{{index + 1}}</span>
            <span class="step-index-mark" *ngIf="step.passed">
              <one-icon name="checkmark-outline"></one-icon>
            </span>
          </ng-container>
          <ng-container *ngIf="step.invalid">
            <span class="step-index-mark">!</span>
          </ng-container>
        </span>
      </span>
      <span class="step-separator">
        <span
          class="step-separator-line"
          *ngIf="index < steps.length - 1"
          [class.background-black]="(step.passed && !nonSequentialNav) || (nonSequentialNav && step.passedLine)"
        ></span>
      </span>
      <span class="step-label">
        <span class="step-label-link" [class.text-blue]="step.active" [class.text-black]="step.passed">{{step.label || $any(step).title}}</span>
      </span>
    </a>
  </div>
  <div class="Mat-SideMenu">
    <button class="Mat-SideMenu-Button _active" mat-raised-button [matMenuTriggerFor]="menu">
      <span class="Mat-SideMenu-Counter">{{ currentStep.index + 1 }}</span>
      <span class="Mat-SideMenu-Label">{{ currentStep?.label || currentStep?.title }}</span>
    </button>
    <mat-menu class="Mat-SideMenu-Dropdown" #menu="matMenu">
      <ng-container *ngFor="let step of $any(steps); let i = index">
        <button [disabled]="step?.disabled"
                (click)="onStepClick(step)"
                mat-menu-item
                class="Mat-SideMenu-Item"
                routerLinkActive="_active"
                [routerLink]="step.url">
              <span class="Mat-SideMenu-Counter">
              <ng-container *ngIf="step.passed && step.valid; else counterTemplateMenu">
                  <one-icon style="font-size: 16px;" name="checkmark"></one-icon>
                </ng-container>
                <ng-template #counterTemplateMenu>{{ i + 1 }}</ng-template>
              </span>
          <span class="Mat-SideMenu-Label">{{ step?.label || step?.title }}</span>
        </button>
      </ng-container>
    </mat-menu>
  </div>
</section>
