<div class="PlansItem" *ngIf="planData">
  <div class="Title" [class._mini]="mini && planData.title.length > 20" [class._budding]="!mini">{{planData.title}}</div>
  <div class="Subtitle">{{planData.subtitle}}</div>

  <ng-container
    *ngFor="let limit of planData.limits">
    <div class="Feature {{limit.parent_class_name}}" [ngClass]="{'_active': limit.active}">
      <div class="Feature__Description {{limit.class_name}}">
        <span class="_strikethrough" *ngIf="limit.sub_description">{{limit.sub_description}}</span>
        <span [class._tooltip]="limit.tooltip" [oneTooltip]="limit.tooltip">{{limit.description}}</span>
        <!-- <span class="_tooltiptext" *ngIf="limit.tooltip">{{limit.tooltip}}</span> -->
      </div>
    </div>
  </ng-container>

  <div *ngIf="planData.altButton" class="_local-alt-action">
    <div class="Pricing _center">
      <span class="Price"
          [class._popular]="data.popular"
          [class._shopify]="shopify">
      <span *ngIf="planData.price.amount === '$0'" class="Price__Amount">FREE</span>
      <span *ngIf="planData.price.amount !== '$0'">
        <span class="Price__Amount">{{ planData.price.amount }}</span>
        <span class="Price__Period">{{ planData.price.period }}</span>
      </span>
    </span>
    </div>
    <button class="pf-button filled indigo sm"
            [disabled]="isCurrentPlan"
            (click)="onSelectPlanClick(planData.altButton)">
      {{ isCurrentPlan ? 'Current Plan' : planData.altButton.plan_cta_label || 'Choose Plan' }}
    </button>

    <div class="mt-2 _local-trial">
      &nbsp;
      <span *ngIf="!planData.isBudding && planData.altButton.trial_period_text && !isCurrentPlan">
          {{ planData.altButton.trial_period_text }}
      </span>
      &nbsp;
    </div>
    <div class="Feature _alternating-weight _dividerBox _active _mini">
      <div _ngcontent-nos-c367="" class="Feature__Description _divider">
        <hr>
        <div class="_local-alt-or">
          <span>OR</span>
        </div>
      </div>
    </div>
  </div>

  <div class="Pricing"
       [class._center]="planData.price.amount === '$0' || (!planData.trial_extension_text && (!planData.old_display_price || planData.old_display_price !== '$0'))">
    <span class="Price"
          *ngIf="!planData.trial_extension_text; else trialExtensionTemplate"
          [class._popular]="data.popular"
          [class._shopify]="shopify">
      <span *ngIf="planData.price.amount === '$0'" class="Price__Amount">FREE</span>
      <span *ngIf="planData.price.amount !== '$0'">
        <span *ngIf="planData.old_display_price && planData.old_display_price !== '$0'"
              class="Price__Old">{{ planData.old_display_price }}</span>
        <span class="Price__Amount">{{ planData.price.amount }}</span>
        <span class="Price__Period">{{ planData.price.period }}</span>
      </span>
    </span>

    <ng-template #trialExtensionTemplate>
      <span class="Price "
            [class._popular]="data.popular || data.forceGreen"
            [class._shopify]="shopify"
            [class.pr-0]="mini">
        <span *ngIf="planData.price.amount === '$0'" class="Price__Amount">FREE</span>
        <span *ngIf="planData.price.amount !== '$0'">
          <span class="_custom-strikethrough">
            <span class="Price__Old">{{ planData.old_display_price }}</span> {{ planData.price.period }}
          </span>
          <span class="Price__Amount" [class._popular]="data.forceGreen">{{planData.trial_extension_text}}</span>
        </span>
      </span>
    </ng-template>
  </div>

  <button class="pf-button filled blue sm"
      [class.indigo]="shopify"
      [class.shopify-green]="data.popular || data.forceGreen"
      [disabled]="isCurrentPlan"
      (click)="onSelectPlanClick()">
    {{ isCurrentPlan ? 'Current Plan' : (buttonText || planData.trial.period) }}
  </button>

  <div class="mt-2 _local-trial">
    &nbsp;
    <span *ngIf="!planData.isBudding && planData.trial_period && !isCurrentPlan">
      <ng-container *ngIf="planData.trial_period_text; else defaultTrialText">
        {{ planData.trial_period_text }}
      </ng-container>
      <ng-template #defaultTrialText>
        {{planData.trial_period}}-Day Free Trial
      </ng-template>
    </span>
    &nbsp;
  </div>

  <div class="Feature-Container" [class._shopify]="shopify">
    <ng-container
      *ngFor="let feature of planData.features">
      <div *ngIf="feature" class="Feature _alternating-weight {{feature.parent_class_name}}"
           [ngClass]="{'_active': feature.active, '_hide': !feature.active && hideInactive, '_mini': mini, '_has-overlay': !!feature.crispOverlay}">
        <div class="Feature__Description" [ngClass]="feature.class_name" [class._hover-hide]="!!feature.crispOverlay" [innerHTML]="feature.description"
             *ngIf="!feature.tooltip || feature.crispOverlay; else withTooltip"></div>
        <ng-template #withTooltip>
          <div class="Feature__Description" [ngClass]="feature.class_name" [class._hover-hide]="!!feature.crispOverlay" [innerHTML]="feature.description"
               [class._tooltip]="feature.tooltip" [oneTooltip]="tooltip"></div>
          <ng-template #tooltip>
            <span class="py-1 px-2" [innerHTML]="feature.tooltip"></span>
          </ng-template>
        </ng-template>
        <div *ngIf="feature.crispOverlay" class="Feature__Description _clickable _hover-show" [ngClass]="feature.class_name" [class._tooltip]="feature.tooltip"
                (click)="openCrisp(feature.crispOverlay.message)" [innerHTML]="feature.crispOverlay.text"
                [oneTooltip]="feature.tooltip">
      </div>
      </div>
    </ng-container>
  </div>

</div>
