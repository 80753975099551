import { Injectable } from '@angular/core'
import { BehaviorSubject, Subject } from 'rxjs'
// import { BreadcrumbsComponent } from '../../breadcrumbs/breadcrumbs.component'
// import { OneMainNavComponent } from '../components/one-main-nav/one-main-nav.component'
// import { OneMainLogoComponent } from '../../one-side-nav/one-main-logo/one-main-logo.component'
import { OneMainNavStatsComponent } from '../components/one-main-nav-stats/one-main-nav-stats.component'
import { OneMainSearchComponent } from '../components/one-main-search/one-main-search.component'
import { OneUserNavComponent } from '../components/one-user-nav/one-user-nav.component'

export enum OneHeaderView {
  save = 'save',
  defaultView = 'defaultView',
  popupConfig = 'popupConfig',
}

export enum ComponentToLoadPosition {
  left = 'left',
  mid = 'mid',
  right = 'right',
}

export interface LoadedComponentsState {
  [ComponentToLoadPosition.left]: any
  [ComponentToLoadPosition.mid]: any
  [ComponentToLoadPosition.right]: any
} 

export interface ComponentToLoadData {
  component: any
  position: ComponentToLoadPosition
}

@Injectable({providedIn: 'root'})
export class OneHeaderService {
  private _title = new BehaviorSubject<string>('')
  private _blackBg = new BehaviorSubject<boolean>(false)
  private _showBackButton = new BehaviorSubject<boolean>(false)
  private _showSaveButton = new BehaviorSubject<boolean>(false)
  private _loadedComponents$ = new BehaviorSubject<LoadedComponentsState>({
    [ComponentToLoadPosition.left]: null,
    [ComponentToLoadPosition.mid]: null,
    [ComponentToLoadPosition.right]: null,
  })

  public blackBg$ = this._blackBg.asObservable()
  public title$ = this._title.asObservable()
  public showBackButton$ = this._showBackButton.asObservable()
  public showSaveButton$ = this._showSaveButton.asObservable()

  public loadedComponents$ = this._loadedComponents$.asObservable()

  public saveSubject$ = new Subject<void>()
  public discardSubject$ = new Subject<void>()

  public mobileMenuSubject$ = new BehaviorSubject<boolean>(false)

  constructor() { }


  setTitle(value: string) {
    this._title.next(value)
  }

  setShowBackButton(value: boolean) {
    this._showBackButton.next(value)
  }

  setShowSaveButton(value: boolean) {
    this._showSaveButton.next(value)
  }

  setBlackBg(value: boolean) {
    this._blackBg.next(value)
  }

  /**
   * @description Load a component into the header
   * @param component Component to load (should be standalone)
   * @param position Position to load the component in (left, mid, right)
   * @returns void 
   * @example this.oneHeaderService.loadComponent(BreadcrumbsComponent, ComponentToLoadPosition.mid)
   */
  public loadComponent(component: any, position: ComponentToLoadPosition = ComponentToLoadPosition.mid): void {
    const loadedComponents = this._loadedComponents$.value
    loadedComponents[position] = component
    this._loadedComponents$.next(loadedComponents)
  }

  /**
   * @description Remove a component from the header's specified position
   * @param position Position to remove the component from (left, mid, right)
   * @returns void
   * @example this.oneHeaderService.removeLoadedComponent(ComponentToLoadPosition.mid)
   */
  public removeLoadedComponent(position: ComponentToLoadPosition): void {
    const loadedComponents = this._loadedComponents$.value
    loadedComponents[position] = null
    this._loadedComponents$.next(loadedComponents)
  }

  /**
   * @description Remove all loaded components from all positions
   * @returns void
   * @example this.oneHeaderService.resetLoadedComponents()
   */
  public resetLoadedComponents(): void {
    this._loadedComponents$.next({
      [ComponentToLoadPosition.left]: null,
      [ComponentToLoadPosition.mid]: null,
      [ComponentToLoadPosition.right]: null,
    })
  }

  showSaveView() {
    this.setTitle('Unsaved Changes')
    this.setShowSaveButton(true)
    this.setBlackBg(true)
    this.removeLoadedComponent(ComponentToLoadPosition.mid)
    this.removeLoadedComponent(ComponentToLoadPosition.right)
  }

  reset() {
    this.setTitle('')
    this.setShowBackButton(false)
    this.setShowSaveButton(false)
    this.resetLoadedComponents()
    this.setBlackBg(false)

    // this.loadComponent(OneMainSearchComponent, ComponentToLoadPosition.left)
    this.loadComponent(OneMainNavStatsComponent, ComponentToLoadPosition.mid)
    this.loadComponent(OneUserNavComponent, ComponentToLoadPosition.right)
  }

  setView(view: OneHeaderView) {
    this.reset()
    switch (view) {
      case OneHeaderView.defaultView:
        this.reset()
        break
      case OneHeaderView.save:
        this.showSaveView()
        break
      // case OneHeaderView.popupConfig:
      //   this.setTitle('')
      //   this.setShowUserNav(false)
      //   this.setShowBackButton(false)
      //   this.setShowSaveButton(false)
      //   this.loadComponent(BreadcrumbsComponent, ComponentToLoadPosition.mid)
      //   break
      default:
        this.reset()
        break
    }
  }
  
}
