import { UserActions } from './user.actions'
import { initialUserShopState, initialUserState } from './user.state'
import { UserActionTypes } from './user.action-types'


export function userReducer(state = initialUserState, action: UserActions) {
  switch (action.type) {

    case UserActionTypes.SET_SIGN_UP_FLOW_COMPLETED:
      return {
        ...state,
        info: {
          ...state.info,
          onboarding: {
            ...state.info.onboarding,
            sign_up_flow_completed: action.payload,
          },
        },
      }

    case UserActionTypes.SET_USER_CHOSE_PLAN:
      return {
        ...state,
        info: {
          ...state.info,
          onboarding: {
            ...state.info.onboarding,
            chose_plan: action.payload,
          },
        },
      }

    case UserActionTypes.SET_PLUGIN_INSTALLED:
      return {
        ...state,
        info: {
          ...state.info,
          onboarding: {
            ...state.info.onboarding,
            plugin_installed: action.payload,
          },
        },
      }

    case UserActionTypes.SET_INFO:
      // removet method set from reducer and use service instead in header component line 260.
      // const max_unique_vists = action.payload['subscription']
      //   && action.payload['subscription'].unique_visits_limit
      //   || 0
      return {
        ...state,
        info: action.payload,
        // usage: {
        //   percentage: action.payload['current_month_usage'] / max_unique_vists * 100,
        // },
      }

    case UserActionTypes.SET_PROMO:
      return {
        ...state,
        promo: action.payload,
      }

    case UserActionTypes.RESET_INFO:
      return {
        ...state,
        shop: { ...initialUserShopState },
      }

    case UserActionTypes.GET_SITES_REQUEST:
    case UserActionTypes.GET_COUPONS_REQUEST:
    case UserActionTypes.GET_USER_SHOP_REQUEST:
      return { ...state, error: null }

    case UserActionTypes.GET_COUPONS_FAILURE:
    case UserActionTypes.GET_SITES_FAILURE:
    case UserActionTypes.GET_USER_SHOP_FAILURE:
      return { ...state, error: action.payload }

    case UserActionTypes.GET_SITES_SUCCESS:
      return { ...state, sites: action.payload }

    case UserActionTypes.GET_ORDERS_SUCCESS:
      return { ...state, orders: action.payload }

    case UserActionTypes.GET_COUPONS_SUCCESS:
      return { ...state, coupons: action.payload }

    case UserActionTypes.GET_USER_SHOP_SUCCESS:
      return {
        ...state,
        info: {
          ...state.info,
          shop: {
            ...state.info.shop,
            type: action.payload.type,
          },
        },
      }
    case UserActionTypes.GET_USER_ONBOARDING_FAILURE:
      return {
        ...state,
        error: action.payload,
      }

    case UserActionTypes.GET_USER_ONBOARDING_SUCCESS:
      return {
        ...state,
        info: {
          ...state.info,
          onboarding: action.payload,
        },
      }

    default:
      return state

  }
}
