import {
  ElementRef,
  HostListener,
  Directive,
  Input,
  OnDestroy, ViewContainerRef, ComponentRef, AfterViewInit, Output, EventEmitter
} from '@angular/core';
import { AIDropdownComponent } from '../components/ai-dropdown/ai-dropdown-component';
import { UserService } from '../../core/services/user.service';
import { NgControl } from '@angular/forms';
import _ from 'lodash';

@Directive({
  selector: '[oneAI]',
  standalone: true
})
export class OneAIDirective implements AfterViewInit, OnDestroy {
  @Input() aiLabel = true
  @Input() isEmbedded = false
  @Input() disabledAI = false
  @Input() isQuill = false
  @Input() topLabelMargin = 0
  @Input() topLabelPadding = 0
  @Output() aiText = new EventEmitter<string>();

  protected componentRef: ComponentRef<AIDropdownComponent>;
  protected isLoadingAI = false;
  protected aiLabelRef: HTMLElement = null;

  @HostListener('input', ['$event.target']) onInput(input: HTMLTextAreaElement | HTMLInputElement): void {
    this.setInputValue(input);
  }

  @HostListener('ngModelChange', ['$event']) onChange(inputValue: string) {
    if (this.aiLabelRef) {
      inputValue?.trim()?.length ? this.aiLabelRef.classList.remove('hidden') : this.aiLabelRef.classList.add('hidden')
    }
  }

  constructor(
    public element: ElementRef,
    protected control: NgControl,
    protected viewContainerRef: ViewContainerRef,
    protected userService: UserService
  ) { }


  protected setInputValue(input: HTMLTextAreaElement | HTMLInputElement): void {
    if (this.componentRef && this.componentRef.instance?.inputValue !== input.value) {
      this.componentRef.instance.inputValue = input.value;
    }
  }

  ngAfterViewInit() {
    const hasEnableAI = _.get(this.userService.userInfo, 'can_use_ai_generated_content', false)
    // do not show AI if user is not allowed to use it
    if (this.control?.control?.disabled || this.disabledAI || !hasEnableAI || this.element.nativeElement?.disabled) {
      return;
    }
    if (this.aiLabel) {
      // create label
      const areaElement = this.element.nativeElement
      const parentElement = areaElement.parentElement;
      const labelElement = document.createElement('div');
      this.aiLabelRef = labelElement
      labelElement.classList.add('ai-label');
      // hide label if the input is empty
      if (!areaElement.value?.trim()?.length) {
        labelElement.classList.add('hidden')
      }

      parentElement.insertBefore(labelElement, areaElement);

      // sometimes we forget to add position relative to parent element
       if (areaElement.parentElement?.style.position !== 'relative') {
        areaElement.parentElement.style.position = 'relative';
      }

      // if it is embbed
      if (this.isEmbedded) {
        areaElement.style.width = '95%'
        areaElement.style.marginLeft = 'calc(5% - 2px)'
        areaElement.style.paddingLeft = '5px'
        labelElement.style.width = '5%'
        labelElement.style.left = '0'
        labelElement.style.height = '-webkit-fill-available'
        labelElement.style.height = '-moz-available'
        labelElement.style.height = 'fill-available'
        // 36 comes from .ai-label css
        labelElement.style.height = (areaElement.offsetHeight || 36) + 'px'
      }

      if (this.topLabelMargin !== 0) {
        labelElement.style.top = 'unset'
        labelElement.style.marginTop = this.topLabelMargin + 'px'
      }

      if (this.isQuill && this.topLabelPadding !== 0) {
        labelElement.style.top = this.topLabelPadding + 'px'
      }

      // create dropdown
      const createComponentRef = () => {
        labelElement.classList.add('hover');
        this.componentRef = this.viewContainerRef.createComponent(AIDropdownComponent);
        this.componentRef.instance.inputValue = areaElement.value;
        this.componentRef.instance.isQuillEditor = this.isQuill;
        this.componentRef.instance.optionSelected.subscribe((option: string) => {
          this.element.nativeElement.value = option;
          this.control?.control?.setValue(option);
          this.componentRef.instance.inputValue = option;
          this.aiText.emit(option);
        });
        this.componentRef.instance.loadingAI.subscribe((loading: boolean) => {
          if (loading) {
            this.isLoadingAI = true;
          } else {
            this.isLoadingAI = false;
          }
        });
        this.componentRef.instance.isLeaveAI.subscribe(() => {
          if (this.isLoadingAI) {
            return;
          }
          labelElement.classList.remove('hover');
          labelElement.classList.remove('error');
          this.destoryComponentRef();
        });
        this.componentRef.instance.hasErrorAI.subscribe((hasError: boolean) => {
          if (hasError) {
            labelElement.classList.add('error');
          } else {
            labelElement.classList.remove('error');
          }
        });
        // preview text rendering in individual component
        this.componentRef.instance.previewTextChanged.subscribe((text: string) => {
          this.componentRef.instance.previewText = text;
        });
      };
      // add desktop mouseenter event
      labelElement.addEventListener('mouseenter', () => {
        if (!this.element.nativeElement?.value) {
          labelElement.setAttribute('title', 'Please enter some text first to use AI')
          return;
        }
        if (document.querySelector('.ai-label.hover')) {
          labelElement.setAttribute('title', 'Please close the other AI dropdown first')
          return;
        }
        labelElement.removeAttribute('title')
        if (!this.componentRef) {
          createComponentRef();
        }
      });
      // add mobile support
      labelElement.addEventListener('touchstart', () => {
        if (!this.element.nativeElement?.value) {
          labelElement.setAttribute('title', 'Please enter some text first to use AI')
          return;
        }
        if (document.querySelector('.ai-label.hover')) {
          labelElement.setAttribute('title', 'Please close the other AI dropdown first')
          return;
        }
        labelElement.removeAttribute('title')
        if (!this.componentRef) {
          createComponentRef();
        }
      });
    }
  }

  ngOnDestroy() {
    this.destoryComponentRef();
  }

  protected destoryComponentRef() {
    if (this.componentRef) {
      this.componentRef.destroy();
      this.componentRef = null;
    }
  }
}


