import { LoadingActions } from './loading.actions'
import { LoadingActionTypes } from './loading.action-types'
import { initialLoadingState } from './loading.state'


export function loadingReducer(state = initialLoadingState, action: LoadingActions) {
  let pendingList
  switch (action.type) {

    case LoadingActionTypes.SHOW:
      pendingList = [...state.pendingList]
      if (!pendingList.includes(action.payload)) pendingList.push(action.payload)
      return { active: pendingList.length > 0, pendingList }

    case LoadingActionTypes.HIDE:
      pendingList = [...state.pendingList]
      const index = pendingList.indexOf(action.payload)
      if (index > -1) {
        pendingList.splice(index, 1)
      }
      return { active: pendingList.length > 0, pendingList }

    case LoadingActionTypes.RESET:
      return { ...initialLoadingState }

    default:
      return state

  }
}
