import { CollectionViewer, DataSource } from '@angular/cdk/collections'
import { CurrencyPipe } from '@angular/common'
import { Store } from '@ngrx/store'
import _ from 'lodash'
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs'
import { catchError, finalize } from 'rxjs/operators'
import { UserService } from '../../../core/services/user.service'
import { HideLoading, ShowLoading } from '../../../store/loading/loading.actions'
import { StoreState } from '../../../store/store.state'
import { UpsellDiscountType, UpsellListItem, UpsellShippingType } from '../models/upsell-page.models'
import { UpsellService } from './upsell.service'

export class UpsellPageDatasource implements DataSource<UpsellListItem[]> {
  private dataSubject = new BehaviorSubject<UpsellListItem[]>([])
  public data$ = this.dataSubject.asObservable()

  private loadingSubject = new BehaviorSubject<boolean>(false)
  public loading$ = this.loadingSubject.asObservable()

  private sampleSubject = new BehaviorSubject<boolean>(false)
  public sample$ = this.sampleSubject.asObservable()

  subscription$ = new Subscription()

  private loadingLabel = 'upsell-data-load'

  private userCurrency = this.userService.userInfo?.shop?.profile?.currency || 'USD'

  private isFirstLoad = true

  constructor(
    private upsellService: UpsellService,
    private currencyPipe: CurrencyPipe,
    private userService: UserService,
    private store: Store<StoreState>,
  ) {
  }

  loadPage(params: { page?: number, limit?: number, status?: any, start_date?: string, end_date?: string }) {
    this.loadingSubject.next(true)
    this.store.dispatch(new ShowLoading(this.loadingLabel))
    this.upsellService.getUpsells(params).pipe(
        catchError(() => of([])),
        finalize(() => {
          this.loadingSubject.next(false)
          this.store.dispatch(new HideLoading(this.loadingLabel))
        }),
      ).subscribe(res => {
      const data: UpsellListItem[] = (res || []).map(offer => {
        return {
          ...offer,
          total_weight: offer.variants.reduce((res, item) => (res + item.weight), 0),
          root_variant: this.getRootVariant(offer),
          other_variants: this.getOtherVariants(offer),
        }
      }).sort((a, b) => a.priority - b.priority)
      this.dataSubject.next(data)
      // check if there are no upsells on first load to determine if sample upsell is needed
      if (this.isFirstLoad) {
        this.isFirstLoad = false
        if (data?.length === 0) {
          this.sampleSubject.next(true)
        }
      }
    })
  }

  getRootVariant(offer) {
    const variant = offer.variants.find(v => v.root_variant) || {}
    return this.parseVariant(variant)
  }

  getOtherVariants(offer) {
    // FIXME variant.id !== 'c0dc3f29-3a45-4db8-b6a6-9055a4e6586d' is a hack to hide specific upsell variant for a big user we want to retain
    // should be removed once variant archival/deletion functionality is added
    // thread: https://one.slack.com/archives/G01FB7HEX51/p1669156187539269
    return offer.variants.filter(variant => !variant.root_variant && variant.id !== 'c0dc3f29-3a45-4db8-b6a6-9055a4e6586d')
                         .map(variant => this.parseVariant(variant))
                         .sort((a, b) => new Date(a.created_at || '').getTime() - new Date(b.created_at || '').getTime())
  }

  parseVariant(variant) {
    return {...variant,
      weight: variant.weight || 0,
      discount_text: this.createDiscountText(variant),
      shipping: {
        type: variant.shipping_type || UpsellShippingType.Free,
        amount: variant.shipping_amount || 0,
        per_unit: variant.shipping_per_unit || false,
      }
    }
  }

  createDiscountText(offer) {
    if (!offer.discounted) {
      return 'None'
    }
    switch (offer.discount_type) {
      case UpsellDiscountType.Percentage:
        return `${offer.discount_value}% off`
      case UpsellDiscountType.Fixed:
        return `${this.currencyPipe.transform(offer.discount_value, this.userCurrency)} off`
    }
  }

  createDisplayPercent(offer, totalWeight) {
    if (!offer.weight || totalWeight === 0) {
      return 1
    } else {
      return (offer.weight / totalWeight)
    }
  }

  connect(collectionViewer: CollectionViewer): Observable<any[]> {
    return this.dataSubject.asObservable()
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.dataSubject.complete()
    this.loadingSubject.complete()
  }
}
