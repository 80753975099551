import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideNavigationBarComponent } from './side-navigation-bar.component';
import { StepsPanelModule } from '../steps-panel/steps-panel.module';
import { SideNavigationBarHeadlineComponent } from './components/side-navigation-bar-headline/side-navigation-bar-headline.component';
import { SideNavigationBarSubHeadlineComponent } from './components/side-navigation-bar-sub-headline/side-navigation-bar-sub-headline.component';
import { SideNavigationBarBackButtonComponent } from './components/side-navigation-bar-back-button/side-navigation-bar-back-button.component'
import { StepperModule } from '../stepper/stepper.module';
import { SideNavigationBarPinButtonComponent } from './components/side-navigation-bar-pin-button/side-navigation-bar-pin-button.component'
import { RouterModule } from '@angular/router'
import { TitleWithTooltipModule } from '../title-with-tooltip/title-with-tooltip.module'



@NgModule({
  declarations: [SideNavigationBarComponent, SideNavigationBarHeadlineComponent, SideNavigationBarSubHeadlineComponent, SideNavigationBarBackButtonComponent, SideNavigationBarPinButtonComponent],
  imports: [
    CommonModule,
    StepsPanelModule,
    StepperModule,
    RouterModule,
    TitleWithTooltipModule,
  ],
  exports: [SideNavigationBarComponent]
})
export class SideNavigationBarModule { }
