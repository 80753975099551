<input
  type="file"
  accept='image/*'
  id="upload-image"
  [(ngModel)]="file"
  #ctrl="ngModel"
  (change)="fileSelected($event)"
  hidden>

<div class="ImageUpload-Preview">
  <img [src]="selectedImageSrc" *ngIf="selectedImageSrc" alt="">
</div>

<label for="upload-image" class="pf-button filled blue mt-4">+ Upload New Image</label>
