import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'
import { CampaignSideComponentNavList } from '../../../../shared/components/campaign-side/campaign-side.component'
import { CampaignRouteStep, CampaignUrlService } from '../../../../core/services/campaign-url.service'
import { CampaignService } from '../../../../core/services/campaign.service'
import { CampaignPluginName, Campaign } from '../../../../shared/models/campaign/campaign'
import { RouteHeaderUrl } from '../../../../shared/components/one-header/header-navigation.model'

@Injectable()
export class CampaignSaveService {
  private subjectSave = new Subject<void>()
  private subjectSaveAndNext = new Subject<any>()

  public routeList: CampaignSideComponentNavList

  public campaignSteps: { step: CampaignRouteStep, label: string, new: boolean, feature: string }[]

  public routerURL: string

  navigation = {
    prev: null,
    next: null,
    save: null,
  }

  readonly pluginNames = [
    CampaignPluginName.RecentActivity,
    CampaignPluginName.AggregateActivity,
    CampaignPluginName.LiveVisitorCount,
    CampaignPluginName.AggregateVisitorCount,
    CampaignPluginName.FortuneWheel,
    CampaignPluginName.CouponBoxNotification,
    CampaignPluginName.NewsLetterNotification,
    CampaignPluginName.FreeShipping,
    CampaignPluginName.SalesPopup,
    CampaignPluginName.ProductPopup,
  ]
  readonly pluginLabels = {
    [CampaignPluginName.RecentActivity]: 'Recent Activity',
    [CampaignPluginName.AggregateActivity]: 'Aggregate Activity',
    [CampaignPluginName.LiveVisitorCount]: 'Live Visitor Count',
    [CampaignPluginName.AggregateVisitorCount]: 'Aggregate Visitor Count',
    [CampaignPluginName.FortuneWheel]: 'Spin Wheel',
    [CampaignPluginName.CouponBoxNotification]: 'Pop up with Reward',
    [CampaignPluginName.NewsLetterNotification]: 'Pop up without Reward',
    [CampaignPluginName.FreeShipping]: 'Free Shipping Announcement',
    [CampaignPluginName.SalesPopup]: 'Sales Announcement',
    [CampaignPluginName.ProductPopup]: 'Product Announcement',
  }

  readonly pluginNew = {
    [CampaignPluginName.RecentActivity]: false,
    [CampaignPluginName.AggregateActivity]: true,
    [CampaignPluginName.LiveVisitorCount]: false,
    [CampaignPluginName.AggregateVisitorCount]: false,
    [CampaignPluginName.FortuneWheel]: false,
    [CampaignPluginName.CouponBoxNotification]: false,
    [CampaignPluginName.FreeShipping]: true,
    [CampaignPluginName.SalesPopup]: true,
    [CampaignPluginName.ProductPopup]: true,
  }

  readonly pluginFeature = {
    [CampaignPluginName.RecentActivity]: '',
    [CampaignPluginName.AggregateActivity]: '',
    [CampaignPluginName.LiveVisitorCount]: '',
    [CampaignPluginName.AggregateVisitorCount]: '',
    [CampaignPluginName.FortuneWheel]: '',
    [CampaignPluginName.CouponBoxNotification]: '',
    [CampaignPluginName.NewsLetterNotification]: '',
    [CampaignPluginName.FreeShipping]: '',
    [CampaignPluginName.SalesPopup]: '',
    [CampaignPluginName.ProductPopup]: '',
  }

  constructor(
    private campaignService: CampaignService,
    private campaignUrlService: CampaignUrlService,
  ) {
  }

  getSave() {
    return this.subjectSave.asObservable()
  }

  getSaveAndNext() {
    return this.subjectSaveAndNext.asObservable()
  }

  save() {
    this.subjectSave.next()
  }

  saveAndNext(data: any) {
    this.subjectSaveAndNext.next(data)
  }

  public updateNavigation(navigation) {
    this.navigation = { ...navigation }
  }

  public setRouterURL(url: string) {
    this.routerURL = url
  }

  public setCampaignSteps(steps: { step: CampaignRouteStep, label: string, new: boolean, feature: string }[]) {
    this.campaignSteps = steps
  }

  public generateRouteList(campaign: Campaign, isShop: boolean) {
    // this.logger.log(LogLabel.SaveService, `campaign: `, campaign, `isShop: `, isShop)
    this.routeList = this.campaignSteps
      .filter(item => this.shouldCampaignStepBeVisible(item, campaign, isShop))
      .map(item => ({
        url: this.getRouteItemUrl(item.step, campaign),
        label: item.label,
        new: item.new,
        feature: item.feature,
        active: this.getRouteItemActive(item.step),
        items: this.getRouteItems(item.step, campaign, isShop),
      }))
    return this.routeList
  }

  private changePluginNameIfNeeded(pluginName: CampaignPluginName, isShop: boolean): string {
    if (pluginName === CampaignPluginName.RecentActivity && isShop) {
      return 'Recent Sales Pop'
    } else if (pluginName === CampaignPluginName.AggregateActivity && isShop) {
      return 'Aggregate Sales Pop'
    } else {
      return this.pluginLabels[pluginName]
    }
  }

  public shouldCampaignStepBeVisible(item: { step: CampaignRouteStep, label: string }, campaign: Campaign, isShop: boolean): boolean {
    // this.logger.log(LogLabel.SaveService, `item: `, item, `campaign: `, campaign, `isShop: `, isShop)
    switch (item.step) {
      case CampaignRouteStep.CAPTURE:
        const isRecentActivityActive = campaign && (
          (campaign[CampaignPluginName.RecentActivity] && campaign[CampaignPluginName.RecentActivity].active) ||
          (campaign[CampaignPluginName.AggregateActivity] && campaign[CampaignPluginName.AggregateActivity].active)
        )
        return !isShop && isRecentActivityActive
      case CampaignRouteStep.LANGUAGE:
        const noSelectedPlugins = campaign
          && campaign[CampaignPluginName.RecentActivity]
          && !campaign[CampaignPluginName.RecentActivity].active
          && campaign[CampaignPluginName.AggregateActivity]
          && !campaign[CampaignPluginName.AggregateActivity].active
          && campaign[CampaignPluginName.AggregateVisitorCount]
          && !campaign[CampaignPluginName.AggregateVisitorCount].active
          && campaign[CampaignPluginName.LiveVisitorCount]
          && !campaign[CampaignPluginName.LiveVisitorCount].active
          && campaign[CampaignPluginName.FortuneWheel]
          && !campaign[CampaignPluginName.FortuneWheel].active
        return !noSelectedPlugins
      case CampaignRouteStep.CONVERSIONS:
        const recentActivityActive = campaign && (
          (campaign[CampaignPluginName.RecentActivity] && campaign[CampaignPluginName.RecentActivity].active) ||
          (campaign[CampaignPluginName.AggregateActivity] && campaign[CampaignPluginName.AggregateActivity].active)
        )
        return !isShop && recentActivityActive
      case CampaignRouteStep.DESIGN:
      case CampaignRouteStep.BEHAVIOR:
        const noSelectedNotificationPlugins = campaign
          && campaign[CampaignPluginName.RecentActivity]
          && !campaign[CampaignPluginName.RecentActivity].active
          && campaign[CampaignPluginName.AggregateVisitorCount]
          && !campaign[CampaignPluginName.AggregateVisitorCount].active
          && campaign[CampaignPluginName.LiveVisitorCount]
          && !campaign[CampaignPluginName.LiveVisitorCount].active
          && campaign[CampaignPluginName.AggregateActivity]
          && !campaign[CampaignPluginName.AggregateActivity].active
        return !noSelectedNotificationPlugins
    }
    return true
  }

  getRouteItemUrl(route: string, campaign: Campaign) {
    if (campaign && campaign.id) {
      return this.campaignUrlService.campaignStep(campaign.id, route as any)
    }
    return null
  }

  getRouteItemActive(route: string) {
    return this.routerURL.indexOf(route) > -1
  }

  getRouteItems(step: CampaignRouteStep, campaign: Campaign, isShop: boolean): CampaignSideComponentNavList {
    if (step === CampaignRouteStep.APPS) {
      return this.getPluginsRouteItems(campaign, isShop)
    }
    return
  }

  getPluginsRouteItems(campaign: Campaign, isShop: boolean): CampaignSideComponentNavList {
    if (!campaign || !campaign.id) {
      return
    }
    return this.pluginNames
      .filter(pluginName => campaign[pluginName].active)
      .map(pluginName => {
        return {
          url: this.campaignService.getConfigureLink(campaign.id, pluginName),
          label: this.changePluginNameIfNeeded(pluginName, isShop),
          new: this.pluginNew[pluginName],
          feature: this.pluginFeature[pluginName],
          active: false,
        }
      })
  }

  public _getPrevRoute() {
    const subpageIndex = this._getCurrentRouteIndex()
    this.routeList.reverse()
    const navigationItem = this.routeList.find((item, i, arr) => {
      return arr.length - 1 - i < subpageIndex
    })
    this.routeList.reverse()
    return navigationItem && navigationItem.url
  }

  private _getCurrentRouteIndex() {
    return this.routeList.findIndex(item => this.routerURL.indexOf(item.url) > -1)
  }

  public _getNextRoute() {
    const subpageIndex = this._getCurrentRouteIndex()
    const navigationItem = this.routeList.find((item, i) => i > subpageIndex)
    return navigationItem && navigationItem.url
  }

  public _getSaveRoute() {
    const currentRoute = this._getCurrentRoute()
    const lastRoute = this.routeList && this.routeList.length && this.routeList[this.routeList.length - 1].url
    return lastRoute && lastRoute.indexOf(currentRoute) > -1 && `/${RouteHeaderUrl.popups}/${RouteHeaderUrl.campaigns}/`
  }

  public _getCurrentRoute() {
    const isParamInRoute = this.routerURL.indexOf('?')
    const path = this.routerURL
      .substring(0, isParamInRoute !== -1 ? isParamInRoute : this.routerURL.length)
      .split('/')

    return path[path.length - 1]
  }
}
