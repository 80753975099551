import { StoreState } from '../store.state'
import { UserShopType } from '../../shared/models/user/user-shop-type.model'
import * as _ from 'lodash'
import { UserInfo } from './user.state'
import { UserShop } from '../../shared/models/user/user-shop.model'

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export const getSignUpFlowCompleted = (state: StoreState): boolean => {
  return _.get(state, 'user.info.onboarding.sign_up_flow_completed', undefined)
}

export const getUserChoseInitialPlanCompleted = (state: StoreState): boolean => {
  return getCookie('pf-admin-user') ? true : _.get(state, 'user.info.onboarding.chose_plan', undefined)
}

export const getPluginInstalled = (state: StoreState): boolean => {
  return _.get(state, 'user.info.onboarding.plugin_installed', undefined)
}
export const getUserInfo = (state: StoreState): UserInfo => {
  return state.user.info
}

export const getUserPromo = (state: StoreState): object => {
  return state.user.promo
}

// export const getUserUsagePercentage = (state: StoreState): number => {
//   return state.user.usage.percentage
// }

export const getUserShopType = (state: StoreState): UserShopType => {
  /* If there is no shop variable set then return undefined */
  if (_.get(state, 'user.info.shop', undefined) === undefined) {
    return undefined
  }

  /* if there is a shop variable and it is set to null then we return false for shop type */
  return _.get(state, 'user.info.shop.type', null)
}

export const getUserShop = (state: StoreState): UserShop => {
  return _.get(state, 'user.info.shop', undefined)
}

export const getUserError = (state: StoreState): string => {
  return state.user.error
}

export const getUserSites = (state: StoreState): string[] => {
  return state.user.sites
}

/* It seems like no selector for this, disable temporary */
// export const getUserCoupons = (state: StoreState): CouponCode[] => {
//   return state.user.coupons
// }
