import { Injectable } from '@angular/core'
import _ from 'lodash'
import { CouponBoxThemeConfigDefault, CouponBoxThemeConfigLocal } from '../../../../../shared/models/campaign/coupon-box-notification.model'
import { CouponCode, CouponCodeType } from '../../../../coupon-codes/models/coupon-code'
import { NewCouponCodesService } from '../../../../coupon-codes/services/new-coupon-code.service'
import { GiftCard } from '../../../../gift-cards/models/gift-card'

@Injectable()
export class CouponBoxService {
  public themesList: CouponBoxThemeConfigDefault[]
  public isOnboarding: boolean = false

  constructor(
  ) { }

  extendThemeToLocal(themesList: CouponBoxThemeConfigLocal[]): CouponBoxThemeConfigLocal[] {
    const newThemesList = []

    themesList.forEach(theme => {
      newThemesList.push({
        ...theme,
        selected: false,
        loaded: false,
        resetable: false,
      })
    })
    return newThemesList.sort((a, b) => b.list_order - a.list_order)
  }

  generateCode(currentCode: CouponCode | GiftCard) {
    if (!currentCode) {
      return null
    }

    switch (_.get(currentCode, 'type')) {
      case CouponCodeType.manual_master:
      case CouponCodeType.shopify_master:
      case CouponCodeType.big_commerce_master:
      case CouponCodeType.woo_commerce_master: {
        return currentCode.master_code
      }
      case CouponCodeType.big_commerce_unique:
      case CouponCodeType.woo_commerce_unique:
      case CouponCodeType.shopify_unique:
      case CouponCodeType.shopify_gift_card_unique: {
        return `${currentCode.prefix_code}${NewCouponCodesService.randomString(8)}`
      }
    }
  }
}
