import { CommonModule } from '@angular/common'
import { Component, ElementRef, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { Router, RouterModule, RoutesRecognized } from '@angular/router'
import { animate, style, transition, trigger } from '@angular/animations'
import { OneHeaderService, OneHeaderView } from './services/one-header.service'
import { Subscription, filter, combineLatest } from 'rxjs'
import { OneUserNavComponent } from './components/one-user-nav/one-user-nav.component'
import { OneMainLogoComponent } from '../one-side-nav/one-main-logo/one-main-logo.component'
import { Store, select } from '@ngrx/store'
import { StoreState } from '../../../store/store.state'
import { getLoadingPendingList } from '../../../store/loading/loading.selectors'
import { MultiSaveDetectorService } from './services/multi-save-detector.service'
import { OneIconComponent } from '../one-icon/one-icon.component'
import { ShopifyService } from '../../../core/services/shopify.service'
import { SafeLocalStorageService } from '../../../core/services/safe-local-storage.service'
import { UserService } from '../../../core/services/user.service'
import { UpgradeModalType, UpgradePlanHelperService } from '../../../core/services/upgrade-plan-helper.service'
import { PricingUpdatesModalComponent } from '../modals/pricing-updates.modal/pricing-updates.modal.component'
import { take } from 'rxjs/operators'
import {
  CustomPricePlanModalType,
  CustomPricePlanReminderModal,
} from '../modals/custom-price-plan-reminder-modal/custom-price-plan-reminder-modal.component'
import { MatDialog } from '@angular/material/dialog'

@Component({
  selector: 'one-header',
  templateUrl: './one-header.component.html',
  styleUrls: ['./one-header.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    OneIconComponent,
    OneMainLogoComponent,
    OneUserNavComponent,
  ],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('250ms ease-out', style({ opacity: 1 }))
      ]),
    ])
  ],
})

export class OneHeaderComponent implements OnInit, OnDestroy {
  @ViewChild('mobileMenu', {static: false}) mobileMenu: ElementRef
  @HostBinding('class._black-bg') blackBg: boolean = false
  componentsToLoad$ = this.oneHeaderService.loadedComponents$
  title = null
  showBackButton = undefined
  showSaveButton = undefined

  isShopifyEmbedded = this.shopifyService.isEmbedded
  isFullscreen$ = this.shopifyService.fullScreen$

  isMobile = /iPhone|iPod|Android/i.test(navigator.userAgent)

  private subscription = new Subscription()

  isLoading: boolean = false
  activeKnownCustomersCount$ = this.shopifyService.activeKnownCustomersCount$
  showUpgradeModalOnLogin$ = this.upgradePlanHelperService.showUpgradeModalOnLogin$
  showRequestPriceModal$ = this.upgradePlanHelperService.showRequestPriceModal$

  constructor(
    private oneHeaderService: OneHeaderService,
    private router: Router,
    private shopifyService: ShopifyService,
    private multiSaveDetectorService: MultiSaveDetectorService,
    private store: Store<StoreState>,
    private safeLocalStorageService: SafeLocalStorageService,
    private userService: UserService,
    private upgradePlanHelperService: UpgradePlanHelperService,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.subscription.add(
      this.router.events.pipe(
        filter(event => event instanceof RoutesRecognized)
      ).subscribe((event: RoutesRecognized) => {
        // uncheck mobile menu checkbox on navigation
        if (this.mobileMenu?.nativeElement) {
          this.mobileMenu.nativeElement.checked = false
        }
      }),
    )
    this.subscription.add(
      this.oneHeaderService.blackBg$.subscribe(isBlack => this.blackBg = isBlack)
    )
    this.subscription.add(
      this.oneHeaderService.title$.subscribe(title => this.title = title)
    )
    this.subscription.add(
      this.oneHeaderService.showBackButton$.subscribe(showBackButton => this.showBackButton = showBackButton)
    )
    this.subscription.add(
      this.oneHeaderService.showSaveButton$.subscribe(showSaveButton => this.showSaveButton = showSaveButton)
    )

    if (this.userService.userInfo?.custom_plan_invoice && !this.userService.userInfo?.custom_plan_invoice_paid) {
      this.showCustomPlanReminder(CustomPricePlanModalType.invoiceReminder)
    }

    this.subscription.add(
      this.showRequestPriceModal$.pipe(filter(next => next !== null), take(1))
        .subscribe((show) => {
          if (show) {
            const customPriceRequested = this.userService?.userInfo?.custom_plan_price_request
            const type = customPriceRequested ? CustomPricePlanModalType.askForUpdate : CustomPricePlanModalType.requestPriceReminder
            this.showCustomPlanReminder(type)
          }
        })
    )


    this.subscription.add(
      // Watch the pending list in store
      this.store.pipe(select(getLoadingPendingList)).subscribe(storeList => {
        const pendingSave = []
        // Check if there are any pending lists that match the tracked list ids
        Array.from(this.multiSaveDetectorService.trackList.values()).forEach(record => {
          if (record.hasChanged) {
            pendingSave.push(record.id)
          }
        })
        const result = storeList.filter(id => pendingSave.find(recordId => recordId === id))
        // If pendingList items match with tracked list ids, then show loading indicator
        this.isLoading = result.length > 0
      })
    )

    this.redirectIntentIfNeeded()

    if (this.shopifyService.isStorybook) {
      this.oneHeaderService.setView(OneHeaderView.defaultView)
    }

    this.subscription.add(
      combineLatest([
        this.activeKnownCustomersCount$.pipe(filter(next => next !== null), take(1)),
        this.showUpgradeModalOnLogin$.pipe(filter(next => next !== null), take(1)),
      ]).subscribe({
        next: ([count, showUpgradeModal]) => {
          // FIXME remove AKC check once it's added to backend
          if (count > 50) {
            this.showPricingUpdateModalIfNeeded(showUpgradeModal)
          }
        }
      })
    )
  }

  showCustomPlanReminder(type: CustomPricePlanModalType) {
    this.dialog.open(CustomPricePlanReminderModal, {
      width: '410px',
      disableClose: type !== CustomPricePlanModalType.askForUpdate,
      data: {
        type: type
      }
    })
  }


  closeMenu() {
    if (this.mobileMenu?.nativeElement) {
      this.mobileMenu.nativeElement.checked = false
    }
  }

  enterFullScreen(event) {
    event.preventDefault()
    this.shopifyService.enableFullScreen()
  }

  exitFullScreen(event) {
    event.preventDefault()
    this.shopifyService.disableFullScreen()
  }

  saveClick() {
    this.oneHeaderService.saveSubject$.next()
  }

  discardClick() {
    this.oneHeaderService.discardSubject$.next()
  }

  toggleMobileMenu() {
    this.oneHeaderService.mobileMenuSubject$.next(!this.oneHeaderService.mobileMenuSubject$.value)
  }

  redirectIntentIfNeeded() {
      const intent = this.safeLocalStorageService.getItem('one-app-primary-intent')
      if (intent) {
        this.safeLocalStorageService.setItem('one-app-primary-intent', '')
        const url = this.userService.getOnboardingIntentUrl(intent)
        this.router.navigate([url])
    }
  }

  showPricingUpdateModalIfNeeded(showUpgradeModal: boolean, force = false, version = null) {
    // show_pricing_update_modal comes from BE and will be true only once, after modal is shown for the first time BE sets it to false
    // to show modal upon login again, FE should perform check
    if (this.userService.showPricingUpdateModal || showUpgradeModal || force) {
      // this.dialog.open(PricingUpdatesModalComponent, {
      //   width: '560px',
      //   disableClose: true,
      //   data: {
      //     version: version,
      //   }
      // })
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }
}
