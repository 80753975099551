<ng-container [formGroup]="requestForm">

  <ng-container *ngIf="!data.isWaitingList; else waitList">
    <div class="ThemeRequest-Title mb-0" mat-dialog-title>
      Vote for a Feature
    </div>
    <div class="ThemeRequest-Content" mat-dialog-content>
      <div class="config-control">
        <label>Feature</label>
        <mat-form-field class="pf-form-field w-100">
          <mat-select class="form-control _select" formControlName="type" (selectionChange)="updateType($event)">
            <mat-option value="products_text_reviews">Product Text Reviews</mat-option>
            <mat-option value="products_photo_reviews">Product Photo Reviews</mat-option>
            <mat-option value="products_video_reviews">Product Video Reviews</mat-option>
            <mat-option value="products_tags_generated_tags">Product Tags - AI-Generated Tags</mat-option>
            <mat-option value="products_images_generated_scenes">Product Images - AI-Generated Scenes</mat-option>
            <mat-option value="products_images_removed_bg">Product Images - AI-Removed Backgrounds</mat-option>
            <mat-option value="loyalty_point">Loyalty Points</mat-option>
            <mat-option value="download_links">Download Links</mat-option>
            <mat-option value="store_credits">Store Credits</mat-option>
            <mat-option value="whats_app">WhatsApp</mat-option>
            <mat-option value="push_notification">Push Notification</mat-option>
            <mat-option value="customers_profiles">Customer Profiles</mat-option>
            <mat-option value="customers_segments">Customer Segments</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <ng-container *ngIf="requestDetails">
        <div class="config-control">
          <label>Description</label>
          <div class="ThemeRequest-Input">
            <div class="_desc w-100 _mxh320">{{ requestDetails }}</div>
          </div>
        </div>
      </ng-container>
      <div class="ThemeRequest-Textarea mb-1">
        <div class="_textarea" *ngIf="requestForm.get('description'); let control">
          <div class="config-control">
            <label>Feedback <span class="text-muted">(Optional)</span></label>
            <textarea type="text" rows="7" [maxlength]="descMaxLength + 35" formControlName="description" placeholder="Brief details about why and how you'd like to use this feature."
              class="form-control control-textarea" [class.is-invalid]="control.errors && control.dirty"></textarea>
            <span class="_count">{{descTypeLength}} / {{descMaxLength}}</span>
          </div>
        </div>
      </div>
      <div *ngIf="requestForm.get('description'); let control">
        <ng-container *ngIf="control.dirty && control.errors">
          <small class="invalid-feedback d-block" *ngIf="control.errors.maxlength">
            Details must be less than {{control.errors.maxlength.requiredLength}} characters
          </small>
        </ng-container>
      </div>
    </div>
    <div mat-dialog-actions class="mt-2 mb-0 justify-content-end">
      <button class="pf-button outline md gray" (click)="dialogRef.close()">Cancel</button>
      <button type="submit" class="pf-button filled md blue ml-3" (click)="sendRequest()" [disabled]="this.requestForm.invalid">Vote Now</button>
    </div>
  </ng-container>


  <ng-template #waitList>
    <div class="ThemeRequest-Title mb-0" mat-dialog-title>
      {{ waitlistTitle}} <span class="pf-badge light-warning outline ml-1" style="margin-bottom: 4px;"> Early Access </span>
    </div>
    <div class="ThemeRequest-Content" mat-dialog-content>
      <div class="config-control">
        <label>Feature</label>
        <input type="text" disabled class="preset-value form-control" value="{{betaFeatureTitle[data.featureType]}}">
      </div>
      <ng-container *ngIf="requestDetails">
        <div class="config-control">
          <label>Description</label>
          <div class="ThemeRequest-Input">
            <div class="_desc w-100 _mxh320">{{ requestDetails }}</div>
          </div>
        </div>
      </ng-container>

      <div class="ThemeRequest-Textarea mb-1">
        <div class="_textarea" *ngIf="requestForm.get('description'); let control">
          <div class="config-control">
            <ng-container *ngIf="!isCopilot; else copilot">
              <label>Feedback <span class="text-muted">(Optional)</span></label>
              <textarea type="text" rows="7" [maxlength]="descMaxLength + 35" formControlName="description" placeholder="Brief details about why and how you'd like to use this feature."
              class="form-control control-textarea" [class.is-invalid]="control.errors && control.dirty"></textarea>
            </ng-container>
            <ng-template #copilot>
              <label>First Question</label>
              <textarea type="text" rows="7" [maxlength]="descMaxLength + 35" formControlName="description" placeholder="What would be the first question or task you ask the ONE Store Co-Pilot (AI Superhero) do for your store?"
              class="form-control control-textarea" [class.is-invalid]="control.errors && control.dirty"></textarea>
            </ng-template>
            <span class="_count">{{descTypeLength}} / {{descMaxLength}}</span>
          </div>
        </div>
      </div>
    </div>

    <div mat-dialog-actions class="mt-2 mb-0 justify-content-end">
      <button class="pf-button outline md gray" (click)="dialogRef.close()">Cancel</button>
      <button type="submit" class="pf-button filled md yellow ml-3" (click)="sendRequest()" [disabled]="this.requestForm.invalid">{{ waitlistButtonText }}</button>
    </div>

  </ng-template>


</ng-container>

<pf-loading-overlay [filter]="{only: ['getCannyFeatureRequests']}" [isFixed]="false"></pf-loading-overlay>
