import { Billing } from '../../shared/models/billing.model'

import { BillingActionTypes } from './billing.action-types'
import { AsyncAction } from '../../shared/models/store/async-action.model'

export class ReadBillingRequest implements AsyncAction {
  readonly type = BillingActionTypes.READ_BILLING_REQUEST
  loading = { id: BillingActionTypes.READ_BILLING_REQUEST, active: true }
  noLoad: boolean
}


export class ReadBillingSuccess implements AsyncAction {
  readonly type = BillingActionTypes.READ_BILLING_SUCCESS
  loading = { id: BillingActionTypes.READ_BILLING_REQUEST, active: false }

  constructor(public payload: Billing) {
  }
}

export class ReadBillingFailure implements AsyncAction {
  readonly type = BillingActionTypes.READ_BILLING_FAILURE
  loading = { id: BillingActionTypes.READ_BILLING_REQUEST, active: false }

  constructor(public payload: string) {
  }
}


export type BillingActions =
  | ReadBillingRequest
  | ReadBillingSuccess
  | ReadBillingFailure
