<div class="_calendly-title" *ngIf="!alreadyScheduled && !scheduleSubmitted" [class._has-navbar]="hasNavbar">
  <h3>
    Welcome! Book a personalized demo to get started.
  </h3>
  <h5 class="mb-0" *ngIf="userType === 'new'">
    Please note, this is a requirement to start using the app.
  </h5>
</div>
<div #container [class.calendly-embed]="!alreadyScheduled" [class._has-navbar]="hasNavbar"></div>
<div *ngIf="alreadyScheduled" class="_success-wrapper" [class._has-navbar]="hasNavbar">
  <div class="Card">
    <img src="/app_assets/campaigns-list/success.png" alt="" class="CL-Image mb-2">
    <p>You have succesfully scheduled a call with our representative!</p>
    <p>Looking forward to meeting you on the selected date!</p>
    <button type="button" class="pf-button outline blue xs"
            (click)="reschedule()">
      Reschedule
    </button>
    <!-- decided not to make it easier for customers to cancel for now -->
    <!-- <a class="mt-2" href="#" (click)="details($event)">Details</a> -->
    <!-- <a class="mt-2" href="#" (click)="cancel($event)">Cancel</a> -->
  </div>
</div>

<pf-loading-overlay></pf-loading-overlay>
