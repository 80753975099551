import * as mustache from 'mustache'
import * as _ from 'lodash'
import * as Countires from 'country-emoji'

export class TemplateHelper {
  filters = {
    mask: str => TemplateHelper.mask(str),
    mask_trail: str => TemplateHelper.maskTrail(str),
    first_letter: str => str[0],
    capitalize: (str: string) => TemplateHelper.capitalize(str),
    uppercase: (str: string) => TemplateHelper.uppercase(str),
  }

  static mask(str: string): string {
    return str.replace(/./g, '*')
  }

  static TitleCase(str: string): string {
    return str ? str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
      },
    ) : str
  }

  static maskTrail(str: string): string {
    return str.length > 2
      ? str.substring(0, 2) + this.mask(str.substring(2))
      : str.length === 2
        ? str.substring(0, 1) + this.mask(str.substring(1))
        : this.mask(str)
  }

  static capitalize(str: string): string {
    return str[0].toUpperCase() + str.slice(1)
  }

  static reverseString(str) {
    // Step 1. Use the split() method to return a new array
    const splitString = str.split('') // var splitString = "hello".split("");
    // ["h", "e", "l", "l", "o"]

    // Step 2. Use the reverse() method to reverse the new created array
    const reverseArray = splitString.reverse() // var reverseArray = ["h", "e", "l", "l", "o"].reverse();
    // ["o", "l", "l", "e", "h"]

    // Step 3. Use the join() method to join all elements of the array into a string
    const joinArray = reverseArray.join('') // var joinArray = ["o", "l", "l", "e", "h"].join("");
    // "olleh"

    // Step 4. Return the reversed string
    return joinArray // "olleh"
  }

  static uppercase(str: string): string {
    return str.toUpperCase()
  }

  static titleize(_str: string): string {
    let string_array = _str.split(' ')
    string_array = string_array.map(str => {
      return TemplateHelper.capitalize(str)
    })
    return string_array.join(' ')
  }

  static lowercase(str: string): string {
    return str.toLowerCase()
  }

  parse(template: string, params: object, functions: object = null) {
    const modifiedParams = TemplateHelper.modifyParams(template, params, functions)
    const modifiedTemplate = TemplateHelper.modifyTemplate(template)

    mustache.escape = (text: string) => {
      return text
    }

    try {
      return mustache.render(modifiedTemplate, modifiedParams)
    } catch (_) {
    }
    return template
  }

  static tagsFromTemplate(message_text) {
    const mustacheStatements = message_text.match(/{{.*?}}/g) || []
    if (!mustacheStatements) {
      return []
    }
    return mustacheStatements
      .filter(str => !!str)
      .filter(str => {
        const clean = str.trim().replace('{{', '').replace('}}', '').trim()
        return clean.length
      }).map(match => {
        const stripped_match = match
          .replace('{{', '')
          .replace('}}', '')
          .trim()

        /* Get the first variable */
        const variable = stripped_match
          .trim()
          .split('||')
          .map(str => str.trim())
          .filter(str => str.length)[0]

        /* Make sure to parse out functions */
        return variable
          .trim()
          .split('|')
          .map(str => str.trim())
          .filter(str => str.length)[0]
      })
  }

  /* {{ first_name || last_name | uppercase }} */
  static functionsFromTemplate(message_text) {
    const functions = {}
    const tagBodies = message_text.match(/{{.*?}}/g) || []
    if (!tagBodies) {
      return functions
    }
    tagBodies.forEach(match => {
      const stripped_match = match
        .replace('{{', '')
        .replace('}}', '')
        .trim()
      const variables = stripped_match
        .split('||')
        .map(str => str.trim())
        .filter(str => str.length)

      let tag = null
      let result = []
      if (variables.length > 1) {
        const tags = variables.slice(0, variables.length - 1)
        result = variables[variables.length - 1]
          .trim()
          .split('|')
          .map(str => str.trim())
          .filter(str => str.length)

        /* Add the last tag back */
        tags.push(result[0])
        tag = tags[0]
        if (tags.length > 1) {
          functions[tag] = functions[tag] || []
          functions[tag].push({
            name: 'fallback_tags',
            params: {
              fallbacks: tags.slice(1, tags.length),
            },
            order: 0,
          })
        }
      } else if (variables.length === 1) {
        result = variables[0]
          .trim()
          .split('|')
          .map(str => str.trim())
          .filter(str => str.length)
        tag = result[0]
      }

      if (result && tag) {
        result.slice(1, result.length).forEach(_function => {
          functions[tag] = functions[tag] || []
          if (_function.match(/fallback *?\[.+?\]/g)) {
            const [name, fallback] = _function.match(/(fallback) *?\[(.*)\]/).slice(1, 3)
            functions[tag].push({
              name: name,
              params: {
                fallback: fallback,
              },
              order: 1,
            })
          } else if (_function.match(/fallback_random *?\[.+?\]/g)) {
            const [name, fallbacks_string] = _function.match(/(fallback_random) *?\[(.*)\]/).slice(1, 3)
            functions[tag].push({
              name: name,
              params: {
                fallbacks: fallbacks_string
                  .split(',')
                  .map(str => str.trim())
                  .filter(str => str.length),
              },
              order: 1,
            })
          } else if (_function.match(/delete_before *?\[.+?\]/g)) {
            const [name, character] = _function.match(/(delete_before) *?\[(.*)\]/).slice(1, 3)
            functions[tag].push({
              name: name,
              params: {
                character: character,
              },
              order: 2,
            })
          } else if (_function.match(/delete_after *?\[.+?\]/g)) {
            const [name, character] = _function.match(/(delete_after) *?\[(.*)\]/).slice(1, 3)
            functions[tag].push({
              name: name,
              params: {
                character: character,
              },
              order: 2,
            })
          } else if (
            _function.match(/delete *?\[.+?\]/g) ||
            _function.match(/delete_first *?\[.+?\]/g) ||
            _function.match(/delete_last *?\[.+?\]/g)
          ) {
            const [name, characters_string] = _function
              .match(/(delete|delete_first|delete_last) *?\[(.*)\]/)
              .slice(1, 3)
            functions[tag].push({
              name: name,
              params: {
                characters: characters_string.split(','),
              },
              order: 2,
            })
          } else if (_function.match(/replace *?\[(.*?),(.*?)\]/g)) {
            const [name, _this, _that] = _function.match(/(replace) *?\[(.*?),(.*?)\]/).slice(1, 4)
            functions[tag].push({
              name: name,
              params: {
                this: _this,
                that: _that,
              },
              order: 2,
            })
          } else if (_function.match(/truncate *(\d*)/g)) {
            const length = _function.match(/truncate *(\d*)/)[1]
            functions[tag].push({
              name: 'truncate',
              params: {
                length: length,
              },
              order: 2,
            })
          } else {
            functions[tag].push({
              name: _function,
              order: 2,
            })
          }
        })
      }
    })
    return functions
  }

  static modifyParams(template: string, params: object, existingFunctions: object = null): object {
    const localParams = JSON.parse(JSON.stringify(params))
    const functions = existingFunctions ? existingFunctions : TemplateHelper.functionsFromTemplate(template)
    const tags = TemplateHelper.tagsFromTemplate(template)

    if (tags.includes('country_flag')) {
      if (localParams['country_code']) {
        const countryCode = localParams['country_code'].trim()
        const parsedCode = Countires.code(Countires.flag(countryCode))
        if (parsedCode && parsedCode.toLowerCase() === countryCode.toLowerCase() && Countires.flag(countryCode)) {
          localParams['country_flag'] = Countires.flag(countryCode)
        }
      }

      if (localParams['country'] && !localParams['country_flag']) {
        const country = localParams['country'].trim()
        const parsedCode = Countires.code(Countires.flag(country))
        const parsedName = Countires.name(Countires.flag(country))

        if (parsedCode && parsedCode.toLowerCase() === country.toLowerCase() && Countires.flag(country)) {
          localParams['country_flag'] = Countires.flag(country)
        } else if (parsedName && parsedName.toLowerCase() === country.toLowerCase() && Countires.flag(country)) {
          localParams['country_flag'] = Countires.flag(country)
        }
      }
    }

    Object.keys(functions).forEach(tag => {
      const function_methods = _.get(functions, tag, []).sort(
        (a, b) => (a.order > b.order ? 1 : b.order > a.order ? -1 : 0),
      )
      function_methods.forEach(method => {
        const existingTag = `${_.get(localParams, tag, '')}`
        if (existingTag && existingTag.length) {
          switch (method.name) {
            case 'mask': {
              _.set(localParams, tag, TemplateHelper.mask(existingTag))
              break
            }
            case 'downcase': {
              _.set(localParams, tag, TemplateHelper.lowercase(existingTag))
              break
            }
            case 'upcase':
            case 'uppercase': {
              _.set(localParams, tag, TemplateHelper.uppercase(existingTag))
              break
            }
            case 'mask_trail': {
              _.set(localParams, tag, TemplateHelper.maskTrail(existingTag))
              break
            }
            case 'propercase': {
              _.set(localParams, tag, TemplateHelper.TitleCase(existingTag))
              break
            }
            case 'capitalize':
            case 'capitalize_first_letter': {
              _.set(localParams, tag, TemplateHelper.capitalize(existingTag))
              break
            }
            case 'titleize': {
              _.set(localParams, tag, TemplateHelper.titleize(existingTag))
              break
            }
            case 'first_letter': {
              _.set(localParams, tag, existingTag[0])
              break
            }
            case 'trim_after': {
              _.set(localParams, tag, existingTag.trimRight())
              break
            }
            case 'delete_after': {
              const _this = _.get(method, 'params.character')
              if (_this) {
                _.set(localParams, tag, existingTag.split(_this)[0])
              }
              break
            }
            case 'delete_before': {
              const _this = _.get(method, 'params.character')
              if (_this) {
                const split_string = existingTag.split(_this)
                _.set(localParams, tag, split_string[split_string.length])
              }
              break
            }
            case 'number': {
              _.set(localParams, tag, existingTag.toLocaleString())
              break
            }
            case 'truncate': {
              const length = _.get(method, 'params.length', 30)
              _.set(localParams, tag, existingTag.substring(0, length))
              break
            }
            case 'replace': {
              const _this = _.get(method, 'params.this')
              const _that = _.get(method, 'params.that')
              _.set(localParams, tag, existingTag.replace(new RegExp(_this, 'g'), _that))
              break
            }
            case 'delete': {
              const characters = _.get(method, 'params.characters', [])
              characters.forEach(char => {
                _.set(localParams, tag, existingTag.replace(new RegExp(char, 'g'), ''))
              })
              break
            }
            case 'delete_first': {
              const characters = _.get(method, 'params.characters', [])
              characters.forEach(char => {
                _.set(localParams, tag, existingTag.replace(char, ''))
              })
              break
            }
            case 'delete_last': {
              const characters = _.get(method, 'params.characters', [])
              characters.forEach(char => {
                _.set(
                  localParams,
                  tag,
                  TemplateHelper.reverseString(TemplateHelper.reverseString(existingTag).replace(char, '')),
                )
              })
              break
            }
          }
        } else {
          switch (method.name) {
            case 'fallback': {
              _.set(localParams, tag, _.get(method, 'params.fallback'))
              break
            }
            case 'fallback_random': {
              if (!localParams[tag]) {
                const fallbacks = _.get(method, 'params.fallbacks', [])
                _.set(localParams, tag, fallbacks[Math.floor(Math.random() * fallbacks.length)])
              }
              break
            }
            case 'fallback_tags': {
              if (!localParams[tag]) {
                const fallbacks = _.get(method, 'params.fallbacks', [])
                for (const fallback_tag of fallbacks) {
                  if (_.get(localParams, fallback_tag)) {
                    _.set(localParams, tag, _.get(localParams, fallback_tag))
                    break
                  }
                }
              }
            }
          }
        }
      })
    })

    return localParams
  }

  // TODO: move to helper or another service
  getByPath(object, path) {
    let o = object
    path = path.replace(/\[(\w+)\]/g, '.$1')
    path = path.replace(/^\./, '')
    const a = path.split('.')
    while (a.length) {
      const n = a.shift()
      if (n in o) {
        o = o[n]
      } else {
        return
      }
    }
    return o
  }

  static modifyTemplate(template: string): string {
    return template.replace(/{{[^}]*}}/g, tagBody => {
      return '{{' + tagBody.split(/[{}|\s]/).filter(str => str.length)[0] + '}}'
    })
  }

  static highlightText(text: string) {
    return text ? text.replace(/\*\*(.*?)\*\*/g, `<span class='proof-factor-title-highlight'>$1</span>`) : null
  }
}

export const templateHelper = new TemplateHelper()
