import { Component, OnInit, Inject, OnDestroy, AfterViewInit } from '@angular/core'
import { Action, select, Store } from '@ngrx/store'
import {
  ActivatedRoute, ActivationEnd,
  ActivationStart,
  Data,
  NavigationEnd,
  Router,
  RoutesRecognized,
} from '@angular/router'
import { GetUserOnboardingRequest, SetCurrentUserInfo } from './store/user/user.actions'
import { forkJoin, Subscription, Observable, of } from 'rxjs'
import { environment } from '../environments/environment'
import { ReadBillingRequest } from './store/billing/billing.actions'
import { CampaignState } from './store/campaign/campaign.state'
import { getSignUpFlowCompleted } from './store/user/user.selectors'
import { Actions, ofType } from '@ngrx/effects'
import { UserActionTypes } from './store/user/user.action-types'
import { StoreState } from './store/store.state'
import { Logger } from './core/services/logger.service'
import { HideLoading, ResetLoading, ShowLoading } from './store/loading/loading.actions'
import { CookieService } from 'ngx-cookie-service'
import moment from 'moment'
import * as _ from 'lodash'
import { PromoService } from './shared/modules/promo/services/promo.service'
import { FullScreenService } from './core/services/full-screen.service'
import { DOCUMENT } from '@angular/common'
import { Title } from '@angular/platform-browser'
import { catchError, concatMap, filter, map, mergeMap, switchMap, take, tap } from 'rxjs/operators'
import { RouteAvailabilityService } from './shared/modules/service-pages/services/route-availability.service'
import { SegmentAnalyticsService } from './shared/services/segment-analytics.service'
import { AdminUserTokenService } from './core/services/admin-user-token.service'
import { AnalyticsService } from './core/services/analytics.service'
import { ApiService } from './core/services/api/api.service'
import { AuthService } from './core/services/auth.service'
import { BrandingService } from './core/services/branding.service'
import { GettingStartedService } from './core/services/getting-started.service'
import { RedirectService } from './core/services/redirect.service'
import { UserService } from './core/services/user.service'
import { loadingLayout } from './shared/animations/loadingLayout.animation'
import { GettingStartedInteractions } from './shared/models/getting-started/getting-started-interactions.model'
import { GettingStartedStatus } from './shared/models/getting-started/getting-started-status.model'
import { LogLabel } from './shared/models/logger/log-label.model'
import { NavType } from './shared/models/nav-types.model'
import {
  SetGettingStartedCompleted,
  SetGettingStartedFirstCampaignLaunched,
  SetGettingStartedFirstCampaignSkipped,
  SetGettingStartedFirstCampaignId,
  SetGettingStartedEvents,
  SetGettingStartedStatus,
} from './store/getting-started/getting-started.actions'
import { History } from '@shopify/app-bridge/actions';
import { BrowserVendor, ShopifyService } from './core/services/shopify.service'
import { v4 as uuidv4 } from 'uuid'
import { ActiveAppCampaignService } from './core/services/active-app-campaign.service'
import { SafeLocalStorageService } from './core/services/safe-local-storage.service'
import { CrispService } from './core/services/crisp.service'
import { RouteHeaderUrl } from './shared/components/one-header/header-navigation.model'
import { PreviousUrlService } from './core/services/previous-url.service'
import { ShopifySubscribersApiService } from './pages/subscribers/services/shopify-subscribers-api.service'
import { ContactsResponseType } from '../app/pages/subscribers/models/subscribers-page.model'
import { OneHeaderService } from './shared/components/one-header/services/one-header.service'
import { SideNavService } from './shared/components/one-side-nav/services/one-side-nav.service'
import { BreakpointObserver } from '@angular/cdk/layout'
import { BreakpointService } from './core/services/breakpoint.service'
import { MatDialog } from '@angular/material/dialog'
import {
  ConfirmModalComponent,
  ConfirmModalConfig,
} from './shared/components/modals/confirm.modal/confirm.modal.component'
import { EmailOnboardingApiService } from './shared/modules/email-onboarding/services/email-onboarding-api.service'
// import LogRocket from 'logrocket'
import { FlywheelAnalytics } from '@flywheelapp/flywheel.js'
import { CartApiService } from './pages/cart/services/cart-api.service'
import { UserInfo, UserUsageInfo } from './store/user/user.state'
import { ApiJotformService } from './core/services/api/api-jotform.service'
import { SmsPhoneNumberVerificationService } from './core/services/sms-phone-number-verification.service'

const PROOF_FACTOR_USER_SESSION_COOKIE = 'proof_factor_user_session_cookie'

declare var $crisp: any

export class Storage implements Action {
  readonly type = '@ngrx/store/storage'

  constructor(readonly payload: string) {
  }
}

@Component({
  selector: 'pf-app',
  templateUrl: './app.component.html',
  styles: [`
    :host {
      display: block;
      height: 100%;
      min-height: 0;
      grid-area: content;
      overflow: hidden;
    }
  `],
  animations: [loadingLayout],
  providers: [
    RouteAvailabilityService,
  ],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  campaigns: Observable<CampaignState>
  signUpFlowCompleted = false
  isAuthenticated = false
  gettingStartedCompleted = false
  navType = NavType
  showLoading = true
  isFullScreen = false
  isShopifyEmbedded = this.shopifyService.isEmbedded
  isFullscreen$ = this.shopifyService.fullScreen$
  subscription = new Subscription()
  translationWidgetAdded = false
  pageTitle: string = null
  backBtnUrl: string = null
  navPinned$ = this.sideNavService.pinnedSideNav$
  mobileMenu$ = this.oneHeaderService.mobileMenuSubject$
  bp$ = this.breakpointService.bp$

  readonly maintainanceTime = moment.utc('2023-11-04 19:30:00')
  /* Set to false if no need to show banner */
  displayBanner = true
  emailSenderRequirementsUpdate = true
  bannerInterval: any;
  bannerHTML: string = null

  private standaloneShown = false

  readonly shopifyFullScreenPages = [
    `/${RouteHeaderUrl.popups}/${RouteHeaderUrl.campaigns}/`,
    `/${RouteHeaderUrl.email_editor}`,
    `/${RouteHeaderUrl.email_automation_builder}`,
    // `/${RouteHeaderUrl.onboarding}`, // Disabled due to Shopify audit complaint
    `/${RouteHeaderUrl.dns}`,
    `/${RouteHeaderUrl.upsell}`,
  ]
  readonly hideConveyPages = [
    ...this.shopifyFullScreenPages,
    `/${RouteHeaderUrl.gift_cards}/`,
    `/${RouteHeaderUrl.coupons}/`,
    `/${RouteHeaderUrl.sms}/${RouteHeaderUrl.automations}/`,
    `/${RouteHeaderUrl.sms}/${RouteHeaderUrl.broadcasts}/`,
  ]
  readonly crispSessionKey = 'crisp_session'
  readonly bannerSessionKey = 'one_banner_email_requirements_update'
  readonly userSessionKey = 'one_user_id'

  constructor(
    private analytics: AnalyticsService,
    private userService: UserService,
    private store: Store<StoreState>,
    private authService: AuthService,
    private logger: Logger,
    private gettingStartedService: GettingStartedService,
    private router: Router,
    private actions$: Actions,
    private cookieService: CookieService,
    private apiService: ApiService,
    private promoService: PromoService,
    private fullScreenService: FullScreenService,
    private redirectService: RedirectService,
    private previousUrlService: PreviousUrlService,
    private routeAvailabilityService: RouteAvailabilityService,
    private safeLocalStorageService: SafeLocalStorageService,
    @Inject(DOCUMENT) private document: Document,
    private adminUserTokenService: AdminUserTokenService,
    private titleService: Title,
    private brandingService: BrandingService,
    private activatedRoute: ActivatedRoute,
    private segmentAnalyticsService: SegmentAnalyticsService,
    private crispService: CrispService,
    private shopifyService: ShopifyService,
    private cartApiService: CartApiService,
    private jotformApiService: ApiJotformService,
    private activeAppCampaignService: ActiveAppCampaignService,
    private shopifySubscribersApiService: ShopifySubscribersApiService,
    private oneHeaderService: OneHeaderService,
    private sideNavService: SideNavService,
    private breakpointObserver: BreakpointObserver,
    private breakpointService: BreakpointService,
    private dialog: MatDialog,
    private emailOnboardingApiService: EmailOnboardingApiService,
    private smsPhoneNumberVerificationService: SmsPhoneNumberVerificationService
  ) {
    // listen if user logs in from another tab
    addEventListener('storage', (event) => {
      if (event.url?.endsWith('.spec.js')) return // ignore storage events from tests
      // if one of these keys changes, it means that the user has logged in to another account from another tab
      const keys = [this.userSessionKey, 'one_shopify_store_user_id', 'one-shopify-shop-identity']
      if (keys.includes(event?.key)) {
        this.storeChangeListener(event)
      }
    })

    // in some edge cases crisp don't have time to create a session token, so generating custom crisp session id
    // Safari has some weird local storage policy which prevents local storage data to be shared between websites even on same domain,
    // so have to set crisp session id for Safari when embedded app loads
    const setSessionForSafari = this.shopifyService.isEmbedded && this.shopifyService.currentBrowser === BrowserVendor.Safari
    if ((!this.shopifyService.isEmbedded || setSessionForSafari) && typeof $crisp !== 'undefined') {
      if (!this.safeLocalStorageService.getItem(this.crispSessionKey)) {
        this.safeLocalStorageService.setItem(this.crispSessionKey, uuidv4())
      }
    }

    this.titleService.setTitle(this.brandingService.getBrandingData().title)

    this.subscription.add(
      // Update list of pages that should be closed during maintenance
      this.routeAvailabilityService.getConfig().subscribe(() => {
        this.processDisabledPages(window.location.pathname)
      }),
    )
    // LogRocket.init('morpheus/one', {
    //   rootHostname: 'one.store',
    //   mergeIframes: true,
    //   childDomains: ['*']
    // })
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }

  ngOnInit() {

    // if route has resolver show loading overlay
    this.subscription.add(
      this.router.events
        .pipe(
          filter(event => event instanceof ActivationStart),
          filter((event: any) => event?.snapshot?.routeConfig?.resolve),
        ).subscribe(() => {
        this.store.dispatch(new ShowLoading('Navigation'))
      }),
    )

    // hide loading when route is resolved
    this.subscription.add(
      this.router.events
        .pipe(filter(event => event instanceof ActivationEnd))
        .subscribe(event => {
          this.store.dispatch(new HideLoading('Navigation'))
        })
    )

    this.subscription.add(
      this.router.events.pipe(
        filter(event => event instanceof RoutesRecognized),
      ).subscribe((event: RoutesRecognized) => {
          if (this.shopifyService.isEmbedded) {
            this.shopifyService.history.dispatch(History.Action.REPLACE, '/app' + event.urlAfterRedirects)

            // if page has a side stepper enter a full screen mode
            const triggerFullScreen = this.shopifyFullScreenPages.some(route => event.url.includes(route))
            if (triggerFullScreen && !this.shopifyService.fullScreen$.value) {
              this.shopifyService.enableFullScreen()
            }

            /**
             * Open standalone app for specific plans
             */
            const planName = this.userService.userInfo?.shop?.profile?.plan_name
            const url = event.urlAfterRedirects
            const accountId = this.userService.userInfo?.id
            const email = this.userService.userInfo?.email
            const allowedRedirect = this.shopifyService.allowedStandaloneRedirect(planName, accountId, email, url)
            if (allowedRedirect && !this.standaloneShown) {
              this.standaloneShown = true
              window.open(`${window.location.origin}/app${event.url}?redirectForStandalone` , '_blank')
            }
          } else {
            const redirectForStandalone = window.location.search.includes('redirectForStandalone')
            const storedToken = this.safeLocalStorageService.getItem('one_c_token')
            if (redirectForStandalone && storedToken && this.authService.getCurrentUser()) {
              // Remove both ?redirectForStandalone and &redirectForStandalone from the URL
              const url = window.location.href.replace(/[?&]redirectForStandalone/g, '')
              window.history.replaceState({}, document.title, url)
              this.showLoading = true
              // login with stored token
              this.authService.signInWithCustomToken(storedToken).finally(() => {
                this.showLoading = false
              })
            }
          }

          // show language picker for top level pages
          const showLanguagePicker = !this.hideConveyPages.some(route => event.url.includes(route))
          if (showLanguagePicker) {
           this.displayLanguagePicker(true)
          } else {
            this.displayLanguagePicker(false)
          }

            if (!event.urlAfterRedirects.includes('unavailable')) {
             this.processDisabledPages(event.url)
            }
          // Previous code:
          // const url = event.urlAfterRedirects
          // const closedPageParams = this.routeAvailabilityService.findMatch(url)
          // if (closedPageParams) {
          //   this.routeAvailabilityService.title$.next(closedPageParams.title)
          //   this.router.navigate(['/unavailable'])
          // } else {
          //   return
          // }
        }),
    )

    this.subscription.add(this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        tap((event: NavigationEnd) => {
          // A way to skip saving previous url for routes that have skip_previous_url property
          let childRoute = this.activatedRoute.firstChild
          let shouldSkipPreviousUrl = false
          while (childRoute) {
            const data = childRoute?.snapshot?.data
            if (data.skip_previous_url) {
              shouldSkipPreviousUrl = true
            }
            childRoute = childRoute.firstChild
          }
          if (!shouldSkipPreviousUrl) {
            this.previousUrlService.currentUrl = event.url
          }
          this.previousUrlService.previousUrl = this.previousUrlService.currentUrl
        }),
        map(() => this.activatedRoute),
        map(route => {
          let routes = []
          // First walk down the route tree
          while (route.firstChild) {
            routes.push(route.firstChild)
            route = route.firstChild
          }
          // Then walk back up and return the first route that has hide_navbar property
          // this will allow us to show navbar on child routes if needed and hide it on parent
          for (const item of routes.reverse()) {
            if (
              item.data.value.hasOwnProperty('hide_navbar') ||
              item.data.value.hasOwnProperty('oneHeaderView')
            ) {
              return item
            }
          }

          return route
        }),
      ).pipe(
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data),
      ).subscribe((data: Data) => {
        this.showNavigationBar = !data.hide_navbar
        if (data.oneHeaderView) {
          this.oneHeaderService.setView(data.oneHeaderView)
        } else {
          this.oneHeaderService.setView(data.defaultView)
        }
        this.pageTitle = data?.pageTitle || null
        // Fugly way of handling backBtnUrl when module might be used in different routes
        // It expects it to be like ../ or ../../
        if (data?.backBtnUrl && /^(\.\.\/)+$/.test(data.backBtnUrl)) {
          // Determine the depth of navigation back
          let backDepth = (data.backBtnUrl.match(/\.\.\//g) || [])?.length
          // Get the base path of the application from baseURI, removing the protocol, domain, and trailing slash if present
          let basePath = new URL(document.baseURI).pathname.replace(/\/$/, '')
          // Split the base path into segments and filter out 'app'
          let basePathSegments = basePath?.split('/')?.filter(path => path !== '' && path !== 'app')
          // Get the current path excluding the domain and base path
          let currentPathSegments = window.location.pathname.replace(basePath, '').split('/').filter(path => path !== '')
          // Calculate the remaining segments after removing the last 'n' segments based on backDepth
          let remainingSegments = currentPathSegments?.slice(0, -backDepth)
          // Construct the new URL by combining the base path with the remaining segments
          this.backBtnUrl = '/' + [...basePathSegments, ...remainingSegments].join('/') + '/'
        } else {
          // Usual way of handling backBtnUrl
          this.backBtnUrl = data?.backBtnUrl || null
        }
      }))

    this.store.dispatch(new ResetLoading()) // FIXME: It is a quick dirty fix for issue with localStorageSyncReducer (pendingList is not empty on the init)

    this.subscription.add(this.store.pipe(select(getSignUpFlowCompleted))
      .subscribe(next => {
        this.signUpFlowCompleted = next
      }))
    this.analytics.trackPageViews()

    this.subscription.add(this.authService.user$.subscribe(user => user !== null && this.processLoggedInUser(user)))

    this.setCrispReadyTrigger()

    // Flywheel init
    const isAdminAsUser = !!this.adminUserTokenService.getValue()
    const disableFw = this.safeLocalStorageService.getItem('flywheel_disable') === 'true'
    if (!disableFw) {
      window['flywheel'] = FlywheelAnalytics.load({
        writeKey: "beb03055-6566-4005-b7ad-a4dffbfd1bfd",
        apiHost: "theflywheel.app",
        disable: isAdminAsUser,
      })
    }

    this.subscription.add(this.fullScreenService
      .asObservable()
      .subscribe(state => {
        this.isFullScreen = state.enabled
        if (this.isFullScreen) {
          this.document.body.classList.add('_in-full-screen')
        } else {
          this.document.body.classList.remove('_in-full-screen')
        }
      }))

    this.checkIfRedirectIsPending()

    if (!!environment.segmentApiKey) {
      this.segmentAnalyticsService.load(environment.segmentApiKey)
    }

    this.subscription.add(
      this.adminUserTokenService.asObservable().subscribe(val => {
        const isLiveWebsite = window.location.host.includes('prooffactor.com') || window.location.host.includes('one.store')
        if (val && isLiveWebsite) {
          window.document.body.classList.add('page-warning-border')
        } else {
          window.document.body.classList.remove('page-warning-border')
        }
      }),
    )

    // Conveythis init script
    this.subscription.add(
      this.userService.shopUserRequestDone$.subscribe(isDone => {
        switch (isDone) {
          // If request was done or skipped
          case true:
          case false:
            let showLanguageSelector = false
            const plan = this.userService.userInfo?.shop?.profile?.plan_name
            // Show language selector for staff
            if (plan) {
              if (
                plan === 'staff' ||
                plan === 'staff_business' ||
                plan === 'sales_training' ||
                plan === 'shopify_alumni'
              ) {
                showLanguageSelector = true
              }
            }
            // Show language selector for non-english locales
            if (this.userService.userInfo?.shop_user?.locale && !this.userService.userInfo?.shop_user?.locale?.contains('en')) {
              showLanguageSelector = true
            }
            // Show language selector for non-english browsers
            const isEngLang = (
              navigator.languages.length === 1 && /^en\b/.test(navigator.language)
            ) || (
              navigator.languages.length === 2 && /^en\b/.test(navigator.languages[0]) && /^en\b/.test(navigator.languages[1])
            )
            if (!isEngLang) {
              showLanguageSelector = true
            }
            if (showLanguageSelector && !this.translationWidgetAdded) {
              this.addConveyThisScript()
            }
            break
          // If it we don't know yet
          case null:
            break
        }
      })
    )

    // Monitoring the viewport width
    const bp = this.breakpointService.bp$.value
    this.subscription.add(
      this.breakpointObserver.observe(
        Object.values(this.breakpointService.breakpoints).map(bp => `(max-width: ${bp.value})`)
      ).subscribe(result => {
        for (let key in result.breakpoints) {
          // Extracting the numerical value from the breakpoint string
          let width = key.split(': ')[1].replace('px)', '')
          // Construct the key for the bp object
          let bpKey = '_' + width
          // Update the active state
          if (bp.hasOwnProperty(bpKey)) {
              bp[bpKey].active = !result.breakpoints[key]
          }
        }
        try {
          window['ONE_BP'] = bp
        } catch (e) {
          console.error(e)
        }
        this.breakpointService.bp$.next(bp)
      })
    )
  } // ngOnInit


  addConveyThisScript() {
    const _conveyThisScript = document.createElement('script')
    _conveyThisScript.setAttribute('src', 'https://cdn.conveythis.com/javascript/conveythis-initializer.js')
    _conveyThisScript.setAttribute('type', 'text/javascript')
    window.document.head.appendChild(_conveyThisScript)
    _conveyThisScript.onload = () => {
      window['ConveyThis_Initializer']?.init({
        api_key: 'pub_ec24fe650ddda8d659ecd445f49b9924'
      })
      this.translationWidgetAdded = true
    }
  }

  updateProfilesCount() {
    const fields = ['contacts_total', 'subscribers_total', 'customers_total', 'active_known_customers_total'] as ContactsResponseType[]
    this.subscription.add(
      this.shopifySubscribersApiService.getContactsStatisticsField(fields).subscribe(res => {
        this.shopifyService.contactsCount$.next(res?.contacts_total)
        this.shopifyService.subscribersCount$.next(res?.subscribers_total)
        this.shopifyService.customersCount$.next(res?.customers_total)
        this.shopifyService.activeKnownCustomersCount$.next(res?.active_known_customers_total)
      })
    )
  }

  getEmailActivity() {
    this.subscription.add(
      this.emailOnboardingApiService.getSentEmailStats().subscribe(res => {
        this.shopifyService.highEmailActivity$.next(res?.high_email_activity || false)
      })
    )
  }

  updateCampaignsCount() {
    this.activeAppCampaignService.refreshTotals()
  }

  displayLanguagePicker(visible) {
    const display = visible ? 'block' : 'none'
    setTimeout(() => {
      const convey = document.getElementById('conveythis-wrapper')
      convey && convey.style.setProperty('display', display, 'important');
    }, 200)
  }

  processDisabledPages(url) {
    const disabledPages = this.routeAvailabilityService.disabledPages
    const recognizedUrl = url.slice(1) // to support urls of '/url' and 'url' types
    const pageUnavailable = disabledPages.includes(recognizedUrl) || disabledPages.includes(url)
    const partialMatches = disabledPages.filter(url => url.endsWith('*'))

    if (!disabledPages.length) {
      return
    }

    if (partialMatches.length) {
      partialMatches.forEach(item => {
        if (url.indexOf(item.replace('*', '')) !== -1) {
          this.navigateToUnavailablePage(url)
        }
      })
    }

    // disable all app
    if (disabledPages.includes('/')) {
      this.navigateToUnavailablePage(url)
      return
    }

    if (pageUnavailable) {
      this.navigateToUnavailablePage(url)
    }
  }

  navigateToUnavailablePage(url) {
    this.safeLocalStorageService.setItem('pf-maintenance-redirect-back-link', url)
    this.router.navigate(['/unavailable'])

  }

  checkIfRedirectIsPending() {
    const redirect = this.redirectService.check()
    if (redirect && redirect.length) {
      this.router.navigate([redirect])
    }
  }

  set_cross_cookie(account_id) {
    import(/* webpackChunkName: "xdomaincookie" */ 'xdomain-cookies/src/xdomain_cookie.js')
      .then((exports) => {
        const xd_cookie = exports.xDomainCookie(`//${environment.firebase.cdnUrl}`, 'prooffactor-widget', true, 10000, false, false)
        const cookie_key = 'proof_factor_account_id'
        xd_cookie.get(cookie_key, (proof_factor_account_id) => {
          this.logger.log(LogLabel.AppComponent, `proof_factor_account_id: ${proof_factor_account_id} setting to: ${account_id}`)
          if (account_id && account_id.length > 0) {
            xd_cookie.set(cookie_key, account_id)
          }
        })
      })
  }

  setCrispReadyTrigger(): void {
    window['CRISP_READY_TRIGGER'] = () => {
      const isAdminAsUser = this.adminUserTokenService.getValue()
      if (!isAdminAsUser) {
        if (typeof $crisp !== 'undefined') {
          const displayName = _.get(this.authService, 'userDetails.displayName') || _.get(this.authService, 'userDetails.email')
          const email = _.get(this.authService, 'userDetails.email')
          if (Boolean(email)) {
            $crisp.push(['set', 'user:email', [email]])
          }
          if (Boolean(displayName)) {
            $crisp.push(['set', 'user:nickname', [displayName]])
          }
        }
        this.addCrispHelpMsgListener()
      }
    }

    this.subscription.add(this.actions$
      .pipe(ofType(UserActionTypes.LOGOUT))
      .subscribe(() => this.isAuthenticated = false))
  }

  addCrispHelpMsgListener() {
    $crisp.push(['on', 'message:received', (e) => {
      if (e?.content?.includes('Do you need help setting up')) {
        $crisp.push(['on', 'message:sent', () => {
          this.segmentAnalyticsService.track('Crisp Msg - Merchant Reply for Help')
          $crisp.push(['on', 'message:sent', null])
        }])
      }
    }])
  }

  processLoggedInUser(user) {
    /* Remove banner session key */
    if (this.safeLocalStorageService.getItem(this.bannerSessionKey)) {
      this.displayBanner = false
      /* If user auth is different, make it displayed again */
      if (user?.uid !== this.safeLocalStorageService.getItem(this.userSessionKey)) {
        this.safeLocalStorageService.removeItem(this.bannerSessionKey)
        this.displayBanner = true
      }
    }

    /* Set user session key */
    if (user?.uid) {
      this.safeLocalStorageService.setItem(this.userSessionKey, user.uid)
    }

    /* Redirect to Canny */

    const redirectUrl = this.safeLocalStorageService.getItem('proof_before_login_url')
    if (redirectUrl && redirectUrl.indexOf('/login/canny')) {
      /* Make sure we delete the existing redirect url*/
      this.safeLocalStorageService.removeItem('proof_before_login_url')
      window.location.href = redirectUrl
    }

    this.set_cross_cookie(this.authService.userDetails.uid)

    if (window['profitwell'] && this.authService.userDetails.email) {
      (window as any).profitwell('start', { 'user_email': this.authService.userDetails.email })
    }

    if (!this.cookieService.get(PROOF_FACTOR_USER_SESSION_COOKIE)) {
      const isAdminAsUser = this.adminUserTokenService.getValue()
      if (!isAdminAsUser) {
        this.subscription.add(this.apiService
          .post('/v1/me/sessions', { start: true })
          .subscribe(() => {
            const now = new Date()
            const expiration_date = moment(now).add(30, 'm').toDate()
            this.cookieService.set(PROOF_FACTOR_USER_SESSION_COOKIE, 'active', expiration_date, '/')
          }))
      }
    }

    // No load usage
    const action = new ReadBillingRequest()
    action.noLoad = true
    this.store.dispatch(action)
    this.subscription.add(
      forkJoin([
        this.userService.readUser(),
        this.userService.readInteractions(),
        this.userService.readUserUsage(),
      ]).pipe(
        concatMap(([_userData, _interactionsData, _userUsageData]) => {
          // const stripeCustomerId = 'cus_PlfciKxxJWZFEE' // 2 products in subscription
          // const stripeCustomerId = 'cus_Pjo7nuDut5KpYo' // 2 product in 1 subscription
          // const stripeCustomerId = 'cus_Plfep4XUDf8oVh' // 2 products in 2 subscriptions
          const stripeCustomerId = _interactionsData?.stripe_billing?.customer_id
          const stripeSubscriptionId = _interactionsData?.stripe_billing?.subscription_id
          if (stripeCustomerId) {
            return this.cartApiService.getSubscriptionsByCustomerId(stripeCustomerId).pipe(
              map(stripeResponse => {
                if (stripeResponse.data?.length) {
                  // FIXME: Uncertain how to handle multiple subscriptions
                  _userData.stripe_billing = {
                    subscription: stripeResponse.data[0],
                    subscription_id: stripeSubscriptionId || stripeResponse.data[0]?.id,
                    customer_id: stripeCustomerId,
                  }
                } else {
                  _userData.stripe_billing = {
                    customer_id: stripeCustomerId,
                  }
                }
                return ([_userData, _interactionsData, _userUsageData])
              })
            )
          } else {
            _userData.stripe_billing = {}
            return of([_userData, _interactionsData, _userUsageData])
          }
        }),
      ).subscribe({
        next: ([userData, interactionsData, userUsageData]: [UserInfo, GettingStartedInteractions, UserUsageInfo]) => {
          this.logger.log(<LogLabel>'app-component', 'userData: ', userData, 'interactionsData: ', interactionsData, 'userUsageData: ', userUsageData)
          this.store.dispatch(new SetCurrentUserInfo(userData))
          this.isAuthenticated = true
          this.handleInteractionsData(interactionsData)
          this.setCrispSession()
          this.smsPhoneNumberVerificationService.checkSMSPhoneNumberVerification()
          if (userData?.shop?.type === 'ShopifyShop') {
            this.updateProfilesCount()
            this.getEmailActivity()
            this.showSMSCreditsModalIfNeeded(interactionsData.onboarding_sms)
          }
          this.updateCampaignsCount()
          if (userData) {
            this.promoService.definePromo({}, userData)
          }
        },
        error: (error) => {
          this.logger.error(LogLabel.AppComponent, 'Error fetching user data', error)
          throw error
        },
        complete: () => {
        }
      })
    )

    const onboarding_action = new GetUserOnboardingRequest()
    onboarding_action.noLoad = true
    this.store.dispatch(onboarding_action)
  }

  showSMSCreditsModalIfNeeded(smsOnboarding) {
    const credits = this.userService.smsCredits
    const smsOnboardingCompleted = smsOnboarding?.completed || smsOnboarding?.single_opt_in_terms_completed
    if (smsOnboardingCompleted && credits < 1) {
      const dialog = this.dialog.open(ConfirmModalComponent, {
        width: '400px',
        data: {
          title: 'You’ve run out of SMS Credits!',
          text: 'It looks like you’ve run out of SMS credits! In order to make sure that your SMS related campaigns still function properly, make sure to purchase some more! We recommend enabling Auto-refill so that you don’t run out of credits again!',
          cancelButton: {
            text: 'Later',
            classes: 'pf-button outline black w-100',
          },
          acceptButton: {
            text: 'Buy Credits',
            classes: 'pf-button filled black w-100',
          },
          footerClass: 'mb-0 p-0'
        }
      })

      this.subscription.add(
        dialog.afterClosed().subscribe((res) => {
          if (res) {
            this.router.navigate([`/${RouteHeaderUrl.settings}/${RouteHeaderUrl.billing}/${RouteHeaderUrl.sms}`])
              .then(() => {
                this.segmentAnalyticsService.track('Login_Modal_SMS_Credits')
              })
          } else {
            this.segmentAnalyticsService.track('Login_Modal_SMS_Credits_Closed')
          }
        })
      )
    }
  }


  handleInteractionsData(data: GettingStartedInteractions) {
    const hasStatus = data && data.onboarding && data.onboarding.status
    const isCompleted = hasStatus
      ? this.gettingStartedService.isCompletedByStatus(data.onboarding.status as GettingStartedStatus)
      : false
    this.store.dispatch(new SetGettingStartedCompleted(isCompleted))

    const hasLaunchedFirstCampaign = hasStatus ? data.onboarding.status.firstCampaignLaunched : false
    this.store.dispatch(new SetGettingStartedFirstCampaignLaunched(hasLaunchedFirstCampaign))
    const hasSkippedFirstCampaign = hasStatus ? !!data.onboarding.status.firstCampaignSkipped : false
    this.store.dispatch(new SetGettingStartedFirstCampaignSkipped(hasSkippedFirstCampaign))
    if (hasStatus && data.onboarding.status.firstCampaignId) {
      this.store.dispatch(new SetGettingStartedFirstCampaignId(data.onboarding.status.firstCampaignId))
    }
    if (!hasStatus) {
      this.addGettingStartedStatus()
      return
    }
    this.store.dispatch(new SetGettingStartedEvents(data.onboarding.events))
    this.store.dispatch(new SetGettingStartedStatus(data.onboarding.status))
  }

  addGettingStartedStatus() {
    const updatedInteractions = this.gettingStartedService.generateInteractionsBody()

    this.subscription.add(this.gettingStartedService
      .updateInteractions(updatedInteractions)
      .subscribe(() => this.store.dispatch(new SetGettingStartedStatus(updatedInteractions.onboarding.status))))

  }

  showNavigationBar = true

  get showNavbar(): boolean {
    return this.isAuthenticated && this.showNavigationBar
  }

  /* - Show banner after navigation appeared, use for promo
  /* const bannerState = this.displayBanner && this.showNavbar
  /* - Show banner whatever, use for maintenance
  /* const bannerState = this.displayBanner
  **/
  get showBanner(): boolean {
    const bannerState = this.displayBanner
    this.promoService.fullWidthBanner$.next(bannerState)
    return bannerState
  }

  get showStarterNavbar() {
    return this.isAuthenticated
      && [
        '/subscriptions',
        '/onboarding/signup/new/welcome',
        '/onboarding/signup/returning/welcome',
        '/onboarding/signup/new/subscriptions',
        '/onboarding/signup/returning/subscriptions',
        '/onboarding/signup/returning/questionnaire',
      ].some(value => this.router.url.indexOf(value) === 0)
  }

  updateBanner() {
    const maintenanceTime = this.maintainanceTime;
    const now = moment.utc();

    if (this.emailSenderRequirementsUpdate) {
      this.bannerHTML = `Starting February 1, Google and Yahoo will enforce new email sender requirements. <a href="https://help.one.store/en/article/understanding-dmarc-email-sending-requirements-1bbffxq/?bust=1705446843832" target="_blank">Learn more about how to prepare<a>`
    } else if (now < maintenanceTime) {
      const diff = maintenanceTime.diff(now);
      const duration = moment.duration(diff);

      const days = duration.days();
      const hours = duration.hours();
      const minutes = duration.minutes();
      const seconds = duration.seconds();

      const formattedTime = `${days}d ${hours}h ${minutes}m ${seconds}s`;
      this.bannerHTML = `<b>Planned Database Upgrade (Part 4)</b> | ONE platform will be temporarily down in <b>${formattedTime}</b> | Saturday, 4 Nov at 3:30 PM EST (<b>7:30 PM UTC</b>)`;
    } else {
      this.bannerHTML = "<b>Database Upgrade (Part 3) Optimization<b> is now in progress... expect slow speeds or downtime";
      // this.bannerHTML = "<b>Database Upgrade (Part 3.5)</b> is now complete. Thank you for your patience!";
      clearInterval(this.bannerInterval);
    }
  }

  closeBanner() {
    this.displayBanner = false;
    this.safeLocalStorageService.setItem(this.bannerSessionKey, 'true')
    clearInterval(this.bannerInterval);
  }

  setCrispSession() {
    const crisp = this.crispService.getCrisp()
    if (crisp) {
      const isAdminAsUser = this.adminUserTokenService.getValue()
      const crispSessionToken = this.safeLocalStorageService.getItem(this.crispSessionKey)

      if (!isAdminAsUser && crispSessionToken) {
        window['CRISP_TOKEN_ID'] = crispSessionToken
        $crisp.push(['do', 'session:reset'])
      }
    }
  }

  private storeChangeListener(event) {
    const dialogExists = this.dialog.getDialogById('reload-app-modal')
    if (event?.newValue === event?.oldValue || dialogExists) return
    const dialog = this.dialog.open(ConfirmModalComponent, {
      width: '560px',
      id: 'reload-app-modal',
      data: {
        title: 'Multiple Account Logins Detected',
        html: `
          <p>
          We noticed that you are logged in to multiple ONE accounts at the same time, likely in different
          tabs in your browser. <b>This could result in unexpected behavior within the app.</b>
          <p>
          We highly recommend only logging in to one account at a time.
          Please close all the other tabs that you have the ONE app open in, and then <b>refresh this page</b>.
          </p>
        `,
        acceptButton: {
          text: 'Refresh Page',
          classes: 'pf-button filled blue sm',
        },
      } as ConfirmModalConfig,
    })
    this.subscription.add(
      dialog.afterClosed().subscribe((res) => {
        if (res) {
          this.shopifyService.redirectToUrlWithReload('/')
        }
      })
    )
  }

  ngAfterViewInit(): void {
    if (this.displayBanner) {
      this.bannerInterval = setInterval(() => {
        this.updateBanner();
      }, 1000);
    }
  }
}
