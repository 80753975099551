import moment from "moment";
import { HolidayTypes } from "../models/promotion.models";

export class PromotionCards {
  today = moment().startOf('day').utc()

  cards = {
    january: [
      {
        id: HolidayTypes.NewYear,
        class: '_new-year',
        title: 'New Year',
        buttonText: 'Explore',
        active: {
          start: '2024-01-03T00:00:00.000',
          end: '2024-01-13T00:00:00.000',
        },
        date: `2024-01-01`,
        time_left: this.daysLeft(`2024-01-01`),
      },
    ],
    february: [
      {
        id: HolidayTypes.LunarNewYear,
        class: '_lunar-new-year',
        title: 'Lunar New Year',
        buttonText: 'Explore',
        active: {
          start: '2024-01-01T00:00:00.000',
          end: '2024-02-16T00:00:00.000',
        },
        time_left: this.daysLeft(`2024-02-10`),
        date: `2024-02-10`,
      },
      {
        id: HolidayTypes.SuperBowl,
        class: '_super-bowl',
        title: `Super Bowl`,
        buttonText: 'Explore',
        active: {
          start: '2024-01-02T00:00:00.000',
          end: '2024-02-14T00:00:00.000',
        },
        time_left: this.daysLeft(`2024-02-11`),
        date: `2024-02-11`,
      },
      {
        id: HolidayTypes.ValentinesDay,
        class: '_valentines-day',
        title: `Valentine's Day`,
        buttonText: 'Explore',
        active: {
          start: '2024-01-02T00:00:00.000',
          end: '2024-02-15T00:00:00.000',
        },
        time_left: this.daysLeft(`2024-02-14`),
        date: `2024-02-14`,
      },
      {
        id: HolidayTypes.LanternFestival,
        class: '_lantern-festival',
        title: `Lantern Festival`,
        buttonText: 'Explore',
        active: {
          start: '2024-01-15T00:00:00.000',
          end: '2024-02-25T00:00:00.000',
        },
        time_left: this.daysLeft(`2024-02-24`),
        date: `2024-02-24`,
      },
    ],
    march: [
      {
        id: HolidayTypes.WomensDay,
        class: '_womens-day',
        title: `Women's Day`,
        buttonText: 'Explore',
        active: {
          start: '2024-02-15T00:00:00.000',
          end: '2024-03-09T00:00:00.000',
        },
        time_left: this.daysLeft(`2024-03-08`),
        date: `2024-03-08`,
      },
      {
        id: HolidayTypes.StPatricksDay,
        class: '_st-patricks-day',
        title: `St. Patrick's Day`,
        buttonText: 'Explore',
        active: {
          start: '2024-03-01T00:00:00.000',
          end: '2024-03-18T00:00:00.000',
        },
        time_left: this.daysLeft(`2024-03-17`),
        date: `2024-03-17`,
      },
      {
        id: HolidayTypes.HoliFestival,
        class: '_holi-festival',
        title: `Holi Festival`,
        buttonText: 'Explore',
        active: {
          start: '2024-03-12T00:00:00.000',
          end: '2024-03-25T00:00:00.000',
        },
        time_left: this.daysLeft(`2024-03-24`),
        date: `2024-03-24`,
      },
      {
        id: HolidayTypes.MothersDay,
        class: '_mothers-day',
        title: `Mother's Day(UK)`,
        buttonText: 'Explore',
        active: {
          start: '2024-03-1T00:00:00.000',
          end: '2024-03-11T00:00:00.000',
        },
        time_left: this.daysLeft(`2024-03-10`),
        date: `2024-03-10`,
      },
      {
        id: HolidayTypes.Easter,
        class: '_easter',
        title: `Easter`,
        buttonText: 'Explore',
        active: {
          start: '2024-03-24T00:00:00.000',
          end: '2024-04-01T00:00:00.000',
        },
        time_left: this.daysLeft(`2024-03-31`),
        date: `2024-03-31`,
      },
      {
        id: HolidayTypes.Ramadan,
        class: '_ramadan',
        title: `Ramadan`,
        buttonText: 'Explore',
        active: {
          start: '2024-03-10T00:00:00.000',
          end: '2024-04-09T00:00:00.000',
        },
        time_left: this.daysLeft(`2024-03-10`),
        date: `2024-03-10`,
      },
    ],
    april: [],
    may: [
      {
        id: HolidayTypes.LaborDay,
        class: '_labor-day',
        title: `Labor Day`,
        buttonText: 'Explore',
        active: {
          start: '2024-04-28T00:00:00.000',
          end: '2024-05-02T00:00:00.000',
        },
        time_left: this.daysLeft(`2024-05-01`),
        date: `2024-05-01`,
      },
      {
        id: HolidayTypes.CincoDeMayo,
        class: '_cinco-de-mayo',
        title: `Cinco de Mayo`,
        buttonText: 'Explore',
        active: {
          start: '2024-05-02T00:00:00.000',
          end: '2024-05-06T00:00:00.000',
        },
        time_left: this.daysLeft(`2024-05-05`),
        date: `2024-05-05`,
      },
      {
        id: HolidayTypes.MothersDay,
        class: '_mothers-day',
        title: `Mother's Day`,
        buttonText: 'Explore',
        active: {
          start: '2024-05-01T00:00:00.000',
          end: '2024-05-13T00:00:00.000',
        },
        time_left: this.daysLeft(`2024-05-12`),
        date: `2024-05-12`,
      },
      {
        id: HolidayTypes.MemorialDay,
        class: '_memorial-day',
        title: `Memorial Day`,
        buttonText: 'Explore',
        active: {
          start: '2024-05-06T00:00:00.000',
          end: '2024-05-28T00:00:00.000',
        },
        time_left: this.daysLeft(`2024-05-27`),
        date: `2024-05-27`,
      },
    ],
    june: [
      {
        id: HolidayTypes.PrideMonth,
        class: '_pride-month',
        title: `Pride Month`,
        buttonText: 'Explore',
        active: {
          start: '2024-06-01T00:00:00.000',
          end: '2024-07-01T00:00:00.000',
        },
        time_left: this.daysLeft(`2024-06-30`),
        date: `2024-06-30`,
      },
      {
        id: HolidayTypes.ChildrensDay,
        class: '_childrens-day',
        title: `Children's Day`,
        buttonText: 'Explore',
        active: {
          start: '2024-05-31T00:00:00.000',
          end: '2024-06-10T00:00:00.000',
        },
        time_left: this.daysLeft(`2024-06-09`),
        date: `2024-06-09`,
      },
      {
        id: HolidayTypes.FathersDay,
        class: '_fathers-day',
        title: `Father's Day`,
        buttonText: 'Explore',
        active: {
          start: '2024-05-09T00:00:00.000',
          end: '2024-06-17T00:00:00.000',
        },
        time_left: this.daysLeft(`2024-06-16`),
        date: `2024-06-16`,
      },
      {
        id: HolidayTypes.FirstDayOfSummer,
        class: '_summer',
        title: `First Day of Summer`,
        buttonText: 'Explore',
        active: {
          start: '2024-06-14T00:00:00.000',
          end: '2024-06-22T00:00:00.000',
        },
        time_left: this.daysLeft(`2024-06-20`),
        date: `2024-06-20`,
      },
      {
        id: HolidayTypes.EidAlAdha,
        class: '_eid-al-adha',
        title: `Eid al-Adha`,
        buttonText: 'Explore',
        active: {
          start: '2024-06-10T00:00:00.000',
          end: '2024-06-19T00:00:00.000',
        },
        time_left: this.daysLeft(`2024-06-16`, `2024-06-19`),
        date: `2024-06-16`,
      },
    ],
    july: [
      {
        id: HolidayTypes.IndependenceDay,
        class: '_independence-day',
        title: `Independence Day`,
        buttonText: 'Explore',
        active: {
          start: '2024-06-20T00:00:00.000',
          end: '2024-07-05T00:00:00.000',
        },
        time_left: this.daysLeft(`2024-07-04`),
        date: `2024-07-04`,
      },
    ],
    august: [
      {
        id: HolidayTypes.RakshaBandhanDay,
        class: '_raksha-bandhan',
        title: `Raksha Bandhan Day`,
        buttonText: 'Explore',
        active: {
          start: '2024-08-01T00:00:00.000',
          end: '2024-08-20T00:00:00.000',
        },
        time_left: this.daysLeft(`2024-08-19`),
        date: `2024-08-19`,
      },
      {
        id: HolidayTypes.PakistanIndependenceDay,
        class: '_pakistan-independence-day',
        title: `Pakistan Independence Day`,
        buttonText: 'Explore',
        active: {
          start: '2024-08-01T00:00:00.000',
          end: '2024-08-15T00:00:00.000',
        },
        time_left: this.daysLeft(`2024-08-14`),
        date: `2024-08-14`,
      },
      {
        id: HolidayTypes.IndiaIndependenceDay,
        class: '_india-independence-day',
        title: `India Independence Day`,
        buttonText: 'Explore',
        active: {
          start: '2024-07-31T00:00:00.000',
          end: '2024-08-16T00:00:00.000',
        },
        time_left: this.daysLeft(`2024-08-15`),
        date: `2024-08-15`,
      },
      {
        id: HolidayTypes.UkraineIndependenceDay,
        class: '_ukraine-independence-day',
        title: `Ukraine Independence Day`,
        buttonText: 'Explore',
        active: {
          start: '2024-08-01T00:00:00.000',
          end: '2024-08-25T00:00:00.000',
        },
        time_left: this.daysLeft(`2024-08-24`),
        date: `2024-08-24`,
      },
    ],
    september: [
      {
        id: HolidayTypes.LaborDay,
        class: '_labor-day',
        title: `Labor Day`,
        buttonText: 'Try It',
        active: {
          start: '2024-08-25T00:00:00.000',
          end: '2024-09-03T00:00:00.000',
        },
        time_left: this.daysLeft(`2024-09-02`),
        date: `2024-09-02`,
      },
      {
        id: HolidayTypes.MidAutumnFestival,
        class: '_mid-autumn-festival',
        title: `Mid-Autumn Festival`,
        buttonText: 'Explore',
        active: {
          start: '2024-08-25T00:00:00.000',
          end: '2024-09-18T00:00:00.000',
        },
        time_left: this.daysLeft(`2024-09-17`),
        date: `2024-09-17`,
      },
      {
        id: HolidayTypes.EidMiladUnNabi,
        class: '_eid-milad-un-nabi',
        title: `Eid Milad-un-Nabi`,
        buttonText: 'Explore',
        active: {
          start: '2024-09-01T00:00:00.000',
          end: '2024-09-17T00:00:00.000',
        },
        time_left: this.daysLeft(`2024-09-15`, `2024-09-16`),
        date: `2024-09-16`,
      },
    ],
    october: [
      {
        id: HolidayTypes.NavratriFestival,
        class: '_navratri-festival',
        title: `Navratri Festival`,
        buttonText: 'Explore',
        active: {
          start: '2024-09-25T00:00:00.000',
          end: '2024-10-13T00:00:00.000',
        },
        time_left: this.daysLeft(`2024-10-03`, `2024-10-12`),
        date: `2024-10-12`,
      },
      {
        id: HolidayTypes.NigeriaIndependenceDay,
        class: '_nigeria-independence-day',
        title: `Nigeria Independence Day`,
        buttonText: 'Explore',
        active: {
          start: '2024-09-01T00:00:00.000',
          end: '2024-10-02T00:00:00.000',
        },
        time_left: this.daysLeft(`2024-10-01`),
        date: `2024-10-01`,
      },
      {
        id: HolidayTypes.DoubleTenthDay,
        class: '_double-tenth-national-day',
        title: `Double Tenth National Day`,
        buttonText: 'Explore',
        active: {
          start: '2024-09-01T00:00:00.000',
          end: '2024-10-11T00:00:00.000',
        },
        time_left: this.daysLeft(`2024-10-10`),
        date: `2024-10-10`,
      },
      {
        id: HolidayTypes.Mashujaa,
        class: '_mashujaa',
        title: 'Mashujaa Day',
        buttonText: 'Explore',
        active: {
          start: '2024-10-01T00:00:00.000',
          end: '2024-10-21T00:00:00.000',
        },
        time_left: this.daysLeft(`2024-10-20`, `2024-10-21`),
        date: `2024-10-20`,
      },
      {
        id: HolidayTypes.Halloween,
        class: '_halloween',
        title: 'Halloween',
        buttonText: 'Explore',
        active: {
          start: '2024-10-09T00:00:00.000',
          end: '2024-11-01T00:00:00.000',
        },
        time_left: this.daysLeft(`2024-10-31`),
        date: `2024-10-31`,
      },
    ],
    november: [
      {
        id: HolidayTypes.Diwali,
        class: '_diwali',
        title: `Diwali`,
        buttonText: 'Explore',
        active: {
          start: '2024-10-21T00:00:00.000',
          end: '2024-11-05T00:00:00.000',
        },
        time_left: this.daysLeft(`2024-10-31`,`2024-11-04`),
        date: `2024-11-04`,
      },
      {
        id: HolidayTypes.DiaDeMuertos,
        class: '_dia-de-muertos',
        title: 'Día de Muertos',
        buttonText: 'Explore',
        active: {
          start: '2024-10-21T00:00:00.000',
          end: '2024-11-03T00:00:00.000',
        },
        time_left: this.daysLeft(`2024-11-01`, `2023-11-02`),
        date: `2024-11-02`,
      },
      {
        id: HolidayTypes.Double11,
        class: '_double-11',
        title: 'Singles\' Day 11.11',
        buttonText: 'Explore',
        active: {
          start: '2024-11-01T00:00:00.000',
          end: '2024-11-12T00:00:00.000',
        },
        time_left: this.daysLeft(`2024-11-11`),
        date: `2024-11-11`,
      }, // PF-5131 2023-11-20 update 2024 year date above
      {
        id: HolidayTypes.Thanksgiving,
        class: '_thanksgiving',
        title: 'Thanksgiving',
        buttonText: 'Explore',
        active: {
          end: '2023-11-26T00:00:00.000'
        },
        time_left: this.daysLeft(`2023-11-24`),
        date: `2023-11-24`,
      },
      {
        id: HolidayTypes.BlackFriday,
        class: '_black-friday',
        title: 'Black Friday',
        buttonText: 'Explore',
        active: {
          start: '2023-11-20T00:00:00.000',
          end: '2023-11-27T00:00:00.000',
        },
        time_left: this.daysLeft(`2023-11-25`),
        date: `2023-11-25`,
      },
      {
        id: HolidayTypes.CyberMonday,
        class: '_cyber-monday',
        title: 'Cyber Monday',
        buttonText: 'Explore',
        active: {
          end: '2023-11-28T00:00:00.000',
        },
        time_left: this.daysLeft(`2023-11-28`),
        date: `2023-11-28`,
      },
      {
        id: HolidayTypes.CyberWeek,
        class: '_cyber-week',
        title: 'Cyber Week',
        buttonText: 'Explore',
        active: {
          start: '2023-11-26T00:00:00.000',
          end: '2023-12-04T00:00:00.000',
        },
        time_left: this.daysLeft(`2023-12-04`),
        date: `2023-12-04`,
      },

    ],
    december: [
      {
        id: HolidayTypes.Hanukkah,
        class: '_hanukkah',
        title: 'Hanukkah',
        buttonText: 'Explore',
        active: {
          start: '2023-12-07T00:00:00.000',
          end: '2023-12-16T00:00:00.000',
        },
        time_left: this.daysLeft(`2023-12-07`, `2023-12-15`),
        date: `2023-12-07`,
      },
      {
        id: HolidayTypes.Christmas,
        class: '_christmas',
        title: 'Christmas',
        buttonText: 'Explore',
        active: {
          end: '2023-12-26T00:00:00.000',
        },
        time_left: this.daysLeft(`2023-12-25`),
        date: `2023-12-25`,
      },
      // {
      //   id: HolidayTypes.NewYear,
      //   class: '_new-year',
      //   title: 'New Year',
      //   buttonText: 'Explore',
      //   active: {
      //     start: '2022-01-03T00:00:00.000',
      //     end: '2022-01-13T00:00:00.000',
      //   },
      //   date: `2023-12-31`,
      //   time_left: this.daysLeft(`2023-12-31`),
      // },
    ],
  }

  daysLeft(date: string, end: string = '') {
    const difference =  moment(date, 'YYYY-MM-DD').utc().diff(this.today, 'days') as number
    const endDifference = end && moment(end, 'YYYY-MM-DD').utc().diff(this.today, 'days') as number
    let useDifference = difference
    if (difference > 30) {
      const monthsLeft = Math.floor(difference / 30).toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
      })
      return `${monthsLeft} Months Left`
    }
    // if festival has end and end date hasn't passed say NOW
    if (end && difference < -1 && endDifference >= 0) {
      return 'NOW'
    } else if (end && difference < -1 && endDifference < 0) {
      useDifference = endDifference
    }
    switch (difference) {
      case 1:
        return `Tomorrow`
      case 0:
        return `Today`
      case -1:
        return `Yesterday`
      default:
        const daysLeft = difference.toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false
        })
        return difference < -1 ? `${-daysLeft} Days Ago` : `${daysLeft} Days Left`
    }
  }
}
