<h1 mat-dialog-title class="_local-main-title">
  <img src="/app_assets/one-logo-rounded.svg" alt="">
  <ng-container [ngSwitch]="data.type">
    <ng-container *ngSwitchCase="modalType.invoiceReminder">
      <ng-container *ngIf="passedDeadline">Payment Required</ng-container>
      <ng-container *ngIf="!passedDeadline">Invoice Reminder</ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="modalType.requestPriceReminder">Request Custom Price</ng-container>
    <ng-container *ngSwitchCase="modalType.askForUpdate">Custom Pricing Status</ng-container>
  </ng-container>
  <button
    *ngIf="data.type === modalType.askForUpdate"
    class="mat-dialog-close" (click)="dialogRef.close(null)"
  >
    <span>&times;</span>
  </button>
</h1>
<div class="PU-Body mb-2 mt-1">
  <ng-container *ngIf="data.type === modalType.invoiceReminder">
    <ng-container *ngIf="passedDeadline">
      <p class="PU-Text" *ngIf="data?.accountLocked; else campaignsPaused">
        <b>Account locked</b> due to overdue invoice. Immediate payment required.
      </p>
      <ng-template #campaignsPaused>
        <p class="PU-Text">
          Campaigns paused due to overdue invoice. Immediate payment required.
        </p>
      </ng-template>
    </ng-container>

    <p class="PU-Text" *ngIf="!passedDeadline">
      Friendly reminder that your invoice, is due for payment by {{dueDate}}.
      <br>
      <br>
      Please ensure that the payment for the amount of {{price | currency : 'USD' : 'symbol' : '1.2-2'}} is made by this date to avoid any late fees or service disruptions.
    </p>
  </ng-container>

  <ng-container *ngIf="data.type === modalType.requestPriceReminder">
    <p class="PU-Text">
      Friendly reminder to request your pricing plan by {{dueDate}}.
      If you have any questions or concerns, please do not hesitate to <a href="#" (click)="contactSupport($event)">contact us</a>.
    </p>

  </ng-container>

  <ng-container *ngIf="data.type === modalType.askForUpdate">

    <p class="PU-Text">
      <ng-container *ngIf="requestCreatedHoursAgo; else noDate">
        Thanks for requesting a custom price
        <span
          class="Tooltip-Dots"
          [matTooltip]="customPlanRequestCreateDate"
          matTooltipClass="Tooltip-Box"
          matTooltipPosition="above">{{ requestCreatedHoursAgo }} hours ago</span>.
      </ng-container>
      <ng-template #noDate>Thanks for requesting a custom price.</ng-template>
      We will get back to you within 24 hours!
    </p>

    <ng-container *ngIf="requestCreatedHoursAgo">
      <h4 class="d-flex justify-content-center align-items-center flex-column m-0">
        <span style="font-size: 12px">Expect Reply by</span>
        <span class="mt-1">{{expectReplyBy}}</span>
      </h4>

      <div class="text-center">
        <div class="pf-badge xs green my-2" >
          <ng-container *ngIf="replyTimeLeft > 0; else expiredState">
            {{ replyTimeLeft }} Hours Left
          </ng-container>
          <ng-template #expiredState>
            0 Hours Left
          </ng-template>
        </div>
      </div>
    </ng-container>
  </ng-container>


  <div class="text-center" *ngIf="data.type !== modalType.askForUpdate">
    <h4 class="text-center mt-0 mb-0">
      Due {{dueDate}}
    </h4>
    <div class="pf-badge xs green my-2" *ngIf="!passedDeadline">
      <ng-container *ngIf="daysLeft === 0; else showDays">
        Last Day
      </ng-container>
      <ng-template #showDays>
        {{(daysLeft > 0 ? daysLeft : 0) | pfxPlural:'Day':'Days'}} Left
      </ng-template>
    </div>
    <div class="pf-badge xs red my-2" *ngIf="passedDeadline">
      {{ data?.accountLocked ? 'Account Locked' : 'Campaigns Paused' }}
    </div>
  </div>
</div>

<div class="PU-Footer mb-0">
  <ng-container *ngIf="data.type === modalType.invoiceReminder">
    <button  class="pf-button filled fw black " (click)="payInvoice()">
      Pay Invoice <span class="_due-date">| Due {{ passedDeadline ? 'Now' : dueDateShort}}</span>
    </button>
    <button (click)="contactSupport($event)" class="pf-button fw shopify-green filled my-2">Chat with Us</button>
  </ng-container>
  <ng-container *ngIf="data.type === modalType.requestPriceReminder">
    <button (click)="openCalendlyWidget()" class="pf-button fw black filled">Schedule Pricing Call</button>
    <button (click)="contactSupport($event)" class="pf-button fw shopify-green filled my-2">Request Custom Price</button>
  </ng-container>
  <ng-container *ngIf="data.type === modalType.askForUpdate">
    <button (click)="openCalendlyWidget()" class="pf-button fw black filled">Book a Call</button>
    <button (click)="contactSupport($event)" class="pf-button fw shopify-green filled my-2">Chat with Us</button>
  </ng-container>
  <div *ngIf="!passedDeadline && data.type !== modalType.askForUpdate">
    <a href="#"
       (click)="close($event)">
      {{data.type === modalType.invoiceReminder ? 'No Thanks, I Will Pay Later' : 'No Thanks, I Will Pay Full Price Later'}}
    </a>
  </div>

  <!-- <div class="PU-Questions" *ngIf="!passedDeadline">
    <h6 class="PU-Questions-Title">Questions?</h6>
    <div class="d-flex justify-content-around mb-3 px-4">
      <button class="pf-button outline black sm" (click)="openCalendlyLink()">Book Call</button>
      <button (click)="openFaqLink()" class="pf-button outline black sm">FAQ Page</button>
    </div>
  </div> -->
  <small *ngIf="showContactsFooter"><b><i>xTool Primary Contacts</i></b> <br> Susie (李舒婷), Yoyo Wen & Yuan Hui Xian</small>
</div>

<pf-loading-overlay></pf-loading-overlay>
