import { Injectable } from '@angular/core'
import _ from 'lodash'
import { Observable, of, Subject } from 'rxjs'
import { tap } from 'rxjs/operators'
import { Campaign, CampaignPluginName } from '../../../shared/models/campaign/campaign'
import { UpdatePluginConfigPayload } from '../../../shared/models/campaign/update-plugin-config-payload.model'
import { WidgetFortuneWheelThemeVariableName } from '../../../shared/models/widget/fortune-wheel.model'
import { CustomSnackbarService } from '../../../shared/modules/custom-snackbar/custom-snackbar.service'
import { ApiCampaignService } from '../api/api-campaign.service'
import { CampaignService } from '../campaign.service'
import { PubSub } from '../pub-sub'
import { RoleBasedAccessDeniedError } from '../../interceptors/role-based.interceptor'
import { SmsPhoneNumberVerificationService } from '../sms-phone-number-verification.service'

export interface ICampaignDataService {
  asObservable(): Observable<Campaign>;
}

@Injectable({
  providedIn: 'root'
})
export class CampaignDataService extends PubSub<Campaign> implements ICampaignDataService {
  public saved$: Subject<void> = new Subject()
  public saveSubject$: Subject<void> = new Subject()

  constructor(
    // private errorService: ErrorsService,
    private apiCampaignService: ApiCampaignService,
    private snackbarService: CustomSnackbarService,
    private campaignService: CampaignService,
    private smsPhoneNumberVerificationService: SmsPhoneNumberVerificationService,
  ) {
    super(null)
  }

  getCampaign(id: string): Observable<Campaign> {
    return this.apiCampaignService.getCampaign(id).pipe(
      tap( data => {
        // idk why but we have a different nesting for some plugins config
        // this is a workaround
        Object.values(CampaignPluginName).forEach(type => {
          if (data[type]) {
            data[type].config = _.get(data[type], 'config.v1', data[type].config)
            if (type === CampaignPluginName.CouponBoxNotification || type === CampaignPluginName.NewsLetterNotification) {
              // disable sms double opt-in if number is not verified
              // TODO remove this after BE default config settings are fixed
              if (this.smsPhoneNumberVerificationService.numberUnverified && data[type].config.form_config.phone) {
                data[type].config.form_config.phone.tcpa.enabled = false
              }
              if (_.has(data[type], 'config.texts')) {
                data[type].config.texts.sms_waiting = {
                  description: 'You should get an SMS soon, please follow its instructions to complete the subscription.',
                  action_button: 'GO BACK',
                  ...data[type].config.texts.sms_waiting,
                }
                data[type].config.texts.email_waiting = {
                  description: 'You should get an Email soon, please follow its instructions to complete the subscription.',
                  action_button: 'GO BACK',
                  ...data[type].config.texts.email_waiting,
                }
              }
              // TODO: remove once the migration(PF-4234) is done on prod and waiting configs are coming from the backend instead
              // leaving it as it is for now to be on a safe side
              if (_.has(data[type], 'config.active_theme') || _.has(data[type], 'config.theme')) {
                const theme = data[type].config.theme[data[type].config.active_theme]
                if (theme) {
                  theme.email_waiting = {
                    general: { ..._.get(theme, 'prompt.general', {}), ..._.get(theme, 'email_waiting.general', {}) },
                    description: { ..._.get(theme, 'prompt.body.line_1', {}), ..._.get(theme, 'email_waiting.description', {}) },
                    action_button: { ..._.get(theme, 'prompt.action_button'), ..._.get(theme, 'email_waiting.action_button', {}) }
                  }
                  theme.sms_waiting = {
                    general: { ..._.get(theme, 'prompt.general', {}), ..._.get(theme, 'sms_waiting.general', {}) },
                    description: { ..._.get(theme, 'prompt.body.line_1', {}), ..._.get(theme, 'sms_waiting.description', {}) },
                    action_button: { ..._.get(theme, 'prompt.action_button'), ..._.get(theme, 'sms_waiting.action_button', {}) }
                  }
                }
              }
            } else if (type === CampaignPluginName.FortuneWheel) {
              // disable sms double opt-in if number is not verified
              // TODO remove this after BE default config settings are fixed
              if (this.smsPhoneNumberVerificationService.numberUnverified && data[type].config.form_config.phone) {
                data[type].config.form_config.phone.tcpa.enabled = false
              }
              if (_.has(data[type], 'config.texts')) {
                data[type].config.texts.sms_state = {
                  back: 'back',
                  description: 'You should get an SMS soon, please follow its instructions to complete the subscription',
                  title: 'SMS Verification',
                  ...data[type].config.texts.sms_state,
                }
                data[type].config.texts.email_state = {
                  back: 'back',
                  description: 'You should get an Email soon, please follow its instructions to complete the subscription.',
                  title: 'Email Verification',
                  ...data[type].config.texts.email_state,
                }
              }
              if (_.has(data[type], 'config.theme')) {
                const theme = data[type].config.theme
                if (theme && theme.variables) {
                  theme.variables = {
                    [WidgetFortuneWheelThemeVariableName.email_title_color]:          theme.variables[WidgetFortuneWheelThemeVariableName.result_title_color] || 'rgb(0, 0, 0)',
                    [WidgetFortuneWheelThemeVariableName.email_title_size]:           theme.variables[WidgetFortuneWheelThemeVariableName.result_title_size] || '48px',
                    [WidgetFortuneWheelThemeVariableName.email_title_bg_color]:       theme.variables[WidgetFortuneWheelThemeVariableName.result_title_bg_color] || 'rgba(0,0,0,0)',
                    [WidgetFortuneWheelThemeVariableName.email_title_weight]:         theme.variables[WidgetFortuneWheelThemeVariableName.result_title_weight] || '700',
                    [WidgetFortuneWheelThemeVariableName.email_description_color]:    theme.variables[WidgetFortuneWheelThemeVariableName.description_color] || '#ffffff',
                    [WidgetFortuneWheelThemeVariableName.email_description_size]:     theme.variables[WidgetFortuneWheelThemeVariableName.description_size] || '19px',
                    [WidgetFortuneWheelThemeVariableName.email_description_bg_color]: theme.variables[WidgetFortuneWheelThemeVariableName.description_bg_color] || 'rgba(0,0,0,0)',
                    [WidgetFortuneWheelThemeVariableName.email_description_weight]:   theme.variables[WidgetFortuneWheelThemeVariableName.description_weight] || '700',
                    [WidgetFortuneWheelThemeVariableName.email_back_text_color]:      theme.variables[WidgetFortuneWheelThemeVariableName.description_color] || '#ffffff',
                    [WidgetFortuneWheelThemeVariableName.email_back_text_size]:       theme.variables[WidgetFortuneWheelThemeVariableName.description_size] || '19px',
                    [WidgetFortuneWheelThemeVariableName.email_back_text_bg_color]:   theme.variables[WidgetFortuneWheelThemeVariableName.description_bg_color] || 'rgba(0,0,0,0)',
                    [WidgetFortuneWheelThemeVariableName.email_back_text_weight]:     theme.variables[WidgetFortuneWheelThemeVariableName.description_weight] || '700',

                    [WidgetFortuneWheelThemeVariableName.sms_title_color]:            theme.variables[WidgetFortuneWheelThemeVariableName.result_title_color] || 'rgb(0, 0, 0)',
                    [WidgetFortuneWheelThemeVariableName.sms_title_size]:             theme.variables[WidgetFortuneWheelThemeVariableName.result_title_size] || '48px',
                    [WidgetFortuneWheelThemeVariableName.sms_title_bg_color]:         theme.variables[WidgetFortuneWheelThemeVariableName.result_title_bg_color] || 'rgba(0,0,0,0)',
                    [WidgetFortuneWheelThemeVariableName.sms_title_weight]:           theme.variables[WidgetFortuneWheelThemeVariableName.result_title_weight] || '700',
                    [WidgetFortuneWheelThemeVariableName.sms_description_color]:      theme.variables[WidgetFortuneWheelThemeVariableName.description_color] || '#ffffff',
                    [WidgetFortuneWheelThemeVariableName.sms_description_size]:       theme.variables[WidgetFortuneWheelThemeVariableName.description_size] || '19px',
                    [WidgetFortuneWheelThemeVariableName.sms_description_bg_color]:   theme.variables[WidgetFortuneWheelThemeVariableName.description_bg_color] || 'rgba(0,0,0,0)',
                    [WidgetFortuneWheelThemeVariableName.sms_description_weight]:     theme.variables[WidgetFortuneWheelThemeVariableName.description_weight] || '700',
                    [WidgetFortuneWheelThemeVariableName.sms_back_text_color]:        theme.variables[WidgetFortuneWheelThemeVariableName.description_color] || '#ffffff',
                    [WidgetFortuneWheelThemeVariableName.sms_back_text_size]:         theme.variables[WidgetFortuneWheelThemeVariableName.description_size] || '19px',
                    [WidgetFortuneWheelThemeVariableName.sms_back_text_bg_color]:     theme.variables[WidgetFortuneWheelThemeVariableName.description_bg_color] || 'rgba(0,0,0,0)',
                    [WidgetFortuneWheelThemeVariableName.sms_back_text_weight]:       theme.variables[WidgetFortuneWheelThemeVariableName.description_weight] || '700',

                    ...theme.variables,
                  }
                }
              }
            }
          }
        })
        data.config = _.get(data.config, 'v1', data.config)
        // end of workaround
        this.set(data)
      })
    )
  }

  refreshCampaign() {
    const id = this.get().id
    if (id) {
      this.getCampaign(id).subscribe()
    }
  }

  saveCampaign(payload, isFormValid = true) {
    if (!isFormValid) {
      this.snackbarService.showError({
        text: 'Please check your configuration for errors',
        title: 'Configuration not saved',
        duration: 2000,
      })
      return of(null)
    }
    return this.campaignService.saveCampaign(payload).pipe(
      tap(() => {
        this.snackbarService.showSuccess({title: 'Saved', text: 'Campaign Configuration'})
        this.refreshCampaign()
        this.saved$.next()
      }, err => {
        if (!(err instanceof RoleBasedAccessDeniedError)) {
          this.snackbarService.showError({text: 'Something went wrong'})
        }
      })
    )
  }

  savePopupParams(payload: UpdatePluginConfigPayload, isFormValid = true) {
    if (!isFormValid) {
      this.snackbarService.showError({
        text: 'Please check your configuration for errors',
        title: 'Configuration not saved',
        duration: 2000,
      })
      return of(null)
    }
    return this.campaignService.updatePluginConfig(
      payload.campaignId,
      payload.type,
      payload.config,
      payload.active,
      payload.capture_configs,
      payload.capture_source,
      payload.data_sources,
      payload.capture_type,
      payload.sms_keyword,
      payload.products,
      payload.sync_with_shopify,
      payload.product_filter,
      payload.unlayer_email_template,
      payload.slices,
      payload.reward,
      payload.coupon_required
    ).pipe(
      tap(() => {
        this.snackbarService.showSuccess({title: 'Saved', text: 'Campaign Configuration'})
        this.refreshCampaign()
        this.saved$.next()
      }, err => {
        if (!(err instanceof RoleBasedAccessDeniedError)) {
          this.snackbarService.showError({text: 'Something went wrong'})
        }
      })
    )
    // pipe(mergeMap(res => [new UpdatePluginConfigSuccess(res), new ReadCampaignRequest(payload.campaignId)]),
    //   catchError((err: any): any => this.errorService.isTemplateValidationError(err)
    //     ? of(new SetTemplateError(this.errorService.extractTemplateValidationError(err)))
    //     : of(new UpdatePluginConfigFailure(this.errorService.extractError(err))),
    //   )
    // )
  }
}
