export enum GettingStartedStepName {
  // Install
  SelectPlatform = 'select_platform',
  Install = 'install',
  Configure = 'configure',
  Link = 'link',
  Dashboard = 'dashboard',
  Tracking = 'tracking',
  Snippet = 'snippet',
  // Detect
  DetectPixel = 'detect_pixel',
  // Profile
  CompleteProfile = 'complete_profile',
  // Support
  ContactSupport = 'contact_support',
  // Campaign
  CampaignName = 'campaign_name',
  LiveVisitorCount = 'live_visitor_count',
  RecentActivity = 'recent_activity',
  DisplayRules = 'display_rules',
  CaptureRules = 'capture_rules',
  Language = 'language',
  CampaignConfirm = 'campaign_confirm',
}
