  <div class="AppsCards-Card" *ngIf="!card.hidden" [class._locked]="card.locked" (click)="onCardClick(card)">
    <div class="AppsCards-Card-Image" [style.background]="'url(' + card.img + ')'">
    </div>
    <div *ngIf="!card.locked && !viewOnlyCard" class="AppsCards-Card-TimeTag">
      <h5 [class._letters]="card.setup_mins === 1">{{ card.setup_mins === 1 ? 'one' : card.setup_mins }}</h5>
      <span>{{ card.setup_mins === 1 ? 'min' : 'mins'}}</span>
      <span>setup</span>
    </div>
    <div class="AppsCards-Card-Body">
      <!-- <div class="AppsCards-Card-Header">
        <div *ngFor="let badge of card.badges" class="pf-badge success mr-1">
          {{ badge }}
        </div>
      </div> -->
      <div>
        <h5 class="text-center">{{card.title}}</h5>
        <!-- <p class="mb-2">{{card.description}}</p> -->
      </div>
      <!-- <div class="AppsCards-Card-Footer">
        <div *ngFor="let badge of card.badges" class="pf-badge success mr-1">
          {{ badge }}
        </div>
      </div> -->
      <div class="AppsCards-Card-Buttons">
        <ng-container *ngIf="card?.hasTutorial && card?.videoTutorial">
          <button class="one-button sm purple outline mr-2"
                  (click)="navigateToTutorials(card, $event)">
            <one-icon name="videocam-outline"></one-icon>
            Tutorial
          </button>
        </ng-container>
        <div>
          <button class="one-button blue filled sm">{{ cardButtonText }}</button>
        </div>
      </div>
    </div>
    <ng-container *ngIf="!viewOnlyCard">
      <div>
        <button *ngIf="card.locked" class="AppsCards-Card-Button pf-button _contact-button"
                (click)="onContactUsClick(card)">
          Contact us to unlock
        </button>
      </div>
      <div *ngIf="card.locked" class="AppsCards-Card-Lock">
        <one-icon name="lock-closed-outline"></one-icon>
      </div>
    </ng-container>

  </div>
