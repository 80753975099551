<div class="FilterSelect" [ngStyle]="{ 'width': width ? width : null }">
  <div class="FilterSelect-Dropdown _local-Dropdown" #filterRef>
    <select [(ngModel)]="value" class="form-control" (change)="filterChanged($event)">
      <ng-container *ngFor="let item of groupArr">

        <optgroup *ngIf="item.group" [attr.label]="item.group">
          <ng-container *ngFor="let option of item.groupItem">
            <option [value]="option.value">{{option.title}}</option>
          </ng-container>
        </optgroup>

        <ng-container *ngIf="!item.group">
          <ng-container *ngFor="let option of item.groupItem">
            <option [value]="option.value">{{option.title}}</option>
          </ng-container>
        </ng-container>

      </ng-container>
    </select>
  </div>
</div>
