import { Injectable } from '@angular/core'
import { AngularFireAuth } from '@angular/fire/compat/auth'
import { Observable } from 'rxjs'
import { from, of } from 'rxjs'
import { Logger } from './logger.service'
import * as Sentry from '@sentry/browser'
import { AdminUserTokenService } from './admin-user-token.service'
import { switchMap, take } from 'rxjs/operators'
import firebase from 'firebase/compat/app'
import { LogLabel } from '../../shared/models/logger/log-label.model'
import { SafeLocalStorageService } from './safe-local-storage.service'

export enum AuthServiceProvider {
  Google = 'google.com',
  Password = 'password',
}

declare var $crisp: any

@Injectable()
export class AuthService {
  public user$: Observable<firebase.User>
  public userDetails: any = null
  public customShopifyToken = null

  constructor(
    private _firebaseAuth: AngularFireAuth,
    private  logger: Logger,
    private safeLocalStorageService: SafeLocalStorageService,
    private adminUserTokenService: AdminUserTokenService,
  ) {
    this.user$ = _firebaseAuth.authState
    this.user$.subscribe(user => {
      if (user) {
        this.userDetails = user
        this.logger.log(LogLabel.AuthService, 'User Details:', JSON.parse(JSON.stringify(this.userDetails)))
        const isAdminAsUser = this.adminUserTokenService.getValue()
        if (!isAdminAsUser) {
          try {
            Sentry.configureScope((scope) => {
              scope.setUser({ email: this.userDetails.email, id: this.userDetails.uid })
            })
            if (typeof $crisp !== 'undefined') {
              if (Boolean(this.userDetails.email)) {
                $crisp.push(['set', 'user:email', [this.userDetails.email]])
              }
            }
          } catch (e) {
            this.logger.log(LogLabel.AuthService, e)
          }
        }

      } else {
        this.userDetails = null
      }
    })
  }

  getCurrentUser() {
    return this._firebaseAuth.currentUser
  }

  signOut(): Promise<void> {
    return this._firebaseAuth.signOut()
  }

  register(email, password): Promise<firebase.auth.UserCredential> {
    return this._firebaseAuth.createUserWithEmailAndPassword(email, password)
  }

  requestPass(email): Promise<void> {
    return this._firebaseAuth.sendPasswordResetEmail(email)
  }

  signInWithCustomToken(token): Promise<firebase.auth.UserCredential> {
    return this._firebaseAuth.signInWithCustomToken(token)
  }

  signInWithEmail(email, password): Promise<firebase.auth.UserCredential> {
    return this._firebaseAuth.signInWithEmailAndPassword(email, password)
  }

  signInWithEmailLink(email): Promise<void> {
    return this._firebaseAuth.sendSignInLinkToEmail(email, {
      url: `${window.location.origin}/login?email_login=true&email=${email}`,
      // This must be true.
      handleCodeInApp: true,
    })
  }

  confirmSignInWithEmailLink(): Promise<firebase.auth.UserCredential> {
    if (this._firebaseAuth.isSignInWithEmailLink(window.location.href)) {
      let email = this.getSignInEmail();
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt('Please provide your email for confirmation');
      }
      // The client SDK will parse the code from the link for you.
      return this._firebaseAuth.signInWithEmailLink(email, window.location.href)
    } else {
      return Promise.resolve(null)
    }
  }

  signInWithGoogle(): Promise<firebase.auth.UserCredential> {
    return this._firebaseAuth.signInWithPopup(new firebase.auth.GoogleAuthProvider())
  }

  getUserIdToken(): Observable<string | null> {
    return this._firebaseAuth.authState.pipe(
      take(1),
      switchMap((user) => {
        if (user) {
          return from(user.getIdToken())
        }
        return of(null)
      }),
    )
  }

  async refreshUserIDToken(): Promise<string> {
    const user = await this.getCurrentUser()
    if (user) {
      return user.getIdToken(true)
    }
    return null
  }

  linkGoogle() {
    return this.userDetails
      .linkWithPopup(new firebase.auth.GoogleAuthProvider())
      .then(data => {
        this.userDetails = data.user
        return data
      })
  }

  unlink(providerId: AuthServiceProvider) {
    return this.userDetails.unlink(providerId).then(data => {
      this.userDetails = data
      return data
    })
  }

  updateEmail(email: string) {
    return this.userDetails.updateEmail(email).then(data => {
      this.userDetails = data
      return data
    })
  }

  updatePassword(password: string) {
    return this.userDetails.updatePassword(password).then(data => {
      this.userDetails = data
      return data
    })
  }

  setSignInEmail(email: string)  {
    this.safeLocalStorageService.setItem('pf-signin-email', email)
  }

  getSignInEmail()  {
    return this.safeLocalStorageService.getItem('pf-signin-email')
  }

  clearSignInEmail()  {
    return this.safeLocalStorageService.removeItem('pf-signin-email')
  }
}
