import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { UploadTaskSnapshot } from '@angular/fire/compat/storage/interfaces';
import { ReactiveFormsModule, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import { FileStorageService } from '../../../../core/services/file-storage.service';
import { ImageUploadService } from '../../../modules/image-upload/services/image-upload.service';
import moment from 'moment';
import { FileSizeService } from '../../../../core/services/file-size.service';
import { SafeLocalStorageService } from '../../../../core/services/safe-local-storage.service';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { OneIconComponent } from '../../one-icon/one-icon.component';
import { LoadingOverlayComponent } from '../../loading-overlay/loading-overlay.component';
import { MatSelectModule } from '@angular/material/select';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";

export interface ThemeRequestdModalData {
  themeType: 'popup' | 'email'
  userId: string
  userEmail: string
  userName: string
  userCompany: string
  userShop: string
  userCreatedTime: string
}

const sessionKey = 'pf-theme-request-images';
@Component({
    templateUrl: './theme-request.modal.component.html',
    styleUrls: ['./theme-request.modal.component.scss'],
    standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    OneIconComponent,
    LoadingOverlayComponent,
    MatDialogModule
  ]
})
export class ThemeRequestModalComponent implements OnInit, OnDestroy {
  @ViewChild('uploader', { static: false }) fileInput: ElementRef
  private subscription = new Subscription()
  requestForm: UntypedFormGroup
  isImageFailed = false
  isImageInvalid = false
  isImageOverLimit = false
  isImageOverCount = false
  nameMaxLength = 120
  descMaxLength = 280
  descTypeLength = 0

  constructor(
    private fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<ThemeRequestModalComponent>,
    private imageUploadService: ImageUploadService,
    private fileStorageService: FileStorageService,
    private safeLocalStorageService: SafeLocalStorageService,
    @Inject(MAT_DIALOG_DATA) public data: ThemeRequestdModalData) {
  }

  ngOnInit(): void {
    this.requestForm = this.fb.group({
      type: [this.data.themeType, Validators.required],
      name: ['', [Validators.required, Validators.maxLength(this.nameMaxLength)]],
      description: ['', [Validators.required, Validators.maxLength(this.descMaxLength)]],
      imageUrl: this.fb.array(this.getImages())
    })

    this.subscription.add(
      this.requestForm.get('description').valueChanges.subscribe(word => {
        this.descTypeLength = word.length
      })
    )

    this.subscription.add(
      this.imageUploadService.imageSelected$.subscribe(res => {
        const store = this.safeLocalStorageService.getItem(sessionKey) || null
        if(!store) {
          const arr = []
          arr.push({ name: res.name, url: res.url, size: res.size, date: res.lastModifiedDate })
          this.safeLocalStorageService.setItem(sessionKey, JSON.stringify(arr))
        } else {
          try {
            const objArr = JSON.parse(store)
            objArr.push({ name: res.name, url: res.url, size: res.size, date: res.lastModifiedDate })
            this.safeLocalStorageService.setItem(sessionKey, JSON.stringify(objArr))
          } catch (e) {
            this.safeLocalStorageService.removeItem(sessionKey)
          }
        }
        (this.requestForm.get('imageUrl') as UntypedFormArray).push(
          this.fb.group({ name: res.name, url: res.url, size: res.size, date: res.lastModifiedDate }))
      })
    )
  }

  getImages() {
    const store = this.safeLocalStorageService.getItem(sessionKey) || null
    if(store) {
      try {
        const objArr = JSON.parse(store)
        return objArr
      } catch (e) {
        this.safeLocalStorageService.removeItem(sessionKey)
      }
    }
    return []
  }

  sendRequest() {
    this.requestForm.updateValueAndValidity()
    Object.keys(this.requestForm.controls).forEach(key => {
      this.requestForm.get(key).markAsTouched()
      this.requestForm.get(key).markAsDirty()
    })
    if (!this.requestForm.valid) {
      return
    }
    this.safeLocalStorageService.removeItem(sessionKey)
    this.dialogRef.close({ request: this.requestForm.value })
  }

  onFileUpload(event) {
    const files = event.target.files
    const imageLength = this.requestForm.get('imageUrl').value?.length
    if (imageLength + files.length > 5) {
      this.isImageOverCount = true
    } else if (files.length > 0) {
      this.isImageOverCount = false
      files.forEach((_ele, idx) => {
        this.validateFileType(files[idx])
      });
    }
  }

  onFileDropped(event) {
    const imageLength = this.requestForm.get('imageUrl').value?.length
    if (imageLength + event.length > 5) {
      this.isImageOverCount = true
    } else if(event.length > 0) {
      this.isImageOverCount = false
      event.forEach((_ele, idx) => {
        this.validateFileType(event[idx])
      });
    }
  }

  onFileRemove(index: number) {
    (this.requestForm.get('imageUrl') as UntypedFormArray).removeAt(index)
    const store = this.safeLocalStorageService.getItem(sessionKey) || null
    if (store && store.length > 0) {
      try {
        const images = JSON.parse(store)
        images.splice(index, 1)
        this.safeLocalStorageService.setItem(sessionKey, JSON.stringify(images))
      } catch (e) {
        this.safeLocalStorageService.removeItem(sessionKey)
      }
    }
    this.isImageOverCount = false
    this.fileInput.nativeElement.value = ''
  }

  onImageError() {
    this.isImageFailed = true
  }

  onImageLoad() {
    this.isImageFailed = false
  }

  private validateFileType(file) {
    const allowedFormats = ['jpg', 'jpeg', 'png', 'gif']
    const fileName = file.name
    const idxDot = fileName.lastIndexOf('.') + 1
    const extFile = fileName.substr(idxDot, fileName.length).toLowerCase()

    this.isImageInvalid = false
    this.isImageOverLimit = false
    if (!allowedFormats.includes(extFile)) {
      this.isImageInvalid = true
    } else if (file.size > 1024 * 1024 * 5) {
      this.isImageOverLimit = true
    } else {
      this.uploadFile(file)
    }
  }

  uploadFile(file) {
    const fileSize = FileSizeService.formatFileSize(file.size)
    const fileDate = moment(file.lastModifiedDate).format('YYYY MMM D')
    const newFileName = FileStorageService.generateFileName(file)
    if (this.data.userId) {
      const filePath = `${this.data.userId}/images/${newFileName}`
      this.subscription.add(
        this.fileStorageService.upload(filePath, file, (data: UploadTaskSnapshot) => {
          const path = `https://${data.ref.bucket}/${data.ref.fullPath}`
          this.imageUploadService.imageSelected$.next({ name: file.name, url:path, size: fileSize, lastModifiedDate: fileDate })
        }).subscribe()
      )
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }
}
