import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ProductsSelectorComponent } from './products-selector.component'
import { ReactiveFormsModule, FormsModule } from '@angular/forms'
import { ProductsSelectorListComponent } from './components/products-selector-list/products-selector-list.component'
import { ProductsListService } from './services/products-list.service'
import { ProductsOutputComponent } from './components/products-output/products-output.component'
import { LoadingOverlayComponent } from '../../components/loading-overlay/loading-overlay.component'
import { MatPaginatorModule } from '@angular/material/paginator'
import { NoTableDataComponent } from '../../components/no-table-data/no-table-data.component'

@NgModule({
  declarations: [
    ProductsSelectorComponent,
    ProductsSelectorListComponent,
    ProductsOutputComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LoadingOverlayComponent,
    MatPaginatorModule,
    NoTableDataComponent
  ],
  exports: [
    ProductsSelectorComponent,
    ProductsOutputComponent,
    ProductsSelectorListComponent,
  ],
  providers: [
    ProductsListService
  ],
})

export class ProductsSelectorModule {
}
