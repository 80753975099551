import { Injectable } from '@angular/core';
import { PubSub } from './pub-sub'

// PubSub used to watch for Save plugin initiation from outside of PluginConfigureComponent
@Injectable()
export class ExternalSaveService extends PubSub<any> {
  constructor() {
    super(null)
  }
}
