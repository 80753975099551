const fi = {
  Quantity: 'Määrä',
  Size: 'Koko',
  Color: 'Väri',
  Material: 'Materiaali',
  Style: 'Tyyli',
  Savings: 'Säästä',
  Subtotal: 'Välisumma',
  Shipping: 'Toimituskulut',
  Taxes: 'Verot',
  Total: 'Yhteensä',
  Free: 'Ilmainen',
  Buy: 'Osta',
  'Pay Now': 'Maksa nyt',
  'Take the Deal': 'Hyödynnä tarjous',
  'Add Now': 'Lisää nyt',
  'You will be charged immediately': 'Veloitus tapahtuu heti',
  'Decline Offer': 'Kieltäydy tarjouksesta',
  'No Thanks': 'Ei kiitos',
  '{price}': '{Hinta}',
  'Sold Out': 'Loppuunmyyty',
  'see more': 'näytä lisää',
  less: 'vähemmän',
};
export {fi};
