import { Injectable } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { LoadingOverlayService } from './loading-overlay.service'
import { filter, map, tap } from 'rxjs/operators'
import { combineLatest, Observable, of } from 'rxjs'
import { Logger } from './logger.service'
import { StoreState } from '../../store/store.state'
import { GettingStartedCreateEventParams } from '../../shared/models/getting-started/getting-started-create-event-params'
import { UserShopType } from '../../shared/models/user/user-shop-type.model'
import { getUserShopType } from '../../store/user/user.selectors'
import * as _ from 'lodash'
import { GettingStartedStatus, initialGettingStartedStatus, ScholarshipApplicationStatusEnum } from '../../shared/models/getting-started/getting-started-status.model'
import { GettingStartedEvent } from '../../shared/models/getting-started/getting-started-event'
import { ApiService } from './api/api.service'
import { ApiGettingStartedService } from './api/api-getting-started.service'
import { GettingStartedGroupName } from '../../shared/models/getting-started/getting-started-group-name.model'
import { LogLabel } from '../../shared/models/logger/log-label.model'
import { GettingStartedStepName } from '../../shared/models/getting-started/getting-started-step-name.model'
import { GettinStartedCampaignStepStatus } from '../../shared/models/getting-started/getting-started-campaign-step-status'
import { GettingStartedCampaignStepDependencies } from '../../shared/models/getting-started/getting-started-campaign-steps'
import { GettingStartedCampaignStep } from '../../shared/models/getting-started/getting-started-campaign-step'
import { GettingStartedInstallPlatform } from '../../shared/models/getting-started/getting-started-install-platform.model'
import { GettingStartedInteractions } from '../../shared/models/getting-started/getting-started-interactions.model'
import { UpdateInteractionsResponse } from '../../shared/models/getting-started/update-interactions-response.model'
import { Campaign } from '../../shared/models/campaign/campaign'
import { GettingStartedEventState } from '../../shared/models/getting-started/getting-started-event-state.model'
import { GettingStartedCampaignSchemas } from '../../shared/models/getting-started/getting-started-campaign-schemas'
import { SetGettingStartedCompleted, SetGettingStartedFirstCampaignLaunched, SetGettingStartedFirstCampaignId, SetGettingStartedFirstCampaignSkipped, SetGettingStartedStatus, SetGettingStartedCampaign, SetGettingStartedEvents } from '../../store/getting-started/getting-started.actions'
import { getGettingStartedStatus, getGettingStartedEvents } from '../../store/getting-started/getting-started.selectors'
import { ActiveAppCampaigns } from '../../shared/models/app-campaign-type.model'

@Injectable()
export class GettingStartedService {
  status: GettingStartedStatus
  events: GettingStartedEvent[]
  shopType: UserShopType

  constructor(
    private store: Store<StoreState>,
    private loadingOverlay: LoadingOverlayService,
    private apiService: ApiService,
    private apiGettingStartedService: ApiGettingStartedService,
    private logger: Logger,
  ) {

    this.store.pipe(select(getGettingStartedStatus))
      .subscribe(status => this.status = {...initialGettingStartedStatus, ...status} as GettingStartedStatus)

    this.store.pipe(
      select(getGettingStartedEvents),
    ).subscribe(events => this.events = events)

    this.store.pipe(
      select(getUserShopType),
      filter((next) => next !== undefined),
    ).subscribe(shopType => {
      this.shopType = shopType
    })
  }

  isCompletedByStatus(status: GettingStartedStatus) {
    if (status.completed) {
      return true
    }
    const { install, detect, profile, campaign } = status
    if (!(install && detect && profile && campaign)) {
      return false
    }
    return install.completed && detect.completed && profile.completed && campaign.completed
  }

  completeStatus(completed: boolean = true): Observable<any> {
    return this.updateStatus({ ...this.status, completed })
      .pipe(
        tap(() => {
          this.store.dispatch(new SetGettingStartedCompleted(completed))
        }),
      )
  }

  completeFirstCampaign(firstCampaignLaunched: boolean = true, campaign_id: string): Observable<any> {
    return this.updateStatus({ ...this.status, firstCampaignLaunched, firstCampaignId: campaign_id })
      .pipe(
        tap(() => {
          this.store.dispatch(new SetGettingStartedFirstCampaignLaunched(firstCampaignLaunched))
          this.store.dispatch(new SetGettingStartedFirstCampaignId(campaign_id))
        }),
      )
  }

  completeStatusAndFirstCampaign(campaign_id: string) {
    return this.updateStatus({ ...this.status, completed: true, firstCampaignLaunched: true, firstCampaignId: campaign_id })
      .pipe(
        tap(() => {
          this.store.dispatch(new SetGettingStartedCompleted(true))
          this.store.dispatch(new SetGettingStartedFirstCampaignLaunched(true))
          this.store.dispatch(new SetGettingStartedFirstCampaignId(campaign_id))
        }),
      )
  }

  completeStatusAndSkipFirstCampaign() {
    return this.updateStatus({ ...this.status, completed: true, firstCampaignSkipped: true })
      .pipe(
        tap(() => {
          this.store.dispatch(new SetGettingStartedCompleted(true))
          this.store.dispatch(new SetGettingStartedFirstCampaignSkipped(true))
        }),
      )
  }

  completeStatusGroup(group: GettingStartedGroupName, completed: boolean = true): void {
    if (this.status[group].completed !== completed) {
      const status = {
        ...this.status, [group]: {
          ...this.status[group],
          completed: completed,
        },
      }
      this.updateStatus(status).subscribe()
      this.status = status
    }
  }

  setStatusGroupVisited(group: GettingStartedGroupName): void {
    if (!this.status[group].visited) {
      const status = {
        ...this.status, [group]: {
          ...this.status[group],
          visited: true,
        },
      }
      this.logger.log(LogLabel.GettingStartedService, `Set status.${group}.visited =`, true)
      this.updateStatus(status).subscribe()
      this.status = status
    }
  }

  completeStatusGroups(groups: { [key in GettingStartedGroupName]?: boolean }): void {
    let changed = false
    let status = this.status
    for (const prop in groups) {
      if (groups.hasOwnProperty(prop)) {
        const value = groups[prop]
        if (this.status[prop].completed !== value) {
          changed = true
          status = {
            ...status,
            [prop]: {
              ...this.status[prop],
              completed: value,
            },
          }
        }
      }
    }
    if (changed) {
      this.updateStatus(status).subscribe()
      this.status = status
    }
  }

  completeCampaignStep(step: GettingStartedStepName, params: Object = {}): Observable<any> {
    const steps = this.findAndChangeStepStatus(this.status.campaign.steps, step, GettinStartedCampaignStepStatus.COMPLETED, params)
    return this.updateStatus({
      ...this.status,
      campaign: {
        ...this.status.campaign,
        steps: [...steps],
      },
    })
  }

  skipCampaignStep(stepName: GettingStartedStepName): Observable<any> {
    let steps = this.findAndChangeStepStatus(this.status.campaign.steps, stepName, GettinStartedCampaignStepStatus.SKIPPED)
    const dependencies = (GettingStartedCampaignStepDependencies[stepName] || [])
    dependencies.forEach(dependency => {
      steps = this.findAndChangeStepStatus(steps, dependency, GettinStartedCampaignStepStatus.MISSED)
    })
    return this.updateStatus({
      ...this.status, campaign: {
        ...this.status.campaign,
        steps: [...steps],
      },
    })
  }

  incompleteCampaignStep(stepName: GettingStartedStepName): Observable<GettingStartedStatus> {
    let steps = this.findAndChangeStepStatus(this.status.campaign.steps, stepName, null)
    const dependencies = (GettingStartedCampaignStepDependencies[stepName] || [])
    dependencies.forEach(dependency => {
      steps = this.findAndChangeStepStatus(steps, dependency, null)
    })
    return this.updateStatus({
      ...this.status, campaign: {
        ...this.status.campaign, steps: [...steps],
      },
    })
  }

  findAndChangeStepStatus(steps: GettingStartedCampaignStep[], stepName: GettingStartedStepName, status: GettinStartedCampaignStepStatus, params: Object = {}): GettingStartedCampaignStep[] {
    return steps.map(step => step.name === stepName ? {
      ...step,
      status: status,
      params: params,
    } : step)
  }

  setStatusPlatform(platform: GettingStartedInstallPlatform): void {
    if (this.status.install.platform !== platform) {
      this.updateStatus({
        ...this.status,
        install: {
          ...this.status.install,
          platform: platform,
        },
      }).subscribe()
    }
  }

  setStatusSteps(steps: GettingStartedCampaignStep[]) {
    this.updateStatus({
      ...this.status,
      campaign: {
        ...this.status.campaign,
        steps,
      },
    }).subscribe()
  }

  updateStatus(status: GettingStartedStatus): Observable<GettingStartedStatus> {
    return this.apiGettingStartedService.postInteractionsStatus(status)
      .pipe(
        tap(next => {
          this.status = next
          this.store.dispatch(new SetGettingStartedStatus(next))
        }))
  }

  updateScholarshipStatusFromQueryParams(queryParams) {
    const payload: any = {
      popup_seen: false,
      application: {
        status: ScholarshipApplicationStatusEnum.Pending,
        status_last_update_date: new Date().toISOString(),
        submission_id: _.get(queryParams, 'jotform_submission_id', null) || _.get(this.status, 'scholarship.application.submission_id', null),
        submission_ip: _.get(queryParams, 'jotform_submission_ip', null) || _.get(this.status, 'scholarship.application.submission_ip', null),
        submission_date: new Date().toISOString(),
        requested_plan_id: _.get(queryParams, 'requested_plan_id', null) || _.get(this.status, 'scholarship.application.requested_plan_id', null),
        requested_plan_name: _.get(queryParams, 'requested_plan_name', null) || _.get(this.status, 'scholarship.application.requested_plan_name', null),
        submitter: {
          name: _.get(queryParams, 'submitter_name', null) || _.get(this.status, 'scholarship.application.submitter.name', null),
          email: _.get(queryParams, 'submitter_email', null) || _.get(this.status, 'scholarship.application.submitter.email', null),
          phone: _.get(queryParams, 'submitter_phone', null) || _.get(this.status, 'scholarship.application.submitter.phone', null),
          store_role: _.get(queryParams, 'submitter_store_role', null) || _.get(this.status, 'scholarship.application.submitter.store_role', null),
        }
      }
    }
    if (_.has(queryParams, 'submitter_address')) {
      const keyMap = {
        'Street Address': 'address_line1',
        'Street Address Line 2': 'address_line2',
        'City': 'address_city',
        'State / Province': 'address_state',
        'Country': 'address_country',
        'Postal / Zip Code': 'address_postal_code',
      }
      const addressArr = _.get(queryParams, 'submitter_address', null).split('<br>')
      addressArr.forEach(pair => {
        const [key, value] = pair.split(': ')
        if (keyMap[key]) {
          payload.application.submitter[keyMap[key]] = value
        }
      })
    }

    return this.updateScholarshipStatus(payload)
  }

  updateScholarshipStatus(scholarship) {
    return this.updateStatus({...this.status, scholarship: {...this.status.scholarship, ...scholarship}})
  }

  updateFeatureVoteStatus(payload) {
    return this.updateStatus({...this.status, feature_votes: {...this.status.feature_votes, ...payload}})
  }

  updateKeyValuePair(key: string, value: any) {
    return this.updateStatus({...this.status, key_value_pair: {...this.status.key_value_pair, [key]: value}})
  }

  updateKeyValuePairs(pairs: {key: string, value: any}) {
    return this.updateStatus({...this.status, key_value_pair: {...this.status.key_value_pair, ...pairs}})
  }

  getKeyValuePair() {
    return this.status?.key_value_pair || {}
  }

  getKeyValuePairValue(key: string) {
    return this.status?.key_value_pair?.[key]
  }

  getActiveCampaignCounts() {
    return {
      [ActiveAppCampaigns.Popup]: this.status?.key_value_pair?.[ActiveAppCampaigns.Popup] || 0,
      [ActiveAppCampaigns.PostPurchaseUpsell]: this.status?.key_value_pair?.[ActiveAppCampaigns.PostPurchaseUpsell] || 0,
      [ActiveAppCampaigns.EmailAutomation]: this.status?.key_value_pair?.[ActiveAppCampaigns.EmailAutomation] || 0,
      [ActiveAppCampaigns.EmailBroadcast]: this.status?.key_value_pair?.[ActiveAppCampaigns.EmailBroadcast] || 0,
      [ActiveAppCampaigns.SmsAutomation]: this.status?.key_value_pair?.[ActiveAppCampaigns.SmsAutomation] || 0,
      [ActiveAppCampaigns.SmsBroadcast]: this.status?.key_value_pair?.[ActiveAppCampaigns.SmsBroadcast] || 0,
    }
  }

  getScholarshipStatus() {
    return this.status && this.status.scholarship ? this.status.scholarship : {}
  }

  getFeatureVoteStatus() {
    return this.status?.feature_votes || {}
  }

  getNavigateUrlByStatus(status: GettingStartedStatus = this.status): string {
    const initialState = !status.install.platform
    const platformState = !status.install.completed && status.install.platform
    const detectState = !status.detect.completed
    const profileState = !status.profile.completed
    const launchState = !status.campaign.completed
    // const supportState = !_.get(status, 'support.completed', false)

    if (this.shopType) {
      // Remove support stage for now, leaving as we might bring it back later
      // if (supportState) {
      //   return '/getting-started/support'
      // } else {
      //   return '/getting-started/campaign'
      // }
      return '/getting-started/campaign'
    } else {
      if (initialState) {
        return '/getting-started/install'
      } else if (platformState) {
        return '/getting-started/' + status.install.platform
      } else if (detectState) {
        return '/getting-started/detect'
      } else if (profileState) {
        return '/getting-started/profile'
      } else if (launchState) {
        return '/getting-started/campaign'
      }
    }

    return null
  }

  updateInteractions(interactions: GettingStartedInteractions = {
    onboarding: {
      events: [],
      status: this.status,
    },
  }): Observable<UpdateInteractionsResponse> {
    return this.loadingOverlay.wrap(this.apiService.put('/v1/me', { interactions: interactions }), 'updateInteractions')
  }

  generateInteractionsBody(): GettingStartedInteractions {
    return {
      onboarding: {
        events: [],
        questionnaire: {},
        status: initialGettingStartedStatus,
      },
    }
  }


  linkCampaign(campaign: Campaign): Observable<boolean> {
    if (campaign.id === this.status.campaign.campaign_id) {
      this.store.dispatch(new SetGettingStartedCampaign(campaign))
      return of(true)
    }
    return this.updateStatus({
      ...this.status,
      campaign: {
        ...this.status.campaign,
        campaign_id: campaign.id,
      },
    }).pipe(map(next => !!next))
  }

  addEvent(params: GettingStartedCreateEventParams) {
    const event = this.createGettingStartedEvent(params)
    this.apiGettingStartedService.postInteractionsEvent(event).subscribe((next) => {
      const events = next
      this.store.dispatch(new SetGettingStartedEvents(events))
      this.logger.logAsync(LogLabel.GettingStartedService, () => [`Add event:`, this.eventToString(event), event.metadata || ''])
      this.logger.logAsync(LogLabel.GettingStartedService, () => [`Events (${events.length}):`,
        [...events].slice(-10).reverse().map(ev => {
          const res = {}
          res['event'] = this.eventToString(ev)
          if (ev.metadata) {
            res['metadata'] = ev.metadata
          }
          res['created_at'] = ev.created_at
          return res
        })])
    })
  }


  createGettingStartedEvent(params: GettingStartedCreateEventParams): GettingStartedEvent {
    const createdAt = new Date().toISOString()
    return {
      action: {
        type: params.type,
        name: params.name,
      },
      state: params.state,
      created_at: createdAt,
      metadata: params.metadata,
    }
  }

  private eventToString(event: GettingStartedEvent): string {
    return event && event.state && `${event.state.group}${event.state.flow ? '.' + event.state.flow : ''}.${event.state.step} | ${event.action.type}.${event.action.name}`
  }

  enqueuePluginStatusCheck(domain: string) {
    return this.apiService.post('/v1/check_plugin_status', { site_url: domain })
  }

  setDomain(domain: string): Observable<any[]> {
    const preparedDomain = this.trimURL(domain)
    const observables = []
    observables.push(this.enqueuePluginStatusCheck(preparedDomain))
    observables.push(this.updateStatus({ ...this.status, detect: { ...this.status.detect, domain: preparedDomain } }))
    return combineLatest(observables)
  }

  trimURL(domain: string) {
    return domain ? domain
        .replace('https://', '')
        .replace('http://', '')
        .replace('www.', '')
        .replace(/\/$/, '')
        .replace(/\?.*/, '')
      : ''
  }

  getEventState(status: GettingStartedStatus): GettingStartedEventState {
    if (!status) {
      return undefined
    }
    const group = this.getEventStateGroup(status)
    const step = this.getEventStateStep(status, group)
    const flow = this.getEventStateFlow(status, group)
    return { group, step, flow }
  }

  getEventStateGroup(status: GettingStartedStatus): GettingStartedGroupName {
    const groupNames = Object.values(GettingStartedGroupName)
    return groupNames.find(groupName => {
      return status && status[groupName] && status[groupName].completed === false
    })
  }

  getEventStateStep(status: GettingStartedStatus, group: GettingStartedGroupName): GettingStartedStepName {
    switch (group) {
      case GettingStartedGroupName.Detect:
        return GettingStartedStepName.DetectPixel
      case GettingStartedGroupName.Profile:
        return GettingStartedStepName.CompleteProfile
      case GettingStartedGroupName.Install:
        return
      case GettingStartedGroupName.Campaign:
        const step = this.findCurrentStep(status.campaign.steps)
        return step && step.name
    }
  }

  getEventStateFlow(status: GettingStartedStatus, group: GettingStartedGroupName): GettingStartedInstallPlatform {
    return group === GettingStartedGroupName.Install
      ? status.install.platform
      : undefined
  }

  findCurrentStep(steps: GettingStartedCampaignStep[]): GettingStartedCampaignStep {
    return steps.find(step => ![
      GettinStartedCampaignStepStatus.MISSED,
      GettinStartedCampaignStepStatus.COMPLETED,
      GettinStartedCampaignStepStatus.SKIPPED,
    ].includes(step.status)) || {} as GettingStartedCampaignStep
  }

  // DO NOT REMOVE
  updateStatusInstallStepsBySchema(status: GettingStartedStatus, schema: GettingStartedStepName[]): GettingStartedStatus {
    const steps = schema.map(name => {
      const newStep = status.install.steps.find(step => step.name === name) || { name: name, status: null }
      return { ...newStep }
    })
    return {
      ...status,
      install: {
        ...status.install,
        steps,
      },
    }
  }

  changeCurrentGroup(group: GettingStartedGroupName) {
    let completed: { install: boolean, detect: boolean, profile: boolean, campaign: boolean, support: boolean }
    switch (group) {
      case GettingStartedGroupName.Campaign:
        completed = { install: true, detect: true, profile: true, campaign: false, support: true }
        break
      case GettingStartedGroupName.Profile:
        completed = { install: true, detect: true, profile: false, campaign: false, support: false }
        break
      case GettingStartedGroupName.Detect:
        completed = { install: true, detect: false, profile: false, campaign: false, support: false }
        break
      case GettingStartedGroupName.Install:
        completed = { install: false, detect: false, profile: false, campaign: false, support: false }
        break
      case GettingStartedGroupName.Support:
        completed = { install: false, detect: false, profile: false, campaign: false, support: false }
        break
    }

    this.updateStatus({
      ...this.status,
      install: {
        ...this.status.install,
        completed: completed.install,
      },
      detect: {
        ...this.status.detect,
        completed: completed.detect,
      },
      profile: {
        ...this.status.profile,
        completed: completed.profile,
      },
      campaign: {
        ...this.status.campaign,
        completed: completed.campaign,
      },
    }).subscribe()
  }

  selectCampaignStep(nextStep: GettingStartedStepName): Observable<GettingStartedStatus> {
    const current: GettingStartedStepName = this.findCurrentStep(this.status.campaign.steps).name
    const currentIndex = GettingStartedCampaignSchemas.default.indexOf(current)
    const nextStepIndex = GettingStartedCampaignSchemas.default.indexOf(nextStep)
    if (currentIndex === nextStepIndex) {
      return of(null)
    }
    const stepNamesBetween: GettingStartedStepName[] = GettingStartedCampaignSchemas.default.slice(
      Math.min(currentIndex, nextStepIndex),
      Math.max(currentIndex, nextStepIndex),
    )
    const newStatus = (currentIndex < nextStepIndex) ? GettinStartedCampaignStepStatus.MISSED : null
    const newSteps: GettingStartedCampaignStep[] = this.status.campaign.steps.map(
      step => stepNamesBetween.includes(step.name) ? { ...step, status: newStatus } : step,
    )
    this.logger.logAsync(LogLabel.GettingStartedService, () => [
      `Select campaign step (current: ${current}, next: ${nextStep}). Steps:`,
      newSteps.map(step => `${step.name}: ${step.status}`),
    ])
    return this.updateStatus({
      ...this.status, campaign: {
        ...this.status.campaign, steps: [...newSteps],
      },
    })
  }
}
