import { CampaignNotificationType } from './campaign'

export interface MarketingEmail {
  id: string,
  notification_id: string,
  campaign_id?: string,
  type: MarketingEmailType,
  notification_type?: CampaignNotificationType,
  active: boolean,
  reply_to: string,
  subject: string,
  preview_text: string,
  scheduling_method: MarketingEmailSchedulingMethod,
  marketing_email_type?: MarketingEmailType,
  scheduling_date: string, // if method is fixed
  scheduling_value: number, // if method is after_sginup x days
  template_id: number,
  template_html: any,
  template_json: any,
  from_name: string,
  from_address: string,
  sent: number,
  opened: number,
  clicked: number,
  revenue: number,
  domain_id: string, // custom sending verified domain id
  address_id: string, // custom sending email id
  created_at: string,
  updated_at: string,
  can_destroy: boolean,
  pluginType?: string,
  template_data?: any,
}

export enum MarketingEmailType {
  AutoResponderMarketingEmail = 'Campaign::PopUpNotification::AutoResponderMarketingEmail',
  ScheduledMarketingEmail = 'Campaign::PopUpNotification::ScheduledMarketingEmail',
  SubscriptionConfirmationEmail = 'Campaign::PopUpNotification::SubscriptionConfirmationEmail',
}

export enum MarketingEmailSchedulingMethod {
  Fixed = 'fixed',
  AfterSignup = 'after_signup',
}
