import { Injectable } from '@angular/core'
import { UntypedFormArray, UntypedFormGroup, ValidationErrors } from '@angular/forms'
import { mapValues } from 'lodash'

@Injectable()
export class FormsHelperService {
  touchAll(formGroup: UntypedFormGroup | UntypedFormArray, func = 'markAsDirty', opts = {onlySelf: false}) {
    formGroup.markAllAsTouched()
    mapValues(formGroup.controls, (control, i) => {
      if (control instanceof UntypedFormGroup || control instanceof UntypedFormArray) {
        this.touchAll(control, func, opts)
      } else {
        control[func](opts)
      }
    })
  }

  updateValueAndValidityAll(formGroup: UntypedFormGroup | UntypedFormArray, func = 'updateValueAndValidity') {
    mapValues(formGroup.controls, (control, i) => {
      if (control instanceof UntypedFormGroup || control instanceof UntypedFormArray) {
        this.updateValueAndValidityAll(control, func)
      } else {
        control[func]()
      }
    })
  }

  inputNumberMaxlength(event: any, maxLength: number) {
    if (event.target.value) {
      if (event.target.value.length === maxLength) {
        const selection = window.getSelection().toString()
        if (selection !== event.target.value) {
          event.preventDefault()
          return false
        }
      }
    }
  }

  getInvalidControls(form: UntypedFormGroup): {[name: string]: ValidationErrors | null}[] {
    // TODO: Implement nested form groups
    const invalid = []
    const controls = form.controls
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push({[name]: controls[name].errors})
      }
    }
    return invalid
  }
}
