<div class="side">

  <div class="side-header">
    <div class="position-relative d-flex">
      <div class="one-header-menu-btn" (click)="toggleMobileMenu()">
        <one-icon name="menu"></one-icon>
      </div>
      <one-main-logo></one-main-logo>
      <div class="side-pin"
        (click)="togglePin()"
        [class._active]="isPinned$ | async">
      </div>
    </div>
  </div>


  <!-- Nav -->
  <div class="side-nav">
    <!-- Shop switch -->
    <one-shop-switch
      *ngIf="isShopifyShop"
    ></one-shop-switch>

    <!-- Filter -->
    <div class="side-nav-item _is-filter">
      <a class="side-nav-item-parent position-relative">
        <one-icon class="side-filter-search" name="search-outline"></one-icon>
        <input type="text"
          class="form-control"
          placeholder="Filter menu"
          [(ngModel)]="filterInput"
          (ngModelChange)="onFilterChange()">
        <one-icon *ngIf="filterInput?.length"
          class="side-filter-clear"
          name="close-sharp"
          (click)="clearFilter()"
        ></one-icon>
      </a>
    </div>

    <!-- Navigation -->
    <ng-container *ngFor="let item of SIDE_NAVIGATION">
      <div
        *ngIf="item.visible"
        [class.side-nav-item]="!item?.separator"
        [class.side-nav-separator]="item?.separator"
        routerLinkActive="_active"
      >


        <!-- Solo item -->
        <ng-container *ngIf="!item?.children?.length">
          <!-- separator -->
          <ng-container *ngIf="item?.separator; else navItem">
            <span>{{item.name}}</span>
          </ng-container>

          <ng-template #navItem>
            <a class="side-nav-item-parent" [routerLink]="item.url" routerLinkActive="_active">
              <one-icon [src]="item?.iconSrc" [name]="item?.icon"></one-icon> <span>{{item.name}}</span>
            </a>
          </ng-template>
        </ng-container>

        <!-- Item with dropdown -->
        <ng-container *ngIf="item?.children?.length">
          <a class="side-nav-item-parent" [class._expanded]="item._expanded" (click)="item._expanded = !item._expanded">
            <one-icon [src]="item?.iconSrc" [name]="item?.icon"></one-icon> <span>{{item.name}}</span>
            <one-icon class="side-nav-item-arrow" name="chevron-forward-outline"></one-icon>
          </a>
          <ng-container *ngFor="let child of item.children">
            <!-- Child with link -->
            <ng-container *ngIf="!child?.method">
              <a class="side-nav-item-child"
                [class._has-icon]="child?.icon"
                *ngIf="child.visible"
                [routerLink]="child?.url"
                [routerLinkActiveOptions]="child?.routerLinkActiveOptions || {exact: false}"
                [class._active]="child?.forceActive"
                routerLinkActive="_active"
              >
                <one-icon *ngIf="child?.icon" [src]="item?.iconSrc" [name]="child?.icon"></one-icon>
                {{child.name}}
              </a>
            </ng-container>
            <!-- Child with method -->
            <ng-container *ngIf="child?.method">
              <a class="side-nav-item-child"
                [class._has-icon]="child?.icon"
                *ngIf="child.visible"
                (click)="child?.method($event)"
                routerLinkActive="_active"
              >
                <one-icon *ngIf="child?.icon" [src]="item?.iconSrc" [name]="child?.icon"></one-icon>
                {{child.name}}
              </a>
            </ng-container>

          </ng-container>
        </ng-container>

      </div>
    </ng-container>

  </div>

  <!-- Footer -->
  <div class="side-footer">
    <div class="side-nav-item">
      <a class="side-nav-item-parent" routerLinkActive="_active" [routerLink]="routeHeaderUrl.settings">
        <one-icon name="settings-outline"></one-icon> <span>Settings</span>
      </a>
    </div>
  </div>
</div>

<div class="side-backdrop" (click)="toggleMobileMenu()"></div>
