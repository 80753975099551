import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs'
import { Campaign } from '../../../shared/models/campaign/campaign'

export interface IAllCampaignService {
  getAllCampaigns(): Observable<Campaign[]>;
}

@Injectable({
  providedIn: 'root'
})
export class AllCampaignService implements IAllCampaignService {
  campaignsSubject = new BehaviorSubject<Campaign[]>([])

  constructor() { }

  setAllCampaigns(campaigns: Campaign[]) {
    this.campaignsSubject.next(campaigns)
  }

  getAllCampaigns(): Observable<Campaign[]> {
    return this.campaignsSubject.asObservable()
  }
}
