import {animate, style, transition, trigger} from '@angular/animations'

export const loadingLayout = trigger('loadingLayout', [
  transition(':enter', [
    style({opacity: 0}),
    animate(150, style({opacity: 1})),
  ]),
  transition(':leave', [
    style({opacity: 1}),
    animate(150, style({opacity: 0})),
  ]),
])
