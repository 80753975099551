import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

export enum Breakpoint {
  _320 = '_320',
  _660 = '_660',
  _768 = '_768',
  _992 = '_992',
  _1200 = '_1200',
  _1440 = '_1440',
  _1600 = '_1600',
  _1920 = '_1920',
}

export type BreakpointStateModel = {
  [key in Breakpoint]: {
    value: string,
    active: boolean,
  }
}

@Injectable({providedIn: 'root'})
export class BreakpointService {

  readonly breakpoints = {
    [Breakpoint._320]: {
      value: '320px',
      active: false,
    },
    [Breakpoint._660]: {
      value: '660px',
      active: false,
    },
    [Breakpoint._768]: {
      value: '768px',
      active: false,
    },
    [Breakpoint._992]: {
      value: '992px',
      active: false,
    },
    [Breakpoint._1200]: {
      value: '1200px',
      active: false,
    },
    [Breakpoint._1440]: {
      value: '1440px',
      active: false,
    },
    [Breakpoint._1600]: {
      value: '1600px',
      active: false,
    },
    [Breakpoint._1920]: {
      value: '1920px',
      active: false,
    },
  }

  public bp$ = new BehaviorSubject<BreakpointStateModel>(JSON.parse(JSON.stringify(this.breakpoints)))

  constructor() { }

}
