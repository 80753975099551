export enum DateRangeLabel {
  AllTime = 'All Time',
  Today = 'Today',
  Yesterday = 'Yesterday',
  Last7Days = 'Last 7 days',
  Last14Days = 'Last 14 days',
  Last30Days = 'Last 30 days',
  Last60Days = 'Last 60 days',
  Last90Days = 'Last 90 days',
  Last365Days = 'Last 365 days',
  LastWeek = 'Last week',
  LastMonth = 'Last month',
  ThisWeek = 'This week',
  ThisMonth = 'This month',
}

export interface MatDatepickerRange {
  title: string
  active: boolean
  isAllTime?: boolean
  range: moment.Moment[]
  rangeWithToday?: moment.Moment[]
  rangeDefault?: moment.Moment[]
}

export interface MatDatepickerOutput {
  label: string
  start: moment.Moment
  end: moment.Moment
  start_date: string
  end_date: string
  includes_today: boolean
  isAllTime?: boolean
}
