import { Injectable } from '@angular/core';
import { GiftCardSummary } from '../models/gift-card'

@Injectable()
export class GiftCardsService {

  constructor() { }

  updateSummary(value, randomString: string, currency: string = ''): GiftCardSummary {
    return {
      code: `${value.prefix_code}${randomString}`,
      randomString: randomString,
      currency: currency,
      discount: value.value
    }
  }
}
