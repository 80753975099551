export interface Step {
  label: StepLabels,
  url?: string,
  passed?: boolean,
  passedLine?: boolean,
  active?: boolean,
  disabled?: boolean,
  invalid?: boolean,
  invalid_message?: string,
}


export enum StepLabels {
  Theme = 'Theme',
  Design = 'Design',
  Offer = 'Offer',
  Triggers = 'Triggers',
  Settings = 'Settings',
  Template = 'Template',
}
