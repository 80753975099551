import * as timeago from 'timeago.js'

export class ProofFactorLanguageService {
  readonly availableTimeAgoLocales = ['ar', 'be', 'bg', 'bn_IN', 'ca', 'da', 'el', 'en_short', 'es', 'eu', 'fa', 'fi', 'fr', 'gl', 'he', 'hi_IN', 'hu', 'it', 'ja', 'ka', 'ko', 'ml', 'my', 'nb_NO', 'nl', 'nn_NO', 'pl', 'pt_BR', 'ro', 'ru', 'sq', 'sr', 'sv', 'ta', 'th', 'tr', 'uk', 'vi', 'zh_CN', 'zh_TW']
  // Add New Language
  readonly availableProofFactorLocales = ['de', 'es', 'pt_BR', 'fr', 'ar', 'ru', 'uk', 'it', 'vi', 'en', 'da', 'pl', 'ja', 'ro']

  private registeredTimeAgoLocales = []
  private registeredProofFactorLocales: { [key: string]: any } = {}

  constructor() {
    this.registerLocale('en')
  }

  getTimeAgoValue(value, locale?) {
    return locale ? timeago.format(value, locale) : timeago.format(value)
  }

  getProofFactorValue(value, locale = 'en') {
    return this.registeredProofFactorLocales[locale]
      ? this.registeredProofFactorLocales[locale][value]
      : this.registeredProofFactorLocales['en']
        ? this.registeredProofFactorLocales['en'][value]
        : null
  }

  registerLocale(locale: string): Promise<any> {
    const timeAgoLocale = this.availableTimeAgoLocales.includes(locale) ? locale : 'en'
    const proofFactorLocale = this.availableProofFactorLocales.includes(locale) ? locale : 'en'

    return this.registerTimeAgoLocale(timeAgoLocale)
      .then(() => this.registerProofFactorLocale(proofFactorLocale))
  }

  registerTimeAgoLocale(locale: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.registeredTimeAgoLocales.includes(locale)) {
        console.warn('[registerTimeAgoLocale]', `"${locale}" is already loaded`)
        resolve()
      }
      this.loadTimeAgoLocale(locale)
        .then(module => {
          const localeFunction = module.default
          if (localeFunction) {
            this.registeredTimeAgoLocales.push(locale)
            timeago.register(locale, localeFunction)
          }
          resolve()
        })
        .catch(() => resolve())
    })
  }

  registerProofFactorLocale(locale: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.registeredProofFactorLocales[locale]) {
        console.warn('[registerProofFactorLocale]', `"${locale}" is already loaded`)
        resolve()
      }
      this.loadProofFactorLocale(locale)
        .then(module => {
          const texts = module.default
          if (texts) {
            this.registeredProofFactorLocales[locale] = texts
          }
          resolve()
        })
        .catch(() => resolve())
    })
  }

  private loadTimeAgoLocale(locale: string): Promise<void> | any {
    switch (locale) {
      case 'de':
        return import(/* webpackChunkName: "proof-factor-language-service-time-de" */ './locales/time-ago/de')

      case 'ar':
        return import(/* webpackChunkName: "proof-factor-language-service-time-ar" */ 'timeago.js/lib/lang/ar')

      case 'be':
        return import(/* webpackChunkName: "proof-factor-language-service-time-be" */ 'timeago.js/lib/lang/be')

      case 'bg':
        return import(/* webpackChunkName: "proof-factor-language-service-time-bg" */ 'timeago.js/lib/lang/bg')

      case 'bn_IN':
        return import(/* webpackChunkName: "proof-factor-language-service-time-bn_IN" */ 'timeago.js/lib/lang/bn_IN')

      case 'ca':
        return import(/* webpackChunkName: "proof-factor-language-service-time-ca" */ 'timeago.js/lib/lang/ca')

      case 'da':
        return import(/* webpackChunkName: "proof-factor-language-service-time-da" */ 'timeago.js/lib/lang/da')

      case 'el':
        return import(/* webpackChunkName: "proof-factor-language-service-time-el" */ 'timeago.js/lib/lang/el')

      case 'en_short':
        return import(/* webpackChunkName: "proof-factor-language-service-time-en_short" */ 'timeago.js/lib/lang/en_short')

      case 'es':
        return import(/* webpackChunkName: "proof-factor-language-service-time-es" */ 'timeago.js/lib/lang/es')

      case 'eu':
        return import(/* webpackChunkName: "proof-factor-language-service-time-eu" */ 'timeago.js/lib/lang/eu')

      case 'fa':
        return import(/* webpackChunkName: "proof-factor-language-service-time-fa" */ 'timeago.js/lib/lang/fa')

      case 'fi':
        return import(/* webpackChunkName: "proof-factor-language-service-time-fi" */ 'timeago.js/lib/lang/fi')

      case 'fr':
        return import(/* webpackChunkName: "proof-factor-language-service-time-fr" */ 'timeago.js/lib/lang/fr')

      case 'gl':
        return import(/* webpackChunkName: "proof-factor-language-service-time-gl" */ 'timeago.js/lib/lang/gl')

      case 'he':
        return import(/* webpackChunkName: "proof-factor-language-service-time-he" */ 'timeago.js/lib/lang/he')

      case 'hi_IN':
        return import(/* webpackChunkName: "proof-factor-language-service-time-hi_IN" */ 'timeago.js/lib/lang/hi_IN')

      case 'hu':
        return import(/* webpackChunkName: "proof-factor-language-service-time-hu" */ 'timeago.js/lib/lang/hu')

      case 'it':
        return import(/* webpackChunkName: "proof-factor-language-service-time-it" */ 'timeago.js/lib/lang/it')

      case 'ja':
        return import(/* webpackChunkName: "proof-factor-language-service-time-ja" */ 'timeago.js/lib/lang/ja')

      case 'ka':
        return import(/* webpackChunkName: "proof-factor-language-service-time-ka" */ 'timeago.js/lib/lang/ka')

      case 'ko':
        return import(/* webpackChunkName: "proof-factor-language-service-time-ko" */ 'timeago.js/lib/lang/ko')

      case 'ml':
        return import(/* webpackChunkName: "proof-factor-language-service-time-ml" */ 'timeago.js/lib/lang/ml')

      case 'my':
        return import(/* webpackChunkName: "proof-factor-language-service-time-my" */ 'timeago.js/lib/lang/my')

      case 'nb_NO':
        return import(/* webpackChunkName: "proof-factor-language-service-time-nb_NO" */ 'timeago.js/lib/lang/nb_NO')

      case 'nl':
        return import(/* webpackChunkName: "proof-factor-language-service-time-nl" */ 'timeago.js/lib/lang/nl')

      case 'nn_NO':
        return import(/* webpackChunkName: "proof-factor-language-service-time-nn_NO" */ 'timeago.js/lib/lang/nn_NO')

      case 'pl':
        return import(/* webpackChunkName: "proof-factor-language-service-time-pl" */ 'timeago.js/lib/lang/pl')

      case 'pt_BR':
        return import(/* webpackChunkName: "proof-factor-language-service-time-pt_BR" */ 'timeago.js/lib/lang/pt_BR')

      case 'ro':
        return import(/* webpackChunkName: "proof-factor-language-service-time-ro" */ 'timeago.js/lib/lang/ro')

      case 'ru':
        return import(/* webpackChunkName: "proof-factor-language-service-time-ru" */ 'timeago.js/lib/lang/ru')

      case 'sq':
        return import(/* webpackChunkName: "proof-factor-language-service-time-sq" */ 'timeago.js/lib/lang/sq')

      case 'sr':
        return import(/* webpackChunkName: "proof-factor-language-service-time-sr" */ 'timeago.js/lib/lang/sr')

      case 'sv':
        return import(/* webpackChunkName: "proof-factor-language-service-time-sv" */ 'timeago.js/lib/lang/sv')

      case 'ta':
        return import(/* webpackChunkName: "proof-factor-language-service-time-ta" */ 'timeago.js/lib/lang/ta')

      case 'th':
        return import(/* webpackChunkName: "proof-factor-language-service-time-th" */ 'timeago.js/lib/lang/th')

      case 'tr':
        return import(/* webpackChunkName: "proof-factor-language-service-time-tr" */ 'timeago.js/lib/lang/tr')

      case 'uk':
        return import(/* webpackChunkName: "proof-factor-language-service-time-uk" */ 'timeago.js/lib/lang/uk')

      case 'vi':
        return import(/* webpackChunkName: "proof-factor-language-service-time-vi" */ 'timeago.js/lib/lang/vi')

      case 'zh_CN':
        return import(/* webpackChunkName: "proof-factor-language-service-time-zh_CN" */ 'timeago.js/lib/lang/zh_CN')

      case 'zh_TW':
        return import(/* webpackChunkName: "proof-factor-language-service-time-zh_TW" */ 'timeago.js/lib/lang/zh_TW')

      default:
        return new Promise((resolve, reject) => {
          resolve(null)
        })
    }
  }
  // Add New Language
  private loadProofFactorLocale(locale: string): Promise<any> {
    switch (locale) {
      case 'pl':
        return import(/* webpackChunkName: "proof-factor-language-service-pl" */ './locales/proof-factor/pl')
      case 'da':
        return import(/* webpackChunkName: "proof-factor-language-service-da" */ './locales/proof-factor/da')
      case 'de':
        return import(/* webpackChunkName: "proof-factor-language-service-de" */ './locales/proof-factor/de')
      case 'es':
        return import(/* webpackChunkName: "proof-factor-language-service-es" */ './locales/proof-factor/es')
      case 'pt_BR':
        return import(/* webpackChunkName: "proof-factor-language-service-pt-br" */ './locales/proof-factor/pt_BR')
      case 'fr':
        return import(/* webpackChunkName: "proof-factor-language-service-fr" */ './locales/proof-factor/fr')
      case 'ar':
        return import(/* webpackChunkName: "proof-factor-language-service-ar" */ './locales/proof-factor/ar')
      case 'ru':
        return import(/* webpackChunkName: "proof-factor-language-service-ru" */ './locales/proof-factor/ru')
      case 'uk':
        return import(/* webpackChunkName: "proof-factor-language-service-uk" */ './locales/proof-factor/uk')
      case 'it':
        return import(/* webpackChunkName: "proof-factor-language-service-it" */ './locales/proof-factor/it')
      case 'vi':
        return import(/* webpackChunkName: "proof-factor-language-service-vi" */ './locales/proof-factor/vi')
      case 'ja':
        return import(/* webpackChunkName: "proof-factor-language-service-ja" */ './locales/proof-factor/ja')
      case 'ro':
        return import(/* webpackChunkName: "proof-factor-language-service-ja" */ './locales/proof-factor/ro')
      case 'en':
      default:
        return import(/* webpackChunkName: "proof-factor-language-service-en" */ './locales/proof-factor/en')
    }
  }
}

// const proofFactorLanguageService = new ProofFactorLanguageService()
//
// export default proofFactorLanguageService
