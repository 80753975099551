const da = {
  Quantity: 'Antal',
  Size: 'Størrelse',
  Color: 'Farve',
  Material: 'Materiale',
  Style: 'Stil',
  Savings: 'Besparelser',
  Subtotal: 'Subtotal',
  Shipping: 'Forsendelse',
  Taxes: 'Skatter',
  Total: 'I alt',
  Free: 'Ledig',
  Buy: 'Køb',
  'Pay Now': 'Betal nu',
  'Take the Deal': 'Tag aftalen',
  'Add Now': 'Tilføj nu',
  'You will be charged immediately': 'Du vil blive debiteret med det samme',
  'Decline Offer': 'Afvis tilbud',
  'No Thanks': 'Nej tak',
  '{price}': '{price}',
	'Sold Out': 'Udsolgt',
  'see more': 'se mere',
  less: 'mindre',
};
export {da};
