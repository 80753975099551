
<pf-featured-apps
  *ngIf="showFeaturedApps"
  [featuredApps]="featuredApps"
  (onShowModal)="showHolidayPromotionModal($event)"
></pf-featured-apps>

<div *ngIf="showHolidayCards" class="holiday-cards">
  <!--
  <h1 class="mb-4">Recently Passed Holidays</h1>
  <ng-container [ngTemplateOutlet]="holidayCards" [ngTemplateOutletContext]="{cards: passedHolidayCards}"></ng-container>
  -->
  <!-- add _local-header class back if Recently Passed Holidays enabled -->
  <ng-container *ngIf="show2023HolidayCards">
    <div class="d-flex justify-content-between align-items-end mb-4">
      <div>
        <h1 class="mb-2">2023 Upcoming Holidays</h1>
        <h2 class="fz22 mb-0">
          Quickly create a pop up or email for your holiday promotions!
        </h2>
      </div>
      <button class="pf-button blue filled" (click)="requestDesign()">
        Request Theme
      </button>
    </div>
    <ng-container [ngTemplateOutlet]="holidayCards"
                  [ngTemplateOutletContext]="{cards: upcoming2023HolidayCards}"></ng-container>
  </ng-container>



  <div class="d-flex justify-content-between align-items-end mb-4">
    <div>
      <h1 class="mb-2">2024 Upcoming Holidays</h1>
      <h2 *ngIf="!show2023HolidayCards" class="fz22 mb-0">
        Quickly create a pop up or email for your holiday promotions!
      </h2>
    </div>
    <button *ngIf="!show2023HolidayCards" class="pf-button blue filled" (click)="requestDesign()">
      Request Theme
    </button>
  </div>
  <ng-container [ngTemplateOutlet]="holidayCards"
                [ngTemplateOutletContext]="{cards: upcoming2024HolidayCards}"></ng-container>
</div>

<ng-template #holidayCards let-cards="cards">
  <div class="holiday-cards-content mb-4">
    <div class="holiday-cards-container">
      <div [id]="month" *ngFor="let month of visibleMonths">
        <pf-holiday-cards-month
          *ngIf="cards[month].length"
          [cards]="cards[month]"
          [month]="month"
          (onShowModal)="showHolidayPromotionModal($event)"
        >
        </pf-holiday-cards-month>
      </div>
    </div>
  </div>
</ng-template>


<pf-loading-overlay [filter]="{only: ['CreateCampaignWithPlugin']}"></pf-loading-overlay>


