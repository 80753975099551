import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Observable } from 'rxjs'
import { first, map, tap } from 'rxjs/operators'

import { AuthService } from '../services/auth.service'
import { Logger } from '../services/logger.service'
import { SegmentAnalyticsService } from '../../shared/services/segment-analytics.service'
import { LogLabel } from '../../shared/models/logger/log-label.model'
import { SafeLocalStorageService } from '../services/safe-local-storage.service'

@Injectable()
export class AuthGuard  {
  constructor(
    private router: Router,
    private authService: AuthService,
    private logger: Logger,
    private safeLocalStorageService: SafeLocalStorageService,
    private segmentAnalyticsService: SegmentAnalyticsService,
  ) {
  }

  debounceTimeout

  check(): Observable<boolean> {
    if (this.debounceTimeout) clearTimeout(this.debounceTimeout)
    this.debounceTimeout = setTimeout(
      () => {
        this.segmentAnalyticsService.page()
      },
      500,
    )
    return this.authService.user$.pipe(
      first(),
      map(authState => !!authState),
      tap(authenticated => {
        this.logger.log(<LogLabel>'auth-guard', 'authenticated: ', authenticated)
        if (!authenticated) {
          /* Store URL so we can re-direct */
          this.safeLocalStorageService.setItem('proof_before_login_url', location.href)
          if (window.location.search.includes('redirectForStandalone')) {
            this.router.navigate(['/login'], { queryParams: { redirectForStandalone: true}})
          } else {
            this.router.navigate(['/login'])
          }
        }
      }),
    )
  }

  canActivate(): Observable<boolean> {
    return this.check()
  }

  canActivateChild(): Observable<boolean> {
    return this.check()
  }
}
