import { Injectable } from '@angular/core'

import { ApiRewardsService } from '../../../../../core/services/api/api-rewards.service'
import { RewardsType } from '../../../../coupon-codes/models/rewards-model'
import { GiftCardStatus } from '../../../../gift-cards/models/gift-card'

@Injectable()
export class CouponBoxGiftCardsResolverService  {
  constructor(
    private apiRewardsService: ApiRewardsService
  ) { }

  resolve() {
    return this.apiRewardsService.getRewards({ page: 1, limit: 25, status: GiftCardStatus.active, reward_type: RewardsType.gift_card })
  }
}
