<h2 mat-dialog-title>
  {{showResult ? 'Import is ongoing' : 'Import Existing Coupons'}}
  <button class="mat-dialog-close" (click)="onCloseClick()">
    <span>&times;</span>
  </button>
</h2>

<div mat-dialog-content class="mb-3">

  <div class="CouponsPagination mb-3" *ngIf="!showResult">
    <div class="_local-search">
      <one-icon name="search"></one-icon>
      <input type="text"
        placeholder="Search by coupon title"
        [(ngModel)]="searchValue"
        (keyup)="onSearchKeyUp()"
        class="form-control">
    </div>
    <div>
      <button
      class="pf-button outline gray xs mr-2"
      [disabled]="!this.pagination || !this.pagination.before || loading"
      [class._secondary]="!this.pagination || !this.pagination.before"
      [class._primary]="this.pagination && this.pagination.before"
      (click)="prevPageClick()"
      >PREVIOUS PAGE</button>
    <button
      class="pf-button outline gray xs"
      [disabled]="!this.pagination || !this.pagination.after || loading"
      [class._secondary]="!this.pagination || !this.pagination.after"
      [class._primary]="this.pagination && this.pagination.after"
      (click)="nextPageClick()"
      >NEXT PAGE</button>
    </div>
  </div>

  <div class="CouponsList" #CouponsListRef>
    <ng-container *ngIf="!showResult">
      <div class="CouponsList-Head">
        <label class="checkbox" title="Select All">
          <input type="checkbox" (change)="selectAllChecked($event)">
          <span></span>
        </label>
        <span class="CouponsList-Head-Title">Title</span>
        <span class="CouponsList-Head-Title">Code</span>
      </div>

      <ng-container *ngFor="let coupon of coupons">
        <ng-container
          *ngTemplateOutlet="coupon.imported ? importedCoupon : defaultCoupon; context: {coupon: coupon}">
        </ng-container>
      </ng-container>

      <ng-template #defaultCoupon let-coupon="coupon">
        <label class="CouponsList-Item checkbox">
          <input type="checkbox" [checked]="coupon.selected" (change)="updateSelected(coupon)">
          <span></span>
          <span class="CouponsList-Item-Title">{{coupon.title}}</span>
          <span class="CouponsList-Item-Code">{{coupon.code}}</span>
        </label>
      </ng-template>

      <ng-template #importedCoupon let-coupon="coupon">
        <div class="CouponsList-Item is-imported">
          <one-icon class="CouponsList-Item-Icon" name="cloud-done" title="Imported"></one-icon>
          <span class="CouponsList-Item-Title">{{coupon.title}}</span>
          <span class="CouponsList-Item-Code">{{coupon.code}}</span>
        </div>
      </ng-template>
    </ng-container>

    <ng-container *ngIf="showResult">
      <div class="CouponsNotice">
        <one-icon class="CouponsNotice-Icon" name="cloud-download"></one-icon>
        <h3>{{buttonCounter | lowercase}} is being imported</h3>
        Importing large amount of coupons at one time may take a while, they will appear in the {{ destination }} as soon as the import is done.
        <button class="pf-button filled blue mt-3" (click)="dialogRef.close({imported: true, count: selectedCoupons.size})">GOT IT</button>
      </div>
    </ng-container>

    <pf-simple-loading-overlay [isLoading]="loading"></pf-simple-loading-overlay>
  </div>
</div>

<div mat-dialog-actions class="mb-1 justify-content-end" *ngIf="!showResult">
  <button class="pf-button outline gray"
    [mat-dialog-close]="true">CANCEL</button>
  <button
    class="pf-button filled blue ml-3"
    [disabled]="!selectedCoupons.size"
    (click)="onImportClicked()">IMPORT{{buttonCounter}}</button>
</div>
