export enum GettingStartedActionTypes {
  SET_DOMAIN = 'GettingStarted.SET_DOMAIN',
  SET_CAMPAIGN = 'GettingStarted.SET_CAMPAIGN',
  SET_FIRSTCAMPAIGN_LAUNCHED = 'GettingStarted.SET_FIRSTCAMPAIGN_LAUNCHED',
  SET_FIRSTCAMPAIGN_SKIPPED = 'GettingStarted.SET_FIRSTCAMPAIGN_SKIPPED',
  SET_FIRSTCAMPAIGN_ID = 'GettingStarted.SET_FIRSTCAMPAIGN_ID',
  SET_EVENTS = 'GettingStarted.SET_EVENTS',
  SET_STATUS = 'GettingStarted.SET_STATUS',
  SET_COMPLETED = 'GettingStarted.SET_COMPLETED',
}
