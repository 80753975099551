<ng-container>

  <div class="StatsCard mb-12px" oneConfetti *ngIf="stats?.total_revenue as total_revenue" [totalCount]="total_revenue">
    <div class="StatsCard-Hero">
      ONE Upsell made your store
      <span class="text-primary conveythis-no-translate">{{(total_revenue | currency : userCurrency : 'symbol' : '1.2-2') || 0}}</span>
    </div>
    <pf-loading-overlay
      [filter]="{only: ['UpsellStats']}"
      [isFixed]='false'
      class='loading-overlay'
    ></pf-loading-overlay>
  </div>

  <div class='_local-upsell-stats'>
    <div class='StatsCard'>
      <one-icon class="StatsCard-Tooltip" name="help-circle-outline" [matTooltip]="'Number of times an upsell offer was displayed'"
         matTooltipClass="Tooltip-Box" matTooltipPosition="above"></one-icon>
      <div class='StatsCard-Title'>
        Views
      </div>
      <div class='StatsCard-Value conveythis-no-translate' *ngIf="stats && !stats?.loading">
        {{(stats?.views || 0) | number}}
        <pf-loading-overlay
          [filter]="{only: ['UpsellStats']}"
          [isFixed]='false'
          class='loading-overlay'
        ></pf-loading-overlay>
      </div>
    </div>

    <div class='StatsCard'>
      <one-icon class="StatsCard-Tooltip" name="help-circle-outline" [matTooltip]="'Number of upsell offers that were purchased'"
         matTooltipClass="Tooltip-Box" matTooltipPosition="above"></one-icon>
      <div class='StatsCard-Title'>
        Conversions
      </div>
      <div class='StatsCard-Value conveythis-no-translate' *ngIf="stats && !stats?.loading">
        {{(stats?.conversions || 0) | number}}
        <pf-loading-overlay
          [filter]="{only: ['UpsellStats']}"
          [isFixed]='false'
          class='loading-overlay'
        ></pf-loading-overlay>
      </div>
    </div>

    <div class='StatsCard'>
      <one-icon class="StatsCard-Tooltip" name="help-circle-outline" [matTooltip]="'% of purchased upsells out of number of upsells displayed'"
        matTooltipClass="Tooltip-Box" matTooltipPosition="above"></one-icon>
      <div class='StatsCard-Title'>
        Conversion Rate
      </div>
      <div class='StatsCard-Value conveythis-no-translate' *ngIf="stats && !stats?.loading">
        {{(stats?.conversion_rate | percent) || '0%'}}
        <pf-loading-overlay
          [filter]="{only: ['UpsellStats']}"
          [isFixed]='false'
          class='loading-overlay'
        ></pf-loading-overlay>
      </div>
    </div>

    <div class='StatsCard'>
      <one-icon class="StatsCard-Tooltip" name="help-circle-outline" [matTooltip]="'Average dollar amount spent on an order'"
        matTooltipClass="Tooltip-Box" matTooltipPosition="above"></one-icon>
      <div class='StatsCard-Title'>
        Average Order Value
      </div>
      <div class='StatsCard-Value conveythis-no-translate' *ngIf="stats && !stats?.loading">
        {{(stats?.average_order | currency:userCurrency:'symbol':'1.2-2') || 0}}
        <pf-loading-overlay
        [filter]="{only: ['UpsellStats']}"
        [isFixed]='false'
        class='loading-overlay'
        ></pf-loading-overlay>
      </div>
      <small *ngIf="stats?.average_increase_rate > 0" class="upsell-stats-subtitle">
        <span class="_local-highlight"><span class="conveythis-no-translate">+{{ stats?.average_increase_rate | percent}}</span> higher</span>
        compared to previous period
      </small>
    </div>
  </div>
</ng-container>
