<div class="holiday-cards-month">
  <div class="holiday-cards-month-header">
    <div>
      <h3 class="mb-0">{{month | titlecase}}</h3>
    </div>
    <div *ngIf="cards.length > maxHolidayCardsShown">
      <one-icon
        matTooltipPosition="above"
        matTooltip="Previous page"
        [class._disabled]="holidayCardsCounter === 0"
        (click)="previousHolidayCards()"
        name="chevron-back-outline">
      </one-icon>
      <one-icon
        matTooltipPosition="above"
        matTooltip="Next page"
        [class._disabled]="((holidayCardsCounter + 1) * maxHolidayCardsShown) >
              cards.length"
        (click)="nextHolidayCards()"
        name="chevron-forward-outline">
      </one-icon>
    </div>
  </div>
  <div #cardRef class="cards-container d-flex flex-nowrap w-100">
    <ng-container *ngFor="let card of cardsToShow">
      <div  class="holiday-promotion" [ngClass]="card.class" (click)="card.action ? card.action() : showHolidayPromotionModal(card.id)">
        <div class="days-left" *ngIf="card.time_left">
          <span>{{card.time_left}}</span>
        </div>
        <div class="holiday-promotion-content">
          <div class="holiday-promotion-text">
            <p class="holiday-promotion-text-title">{{ card.text || 'Pre-built Campaigns for' }}</p>
            <span class="holiday-promotion-text-name">{{ card.title }}</span>
            <p *ngIf="card.subtitle" class="holiday-promotion-text-title">{{ card.subtitle }}</p>
          </div>
          <button class="pf-button outline blue sm">{{ card.buttonText ||
          'Try It'}}</button>
        </div>
      </div>
    </ng-container>
  </div>

</div>


<ng-template #holidayCards let-cards="cards" >


</ng-template>
