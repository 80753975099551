import { Injectable, Optional } from '@angular/core'
import { DateRange } from '@angular/material/datepicker'
import _ from 'lodash'
import moment from 'moment'
import { BehaviorSubject } from 'rxjs'
import { SafeLocalStorageService } from '../../../../core/services/safe-local-storage.service'
import { UserService } from '../../../../core/services/user.service'
import { DateRangeLabel, MatDatepickerRange } from '../models/mat-daterange.model'

@Injectable()
export class MatDaterangeService {
  // Set includeToday default value here
  private readonly includeTodayDefault = true
  // Subject to watch the month change in calendar view
  public monthChange$ = new BehaviorSubject<{direction: 'next' | 'prev', origin: 'left' | 'right'}>({direction: null, origin: null})
  // Main date range that is used as an Output value
  public selectedRange$ = new BehaviorSubject<DateRange<moment.Moment> | undefined>(null)
  // Label of current selected range
  public selectedRangeLabel$ = new BehaviorSubject<string>(null)
  // Label of local label (to store value before apply)
  public selectedRangeLabelLocal$ = new BehaviorSubject<string>(null)
  // Flag to show/hide allTime range
  public includeAllTime$ = new BehaviorSubject<boolean>(true)
  // Flag to see if AllTime is currently selected
  public isAllTime$ = new BehaviorSubject<boolean>(false)
  // Local value to be saved in localStorage on daterange apply
  public includeTodayLocal$ = new BehaviorSubject<boolean>(this.includeTodayDefault)

  // Initial Date Ranges
  private readonly _ranges: MatDatepickerRange[] = [
    {
      title: DateRangeLabel.Today,
      active: false,
      range: [],
      rangeDefault: [
        moment().utc(),
        moment().utc()
      ]
    },
    {
      title: DateRangeLabel.Yesterday,
      active: false,
      range: [],
      rangeDefault: [
        moment().utc().subtract(1, 'days'),
        moment().utc().subtract(1, 'days')
      ]
    },
    {
      title: DateRangeLabel.Last7Days,
      active: false,
      range: [],
      rangeDefault: [
        moment().utc().subtract(7, 'days'),
        moment().subtract(1, 'days').utc()
      ],
      rangeWithToday: [
        moment().utc().subtract(6, 'days'),
        moment().utc()
      ]
    },
    {
      title: DateRangeLabel.Last14Days,
      active: false,
      range: [],
      rangeDefault: [
        moment().utc().subtract(14, 'days'),
        moment().subtract(1, 'days').utc()
      ],
      rangeWithToday: [
        moment().utc().subtract(13, 'days'),
        moment().utc()
      ]
    },
    {
      title: DateRangeLabel.Last30Days,
      active: false,
      range: [],
      rangeDefault: [
        moment().utc().subtract(30, 'days'),
        moment().subtract(1, 'days').utc()
      ],
      rangeWithToday: [
        moment().utc().subtract(29, 'days'),
        moment().utc()
      ]
    },
    {
      title: DateRangeLabel.Last60Days,
      active: false,
      range: [],
      rangeDefault: [
        moment().utc().subtract(60, 'days'),
        moment().subtract(1, 'days').utc()
      ],
      rangeWithToday: [
        moment().utc().subtract(59, 'days'),
        moment().utc()
      ]
    },
    {
      title: DateRangeLabel.Last90Days,
      active: false,
      range: [],
      rangeDefault: [
        moment().utc().subtract(90, 'days'),
        moment().subtract(1, 'days').utc()
      ],
      rangeWithToday: [
        moment().utc().subtract(89, 'days'),
        moment().utc()
      ]
    },
    {
      title: DateRangeLabel.Last365Days,
      active: false,
      range: [],
      rangeDefault: [
        moment().utc().subtract(365, 'days'),
        moment().subtract(1, 'days').utc()
      ],
      rangeWithToday: [
        moment().utc().subtract(364, 'days'),
        moment().utc()
      ]
    },
    {
      title: DateRangeLabel.LastWeek,
      active: false,
      range: [],
      rangeDefault: [
        moment().utc().subtract(1, 'week').startOf('week').add(1, 'day'),
        moment().utc().subtract(1, 'week').endOf('week').add(1, 'day')
      ]
    },
    {
      title: DateRangeLabel.LastMonth,
      active: false,
      range: [],
      rangeDefault: [
        moment().utc().subtract(1, 'month').startOf('month'),
        moment().utc().subtract(1, 'month').endOf('month')
      ]
    },
    {
      title: DateRangeLabel.ThisWeek,
      active: false,
      range: [],
      rangeDefault: [
        moment().utc().startOf('week').add(1, 'day'),
        moment().utc()
      ]
    },
    {
      title: DateRangeLabel.ThisMonth,
      active: false,
      range: [],
      rangeDefault: [
        moment().utc().startOf('month'),
        moment().utc()
      ]
    },
    {
      title: DateRangeLabel.AllTime,
      active: false,
      isAllTime: true,
      range: [],
      rangeDefault: [
        moment(this.userService?.userInfo?.created_at || '03/22/2019 9:00').utc(),
        moment().utc()
      ]
    },
  ]

  constructor(
    @Optional() private userService: UserService,
    private safeLocalStorageService: SafeLocalStorageService,
  ) {
  }

  public setLocalLabel(label: string) {
    this.selectedRangeLabelLocal$.next(label)
  }

  // Set selected range
  public get ranges(): MatDatepickerRange[] {
    let ranges = _.cloneDeep(this._ranges)
    const includeToday = this.includeTodayLocal$.value
    ranges.forEach(r => {
      if (r.rangeWithToday && includeToday) {
        r.range = _.cloneDeep(r.rangeWithToday)
      } else {
        r.range = _.cloneDeep(r.rangeDefault)
      }
    })
    // If allTime is false, remove allTime range
    if (!this.includeAllTime$.value) {
      ranges = ranges.filter(r => !r.isAllTime)
    }
    return ranges
  }

  // Set includeToday value to localStorage
  public setIncludeToday(include: boolean) {
    this.safeLocalStorageService.setItem('mat-daterange-includeToday', include.toString())
  }

  // Get includeToday value from localStorage
  public getIncludeToday(): boolean {
    const includeToday = this.safeLocalStorageService.getItem('mat-daterange-includeToday')
    if (includeToday) {
      // if key exists, return value
      return includeToday === 'true'
    } else {
      // if key does not exist, set default value
      this.setIncludeToday(this.includeTodayDefault)
      return this.includeTodayDefault
    }
  }

  // Get range by title
  public getDateRangeByLabel(label: DateRangeLabel, local = false): DateRange<moment.Moment> {
    const r = this.ranges.find(r => r.title === label)
    if (r) {
      // For local use we need to use includeTodayLocal$ value
      if (local) {
        if (this.includeTodayLocal$.value && r.rangeWithToday) {
          return new DateRange<moment.Moment>(r.rangeWithToday[0], r.rangeWithToday[1])
        } else {
          return new DateRange<moment.Moment>(r.rangeDefault[0], r.rangeDefault[1])
        }
      // For external use we need to use value from localStorage
      } else {
        if (this.getIncludeToday() && r.rangeWithToday) {
          return new DateRange<moment.Moment>(r.rangeWithToday[0], r.rangeWithToday[1])
        } else {
          return new DateRange<moment.Moment>(r.rangeDefault[0], r.rangeDefault[1])
        }
      }
    } else {
      return null
    }
  }

}
