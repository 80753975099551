// transfer method written in src/app/shared/services/integrations.service.ts: transferIntegration
export enum IntegrationLabel {
  MailChimp = 'MailChimp',
  Omnisend = 'Omnisend',
  ConstantContact = 'Constant Contact',
  Drip = 'GetDrip',
  Klaviyo = 'Klaviyo',
  Zapier = 'Zapier',
  CampaignMonitor = 'Campaign Monitor', // CMCommerce
  Zoho = 'Zoho',
  AWeber = 'AWeber',
  ActiveCampaign = 'ActiveCampaign',
  GetResponse = 'GetResponse',
  Bronto = 'Bronto', // BrontoModel
  Brevo = 'Brevo', // Sendinblue
  Mailerlite = 'Mailerlite',
  SmartrMail = 'SmartrMail',
  ShopifyEmail = 'Shopify Email',
  Seguno = 'Seguno',
  SmsBump = 'SMSBump',
}

export enum IntegrationType {
  MailChimp = 'MailChimp',
  Omnisend = 'Omnisend',
  ConstantContact = 'ConstantContact',
  Drip = 'GetDrip',
  Klaviyo = 'Klaviyo',
  Zapier = 'Zapier',
  CMCommerce = 'CMCommerce',
  Zoho = 'Zoho',
  AWeber = 'AWeber',
  ActiveCampaign = 'ActiveCampaign',
  GetResponse = 'GetResponse',
  BrontoModel = 'BrontoModel',
  Sendinblue = 'Sendinblue',
  Mailerlite = 'Mailerlite',
  SmartrMail = 'SmartrMail',
  ShopifyEmail = 'ShopifyEmail',
  Seguno = 'Seguno',
  SmsBump = 'SMSBump',
}

export interface Integration {
  id?: string
  type?: IntegrationType
  title?: string
  api_key?: string
  optin?: boolean
  oauth_code?: string
  created_at?: string
  updated_at?: string
  supports_lists?: boolean
  slice_label_field?: boolean
  slice_value_field?: boolean
  integrations?: MailIntegration[]
  hidden?: boolean
}

export interface CreateIntegrationPayload {
  type: IntegrationType
  title: string
  oauth_code: string
  location: string
}

export interface BaseIntegration {
  id: string

  list_id: string | number
  list_name: string

  notification_id: string
  notification_type: string

  campaign_id: string
  campaign_name: string
  campaign_status: string

  status: 'forwarding' | 'paused'
  status_tooltip: string

  integration_name?: string

  created_at?: string
  updated_at?: string
  supports_lists?: boolean
}

export interface MailIntegration extends BaseIntegration {
  mail_system_id: string
  mail_system_api_key: string
  mail_system_type: IntegrationType
  mail_system_optin: boolean
}

export interface SmsIntegration extends BaseIntegration {
  sms_system_id: string
  sms_system_api_key: string
  sms_system_type: IntegrationType
  sms_system_optin: boolean
}

export type IntegrationModel = MailIntegration | SmsIntegration

export interface MailIntegrationList {
  id: string
  name: string
}

export interface MailIntegrationAccount {
  id: string
  name: string
}
