import { Campaign } from '../../shared/models/campaign/campaign'
import { CouponBoxThemeConfigDefault, NewsletterThemeConfigDefault } from '../../shared/models/campaign/coupon-box-notification.model'

export interface CampaignTemplateError {
  param: string
  message: string
  position: { line: string; line_number: number; column: number }
}

export interface CampaignState {
  list: Campaign[]
  searchQuery: string
  error: string
  templateError: CampaignTemplateError
  limitError?: {
    errors: {
      code: string
      field: string
      resource: string
    }
    message: string
  }
  requests: {
    updateTypeConfig: boolean // todo: rename or get rid of it. it is too confusing
  }
  cb: {
    themes: CouponBoxThemeConfigDefault[]
  }
  newsletter: {
    themes: NewsletterThemeConfigDefault[]
  }
  wheel: {
    patterns: any
    themes: any
  }
  selectedCampaign: Campaign
  selectedCampaignEventsResult: { events; status: boolean }
}

export const initialCampaignState: CampaignState = {
  list: [],
  selectedCampaign: null,
  selectedCampaignEventsResult: { events: [], status: false },
  searchQuery: '',
  error: null,
  limitError: null,
  templateError: null,
  requests: {
    updateTypeConfig: null,
  },
  cb: {
    themes: null,
  },
  newsletter: {
    themes: null,
  },
  wheel: {
    patterns: null,
    themes: null,
  },
}
