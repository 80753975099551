import { Directive, ElementRef, HostListener, Input, OnDestroy } from '@angular/core'
import * as confetti from 'canvas-confetti'

interface HTMLCanvasElementConfetti extends HTMLCanvasElement {
  confetti?: any
}

@Directive({
    selector: '[oneConfetti]',
    standalone: true
})
export class OneConfettiDirective implements OnDestroy {
  private _totalCount: number
  private confettiCount = 0
  private windowIsFocused = true
  @Input('totalCount') set totalCount(value: number) {
    this._totalCount = value
    this.onTotalUpdate()
  }
  private confettiInterval: any
  private canvas: HTMLCanvasElementConfetti
  @HostListener('window:focus', ['$event']) onPageFocus(event: FocusEvent): void {
    this.windowIsFocused = true
  }
  @HostListener('window:blur', ['$event']) onPageBlur(event: FocusEvent): void {
    this.windowIsFocused = false
  }

  constructor(
    private elementRef: ElementRef,
  ) { }

  createCanvas() {
    this.elementRef.nativeElement.style.position = 'relative'
    this.canvas = document.createElement('canvas')
    this.elementRef.nativeElement.appendChild(this.canvas)
    this.canvas.style.position = 'absolute'
    this.canvas.style.top = '0'
    this.canvas.style.left = '0'
    this.canvas.style.pointerEvents = 'none'
    this.canvas.style.width = '100%'
    this.canvas.style.height = '100%'
  }

  onTotalUpdate(): void {
    if (this._totalCount > 0) {
      if (!this.canvas) {
        // need to have a small delay to allow the DOM to render
        setTimeout(() => {
          this.createCanvas()
          this.createInterval()
        }, 100)
      } else {
        this.createInterval()
      }
    }
  }

  createInterval(): void {
    this.confettiInterval = setInterval(() => {
      if (this.windowIsFocused) {
        this.launchConfetti()
        this.confettiCount++
      }
      if (this.confettiCount > 50) {
        clearInterval(this.confettiInterval)
      }
    }, 800)
  }

  launchConfetti(): void {
    this.canvas.confetti = this.canvas.confetti || confetti.create(this.canvas, { resize: true })
    if (typeof this.canvas.confetti === 'function') {
      this.canvas.confetti({
        particleCount: 80,
        angle: 60,
        spread: 55,
        origin: { x: 0, y: -1},
      })
      this.canvas.confetti({
        particleCount: 80,
        angle: 120,
        spread: 55,
        origin: { x: 1, y: -1},
      })
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.confettiInterval)
  }
}
