const nb_NO = {
  Quantity: 'Antall',
  Size: 'Størrelse',
  Color: 'Farge',
  Material: 'Materiale',
  Style: 'Stil',
  Savings: 'Besparelser',
  Subtotal: 'Delsum',
  Shipping: 'Frakt',
  Taxes: 'Avgifter',
  Total: 'Totalt',
  Free: 'Gratis',
  Buy: 'Kjøp',
  'Pay Now': 'Betal nå',
  'Take the Deal': 'Ta avtalen',
  'Add Now': 'Legg til nå',
  'You will be charged immediately': 'Du vil bli belastet umiddelbart',
  'Decline Offer': 'Avslå tilbud',
  'No Thanks': 'Nei takk',
  '{price}': '{pris}',
  'Sold Out': 'Utsolgt',
  'see more': 'se mer',
  less: 'mindre',
};
export {nb_NO};
