<ng-container *ngIf="isInvalid && !noValidate">
  <span class="text-danger">
    Can't be blank
  </span>
</ng-container>

<ng-container>
  <div class="ProductsList" #productsList [ngClass]="customClass">
    <pf-loading-overlay [filter]="{only: ['ProductsSelector']}" [isFixed]="false"></pf-loading-overlay>
    <ng-container *ngIf="!outputList || !outputList.length">
      <div class="ProductsList-ItemRow _no-selection">
        <pf-no-table-data
          [text]="paginator?.pageIndex === 0 ? emptyText : 'No more products found'">
        </pf-no-table-data>
      </div>
    </ng-container>
    <ng-container *ngIf="outputList && outputList.length">
      <div *ngFor="let item of outputList" class="ProductsList-Item">
        <div class="ProductsList-ItemRow">
          <div class="ProductsList-ItemImage" [class._no-image]="!(item.images && item.images.length > 0 && item.images[0].compact_url) && !item.image_url">
            <img [attr.src]="item.images && item.images.length > 0 && item.images[0].compact_url || item.image_url" alt="">
          </div>
          <span class="ProductsList-ItemClearable" *ngIf="clearable; else itemTitle">
            <span class="ProductsList-ItemTitle">
              {{item.name || item.title}}
            </span>
            <span>
              <span *ngIf="displayQuantity && item.inventory_management && item.inventory_policy === 'deny'">
                <small *ngIf="item.inventory_quantity" class="font-italic">{{item.inventory_quantity}} in stock</small>
                <small *ngIf="!item.inventory_quantity" class="text-danger">Out of stock</small>
              </span>
              <span class="_remove-button" (click)="onRemoveItem(item)">&times;</span>
            </span>
          </span>
          <ng-template #itemTitle>
            <span class="ProductsList-ItemTitle">
              {{item.name || item.title}}
            </span>
            <span *ngIf="displayQuantity && item.inventory_management && item.inventory_policy === 'deny'">
              <small *ngIf="item.inventory_quantity" class="font-italic">{{item.inventory_quantity}} in stock</small>
              <small *ngIf="!item.inventory_quantity" class="text-danger">Out of stock</small>
            </span>
          </ng-template>
        </div>

        <div *ngIf="item && item.variants" class="ProductVariants ml-3">
          <div *ngFor="let variant of item.variants" class="ProductVariants-Item justify-content-between align-items-center">
            <span class="ProductsList-ItemClearable" *ngIf="clearable; else itemTitle">
              <span class="ProductsList-ItemTitle">
                {{variant.name}}
              </span>
              <span>
                <span *ngIf="displayQuantity && variant.inventory_management && variant.inventory_policy === 'deny'">
                  <small *ngIf="variant.inventory_quantity" class="font-italic">{{variant.inventory_quantity}} in stock</small>
                  <small *ngIf="!variant.inventory_quantity" class="text-danger">Out of stock</small>
                </span>
                <span class="_remove-button" (click)="onRemoveItem(item, variant)">&times;</span>
              </span>
            </span>
            <ng-template #itemTitle>
              <span class="ProductsList-ItemTitle">
                {{variant.name}}
              </span>
              <span *ngIf="displayQuantity && variant.inventory_management && variant.inventory_policy === 'deny'">
                <small *ngIf="variant.inventory_quantity" class="font-italic">{{variant.inventory_quantity}} in stock</small>
                <small *ngIf="!variant.inventory_quantity" class="text-danger">Out of stock</small>
              </span>
            </ng-template>
          </div>
        </div>

      </div>
    </ng-container>
  </div>

  <small class="text-danger" *ngIf="outOfStockError">{{ outOfStockError }}</small>

  <mat-paginator
    #paginator
    [class._last-btn-hidden]="selection?.type === uiCouponTargetSelection.entitled_products"
    [class.is-hidden]="hidePagination"
    [pageSize]="itemsPerPage"
    [pageSizeOptions]="pageSizeOptions"
    [pageIndex]="pageIndex"
    [length]="totalItems"
    (page)="loadPage($event)"
  ></mat-paginator>
</ng-container>
