import { BrandingNameResponse } from '../../../shared/models/branding-data.model';

export interface EmailBrandingRequest {
  name: BrandingNameResponse
  logo_url?: string
  style: EmailBrandingStyle
  utm_params?: {
    utm_campaign: string
    utm_medium: string
    utm_source: string
  }
}

export enum EmailBrandingStyle {
  Black = 'black',
  Blue = 'blue',
  Gray = 'gray',
  LightGray = 'lightgray'
}

export enum EmailBrandingStyleCode {
  black_bg = '#000000',
  black_color = '#ffffff',
  blue_bg = '#0095f7',
  blue_color = '#ffffff',
  gray_bg = '#808080',
  gray_color = '#ffffff',
  ligtgray_bg = '#eeeeee',
  ligtgray_color = '#000000'
}

export enum EmailBrandingDesignTags {
  Word = 'email_branding_word',
  LogoUrl = 'email_branding_logo_url',
  BackgroundColor = 'email_branding_background_color',
  TextColor = 'email_branding_text_color'
}

export enum EmailBrandingUniqueVariables {
  UniquePreviewClass = 'pf-email-branding-logo-preview-only', /* be aware to change, use for backend to identify the preview branding element and hide it in real email */
  UniqueBlockId = 'pf-email-branding-block-id', /* used to identify the branding element to avoid pushing twice */
  UniqueIframeMessage = 'pf-email-branding-iframe-click' /* use to communicate between iframe and its parent event */
}

/* preview email that will be shown in the unlayer editor */
function previewBrandingHTML(hasLogo: boolean): string {
  return `
<style>
  .${EmailBrandingUniqueVariables.UniquePreviewClass} .tooltip {
  position: relative;
  }
  .${EmailBrandingUniqueVariables.UniquePreviewClass} .tooltip::after {
  background-color: #333;
  border-radius: 10px;
  color: #fff;
  display: none;
  padding: 10px 15px;
  position: absolute;
  text-align: center;
  z-index: 999;
  }
  .${EmailBrandingUniqueVariables.UniquePreviewClass} .tooltip::before {
  background-color: #333;
  content: ' ';
  display: none;
  position: absolute;
  width: 15px;
  height: 15px;
  z-index: 999;
  }
  .${EmailBrandingUniqueVariables.UniquePreviewClass} .tooltip:hover::after {
  display: block;
  }
  .${EmailBrandingUniqueVariables.UniquePreviewClass} .tooltip:hover::before {
  display: block;
  }
  .${EmailBrandingUniqueVariables.UniquePreviewClass} .tooltip.top::after {
  content: "[[{${EmailBrandingDesignTags.Word}}]] branding is automatically added to the footer of all emails. Click this brand logo if you\'d like to update the color or remove the branding.";
  white-space: pre-wrap;
  width: 435px;
  top: 0;
  left: 50%;
  transform: translate(-50%, calc(-100% - 10px));
  }
  .${EmailBrandingUniqueVariables.UniquePreviewClass} .tooltip.top::before {
  top: 0;
  left: 50%;
  transform: translate(-50%, calc(-100% - 5px)) rotate(45deg);
  }
</style>
<br>
<table align="center" class="${EmailBrandingUniqueVariables.UniquePreviewClass}" style="text-align:center;background:transparent;width:100%" width="100%">
<tbody><tr>
<td align="center" valign="center" style="font-family:Helvetica,Arial,sans-serif; font-size:12px; text-align:center; vertical-align:center;">
<a class="tooltip top" href="#"
onclick="window.parent.postMessage('${EmailBrandingUniqueVariables.UniqueIframeMessage}', '*');"
style="text-decoration:none !important;
background-color: [[{${EmailBrandingDesignTags.BackgroundColor}}]];
color: [[{${EmailBrandingDesignTags.TextColor}}]];
padding: 10px 20px; white-space: nowrap;">
${hasLogo ? `<span>Powered by </span><img width="34" height="10" style="margin-bottom: -1px;" alt="[[{${EmailBrandingDesignTags.Word}}]]" src="[[${EmailBrandingDesignTags.LogoUrl}]]">` :
      `<span>Powered by [[{${EmailBrandingDesignTags.Word}}]]</span>`}
</a>
</td>
</tr></tbody>
</table>
<br>
`
}

export function renderingBrandingPreviewHTML(hasLogo: boolean) {
  return {
    "id": EmailBrandingUniqueVariables.UniqueBlockId,
    "cells": [
      1
    ],
    "columns": [
      {
        "contents": [
          {
            "type": "html",
            "values": {
              "html": previewBrandingHTML(hasLogo),
              "selectable": false,
              "draggable": false,
              "duplicatable": false,
              "deletable": false,
              "hideable": false
            }
          }
        ],
        "values": {
          "backgroundColor": "",
          "padding": "0px",
          "borderRadius": "0px",
        }
      }
    ],
    "values": {
      "padding": "0px",
      "selectable": false,
      "draggable": false,
      "duplicatable": false,
      "deletable": false,
      "hideable": false
    }
  }
}
