<h2 mat-dialog-title>{{title}}
  <small
    *ngIf="description"
    [class]="data.maxSelectionCount && (tooManyProducts || tooManyCollections) ? 'text-danger' : 'text-muted'"><br>
    {{description}}
  </small>
</h2>
<div mat-dialog-content>
  <pf-products-selector-list (onSelect)="updateList($event)" [userInfo]="userInfo" [data]="data">
  </pf-products-selector-list>
</div>
<div mat-dialog-actions class="d-flex justify-content-end">
  <button class="pf-button outline gray md" (click)="dialogRef.close()" cdkFocusInitial>Cancel</button>
  <button [disabled]="buttonDisabled()"
          class="pf-button filled blue ml-3"
          (click)="onSelectClick()"
          cdkFocusInitial>
    Select
  </button>
</div>
