<div id="jotform-script"></div>
<ng-container *ngIf="!formCompleted; else congratsModal">
  <h1 mat-dialog-title class="AF-Title">
    {{ activeForm?.header?.title }}
    <button class="mat-dialog-close" (click)="dialogRef.close()">
        <span>&times;</span>
    </button>
  </h1>
  <div mat-dialog-content class="mb-2">
    <div class="px-4 mb-4">
      <p class="AF-Subtitle">
        {{ activeForm?.header?.subtitle }}
      </p>
      <hr>
      <p class="AF-Description" [innerHtml]="activeForm?.description"></p>

      <div *ngFor="let question of activeForm.questions">
        <div *ngIf="!question.hidden" class="AF-Question-Row" [id]="question.qid">
          <h5 class="AF-Question-Label" *ngIf="question.type !== questionType.ControlWidget">
            {{ question.text }}
            <span class="text-danger" *ngIf="question.required">*</span>
          </h5>
          <ng-container [ngSwitch]="question.type">
            <!-- Full Name Control -->
            <ng-container *ngSwitchCase="questionType.ControlFullname">
              <div class="AF-Question-Double">
                <div *ngIf="question.control && question.control.get('first'); let control">
                  <input type="text" class="form-control" [formControl]="control" [placeholder]="question.placeholder.first">
                  <div *ngIf="control.touched && control.errors">
                    <small class="text-danger" *ngIf="control.errors.required">
                      This field is required
                    </small>
                    <small class="text-danger" *ngIf="control.errors.minlength">
                      Too few characters. The minimum is {{ question.entryLimitMin }}
                    </small>
                    <small class="text-danger" *ngIf="control.errors.maxlength">
                      Too many characters. The maximum is {{ question.entryLimit }}
                    </small>
                  </div>
                </div>
                <div *ngIf="question.control && question.control.get('last'); let control">
                  <input type="text" class="form-control" [formControl]="control" [placeholder]="question.placeholder.last">
                  <div *ngIf="control.touched && control.errors">
                    <small class="text-danger" *ngIf="control.errors.required">
                      This field is required
                    </small>
                    <small class="text-danger" *ngIf="control.errors.minlength">
                      Too few characters. The minimum is {{ question.entryLimitMin }}
                    </small>
                    <small class="text-danger" *ngIf="control.errors.maxlength">
                      Too many characters. The maximum is {{ question.entryLimit }}
                    </small>
                  </div>
                </div>
              </div>
            </ng-container>
            <!-- Email Control -->
            <ng-container *ngSwitchCase="questionType.ControlEmail">
              <div class="AF-Question">
                <div *ngIf="question.control; let control">
                  <input type="email" class="form-control" [formControl]="control" [placeholder]="question.hint">
                  <div *ngIf="control.touched && control.errors">
                    <small class="text-danger" *ngIf="control.errors.required">
                      This field is required
                    </small>
                    <small class="text-danger" *ngIf="control.errors.invalid_email">
                      Please enter a valid email
                    </small>
                    <small class="text-danger" *ngIf="control.errors.minlength">
                      Too few characters. The minimum is {{ question.entryLimitMin }}
                    </small>
                    <small class="text-danger" *ngIf="control.errors.maxlength">
                      Too many characters. The maximum is {{ question.entryLimit }}
                    </small>
                  </div>
                </div>
              </div>
            </ng-container>
            <!-- Phone Control -->
            <ng-container *ngSwitchCase="questionType.ControlPhone">
              <div class="AF-Question">
                <div *ngIf="question.control; let control">
                  <pf-phone-input
                    [required]="question.required"
                    [formControl]="control"
                  ></pf-phone-input>
                </div>
              </div>
            </ng-container>
            <!-- Text Control -->
            <ng-container *ngSwitchCase="questionType.ControlTextbox">
              <div class="AF-Question">
                <div *ngIf="question.control; let control">
                  <input type="text" class="form-control" [formControl]="control" [placeholder]="question.hint">
                  <div *ngIf="control.touched && control.errors">
                    <small class="text-danger" *ngIf="control.errors.required">
                      This field is required
                    </small>
                    <small class="text-danger" *ngIf="control.errors.minlength">
                      Too few characters. The minimum is {{ question.entryLimitMin }}
                    </small>
                    <small class="text-danger" *ngIf="control.errors.maxlength">
                      Too many characters. The maximum is {{ question.entryLimit }}
                    </small>
                    <small class="text-danger" *ngIf="control.errors.invalid_url">
                      Please enter valid URL
                    </small>
                  </div>
                </div>
              </div>
            </ng-container>
            <!-- TextArea Control -->
            <ng-container *ngSwitchCase="questionType.ControlTextarea">
              <div class="AF-Question">
                <div *ngIf="question.control; let control">
                  <textarea type="text" class="form-control" [formControl]="control" [placeholder]="question.hint"></textarea>
                  <div *ngIf="control.touched && control.errors">
                    <small class="text-danger" *ngIf="control.errors.required">
                      This field is required
                    </small>
                    <small class="text-danger" *ngIf="control.errors.minlength">
                      Too few characters. The minimum is {{ question.entryLimitMin }}
                    </small>
                    <small class="text-danger" *ngIf="control.errors.maxlength">
                      Too many characters. The maximum is {{ question.entryLimit }}
                    </small>
                  </div>
                </div>
              </div>
            </ng-container>
            <!-- Number Control -->
            <ng-container *ngSwitchCase="questionType.ControlNumber">
              <div class="AF-Question">
                <div *ngIf="question.control; let control">
                  <input type="number" class="form-control" [formControl]="control" min="0" max="question.maxValue || 999" [placeholder]="question.hint">
                  <div *ngIf="control.touched && control.errors">
                    <small class="text-danger" *ngIf="control.errors.required">
                      This field is required
                    </small>
                    <small class="text-danger" *ngIf="control.errors.minlength">
                      Too few characters. The minimum is {{ question.entryLimitMin }}
                    </small>
                    <small class="text-danger" *ngIf="control.errors.maxlength">
                      Too many characters. The maximum is {{ question.entryLimit }}
                    </small>
                  </div>
                </div>
              </div>
            </ng-container>
            <!-- CheckBox Control -->
            <ng-container *ngSwitchCase="questionType.ControlCheckbox">
              <div class="AF-Question-Checkbox" [class._double]="question.spreadCols === 2">
                <label *ngFor="let option of question.options" class="AF-Question-Checkbox-Option" [class._checked]="option.checked" [class._error]="question.error"
                       (click)="checkOption($event, option, question)">
                  <div class="_mark"></div>
                  <span *ngIf="option.type !== 'other'">{{ option.value }}</span>
                  <input *ngIf="option.type === 'other'" type="text" [(ngModel)]="option.value" [placeholder]="option.placeholder">
                </label>
              </div>
              <div class="mt-2" *ngIf="question.error">
                <small class="text-danger">
                  At least one option should be selected
                </small>
              </div>
            </ng-container>

            <!-- Terms Control -->
            <ng-container *ngSwitchCase="questionType.ControlWidget">
              <ng-container *ngIf="question.widgetType === widgetType.Field">
                <label class="AF-Question-Terms-Checkbox" [class._checked]="question.checked" [class._error]="question.error"
                       (click)="checkTerms(question)">
                  <div class="_mark"></div>
                  <span class="AF-Question-Terms-Label" [class.text-danger]="question.error">
                    {{ question.label }}
                    <span class="text-danger" *ngIf="question.required">*</span>
                  </span>
                </label>
              </ng-container>
            </ng-container>

            <!-- Number Control -->
            <ng-container *ngSwitchCase="questionType.ControlFileUpload">
              <!-- TODO implement file upload -->
              <pf-multi-upload
                path="sms_access"
                buttonText="Browse Files"
                class="ImageUpload-Loader"
                (selectedImages)="setImages(question, $event)"
              ></pf-multi-upload>
              <div *ngIf="question.error">
                <small class="text-danger">
                  Please upload files before submitting
                </small>
              </div>
              <small class="text-muted">{{ question.description }}</small>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
    <div mat-dialog-actions class="d-flex justify-content-center">
      <button class="pf-button filled blue px-5" (click)="submitForm()" [disabled]="loading">Get Access</button>
    </div>
    </div>

  <small *ngIf="submissionError" class="d-flex justify-content-center text-danger mt-2">{{ submissionError }}</small>
</ng-container>

<ng-template #congratsModal>
  <button class="mat-dialog-close" (click)="dialogRef.close()">
    <span>&times;</span>
  </button>

  <div mat-dialog-content class="d-flex flex-column justify-content-center align-items-center mb-2">
    <img src="/app_assets/success.svg" alt="">
    <h5>We've received your request.</h5>
    <p>Thank you very much. We'll get back to you soon!</p>
  </div>
  <div mat-dialog-actions class="d-flex flex-column justify-content-center align-items-center">
    <button class="pf-button outline blue" (click)="dialogRef.close()">Close</button>
    <small class="mt-2" *ngIf="allowRefill">
      <a href="#" (click)="$event.preventDefault(); retakeForm()">Retake form</a>
    </small>
  </div>
</ng-template>

<pf-simple-loading-overlay [isLoading]="loading"></pf-simple-loading-overlay>
