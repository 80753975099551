export enum InteractionsKey {
  UpgradeEvents = 'upgrade_events',
  Stripe = 'stripe'
}

export enum InteractionEventName {
  ReviewPlansClick = 'review_plans_click',
  SelectPlanClick = 'select_plan_click',
  UpgradeThemeClick = 'upgrade_theme_click',
}

export interface InteractionsModel {
  event_name: InteractionEventName,
  [key: string]: any
}
