import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { tap } from 'rxjs/operators'
import { DashboardSettingsService } from '../../../../core/services/dashboard-settings.service'
import { RouteAvailabilityConfig, RouteAvailabilityKey } from '../models/route-availability.model'
/* TODO: Rename RouteAvailabilityService to something relate to dashboard config ^_^ ?  */
@Injectable()
export class RouteAvailabilityService {
  public disabledPages: String[] = []
  public isEmailStatsPaused: boolean = false
  public title$: BehaviorSubject<string> = new BehaviorSubject('')

  constructor(
    private dashboardSettingsService: DashboardSettingsService,
  ) {}

  public getConfig(): Observable<RouteAvailabilityConfig> {
    return this.dashboardSettingsService.pollSettings().pipe(
      tap((response: RouteAvailabilityConfig) => {
        this.setEmailStats(response)
        this.setDisabledPages(response)
      })
    )
  }

  private setEmailStats(config: RouteAvailabilityConfig) {
    if (!config) {
      return false
    }

    if (config[RouteAvailabilityKey.EmailStatsPaused] === true) {
      this.isEmailStatsPaused = true
    } else {
      this.isEmailStatsPaused = false
    }
  }

  private setDisabledPages(config: RouteAvailabilityConfig) {
    const disabledPages = []
    if (!config) {
      return false
    }

    // leave it to support 'Analytics disabled' checkbox on admin page
    const keys = Object.keys(config)
    keys.forEach(key => {
      if (config[key]) {
        switch (key) {
          case RouteAvailabilityKey.Analytics:
            disabledPages.push('dashboard')
            break
        }
      }
    })
    if (config?.disabled_pages?.length) {
      disabledPages.push(...config.disabled_pages)
    }
    this.disabledPages = disabledPages
  }

}
