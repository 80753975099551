import { Injectable } from '@angular/core'
import { UserInfo } from '../../../../store/user/user.state'
import { Store } from '@ngrx/store'
import { StoreState } from '../../../../store/store.state'
import { SetPromo } from '../../../../store/user/user.actions'
import { Promo, PromoParam } from '../models/promo.model'
import * as Cookies from 'js-cookie'
import * as _ from 'lodash'
import {  Router } from '@angular/router'
import { SafeLocalStorageService } from '../../../../core/services/safe-local-storage.service'
import { BehaviorSubject } from 'rxjs'

@Injectable()
export class PromoService {
  fullWidthBanner$ = new BehaviorSubject<boolean>(false)

  promoParams = [
    PromoParam.promo_code,
    PromoParam.promo_type,
    PromoParam.promo_referrer,
    PromoParam.promo_view,
  ]

  // ?promo_code=50_OFF&promo_type=bfcm&promo_referrer=reconvert&promo_view=cyber_week
  // ?promo_code={{CODE_HERE}}&promo_type=special_offer&promo_referrer=prooffactor&promo_view=special_offer

  constructor(
    private store: Store<StoreState>,
    private router: Router,
    private safeLocalStorageService: SafeLocalStorageService,
  ) {
  }

  clearCookiesAndStorage(user: UserInfo) {
    this._migratePromoParams(user)
    const domain = document.location.hostname.split('.').reverse().splice(0, 2).reverse().join('.')
    const promoKey = user.id ? `proof_factor_promo_${user.id}` : null
    if (promoKey) {
      Cookies.remove(promoKey, {path: '', domain: domain})
      this.safeLocalStorageService.removeItem(promoKey)
    }
  }

  definePromo({params = null, removeUrlParams = false} = {}, user: UserInfo) {
    const urlParams = this.getUrlPromoParams(params)
    if (this._hasPromoParams(urlParams)) {
      this._migratePromoParams(user)
      this._savePromoParams(urlParams, user)
    }
    if (removeUrlParams) {
      this.removeUrlParams()
    }
  }

  public removeUrlParams() {
    this.router.navigate([], { queryParams: { promo_code: null, promo_type: null, promo_referrer: null, promo_view: null }, queryParamsHandling: 'merge' })
  }

  public getUrlPromoParams(params?: string): Promo {
    const urlQuery = params ? params.substring(1) : window.location.search.substring(window.location.search.indexOf('?') + 1)
    const queries = urlQuery.split('&')
    const parsedQueries = {}
    queries.forEach(query => {
      const tmp = query.split('=')
      if (this.promoParams.includes(tmp[0] as PromoParam)) {
        parsedQueries[tmp[0]] = tmp[1]
      }
    })
    return parsedQueries as Promo
  }

  private _hasPromoParams(promo: Promo) {
    return _.get(promo, PromoParam.promo_code, false) ||
           (_.get(promo, PromoParam.promo_type, false) || _.get(promo, PromoParam.promo_view, false))
  }

  // used to migrate the legacy proof_factor_promo cookie to the new format
  private _migratePromoParams(user: UserInfo) {
    const orignalPromo = Cookies.getJSON('proof_factor_promo')
    const domain = document.location.hostname.split('.').reverse().splice(0, 2).reverse().join('.')
    if (orignalPromo) {
      this._savePromoParams(orignalPromo, user)
      Cookies.remove('proof_factor_promo', {path: '', domain: domain})
    }
  }

  private _savePromoParams(promo: Promo, user: UserInfo) {
    const domain = document.location.hostname.split('.').reverse().splice(0, 2).reverse().join('.')
    const promoKey = user.id ? `proof_factor_promo_${user.id}` : null
    if (promoKey && promo) {
      Cookies.set(promoKey, promo, { path: '', domain: domain, expires: 365 })
      this.safeLocalStorageService.setItem(promoKey, JSON.stringify(promo))
    }
  }

  public togglePromoState(user: UserInfo, overrideEligibility = false) {
    const promoKey = user.id ? `proof_factor_promo_${user.id}` : 'proof_factor_promo'
    let promo = Cookies.getJSON(promoKey)
    if (!promo) {
      const lsPromo = this.safeLocalStorageService.getItem(promoKey)
      if (lsPromo) {
        promo = JSON.parse(lsPromo)
      }
    }

    if (this._hasPromoParams(promo) && (_.get(user, 'promo_eligible', false) || overrideEligibility)) {
      this.store.dispatch(new SetPromo(promo))
    } else if (!this._hasPromoParams(promo) && (_.get(user, 'promo_eligible', false) || overrideEligibility)) {
      // this guards against unsetting promo params if user object has only onboarding data
      this.store.dispatch(new SetPromo(undefined))
    }
  }

  public promoCookieSet(user: UserInfo) {
    this._migratePromoParams(user)
    const promoKey = `proof_factor_promo_${user.id}`
    const promo = Cookies.getJSON(promoKey)
    return this._hasPromoParams(promo)
  }
}
