import { Action } from '@ngrx/store'
import { UserActionTypes } from './user.action-types'
import { AsyncAction } from '../../shared/models/store/async-action.model'
import { CouponCode } from '../../pages/coupon-codes/models/coupon-code'
import { UserShop } from '../../shared/models/user/user-shop.model'
import { ConversionsResponse } from '../../pages/dashboard-new/models/dashboard.models'


export class SetSignUpCompleted implements Action {
  readonly type = UserActionTypes.SET_SIGN_UP_FLOW_COMPLETED

  constructor(public payload: boolean) {
  }
}

export class SetUserChosePlan implements Action {
  readonly type = UserActionTypes.SET_USER_CHOSE_PLAN

  constructor(public payload: boolean) {
  }
}

export class SetPluginInstalled implements Action {
  readonly type = UserActionTypes.SET_PLUGIN_INSTALLED

  constructor(public payload: boolean) {
  }
}

export class SetCurrentUserInfo implements Action {
  readonly type = UserActionTypes.SET_INFO

  constructor(public payload: object) {
  }
}

export class ResetUserInfo implements Action {
  readonly type = UserActionTypes.RESET_INFO
}

export class SetPromo implements Action {
  readonly type = UserActionTypes.SET_PROMO

  constructor(public payload: object) {
  }
}

export class GetUserSitesRequest implements AsyncAction {
  readonly type = UserActionTypes.GET_SITES_REQUEST
  readonly loading = { id: UserActionTypes.GET_SITES_REQUEST, active: true }
}

export class GetUserSitesSuccess implements AsyncAction {
  readonly type = UserActionTypes.GET_SITES_SUCCESS
  readonly loading = { id: UserActionTypes.GET_SITES_REQUEST, active: false }

  constructor(public payload: string[]) {
  }
}

export class GetUserSitesFailure implements AsyncAction {
  readonly type = UserActionTypes.GET_SITES_FAILURE
  readonly loading = { id: UserActionTypes.GET_SITES_REQUEST, active: false }

  constructor(public payload: string) {
  }
}

export class GetUserOrdersRequest implements AsyncAction {
  readonly type = UserActionTypes.GET_ORDERS_REQUEST
  readonly loading = { id: UserActionTypes.GET_ORDERS_REQUEST, active: true }
}

export class GetUserOrdersSuccess implements AsyncAction {
  readonly type = UserActionTypes.GET_ORDERS_SUCCESS
  readonly loading = { id: UserActionTypes.GET_ORDERS_REQUEST, active: false }

  constructor(public payload: ConversionsResponse) {
  }
}

export class GetUserOrdersFailure implements AsyncAction {
  readonly type = UserActionTypes.GET_ORDERS_FAILURE
  readonly loading = { id: UserActionTypes.GET_ORDERS_REQUEST, active: false }

  constructor(public payload: string) {
  }
}

export class GetUserShopRequest implements AsyncAction {
  readonly type = UserActionTypes.GET_USER_SHOP_REQUEST
  readonly loading = { id: UserActionTypes.GET_USER_SHOP_REQUEST, active: true }
}

export class GetUserShopSuccess implements AsyncAction {
  readonly type = UserActionTypes.GET_USER_SHOP_SUCCESS
  readonly loading = { id: UserActionTypes.GET_USER_SHOP_REQUEST, active: false }

  constructor(public payload: UserShop) {
  }
}

export class GetUserShopFailure implements AsyncAction {
  readonly type = UserActionTypes.GET_USER_SHOP_FAILURE
  readonly loading = { id: UserActionTypes.GET_USER_SHOP_REQUEST, active: false }

  constructor(public payload: string) {
  }
}

export class GetUserOnboardingRequest implements AsyncAction {
  readonly type = UserActionTypes.GET_USER_ONBOARDING_REQUEST
  readonly loading = { id: UserActionTypes.GET_USER_ONBOARDING_REQUEST, active: true }
  noLoad: boolean
}

export class GetUserOnboardingSuccess implements AsyncAction {
  readonly type = UserActionTypes.GET_USER_ONBOARDING_SUCCESS
  readonly loading = { id: UserActionTypes.GET_USER_ONBOARDING_REQUEST, active: false }

  constructor(public payload: {}) {
  }
}

export class GetUserOnboardingFailure implements AsyncAction {
  readonly type = UserActionTypes.GET_USER_ONBOARDING_FAILURE
  readonly loading = { id: UserActionTypes.GET_USER_ONBOARDING_REQUEST, active: false }

  constructor(public payload: string) {
  }
}

export class LogoutCurrentUser implements Action {
  readonly type = UserActionTypes.LOGOUT
}

export class GetUserCouponsRequest implements AsyncAction {
  readonly type = UserActionTypes.GET_COUPONS_REQUEST
  readonly loading = { id: UserActionTypes.GET_COUPONS_REQUEST, active: true }
}

export class GetUserCouponsSuccess implements AsyncAction {
  readonly type = UserActionTypes.GET_COUPONS_SUCCESS
  readonly loading = { id: UserActionTypes.GET_COUPONS_REQUEST, active: false }

  constructor(public payload: CouponCode[]) {
  }
}

export class GetUserCouponsFailure implements AsyncAction {
  readonly type = UserActionTypes.GET_COUPONS_FAILURE
  readonly loading = { id: UserActionTypes.GET_COUPONS_REQUEST, active: false }

  constructor(public payload: string) {
  }
}

export type UserActions =
  | SetSignUpCompleted
  | SetUserChosePlan
  | SetCurrentUserInfo
  | SetPromo
  | LogoutCurrentUser
  | SetPluginInstalled
  | GetUserSitesRequest
  | GetUserSitesSuccess
  | GetUserSitesFailure
  | GetUserOrdersRequest
  | GetUserOrdersSuccess
  | GetUserOrdersFailure
  | GetUserShopRequest
  | GetUserShopSuccess
  | GetUserShopFailure
  | ResetUserInfo
  | GetUserOnboardingRequest
  | GetUserOnboardingSuccess
  | GetUserOnboardingFailure
  | GetUserCouponsRequest
  | GetUserCouponsSuccess
  | GetUserCouponsFailure
