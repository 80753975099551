import { CommonModule } from '@angular/common'
import { Component, EventEmitter, Input, OnDestroy, Output, TemplateRef, ViewChild, ViewContainerRef, inject, type OnInit } from '@angular/core'
import { Observable, Subscription, forkJoin, tap } from 'rxjs'
import { UserService } from '../../../core/services/user.service'
import { OneIconComponent } from '../one-icon/one-icon.component'
import { MgUploadService } from './services/media-gallery-upload.service'
import { UploadTaskSnapshot } from '@angular/fire/compat/storage/interfaces'
import { FormsModule } from '@angular/forms'
import { LoadingOverlayComponent } from '../loading-overlay/loading-overlay.component'
import { MgService } from './services/media-gallery.service'
import { MGBucket, MGPathData, MGView, MgImage, MgImageList } from './models/media-gallery-models'
import { MGPath } from './services/MGPath'
import { AngularPinturaModule } from '@pqina/angular-pintura'
import { PinturaDefaultImageWriterResult, PinturaNode, createNode, getEditorDefaults} from '@pqina/pintura';
import { FileSizePipe } from "../../pipes/file-size.pipe";
import { OneTooltipDirective } from '../../directives/one-tooltip.directive'
import { MgStorageService } from './services/media-gallery-storage.service'
import { C } from '@angular/cdk/keycodes'

@Component({
    selector: 'one-media-gallery',
    standalone: true,
    templateUrl: './media-gallery.component.html',
    styleUrl: './media-gallery.component.scss',
    imports: [
      CommonModule,
      FormsModule,
      OneIconComponent,
      LoadingOverlayComponent,
      AngularPinturaModule,
      FileSizePipe,
      OneTooltipDirective,
    ]
})

export class MediaGalleryComponent implements OnInit, OnDestroy {
  private subscription = new Subscription()
  @ViewChild('editor', {static: false}) editor: any
  @Input() modalMode = false
  @Input() modalPath: MGPathData[]
  @Input() namePrefix: string
  @Input() currentImage: string
  @Output() imageSelected: EventEmitter<string> = new EventEmitter()
  private mgUploadService = inject(MgUploadService)
  public MGView = MGView
  public viewState: MGView = MGView.gallery
  public isDragOver = false
  public viewGrid = true
  public selectedImg: MgImage
  private userId = this.userService.userInfo.id
  private basePath: MGPathData[] = this.mgUploadService.basePath
  private galleryPaths: MGPathData[][] = [
    [...this.basePath, MGPath.logos],
  ]
  public galleryList: MgImageList[] = []
  public displayList: MgImage[] = []
  public activePath: MGPathData[] = []
  public file: any
  private saveAsCopyActive = false
  private bucketsStack: MGBucket[] = this.mgStorageService.getBuckets()
  search = ''
  editorOptions: any = {
    ...getEditorDefaults(),
  }
  editorLocale = {
    ...getEditorDefaults().locale,
    labelButtonExport: 'Save',
  }
  /**
   * toolbar: [ Array(4), Array(4), Array(4) ]
   * env: { orientation: "landscape", verticalSpace: "short", … }
   * redraw: call redraw to trigger a redraw of the editor state
   */
  willRenderToolbar = (
    toolbar: PinturaNode[],
    env: any,
    redraw: () => void
  ): PinturaNode[] => {
    const saveCopyButtonNode = createNode('Button', 'PinturaButton', {
      label: 'Save as Copy',
      class: 'PinturaButtonExport',
      onclick: () => {
        this.saveAsCopyActive = true
        this.editor.editor.processImage().then((result: PinturaDefaultImageWriterResult) => {
          this.handleEditorProcess(result, false)
          this.saveAsCopyActive = false
        })
      }
    })
    const cancelButtonNode = createNode('Button', 'PinturaButton2', {
      label: 'Cancel',
      onclick: () => {
        this.viewState = MGView.gallery
      }
    })
    toolbar?.[2]?.[3]?.push(saveCopyButtonNode)
    toolbar?.[2]?.[3]?.unshift(cancelButtonNode)
    return toolbar
  }

  constructor(
    private userService: UserService,
    private mgService: MgService,
    private mgStorageService: MgStorageService,
  ) { }

  ngOnInit(): void {
    this.editorOptions.utils = [
      'trim',
      'crop',
      'filter',
      'finetune',
      'retouch',
      'annotate',
      'sticker',
      'fill',
      'frame',
      'redact',
      "resize",
    ]
    if (this.modalMode && this.modalPath) {
      this.activePath = [...this.basePath, ...this.modalPath]
    } else {
      this.activePath = this.galleryPaths[0]
    }
    this.fetchImages()
  }

  fetchImages() {
    this.subscription.add(
      forkJoin(
        this.galleryPaths?.map(
          paths => this.mgService.listAll(this.getUrl(paths))
        )
      ).subscribe((res) => {
        this.galleryList = []
        if (res?.length) {
          res.forEach((result, index) => {
            this.galleryList.push({
              path: this.galleryPaths[index],
              url: this.getUrl(this.galleryPaths[index]),
              active: index === 0,
              items: [] 
            })
            if (result?.items.length) {
              result.items.forEach(item => {
                const bucket = this.mgStorageService?.config?.[item.bucket]?.cdnUrl
                // item.bucket === 'cdn.prooffactor.com' ? 'cdn.one.store' : item.bucket
                const url = `https://${bucket}/${item.fullPath}`
                this.galleryList[index].items.push({
                  ...item,
                  url: url + `?${new Date().getTime()}`,
                  _hidden: false,
                  _selected: false,
                })
              })
              if (this.currentImage) {
                const match = this.mgService.matchByUrl(this.galleryList[index].items, this.currentImage)
                if (match) {
                  this.selectedImg = match
                  // if matched sort to the top
                  this.galleryList[index].items = this.galleryList[index].items.filter(item => item.url !== match.url)
                  this.galleryList[index].items.unshift(match) 
                }
              }
            }
          })
          this.loadContents()
        }
      })
    )
  }

  onSearch(event, clear = false) {
    this.selectedImg = null
    if (clear) {
      this.search = ''
    }
    const value = clear ? null : event.target.value?.trim()?.toLowerCase()
    if (value) {
      this.displayList.forEach(item => item._hidden = !item?.name?.toLowerCase()?.includes(value))
    } else {
      this.displayList.forEach(item => item._hidden = false)
    }
  }

  fileSelected(event) {
    const isValidFile = this.mgUploadService.validateImageFileType(event)
    if (!isValidFile) {
      return
    }
    if (this.userId) {
      this.file = null
      this.subscription.add(
        this.upload(event.target.files[0]).subscribe()
      )
    }
  }

  upload(file: any, overwrite = false): Observable<UploadTaskSnapshot> {
    const metadata = this.mgService.getMetadata(this.activePath)
    const url = this.activePath.map(path => path.url).join('/')
    return this.mgUploadService.upload(
      url, 
      file,
      {customMetadata: metadata},
      this.namePrefix,
      overwrite
    ).pipe(
      tap((data: UploadTaskSnapshot) => {
        const url = `https://${data.ref.bucket === 'cdn.prooffactor.com' ? 'cdn.one.store' : data.ref.bucket}/${data.ref.fullPath}`
        this.currentImage = url
        this.fetchImages()
      })
    )
  }

  onImageClick(image: MgImage) {
    // if (this.modalMode) {
      this.selectedImg = image
      this.markSelected(image)
    // } else {
      // this.imgPreviewSrc = image.url
      // this.previewContainer.clear()
      // this.previewContainer.createEmbeddedView(this.imgPreview)
    // }
  }

  markSelected(image: MgImage) {
    if (image) {
      this.displayList.forEach(item => item._selected = item.url === image.url)
    } else {
      this.displayList.forEach(item => item._selected = false)
    }
  }

  onDragOver(event) {
    event.preventDefault()
    this.isDragOver = true
  }

  onDragLeave(event) {
    event.preventDefault()
    this.isDragOver = false
  }

  onDrop(event) {
    event.preventDefault()
    this.isDragOver = false
  }

  onEditImageClick() {
    this.viewState = MGView.edit
  }
  
  onDeleteImageClick() {
    this.viewState = MGView.delete
  }

  onApplyImageClick() {
    this.imageSelected.emit(this.selectedImg.url)
  }

  switchActive(url: string) {
    this.galleryList.forEach(item => item.active = item.url === url)
    this.loadContents()
  }

  handleEditorProcess(imageWriterResult: PinturaDefaultImageWriterResult, overwrite = true, outputTriggered = false) {
    if (this.saveAsCopyActive && outputTriggered) {
      // since handleEditorProcess being triggered twice, we need to skip the one that comes from the output
      // (happens when clicking the save as copy button)
      return
    }
    this.subscription.add(
      this.upload(imageWriterResult.dest, overwrite).subscribe({
        next: () => {
          this.viewState = MGView.gallery
        }
      })
    )
  }

  onArrowClick(direction: 'prev' | 'next') {
    const currentIndex = this.displayList.findIndex(item => item.url === this.selectedImg.url);
    let nextIndex = direction === 'next' ? currentIndex + 1 : currentIndex - 1

    if (nextIndex < 0) {
      nextIndex = this.displayList.length - 1
    } else if (nextIndex >= this.displayList.length) {
      nextIndex = 0
    }

    this.selectedImg = this.displayList[nextIndex]
    this.markSelected(this.selectedImg)
  }

  onDeleteImage() {
    this.subscription.add(
      this.mgUploadService.deleteFile(this.selectedImg).subscribe({
        next: () => {
          this.displayList = this.displayList.filter(item => !item.url.startsWith(this.selectedImg.url.split('?')[0]))
          this.selectedImg = null
          this.viewState = MGView.gallery
        }
      })
    )
  }

  loadContents() {
    const active = this.galleryList.find(item => item.active)
    this.displayList = active?.items
    this.activePath = active?.path
    this.markSelected(this.selectedImg)
  }

  private getUrl(path: MGPathData[]): string {
    return this.mgService.pathsToUrl(path)
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }
}
