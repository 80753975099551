import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { Subscription } from 'rxjs'
import * as _ from 'lodash'
import { ActivatedRoute, Router } from '@angular/router'
import postscribe from 'postscribe'
import { GettingStartedService } from '../../core/services/getting-started.service'
import { UserService } from '../../core/services/user.service'
import { SimpleLoadingOverlayComponent } from '../../shared/components/simple-loading-overlay/simple-loading-overlay.component'
import { CommonModule } from '@angular/common'
import { RouteHeaderUrl } from '../../shared/components/one-header/header-navigation.model'

@Component({
  templateUrl: './scholarship-form.component.html',
  styleUrls: ['./scholarship-form.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    SimpleLoadingOverlayComponent,
  ]
})

export class ScholarshipFormComponent implements OnInit, OnDestroy {
  @ViewChild('container', { static: true }) container: ElementRef
  @ViewChild('iFrame', {static: true}) iFrameRef: ElementRef

  subscription = new Subscription()

  loading: boolean = true
  iframeCount = 0
  pollCount = 0

  jotFormArr = [
    'https://eu.jotform.com',
    'https://www.jotform.com',
    'https://www.jotform.ca',
    'https://form.jotform.com',
  ]
  formId = '212625444051447'

  constructor(
    private route: ActivatedRoute,
    private router:Router,
    private gettingStartedService: GettingStartedService,
    private userService: UserService,
  ) {
    // If user submitted scholarship application update scholarship status & set them on budding plan
    if (_.get(this.route, 'snapshot.queryParams.application_submitted')) {
      this.subscription.add(
        this.gettingStartedService.updateScholarshipStatusFromQueryParams(_.get(this.route, 'snapshot.queryParams', {})).subscribe(() => {
          this.router.navigate([`/${RouteHeaderUrl.apps}`])
        }, err => {
          console.error('pre-plans: failed to updateScholarshipStatus', err)
        })
      )
    }
  }

  ngOnInit() {
    this.subscription.add(this.userService.readUser().subscribe(userInfo => {
      // if user is from china load form to sandboxed iframe
      if (_.get(userInfo, 'country_code') === 'CN') {
        this.loadForm()
      } else {
        this.loadFormFromEmbedScript()
      }
    }))
  }


  loadFormFromEmbedScript(i = 0) {
    // if all urls fail redirect to referrer/subscriptions page
    if (i === this.jotFormArr.length) {
      this.router.navigateByUrl(_.get(this.route, 'snapshot.queryParams.referrer', '/onboarding/signup/new/subscriptions')).then(() => {
        this.loading = false
      })
    } else {
      postscribe('#scholarship-form', `<script type="text/javascript" src="${this.jotFormArr[i]}/jsform/${this.formId}"></script>`,
        {
          done: () => {
            this.checkIfLoaded(true)
          },
          error: (err) => {
            // in case jotform fails to load try next url
            console.error('scholarship jotform load error', err)
            this.loadFormFromEmbedScript(i + 1)
          }
        })
    }
  }

  checkIfLoaded(setSandbox = false) {
    // if it's been loading for 20+ secs just stop
    if (this.pollCount > 100) {
      this.loading = false
      return
    }
    this.pollCount++
    if (!!document.querySelector('#scholarship-form iframe')) {
      // when iframe is detected set sandbox attribute
      if (this.iframeCount === 0 && setSandbox) {
        document.querySelector('#scholarship-form iframe').setAttribute('sandbox', 'allow-top-navigation allow-scripts allow-popups allow-forms allow-same-origin')
      }
      // this is needed because the actual ui is visible
      // with slight delay after iframe is spotted by the query
      if (this.iframeCount > 7) {
        this.loading = false
        return
      }
      this.iframeCount++
    }
    setTimeout(() => this.checkIfLoaded(), 200)
  }

  loadForm(i = 0) {
    // if all urls fail redirect to referrer/subscriptions page
    if (i === this.jotFormArr.length) {
      this.router.navigateByUrl(_.get(this.route, 'snapshot.queryParams.referrer', '/onboarding/signup/new/subscriptions')).then(() => {
        this.loading = false
      })
    } else {
      postscribe('#scholarship-form', this.getScript(i), {
        done: () => {
          this.checkIfLoaded()
        },
        error: (err) => {
          // in case jotform fails to load try next url
          console.error('scholarship jotform load error', err)
          this.loadForm(i + 1)
        }
      })
    }
  }

  getScript(index) {
    return `<iframe
              id="JotFormIFrame-${this.formId}"
              title="ONE+ Startup Program Application"
              onload="window.parent.scrollTo(0,0)"
              allowtransparency="true"
              allowfullscreen="true"
              allow="geolocation; microphone; camera"
              src="${this.jotFormArr[index]}/${this.formId}"
              frameborder="0"
              style="
              min-width: 100%;
              height:539px;
              border:none;"
              scrolling="no"
              sandbox="allow-top-navigation allow-scripts allow-popups allow-forms allow-same-origin"
            >
            </iframe>
            <script type="text/javascript">
              var ifr = document.getElementById("JotFormIFrame-${this.formId}");
              if (ifr) {
                var src = ifr.src;
                var iframeParams = [];
                if (window.location.href && window.location.href.indexOf("?") > -1) {
                  iframeParams = iframeParams.concat(window.location.href.substr(window.location.href.indexOf("?") + 1).split('&'));
                }
                if (src && src.indexOf("?") > -1) {
                  iframeParams = iframeParams.concat(src.substr(src.indexOf("?") + 1).split("&"));
                  src = src.substr(0, src.indexOf("?"))
                }
                iframeParams.push("isIframeEmbed=1");
                ifr.src = src + "?" + iframeParams.join('&');
              }
              window.handleIFrameMessage = function(e) {
                if (typeof e.data === 'object') { return; }
                var args = e.data.split(":");
                if (args.length > 2) { iframe = document.getElementById("JotFormIFrame-" + args[(args.length - 1)]); } else { iframe = document.getElementById("JotFormIFrame"); }
                if (!iframe) { return; }
                switch (args[0]) {
                  case "scrollIntoView":
                    iframe.scrollIntoView();
                    break;
                  case "setHeight":
                    iframe.style.height = args[1] + "px";
                    break;
                  case "collapseErrorPage":
                    if (iframe.clientHeight > window.innerHeight) {
                      iframe.style.height = window.innerHeight + "px";
                    }
                    break;
                  case "reloadPage":
                    window.location.reload();
                    break;
                  case "loadScript":
                    if( !window.isPermitted(e.origin, ['jotform.com', 'jotform.pro']) ) { break; }
                    var src = args[1];
                    if (args.length > 3) {
                        src = args[1] + ':' + args[2];
                    }
                    var script = document.createElement('script');
                    script.src = src;
                    script.type = 'text/javascript';
                    document.body.appendChild(script);
                    break;
                  case "exitFullscreen":
                    if      (window.document.exitFullscreen)        window.document.exitFullscreen();
                    else if (window.document.mozCancelFullScreen)   window.document.mozCancelFullScreen();
                    else if (window.document.mozCancelFullscreen)   window.document.mozCancelFullScreen();
                    else if (window.document.webkitExitFullscreen)  window.document.webkitExitFullscreen();
                    else if (window.document.msExitFullscreen)      window.document.msExitFullscreen();
                    break;
                }
                var isJotForm = (e.origin.indexOf("jotform") > -1) ? true : false;
                if(isJotForm && "contentWindow" in iframe && "postMessage" in iframe.contentWindow) {
                  var urls = {"docurl":encodeURIComponent(document.URL),"referrer":encodeURIComponent(document.referrer)};
                  iframe.contentWindow.postMessage(JSON.stringify({"type":"urls","value":urls}), "*");
                }
              };
              window.isPermitted = function(originUrl, whitelisted_domains) {
                var url = document.createElement('a');
                url.href = originUrl;
                var hostname = url.hostname;
                var result = false;
                if( typeof hostname !== 'undefined' ) {
                  whitelisted_domains.forEach(function(element) {
                      if( hostname.slice((-1 * element.length - 1)) === '.'.concat(element) ||  hostname === element ) {
                          result = true;
                      }
                  });
                  return result;
                }
              };
              if (window.addEventListener) {
                window.addEventListener("message", handleIFrameMessage, false);
              } else if (window.attachEvent) {
                window.attachEvent("onmessage", handleIFrameMessage);
              }
              </script>`
  }
  ngOnDestroy() {
    this.subscription.unsubscribe()
  }
}
