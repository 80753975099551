<pf-auth-layout *ngIf="!isHidden">
  <ng-container class="auth-title">Welcome to {{brandingName}}</ng-container>
  <ng-container class="auth-content">

    <div class="Login__Box">
      <ng-container *ngIf="!hasSentEmail; else emailSent">
        <ng-container *ngIf="brandingDomain !== brandingDomainEnum.morpheusone">
          <div *ngIf="googleSigninAvailable" class="custom-login-btn _google mb-2" (click)="$event.preventDefault();loginWithGoogle()">
          </div>
          <div class="custom-login-btn _shopify" (click)="$event.preventDefault();loginWithShopify()">
          </div>
          <div class="Login__Separator">
            <span>or</span>
          </div>
        </ng-container>

        <form novalidate [formGroup]="loginForm" *ngIf="!hasSentEmail">
          <div class="form-horizontal">
            <div class="form-group">
              <input name="email" type="email" formControlName="email" placeholder="user@gmail.com" required="required"
                      autocomplete="email" class="form-control">
            </div>
          </div>
          <div *ngIf="errors.length">
            <div class="alert alert-danger">
              <div *ngFor="let error of errors">{{ error }}</div>
            </div>
          </div>
          <button class="pf-button fw filled blue mb-4"
                  (click)="loginWithEmailLink($event)">
            Login
          </button>
        </form>
      </ng-container>
      <ng-template #emailSent>
        <div class="text-center">
          <p class="my-2">Log-in link sent to</p>
          <h6 class="my-2">«{{ loginForm.value.email || 'your email' }}»</h6>
          <p class="my-2">Please check your mailbox</p>
        </div>
      </ng-template>

      <!-- TODO commented for now, might bring back with captcha -->
      <!-- <form class="Login__Box" novalidate (submit)="loginWithEmail($event)" [formGroup]="loginForm">
        <div class="form-horizontal">
          <div class="form-group row">
            <label class="col-sm-3 form-control-label text-sm-right mt-sm-2 pr-sm-0">Email</label>
            <div class="col-sm-9">
              <input class="form-control" name="email" type="email" formControlName="email" placeholder="user@gmail.com"
                     autocomplete="email">
              <div class="alert alert-danger"
                   *ngIf="loginForm.controls['email'].invalid && (loginForm.controls['email'].dirty || loginForm.controls['email'].touched)">
                <div *ngIf="loginForm.controls['email'].errors['email']">The value does not match email format.</div>
                <div *ngIf="loginForm.controls['email'].errors['required']">The value is required</div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-3 form-control-label text-sm-right mt-sm-2 pr-sm-0">Password</label>
            <div class="col-sm-9">
              <input class="form-control" name="password" type="password" formControlName="password"
                     placeholder="**************"
                     autocomplete="new-password">
            </div>
          </div>
        </div>
        <div *ngIf="errors.length">
          <div class="alert alert-danger">
            <div *ngFor="let error of errors">{{ error }}</div>
          </div>
        </div>
        <button type="submit" class="pf-button filled blue fw mb-4" [disabled]="loginForm.invalid || isLoading">
          <ng-container *ngIf="!isLoading">Login</ng-container>
          <ng-container *ngIf="isLoading">Waiting...</ng-container>
        </button>
      </form> -->
      <ng-container *ngIf="brandingDomain !== brandingDomainEnum.morpheusone">
        <!-- <p>Trouble logging in? <a routerLink="/reset">Reset your password</a></p> -->
        <ng-container *ngIf="!hasSentEmail; else emailSentBtn">
          <p *ngIf="!hasSentEmail">Don't have an account? <a routerLink="/register">Sign up here</a></p>
        </ng-container>
        <ng-template #emailSentBtn>
          <p *ngIf="hasSentEmail"><a routerLink="/login" (click)="hasSentEmail = false">Back</a></p>
        </ng-template>
        <p>Don't have email access? <a routerLink="/login/with-password" (click)="hasSentEmail = false">Login with password</a></p>
      </ng-container>
    </div>

  </ng-container>
</pf-auth-layout>

<pf-loading-overlay></pf-loading-overlay>
