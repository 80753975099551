<pf-loading-overlay [filter]="{only: ['UpsellSubmit']}"></pf-loading-overlay>

<section>
  <div class="headline">
    <h3 class="mb-0">Offer Details</h3>
  </div>
  <div class="content" [formGroup]="form" *ngIf="form" #formRef pfScrollInvalidInput [scrollContainer]="'.main-content-wrapper'">
    <div class="content-row" *ngIf="form.get('name'); let control">
      <fieldset class="config-control">
        <label>Offer Name</label>
        <input type="text"
               class="form-control _local-has-tooltip"
               placeholder="e.g. Upsell [Product]"
               formControlName="name"/>
        <span class="Tooltip _local-input-tooltip" matTooltipClass="Tooltip-Box" matTooltipPosition="above"
            matTooltip="Name is for your own reference, your customers won’t see this.">
        </span>
        <ng-container *ngIf="control.errors && control.dirty">
          <small class="text-danger mb-1" *ngIf="control.errors.required">
            This field is required
          </small>
          <small class="text-danger mb-1" *ngIf="control.errors.maxlength">
            Must be less than {{ control.errors.maxlength.requiredLength }} characters
          </small>
          <small class="text-danger mb-1" *ngIf="control?.errors['space_only']">
            The value must contain something other than space
          </small>
        </ng-container>
      </fieldset>
    </div>

    <div class="content-row" *ngIf="form.get('trigger'); let control">
      <fieldset class="config-control">
        <label>Trigger Type</label>
        <ng-select
          bindValue="value"
          bindLabel="label"
          [clearable]="false"
          [searchable]="false"
          formControlName="trigger"
          class="h-46"
          [items]="triggerTypeOptions"
        ></ng-select>
        <small>{{triggerTypeDescription}}</small>
      </fieldset>
    </div>

    <div class="content-row">
      <ng-container [ngSwitch]="form.get('trigger').value">
        <div *ngSwitchCase="triggerType.Collections" class="form-group">
          <ng-container *ngIf="form.get('trigger_collections') as control">
            <div class="d-flex flex-column align-items-start">
              <button class="pf-button filled blue mb-2" [disabled]="!isRootVariant"
                      (click)="onShowTriggerCollectionSelectorClick()">
                Select Collections
              </button>
              <small *ngIf="control.dirty && control.errors" class="text-danger mb-2">Please select at least one collection</small>
            </div>
            <pf-products-output
              [productsListService]="productsListService"
              [setSelection]="triggerCollectionSelection"
              [emptyText]="'No Collection(s) Selected'"
              [noValidate]="true"
              [customClass]="control.dirty && control.errors ? 'ng-invalid has-error h-182' : 'h-182'"
              [clearable]="true"
              (change)="updateTriggerCollectionSelection($event)"
            ></pf-products-output>
          </ng-container>
        </div>
        <div *ngSwitchCase="triggerType.Products" class="form-group">
          <ng-container *ngIf="form.get('trigger_products') as control">
            <div class="d-flex flex-column align-items-start">
              <button class="pf-button filled blue mb-2" [disabled]="!isRootVariant"
                      (click)="onShowTriggerProductSelectorClick()">
                Select Products
              </button>
              <small *ngIf="control.dirty && control.errors" class="text-danger mb-2">Please select at least one product</small>
            </div>
            <pf-products-output
              [productsListService]="productsListService"
              [setSelection]="triggerProductSelection"
              [emptyText]="'No Product(s) Selected'"
              [noValidate]="true"
              [customClass]="control.dirty && control.errors ? 'ng-invalid has-error h-182' : 'h-182'"
              [clearable]="true"
              (change)="updateTriggerProductSelection($event)"
            ></pf-products-output>
          </ng-container>
        </div>
      </ng-container>
    </div>

    <div class="content-row">
      <div class="form-group">
        <label class="mb-0">Upsell Offer</label>
        <p class="mb-2">
          <small>Select which product to upsell or cross sell.</small>
        </p>
        <ng-container *ngIf="form.get('target_products') as control">
          <div class="d-flex flex-column align-items-start">
            <button class="pf-button filled blue mb-2"
                    (click)="onShowTargetProductSelectorClick()">
              Select Product
            </button>
            <small *ngIf="control.dirty && control.errors" class="text-danger mb-2">Please select a product</small>
          </div>
          <pf-products-output
            [productsListService]="productsListService"
            [setSelection]="targetProductSelection"
            [emptyText]="'No Product Selected'"
            [noValidate]="true"
            [customClass]="control.dirty && control.errors ? 'ng-invalid has-error h-182' : 'h-182'"
            [hidePagination]="true"
            [showOutOfStock]="true"
            [clearable]="true"
            [displayQuantity]="true"
            (change)="updateTargetProductSelection($event)"
          ></pf-products-output>
        </ng-container>
      </div>
    </div>

    <div class="content-row mt-3" *ngIf="form.get('no_repeat'); let control">
      <fieldset class="form-group">
        <label class="d-flex align-items-center">
          <mat-checkbox formControlName="no_repeat" (change)="showRepeatErrorIfNeeded()" class="mr-2"></mat-checkbox>
          Skip upsell if your customer just purchased the same product
        </label>
        <small *ngIf="skipError" class="text-danger mb-2">Trigger and upsell products are the same</small>
      </fieldset>
    </div>

    <div class="content-row" *ngIf="form.get('discounted'); let control">
      <fieldset class="form-group">
        <label class="d-flex align-items-center">
          Give Discount on Upsell Offer
          <pf-switch-box formControlName="discounted"
            class="ml-2"
            [labelInside]="true"
            (change)="updateDiscountValidators(true)"
            labelOff="Off"
          ></pf-switch-box>
        </label>
      </fieldset>
    </div>

    <ng-container *ngIf="form.get('discounted').value">
      <div class="content-row" *ngIf="form.get('discount_type'); let control">
        <fieldset class="config-control">
          <label>Discount Type</label>
          <ng-select
            bindValue="value"
            bindLabel="label"
            [clearable]="false"
            [searchable]="false"
            formControlName="discount_type"
            class="h-46"
            [items]="discountTypeOptions"
            (change)="updateDiscountValidators(true)"
          ></ng-select>
        </fieldset>
      </div>

      <div class="content-row" *ngIf="form.get('discount_value'); let control">
        <fieldset class="config-control">
          <label>Discount Value:</label>
          <div class="input-group">
            <input type="number" min="0"
                  class="form-control"
                  formControlName="discount_value"
                  (click)="onNumberInputClick($event)"
                  (focusout)="checkIfProductIsFree()">
            <div class="input-group-append">
              <span class="input-group-text" *ngIf="form.get('discount_type').value === discountType.Percentage">%</span>
              <span class="input-group-text" *ngIf="form.get('discount_type').value === discountType.Fixed">{{userCurrencySymbol}}</span>
            </div>
          </div>
          <small class="text-muted" *ngIf="form.get('discount_type').value === discountType.Fixed">
            Discount will be applied to each upsell item.
          </small>
          <ng-container *ngIf="control.errors && control.dirty">
            <small class="text-danger mb-1" *ngIf="control.errors.required">
              This field is required
            </small>
            <small class="text-danger mb-1" *ngIf="control.errors.min">
              The value must be greater than 0
            </small>
            <small class="text-danger mb-1" *ngIf="control.errors.max">
              The value must be less than {{ control.errors.max?.max }}
            </small>
          </ng-container>
        </fieldset>
      </div>
    </ng-container>

  </div>
</section>
