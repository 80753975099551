import { Injectable } from '@angular/core'
import { ProofFactorLanguageService } from '../../../../proof-factor-language-service/proof-factor-language-service'

@Injectable()
export class LanguageService {
  proofFactorLanguageService: ProofFactorLanguageService

  constructor() {
    this.proofFactorLanguageService = new ProofFactorLanguageService()
  }

  getTimeAgoValue(value, locale?): string {
    return this.proofFactorLanguageService.getTimeAgoValue(value, locale)
  }

  getProofFactorValue(value, locale = 'en'): string {
    return this.proofFactorLanguageService.getProofFactorValue(value, locale)
  }

  registerLocale(locale: string): Promise<any> {
    return this.proofFactorLanguageService.registerLocale(locale)
  }
}
