import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { of } from 'rxjs'
import { catchError, map, switchMap, tap } from 'rxjs/operators'

import {
  CreateFortuneWheelIntegrationRequest,
  CreateFortuneWheelIntegrationFailure,
  CreateFortuneWheelIntegrationSuccess,
  CreateCouponBoxIntegrationFailure,
  CreateCouponBoxIntegrationRequest,
  CreateCouponBoxIntegrationSuccess,
  CreateIntegrationRequest,
  CreateIntegrationFailure,
  CreateIntegrationSuccess,
  ReadAllIntegrationsFailure,
  ReadAllIntegrationsSuccess,
  ReadIntegrationListsRequest,
  ReadIntegrationListsFailure,
  ReadIntegrationListsSuccess,
  DeleteIntegrationRequest,
  DeleteIntegrationFailure,
  DeleteIntegrationSuccess,
  DeleteFortuneWheelIntegrationRequest,
  DeleteFortuneWheelIntegrationFailure,
  DeleteFortuneWheelIntegrationSuccess,
  DeleteCouponBoxIntegrationRequest,
  DeleteCouponBoxIntegrationSuccess,
  DeleteCouponBoxIntegrationFailure,
  ReadAllIntegrationsRequest,
  CreateMultipleIntegrationsRequest,
  CreateMultipleIntegrationsSuccess,
  CreateMultipleIntegrationsFailure,
  DeleteNewsletterIntegrationRequest,
  DeleteNewsletterIntegrationFailure,
  DeleteNewsletterIntegrationSuccess,
  CreateNewsletterIntegrationRequest,
  CreateNewsletterIntegrationSuccess,
  CreateNewsletterIntegrationFailure,
} from './mail-integration.actions'
import { MailIntegrationActionTypes } from './mail-integration.action-types'
import { ReadCampaignRequest } from '../campaign/campaign.actions'
import _ from 'lodash'
import { IntegrationsService } from '../../core/services/integrations.service'
import { ErrorsService } from '../../core/services/errors.service'
import { MatDialog } from '@angular/material/dialog'

@Injectable()
export class MailIntegrationEffects {
  constructor(
    private actions$: Actions,
    private integrationService: IntegrationsService,
    private errorService: ErrorsService,
    private dialog: MatDialog
  ) {}

  readAllIntegrationsRequest$ = createEffect(() => this.actions$.pipe(
    ofType(MailIntegrationActionTypes.READ_ALL_INTEGRATIONS_REQUEST),
    switchMap(() =>
      this.integrationService
        .getAllIntegrations()
        .pipe(
          map(data => new ReadAllIntegrationsSuccess(data)),
          catchError(err => of(new ReadAllIntegrationsFailure(this.errorService.extractError(err)))),
        ),
    ),
  ))

  createIntegrationRequest$ = createEffect(() => this.actions$.pipe(
    ofType(MailIntegrationActionTypes.CREATE_INTEGRATION_REQUEST),
    switchMap((action: CreateIntegrationRequest) =>
      this.integrationService
        .createIntegration(action.payload)
        .pipe(
          map(data => new CreateIntegrationSuccess(data)),
          tap(() => this.dialog.closeAll()),
          catchError(err => of(new CreateIntegrationFailure(this.errorService.extractError(err)))),
        ),
    ),
  ))

  deleteIntegrationRequest$ = createEffect(() => this.actions$.pipe(
    ofType(MailIntegrationActionTypes.DELETE_INTEGRATION_REQUEST),
    switchMap((action: DeleteIntegrationRequest) => {
      return this.integrationService
        .removeIntegration(action.payload.id, action.payload.typeOfIntegrationEntity)
        .pipe(
          map(() => new DeleteIntegrationSuccess(action.payload.id)),
          tap(() => this.dialog.closeAll()),
          catchError(err => of(new DeleteIntegrationFailure(this.errorService.extractError(err)))),
        )
      }
    ),
  ))

  deleteFortuneWheelIntegrationRequest$ = createEffect(() => this.actions$.pipe(
    ofType(MailIntegrationActionTypes.DELETE_FORTUNE_WHEEL_INTEGRATION_REQUEST),
    switchMap((action: DeleteFortuneWheelIntegrationRequest) =>
      this.integrationService
        .removeLinkedIntegration(action.payload)
        .pipe(
          switchMap(data => [
            new DeleteFortuneWheelIntegrationSuccess(data),
            new ReadCampaignRequest(action.payload.id),
            new ReadAllIntegrationsRequest(),
          ]),
          tap(() => this.dialog.closeAll()),
          catchError(err => of(new DeleteFortuneWheelIntegrationFailure(this.errorService.extractError(err)))),
        ),
    ),
  ))

  deleteCouponBoxIntegrationRequest$ = createEffect(() => this.actions$.pipe(
    ofType(MailIntegrationActionTypes.DELETE_COUPON_BOX_INTEGRATION_REQUEST),
    switchMap((action: DeleteCouponBoxIntegrationRequest) =>
      this.integrationService
        .removeLinkedIntegrationCouponBox(action.payload)
        .pipe(
          switchMap(data => [
            new DeleteCouponBoxIntegrationSuccess(data),
            new ReadCampaignRequest(action.payload.id),
            new ReadAllIntegrationsRequest(),
          ]),
          tap(() => this.dialog.closeAll()),
          catchError(err => of(new DeleteCouponBoxIntegrationFailure(this.errorService.extractError(err)))),
        ),
    ),
  ))

  deleteNewsletterIntegrationRequest$ = createEffect(() => this.actions$.pipe(
    ofType(MailIntegrationActionTypes.DELETE_NEWSLETTER_INTEGRATION_REQUEST),
    switchMap((action: DeleteNewsletterIntegrationRequest) =>
      this.integrationService
        .removeLinkedIntegrationNewsletter(action.payload)
        .pipe(
          switchMap(data => [
            new DeleteNewsletterIntegrationSuccess(data),
            new ReadCampaignRequest(action.payload.id),
            new ReadAllIntegrationsRequest(),
          ]),
          tap(() => this.dialog.closeAll()),
          catchError(err => of(new DeleteNewsletterIntegrationFailure(this.errorService.extractError(err)))),
        ),
    ),
  ))

  readIntegrationListsRequest$ = createEffect(() => this.actions$.pipe(
    ofType(MailIntegrationActionTypes.READ_INTEGRATION_LISTS_REQUEST),
    switchMap((action: ReadIntegrationListsRequest) =>
      this.integrationService
        .getIntegrationLists(action.payload)
        .pipe(
          map(data => new ReadIntegrationListsSuccess(data)),
          catchError(err => of(new ReadIntegrationListsFailure(this.errorService.extractError(err)))),
        ),
    ),
  ))

  createFortuneWheelIntegrationRequest$ = createEffect(() => this.actions$.pipe(
    ofType(MailIntegrationActionTypes.CREATE_FORTUNE_WHEEL_INTEGRATION_REQUEST),
    switchMap((_action: CreateFortuneWheelIntegrationRequest) => {
      const action = _.cloneDeep(_action)
      const typeOfIntegrationEntity = action.payload.typeOfIntegrationEntity;
      delete action.payload.typeOfIntegrationEntity;

      return this.integrationService
        .addIntegrationToCampaign(action.payload, typeOfIntegrationEntity)
        .pipe(
          switchMap(data => [
            new CreateFortuneWheelIntegrationSuccess(data),
            new ReadCampaignRequest(action.payload.id),
            new ReadAllIntegrationsRequest(),
          ]),
          catchError(err => of(new CreateFortuneWheelIntegrationFailure(this.errorService.extractError(err)))),
        )}),
  ))

  createCouponBoxIntegrationRequest$ = createEffect(() => this.actions$.pipe(
    ofType(MailIntegrationActionTypes.CREATE_COUPON_BOX_INTEGRATION_REQUEST),
    switchMap((_action: CreateCouponBoxIntegrationRequest) => {
      const action = _.cloneDeep(_action)
      const typeOfIntegrationEntity = action.payload.typeOfIntegrationEntity;
      delete action.payload.typeOfIntegrationEntity;

      return this.integrationService
        .addIntegrationToCampaignCouponBox(action.payload, typeOfIntegrationEntity)
        .pipe(
          switchMap(data => [
            new CreateCouponBoxIntegrationSuccess(data),
            new ReadCampaignRequest(action.payload.id),
            new ReadAllIntegrationsRequest(),
          ]),
          catchError(err => of(new CreateCouponBoxIntegrationFailure(this.errorService.extractError(err)))),
        )
    }),
  ))

  createNewsletterIntegrationRequest$ = createEffect(() => this.actions$.pipe(
    ofType(MailIntegrationActionTypes.CREATE_NEWSLETTER_INTEGRATION_REQUEST),
    switchMap((_action: CreateNewsletterIntegrationRequest) => {
      const action = _.cloneDeep(_action)
      const typeOfIntegrationEntity = action.payload.typeOfIntegrationEntity;
      delete action.payload.typeOfIntegrationEntity;

      return this.integrationService
        .addIntegrationToCampaignNewsletter(action.payload, typeOfIntegrationEntity)
        .pipe(
          switchMap(data => [
            new CreateNewsletterIntegrationSuccess(data),
            new ReadCampaignRequest(action.payload.id),
            new ReadAllIntegrationsRequest(),
          ]),
          catchError(err => of(new CreateNewsletterIntegrationFailure(this.errorService.extractError(err)))),
        )
    }),
  ))

  createMultipleIntegrationsRequest$ = createEffect(() => this.actions$.pipe(
    ofType(MailIntegrationActionTypes.CREATE_MULTIPLE_INTEGRATIONS_REQUEST),
    switchMap((_action: CreateMultipleIntegrationsRequest) => {
      const action = _.cloneDeep(_action)
      const typeOfIntegrationEntity = action.payload.typeOfIntegrationEntity;
      delete action.payload.typeOfIntegrationEntity;

      return this.integrationService
        .addIntegrationsToCampaign(action.payload.campaignId, action.payload.integrations, typeOfIntegrationEntity)
        .pipe(
          switchMap(data => [
            new CreateMultipleIntegrationsSuccess(data),
            new ReadCampaignRequest(action.payload.campaignId),
            new ReadAllIntegrationsRequest(),
          ]),
          catchError(err => of(new CreateMultipleIntegrationsFailure(this.errorService.extractError(err)))),
        )
    }),
  ))
}
