import { Injectable, signal } from '@angular/core'

@Injectable({providedIn: 'root'})

export class PageTitleService {
  titleVisible = signal(true)
  backBtnVisible = signal(true)

  constructor() { }

  hideTitle(): void {
    this.titleVisible.update(() => false) 
  }

  showTitle(): void {
    this.titleVisible.update(() => true)
  }

  hideBackBtn(): void {
    this.backBtnVisible.update(() => false)
  }

  showBackBtn(): void {
    this.backBtnVisible.update(() => true)
  }
  
}
