import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { CouponBoxPreviewModule } from '../plugins/coupon-box-plugin/preview/coupon-box-preview.module'
import { CouponBoxGiftCardsResolverService } from '../plugins/coupon-box-plugin/services/coupon-box-gift-cards.resolver'
import { CouponBoxService } from '../plugins/coupon-box-plugin/services/coupon-box.service'
import { FortuneWheelPreviewModule } from '../plugins/fortune-wheel/preview/fortune-wheel-preview.module'
import { QuickSetupComponent } from './quick-setup.component'
import { QuickSetupCouponBoxComponent } from './components/coupon-box/quick-setup-coupon-box.component'
import { NewsletterService } from '../plugins/newsletter-plugin/services/newsletter.service'
import { FortuneWheelService } from '../plugins/fortune-wheel/services/fortune-wheel.service'
import { QuickSetupFortuneWheelComponent } from './components/fortune-wheel/quick-setup-fortune-wheel.component'
import { QuickSetupNewsletterComponent } from './components/newsletter/quick-setup-newsletter.component'
import { QuickSetupService } from './services/quick-setup.service'
import { QuickSetupInputsComponent } from './components/steps/inputs/quick-setup-inputs.component'
import { QuickSetupEndComponent } from './components/steps/end/quick-setup-end.component'
import { PreviewService } from '../services/preview.service'
import { QuickSetupStartComponent } from './components/steps/start/quick-setup-start.component'
import { MarketingEmailsApiService } from '../../../shared/modules/marketing-emails/services/marketing-emails-api.service'
import { ShopifySubscribersApiService } from '../../subscribers/services/shopify-subscribers-api.service'
import { RewardsService } from '../../../core/services/rewards-service.service'
import { ApiService } from '../../../core/services/api/api.service'
import { RouterModule } from '@angular/router'
import { EmailEditorApiService } from '../../email-builder/services/email-editor-api.service'
import { OneConfettiDirective } from '../../../shared/directives/one-confetti.directive'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RewardSelectComponent } from '../../../shared/components/controls/reward-select/reward-select.component'
import { ThemeCarouselComponent } from '../../../shared/components/theme-carousel/theme-carousel.component'
import { NewsletterPreviewModule } from '../plugins/newsletter-plugin/preview/newsletter-preview.module'
import { LoadingOverlayComponent } from '../../../shared/components/loading-overlay/loading-overlay.component'
import { OneIconComponent } from '../../../shared/components/one-icon/one-icon.component'
import { BreadcrumbsComponent } from '../../../shared/components/breadcrumbs/breadcrumbs.component'
import { MatTooltipModule } from '@angular/material/tooltip'

@NgModule({
  imports: [
    CommonModule,
    OneConfettiDirective,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    RewardSelectComponent,
    CouponBoxPreviewModule,
    FortuneWheelPreviewModule,
    BreadcrumbsComponent,
    ThemeCarouselComponent,
    NewsletterPreviewModule,
    LoadingOverlayComponent,
    OneIconComponent,
    MatTooltipModule,
  ],
  exports: [],
  declarations: [
    QuickSetupComponent,
    QuickSetupCouponBoxComponent,
    QuickSetupFortuneWheelComponent,
    QuickSetupNewsletterComponent,
    QuickSetupInputsComponent,
    QuickSetupEndComponent,
    QuickSetupStartComponent,
  ],
  providers: [
    QuickSetupService,
    CouponBoxService,
    NewsletterService,
    FortuneWheelService,
    PreviewService,
    ApiService,
    RewardsService,
    EmailEditorApiService,
    MarketingEmailsApiService,
    ShopifySubscribersApiService,
    CouponBoxGiftCardsResolverService,
  ],
})
export class QuickSetupModule { }
