<div class="content">
  <div class="preview">
    <pf-upsell-preview [data]="previewParams" [minutes]="previewParams?.timer" class="h-100"></pf-upsell-preview>
  </div>
  <div class="controls" [formGroup]="form" #formRef pfScrollInvalidInput [scrollContainer]="'.controls'">
    <!-- Display Rule -->
    <div class="config">
      <div class="config-head _hoverable"
            [class._closed]="!sectionOpen.placement"
            (click)="sectionOpen.placement = !sectionOpen.placement">
        <one-icon class="mr-2" name="chevron-down" [class._rotated]="sectionOpen.placement"></one-icon>
        Display Rule
      </div>

      <div class="config-body" [@expandCollapse]="sectionOpen.placement">
        <div *ngIf="sectionOpen.placement">
          <div class="config-control" *ngIf="form.get('placement'); let control">
            <label>Show Upsell Page</label>
            <ng-select
              bindValue="value"
              bindLabel="label"
              [clearable]="false"
              class="h-46"
              formControlName="placement"
              [items]="placementOptions"
            ></ng-select>
            <small>
              Checkout Page >
              <span
                matTooltip="Customers can accept with one-click, no need to re-enter payment information."
                matTooltipClass="Tooltip-Box"
                matTooltipPosition="above"
                class="Tooltip-Dots">
                <strong>ONE Upsell Page</strong>
              </span>
              > Thank You Page
            </small>
          </div>
        </div>
      </div>
    </div>

    <!-- Language Selector -->
    <div class="config">
      <div class="config-head _hoverable"
            [class._closed]="!sectionOpen.language"
            (click)="sectionOpen.language = !sectionOpen.language">
        <one-icon class="mr-2" name="chevron-down" [class._rotated]="sectionOpen.language"></one-icon>
        <div class="d-flex aligh-items-center justify-content-between w-100">
          System Generated Text
          <span class="Tooltip"
            matTooltipClass="Tooltip-Box"
            matTooltipPosition="above"
            matTooltip="System generated text (subtotal, shipping, taxes, pay now, etc.) will be translated into the selected language. Other text (banner and countdown title) can be edited manually."
          ></span>
        </div>
      </div>

      <div class="config-body" [@expandCollapse]="sectionOpen.language">
        <div *ngIf="sectionOpen.language">
          <div class="config-control" *ngIf="form.get('locale'); let control">
            <label>Language</label>
            <ng-select
              bindValue="value"
              bindLabel="title"
              [clearable]="false"
              [searchable]="false"
              formControlName="locale"
              class="h-46"
              [items]="languageOptions"
              appendTo="body"
              (change)="updatePreviewParams('locale')"
            ></ng-select>
          </div>

          <div class="Translation-Examples" *ngIf="form.get('locale').value !== 'en'">
            <div class="ExampleItem">
              <div class="ExampleItem__Code">Pay Now</div>
              <div class="ExampleItem__Result">{{ 'Pay Now' | translateUpsell:(form.get('locale')?.value || 'en')}}</div>
              <img src="/app_assets/icons/change-icon.svg" alt="">
            </div>
          </div>

          <div class="mt-2">
            <small>
              Header and footer text
              (you've paid for your order, view order confirmation, all rights reserved)
              are directly controlled by your Shopify Settings for languages.
            </small>
          </div>
        </div>
      </div>
    </div>
    <!-- Callout Banner -->
    <div class="config">
      <div class="config-head _hoverable"
            [class._closed]="!sectionOpen.banner"
            (click)="sectionOpen.banner = !sectionOpen.banner">
        <one-icon class="mr-2" name="chevron-down" [class._rotated]="sectionOpen.banner"></one-icon>
        Callout Banner
      </div>
      <div class="config-body" [@expandCollapse]="sectionOpen.banner">
        <div *ngIf="sectionOpen.banner">
          <div class="config-control" *ngIf="form.get('banner_title'); let control">
            <label>Title</label>
            <input
              oneAI
              [topLabelMargin]="2"
              formControlName="banner_title"
              type="text"
              class="form-control"
              (aiText)="updatePreviewParams('banner_title')"
              (input)="updatePreviewParams('banner_title')">
            <ng-container *ngIf="control.errors && control.dirty">
              <small *ngIf="control?.errors['required']" class="text-danger">
                Banner title is required
              </small>
              <small *ngIf="control?.errors['maxlength']" class="text-danger">
                Must be less than {{ control.errors.maxlength.requiredLength }} characters
              </small>
              <small *ngIf="control?.errors['space_only']" class="text-danger">
                The value must contain something other than space
              </small>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <!-- Countdown Timer -->
    <div class="config">
      <div class="config-head _hoverable"
            [class._closed]="!sectionOpen.countdown"
            (click)="sectionOpen.countdown = !sectionOpen.countdown && form.get('timer_enabled')?.value">
        <one-icon class="mr-2" name="chevron-down" [class._rotated]="sectionOpen.countdown"></one-icon>
        <div class="d-flex align-items-center justify-content-between w-100">
          Countdown Timer
          <div *ngIf="form.get('timer_enabled'); let control">
            <pf-switch-box formControlName="timer_enabled"
              [labelInside]="true"
              (change)="onCountdownTimerToggle($event)"
              labelOff="Off"
            ></pf-switch-box>
          </div>
        </div>
      </div>
      <div class="config-body" [@expandCollapse]="sectionOpen.countdown">
        <ng-container *ngIf="sectionOpen.countdown && form.get('timer_enabled')?.value">
          <div class="config-control" *ngIf="form.get('timer_text'); let control">
            <label>Text</label>
            <input
              oneAI
              [topLabelMargin]="2"
              formControlName="timer_text"
              type="text"
              class="form-control"
              (aiText)="updatePreviewParams('timer_text')"
              (input)="updatePreviewParams('timer_text')">
            <ng-container *ngIf="control.errors && control.dirty">
              <small *ngIf="control?.errors['required']" class="text-danger">
                Countdown title is required
              </small>
              <small *ngIf="control?.errors['maxlength']" class="text-danger">
                Must be less than {{ control.errors.maxlength.requiredLength }} characters
              </small>
              <small *ngIf="control?.errors['space_only']" class="text-danger">
                The value must contain something other than space
              </small>
            </ng-container>
          </div>

          <div class="config-control" *ngIf="form.get('timer'); let control">
            <label>Time</label>
            <div class="input-group">
              <input
                class="form-control"
                formControlName="timer"
                type="number"  min="1" max="10"
                (input)="updatePreviewParams('timer')">
              <div class="input-group-append">
                <span class="input-group-text">Mins</span>
              </div>
            </div>
            <ng-container *ngIf="control.errors && control.dirty">
              <small *ngIf="control?.errors['required']" class="text-danger">
                Timer is required
              </small>
              <small class="text-danger mb-1" *ngIf="control.errors.min">
                The value must be greater than 0
              </small>
              <small class="text-danger mb-1" *ngIf="control.errors.max">
                Value is over limit ({{ control.errors.max?.max }} mins)
              </small>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>

    <!-- Image View -->
    <div class="config">
      <div class="config-head _hoverable"
            [class._closed]="!sectionOpen.image"
            (click)="sectionOpen.image = !sectionOpen.image">
        <one-icon class="mr-2" name="chevron-down" [class._rotated]="sectionOpen.image"></one-icon>
        Product Image
      </div>

      <div class="config-body" [@expandCollapse]="sectionOpen.image">
        <div *ngIf="sectionOpen.image">
          <div class="config-control" *ngIf="form.get('image_view'); let control">
            <label>Layout</label>
            <ng-select
              bindValue="value"
              bindLabel="label"
              [clearable]="false"
              [searchable]="false"
              class="h-46"
              formControlName="image_view"
              [items]="imageViewOptions"
              (change)="updatePreviewParams('image_view')"
              appendTo="body"
            ></ng-select>
            <small>
              Arrow and Carousel layouts will only be applied if the product has multiple images
            </small>
          </div>
        </div>
      </div>
    </div>

    <!-- Fulfillment Hold Timer -->
    <div class="config" *ngIf="!form.get('timer_enabled')?.value">
      <div class="config-head _hoverable"
            [class._closed]="!sectionOpen.hold_timer"
            (click)="sectionOpen.hold_timer = !sectionOpen.hold_timer">
        <one-icon class="mr-2" name="chevron-down" [class._rotated]="sectionOpen.hold_timer"></one-icon>
        Fulfillment Hold Timer (Advanced)
      </div>
      <div class="config-body" [@expandCollapse]="sectionOpen.hold_timer">
        <div *ngIf="sectionOpen.hold_timer">
          <p class="config-description mt-3">
            If the customer sees an upsell offer and doesn't do anything (or closes the browser),
            then Shopify places a fulfillment hold on the initial order for 1 hour.
            This gives customers time to complete the upsell flow.
          </p>
          <p class="config-description">
            To lift the hold earlier, enable the below option.
          </p>
          <div class="config-control" *ngIf="form.get('hold_timer_enabled'); let control">
            <fieldset class="d-flex align-items-center justify-content-between">
              <span class="switchbox-label">
                Force-lift fulfillment hold
              </span>
              <pf-switch-box formControlName="hold_timer_enabled"
                [labelInside]="true"
                labelOff="Off"
              ></pf-switch-box>
            </fieldset>
          </div>

          <ng-container *ngIf="form.get('hold_timer_enabled')?.value">
            <div class="config-control" *ngIf="form.get('hold_timer_value'); let control">
              <label>Hold Timer</label>
              <ng-select
                bindValue="value"
                bindLabel="label"
                [clearable]="false"
                [searchable]="false"
                formControlName="hold_timer_value"
                class="h-46"
                [items]="holdTimerOptions"
                appendTo="body"
              ></ng-select>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <!-- Product Title -->
    <div class="config">
      <div class="config-head _hoverable"
        [class._closed]="!sectionOpen.title"
        (click)="sectionOpen.title = !sectionOpen.title">
        <one-icon class="mr-2" name="chevron-down" [class._rotated]="sectionOpen.title"></one-icon>
        Product Title
      </div>
      <div class="config-body" [@expandCollapse]="sectionOpen.title">
        <div *ngIf="sectionOpen.title" class="config-control">
          <label>Title Format</label>
          <input
            value="Original Title"
            type="text"
            class="form-control"
            disabled="true"/>
          <span class="Tooltip _local-input-tooltip"
                matTooltipClass="Tooltip-Box"
                matTooltipPosition="above"
                matTooltip="Shopify doesn't allow editing the product title">
          </span>
          <small>
            Whole product title will be shown
          </small>
        </div>
      </div>
    </div>

    <!-- Product Price -->
    <div class="config">
      <div class="config-head _hoverable"
            [class._closed]="!sectionOpen.savings"
            (click)="sectionOpen.savings = !sectionOpen.savings">
        <one-icon class="mr-2" name="chevron-down" [class._rotated]="sectionOpen.savings"></one-icon>
        Product Price
      </div>
      <div class="config-body" [@expandCollapse]="sectionOpen.savings">
        <div *ngIf="sectionOpen.savings">
          <div class="config-control" *ngIf="form.get('label'); let control">
            <fieldset class="d-flex align-items-center justify-content-between">
              <span class="switchbox-label">
                Show Savings Label
              </span>
              <pf-switch-box formControlName="label"
                [labelInside]="true"
                (change)="onSavingsLabelToggle($event)"
                labelOff="Off"
              ></pf-switch-box>
            </fieldset>
          </div>
        </div>
      </div>
    </div>

    <!-- Product Description -->
    <div class="config">
      <div class="config-head _hoverable"
            [class._closed]="!sectionOpen.description"
            (click)="sectionOpen.description = !sectionOpen.description">
        <one-icon class="mr-2" name="chevron-down" [class._rotated]="sectionOpen.description"></one-icon>
        Product Description
      </div>
      <div class="config-body" [@expandCollapse]="sectionOpen.description">
        <div *ngIf="sectionOpen.description">
          <div class="config-control" *ngIf="form.get('description_format'); let control">
            <label>Description Format</label>
            <ng-select
              bindValue="value"
              bindLabel="label"
              [clearable]="false"
              [searchable]="false"
              formControlName="description_format"
              class="h-46"
              [items]="descriptionFormatOptions"
              appendTo="body"
              (change)="onDescriptionFormatChange()"
            ></ng-select>
            <small *ngIf="control.value === upsellFormatType.Default">
              Whole product description will be shown
            </small>
          </div>
          <div class="config-control" *ngIf="form.get('description_format')?.value === upsellFormatType.Custom && form.get('description_custom'); let control">
            <label>Custom Description</label>
            <quill-editor placeholder="Enter description" [modules]="editorConfig"
                          formControlName="description_custom">
            </quill-editor>
            <ng-container *ngIf="control.errors && control.dirty">
              <small *ngIf="control?.errors['required']" class="text-danger">
                Description is required
              </small>
              <small *ngIf="control?.errors['maxlength']" class="text-danger">
                Must be less than {{ control.errors.maxlength.requiredLength }} characters
              </small>
              <small *ngIf="control?.errors['space_only']" class="text-danger">
                The value must contain something other than space
              </small>
            </ng-container>
          </div>
          <div class="config-control" *ngIf="[upsellFormatType.Truncated, upsellFormatType.Custom].includes(form.get('description_format')?.value) && form.get('description_length'); let control">
            <div>
              Truncate description after
              <input class="form-control _local-mini-stepper"
                    formControlName="description_length"
                    type="number"  min="1" max="500"
                    (input)="updatePreviewParams('description_length')">
              {{ form.get('description_format')?.value === upsellFormatType.Truncated ? 'characters' : 'paragraphs' }}
            </div>
            <ng-container *ngIf="control.errors && control.dirty">
              <small *ngIf="control?.errors['required']" class="text-danger">
                Length is required
              </small>
              <small class="text-danger mb-1" *ngIf="control.errors.min">
                The value must be greater than 0
              </small>
              <small class="text-danger mb-1" *ngIf="control.errors.max">
                The value must be ≤ {{ control.errors.max?.max }}
              </small>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <!-- Product Quantity Selector -->
    <div class="config">
      <div class="config-head _hoverable"
            [class._closed]="!sectionOpen.quantity"
            (click)="sectionOpen.quantity = !sectionOpen.quantity">
        <one-icon class="mr-2" name="chevron-down" [class._rotated]="sectionOpen.quantity"></one-icon>
        Product Quantity Selector
      </div>
      <div class="config-body" [@expandCollapse]="sectionOpen.quantity">
        <div *ngIf="sectionOpen.quantity">
          <div class="config-control" *ngIf="form.get('predefined_quantity'); let control">
            <fieldset class="d-flex align-items-center justify-content-between">
              <span class="switchbox-label">
                Predefined Quantity
                <span class="Tooltip"
                      matTooltipClass="Tooltip-Box"
                      matTooltipPosition="above"
                      matTooltip="If this setting is selected, the customer won't be able to change the product quantity.">
                </span>
              </span>
              <pf-switch-box formControlName="predefined_quantity"
                [labelInside]="true"
                (change)="onPredefinedQuantityToggle($event)"
                labelOff="Off"
              ></pf-switch-box>
            </fieldset>
          </div>

          <ng-container *ngIf="form.get('predefined_quantity')?.value">
            <div class="config-control" *ngIf="form.get('quantity'); let control">
              <label>Quantity</label>
              <input
                formControlName="quantity"
                type="number"
                class="form-control"
                (input)="updatePreviewParams('quantity')">
              <ng-container *ngIf="control.errors && control.dirty">
                <small *ngIf="control?.errors['required']" class="text-danger">
                  Quantity is required
                </small>
                <small class="text-danger mb-1" *ngIf="control.errors.min">
                  The value must be greater than 0
                </small>
                <small class="text-danger mb-1" *ngIf="control.errors.max">
                  Value is over limit ({{ control.errors.max?.max }})
                </small>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <!-- Product Shipping -->
    <div class="config">
      <div class="config-head _hoverable"
            [class._closed]="!sectionOpen.shipping"
            (click)="sectionOpen.shipping = !sectionOpen.shipping">
        <one-icon class="mr-2" name="chevron-down" [class._rotated]="sectionOpen.shipping"></one-icon>
        Product Shipping
      </div>
      <div class="config-body" [@expandCollapse]="sectionOpen.shipping">
        <div *ngIf="sectionOpen.shipping">
          <div class="config-control" *ngIf="form.get('shipping_type'); let control">
            <label>Shipping</label>
            <ng-select
              bindValue="value"
              bindLabel="label"
              [clearable]="false"
              [searchable]="false"
              formControlName="shipping_type"
              class="h-46"
              [items]="shippingTypeOptions"
              appendTo="body"
              (change)="onShippingTypeChange()"
            ></ng-select>
          </div>
          <div class="config-control" *ngIf="form.get('shipping_type')?.value === upsellShippingType.Fixed && form.get('shipping_amount'); let control">
            <label>Shipping Rate</label>
            <input class="form-control"
                  formControlName="shipping_amount"
                  type="number" min="0" max="9999999" step="1"
                  (input)="updatePreviewParams('shipping_amount')">
            <ng-container *ngIf="control.errors && control.dirty">
              <small *ngIf="control?.errors['required']" class="text-danger">
                Shipping rate is required
              </small>
              <small class="text-danger mb-1" *ngIf="control.errors.min">
                The value must be greater than 0
              </small>
              <small class="text-danger mb-1" *ngIf="control.errors.max">
                Value is over limit ({{ control.errors.max?.max }})
              </small>
            </ng-container>
          </div>
          <div class="form-group mt-3 mb-0" *ngIf="form.get('shipping_type')?.value === upsellShippingType.Fixed && form.get('shipping_per_unit'); let control">
            <label class="d-flex align-items-center">
              <mat-checkbox formControlName="shipping_per_unit" (change)="updatePreviewParams('shipping_per_unit')" class="mr-2"></mat-checkbox>
              Charge per unit
              <span class="Tooltip"
                    matTooltipClass="Tooltip-Box"
                    matTooltipPosition="above"
                    matTooltip="Shipping rate will be applied to each upsell item."></span>
            </label>
          </div>
        </div>
      </div>
    </div>

    <!-- One-Click Checkout Buttons -->
    <div class="config">
      <div class="config-head _hoverable"
            [class._closed]="!sectionOpen.buttons"
            (click)="sectionOpen.buttons = !sectionOpen.buttons">
        <one-icon class="mr-2" name="chevron-down" [class._rotated]="sectionOpen.buttons"></one-icon>
        One-Click Checkout Buttons
      </div>
      <div class="config-body" [@expandCollapse]="sectionOpen.buttons">
        <div *ngIf="sectionOpen.buttons">
          <div class="config-control" *ngIf="form.get('accept_button_text'); let control">
            <label>Accept Button</label>
            <ng-select
              bindValue="value"
              bindLabel="label"
              [clearable]="false"
              [searchable]="false"
              formControlName="accept_button_text"
              class="h-46"
              [items]="acceptButtonOptions"
              appendTo="body"
              (change)="updatePreviewParams('accept_button_text')"
            >
              <ng-template ng-label-tmp let-item="item">
                {{item.label | translateUpsell:(form.get('locale')?.value || 'en')}}
              </ng-template>
              <ng-template ng-option-tmp let-item="item">
                {{item.label | translateUpsell:(form.get('locale')?.value || 'en')}}
              </ng-template>
            </ng-select>
          </div>
          <div class="config-control" *ngIf="form.get('decline_button_text'); let control">
            <label>Decline Button</label>
            <ng-select
              bindValue="value"
              bindLabel="label"
              [clearable]="false"
              [searchable]="false"
              formControlName="decline_button_text"
              class="h-46"
              [items]="declineButtonOptions"
              appendTo="body"
              (change)="updatePreviewParams('decline_button_text')"
            >
              <ng-template ng-label-tmp let-item="item">
                {{item.label | translateUpsell:(form.get('locale')?.value || 'en')}}
              </ng-template>
              <ng-template ng-option-tmp let-item="item">
                {{item.label | translateUpsell:(form.get('locale')?.value || 'en')}}
              </ng-template>
            </ng-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
