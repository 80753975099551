import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class FooterbarNavigationService {
  primaryBtnText$ = new BehaviorSubject<string>(null);
  primaryBtnDisabled$ = new BehaviorSubject<boolean>(false);
  secondaryBtnText$ = new BehaviorSubject<string>(null);
  secondaryBtnDisabled$ = new BehaviorSubject<boolean>(false);
  tertiaryBtnText$ = new BehaviorSubject<string>(null);
  tertiaryBtnDisabled$ = new BehaviorSubject<boolean>(false);
  exitBtnText$ = new BehaviorSubject<string>(null);
  exitBtnDisabled$ = new BehaviorSubject<boolean>(false);

  primaryBtnAction: Function = () => {};
  secondaryBtnAction: Function = () => {};
  tertiaryBtnAction: Function = () => {};
  exitBtnAction: Function = () => {};

  setPrimaryBtnText(value: string): void {
    this.primaryBtnText$.next(value);
  }

  setPrimaryBtnDisabled(value: boolean): void {
    this.primaryBtnDisabled$.next(value);
  }

  setPrimaryBtnAction(fnc: Function): void {
    this.primaryBtnAction = fnc;
  }

  setSecondaryBtnText(value: string): void {
    this.secondaryBtnText$.next(value);
  }

  setSecondaryBtnDisabled(value: boolean): void {
    this.secondaryBtnDisabled$.next(value);
  }

  setSecondaryBtnAction(fnc: Function): void {
    this.secondaryBtnAction = fnc;
  }

  setTertiaryBtnText(value: string): void {
    this.tertiaryBtnText$.next(value);
  }

  setTertiaryBtnDisabled(value: boolean): void {
    this.tertiaryBtnDisabled$.next(value);
  }

  setTertiaryBtnAction(fnc: Function): void {
    this.tertiaryBtnAction = fnc;
  }

  setExitBtnText(value: string): void {
    this.exitBtnText$.next(value);
  }

  setExitBtnDisabled(value: boolean): void {
    this.exitBtnDisabled$.next(value);
  }

  setExitBtnAction(fnc: Function): void {
    this.exitBtnAction = fnc;
  }

  clearData() {
    this.setPrimaryBtnText(null);
    this.setPrimaryBtnDisabled(false);
    this.setPrimaryBtnAction(null);
    this.setSecondaryBtnText(null);
    this.setSecondaryBtnDisabled(false);
    this.setSecondaryBtnAction(null);
    this.setTertiaryBtnText(null);
    this.setTertiaryBtnDisabled(false);
    this.setTertiaryBtnAction(null);
    this.setExitBtnText(null);
    this.setExitBtnDisabled(false);
    this.setExitBtnAction(null);
  }
}
