import { RouterModule, Routes } from '@angular/router'
import { NgModule } from '@angular/core'
import { PlansComponent } from './plans.component'
import { ShopifyCheckoutSuccessComponent } from '../../pages/billing/shopify-checkout-success/shopify-checkout-success.component'
import { ChosePlanIncompleteGuard } from '../../core/guards/chose-plan-incomplete-guard.service'

const routes: Routes = [
  {
    path: '',
    component: PlansComponent,
    canActivate: [ChosePlanIncompleteGuard],
  },
  {
    path: 'checkout/success',
    pathMatch: 'full',
    component: ShopifyCheckoutSuccessComponent
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})

export class PlansRoutingModule {
}
