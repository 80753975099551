<div class="L-CouponCode">
  <div class="L-CouponCode-Content Card" [class.pt-0]="isEditMode">
    <form class="CouponCode-Form" [class._is-edit]="isEditMode" [formGroup]="form">

      <h5 class="CouponCode-Form-Subtitle">
        Title
        <span class="Tooltip mt-1 mr-auto"
              matTooltip="Title is for your own reference. It can contain any value."
              matTooltipClass="Tooltip-Box" matTooltipPosition="above"
        ></span>
      </h5>

      <div class="PluginConfig-Line">
        <input type="text" class="form-control" maxlength="32" formControlName="title"
               placeholder="e.g. 30% OFF, Free Shipping">
        <div class="invalid-feedback">
          Please provide a Title
        </div>
      </div>
      <ng-container *ngIf="!isEditMode">
        <h5 class="CouponCode-Form-Subtitle">Code</h5>
        <div class="CouponCode-Config">
          <div class="PluginConfig-Line">
            <label>Type</label>
            <div class="CouponCode-Radio">
              <div class="CouponCode-Radio-Item _is-recommended" *ngIf="!onlyStaticCodeAllowed">
                <input type="radio" formControlName="type" id="{{'radio' + couponCodeType.woo_commerce_unique}}"
                       [value]="couponCodeType.woo_commerce_unique">
                <label for="{{'radio' + couponCodeType.woo_commerce_unique}}">
                  <span class="checkmark"></span>
                  Unique Code
                  <span class="_recommended-tip">Recommended</span>
                  <small>Custom One-Time Use Codes</small>
                </label>
              </div>
              <div class="CouponCode-Radio-Item">
                <input type="radio" formControlName="type" id="{{'radio' + couponCodeType.woo_commerce_master}}"
                       [value]="couponCodeType.woo_commerce_master">
                <label for="{{'radio' + couponCodeType.woo_commerce_master}}">
                  <span class="checkmark"></span>
                  Generic Code
                  <small>Same Code for All Subscribers</small>
                </label>
              </div>
            </div>
            <div class="CouponCode-Manual-Code-Separator">
              <span>OR</span>
            </div>
            <div class="CouponCode-Radio-Item">
              <input type="radio" formControlName="type" id="{{'radio' + couponCodeType.manual_master}}"
                     [value]="couponCodeType.manual_master">
              <label for="{{'radio' + couponCodeType.manual_master}}">
                <span class="checkmark"></span>
                Use Manual Code
              </label>
            </div>
          </div>
        </div>
      </ng-container>


      <div class="PluginConfig-Line" *ngIf="isEditMode">
        <label>Type</label>
        <div class="CouponCode-Radio _is-edit">
          <div class="CouponCode-Radio-Item">
            <ng-container [ngSwitch]="true" *ngIf="form.getRawValue().type as Type">
              <label *ngSwitchCase="Type === couponCodeType.woo_commerce_master">
                Generic Coupon
              </label>
              <label *ngSwitchCase="Type === couponCodeType.manual_master">
                Manual Coupon
              </label>
              <label *ngSwitchCase="Type === couponCodeType.woo_commerce_unique">
                Unique Coupon
              </label>
              <label *ngSwitchCase="Type === couponCodeType.manual_unique">
                Manual Coupon
              </label>
            </ng-container>
          </div>
        </div>
      </div>

      <ng-container [ngSwitch]="true" *ngIf="form.getRawValue().type as Type">

        <!-- UNIQUE COUPON -->
        <ng-container *ngSwitchCase="Type === couponCodeType.woo_commerce_unique">
          <div class="PluginConfig-Line">
            <label class="d-flex justify-content-between">
              Code Prefix (Optional)
              <span class="Tooltip mt-1 ml-1 mr-auto"
                    matTooltip="Add a prefix to the customer’s uniquely generated code (8 random letters and numbers). "
                    matTooltipClass="Tooltip-Box" matTooltipPosition="above"
              ></span>
            </label>
            <div class="CouponCode-Prefix">
              <input type="text" class="form-control" formControlName="prefix_code" maxlength="16"
                     placeholder="e.g. WELCOME-, 20OFF-, VDAY-, ABCSHOP-">
              <div class="CouponCode-Prefix-Code-Example">
                <span *ngIf="summary">{{summary.randomString}}</span>
              </div>
            </div>
            <small class="CouponCode-Tip">(Keep it short, memorable, and sweet.)</small>
          </div>

          <div class="PluginConfig-Line" formGroupName="data">
            <label class="d-flex justify-content-between">
              Code Length
            </label>
            <input type="number" min="6" max="16" class="form-control _small mt-3"
                   (click)="onNumberInputClick($event)"
                   (focusout)="onCodeLengthChange($event)" (focus)="touchControl('coupon_code_length')" formControlName="coupon_code_length">
            <div class="invalid-feedback">
              Please use a coupon code with length between 6 and 16 characters to minimize the chance that two customers receive the same coupon code.
            </div>
          </div>
          <small class="CouponCode-Tip">
            (Up to {{form.getRawValue().data.coupon_code_length}} random letters and numbers.)
          </small>

          <ng-container formGroupName="data">
            <h5 class="CouponCode-Form-Subtitle">Behavior</h5>

            <div class="PluginConfig-Line">
              <label class="PluginConfig-Control-Label _black _with-switch-fw">
                Expires after a certain number of days/hours
                <pf-switch-box
                  [labelInside]="true"
                  labelOff="Off"
                  formControlName="expires_after_enabled">
                </pf-switch-box>
              </label>

              <div class="text-danger Expires-validity"
                   [class._invalid]="form.controls['data'].hasError('expiresError')">
                All fields can't be zero
              </div>

              <div class="form-inline" [class.is-hidden]="!form.getRawValue().data.expires_after_enabled">
                <label class="mr-3">Expires After</label>
                <input type="number" min="0" max="365" style="width: 80px;" class="form-control"
                       formControlName="expires_after_days"
                       (click)="onNumberInputClick($event)"
                       (focus)="touchControl('expires_after_days')" (focusout)="onValueChange('expires_after_days', 365)">
                <label class="ml-1 mr-3">days</label>
                <input type="number" min="0" max="24" style="width: 80px;" class="form-control"
                       formControlName="expires_after_hours"
                       (click)="onNumberInputClick($event)"
                       (focus)="touchControl('expires_after_hours')" (focusout)="onValueChange('expires_after_hours', 24)">
                <label class="ml-1 mr-3">hours</label>
                <input type="number" min="0" max="60" step="15" style="width: 80px;" class="form-control"
                       formControlName="expires_after_minutes"
                       (click)="onNumberInputClick($event)"
                       (focus)="touchControl('expires_after_minutes')" (focusout)="onValueChange('expires_after_minutes', 59)">
                <label class="ml-1">minutes</label>
                <div class="invalid-feedback" *ngIf="form.get('data.expires_after_days').errors !== null">
                  Please enter a day between 0 and 365.
                </div>
                <div class="invalid-feedback" *ngIf="form.get('data.expires_after_hours').errors !== null">
                  Please enter a hour between 0 and 24.
                </div>
                <div class="invalid-feedback" *ngIf="form.get('data.expires_after_minutes').errors !== null">
                  Please enter a minute between 0 and 59.
                </div>
              </div>
            </div>

            <h5 class="CouponCode-Form-Subtitle">Discount Rules</h5>

            <div class="PluginConfig-Line">
              <label>Discount Type</label>
              <select class="form-control" formControlName="value_type" (change)="updateValueType()">
                <option *ngFor="let option of valueTypeOptions" [value]="option.value">{{option.label}}</option>
              </select>
              <ng-container [ngSwitch]="this.form.get('data.value_type').value">
                <ng-container *ngSwitchCase="discountType.Percentage">
                  <div class="alert alert-warning mt-3 mb-1">
                    <b>Discount on entire cart value or selected products.</b><br>
                    For example, if the cart contains three (3) t-shirts &#64; $20 each = $60, a coupon for 10% off applies a
                    discount of $6. <br><br><i>As a result, a final cart price of $54 ($60 - $6).</i>
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="discountType.FixedProduct">
                  <div class="alert alert-warning mt-3 mb-1">
                    <b>Discount for selected products ONLY & applied to each item in cart.</b><br>
                    For example, three (3) t-shirts &#64; $20 = $60, a coupon for $10 off applies on each t-shirt, a total
                    discount of $30 ($10 * 3).  <br><br><i>As a result, a final cart price of $30 ($60 - $30).</i>
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="discountType.FixedCart">
                  <div class="alert alert-warning mt-3 mb-1">
                    <b>Discount on entire cart value or selected products.</b><br>
                    For example, if the cart contains three (3) t-shirts &#64; $20 each = $60, a coupon for $10 off gives a
                    discount of $10. <br><br><i>As a result, a final cart price of $50 ($60 - $10).</i>
                  </div>
                </ng-container>
              </ng-container>
            </div>

            <ng-container [ngSwitch]="this.form.get('data.value_type').value">
              <ng-container *ngSwitchCase="discountType.Percentage">
                <div class="PluginConfig-Line">
                  <label>Discount Percentage</label>
                  <div class="input-group">
                    <input type="number" min="0" max="100" class="form-control" formControlName="value"
                           (click)="onNumberInputClick($event)"
                           (keydown)="touchControl('value')" (focusout)="onValueChange('value', 100, 0)">
                    <div class="input-group-append">
                      <span class="input-group-text">%</span>
                    </div>
                    <div class="invalid-feedback" [class.visible]="this.form.get('data.value').invalid && this.form.get('data.value').touched">
                      Please provide a percentage between 0 and 100
                    </div>
                  </div>
                  <div class="text-danger"
                       *ngIf="isDiscountError()">
                    Please provide Discount Value or Free Shipping
                  </div>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="discountType.FixedProduct">
                <div class="PluginConfig-Line">
                  <label>Discount Value pre Item</label>
                  <div class="input-group">
                    <input type="number" min="0" class="form-control" formControlName="value">
                    <div class="invalid-feedback">
                      Please provide a value greater than 0
                    </div>
                  </div>
                  <div class="text-danger"
                       *ngIf="isDiscountError()">
                    Please provide Discount Value or Free Shipping
                  </div>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="discountType.FixedCart">
                <div class="PluginConfig-Line">
                  <label>Discount Value</label>
                  <div class="input-group">
                    <input type="number" min="0" class="form-control" formControlName="value">
                    <div class="invalid-feedback">
                      Please provide a value greater than 0
                    </div>
                  </div>
                  <div class="text-danger"
                       *ngIf="isDiscountError()">
                    Please provide Discount Value or Free Shipping
                  </div>
                </div>
              </ng-container>
            </ng-container>

            <!--current product selector can't support dynamic change of productSelectorType, that's why in this case
            i need this tricky resolve and duplicate different ui with different productSelectorType for
            entitled_target_selection-->
            <pf-coupon-code-products
              *ngIf="form.get('data.value_type').value !== discountType.FixedProduct"
              controlName="defaultEntitledTargetSelection"
              [productSelectorType]="uiProductSelectorType.wooCommerceInclude"
              label="Applies to"
              [formGroup]="form"
              [isEditMode]="isEditMode"
              [productsListService]="includeProductsListService"
              [customError]="productsRequiredError"
              (updateTargetSelectionChange)="updateTargetSelection($event, 'entitled_target_selection')"
              (showProductSelectorClick)="showProductSelector($event, false)"
            ></pf-coupon-code-products>

            <pf-coupon-code-products
              *ngIf="form.get('data.value_type').value === discountType.FixedProduct"
              controlName="defaultEntitledTargetSelection"
              [productSelectorType]="uiProductSelectorType.wooCommerceExclude"
              label="Applies to"
              [formGroup]="form"
              [isEditMode]="isEditMode"
              [productsListService]="includeProductsListService"
              [customError]="productsRequiredError"
              (updateTargetSelectionChange)="updateTargetSelection($event, 'entitled_target_selection')"
              (showProductSelectorClick)="showProductSelector($event, false)"
            ></pf-coupon-code-products>

            <pf-coupon-code-products
              *ngIf="form.get('data.value_type').value !== discountType.FixedProduct"
              controlName="defaultExcludedTargetSelection"
              [productSelectorType]="uiProductSelectorType.wooCommerceExclude"
              label="Exclude Products"
              [formGroup]="form"
              [isEditMode]="isEditMode"
              [productsListService]="excludeProductsListService"
              [customError]="productsRequiredError"
              (updateTargetSelectionChange)="updateTargetSelection($event, 'excluded_target_selection')"
              (showProductSelectorClick)="showProductSelector($event, true)"
              [noValidate]="true"
            ></pf-coupon-code-products>

            <div class="PluginConfig-Line">
              <label class="dashed-underline">
                Minimum Spend
                <span
                  class="Tooltip" matTooltipClass="Tooltip-Box" matTooltipPosition="above"
                  [matTooltip]="'This field allows you to set the minimum spend (subtotal) allowed to use the coupon.'">
                </span>
              </label>
              <div class="input-group">
                <input type="number" min="0" class="form-control" formControlName="prerequisite_subtotal_min">
              </div>
            </div>

            <div class="PluginConfig-Line">
              <label class="dashed-underline">
                Maximum Spend
                <span
                  class="Tooltip" matTooltipClass="Tooltip-Box" matTooltipPosition="above"
                  [matTooltip]="'This field allows you to set the maximum spend (subtotal) allowed when using the coupon.'">
                </span>
              </label>
              <div class="input-group">
                <input type="number" min="0" class="form-control" formControlName="prerequisite_subtotal_max">
              </div>
            </div>

            <div class="PluginConfig-Line mat-checkbox-wrap">
              <label>Free Shipping</label>
              <mat-checkbox formControlName="free_shipping">
                Check this box if the coupon grants free shipping.
                A <a href="https://docs.woocommerce.com/document/free-shipping/" target="_blank">free shipping method</a>
                must be enabled in your shipping zone and be set to require "a valid free shipping coupon" (see the "Free
                Shipping Requires" setting).
              </mat-checkbox>
              <div class="text-danger"
                   *ngIf="isDiscountError()">
                Please provide Discount Value or Free Shipping
              </div>
            </div>

            <div class="PluginConfig-Line mat-checkbox-wrap">
              <label>Exclude sale items</label>
              <mat-checkbox formControlName="exclude_sale_items">
                Check this box if the coupon should not apply to items on sale. Per-item coupons will only work if the
                item is not on sale. Per-cart coupons will only work if there are items in the cart that are not on sale.
              </mat-checkbox>
            </div>

            <div class="PluginConfig-Line mat-checkbox-wrap">
              <label>Stackable Discount Code</label>
              <mat-checkbox formControlName="individual_use">
                Allow the customer to stack multiple promotions with this discount code to maximize total savings. Thus, this coupon code can be used together with other coupon codes that also have this setting enabled.              </mat-checkbox>
            </div>

            <div class="PluginConfig-Line">
              <label>Allowed emails</label>
              <ng-select [addTag]="true" [multiple]="true" [clearable]="true" formControlName="allowed_emails"></ng-select>
            </div>

          </ng-container>
        </ng-container>

        <!-- GENERIC COUPON -->
        <ng-container *ngSwitchCase="Type === couponCodeType.woo_commerce_master">
          <div class="PluginConfig-Line">
            <label class="d-flex justify-content-between">
              Coupon Code
              <span class="Tooltip mt-1 ml-1 mr-auto"
                    matTooltip="All customers will enter this discount code at checkout."
                    matTooltipClass="Tooltip-Box" matTooltipPosition="above"
              ></span>
            </label>
            <div class="CouponCode-Generator">
              <input type="text" class="form-control" formControlName="master_code" maxlength="255"
                     placeholder="Enter coupon code here"
                     #masterCodeInput>
              <a *ngIf="!isEditMode" href="#" (click)="generateMasterCode($event)">Generate Code</a>
            </div>
            <ng-container *ngIf="form.get('master_code').errors; let errors">
              <div class="invalid-feedback" *ngIf="errors.required">
                Please provide a Code
              </div>
              <div class="invalid-feedback" *ngIf="errors.maxlength">
                Code must be less than 50 characters
              </div>
            </ng-container>
          </div>

          <ng-container formGroupName="data">
            <h5 class="CouponCode-Form-Subtitle">Usage Limits</h5>

            <div class="PluginConfig-Line">
              <label class="PluginConfig-Control-Label _black _with-switch-fw" formGroupName="_local">
                Limit number of times this discount can be used in total
                <pf-switch-box [labelInside]="true" labelOff="Off" formControlName="isUsageLimit">
                </pf-switch-box>
              </label>

              <div class="form-group mt-3" [class.is-hidden]="!form.getRawValue().data._local.isUsageLimit">
                <div class="input-group">
                  <input type="number" min="0" max="99999999" step="1" formControlName="usage_limit" class="form-control"
                         (click)="onNumberInputClick($event)"
                        (focus)="touchControl('usage_limit')"
                        (focusout)="onValueChange('usage_limit', 99999999, 0)">
                  <div class="invalid-feedback">
                    Please provide a value between 0 and 99999999
                  </div>
                </div>
              </div>
            </div>

            <div class="PluginConfig-Line">
              <label class="PluginConfig-Control-Label _black _with-switch-fw" formGroupName="_local">
                Limit number of times this discount can be used by user
                <pf-switch-box
                  [labelInside]="true"
                  labelOff="Off"
                  formControlName="isUserUsageLimit">
                </pf-switch-box>
              </label>

              <div class="form-group mt-3" [class.is-hidden]="!form.getRawValue().data._local.isUserUsageLimit">
                <div class="input-group">
                  <input type="number" min="0" max="99999999" step="1" formControlName="usage_limit_per_customer" class="form-control"
                       (focus)="touchControl('usage_limit_per_customer')"
                         (click)="onNumberInputClick($event)"
                       (focusout)="onValueChange('usage_limit_per_customer', 99999999, 0)">
                  <div class="invalid-feedback">
                    Please provide a value between 0 and 99999999
                  </div>
                </div>
              </div>
            </div>
            <div class="PluginConfig-Line" *ngIf="!isCartSelected">
              <label class="PluginConfig-Control-Label _black _with-switch-fw" formGroupName="_local">
                The maximum number of individual items this coupon can apply to when using product discounts. Leave blank to apply to all qualifying items in cart.
                <pf-switch-box
                  [labelInside]="true"
                  labelOff="Off"
                  formControlName="isProductUsageLimit">
                </pf-switch-box>
              </label>

              <div class="form-group mt-3" [class.is-hidden]="!form.getRawValue().data._local.isProductUsageLimit">
                <div class="input-group">
                  <input type="number" min="0" max="99999999" step="1" formControlName="limit_usage_to_x_items" class="form-control"
                       (focus)="touchControl('limit_usage_to_x_items')"
                         (click)="onNumberInputClick($event)"
                       (focusout)="onValueChange('limit_usage_to_x_items', 99999999, 0)">
                  <div class="invalid-feedback">
                    Please provide a value between 0 and 99999999
                  </div>
                </div>
              </div>
            </div>

            <h5 class="CouponCode-Form-Subtitle">Discount Rules</h5>

            <div class="PluginConfig-Line">
              <label>Discount Type</label>
              <select class="form-control" formControlName="value_type" (change)="updateValueType()">
                <option *ngFor="let option of valueTypeOptions" [value]="option.value">{{option.label}}</option>
              </select>
              <ng-container [ngSwitch]="this.form.get('data.value_type').value">
                <ng-container *ngSwitchCase="discountType.Percentage">
                  <div class="alert alert-warning mt-3 mb-1">
                    <b>Discount on entire cart value or selected products.</b><br>
                    For example, if the cart contains three (3) t-shirts &#64; $20 each = $60, a coupon for 10% off applies a
                    discount of $6. <br><br><i>As a result, a final cart price of $54 ($60 - $6).</i>
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="discountType.FixedProduct">
                  <div class="alert alert-warning mt-3 mb-1">
                    <b>Discount for selected products ONLY & applied to each item in cart.</b><br>
                    For example, three (3) t-shirts &#64; $20 = $60, a coupon for $10 off applies on each t-shirt, a total
                    discount of $30 ($10 * 3).  <br><br><i>As a result, a final cart price of $30 ($60 - $30).</i>
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="discountType.FixedCart">
                  <div class="alert alert-warning mt-3 mb-1">
                    <b>Discount on entire cart value or selected products.</b><br>
                    For example, if the cart contains three (3) t-shirts &#64; $20 each = $60, a coupon for $10 off gives a
                    discount of $10. <br><br><i>As a result, a final cart price of $50 ($60 - $10).</i>
                  </div>
                </ng-container>
              </ng-container>
            </div>

            <ng-container [ngSwitch]="this.form.get('data.value_type').value">
              <ng-container *ngSwitchCase="discountType.Percentage">
                <div class="PluginConfig-Line">
                  <label>Discount Percentage</label>
                  <div class="input-group">
                    <input type="number" min="0" max="100" class="form-control" formControlName="value"
                           (click)="onNumberInputClick($event)"
                           (keydown)="touchControl('value')" (focusout)="onValueChange('value', 100, 0)">
                    <div class="input-group-append">
                      <span class="input-group-text">%</span>
                    </div>
                    <div class="invalid-feedback" [class.visible]="this.form.get('data.value').invalid && this.form.get('data.value').touched">
                      Please provide a percentage between 0 and 100
                    </div>
                  </div>
                  <div class="text-danger"
                       *ngIf="isDiscountError()">
                    Please provide Discount Value or Free Shipping
                  </div>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="discountType.FixedProduct">
                <div class="PluginConfig-Line">
                  <label>Discount Value per Item</label>
                  <div class="input-group">
                    <input type="number" min="0" class="form-control" formControlName="value">
                    <div class="invalid-feedback">
                      Please provide a value greater than 0
                    </div>
                  </div>
                  <div class="text-danger"
                       *ngIf="isDiscountError()">
                    Please provide Discount Value or Free Shipping
                  </div>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="discountType.FixedCart">
                <div class="PluginConfig-Line">
                  <label>Discount Value</label>
                  <div class="input-group">
                    <input type="number" min="0" class="form-control" formControlName="value">
                    <div class="invalid-feedback">
                      Please provide a value greater than 0
                    </div>
                  </div>
                  <div class="text-danger"
                       *ngIf="isDiscountError()">
                    Please provide Discount Value or Free Shipping
                  </div>
                </div>
              </ng-container>
            </ng-container>

            <!--current product selector can't support dynamic change of productSelectorType, that's why in this case
            i need this tricky resolve and duplicate different ui with different productSelectorType for
            entitled_target_selection-->
            <pf-coupon-code-products
              *ngIf="form.get('data.value_type').value !== discountType.FixedProduct"
              controlName="defaultEntitledTargetSelection"
              [productSelectorType]="uiProductSelectorType.wooCommerceInclude"
              label="Applies to"
              [formGroup]="form"
              [isEditMode]="isEditMode"
              [productsListService]="includeProductsListService"
              [customError]="productsRequiredError"
              (updateTargetSelectionChange)="updateTargetSelection($event, 'entitled_target_selection')"
              (showProductSelectorClick)="showProductSelector($event, false)"
            ></pf-coupon-code-products>

            <pf-coupon-code-products
              *ngIf="form.get('data.value_type').value === discountType.FixedProduct"
              controlName="defaultEntitledTargetSelection"
              [productSelectorType]="uiProductSelectorType.wooCommerceExclude"
              label="Applies to"
              [formGroup]="form"
              [isEditMode]="isEditMode"
              [productsListService]="includeProductsListService"
              [customError]="productsRequiredError"
              (updateTargetSelectionChange)="updateTargetSelection($event, 'entitled_target_selection')"
              (showProductSelectorClick)="showProductSelector($event, false)"
            ></pf-coupon-code-products>

            <pf-coupon-code-products
              *ngIf="form.get('data.value_type').value !== discountType.FixedProduct"
              controlName="defaultExcludedTargetSelection"
              [productSelectorType]="uiProductSelectorType.wooCommerceExclude"
              label="Exclude Products"
              [formGroup]="form"
              [isEditMode]="isEditMode"
              [productsListService]="excludeProductsListService"
              [customError]="productsRequiredError"
              (updateTargetSelectionChange)="updateTargetSelection($event, 'excluded_target_selection')"
              (showProductSelectorClick)="showProductSelector($event, true)"
              [noValidate]="true"
            ></pf-coupon-code-products>

            <div class="PluginConfig-Line">
              <label class="dashed-underline">
                Minimum Spend
                <span
                  class="Tooltip" matTooltipClass="Tooltip-Box" matTooltipPosition="above"
                  [matTooltip]="'This field allows you to set the minimum spend (subtotal) allowed to use the coupon.'">
                </span>
              </label>
              <div class="input-group">
                <input type="number" min="0" class="form-control" formControlName="prerequisite_subtotal_min">
              </div>
            </div>

            <div class="PluginConfig-Line">
              <label class="dashed-underline">
                Maximum Spend
                <span
                  class="Tooltip" matTooltipClass="Tooltip-Box" matTooltipPosition="above"
                  [matTooltip]="'This field allows you to set the maximum spend (subtotal) allowed when using the coupon.'">
                </span>
              </label>
              <div class="input-group">
                <input type="number" min="0" class="form-control" formControlName="prerequisite_subtotal_max">
              </div>
            </div>

            <div class="PluginConfig-Line mat-checkbox-wrap">
              <label>Free Shipping</label>
              <mat-checkbox formControlName="free_shipping">
                Check this box if the coupon grants free shipping.
                A <a href="https://docs.woocommerce.com/document/free-shipping/" target="_blank">free shipping method</a>
                must be enabled in your shipping zone and be set to require "a valid free shipping coupon" (see the "Free
                Shipping Requires" setting).
              </mat-checkbox>
            </div>

            <div class="PluginConfig-Line mat-checkbox-wrap">
              <label>Exclude sale items</label>
              <mat-checkbox formControlName="exclude_sale_items">
                Check this box if the coupon should not apply to items on sale. Per-item coupons will only work if the
                item is not on sale. Per-cart coupons will only work if there are items in the cart that are not on sale.
              </mat-checkbox>
            </div>

            <div class="PluginConfig-Line mat-checkbox-wrap">
              <label>Stackable Discount Code</label>
              <mat-checkbox formControlName="individual_use">
                Allow the customer to stack multiple promotions with this discount code to maximize total savings. Thus, this coupon code can be used together with other coupon codes that also have this setting enabled.              </mat-checkbox>
            </div>

            <div class="PluginConfig-Line">
              <label>Allowed emails</label>
              <ng-select [addTag]="true" [multiple]="true" [clearable]="true" formControlName="allowed_emails"></ng-select>
            </div>
          </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="Type === couponCodeType.manual_master">
          <div class="PluginConfig-Line">
            <label class="d-flex justify-content-between">
              Coupon Code
              <span class="Tooltip mt-1 ml-1 mr-auto"
                    matTooltip="All customers will enter this discount code at checkout."
                    matTooltipClass="Tooltip-Box" matTooltipPosition="above"
              ></span>
            </label>
            <div class="CouponCode-Generator">
              <input type="text" class="form-control" formControlName="master_code" maxlength="255"
                     placeholder="Enter coupon code here">
              <a *ngIf="!isEditMode" href="#" (click)="generateMasterCode($event)">Generate Code</a>
            </div>
            <ng-container *ngIf="form.get('master_code').errors; let errors">
              <div class="invalid-feedback" *ngIf="errors.required">
                Please provide a Code
              </div>
              <div class="invalid-feedback" *ngIf="errors.maxlength">
                Code must be less than 50 characters
              </div>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
    </form>


  </div>

  <div class="L-CouponCode-Aside">
    <div class="CouponCode-Summary">
      <div class="text-dark" *ngIf="summary">
<!--        <h5 class="mb-3">{{form.value.title}} Summary</h5>-->

        <!-- Schedule -->
        <!-- <p>{{summary.schedule.start}}</p>
        <p *ngIf="summary.schedule.end">{{summary.schedule.end}}</p> -->

        <!-- Code Type -->
<!--       <p>{{summary.codeType}}</p>-->

        <!-- Code -->
        <div class="CouponCode-Code">
          <ng-container [ngSwitch]="true" *ngIf="form.getRawValue().type as Type">
            <ng-container *ngSwitchCase="Type === couponCodeType.manual_master">
              <h5>Master Code Preview</h5>
              <small class="text-danger" *ngIf="!form.pristine && summary.code">
                Coupon valid after save
              </small>
              <pre>{{summary.code}} </pre>
            </ng-container>
            <ng-container *ngSwitchCase="Type === couponCodeType.woo_commerce_master">
              <h5>Master Code Preview</h5>
              <small class="text-danger" *ngIf="!form.pristine && summary.code">
                Coupon valid after save
              </small>
              <pre>{{summary.code}} </pre>
            </ng-container>
            <ng-container *ngSwitchCase="Type === couponCodeType.manual_unique">
              <h5>Unique Code Preview</h5>
              <small class="text-danger">
                [Sample] Real Code Generated on Popup
              </small>
              <pre>{{summary.code}} </pre>
            </ng-container>
            <ng-container *ngSwitchCase="Type === couponCodeType.woo_commerce_unique">
              <h5>Unique Code Preview</h5>
              <small class="text-danger">
                [Sample] Real Code Generated on Popup
              </small>
              <pre>{{summary.code}} </pre>
            </ng-container>
          </ng-container>
        </div>

        <ul>
          <li *ngIf="summary.discount">{{summary.discount}} {{summary.discoutnRelatedTo}}</li>
          <li *ngIf="summary.usageByOneUser">{{summary.usageByOneUser}}</li>
          <li *ngIf="summary.individualUsageItems">{{summary.individualUsageItems}}</li>
          <li *ngIf="summary.limit">{{summary.limit}}</li>
          <li *ngIf="summary.minimumSpend > 0">Minimum spend {{summary.minimumSpend}} to allow coupon</li>
          <li *ngIf="summary.maximumSpend > 0">Maximum spend {{summary.maximumSpend}} to allow coupon</li>
          <li *ngIf="summary.freeShipping">Free Shipping</li>
          <li *ngIf="summary.excludeSaleItems">Exclude sale items</li>
          <li *ngIf="summary.stackableDiscountCode">Stackable Discount Code</li>
        </ul>

        <div class="d-flex justify-content-around mt-4">
          <button class="pf-button md outline gray" (click)="cancel()">CANCEL</button>
          <button class="pf-button md filled blue" (click)="submit()">SAVE</button>
        </div>
      </div>
    </div>
  </div>
</div>
