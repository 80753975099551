import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { AccessFormModalComponent } from './components/access-form-modal/access-form-modal.component'
import { JotFormService } from './services/jotform.service'
import { ImageUploadModule } from '../image-upload/image-upload.module'
import { PhoneInputModule } from '../phone-input/phone-input.module'
import { SimpleLoadingOverlayComponent } from '../../components/simple-loading-overlay/simple-loading-overlay.component'
import { MatDialogModule } from "@angular/material/dialog";

@NgModule({
  declarations: [
    AccessFormModalComponent,
  ],
  exports: [
    AccessFormModalComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ImageUploadModule,
    PhoneInputModule,
    SimpleLoadingOverlayComponent,
    MatDialogModule
  ],
  providers: [
      JotFormService
  ]
})

export class JotFormModule { }
