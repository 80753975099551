import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { UpsellDesignStepComponent } from './components/upsell-builder/steps/design/upsell-design-step.component'
import { UpsellOfferStepComponent } from './components/upsell-builder/steps/offer/upsell-offer-step.component'
import { UpsellBuilderComponent } from './components/upsell-builder/upsell-builder.component'
import { SavableUpsellGuard } from './services/savable-upsell.guard'
import { UpsellResolverService } from './services/upsell-resolver.service'
import { UpsellPageComponent } from './upsell-page.component'
import { SideNavigationTitle } from '../../shared/components/one-header/header-navigation.model'

const childRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'offer',
  },
  {
    path: 'offer',
    component: UpsellOfferStepComponent,
    resolve: {
      upsell: UpsellResolverService,
    },
    canDeactivate: [SavableUpsellGuard],
    data: { hide_navbar: true },
  },
  {
    path: 'design',
    component: UpsellDesignStepComponent,
    resolve: {
      upsell: UpsellResolverService,
    },
    canDeactivate: [SavableUpsellGuard],
    data: { hide_navbar: true },
  },
  {
    path: 'variant/offer',
    component: UpsellOfferStepComponent,
    resolve: {
      upsell: UpsellResolverService,
    },
    canDeactivate: [SavableUpsellGuard],
    data: { hide_navbar: true },
  },
  {
    path: 'variant/:variantId/offer',
    component: UpsellOfferStepComponent,
    resolve: {
      upsell: UpsellResolverService,
    },
    canDeactivate: [SavableUpsellGuard],
    data: { hide_navbar: true },
  },
  {
    path: 'variant/:variantId/design',
    component: UpsellDesignStepComponent,
    resolve: {
      upsell: UpsellResolverService,
    },
    canDeactivate: [SavableUpsellGuard],
    data: { hide_navbar: true },
  },
]

const routes: Routes = [
  {
    path: '',
    component: UpsellPageComponent,
    data: {
      pageTitle: SideNavigationTitle.upsell_checkout,
    },
  },
  {
    path: ':upsellId',
    component: UpsellBuilderComponent,
    children: childRoutes,
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UpsellPageRoutingModule {
}
