export enum LoadingLabel {
  // Sign In
  SignInEmail = 'SignInEmail',
  SignInGoogle = 'SignInGoogle',
  SignInShopify = 'SignInShopify',

  // Sign Up
  SignUpEmail = 'SignUpEmail',
  SignUpGoogle = 'SignUpGoogle',

  LogoutCurrentUser = 'LogoutCurrentUser',

  // Guards
  GuardGettingStartedUndone = 'GuardGettingStartedUndone',
  GuardGettingStartedDone = 'GuardGettingStartedDone',

  ChoseInitialPlanCompleteGuard = 'ChoseInitialPlanCompleteGuard',
  ChoseInitialPlanIncompleteGuard = 'ChoseInitialPlanIncompleteGuard',

  LaunchedFirstCampaignCompleteGuard = 'LaunchedFirstCampaignCompleteGuard',
  LaunchedFirstCampaignInCompleteGuard = 'LaunchedFirstCampaignCompleteGuard',
  AuthGuard = 'AuthGuard',

  OnboardingAppsUndone = 'OnboardingAppsUndone',

  PhoneVerifiedGuard = 'PhoneVerifiedGuard',
  PrePlansSurveyIncompleteGuard = 'PrePlansSurveyIncompleteGuard',
  PlatformOnboardingDoneGuard = 'PlatformOnboardingDoneGuard',
  PlatformOnboardingUndoneGuard = 'PlatformOnboardingUndoneGuard',

  // Getting Started Flow
  OnContinue = 'OnContinue',
  OnSkip = 'OnSkip',
  SelectCampaignStep = 'SelectCampaignStep',
  OnGetStarted = 'OnGetStarted',

  OnBoardingStatus = 'OnBoardingStatus',
  ConversionsStatus = 'ConversionsStatus',
  DashboardFetch = 'DashboardFetch',
  NotificationsTableDataSourceLoad = 'NotificationsTableDataSourceLoad',

  DataTable = 'DataTable',
  LinkedCoupons = 'LinkedCoupons',
  RewardsTable = 'RewardsTable',

  AdminTableDataSourceLoad = 'AdminTableDataSourceLoad',

  OnNavigateAway = 'OnNavigateAway'
}
