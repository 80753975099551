import { Injectable, inject } from '@angular/core'
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router'
import { select, Store } from '@ngrx/store'
import { forkJoin, Observable, Subscription } from 'rxjs'
import { StoreState } from '../../store/store.state'
import { getUserChoseInitialPlanCompleted, getUserInfo, getUserShop } from '../../store/user/user.selectors'
import { filter, finalize, map } from 'rxjs/operators'
import { first } from 'rxjs/operators'
import { HideLoading, ShowLoading } from '../../store/loading/loading.actions'
import { Logger } from '../services/logger.service'
import { LoadingLabel } from '../../shared/models/loading/loading-label'
import { ApiPaymentService } from '../services/api/api-payment.service'
import _ from 'lodash'
import { SetCurrentUserInfo, SetSignUpCompleted, SetUserChosePlan } from '../../store/user/user.actions'
import { SegmentAnalyticsService } from '../../shared/services/segment-analytics.service'
import { UserShop } from '../../shared/models/user/user-shop.model'
import { UserInfo } from '../../store/user/user.state'
import { PromoService } from '../../shared/modules/promo/services/promo.service'
import { DashboardSettingsService } from '../services/dashboard-settings.service'
import { LogLabel } from '../../shared/models/logger/log-label.model'
import { BrandingService } from '../services/branding.service'
import { GettingStartedStatus } from '../../shared/models/getting-started/getting-started-status.model'
import { getGettingStartedStatus } from '../../store/getting-started/getting-started.selectors'
import { GettingStartedService } from '../services/getting-started.service'
import { PlansV3Identifier } from '../../shared/models/subscription-plan.model'
import { PlansPageVersion } from '../../shared/components/plans-view/models/plans-version.model'
import { keyValuePairKey } from '../../shared/models/key-value-pair.model'
import { ApiJotformService } from '../services/api/api-jotform.service'
import { PlatformOnboardingUnlockedFlow } from '../../shared/models/platform-onboarding.model'
import { SafeLocalStorageService } from '../services/safe-local-storage.service'
import { RouteHeaderUrl } from '../../shared/components/one-header/header-navigation.model'
// import { stripePricingPaths } from '../../pages/cart/services/products-page-config'
import { CartService } from '../../pages/cart/services/cart.service'
import { ProductPageConfig } from '../../pages/cart/services/products-page-config'
import { UserService } from '../services/user.service'

@Injectable()
export class ChosePlanCompleteGuard  {
  subscription = new Subscription()
  productPageConfig = new ProductPageConfig()
  hideStripePlansFrom = [
    'partner_test',
    'affiliate',
    'staff',
    'staff_business',
    'shopify_alumni',
  ]
  showPrePlansSurvey = [
    'open_learning',
    'npo_lite',
    'npo_full',
    'trial',
    'dormant',
    'basic',
    'starter',
    'professional',
    'unlimited',
    'shopify_plus',
    'affiliate',
    'partner_test',
    'staff',
    'staff_business',
    'shopify_alumni',
  ]
  showPlans = [
    'professional',
    'unlimited',
    'shopify_plus'
  ]
  showScholarshipPlans = [
    'open_learning',
    'npo_lite',
    'npo_full',
    'trial',
    'dormant',
    'basic',
    'starter',
    'professional',
    'unlimited',
    'shopify_plus',
    'staff',
    'staff_business',
    'shopify_alumni',
  ]

  userType = 'new'

  initialPaths = `/${RouteHeaderUrl.apps}`
  forceStripePricing = inject(CartService).forceStripePricing

  constructor(
    private router: Router,
    private store: Store<StoreState>,
    private logger: Logger,
    private userService: UserService,
    private promoService: PromoService,
    private apiPaymentService: ApiPaymentService,
    private segmentAnalyticsService: SegmentAnalyticsService,
    private dashboardSettingsService: DashboardSettingsService,
    private brandingService: BrandingService,
    private safeLocalStorageService: SafeLocalStorageService,
    private gettingStartedService: GettingStartedService,
    private apiJotformService: ApiJotformService,
  ) {
  }

  check(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.store.dispatch(new ShowLoading(LoadingLabel.ChoseInitialPlanCompleteGuard))
    return forkJoin([
      this.userChoseInitialPlanCompleted$,
      this.userShop$,
      this.userInfo$,
      this.dashboardSettings$,
      this.gettingStartedStatus$,
      this.userInteractions$,
    ]).pipe(
      map(([choseInitialPlan, userShop, userInfo, settings, status, interactions]) => {
        this.logger.log(<LogLabel>'signup-completed-guard', 'choseInitialPlan: ', choseInitialPlan)
        if (!choseInitialPlan) {
          // If a user satisfies given criteria then skip plans page
          const shopType = _.get(userShop, 'type')
          const shopifyPlan = _.get(userShop, 'profile.plan_name')
          const assignedPlan = _.get(userInfo, 'subscription.plan.payment_gateway_plan_identifier')
          const isShopifyShop = shopType === 'ShopifyShop'
          const isShopifyBudding = isShopifyShop && assignedPlan === 'budding'
          let defaultPlan = 'budding'

          // If user tries to navigate to custom stripe plan page - allow
          const allowedPaths = Object.values(this.productPageConfig.stripePricingPaths)
          if (( !this.hideStripePlansFrom.includes(shopifyPlan) && allowedPaths.some(p => state.url.includes(p)) ) || this.forceStripePricing) {
            return true
          }

          const userCreated =  new Date(_.get(userInfo, 'created_at', null))

          const skipRange = {
            start_date: new Date('2021-09-15T19:00:00.000Z'),
            end_date: null, // range end is null for now, will be read from backend in the future
          }

          const startupProgramEnabled = settings && settings.startup_program_enabled
          const dateFilterEnabled = settings && !!settings.startup_program_offer_dates
          const startupProgramDates = dateFilterEnabled ? settings.startup_program_offer_dates : []

          // If plan is not chosen and onboarding status is completed - user is returning
          this.userType = !_.get(status, 'completed', false) ? 'new' : 'returning'

          // Criteria for pre-plans survey
          let prePlansSurvey = this.shouldSeePrePlansSurvey(shopifyPlan)
                                && this.userType === 'new'
                                && !_.get(status, `pre_plans_survey_${this.userType}.completed`, false)

          // Criteria for skipping
          let plansShouldBeSkipped = isShopifyBudding
                && !this.shouldSeePlansPage(shopifyPlan)
                && !this.shouldSeeScholarshipPromo(shopifyPlan)
                && (!skipRange.start_date || userCreated > skipRange.start_date)
                && (!skipRange.end_date || userCreated < skipRange.end_date)

          // Criteria for scholarship promo
          let shouldApplyScholarship = !plansShouldBeSkipped && isShopifyBudding
                && this.shouldSeeScholarshipPromo(shopifyPlan)
                && !this.hasAnotherPromo(userInfo)
                && startupProgramEnabled
                && (!dateFilterEnabled || this.isInDateRange(userCreated, startupProgramDates))

          // OVERRIDE TO SHOW PLANS TO ALL SHOPIFY USERS
          if (isShopifyShop) {
            plansShouldBeSkipped = false
            shouldApplyScholarship = false
          }

          // if unlock flow is set to skip plans page skip them
          if (userInfo.platform_onboarding_locked === false && userInfo.platform_onboarding_unlocked_flow === PlatformOnboardingUnlockedFlow.SkipPlanSelection) {
            plansShouldBeSkipped = true
          }

          // as part of plans V3 skip plans page for users with more than 1000 active customers & preselect new_free_v3 by default
          if (this.userType === 'new' && isShopifyShop && (userInfo?.contacts?.active_known_customers_total < 51 || userInfo?.contacts?.active_known_customers_total > 3000)) {
            plansShouldBeSkipped = true
            defaultPlan = PlansV3Identifier.Free
          }

          // skip plans page if user paid invoice with Stripe
          if (userInfo?.custom_plan_invoice) {
            plansShouldBeSkipped = true
            shouldApplyScholarship = false
            defaultPlan = PlansV3Identifier.Custom
          }

          // FIXME: This is bad:
          // check if user has active stripe subscription 
          // since we have a bug where we can't update the plan name in the subscription object
          // we need to check if the subscription is active by looking into interactions data
          const stripeSubActive = interactions?.stripe_billing?.subscription

          // Redirect to pre plans survey if shopify plan is in survey list and user hasn't completed it yet
          if (prePlansSurvey) {
            this.router.navigateByUrl(`/onboarding/signup/${this.userType}/welcome`).then(() => {
              this.store.dispatch(new HideLoading(LoadingLabel.ChoseInitialPlanCompleteGuard))
            })
          // Redirect to Apps Page if not one of nonSkipPlans and user created after skipRange
          } else if (plansShouldBeSkipped) {
            this.subscription.add(forkJoin([
              this.apiPaymentService.putSubscriptions(defaultPlan, null),
              this.apiPaymentService.getPlans(),
              this.gettingStartedService.completeStatus(),
            ]).subscribe(([res, plansData, res2])  => {
              this.store.dispatch(new SetCurrentUserInfo({
                ...userInfo,
                subscription: res.result.subscription,
              }))
              this.store.dispatch(new SetUserChosePlan(true))
              this.store.dispatch(new SetSignUpCompleted(true))

              // tell segment that this user skipped plans page
              this.segmentAnalyticsService.identify(userInfo.id, {
                plan: defaultPlan,
                plans_skipped: true,
                plans_skipped_reason: 'satisfies_criteria'
              })
              this.segmentAnalyticsService.track('Skipped Plans Page')
              const pairs: any = {
                [keyValuePairKey.SlectedPlanTimestamp]: Date.now()
              }

              const assignedPlan = plansData?.plans?.find(p => p.payment_gateway_plan_identifier === defaultPlan)
              if (assignedPlan) {
                const lowestUsagePlan = assignedPlan.usage_plans?.find(up => up.min === 0)
                if (lowestUsagePlan?.max) {
                  pairs[keyValuePairKey.FreePlanMaxActiveKnownCustomers] = lowestUsagePlan.max
                }
              }
              if (!_.isEmpty(pairs)) {
                this.subscription.add(this.gettingStartedService.updateKeyValuePairs(pairs).subscribe(() => {
                  this.updateJotformIfNeeded(assignedPlan)
                  setTimeout(() => this.router.navigateByUrl(this.initialPaths).then(() => {
                    this.store.dispatch(new HideLoading(LoadingLabel.ChoseInitialPlanCompleteGuard))
                  }), 200)
                }))
              } else {
                setTimeout(() => this.router.navigateByUrl(this.initialPaths).then(() => {
                  this.store.dispatch(new HideLoading(LoadingLabel.ChoseInitialPlanCompleteGuard))
                }), 100)
              }
            }))

            return true
          // Allow if user has active stripe subscription
          } else if (!!stripeSubActive) {
            return true
          // Redirect to plans page if shopifyPlan is in scholarshipPlans
          } else if (shouldApplyScholarship) {
            let promoParams = ''
            const brandingName = this.brandingService.getBrandingData().name.trim().toLowerCase()
            switch (shopifyPlan) {
              // Students
              case 'open_learning':
                promoParams = `?promo_code=ONE%2BEDU&promo_type=startup_program&promo_referrer=${brandingName}&promo_view=scholarship`
                break
              // Non-Profits
              case 'npo_lite':
              case 'npo_full':
                promoParams = `?promo_code=ONE%2BNPO&promo_type=startup_program&promo_referrer=${brandingName}&promo_view=scholarship`
                break
              // Small Businesses
              case 'trial':
              case 'dormant':
                promoParams = `?promo_code=ONE%2BSTORE&promo_type=startup_program&promo_referrer=${brandingName}&promo_view=scholarship`
                break
              case 'basic':
              case 'starter':
                promoParams = `?promo_code=ONE%2BSTORE&promo_type=startup_program&promo_referrer=${brandingName}&promo_view=scholarship`
                break
              // Medium Businesses
              case 'professional':
              case 'unlimited':
                promoParams = `?promo_code=ONE%2BSTORE&promo_type=startup_program&promo_referrer=${brandingName}&promo_view=scholarship`
                break
              // Enterprise Businesses
              case 'shopify_plus':
                promoParams = `?promo_code=ONE%2BSTORE&promo_type=startup_program&promo_referrer=${brandingName}&promo_view=scholarship`
                break
              // Shopify Partners
              case 'affiliate':
                promoParams = `?promo_code=ONE%2BAFFILIATE&promo_type=startup_program&promo_referrer=${brandingName}&promo_view=scholarship`
                break
              case 'partner_test':
                promoParams = `?promo_code=ONE%2BPARTNER&promo_type=startup_program&promo_referrer=${brandingName}&promo_view=scholarship`
                break
              // Shopify Employees
              case 'staff':
              case 'staff_business':
              case 'shopify_alumni':
                promoParams = `?promo_code=ONE%2BSHOPIFY&promo_type=startup_program&promo_referrer=${brandingName}&promo_view=scholarship`
                break
            }
            this.promoService.definePromo({params: promoParams}, userInfo)
            this.router.navigateByUrl(`/onboarding/signup/${this.userType}/subscriptions` + promoParams).then(() => {
              this.promoService.togglePromoState(userInfo, true)
            })
          } else {
            if (!this.promoService.promoCookieSet(userInfo)) {
              this.promoService.togglePromoState(userInfo, true)
            }
            if (this.getAdminNavOverride(userInfo)) {
              return true
            } else {
              if (this.userType === 'new') {
                this.router.navigateByUrl(`/onboarding/signup/new/subscriptions`)
              } else {
                this.router.navigateByUrl(`/onboarding/signup/returning/welcome`)
              }
            }
          }

        }
        if (this.getAdminNavOverride(userInfo)) {
          return true
        } else {
          return choseInitialPlan
        }
      }),
      finalize(() => this.store.dispatch(new HideLoading(LoadingLabel.ChoseInitialPlanCompleteGuard))),
    )
  }

  // If it's an admin that choose to override navigation for this specific user, let them go
  getAdminNavOverride(userInfo): boolean {
    const overrideForAdmin = this.safeLocalStorageService.getItem('one-override-uninstalled-behavior')
    return overrideForAdmin === userInfo.id
  }

  get userChoseInitialPlanCompleted$(): Observable<boolean> {
    return this.store.pipe(
      select(getUserChoseInitialPlanCompleted),
      filter(completed => completed !== undefined),
      first(),
    )
  }

  get userShop$(): Observable<UserShop> {
    return this.store.pipe(
      select(getUserShop),
      filter(shop => shop !== undefined),
      first(),
    )
  }

  get userInteractions$(): Observable<any> {
    return this.userService.interactions$.pipe(
      filter(interactions => !!interactions),
      first(),
    )
  }

  get userInfo$(): Observable<UserInfo> {
    return this.store.pipe(
      select(getUserInfo),
      filter(info => info !== undefined && !!info.created_at),
      first(),
    )
  }

  get dashboardSettings$(): Observable<any> {
    return this.dashboardSettingsService.getSettings().pipe(
      filter(next => next !== undefined)
    )
  }

  get gettingStartedStatus$(): Observable<GettingStartedStatus> {
    return this.store.pipe(
      select(getGettingStartedStatus),
      filter(next => next !== undefined),
      filter(status => !!status),
      first(),
    )
  }

  shouldSeePrePlansSurvey(shopifyPlan: string): boolean {
    // show pre plans survey for all shopify users
    return !!shopifyPlan
    // return this.showPrePlansSurvey.includes(shopifyPlan)
  }

  shouldSeePlansPage(shopifyPlan) {
    return this.showPlans.includes(shopifyPlan)
  }

  shouldSeeScholarshipPromo(shopifyPlan) {
    return this.showScholarshipPlans.includes(shopifyPlan)
  }

  hasAnotherPromo(userInfo: UserInfo) {
    return this.promoService.promoCookieSet(userInfo)
  }

  isInDateRange(date, ranges) {
    return ranges.some((range) => {
      if (!range.start_date) {
        return false
      }
      let inRange = false
      try {
        const start = new Date(range.start_date)
        inRange = start < date
      } catch {
        return false
      }
      if (inRange && range.end_date) {
        try {
          const end = new Date(range.end_date)
          inRange = end > date
        } catch {
          return false
        }
      }
      return inRange
    })
  }

  updateJotformIfNeeded(plan) {
    if (this.userType === 'new') {
      // also store selection time to calculate time spent till approval
      const submissionId = this.gettingStartedService.getKeyValuePairValue(keyValuePairKey.PrePlansSurveyNewSubmissionId)
      const prefilled5Id = this.gettingStartedService.getKeyValuePairValue(keyValuePairKey.PrePlansSurveyNewPrefilled5)
      if (submissionId && prefilled5Id) {
        const jotFormPayload = {
          [prefilled5Id]: {
            field_1: plan.name || '', // plan name
            field_2: `${+plan.price?.fractional / plan.price?.currency?.subunit_to_unit}` || plan.price?.amount || '', // plan price
            field_3: plan.name === 'Free' ? 'True' : 'False', // free plan doesn't need approval
            field_4: '0', // initialize to 0, will be updated later
            field_5: 'Plans page skipped'
          },
          new: '1',
        }
        // wrap in try catch to make sure this doesn't block anything
        try {
            this.subscription.add(this.apiJotformService.editSubmission(submissionId, jotFormPayload).subscribe())
        } catch (e) {
        }
      }
    }
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.check(next, state)
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.check(next, state)
  }
}
