/* TODO: reduce /v1/dashboard_settings api call, rewrite 'startup_program_enabled' & 'startup_program_offer_dates' logic here */
export enum RouteAvailabilityKey {
  Analytics = 'analytics_disabled',
  EmailStatsPaused = 'email_stats_paused',
  StartupProgramEnabled = 'startup_program_enabled',
  StartupProgramOfferDates = 'startup_program_offer_dates'
}

export interface RouteAvailabilityConfig {
  [RouteAvailabilityKey.Analytics]: boolean,
  [RouteAvailabilityKey.EmailStatsPaused]: boolean,
  disabled_pages: string[]
}

export interface DisabledPageConfig {
  url: string
  title: string
}
