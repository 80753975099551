<div *ngIf="type === swiperType.Review" id="swiper-{{type}}">
  <one-icon class="_swiper-prev swiper-button-disabled" name="chevron-back" (click)="swipePrev()"></one-icon>
  <swiper [config]="reviewSwipeConfig" #swiper (slideChange)="onSlideChange($event)">
      <ng-template *ngFor="let item of reviewsData" swiperSlide>
        <ng-container *ngIf="item.type === 'case_study'; else shop_review">
          <div class="Case-Card" [class.cursor-pointer]="item.redirect_url" (tap)="redirectUrl(item)">
            <div class="Case-Card-Header">
              <div class="Case-Card-Header-Left">
                <div class="Case-Card-Header-Left-Text">{{ item.title }}</div>
              </div>
              <div class="Case-Card-Header-Right">
                <img src="/app_assets/icons/open-new-tab.svg" (click)="redirectUrl(item)" />
              </div>
            </div>
            <div class="Case-Card-Body">
              <div class="Case-Card-Body-Text" [innerHTML]="item.body"></div>
            </div>
            <div class="Case-Card-Footer">
              <div class="Case-Card-Footer-Left">
                <div class="Case-Card-Footer-Left-Text">{{ item.industry }}</div>
                <div class="Case-Card-Footer-Left-Text">{{ item.apps }}</div>
              </div>
              <div class="Case-Card-Footer-Right">
                <div *ngIf="item.image" class="Case-Card-Footer-Right-Image" [style.background]="getBgImage(item.image)" [style.backgroundSize]="'cover'" [style.backgroundPosition]="'center'"></div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-template #shop_review>
          <div class="Review-Card" [class.cursor-pointer]="item.redirect_url" (tap)="redirectUrl(item)">
            <div class="Review-Card-Header">
              <div class="Review-Card-Header-Left">
                <div class="Review-Card-Header-Left-Name">
                  <div class="Review-Card-Header-Left-Name-Text">
                    <ng-container *ngFor="let i of [].constructor(item.stars)"><one-icon name="star"></one-icon></ng-container>
                  </div>
                </div>
              </div>
              <div class="Review-Card-Header-Right">
                <div class="Review-Card-Header-Right-Text">
                  <ng-container *ngIf="item.date">{{ item.date }}</ng-container>
                </div>
              </div>
            </div>
            <div class="Review-Card-Body">
              <div class="Review-Card-Body-Text">{{ item.review }}</div>
            </div>
            <div class="Review-Card-Footer">
              <div class="Review-Card-Footer-Left">
                <div class="Review-Card-Footer-Left-Text">{{ item.store_name }}</div>
                <div class="Review-Card-Footer-Left-Text">{{ item.store_location }}</div>
              </div>
              <div class="Review-Card-Footer-Right">
                <img *ngIf="item.image" class="Review-Card-Footer-Right-Image" [src]="item.image">
              </div>
            </div>
          </div>
        </ng-template>
      </ng-template>
  </swiper>
  <one-icon class="_swiper-next" name="chevron-forward" (click)="swipeNext()"></one-icon>
</div>
