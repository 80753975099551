import { Injectable } from '@angular/core'
import { ApiService } from './api.service'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { DisplayRule } from '../../../shared/models/campaign/display-rules.model'
import { ProductsResponse, CollectionsResponse } from '../../../shared/modules/products-selector/models/Product'
import { AggregateActivity } from '../../interfaces/campaign/aggregate-activity.interface'
import { ThemeTagsResponse } from '../../../shared/modules/theme-filter/models/theme-filter.models'
import { Campaign, CampaignDisplayStatus, CampaignDisplayStatusToFilterStatus, CampaignPluginName, CampaignState } from '../../../shared/models/campaign/campaign'
import { ConversionsResponse } from '../../../pages/dashboard-new/models/dashboard.models'

@Injectable()
export class ApiCampaignService {
  constructor(private apiService: ApiService) {
  }

  getCampaigns(
    status: CampaignDisplayStatus = CampaignDisplayStatus.All,
    full = false,
    show_connections = false,
    page = 1,
    limit = 25,
    searchTerm = ''
  ): Observable<{ campaigns: Campaign[], total_count: number, total_pages: number }> {
    const filterStatus = CampaignDisplayStatusToFilterStatus[status]
    return this.apiService.get(`/v1/campaigns?query=${encodeURIComponent(searchTerm)}&page=${page}&limit=${limit}&status=${filterStatus}${full ? '&full=true' : ''}${show_connections ? '&show_connections=true' : ''}`)
  }

  getActiveCampaigns(): Observable<{ campaigns: Campaign[], total_count: number, total_pages: number }> {
    return this.apiService.get(`/v1/campaigns?status=${CampaignState.On}`)
  }

  getCampaign(id: string): Observable<Campaign> {
    return this.apiService.get(`/v1/campaigns/${id}`).pipe(map(res => {
      // Edge case: if campaign is in draft mode and it has plugins with errors, we need to set the campaign status to invalid
      // Since Draft is a one time (default) status, it will be hard for backend to set campaign to invalid and then back to draft
      if (res?.state === CampaignState.Draft) {
        const activePlugins = Object.keys(res.plugin_types)?.filter(k => res.plugin_types[k])
        activePlugins?.forEach(plugin => {
          const validityExists = !!res[plugin]?.validity // for some plugins, validity doesn't exist
          const isValid = res[plugin]?.validity?.status
          if (validityExists && !isValid) {
            res.state = CampaignState.On
          }
        })
      }
      return res
    }))
  }

  getDisplayConfigs(id: string) {
    // todo: add return type
    return this.apiService.get(`/v1/campaigns/${id}/display_configs`)
  }

  postCampaign(campaign: Campaign): Observable<Campaign> {
    return this.apiService.post('/v1/campaigns/', { campaign })
  }

  postDisplayConfigs(campaignId: string, displayItemsConfig: DisplayRule[]): Observable<Campaign> {
    return this.apiService.post(`/v1/campaigns/${campaignId}/display_configs`, displayItemsConfig)
  }

  putPlugin(campaignId: string, pluginName: CampaignPluginName, data: object): Observable<any> {
    // todo: add return type
    return this.apiService.put(`/v1/campaigns/${campaignId}/${pluginName}`, { [pluginName]: data })
  }

  putCampaign(campaign: Campaign): Observable<Campaign> {
    return this.apiService.put(`/v1/campaigns/${campaign.id}`, { campaign })
  }

  patchCampaign(campaign: Campaign): Observable<Campaign> {
    return this.apiService.patch(`/v1/campaigns/${campaign.id}`, { campaign })
  }

  getWheelThemeTags(): Observable<ThemeTagsResponse> {
    return this.apiService.get('/v1/default_wheel_theme_tags').pipe(map(res => this.mapTagsData(res)))
  }

  getDefaultWheelThemes() {
    // todo: add return type
    return this.apiService.get('/v1/default_wheel_themes')
  }

  getFilteredWheelThemes(filter = {}) {
    return this.apiService.post('/v1/default_wheel_themes', filter)
  }

  getCBThemeTags(): Observable<ThemeTagsResponse> {
    return this.apiService.get('/v1/default_cb_theme_tags').pipe(map(res => this.mapTagsData(res)))
  }

  getDefaultCBThemes() {
    // todo: add return type
    return this.apiService.get('/v1/default_cb_themes')
  }

  getCBThemeById(id: string) {
    return this.apiService.get(`/v1/default_cb_themes/${id}`)
  }

  getFWThemeById(id: string) {
    return this.apiService.get(`/v1/default_wheel_themes/${id}`)
  }

  getNewsletterThemeById(id: string) {
    return this.apiService.get(`/v1/default_newsletter_themes/${id}`)
  }

  getFilteredCBThemes(filter = {}) {
    return this.apiService.post('/v1/default_cb_themes', filter)
  }

  getInfoThemeTags(): Observable<ThemeTagsResponse> {
    return this.apiService.get('/v1/default_free_shipping_theme_tags').pipe(map(res => this.mapTagsData(res)))
  }

  getDefaultFreeShippingThemes() {
    return this.apiService.get('/v1/default_free_shipping_themes')
  }

  getDefaultSalesThemes() {
    return this.apiService.get('/v1/default_sales_announcement_themes')
  }

  getDefaultProductThemes() {
    return this.apiService.get('/v1/default_products_announcement_themes')
  }

  getFilteredFreeShippingThemes(filter = {}) {
    return this.apiService.post('/v1/default_free_shipping_themes', filter)
  }

  getFilteredSalesPopupThemes(filter = {}) {
    return this.apiService.post('/v1/default_sales_announcement_themes', filter)
  }

  getFilteredProductPopupThemes(filter = {}) {
    return this.apiService.post('/v1/default_products_announcement_themes', filter)
  }

  getNewsletterThemeTags(): Observable<ThemeTagsResponse> {
    return this.apiService.get('/v1/default_newsletter_theme_tags').pipe(map(res => this.mapTagsData(res)))
  }

  getDefaultNewsletterThemes() {
    // todo: add return type
    return this.apiService.get('/v1/default_newsletter_themes')
  }

  getFilteredNewsletterThemes(filter = {}) {
    return this.apiService.post('/v1/default_newsletter_themes',  filter)
  }

  getDefaultWheelPatterns() {
    // todo: add return type
    return this.apiService.get('/v1/default_wheel_patterns')
  }

  getShopOrders(limit = 25, start_date: string = '', end_date: string = ''): Observable<ConversionsResponse> {
    // todo: add return type
    return this.apiService.get(`/v1/me/shop/orders?limit=${limit}&start_date=${start_date}&end_date=${end_date}`)
  }

  getShopProducts(
    page: number = 1,
    limit: number = 999,
    query?: string,
  ): Observable<ProductsResponse> {
    const params = { page, limit }
    if (query && query.length) {
      params['query'] = query
    }
    return this.apiService.post(`/v1/me/shop/fetch_products`, params)
  }

  getShopProductsByUniqueID(
    product_ids: Array<string> = [],
    variant_ids: Array<string> = [],
    page: number,
    limit: number,
  ): Observable<ProductsResponse> {
    const params = { page, limit, product_ids, variant_ids }
    // remove this check after BE fixes the issue when it returns all products when FE passes empty array
    if (!product_ids.length) {
      params.product_ids = ['']
    }
    return this.apiService.post(`/v1/me/shop/fetch_products`, params)
  }

  getShopCollectionsByUniqueID(
    collection_ids: Array<string> = [],
    page: number,
    limit: number,
  ): Observable<CollectionsResponse> {
    const params = { page, limit, collection_ids }
    return this.apiService.post(`/v1/me/shop/fetch_collections`, params)
  }

  getShopCollections(
    page: number = 0,
    limit: number = 999,
    query?: string,
  ): Observable<CollectionsResponse> {
    const params = { page, limit }
    if (query && query.length) {
      params['query'] = query
    }
    return this.apiService.post(`/v1/me/shop/fetch_collections`, params)
  }

  getUserActivityPreviewEvents(campaignId: string) {
    // todo: add return type
    return this.apiService.get(`/v1/campaigns/${campaignId}/user_activity_notification/preview_events`)
  }

  getUserActivityPreviewOrders(campaignId: string, locale: string = null) {
    return this.apiService.get(`/v1/campaigns/${campaignId}/user_activity_notification/preview_orders?locale=${locale}`)
  }

  deleteCampaign(campaignId: string) {
    // todo: add return type
    return this.apiService.delete(`/v1/campaigns/${campaignId}`)
  }

  duplicateCampaign(campaignId: string, campaignName: string) {
    // todo: add return type
    return this.apiService.post(`/v1/campaigns/${campaignId}/duplicate`, { name: campaignName })
  }

  postUserActivityWebhook(campaignId: string, name: string) {
    // todo: add return type
    return this.apiService.post(`/v1/campaigns/${campaignId}/user_activity_notification/webhook`, {
      webhook: { name },
    })
  }

  postAllEmailsExport(email: string) {
    // todo: add return type
    return this.apiService.post(`/v1/me/subscriptions/export`, { email: email, start_date: '', end_date: '' })
  }

  getPluginEmails(id: string, page: number, limit: number, pluginName: CampaignPluginName) {
    // todo: add return type
    return this.apiService.get(`/v1/campaigns/${id}/${pluginName}/emails?page=${page}&limit=${limit}`)
  }

  postPluginEmailsExport(id: string, email: string, pluginName: CampaignPluginName) {
    // todo: add return type
    return this.apiService.post(`/v1/campaigns/${id}/${pluginName}/emails/export`, { email })
  }

  deleteSubscriber(id: string) {
    return this.apiService.delete(`/v1/me/subscriptions/${id}`)
  }

  deletePluginSubscriber(campaign_id: string, pluginName: CampaignPluginName, id: string) {
    return this.apiService.delete(`/v1/campaigns/${campaign_id}/${pluginName}/emails/${id}`)
  }

  getAggregateActivityByCampaignId(campaign_id: string, pluginName: CampaignPluginName): Observable<AggregateActivity> {
    return this.apiService.get(`/v1/campaigns/${campaign_id}/${pluginName}/counts`)
  }

  getFullCampaignsList() {
    return this.apiService.get('/v1/campaigns/?full=true')
  }

  getCompactCampaignsList() {
    return this.apiService.get('/v1/campaigns/?compact=true')
  }

  searchCampaignCompact(searchTerm: string) {
    return this.apiService.get(`/v1/campaigns?compact=true&query=${searchTerm}`)
  }

  mapTagsData(res: ThemeTagsResponse): ThemeTagsResponse {
    if (res && res.categories && res.categories.length > 0) {
      return {...res, categories: res.categories.map(category => {
          if (category.tags && category.tags.length > 0) {
            return { ...category, tags: category.tags.map(tag => typeof tag === 'string' ? tag : tag.name)}
          }
          return category
        })
      }
    }
    return res
  }

  campaignsWithSMSorTCPA(): Observable<{ result: boolean }> {
    return this.apiService.get('/v1/campaigns/marketing_sms_or_tcpa_enabled')
  }
}
