import { Injectable } from '@angular/core'
import { PubSub } from './pub-sub'

interface GSHeader {
  title: string,
  subtitle: string,
  pluginName?: string,
  isButton?: boolean,
  buttonURL?: string,
  buttonLabel?: string,
}

@Injectable()
export class GettingStartedHeaderService extends PubSub<GSHeader> {
  constructor() {
    super({title: '', subtitle: '', pluginName: '', isButton: false})
  }

  setTitle(title: string) {
    const value = this.get()
    value.title = title
    value.subtitle = null
    value.isButton = false
    value.pluginName = null
    value.buttonURL = null
    value.buttonLabel = null
    this.set(value)
  }

  setButton(label: string, url: string) {
    this.set({
      title: null,
      subtitle: null,
      pluginName: null,
      isButton: true,
      buttonURL: url,
      buttonLabel: label,
    })
  }
}
