import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router'
import { EmbeddedAppsAvailabilityService } from '../services/embedded-apps-availability.service'
import { Observable, Subject } from 'rxjs'

@Injectable()
export class EmbeddedAppsGuard  {
  private statusIndicators = ['critical', 'maintenance']

  constructor(
    private embeddedAppsAvailability: EmbeddedAppsAvailabilityService,
    private router: Router
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    const subject = new Subject<boolean>()
    const appName = route.data?.appName
    if (appName) {
      this.embeddedAppsAvailability.getStatus(route.data.appName)
        .subscribe(status => {
            const isAvailable = !this.statusIndicators.includes(status?.status?.indicator)
            subject.next(isAvailable)
            if (!isAvailable) {
              this.router.navigate(['unavailable'], { queryParams: { statusLink: status?.page?.url, appName: appName } })
            }
            subject.complete()
          },
          error => {
            subject.next(true)
            subject.complete()
          },
        )

    } else {
      return true
    }
    return subject.asObservable()
  }

}
