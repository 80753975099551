<pf-auth-layout *ngIf="!isHidden">
  <ng-container class="auth-title">
    <ng-container *ngIf="!hasSentEmail; else emailSentTitle">Sign Up</ng-container>
    <ng-template #emailSentTitle>Welcome to {{brandingName}}</ng-template>
  </ng-container>
  <ng-container class="auth-content">
    <div class="Login__Box">
      <ng-container *ngIf="!hasSentEmail; else emailSent">

        <div *ngIf="googleSigninAvailable" class="custom-login-btn _google mb-2" (click)="$event.preventDefault();signUpWithGoogle()">
        </div>
        <div class="custom-login-btn _shopify" (click)="$event.preventDefault();signUpWithShopify()">
        </div>

        <div class="Register__Separator">
          <span>or</span>
        </div>

        <form novalidate [formGroup]="registerForm" *ngIf="!hasSentEmail">
          <p>Enter your email to start using {{brandingName}}</p>
          <div class="form-horizontal">
            <div class="form-group">
              <input name="email" type="email" formControlName="email" placeholder="user@gmail.com" required="required"
                      autocomplete="email" class="form-control">
            </div>
          </div>
          <div *ngIf="errors.length">
            <div class="alert alert-danger">
              <div *ngFor="let error of errors">{{ error }}</div>
            </div>
          </div>
          <button class="pf-button fw filled blue mb-4"
                  (click)="signUpWithEmailLink($event)">
            Sign Up
          </button>
        </form>
      </ng-container>
      <ng-template #emailSent>
        <div *ngIf="hasSentEmail" class="text-center">
          <p class="my-2">Sign-up link sent to</p>
          <h6 class="my-2">«{{ registerForm.value.email || 'your email' }}»</h6>
          <p class="my-2">Please check your mailbox</p>
        </div>
      </ng-template>

      <!-- TODO commented for now, might bring back with captcha -->
      <!-- <div class="Register__Separator">
        <span>or</span>
      </div>

      <form novalidate (submit)="signUpWithEmail($event)" [formGroup]="registerForm">
        <p>Enter your email to start using {{brandingName}}</p>
        <div class="form-horizontal">
          <div class="form-group row">
            <label class="col-sm-3 form-control-label text-sm-right mt-sm-2 pr-sm-0">Email</label>
            <div class="col-sm-9">
              <input name="email" type="email" formControlName="email" placeholder="user@gmail.com" required="required"
                     autocomplete="email" class="form-control">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-3 form-control-label text-sm-right mt-sm-2 pr-sm-0">Password</label>
            <div class="col-sm-9">
              <input class="form-control" placeholder="**************" type="password" formControlName="password"
                     name="password" required="required" autocomplete="new-password">
            </div>
          </div>
        </div>
        <div *ngIf="errors.length">
          <div class="alert alert-danger">
            <div *ngFor="let error of errors">{{ error }}</div>
          </div>
        </div>
        <button class="pf-button fw filled blue mb-4"
                type="submit">
          Sign Up
        </button>
      </form> -->

      <ng-container *ngIf="!hasSentEmail; else emailSentBtn">
        <p *ngIf="!hasSentEmail">Already have an account? <a routerLink="/login">Login here</a></p>
      </ng-container>
      <ng-template #emailSentBtn>
        <p *ngIf="hasSentEmail"><a routerLink="/register" (click)="hasSentEmail = false">Back</a></p>
      </ng-template>
    </div>
  </ng-container>
</pf-auth-layout>

<pf-loading-overlay></pf-loading-overlay>
