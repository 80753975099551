import { Injectable } from '@angular/core'
import { Logger } from '../../core/services/logger.service'
import { LogLabel } from '../models/logger/log-label.model'

@Injectable()
export class SegmentAnalyticsService {
    constructor(
        private  logger: Logger,
    ) {}

    isAnalyticsLoaded(method = 'track') {
        if (window && window.analytics && window.analytics[method]) {
            return true
        }
        this.logger.error(LogLabel.SegmentAnalyticsService, `Failed to execute analytics.'${method}'`)
        return false
    }

    load(apiKey: string) {
        if (this.isAnalyticsLoaded('load')) {
            this.logger.log(LogLabel.SegmentAnalyticsService, 'Segment loaded successfully')
            window.analytics._writeKey = apiKey
            window.analytics.load(apiKey)
        }
    }

    identify(userId: string, payload: any) {
        if (this.isAnalyticsLoaded('identify')) {
            window.analytics.identify(userId, payload)
        }
    }

    page() {
        if (this.isAnalyticsLoaded('page')) {
            window.analytics.page()
        }
    }

    track(name: string, payload: any = null): Promise<any> {
        if (this.isAnalyticsLoaded('track')) {
            if (payload) {
               return window.analytics.track(name, {
                  ...payload,
                  ...this.urlData()
                })
            } else {
               return window.analytics.track(name, {...this.urlData()})
            }
        }
    }

    urlData() {
        const currentUrl = window.location.href
        if (!currentUrl.includes('/app/')) {
            return {
                url: currentUrl,
                path: new URL(currentUrl).pathname
            }
        }
        // Construct a new URL object to make manipulation easier
        const originalUrl = new URL(currentUrl)
        // Remove '/app/' from the pathname
        const newPathname = originalUrl.pathname.replace('/app/', '/')
        // Construct the new URL without '/app/'
        const newUrl = `${originalUrl.protocol}//${originalUrl.host}${newPathname}${originalUrl.search}${originalUrl.hash}`
        // Construct and return the desired object with the new URL and path
        return {
            url: newUrl,
            path: newPathname
        }
    }
}
