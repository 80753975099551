import { Component, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core'
import { StoreState } from '../../../../store/store.state'
import { select, Store } from '@ngrx/store'
import { ActivatedRoute, Router } from '@angular/router'
import { HideLoading, ResetLoading, ShowLoading } from '../../../../store/loading/loading.actions'
import { ValidateCouponResponse } from '../../../../shared/models/validate-coupon-response'
import {
  getSignUpFlowCompleted, getUserPromo,
} from '../../../../store/user/user.selectors'

import * as _ from 'lodash'
import { UserInfo } from '../../../../store/user/user.state'
import { forkJoin, Subscription } from 'rxjs'
import { SMSService } from '../../../../shared/services/sms.service'
import { SegmentAnalyticsService } from '../../../../shared/services/segment-analytics.service'
import { GettingStartedService } from '../../../../core/services/getting-started.service'
import { UserService } from '../../../../core/services/user.service'
import * as mustache from 'mustache'
import { BrandingService } from '../../../../core/services/branding.service'
import { SubscriptionPlan, SubscriptionPlanData, UsagePlan } from '../../../models/subscription-plan.model'
import { ConfirmModalComponent, ConfirmModalConfig } from '../../modals/confirm.modal/confirm.modal.component'
import { Promo } from '../../../modules/promo/models/promo.model'
import { ShopifySubscribersApiService } from '../../../../pages/subscribers/services/shopify-subscribers-api.service'
import moment from 'moment'
import { featureCardsMockData } from './data/feature-cards-mock-data'
import { ShopifyService } from '../../../../core/services/shopify.service'
import { PaymentService } from '../../../../core/services/payment.service'
import { ChargeResponse } from '../../../models/charge-response.model'
import { LogLabel } from '../../../models/logger/log-label.model'
import { Logger } from '../../../../core/services/logger.service'
import { Redirect } from '@shopify/app-bridge/actions'
import { SetCurrentUserInfo, SetUserChosePlan } from '../../../../store/user/user.actions'
import { CustomSnackbarService } from '../../../modules/custom-snackbar/custom-snackbar.service'
import { PromoService } from '../../../modules/promo/services/promo.service'
import { filter, finalize, take } from 'rxjs/operators'
import { UsagePlanMetricType } from '../models/usage-plan-metric.model'
import { mockProdPlans } from './data/mock-prod-plans-data'
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms'
import { ApiJotformService } from '../../../../core/services/api/api-jotform.service'
import { keyValuePairKey } from '../../../models/key-value-pair.model'
import { PlatformOnboardingStatus } from '../../../models/platform-onboarding.model'
import { CrispService } from '../../../../core/services/crisp.service'
import { CommonModule } from '@angular/common'
import { PromoModule } from '../../../modules/promo/promo.module'
import { OneTooltipDirective } from '../../../directives/one-tooltip.directive'
import { FloatingTabModule } from '../../../modules/floating-tab/floating-tab.module'
import { NgSelectModule } from '@ng-select/ng-select'
import { ShortNumberPipe } from '../../../pipes/short-number.pipe'
import { SupportBoxsComponent } from '../../support-boxs/support-boxs.component'
import { LoadingOverlayComponent } from '../../loading-overlay/loading-overlay.component'
import { PlansVersion } from '../models/plans-version.model'
import { RouteHeaderUrl } from '../../one-header/header-navigation.model'
import { MatDialog } from '@angular/material/dialog'
import { AllowedFreePlanUsers } from '../allowed-free-plan-users'

@Component({
    selector: 'pf-plans-view-new-2',
    templateUrl: './plans-2.component.html',
    styleUrls: ['./plans-2.component.scss'],
    standalone: true,
    imports: [
      CommonModule,
      ReactiveFormsModule,
      PromoModule,
      OneTooltipDirective,
      FloatingTabModule,
      NgSelectModule,
      ShortNumberPipe,
      SupportBoxsComponent,
      LoadingOverlayComponent,
    ]
})
export class PlansViewNew2Component implements OnDestroy, OnInit {
  @Input() pageTitle = ''
  @Input() userType = 'new'
  @Input() noCurrentPlan // A dirty temporary hack
  plans: SubscriptionPlan[] = []
  visiblePlans = []

  shopType: string
  userInfo: UserInfo
  userDomain: string
  currentPlan: SubscriptionPlanData = null
  currentUsagePlan: UsagePlan = null
  currentPlanIdentifier = null
  coupon: ValidateCouponResponse = null
  staticPromo: Promo = {
    promo_code: 'ONE STORE BFCM22',
    promo_type: 'bfcm',
    promo_referrer: 'onestore',
    promo_view: 'bfcm',
  }
  userPromo: Promo = this.userType !== 'existing' ? this.staticPromo : null
  brandingName: string
  subscription = new Subscription()
  // initialPaths = '/onboarding/signup/new/apps/view/standard'
  initialPaths = `/${RouteHeaderUrl.apps}`
  knownCustomers = 0
  activeKnownCustomers = 0
  totalsLoaded = false

  readonly usagePlanMetric: UsagePlanMetricType = UsagePlanMetricType.ActiveKnownCustomers

  featureCards = featureCardsMockData

  plansOrder = [
    'new_free',
    'new_starter',
    'new_basic',
    'new_pro',
    'new_enterprise'
  ]

  freePlanDisabled = false
  defaultPromoApplied = false

  showBookDemoButton = false

  floatingTabDiscountHidden = true
  floatingTabDiscountCode: string
  floatingTabDiscountValue: number

  selectControls = {}
  plansVersion = PlansVersion.V1

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private store: Store<StoreState>,
    private brandingService: BrandingService,
    private renderer: Renderer2,
    private smsService: SMSService,
    private segmentAnalyticsService: SegmentAnalyticsService,
    private shopifySubscribersApiService: ShopifySubscribersApiService,
    private gettingStartedService: GettingStartedService,
    private userService: UserService,
    private shopifyService: ShopifyService,
    private route: ActivatedRoute,
    private crispService: CrispService,
    private paymentService: PaymentService,
    private logger: Logger,
    private snackbarService: CustomSnackbarService,
    private apiJotformService: ApiJotformService,
    ) {
    this.brandingName = this.brandingService.getBrandingData().name

    this.subscription.add(this.store.pipe(select(getSignUpFlowCompleted)).subscribe())

    this.segmentAnalyticsService.track('Viewed Plans', {
      state: {
        onboarding: true,
        step: 'view_plans',
        group: 'choose_plan',
      },
    })
  }

  ngOnInit() {
    this.renderer.addClass(document.body, '_white-bg')
    this.renderer.addClass(document.body, '_no-content-top-padding')
    this.userInfo = this.userService.userInfo
    this.shopType = this.userInfo.shop?.type
    this.userDomain = this.userInfo.shop?.domain

    this.store.dispatch(new ShowLoading('LoadingPlans'))
    this.subscription.add(
      forkJoin([
        this.shopifyService.contactsCount$.pipe(filter(next =>next !== null), take(1)),
        this.shopifyService.activeKnownCustomersCount$.pipe(filter(next =>next !== null), take(1)),
        this.paymentService.getPlansRequest(this.plansVersion),
      ]).pipe(
        finalize(()=> this.store.dispatch(new HideLoading('LoadingPlans')))
      ).subscribe(([total, akc, plansData]) => {
        this.plans = plansData.plans
          // .filter((plan) => plan.version === 1 || plan.payment_gateway_plan_identifier === 'new_starter')
          .filter((plan) => plan.version === this.plansVersion && plan.public)
          .sort((a, b) => +a.price?.fractional - +b.price?.fractional)


        this.knownCustomers = total
        this.activeKnownCustomers = akc

        // Toggle demo button visibility
        const allowedDemoShopifyPlans = ['staff', 'staff_business', 'plus_partner_sandbox', 'unlimited', 'shopify_plus']
        const currentShopifyPlan = _.get(this.userInfo, 'shop.profile.plan_name')
        this.showBookDemoButton = allowedDemoShopifyPlans.includes(currentShopifyPlan) || this.activeKnownCustomers >= 250

        // If the plan is already selected, we need to create a static plan object
        // which will be used in card, so the plan usage selection will not affect the card
        this.createCurrentPlanIfNeeded()

        // Decide if visible, discount amount and code for floating tab
        // disable floating discount button for now
        // if (this.activeKnownCustomers >= 1501 && this.activeKnownCustomers <= 10000) {
        //   this.floatingTabDiscountValue = 25
        //   this.floatingTabDiscountCode ='oneapp'
        //   this.floatingTabDiscountHidden = false
        //   this.userPromo = {
        //     promo_code: '',
        //     promo_type: 'ShopifyCoupon',
        //     promo_referrer: 'onestore',
        //     promo_view: 'bfcm',
        //   }
        // } else if (this.activeKnownCustomers >= 10001 && this.activeKnownCustomers <= 150000) {
        //   this.floatingTabDiscountValue = 35
        //   this.floatingTabDiscountCode = 'onestore'
        //   this.floatingTabDiscountHidden = false
        //   this.userPromo = {
        //     promo_code: '',
        //     promo_type: 'ShopifyCoupon',
        //     promo_referrer: 'onestore',
        //     promo_view: 'bfcm',
        //   }
        // }

        this.currentPlanIdentifier = _.get(this.userInfo, 'subscription.plan.payment_gateway_plan_identifier', false)
        if (this.plans.length) {

          if (this.activeKnownCustomers >= 251) {

            this.freePlanDisabled = !(this.userType === 'existing' && AllowedFreePlanUsers.includes(this.userInfo?.id));
          }

          this.updateVisiblePlans()

          this.totalsLoaded = true

          // Generate ranges based on what we have in plans response
          this.plans.forEach((plan) => {
            this.selectControls[plan.payment_gateway_plan_identifier] = new UntypedFormControl(null)
            if (plan.usage_plans) {


              plan.usage_plans = plan.usage_plans.filter(p => p.metric === this.usagePlanMetric).sort((a, b) => +a.min - +b.min)

              // generate dummy usage plans for custom price tier based on highest available usage plan
              const highestAvailableUsage = plan.usage_plans[plan.usage_plans.length - 1]
              const customPriceTier = {
                id: 'custom_price_tier',
                title: `${highestAvailableUsage.max?.toLocaleString('en-US')}+`,
                customPriceTier: true,
                min: highestAvailableUsage.max + 1,
                max: Number.MAX_SAFE_INTEGER,
                metric: this.usagePlanMetric,
              } as UsagePlan
              plan.usage_plans.push(customPriceTier)

              // preselected dropdown value should be either active known customers, or whatever usage plan the user has preselected
              let targetKnownCustomers = this.activeKnownCustomers
              if (this.userType === 'existing' && this.currentUsagePlan && this.currentUsagePlan?.min && this.currentUsagePlan?.min !== 0) {
                targetKnownCustomers = this.currentUsagePlan?.min
              }

              plan.usage_plans.forEach((option, index, arr) => {
                if (this.currentUsagePlan?.id === option?.id) {
                  option.selected = true
                }
                // skip customPriceTier option and set it later
                if (!option.customPriceTier && targetKnownCustomers >= option.min && targetKnownCustomers <= option.max) {
                  this.selectControls[plan.payment_gateway_plan_identifier].patchValue(option)
                  this.changeUsagePlan(plan.payment_gateway_plan_identifier)
                  this.segmentAnalyticsService.track('Plans - Preselect Number of Profiles Field', {value: option.min})
                } else if (this.activeKnownCustomers > option.max) {
                  option.disabled = true
                }
              })

              // If contactsTotalCount is out of bounds, select the last option
              const lastOption = plan.usage_plans[plan.usage_plans.length - 1]
              if (lastOption && this.activeKnownCustomers > lastOption.max) {
                this.selectControls[plan.payment_gateway_plan_identifier].patchValue(lastOption.id)
                this.changeUsagePlan(plan.payment_gateway_plan_identifier)
                this.segmentAnalyticsService.track('Plans - Preselect Number of Profiles Field', {value: lastOption.min})
              }

              // preselect custom price tier if store has more akcs than the highest available usage plan
              if (this.activeKnownCustomers > highestAvailableUsage.max) {
                this.selectControls[plan.payment_gateway_plan_identifier].patchValue(customPriceTier)
              }

              this.updateVisiblePlans()
            }
          })
        }

        if (this.userType === 'existing' && this.currentPlanIdentifier) {
          // if user has an applied coupon show that data on the current plan (reduced price, etc.)
          const currentlyAppliedCoupon = _.get(this.userInfo, 'subscription.coupon')
          const currentPlan = this.plans.find(plan => plan?.payment_gateway_plan_identifier === this.currentPlanIdentifier)
          if (currentlyAppliedCoupon && currentPlan && this.plansOrder.includes(this.currentPlanIdentifier)) {
            this.modifyPlan(currentPlan, currentlyAppliedCoupon, true)
            this.updateVisiblePlans()
          }
        }


        // disable static promo
        // this.applyStaticPromoCodeIfNeeded()
      }, err => {
        if ((err?.status === 429 || err?.status === 400) && err?.error?.message) {
          this.snackbarService.showError({ title: err.error?.title, text: err.error?.message })
        }
      })
    )

    this.subscription.add(this.store.select(getUserPromo).subscribe((promo: Promo) => {
      this.userPromo = promo
    }))
  }

  applyStaticPromoCodeIfNeeded() {
    // experiment - might need to be removed in future
    if (this.userPromo || this.userType === 'existing') {
      return
    }
    const contacts = this.activeKnownCustomers

    if (contacts >= 1501 && contacts <= 10000) {
      this.userPromo = {
        promo_code: 'ONE APP BFCM22',
        promo_type: 'ShopifyCoupon',
        promo_referrer: 'onestore',
        promo_view: 'bfcm',
      }
    }

    if (contacts >= 10001 && contacts <= 150000) {
      this.userPromo = {
        promo_code: 'ONE SHOPIFY APP BFCM22',
        promo_type: 'ShopifyCoupon',
        promo_referrer: 'onestore',
        promo_view: 'bfcm',
      }
    }
  }

  createCurrentPlanIfNeeded() {
    let currentPlan = _.get(this.userInfo, 'subscription.plan')
    let currentUsagePlan = _.get(this.userInfo, 'subscription.usage_plan')

    if (currentPlan && currentUsagePlan) {
      currentPlan = {
        ...currentPlan,
        price: {...currentUsagePlan.price},
      } as any
      const currentlyAppliedCoupon = _.get(this.userInfo, 'subscription.coupon')
      if (currentlyAppliedCoupon) {
        this.modifyPlan(currentPlan as any, currentlyAppliedCoupon, true)
      }
      currentPlan = {
        ...currentPlan,
        price: {
          ...currentPlan.price,
          amount: `${+currentPlan.price.fractional / currentPlan.price.currency.subunit_to_unit}`,
        } as any,
      }
      this.currentUsagePlan = currentUsagePlan
      this.currentPlan = currentPlan as any
    }
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, '_white-bg')
    this.renderer.removeClass(document.body, '_no-content-top-padding')
    this.subscription.unsubscribe()
    this.store.dispatch(new ResetLoading())
  }

  contactsOpen() {
    this.segmentAnalyticsService.track('Plans - Click Number of Profiles Field')
  }

  contactsOptionChanged(planId) {
    Object.keys(this.selectControls).forEach(key => {
      const shouldUpdate = true
      if (shouldUpdate) {
        const plan = this.plans.find(p => p.payment_gateway_plan_identifier === key)
        const usagePlan = plan.usage_plans?.find(up => up.min === this.selectControls[planId].value?.min)
        if (usagePlan) {
          this.selectControls[key].patchValue(usagePlan)
        }
        this.changeUsagePlan(key)
      }
    })
    this.updateVisiblePlans()
  }

  changeUsagePlan(planId) {
    const plan = this.plans.find(p => p.payment_gateway_plan_identifier === planId)
    // this.plans.forEach((plan) => {
      plan.misconfigured = false
      if (plan.usage_plans) {
        const usagePlan = plan.usage_plans.find(option => option.id === this.selectControls[plan.payment_gateway_plan_identifier]?.value?.id)
        if (usagePlan) {
          delete plan.price
          plan.price = {
            ...usagePlan.price,
          }
          // Need to reset initial price to be able to calculate coupon properly
          if (plan?.initial_price) {
            delete plan.initial_price
          }
          this.segmentAnalyticsService.track('Plans - Select Number of Profiles Field', {min: usagePlan.min})
        } else {
          // If we don't have a usage plan for this plan, alert the user
          // const allowedNoUsagePlans = ['new_free', 'new_enterprise', 'new_custom']
          // if (!allowedNoUsagePlans.includes(plan.payment_gateway_plan_identifier)) {
          //   plan.misconfigured = true
          // }
        }
      }
    // })
    if (this.userType === 'existing' && this.currentPlanIdentifier) {
      const currentlyAppliedCoupon = _.get(this.userInfo, 'subscription.coupon')
      if (currentlyAppliedCoupon) {
        this.applyPlansDiscount(currentlyAppliedCoupon, this.currentPlanIdentifier)
      }
    }
    if (this.coupon) {
      this.applyPlansDiscount(this.coupon)
    }
  }

  updateVisiblePlans() {    /* Make sure plans are set */
    if (!this.plans) {
      return
    }

    this.visiblePlans = this.plans.map(plan => this.generateVisiblePlan(plan))
    this.visiblePlans = this.visiblePlans.sort((a, b) => a.order - b.order)
  }

  generateVisiblePlan(plan: SubscriptionPlan) {
    const planId = plan.payment_gateway_plan_identifier
    const isFreePlan = 'new_free' === planId
    const isCustomPlan = ['new_enterprise', 'new_custom'].includes(planId)
    const isCurrentPlan = this.currentPlanIdentifier === planId && !(isFreePlan && this.freePlanDisabled)
    let lowerPlanSelected = false
    let customPriceTier = this.selectControls[planId]?.value?.customPriceTier
    let showCustomPrice = customPriceTier
    let displayPrice = null
    if (this.currentPlanIdentifier && this.plansOrder.includes(this.currentPlanIdentifier)) {
      lowerPlanSelected = this.plansOrder.indexOf(this.currentPlanIdentifier) < this.plansOrder.indexOf(planId)
    }

    // show their current plan price for edge case when existing user has more akcs then our highest usage plan
    // last usage plan is always a custom price tier, so here we need to check the second to last usage plan
    if (plan.usage_plans[plan.usage_plans.length - 2].max < this.activeKnownCustomers &&
      isCurrentPlan &&
      !isFreePlan &&
      this.userInfo?.subscription?.price?.fractional) {
      displayPrice = `${this.userInfo?.subscription?.price.fractional / this.userInfo?.subscription?.price?.currency?.subunit_to_unit}`
      showCustomPrice = false
    }

    const populatedPlan: SubscriptionPlanData = {
      ...plan,
      title: plan.name,
      subtitle: '',
      price: {
        ...plan.price,
        amount: displayPrice || `$${+plan?.price?.fractional / plan?.price?.currency?.subunit_to_unit}`,
        period: plan.interval,
      },
      plan_cta_action: 'choose_plan',
      plan_cta_label: 'Choose Plan',
      isFree: isFreePlan,
      isCustom: isCustomPlan,
      isCustomPriceTier: customPriceTier ,
      limits: [],
      features: [],
      order: this.plansOrder.indexOf(plan.payment_gateway_plan_identifier),
      selected: this.currentPlanIdentifier === planId && this.currentUsagePlan?.id === this.selectControls[planId]?.value?.id,
      disabled: false,
      content: [
        {
          id: 'apps',
          title: 'ONE Apps',
          order: 1,
          points: [
            {
              title: 'Access to All Apps',
              active: true,
            },
            {
              title: `${isCustomPlan ? 'Custom' : plan.max_active_campaigns} Active App Campaigns`,
              active: true,
              withTooltip: true,
              tooltipText: `<div class="maxw-400"">
                  Active Campaign is anything that can be published on the ONE app such as pop up campaigns,
                  email/sms automations, or one-click upsell campaigns.
                  For ex: 1 pop up + 2 email automations + 1 sms automations + 1 one-click upsell = 5 active campaigns.
                  Drafts, Email/SMS Broadcasts, and Pop Up Autoresponders do NOT count towards your active campaign limit.
                </div>`
            },
            {
              title: 'Removable Branding',
              active: false,
            },
            {
              title: 'A/B Testing',
              active: false,
            },
            {
              title: 'Custom Reports',
              active: false,
            }
          ]
        },
        {
          id: 'cdp',
          title: 'Customer Data Platform (CDP)',
          order: 2,
          points: [
            {
              title: 'Customer Profile Sync',
              active: true,
            },
            {
              title: 'Marketing Consent Management',
              active: false,
            },
            {
              title: 'Cart / Checkout / Order Events',
              active: false,
            },
            {
              title: 'ONE App Events',
              active: false,
            },
            {
              title: 'Data Syncs Every 500 ms',
              active: false,
            },
          ]
        },
        {
          id: 'support',
          title: 'Support',
          order: 3,
          points: [
            {
              title: 'Live Chat, Email & Video Call',
              active: true,
            },
            {
              title: 'Priority Custom Theme Requests',
              active: false,
            },
            {
              title: 'Dedicated Campaign Manager',
              active: false,
            },
            {
              title: '99.99% Guaranteed Uptime',
              active: false,
            },
          ]
        },

        // {
          // id: 'pdp',
          // title: 'Product Data Platform (PDP)',
          // order: 3,
          // points: [
          //   {
          //     title: 'Product Catalog Sync',
          //     active: true,
          //   },
          //   {
          //     title: 'Product Editor',
          //     active: true,
          //   },
          // ]
        // },
      ]
    }

    const cdp = populatedPlan.content.find(c => c.id === 'cdp')
    const apps = populatedPlan.content.find(c => c.id === 'apps')
    const support = populatedPlan.content.find(c => c.id === 'support')

    let trialAvailable = plan.trial_period
    if (trialAvailable !== 0 && (this.userInfo?.subscription?.is_trial || this.userInfo?.subscription?.trial_period_start)) {
      trialAvailable = this.userInfo?.subscription?.trial_days_remaining
    }
    switch (plan.payment_gateway_plan_identifier) {
      case 'new_free':
        populatedPlan.title = 'FREE'
        populatedPlan.subtitle = 'Save time & money with ONE App.'
        populatedPlan.price.custom = 'Free'
        populatedPlan.disabled = this.freePlanDisabled
        populatedPlan.plan_cta_label_override = 'Choose Plan'
        if (this.freePlanDisabled) {
          populatedPlan.disabled = true
          populatedPlan.subtext = 'Limited to stores with 0-250 active customers'
        }
        if (customPriceTier) {
          populatedPlan.plan_cta_label_override = 'Book a Call'
          populatedPlan.plan_cta_action_override = 'book_demo'
        }
        break
      case 'new_basic':
        if (lowerPlanSelected) {
          populatedPlan.plan_cta_label = 'Upgrade Plan'
          if (trialAvailable) {
            populatedPlan.subtext = `${trialAvailable}-Day Free Trial`
          }
        } else if (trialAvailable) {
          populatedPlan.plan_cta_label = `Start ${trialAvailable}-Day Free Trial`
        }
        populatedPlan.subtitle = 'Own your customer data, own your marketing.'
        if (customPriceTier) {
          if (showCustomPrice) {
            populatedPlan.price.custom = 'Custom Price'
            populatedPlan.price.class = 'font-42'
          }
          populatedPlan.plan_cta_label_override = 'Request Custom Price'
          populatedPlan.plan_cta_action_override = 'send_crisp_message'
        }
        if (cdp) {
          cdp.points = cdp.points.map((point) => ({
            ...point,
            active: point.active || [
              'Marketing Consent Management',
            ].includes(point.title)
          }))
        }
        if (apps) {
          apps.points = apps.points.map((point) => ({
            ...point,
            active: point.active || [
              'Removable Branding',
            ].includes(point.title)
          }))
        }

        if (support) {
          support.points = support.points.map((point) => ({
            ...point,
            active: point.active || [
              'Priority Custom Theme Requests',
            ].includes(point.title)
          }))
        }
        break
      // case 'new_starter':
      //   if (lowerPlanSelected) {
      //     populatedPlan.plan_cta_label = 'Upgrade Plan'
      //     if (trialAvailable) {
      //       populatedPlan.subtext = `${trialAvailable}-Day Free Trial`
      //     }
      //   } else if (trialAvailable) {
      //     populatedPlan.plan_cta_label = `Start ${trialAvailable}-Day Free Trial`
      //   }
      //   populatedPlan.subtitle = 'Own your customer data, own your marketing.'
      //   if (cdp) {
      //     cdp.points = cdp.points.map((point) => ({
      //       ...point,
      //       active: point.active || [
      //         'Marketing Consent Management',
      //       ].includes(point.title)
      //     }))
      //   }
      //   if (apps) {
      //     apps.points = apps.points.map((point) => ({
      //       ...point,
      //       active: point.active || [
      //         'Removable Branding',
      //       ].includes(point.title)
      //     }))
      //   }
      //   if (support) {
      //     support.points = support.points.map((point) => ({
      //       ...point,
      //       active: point.active || [
      //         'Priority Custom Theme Requests',
      //         'Dedicated Campaign Manager',
      //       ].includes(point.title)
      //     }))
      //   }
      //   break
      case 'new_pro':
        if (lowerPlanSelected) {
          populatedPlan.plan_cta_label = 'Upgrade Plan'
          if (trialAvailable) {
            populatedPlan.subtext = `${trialAvailable}-Day Free Trial`
          }
        } else if (trialAvailable) {
          populatedPlan.plan_cta_label = `Start ${trialAvailable}-Day Free Trial`
        }
        populatedPlan.subtitle = 'Scale your customer experience.'
        if (customPriceTier) {
          if (showCustomPrice) {
            populatedPlan.price.custom = 'Custom Price'
            populatedPlan.price.class = 'font-42'
          }
          populatedPlan.plan_cta_label_override = 'Request Custom Price'
          populatedPlan.plan_cta_action_override = 'send_crisp_message'
        }
        if (cdp) {
          cdp.points = cdp.points.map((point) => ({
            ...point,
            active: point.active || [
              'Cart / Checkout / Order Events',
              'Marketing Consent Management',
            ].includes(point.title)
          }))
        }
        if (apps) {
          apps.points = apps.points.map((point) => ({
            ...point,
            active: point.active || [
              'Removable Branding',
              'A/B Testing',
            ].includes(point.title)
          }))
        }
        if (support) {
          support.points = support.points.map((point) => ({
            ...point,
            active: point.active || [
              'Priority Custom Theme Requests',
              'Dedicated Campaign Manager',
            ].includes(point.title)
          }))
        }
        break
      case 'new_enterprise':
      case 'new_custom':
        populatedPlan.title = 'Custom'
        populatedPlan.subtitle = 'Request pricing that lets us grow together!'
        populatedPlan.price.custom = 'Custom Price'
        populatedPlan.price.class = 'font-42'
        populatedPlan.plan_cta_label_override = 'Request Custom Price'
        populatedPlan.plan_cta_action_override = 'send_crisp_message'
        populatedPlan.plan_cta_payload_override = `Hi ONE team, we currently have\n\n Active Customers: ${this.activeKnownCustomers} \nKnown Customers: ${this.knownCustomers}\n\n and we\'d like to request a custom price.`
        if (customPriceTier) {
          populatedPlan.plan_cta_label_override = 'Request Custom Price'
          populatedPlan.plan_cta_action_override = 'send_crisp_message'
        }
        if (cdp) {
          cdp.points = cdp.points.map((point) => ({
            ...point,
            active: point.active || [
              'Marketing Consent Management',
              'Cart / Checkout / Order Events',
              'ONE App Events',
              'Data Syncs Every 500 ms',
            ].includes(point.title)
          }))
        }
        if (apps) {
          apps.points = apps.points.map((point) => ({
            ...point,
            active: point.active || [
              'Removable Branding',
              'A/B Testing',
              'Custom Reports',
            ].includes(point.title)
          }))
        }
        if (support) {
          support.points = support.points.map((point) => ({
            ...point,
            active: point.active || [
              'Priority Custom Theme Requests',
              'Dedicated Campaign Manager',
              '99.99% Guaranteed Uptime',
            ].includes(point.title)
          }))
        }
        break
    }
    if (populatedPlan.selected) {
      populatedPlan.plan_cta_label_override = 'Current Plan'
    }
    return populatedPlan;
  }

  onSelectPlanClick(plan: SubscriptionPlan) {
    if (plan.misconfigured) {
      this.sendMisconfiguredPlanCrispMessage(plan)
      return
    }
    const action = plan.plan_cta_action_override || plan.plan_cta_action
    switch (action) {
      case 'book_demo':
        this.openCalendlyWidget(plan.payment_gateway_plan_identifier)
        return
      case 'send_crisp_message':
        this.sendCrispMessage(plan)
        return
      case 'redirect_page':
        this.subscription.add(
          this.gettingStartedService.updateScholarshipStatus({ plan_id: plan.payment_gateway_plan_identifier, plan_name: plan.name }).subscribe()
        )
        let url = plan.plan_cta_payload_override || plan.plan_cta_payload
        url += `?selectedPlan=${plan.name}`
        url += `&onePlanid=${plan.payment_gateway_plan_identifier}`
        this.userInfo = this.userService.userInfo
        if (_.get(this.userInfo, 'shop.store_url')) {
          url += `&shopifyUrl=${_.get(this.userInfo, 'shop.store_url')}`
          url += `&oneShopurl=${_.get(this.userInfo, 'shop.store_url')}`
        }
        if (_.get(this.userInfo, 'shop.profile.name')) {
          url += `&shopName=${_.get(this.userInfo, 'shop.profile.name')}`
          url += `&oneShopname=${_.get(this.userInfo, 'shop.profile.name')}`
        }
        if (_.get(this.userInfo, 'shop.profile.plan_name')) {
          url += `&oneShopifyplan=${_.get(this.userInfo, 'shop.profile.plan_name')}`
        }
        if (_.get(this.userInfo, 'id')) {
          url += `&oneUserid=${_.get(this.userInfo, 'id')}`
        }
        if (_.get(this.userInfo, 'address.country.code')) {
          url += `&oneCountry=${_.get(this.userInfo, 'address.country.code')}`
        }
        if (this.userPromo) {
          if (_.get(this.userPromo, 'promo_code')) {
            url += `&onePromocode=${_.get(this.userPromo, 'promo_code')}`
          }
          if (_.get(this.userPromo, 'promo_type')) {
            url += `&onePromotype=${_.get(this.userPromo, 'promo_type')}`
          }
          if (_.get(this.userPromo, 'promo_referrer')) {
            url += `&onePromoreferrer=${_.get(this.userPromo, 'promo_referrer')}`
          }
          if (_.get(this.userPromo, 'promo_view')) {
            url += `&onePromoview=${_.get(this.userPromo, 'promo_view')}`
          }
        }
        if (window && window.location) {
          url += `&referrer=${window.location.origin + window.location.pathname}`
        }
        if (_.get(navigator, 'language')) {
          url += `&language=${_.get(navigator, 'language')}`
        }
        if (this.isValidHttpUrl(url)) {
          window.open(url, '_blank')
        } else {
          // if plan cta payload is not a valid url open default crisp message
          this.sendCrispMessage({...plan, plan_cta_payload: ''})
        }
        break
      case 'open_popup':
        const dialogRef = this.dialog.open(ConfirmModalComponent, {
          width: '560px',
          data: {
            title: plan.name,
            html: plan.plan_cta_payload_override || plan.plan_cta_payload,
            footerClass: 'justify-content-end mb-0',
            cancelButton: {
              text: 'Cancel',
              classes: 'pf-button outline gray'
            },
            acceptButton: {
              text: 'Continue',
              classes: 'pf-button filled blue'
            }
          } as ConfirmModalConfig,
        })
        this.subscription.add(dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.choosePlan(plan)
          }
        }))
        break
      case 'choose_plan':
      default:
        this.choosePlan(plan)
    }
  }

  openCalendlyWidget(plan_id: string) {
    if (window && window['Calendly'] && window['Calendly'].initPopupWidget) {
      window['Calendly'].initPopupWidget({
        url: 'https://calendly.com/one-csm',
        utm: {
          utmCampaign: plan_id === 'new_enterprise' ? 'demo_enterprise_plan' : 'demo_free_plan'
        }
      })
      if (plan_id === 'new_enterprise') {
        this.segmentAnalyticsService.track('Start Plan - Enterprise Book Demo')
      }
      if (plan_id = 'new_free') {
        this.segmentAnalyticsService.track('Book Demo - Free Plan')
      }
    }
  }

  isValidHttpUrl(string) {
    let url

    try {
      url = new URL(string)
    } catch (_) {
      return false
    }

    return url.protocol === 'http:' || url.protocol === 'https:'
  }

  choosePlan(plan) {
    const usagePlanId = this.selectControls[plan.payment_gateway_plan_identifier]?.value?.id
    const planWithoutUsagePlans = ['new_free', 'new_enterprise'].includes(plan?.payment_gateway_plan_identifier)
    if (!usagePlanId && !planWithoutUsagePlans) {
      console.error('Usage plan is undefined')
      return
    }
    this.segmentAnalyticsService.track('Choose Plan', {
      state: {
        onboarding: true,
        step: 'click_plan',
        group: 'choose_plan',
      },
      metadata: { plan },
    })
    this.updateJotformIfNeeded(plan)
    this.trackStartPlanEvent(plan)
    this.store.dispatch(new ShowLoading('submitSubscriptionChanges'))
    let redirectUrl = this.shopifyService.isEmbedded ? this.userService.getEmbeddedRedirectUrl() : window.location.href
    this.subscription.add(
      this.paymentService.changeSubscriptionPlan(plan, this.coupon ? this.coupon.code : null, redirectUrl, usagePlanId)
      .subscribe((res: ChargeResponse) => {
          this.subscription.add(
            this.gettingStartedService.completeStatus().subscribe(() => {
              this.afterPlanChanged(res, plan)
            })
          )
        },
        err => {
          this.segmentAnalyticsService.track('Plan Error', {
            state: {
              onboarding: true,
              step: 'error',
              group: 'choose_plan',
              status: 'error',
            },
            metadata: {
              error: err,
            },
          })
          this.store.dispatch(new HideLoading('submitSubscriptionChanges'))
          const message = err.message || err.exception || err.error || 'Something went wrong'
          this.snackbarService.showError({text: message})
          this.logger.error(LogLabel.PlansComponent, message)
        },
      ))
  }

  updateJotformIfNeeded(plan) {
    if (this.userInfo.platform_onboarding_status !== PlatformOnboardingStatus.Completed) {
      // also store selection time to calculate time spent till approval
      this.subscription.add(this.gettingStartedService.updateKeyValuePair(keyValuePairKey.SlectedPlanTimestamp, Date.now()).subscribe())
      const submissionId = this.gettingStartedService.getKeyValuePairValue(keyValuePairKey.PrePlansSurveyNewSubmissionId)
      const prefilled5Id = this.gettingStartedService.getKeyValuePairValue(keyValuePairKey.PrePlansSurveyNewPrefilled5)
      if (submissionId && prefilled5Id) {
        const jotFormPayload = {
          [prefilled5Id]: {
            field_1: plan.name || '', // plan name
            field_2: `${+plan.price.fractional / plan.price.currency.subunit_to_unit}` || plan.price?.amount || '', // plan price
            field_3: plan.name === 'Free' ? 'True' : 'False', // free plan doesn't need approval
            field_4: "0", // initialize to 0, will be updated later
          },
          new: '1',
        }
        // wrap in try catch to make sure this doesn't block anything
        try {
            this.subscription.add(this.apiJotformService.editSubmission(submissionId, jotFormPayload).subscribe())
        } catch (e) {
        }
      }
    }
  }

  afterPlanChanged(res, plan) {
    this.logger.log(LogLabel.PlansComponent, JSON.stringify(res))
    if (res.result && res.result.type === 'shopify' && res.result.confirmation_url) {
      this.segmentAnalyticsService.track('Plan Checkout', {
        state: {
          onboarding: true,
          step: 'plan_checkout',
          group: 'choose_plan',
        },
        metadata: {
          gateway: 'shopify',
          plan: plan,
        },
      })
      if (this.shopifyService.isEmbedded) {
        const url = res.result.confirmation_url
        const chargesUrl = url.slice(url.indexOf('/charges'), url.length)
        this.shopifyService.redirect.dispatch(Redirect.Action.ADMIN_PATH, { path: chargesUrl })
      } else {
        window.location.href = res.result.confirmation_url
      }
    } else {
      this.currentUsagePlan = plan?.usage_plan
      const queryParams = !this.userInfo?.onboarding.chose_plan ? { onboarding: true } : {}
      this.userInfo = this.userService.userInfo
      this.store.dispatch(new SetCurrentUserInfo({
        ...this.userInfo,
        subscription: res.result.subscription,
      }))
      this.store.dispatch(new SetUserChosePlan(true))
      this.router.navigate([this.initialPaths],{ queryParams }).then(() => {
        this.store.dispatch(new HideLoading('submitSubscriptionChanges'))
        if (!this.shopifyService.isEmbedded) {
          window.location.reload()
        }
      })
    }
  }

  trackStartPlanEvent(plan) {
    this.segmentAnalyticsService.track(`Start Plan - ${plan?.name}`)
  }

  sendCrispMessage(plan: SubscriptionPlan) {
    const crisp = this.crispService.getCrisp()
    if (crisp) {

      let message = `Hi team, what can I do to activate ${plan.name} for ${this.shopType}?`

      // if there is a payload parse it with mustache
      const payload = plan.plan_cta_payload_override || plan.plan_cta_payload
      if (payload) {
        const mustacheData = {...plan, shop_type: this.shopType}
        message = mustache.render(payload,  mustacheData)
      }

      crisp.push(['do', 'chat:open'])
      crisp.push(['do', 'message:send', ['text', message]])
    }
  }

  sendMisconfiguredPlanCrispMessage(plan: SubscriptionPlan) {
    const crisp = this.crispService.getCrisp()
    if (crisp) {

      let message = `Hi team, please help me activate ${plan.name} for ${this.selectControls[plan.payment_gateway_plan_identifier]?.value?.title} range.`
      crisp.push(['do', 'chat:open'])
      crisp.push(['do', 'message:send', ['text', message]])
    }
  }

  onCouponApplied(coupon: ValidateCouponResponse) {
    if (coupon) {
      if (!coupon.field_button_label) {
        coupon.field_button_label = 'Applied!'
      }
      this.coupon = coupon
      this.applyPlansDiscount(coupon)
      if (this.userType !== 'existing' && !this.defaultPromoApplied) {
        this.defaultPromoApplied = true
      }
      this.segmentAnalyticsService.track('Plans - Apply Sale', {coupon})
    } else {
      if (this.userType !== 'existing' && !this.defaultPromoApplied) {
        // if promo defined in src/app/auth/plans/plans.component.ts is invalid
        // fallback to static promo for new users
        this.userPromo = null
        setTimeout(() => {
          this.userPromo = this.staticPromo
        })
        this.defaultPromoApplied = true
      }
      this.coupon = null
      this.removePlansDiscount()
    }
  }

  applyPlansDiscount(coupon: ValidateCouponResponse, planId = null) {
    const couponPlans = coupon.plans || []
    let plans = this.plans
    if (planId) {
      plans = plans.filter((plan) => plan.payment_gateway_plan_identifier === planId)
    }
    if (plans) {
      plans.map((plan: SubscriptionPlan) => {
        // Skip plan if coupon does not applies to it
        // if no coupon plans are set apply to all
        if (couponPlans && couponPlans.length > 0 && !couponPlans.some(_plan => _plan.payment_gateway_plan_identifier === plan.payment_gateway_plan_identifier)) {
          return
        }
        this.modifyPlan(plan, coupon)
      })
    }

    this.updateVisiblePlans()
  }

  modifyPlan(plan: SubscriptionPlan, coupon: ValidateCouponResponse, applyToCurrentPlan = false) {
    // Don't apply coupons to free & custom plans
    // also don't apply to currently selected plan unless otherwise specified
    if (plan?.old_display_price) {
      delete plan.old_display_price
    }
    if (!plan || ['new_free', 'new_enterprise', 'new_starter', applyToCurrentPlan || this.currentPlanIdentifier].includes(plan?.payment_gateway_plan_identifier)) {
      return
    }
    // PERCENT OFF
    if (coupon.percent_off_precise) {
      if (plan.initial_price) {
        plan.price = _.cloneDeep(plan.initial_price)
      }
      plan.old_display_price = `${+plan.price.fractional / plan.price.currency.subunit_to_unit}`
      plan.initial_price = _.cloneDeep(plan.price)
      plan.price.fractional = `${Math.ceil((+plan.price.fractional / 100.0) * (100.0 - coupon.percent_off_precise))}`

    // AMOUNT OFF
    } else if (coupon.amount_off) {
      if (plan.initial_price) {
        plan.price = _.cloneDeep(plan.initial_price)
      }
      plan.old_display_price = `${+plan.price.fractional / plan.price.currency.subunit_to_unit}`
      plan.initial_price = _.cloneDeep(plan.price)
      plan.price.fractional = `${Math.ceil(+plan.price.fractional - (coupon.amount_off * 100))}`

    // TRIAL DAYS
    } else if (coupon.extended_trial_days) {
      plan.trial_period = coupon.extended_trial_days
      plan.plan_cta_label_override = `Start ${coupon.extended_trial_days}-Day Trial`
    }

    if (coupon.disable_trial) {
      plan.trial_period = 0
    }
    if (coupon.plan_cta_action) {
      plan.plan_cta_action_override = coupon.plan_cta_action
    }
    if (coupon.plan_cta_label) {
      plan.plan_cta_label_override = coupon.plan_cta_label
    }
    if (coupon.plan_cta_payload) {
      plan.plan_cta_payload_override = coupon.plan_cta_payload
    }
  }

  removePlansDiscount() {
    const currentPlanId = _.get(this.userInfo, 'subscription.plan.payment_gateway_plan_identifier')
    this.plans.map((plan: SubscriptionPlan) => {
      if (plan.payment_gateway_plan_identifier !== currentPlanId) {
        if (plan.initial_price) {
          delete plan.old_display_price
          plan.price = _.cloneDeep(plan.initial_price)
          delete plan.initial_price
        }
        delete plan.plan_cta_label_override
        delete plan.plan_cta_action_override
        delete plan.plan_cta_payload_override
      }
    })
    this.updateVisiblePlans()
  }

}
