import { Injectable } from '@angular/core';
import { SegmentAnalyticsService } from '../../shared/services/segment-analytics.service'
import { CrispService } from './crisp.service';
import { SafeLocalStorageService } from './safe-local-storage.service';


export enum SetupFlows {
  SMSFlow = 'sms_flow',
  EmailFlow = 'email_flow',
  PopupFlow = 'popup_flow'
}

@Injectable()
export class CrispTimeoutMessageService {
  public crispTimeout: any
  private readonly crispLSMsgShownKey = 'pf-setup-crisp-help-message-shown'
  private timeOut = 30 * 60 * 1000
  private readonly bookCallLink = 'https://one.store/vip?utm_campaign=offer_help&utm_source=crisp&utm_medium=crisp_automessage&utm_content=text_link'
  private crispMessageMap = {
    [SetupFlows.PopupFlow]: `Do you need help setting up your pop up? Book a free 1-on-1 Zoom call via [one.store/vip](${this.bookCallLink}) or send us a live chat here.`,
    [SetupFlows.EmailFlow]: `Do you need help setting up your email? Book a free 1-on-1 Zoom call via [one.store/vip](${this.bookCallLink}) or send us a live chat here.`,
    [SetupFlows.SMSFlow]: `Do you need help setting up your SMS? Book a free 1-on-1 Zoom call via [one.store/vip](${this.bookCallLink}) or send us a live chat here.`,
  }

  constructor(
    private segmentAnalyticsService: SegmentAnalyticsService,
    private safeLocalStorageService: SafeLocalStorageService,
    private crispService: CrispService,
  ) { }

  public setCrispTimeoutIfNeeded(flow: SetupFlows): void {
    if (!this.safeLocalStorageService.getItem(this.crispLSMsgShownKey)) {
      // show crisp msg after 30 mins
      this.crispTimeout = setTimeout(() => {
        this.showCrispHelpMsg(flow)
      }, this.timeOut)
    }
  }

  public clearCrispTimeout(): void {
    clearTimeout(this.crispTimeout)
    this.crispTimeout = null
  }

  private showCrispHelpMsg(flow: SetupFlows): void {
    if (this.safeLocalStorageService.getItem(this.crispLSMsgShownKey)) {
      return
    }
    const message = this.crispMessageMap[flow]
    this.safeLocalStorageService.setItem(this.crispLSMsgShownKey, 'true')
    const crisp = this.crispService.getCrisp()
    if (crisp) {
      crisp.push(['do', 'chat:open'])
      crisp.push(['do', 'message:show', ['text', message]])

      this.segmentAnalyticsService.track('Crisp Msg - Help Campaign Setup', {
        metadata: {
          flow: flow
        }
      })
    }
  }

}
