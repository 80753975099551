<!-- theme received -->
<ng-container *ngIf="data.type === receiveType.Theme">
    <div mat-dialog-title class="RQ-Title text-center">
      <img src="/app_assets/themes/theme-received.svg" alt="" class="RQ-Image">
      <button class="mat-dialog-close" (click)="dialogRef.close()">
        <span>&times;</span>
      </button>
    </div>
    <div mat-dialog-content class="text-center mb-1">
      <h2>We’ve received your request.</h2>
      <p class="mt-1">Thank you very much. We’ll get back to you soon! You can also check the status of your request by clicking the button below.<br></p>
    </div>
    <div mat-dialog-actions class="text-center m-0 d-flex justify-content-end">
      <button class="pf-button outline md blue" (click)="dialogRef.close()">Close</button>
      <button class="pf-button filled md blue ml-3" (click)="navigateToCanny(data.url)" [disabled]="!data.url">View My Request</button>
    </div>
</ng-container>

<!-- vote received -->
<ng-container *ngIf="data.type === receiveType.Vote">
    <div mat-dialog-title class="RQ-Title text-center">
      <img src="/app_assets/themes/theme-received.svg" alt="" class="RQ-Image">
      <button class="mat-dialog-close" (click)="dialogRef.close()">
        <span>&times;</span>
      </button>
    </div>
    <div mat-dialog-content class="text-center mb-1">
      <h2>Thanks For Voting!</h2>
      <p class="mt-1">Thank you very much. We’ll get back to you soon! You can also check the status of the feature request by clicking the button below.<br></p>
    </div>
    <div mat-dialog-actions class="text-center m-0 d-flex justify-content-end">
      <button class="pf-button outline md gray" (click)="dialogRef.close()">Close</button>
      <button class="pf-button filled md blue ml-3" (click)="navigateToCanny(data.url)" [disabled]="!data.url">View My Vote</button>
    </div>
</ng-container>

<!-- isWaitingList received -->
<ng-container *ngIf="data.type === receiveType.WaitingList">
  <div mat-dialog-title class="RQ-Title text-center">
    <h2 class="my-3">You're on the Waitlist!</h2>
    <img style="margin: 20px 0;" src="/app_assets/themes/theme-received-crop.svg" alt="" class="RQ-Image">
    <button class="mat-dialog-close" (click)="dialogRef.close()">
      <span>&times;</span>
    </button>
  </div>
  <div mat-dialog-content class="text-center mb-1">
    <h3 class="mt-1" style="margin-bottom: 40px"><span style="color: #ffa100">{{ data.voteCount }} stores</span> in front of you</h3>
    <pf-message-box *ngIf="!skipWaitlist; else skipWaitlistMessageBox"
      messageType="info"
      [customStyle]="{'border-radius': '5px', 'margin-bottom': '12px', 'text-align': 'left'}"
      [messageHtml]="waitingListText"></pf-message-box>
    <ng-template #skipWaitlistMessageBox>
      <pf-message-box
        messageType="info" messageTitle="Want to skip the line?"
        [customStyle]="{'border-radius': '5px', 'margin-bottom': '12px', 'text-align': 'left'}"
        buttonText="Contact Support" (buttonClick)="openCrisp()"></pf-message-box>
    </ng-template>
  </div>
  <div mat-dialog-actions class="text-center m-0 d-flex justify-content-end" *ngIf="!skipWaitlist">
    <button class="pf-button outline md gray" (click)="dialogRef.close()">Close</button>
    <button class="pf-button filled md yellow ml-3" (click)="navigateToCanny(data.url)" [disabled]="!data.url">View Waitlist</button>
  </div>
</ng-container>
