import { Injectable } from '@angular/core'
import { SafeLocalStorageService } from '../../../core/services/safe-local-storage.service'
import { OneBackup, OneBackupCheck, OneBackupInitialStructure, OneBackupKeyPrefix, OneBackupRecord, OneBackupRecordData } from './backup.model'
import _ from 'lodash'
import { EmailAutomationTypeEnum } from '../../../pages/emails/components/email-automations/enums/email-automation-type.enum'
import { MarketingEmailType } from '../../models/campaign/marketing-email'
import { BroadcastNewsletterEmailType } from '../../../pages/email-builder/models/email-editor.models'
import { RouteHeaderUrl } from '../../components/one-header/header-navigation.model'
import { Router } from '@angular/router'
import { CampaignService } from '../../../core/services/campaign.service'
import { v4 as uuidv4 } from 'uuid'
import { UserService } from '../../../core/services/user.service'

@Injectable({providedIn: 'root'})
export class BackupService {
  /**
   * If localStorage is not available, we don't want to use this service
   */
  public active: boolean
  private backupDecayTime = 24 // hours
  private backupKey = OneBackupKeyPrefix + this.userService.userInfo.id

  constructor(
    private safeLocalStorage: SafeLocalStorageService,
    private campaignService: CampaignService,
    private userService: UserService,
    private router: Router,
  ) {
    // We use SafeLocalStorageService which stores data in localStorage or cookies (whatever is available),
    // But we don't want to store backup data in cookies, because it's too big
    // that's why we do nothing if localStorage is not available
    this.active = this.safeLocalStorage.localStorageAvailable
  }

  /**
   * Get backup data from localStorage
   * If there is no data in localStorage, create it
   */
  get data(): OneBackup {
    if (!this.active) return null
    const backup = this.safeLocalStorage.getItem(this.backupKey)
    // If OneBackupKey is not present in localStorage, create it so we can use it later
    if (!backup) {
      this.safeLocalStorage.setItem(this.backupKey, JSON.stringify(this.emptyBackup))
      return this.emptyBackup
    } else {
      return JSON.parse(backup)
    }
  }

  /**
   * Check if there is any unsaved data in localStorage
   * @returns {OneBackupCheck} object with hasUnsavedData and backups array
   */
  checkLs(): OneBackupCheck {
    if (!this.active) return {hasUnsavedData: false, unsavedData: []}
    const backupStorage = this.data
    // Check if there is any unsaved data
    let foundBackups = []
    // Iterate over all email types
    for (var key in backupStorage?.messaging?.emails) {
      // TODO: find a better way to iterate over all backups in case we add more entities like popups, etc.
      if (backupStorage.messaging.emails.hasOwnProperty(key)) {
        const backupsArray = backupStorage.messaging.emails[key]
        // If any backup exists
        if (backupsArray?.length) {
          // There can be multiple backups for one email type
          backupsArray.forEach(item => {
            // check if item was saved in the past 24 hours
            const savedAt = new Date(item.saved_at)
            const now = new Date()
            const diff = now.getTime() - savedAt.getTime()
            const diffHours = diff / (1000 * 3600)
            if (diffHours < this.backupDecayTime) {
              // FIXME: messaging.emails string should be dynamic
              foundBackups.push({['messaging.emails.' + key]: item})
            } else {
              // If backup is older than 24 hours, remove it
              backupsArray.splice(backupsArray.indexOf(item), 1)
            }
          })
        }
      }
    }
    // Update localStorage in case we removed some old backups
    this.safeLocalStorage.setItem(this.backupKey, JSON.stringify(backupStorage))
    // Return result
    return {
      hasUnsavedData: foundBackups.length > 0,
      unsavedData: foundBackups,
    }
  }

  private get emptyBackup(): OneBackup {
    // Old but solid way to clone an object
    return JSON.parse(JSON.stringify(OneBackupInitialStructure))
  }

  /**
   * Save data to localStorage backup object
   * @param path - path to the data in backup object (example: `"messaging.emails.EmailAutomation::Welcome::Subscriber"`)
   * @param data - object to save
   * @returns OneBackupRecord
   */
  save(path: string, type: string, data: OneBackupRecordData): OneBackupRecord {
    if (!this.active) return
    // Check if path is not empty and is at least 2 levels deep
    if (!path || path.split('.').length < 2) {
      console.error('OneBackupService: Valid path is required')
      return
    }
    let backupRecord: OneBackupRecord
    const backupObj = this.data
    // If path exists in backup object, set the data
    const key = `${path}.${type}`
    if (_.has(backupObj, key)) {
      // Check if there is already a backup for this object
      const backupsArray = _.get(backupObj, key) as any
      backupsArray?.forEach(item => {
        // If there is a backup for this object, remove it
        // We don't want to have multiple backups for the same object
        // If data.id is undefined and item.data.id is undefined, we assume that it's the same object
        // (basically, we don't want to have more than 1 backup of the same type without id)
        if (
          item.data.id === data.id
          || (typeof item.data?.id === 'undefined' && typeof data?.id === 'undefined')
        ) {
          backupsArray.splice(backupsArray.indexOf(item), 1)
        }
      })
      backupRecord = {
        saved_at: new Date().toISOString(),
        type: type,
        name: this.getRecordName(data, type),
        temp_id: data.id ? null : uuidv4(),
        data,
      } as OneBackupRecord
      backupsArray.push(backupRecord)
    } else {
      console.error(`OneBackupService: Path ${key} does not exist in OneBackup object`)
    }
    // Update localStorage
    this.safeLocalStorage.setItem(this.backupKey, JSON.stringify(backupObj))
    // Return backup record in case we need to know what was saved
    return backupRecord
  }

  /**
   * Get record name or use a default one
   */
  getRecordName(record: OneBackupRecordData, type: string): string {
    if (record.name) {
      return record.name
    }
    switch (type) {
      case EmailAutomationTypeEnum.Birthday:
        return 'Birthday Automation'
      case EmailAutomationTypeEnum.Upsell:
        return 'Upsell Automation'
      case EmailAutomationTypeEnum.WinBack:
        return 'WinBack Automation'
      case EmailAutomationTypeEnum.CartRecovery:
        return 'Cart Recovery Automation'
      case EmailAutomationTypeEnum.WelcomeSubscribers:
        return 'Welcome New Subscribers Automation'
      case EmailAutomationTypeEnum.WelcomeCustomers:
        return 'Thank You 1st Purchase Automation'
      case EmailAutomationTypeEnum.WelcomeAccounts:
        return 'Welcome New Accounts Automation'
      case EmailAutomationTypeEnum.ThankYou:
        return 'Thank You Shopify Subscribers Automation'
      case MarketingEmailType.AutoResponderMarketingEmail:
        return 'Auto Responder'
      case MarketingEmailType.ScheduledMarketingEmail:
        return 'Scheduled Email'
      case MarketingEmailType.SubscriptionConfirmationEmail:
        return 'Subscription Confirmation'
      case BroadcastNewsletterEmailType.broadcastCoupon:
        return 'Newsletter Email (With Reward)'
      case BroadcastNewsletterEmailType.broadcastNewsletter:
        return 'Newsletter Email (No Reward)'
      default:
        return '-'
    }
  }

  /**
   * Restore data from localStorage backup object
   * @param record - backup record to restore
   * @param key - path to the data in backup object (example: `"messaging.emails.EmailAutomation::Welcome::Subscriber"`)
   */
  restore(record: OneBackupRecord, key: string): void {
    const url = this._getEditUrl(record.type, record.data.id, record)
    if (url) {
      this.removeBackupRecord(record, key)
      this.router.navigateByUrl(url, {state: {backup: record.data}})
    }
  }

  public removeAllBackups(): void {
    this.safeLocalStorage.removeItem(this.backupKey)
  }

  public removeBackupRecord(record: OneBackupRecord, key: string): void {
    const backupObj = this.data
    const backupsArray = _.get(backupObj, key)
    if (!backupsArray) return
    backupsArray.splice(backupsArray.indexOf(record), 1)
    this.safeLocalStorage.setItem(this.backupKey, JSON.stringify(backupObj))
  }

  removeBackupById(id: string): void {
    if (!id) return
    const backupObj = this.data
    // Iterate over all email types
    for (let key in backupObj?.messaging?.emails) {
      backupObj.messaging.emails[key].forEach((item, index) => {
        if (item.data.id === id) {
          this.removeBackupRecord(item, `messaging.emails.${key}`)
        }
      })
    }
  }

  removeBackupByTempId(temp_id: string): void {
    if (!temp_id) return
    const backupObj = this.data
    // Iterate over all email types
    for (let key in backupObj?.messaging?.emails) {
      backupObj.messaging.emails[key].forEach((item, index) => {
        if (item.temp_id === temp_id) {
          this.removeBackupRecord(item, `messaging.emails.${key}`)
        }
      })
    }
  }

  private _getEditUrl(emailType: string, id: string, record: OneBackupRecord): string {
    switch (emailType) {
      // Automations
      case EmailAutomationTypeEnum.Birthday:
        return `/${RouteHeaderUrl.email_editor}/birthday${id ? `/${id}` : ''}`
      case EmailAutomationTypeEnum.Upsell:
        return `/${RouteHeaderUrl.email_automation_builder}/${RouteHeaderUrl.upsell}/${id}/template`
      case EmailAutomationTypeEnum.WinBack:
        return `/${RouteHeaderUrl.email_automation_builder}/${RouteHeaderUrl.winback}/${id}/template`
      case EmailAutomationTypeEnum.CartRecovery:
        return `/${RouteHeaderUrl.email_automation_builder}/${RouteHeaderUrl.cartRecovery}/${id}/design`
      case EmailAutomationTypeEnum.WelcomeSubscribers:
        return `/${RouteHeaderUrl.email_automation_builder}/${RouteHeaderUrl.welcomeEmail}/${id}/subscribers/design`
      case EmailAutomationTypeEnum.WelcomeCustomers:
        return `/${RouteHeaderUrl.email_automation_builder}/${RouteHeaderUrl.welcomeEmail}/${id}/customers/design`
      case EmailAutomationTypeEnum.WelcomeAccounts:
        return `/${RouteHeaderUrl.email_automation_builder}/${RouteHeaderUrl.welcomeEmail}/${id}/accounts/design`
      case EmailAutomationTypeEnum.ThankYou:
        return `/${RouteHeaderUrl.email_automation_builder}/${RouteHeaderUrl.welcomeEmail}/${id}/thank-you/design`

      // Marketing emails
      case MarketingEmailType.AutoResponderMarketingEmail:
      case MarketingEmailType.ScheduledMarketingEmail:
      case MarketingEmailType.SubscriptionConfirmationEmail:
        const configureUrl = this.campaignService.getConfigureLink(record.data.campaign_id, record.data.pluginType)
        return `/${configureUrl}/${RouteHeaderUrl.marketing_emails}/editor/email/edit/${record.data.id}`

      // Broadcasts
      case BroadcastNewsletterEmailType.broadcastCoupon:
      case BroadcastNewsletterEmailType.broadcastNewsletter:
        return `/${RouteHeaderUrl.email_editor}/broadcast/edit${id ? `/${id}` : '?type=' + emailType}`

      default:
        console.error('OneBackupService: Email type not found: ' + emailType)
        return null
    }
  }

}
