import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { StepperMenuComponent } from './components/stepper-menu/stepper-menu.component'
import { RouterModule } from '@angular/router';
import { StepperContentComponent } from './components/stepper-content/stepper-content.component';
import { StepperStepComponent } from './components/stepper-step/stepper-step.component'


@NgModule({
  declarations: [
    StepperMenuComponent,
    StepperContentComponent,
    StepperStepComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
  ],
  exports: [
    StepperStepComponent,
    StepperContentComponent,
    StepperMenuComponent,
  ],
})
export class StepperModule { }
