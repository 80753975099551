export const AllowedFreePlanUsers = [
  'd52b1a4c-5ef8-4dfd-ab25-042306e0d004', // proof-factor-nikita
  'ebcd21ed-53e0-47a5-8073-ab83e4bee63d', // purpleheartuk.myshopify.com
  'c86445d7-b08f-4f3d-8106-996fe43832f1', // shopaftermathsports.myshopify.com
  '15d441e9-6031-4487-838e-b8f1f3717978', // trendhyllan-se.myshopify.com
  'b5f88ec5-d0f2-4df7-b6ca-4e248a52584b', // murano-glass-uk.myshopify.com
  '0f57b9c3-02cb-475f-8dd5-6e9acb180d44', // troovy-1170.myshopify.com
  '15c0260a-c95c-4a86-8bdd-a6c4b86b2819', // www-tailor-queen-fr.myshopify.com
  'd3f9ee70-4038-47a7-9a85-29dab9469c6f', // pawsivibes-2.myshopify.com
  '081071ad-0937-498e-80d3-49bcf902a000', // everydayheroesstore.myshopify.com
  'ec4d2802-a68a-4111-bef1-47f5fae27e2d', // jurelma-cosmetics.myshopify.com
  'c636666b-febb-401c-bb26-7ead0c33000d', // 602976-2.myshopify.com
  'ad2639fa-3eba-4c50-bc7d-b6ec93a68e91', // lamotive.myshopify.com
  '09d80e67-d0c2-4cba-b101-b00c76018a80', // smackedshop.myshopify.com
  '52511daf-6d16-4d50-b683-7bd7cb5e8237', // a9e0d7-3.myshopify.com
]
