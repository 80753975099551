export const couponBoxCustomCssTemplate =
  `/* Customize your styles using CSS and class selectors below */
.overlay {}
.prompt-content {}
.prompt-close {}
.prompt-header {}
.header-title {}
.header-subtitle {}
.prompt-code {}
.prompt-opt-in {}
.prompt-footer {}
.prompt-input-wrap {}
/* use -webkit-text-fill-color to configure input color */
.prompt-input {}
.prompt-input-firstname {}
.prompt-input-lastname {}
.prompt-input-email {}
.prompt-input-phone {}
.prompt-input-birthday {}
.subscribe-button {}
.result-content {}
.result-close {}
.result-header {}
.result-title {}
.result-code {}
.result-code-input {}
.result-copy-button {}
.result-footer {}
`

