import { Component, Input } from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';

@Component({
  selector: 'pf-title-with-tooltip',
  templateUrl: './title-with-tooltip.component.html',
  styleUrls: ['./title-with-tooltip.component.scss']
})
export class TitleWithTooltipComponent {
  @Input() underline: boolean = true;
  @Input() label: string = null;
  @Input() tooltip: string = null;
  @Input() tooltipPosition: TooltipPosition = null;
}
