<div class="plan-selected">
  <div *ngIf="!userAgreed">
    <h1 mat-dialog-title class="mb-0">
      Start Free, Then Pay As You Grow Plan
      <button class="mat-dialog-close" (click)="dialogRef.close()">
        <span>&times;</span>
      </button>
    </h1>
    <div mat-dialog-content>
      <div class="Tip _shopify-green _clickable my-3"
           (click)="showFreeDetails=!showFreeDetails; showPayDetails=false">
        <div class="Tip__Title">FAQ</div>
        <div class="Tip__Body">
          What is included in my start free plan?
          <one-icon name="chevron-down-circle-outline" class="text-shopify-green ml-1 _togglable" [class._toggled]="showFreeDetails" style="float: right"></one-icon>
        </div>
      </div>
      <ul class="CardFormList" *ngIf="showFreeDetails">
        <li>
          <one-icon class="status text-shopify-green" name="checkmark-circle"></one-icon>
          <span><strong>Price:</strong> $0/month (free)</span>
        </li>
        <li>
          <one-icon class="status text-shopify-green" name="checkmark-circle"></one-icon>
          <span><strong>Visitors:</strong> {{freeCount | number}} unique visitors/month</span>
        </li>
        <li>
          <one-icon class="status text-shopify-green" name="checkmark-circle"></one-icon>
          <span><strong>Emails:</strong> {{freeCount | number}} sends/month</span>
        </li>
        <li>
          <one-icon name="close-circle" class="status text-shopify-red"></one-icon>
          <span><strong>A/B Testing</strong> multiple active campaigns</span>
        </li>
        <li>
          <one-icon name="close-circle" class="status text-shopify-red"></one-icon>
          <span><strong>PLUS Holiday Themes</strong></span>
        </li>
      </ul>
      <div class="Tip _shopify-green _clickable my-3"
           (click)="showPayDetails=!showPayDetails; showFreeDetails=false">
        <div class="Tip__Title">FAQ</div>
        <div class="Tip__Body">
          What if I go over the {{ freeCount | number }} free visitor limit?
          <one-icon name="chevron-down-circle-outline" class="text-shopify-green ml-1 _togglable" [class._toggled]="showFreeDetails" style="float: right"></one-icon>
        </div>
      </div>
      <ul class="CardFormList" *ngIf="showPayDetails">
        <li *ngIf="data.trial > 0">
          <one-icon class="status text-shopify-green" name="checkmark-circle"></one-icon>
          <span><strong>Free for first {{ data.trial }} days</strong> even above {{ freeCount | number }} visitor free limit</span>
        </li>
        <li>
          <one-icon class="status text-shopify-green" name="checkmark-circle"></one-icon>
          <span>
            <strong>Price: </strong> ${{ tierPrice }}
            /month per additional {{ tierSize | number }} visitors
          </span>
        </li>
        <li>
          <one-icon class="status text-shopify-green" name="checkmark-circle"></one-icon>
          <span>
            <strong>Maximum Price: </strong>
            ${{ cappedPrice | number }} per month
          </span>
        </li>
      </ul>
    </div>
  </div>
  <div *ngIf="userAgreed">
    <h1 mat-dialog-title>
      Shopify Charge Approval
      <button class="mat-dialog-close" (click)="dialogRef.close()">
        <span>&times;</span>
      </button>
    </h1>
    <div mat-dialog-content>
      <p>
        In the next step, Shopify will ask you to approve a maximum charge LIMIT of ${{ cappedPrice }} per month.
      </p>
      <div class="Tip _success my-3">
        <div class="Tip__Title">NOTE</div>
        <div class="Tip__Body">You will <strong>NOT</strong> be charged ${{ cappedPrice }} today, this is just a maximum charge limit. See pricing table below.</div>
      </div>
      <div class="Tip _success mt-3 my-3" *ngIf="data.trial">
        <div class="Tip__Title">NOTE</div>
        <div class="Tip__Body">
          Regardless, you will have a <b>{{data.trial}}-Day Free Trial</b>
          <one-icon name="help-circle" class="is-help-icon"
            [matTooltip]="'You will not be charged until after trial period.'"
            matTooltipClass="Tooltip-Box"
            matTooltipPosition="above"
          ></one-icon>
        </div>
      </div>
    </div>
  </div>
  <div mat-dialog-content>
    <div class="pricing-table" [class._trial]="data.trial > 0">
      <div class="head">
        <div class="th">Visitors per Month</div>
        <div class="th">Price per Tier</div>
        <div class="th">Total Price</div>
      </div>
      <div class="body" [class.scrollable]="tiers.length > visibleTiers">
        <div class="tr" *ngFor="let tier of tiers">
          <div class="td">
            {{ tier.start | number }}
            <ng-container *ngIf="isNumber(tier.end); else plus"> - {{ tier.end | number }}</ng-container>
            <ng-template #plus>+</ng-template>
          </div>
          <div class="td">
            <ng-container *ngIf="isNumber(tier.price); else price">${{ tier.price | number }}</ng-container>
            <ng-template #price>{{ tier.price }}</ng-template>
          </div>
          <div class="td _space-between">
            <ng-container *ngIf="isNumber(tier.total); else total">

              <span [class._strikethrough]="data.trial > 0">${{ tier.total | number }}</span>

              <small *ngIf="data.trial > 0" class="pf-badge shopify-darkgreen sm">
                {{ data.trial }}-Day Free Trial
              </small>

              <div></div>
            </ng-container>
            <ng-template #total>{{ tier.total }}</ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="mb-0 mt-3">
    <div>
      <button *ngIf="userAgreed" class="pf-button md outline gray mr-3 mt-1" (click)="back()">Back
      </button>
    </div>
    <div>
      <button *ngIf="userAgreed" class="pf-button md filled shopify-green mt-1" (click)="letsGo()">Let's Go!</button>
      <button *ngIf="!userAgreed" class="pf-button md filled shopify-green mt-1" (click)="agree()">Agree</button>
    </div>
  </div>
</div>
<pf-loading-overlay></pf-loading-overlay>
