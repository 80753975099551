import { Injectable } from '@angular/core'
import { AngularFireStorage } from '@angular/fire/compat/storage'
import { ListResult } from '@angular/fire/compat/storage/interfaces'
import { Store } from '@ngrx/store'
import { Observable, finalize, forkJoin, from, map, switchMap, tap } from 'rxjs'
import { StoreState } from '../../../../store/store.state'
import { HideLoading, ShowLoading } from '../../../../store/loading/loading.actions'
import { MGBucket, MGPathData, MgImage } from '../models/media-gallery-models'
import { MgStorageService } from './media-gallery-storage.service'

@Injectable({providedIn: 'root'})
export class MgService {
  private bucketsStack: MGBucket[] = this.mgStorageService.getBuckets()
  
  constructor(
    private angularFireStorage: AngularFireStorage,
    private store: Store<StoreState>,
    private mgStorageService: MgStorageService,
  ) { }

  pathsToUrl(paths: MGPathData[]): string {
    return paths?.map(path => path.url).join('/')
  }

  getMetadata(path: MGPathData[]): Record<string, any> {
    return path.reduce((acc, path) => {
      Object.keys(path?.metadata)?.forEach(key => {
        acc[key] = path.metadata[key]
      })
      return acc
    }, {})
  }

  /**
   * Matches the url of an image to the url of an item in the list
   * @param {MgImage[]} items
   * @param {string} url
   * @returns {MgImage}
   */
  matchByUrl(items: MgImage[], url: string): MgImage {
    return items?.find(item => item.url.split('?')[0] === url.split('?')[0])
  }
  
  listAll(path: string): Observable<any> {
    this.store.dispatch(new ShowLoading('MGFetch'))
    const bucket = this.bucketsStack[0]
    const app = this.mgStorageService.getFireApp(bucket)
    const task = from(app.storage(bucket).ref(path).listAll())
    return task.pipe(
      switchMap((res: ListResult) => {
        const metadataObservables = res.items.map(item => 
          from(app.storage(bucket).ref(item.fullPath).getMetadata()).pipe(
            map(metadata => ({...metadata, ref: item}))
          )
        )
        return forkJoin(metadataObservables).pipe(
          map(metadataArray => {
            return {...res, items: metadataArray}
          })
        )
      }),
      finalize(() => {
        this.store.dispatch(new HideLoading('MGFetch'))
        // app.delete()
      })
    )
  }
}