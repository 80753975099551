import { CampaignCaptureSource } from './campaign-capture-source'

export interface CaptureRule {
  id?: string,
  _destroy?: boolean,
  type: CaptureRuleType | string,
  capture_source?: CampaignCaptureSource,
  match_type: CaptureMatchType,
  match_input: string,
}

export enum CaptureMatchType {
  Contains = 'contains',
  ExactMatch = 'exact_match',
}

export enum CaptureRuleType {
  Whitelist = 'whitelist',
  Blacklist = 'blacklist',
}
