export enum CapturedDataType {
  Fields = 'Campaign::PopUpNotification::CapturedFields',
}

export interface SubscribersStats {
  customers_accepts_marketing_count: number
  customers_count: number
  subscriptions_count: number
}

export enum ContactsStatsType {
  abandoned_checkouts_subscribers_email_total = 'abandoned_checkouts_subscribers_email_total',
  abandoned_checkouts_subscribers_sms_total = 'abandoned_checkouts_subscribers_sms_total',
  abandoned_checkouts_subscribers_total = 'abandoned_checkouts_subscribers_total',
  abandoned_checkouts_total = 'abandoned_checkouts_total',
  active_known_customers_total = 'active_known_customers_total',
  contacts_email_total = 'contacts_email_total',
  contacts_sms_total = 'contacts_sms_total',
  contacts_total = 'contacts_total',
  customers_first_order_subscribers_email_total = 'customers_first_order_subscribers_email_total',
  customers_first_order_subscribers_sms_total = 'customers_first_order_subscribers_sms_total',
  customers_first_order_subscribers_total = 'customers_first_order_subscribers_total',
  customers_returning_order_subscribers_email_total = 'customers_returning_order_subscribers_email_total',
  customers_returning_order_subscribers_sms_total = 'customers_returning_order_subscribers_sms_total',
  customers_returning_order_subscribers_total = 'customers_returning_order_subscribers_total',
  customers_returning_order_total = 'customers_returning_order_total',
  customers_subscribers_email_total = 'customers_subscribers_email_total',
  customers_subscribers_sms_total = 'customers_subscribers_sms_total',
  customers_subscribers_total = 'customers_subscribers_total',
  customers_total = 'customers_total',
  subscribers_email_only_total = 'subscribers_email_only_total',
  subscribers_email_total = 'subscribers_email_total',
  subscribers_sms_country_code_1_total = 'subscribers_sms_country_code_1_total',
  subscribers_sms_only_total = 'subscribers_sms_only_total',
  subscribers_sms_total = 'subscribers_sms_total',
  subscribers_total = 'subscribers_total',
  customers_first_order_total = 'customers_first_order_total',
}

export interface ContactsStats {
  [ContactsStatsType.contacts_total]: number
  [ContactsStatsType.contacts_email_total]: number
  [ContactsStatsType.contacts_sms_total]: number
  [ContactsStatsType.subscribers_total]: number
  [ContactsStatsType.subscribers_email_total]: number
  [ContactsStatsType.subscribers_email_only_total]: number
  [ContactsStatsType.subscribers_sms_total]: number
  [ContactsStatsType.subscribers_sms_only_total]: number
  [ContactsStatsType.subscribers_sms_country_code_1_total]: number
  [ContactsStatsType.customers_total]: number
  [ContactsStatsType.customers_subscribers_total]: number
  [ContactsStatsType.customers_subscribers_email_total]: number
  [ContactsStatsType.customers_subscribers_sms_total]: number
  [ContactsStatsType.customers_first_order_total]: number
  [ContactsStatsType.customers_first_order_subscribers_email_total]: number
  [ContactsStatsType.customers_first_order_subscribers_sms_total]: number
  [ContactsStatsType.customers_returning_order_total]: number
  [ContactsStatsType.customers_returning_order_subscribers_email_total]: number
  [ContactsStatsType.customers_returning_order_subscribers_sms_total]: number
  [ContactsStatsType.customers_returning_order_subscribers_total]: number
  [ContactsStatsType.abandoned_checkouts_total]: number
  [ContactsStatsType.abandoned_checkouts_subscribers_email_total]: number
  [ContactsStatsType.abandoned_checkouts_subscribers_sms_total]: number
}

export type ContactsResponseType = keyof ContactsResponse
export interface ContactsResponse {
  abandoned_checkouts_subscribers_email_total?: number
  abandoned_checkouts_subscribers_sms_total?: number
  abandoned_checkouts_subscribers_total?: number
  abandoned_checkouts_total?: number
  active_known_customers_total?: number
  contacts_email_total?: number
  contacts_sms_total?: number
  contacts_total?: number
  customers_first_order_subscribers_email_total?: number
  customers_first_order_subscribers_sms_total?: number
  customers_first_order_subscribers_total?: number
  customers_first_order_total?: number
  customers_returning_order_subscribers_email_total?: number
  customers_returning_order_subscribers_sms_total?: number
  customers_returning_order_subscribers_total?: number
  customers_returning_order_total?: number
  customers_subscribers_email_total?: number
  customers_subscribers_sms_total?: number
  customers_subscribers_total?: number
  customers_total?: number
  subscribers_email_only_total?: number
  subscribers_email_total?: number
  subscribers_sms_country_code_1_total?: number
  subscribers_sms_only_total?: number
  subscribers_sms_total?: number
  subscribers_total?: number
}

export interface ContactsStatsConfig {
  label: string
  value?: number
  type: ContactsStatsType
  icon?: string
  percentage?: number
  iconSize?: number
  tooltip?: string
  oneValue?: number
  primary?: boolean
  iconTooltips?: {
    one?: string
    shopify?: string
    account?: string
    email?: string
    sms?: string
  }
}

export interface SubscribersStatsTabConfig {
  label: string
  url: string
  locked: boolean
  tooltip?: string
}
