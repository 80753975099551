import { CommonModule } from '@angular/common'
import { AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core'
import { Router, RouterModule } from '@angular/router'
import { OneIconComponent } from '../../../one-icon/one-icon.component'
import { ShopifyService } from '../../../../../core/services/shopify.service'
import { UserService } from '../../../../../core/services/user.service'
import { BrandingService } from '../../../../../core/services/branding.service'
import { RouteHeaderUrl } from '../../header-navigation.model'
import { ContactUsModalComponent } from '../../../modals/contact-us.modal/contact-us.modal.component'
import { UserShop } from '../../../../models/user/user-shop.model'
import { GettingStartedService } from '../../../../../core/services/getting-started.service'
import { keyValuePairKey } from '../../../../models/key-value-pair.model'
import { AdminUserTokenService } from '../../../../../core/services/admin-user-token.service'
import { SafeLocalStorageService } from '../../../../../core/services/safe-local-storage.service'
import { PromoService } from '../../../../modules/promo/services/promo.service'
import { AngularFireAuth } from '@angular/fire/compat/auth'
import { Store } from '@ngrx/store'
import { StoreState } from '../../../../../store/store.state'
import { LogoutCurrentUser } from '../../../../../store/user/user.actions'
import { Subscription } from 'rxjs'
import { AuthService } from '../../../../../core/services/auth.service'
import { UserRole } from '../../../../models/user/user.model'
import { environment } from '../../../../../../environments/environment'
import { MatDialog } from '@angular/material/dialog'

declare const Canny: Function

@Component({
  selector: 'one-user-nav',
  templateUrl: 'one-user-nav.component.html',
  styleUrls: ['one-user-nav.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    OneIconComponent,
  ],
})
/**
 * Please keep in mind this component is used in two places to accommodate mobile and desktop views:
 * 1. src/app/shared/components/one-header/components/one-main-nav/one-main-nav.component.html
 * 2. src/app/shared/components/one-header/one-header.component.html
 */
export class OneUserNavComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output() closeMenu: EventEmitter<any> = new EventEmitter()
  private subscription = new Subscription()
  environment = environment

  isShopifyEmbedded = this.shopifyService.isEmbedded
  userAccountName = this.userService.getAccountName()
  isShopifyShop = this.userService.isShopifyShop
  cannyUrl: string
  brandingHelpUrl: string
  routeHeaderUrl = RouteHeaderUrl
  newPricingEligible = false
  isStripeCustomer = false
  isAuthenticated = false
  isShopifyBudding = this.userService.isLegacyBuddingPlanUser
  shopType: UserShop
  adminAsUser: any
  userRoles = UserRole

  isAdmin = this.userService.isAdmin()
  isAdminAsUser = false

  displayBanner = false

  constructor(
    protected afAuth: AngularFireAuth,
    protected authService: AuthService,
    private store: Store<StoreState>,
    private userService: UserService,
    private dialog: MatDialog,
    private brandingService: BrandingService,
    private shopifyService: ShopifyService,
    private router: Router,
    private gettingStartedService: GettingStartedService,
    private adminUserTokenService: AdminUserTokenService,
    private safeLocalStorageService: SafeLocalStorageService,
    private promoService: PromoService,
  ) {
    this.shopType = this.userService.userInfo?.shop
    this.cannyUrl = `${this.brandingService.getBrandingData().canny}/feature-requests`
    this.brandingHelpUrl = this.brandingService.getBrandingData().help
    this.newPricingEligible = this.isShopifyShop && (this.userService.newPricingEligible || this.userService.isNewFreePlanUser || this.userService.isNewV3PlanUser)
    this.isShopifyBudding = this.userService.isLegacyBuddingPlanUser
    this.isStripeCustomer = this.userService.isStripeCustomer
  }

  ngOnInit() {
    this.subscription.add(
      this.authService.user$.subscribe(data => this.isAuthenticated = !!data),
    )

    this.subscription.add(
      this.adminUserTokenService.asObservable().subscribe(val => {
        this.isAdminAsUser = val ? true : false
        this.adminAsUser = val
      }),
    )

    this.subscription.add(
      this.promoService.fullWidthBanner$.asObservable().subscribe(val => {
        this.displayBanner = val
      }),
    )
  }

  ngAfterViewInit(): void {
    this.initCanny()
  }

  openModal(e: MouseEvent, modalName: string) {
    e.preventDefault()
    this.closeMenu.emit()
    if (modalName === 'ContactUsModalComponent') {
      this.dialog.open(ContactUsModalComponent, {
        width: '500px',
        data: {
          shop: this.shopType ? this.userService.getShopTypeName(this.shopType.type) : null
        }
      })
    }
  }

  navigateToPlansPage(event) {
    event.preventDefault()
    const isStripeCustomer = this.userService.isStripeCustomer
    const isNewPlansPageVersion = this.userService.isNewPlanUser || this.newPricingEligible || this.isShopifyBudding
    if (isNewPlansPageVersion) {
      if (isStripeCustomer) {
        this.router.navigate([`/${this.routeHeaderUrl.settings}/${this.routeHeaderUrl.billing}/${RouteHeaderUrl.subscriptions}/${RouteHeaderUrl.custom}`])
      } else {
        this.router.navigate([`/${this.routeHeaderUrl.settings}/${this.routeHeaderUrl.billing}/${RouteHeaderUrl.subscriptions}`])
      }
    } else {
      this.router.navigate([this.routeHeaderUrl.choose_plan])
    }
  }

  logout(e: MouseEvent) {
    e.preventDefault()
    this.adminUserTokenService.setValue(null)
    this.safeLocalStorageService.removeItem('one-override-uninstalled-behavior')
    this.promoService.clearCookiesAndStorage(this.userService.userInfo)
    this.afAuth.signOut().then(() => {
      this.store.dispatch(new LogoutCurrentUser())
      this.isAuthenticated = false
      if (this.safeLocalStorageService.getItem('pf-shopify-shop-user') || this.safeLocalStorageService.getItem('pf-shopify-shop-user-id')) {
        this.safeLocalStorageService.removeItem('pf-shopify-shop-user')
        this.safeLocalStorageService.removeItem('pf-shopify-shop-user-id')
      }
    }).catch((error) => {
      console.error('error logging out from header: ', error)
    })
    setTimeout(() => {
      this.router.navigate(['/login'])
    }, 0)
  }

  adminLoginClicked(action: 'login' | 'logout') {
    if (action === 'login') {
      this.router.navigate([RouteHeaderUrl.admin])
    }
    if (action === 'logout') {
      this.adminUserTokenService.logOutAsUser()
    }
  }

  initCanny() {
    const cannyButton = document.getElementById('canny-button')
    if (!cannyButton) {
      setTimeout(this.initCanny, 1000)
    } else {
      if (typeof Canny !== 'undefined') {
        Canny('initChangelog', {
          appID: '60d9bb5b4f6653148925201c',
          position: 'bottom',
          align: 'right',
        }, cannyButton)
      }
    }
  }

  navigateToStandaloneAdmin(event: MouseEvent) {
    event.preventDefault()
    window.open(`https://${environment.hostname}/admin?redirectForStandalone`, '_blank')
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }
}
