import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core'
import { StoreState } from '../../store/store.state'
import { select, Store } from '@ngrx/store'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { filter, first, shareReplay } from 'rxjs/operators'
import { getSignUpFlowCompleted, getUserInfo, getUserShop, getUserShopType } from '../../store/user/user.selectors'

import * as _ from 'lodash'
import { UserInfo } from '../../store/user/user.state'
import { combineLatest, Subscription } from 'rxjs'
import { ShopifySubscribersApiService } from '../../pages/subscribers/services/shopify-subscribers-api.service'
import { ShopifyService } from '../../core/services/shopify.service'
import { getGettingStartedStatus } from '../../store/getting-started/getting-started.selectors'
import { BrandingService } from '../../core/services/branding.service'
import { PromoService } from '../../shared/modules/promo/services/promo.service'
import { GettingStartedService } from '../../core/services/getting-started.service'
import { UsagePlanMetricType } from '../../shared/components/plans-view/models/usage-plan-metric.model'
import { SegmentAnalyticsService } from '../../shared/services/segment-analytics.service'
import { UserService } from '../../core/services/user.service'
import { keyValuePairKey } from '../../shared/models/key-value-pair.model'
import { ApiJotformService } from '../../core/services/api/api-jotform.service'
import { PlansPageVersion } from '../../shared/components/plans-view/models/plans-version.model'
import { PlatformOnboardingStage, PlatformOnboardingStatus } from '../../shared/models/platform-onboarding.model'

declare let gtag: Function;

@Component({
  selector: 'pf-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.scss'],
})
export class PlansComponent implements OnInit, OnDestroy {
  shopType: string
  shopifyPlan: string
  userInfo: UserInfo
  subscription = new Subscription()
  showPageWithFreePlan = false
  isShopifyEmbedded = this.shopifyService.isEmbedded
  isFullscreen$ = this.shopifyService.fullScreen$
  isMobile = /iPhone|iPod|Android/i.test(navigator.userAgent)
  planPageVersion: PlansPageVersion = PlansPageVersion.Old
  userType = 'new'
  specialOffer: UsagePlanMetricType | PlansPageVersion = this.route?.snapshot?.queryParams?.special_offer
  platformOnboardingUpdated = false
  plansPageVersionOptions = PlansPageVersion

  subscriptionTriggered = false

  readonly viewPlansVersion: PlansPageVersion = this.route?.snapshot?.queryParams?.view_plans_version || null
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<StoreState>,
    private renderer: Renderer2,
    private shopifyService: ShopifyService,
    private shopifySubscribersApiService: ShopifySubscribersApiService,
    private brandingService: BrandingService,
    private promoService: PromoService,
    private gettingStartedService: GettingStartedService,
    private segmentAnalyticsService: SegmentAnalyticsService,
    private userService: UserService,
    private apiJotformService: ApiJotformService,
  ) {
    this.subscription.add(this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // gtag event fot plans page
        gtag('event', 'conversion', {'send_to': 'AW-475166455/cS4dCLOGqcwCEPftyeIB'})
      }
    }))

    this.subscription.add(
      combineLatest([
        this.userService.readUser(),
        this.gettingStartedStatus$,
        this.signUpFlowCompleted$,
      ]).subscribe(([userInfo, status, completed]) => {
        this.userInfo = userInfo

        this.shopType = _.get(userInfo, 'shop.type', null)
        this.shopifyPlan = _.get(userInfo, 'shop.profile.plan_name', null)

        if (completed && this.shopType !== 'ShopifyShop' && typeof this.shopType !== 'undefined') {
          this.router.navigateByUrl('/choose-plan')
        }
        // if user has already been shown a specific plans page version show it to them again
        // const plansPageVersionSeen = this.gettingStartedService.getKeyValuePairValue(keyValuePairKey.PlansPageVersionSeen)
        // if user is eligible for new pricing send them to new page
        if (this.userService.userInfo?.new_pricing_eligible) {
          this.userType = !_.get(status, 'completed', false) ? 'new' : 'returning'
          this.planPageVersion = PlansPageVersion.Version3View1
        } else if (this.shopType === 'ShopifyShop') {
          // if user is new or returning show them the new plans page no matter what plan they used to be on
          this.userType = !_.get(status, 'completed', false) ? 'new' : 'returning'
          this.planPageVersion = PlansPageVersion.Version3View1
        } else {
          this.planPageVersion = PlansPageVersion.Old
        }

        // if special_offer query param is present override default selection
        if (this.specialOffer) {
          switch (this.specialOffer) {
            case UsagePlanMetricType.Customers:
            case UsagePlanMetricType.Subscribers:
              this.planPageVersion = PlansPageVersion.New1
              break
            case UsagePlanMetricType.ActiveKnownCustomers:
              this.planPageVersion = PlansPageVersion.Version3View1
              break
            case UsagePlanMetricType.UniqueVisitors:
              this.planPageVersion = PlansPageVersion.Old
          }
        }

        // shortcut to view different plans pages for testing purposes
        if (this.viewPlansVersion) {
          switch (this.viewPlansVersion) {
            case PlansPageVersion.Old:
              this.planPageVersion = PlansPageVersion.Old
              break
            case PlansPageVersion.New1:
              this.planPageVersion = PlansPageVersion.New1
              break
            case PlansPageVersion.New2:
              this.planPageVersion = PlansPageVersion.New2
              break
            case PlansPageVersion.New3:
              this.planPageVersion = PlansPageVersion.New3
              break
            case PlansPageVersion.Version3View1:
              this.planPageVersion = PlansPageVersion.Version3View1
              break
          }
        }

        this.subscriptionTriggered = true

        // dynamic promo works now but the codes are not set up so commenting out for now
        // TODO uncomment when those codes are set up
        // this.setDynamicPromo()

        // save which version the user saw
        // this.subscription.add(this.gettingStartedService.updateKeyValuePair(keyValuePairKey.PlansPageVersionSeen, this.planPageVersion).subscribe())

        if (!this.platformOnboardingUpdated && userInfo?.platform_onboarding_status !== PlatformOnboardingStatus.Completed) {
          this.subscription.add(this.userService.updatePlatformOnboarding({status: PlatformOnboardingStatus.Started, stage: PlatformOnboardingStage.PlanSelection}).subscribe())
          this.updateJotformIfNeeded()
          this.platformOnboardingUpdated = true
        }
      })
    )

    if (this.route?.snapshot?.data?.userType === 'returning') {
      this.segmentAnalyticsService.track('Viewed Plans - Reinstalled User')
    }

    // TODO this is where branching can happen depending on different criteria
    // this.subscription.add(this.shopifySubscribersApiService.getSubscribers().pipe(shareReplay()).subscribe(data => {
    //   this.showPageWithFreePlan = data.contacts_total === 0
    // }))
  }

  get signUpFlowCompleted$()  {
    return this.store.pipe(
      select(getSignUpFlowCompleted),
      filter(next => next !== undefined),
      first(),
    )
  }

  get gettingStartedStatus$() {
    return this.store.pipe(
      select(getGettingStartedStatus),
      filter(next => next !== undefined),
      filter(status => !!status),
      first(),
    )
  }

  setDynamicPromo() {
    // don't apply default promo if one is being applied from url
    const { queryParams } = this.route.snapshot
    if (queryParams.promo_code) {
      return
    }

    const brandingName = this.brandingService.getBrandingData().name.trim().toLowerCase()
    let promoParams = ''
    // TODO in the future we'll show different plans versions to different shopify plans
    switch (this.shopifyPlan) {
      // Students
      case 'open_learning':
        promoParams = `?promo_code=ONE%20EDU%20BFCM22&promo_type=startup_program&promo_referrer=${brandingName}&promo_view=bfcm`
        break
      // Non-Profits
      case 'npo_lite':
      case 'npo_full':
        promoParams = `?promo_code=ONE%20NPO%20BFCM22&promo_type=bfcm&promo_referrer=${brandingName}&promo_view=bfcm`
        break
      // Small Businesses
      case 'trial':
      case 'starter_2022_trial':
      case 'dormant':
        promoParams = `?promo_code=ONE%20STORE%20BFCM22&promo_type=bfcm&promo_referrer=${brandingName}&promo_view=bfcm`
        break
      case 'basic':
      case 'starter':
      case 'starter_2022':
      case 'grandfather':
        promoParams = `?promo_code=ONE%20STORE%20BFCM22&promo_type=bfcm&promo_referrer=${brandingName}&promo_view=bfcm`
        break
      // Medium Businesses
      case 'business':
      case 'professional':
      case 'unlimited':
      case 'custom':
        promoParams = `?promo_code=ONE%20STORE%20BFCM22&promo_type=bfcm&promo_referrer=${brandingName}&promo_view=bfcm`
        break
      // Enterprise Businesses
      case 'shopify_plus':
      case 'plus_partner_sandbox':
        promoParams = `?promo_code=ONE%20ENTERPRISE%20STORE%20BFCM22&promo_type=bfcm&promo_referrer=${brandingName}&promo_view=bfcm`
        break
      // Shopify Partners
      case 'affiliate':
        promoParams = `?promo_code=ONE%20AFFILIATE%20BFCM22&promo_type=bfcm&promo_referrer=${brandingName}&promo_view=bfcm`
        break
      case 'partner_test':
        promoParams = `?promo_code=ONE%20PARTNER%20BFCM22&promo_type=bfcm&promo_referrer=${brandingName}&promo_view=bfcm`
        break
      // Shopify Employees
      case 'staff':
      case 'staff_business':
      case 'sales_training':
      case 'shopify_alumni':
        promoParams = `?promo_code=ONE%20SHOPIFY%20STORE%20BFCM22&promo_type=bfcm&promo_referrer=${brandingName}&promo_view=bfcm`
        break
      // Shopify Churned
      case 'cancelled':
      case 'frozen':
      // Shopify Fraud
      case 'fraudulent':
      case 'locked':
        promoParams = `?promo_code=ONE%20STORE%20BFCM22&promo_type=bfcm&promo_referrer=${brandingName}&promo_view=bfcm`
        break
      default:
        promoParams = `?promo_code=ONE%20STORE%20BFCM22&promo_type=bfcm&promo_referrer=${brandingName}&promo_view=bfcm`
    }

    this.promoService.definePromo({params: promoParams}, this.userInfo)
    this.promoService.togglePromoState(this.userInfo)
  }

  updateJotformIfNeeded() {
    // if this is phone verification on signup flow send data to jotform
    if (this.userType === 'new') {
      const submissionId = this.gettingStartedService.getKeyValuePairValue(keyValuePairKey.PrePlansSurveyNewSubmissionId)
      const prefilled6Id = this.gettingStartedService.getKeyValuePairValue(keyValuePairKey.PrePlansSurveyNewPrefilled6)
      if (submissionId && prefilled6Id) {
        const jotFormPayload: any = {
          [prefilled6Id]: {
            field_1: PlatformOnboardingStatus.Started,
            field_2: PlatformOnboardingStage.PlanSelection,
          },
          new: '1',
        }
        // wrap in try catch to make sure this doesn't block anything
        try {
          this.subscription.add(this.apiJotformService.editSubmission(submissionId, jotFormPayload).subscribe())
        } catch (e) {
        }
      }
    }
  }

  enterFullScreen(event) {
    event.preventDefault()
    this.shopifyService.enableFullScreen()
  }

  exitFullScreen(event) {
    event.preventDefault()
    this.shopifyService.disableFullScreen()
  }

  ngOnInit() {
    this.renderer.addClass(document.body, '_white-bg')
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
    this.renderer.removeClass(document.body, '_white-bg')
  }
}
