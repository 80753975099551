import { HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { forkJoin, Observable, of, throwError, timer } from 'rxjs'
import { catchError, mergeMap, retryWhen } from 'rxjs/operators'
import { ApiService } from '../../../core/services/api/api.service'
import { ContactsResponse, ContactsResponseType, ContactsStatsType } from '../models/subscribers-page.model'

@Injectable()
export class ShopifySubscribersApiService {

  constructor(
    private apiService: ApiService,
  ) { }

  getCounts() {
    return this.apiService.get('/v1/me/subscriptions/counts')
  }

  getStatistics(startDate?: string, endDate?: string) {
    let req = new HttpParams()
    let req2 = new HttpParams()
    if (startDate && endDate) {
      req = req.append('start_date', startDate)
      req = req.append('end_date', endDate)
      req2 = req2.append('start_date', startDate)
      req2 = req2.append('end_date', endDate)
    }
    req2 = req2.append('source', 'one')
    return forkJoin([
      this.apiService.get('/v1/me/shop/contacts_statistics', req),
      this.apiService.get('/v1/me/shop/contacts_statistics', req2)]
    )
  }

  getStatisticsRange(startDate: string, endDate: string, source?: string) {
    let req = new HttpParams()
    req = req.append('start_date', startDate)
    req = req.append('end_date', endDate)
    if (source) {
      req = req.append('source', source)
    }
    return this.apiService.get('/v1/me/shop/contacts_statistics', req).pipe(
      catchError(error => {
        return this.handleWithStatisticsError(error, 'all')
      })
    )
  }

  getSubscribers(): Observable<ContactsResponse> {
    return this.apiService.get('/v1/me/shop/contacts_statistics').pipe(
      catchError(error => {
        return this.handleWithStatisticsError(error, 'all')
      })
    )
  }

  getContactsStatisticsField(field: ContactsResponseType | ContactsResponseType[]): Observable<ContactsResponse> {
    let params = new HttpParams()
    params = params.append('remote', 'false')
    if (typeof field === 'string') {
      params = params.append('field', field)
    } else {
      field?.forEach(f => {
        params = params.append('field[]', f)
      })
    }
    return this.apiService.get(`/v1/me/shop/contacts_statistics_field`, params).pipe(
      catchError(error => {
        return this.handleWithStatisticsError(error, field)
      })
    )
    // return this.apiService.get(`/v1/me/shop/contacts_statistics_field?field=${field}`).pipe(
    //   retryWhen(errors => errors.pipe(
    //     mergeMap(error => {
    //       if (error?.status === 429 || error?.status === 400) {
    //         return timer(5000)
    //       }
    //       return throwError(error)
    //     }
    //     )
    //   ))
    // )
  }

  putCustomPlanTrial(custom_plan_trial: boolean, custom_plan_trial_end_date: string) {
    return this.apiService.put('/v1/me/plans/custom', {custom_plan_trial, custom_plan_trial_end_date})
  }

  private handleWithStatisticsError(error: any, field: ContactsResponseType | 'all' | ContactsResponseType[]) {
    if (field === 'all') {
      console.error('Error getting contacts statistics', error)
      const contactsResponseKeys = Object.values(ContactsStatsType)
      const values = contactsResponseKeys.reduce((acc, key) => {
      acc[key] = 0
      return acc
      } , {} as ContactsResponse)
      return of(values)
    } else {
      console.error(`Error getting contacts statistics for field: ${field}`, error)
      if (typeof field === 'string') {
        return of({ [field]: 0 } as ContactsResponse)
      } else {
        const values = field.reduce((acc, key) => {
          acc[key] = 0
          return acc
        } , {} as ContactsResponse)
        return of(values)
      }
    }
  }
}
