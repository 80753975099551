export interface LoadingState {
  active: boolean, // todo: remove active prop because you can understand that from pendinglist size
  pendingList: Array<string>,
}


export const initialLoadingState: LoadingState = {
  active: false,
  pendingList: [],
}
