import { Component } from '@angular/core'
import { BasePromoComponent } from '../base-promo.component'
import { UntypedFormBuilder } from '@angular/forms'
import { ApiPaymentService } from '../../../../../core/services/api/api-payment.service'

@Component({
  selector: 'pf-cyber-week-view',
  templateUrl: './cyber-week-view.component.html',
  styleUrls: ['./cyber-week-view.component.scss']
})

export class CyberWeekViewComponent extends BasePromoComponent {
  constructor(
    public apiPaymentService: ApiPaymentService,
    public fb: UntypedFormBuilder
  ) {
    super(apiPaymentService, fb)
  }

}

