import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { tap } from 'rxjs/operators'
import { ActiveAppCampaigns } from '../../shared/models/app-campaign-type.model'
import { ApiService } from './api/api.service'

export interface CampaignCounts {
  [ActiveAppCampaigns.Popup]: number,
  [ActiveAppCampaigns.PostPurchaseUpsell]: number,
  [ActiveAppCampaigns.EmailAutomation]: number,
  [ActiveAppCampaigns.EmailBroadcast]: number,
  [ActiveAppCampaigns.SmsAutomation]: number,
  [ActiveAppCampaigns.SmsBroadcast]: number,
  [ActiveAppCampaigns.LinkedEmailAutomation]: number,
  [ActiveAppCampaigns.LinkedSmsAutomation]: number,
  [ActiveAppCampaigns.Total]: number,
  [ActiveAppCampaigns.CompletedEmailBroadcast]: number,
  [ActiveAppCampaigns.CompletedSmsBroadcast]: number,
  [ActiveAppCampaigns.LinkedCoupons]: number,
  [ActiveAppCampaigns.LinkedGiftCards]: number,
}
@Injectable()
export class ActiveAppCampaignService {
  public campaignCounts$ = new BehaviorSubject<CampaignCounts>(null)
  public activeCampaignCount$ = new BehaviorSubject<number>(null)

  constructor(
    private apiService: ApiService,
  ) {
  }

  refreshTotals() {
    this.apiService.get('/v1/me/active_campaigns_totals').pipe(tap(totals => {
      if (totals.hasOwnProperty(ActiveAppCampaigns.Total)) {
        this.campaignCounts$.next(totals)
        this.activeCampaignCount$.next(totals[ActiveAppCampaigns.Total])
      }
    })).subscribe()
  }
}
