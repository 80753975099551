import { NgModule } from '@angular/core'
import { CustomSnackbarService } from './custom-snackbar.service'
import { CustomSnackbarComponent } from './custom-snackbar.component'
import { CommonModule } from '@angular/common'
import { MatSnackBar } from '@angular/material/snack-bar'
import { OneIconComponent } from '../../components/one-icon/one-icon.component'

@NgModule({

  declarations: [
    CustomSnackbarComponent,
  ],
  imports: [
    CommonModule,
    OneIconComponent,
  ],
  exports: [
  ],
  providers: [
    CustomSnackbarService,
    MatSnackBar,
  ]
})
export class CustomSnackbarModule { }
