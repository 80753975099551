import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'one-avatar',
  templateUrl: 'one-avatar.component.html',
  styleUrls: ['one-avatar.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
  ]
})

export class OneAvatarComponent implements OnChanges {
  @Input() username: string = ''
  backgroundColor: string = ''
  initials: string = ''

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['username']) {
      this.backgroundColor = this.stringToColor(this.username)
      this.initials = this.getInitials(this.username)
    }
  }

  stringToColor(str: string) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#'
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xFF
      color += ('00' + value.toString(16)).substr(-2)
    }
    return color;
  }

  private getInitials(name: string): string {
    const names = name.split('-')
    if (names.length === 1) {
      return name.substring(0, 3).toUpperCase()
    } else {
      return names.slice(0, 3).map(n => n[0]).join('').toUpperCase()
    }
  }
}
