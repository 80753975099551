import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Observable } from 'rxjs'
import { map, tap, first } from 'rxjs/operators'

import { AuthService } from '../services/auth.service'
import { Store } from '@ngrx/store'
import { StoreState } from '../../store/store.state'
import { HideLoading, ShowLoading } from '../../store/loading/loading.actions'
import { Logger } from '../services/logger.service'
import { LogLabel } from '../../shared/models/logger/log-label.model'

@Injectable()
export class NonAuthGuard  {
  constructor(
    private router: Router,
    private authService: AuthService,
    private store: Store<StoreState>,
    private logger: Logger,
  ) {
  }

  check(): Observable<boolean> {
    const loadingLabel = 'NonAuthGuard'
    this.store.dispatch(new ShowLoading(loadingLabel))
    return this.authService.user$.pipe(
      first(),
      map(authState => !authState),
      tap((authenticated) => {
        this.logger.log(<LogLabel>'non-auth-guard', 'authenticated: ', authenticated)
        this.store.dispatch(new HideLoading(loadingLabel))
      }),
      tap(isGuest => !isGuest && this.router.navigate(['/'])),
    )
  }

  canActivate(): Observable<boolean> {
    return this.check()
  }

  canActivateChild(): Observable<boolean> {
    return this.check()
  }
}
