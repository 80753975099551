<div class="modal-header">
    <div class="modal-title">Select Plan</div>
    <button class="close" (click)="dialogRef.close()">
        <span>&times;</span>
    </button>
</div>
<div class="modal-body">
    <div>You are going to change your plan to <b>{{newPlan.name}}</b>.</div>
</div>
<div class="modal-footer">
    <button class="pf-button md outline gray" (click)="dialogRef.close()">Cancel</button>
    <button class="pf-button md filled blue ml-3" (click)="submit()">Change</button>
</div>

<pf-loading-overlay></pf-loading-overlay>
