import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { select, Store } from '@ngrx/store'
import { forkJoin, Observable } from 'rxjs'
import { StoreState } from '../../store/store.state'
import { getUserChoseInitialPlanCompleted, getUserInfo } from '../../store/user/user.selectors'
import { filter, finalize, map } from 'rxjs/operators'
import { first } from 'rxjs/operators'
import { HideLoading, ShowLoading } from '../../store/loading/loading.actions'
import { LoadingLabel } from '../../shared/models/loading/loading-label'
import { Logger } from '../services/logger.service'
import { LogLabel } from '../../shared/models/logger/log-label.model'
import _ from 'lodash'
import { UserInfo } from '../../store/user/user.state'
import { GettingStartedStatus } from '../../shared/models/getting-started/getting-started-status.model'
import { getGettingStartedStatus } from '../../store/getting-started/getting-started.selectors'

@Injectable()
export class PlatformOnboardingDoneGuard  {
  constructor(
    private store: Store<StoreState>,
    private logger: Logger,
    private router: Router,
  ) {
  }

  check() {
    this.store.dispatch(new ShowLoading(LoadingLabel.PlatformOnboardingDoneGuard))
    return forkJoin([this.userInfo$, this.gettingStartedStatus$, this.userChoseInitialPlanCompleted$]).pipe(
      map(([userInfo, status, choseInitialPlan]) => {
        this.logger.log(<LogLabel>'platform-onboarding-done-guard', 'userInfo: ', userInfo)
        const isShopifyShop = userInfo.shop?.type === 'ShopifyShop'
        const onboardingLocked = userInfo?.platform_onboarding_locked !== false
        // If plan is not chosen and onboarding status is completed - user is returning
        const userType = !_.get(status, 'completed', false) ? 'new' : 'returning'
        const isShopifyStaff = _.get(userInfo, 'shop.profile.plan_name', '') === 'staff'

        // if they should see the onboarding (many active customers) & are locked (true or undefined)
        if (!choseInitialPlan && !isShopifyStaff && userType === 'new' && isShopifyShop && onboardingLocked) {
          this.router.navigateByUrl(`/onboarding/signup/${userType}/vip-onboarding`).then(() => {
            this.store.dispatch(new HideLoading(LoadingLabel.ChoseInitialPlanCompleteGuard))
          })
          return false
        }

        return true
      }),
      finalize(() => this.store.dispatch(new HideLoading(LoadingLabel.PlatformOnboardingDoneGuard))),
    )
  }

  get userInfo$(): Observable<UserInfo> {
    return this.store.pipe(
      select(getUserInfo),
      filter(info => info !== undefined && !!info.created_at),
      first(),
    )
  }

  get gettingStartedStatus$(): Observable<GettingStartedStatus> {
    return this.store.pipe(
      select(getGettingStartedStatus),
      filter(next => next !== undefined),
      filter(status => !!status),
      first(),
    )
  }

  get userChoseInitialPlanCompleted$(): Observable<boolean> {
    return this.store.pipe(
      select(getUserChoseInitialPlanCompleted),
      filter(completed => completed !== undefined),
      first(),
    )
  }

  canActivate(): Observable<boolean> {
    return this.check()
  }

  canActivateChild(): Observable<boolean> {
    return this.check()
  }
}
