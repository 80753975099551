import { Action } from '@ngrx/store'
import { MailIntegrationActionTypes } from './mail-integration.action-types'
import { AsyncAction } from '../../shared/models/store/async-action.model'
import { TypeOfIntegrationEntity } from '../../core/services/integrations.service'
import { Integration, MailIntegration, MailIntegrationList } from '../../shared/models/mail-integration.model'
import { CampaignPluginName } from '../../shared/models/campaign/campaign'

export class SetSelectedIntegration implements Action {
  readonly type = MailIntegrationActionTypes.SET_SELECTED_INTEGRATION

  constructor(public payload: MailIntegration) {
  }
}

export class ClearSelectedIntegration implements Action {
  readonly type = MailIntegrationActionTypes.CLEAR_SELECTED_INTEGRATION
}

export class ReadAllIntegrationsRequest implements AsyncAction {
  readonly type = MailIntegrationActionTypes.READ_ALL_INTEGRATIONS_REQUEST
  readonly loading = { id: MailIntegrationActionTypes.READ_ALL_INTEGRATIONS_REQUEST, active: true }
}

export class ReadAllIntegrationsSuccess implements AsyncAction {
  readonly type = MailIntegrationActionTypes.READ_ALL_INTEGRATIONS_SUCCESS
  readonly loading = { id: MailIntegrationActionTypes.READ_ALL_INTEGRATIONS_REQUEST, active: false }

  constructor(public payload: MailIntegration[]) {
  }
}

export class ReadAllIntegrationsFailure implements AsyncAction {
  readonly type = MailIntegrationActionTypes.READ_ALL_INTEGRATIONS_FAILURE
  readonly loading = { id: MailIntegrationActionTypes.READ_ALL_INTEGRATIONS_REQUEST, active: false }

  constructor(public payload: string) {
  }
}

export class CreateIntegrationRequest implements AsyncAction {
  readonly type = MailIntegrationActionTypes.CREATE_INTEGRATION_REQUEST
  readonly loading = { id: MailIntegrationActionTypes.CREATE_INTEGRATION_REQUEST, active: true }

  constructor(public payload: Integration) {
  }
}

export class CreateIntegrationSuccess implements AsyncAction {
  readonly type = MailIntegrationActionTypes.CREATE_INTEGRATION_SUCCESS
  readonly loading = { id: MailIntegrationActionTypes.CREATE_INTEGRATION_REQUEST, active: false }

  constructor(public payload: MailIntegration) {
  }
}

export class CreateIntegrationFailure implements AsyncAction {
  readonly type = MailIntegrationActionTypes.CREATE_INTEGRATION_FAILURE
  readonly loading = { id: MailIntegrationActionTypes.CREATE_INTEGRATION_REQUEST, active: false }

  constructor(public payload: string) {
  }
}

export class DeleteIntegrationRequest implements AsyncAction {
  readonly type = MailIntegrationActionTypes.DELETE_INTEGRATION_REQUEST
  readonly loading = { id: MailIntegrationActionTypes.DELETE_INTEGRATION_REQUEST, active: true }

  constructor(public payload: {id: string, typeOfIntegrationEntity: TypeOfIntegrationEntity}) {
  }
}

export class DeleteIntegrationSuccess implements AsyncAction {
  readonly type = MailIntegrationActionTypes.DELETE_INTEGRATION_SUCCESS
  readonly loading = { id: MailIntegrationActionTypes.DELETE_INTEGRATION_REQUEST, active: false }

  constructor(public payload: string) {
  }
}

export class DeleteIntegrationFailure implements AsyncAction {
  readonly type = MailIntegrationActionTypes.DELETE_INTEGRATION_FAILURE
  readonly loading = { id: MailIntegrationActionTypes.DELETE_INTEGRATION_REQUEST, active: false }

  constructor(public payload: string) {
  }
}

export class DeleteFortuneWheelIntegrationRequest implements AsyncAction {
  readonly type = MailIntegrationActionTypes.DELETE_FORTUNE_WHEEL_INTEGRATION_REQUEST
  readonly loading = { id: MailIntegrationActionTypes.DELETE_FORTUNE_WHEEL_INTEGRATION_REQUEST, active: true }

  constructor(public payload: { mail_system_id: string; sms_system_id: string; id: string, typeOfIntegrationEntity: TypeOfIntegrationEntity }) {
  }
}

export class DeleteFortuneWheelIntegrationSuccess implements AsyncAction {
  readonly type = MailIntegrationActionTypes.DELETE_FORTUNE_WHEEL_INTEGRATION_SUCCESS
  readonly loading = { id: MailIntegrationActionTypes.DELETE_FORTUNE_WHEEL_INTEGRATION_REQUEST, active: false }

  constructor(public payload: { status: boolean }) {
  }
}

export class DeleteFortuneWheelIntegrationFailure implements AsyncAction {
  readonly type = MailIntegrationActionTypes.DELETE_FORTUNE_WHEEL_INTEGRATION_FAILURE
  readonly loading = { id: MailIntegrationActionTypes.DELETE_FORTUNE_WHEEL_INTEGRATION_REQUEST, active: false }

  constructor(public payload: string) {
  }
}

export class ReadIntegrationListsRequest implements AsyncAction {
  readonly type = MailIntegrationActionTypes.READ_INTEGRATION_LISTS_REQUEST
  readonly loading = { id: MailIntegrationActionTypes.READ_INTEGRATION_LISTS_REQUEST, active: true }

  constructor(public payload: MailIntegration) {
  }
}

export class ReadIntegrationListsSuccess implements AsyncAction {
  readonly type = MailIntegrationActionTypes.READ_INTEGRATION_LISTS_SUCCESS
  readonly loading = { id: MailIntegrationActionTypes.READ_INTEGRATION_LISTS_REQUEST, active: false }

  constructor(public payload: MailIntegrationList[]) {
  }
}

export class ReadIntegrationListsFailure implements AsyncAction {
  readonly type = MailIntegrationActionTypes.READ_INTEGRATION_LISTS_FAILURE
  readonly loading = { id: MailIntegrationActionTypes.READ_INTEGRATION_LISTS_REQUEST, active: false }

  constructor(public payload: string) {
  }
}

export class CreateFortuneWheelIntegrationRequest implements AsyncAction {
  readonly type = MailIntegrationActionTypes.CREATE_FORTUNE_WHEEL_INTEGRATION_REQUEST
  readonly loading = { id: MailIntegrationActionTypes.CREATE_FORTUNE_WHEEL_INTEGRATION_REQUEST, active: true }

  constructor(public payload: CreateIntegrationRequestParams) {}
}

export class CreateFortuneWheelIntegrationSuccess implements AsyncAction {
  readonly type = MailIntegrationActionTypes.CREATE_FORTUNE_WHEEL_INTEGRATION_SUCCESS
  readonly loading = { id: MailIntegrationActionTypes.CREATE_FORTUNE_WHEEL_INTEGRATION_REQUEST, active: false }

  constructor(public payload: { status: boolean }) {
  }
}

export class CreateFortuneWheelIntegrationFailure implements AsyncAction {
  readonly type = MailIntegrationActionTypes.CREATE_FORTUNE_WHEEL_INTEGRATION_FAILURE
  readonly loading = { id: MailIntegrationActionTypes.CREATE_FORTUNE_WHEEL_INTEGRATION_REQUEST, active: false }

  constructor(public payload: string) {
  }
}

export class DeleteCouponBoxIntegrationRequest implements AsyncAction {
  readonly type = MailIntegrationActionTypes.DELETE_COUPON_BOX_INTEGRATION_REQUEST
  readonly loading = { id: MailIntegrationActionTypes.DELETE_COUPON_BOX_INTEGRATION_REQUEST, active: true }

  constructor(public payload: { mail_system_id: string; sms_system_id: string; id: string, typeOfIntegrationEntity: TypeOfIntegrationEntity }) {
  }
}

export class DeleteCouponBoxIntegrationSuccess implements AsyncAction {
  readonly type = MailIntegrationActionTypes.DELETE_COUPON_BOX_INTEGRATION_SUCCESS
  readonly loading = { id: MailIntegrationActionTypes.DELETE_COUPON_BOX_INTEGRATION_REQUEST, active: false }

  constructor(public payload: { status: boolean }) {
  }
}

export class DeleteCouponBoxIntegrationFailure implements AsyncAction {
  readonly type = MailIntegrationActionTypes.DELETE_COUPON_BOX_INTEGRATION_FAILURE
  readonly loading = { id: MailIntegrationActionTypes.DELETE_COUPON_BOX_INTEGRATION_REQUEST, active: false }

  constructor(public payload: string) {
  }
}

export class DeleteNewsletterIntegrationRequest implements AsyncAction {
  readonly type = MailIntegrationActionTypes.DELETE_NEWSLETTER_INTEGRATION_REQUEST
  readonly loading = { id: MailIntegrationActionTypes.DELETE_NEWSLETTER_INTEGRATION_REQUEST, active: true }

  constructor(public payload: { mail_system_id: string; sms_system_id: string; id: string, typeOfIntegrationEntity: TypeOfIntegrationEntity }) {
  }
}

export class DeleteNewsletterIntegrationSuccess implements AsyncAction {
  readonly type = MailIntegrationActionTypes.DELETE_NEWSLETTER_INTEGRATION_SUCCESS
  readonly loading = { id: MailIntegrationActionTypes.DELETE_NEWSLETTER_INTEGRATION_REQUEST, active: false }

  constructor(public payload: { status: boolean }) {
  }
}

export class DeleteNewsletterIntegrationFailure implements AsyncAction {
  readonly type = MailIntegrationActionTypes.DELETE_NEWSLETTER_INTEGRATION_FAILURE
  readonly loading = { id: MailIntegrationActionTypes.DELETE_NEWSLETTER_INTEGRATION_REQUEST, active: false }

  constructor(public payload: string) {
  }
}

export interface BaseCreateIntegrationRequestParams {
  typeOfIntegrationEntity: TypeOfIntegrationEntity,
  id: string;
}

export interface CreateSMSIntegrationRequestParams extends BaseCreateIntegrationRequestParams {
  sms_system_id: string;
}

export interface CreateEmailIntegrationRequestParams extends BaseCreateIntegrationRequestParams {
  mail_system_id: string;
  list_id: string;
  sync_existing: boolean,
  account_id?: string
}

export type CreateIntegrationRequestParams = CreateEmailIntegrationRequestParams | CreateSMSIntegrationRequestParams;

export class CreateNewsletterIntegrationRequest implements AsyncAction {
  readonly type = MailIntegrationActionTypes.CREATE_NEWSLETTER_INTEGRATION_REQUEST
  readonly loading = { id: MailIntegrationActionTypes.CREATE_NEWSLETTER_INTEGRATION_REQUEST, active: true }

  constructor(public payload: CreateIntegrationRequestParams) {
  }
}

export class CreateNewsletterIntegrationSuccess implements AsyncAction {
  readonly type = MailIntegrationActionTypes.CREATE_NEWSLETTER_INTEGRATION_SUCCESS
  readonly loading = { id: MailIntegrationActionTypes.CREATE_NEWSLETTER_INTEGRATION_REQUEST, active: false }

  constructor(public payload: { status: boolean }) {
  }
}

export class CreateNewsletterIntegrationFailure implements AsyncAction {
  readonly type = MailIntegrationActionTypes.CREATE_NEWSLETTER_INTEGRATION_FAILURE
  readonly loading = { id: MailIntegrationActionTypes.CREATE_NEWSLETTER_INTEGRATION_REQUEST, active: false }

  constructor(public payload: string) {
  }
}

export class CreateCouponBoxIntegrationRequest implements AsyncAction {
  readonly type = MailIntegrationActionTypes.CREATE_COUPON_BOX_INTEGRATION_REQUEST
  readonly loading = { id: MailIntegrationActionTypes.CREATE_COUPON_BOX_INTEGRATION_REQUEST, active: true }

  constructor(public payload: CreateIntegrationRequestParams) {
  }
}

export class CreateCouponBoxIntegrationSuccess implements AsyncAction {
  readonly type = MailIntegrationActionTypes.CREATE_COUPON_BOX_INTEGRATION_SUCCESS
  readonly loading = { id: MailIntegrationActionTypes.CREATE_COUPON_BOX_INTEGRATION_REQUEST, active: false }

  constructor(public payload: { status: boolean }) {
  }
}

export class CreateCouponBoxIntegrationFailure implements AsyncAction {
  readonly type = MailIntegrationActionTypes.CREATE_COUPON_BOX_INTEGRATION_FAILURE
  readonly loading = { id: MailIntegrationActionTypes.CREATE_COUPON_BOX_INTEGRATION_REQUEST, active: false }

  constructor(public payload: string) {
  }
}

export interface CreateMultipleSmsSystemParams {
  sms_system_id: string
  plugin_type: CampaignPluginName.FortuneWheel | CampaignPluginName.CouponBoxNotification | CampaignPluginName.NewsLetterNotification
  list_id?: string | number
}

export interface CreateMultipleMailSystemParams {
  mail_system_id: string
  list_id: string
  account_id?: string
  sync_existing: boolean
  plugin_type: CampaignPluginName.FortuneWheel | CampaignPluginName.CouponBoxNotification | CampaignPluginName.NewsLetterNotification
}

export type CreateMultipleSystemParams = CreateMultipleMailSystemParams | CreateMultipleSmsSystemParams;

export class CreateMultipleIntegrationsRequest implements AsyncAction {
  readonly type = MailIntegrationActionTypes.CREATE_MULTIPLE_INTEGRATIONS_REQUEST
  readonly loading = { id: MailIntegrationActionTypes.CREATE_MULTIPLE_INTEGRATIONS_REQUEST, active: true }

  constructor(
    public payload: {
      campaignId: string
      integrations: CreateMultipleSystemParams[],
      typeOfIntegrationEntity: TypeOfIntegrationEntity,
    },
  ) {
  }
}

export class CreateMultipleIntegrationsSuccess implements AsyncAction {
  readonly type = MailIntegrationActionTypes.CREATE_MULTIPLE_INTEGRATIONS_SUCCESS
  readonly loading = { id: MailIntegrationActionTypes.CREATE_MULTIPLE_INTEGRATIONS_REQUEST, active: false }

  constructor(public payload: { status: boolean }) {
  }
}

export class CreateMultipleIntegrationsFailure implements AsyncAction {
  readonly type = MailIntegrationActionTypes.CREATE_MULTIPLE_INTEGRATIONS_FAILURE
  readonly loading = { id: MailIntegrationActionTypes.CREATE_MULTIPLE_INTEGRATIONS_REQUEST, active: false }

  constructor(public payload: string) {
  }
}

export type MailIntegrationActions =
  | ReadAllIntegrationsRequest
  | ReadAllIntegrationsSuccess
  | ReadAllIntegrationsFailure
  | CreateIntegrationRequest
  | CreateIntegrationSuccess
  | CreateIntegrationFailure
  | DeleteIntegrationRequest
  | DeleteIntegrationSuccess
  | DeleteIntegrationFailure
  | SetSelectedIntegration
  | ClearSelectedIntegration
  | ReadIntegrationListsRequest
  | ReadIntegrationListsSuccess
  | ReadIntegrationListsFailure
  | CreateFortuneWheelIntegrationRequest
  | CreateFortuneWheelIntegrationSuccess
  | CreateFortuneWheelIntegrationFailure
  | CreateCouponBoxIntegrationRequest
  | CreateCouponBoxIntegrationSuccess
  | CreateCouponBoxIntegrationFailure
  | DeleteCouponBoxIntegrationRequest
  | DeleteCouponBoxIntegrationSuccess
  | DeleteCouponBoxIntegrationFailure
  | CreateMultipleIntegrationsRequest
  | CreateMultipleIntegrationsSuccess
  | CreateMultipleIntegrationsFailure
  | DeleteNewsletterIntegrationRequest
  | DeleteNewsletterIntegrationSuccess
  | DeleteNewsletterIntegrationFailure
  | CreateNewsletterIntegrationRequest
  | CreateNewsletterIntegrationSuccess
  | CreateNewsletterIntegrationFailure
