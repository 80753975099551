import { Injectable } from '@angular/core'
import { LoadingOverlayService } from './loading-overlay.service'
import { Logger } from './logger.service'
import { ValidateCouponResponse } from '../../shared/models/validate-coupon-response'
import { Observable } from 'rxjs'
import { Token } from '@stripe/stripe-js'
import { ApiPaymentService } from './api/api-payment.service'
import { LogLabel } from '../../shared/models/logger/log-label.model'
import { SubscriptionPlan } from '../../shared/models/subscription-plan.model'

@Injectable()
export class PaymentService {

  public source: Token

  constructor(
    private apiPaymentService: ApiPaymentService,
    private logger: Logger,
    private loadingOverlay: LoadingOverlayService,
  ) {
  }

  /* API */

  saveStripeToken(source) {
    this.source = source
    return this.apiPaymentService.putBillingStripeToken(source.id)
  }


  getPlansRequest(version: number = null) {
    return this.apiPaymentService.getPlans(version)
  }

  getPlans() {
    const value = this.loadingOverlay.wrap(
      this.apiPaymentService.getPlans(), 'payment_plans',
    )
    this.logger.log(LogLabel.PaymentService, `value: ${JSON.stringify(value)}`)
    return value
  }


  validateCoupon(coupon: string): Observable<ValidateCouponResponse> {
    return this.apiPaymentService.postBillingValidCoupon(coupon)
  }

  changeSubscriptionPlan({ payment_gateway_plan_identifier }: SubscriptionPlan, coupon?: string, redirectUrl?: string, usage_plan_id?: string) {
    if (redirectUrl) {
      const url = new URL(redirectUrl)
      url.searchParams.delete('payment_gateway')
      url.searchParams.append('payment_gateway', 'shopify')
      redirectUrl = url.toString()
    }
    return this.apiPaymentService.putSubscriptions(payment_gateway_plan_identifier, coupon, redirectUrl, usage_plan_id)
  }

  getSubscriptionPlanSettingsLabel(plan): string {
    return plan.name + (plan.interval === 'year' ? ' (Annual)' : '')
  }


  getPriceAmount(plan: SubscriptionPlan): number {
    return +plan.price.fractional / plan.price.currency.subunit_to_unit
  }

  getPriceAmountWithDiscount(plan: SubscriptionPlan, coupon?: ValidateCouponResponse): number {
    let amount = this.getPriceAmount(plan)
    if (coupon && coupon.valid) {
      if (coupon.amount_off) {
        amount = amount - coupon.amount_off
      } else if (coupon.percent_off) {
        amount = amount - (amount / 100 * coupon.percent_off)
      }
    }
    return amount > 0 ? amount : 0
  }

  getFreePlan(plans: SubscriptionPlan[]): SubscriptionPlan {
    return plans.find(plan => plan.payment_gateway_plan_identifier === 'budding')
  }

  getVisibleSmallerPlans(plans: SubscriptionPlan[], annual?: boolean, buddingMiddle?: boolean): SubscriptionPlan[] {
    const visiblePlans = this.getVisiblePlans(plans, annual).slice(0, 3)
    // make sure the first plan is budding and place it int the second place for visible plans
    if (buddingMiddle && visiblePlans.length > 2 && visiblePlans[0].payment_gateway_plan_identifier === 'budding') {
      const budding = visiblePlans[0]
      visiblePlans[0] = visiblePlans[1]
      visiblePlans[1] = budding
    }
    this.definePopularPlan(visiblePlans)
    return visiblePlans
  }

  getVisibleLargerPlans(plans: SubscriptionPlan[], annual?: boolean): SubscriptionPlan[] {
    const visiblePlans = this.getVisiblePlans(plans, annual).slice(3)
    this.definePopularPlan(visiblePlans)
    return visiblePlans
  }

  getVisiblePaidPlans(plans: SubscriptionPlan[], annual?: boolean): SubscriptionPlan[] {
    return this.getVisiblePlans(plans, annual)
      .filter(plan => parseFloat(plan.price.fractional) > 0)
  }

  getVisiblePlans(plans: SubscriptionPlan[], annual?: boolean): SubscriptionPlan[] {
    return plans
      .filter(plan => this.showPlan(plan, annual))
  }

  definePopularPlan(visiblePlans: SubscriptionPlan[]) {
    if (visiblePlans.length === 3 && visiblePlans[1]) {
      visiblePlans[1].popular = true
    }
  }


  showPlan(plan: SubscriptionPlan, isIntervalAnnual: boolean) {
    const hasCorrectInterval = plan.interval === (isIntervalAnnual ? 'year' : 'month')
    return plan.public && hasCorrectInterval
  }

}
