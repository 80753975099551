import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { Store } from '@ngrx/store'
import { StoreState } from '../../../store/store.state'
import { ShowLoading, HideLoading, ResetLoading } from '../../../store/loading/loading.actions'
import { ValidateCouponResponse } from '../../../shared/models/validate-coupon-response'
import { ReadBillingSuccess } from '../../../store/billing/billing.actions'
import { Subscription } from 'rxjs'
import { StripeElements, StripeCardElement } from '@stripe/stripe-js'
import { StripeService } from 'ngx-stripe'
import { SubscriptionPlan } from '../../../shared/models/subscription-plan.model'
import { PaymentService } from '../../../core/services/payment.service'
import { ErrorsService } from '../../../core/services/errors.service'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

@Component({
  templateUrl: './add-card.modal.html',
  styleUrls: ['./add-card.modal.scss'],
})
export class AddCardModal implements OnInit, OnDestroy {
  plan: SubscriptionPlan = this.data
  isCouponBodyVisible: boolean
  errors = []
  couponControl = new UntypedFormControl('', [Validators.required])
  couponResponse: ValidateCouponResponse
  showCouponFailMessage = false
  lastValidatedCoupon: string
  elements: StripeElements
  stripeForm: UntypedFormGroup
  stripeElement: StripeCardElement = null
  subscription = new Subscription()
  zipCode = new UntypedFormControl('')

  constructor(
    private paymentService: PaymentService,
    private store: Store<StoreState>,
    private stripeService: StripeService,
    private errorsService: ErrorsService,
    public dialogRef: MatDialogRef<AddCardModal>,
    @Inject(MAT_DIALOG_DATA) public data: SubscriptionPlan,
  ) {
    this.dialogRef.addPanelClass(['_no-padding'])
  }

  ngOnInit() {
    this.stripeForm = new UntypedFormGroup({})
    this.stripeForm.disable()
    this.store.dispatch(new ShowLoading('stripeLoading'))
    this.subscription.add(this.stripeService.elements()
      .subscribe(elements => {
        this._setupStripeElements(elements)
      }))
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
    this.store.dispatch(new ResetLoading())
  }

  private _setupStripeElements(elements) {
    this.elements = elements
    this._mountElement('cardNumber')
  }

  private _mountElement(mountName) {
    this.stripeElement = this.elements.create('card', {
      style: {
        base: {
          iconColor: '#666EE8',
          color: '#31325F',
          lineHeight: '40px',
          fontWeight: 300,
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSize: '18px',
          '::placeholder': {
            color: '#CFD7E0',
          },
        },
      },
    })
    this.stripeElement.mount('#' + mountName)
    this.stripeElement.on('change', ({ empty, error, complete }) => {
      if (complete) {
        this.stripeForm.enable()
        this.errors = []
      } else if (error && error.message) {
        this.stripeForm.disable()
        this.errors = [error.message]
      } else if (empty) {
        this.errors = []
        this.stripeForm.disable()
      }
    })
    this.stripeElement.on('ready', () => {
      this.store.dispatch(new HideLoading('stripeLoading'))
    })
  }

  onCardFormSubmit(result) {
    this.errors = []
    if (result.token) {
      this.savePaymentToken(result.token)
    } else if (result.error) {
      this.handleError(result.error)
    }
  }

  savePaymentToken(source) {
    this.store.dispatch(new ShowLoading('savePaymentToken'))
    const tokenSubscription = this.paymentService.saveStripeToken(source).subscribe(
      (billingInfo) => {
        this.store.dispatch(new ReadBillingSuccess(billingInfo))
        this.dialogRef.close(this.couponResponse)
      },
      err => this.handleError(err.error))

    this.subscription.add(tokenSubscription)

    tokenSubscription.add(() => {
      this.store.dispatch(new HideLoading('savePaymentToken'))
    })
  }

  handleError(error) {
    this.errors.push(this.errorsService.stringifyBilling(error))
  }

  addCard() {
    if (!this.stripeForm.disabled && this.stripeForm.valid) {
      this.store.dispatch(new ShowLoading('addCard'))
      this.subscription.add(this.stripeService
        .createToken(this.stripeElement, { address_zip: this.zipCode.value })
        .subscribe(result => {
          this.store.dispatch(new HideLoading('addCard'))
          this.onCardFormSubmit(result)
        }))
    }
  }

  getPriceAmount(plan: SubscriptionPlan): number {
    return this.paymentService.getPriceAmount(plan)
  }

  getPriceAmountWithDiscount(plan: SubscriptionPlan): number {
    return this.paymentService.getPriceAmountWithDiscount(plan)
  }

  onCouponApplyClick(e: MouseEvent) {
    e.preventDefault()
    this.showCouponFailMessage = false
    if (this.couponControl.valid) {
      const coupon = this.couponControl.value
      this.lastValidatedCoupon = coupon
      this.validateCoupon(coupon)
    }
  }

  validateCoupon(coupon: string) {
    this.store.dispatch(new ShowLoading('validateCoupon'))
    const paymentSubscription = this.paymentService.validateCoupon(coupon)
      .subscribe(
        (next: ValidateCouponResponse) => {
          this.couponResponse = next
          if (!next.valid) {
            this.showCouponFailMessage = true
          }
        },
        () => this.showCouponFailMessage = true,
      )
    this.subscription.add(paymentSubscription)
    paymentSubscription.add(() => this.store.dispatch(new HideLoading('validateCoupon')))
  }
}
