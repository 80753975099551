<div class="Support-Flex">
  <pf-message-box
    *ngIf="hasCalendly"
    [mobileLayout]="true"
    messageType="success"
    class="mx-4"
    [customStyle]="{'border-radius': '5px', 'margin-bottom': '12px'}"
    [messageHtml]="'Need a demo?\<br>Try our \<b>Free VIP Setup\</b>'"
    buttonText="Schedule Call"
    (buttonClick)="openCalendlyLink()"
    buttonClass="pf-button green outline">
  </pf-message-box>
  <pf-message-box
    *ngIf="hasContactSupport"
    [mobileLayout]="true"
    messageType="info"
    class="mx-4"
    [customStyle]="{'border-radius': '5px', 'margin-bottom': '12px'}"
    [messageHtml]="'Have a question?\<br>Talk to a \<b>Real Person\</b>'"
    buttonText="Start Live Chat"
    (buttonClick)="contactSupport()"
    buttonClass="pf-button blue outline">
  </pf-message-box>
  <pf-message-box
    *ngIf="hasRequestFeature"
    [mobileLayout]="true"
    messageType="error"
    class="mx-4"
    [customStyle]="{'border-radius': '5px', 'margin-bottom': '12px'}"
    messageText="Don't see what you want?"
    buttonText="Request Feature"
    (buttonClick)="requestFeature()">
  </pf-message-box>
</div>
<div [innerHtml]="calendlyScript"></div>
