<pf-auth-layout *ngIf='!isLoading'>
  <ng-container class='auth-title'>
    {{ isRegisterPage ? 'Sign Up with Shopify' : 'Login with Shopify'}}
  </ng-container>
  <ng-container class='auth-content'>
    <div class="_local-width">
      <div class='form-horizontal'>
        <div class='form-group row mb-0'>
          <div class='col-sm-12'>
            <div class="form-group inside-label position-relative">
              <label>Store URL</label>
              <input 
                type="text"
                class="form-control _local-input"
                name="shop"
                (keyup.enter)="inputEnterPress()"
                (paste)="onPaste($event)"
                autocomplete="off"
                [(ngModel)]="domain"
                required>
              <span class="_local-domain-hint">.myshopify.com</span>
            </div>
          </div>
        </div>
      </div>
      <!-- REGISTER -->
      <ng-container *ngIf="isRegisterPage">
        <form ngNoForm #shopifyForm (submit)="onRegisterSubmit($event)" autocomplete="off">
          <button class='pf-button fw filled blue mb-4' type="submit">
            Sign Up
          </button>
          <ng-container *ngIf="showGoToButton">
            <p>Go to
              <a *ngIf='isRegisterPage' routerLink='/register'>sign up page</a>
              <a *ngIf='!isRegisterPage' routerLink='/login'>login page</a>
            </p>
          </ng-container>
        </form>
      </ng-container>
    
      <!-- LOGIN -->
      <ng-container *ngIf="!isRegisterPage">
        <form ngNoForm  #shopifyForm (submit)="onLoginSubmit($event)" autocomplete="off">
          <button class='pf-button fw filled blue mb-4' type='submit'>
            Login
          </button>
          <ng-container *ngIf="showGoToButton">
            <p>Go to
              <a *ngIf='isRegisterPage' routerLink='/register'>sign up page</a>
              <a *ngIf='!isRegisterPage' routerLink='/login'>login page</a>
            </p>
          </ng-container>
        </form>
      </ng-container>

     
    </div>
  </ng-container>
</pf-auth-layout>

<pf-loading-overlay></pf-loading-overlay>
