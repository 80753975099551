import { Injectable } from '@angular/core'

@Injectable({providedIn: 'root'})
export class PreviousUrlService {

  private _previousUrl: string = ''
  private _currentUrl: string = ''
  
  public get previousUrl() {
    return this._previousUrl
  }

  public set previousUrl(value: string) {
    this._previousUrl = value
  }

  public get currentUrl() {
    return this._currentUrl
  }

  public set currentUrl(value: string) {
    this._currentUrl = value
  }

  constructor() { }
  
}
