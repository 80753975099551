import { UserService } from './user.service';
import _ from 'lodash';
import { Injectable } from '@angular/core'
import { RoutePluginStepName } from '../../pages/campaign-page/plugin-configure/models/plugin-configure-navigation.model'
import { BrandingData, BrandingDomain, BrandingName, BrandingNameResponse, BrandingWord, PopupBrandingWordResponse } from '../../shared/models/branding-data.model'
import { EmailBrandingStyle, EmailBrandingStyleCode, EmailBrandingUniqueVariables, renderingBrandingPreviewHTML } from '../../pages/email-builder/services/logo.branding';
import { OneEncodedSvg } from '../../shared/svg/svg.encoder';

@Injectable()
export class BrandingService {

  constructor(private userService: UserService) {
  }

  public getUserBrandName(): string {
    const host = window.location.hostname
    const pathname = window.location.pathname
    if (host.includes('one.store')) {
      return pathname.includes(RoutePluginStepName.Objectives) ? BrandingNameResponse.onestore : BrandingNameResponse.one
    }
    if (host.includes('prooffactor')) {
      return BrandingNameResponse.prooffactor
    }
    if (host.includes('morpheusone')) {
      return BrandingNameResponse.morpheus
    }
    return pathname.includes(RoutePluginStepName.Objectives) ? BrandingNameResponse.onestore : BrandingNameResponse.one
  }

  public getBrandingData(): BrandingData {
    const locationOrigin = window.location.origin
    if (locationOrigin.includes('prooffactor')) {
      return {
        name: 'ONE',
        class: '_one',
        title: 'ONE | Social Proof',
        domain: BrandingDomain.prooffactor,
        logo: '/app_assets/one-logo-inverted.svg',
        canny: 'https://feedback.prooffactor.com',
        help: 'https://help.one.store',
        google_login: true,
      }
    } else if (locationOrigin.includes('morpheusone')) {
      return {
        name: 'Morpheus',
        class: '',
        title: 'Morpheus | Personalized Commerce',
        domain: BrandingDomain.morpheusone,
        logo: '/app_assets/m-logo.png',
        canny: 'https://feedback.prooffactor.com',
        help: 'https://help.one.store',
        google_login: true,
      }
    } else if (locationOrigin.includes('one')) {
      return {
        name: 'ONE',
        class: '_one',
        title: 'ONE | 18+ Apps in One',
        domain: BrandingDomain.one,
        logo: '/app_assets/one-logo-inverted.svg',
        canny: 'https://feedback.one.store',
        help: 'https://help.one.store',
        google_login: true,
      }
    }
    // Default
    return {
      name: 'ONE',
      class: '_one',
      title: 'ONE | Increase Your Conversion',
      domain: BrandingDomain.one,
      logo: '/app_assets/one-logo-inverted.svg',
      canny: 'https://feedback.one.store',
      help: 'https://help.one.store',
      google_login: true,
    }
  }


  getPopupBrandingWord(text: PopupBrandingWordResponse): BrandingWord {
    if (text === PopupBrandingWordResponse.poweredby) {
      return BrandingWord.poweredby
    }
    if (text === PopupBrandingWordResponse.createdwith) {
      return BrandingWord.createdwith
    }
    if (text === PopupBrandingWordResponse.madewith) {
      return BrandingWord.madewith
    }
    if (text === PopupBrandingWordResponse.verifiedby) {
      return BrandingWord.verifiedby
    }
  }

  getEmailBrandingLogoConfig(): {word: string; link: string; textColor: string; backgroundColor: string} {
    const name = _.get(this.userService.userInfo, 'email_branding_logo_settings.name', this.getUserBrandName())
    const word = BrandingName[name]
    const style = _.get(this.userService.userInfo, 'email_branding_logo_settings.style', EmailBrandingStyle.Black) as EmailBrandingStyle
    // we need to use encoded svg here because unlayer doesn't recognize the assets path
    const link = (style === EmailBrandingStyle.LightGray) ? OneEncodedSvg.black : OneEncodedSvg.white
    const textColor = (style === EmailBrandingStyle.Black) ? EmailBrandingStyleCode.black_color : (style === EmailBrandingStyle.Blue) ? EmailBrandingStyleCode.blue_color : (style === EmailBrandingStyle.Gray) ? EmailBrandingStyleCode.gray_color : EmailBrandingStyleCode.ligtgray_color
    const backgroundColor = (style === EmailBrandingStyle.Black) ? EmailBrandingStyleCode.black_bg : (style === EmailBrandingStyle.Blue) ? EmailBrandingStyleCode.blue_bg : (style === EmailBrandingStyle.Gray) ? EmailBrandingStyleCode.gray_bg : EmailBrandingStyleCode.ligtgray_bg
    return { word, link, textColor, backgroundColor }
  }

  setEmailBrandingLogoBody(body) {
    if (body?.rows && body?.rows.length > 0) {
      if (this.userService.brandingEmailIsPremium) {
        if (body.rows.find(row => row.id === EmailBrandingUniqueVariables.UniqueBlockId)) {
          body.rows = body.rows.filter(row => row.id !== EmailBrandingUniqueVariables.UniqueBlockId)
        }
      } else {
        if (!body.rows.find(row => row.id === EmailBrandingUniqueVariables.UniqueBlockId)) {
          const name = _.get(this.userService.userInfo, 'email_branding_logo_settings.name', this.getUserBrandName())
          const isONE = name === BrandingNameResponse.one || name === BrandingNameResponse.onestore
          body.rows.push(renderingBrandingPreviewHTML(isONE))
        } else {
          // remove and re-rennder the block
          body.rows = body.rows.filter(row => row.id !== EmailBrandingUniqueVariables.UniqueBlockId)
          const name = _.get(this.userService.userInfo, 'email_branding_logo_settings.name', this.getUserBrandName())
          const isONE = name === BrandingNameResponse.one || name === BrandingNameResponse.onestore
          body.rows.push(renderingBrandingPreviewHTML(isONE))
        }
      }
    }
    return body
  }
}
