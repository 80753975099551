<a *ngIf="isShopifyEmbedded && !isMobile && (isFullscreen$ | async) === false"
  href="#"
  class="Plans-fs-button _enter"
  (click)="enterFullScreen($event)">
  <one-icon name="expand-outline"></one-icon>&nbsp;
  Full Screen
</a>

<a *ngIf="isShopifyEmbedded && !isMobile && (isFullscreen$ | async) === true"
  class="Plans-fs-button _exit"
  href="#"
  (click)="exitFullScreen($event)">
  <one-icon class="Exit-Fullscreen-icon" src="/app_assets/icons/shopify-exit-fs.svg"></one-icon>&nbsp;
  <span> Back </span>
</a>

<ng-container *ngIf="subscriptionTriggered">
  <ng-container [ngSwitch]="planPageVersion">
    <ng-container *ngSwitchCase="plansPageVersionOptions.Old">
      <pf-plans-view-alt-2 pageTitle="Simple. Straightforward. Flexible Pricing." [noCurrentPlan]="true"></pf-plans-view-alt-2>
    </ng-container>
    <ng-container *ngSwitchCase="plansPageVersionOptions.New1">
      <pf-plans-view-new-1 pageTitle="All-in-One. Flexible. Pricing." [userType]="userType"></pf-plans-view-new-1>
    </ng-container>
    <ng-container *ngSwitchCase="plansPageVersionOptions.New2">
      <pf-plans-view-new-2 pageTitle="All-in-One. Flexible. Pricing." [userType]="userType"></pf-plans-view-new-2>
    </ng-container>
    <ng-container *ngSwitchCase="plansPageVersionOptions.New3">
      <pf-plans-view-new-3 pageTitle="All-in-One. Flexible. Pricing." [userType]="userType"></pf-plans-view-new-3>
    </ng-container>
    <ng-container *ngSwitchCase="plansPageVersionOptions.Version3View1">
      <pf-plans-version-3-view-1 pageTitle="All-in-One. Flexible. Pricing." [userType]="userType"></pf-plans-version-3-view-1>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <pf-plans-view-alt-2 pageTitle="Simple. Straightforward. Flexible Pricing." [userType]="userType" [noCurrentPlan]="userType === 'new'"></pf-plans-view-alt-2>
    </ng-container>
  </ng-container>
</ng-container>

<pf-log-out position="center"></pf-log-out>
