<div class="PluginConfig-Select" [class.disabled]="disabled">
<ng-select
           [items]="rewardSelectItems"
           class="CouponCode custom conveythis-no-translate"
           [ngClass]="customClass"
           [class._invalid]="isInvalid"
           [class._missing]="missingReward"
           [class._expired]="expiredCoupon"
           [class._opened]="openOverlay && searchable"
           [loading]="isLoading"
           [placeholder]="openOverlay && searchable ? 'Search Reward' : 'Select Reward'"
           [dropdownPosition]="dropdownPosition"
           notFoundText="No items found"
           [appendTo]="appendTo"
           (change)="selected($event)"
           (open)="onOpen()"
           (close)="onClose()"
           (clear)="onClear()"
           (search)="search($event)"
           [(ngModel)]="value"
           [clearable]="clearable && !expiredCoupon"
           [searchable]="searchable"
           [clearOnBackspace]="clearOnBackspace"
           [class.disabled]="disabled"
           bindLabel="title"
           bindValue="id">

  <!-- <ng-template ng-header-tmp></ng-template> -->
  <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
    <div class="CouponCode-Item" [class._expired]="item.disabled">
      <span
        class="CouponCode-Item-Badge mr-2"
        [class._Unique]="uniqueShopCouponsArray.includes(item.type)"
        [class._Generic]="masterShopCouponsArray.includes(item.type)"
        [class._Manual]="manualCoupons.includes(item.type)"
      >
        {{item.texts.type}}
      </span>

      <span
        *ngIf="item.texts.state"
        class="CouponCode-Item-Badge mr-2"
        [class._active]="item.texts.state === couponCodeState.active"
        [class._expired]="item.texts.state === couponCodeState.expired"
        [class._scheduled]="item.texts.state === couponCodeState.scheduled"
      >
        {{item.texts.state}}
      </span>

      <div class="CouponCode-Item-Title mt-1">
        <span>{{item.title}}</span>
        <small>{{item.texts.discount}}</small>
        <small *ngIf="item.texts.limit"> &bull; {{item.texts.limit}}</small>
      </div>

    </div>
  </ng-template>
  <ng-template ng-notfound-tmp *ngIf="!rewardSelectItems.length && !isTyping">
    <div class="no-coupon-template">
      <span class="no-coupon-template-text">No {{ rewardType === selectType.gift_card ? 'gift card': 'coupon' }} yet</span>
    </div>
  </ng-template>

  <ng-template ng-footer-tmp>

    <div *ngIf="rewardSelectItems.length || isTyping" class="border-top" style="border-right: 2px solid #dee2e6; border-left: 2px solid #dee2e6;">
      <mat-paginator
        class="pf-reward-select-paginator"
        showFirstLastButtons
        hidePageSize
        [pageIndex]="currentPage - 1"
        [pageSize]="itemsPerPage"
        [length]="itemsQuantity"
        (page)="onPageChanged($event)"
      ></mat-paginator>
    </div>

    <div *ngIf="rewardType === selectType.coupon_code" class="CouponCode-Footer" [class._double]="shopType === userShopType.ShopifyShop">
        <div class="pf-button outline blue _left" (click)="openCouponOverlay()">
          <one-icon name="add"></one-icon>{{ shopType === userShopType.ShopifyShop ? 'CREATE' : 'CREATE COUPON' }}
        </div>
        <div *ngIf="shopType === userShopType.ShopifyShop" class="pf-button outline blue _right" (click)="openCouponImport()">
          <one-icon name="cloud-upload-outline"></one-icon> IMPORT
        </div>
    </div>
    <div *ngIf="rewardType === selectType.gift_card" class="CouponCode-Footer">
        <div class="pf-button outline blue" (click)="openGiftCardOverlay()">
          <one-icon name="add"></one-icon>
          Create Gift Card
        </div>
    </div>
  </ng-template>
</ng-select>
  <div *ngIf="!openOverlay"
  class="PluginConfig-Select-Title _local-select-title"
  [class._missing]="missingReward"
  [class._expired]="expiredCoupon">
  Reward Code
  </div>
</div>
