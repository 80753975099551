const tr = {
  Quantity: 'Miktar',
  Size: 'Boyut',
  Color: 'Renk',
  Material: '',
  Style: 'Malzeme',
  Savings: 'Tasarruf',
  Subtotal: 'Ara toplam',
  Shipping: 'Nakliye',
  Taxes: 'Vergiler',
  Total: 'Toplam',
  Free: 'Özgür',
  Buy: 'Satın almak',
  'Pay Now': 'Şimdi öde',
  'Take the Deal': 'Anlaşmayı Kabul Et',
  'Add Now': 'Anlaşmayı Kabul Et',
  'You will be charged immediately': 'Hemen ücretlendirileceksiniz',
  'Decline Offer': 'Teklifi reddet',
  'No Thanks': 'Hayır teşekkürler',
  '{price}': '{Fiyat}',
  'Sold Out': 'Hepsi satıldı',
  'see more': 'daha fazla gör',
  less: 'az',
};
export {tr};
