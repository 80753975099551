<div class="Mat-DaterangePicker">
  <div class="Mat-DaterangePicker-Select">
    <select class="form-control" [(ngModel)]="selectValue" (change)="rangeSelectChange($event)">
      <option *ngFor="let btn of ranges" [value]="btn.title">{{btn.title}}</option>
    </select>
  </div>
  <div class="Mat-DaterangePicker-Buttons">
    <div *ngFor="let btn of ranges" 
      [class._active]="btn.active"
      class="Mat-DaterangePicker-Button" 
      (click)="rangeBtnClick(btn.range, btn)"
    >{{btn.title}}</div>
  </div>
  <div class="Mat-DaterangePicker-Calendars">
    <mat-calendar 
      #startCalendar
      [maxDate]="maxDate"
      [headerComponent]="customHeaderLeft"
      [selected]="selectedLocalRange$ | async"
      (selectedChange)="onSelectedChange($event)"
    ></mat-calendar>
    <mat-calendar х
      #endCalendar
      [maxDate]="maxDate"
      [headerComponent]="customHeaderRight"
      [selected]="selectedLocalRange$ | async"
      (selectedChange)="onSelectedChange($event)"
    ></mat-calendar>
    <div class="Mat-DaterangePicker-Controls">
      <label class="checkbox" [class.is-hidden]="includeTodayHidden">
        <input 
          type="checkbox"
          (change)="includeTodayChange($event)" 
          [checked]="includeTodayChecked"> <span>Include today</span>
      </label>
    </div>
    <div class="Mat-DaterangePicker-Footer">
      <button class="pf-button outline gray sm" (click)="cancelClick()">Cancel</button>
      <button class="pf-button filled blue sm ml-2" [disabled]="applyDisabled" (click)="applyRange()">Apply</button>
    </div>
  </div>
</div>
