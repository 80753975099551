export enum PluginConfigureRoutePluginNames {
  LiveVisitorCount = 'live-visitor-count',
  AggregateVisitorCount = 'aggregate-visitor-count',
  AggregateActivity = 'aggregate-activity',
  RecentActivity = 'recent-activity',
  FortuneWheel = 'fortune-wheel',
  CouponBox = 'coupon-box',
  NewsLetterNotification = 'subscribe-box',
  FreeShippingNotification = 'free-shipping',
  SalesPopup = 'sales',
  ProductPopup = 'product',
}

export enum PluginConfigureStepName {
  Appearance = 'design',
  Theme = 'theme',
  Slices = 'rewards',
  Text = 'text',
  Fields = 'fields',
  Design = 'design',
  Coupon = 'reward',
  Orders = 'orders',
  Triggers = 'triggers',
  MarketingEmails = 'marketing_emails',
  MarketingSMS = 'marketing_sms',
  Display = 'display',
  Advanced = 'advanced',
  Integrations = 'integrations',
  Subscribers = 'subscribers',
  Objectives = 'objectives',
  Products = 'products',
  Image = 'image',
}

export enum PhoneAndEmailRequiredOption {
  Either = 'either',
  Both = 'both',
  EmailOnly = 'email-only',
  PhoneOnly = 'phone-only',
}
export enum SubscriptionPeriodOptions {
  Anytime = 'anytime',
  OnDate = 'on_date',
  AfterDate = 'after_date',
  BeforeDate = 'before_date',
  BetweenDates = 'between_dates',
  LastDays = 'days_ago',
  LastHours = 'hours_ago',

}
export const PluginConfigureStepDisplayName = {
  [PluginConfigureStepName.MarketingEmails]: 'Emails',
  [PluginConfigureStepName.MarketingSMS]: 'SMS',
  [PluginConfigureStepName.Fields]: 'Fields',
  [PluginConfigureStepName.Triggers]: 'Triggers',
}

/* I made these all consts so our app is not dynamically allocating arrays and strings all the time */
export const RecentActivitySteps = [
  PluginConfigureStepName.Text,
  PluginConfigureStepName.Image,
  PluginConfigureStepName.Display,
  PluginConfigureStepName.Objectives,
]

export const RecentActivityShopSteps = [
  PluginConfigureStepName.Orders,
  PluginConfigureStepName.Text,
  PluginConfigureStepName.Display,
  PluginConfigureStepName.Products,
  PluginConfigureStepName.Objectives,
]

export const LiveVisitorCountSteps = [
  PluginConfigureStepName.Text,
  PluginConfigureStepName.Display,
  PluginConfigureStepName.Image,
  PluginConfigureStepName.Objectives,
]

export const AggregateActivitySteps = [
  PluginConfigureStepName.Text,
  PluginConfigureStepName.Display,
  PluginConfigureStepName.Image,
  PluginConfigureStepName.Objectives,
]

export const AggregateActivityShopSteps = [
  PluginConfigureStepName.Products,
  PluginConfigureStepName.Text,
  PluginConfigureStepName.Display,
  PluginConfigureStepName.Objectives,
]

export const AggregateVisitorCountSteps = [
  PluginConfigureStepName.Text,
  PluginConfigureStepName.Display,
  PluginConfigureStepName.Image,
  PluginConfigureStepName.Objectives,
]

export const FortuneWheelSteps = [
  PluginConfigureStepName.Theme,
  PluginConfigureStepName.Fields,
  PluginConfigureStepName.Slices,
  PluginConfigureStepName.Appearance,
  PluginConfigureStepName.Triggers,
  PluginConfigureStepName.MarketingEmails,
  PluginConfigureStepName.MarketingSMS,
  PluginConfigureStepName.Integrations,
  PluginConfigureStepName.Advanced,
]

export const CouponBoxSteps = [
  PluginConfigureStepName.Theme,
  PluginConfigureStepName.Fields,
  PluginConfigureStepName.Design,
  PluginConfigureStepName.Coupon,
  PluginConfigureStepName.Triggers,
  PluginConfigureStepName.MarketingEmails,
  PluginConfigureStepName.MarketingSMS,
  PluginConfigureStepName.Integrations,
  PluginConfigureStepName.Advanced,
]

export const NewsLetterNotificationSteps = [
  PluginConfigureStepName.Theme,
  PluginConfigureStepName.Fields,
  PluginConfigureStepName.Design,
  PluginConfigureStepName.Triggers,
  PluginConfigureStepName.MarketingEmails,
  PluginConfigureStepName.MarketingSMS,
  PluginConfigureStepName.Integrations,
  PluginConfigureStepName.Advanced,
]

export const PluginConfigureSteps = {
  [PluginConfigureRoutePluginNames.RecentActivity]: RecentActivitySteps,
  [PluginConfigureRoutePluginNames.LiveVisitorCount]: LiveVisitorCountSteps,
  [PluginConfigureRoutePluginNames.AggregateVisitorCount]: AggregateVisitorCountSteps,
  [PluginConfigureRoutePluginNames.AggregateActivity]: AggregateActivitySteps,
  [PluginConfigureRoutePluginNames.FortuneWheel]: FortuneWheelSteps,
  [PluginConfigureRoutePluginNames.CouponBox]: CouponBoxSteps,
  [PluginConfigureRoutePluginNames.NewsLetterNotification]: NewsLetterNotificationSteps,
}

export const ShopPluginConfigureSteps = {
  [PluginConfigureRoutePluginNames.RecentActivity]: RecentActivityShopSteps,
  [PluginConfigureRoutePluginNames.LiveVisitorCount]: LiveVisitorCountSteps,
  [PluginConfigureRoutePluginNames.AggregateVisitorCount]: AggregateVisitorCountSteps,
  [PluginConfigureRoutePluginNames.AggregateActivity]: AggregateActivityShopSteps,
  [PluginConfigureRoutePluginNames.FortuneWheel]: FortuneWheelSteps,
  [PluginConfigureRoutePluginNames.CouponBox]: CouponBoxSteps,
  [PluginConfigureRoutePluginNames.NewsLetterNotification]: NewsLetterNotificationSteps,
}
