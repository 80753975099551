import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'
import { CustomSnackbarComponent } from './custom-snackbar.component'
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarRef, MatSnackBarVerticalPosition } from '@angular/material/snack-bar'

export enum SnackbarType {
  Success = 'success',
  Error = 'error',
  Warning = 'warning',
  Info = 'info'
}

@Injectable()
export class CustomSnackbarService {
  public notification$: Subject<string> = new Subject()
  public snackBarRef: MatSnackBarRef<CustomSnackbarComponent>

  constructor(
    private snackBar: MatSnackBar
  ) {
  }

  public showSnackbar({title = '', text = '', html = '', type = SnackbarType.Success, persist_forever = false, duration = 3000, persist = false, v = 'top', h = 'start', crisp = {}, button = {}}) {
    const snackData = {
      data: { title, text, html, type, duration, persist, persist_forever, crisp, button },
      panelClass: ['pf-snackbar', type],
      verticalPosition: v as MatSnackBarVerticalPosition,
      horizontalPosition: h as MatSnackBarHorizontalPosition,
      duration,
    }
    // if a snackbar is active and is set to presist let it run out before showing the new one, but cap it at 2 sec
    // if persist_forever is true, don't show the new snackbar
    if (this.snackBarRef?.instance?.data?.persist) {
      let duration = this.snackBarRef?.instance?.remainingDuration || 2000
      duration = duration < 2000 ? duration : 2000
      if (this.snackBarRef?.instance?.data?.persist_forever) {
        return
      }
      setTimeout(() => {
        this.snackBarRef = this.snackBar.openFromComponent(CustomSnackbarComponent, snackData)
      }, duration)
    } else {
      this.snackBarRef = this.snackBar.openFromComponent(CustomSnackbarComponent, snackData)
    }
    this.snackBarRef.afterDismissed().subscribe(() => {
      this.snackBarRef = null
    })
  }

  public showSuccess({title = 'Success', text = '', html = '', duration = 3000, persist = false, persist_forever = false}, {crisp = false, show = null, send = null, type = 'button'} = {}, { button = false, button_type = 'button', button_text = '', cb = null} = {}) {
    this.showSnackbar({title, text, html, duration, persist, persist_forever, type: SnackbarType.Success, crisp: {enabled: crisp, show, send, type}, button: {enabled: button, type: button_type, text: button_text, cb}})
  }

  public showError({title = 'Error', text = '', html = '', duration = 0, persist = false, persist_forever = false}, {crisp = false, show = null, send = null, type = 'button'} = {}, { button = false, button_type = 'button', button_text = '', cb = null} = {}) {
    this.showSnackbar({title, text, html, duration, persist, persist_forever, type: SnackbarType.Error, crisp: {enabled: crisp, show, send, type}, button: {enabled: button, type: button_type, text: button_text, cb}})
  }

  public showWarning({title = 'Warning', text = '', html = '', duration = 5000, persist = false, persist_forever = false}, {crisp = false, show = null, send = null, type = 'button'} = {}, { button = false, button_type = 'button', button_text = '', cb = null} = {}) {
    this.showSnackbar({title, text, html, duration, persist, persist_forever, type: SnackbarType.Warning, crisp: {enabled: crisp, show, send, type}, button: {enabled: button, type: button_type, text: button_text, cb}})
  }

  public showInfo({title = 'Note', text = '', html = '', duration = 5000, persist = false, persist_forever = false}, {crisp = false, show = null, send = null, type = 'button'} = {}, { button = false, button_type = 'button', button_text = '', cb = null} = {}) {
    this.showSnackbar({title, text, html, duration, persist, persist_forever, type: SnackbarType.Info, crisp: {enabled: crisp, show, send, type}, button: {enabled: button, type: button_type, text: button_text, cb}})
  }

  public dismiss() {
    this.snackBar.dismiss()
  }
}
