export function combineSocialOptions(_icons) {
  const icons = _icons ? {icons: _icons} : {};
  return {
    properties: {
      icons: {
        value: {
          ...icons,
          editor: {
            data: {
              showDefaultIcons: false,
              customIcons: [
                {
                  name: 'Twitter',
                  url: 'https://twitter.com/',
                  icons: {
                    'circle': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/ms38om.png',
                    'circle-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/iuz7xh.png',
                    'circle-white': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/lfdrx.png',
                    'rounded': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/nnxd8.png',
                    'rounded-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/z5y9h9.png',
                    'squared': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/cf0cu.png',
                    'squared-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/qj9pyd.png',
                  }
                },
                {
                  name: 'X',
                  url: 'https://x.com/',
                  icons: {
                    'circle': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/3dbd53b4-bcd8-475d-a297-b380475d9e7c/coupon_box_notification/7mibmm.png',
                    'circle-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/3dbd53b4-bcd8-475d-a297-b380475d9e7c/coupon_box_notification/7mibmm.png',
                    'circle-white': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/3dbd53b4-bcd8-475d-a297-b380475d9e7c/coupon_box_notification/p9k5td.png',
                    'rounded': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/3dbd53b4-bcd8-475d-a297-b380475d9e7c/coupon_box_notification/ypdng8.png',
                    'rounded-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/3dbd53b4-bcd8-475d-a297-b380475d9e7c/coupon_box_notification/ypdng8.png',
                    'squared': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/3dbd53b4-bcd8-475d-a297-b380475d9e7c/coupon_box_notification/5townk.png',
                    'squared-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/3dbd53b4-bcd8-475d-a297-b380475d9e7c/coupon_box_notification/5townk.png',
                  }
                },
                {
                  name: 'LinkedIn',
                  url: 'https://linkedin.com/',
                  icons: {
                    'circle': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/st70ht.png',
                    'circle-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/3g867w.png',
                    'circle-white': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/3akqk8.png',
                    'rounded': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/f4aq2kt.png',
                    'rounded-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/d90gom.png',
                    'squared': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/inierr.png',
                    'squared-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/tvmpz.png',
                  }
                },
                {
                  name: 'Facebook',
                  url: 'https://facebook.com/',
                  icons: {
                    'circle': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/5z08k5.png',
                    'circle-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/2h9ll6.png',
                    'circle-white': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/f7n5ar.png',
                    'rounded': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/0gjps.png',
                    'rounded-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/lzp87d.png',
                    'squared': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/bf2ez.png',
                    'squared-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/5n6kzf.png',
                  }
                },
                {
                  name: 'Instagram',
                  url: 'https://instagram.com/',
                  icons: {
                    'circle': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/5dcswh.png',
                    'circle-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/idhzvp.png',
                    'circle-white': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/om0dob.png',
                    'rounded': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/brxofg.png',
                    'rounded-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/8eg5nj.png',
                    'squared': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/ook5q.png',
                    'squared-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/04i07.png',
                  }
                },
                {
                  name: 'Pinterest',
                  url: 'https://pinterest.com/',
                  icons: {
                    'circle': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/ohtigg.png',
                    'circle-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/i4oann.png',
                    'circle-white': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/npzn2k.png',
                    'rounded': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/v7ixd5.png',
                    'rounded-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/q66zh7.png',
                    'squared': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/h611dr.png',
                    'squared-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/xok2e9.png',
                  }
                },
                {
                  name: 'Vimeo',
                  url: 'https://vimeo.com/',
                  icons: {
                    'circle': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/r8xmv7.png',
                    'circle-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/bdsum.png',
                    'circle-white': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/ax3hg.png',
                    'rounded': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/vc23q.png',
                    'rounded-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/76hqlt.png',
                    'squared': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/fng7ps.png',
                    'squared-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/la0f.png',
                  }
                },
                {
                  name: 'YouTube',
                  url: 'https://youtube.com/',
                  icons: {
                    'circle': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/m0l14.png',
                    'circle-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/b6az7b.png',
                    'circle-white': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/7vloqw7.png',
                    'rounded': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/5wtmmq.png',
                    'rounded-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/dvswr6.png',
                    'squared': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/a3fjnm.png',
                    'squared-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/5b7par.png',
                  }
                },
                {
                  name: 'Snapchat',
                  url: 'https://snapchat.com/',
                  icons: {
                    'circle': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/huqeja.png',
                    'circle-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/dr3gsd.png',
                    'circle-white': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/fc7xq.png',
                    'rounded': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/q5gq9f.png',
                    'rounded-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/vknwlp.png',
                    'squared': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/yj84ps.png',
                    'squared-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/0b8c5j.png',
                  }
                },
                {
                  name: 'WhatsApp',
                  url: 'https://whatsapp.com/',
                  icons: {
                    'circle': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/7dvg78.png',
                    'circle-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/e76f1j.png',
                    'circle-white': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/3uw7h7.png',
                    'rounded': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/z6qh9r.png',
                    'rounded-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/lle8nq.png',
                    'squared': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/83z7am.png',
                    'squared-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/qhmupt.png',
                  }
                },
                {
                  name: 'Reddit',
                  url: 'https://reddit.com/',
                  icons: {
                    'circle': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/vu8c.png',
                    'circle-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/0u4u19.png',
                    'circle-white': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/4iw2pa.png',
                    'rounded': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/d2i8i3.png',
                    'rounded-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/2tfzk.png',
                    'squared': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/9dpzfg.png',
                    'squared-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/h55fjd.png',
                  }
                },
                {
                  name: 'Messenger',
                  url: 'https://messenger.com/',
                  icons: {
                    'circle': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/clnwne.png',
                    'circle-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/kfaz0k.png',
                    'circle-white': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/rxb63.png',
                    'rounded': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/7hacga.png',
                    'rounded-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/2ieen6.png',
                    'squared': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/sz5nyl.png',
                    'squared-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/6tk35.png',
                  }
                },
                {
                  name: 'Tripadvisor',
                  url: 'https://tripadvisor.com/',
                  icons: {
                    'circle': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/hp0uefs.png',
                    'circle-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/2v3b3p.png',
                    'circle-white': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/tc17j.png',
                    'rounded': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/29enyn.png',
                    'rounded-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/iwk5a.png',
                    'squared': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/p7n0jj.png',
                    'squared-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/5uz2i.png',
                  }
                },
                {
                  name: 'Meetup',
                  url: 'https://meetup.com/',
                  icons: {
                    'circle': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/rporkp.png',
                    'circle-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/a7w3fu.png',
                    'circle-white': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/84zy4.png',
                    'rounded': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/gc6mp8.png',
                    'rounded-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/a6mra8.png',
                    'squared': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/kkasb.png',
                    'squared-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/1grem.png',
                  }
                },
                {
                  name: 'ProductHunt',
                  url: 'https://producthunt.com/',
                  icons: {
                    'circle': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/50uo9.png',
                    'circle-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/c32nu7.png',
                    'circle-white': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/x9r2j8.png',
                    'rounded': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/mhxsgs.png',
                    'rounded-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/gfw4ll.png',
                    'squared': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/bqn75h.png',
                    'squared-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/5r3d7.png',
                  }
                },
                {
                  name: 'RSS',
                  url: 'https://rss.com/',
                  icons: {
                    'circle': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/1mr9fh.png',
                    'circle-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/1oiqqk.png',
                    'circle-white': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/ym7dun.png',
                    'rounded': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/qffb8b.png',
                    'rounded-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/l43okr.png',
                    'squared': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/4z1ysc.png',
                    'squared-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/8yink.png',
                  }
                },
                {
                  name: 'Tinder',
                  url: 'https://tinder.com/',
                  icons: {
                    'circle': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/x73g8.png',
                    'circle-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/hno8g.png',
                    'circle-white': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/z2qknd.png',
                    'rounded': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/9xwjsp.png',
                    'rounded-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/jltf5g.png',
                    'squared': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/buzx8.png',
                    'squared-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/b893n.png',
                  }
                },
                {
                  name: 'Tumblr',
                  url: 'https://tumblr.com/',
                  icons: {
                    'circle': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/qdw97a.png',
                    'circle-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/iqgyb.png',
                    'circle-white': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/7jr9xv.png',
                    'rounded': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/u8xkzmb.png',
                    'rounded-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/iuf2q.png',
                    'squared': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/jkwnwm.png',
                    'squared-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/3i4yle.png',
                  }
                },
                {
                  name: 'AppleMusic',
                  url: 'https://apple.com/',
                  icons: {
                    'circle': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/7chx0q.png',
                    'circle-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/fm7tq5.png',
                    'circle-white': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/3efvl.png',
                    'rounded': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/4ba2c.png',
                    'rounded-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/uv2lp9.png',
                    'squared': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/11u1r.png',
                    'squared-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/q8i5s.png',
                  }
                },
                {
                  name: 'Spotify',
                  url: 'https://spotify.com/',
                  icons: {
                    'circle': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/6i11r.png',
                    'circle-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/zlv6fq.png',
                    'circle-white': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/7i6h8l.png',
                    'rounded': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/fz2w44.png',
                    'rounded-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/v5ooxb.png',
                    'squared': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/8h1fkm.png',
                    'squared-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/te7pe.png',
                  }
                },
                {
                  name: 'SoundCloud',
                  url: 'https://soundcloud.com/',
                  icons: {
                    'circle': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/1lj548.png',
                    'circle-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/ebnq2j.png',
                    'circle-white': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/4vodzd.png',
                    'rounded': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/0plqi8.png',
                    'rounded-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/1salrm.png',
                    'squared': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/y55u2.png',
                    'squared-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/vw0pxk.png',
                  }
                },
                {
                  name: 'Yelp',
                  url: 'https://yelp.com/',
                  icons: {
                    'circle': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/9ba03.png',
                    'circle-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/0fafe8.png',
                    'circle-white': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/enaekl.png',
                    'rounded': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/2o1m9.png',
                    'rounded-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/f6nvzh.png',
                    'squared': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/nbtn5v.png',
                    'squared-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/113oh.png',
                  }
                },
                {
                  name: 'Medium',
                  url: 'https://medium.com/',
                  icons: {
                    'circle': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/o9cdmb.png',
                    'circle-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/lsreu.png',
                    'circle-white': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/8j9nhf.png',
                    'rounded': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/jderk.png',
                    'rounded-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/4qhtla.png',
                    'squared': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/d86v3d.png',
                    'squared-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/las9hd.png',
                  }
                },
                {
                  name: 'Skype',
                  url: 'https://skype.com/',
                  icons: {
                    'circle': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/1ju3ag.png',
                    'circle-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/po2ari.png',
                    'circle-white': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/n54n4p.png',
                    'rounded': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/xhk3i2.png',
                    'rounded-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/f9tnv6.png',
                    'squared': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/zwoqon.png',
                    'squared-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/wlh0wu.png',
                  }
                },
                {
                  name: 'Flickr',
                  url: 'https://flickr.com/',
                  icons: {
                    'circle': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/0452.png',
                    'circle-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/u2rsp.png',
                    'circle-white': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/oxhxy.png',
                    'rounded': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/jvq5fg.png',
                    'rounded-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/gjxwjw.png',
                    'squared': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/pza5wv.png',
                    'squared-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/rgf96s.png',
                  }
                },
                {
                  name: 'GitHub',
                  url: 'https://github.com/',
                  icons: {
                    'circle': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/9hlunc.png',
                    'circle-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/lwf7qh.png',
                    'circle-white': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/u30805.png',
                    'rounded': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/ohltmi.png',
                    'rounded-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/ej2g7h.png',
                    'squared': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/nercee.png',
                    'squared-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/wqqfpb.png',
                  }
                },
                {
                  name: 'GooglePlus',
                  url: 'https://google.com/',
                  icons: {
                    'circle': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/6k0cpdr.png',
                    'circle-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/vflcwv.png',
                    'circle-white': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/nli90x.png',
                    'rounded': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/au07l8.png',
                    'rounded-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/gwvbz.png',
                    'squared': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/kmtum5.png',
                    'squared-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/z21dnw.png',
                  }
                },
                {
                  name: 'Discord',
                  url: 'https://discordapp.com/',
                  icons: {
                    'circle': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/60gqcl.png',
                    'circle-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/x7wrph.png',
                    'circle-white': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/br7sd4.png',
                    'rounded': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/y49qou.png',
                    'rounded-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/gsboxi.png',
                    'squared': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/rvlca7.png',
                    'squared-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/ifggfp.png',
                  }
                },
                {
                  name: 'Telegram',
                  url: 'https://telegram.org/',
                  icons: {
                    'circle': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/1y7noo.png',
                    'circle-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/kk9s1.png',
                    'circle-white': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/avs44.png',
                    'rounded': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/iu9swv.png',
                    'rounded-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/b2vfzj.png',
                    'squared': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/pzr7cq.png',
                    'squared-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/yb6ven.png',
                  }
                },
                {
                  name: 'TikTok',
                  url: 'https://tiktok.com/',
                  icons: {
                    'circle': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/vyqgsfp.png',
                    'circle-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/f9gllc.png',
                    'circle-white': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/7hqj5e.png',
                    'rounded': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/7zcc1.png',
                    'rounded-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/xyuzbw.png',
                    'squared': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/afike9.png',
                    'squared-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/dk2wiv.png',
                  }
                },
                {
                  name: 'Email',
                  url: 'https://email.com/',
                  icons: {
                    'circle': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/9pci3f.png',
                    'circle-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/9pci3f.png',
                    'circle-white': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/9tueag.png',
                    'rounded': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/6lfe2.png',
                    'rounded-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/6lfe2.png',
                    'squared': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/thj5hzj.png',
                    'squared-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/06f74dab-12ef-4aac-b7b9-aada958c6ecd/fortune_wheel_notification/thj5hzj.png',
                  }
                },
                {
                  name: 'Twitch',
                  url: 'https://twitch.tv/',
                  icons: {
                    'circle': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/275c3da5-5071-4721-b0e8-0810cf191db5/fortune_wheel_notification/f2n1z.png',
                    'circle-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/275c3da5-5071-4721-b0e8-0810cf191db5/fortune_wheel_notification/vzds8.png',
                    'circle-white': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/275c3da5-5071-4721-b0e8-0810cf191db5/fortune_wheel_notification/whkjso.png',
                    'rounded': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/275c3da5-5071-4721-b0e8-0810cf191db5/fortune_wheel_notification/s2lj2.png',
                    'rounded-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/275c3da5-5071-4721-b0e8-0810cf191db5/fortune_wheel_notification/ekrit2.png',
                    'squared': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/275c3da5-5071-4721-b0e8-0810cf191db5/fortune_wheel_notification/gvqdlt.png',
                    'squared-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/275c3da5-5071-4721-b0e8-0810cf191db5/fortune_wheel_notification/7vxws8.png',
                  }
                },
                {
                  name: 'Threads',
                  url: 'https://threads.net/',
                  icons: {
                    'circle': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/3dbd53b4-bcd8-475d-a297-b380475d9e7c/coupon_box_notification/sqvrfm.png',
                    'circle-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/3dbd53b4-bcd8-475d-a297-b380475d9e7c/coupon_box_notification/sqvrfm.png',
                    'circle-white': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/3dbd53b4-bcd8-475d-a297-b380475d9e7c/coupon_box_notification/9jb2zd.png',
                    'rounded': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/3dbd53b4-bcd8-475d-a297-b380475d9e7c/coupon_box_notification/9zhml.png',
                    'rounded-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/3dbd53b4-bcd8-475d-a297-b380475d9e7c/coupon_box_notification/9zhml.png',
                    'squared': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/3dbd53b4-bcd8-475d-a297-b380475d9e7c/coupon_box_notification/pt26x.png',
                    'squared-black': 'https://cdn.prooffactor.com/shopify:prod-nik.myshopify.com/campaigns/3dbd53b4-bcd8-475d-a297-b380475d9e7c/coupon_box_notification/pt26x.png',
                  }
                },
              ]
            }
          }
        }
      }
    }
  }
}

export const templateDataCustomIcons = combineSocialOptions()['properties']['icons']['value']['editor']['data']['customIcons']
