const he = {
  Quantity: 'כמות',
  Size: 'מידה',
  Color: 'צבע',
  Material: 'חומר',
  Style: 'סטייל',
  Savings: 'חיסכון של',
  Subtotal: 'סך הכול',
  Shipping: 'משלוח',
  Taxes: 'מיסים',
  Total: 'סך הכול',
  Free: 'חינם',
  Buy: 'לקנות',
  'Pay Now': 'שלם עכשיו',
  'Take the Deal': 'לקחת את העסקה',
  'Add Now': 'הוסף עכשיו',
  'You will be charged immediately': 'תחוייב במיידית',
  'Decline Offer': 'וותר על ההצעה',
  'No Thanks': 'לא תודה',
  '{price}': '{מחיר}',
  'Sold Out': 'נמכר הכל',
  'see more': 'ראה עוד',
  less: 'פחות',
};
export {he};
