import { Injectable } from '@angular/core'
import { ApiService } from './api/api.service'
import {
  CreateIntegrationRequestParams,
  CreateMultipleSystemParams,
} from '../../store/mail-integration/mail-integration.actions'
import { Observable, Subject, zip } from 'rxjs'
import { map } from 'rxjs/operators'
import { SMS_INTEGRATIONS_TYPE_LIST } from '../../pages/integrations/helpers/sms-integration-types-list'
import { CreateIntegrationPayload, Integration, IntegrationLabel, IntegrationType } from '../../shared/models/mail-integration.model'

export enum TypeOfIntegrationEntity {
  Email = 'email',
  Sms = 'sms'
}

@Injectable()
export class IntegrationsService {
  routePath = {
    [TypeOfIntegrationEntity.Email]: 'mail_systems',
    [TypeOfIntegrationEntity.Sms]: 'sms_systems',
  }
  private integrationsSubj$ = new Subject<IntegrationType>()
  private integrationsDropdownVisibleSubj$ = new Subject<boolean>()

  constructor(private apiService: ApiService) {
  }

  transferIntegration(type: IntegrationType): IntegrationLabel | IntegrationType {
    if (type === IntegrationType.Sendinblue) {
      return IntegrationLabel.Brevo
    }
    if (type === IntegrationType.BrontoModel) {
      return IntegrationLabel.Bronto
    }
    if (type === IntegrationType.CMCommerce) {
      return IntegrationLabel.CampaignMonitor
    }
    return type
  }

  setIntegrationDropdownVisibility(visible: boolean) {
    this.integrationsDropdownVisibleSubj$.next(visible)
  }

  get integrationsDropdownVisible$(): Observable<boolean> {
    return this.integrationsDropdownVisibleSubj$.asObservable()
  }

  addIntegration(integration: IntegrationType) {
    this.integrationsSubj$.next(integration)
  }

  get onAddIntegration(): Observable<IntegrationType> {
    return this.integrationsSubj$.asObservable()
  }

  /**
   * Combining results both from email and sms
   */
  getAllIntegrations(): Observable<any> {
    return zip(this.getAllEmailIntegrations(), this.getAllSmsIntegrations()).pipe(map(x => x[0].concat(x[1])))
  }

  /**
   * Depend on type of integration creating it
   * @param integration
   */
  createIntegration(integration: CreateIntegrationPayload | Integration) {
    const typeOfIntegrationEntity = SMS_INTEGRATIONS_TYPE_LIST.includes(integration.type) ?
      TypeOfIntegrationEntity.Sms :
      TypeOfIntegrationEntity.Email;
    return this.apiService.post(`/v1/${this.routePath[typeOfIntegrationEntity]}`, integration);
  }

  /**
   * Depend on typeOfIntegrationEntity we have different route path
   * @param id
   * @param typeOfIntegrationEntity
   */
  removeIntegration(id: string, typeOfIntegrationEntity: TypeOfIntegrationEntity) {
    return this.apiService.delete(`/v1/${this.routePath[typeOfIntegrationEntity]}/${id}`)
  }

  /** TODO check if not brakes anything
   * SMS now supports lists
   * @param integration
   * @param account_id
   */
  getIntegrationLists(integration: Integration, account_id: string = null) {
    const typeOfIntegrationEntity = SMS_INTEGRATIONS_TYPE_LIST.includes(integration.type) ?
      TypeOfIntegrationEntity.Sms :
      TypeOfIntegrationEntity.Email;
    return this.apiService.get(`/v1/${this.routePath[typeOfIntegrationEntity]}/${integration.id}/lists?account_id=${account_id}`)
  }

  /** TODO check if not brakes anything
   * Keeping as backup
   * @param integrationId
   * @param account_id
   */
  getIntegrationListsById(integrationId: string, account_id: string = null) {
    return this.apiService.get(`/v1/mail_systems/${integrationId}/lists?account_id=${account_id}`)
  }

  /** TODO check if not brakes anything
   * SMS do not have accounts
   * @param integrationId
   */
  getIntegrationAccounts(integrationId: string) {
    return this.apiService.get(`/v1/mail_systems/${integrationId}/accounts`)
  }

  /**
   * Depend on typeOfIntegrationEntity we have different route path
   * @param data
   * @param typeOfIntegrationEntity
   */
  addIntegrationToCampaign(data: CreateIntegrationRequestParams, typeOfIntegrationEntity: TypeOfIntegrationEntity) {
    return this.apiService.post(`/v1/campaigns/${data.id}/fortune_wheel_notification/${this.routePath[typeOfIntegrationEntity]}/add_integration`, data)
  }

  /**
   * Depend on typeOfIntegrationEntity we have different route path
   * @param id
   * @param integrations
   * @param typeOfIntegrationEntity
   */
  addIntegrationsToCampaign(id, integrations: CreateMultipleSystemParams[], typeOfIntegrationEntity: TypeOfIntegrationEntity) {
    return this.apiService.post(`/v1/campaigns/${id}/${this.routePath[typeOfIntegrationEntity]}/add_integrations`, { integrations })
  }

  /**
   * Depend on mail_system_id or sms_system_id we have different route path and params
   * @param mail_system_id
   * @param sms_system_id
   * @param id
   * @param typeOfIntegrationEntity
   */
  removeLinkedIntegration({ mail_system_id, sms_system_id, id, typeOfIntegrationEntity }) {
    const params: {mail_system_integration_id: string} | {sms_system_integration_id: string} | {} = {};
    if (mail_system_id) {
      (params as {mail_system_integration_id: string}).mail_system_integration_id = mail_system_id;
    }  else if (sms_system_id) {
      (params as {sms_system_integration_id: string}).sms_system_integration_id = sms_system_id;
    }
    return this.apiService.delete(
      `/v1/campaigns/${id}/fortune_wheel_notification/${this.routePath[typeOfIntegrationEntity]}/remove_integration`,
        params
      )
  }

  /**
   * Depend on typeOfIntegrationEntity we have different route path
   * @param data
   * @param typeOfIntegrationEntity
   */
  addIntegrationToCampaignCouponBox(data: CreateIntegrationRequestParams, typeOfIntegrationEntity: TypeOfIntegrationEntity) {
    return this.apiService.post(`/v1/campaigns/${data.id}/coupon_box_notification/${this.routePath[typeOfIntegrationEntity]}/add_integration`, data)
  }

  /**
   * Depend on mail_system_id or sms_system_id we have different route path and params
   * @param data
   * @param typeOfIntegrationEntity
   */
  addIntegrationToCampaignNewsletter(data: CreateIntegrationRequestParams, typeOfIntegrationEntity: TypeOfIntegrationEntity) {
    return this.apiService.post(`/v1/campaigns/${data.id}/newsletter_notification/${this.routePath[typeOfIntegrationEntity]}/add_integration`, data)
  }

  /**
   * Depend on mail_system_id or sms_system_id we have different route path and params
   * @param mail_system_id
   * @param sms_system_id
   * @param id
   * @param typeOfIntegrationEntity
   */
  removeLinkedIntegrationCouponBox({ mail_system_id, sms_system_id, id, typeOfIntegrationEntity }) {
    const params: {mail_system_integration_id: string} | {sms_system_integration_id: string} | {} = {};
    if (mail_system_id) {
      (params as {mail_system_integration_id: string}).mail_system_integration_id = mail_system_id;
    }  else if (sms_system_id) {
      (params as {sms_system_integration_id: string}).sms_system_integration_id = sms_system_id;
    }
    return this.apiService.delete(
      `/v1/campaigns/${id}/coupon_box_notification/${this.routePath[typeOfIntegrationEntity]}/remove_integration`,
      params
    )
  }

  /**
   * Depend on mail_system_id or sms_system_id we have different route path and params
   * @param mail_system_id
   * @param sms_system_id
   * @param id
   * @param typeOfIntegrationEntity
   */
  removeLinkedIntegrationNewsletter({ mail_system_id, sms_system_id, id, typeOfIntegrationEntity }) {
    const params: {mail_system_integration_id: string} | {sms_system_integration_id: string} | {} = {};
    if (mail_system_id) {
      (params as {mail_system_integration_id: string}).mail_system_integration_id = mail_system_id;
    }  else if (sms_system_id) {
      (params as {sms_system_integration_id: string}).sms_system_integration_id = sms_system_id;
    }
    return this.apiService.delete(
      `/v1/campaigns/${id}/newsletter_notification/${this.routePath[typeOfIntegrationEntity]}/remove_integration`,
      params
    )
  }

  getAllCampaigns() {
    return this.apiService.get('/v1/campaigns')
  }

  /**
   * EMAIL SERVICE METHODS
   */
  getAllEmailIntegrations() {
    return this.apiService.get('/v1/mail_systems')
  }

  /**
   * SMS SERVICE METHODS
   */
  getAllSmsIntegrations(): Observable<any[]> {
    return this.apiService.get('/v1/sms_systems')
  }
}
