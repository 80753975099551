import { CommonModule } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core'
import { CrispService } from '../../../../core/services/crisp.service';
import { MessageBoxComponent } from '../../message-box/message-box.component';
import { FeatureTypeModal } from '../vote-feature.modal/vote-feature.modal.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export enum ReceiveModalType {
  Theme = 'theme',
  Vote = 'vote',
  WaitingList = 'isWaitingList'
}
@Component({
    templateUrl: './receive-request.modal.component.html',
    styleUrls: ['./receive-request.modal.component.scss'],
    standalone: true,
    imports: [
      CommonModule,
      MessageBoxComponent,
    ]
})

export class ReceiveRequestModalComponent implements OnInit {
  public receiveType = ReceiveModalType
  waitingListText = `<div class="py-2"><b>Want to skip the line? </b><br>Refer a friend to ONE app. Once they have installed us, message our live chat support with their email or Store URL.</div>`
  skipWaitlist = false

  constructor(
    private crispService: CrispService,
    public dialogRef: MatDialogRef<ReceiveRequestModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { type: ReceiveModalType, url?: string, voteCount?: number, featureType: FeatureTypeModal}) {}

  ngOnInit(): void {
    this.skipWaitlist = this.data.type === ReceiveModalType.WaitingList && this.data.featureType === 'upsell_one_click_checkout'
    if (this.skipWaitlist) {
      this.openCrisp()
    }
  }

  navigateToCanny(url: string) {
    window.open(url, '_blank')
  }

  getFeatureName(type: FeatureTypeModal) {
    switch (type) {
      case 'upsell_one_click_checkout':
        return 'Upsell Page (One-Click Checkout)'
      default:
        return type
    }
  }

  openCrisp() {
    const crisp = this.crispService.getCrisp()
    if (crisp) {
      crisp.push(['do', 'chat:open'])
      crisp.push(['do', 'message:send', ['text', `Hello ONE Team,\n\n
                                                  I want to skip line and join ${this.getFeatureName(this.data?.featureType)} beta program.\n\n
                                                  What information can I share to be able to get early access?`]])    }
  }
}
