import { Route } from '@angular/router'
import { Campaign, CampaignPluginName } from '../../../models/campaign/campaign';
import { CouponBoxNotificationParams, NewsLetterNotificationParams } from '../../../models/campaign/coupon-box-notification.model';
import { FortuneWheelNotification } from '../../../models/campaign/fortune-wheel-notification';
import { StepperMenu } from '../../stepper/models/stepper-menu.model'
import { WidgetCouponBoxComponentParams } from '../../../models/widget/coupon-box.model'

// ID to see who is triggering the window.history.state
export const FLOW_ID = 'SMSOnboarding'

// Single source of step names. Change here if you need to edit URL routes
export enum SMSOnboardingStepName {
  welcome = 'welcome',
  keyword = 'keyword',
  try_it_out = 'try-it-out',
  setup_contact = 'setup-contact',
  tcpa = 'tcpa',
  org = 'organization',
  terms_of_service = 'terms-of-service',
  collect_numbers = 'collect-numbers',
  sms_automations = 'sms-automations',
}

export enum SecondaryDesignator {
  ste = 'STE',
  apt = 'APT',
  bldg = 'BLDG',
  unit = 'UNIT'
}
// Used to build a menu stepper and pass data between navigation
export interface SMSStepperStep extends StepperMenu {
  title: string
  validation: boolean
  url?: SMSOnboardingStepName
  index?: number
  flow_id?: string
  passed?: boolean
  clickable?: boolean
  disabled?: boolean
  flow?: SMSOnboardingFlow[SMSOnboardingStepName]
}

// Describes ROUTE_STEPS which are used to define steps and their order
export interface SMSOnboardingRouteStep extends Route {
  data?: SMSStepperStep
}

export interface SMSValidityResponse {
  number: string, valid: boolean
}

export interface SMSContactsForm {
  first_name?: string
  last_name?: string
  phone?: string
  email?: string
  agree_to_proof_factor_sms_tos?: boolean
  understand_sufficient_consent?: boolean
  understand_penalties?: boolean
  understand_liable_compliance?: boolean
  updated_business_sms_tos?: boolean
  completed?: boolean
  single_opt_in_terms_completed?: boolean
}

export interface SMSAccount {
  number: string
  valid: boolean
}

export interface OrganizationResponse {
  legal_name: string
  brand_name: string
  phone: string
  email: string
  mailing_address: {
    house_number: string
    street_name: string
    street_suffix?: null,
    country: {
      id: number
      name: string
      code: string
    },
    city: string,
    state: {
      id: number
      name: string
      code: string
    },
    secondary_designator: SecondaryDesignator,
    secondary_number: string,
    zip_code: string
  }
}

export interface OrganizationPayload {
  legal_name: string
  brand_name: string
  phone: string
  email: string
  mailing_address: {
    house_number: string
    street_name: string
    street_suffix?: string
    country_id: number
    city: number
    state_id: number
    zip_code: string
    secondary_designator: string
    secondary_number: string
  }
}

// This class controls the flow and stores all the data
export class SMSOnboardingFlow {
  [SMSOnboardingStepName.welcome] = {
    valid: false,
    pluginId: null,
    returnURL: null,
    showSmsOnboarding: false,
    showCardSteps: false,
    openStep: null,
  };
  [SMSOnboardingStepName.org] = {
    valid: false,
    form: {
      legal_name: null,
      brand_name: null,
      phone: null,
      email: null,
      mailing_address: {
        country: null,
        state: null,
        city: null,
        zip_code: null,
        house_number: null,
        street_name: null,
        secondary_designator: null,
        secondary_number: null,
      },
    },
    account: {
      long_code_number: null,
      toll_free_number: null,
      valid: false,
    }
  };
  [SMSOnboardingStepName.keyword] = {
    valid: false,
    keyword: null,
  };
  [SMSOnboardingStepName.try_it_out] = {
    valid: false,
    phone: null,
  };
  [SMSOnboardingStepName.setup_contact] = {
    valid: false,
    form: {
      first_name: null,
      brand_name: null,
      last_name: null,
      phone: null,
      email: null,
      agree_to_proof_factor_sms_tos: false,
    },
  };
  [SMSOnboardingStepName.tcpa] = {
    valid: false,
    understand_sufficient_consent: false,
    understand_penalties: false,
    understand_liable_compliance: false,
  };
  [SMSOnboardingStepName.terms_of_service] = {
    valid: false,
    updated_business_sms_tos: false,
  };
  [SMSOnboardingStepName.collect_numbers] = {
    valid: false,
    settings: null
  };
  [SMSOnboardingStepName.sms_automations] = {
    valid: false,
  };
  completed = false;
  single_opt_in_terms_completed: boolean
}

export interface LocalPopup {
  type: CampaignPluginName
  phone: boolean
  tcpa: boolean
  email: boolean
  birthday: boolean
  onlyPhone: boolean // if the phone is the only input
  params: WidgetCouponBoxComponentParams | NewsLetterNotificationParams | FortuneWheelNotification
}
export interface LocalCampaign {
  id: string
  name: string
  hasEmail: boolean
  hasPhone: boolean
  hasTCPA: boolean
  popups: LocalPopup[]
  originalCampaign: Campaign
}

export enum LocalCampaignType {
  Gamification = 'gamification',
  Subscription = 'subscription',
}

export enum LocalSMSAutomationType {
  SMSUpsell = 'sms_upsell',
  SMSWinback = 'sms_winback',
  SMSBirthday = 'sms_birthday',
  SMSCartRecovery = 'sms_cart_recovery',
}
