<ng-container *ngIf="isShopifyShop">
  <!-- Monthly Visitors -->
  <div class="one-main-nav-item _is-stats">
    <div class="stats-cell" (click)="navigateToPlansPage($event)">
      <div class="stats-label">Visitors</div>
      <div class="stats-value conveythis-no-translate">
        <one-icon name="earth-outline"></one-icon>
        {{ currentMonthUsage?.toLocaleString() }}
      </div>
    </div>
  </div>
  <!-- Active Campaigns -->
  <div class="one-main-nav-item _is-stats">
    <div class="stats-cell _credits" [routerLink]="'/' + routeHeaderUrl.dashboard">
      <div class="stats-label">Campaigns</div>
      <div class="stats-value conveythis-no-translate">
        <one-icon class="stats-icon" name="ellipse" [class._paused]="campaignsPaused"></one-icon>
        {{ (activeCampaignCount$ | async)?.toLocaleString() || 0 }}
      </div>
      <one-active-campaign-dropdown
      ></one-active-campaign-dropdown>
    </div>
  </div>
  <!-- Active Customers -->
  <div class="one-main-nav-item _is-stats">
    <div class="stats-cell" (click)="navigateToPlansPage($event)">
      <div class="stats-label">Customers</div>
      <div class="stats-value conveythis-no-translate">
        <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6.15803 0.727228C5.73703 0.258257 5.14902 0 4.5 0C3.84752 0 3.25756 0.256695 2.83851 0.722764C2.41491 1.19397 2.20852 1.83436 2.25698 2.52588C2.35304 3.89016 3.35924 4.99997 4.5 4.99997C5.64076 4.99997 6.64523 3.89038 6.7428 2.52632C6.79191 1.84106 6.58422 1.202 6.15803 0.727228ZM8.30759 9.99994H0.692412C0.592737 10.0013 0.494025 9.97968 0.403461 9.9367C0.312896 9.89372 0.232755 9.83046 0.168869 9.75151C0.028248 9.57807 -0.0284332 9.34124 0.0135368 9.10173C0.196128 8.05665 0.765968 7.17875 1.66162 6.56246C2.45732 6.01537 3.46524 5.71425 4.5 5.71425C5.53476 5.71425 6.54268 6.01559 7.33838 6.56246C8.23403 7.17853 8.80387 8.05643 8.98646 9.10151C9.02843 9.34102 8.97175 9.57785 8.83113 9.75128C8.76727 9.83028 8.68713 9.89358 8.59657 9.9366C8.506 9.97961 8.40728 10.0013 8.30759 9.99994Z" fill="#0095F7"/>
        </svg>
        {{ ((this.activeKnownCustomersCount$ | async) || 0)?.toLocaleString() }}
      </div>
    </div>
  </div>
  <!-- SMS Credits -->
  <div class="one-main-nav-item _is-stats" [class._visitors-over-limit]="overLimit">
    <div class="stats-cell" (click)="onAddCreditsClick()">
      <div class="stats-label"> SMS Credits</div>
      <div class="stats-value conveythis-no-translate">
        <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="4.5" cy="4.5" r="4.5" fill="#FFC700"/>
          <path d="M4.5 1L5.2858 3.41844H7.8287L5.77145 4.91312L6.55725 7.33156L4.5 5.83688L2.44275 7.33156L3.22855 4.91312L1.1713 3.41844H3.7142L4.5 1Z" fill="white"/>
        </svg>
        {{smsCredits?.toLocaleString()}}
      </div>
    </div>
  </div>
</ng-container>



<ng-container *ngIf="!isShopifyShop">
  <!-- Monthly Visitor -->
  <div class="one-main-nav-item _is-stats">
    <div class="stats-cell" (click)="navigateToUsage($event)">
      <div class="stats-label">Visitors</div>
      <div class="stats-value conveythis-no-translate">
        <one-icon name="earth-outline"></one-icon>
        {{ currentMonthUsage?.toLocaleString() }}
      </div>
    </div>
  </div>
  <!-- Active Campaigns -->
  <!-- <div class="one-main-nav-item _is-stats">
    <div class="stats-cell _credits" [routerLink]="'/' + routeHeaderUrl.dashboard">
      <div class="stats-label"> Active <br>Campaigns</div>
      <div class="stats-value conveythis-no-translate">
        <one-icon class="stats-icon" name="ellipse"></one-icon>
        {{ (activeCampaignCount$ | async)?.toLocaleString() || 0 }}
      </div>
      <one-active-campaign-dropdown
      ></one-active-campaign-dropdown>
    </div>
  </div> -->
</ng-container>
