export const fortuneWheelCustomCssTemplate =

  `/* Customize your styles using CSS and class selectors below */
.proof-factor-fw {}
.proof-factor-fw-container {}
.proof-factor-fw-box {}
.proof-factor-fw-background {}
.proof-factor-fw-row {}
.proof-factor-fw-column {}
.proof-factor-fw-content {}
.proof-factor-fw-logo {}
.proof-factor-fw-title {}
.proof-factor-fw-description {}
.proof-factor-fw-email-title {}
.proof-factor-fw-email-description {}
.proof-factor-fw-email-back-text {}
.proof-factor-fw-sms-title {}
.proof-factor-fw-sms-description {}
.proof-factor-fw-sms-back-text {}
/* use -webkit-text-fill-color to configure input color */
.proof-factor-fw-form-input {}
.proof-factor-fw-form-input-firstname {}
.proof-factor-fw-form-input-lastname {}
.proof-factor-fw-form-input-email {}
.proof-factor-fw-form-input-phone {}
.proof-factor-fw-form-input-birthday {}
.proof-factor-fw-email-control {}
.proof-factor-fw-error {}
.proof-factor-fw-button {}
.proof-factor-fw-rules {}
.proof-factor-fw-result-control-box {}
.proof-factor-fw-result-control {}
.proof-factor-fw-result-copied {}
.proof-factor-fw-close {}
.proof-factor-fw-wheel-box {}
.proof-factor-fw-wheel {}
.proof-factor-fw-wheel-background {}
.proof-factor-fw-slice {}
.proof-factor-fw-slice:nth-child(2n) {}
.proof-factor-fw-slice:nth-child(2n+1) {}
.proof-factor-fw-slice-value {}
.proof-factor-fw-pointer {}
.proof-factor-fw-pointer-background {}
.proof-factor-fw-overlay {}
`
