<h1 mat-dialog-title>
  Upgrade Plan
  <button class="mat-dialog-close" (click)="dialogRef.close(false)">
    <span>&times;</span>
  </button>
</h1>
<div mat-dialog-content>
  <p>
    {{text}}
  </p>
</div>
<div mat-dialog-actions class="mb-0 d-flex justify-content-end">
  <button class="pf-button outline md gray " (click)="dialogRef.close(false)">Cancel</button>
  <button class="pf-button filled md blue ml-3" (click)="letsGo()">Review Plans</button>
</div>
