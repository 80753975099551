import { Injectable, OnDestroy } from '@angular/core'
import * as _ from 'lodash'
import { BehaviorSubject, Subscription } from 'rxjs'
import { CampaignPluginName } from '../../../shared/models/campaign/campaign'
import { CouponBoxNotificationParams, NewsLetterNotificationParams } from '../../../shared/models/campaign/coupon-box-notification.model'
import { WidgetFortuneWheelComponentParams } from '../../../shared/models/widget/fortune-wheel.model'
import { DevicePreviewButton, MobileDevicePreviewButton, DesktopDevicePreviewButton, WheelSlicesPreviewButton } from '../../../shared/modules/preview-toggle/models/device-preview'
import { PreviewStateButton, PreviewState } from '../../../shared/modules/preview-toggle/models/preview-state'

@Injectable()
export class PreviewService implements OnDestroy {

  private pluginType: CampaignPluginName
  private subscription: Subscription = new Subscription()

  // NOTE: remove controls flag once email waiting is ready
  public statePreviewButtons: PreviewStateButton[] = [
    {
      title: 'Teaser',
      value: PreviewState.teaser,
      active: false,
      hidden: false,
      controls: true,
      separate: true,
      stepLabel: false,
      enabled: true
    },
    {
      title: 'Subscribe',
      value: PreviewState.default,
      active: false,
      hidden: false,
      controls: true,
      stepLabel: true
    },
    {
      title: 'Quick Subscribe',
      value: PreviewState.quickSubscribe,
      active: false,
      hidden: false,
      controls: true,
      stepLabel: true
    },
    {
      title: 'SMS Double Opt-in',
      value: PreviewState.smsWaiting,
      active: false,
      hidden: false,
      controls: true,
      stepLabel: true
    },
    {
      title: 'Email Double Opt-in',
      value: PreviewState.emailWaiting,
      active: false,
      hidden: false,
      controls: true,
      stepLabel: true
    },
    {
      title: 'Reward',
      value: PreviewState.finished,
      active: false,
      hidden: false,
      controls: true,
      stepLabel: true
    },
    {
      title: 'Reminder',
      value: PreviewState.reminder,
      active: false,
      hidden: false,
      controls: true,
      stepLabel: false,
      separate: true,
      enabled: true
    },
  ]

  public devicePreviewButtons: DevicePreviewButton[] = [
    DesktopDevicePreviewButton,
    MobileDevicePreviewButton
  ]

  public advancedCssState$: BehaviorSubject<any> = new BehaviorSubject(null)
  public previewState$: BehaviorSubject<PreviewState> = new BehaviorSubject(this.statePreviewButtons[1].value)
  public previewDevice$: BehaviorSubject<DevicePreviewButton> = new BehaviorSubject(this.devicePreviewButtons[0])
  private popupParams$: BehaviorSubject< CouponBoxNotificationParams |
                                         WidgetFortuneWheelComponentParams |
                                         NewsLetterNotificationParams > = new BehaviorSubject(null)

  public get previewState(): PreviewState {
    return this.previewState$.value
  }

  public set previewState(value: PreviewState) {
    this.previewState$.next(value)
  }


  private disableStateButtons() {
    Object.keys(this.statePreviewButtons).forEach(key => {
      this.statePreviewButtons[key].active = false
    })
  }

  public get previewDevice(): DevicePreviewButton {
    return this.previewDevice$.value
  }

  public set previewDevice(value: DevicePreviewButton) {
    this.previewDevice$.next(value)
  }

  public get popupParams(): CouponBoxNotificationParams |
                            WidgetFortuneWheelComponentParams |
                            NewsLetterNotificationParams {
    return this.popupParams$.value
  }

  public set popupParams(value: CouponBoxNotificationParams |
                                WidgetFortuneWheelComponentParams |
                                NewsLetterNotificationParams) {
    this.pluginType = this.getPluginType(value)
    this.popupParams$.next(value)
  }

  public showSMSPreview() {
    this.previewDevice = MobileDevicePreviewButton
    this.previewState = PreviewState.quickSubscribe
  }

  constructor() {
    // Listen for Popup Params update
    this.subscription.add(
      this.popupParams$.asObservable().subscribe(() => {
        this.toggleStateButtons()
      })
    )
    // Listen for Preview Device update
    this.subscription.add(
      this.previewDevice$.asObservable().subscribe(() => {
        this.toggleStateButtons()
      })
    )
  }

  private getPluginType(params: CouponBoxNotificationParams |
                                WidgetFortuneWheelComponentParams |
                                NewsLetterNotificationParams) {
    if (params.hasOwnProperty('slices')) {
      return CampaignPluginName.FortuneWheel
    } else if (params.hasOwnProperty('coupon_required')) {
      return CampaignPluginName.SalesPopup
    } else if (params.hasOwnProperty('products')) {
      return CampaignPluginName.ProductPopup
    } else if (params.hasOwnProperty('reward')) {
      return CampaignPluginName.CouponBoxNotification
    } else {
      return CampaignPluginName.NewsLetterNotification
    }
  }

  public getButtonByState(state: PreviewState): PreviewStateButton | null {
    const match = this.statePreviewButtons.find(btn => btn.value === state)
    return match ? match : null
  }

  private toggleStateButtons(): void {
    // We have different nesting of `form_config` in different plugins
    const hasTCPA = _.get(this.popupParams, 'form_config.phone.tcpa.enabled', false) ||
                    _.get(this.popupParams, 'config.form_config.phone.tcpa.enabled', false)
    const hasQuickSubscribe = _.get(this.popupParams, 'form_config.phone.tcpa.mob_quick_subscribe', false) ||
                              _.get(this.popupParams, 'config.form_config.phone.tcpa.mob_quick_subscribe', false)
    const hasEmailDoubleOptIn = _.get(this.popupParams, 'form_config.email.double_optin.enabled', false) ||
                                _.get(this.popupParams, 'config.form_config.email.double_optin.enabled', false)
    const isMobileDevice = this.previewDevice.isMobile

    // The buttons
    const subscribeBtn = this.getButtonByState(PreviewState.default)
    const quickSubscribeBtn = this.getButtonByState(PreviewState.quickSubscribe)
    const smsWaitingBtn = this.getButtonByState(PreviewState.smsWaiting)
    const doubleOptinBtn = this.getButtonByState(PreviewState.emailWaiting)
    const finishedBtn = this.getButtonByState(PreviewState.finished)
    const reminderBtn = this.getButtonByState(PreviewState.reminder)
    const isOptInPopup = [
      CampaignPluginName.NewsLetterNotification,
      CampaignPluginName.CouponBoxNotification,
      CampaignPluginName.FortuneWheel
    ].includes(this.pluginType)

    // Toggle Coupon View Name
    if (this.pluginType === CampaignPluginName.NewsLetterNotification) {
      finishedBtn.title = 'Result'
    } else {
      finishedBtn.title = 'Reward'
    }

    doubleOptinBtn.hidden = !hasEmailDoubleOptIn

    reminderBtn.hidden = ![CampaignPluginName.CouponBoxNotification, CampaignPluginName.FortuneWheel].includes(this.pluginType)

    if (this.pluginType && !isOptInPopup) {
      finishedBtn.hidden = true
      subscribeBtn.title = 'Pop up'
    } else {
      finishedBtn.hidden = false
      subscribeBtn.title = 'Subscribe'
    }


    // Hide Quick Subscribe if Desktop view or Quick Sub is NOT enabled or SMS not enabled
    quickSubscribeBtn.hidden = !isMobileDevice || !hasQuickSubscribe || !hasTCPA

    // Hide SMS Waiting button if SMS is not enabled
    smsWaitingBtn.hidden = !hasTCPA

    // Hide Subscribe button if BCB or BSB plugin has Quick Subscribe and it's a Mobile view
    if (isMobileDevice &&
        hasQuickSubscribe &&
        (this.pluginType === CampaignPluginName.CouponBoxNotification ||
        this.pluginType === CampaignPluginName.NewsLetterNotification)) {
      subscribeBtn.hidden = true
    } else {
      subscribeBtn.hidden = false
    }

    // Toggle State if current state button was hidden
    if (subscribeBtn.hidden && this.previewState === PreviewState.default) {
      this.previewState = PreviewState.quickSubscribe
    }
    if (quickSubscribeBtn.hidden && this.previewState === PreviewState.quickSubscribe) {
      this.previewState = PreviewState.default
    }
    if (smsWaitingBtn.hidden && this.previewState === PreviewState.smsWaiting) {
      this.previewState = PreviewState.default
    }
    if (doubleOptinBtn.hidden && this.previewState === PreviewState.emailWaiting) {
      this.previewState = PreviewState.default
    }

    // If both sms and email double opt ins are enabled
    // Rearrange order to reflect the form
    if (hasTCPA && hasEmailDoubleOptIn && smsWaitingBtn && doubleOptinBtn) {

      smsWaitingBtn.order = _.get(this.popupParams, 'config.form_config.phone.order', 0)
      doubleOptinBtn.order = _.get(this.popupParams, 'config.form_config.email.order', 0)

      this.statePreviewButtons.sort((a, b) => {
        // if not sms or email buttons leave as is
        if (a.order === undefined || b.order === undefined) {
          return 0
        }
        // otherwise rearrange according to order
        return a.order - b.order
      })
    }
  }

  ngOnDestroy(): void {
    // Basically never happens since its parent is a lazy loaded module.
    // It also is not being initialized more than once, so no problem here
    this.subscription.unsubscribe()
  }
}
