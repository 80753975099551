
<one-floating-tab tabText="GET A DISCOUNT" *ngIf="!floatingTabDiscountHidden">
  <div style="min-height: 200px; display: flex; flex-flow: column; justify-content: center; align-items: center;">
    <div style="font-weight: bold; font-size: 24px; margin-bottom: 18px;">
      Text "{{floatingTabDiscountCode}}" to
    </div>
    <div style="font-weight: bold; font-size: 40px;">
      +1 (415) 888-9088
    </div>
    <div style="font-weight: bold; font-size: 24px; margin-top: 18px;">
      for {{floatingTabDiscountValue}}% OFF All Plans
    </div>
  </div>
</one-floating-tab>

<div class="PlansPage">
  <ng-container *ngIf="plans && plans.length">
    <h1 class="PlansPage-Title" [class._promo]="!!userPromo" *ngIf="pageTitle">{{ pageTitle }}</h1>

    <div class="PlansPage-Promo" *ngIf="!!userPromo || !floatingTabDiscountHidden">
      <pf-promo
        [userPromo]="userPromo"
        [userInfo]="userInfo"
        (resultEmitter)="onCouponApplied($event)">
      </pf-promo>
    </div>
    <ng-container *ngIf="!isOnboardingPage">
      <div class="PlansPage-SubHeader">
        <div class="PlansPage-SubHeader-Title" *ngIf="userDomain">
          <h6>
            {{ userDomain }}
            <div *ngIf="uninstalledApp" class="pf-badge red ml-2">
              uninstalled
            </div>
          </h6>
        </div>

        <!-- <div class="PlansPage-SubHeader-Section text-center">
          <h1>—</h1>
          <div class="Tooltip-Dashed"
          oneTooltipPosition="bottom"
          [oneTooltip]="anonymousCustomersTooltip">Anonymous Customers
          </div>
          <div>(Trackable)</div>
        </div> -->

        <div class="PlansPage-SubHeader-Section text-center">
          <ng-container *ngIf="knownCustomers === null; else showKnownCustomers">
            <h1><span style="font-size:0.6em">Loading...</span></h1>
          </ng-container>
          <ng-template #showKnownCustomers>
            <h1>{{ knownCustomers | number }}</h1>
          </ng-template>
          <div class="Tooltip-Dashed"
          oneTooltipPosition="bottom"
          [oneTooltip]="knownCustomersTooltip">Known Customers
          </div>
          <div>(Syncable)</div>
        </div>

        <div class="PlansPage-SubHeader-Section text-center" >
          <ng-container *ngIf="knownCustomers === null; else showActiveKnownCustomers">
            <h1><span style="font-size:0.6em">Loading...</span></h1>
          </ng-container>
          <ng-template #showActiveKnownCustomers>
            <h1>{{ activeKnownCustomers | number }}</h1>
          </ng-template>
          <div class="Tooltip-Dashed text-primary"
          oneTooltipPosition="bottom"
          [oneTooltip]="activeKnownCustomersTooltip">Active Customers
          </div>
          <div class="text-primary">(Billable)</div>
        </div>

        <div class="PlansPage-SubHeader-Section text-center" [class.text-danger]="userOverCurrentCampaignLimit">
          <ng-container *ngIf="knownCustomers === null; else showActiveCampaignCount">
            <h1><span style="font-size:0.6em">Loading...</span></h1>
          </ng-container>
          <ng-template #showActiveCampaignCount>
            <h1>{{ activeCampaignCount | number }}</h1>
          </ng-template>
          <div class="Tooltip-Dashed _active-campaigns-tooltip"
              (mouseover)="renderActiveCampaignDropdown('active_campaigns')"
              [class.text-primary]="!userOverCurrentCampaignLimit">
              Active Campaigns
            <ng-container
              *ngComponentOutlet="activeCampaignDropdownVisible['active_campaigns'] ? activeCampaignDropdownComponent : null"
            >
            </ng-container>
          </div>
          <div [class.text-primary]="!userOverCurrentCampaignLimit">(Billable)</div>
        </div>

      </div>
      <div *ngIf="!paidUserPlan">&nbsp;</div>
      <div *ngIf="paidUserPlan" class="d-flex justify-content-center">Numbers looks off?&nbsp;<span class="cursor-pointer text-primary" (click)="sendNumbersOffCrispMessage()">Contact us</span></div>
    </ng-container>
    <div *ngIf="isOnboardingPage">
      &nbsp;
    </div>


    <ng-template #activeKnownCustomersTooltip>
      <div style="max-width: 400px; font-size: 18px; line-height: 1.2;">
        <p>Any customer with a <strong>known profile</strong> (specifically identified by either phone and/or email) and has placed an order,
        abandoned a cart, viewed a product or has explicitly consented to receive marketing.</p>
        <p>Although ONE tracks, stores, and manages all your customers including anonymous profiles
        and profiles that do not accept marketing ONE bills for only <strong>known customers who are active</strong>.</p>
      </div>
    </ng-template>

    <ng-template #activeCampaignsTooltip>
      <div style="max-width: 400px; font-size: 18px; line-height: 1.2;">
        <p>Active Campaign is anything that can be published on the ONE app such as pop up campaigns,
          email/sms automations, or one-click upsell campaigns.</p>
        <p>For ex: 1 pop up + 2 email automations + 1 sms automations + 1 one-click upsell = 5 active campaigns.
          Drafts, Email/SMS Broadcasts, and Pop Up Autoresponders do NOT count towards your active campaign limit.</p>
      </div>
    </ng-template>

    <ng-template #knownCustomersTooltip>
      <div style="max-width: 400px; font-size: 18px; line-height: 1.2;">
        ONE creates a <strong>known profile</strong> for any customer who has provided Personally Identifiable Information (PII) including:
        full name, home address, email address, phone number, date of birth, and so on through either an integrated ecommerce store or any ONE app.
      </div>
    </ng-template>

    <ng-template #anonymousCustomersTooltip>
      <div style="max-width: 400px; font-size: 18px; line-height: 1.2;">
        ONE creates an <strong>anonymous profile</strong> for any customer who has interacted with your integrated ecommerce store through first-party cookies,
        ONE Analytics Web Pixel, and any data integrations.
      </div>
    </ng-template>

    <div class="Plans">
      <ng-container *ngFor="let plan of visiblePlans; index as i">
        <div class="Plan" [class.is-popular]="plan.isPopular && !plan.current" [class.is-recommended]="plan.isRecommended && !plan.current" [class.is-current]="plan.current">
          <!-- TODO move plan to separate pf-plans-item-new-1 component in case we need to share it -->
          <div class="Plan-Header">
            <div class="Plan-Header-Title">{{ plan.title}}</div>
            <div class="Plan-Header-Subtitle">{{ plan.subtitle }}&nbsp;</div>
          </div>
          <div class="Plan-Details">
            <div class="Plan-Pricing">
              <ng-container *ngIf="plan.price?.custom; else displayPrice">
                <div class="Plan-Pricing-Superscript">
                  <ng-container *ngIf="plan.customSuperScript && (paidUserPlan || plan.isStripePlan)">
                    {{ plan.customSuperScript }}
                  </ng-container>
                </div>
                <div class="Plan-Pricing-Price" [ngClass]="plan.price.class" [ngStyle]="plan.isFree && paidUserPlan && {'font-size':'40px','margin-top': '9px'}">
                  <ng-container *ngIf="plan.price?.custom_with_price_and_period; else customText">
                    {{ plan.price.custom | currency:'USD':'symbol':'1.0-2' }}<span class="Plan-Pricing-Period">/ month</span>
                  </ng-container>
                  <ng-template #customText>
                    {{ plan.price.custom }}
                  </ng-template>
                </div>
              </ng-container>
              <ng-template #displayPrice>
                <div class="Plan-Pricing-Superscript">
                  Starting at
                </div>
                <!-- If Plan is selected -->
                <ng-container *ngIf="plan.selected && currentPlan; else pricingPrice">
                  <div class="Plan-Pricing-Price">
                    <div *ngIf="currentPlan?.price.amount === '0'" class="_amount">FREE</div>
                    <div *ngIf="currentPlan?.price.amount !== '0'">
                      <div class="d-flex align-items-center flex-wrap" *ngIf="currentPlan?.old_display_price && currentPlan?.old_display_price !== '0'; else staticPrice">
                        <span class="_old" [ngClass]="currentPlan.price?.class">${{ currentPlan?.old_display_price }}</span>
                        <span class="_new" [ngClass]="currentPlan.price?.class">${{ currentPlan?.price?.amount }}<span *ngIf="currentPlan?.price?.cents" class="Plan-Pricing-Cents">.{{ currentPlan?.price?.cents }}</span><span class="Plan-Pricing-Period">/ {{ plan.price.period }}</span></span>
                      </div>
                      <ng-template #staticPrice>${{ currentPlan?.price?.amount }}<span *ngIf="currentPlan?.price?.cents" class="Plan-Pricing-Cents">.{{ currentPlan?.price?.cents }}</span><span class="Plan-Pricing-Period">/ {{ plan.price.period }}</span></ng-template>
                    </div>
                  </div>
                </ng-container>
                <!-- If plan is not selected yet -->
                <ng-template #pricingPrice>
                  <div class="Plan-Pricing-Price" [class._discounted]="plan.old_display_price && plan.old_display_price !== '$0'">
                    <ng-container *ngIf="!plan.misconfigured; else misconfigured">
                      <div *ngIf="plan.price.amount === '$0'" class="_amount">FREE</div>
                      <div *ngIf="plan.price.amount !== '$0'">
                        <div class="d-flex align-items-center flex-wrap" *ngIf="plan.old_display_price && plan.old_display_price !== '$0'; else onlyPrice">
                          <span class="_old" [ngClass]="plan.price?.class">${{ plan.old_display_price }}</span>
                          <span class="_new" [ngClass]="plan.price?.class">${{ plan.price?.amount }}<span *ngIf="plan?.price?.cents" class="Plan-Pricing-Cents">.{{ plan?.price?.cents }}</span><span class="Plan-Pricing-Period">/ {{ plan.price?.period }}</span></span>
                        </div>
                        <ng-template #onlyPrice>${{ plan.price?.amount }}<span *ngIf="plan?.price?.cents" class="Plan-Pricing-Cents">.{{ plan?.price?.cents }}</span><span class="Plan-Pricing-Period">/ {{ plan.price?.period }}</span></ng-template>
                      </div>
                    </ng-container>
                    <ng-template #misconfigured>
                      <span class="_amount">Invalid</span>
                    </ng-template>
                  </div>
                </ng-template>
              </ng-template>
            </div>
            <div class="Plan-Controls">
              <div class="PlansPage-Selector" [class._invisible]="plan.isCustom">
                <ng-select class="w-100 mb-2"
                  [clearable]="false"
                  [searchable]="false"
                  [formControl]="selectControls[plan.payment_gateway_plan_identifier]"
                  (click)="contactsOpen()"
                  (change)="contactsOptionChanged(plan.payment_gateway_plan_identifier)"
                  [items]="plan.isFree ? [plan.usage_plans[0]] : plan.usage_plans">

                  <ng-template ng-option-tmp let-item="item">
                    <div *ngIf="item.customPriceTier">
                      {{ item.title }}
                    </div>
                    <div *ngIf="!item.customPriceTier" [ngClass]="{'disabled-option': item.disabled}">
                      {{ item.min | number:'1.0':'en-US' }} - {{ item.max | number:'1.0':'en-US' }}
                      <span *ngIf="plan.current && item.selected">(Current Plan)</span>
                    </div>
                  </ng-template>

                  <ng-template ng-label-tmp let-item="item">
                    <ng-container *ngIf="plan.isFree && freePlanDisabled; else showActiveCount">
                      Up to {{lowestTierMax}} Active Customers
                    </ng-container>
                    <ng-template #showActiveCount>
                      <ng-container *ngIf="item.customPriceTier">
                        {{ item.title }} Active Customers
                      </ng-container>
                      <ng-container *ngIf="!item.customPriceTier">
                        Up to {{ item.max | shortNumber }} Active Customers
                      </ng-container>
                    </ng-template>
                  </ng-template>
                </ng-select>
              </div>
              <ng-container *ngIf="plan.isFree && freePlanDisabled; else planCTA">
                <button class="pf-button fw"
                [ngClass]="{
                  'black outline': plan.plan_cta_color === 'black',
                  'purple filled': plan.plan_cta_color !== 'black'
                }"
                (click)="openCalendlyWidget(plansIdentifier.Free)">
                  Schedule Pricing Call
                </button>
              </ng-container>
              <ng-template #planCTA>
                <button class="pf-button fw filled"
                [ngClass]="getButtonClasses(plan.plan_cta_color)"
                (click)="onSelectPlanClick(plan)"
                [class._disabled]="plan.selected || plan.disabled">
                  <ng-container *ngIf="plan.misconfigured; else buttonText">
                    Contact Support
                  </ng-container>
                  <ng-template #buttonText>
                    {{ plan.plan_cta_label_override || plan.plan_cta_label || 'Choose Plan' }}
                    <span *ngIf="plan.plan_cta_label_due_date" class="_due-date">| Due {{plan.plan_cta_label_due_date}}</span>
                  </ng-template>
                </button>
              </ng-template>
              <!-- <button class="pf-button fw purple filled mt-2"
              *ngIf="(plan.isCustom && largeSizeStore) || (plan.isCustomPriceTier && !plan.isFree) || (plan.akcAbove5k && !plan.isFree)"
              (click)="openCalendlyWidget(plan.payment_gateway_plan_identifier)"
              [class._disabled]="plan.selected || plan.disabled">
                Schedule Pricing Call
              </button> -->
              <small *ngIf="plan.subtext" class="Plan-Controls-Text text-center text-muted">
                <span *ngIf="plan.chip" class="pf-badge white-warning xs mr-1">{{ plan.chip }}</span>
                {{ plan.subtext }}
              </small>
              <button class="pf-button fw black filled mt-2"
                 *ngIf="(plan?.pickPlanLaterEligible && !plan?.price?.custom_with_price_and_period) || (plan?.pickPlanLaterEligible && plan?.isStripePlan)"
                 (click)="pickPlanLater($event)">
                Pick Plan Later
              </button>
            </div>
          </div>
          <div class="Plan-Content">
            <div class="Plan-Content-Item" *ngFor="let item of plan.content;">
              {{ item.title }}
              <div class="Plan-Content-Item-Points">
                <div class="Plan-Content-Item-Point" *ngFor="let point of item.points" [class._inactive]="!point.active" [ngClass]="point.class">
                  <ng-container *ngIf="point.withTooltip">
                    <div class="Tooltip-Dashed" [oneTooltip]="point.tooltipText">
                      {{ point.title }}
                    </div>
                  </ng-container>
                  <ng-container *ngIf="point.activeCampaignsDropdown">
                    <div class="Tooltip-Dashed _active-campaigns-tooltip"
                         (mouseover)="renderActiveCampaignDropdown('plan_' + i)"
                    >
                      {{ point.title }}
                      <ng-container
                        *ngComponentOutlet="activeCampaignDropdownVisible['plan_' + i] ? activeCampaignDropdownComponent : null">
                      </ng-container>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="!point.withTooltip && !point.activeCampaignsDropdown">
                    {{ point.title }}
                  </ng-container>
                </div>
              </div>
            </div>
          </div>

          <!-- <pf-plans-item-alt-1
            [data]="plan"
            [user]="userInfo"
            [smsEligible]="userSMSEligible"
            [shopify]="true"
            [mini]="true"
            [buttonText]="plan.plan_cta_label"
            (onSelect)="onSelectPlanClick(plan, $event)">
          </pf-plans-item-alt-1> -->
        </div>
      </ng-container>
    </div>

    <div *ngIf="!customPlanInvoice" class="IncludedFeatures">
      <h3 class="IncludedFeatures-Title">"All-in-One" Plans Include</h3>
      <div class="IncludedFeatures-Points">
        <div class="IncludedFeatures-Point" *ngFor="let point of includedFeatures">
          <ng-container *ngIf="point.price; else textOnly">
            <span class="_strikethrough mr-1" *ngIf="point.price.new < point.price.old">{{ point.price.old | currency:'USD':'symbol':'1.2-2' }}</span>
            <span>{{ point.price.new | currency:'USD':'symbol':'1.0-2' }}</span>{{ point.title }}
          </ng-container>
          <ng-template #textOnly>
            {{ point.title }}
          </ng-template>
        </div>
      </div>
    </div>

    <div class="Reviews">
      <div class="Reviews-Title">
        <div class="Reviews-Title-Header">Over 2,400 5-Star Reviews on Shopify App Store!</div>
        <p class="Reviews-Title-SubHeader">Our app is beloved for its ease-of-use and best-in-class customer support you can count on.</p>
      </div>
      <div class="Reviews-Body">
        <pf-swiper></pf-swiper>
      </div>
    </div>

    <div class="QuestionList">
      <div class="QuestionTitle">Frequently Asked Questions</div>
      <div class="QuestionBody" [ngClass]="{'_fade-out': hideQuestions}">
        <div class="QuestionItem">
          <p class="QuestionText">
            How does the free trial work?
          </p>
          <p class="QuestionAnswer">
            If you cancel a paying plan within the free trial period you won’t be charged a thing.
          </p>
        </div>
        <div class="QuestionItem" *ngIf="shopType!=='ShopifyShop'">
          <p class="QuestionText">
            Do I get a free trial if I upgrade?
          </p>
          <p class="QuestionAnswer">
            If you upgrade from a free account to a paid account you are not eligible for the free trial. The free trial
            on paying plans only applies if you sign up for a paying plan now. You can always downgrade later if you
            choose.
          </p>
        </div>
        <div class="QuestionItem">
          <p class="QuestionText">
            Do I have to sign a long term contract?
          </p>
          <p class="QuestionAnswer">
            No. {{brandingName}} is a pay-as-you-go service. There are no long term contracts or commitments on your part. You
            simply pay
            month-to-month.
          </p>
        </div>
        <div class="QuestionItem">
          <p class="QuestionText">
            Will this app slow down my page speed?
          </p>
          <p class="QuestionAnswer">
            No. It’s less than 19KB on initial page load (i.e. super small and low impact) and loads asynchronously so it won’t impact page speed or SEO.
          </p>
        </div>
        <div class="QuestionItem">
          <p class="QuestionText">
            Can I change plans at any time?
          </p>
          <p class="QuestionAnswer">
            Yep. Simply click on the “Billing Plan” tab on your dashboard and you’ll see your options.
          </p>
        </div>
        <div class="QuestionItem">
          <p class="QuestionText">
            What types of payment do you accept?
          </p>
          <p *ngIf="shopType!=='ShopifyShop'" class="QuestionAnswer">
            We use Stripe to process your payments and currently accept Visa, Mastercard, and American Express. We do not
            accept PayPal. At this
            time we only accept payments online so
            we will not be able to accept a P.O., invoice you, nor take an order over the phone.
          </p>
          <p *ngIf="shopType==='ShopifyShop'" class="QuestionAnswer">
            We use Shopify to process your payments. All payments go through the credit card you have on file with
            Shopify.
          </p>
        </div>
        <div class="QuestionItem">
          <p class="QuestionText">
            How do SMS credits work?
          </p>
          <p class="QuestionAnswer">
            New users receive 250 free SMS credits. After they’re used up, <span class="Tooltip-Dashed"
            oneTooltipPosition="top"
            [oneTooltip]="oneSMSCreditTooltip">1 SMS credit</span> costs $0.01 USD.
          </p>
        </div>
        <!-- <div class="QuestionItem">
          <p class="QuestionText">
            Are there per-site fees?
          </p>
          <p class="QuestionAnswer">
            No. The prices you see above are all inclusive. For example, the High Traffic Site plan is $79/month for
            50,000 unique visitors per
            month. That means you pay
            $79/month total no matter how many sites you have as long as it’s 50,000 unique visitors per month or less. If
            you don’t see a plan
            large enough for your site
            here, we have other plans available. <a href="mailto:enterprise@prooffactor.com">Please contact us.</a>
          </p>
        </div> -->
        <a class="QuestionItem _full-width text-primary pt-0" target="_blank" href="https://help.one.store/en/article/pricing-plans-faq-10sjxll/?utm_source=billing_page&utm_medium=website&utm_campaign=faq">
          More Information
        </a>
        <div *ngIf="hideQuestions" class="fadeout"></div>
        <div class="mt-2">
          <one-icon *ngIf="hideQuestions" class="_expended-arrow _bounce" name="chevron-down" alignment="center" (click)="toggleMoreReviews(true)"></one-icon>
          <one-icon *ngIf="!hideQuestions" class="_expended-arrow" name="chevron-up" alignment="center" (click)="toggleMoreReviews(false)"></one-icon>
        </div>
      </div>
    </div>

    <ng-template #oneSMSCreditTooltip>
      <div style="line-height: 1em;">
        <p><strong>Message Credit</strong></p>
        <p>Messages sent to subscribers in the US or Canada will generally cost:</p>
        <ul>
          <li>1-3 credits per SMS</li>
          <li>3-9 credits per MMS</li>
        </ul>
        <p>Messages sent to subscribers outside of the US or Canada will generally cost:</p>
        <ul>
          <li>10-20 credits per SMS</li>
          <li>MMS is not supported at this time</li>
        </ul>
        <span style="font-style: italic; color: #888888;">* May vary depending on carrier, country, and current SMS market rates</span>
      </div>
    </ng-template>

    <div class="FeatureCards">
      <h1 class="FeatureCards-Title" *ngIf="pageTitle">20+ ONE Apps Seamlessly Integrated</h1>
      <div class="FeatureCard" *ngFor="let card of featureCards">
        <div class="FeatureCard-Image">
          <img [src]="card.image" alt="">
        </div>
        <div class="FeatureCard-Content">
          <div class="FeatureCard-Title">{{card.title}}</div>
          <ul class="FeatureCard-Points">
            <li class="FeatureCard-Point" *ngFor="let point of card.points">
              {{ point }}
            </li>
          </ul>
        </div>
        <div class="FeatureCard-Pricing">
          <div class="FeatureCard-Pricing-Superscript" *ngIf="card.price.superscript">
            {{ card.price.superscript }}
          </div>
          <div class="FeatureCard-Pricing-Price">
            {{ card.price.amount === 'Free' ? 'Unlimited' : card.price.currency + card.price.amount }}
          </div>
          <div class="FeatureCard-Pricing-Period" *ngIf="card.price.period">
            {{ card.price.period }}
          </div>
        </div>
      </div>
    </div>
<!--
    <div class="Testimonial">
      <div class="Testimonial__Box">
        <div class="Testimonial__Photo"><img src="/app_assets/images/peter.jpeg" alt=""></div>
        <div class="Testimonial__Body">
          <div class="Testimonial__Name">Peter Friis</div>
          <div class="Testimonial__Position">CEO,
            <a style="text-decoration: none; color: #29334e;" href="https://prvolt.com/">PRVolt.com</a></div>
          <div class="Testimonial__Text">"{{brandingName}} makes sure that I get the highest ROI on my marketing campaigns
            by encouraging as many customers to purchase. I wouldn’t market without it."
          </div>
        </div>
      </div>
    </div> -->

    <pf-support-boxs [hasRequestFeature]="false"></pf-support-boxs>
  </ng-container>

  <pf-loading-overlay [filter]="{ignore: ['addCard', 'savePaymentToken', 'stripeLoading']}" [text]="loadingOverlayText"></pf-loading-overlay>
</div>
