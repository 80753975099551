import { Injectable } from '@angular/core'
import _ from 'lodash'
import { BehaviorSubject } from 'rxjs'
import { CouponCodeType } from '../../../../coupon-codes/models/coupon-code'
import { NewCouponCodesService } from '../../../../coupon-codes/services/new-coupon-code.service'
import { FortuneWheelThemeConfig, FortuneWheelThemeConfigDefault, FortuneWheelThemeConfigLocal } from '../models/fortune-wheel.model'

@Injectable()
export class FortuneWheelService {
  public isOnboarding: boolean = false
  // public disableStep$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  private savedCodes = {}
  public themesList: FortuneWheelThemeConfigDefault[]
  public sliceHovered$ = new BehaviorSubject<any>(null)

  constructor(
    private newCouponCodesService: NewCouponCodesService
  ) { }

  generateCode(slices, coupons) {
    const _slices = _.cloneDeep(slices)
    _slices.forEach((slice) => {
      const coupon = coupons.find((code) => {
       return code && code.id === slice.reward.id
      })
      if (coupon) {
        let code = null
        if ([CouponCodeType.shopify_unique,
          CouponCodeType.big_commerce_unique,
          CouponCodeType.woo_commerce_unique,
          CouponCodeType.shopify_gift_card_unique].includes(coupon.type)) {
          code = `${coupon.prefix_code}${this.newCouponCodesService.generateRandomCode()}`
        } else {
          code = coupon.master_code
        }
        slice['value'] = code
      }
    })
    return _slices
  }

  extendThemeToLocal(themesList: FortuneWheelThemeConfig[]): FortuneWheelThemeConfigLocal[] {
    const newThemesList = []

    themesList.forEach(theme => {
      newThemesList.push({
        ...theme,
        selected: false,
        loaded: false,
        resetable: false,
      })
    })
    return newThemesList.sort((a, b) => b.list_order - a.list_order)
  }

  generatePreviewCode(slices, coupons) {
    const _slices = _.cloneDeep(slices)
    _slices.forEach((slice) => {
      const coupon = coupons.find((code) => {
        return code && code.id === slice.reward.id
      })
      if (coupon) {
        let code = null
        if ([CouponCodeType.shopify_unique,
          CouponCodeType.big_commerce_unique,
          CouponCodeType.woo_commerce_unique,
          CouponCodeType.shopify_gift_card_unique].includes(coupon.type)) {
          if (!this.savedCodes[coupon.id]) {
            this.savedCodes[coupon.id] = this.newCouponCodesService.generateRandomCode()
          }
          code = `${coupon.prefix_code}${this.savedCodes[coupon.id]}`
        } else {
          code = coupon.master_code
        }
        slice['value'] = code
      }
    })
    return _slices
  }
}
