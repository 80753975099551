import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FloatingTabComponent } from './components/floating-tab/floating-tab.component'
import { OneIconComponent } from '../../components/one-icon/one-icon.component'

@NgModule({
  
  imports: [
    CommonModule,
    OneIconComponent,
  ],
  declarations: [
    FloatingTabComponent,
  ],
  exports: [
    FloatingTabComponent,
  ]
})
export class FloatingTabModule { }
