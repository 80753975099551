import { Injectable } from '@angular/core'
import { Observable, timer } from 'rxjs'
import { mergeMap, shareReplay } from 'rxjs/operators'
import { ApiSettingsService } from './api/api-settings.service'

@Injectable()
export class DashboardSettingsService {
  settings$: Observable<any>

  constructor(
    private apiSettingsService: ApiSettingsService,
  ) {}

  getSettings(): Observable<any> {
    if (!this.settings$) {
      this.settings$ = this.apiSettingsService.getConfig().pipe(
        shareReplay(1)
      )
    }
    return this.settings$
  }

  pollSettings(): Observable<any> {
   return timer(0,60 * 1000)
      .pipe(
        mergeMap(() => this.apiSettingsService.getConfig()),
      )
  }

}
