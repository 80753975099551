<h1 class="video-tutorial-header mb-3" mat-dialog-title>
  {{ header }}
  <button class="mat-dialog-close" (click)="close()">
    <span>&times;</span>
  </button>
</h1>

<div mat-dialog-content class="mb-2">
  <div *ngIf="!videoLoaded">
    <pf-loading-overlay></pf-loading-overlay>
  </div>
  <one-video-player
    [youtubeId]="youtubeURL"
    [cloudflareId]="cloudflareURL"
  ></one-video-player>

  <ng-container *ngIf="youtubeURL === 'VgE7nM0r5dM'">
    <div class="video-tutorial-text">
      <p>
        Watch this quick video to get a tour of how our app can help you collect subscribers, increase conversions, and
        boost sales!
      </p>
      <p><i>Upsell, Pop ups, Social Proof, Email, SMS and more</i></p>
      <p *ngIf="!isShopify"><small><b>* Upsell and SMS are only available for Shopify users at this time</b></small></p>
    </div>
  </ng-container>

</div>

