import {
  PopupTriggersParams
} from '../../../../../shared/models/campaign/popup-triggers.model'
import { PreviewState } from '../../../../../shared/modules/preview-toggle/models/preview-state'
import { CouponBoxRewardType } from '../../../../../shared/models/campaign/coupon-box-notification.model'
import { CouponCodeType } from '../../../../coupon-codes/models/coupon-code'
import { RewardsType } from '../../../../coupon-codes/models/rewards-model'

export interface FortuneWheelParams {
  active: boolean
  campaign_id: string
  config: {
    advanced: {
      custom_js: string
      custom_css: string
    }
    display: {
      mobile: PopupTriggersParams
      desktop: PopupTriggersParams
    }
    form_config: FortuneWheelFormConfig
    spam_protection: {
      auto_block: boolean
      enable_captcha: boolean
    }
    required_option: string
    required_option_error_text: string
    send_coupon_code: boolean
    send_gift_card_code: boolean
    reward?: {
      id: string,
      type?: CouponCodeType,
      reward_type: RewardsType
    }
    allow_auto_translate: boolean
    slices_config: {
      allow_loss: boolean
      auto_apply_coupon_code: boolean
      auto_apply_gift_card_code: boolean
    }
    branding: {
      show_popup_branding: boolean;
      popup_branding_name: string;
      popup_branding_wording: string;
    }
    texts: FortuneWheelTextsConfig
    theme: FortuneWheelThemeConfig
    elements_styling: ElementStylingConfig
    reminder: {
      enabled: boolean,
      styles: any
      text: string,
      position: 'top' | 'bottom',
    }
  }
  created_at: string
  data_type: string
  id: string
  mail_system_integrations: any[]
  premium: boolean
  slices: FortuneWheelSlice[]
  sms_keyword: FortuneWheelSMSKeyword
  sync_with_shopify: boolean
  unlayer_email_template: {
    enabled: boolean
    template_id: string
    json: object
    html: string
    settings: {
      subject: string
      reply_to: string
      preview_text: string
    }
  }
  updated_at: string
  validity: {
    status: boolean
    messages: any[]
  }
}

export interface ElementStylingConfig {
  idle_state: {
    title: ElementStyles
    subtitle: ElementStyles
    decline_button: ElementStyles
    rules: ElementStyles
    inputs: ElementStyles
    subscribe_button: ElementStyles
    close_button: ElementStyles
  }
  sms_state: {
    description: ElementStyles
    back_button: ElementStyles
    close_button: ElementStyles
  }
  email_state: {
    description: ElementStyles
    back_button: ElementStyles
    close_button: ElementStyles
  }
  result_state: {
    title: ElementStyles
    description: ElementStyles
    reward: ElementStyles
    copy_button: ElementStyles
  }
}

export interface ElementStyles {
  margin: string;
  custom_margin_enabled: boolean;
  visible?: boolean;
}

export interface FortuneWheelPreviewParams {
  active?: boolean
  slices: FortuneWheelSlice[]
  texts: FortuneWheelTextsConfig
  form_config: FortuneWheelFormConfig
  display: {
    mobile: PopupTriggersParams
    desktop: PopupTriggersParams
  },
  theme: FortuneWheelThemeConfig
  branding: {
    show_popup_branding: boolean;
    popup_branding_name: string;
    popup_branding_wording: string;
  },
  elements_styling: any
  advanced: {
    custom_js: string
    custom_css: string
  }
  resultId?: string,
  error?: object,
  onSpin: Function,
  onClose?: Function,
  previewMode?: boolean,
  previewState?: PreviewState,
  previewIframe?: boolean,
  showFullVersion?: boolean,
  isPhonePreview?: boolean,
  isSlicesPreview?: boolean,
  previewModeLoss?: boolean,
  detectedCountry?: string,
  reference_token?: string,
  required_option?: string,
  required_option_error_text?: string,
  send_gift_card_code?: boolean
  send_coupon_code?: boolean
  disablePopupScale?: boolean
  reminder?: {
    enabled: boolean,
    styles: any
    text: string,
    position: 'top' | 'bottom',
  }
}


export interface FortuneWheelThemeConfigDefault {
  name?: string,
  identifier?: FortuneWheelThemeIdentifier,
  premium: boolean,
  variables: FortuneWheelThemeVariables,
  images: FortuneWheelThemeImages,
  css: string
  selected: boolean,
  loaded: boolean,
  resetable: boolean,
  list_order: number,
  preview_image_url: string
}

export interface FortuneWheelThemeConfigLocal extends FortuneWheelThemeConfigDefault {
  selected: boolean,
  loaded: boolean,
  resetable: boolean
}

export interface FortuneWheelSMSKeyword {
  created_at: string
  deleted_at: string
  id: string
  num_subscribers: number
  owner_id: string
  owner_type: string
  text: string
  updated_at: string
  user_id: string
}

export interface FortuneWheelThemeConfig {
  name?: string
  premium?: boolean
  identifier?: FortuneWheelThemeIdentifier
  variables: FortuneWheelThemeVariables
  images: FortuneWheelThemeImages
  css: string
}

export interface FortuneWheelTextsConfig {
  idle_state: {
    title: string
    description: string
    button: string
  }
  email_state: {
    title: string
    description: string
    back: string
  }
  sms_state: {
    title: string
    description: string
    // mob_quick_description: string
    back: string
  }
  coupon_state: {
    title: string
    description: string
    button: string
    email_sent: string
    footer: string
  }
  no_prize_state: {
    title: string
    description: string
    button: string
  }
  errors: {
    email_already_used: string
    email_invalid: string
    unknown_error: string
  }
  other: {
    email_placeholder: string
    rules: {
      type: 'text' | 'html'
      value: string
    }
    close_button: string
    copied_message: string
    fraud_protection: {
      fraud_protection_text: string
      show_fraud_protection: boolean
    }
  }
  terms: {
    text: string
  }
}

export interface FortuneWheelFormConfig {
  name?: {
    order: number
    format: string
    first_name: {
      is_required: boolean
      placeholder: string
      errors: {
        blank: string
      }
    }
    last_name: {
      is_required: boolean
      placeholder: string
      errors: {
        blank: string
      }
    }
  }
  email?: {
    order: number
    placeholder: string
    errors: {
      blank: string
      invalid: string
      duplicated: string
    }
    duplicate_submissions: {
      allow_duplicating: boolean
      subscription_timeout: number
    }
  }
  phone?: {
    tcpa?: {
      enabled: boolean
      mob_quick_subscribe: boolean
      mob_quick_subscribe_text: string
    },
    duplicate_submissions: {
      allow_duplicating: boolean
      subscription_timeout: number
    }
    order: number
    input_placeholder: {
      text: string
      enabled: boolean
    }
    country: {
      placeholder: {
        text: string
        enabled: boolean
      }
      default: string
      flag_enabled: boolean
    }
    errors: {
      blank: string
      invalid: string
      duplicated: string
    }
  }
  birthday?: {
    order: number
    is_required: boolean
    placeholder: {
      text: string
      enabled: boolean
    }
    format: string
    errors: {
      blank: string
    }
  }
}

export interface FortuneWheelSlice {
  reward: {
    id: string
    type: CouponCodeType
    reward_type: RewardsType
    title?: string
    data?: any
  }
  id: string
  gravity: string | number
  index: number
  label: string
  type: FortuneWheelSliceType
  percentage: string | number
}

export enum FortuneWheelSliceType {
  COUPON = 'coupon_code',
  PRODUCT = 'product',
  NO_PRIZE = 'no_prize',
}

export enum FortuneWheelThemeIdentifier {
  default = 'default',
  beach_default = 'beach_default',
  beach_alt_1 = 'beach_alt_1',
  beach_alt_2 = 'beach_alt_2',
  black_friday_alt_1 = 'black_friday_alt_1',
  black_friday_alt_2 = 'black_friday_alt_2',
  black_friday_alt_3 = 'black_friday_alt_3',
  black_friday_extended = 'black_friday_extended',
  black_friday_extended_alt = 'black_friday_extended_alt',
  christmas = 'christmas',
  christmas_alt_1 = 'christmas_alt_1',
  christmas_alt_2 = 'christmas_alt_2',
  cyber_monday = 'cyber_monday',
  cyber_monday_alt_1 = 'cyber_monday_alt_1',
  cyber_week = 'cyber_week',
  cyber_week_alt_1 = 'cyber_week_alt_1',
  love_default = 'love_default',
  love_alt_1 = 'love_alt_1',
  love_alt_2 = 'love_alt_2',
  love_alt_3 = 'love_alt_3',
  lucky_default = 'lucky_default',
  lucky_alt_1 = 'lucky_alt_1',
  lucky_alt_2 = 'lucky_alt_2',
  moon_default = 'moon_default',
  moon_alt_1 = 'moon_alt_1',
  moon_alt_2 = 'moon_alt_2',
  ocean_alt_1 = 'ocean_alt_1',
  ocean_alt_2 = 'ocean_alt_2',
  ocean_alt_3 = 'ocean_alt_3',
  valentine_day_1 = 'valentine_day_1',
  valentine_day_2 = 'valentine_day_2',
}

export type FortuneWheelThemeImages = {
  [key in FortuneWheelThemeImageName]?: FortuneWheelThemeImage
}

export interface FortuneWheelThemeImage {
  url: string
  uploaded?: boolean
}

export enum FortuneWheelThemeImageName {
  pattern = 'pattern',
  background = 'background',
  wheel = 'wheel',
  pointer = 'pointer',
  wheel_logo = 'wheel_logo',
  logo = 'logo',
  mobile_background = 'mobile_background',
}

export type FortuneWheelThemeVariables = {
  [key in FortuneWheelThemeVariable]?: string
}

// need refactoring, else require adding in WidgetFortuneWheelThemeVariableName as well (allowedVariables)
export enum FortuneWheelThemeVariable {
  overlay_bg = 'overlay_bg',
  wheel_bg = 'wheel_bg',

  general_font = 'general_font',

  popup_position = 'popup_position',

  title_color = 'title_color',
  title_size = 'title_size',
  title_bg_color = 'title_bg_color',
  title_weight = 'title_weight',

  subtitle_color = 'subtitle_color',
  subtitle_size = 'subtitle_size',
  subtitle_bg_color = 'subtitle_bg_color',
  subtitle_weight = 'subtitle_weight',

  text_color = 'text_color',
  text_size = 'text_size',
  text_bg_color = 'text_bg_color',
  text_weight = 'text_weight',

  slice_text_color = 'slice_text_color',
  slice_text_size = 'slice_text_size',
  slice_text_bg = 'slice_text_bg',
  slice_text_weight = 'slice_text_weight',
  slice_odd_text_color = 'slice_odd_text_color',
  slice_odd_text_size = 'slice_odd_text_size',
  slice_odd_text_bg = 'slice_odd_text_bg',
  slice_odd_text_weight = 'slice_odd_text_weight',

  error_color = 'error_color',
  error_background = 'error_background',

  input_field_color = 'input_field_color',
  input_field_size = 'input_field_size',
  input_field_background = 'input_field_background',
  input_field_weight = 'input_field_weight',
  input_placeholder_color = 'input_placeholder_color',
  input_placeholder_size = 'input_placeholder_size',

  button_color = 'button_color',
  button_size = 'button_size',
  button_bg = 'button_bg',
  button_weight = 'button_weight',
  button_disabled_color = 'button_disabled_color',
  button_hover_color = 'button_hover_color',
  button_disabled_bg = 'button_disabled_bg',
  button_hover_bg = 'button_hover_bg',
  close_button_color = 'close_button_color',
  close_button_bg_color = 'close_button_bg_color',
  close_button_style = 'close_button_style',
  close_button_size = 'close_button_size',

  cancel_link_color = 'cancel_link_color',
  background = 'background',
  wheel = 'wheel',
  pointer = 'pointer',
  wheel_logo = 'wheel_logo',
  wheel_logo_size = 'wheel_logo_size',
  background_size = 'background_size',
  logo_size = 'logo_size',

  email_title_color = 'email_title_color',
  email_title_size = 'email_title_size',
  email_title_bg_color = 'email_title_bg_color',
  email_title_weight = 'email_title_weight',

  email_description_color = 'email_description_color',
  email_description_size = 'email_description_size',
  email_description_bg_color = 'email_description_bg_color',
  email_description_weight = 'email_description_weight',

  email_back_text_color = 'email_back_text_color',
  email_back_text_size = 'email_back_text_size',
  email_back_text_bg_color = 'email_back_text_bg_color',
  email_back_text_weight = 'email_back_text_weight',

  sms_title_color = 'sms_title_color',
  sms_title_size = 'sms_title_size',
  sms_title_bg_color = 'sms_title_bg_color',
  sms_title_weight = 'sms_title_weight',

  sms_description_color = 'sms_description_color',
  sms_description_size = 'sms_description_size',
  sms_description_bg_color = 'sms_description_bg_color',
  sms_description_weight = 'sms_description_weight',

  sms_back_text_color = 'sms_back_text_color',
  sms_back_text_size = 'sms_back_text_size',
  sms_back_text_bg_color = 'sms_back_text_bg_color',
  sms_back_text_weight = 'sms_back_text_weight',

  result_title_color = 'result_title_color',
  result_title_size = 'result_title_size',
  result_title_bg_color = 'result_title_bg_color',
  result_title_weight = 'result_title_weight',

  result_footer_color = 'result_footer_color',
  result_footer_size = 'result_footer_size',
  result_footer_bg_color = 'result_footer_bg_color',
  result_footer_weight = 'result_footer_weight',

  description_color = 'description_color',
  description_size = 'description_size',
  description_bg_color = 'description_bg_color',
  description_weight = 'description_weight',

  code_field_color = 'code_field_color',
  code_field_size = 'code_field_size',
  code_field_weight = 'code_field_weight',
  code_field_background = 'code_field_background',

  copied_message_color = 'copied_message_color',
  copied_message_size = 'copied_message_size',
  copied_message_weight = 'copied_message_weight',
  copied_message_bg = 'copied_message_bg',

  fraud_protection_color = 'fraud_protection_color',
  fraud_protection_size = 'fraud_protection_size',
  fraud_protection_bg_color = 'fraud_protection_bg_color',
  fraud_protection_weight = 'fraud_protection_weight',
  fraud_protection_text_alignment = 'fraud_protection_text_alignment',

  fraud_protection_reference_id_color = 'fraud_protection_reference_id_color',
  fraud_protection_reference_id_size = 'fraud_protection_reference_id_size',
  fraud_protection_reference_id_bg_color = 'fraud_protection_reference_id_bg_color',
  fraud_protection_reference_id_weight = 'fraud_protection_reference_id_weight',
  fraud_protection_reference_id_case = 'fraud_protection_reference_id_case',

  email_sent_color = 'email_sent_color',
  email_sent_size = 'email_sent_size',
  email_sent_weight = 'email_sent_weight',
  email_sent_bg_color = 'email_sent_bg_color',

  result_button_color = 'result_button_color',
  result_button_size = 'result_button_size',
  result_button_bg = 'result_button_bg',
  result_button_weight = 'result_button_weight',
  result_button_hover_color = 'result_button_hover_color',
  result_button_hover_bg = 'result_button_hover_bg',

  wheel_border_color = 'wheel_border_color',
  wheel_border_size = 'wheel_border_size',
  pattern_position = 'pattern_position',

  mobile_bg_enabled = 'mobile_bg_enabled',
  mobile_background = 'mobile_background',
  mobile_background_size = 'mobile_background_size',

  mobile_close_button_enabled = 'mobile_close_button_enabled',
  mobile_close_button_style = 'mobile_close_button_style',
  mobile_close_button_color = 'mobile_close_button_color',
  mobile_close_button_bg_color = 'mobile_close_button_bg_color',
  mobile_close_button_size = 'mobile_close_button_size',
}


export enum FortuneWheelPosition {
  slide_left = 'slide_left',
  slide_right = 'slide_right',
  popup_center = 'popup_center',
  embedded = 'embedded',
}
