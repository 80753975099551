const lt = {
  Quantity: 'Kiekis',
  Size: 'Dydis',
  Color: 'Spalva',
  Material: 'Medžiaga',
  Style: 'Stilius',
  Savings: 'Sutaupote',
  Subtotal: 'Tarpinė suma',
  Shipping: 'Pristatymas',
  Taxes: 'Mokesčiai',
  Total: 'Iš viso',
  Free: 'Nemokamai',
  Buy: 'Pirkti',
  'Pay Now': 'Mokėti dabar',
  'Take the Deal': 'Pasirinkti pasiūlymą',
  'Add Now': 'Pridėti',
  'You will be charged immediately': 'Suma bus nuskaičiuota iškarto',
  'Decline Offer': 'Atmesti pasiūlymą',
  'No Thanks': 'Ne, ačiū',
  '{price}': '{kaina}',
  'Sold Out': 'Išparduota',
  'see more': 'žiūrėti daugiau',
  less: 'mažiau',
};
export {lt};
