<div [formGroup]="requestForm">
  <div class="ThemeRequest-Title mb-0" mat-dialog-title>
    Theme Design Request
  </div>
  <div class="ThemeRequest-Content pb-1" mat-dialog-content>
    <div class="left">
      <div class="ThemeRequest-Content-title">Theme Type</div>
      <mat-form-field class="pf-form-field w-100">
        <mat-select class="form-control _select" formControlName="type">
          <mat-option value="popup">Pop Up</mat-option>
          <mat-option value="email">Email</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="ThemeRequest-Content-title">Theme Name</div>
      <div class="ThemeRequest-Input" *ngIf="requestForm.get('name'); let control">
        <input class="form-control" [class.is-invalid]="control.errors && control.dirty" type="text" formControlName="name"
          placeholder="What is your theme called">
        <ng-container *ngIf="control.dirty && control.errors">
          <small class="invalid-feedback d-block" *ngIf="control.errors.maxlength">
            Theme name must be less than {{control.errors.maxlength.requiredLength}} characters
          </small>
          <small class="invalid-feedback d-block" *ngIf="control.errors.required">
            Theme name is required
          </small>
        </ng-container>
      </div>
      <div class="ThemeRequest-Content-title">What is this theme for?</div>
      <div class="ThemeRequest-Textarea">
        <div class="_textarea" *ngIf="requestForm.get('description'); let control">
          <textarea type="text" rows="7" [maxlength]="descMaxLength + 35" formControlName="description"
            placeholder="Provide information to help us better understand your expectations (i.e. design styles, the purpose of the theme, elements in the designs, etc)."
            class="form-control control-textarea" [class.is-invalid]="control.errors && control.dirty"></textarea>
          <span class="_count">{{descTypeLength}} / {{descMaxLength}}</span>
        </div>
      </div>
      <div *ngIf="requestForm.get('description'); let control">
        <ng-container *ngIf="control.dirty && control.errors">
          <small class="invalid-feedback d-block" *ngIf="control.errors.maxlength">
            Details must be less than {{control.errors.maxlength.requiredLength}} characters
          </small>
          <small class="invalid-feedback d-block" *ngIf="control.errors.required">
            Details is required
          </small>
        </ng-container>
      </div>
    </div>
    <div class="right">
      <div class="ThemeRequest-Content-title">Inspire us with a design reference! <span class="_note">- Up to 5 images</span></div>
      <div class="_image-upload">
        <input hidden type="file" multiple (change)="onFileUpload($event)" accept="image/*" #uploader>
        <div class="_image-upload-placeholder">
          <div class="_uploader cursor-pointer" (click)="uploader.click()" pfDropZone (fileDropped)="onFileDropped($event)">
            <div class="d-flex flex-column _uploader-text-wrapper">
              <div class="_title">Upload Image here</div>
              <div class="_notice">
                <div class="text-danger" *ngIf="isImageInvalid">
                  The file type is not recognized, please use png, jpg, and gif format.
                </div>
                <div class="text-danger" *ngIf="isImageOverLimit">
                  Please upload a file with a valid file size no larger than 5MB.
                </div>
                <div class="text-danger" *ngIf="isImageOverCount">
                  You cannot upload more than 5 images.
                </div>
                <ng-container *ngIf="!isImageInvalid && !isImageOverLimit && !isImageOverCount">
                  Supported file types: png, jpg, gif. (Max file size 5MB)
                </ng-container>
              </div>
            </div>
          </div>
        </div>
        <div class="_image-upload-preview mt-2" formArrayName="imageUrl" *ngFor="let img of requestForm.get('imageUrl')['controls']; index as i">
          <small *ngIf="isImageFailed">Cannot load image</small>
          <a [href]="img.value.url" target="_blank"><img *ngIf="!isImageFailed" [src]="img.value.url" (error)="onImageError()" (load)="onImageLoad()" alt=""></a>
          <div class="_img-progress relative">
            <span class="_img-progress-filename">{{ img.value.name }}</span>
            <div class="_img-progress-description">
              <span class="_size">{{ img.value.date }} / {{ img.value.size }}</span>
            </div>
          </div>
          <div class="_img-closed">
            <one-icon class="cursor-pointer" name="close-circle-outline" (click)="onFileRemove(i)"></one-icon>
          </div>
        </div>
      </div>
      <div>
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="mt-2 mb-0 justify-content-end _actions">
    <button class="pf-button outline md blue" (click)="dialogRef.close()">Cancel</button>
    <button type="submit" class="pf-button filled md blue ml-3" (click)="sendRequest()">Send to our
      team</button>
  </div>
</div>
<pf-loading-overlay [isFixed]="false"></pf-loading-overlay>
