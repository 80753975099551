export enum MailIntegrationActionTypes {
  SET_SELECTED_INTEGRATION = 'MailIntegration.SET_SELECTED_INTEGRATION',
  CLEAR_SELECTED_INTEGRATION = 'MailIntegration.CLEAR_SELECTED_INTEGRATION',

  READ_ALL_INTEGRATIONS_REQUEST = 'MailIntegration.READ_ALL_INTEGRATIONS_REQUEST',
  READ_ALL_INTEGRATIONS_SUCCESS = 'MailIntegration.READ_ALL_INTEGRATIONS_SUCCESS',
  READ_ALL_INTEGRATIONS_FAILURE = 'MailIntegration.READ_ALL_INTEGRATIONS_FAILURE',

  CREATE_INTEGRATION_REQUEST = 'MailIntegration.CREATE_INTEGRATION_REQUEST',
  CREATE_INTEGRATION_SUCCESS = 'MailIntegration.CREATE_INTEGRATION_SUCCESS',
  CREATE_INTEGRATION_FAILURE = 'MailIntegration.CREATE_INTEGRATION_FAILURE',

  DELETE_INTEGRATION_REQUEST = 'MailIntegration.DELETE_INTEGRATION_REQUEST',
  DELETE_INTEGRATION_SUCCESS = 'MailIntegration.DELETE_INTEGRATION_SUCCESS',
  DELETE_INTEGRATION_FAILURE = 'MailIntegration.DELETE_INTEGRATION_FAILURE',

  DELETE_FORTUNE_WHEEL_INTEGRATION_REQUEST = 'MailIntegration.DELETE_FORTUNE_WHEEL_INTEGRATION_REQUEST',
  DELETE_FORTUNE_WHEEL_INTEGRATION_SUCCESS = 'MailIntegration.DELETE_FORTUNE_WHEEL_INTEGRATION_SUCCESS',
  DELETE_FORTUNE_WHEEL_INTEGRATION_FAILURE = 'MailIntegration.DELETE_FORTUNE_WHEEL_INTEGRATION_FAILURE',

  DELETE_COUPON_BOX_INTEGRATION_REQUEST = 'MailIntegration.DELETE_COUPON_BOX_INTEGRATION_REQUEST',
  DELETE_COUPON_BOX_INTEGRATION_SUCCESS = 'MailIntegration.DELETE_COUPON_BOX_INTEGRATION_SUCCESS',
  DELETE_COUPON_BOX_INTEGRATION_FAILURE = 'MailIntegration.DELETE_COUPON_BOX_INTEGRATION_FAILURE',

  DELETE_NEWSLETTER_INTEGRATION_REQUEST = 'MailIntegration.DELETE_NEWSLETTER_INTEGRATION_REQUEST',
  DELETE_NEWSLETTER_INTEGRATION_SUCCESS = 'MailIntegration.DELETE_NEWSLETTER_INTEGRATION_SUCCESS',
  DELETE_NEWSLETTER_INTEGRATION_FAILURE = 'MailIntegration.DELETE_NEWSLETTER_INTEGRATION_FAILURE',

  READ_INTEGRATION_LISTS_REQUEST = 'MailIntegration.READ_INTEGRATION_LISTS_REQUEST',
  READ_INTEGRATION_LISTS_SUCCESS = 'MailIntegration.READ_INTEGRATION_LISTS_SUCCESS',
  READ_INTEGRATION_LISTS_FAILURE = 'MailIntegration.READ_INTEGRATION_LISTS_FAILURE',

  CREATE_FORTUNE_WHEEL_INTEGRATION_REQUEST = 'MailIntegration.CREATE_FORTUNE_WHEEL_INTEGRATION_REQUEST',
  CREATE_FORTUNE_WHEEL_INTEGRATION_SUCCESS = 'MailIntegration.CREATE_FORTUNE_WHEEL_INTEGRATION_SUCCESS',
  CREATE_FORTUNE_WHEEL_INTEGRATION_FAILURE = 'MailIntegration.CREATE_FORTUNE_WHEEL_INTEGRATION_FAILURE',

  CREATE_COUPON_BOX_INTEGRATION_REQUEST = 'MailIntegration.CREATE_COUPON_BOX_INTEGRATION_REQUEST',
  CREATE_COUPON_BOX_INTEGRATION_SUCCESS = 'MailIntegration.CREATE_COUPON_BOX_INTEGRATION_SUCCESS',
  CREATE_COUPON_BOX_INTEGRATION_FAILURE = 'MailIntegration.CREATE_COUPON_BOX_INTEGRATION_FAILURE',

  CREATE_NEWSLETTER_INTEGRATION_REQUEST = 'MailIntegration.CREATE_NEWSLETTER_INTEGRATION_REQUEST',
  CREATE_NEWSLETTER_INTEGRATION_SUCCESS = 'MailIntegration.CREATE_NEWSLETTER_INTEGRATION_SUCCESS',
  CREATE_NEWSLETTER_INTEGRATION_FAILURE = 'MailIntegration.CREATE_NEWSLETTER_INTEGRATION_FAILURE',

  CREATE_MULTIPLE_INTEGRATIONS_REQUEST = 'MailIntegration.CREATE_MULTIPLE_INTEGRATIONS_REQUEST',
  CREATE_MULTIPLE_INTEGRATIONS_SUCCESS = 'MailIntegration.CREATE_MULTIPLE_INTEGRATIONS_SUCCESS',
  CREATE_MULTIPLE_INTEGRATIONS_FAILURE = 'MailIntegration.CREATE_MULTIPLE_INTEGRATIONS_FAILURE',
}
