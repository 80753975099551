import { Collection, Product } from '../../../../../shared/modules/products-selector/models/Product'
import { SMSAutomationStatusEnum } from '../../../models/sms-automation.models';
import {SMSStats} from '../../sms-broadcasts/models/broadcast';
import { RewardsType } from '../../../../coupon-codes/models/rewards-model';
import { CouponCodeType } from '../../../../coupon-codes/models/coupon-code';

export interface AutomationsResponse {
  automations: Automation[]
  total_count: number
  total_pages: number
}

export enum AutomationType {
  upsell = 'SmsAutomation::Upsell',
  winback = 'SmsAutomation::WinBack',
  birthday = 'SmsAutomation::Birthday',
  cart = 'SmsAutomation::Cart'
}

export enum DiscountType {
  percentage = 'percentage',
  fixedAmount = 'fixed_amount',
  freeShipping = 'free_shipping',
}

export enum ScheduledDayOperatorType {
  on = 'on',
  daysBefore = 'days_before',
  daysAfter = 'days_after',
}

export interface Destroyable {
  _destroy?: boolean
  _new?: boolean
}

export interface SMSAutomationUpsellGroup extends Destroyable {
  id?: string
  purchased_products?: Product[]
  purchased_collections?: Collection[]
  recommended_products?: Product[]
}

export interface SMSAutomationMessage extends Destroyable {
  id?: string
  type?: string
  text: string

  attach_media: boolean
  media_path: string
  media_source: 'custom'

  discount_enabled: boolean
  discount_free_shipping: 'all_countries' | 'united_states' | 'rest_of_world'
  discount_free_shipping_max: number
  discount_type: 'percentage' | 'fixed_amount' | 'free_shipping'
  discount_unique: boolean
  discount_unique_length: number
  discount_unique_prefix: string
  discount_value: number
}

export interface Automation {
  id: string
  name: string
  active: boolean
  type: AutomationType
  upsell_groups: SMSAutomationUpsellGroup[]
  message: SMSAutomationMessage
  created_at: string
  updated_at: string
  reward?: {
    id: string
    type: CouponCodeType,
    reward_type: RewardsType
  }
  start_at?: string
  scheduled_time?: string
  stats: SMSStats
  status: SMSAutomationStatusEnum
  archived?: boolean
}

export interface SMSUpsellAutomation extends Automation {
  repeat_product: boolean
  elay_message: boolean
  delay_message_value: number
  delay_message_unit: number
}

export interface AutomationListItem {
  created_at: string,
  name: string,
  id: string,
  type: string,
  index: number,
  active: boolean
  updated_at: string
  reward_id?: string
  schedule: string
  src?: Automation
  status?: SMSAutomationStatusEnum
}
