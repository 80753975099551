import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Observable } from 'rxjs'
import { Store, select } from '@ngrx/store'
import { StoreState } from '../../store/store.state'
import { getUserInfo } from '../../store/user/user.selectors'
import { AdminUserTokenService } from '../services/admin-user-token.service'
import { map, take } from 'rxjs/operators'
import { UserRole } from '../../shared/models/user/user.model'

@Injectable()
export class AdminGuard  {

  userRoles = UserRole

  constructor(
    private _router: Router,
    private store: Store<StoreState>,
    private adminUserTokenService: AdminUserTokenService,
  ) { }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    const isAdminAsUser = this.adminUserTokenService.getValue()
    return this.store.pipe(select(getUserInfo)).pipe(
      take(1),
      map((_next: any) => {
        if (_next.role === this.userRoles[UserRole.admin] || _next.role === this.userRoles[UserRole.support] || isAdminAsUser) {
          return true
        } else {
          this._router.navigate(['/dashboard'])
          return false
        }
      })
    )
  }

}
