import {CommonModule} from '@angular/common'
import {RouterModule} from '@angular/router'
import {NgModule} from '@angular/core'
import {MenuComponent} from './menu/menu.component'

@NgModule({
  imports: [CommonModule, RouterModule],
  declarations: [MenuComponent],
  exports: [MenuComponent],
})
export class LayoutModule {
}
