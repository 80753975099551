import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common';
import { StepsPanelComponent } from './steps-panel.component';
import { RouterModule } from '@angular/router'

import { OneIconComponent } from '../../components/one-icon/one-icon.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';



@NgModule({
  declarations: [StepsPanelComponent],
  imports: [
    CommonModule,
    RouterModule,
    MatMenuModule,
    MatTooltipModule,
    MatButtonModule,
    OneIconComponent,
  ],

  exports: [StepsPanelComponent]
})
export class StepsPanelModule { }
