import { Injectable } from '@angular/core'
import { PubSub } from './pub-sub'
import { SafeLocalStorageService } from './safe-local-storage.service'
import { ShopifyShopTokenService } from './shopify-shop-token.service'

@Injectable()
export class AdminUserTokenService extends PubSub<any> {
  public key = 'pf-admin-user'

  constructor(
    private safeLocalStorageService: SafeLocalStorageService,
    private shopifyShopTokenService: ShopifyShopTokenService,
  ) {
    super(null)
  }

  getValue() {
    const subjData = this.get()
    const lsData = this.safeLocalStorageService.getItem(this.key) ? JSON.parse(this.safeLocalStorageService.getItem(this.key)) : null
    if (!subjData && lsData) {
      this.set(lsData)
    }
    return this.get()
  }

  setValue(data) {
    if (data) {
      this.safeLocalStorageService.setItem(this.key, JSON.stringify(data))
      this.set(data)
    } else {
      this.safeLocalStorageService.removeItem(this.key)
      this.set(null)
    }
  }

  logOutAsUser() {
    this.shopifyShopTokenService.clear()
    // if admin was already browsing as another user do refresh and clear LS
    const selectedUser = this.getValue()
    if (selectedUser) {
      this.setValue(null)
      this.safeLocalStorageService.removeItem('one-override-uninstalled-behavior')
      const adminPage = new URL(document.baseURI)?.href + 'admin'
      window.location.href = adminPage
    }
  }
}
