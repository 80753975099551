export interface DisplayRule {
  id?: string,
  _destroy?: boolean,
  type: DisplayRuleType | string,
  match_type: DisplayMatchType,
  extra_select?: DisplayMatchType,
  match_input: string,
  created_at?: string,
}

export enum DisplayMatchType {
  AllPages = 'all_pages',
  ProductPages = 'product_pages',
  Contains = 'contains',
  ExactMatch = 'exact_match',
  HomePage = 'home_page',
  PasswordPage = 'password_page',
  ThankYouPage = 'thank_you_page',
  OrderStatusPage = 'order_status_page',
  CheckoutPage = 'checkout_first_page',
  CheckoutShippingPage = 'checkout_shipping_page',
  CheckoutPaymentPage = 'checkout_payment_page',
  _custom = '_custom',
}

export enum DisplayRuleType {
  Whitelist = 'whitelist',
  Blacklist = 'blacklist',
}
