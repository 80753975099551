import {
  Directive,
  HostListener,
  ElementRef,
  Input
} from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { fromEvent } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';

@Directive({
    selector: '[pfScrollInvalidInput]',
    standalone: true
})
export class ScrollInvalidInputDirective {
  // if scrollContainer is not passed it will default to window
  @Input() scrollContainer
  // optional offset to support different type of labels
  @Input() labelOffset = null

  constructor(
    private el: ElementRef,
    private formGroupDir: FormGroupDirective
  ) {
  }

  @HostListener('scrollToInvalid', ['$event']) scrollToInvalid() {
    if (this.formGroupDir.control.invalid) {
      this.scrollToFirstInvalidControl();
    }
  }

  // for cases where we want to scroll to non reactive form inputs
  @HostListener('scrollToInvalidForce', ['$event']) scrollToInvalidForce() {
    this.scrollToFirstInvalidControl();
  }

  private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
      '.ng-invalid'
    )
    if (!firstInvalidControl) {
      return
    }

    const scrollContainerRef = document.querySelector(this.scrollContainer) || window
    const labelOffset = this.labelOffset || 50
    const boundingRect = firstInvalidControl.getBoundingClientRect()

    // if invalid element is already in viewport don't scroll
    if (boundingRect.top >= 0 && boundingRect.bottom <= window.innerHeight) {
      firstInvalidControl.focus()
      return
    }

    scrollContainerRef.scroll({
      top: firstInvalidControl.getBoundingClientRect().top + window.scrollY - labelOffset,
      left: 0,
      behavior: 'smooth'
    })

    fromEvent(scrollContainerRef, 'scroll')
      .pipe(
        debounceTime(100),
        take(1)
      )
      .subscribe(() => firstInvalidControl.focus())
  }
}
