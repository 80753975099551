import { NgModule } from '@angular/core'
import { AddressComponent } from './address.component'
import { CountriesService } from '../../../core/services/countries.service'
import { AddressService } from './address.service'
import { ApiUserService } from '../../../core/services/api/api-user.service'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { SimpleLoadingOverlayComponent } from '../../components/simple-loading-overlay/simple-loading-overlay.component'



@NgModule({
  declarations: [AddressComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SimpleLoadingOverlayComponent,
  ],
  providers: [CountriesService, ApiUserService, AddressService],
  exports: [AddressComponent]
})
export class AddressModule { }
