const pt_BR = {
  Quantity: 'Quantidade',
  Size: 'Tamanho',
  Color: 'Cor',
  Material: 'Material',
  Style: 'Estilo',
  Savings: 'Desconto',
  Subtotal: 'Subtotal',
  Shipping: 'Envio',
  Taxes: 'Impostos',
  Total: 'Total',
  Free: 'Grátis',
  Buy: 'Comprar',
  'Pay Now': 'Pague Agora',
  'Take the Deal': 'Faça o acordo',
  'Add Now': 'Adicione Agora',
  'You will be charged immediately': 'Você será cobrado imediatamente',
  'Decline Offer': 'Recusar oferta',
  'No Thanks': 'Não, Obrigado',
  '{price}': '{Preço}',
  'Sold Out': 'Vendido',
  'see more': 'ver mais',
  less: 'menos',
};
export {pt_BR};
