import 'zone.js'
import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import { AppModule } from './app/app.module'
import { environment } from './environments/environment'

if (environment.production) {
  enableProdMode()
}

console.log('APP', environment.shopify.clientId, 'IS EMBEDDED', environment.shopify.embedded)

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => {
    console.error(err)
  })

const script: HTMLScriptElement = document.createElement('script')
script.setAttribute('id', 'widget-script')
script.async = true
script.setAttribute('src', `${environment.widgetPreviewURL}?timestamp=${+new Date()}`)
script.setAttribute('data-source', 'dashboard')
document.body.appendChild(script)
