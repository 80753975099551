import { Integration, MailIntegration, MailIntegrationList } from "../../shared/models/mail-integration.model"


export interface MailIntegrationState {
  list: Integration[]
  selectedItem: MailIntegration
  selectedItemLists: MailIntegrationList[]
  error: string
}

export const initialMailIntegrationState: MailIntegrationState = {
  list: [],
  selectedItem: null,
  selectedItemLists: null,
  error: null,
}
