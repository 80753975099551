import { MGPathModel } from '../models/media-gallery-models'

export const MGPath: MGPathModel = {
  $region: ((region: string) => {
    return {
      url: region,
      metadata: {
        content_region: region
      }
    }
  }) as any,
  $accountId: ((id: string) => {
    return {
      url: id,
      metadata: {
        content_id: id
      }
    }
  }) as any,
  // MARK: content_type
  accounts: {
    url: 'accounts',
    metadata: {
      content_type: 'account'
    },
  },
  templates: {
    url: 'templates',
    metadata: {
      content_type: 'template'
    },
  },
  // MARK: content_category
  assets: {
    url: 'assets',
    metadata: {
      content_category: 'asset'
    },
  },
  // MARK: asset_type
  images: {
    url: 'images',
    metadata: {
      asset_type: 'image'
    },
  },
  videos: {
    url: 'videos',
    metadata: {
      asset_type: 'video'
    },
  },
  icons: {
    url: 'icons',
    metadata: {
      asset_type: 'icon'
    },
  },
  fonts: {
    url: 'fonts',
    metadata: {
      asset_type: 'font'
    },
  },
  documents: {
    url: 'documents',
    metadata: {
      asset_type: 'document'
    },
  },
  // MARK: asset_category
  logos: {
    url: 'logos',
    metadata: {
      asset_category: 'logo'
    },
  },
  campaigns: {
    url: 'campaigns',
    metadata: {
      asset_category: 'campaign'
    },
  },
  // MARK: product_type
  pop_ups: {
    url: 'pop-ups',
    metadata: {
      product_type: 'pop-up'
    },
  },
  messaging: {
    url: 'messaging',
    metadata: {
      product_type: 'messaging'
    },
  },
  // MARK: product_category
  subscribe_forms: {
    url: 'subscribe-forms',
    metadata: {
      product_category: 'subscribe-form'
    },
  },
  announcements: {
    url: 'announcements',
    metadata: {
      product_category: 'announcement'
    },
  },
  emails: {
    url: 'emails',
    metadata: {
      product_category: 'email'
    },
  },
  sms: {
    url: 'sms',
    metadata: {
      product_category: 'sms'
    },
  },
  // MARK: product_style
  gamified: {
    url: 'gamified',
    metadata: {
      product_style: 'gamified'
    },
  },
  traditional: {
    url: 'traditional',
    metadata: {
      product_style: 'traditional'
    },
  },
  broadcasts: {
    url: 'broadcasts',
    metadata: {
      product_style: 'broadcast'
    },
  },
  automations: {
    url: 'automations',
    metadata: {
      product_style: 'automation'
    },
  },
  // MARK: product_variant
  spin_wheel: {
    url: 'spin-wheel',
    metadata: {
      product_variant: 'spin-wheel'
    },
  },
  reward: {
    url: 'reward',
    metadata: {
      product_variant: 'reward'
    },
  },
  no_reward: {
    url: 'no-reward',
    metadata: {
      product_variant: 'no-reward'
    },
  },
  newsletter: {
    url: 'newsletter',
    metadata: {
      product_variant: 'newsletter'
    },
  },
  redeem_reward_reminder: {
    url: 'redeem-reward-reminder',
    metadata: {
      product_variant: 'redeem-reward-reminder'
    },
  },
  double_opt_in_request: {
    url: 'double-opt-in-request',
    metadata: {
      product_variant: 'double-opt-in-request'
    },
  },
  opt_in_confirmation: {
    url: 'opt-in-confirmation',
    metadata: {
      product_variant: 'opt-in-confirmation'
    },
  },
  abandoned_cart_reminder: {
    url: 'abandoned-cart-reminder',
    metadata: {
      product_variant: 'abandoned-cart-reminder'
    },
  },
  welcome_email: {
    url: 'welcome-email',
    metadata: {
      product_variant: 'welcome-email'
    },
  },
  upsell_email: {
    url: 'upsell-email',
    metadata: {
      product_variant: 'upsell-email'
    },
  },
  winback_email: {
    url: 'winback-email',
    metadata: {
      product_variant: 'winback-email'
    },
  },
  // MARK: theme_element
  game_block_wheel: {
    url: 'game-block-wheel',
    metadata: {
      theme_element: 'game-block-wheel'
    },
  },
  game_block_pointer: {
    url: 'game-block-pointer',
    metadata: {
      theme_element: 'game-block-pointer'
    },
  },
  game_block_logo: {
    url: 'game-block-logo',
    metadata: {
      theme_element: 'game-block-logo'
    },
  },
  teaser_block_background: {
    url: 'teaser-block-background',
    metadata: {
      theme_element: 'teaser-block-background'
    },
  },
  logo_block: {
    url: 'logo-block',
    metadata: {
      theme_element: 'logo-block'
    },
  },
  product_block: {
    url: 'product-block',
    metadata: {
      theme_element: 'product-block'
    },
  },
  teaser_block_icon: {
    url: 'teaser-block-icon',
    metadata: {
      theme_element: 'teaser-block-icon'
    },
  },
  background_block: {
    url: 'background-block',
    metadata: {
      theme_element: 'background-block'
    },
  },
  annotations_block_product: {
    url: 'annotations-block-product',
    metadata: {
      theme_element: 'annotations-block-product'
    },
  },
  image_block: {
    url: 'image-block',
    metadata: {
      theme_element: 'image-block'
    },
  },
}
