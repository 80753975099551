import { TutorialId } from "../services/tutorials.service"

export interface TutorialLibrary {
  playlists: TutorialPlaylist[]
}

export interface TutorialPlaylist {
  title: string
  id: TutorialId
  videos: TutorialVideoItem[]
  thumbNailSrc?: string
}

export interface TutorialVideoItem {
  id: string,
  title: string,
  thumbNailSrc?: string
  index?: number
  eventName?: string // name that being saved in segment
  parentID?: string // being added by getVideoById
  cloudflare?: {
    id?: string
    link?: string
  }
}

export enum TutorialsActionName {
  triggerModal = 'trigger_modal',
  triggerButton = 'trigger_button',
  video = 'video',
  closeModal = 'close_modal',
  blogPost = 'blog_post',
  closeVideoGallery = 'close_video_gallery',
}

export enum TutorialsActionType {
  click = 'click',
  automaticTrigger = 'automatic_trigger',
  videoWatched = 'video_watched',
}

export interface TutorialsEvent {
  metadata: {
    impression?: number,
    video_id?: string,
    blog_url?: string,
    watch_time?: number,
  },
  state: {
    page: string,
  },
  action: {
    name: TutorialsActionName,
    type: TutorialsActionType,
  },
  created_at?: string,
}
