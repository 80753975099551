import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { Step } from '../../../shared/modules/steps-panel/interfaces/step.interface'
import { UpsellStatus } from '../models/upsell-page.models'

@Injectable({
  providedIn: 'root'
})
export class UpsellBuilderStepsService {
  steps$ = new BehaviorSubject<Step[] | null>(null)
  status$ = new BehaviorSubject<UpsellStatus>(UpsellStatus.Off)

  
}
