const es = {
  Quantity: 'Cantidad',
  Size: 'Tamaño',
  Color: 'Color',
  Material: 'Material',
  Style: 'Estilo',
  Savings: 'Ahorrado',
  Subtotal: 'Subtotales',
  Shipping: 'Envío',
  Taxes: 'Impuestos',
  Total: 'Total',
  Free: 'Gratis',
  Buy: 'Comprar',
  'Pay Now': 'Pagar Ahora',
  'Take the Deal': 'Acepta el Trato',
  'Add Now': 'Agregar Ahora',
  'You will be charged immediately': 'Se te cobrará inmediatamente',
  'Decline Offer': 'Rechazar Oferta',
  'No Thanks': 'No, Gracias',
  '{price}': '{precio}',
  'Sold Out': 'Vendido',
  'see more': 'ver mas',
  less: 'menos',
};
export {es};
