import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { EMPTY, Observable, catchError, forkJoin, map, of, switchMap, concatMap } from 'rxjs'
import { StripeResponse, StripeProduct, StripePrice, StripeCustomer, StripeSubscription, StripePriceExpanded, StripePromotion } from '../models/cart-models'
import { ApiService } from '../../../core/services/api/api.service'
import { InteractionsKey } from '../../../shared/models/interactions.model'
import { PlansV3Identifier } from '../../../shared/models/subscription-plan.model'
import { ApiPaymentService } from '../../../core/services/api/api-payment.service'
import { ProductPageConfig } from './products-page-config'


@Injectable({providedIn: 'root'})
export class CartApiService {
  private _sk = new ProductPageConfig().sk
  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private apiPaymentService: ApiPaymentService,
  ) { }

  private stripeGet<T>(url: string): Observable<T> {
    return this.http.get<T>(url, {
      headers: {
        Authorization: `Bearer ${this._sk}`
      }
    })
  }

  private stripePost<T>(url: string, payload: any): Observable<T> {
    const body = this._urlEncode(payload)
    return this.http.post<T>(url, body, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${this._sk}`
      }
    })
  }

  getStripeProducts(): Observable<StripeResponse<StripeProduct>> {
    return this.stripeGet<StripeResponse<StripeProduct>>('https://api.stripe.com/v1/products?limit=100')
  }

  getStripeProductById(id: string): Observable<StripeProduct> {
    return this.stripeGet<StripeProduct>(`https://api.stripe.com/v1/products/${id}`)
  }

  getStripePrices(): Observable<StripeResponse<StripePrice>> {
    return this.stripeGet<StripeResponse<StripePrice>>('https://api.stripe.com/v1/prices?limit=100')
  }

  getStripePrice(priceId: string): Observable<StripePrice> {
    return this.stripeGet<StripePrice>(`https://api.stripe.com/v1/prices/${priceId}`)
  }

  createSession(payload: any): Observable<any> {
    return this.stripePost('https://api.stripe.com/v1/checkout/sessions', payload)
  }

  updateSubscription(subscriptionId: string, payload: any): Observable<any> {
    return this.stripePost(`https://api.stripe.com/v1/subscriptions/${subscriptionId}`, payload)
  }

  updateSubscriptionItems(payload: any): Observable<any> {
    return this.stripePost(`https://api.stripe.com/v1/subscription_items`, payload)
  }

  createBillingPortalSession(payload: any): Observable<any> {
    return this.stripePost('https://api.stripe.com/v1/billing_portal/sessions', payload)
  }

  findStripePromotionCode(code: string): Observable<StripeResponse<StripePromotion>> {
    return this.stripeGet(`https://api.stripe.com/v1/promotion_codes?code=${code}`)
  }

  getSubscriptionsByCustomerId(customerId: string): Observable<StripeResponse<StripeSubscription>> {
    return this.stripeGet<StripeResponse<StripeSubscription>>(
      `https://api.stripe.com/v1/subscriptions?customer=${customerId}`
    )
    // .pipe(
    //   switchMap((res) => {
    //     let priceRequests = res.data.map((sub) => {
    //       return this.getExpandedProductWithPrice(sub.items.data[0].price.id)
    //     })
    //     return forkJoin(priceRequests).pipe(
    //       map((prices) => {
    //         res?.data?.forEach((sub, index) => {
    //           sub.items.data.forEach((item) => {
    //             const price = prices.find((p) => p.id === item.price.id)
    //             if (price) {
    //               item['price'] = price
    //             }
    //           })
    //         })
    //         return res
    //       }),
    //       catchError((err) => {
    //         return EMPTY
    //       })
    //     )
    //   }),
    //   catchError((err) => {
    //     return EMPTY
    //   })
    // )
  }

  createCustomer(payload: any): Observable<StripeCustomer> {
    return this.stripePost('https://api.stripe.com/v1/customers', payload)
  }

  getCustomerByEmail(email: string): Observable<StripeResponse<StripeCustomer>> {
    return this.stripeGet<StripeResponse<StripeCustomer>>(`https://api.stripe.com/v1/customers?email=${email}`)
  }

  getExpandedProductWithPrice(priceId: string): Observable<StripePriceExpanded> {
    return this.stripeGet<StripePriceExpanded>(
      `https://api.stripe.com/v1/prices/${priceId}?expand[]=product&expand[]=tiers`
    ).pipe(map((res) => {
      res?.tiers?.forEach((tier) => {
        if (!tier?.unit_amount && tier.unit_amount_decimal) {
          tier.unit_amount = +tier.unit_amount_decimal
        }
      })
      return res
    }))
  }

  getProductsWithPrices() {
    // Get products
    return this.getStripeProducts().pipe(
      switchMap((products) => {
        // Get prices for each product
        const priceRequests = products['data'].map((product) => {
          return this.getStripePrice(product.default_price)
        })
        return forkJoin(priceRequests).pipe(
          map((prices) => {
            products['data'].forEach((product, index) => {
              product['price'] = prices[index]
            })
            return products
          })
        )
      })
    )
  }

  getSubscriptions(email: string): Observable<StripeResponse<StripeSubscription>> {
    return this.getCustomerByEmail(email).pipe(
      switchMap((res) => {
        const customerId = res.data[0]?.id
        if (customerId) {
          return this.getSubscriptionsByCustomerId(customerId)
        } else {
          return of({data: [], has_more: false, url: '', object: ''})
        }
      }),
      catchError((err) => {
        return EMPTY
      })
    )
  }

  writeStripeInteraction(data: any): Observable<any> {
    return this.apiService.patch(`/v1/me/`, {
      interactions: {
        [InteractionsKey.Stripe]: data
      }
    })
  }

  setFreeStripePlan() {
    return this.apiPaymentService.putSubscriptions(
      PlansV3Identifier.NewCustomFree, 
      null, 
      `${window.location.href}/checkout/success?free=1&payment_gateway=stripe`, 
      null
    )
  }

  private _urlEncode(data: any): string {
    const encodedParams = new URLSearchParams();

    const isEmail = (value: any): boolean => {
      // Add your own email pattern check here if needed
      return typeof value === 'string' && value.includes('@');
    };

    const processObject = (obj: any, parentKey?: string) => {
      Object.keys(obj).forEach((key) => {
        const value = obj[key];
        const currentKey = parentKey ? `${parentKey}[${encodeURIComponent(key)}]` : encodeURIComponent(key);

        if (value instanceof Object) {
          // Recursively process nested objects
          processObject(value, currentKey);
        } else if (value instanceof Array) {
          // Handle arrays
          value.forEach((item, index) => {
            encodedParams.append(`${currentKey}[${index}]`, item);
          });
        } else if (isEmail(value)) {
          // Don't encode emails
          encodedParams.append(currentKey, value);
        } else if (typeof value === 'string' && value.startsWith('http')) {
          // Don't encode URLs
          encodedParams.append(currentKey, value);
        } else {
          // Handle simple key-value pairs
          encodedParams.append(currentKey, value);
        }
      });
    };

    processObject(data);

    return encodedParams.toString();
  }
  
}
