<!-- <div class="youtube-video-preloader" *ngIf="!videoLoaded">
  <pf-loading-spinner></pf-loading-spinner>
</div> -->

<ng-container *ngIf="showCloudflarePlayer; else youTubePlayer">
  <cloudflare-stream
    [autoplay]="true"
    (play)="onVideoUpdated($event, this.videoId)"
    (seeked)="onVideoUpdated($event, this.videoId)"
    (pause)="onVideoUpdated($event, this.videoId)"
    (loadstart)="onVideoLoadStarted()"
    [controls]="true"
    [src]="this.videoId"
  ></cloudflare-stream>
</ng-container>

<ng-template #youTubePlayer>
  <div class="youtube-video-container">
    <div id="youtube-video"></div>
  </div>
</ng-template>