import { AfterViewInit, Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core'
import { SegmentAnalyticsService } from '../../../services/segment-analytics.service'
import moment from 'moment'
import { UserService } from '../../../../core/services/user.service'
import { ShopifyService } from '../../../../core/services/shopify.service'
import { Subscription } from 'rxjs'
import { CommonModule } from '@angular/common'
import { OneTooltipDirective } from '../../../directives/one-tooltip.directive'
import { PluralPipe } from '../../../pipes/plural.pipe'
import { LoadingOverlayComponent } from '../../loading-overlay/loading-overlay.component'
import { ActiveCampaignDropdownComponent } from '../../one-header/components/active-campaign-dropdown/active-campaign-dropdown.component'
import { CrispService } from '../../../../core/services/crisp.service'
import { MatTooltipModule } from '@angular/material/tooltip'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

export enum CustomPricePlanModalType {
  requestPriceReminder = 'requestPriceReminder',
  invoiceReminder = 'invoiceReminder',
  askForUpdate = 'askForUpdate',
}

@Component({
    templateUrl: './custom-price-plan-reminder-modal.component.html',
    styleUrls: ['./custom-price-plan-reminder-modal.component.scss'],
    standalone: true,
    imports: [
      CommonModule,
      OneTooltipDirective,
      ActiveCampaignDropdownComponent,
      PluralPipe,
      LoadingOverlayComponent,
      MatTooltipModule
    ]
})

export class CustomPricePlanReminderModal implements OnInit, AfterViewInit, OnDestroy {
  subscription$ = new Subscription()
  resizeTimeout: any
  popup: HTMLElement
  pHeight = 0
  modalType = CustomPricePlanModalType
  @HostListener('window:resize', ['$event']) onResize(event: any) {
    this.adjustScale()
  }

  price
  dueDate = null
  dueDateShort = null
  passedDeadline = false
  daysLeft = 0
  requestCreatedHoursAgo = null
  expectReplyBy = null
  replyTimeLeft = null
  customPlanRequestCreateDate = null
  crispMessage = {
    [CustomPricePlanModalType.askForUpdate]: `Hello ONE Team,\n\n
                                                  Could you please provide an update on my custom pricing plan?\n
                                                  `
  }

  showContactsFooter = false


  constructor(
    public dialogRef: MatDialogRef<CustomPricePlanReminderModal>,
    private segmentAnalitycsService: SegmentAnalyticsService,
    private userService: UserService,
    private shopifyService: ShopifyService,
    private crispService: CrispService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {

  }

  get endDate(): string {
    switch (this.data.type) {
      case CustomPricePlanModalType.invoiceReminder:
        return this.userService.userInfo?.custom_plan_invoice_due_date
      case CustomPricePlanModalType.requestPriceReminder:
        return this.userService.userInfo?.custom_plan_trial_end_date
    }
  }

  ngOnInit() {
    // for specific shop only
    this.showContactsFooter = this.userService?.userInfo?.shop?.profile?.myshopify_domain?.includes('xtool')
    const now = moment().utc()
    const due = moment(this.endDate).utc()
    this.trackModalSeen()
    // this.userService.userInfo?.custom_plan_invoice
    // this.userService.userInfo?.custom_plan_invoice_paid
    // this.userService.userInfo?.custom_plan_invoice_link
    this.dueDate = due.format('MMMM Do YYYY')
    this.dueDateShort = due.format('MMM Do')
    this.price = this.userService.userInfo?.custom_plan_invoice_price
    this.daysLeft = due.startOf('day').diff(now.startOf('day'), 'days')
    this.passedDeadline = this.data.type === CustomPricePlanModalType.invoiceReminder && this.daysLeft < 0

    if (this.userService?.userInfo?.custom_plan_price_request_created_at) {
      const requestCreated = this.userService?.userInfo?.custom_plan_price_request_created_at
      const requestDate = moment(requestCreated)
      const replyDue =  moment(requestCreated).add(24, 'hours')
      this.customPlanRequestCreateDate = requestDate.format('MMM Do, h:mm a')
      this.requestCreatedHoursAgo = moment().startOf('hour').diff(requestDate.startOf('hour'), 'hours')
      this.expectReplyBy = replyDue.format('MMM Do, h:mm a')
      this.replyTimeLeft = replyDue.startOf('hour').diff(moment().startOf('hour'), 'hours')
    }
  }

  ngAfterViewInit() {
    this.popup = document.body.querySelector(`#${this.dialogRef.id}`) as HTMLElement
    this.pHeight = this.popup?.getBoundingClientRect()?.height
    setTimeout(() => {
      this.adjustScale()
    }, 500)
  }

  payInvoice() {
    this.segmentAnalitycsService.track('Clicked Pay Now - Invoice Reminder')
    window.open(this.userService.userInfo?.custom_plan_invoice_link, '_blank')
  }

  trackModalSeen() {
    switch (this.data.type) {
      case CustomPricePlanModalType.invoiceReminder:
        this.segmentAnalitycsService.track('Viewed Modal - Invoice Reminder')
        break
      case CustomPricePlanModalType.requestPriceReminder:
        this.segmentAnalitycsService.track('Viewed Modal - Request Price Reminder')
    }
  }

  contactSupport(event: any) {
    event.preventDefault()
    const crisp = this.crispService.getCrisp()
    if (crisp) {
      crisp.push(['do', 'chat:open'])
      if (this.crispMessage[this.data.type]) {
        crisp.push(['do', 'message:send', ['text', this.crispMessage[this.data.type]]])
      }
    }
  }

  adjustScale() {
    if (!this.resizeTimeout) {
      this.resizeTimeout = setTimeout(() => {
        this.resizeTimeout = null
        if (this.popup) {
          if (this.pHeight > window.innerHeight) {
            const isMobile = window.innerWidth < 768
            let verticalSpacing = isMobile ? 0 : 50
            if (isMobile && this.shopifyService.isEmbedded) {
              verticalSpacing += 45
            }
            this.popup.style.transform = `scale(${window.innerHeight / (this.pHeight + verticalSpacing)})`
            this.popup.style.transformOrigin = 'top center'
            if (!isMobile) {
              this.popup.parentElement.style.padding = '20px 0'
            }
          } else {
            this.popup.style.transform = `none`
            this.popup.parentElement.style.padding = '0'
          }
        }
      }, 200)
    }
  }

  openCalendlyWidget() {
    this.dialogRef.close()
    if (window && window['Calendly'] && window['Calendly'].initPopupWidget) {
      const userInfo = this.userService?.userInfo
      const name = userInfo?.shop_user?.shopify_shop_user?.first_name ? `${userInfo?.shop_user?.shopify_shop_user?.first_name} ${userInfo?.shop_user?.shopify_shop_user?.last_name || ''}` : userInfo?.profile?.first_name ? `${userInfo?.profile?.first_name} ${userInfo?.profile?.last_name || ''}` : ''
      const email = userInfo?.shop_user?.shopify_shop_user?.email || userInfo?.email || ''
      const phone = userInfo?.shop_user?.shopify_shop_user?.phone || ''
      const storeUrl = userInfo?.shop?.store_url  || ''
      window['Calendly'].initPopupWidget({
        url: 'https://calendly.com/one-csm',
        prefill: {
          name: name,
          email: email,
          customAnswers: {
            a1: phone,
            a2: storeUrl,
          }
        },
      })
    }
  }


  close(event) {
    event.preventDefault()
    this.segmentAnalitycsService.track('Clicked No Thanks - Invoice Reminder')
    this.dialogRef.close()
  }

  ngOnDestroy() {
    this.subscription$.unsubscribe()
  }
}
