import { CampaignState, initialCampaignState } from './campaign/campaign.state'
import { BillingState, initialBillingState } from './billing/billing.state'
import { initialMailIntegrationState, MailIntegrationState } from './mail-integration/mail-integration.state'
import { initialLoadingState, LoadingState } from './loading/loading.state'
import { initialUserState, UserState } from './user/user.state'
import { GettingStartedState, initialGettingStartedState } from './getting-started/getting-started.state'

export interface StoreState {
  readonly campaign: CampaignState
  readonly loading: LoadingState
  readonly billing: BillingState
  readonly user: UserState
  readonly mailIntegration: MailIntegrationState
  readonly gettingStarted: GettingStartedState
}

export const initialStoreState: StoreState = {
  campaign: initialCampaignState,
  loading: initialLoadingState,
  billing: initialBillingState,
  user: initialUserState,
  mailIntegration: initialMailIntegrationState,
  gettingStarted: initialGettingStartedState,
}
