import { Overlay, OverlayRef, FlexibleConnectedPositionStrategy, ConnectionPositionPair, GlobalPositionStrategy } from '@angular/cdk/overlay'
import { ComponentPortal } from '@angular/cdk/portal'
import { Component, ElementRef, EventEmitter, HostListener, Injector, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core'
import { DateRange } from '@angular/material/datepicker'
import { Subscription } from 'rxjs'
import { DateRangeLabel, MatDatepickerOutput } from '../../models/mat-daterange.model'
import { MAT_DATERANGE_OVERLAY_REF } from '../../services/mat-daterange-overlay-token'
import { MatDaterangeService } from '../../services/mat-daterange.service'
import { MatDaterangePickerComponent } from '../mat-daterange-picker/mat-daterange-picker.component'

@Component({
  selector: 'pf-mat-daterange',
  templateUrl: './mat-daterange.component.html',
  styleUrls: ['./mat-daterange.component.scss']
})
export class MatDaterangeComponent implements OnInit, OnDestroy {
  @ViewChild('dateRange', {static: true}) dateRange: ElementRef
  @Input() defaultDateRange: DateRangeLabel = DateRangeLabel.Last7Days
  @Input() includeAllTime = true
  @Output() onSelectedRange: EventEmitter<MatDatepickerOutput> = new EventEmitter<MatDatepickerOutput>()
  @HostListener('window:resize', ['$event']) onResize() {
    this.updateOverlaySize()
  }
  overlayRef: OverlayRef
  datepickerRef: ComponentPortal<MatDaterangePickerComponent>
  label: string
  selectedRange: DateRange<moment.Moment>
  rangeInitialized = false
  dropdownMobileSizeSet = false
  private subscription = new Subscription()
  debounceTimeout: any

  constructor(
    private overlay: Overlay,
    private matDaterangeService: MatDaterangeService,
  ) { }

  ngOnInit(): void {
    this.matDaterangeService.includeAllTime$.next(this.includeAllTime)
    this.matDaterangeService.selectedRangeLabelLocal$.next(this.defaultDateRange)
    this.matDaterangeService.selectedRangeLabel$.next(this.defaultDateRange)
    if (this.includeAllTime && this.defaultDateRange === DateRangeLabel.AllTime) {
      this.matDaterangeService.isAllTime$.next(true)
    }

    this.initOverlay()

    this.subscription.add(
      this.matDaterangeService.selectedRange$.asObservable().subscribe((range) => {
        this.selectedRange = range
        if (range && this.rangeInitialized) {
          this.onSelectedRange.emit({
            label: this.matDaterangeService.selectedRangeLabelLocal$.value,
            start: range.start,
            end: range.end,
            start_date: range.start.format('YYYY-MM-DD'),
            end_date: range.end.format('YYYY-MM-DD'),
            isAllTime: this.matDaterangeService.selectedRangeLabelLocal$.value === DateRangeLabel.AllTime,
            includes_today: this.matDaterangeService.getIncludeToday(),
          })
        }
        if (range) {
          this.rangeInitialized = true
        }
      })
    )

    this.subscription.add(
      this.matDaterangeService.selectedRangeLabel$.asObservable().subscribe((label) => {
        this.label = label
      })
    )

    this.setInitialDateRange()
  }

  initOverlay() {
    this.overlayRef = this.overlay.create({
      positionStrategy: this.getPositionStrategy(),
      scrollStrategy: this.overlay.scrollStrategies.close(),
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      maxWidth: '626px',
    })

    // Creating injector to access an overlay ref in the MatDaterangePickerComponent
    const injector = Injector.create({
      providers: [{ provide: MAT_DATERANGE_OVERLAY_REF, useValue: this.overlayRef }],
    })
    this.datepickerRef = new ComponentPortal(MatDaterangePickerComponent, undefined, injector)

    this.subscription.add(
      this.overlayRef.backdropClick().subscribe(() => {
        this.overlayRef.detach()
      })
    )

    // reset the overlay size when closing the overlay
    this.subscription.add(
      this.overlayRef.detachments().subscribe(() => {
        this.overlayRef.updateSize({ width: 'auto', height: 'auto' })
      })
    )
  }

  setInitialDateRange() {
    this.label = this.defaultDateRange
    const range = this.matDaterangeService.getDateRangeByLabel(this.defaultDateRange, true)
    if (range) {
      this.matDaterangeService.selectedRange$.next(range)
      this.matDaterangeService.selectedRangeLabel$.next(this.defaultDateRange)
    }
  }

  updateOverlaySize() {
    clearTimeout(this.debounceTimeout)
    this.debounceTimeout = setTimeout(() => {
      // if the overlay is not open, don't update the size
      if(!this.overlayRef.hasAttached()) {
        return
      }
      // 450px is also set in the css media query, update it there as well if you change it here
      if (window.innerWidth <= 450) {
        this.dropdownMobileSizeSet = true
        this.overlayRef.updateSize({
          width: '100vw',
          height: '100vh'
        })
        this.overlayRef.updatePositionStrategy(this.getPositionStrategyMobile())
        this.overlayRef.updateScrollStrategy(this.overlay.scrollStrategies.block())
      } else if (this.dropdownMobileSizeSet) {
        this.dropdownMobileSizeSet = false
        this.overlayRef.updateSize({
          width: '626px',
          height: 'auto'
        })
        this.overlayRef.updatePositionStrategy(this.getPositionStrategy())
        this.overlayRef.updateScrollStrategy(this.overlay.scrollStrategies.close())
      }
    }, 100)
  }

  getPositionStrategy(): FlexibleConnectedPositionStrategy {
    const positions = [
      new ConnectionPositionPair({originX: 'start', originY: 'bottom'}, {overlayX: 'start', overlayY: 'top'}),
      new ConnectionPositionPair({originX: 'start', originY: 'top'}, {overlayX: 'start', overlayY: 'bottom'}),
    ]
    return this.overlay.position().flexibleConnectedTo(this.dateRange.nativeElement)
      .withPositions(positions)
      .withFlexibleDimensions(false)
      .withDefaultOffsetY(10)
      .withPush(false)
  }

  getPositionStrategyMobile(): GlobalPositionStrategy {
    const positions = [
      new ConnectionPositionPair({originX: 'start', originY: 'bottom'}, {overlayX: 'start', overlayY: 'top'}),
      new ConnectionPositionPair({originX: 'start', originY: 'top'}, {overlayX: 'start', overlayY: 'bottom'}),
    ]
    return this.overlay.position().global().top('0').left('0')
  }

  outputClick() {
    if (this.overlayRef.hasAttached()) {
      this.overlayRef.detach()
    } else {
      this.updateOverlaySize()
      this.overlayRef.attach(this.datepickerRef)
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }
}
