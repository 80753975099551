import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Observable, finalize, tap, Observer, from, lastValueFrom, switchMap, iif, map, of, forkJoin, catchError, combineLatest } from 'rxjs'
import { UserService } from '../../../../core/services/user.service'
import { MediaGalleryModalComponent } from '../components/media-gallery-modal/media-gallery-modal.component'
import moment from 'moment'
import { AngularFireStorage } from '@angular/fire/compat/storage'
import { Store } from '@ngrx/store'
import { StoreState } from '../../../../store/store.state'
import { HideLoading, ShowLoading } from '../../../../store/loading/loading.actions'
import { UploadMetadata, UploadTaskSnapshot } from '@angular/fire/compat/storage/interfaces'
import { ImageFits } from '../../../../core/services/file-size.service'
import { MGBucket, MGPathData, MgImage, MediaGalleryModalData } from '../models/media-gallery-models'
import { MGPath } from './MGPath'
import { MgStorageService } from './media-gallery-storage.service'
import { AngularFireAuth } from '@angular/fire/compat/auth'
import { HttpClient, HttpHeaders } from '@angular/common/http'

@Injectable({providedIn: 'root'})
export class MgUploadService {

  public basePath: MGPathData[] = [
    (MGPath as any).$region('us'),
    MGPath.accounts,
    (MGPath as any).$accountId(this.userService?.userInfo?.id),
    MGPath.assets,
    MGPath.images
  ]
  private bucketsStack: MGBucket[] = this.mgStorageService.getBuckets()
  private iframeRef: HTMLIFrameElement
  onIframeMessageHandler: (message) => void

  constructor(
    private userService: UserService,
    private store: Store<StoreState>,
    private dialog: MatDialog,
    private angularFireStorage: AngularFireStorage,
    private afAuth: AngularFireAuth,
    private http: HttpClient,
    private mgStorageService: MgStorageService,
  ) { }

  /**
   * Open image upload modal
   * @param path - MGPath array representing a path to upload image
   * @param currentImage - optional image url to display as selected
   * @param namePrefix - optional prefix for the image name (campaign id)
   * @returns Observable<string>
   * @memberof MgUploadService
   * */
  openMGModal(path: MGPathData[], currentImage?: string, namePrefix?: string): Observable<string> {
    const dialogRef = this.dialog.open(MediaGalleryModalComponent, {
      width: '100%',
      maxWidth: '90vw',
      height: '100%',
      maxHeight: '90vh',
      data: {
        path: path ?? this.basePath,
        namePrefix: namePrefix,
        currentImage: currentImage,
      } as MediaGalleryModalData
    })
    return dialogRef.afterClosed()
  }

  public upload(
    activePath: string, 
    file: File, 
    metaData: UploadMetadata = {}, 
    namePrefix?: string, 
    overWrite?: boolean
  ): Observable<UploadTaskSnapshot | {error: any}> { 
    const path = overWrite ? `${activePath}/${file.name}` : `${activePath}/${this.generateFileName(file, namePrefix)}`
    const tasks = this.bucketsStack.map(bucket => this.createUploadTask(bucket, path, file, metaData))
    this.store.dispatch(new ShowLoading('MGUpload'))
    return forkJoin(
      tasks.map(task => task.observable)
    ).pipe(
      map(([task]) => {
        return task
      }),
      finalize(() => {
        tasks.forEach(task => task.deleteApp())
        this.store.dispatch(new HideLoading('MGUpload'))
      })
    )
  }

  public deleteFile(image: MgImage): Observable<any> {
    this.store.dispatch(new ShowLoading('MGDelete'))
    const tasks = this.bucketsStack.map(bucket => this.createDeleteTask(bucket, image.ref.fullPath))
    return forkJoin(
      tasks.map(task => task.observable)
    ).pipe(
      catchError(error => {
        console.error('Error deleting file from bucket', error)
        return of(null)
      }),
      finalize(() => {
        tasks.forEach(task => task.deleteApp())
        this.store.dispatch(new HideLoading('MGDelete'))
      })
    )
  }

  public validateImageFileType(uploadEvent): boolean {
    const allowedFormats = ['jpg', 'jpeg', 'png', 'gif', 'svg', 'webp']
    const fileName = uploadEvent.target.files[0].name
    const idxDot = fileName.lastIndexOf('.') + 1
    const extFile = fileName.substr(idxDot, fileName.length).toLowerCase()
    const isFileTypeValid = allowedFormats.includes(extFile)
    return isFileTypeValid
  }

  private createUploadTask(
    bucket: MGBucket, 
    path: string, 
    file: File, 
    metaData: UploadMetadata = {}
  ): { observable: Observable<UploadTaskSnapshot | { error: any }>, deleteApp: () => void } {
    const app = this.mgStorageService.getFireApp(bucket)
    return {
      observable: from(app.storage(bucket).ref(path).put(file, metaData)).pipe(catchError(error => of({ error }))),
      deleteApp: () => {} // something fails if we delete the app here so just an empty function 
    }
  }

  private createDeleteTask(bucket: MGBucket, path: string): {observable: Observable<any>, deleteApp: () => void } {
    const app = this.mgStorageService.getFireApp(bucket)
    return {
      observable: from(app.storage(bucket).ref(path).delete()),
      deleteApp: () => app.delete()
    }
  }

  public generateFileName(file, namePrefix?: string): string {
    const timestamp = moment().utc().format('YYYY-MM-DD[T]HH_mm_ss.SSS[Z]')
    const srcFileName = file.name.substring(0, file.name.lastIndexOf('.'))
    const name = namePrefix ? `${namePrefix}_${srcFileName}_${timestamp}` : `${srcFileName}_${timestamp}`
    const extension = file.name.slice(((file.name.lastIndexOf('.') - 1) >>> 0) + 2)
    return `${name}.${extension}`
  }

  async getImageSize(url: string): Promise<number> {
    const response = await fetch(url, { method: 'HEAD' })
    const size = Number(response.headers.get('Content-Length'))
    return size * 1e-6 // Convert bytes to megabytes
  }

  /**
   * @param img Uploaded file url
   * @param maxMb Maximum allowed size in Megabytes
   * @param maxPxWidth
   * @param maxPxHeight
   */
  imageSrcFits(img: string, maxMb: number, maxPxWidth: number = null, maxPxHeight: number = null): Observable<ImageFits> {
    return new Observable((observer: Observer<ImageFits>) => {
      const _img = new Image()
      _img.onload = () => {
        const height = _img.naturalHeight
        const width = _img.naturalWidth
        this.getImageSize(img).then(size => {
          const cause = {
            width: maxPxWidth ? width > maxPxWidth : false,
            height: maxPxHeight ? height > maxPxHeight : false,
            fileSize: size > maxMb
          }
          const fits = !cause.fileSize && !cause.width && !cause.height
          let message = ''
          if (cause.fileSize) {
            message = 'Uploaded image is too big, it may slow down the speed of your pop up, please use <a href="https://compressor.io/compress" target="_blank">this service</a> to compress it'
          } else if (cause.width) {
            message = `The file must be at most ${maxPxWidth}px in width`
          } else if (cause.height) {
            message = `The file must be at most ${maxPxHeight}px in height`
          }

          observer.next({width, height, size, cause, fits, message})
          observer.complete()
        })
      }
      _img.onerror = err => {
        observer.error(err)
      }
      _img.src = img
    })
  }



  private onIframeMessage = (message, path: MGPathData[], namePrefix: string) => {
    // Iframe message coming from unlayer logo and product blocks
    if (message?.data?.name === 'image-upload-modal:open') {
      this.openMGModal(path, message?.data?.value, namePrefix).subscribe(res => {
        if (res) {
          this.iframeRef.contentWindow.postMessage({
              name: message?.data?.toolName ? `image-upload-modal:${message?.data?.toolName}:selected` : 'image-upload-modal:selected',
              img: res,
            }, '*')
          }
      })
    }
  }

  removeModalMessageListener() {
    window.removeEventListener('message', this.onIframeMessageHandler)
  }

  openModalOnMessage(iframeRef: HTMLIFrameElement, path: MGPathData[], namePrefix?: string): void {
    this.iframeRef = iframeRef
    this.removeModalMessageListener()
    this.onIframeMessageHandler = (message) => this.onIframeMessage(message, path, namePrefix)
    window.addEventListener('message', this.onIframeMessageHandler)
  }
  
}
