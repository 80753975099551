import { Component, Input, OnInit, ViewChild, ElementRef, Output, EventEmitter, OnDestroy } from '@angular/core'
import { Store } from '@ngrx/store'
import { Subscription } from 'rxjs'
import * as _ from 'lodash'
import { UserInfo } from '../../../../../store/user/user.state'
import { ProductsListService } from '../../services/products-list.service'
import { Product, ProductsResponse, CollectionsResponse, Collection, ProductsSelectorInterface, ProductVariant } from '../../models/Product'
import { StoreState } from '../../../../../store/store.state'
import { HideLoading, ResetLoading, ShowLoading } from '../../../../../store/loading/loading.actions'
import { UICouponTargetSelection } from '../../../../../pages/coupon-codes/models/coupon-code'
import { ApiService } from '../../../../../core/services/api/api.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator'

@Component({
  selector: 'pf-products-selector-list',
  templateUrl: 'products-selector-list.component.html',
  styleUrls: ['./products-selector-list.component.scss']
})

export class ProductsSelectorListComponent implements OnInit, OnDestroy {

  @Input() userInfo: UserInfo
  @Input() data: ProductsSelectorInterface
  @Output() onSelect: EventEmitter<ProductsSelectorInterface> = new EventEmitter()
  @ViewChild('productsList', { static: true }) productsListRef: ElementRef
  @ViewChild('paginator', { static: true }) paginator: MatPaginator
  @ViewChild('paginator', { read: ElementRef }) paginatorRef: ElementRef
  userCurrency: string = 'USD'

  public uiCouponTargetSelection = UICouponTargetSelection
  private _subscription = new Subscription()

  // By default all products are being selected by 'unique_id'
  // This const exists specially for cases when we need to do it by 'id'
  public idKey: 'unique_id' | 'id' = 'unique_id'

  public itemsPerPage = 20
  public pageSizeOptions = [10, 20]
  public pageIndex = 0
  public totalItems = 0
  public totalPages = 0
  public items: Product[] | Collection[] | any
  public selectedProducts: Set<string>
  public selectedCollections: Set<string>
  public selectedVariants: Set<string>
  public summaryText: string = ''
  public selectAllChecked: boolean = false
  public searchQuery: string
  private searchedQuery: string
  public showNoProductsError: boolean
  public showNoCollections: boolean
  public showNoCollectionsError: boolean
  public noCollectionsMsg: string
  public firstProductsFetch: boolean = true
  public firstCollectionsFetch: boolean = true
  public collectionsHasSynced: boolean = false
  public variantsSelectable: boolean = true
  private _searchTimeout: any

  constructor(
    private store: Store<StoreState>,
    private productsListService: ProductsListService,
    private apiService: ApiService,
  ) { }

  ngOnInit() {
    this.productsListService.target = this.data.type
    this.productsListService.productsOnly = this.data.productsOnly || false

    this.userCurrency = this.userInfo?.shop?.profile?.currency ?? this.userInfo?.subscription?.price?.currency?.iso_code ?? 'USD'

    if (this.data.productIdKey && this.data.productIdKey === 'id') {
      this.idKey = this.data.productIdKey
    }

    if (typeof this.data?.variantsSelectable === 'boolean') {
      this.variantsSelectable = this.data.variantsSelectable
    }

    if (this.data.type === this.uiCouponTargetSelection.entitled_collections) {
      this.selectedCollections = new Set(this.data.ids['collections'])
      this.paginator._intl.itemsPerPageLabel = 'Collections per page'
    } else if (this.data.type === this.uiCouponTargetSelection.entitled_products) {
      this.selectedProducts = new Set(this.data.ids['products'])
      this.selectedVariants = new Set(this.data.ids['variants'])
      this.paginator._intl.itemsPerPageLabel = 'Products per page'
    }

    this.updateSummary()
    this.emitData()
    this._subscription.add(
      this.productsListService.dataAsObservable().subscribe((res: ProductsResponse | CollectionsResponse) => {
        if (this.data.type === this.uiCouponTargetSelection.entitled_products) {
          this.items = (res as ProductsResponse).products
          // Add preview image to each product and its variant to avoid using complex logic in the template
          this.items?.forEach((product: Product) => {
            const productImage = product?.images && product?.images?.length > 0 && product?.images?.[0]?.compact_url || product?.image_url
            product.local_preview_image = productImage
            if (product?.variants && product?.variants?.length) {
              product.variants.forEach((variant: ProductVariant) => {
                const variantImage = variant?.image_url || variant?.image?.compact_url || productImage
                variant.local_preview_image = variantImage || productImage
              })
            }
          })
          // since we don't have total_count for products, we need to disable next button when we get less items than pageSize
          const nextButton = this.paginatorRef?.nativeElement?.querySelector('.mat-mdc-paginator-navigation-next') as HTMLButtonElement
          if (nextButton) {
            setTimeout(() => {
              nextButton.disabled = this.items?.length < this.paginator.pageSize
            }, 0)
          }
        } else {
          this.items = (res as CollectionsResponse).collections
        }
        this.totalItems = res.total_count === null ? Number.POSITIVE_INFINITY : res.total_count  // total_count will be null for products, since pagination was removed
        this.totalPages = res.total_pages

        // total count can also be 0 when searched for non-existing product, but we should show error
        // if store has no products at all
        if (this.firstProductsFetch) {
          this.firstProductsFetch = false
          this.showNoProductsError = (res as ProductsResponse).products?.length === 0
        }
        if (this.firstCollectionsFetch) {
          this.firstCollectionsFetch = false
          this.showNoCollections = !!(res as CollectionsResponse).collections
          if((res as CollectionsResponse).collections?.length === 0) {
            this.showNoCollectionsError = true
            this.noCollectionsMsg = 'Do you already have collections but don\'t see them here?'
          } else {
            this.showNoCollectionsError = false
            this.noCollectionsMsg = 'Do you have collections that you don\'t see here?'
          }
        }
        this.scrollToTop()
        this.checkSelectAll()
        this.checkedProductIfHasVariants()
        this.checkFirstVariantIfNeeded()
        this.store.dispatch(new HideLoading('ProductsSelector'))
      })
    )
    this.loadPage()
  }

  checkedProductIfHasVariants() {
    // since we filter out product.unique_id in products-list.services.ts, we need to check if product has variants
    if (this.items && this.items.length > 0) {
      this.items.forEach(item => {
        if (item.variants && item.variants.length) {
          item.variants.forEach(variant => {
            if (this.selectedVariants.has(variant[this.idKey])) {
              this.selectedProducts.add(item[this.idKey])
              this.emitData()
            }
          })
          this.setIndeterminateState(item)
        }
      })
    }
  }

  checkFirstVariantIfNeeded() {
    if (this.data.autoSelectFirstVariant && this.selectedVariants.size === 0 && this.items && this.items.length > 0) {
      const product = this.items.find(item => this.selectedProducts.has(item[this.idKey]))
      if (product?.variants?.length > 0) {
        this.selectedVariants.add(product.variants[0][this.idKey])
      }
    }
  }

  toggleCheckbox(event: any, isParentProduct: boolean = false) {
    event.stopPropagation()
    const parent = event?.target?.closest('.ProductVariants-Item') || event?.target?.closest('.ProductsList-Item')
    if (parent) {
      const checkbox = parent.querySelector('input[type="checkbox"]')
      if (checkbox && event.target.type !== 'checkbox') {
        if (!isParentProduct && !this.variantsSelectable) {
          return
        }
        event.preventDefault()
        checkbox.click()
      }
    }
  }

  onSelectedProduct(item: Product, event: any) {
    if (!this.data.selectMultiple) {
      this.deselectAll()
      if (event.target.checked) {
        this.selectedProducts.add(item[this.idKey])
        if (this.data.autoSelectFirstVariant && item.variants && item.variants.length) {
          this.selectedVariants.add(item.variants[0][this.idKey])
        }
        if (this.data.selectMultipleVariants && item.variants && item.variants.length) {
          item.variants.forEach(i => this.selectedVariants.add(i[this.idKey]))
          this.setIndeterminateState(item)
        }
      }
    } else {
      if (event.target.checked) {
        this.selectedProducts.add(item[this.idKey])
        if (item.variants && item.variants.length) {
          item.variants.forEach(i => this.selectedVariants.add(i[this.idKey]))
          this.setIndeterminateState(item)
        }
      } else {
        this.selectedProducts.delete(item[this.idKey])
        if (item.variants && item.variants.length) {
          item.variants.forEach(i => this.selectedVariants.delete(i[this.idKey]))
          this.setIndeterminateState(item)
        }
      }
      this.checkSelectAll()
    }
    this.emitData()
  }

  onSelectedCollection(item: Product, event: any) {
    if (!this.data.selectMultiple) {
      this.deselectAll()
      if (event.target.checked) {
        this.selectedCollections.add(item[this.idKey])
      }
    } else {
      if (event.target.checked) {
        this.selectedCollections.add(item[this.idKey])
      } else {
        this.selectedCollections.delete(item[this.idKey])
      }
      this.checkSelectAll()
    }
    this.emitData()
  }

  // getParentProduct(item: ProductVariant): Product {
  //   const product = (this.items as Product[]).find(_product => _product.id === item.product_id)
  //   return product
  // }

  onSelectedVariant(item: ProductVariant, event: any, index: number) {
    // deselect existing values if selectMultiple is false
    // or if selectedMultipleVariants is true and selected product is not the variant's parent
    let shouldDeselect = !this.data.selectMultiple
    if (this.data.selectMultipleVariants) {
      const parentProduct = this.items[index]
      if (this.selectedProducts.size && !this.selectedProducts.has(parentProduct.unique_id)) {
        shouldDeselect = true
      } else {
        shouldDeselect = false
      }
    }

    if (shouldDeselect) {
      this.deselectAll()
      if (event.target.checked) {
        this.selectedVariants.add(item[this.idKey])
        const parentProduct = this.items[index]
        if (parentProduct) {
          this.selectedProducts.add(parentProduct[this.idKey])
        }
      }
    } else {
      if (event.target.checked) {
        this.selectedVariants.add(item[this.idKey])
        const parentProduct = this.items[index]
        if (parentProduct) {
          this.selectedProducts.add(parentProduct[this.idKey])
        }
      } else {
        this.selectedVariants.delete(item[this.idKey])
        const parentProduct = this.items[index] as Product
        const allIsDeselected = parentProduct.variants.every(variant => !this.selectedVariants.has(variant[this.idKey]))
        if (allIsDeselected) {
          this.selectedProducts.delete(parentProduct[this.idKey])
        }
      }
      this.checkSelectAll()
    }
    this.setIndeterminateState(this.items[index] as Product)
    this.emitData()
  }

  private setIndeterminateState(item: Product) {
    if (item.variants.every(variant => this.selectedVariants.has(variant[this.idKey]))) {
      item['indeterminate'] = false
    } else {
      item['indeterminate'] = true
    }
  }

  deselectAll() {
    this.selectedProducts && this.selectedProducts.clear()
    this.selectedVariants && this.selectedVariants.clear()
    this.selectedCollections && this.selectedCollections.clear()
  }

  emitData() {
    const data: ProductsSelectorInterface = {
      type: this.data.type,
      ids: {},
      productsOnly: this.data.productsOnly,
      variantsOnly: this.data.variantsOnly,
      selectMultiple: this.data.selectMultiple,
      selectMultipleVariants: this.data.selectMultipleVariants,
      autoSelectFirstVariant: this.data.autoSelectFirstVariant,
    }
    if (this.data.type === this.uiCouponTargetSelection.entitled_collections) {
      data.ids['collections'] = Array.from(this.selectedCollections)
    } else if (this.data.type === this.uiCouponTargetSelection.entitled_products) {
      data.ids['products'] = Array.from(this.selectedProducts)
      data.ids['variants'] = Array.from(this.selectedVariants)
    }
    this.onSelect.emit(data)
    this.updateSummary()
  }

  gotoProductsSettings() {
    if (this.data.type === this.uiCouponTargetSelection.entitled_collections) {
      window.open(`https://${this.userInfo.shop.domain}/admin/collections`, '_blank')
    } else if (this.data.type === this.uiCouponTargetSelection.entitled_products) {
      window.open(`https://${this.userInfo.shop.domain}/admin/products`, '_blank')
    }
  }

  scrollToTop() {
    this.productsListRef.nativeElement.scroll(0, 0)
  }

  // Compare selected IDs with current page, mark Select All checkbox accordingly
  checkSelectAll() {
    if (!this.items.length || !this.data.selectMultiple) {
      this.selectAllChecked = false
      return
    }
    if (this.data.type === this.uiCouponTargetSelection.entitled_collections) {
      this.selectAllChecked = (this.items as any).every(item => this.selectedCollections.has(item[this.idKey])) // FIXME: replace "as any"
    } else if (this.data.type === this.uiCouponTargetSelection.entitled_products) {
      const variantsArr = (this.items as Product[]).reduce((arr, item) => arr.concat(item.variants), []);
      const selectedProductsMatchesPage = (this.items as any).every(item => this.selectedProducts.has(item[this.idKey])) // FIXME: replace "as any"
      if (this.productsListService.productsOnly) {
        this.selectAllChecked = selectedProductsMatchesPage
      } else {
        const selectedVariantsMatchesPage = variantsArr.every(item => this.selectedVariants.has(item[this.idKey]))
        this.selectAllChecked = selectedProductsMatchesPage && selectedVariantsMatchesPage
      }
    }
  }

  onSelectAll(event: any) {
    if (event.target.checked) {
      if (this.data.type === this.uiCouponTargetSelection.entitled_collections) {
        (this.items as Collection[]).forEach(item => this.selectedCollections.add(item[this.idKey]))
      } else if (this.data.type === this.uiCouponTargetSelection.entitled_products) {
        (this.items as Product[]).forEach(item => {
          this.selectedProducts.add(item[this.idKey])
          if (item.variants && item.variants.length) {
            item.variants.forEach(variant => this.selectedVariants.add(variant[this.idKey]))
            this.setIndeterminateState(item)
          }
        })
      }
    } else {
      if (this.data.type === this.uiCouponTargetSelection.entitled_collections) {
        (this.items as Collection[]).forEach(item => this.selectedCollections.delete(item[this.idKey]))
      } else if (this.data.type === this.uiCouponTargetSelection.entitled_products) {
        (this.items as Product[]).forEach(item => {
          this.selectedProducts.delete(item[this.idKey])
          if (item.variants && item.variants.length) {
            item.variants.forEach(variant => this.selectedVariants.delete(variant[this.idKey]))
            this.setIndeterminateState(item)
          }
        })
      }
    }
    this.emitData()
  }

  updateSummary() {
    if (this.data.type === this.uiCouponTargetSelection.entitled_collections) {
      this.summaryText = `Selected: ${this.selectedCollections.size} Collections`
    } else if (this.data.type === this.uiCouponTargetSelection.entitled_products) {
      if (this.productsListService.productsOnly) {
        this.summaryText = `Selected: ${this.selectedProducts.size} Products`
      } else {
        this.summaryText = `Selected: ${this.selectedProducts.size} Products & ${this.selectedVariants.size} Variants`
      }
    }
  }

  loadPage(page?: PageEvent) {
    this.store.dispatch(new ShowLoading('ProductsSelector'))
    let pageToLoad = page ? page.pageIndex : 0
    // if this is a new search start from first page
    if (this.searchQuery !== this.searchedQuery) {
      pageToLoad = 0
      this.paginator.firstPage()
    }
    this.productsListService.fetchPage(pageToLoad, page ? page.pageSize : this.itemsPerPage, this.searchQuery)
    this.searchedQuery = this.searchQuery
  }

  onSearch(e: any) {
    clearTimeout(this._searchTimeout)
    this._searchTimeout = setTimeout(() => {
      this.loadPage()
    }, 1000)
  }

  searchClear() {
    this.searchQuery = null
    this.loadPage()
  }

  syncCollections() {
    this._subscription.add(
      this.apiService.post('/v1/me/shop/collections/sync_all').subscribe(()=>{
        this.showNoCollections = false
        this.collectionsHasSynced = true
      }, (_err) => {
        console.error('Error syncing collections')
      })
    )
  }

  ngOnDestroy() {
    this._subscription.unsubscribe()
    this.paginator._intl.itemsPerPageLabel = 'Items per page'
    this.store.dispatch(new ResetLoading())
  }
}
