import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { select, Store } from '@ngrx/store'
import { forkJoin, Observable } from 'rxjs'
import { StoreState } from '../../store/store.state'
import { getUserChoseInitialPlanCompleted } from '../../store/user/user.selectors'
import { filter, finalize, map } from 'rxjs/operators'
import { first } from 'rxjs/operators'
import { HideLoading, ShowLoading } from '../../store/loading/loading.actions'
import { LoadingLabel } from '../../shared/models/loading/loading-label'
import { Logger } from '../services/logger.service'
import { LogLabel } from '../../shared/models/logger/log-label.model'
import { GettingStartedStatus } from '../../shared/models/getting-started/getting-started-status.model'
import { getGettingStartedStatus } from '../../store/getting-started/getting-started.selectors'
import _ from 'lodash'
import { RouteHeaderUrl } from '../../shared/components/one-header/header-navigation.model'

@Injectable()
export class PrePlansSurveyIncompleteGuard  {
  initialPaths = `/${RouteHeaderUrl.apps}`

  constructor(
    private store: Store<StoreState>,
    private logger: Logger,
    private router: Router,
  ) {
  }

  check() {
    this.store.dispatch(new ShowLoading(LoadingLabel.PrePlansSurveyIncompleteGuard))
    return forkJoin([
      this.userChoseInitialPlanCompleted$,
      this.gettingStartedStatus$
    ]).pipe(
      map(([choseInitialPlan, status]) => {
        this.logger.log(<LogLabel>'signup-incomplete-guard', 'choseInitialPlan: ', choseInitialPlan)
        const userType = !_.get(status, 'completed', false) ? 'new' : 'returning'

        if (choseInitialPlan || _.get(status, `pre_plans_survey_${userType}.completed`, false)) {
          this.router.navigateByUrl(this.initialPaths)
        }
        return true
      }),
      finalize(() => this.store.dispatch(new HideLoading(LoadingLabel.PrePlansSurveyIncompleteGuard))),
    )
  }

  get userChoseInitialPlanCompleted$(): Observable<boolean> {
    return this.store.pipe(
      select(getUserChoseInitialPlanCompleted),
      filter(completed => completed !== undefined),
      first(),
    )
  }

  get gettingStartedStatus$(): Observable<GettingStartedStatus> {
    return this.store.pipe(
      select(getGettingStartedStatus),
      filter(next => next !== undefined),
      filter(status => !!status),
      first(),
    )
  }

  canActivate(): Observable<boolean> {
    return this.check()
  }

  canActivateChild(): Observable<boolean> {
    return this.check()
  }
}
