import { CommonModule } from '@angular/common'
import { Component, Inject } from '@angular/core'
import { CrispService } from '../../../../../core/services/crisp.service'
import { OneIconComponent } from '../../../one-icon/one-icon.component'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

@Component({
    templateUrl: './connect-stores-modal.component.html',
    styleUrls: ['./connect-stores-modal.component.scss'],
    standalone: true,
    imports: [
      CommonModule,
      OneIconComponent,
    ]
})
export class ConnectStoresModalComponent {
  constructor(
    private crispService: CrispService,
    public dialogRef: MatDialogRef<ConnectStoresModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.dialogRef.addPanelClass(['_no-padding'])
  }

  onLiveChatClick(event) {
    event.preventDefault()
    const crisp = this.crispService.getCrisp()
    if (crisp) {
      crisp.push(['do', 'chat:open'])
      crisp.push(['do', 'message:send', ['text', `I am having trouble connecting other Shopify stores to my ONE account.`]])
    }
  }
}
