<div class="snackbar-body">
  <div class="snackbar-icon-container">
    <img src="./app_assets/icons/message-{{data.type}}-icon.svg" alt="">
  </div>
  <div class="snackbar-text">
    <h4>{{data.title}}</h4>
    <div class="d-flex align-items-center">
      <p>
        {{data.text}}
        <span *ngIf="data.html" [innerHtml]="data.html" class="mr-1"></span>
        <a *ngIf="data.crisp && data.crisp.enabled && data.crisp.type === 'link'" (click)="openCrisp()" class="cursor-pointer">live chat</a>
        <a *ngIf="data.button && data.button.enabled && data.button.type === 'link' && data.button.cb" (click)="data.button.cb()" class="cursor-pointer">{{ data.button.text || 'Click' }}</a>
      </p>
      <button *ngIf="data.crisp && data.crisp.enabled && data.crisp.type === 'button'"
              class="pf-button red filled sm ml-2"
              [ngClass]="buttonColor[data.type]"
              (click)="openCrisp()">Live Chat</button>
      <button *ngIf="data.button && data.button.enabled && data.button.text && data.button.type === 'button' && data.button.cb"
              class="pf-button blue filled sm ml-2"
              (click)="data.button.cb()">{{ data.button.text || 'Click' }}</button>
    </div>
  </div>
  <div class="snackbar-close" (click)="dismissSnackbar()">
    <one-icon name="close"></one-icon>
  </div>
</div>

<div class="snackbar-progress" [class.hidden]="!data.duration">
  <div class="snackbar-progress-bar" [style.width.%]="progress"></div>
</div>
