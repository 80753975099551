import { EmailAutomationTypeEnum } from '../../emails/components/email-automations/enums/email-automation-type.enum';
import {BroadcastEmail} from '../../../shared/models/broadcast-email.model';
import { CampaignPluginName } from '../../../shared/models/campaign/campaign';
import { MarketingEmail, MarketingEmailType } from '../../../shared/models/campaign/marketing-email'
import { HolidayTypes } from '../../../shared/components/promotion-cards/models/promotion.models'
import { UnlayerOptions } from '../../../shared/modules/unlayer-editor/unlayer-editor.component'

export interface UnlayerTemplate {
  created_at: string
  id: string
  json: object
  name: string
  template_id: string
  html: string
  updated_at: string
  type?: string
  tags?: string[]
}

export enum EmailBuilderType {
  Broadcast = 'broadcast',
  Birthday = 'birthday',
  Email = 'email',
}

export interface UnlayerTemplateResponse {
  default_template: UnlayerTemplate,
  templates: UnlayerTemplate[]
}

export interface PFUnlayerOptions extends UnlayerOptions {
  projectId?: number
  user?: {
    id: string
  }
  features?: {
    smartMergeTags?: boolean
    textEditor?: {
      inlineFontControls?: boolean
    }
  }
  tools?: object
  appearance?: object
  locale?: string
  customJS?: string[]
  customCSS?: string[]
  designTags?: any
  mergeTags?: any
}

export interface EmailEditorTheme extends UnlayerTemplate {
  selected: boolean
  loaded: boolean
  premium?: boolean
  identifier: string
  preview_image_url?: string
}

export interface EmailEditorThemeOverlayData {
  promotion?: HolidayTypes
  selectedTemplateId: string
  selectedTemplateJson: any
  unlayerOptions: PFUnlayerOptions
  pluginType?: CampaignPluginName
  type?: EmailAutomationTypeEnum | BroadcastNewsletterEmailType | MarketingEmailType
}

export interface EmailEditorMergeTag {
  id: string
  name: string
  value: string
}

export enum BroadcastNewsletterEmailType {
  broadcastCoupon = 'broadcast_coupon',
  broadcastNewsletter = 'broadcast_newsletter',
}

export interface EmailEditorOverlayData {
  name?: string,
  type?: BroadcastNewsletterEmailType | EmailAutomationTypeEnum
  emailType?: string,
  emailId?: string,
  emailData?: BroadcastEmail | MarketingEmail,
  templateId?: string,
  pluginType?: CampaignPluginName,
  presetDate?: {
    date: string,
    time: number
  },
  description?: any
  image?: string
  couponRequired?: boolean,
  subject?: string,
  mergeTags?: EmailEditorMergeTag[],
  readonly?: boolean,
}

export interface UnlayerHTMLExport {
  chunks: {
    body: string
    css: string
    fonts: { defaultFont: boolean, label: string, url: string, value: string }[]
    js: string
  }
  design: {
    body: object
    counters: object
    schemaVersion: object
  }
  html: string
}

export enum ThemePageView {
  Gallery = 'EmailThemePageComponent.searchTemplates',
  Saved = 'EmailThemePageComponent.savedTemplates',
}
