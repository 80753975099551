import { CommonModule } from '@angular/common'
import { Component, OnInit, Inject } from '@angular/core'
import { CrispService } from '../../../../core/services/crisp.service'
import { SlackChannelName, SlackSupporService } from '../../../../core/services/slack-support.service'
import { OneIconComponent } from '../../one-icon/one-icon.component'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

@Component({
    selector: 'pf-global-error-modal',
    templateUrl: './global-error-modal.component.html',
    styleUrls: ['./global-error-modal.component.scss'],
    standalone: true,

    imports: [
      CommonModule,
      OneIconComponent,
    ]
})
export class GlobalErrorModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<GlobalErrorModalComponent>,
    private crispService: CrispService,
    private slackSupporService: SlackSupporService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void { }

  onLiveChatClick( event: Event ) {
    event.preventDefault()

    if (this.data && this.data.error) {
      this.slackSupporService.sendMessage(this.data.error, SlackChannelName.Support)
    }
    const crisp = this.crispService.getCrisp()
    if (crisp) {
      crisp.push(['do', 'chat:open'])
      crisp.push(['do', 'message:send', [
        'text',
        `Hello team, I am having trouble with the website, can you help?`
      ]])
    }
  }
}
