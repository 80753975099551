// import { CommonModule } from '@angular/common'
import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { BehaviorSubject, Subscription } from 'rxjs'
import { ActiveAppCampaignService, CampaignCounts } from '../../../../../core/services/active-app-campaign.service'
import { RewardStats } from '../../../../../core/services/api/api-rewards.service'
import { UserService } from '../../../../../core/services/user.service'
import { CouponStatus } from '../../../../../pages/coupon-codes/models/coupon-code'
import { RewardsType } from '../../../../../pages/coupon-codes/models/rewards-model'
import { UpsellStats } from '../../../../../pages/upsell/models/upsell-page.models'
import { ActiveAppCampaigns } from '../../../../models/app-campaign-type.model'
import { UserShopType } from '../../../../models/user/user-shop-type.model'
import { DateRangeLabel } from '../../../../modules/mat-daterange/models/mat-daterange.model'
import { MatDaterangeService } from '../../../../modules/mat-daterange/services/mat-daterange.service'
import { UserInfo } from '../../../../../store/user/user.state'
import { RouteHeaderUrl } from '../../header-navigation.model'
import { SegmentAnalyticsService } from '../../../../services/segment-analytics.service'
import { Router, RouterModule } from '@angular/router'
import { filter } from 'rxjs/operators'
import { CommonModule } from '@angular/common'
import { SmsService } from '../../../../../pages/sms/services/sms-service'
import { OneIconComponent } from '../../../one-icon/one-icon.component'
import { AngularFittextDirective } from '../../../../directives/angular-fittext.directive'
import { ActiveCampaignDropdownService } from './services/active-campaign-dropdown.service'

interface ActiveCampaignDropdownItem {
  title: string
  active: number
  completed: number
  showCompleted?: boolean
  activeSource?: ActiveAppCampaigns
  completedSource?: ActiveAppCampaigns | null
  plan?: string
  class?: string
  hidden?: boolean
}
interface ActiveCampaignDropdownConfig {
  title: string
  url: string
  isTotal?: boolean
  isFirst?: boolean
  upgradeButton?: boolean
  badge?: {
    text: string
  }
  items: ActiveCampaignDropdownItem[]
}
@Component({
  selector: 'one-active-campaign-dropdown',
  templateUrl: './active-campaign-dropdown.component.html',
  styleUrls: ['./active-campaign-dropdown.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    OneIconComponent,
    AngularFittextDirective,
  ],
  providers: [
    SmsService,
  ],
})

export class ActiveCampaignDropdownComponent implements OnInit, OnDestroy {
  campaignCounts$: BehaviorSubject<CampaignCounts> = this.activeAppCampaignService.campaignCounts$
  RouteHeaderUrl = RouteHeaderUrl

  userInfo: UserInfo = this.userService.userInfo
  shopTypeArray = [UserShopType.ShopifyShop, UserShopType.BigCommerceShop, UserShopType.WooCommerceShop]
  isShopify = this.userInfo?.shop?.type === UserShopType.ShopifyShop
  isShop = this.shopTypeArray.includes(this.userInfo?.shop?.type)
  userCurrency = this.userInfo?.shop?.profile?.currency || 'USD'
  showPlanLimit = this.userService.isNewPlanUser
  planName = this.userInfo?.subscription?.plan?.name
  planCampaignsLimit = this.userInfo?.subscription?.plan?.max_active_campaigns

  public sections: ActiveCampaignDropdownConfig[] = [{
    title: 'Store',
    url: `/${RouteHeaderUrl.popups}/${RouteHeaderUrl.subscribe_forms}`,
    isFirst: true,
    items: [{
      title: 'Pop Ups',
      activeSource: ActiveAppCampaigns.Popup,
      completedSource: null,
      active: 0,
      completed: 0,
      showCompleted: false,
    }],
  }, {
    title: 'Email',
    url: `/${RouteHeaderUrl.messaging}/${RouteHeaderUrl.emails}/${RouteHeaderUrl.campaigns}`,
    items: [{
      title: 'Broadcasts',
      activeSource: ActiveAppCampaigns.EmailBroadcast,
      completedSource: ActiveAppCampaigns.CompletedEmailBroadcast,
      active: 0,
      completed: 0,
      showCompleted: true,
    }, {
      title: 'Automations',
      activeSource: ActiveAppCampaigns.EmailAutomation,
      completedSource: null,
      active: 0,
      completed: 0,
      showCompleted: false,
    }, {
      title: 'Linked Automations',
      activeSource: ActiveAppCampaigns.LinkedEmailAutomation,
      completedSource: null,
      active: 0,
      completed: 0,
      showCompleted: false,
    }],
  }, {
    title: 'SMS',
    url: `/${RouteHeaderUrl.messaging}/${RouteHeaderUrl.sms}/${RouteHeaderUrl.campaigns}`,
    items: [{
      title: 'Broadcasts',
      activeSource: ActiveAppCampaigns.SmsBroadcast,
      completedSource: ActiveAppCampaigns.CompletedSmsBroadcast,
      active: 0,
      completed: 0,
      showCompleted: true,
    }, {
      title: 'Automations',
      activeSource: ActiveAppCampaigns.SmsAutomation,
      completedSource: null,
      active: 0,
      completed: 0,
      showCompleted: false,
    }, {
      title: 'Linked Automations',
      activeSource: ActiveAppCampaigns.LinkedSmsAutomation,
      completedSource: null,
      active: 0,
      completed: 0,
      showCompleted: false,
    }],
  }, {
    title: 'Checkout',
    url: `/${RouteHeaderUrl.upsell}`,
    items: [{
      title: 'One-Click Upsell Pages',
      activeSource: ActiveAppCampaigns.PostPurchaseUpsell,
      completedSource: null,
      active: 0,
      completed: 0,
      showCompleted: false,
    }],
  }, {
    title: 'Rewards',
    url: `/${RouteHeaderUrl.rewards}/${RouteHeaderUrl.coupons}`,
    items: [{
      title: 'Linked Coupons',
      activeSource: ActiveAppCampaigns.LinkedCoupons,
      completedSource: null,
      active: 0,
      completed: 0,
      showCompleted: false,
    }, {
      title: 'Linked Gift Cards',
      activeSource: ActiveAppCampaigns.LinkedGiftCards,
      completedSource: null,
      active: 0,
      completed: 0,
      showCompleted: false,
    }],
  }, {
    title: this.showPlanLimit ? 'Plan' : 'Total',
    url: `/${RouteHeaderUrl.dashboard}`,
    isTotal: true,
    badge: this.userService.isNewPlanUser ? {
      text: this.planName,
    } : null,
    upgradeButton: false,
    items: [
      {
        title: 'Campaign Totals',
        active: 0,
        completed: 0,
        showCompleted: true,
      },
      {
        title: 'Campaign Limits',
        plan: this.planName,
        active: this.planCampaignsLimit,
        completed: 0,
        showCompleted: false,
        class: '_border-top',
        hidden: !this.showPlanLimit
      }
    ],
  }]

  public totalRevenue = 0
  // public storeTotalRevenue = 0
  private subscription = new Subscription()
  defaultDateRange: DateRangeLabel = DateRangeLabel.AllTime
  startDate = this.matDaterangeService.getDateRangeByLabel(this.defaultDateRange).start
  endDate = this.matDaterangeService.getDateRangeByLabel(this.defaultDateRange).end
  giftStatsLoaded = false
  couponStatsLoaded = false
  upsellStatsLoaded = false

  upsellStats: UpsellStats = {
    views: 0,
    conversions: 0,
    conversion_rate: 0,
    average_order: 0,
    average_historic_value: 0,
    average_increase_rate: 0,
    total_revenue: 0,
  }

  couponStats: RewardStats = {
    num_awarded: 0,
    num_redeemed: 0,
    redemption_rate: 0,
    total_revenue: 0,
    num_redemption_rate: 0
  }

  giftStats: RewardStats = {
    num_awarded: 0,
    num_redeemed: 0,
    redemption_rate: 0,
    total_revenue: 0,
    num_redemption_rate: 0
  }

  constructor(
    private userService: UserService,
    private matDaterangeService: MatDaterangeService,
    private activeAppCampaignService: ActiveAppCampaignService,
    private segmentAnalytics: SegmentAnalyticsService,
    private router: Router,
    private activeCampaignDropdownService: ActiveCampaignDropdownService,
  ) { }

  ngOnInit() {
    // Total Store Revenue
    // this.subscription.add(
    //   this.activeCampaignDropdownService.getTotalUnique(
    //     this.startDate.format('YYYY-MM-DD'),
    //     this.endDate.format('YYYY-MM-DD')
    //   ).subscribe({
    //     next: res => {
    //       this.storeTotalRevenue = res?.total_native_revenue || 0
    //     },
    //     error: err => {
    //       console.error('Unable fetch Analytics', err)
    //     }
    //   })
    // )
    // Campaign Counts
    this.subscription.add(
      this.activeAppCampaignService.campaignCounts$.subscribe(res => {
        if (res) {
          this.sections.forEach(section => {
            section.items.forEach(item => {
              if (!section.isTotal) {
                item.active = res[item.activeSource]
                item.completed = res[item.completedSource]
              }
            })
          })
          // Update Total
          const totalsSection = this.sections.find(section => section.isTotal)
          const totalsItem = totalsSection.items[0]
          const totalCampaigns = res[ActiveAppCampaigns.Total] || 0
          totalsItem.active = totalCampaigns
          totalsItem.completed = this.sections.reduce((acc, section) => {
            if (!section.isTotal) {
              return acc + section.items.reduce((acc2, item) => acc2 + (item.completed || 0), 0)
            }
            return acc
          }, 0)
          if (totalCampaigns >= this.planCampaignsLimit) {
            totalsSection.upgradeButton = this.showPlanLimit
            totalsSection.url = `/${RouteHeaderUrl.settings}/${RouteHeaderUrl.billing}/${RouteHeaderUrl.subscriptions}`
          }
        }
      })
    )

    this.subscription.add(this.userService.userInfo$.pipe(filter(next => next !== null)).subscribe({
      next: (user) => {
        this.userInfo = user
        this.showPlanLimit = this.userService.isNewPlanUser
        this.planName = this.userInfo?.subscription?.plan?.name
        this.planCampaignsLimit = this.userInfo?.subscription?.plan?.max_active_campaigns
        const totalsSection = this.sections.find(section => section.isTotal)
        totalsSection.badge = this.showPlanLimit ? { text: this.planName } : null
        const limitsItem = totalsSection.items[1]
        limitsItem.active = this.planCampaignsLimit
      }
    }))

    // Get data for revenue report (last 30 days)
    this.getRewardData()
    this.getUpsellData()
  }

  // Reward Cards
  getRewardData() {
    if (this.isShop) {
      let params: any = {
        includes: 'stats',
        status: CouponStatus.allExceptArchived,
      }
      params.start = this.startDate.format('YYYY-MM-DD')
      params.end  = this.endDate.format('YYYY-MM-DD')
      // Coupons
      this.subscription.add(
        this.activeCampaignDropdownService.getRewardStats(params, RewardsType.coupon_code).subscribe(res => {
          this.couponStats = res?.stats || {} as RewardStats
          this.couponStatsLoaded = true
          this.getTotalRevenue()
        })
      )
      // Gift Cards
      this.subscription.add(
        this.activeCampaignDropdownService.getRewardStats(params, RewardsType.gift_card).subscribe(res => {
          this.giftStats = res?.stats || {} as RewardStats
          this.giftStatsLoaded = true
          this.getTotalRevenue()
        })
      )
    }
  }

  // Upsell Cards
  getUpsellData() {
    if (this.isShopify) {
      this.subscription.add(
        this.activeCampaignDropdownService.getUpsellStats(
          this.startDate.format('YYYY-MM-DD'),
          this.endDate.format('YYYY-MM-DD')
        ).subscribe(res => {
          if (res) {
            const avgIncreaseRate = 0
            this.upsellStats = {
              ...res,
              average_increase_rate: avgIncreaseRate,
            }
          }
          this.upsellStatsLoaded = true
          this.getTotalRevenue()
        })
      )
    } else {
      this.upsellStatsLoaded = true
    }
  }

  getTotalRevenue() {
    if (this.isShop) {
      if (this.upsellStatsLoaded && this.couponStatsLoaded && this.giftStatsLoaded) {
        this.totalRevenue = this.upsellStats.total_revenue + this.couponStats.total_revenue + this.giftStats.total_revenue
      }
    } else {
      if (this.couponStatsLoaded && this.giftStatsLoaded) {
        this.totalRevenue = this.couponStats.total_revenue + this.giftStats.total_revenue
      }
    }
  }

  goToPlansPage(e) {
    e.preventDefault()
    this.segmentAnalytics.track('Clicked Upgrade - Active Campaign Dropdown')
    setTimeout(() => {
      this.router.navigateByUrl(`/${RouteHeaderUrl.settings}/${RouteHeaderUrl.billing}/${RouteHeaderUrl.subscriptions}`)
    }, 0)
  }

  onLinkClick(url: string) {
    setTimeout(() => {
    this.router.navigateByUrl(url)
    }, 0)
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }
}
