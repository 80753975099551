import { Injectable } from '@angular/core'
import { ApiJotformService } from './api/api-jotform.service'
import { UserService } from './user.service'
import { SMSPhoneNumberVerificationStatus } from '../../pages/sms/models/sms.models'
import { environment } from '../../../environments/environment'
import { ApiService } from './api/api.service'
import { CustomSnackbarService } from '../../shared/modules/custom-snackbar/custom-snackbar.service'
import { Router } from '@angular/router'
import { RouteHeaderUrl } from '../../shared/components/one-header/header-navigation.model'
import { filter } from 'rxjs/operators'


@Injectable({ providedIn: 'root' })
export class SmsPhoneNumberVerificationService {
  public numberUnverified = false
  public pendingVerification = false
  public denied = false
  private submissionId = null
  private jotFormApi = null

  constructor(
    private jotFormApiService: ApiJotformService,
    private userService: UserService,
    private apiService: ApiService,
    private snackbarService: CustomSnackbarService,
    private router: Router
  ) {
    if (window['JF']) {
      this.jotFormApi = window['JF']
      this.jotFormApi.initialize({ apiKey: environment.jotFormApiKey })
    }
  }

  checkSMSPhoneNumberVerification() {
    if (this.userService.isShopifyShop) {
      this.jotFormApiService.readSMSPhoneNumberForm()
        .pipe(filter(data => !!data?.status))
        .subscribe(data => {
          this.submissionId = data.submissionId
          this.numberUnverified = data.status === SMSPhoneNumberVerificationStatus.Pending || data.status === SMSPhoneNumberVerificationStatus.Denied
          this.pendingVerification = data.status === SMSPhoneNumberVerificationStatus.Pending
          this.denied = data.status === SMSPhoneNumberVerificationStatus.Denied
          if (data.status === SMSPhoneNumberVerificationStatus.Verified && !this.userService.interactions.phone_number_verified_msg_seen) {
            this.showVerifiedMessage()
          }
        })
    }
  }

  private showVerifiedMessage() {
    this.apiService.patch('/v1/me', {
      interactions: {
        phone_number_verified_msg_seen: true,
      },
    }).subscribe(() => {
      this.snackbarService.showSuccess(
        { title: 'Congrats!', text: 'Your SMS number has been verified.', duration: 0 },
        {},
        {
          button: true, button_type: 'button', button_text: 'Get Started', cb: () => {
            this.router.navigate([`/${RouteHeaderUrl.messaging}/${RouteHeaderUrl.sms}`])
              .then(() => {
                this.snackbarService.dismiss()
              })
          },
        })
    })
  }

  public showUnverifiedMessage(publish = true) {
    this.snackbarService.showError({
      text: `Unable to ${publish ? 'publish' : 'save'}. Cannot send SMS messages while phone number is unverified.`,
      duration: 5000
    })
  }


  public setPendingPhoneVerification() {
    if (this.submissionId) {
      return
    }

    const submission = {
      '5': this.userService.userInfo.id,
      '7': 'pending',
      '8': this.userService.userInfo.email,
      '9': this.userService?.userInfo?.shop?.profile?.myshopify_domain
    }
    this.jotFormApi?.createFormSubmissions('240951558710357', JSON.stringify([submission]), (res) => {
      this.submissionId = res[0]?.submissionID || res?.submissionID
      this.numberUnverified = true
      this.pendingVerification = true
    })

  }
}
