import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import moment from 'moment'
import { Observable } from 'rxjs'
import { finalize, map } from 'rxjs/operators'
import { Email } from '../../../../core/modules/dns-config/modules/email-address/interfaces/email.interface'
import { ApiService } from '../../../../core/services/api/api.service'
import { HideLoading, ShowLoading } from '../../../../store/loading/loading.actions'
import { StoreState } from '../../../../store/store.state'
import { Industry } from '../../../models/industries.model'
import { BusinessOrgConfig,
  EmailBrandingConfig,
  EmailOnboardingComplianceOfficer,
  EmailOnboardingConfig,
  SavedEmail,SavedReplyToEmailsResponse,
  SavedTestEmailsResponse,
  SentEmailStats,
  SyncContactsStatus,
} from '../models/email-onboarding.model'
import { UserInfo } from '../../../../store/user/user.state'
import { SetCurrentUserInfo } from '../../../../store/user/user.actions'

@Injectable({providedIn: 'root'})
export class EmailOnboardingApiService {
  constructor(
    private apiService: ApiService,
    private store: Store<StoreState>,
  ) { }

  getIndustries(): Observable<Industry[]> {
    return this.apiService.get('/v1/industries')
  }

  getBusinessOrg(): Observable<BusinessOrgConfig> {
    return this.apiService.get('/v1/me/business_organization')
  }

  updateBusinessOrg(data: BusinessOrgConfig): Observable<BusinessOrgConfig> {
    return this.apiService.post('/v1/me/business_organization', data)
  }

  getComplianceOfficer(): Observable<EmailOnboardingComplianceOfficer> {
    return this.apiService.get('/v1/me/email_compliance_officer_profile')
  }

  updateComplianceOfficer(data: EmailOnboardingComplianceOfficer): Observable<EmailOnboardingComplianceOfficer> {
    delete data?.completed
    return this.apiService.post('/v1/me/email_compliance_officer_profile', {email_compliance_officer_profile: data})
  }

  getEmailOnboardingConfig(): Observable<EmailOnboardingConfig> {
    return this.apiService.get('/v1/me/interactions/onboarding_email')
  }

  setEmailOnboardingConfig(data: EmailOnboardingConfig): Observable<EmailOnboardingConfig> {
    return this.apiService.post('/v1/me/interactions/onboarding_email', data)
  }

  updateEmailBrandingConfig(data: EmailBrandingConfig): Observable<UserInfo> {
    return this.apiService.put('/v1/me', data)
      .pipe(
        map((response: UserInfo) => {
          this.store.dispatch(new SetCurrentUserInfo(response))
          return response
        })
      )
  }

  getSyncContactsStatus(): Observable<SyncContactsStatus> {
    return this.apiService.get('/v1/me/shopify/sync_contacts_status')
  }

  getSentEmailStats(): Observable<SentEmailStats> {
    const date = moment().format('YYYY-MM-DD')
    return this.apiService.get(`/v1/me/email_usage?date=${date}`)
  }

  // Reply To emails
  createReplyToEmail(email: string): Observable<SavedEmail> {
    return this.apiService.post('/v1/me/saved_reply_to_emails', {email})
  }

  getReplyToEmails(): Observable<SavedReplyToEmailsResponse> {
    this.store.dispatch(new ShowLoading('EmailOnboardingApiService.getReplyToEmails'))
    return this.apiService.get('/v1/me/saved_reply_to_emails').pipe(
      map(response => {
        return {
          saved_reply_to_emails: (response?.saved_reply_to_emails || [])
        }
      }),
      finalize(() => { this.store.dispatch(new HideLoading('EmailOnboardingApiService.getReplyToEmails')) }),
    )
  }

  resendReplyToConfirmation(id: string): Observable<any> {
    return this.apiService.post(`/v1/me/saved_reply_to_emails/${id}/resend_confirmation`)
  }

  getReplyToEmail(id: string): Observable<SavedEmail> {
    this.store.dispatch(new ShowLoading('EmailOnboardingApiService.getReplyToEmail'))
    return this.apiService.get(`/v1/me/saved_reply_to_emails/${id}`).pipe(
      finalize(() => { this.store.dispatch(new HideLoading('EmailOnboardingApiService.getReplyToEmail')) }),
    )
  }

  deleteReplyToEmail(id: string): Observable<any> {
    return this.apiService.delete(`/v1/me/saved_reply_to_emails/${id}`)
  }

  // Test emails

  createTestEmail(email: string): Observable<SavedEmail> {
    return this.apiService.post('/v1/me/saved_test_emails', {email})
  }

  getTestEmails(): Observable<SavedTestEmailsResponse> {
    return this.apiService.get('/v1/me/saved_test_emails').pipe(
      map(response => {
        return {
          saved_test_emails: (response?.saved_test_emails || [])
        }
      })
    )
  }

  resendTestConfirmation(id: string): Observable<any> {
    return this.apiService.post(`/v1/me/saved_test_emails/${id}/resend_confirmation`)
  }

  getTestEmail(id: string): Observable<SavedEmail> {
    this.store.dispatch(new ShowLoading('EmailOnboardingApiService.getTestEmail'))
    return this.apiService.get(`/v1/me/saved_test_emails/${id}`).pipe(
      finalize(() => { this.store.dispatch(new HideLoading('EmailOnboardingApiService.getTestEmail')) }),
    )
  }

  deleteTestEmail(id: string): Observable<any> {
    return this.apiService.delete(`/v1/me/saved_test_emails/${id}`)
  }


}
