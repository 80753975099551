<section
  class="side-navigation-bar"
  (mouseenter)="toggleExpanded(true)"
  (mouseleave)="toggleExpanded(false)"
  [@expand]="expanded"
>
  <pf-side-navigation-bar-pin-button
    class="pin-button"
    [sideBarPinned]="sideBarPinned"
    (pinSideBar)="pinSideBar()"
    [expanded]="expanded"
  ></pf-side-navigation-bar-pin-button>

  <pf-side-navigation-bar-headline
    *ngIf="headline"
    [headline]="headline"
    [expanded]="expanded"
  ></pf-side-navigation-bar-headline>

  <pf-side-navigation-bar-sub-headline
    *ngIf="subHeadline || statusLabel"
    [subHeadline]="subHeadline"
    [statusLabel]="statusLabel"
    [statusColor]="statusColor"
    [expanded]="expanded"
    [tooltipText]="tooltipText"
  ></pf-side-navigation-bar-sub-headline>

  <div class="navigation-steps" [class._expanded]="expanded">
    <pf-steps-panel
      [multiline]="multiline"
      [steps]="steps"
      [nonSequentialNav]="nonSequentialNav"
      [expanded]="expanded"
      [stepsClickable]="stepsClickable"
    ></pf-steps-panel>

    <pf-side-navigation-bar-back-button
      *ngIf="backButtonUrl"
      [label]="backButtonLabel"
      [url]="backButtonUrl"
      (click)="backButtonClicked.emit()"
      [expanded]="expanded"
    ></pf-side-navigation-bar-back-button>
  </div>

  <div class="action-buttons">
    <ng-content></ng-content>
  </div>
</section>
