import { Injectable } from '@angular/core'
import { ApiService } from './api.service'
import { Observable } from 'rxjs'
import { CouponStatus, CouponsResponse } from '../../../pages/coupon-codes/models/coupon-code'
import { Address, UpdateAddress } from '../../../shared/models/user/user-profile.interface'
import { AutomationsResponse } from '../../../pages/sms/modules/sms-automations/models/automation'
import { BroadcastsResponse } from '../../../pages/sms/modules/sms-broadcasts/models/broadcast'
import { RewardsType } from '../../../pages/coupon-codes/models/rewards-model'
import { UserShopifyCoupon } from '../../../shared/models/user/user-coupons.model'
import { UserShop } from '../../../shared/models/user/user-shop.model'
import { UserShopAccessScope, UserShopAccessScopeApiResponse } from '../../../shared/models/user/user-scope.model'
import { map } from 'rxjs/operators'

@Injectable()
export class ApiUserService {
  constructor(
    private apiService: ApiService,
  ) {
  }

  getShop(): Observable<UserShop> {
    return this.apiService.get('/v1/me/shop')
  }

  getShopDiscounts(): Observable<{ coupons: UserShopifyCoupon[] }> {
    return this.apiService.get('/v1/me/shop/coupons')
  }

  getUserAddress(): Observable<Address> {
    return this.apiService.get('/v1/me/address')
  }

  updateUserAddress(data: UpdateAddress): Observable<Address> {
    return this.apiService.post('/v1/me/address', { ...data })
  }

  // deprecated, use RewardsService instead
  getCouponCodes(
    page: number = 1,
    limit: number = 25,
    status: CouponStatus = CouponStatus.active,
    start?: string,
    end?: string,
    isShopify?: boolean,
  ): Observable<CouponsResponse> {
    if (start && end && isShopify) {
      return this.apiService.get(`/v1/me/rewards?reward_type=${RewardsType.all}&page=${page}&limit=${limit}&status=${status}&start_date=${start}&end_date=${end}`)
    } else {
      return this.apiService.get(`/v1/me/rewards?reward_type=${RewardsType.all}&page=${page}&limit=${limit}&status=${status}`)
    }
  }

  getAutomations(
    page: number = 1,
    limit: number = 999,
    start: string = null,
    end: string = null,
  ): Observable<AutomationsResponse> {
    if (start && end) {
      return this.apiService.get(`/v1/me/sms_automations?page=${page}&limit=${limit}&start_date=${start}&end_date=${end}`)
    } else {
      return this.apiService.get(`/v1/me/sms_automations?page=${page}&limit=${limit}`)
    }
  }

  deleteAutomation(id: string) {
    return this.apiService.delete(`/v1/me/sms_automations/${id}`)
  }

  getBroadcasts(
    page: number = 1,
    limit: number = 999,
    start: string = null,
    end: string = null,
  ): Observable<BroadcastsResponse> {
    if (start && end) {
      return this.apiService.get(`/v1/me/broadcast_sms?page=${page}&limit=${limit}&start_date=${start}&end_date=${end}`)
    } else {
      return this.apiService.get(`/v1/me/broadcast_sms?page=${page}&limit=${limit}`)
    }
  }

  deleteBroadcast(id: string) {
    return this.apiService.delete(`/v1/me/broadcast_sms/${id}`)
  }

  getOnboarding(): Observable<UserShop> {
    return this.apiService.get('/v1/onboarding')
  }

  getSubscribeEvents(page: number, limit: number, search = null, start_date?: string, end_date?: string): Observable<any> {
    let qparams = search ? `&search=${search}` : ''
    if (start_date && end_date) {
      qparams += `&start_date=${start_date}&end_date=${end_date}`
    }
    return this.apiService.get(`/v1/me/subscriptions?page=${page}&limit=${limit}${qparams}`)
  }

  getShopifySubscribers(page: number, limit: number, acceptsMarketing: boolean, search = null): Observable<any> {
    const _search = search ? `&search=${search}` : ''
    if (acceptsMarketing) {
      return this.apiService.get(`/v1/me/shop/customers?page=${page}&limit=${limit}&accepts_marketing=${acceptsMarketing}${_search}`)
    } else {
      return this.apiService.get(`/v1/me/shop/customers?page=${page}&limit=${limit}${_search}`)
    }
  }

  getStripeInvoices(page: number, limit: number): Observable<any> {
    return this.apiService.get(`/v1/me/stripe_invoice?page=${page}&limit=${limit}`)
  }

  getShopAccessScopes(): Observable<UserShopAccessScope> {
    return this.apiService.get('/v1/me/shop/access_scopes').pipe(map((res: UserShopAccessScopeApiResponse) => {
      let accessScopes: UserShopAccessScope = {} as UserShopAccessScope
      if (res.access_scopes && res.access_scopes.length > 0) {
        res.access_scopes.forEach((scope: string) => {
          accessScopes[scope] = true
        })
      }
      return accessScopes
    }))
  }

  getShopPostPurchaseAppStatus(): Observable<any> {
    return this.apiService.get('/v1/me/shop/post_purchase_app')
  }
}
