import { Injectable } from '@angular/core'
import { BehaviorSubject, combineLatest, forkJoin, Observable, of } from 'rxjs'
import { LoadingOverlayService } from './loading-overlay.service'
import { filter, finalize, map, take, tap } from 'rxjs/operators'
import { select, Store } from '@ngrx/store'
import { StoreState } from '../../store/store.state'
import { Logger } from './logger.service'
import { SetCurrentUserInfo } from '../../store/user/user.actions'
import { HideLoading, ShowLoading } from '../../store/loading/loading.actions'
import { environment } from '../../../environments/environment'
import { LoadingLabel } from '../../shared/models/loading/loading-label'
import { UserShopType } from '../../shared/models/user/user-shop-type.model'
import { EnvironmentName } from '../../../environments/models/environment-name.model'
import { PromoService } from '../../shared/modules/promo/services/promo.service'
import { UserInfo, UserUsageInfo } from '../../store/user/user.state'
import { getUserInfo } from '../../store/user/user.selectors'
import * as _ from 'lodash'
import * as Sentry from '@sentry/browser'
import { AdminUserTokenService } from './admin-user-token.service'
import { SegmentAnalyticsService } from '../../shared/services/segment-analytics.service'
import { ApiService } from './api/api.service'
import { LogLabel } from '../../shared/models/logger/log-label.model'
import { GettingStartedInteractions } from '../../shared/models/getting-started/getting-started-interactions.model'
import moment from 'moment'
import { ConveyThis } from '../interfaces/conveythis.model'
import { ShopifyService } from './shopify.service'
import { SafeLocalStorageService } from './safe-local-storage.service'
import { UserRole } from '../../shared/models/user/user.model'
import { RouteHeaderUrl } from '../../shared/components/one-header/header-navigation.model'
import { UserSubscription } from '../../shared/models/user/user-subscription.model'
import { ShopSwitchService } from '../../shared/components/one-side-nav/one-shop-switch/services/one-shop-switch.service'
import { ShopifyShopTokenService } from './shopify-shop-token.service'
import { CartApiService } from '../../pages/cart/services/cart-api.service'
import { StripeCustomer } from '../../pages/cart/models/cart-models'
import { PlansV3Identifier } from '../../shared/models/subscription-plan.model'

declare const $crisp: any
declare const Canny: Function

@Injectable()
export class UserService {

  readonly unlayerLocale = {
    ar: {language: 'Arabic', region: 'UAE', code: 'ar-AE'},
    zh: {language: 'Chinese', region: 'Taiwan', code: 'zh-TW'},
    cs: {language: 'Czech', region: 'Czech Republic', code: 'cs-CZ'},
    da: {language: 'Danish', region: 'Denmark', code: 'da-DA'},
    nl: {language: 'Dutch', region: 'Netherlands', code: 'nl-NL'},
    en: {language: 'English', region: 'USA', code: 'en-US'},
    et: {language: 'Estonian', region: 'Estonia', code: 'et-EE'},
    fa: {language: 'Farsi', region: 'Iran', code: 'fa-IR'},
    fi: {language: 'Finnish', region: 'Finland', code: 'fi-FI'},
    fr: {language: 'French', region: 'France', code: 'fr-FR'},
    de: {language: 'German', region: 'Germany', code: 'de-DE'},
    id: {language: 'Indonesian', region: 'Indonesia', code: 'id-ID'},
    it: {language: 'Italian', region: 'Italy', code: 'it-IT'},
    ja: {language: 'Japanese', region: 'Japan', code: 'ja-JP'},
    ko: {language: 'Korean', region: 'Korea', code: 'ko-KR'},
    no: {language: 'Norwegian', region: 'Norway', code: 'no-NO'},
    pl: {language: 'Polish', region: 'Poland', code: 'pl-PL'},
    pt: {language: 'Portuguese', region: 'Portugal', code: 'pt-PT'},
    ru: {language: 'Russian', region: 'Russia', code: 'ru-RU'},
    es: {language: 'Spanish', region: 'Spain', code: 'es-ES'},
    sv: {language: 'Swedish', region: 'Sweden', code: 'sv-SE'},
    tr: {language: 'Turkish', region: 'Turkey', code: 'tr-TR'},
    uk: {language: 'Ukrainian', region: 'Ukraine', code: 'uk-UA'},
    vi: {language: 'Vietnamese', region: 'Vietnam', code: 'vi-VN'},
  }

  userInfo: UserInfo = null
  userUsageInfo: UserUsageInfo = null
  contactsTotal: number = null
  activeKnownCustomers: number = null
  newPricingUpdateModalData = null
  stripeCustomer: StripeCustomer = null
  stripeCustomerId: string = null

  interactions: GettingStartedInteractions

  userRoles = UserRole

  isPostPurchaseAppInUse = false

  // adding behaviorsubject to use instead of the store getUserInfo
  userInfo$ = new BehaviorSubject<UserInfo>(null)
  userUsageInfo$ = new BehaviorSubject<UserUsageInfo>(null)
  interactions$ = new BehaviorSubject<GettingStartedInteractions>(null)

  // null: - shop user request is in progress
  // false: - not a shop user
  // true: - shop user request is done
  shopUserRequestDone$ = new BehaviorSubject<boolean>(null)

  metabase_dashboard_name = '103-one-store-profiles-beta'

  constructor(
    private apiService: ApiService,
    private logger: Logger,
    private loadingOverlay: LoadingOverlayService,
    private store: Store<StoreState>,
    private promoService: PromoService,
    private adminUserTokenService: AdminUserTokenService,
    private segmentAnalyticsService: SegmentAnalyticsService,
    private shopifyService: ShopifyService,
    private shopSwitchService: ShopSwitchService,
    private cartApiService: CartApiService,
    private safeLocalStorageService: SafeLocalStorageService,
    private shopifyShopTokenService: ShopifyShopTokenService,
  ) {
    this.store.pipe(
      select(getUserInfo),
      filter(next => !!next),
    ).subscribe((userInfo) => {
      if (!this.userInfo) {
        this.userInfo = userInfo
      } else {
        this.userInfo = {...this.userInfo, ...userInfo}
      }
      this.userInfo$.next(this.userInfo)
      // if shop_user is not defined either populate it from localstorage directly and/or use ID if available to request from backend
      if (!this.userInfo.shop_user) {
        const lsShopUserId = JSON.parse(this.safeLocalStorageService.getItem('pf-shopify-shop-user-id'))
        const lsShopUser = JSON.parse(this.safeLocalStorageService.getItem('pf-shopify-shop-user'))
        if (lsShopUser) {
          this.userInfo = {...this.userInfo, shop_user: lsShopUser}
          this.userInfo$.next(this.userInfo)
          if (this.userInfo.shop_user?.id) {
            this.getShopifyShopUser(this.userInfo.shop_user?.id).subscribe()
          }
        } else if (lsShopUserId) {
          this.getShopifyShopUser(lsShopUserId).subscribe()
        } else {
          this.shopUserRequestDone$.next(false)
        }
      }
      if (userInfo?.shop?.type === 'ShopifyShop') {
        // update contacts_count for shopify users
        combineLatest([
          this.shopifyService.contactsCount$.pipe(filter(next => next !== null), take(1)),
          this.shopifyService.activeKnownCustomersCount$.pipe(filter(next => next !== null), take(1)),
        ]).pipe(
          finalize(() => this.setUserData(userInfo)),
        ).subscribe(([contactsTotal, activeKnownCustomers]) => {
          this.contactsTotal = contactsTotal
          this.activeKnownCustomers = activeKnownCustomers
        })
        // get stores list for shopify users
        this.shopSwitchService.getStores()
      } else {
        this.setUserData(userInfo)
      }
 
    })
  }

  private setUserData(userInfo) {
    const isAdminAsUser = this.adminUserTokenService.getValue()
    const isShopifyStoreUser = this.shopifyShopTokenService.getValue()

    if (!isAdminAsUser && !isShopifyStoreUser) {
      if (userInfo.email && userInfo.id) {
        const segmentData: any = {
          email: userInfo.email,
          auth_provider: userInfo.auth_provider,
          first_name: _.get(userInfo, 'profile.first_name'),
          last_name: _.get(userInfo, 'profile.last_name'),
          plan: _.get(userInfo, 'subscription.plan.payment_gateway_plan_identifier'),
          shop: _.get(userInfo, 'shop.domain'),
          shopify_plan: _.get(userInfo, 'shop.profile.plan_name'),
          country_code: _.get(userInfo, 'country_code'),
          ...((this.contactsTotal === 0 || this.contactsTotal > 0) && {contacts_total: this.contactsTotal}),
        }

        const priceSpend = _.get(userInfo, 'subscription.plan.price.fractional') ? (+_.get(userInfo, 'subscription.plan.price.fractional') / _.get(userInfo, 'subscription.plan.price.currency.subunit_to_unit')) : 0
        const cannyUserData: any = {
          id: userInfo.id,
          email: userInfo.email,
          name: _.get(userInfo, 'profile.first_name', '') + ' ' + _.get(userInfo, 'profile.last_name', ''),
          companies: [
            {
              id: _.get(userInfo, 'shop.id'),
              name: _.get(userInfo, 'shop.domain'),
              created: _.get(userInfo, 'shop.created_at'),
              // aware customFields can not be nested objects
              customFields: {
                one_plan: _.get(userInfo, 'subscription.plan.payment_gateway_plan_identifier'),
                one_plan_name:  _.get(userInfo, 'subscription.plan.name'),
                one_plan_description: _.get(userInfo, 'subscription.plan.description'),
                one_plan_anchor: _.get(userInfo, 'subscription.usage_plan.metric'),
                one_plan_anchor_range: _.get(userInfo, 'subscription.usage_plan.title'),
                one_plan_price: priceSpend,
                one_plan_interval: _.get(userInfo, 'subscription.plan.interval', ''),
                ...((this.activeKnownCustomers === 0 || this.activeKnownCustomers > 0) && { active_known_customers: this.activeKnownCustomers }),
              },
              ...(_.get(userInfo, 'subscription.plan.interval') === 'month' ? { monthlySpend: priceSpend } : {}),
              ...(_.get(userInfo, 'subscription.plan.interval') === 'year' ? { yearlySpend: priceSpend } : {})
            }
          ],
          created: userInfo.created_at,
        }

        // identify user for segment
        this.segmentAnalyticsService.identify(userInfo.id, segmentData)

        // identify user for Canny
        // Canny('identify', {
        //   appID: '60d9bb5b4f6653148925201c',
        //   user: cannyUserData,
        // })

        // identify user for logrocket
        // LogRocket.identify(userInfo.id, {
        //   name: userInfo?.profile.first_name,
        //   email: userInfo.email,
        //   plan: userInfo?.subscription.plan.payment_gateway_plan_identifier,
        // })
        // identify user for fullstory
        // FullStory.identify(userInfo.id, {
        //   displayName: _.get(userInfo, 'profile.first_name'),
        //   email: userInfo.email,
        //   plan: _.get(userInfo, 'subscription.plan.payment_gateway_plan_identifier'),
        // })
      }


      Sentry.configureScope((scope) => {
        scope.setUser({
          email: userInfo.email,
          id: userInfo.auth_provider_id,
        })
        scope.setExtra('plan', _.get(userInfo, 'subscription.plan.payment_gateway_plan_identifier'))
      })
      this.updateCrispData(userInfo, this.contactsTotal)

      /* If the FullStory session URL is set then update the Sentry Scope */
      // if (FullStoryService.getCurrentSessionURL()) {
      //   Sentry.configureScope(scope => {
      //     scope.setExtra('sessionURL', FullStoryService.getCurrentSessionURL())
      //   })
      // }

      /* Also Set the Sentry scope using the Full Story Callback */
      // window['_fs_ready'] = () => {
      //   Sentry.configureScope(scope => {
      //     scope.setExtra('sessionURL', FullStoryService.getCurrentSessionURL())
      //   })

      //   this.updateCrispData(userInfo, this.contactsTotal)
      // }
    }
  }

  readOnBoarding() {
    return this.loadingOverlay.wrap(this.apiService.get('/v1/onboarding'), LoadingLabel.OnBoardingStatus)
  }

  readInteractions(): Observable<GettingStartedInteractions> {
    return this.loadingOverlay.wrap(this.apiService.get('/v1/me/interactions').pipe(tap((data: any) => {
      this.interactions = data
      this.interactions$.next(data)
      if (data?.new_pricing_update_modal) {
        this.newPricingUpdateModalData = data.new_pricing_update_modal
      }
    })), 'readInteractions')
  }

  readUserUsage(): Observable<UserUsageInfo> {
    return this.apiService.get('/v1/me/usage').pipe(tap((data: UserUsageInfo) => {
      this.userUsageInfo = data
      this.userUsageInfo$.next(data)
    }))
  }

  getHeaderSnippetCode(accountId) {
    let src = null
    if (environment.name === EnvironmentName.DEVELOPMENT) {
      src = `${environment.widgetURL}?account_id=${accountId}`
    } else {
      src = `https://${environment.firebase.cdnUrl}/javascript/dist/1.0/jcr-widget.js?account_id=${accountId}`
    }
    return `<script defer type='text/javascript' src='${src}'></script>`
  }

  updateUserUpsell(eligible = true): Observable<any> {
    return this.loadingOverlay.wrap(this.apiService.patch('/v1/me', {upsell: {upsell_eligible: eligible}}).pipe(tap(response => {
      this.userInfo = {...this.userInfo, ...response}
      this.userInfo$.next(this.userInfo)
      this.store.dispatch(new SetCurrentUserInfo(this.userInfo))
      setTimeout(() => {
        this.setUserData(this.userInfo)
      })
    })), 'updateUpsellEligibility')
  }

  getSnippetCode(accountId) {
    let src = null
    if (environment.name === EnvironmentName.DEVELOPMENT) {
      src = `${environment.widgetURL}?account_id=${accountId}`
    } else {
      src = `https://${environment.firebase.cdnUrl}/javascript/dist/1.0/jcr-widget.js?account_id=${accountId}`
    }
    if (_.get(this.userInfo, 'shop', false)) {
      return `<script defer type='text/javascript' src='${src}'></script>`
    } else {
      const embedded_href = `<a style='font-size: 8px;color: #19191f36;text-decoration: none;' href='https://prooffactor.com' target='_blank'>Powered by ProofFactor - Social Proof Notifications</a>`
      return `<div style='position: relative;bottom: 0;left: 0;' id='juicier-container' data-account-id='${accountId}'>${embedded_href}</div><script type='text/javascript' src='${src}'></script>`
    }
  }

  getEmbeddedRedirectUrl(): string {
    const domainName = this.userInfo?.shop?.profile?.myshopify_domain?.replace('.myshopify.com', '')
    return `https://admin.shopify.com/store/${domainName}/apps/${environment.shopify.clientId}${window.location.pathname}`
  }

  getEmbeddedSuccessUrl(userType: 'new' | 'returning' | 'existing'): string {
    const domainName = this.userInfo?.shop?.profile?.myshopify_domain?.replace('.myshopify.com', '')
    const intent_url = this.getEmbeddedRedirectUrl()
    let successUrl = `app/${RouteHeaderUrl.settings}/${RouteHeaderUrl.billing}/${RouteHeaderUrl.subscriptions}/${RouteHeaderUrl.checkout}/${RouteHeaderUrl.success}?payment_gateway=shopify&intent_url=${intent_url}`
    switch (userType) {
      case 'new':
        successUrl = `app/onboarding/signup/new/subscriptions/checkout/success?payment_gateway=shopify&intent_url=${intent_url}`
        break
      case 'returning':
        successUrl = `app/onboarding/signup/returning/subscriptions/checkout/success?payment_gateway=shopify&intent_url=${intent_url}`
        break
    }
    return `https://admin.shopify.com/store/${domainName}/apps/${environment.shopify.clientId}/${successUrl}`
  }

  getShopifySuccessUrl(userType: 'new' | 'returning' | 'existing'): string {
    const app = new URL(document.baseURI).href.includes('/app/') ? 'app/' : ''
    let successUrl = `${app}${RouteHeaderUrl.settings}/${RouteHeaderUrl.billing}/${RouteHeaderUrl.subscriptions}/${RouteHeaderUrl.checkout}/${RouteHeaderUrl.success}?payment_gateway=shopify&intent_url=${window.location.href}`
    switch (userType) {
      case 'new':
        successUrl = `${app}onboarding/signup/new/subscriptions/checkout/success?payment_gateway=shopify&intent_url=${window.location.href}`
      case 'returning':
        successUrl = `${app}onboarding/signup/returning/subscriptions/checkout/success?payment_gateway=shopify&intent_url=${window.location.href}`
    }
    return `${window.location.origin}/${successUrl}`
  }

  getAltSnippetCode(accountId) {
    let src = null
    if (environment.name === EnvironmentName.DEVELOPMENT) {
      src = `${environment.widgetURL}?account_id=${accountId}`
    } else {
      src = `https://${environment.firebase.cdnUrl}/javascript/dist/1.0/jcr-widget.js?account_id=${accountId}`
    }
    return `<script>
    function attemptToInitializeProofFactorWithEmbed() {
      if (document.getElementById('juicier-container') === null && document.body && window['PROOF_FACTOR_WIDGET_SCRIPT_LOADED'] === undefined || window['PROOF_FACTOR_WIDGET_SCRIPT_LOADED'] === false) {
        var proofDiv = document.createElement('div');
        proofDiv.id = 'juicier-container';
        proofDiv.setAttribute('data-account-id', '${accountId}');
        proofDiv.setAttribute('style', 'position: relative;bottom: 0;left: 0;');
        var proofJS = document.createElement('script');
        proofJS.type = 'text/javascript';
        proofJS.src = '${src}';
        proofJS.setAttribute('defer', '');
        document.body.appendChild(proofDiv);
        document.body.appendChild(proofJS);
      }
    }
    attemptToInitializeProofFactorWithEmbed();
    document.addEventListener('DOMContentLoaded', function (event) {
    attemptToInitializeProofFactorWithEmbed();
    });
</script>`
  }

  getUserSubscription(): Observable<UserSubscription> {
    return this.apiService.get('/v1/me/subscription')
      .pipe(tap((subscription: UserSubscription) => {
        this.userInfo = {...this.userInfo, subscription}
        this.userInfo$.next(this.userInfo)
        this.store.dispatch(new SetCurrentUserInfo(this.userInfo))
      }))
  }

  readUser() {
    this.store.dispatch(new ShowLoading('readUser'))
    return forkJoin([
      this.apiService.getObserve('/v1/me', null, 'response'),
      this.apiService.get('/v1/me/subscription')
    ]).pipe(
      map(([response, subscription]) => {
        //TODO: find better solution.
        // For some reason for new users subscription on store's info field update is not fired, when SetCurrentUserInfo
        // is dispatched
        this.userInfo = {...this.userInfo, ...response.body, subscription: subscription}
        this.userInfo$.next(this.userInfo)
        if (!this.userInfo.shop_user) {

          const lsShopUserId = JSON.parse(this.safeLocalStorageService.getItem('pf-shopify-shop-user-id'))
          const lsShopUser = JSON.parse(this.safeLocalStorageService.getItem('pf-shopify-shop-user'))
          if (lsShopUser) {
            this.userInfo = {...this.userInfo, shop_user: lsShopUser}
          } else if (lsShopUserId) {
            this.userInfo = {...this.userInfo, shop_user: {...this.userInfo.shop_user, id: lsShopUserId}}
          }
          this.userInfo$.next(this.userInfo)
        }
        setTimeout(() => {
          this.setUserData(this.userInfo)
        })
        this.logger.log(LogLabel.UserService, 'User:', this.userInfo)
        this.store.dispatch(new SetCurrentUserInfo(this.userInfo))
        this.promoService.togglePromoState(response.body, true)
        const country = response.headers.get('proof-factor-user-country-code')
        this.saveUserCountry(country)
        return this.userInfo
      }),
      finalize(() => this.store.dispatch(new HideLoading('readUser')))
    )
  }


  getCurrentTranslationLanguage(): string {
    const conveythis: ConveyThis = window['conveythis']
    if (conveythis) {
      // Convert objet to map, otherwise it won't let us access the keys by id
      const allLangsMap = new Map(Object.entries(conveythis.languages))
      // find currently active language reference
      const currentLang = conveythis?.target_languages?.find(lang => lang.active) || {} as any
      // find the full language object by id
      const fullCurrentLang = allLangsMap.get(currentLang?.id?.toString())
      // get the Unlayer's locale
      const locale = this.unlayerLocale[fullCurrentLang?.code2]
      return locale?.code || 'en-US'
    } else {
      return 'en-US'
    }
  }

  saveUserCountry(country: string) {
    this.safeLocalStorageService.setItem('userCountry', country)
  }

  getUserCountry() {
    return this.safeLocalStorageService.getItem('userCountry')
  }

  updateCrispData(user: UserInfo, contactsTotal?: number) {
    if (typeof $crisp !== 'undefined') {
      try {
        if (user.email) {
          $crisp.push(['set', 'user:email', [user.email]])
        }
        if (_.get(user, 'shop.store_url')) {
          $crisp.push(['set', 'user:company', [user.shop.store_url, {
            url: user.shop.store_url,
            description: `Shop Type: ${user.shop.type}`,
          }]])
        }

        if (_.get(user, 'subscription.plan')) {
          $crisp.push(['set', 'session:data', ['plan', user.subscription.plan.name]])
        }

        if (user.shop) {
          $crisp.push(['set', 'session:data', ['shop_type', user.shop.type]])
          if (user.shop.store_url) {
            $crisp.push(['set', 'session:data', ['store_url', user.shop.store_url]])
          }
          if (user.shop.profile && (user.shop.profile.plan_display_name || user.shop.profile.plan_name)) {
            $crisp.push(['set', 'session:data', ['shopify_plan', user.shop.profile.plan_display_name || user.shop.profile.plan_name]])
          }
          if (contactsTotal === 0 || contactsTotal > 0) {
            $crisp.push(['set', 'session:data', ['contacts_total', contactsTotal]])
          }
        }
        if (user.id) {
          // ONE | Account | Login
          $crisp.push(['set', 'session:data', ['one_account_login_url', `https://shopify.one.store/app/admin?one_account_id=${user.id}`]])
          // ONE | Account | Profile
          $crisp.push(['set', 'session:data', ['one_account_profile_url', `https://metabase.morpheusone.com/dashboard/${this.metabase_dashboard_name}?one_account_id=${user.id}`]])
          // ONE | Account | Admin
          $crisp.push(['set', 'session:data', ['one_account_admin_url', `https://admin.one.store/users/${user.id}`]])
          // Shopify | Partners | Admin
          // $crisp.push(['set', 'session:data', ['shopify_partner_admin_url', `https://partners.shopify.com/823799/stores/{{shopify_store_id}}`]])
        }
        // check if defined before pushing or crisp will throw some errors in console
        // if (FullStoryService.getCurrentSessionURL()) {
        //   $crisp.push(['set', 'session:data', ['FullStorySessionURL', FullStoryService.getCurrentSessionURL()]])
        // }
        // LogRocket.getSessionURL(sessionURL => {
        //   $crisp.push(['set', 'session:data', ['logrocket_session_replay_url', sessionURL]])
        // })
        const flywheelSessionUrl = window['flywheel']?.getSessionURL()
        if (flywheelSessionUrl) {
          $crisp.push(['set', 'session:data', ['flywheel_session_replay_url', flywheelSessionUrl]])
        }
      } catch (e) {
        this.logger.error(LogLabel.UserService, e)
        Sentry.captureException(e)
      }
    }
  }

  public get smsCredits(): number {
    const usedCredits = _.get(this.userInfo, 'sms_marketing_account.credits_used', 0)
    const includedCredits = _.get(this.userInfo, 'sms_marketing_account.sms_credits_included', 0)
    const purchasedCredits = _.get(this.userInfo, 'sms_marketing_account.sms_credits_purchased', 0)
    const giftedCredits = _.get(this.userInfo, 'sms_marketing_account.sms_credits_gifted', 0)
    return (includedCredits + giftedCredits + purchasedCredits) - usedCredits
  }

  public get smsCreditsIsLow(): boolean {
    const minimumSmsCredits = 50
    return this.smsCredits <= minimumSmsCredits
  }

  readonly oldUserTime = '2022-09-28T00:00:00.000Z'

  public get isShopifyPlusUser(): boolean {
    return this.userInfo?.shop?.profile?.plan_name === 'shopify_plus'
  }

  public get shopifyPlanName(): string {
    if (this.isShopifyShop) {
      return this.userInfo?.shop?.profile?.plan_name
    } else {
      return null
    }
  }

  public get planIdentifier(): string {
    return this.userInfo?.subscription?.plan?.payment_gateway_plan_identifier
  }

  public get isNewPlanUser(): boolean {
    return this.isNewFreePlanUser || this.isNewStarterPlanUser || this.isNewBasicPlanUser || this.isNewProPlanUser || this.isNewEnterprisePlanUser || this.isNewCustomPlanUser
  }

  public get isNewPremiumPlanUser(): boolean {
    return this.isNewStarterPlanUser || this.isNewBasicPlanUser || this.isNewProPlanUser || this.isNewEnterprisePlanUser || this.isNewCustomPlanUser
  }

  public get isNewFreePlanUser(): boolean {
    return _.get(this.userInfo, 'subscription.plan.payment_gateway_plan_identifier').includes('new_free')
  }

  public get isNewV3PlanUser(): boolean {
    return _.get(this.userInfo, 'subscription.plan.payment_gateway_plan_identifier').includes('_v3')
  }

  public get isNewStarterPlanUser(): boolean {
    return _.get(this.userInfo, 'subscription.plan.payment_gateway_plan_identifier').includes('new_starter')
  }

  public get isNewBasicPlanUser(): boolean {
    return _.get(this.userInfo, 'subscription.plan.payment_gateway_plan_identifier').includes('new_basic')
  }

  public get isNewProPlanUser(): boolean {
    return _.get(this.userInfo, 'subscription.plan.payment_gateway_plan_identifier').includes('new_pro')
  }

  public get isNewEnterprisePlanUser(): boolean {
    return _.get(this.userInfo, 'subscription.plan.payment_gateway_plan_identifier').includes('new_enterprise')
  }

  public get isNewCustomPlanUser(): boolean {
    return _.get(this.userInfo, 'subscription.plan.payment_gateway_plan_identifier').includes('new_custom')
  }

  public get isLegacyPaidPlanUser(): boolean {
    return !_.get(this.userInfo, 'subscription.plan.payment_gateway_plan_identifier').includes('new_') && _.get(this.userInfo, 'subscription.plan.payment_gateway_plan_identifier') !== 'budding'
  }

  public get isLegacyBuddingPlanUser(): boolean {
    return _.get(this.userInfo, 'subscription.plan.payment_gateway_plan_identifier') === 'budding'
  }

  public get newPricingEligible(): boolean {
    return this.userInfo?.new_pricing_eligible || _.get(this.userInfo, 'subscription.plan.payment_gateway_plan_identifier', '').includes('_v3')
  }

  public get brandingPopupIsPremium(): boolean {
    const isOldUser = this.userInfo?.created_at && moment(this.userInfo?.created_at).isBefore(this.oldUserTime)
    return this.userInfo.remove_branding || _.get(this.userInfo, 'remove_popup_branding') || (this.isShopifyBudding && isOldUser)
  }

  public get brandingSpIsPremium(): boolean {
    const isOldUser = this.userInfo?.created_at && moment(this.userInfo?.created_at).isBefore(this.oldUserTime)
    return this.userInfo.remove_branding || _.get(this.userInfo, 'remove_sp_branding') || (this.isShopifyBudding && isOldUser)
  }

  public get brandingEmailIsPremium(): boolean {
    const oldUserPeriod = _.get(this.userInfo, 'email_branding_logo_settings.display_anchor')
    const isOldUser = this.userInfo?.created_at && moment(this.userInfo?.created_at).isBefore(oldUserPeriod)
    return this.userInfo.remove_branding || _.get(this.userInfo, 'remove_email_branding') || (this.isShopifyBudding && isOldUser)
  }

  public get userHasSeenPricingModal(): boolean {
    return !!this.newPricingUpdateModalData?.first_seen?.timestamp
  }

  public get pricingModalWasShown(): boolean {
    return !!this.newPricingUpdateModalData?.shown
  }

  public get userSentLargeEmailBroadcast(): boolean {
    return !!this.userInfo?.sent_large_broadcast_emails
  }

  public get isStripeNewCustomFreePlanUser(): boolean {
    return this.planIdentifier === PlansV3Identifier.NewCustomFree
  }

  public get isStripeNewCustomPaidPlanUser(): boolean {
    return this.planIdentifier === PlansV3Identifier.NewCustomPaid
  }

  public get isStripeCustomer(): boolean {
    return !!this.interactions?.stripe_billing?.subscription || this.isStripeNewCustomFreePlanUser || this.isStripeNewCustomPaidPlanUser   
  }

  get invoiceDueDatePassed(): boolean {
    const userInfo = this.userInfo
    const needToPayInvoice = userInfo?.custom_plan_invoice_due_date && userInfo?.custom_plan_invoice && !userInfo?.custom_plan_invoice_paid

    if (needToPayInvoice) {
      const currentDate = new Date()
      const invoiceDueDateDate = new Date(userInfo?.custom_plan_invoice_due_date)
      return currentDate > invoiceDueDateDate
    }
    return false
  }

  public get showPricingUpdateModal(): boolean {
    return this.userInfo?.show_pricing_update_modal
  }

  public get pricingModalDynamicFlow(): any {
    return this.newPricingUpdateModalData?.dynamic_flow || null
  }

  public get firstSeenPricingData(): any {
    const data = this.newPricingUpdateModalData?.first_seen || {}
    const dynamicFlow = this.pricingModalDynamicFlow || {}
    // if data is set for third_deadline in dynamic flow override first_seen data
    if (dynamicFlow?.third_coupon_deadline) {
      data.extended = true
      data.first_deadline = dynamicFlow.first_coupon_deadline
      data.first_coupon = dynamicFlow.first_coupon_code
      data.first_coupon_id = dynamicFlow.first_coupon_id
      data.second_deadline = dynamicFlow.second_coupon_deadline
      data.second_coupon = dynamicFlow.second_coupon_code
      data.first_coupon_id = dynamicFlow.second_coupon_id
      data.third_deadline = dynamicFlow.third_coupon_deadline
      data.third_coupon = dynamicFlow.third_coupon_code
      data.first_coupon_id = dynamicFlow.third_coupon_id
    } else if (data) {
      // saw first extended modal
      if (data.extended && !data.third_deadline) {
        data.first_deadline = '2023-01-27'
        data.first_coupon = 'GLASS_HALF_FULL'
        data.second_deadline = '2023-02-01'
        data.second_coupon = 'LOYALTYQUARTER'
        data.third_deadline = '2023-02-05'
        data.third_coupon = 'LOYALTEN'
      }
      // going forward will store deadlines & codes, so no need to populate here
    }
    return data
  }

  public get minimumCorrespondenceTime(): string {
    return _.get(this.userInfo, 'minimum_correspondence_time')
  }

  public get shopifyEmailSendingDelayed(): boolean {
    // If timestamp is set, then it's delayed till that time
    const minCorTime = this.minimumCorrespondenceTime
    if (minCorTime) {
      // Is it expired?
      return moment().isBefore(minCorTime)
    }
    // If not set, then it's not delayed
    return false
  }

  public get isShopifyBudding(): boolean {
    return this.isShopifyShop && this.isLegacyBuddingPlanUser
  }

  public get isShop(): boolean {
    return [
      UserShopType.ShopifyShop,
      UserShopType.BigCommerceShop,
      UserShopType.WooCommerceShop
    ].includes(this.userInfo?.shop?.type)
  }

  public get isShopifyShop(): boolean {
    return UserShopType.ShopifyShop === this.userInfo?.shop?.type
  }

  public isAdmin() {
    if (this.userInfo?.role) {
      return this.userInfo.role === this.userRoles[UserRole.admin] || this.userInfo.role === this.userRoles[UserRole.support]
    }
    return false
  }

  getShopName(shopType: string): string {
    switch (shopType) {
      case UserShopType.BigCommerceShop:
        return 'BigCommerce Shop'
      case UserShopType.ShopifyShop:
        return 'Shopify Shop'
      case UserShopType.WooCommerceShop:
        return 'WooCommerce Shop'
      default:
        return 'your shop'
    }
  }

  getShopTypeName(shopType: string): string {
    switch (shopType) {
      case UserShopType.BigCommerceShop:
        return 'BigCommerce Shop'
      case UserShopType.ShopifyShop:
        return 'Shopify Shop'
      case UserShopType.WooCommerceShop:
        return 'WooCommerce Shop'
      default:
        return 'Custom Shop'
    }
  }

  getAccountName() {
    let currentShopName
    if (this.userInfo && this.userInfo.shop) {
      if (this.userInfo.shop.profile && this.userInfo.shop.profile.name) {
        currentShopName = this.userInfo.shop.profile.name
      } else {
        const url = this.userInfo.shop?.store_url
        switch (this.userInfo.shop?.type) {
          case UserShopType.ShopifyShop:
            currentShopName = url.split('.')[0]
          case UserShopType.WooCommerceShop:
            currentShopName = url
              .replace('https://', '')
              .replace('http://', '')
              .replace('www.', '')
              .replace(/\/$/, '')
        }
        currentShopName = url
      }
    } else {
      currentShopName = ''
    }
    return currentShopName || this.userInfo?.email || 'Account'
  }

  getUserInfo() {
    return this.apiService.get('/v1/me').pipe(
      tap((response) => {
        this.logger.log(LogLabel.UserService, 'response:', response)
        this.store.dispatch(new SetCurrentUserInfo(response))
      }
    ))
  }

  updateUserEmailReceipts(email_receipts: boolean): Observable<any> {
    return this.apiService.put('/v1/me', {
      email_receipts
    }).pipe(
      tap((response) => {
        this.logger.log(LogLabel.UserService, 'response:', response)
        this.store.dispatch(new SetCurrentUserInfo({...this.userInfo, ...response}))
      }),
    )
  }

  updateUserShowEmailScheduleModal(show_email_scheduled_modal: boolean): Observable<any> {
    return this.apiService.put('/v1/me', {
      show_email_scheduled_modal
    }).pipe(
      tap((response) => {
        this.logger.log(LogLabel.UserService, 'response:', response)
        this.store.dispatch(new SetCurrentUserInfo({...this.userInfo, ...response}))
      }),
    )
  }

  updateUserProfile(data: UserInfo) {
    return this.apiService.put('/v1/me', {
      profile: data.profile,
      sms_app_settings: data.sms_app_settings,
    }).pipe(
      tap((response) => {
        this.logger.log(LogLabel.UserService, 'response:', response)
        this.store.dispatch(new SetCurrentUserInfo({...this.userInfo, ...response}))
      }),
    )
  }

  updatePlatformOnboarding(data) {
    return this.apiService.put('/v1/me', {
      onboarding: data
    }).pipe(
      tap((response) => {
        this.logger.log(LogLabel.UserService, 'response:', response)
        this.store.dispatch(new SetCurrentUserInfo({...this.userInfo, ...response}))
      }),
    )
  }

  updatePricingModalShown(shown = true, data: any = { extended: false, count: 0 }, force = false) {
    const count = (this.firstSeenPricingData?.count || data.count || 0) + 1
    const dynamicFlow = this.pricingModalDynamicFlow || {}
    let payload: any = {
      shown,
      first_seen: {
        ...this.firstSeenPricingData, count
      },
      dynamic_flow: {
        ...dynamicFlow,
        engagement: {
          ...dynamicFlow.engagement,
          last_seen_at: new Date().toISOString(),
        }
      }
    }
    if (this.userInfo.shop_user) {
      payload.dynamic_flow.engagement.last_seen_by = {
        shop_user_id: this.userInfo.shop_user?.id,
        first_name: this.userInfo.shop_user?.shopify_shop_user?.first_name,
        last_name: this.userInfo.shop_user?.shopify_shop_user?.last_name,
        email: this.userInfo.shop_user?.shopify_shop_user?.email,
        phone: this.userInfo.shop_user?.shopify_shop_user?.phone,
      }
    }

    if (!this.userHasSeenPricingModal || force) {
      payload = {
        ...payload,
        first_seen: {
          ...payload.first_seen,
          ...data,
          timestamp: this.firstSeenPricingData.timestamp || new Date().toISOString(),
        },
        dynamic_flow: {
          ...payload.dynamic_flow,
          engagement: {
            ...payload.dynamic_flow.engagement,
            first_seen_at: payload.dynamic_flow?.engagement?.first_seen_at || new Date().toISOString(),
          }
        }
      }
      if (this.userInfo.shop_user) {
        payload.dynamic_flow.engagement.first_seen_by = payload.dynamic_flow.engagement.last_seen_by
      }
    }
    return this.apiService.patch('/v1/me', {
      interactions: {
        new_pricing_update_modal: payload
      }
    }).pipe(
      tap((response) => {
        this.logger.log(LogLabel.UserService, 'response:', response)
        this.store.dispatch(new SetCurrentUserInfo({...this.userInfo, ...response}))
      }),
    )
  }

  readSites() {
    return this.apiService.get('/v1/me/sites')
  }

  postPurchaseAppInUse() {
    if (this.isPostPurchaseAppInUse) {
      return of(true)
    } else {
      return this.apiService.get('/v1/me/shop/post_purchase_app').pipe(
        tap(response => {
          // save positive value to reduce backend calls
        if (response?.app_in_use) {
            this.isPostPurchaseAppInUse = response.app_in_use
          }
        }),
        map(response => response?.app_in_use))
    }
  }

  getShopifyShopUser(shopifyUserId: string) {
    this.store.dispatch(new ShowLoading('getShopifyUser'))
    return this.apiService.get(`/v1/me/shop/shop_users/${shopifyUserId}`).pipe(
      tap({
        next: (response) => {
          this.userInfo = {...this.userInfo, shop_user: response}
          this.userInfo$.next(this.userInfo)
          this.safeLocalStorageService.setItem('pf-shopify-shop-user', JSON.stringify(response))
          this.store.dispatch(new HideLoading('getShopifyUser'))
          this.store.dispatch(new SetCurrentUserInfo(this.userInfo))
          setTimeout(() => {
            this.setUserData(this.userInfo)
            this.shopUserRequestDone$.next(true)
          })
        }, error: err => {
          // FIXME:
          this.store.dispatch(new HideLoading('getShopifyUser'))
        }
      })
    )
  }

  updateShopfiyShopUser(payload, shopifyUserId: string = this.userInfo.shop_user?.id) {
    this.store.dispatch(new ShowLoading('setShopifyUser'))
    return this.apiService.put(`/v1/me/shop/shop_users/${shopifyUserId}`, payload).pipe(
      tap((response) => {
        this.userInfo = {...this.userInfo, shop_user: response}
        this.userInfo$.next(this.userInfo)
        this.safeLocalStorageService.setItem('pf-shopify-shop-user', JSON.stringify(response))
        this.store.dispatch(new HideLoading('setShopifyUser'))
        this.store.dispatch(new SetCurrentUserInfo(this.userInfo))
        setTimeout(() => {
          this.setUserData(this.userInfo)
          this.shopUserRequestDone$.next(true)
        })
      }, err => {
        // FIXME:
        this.store.dispatch(new HideLoading('setShopifyUser'))
      })
    )
  }

  sendPhoneVerification(phone) {
    return this.apiService.post('/v1/me/verify_phone', {phone})
  }

  checkPhoneVerification(phone, code) {
    return this.apiService.post('/v1/me/verify_phone/check', {phone, code})
  }

  getOnboardingIntentUrl(intent: string) {
    let url = `/${RouteHeaderUrl.apps}`
    if (['Social Proof', 'Announcement Pop Up', 'Subscribe Pop Up', 'Spin-to-Win Game'].includes(intent)) {
      url = `/${RouteHeaderUrl.popups}/${RouteHeaderUrl.campaigns}`
    } else if (['Email Marketing', 'Cart Recovery'].includes(intent)) {
      url = `/${RouteHeaderUrl.messaging}/${RouteHeaderUrl.emails}/${RouteHeaderUrl.campaigns}`
    } else if (intent.includes('SMS')) {
      url = `/${RouteHeaderUrl.messaging}/${RouteHeaderUrl.sms}/${RouteHeaderUrl.campaigns}`
    } else if (intent.includes('Upsell')) {
      url = `/${RouteHeaderUrl.upsell}`
    } else if (intent.includes('CDP')) {
      url = `/${RouteHeaderUrl.customer_data}`
    } else if (intent.includes('PDP')) {
      url = `/${RouteHeaderUrl.products}`
    } else if (intent.includes('Discount')) {
      url = `/${RouteHeaderUrl.rewards}/${RouteHeaderUrl.coupons}`
    } else if (intent === 'Gift Cards') {
      url = `/${RouteHeaderUrl.rewards}/${RouteHeaderUrl.gift_cards}`
    } else if (intent === 'AI Sidekick') {
      url = `/${RouteHeaderUrl.oneGPT}`
    }
    return url
  }
}
