const hi_IN = {
  Quantity: 'मात्रा',
  Size: 'आकार',
  Color: 'रंग',
  Material: 'सामग्री',
  Style: 'शैली',
  Savings: 'बचत',
  Subtotal: 'उप-योग',
  Shipping: 'शिपिंग',
  Taxes: 'करों',
  Total: 'कुल',
  Free: 'मुक्त',
  Buy: 'खरीदना',
  'Pay Now': 'अब भुगतान करें',
  'Take the Deal': 'डील लें',
  'Add Now': 'अभी जोड़ें',
  'You will be charged immediately': 'आपसे तुरंत शुल्क लिया जाएगा',
  'Decline Offer': 'ऑफर नकारना',
  'No Thanks': 'जी नहीं, धन्यवाद',
  '{price}': '{कीमत}',
  'Sold Out': 'बिक चुका है',
  'see more': 'और देखें',
  less: 'कम',
};
export {hi_IN};
