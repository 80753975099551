import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot } from '@angular/router'
import { Observable } from 'rxjs'
import { ApiService } from './api/api.service'
import { UserInfo } from '../../store/user/user.state'

@Injectable({providedIn: 'root'})
export class UserInfoResolver  {

  constructor(
    private apiService: ApiService,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<UserInfo> {
    return this.apiService.get('/v1/me')
  }
}
