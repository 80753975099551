import { BrandingService } from './../../../../core/services/branding.service';
import { Injectable } from '@angular/core'
import { CampaignPluginName } from '../../../models/campaign/campaign'
import { ApiService } from '../../../../core/services/api/api.service'
import { MarketingEmail } from '../../../models/campaign/marketing-email'
import { ShowLoading, HideLoading } from '../../../../store/loading/loading.actions'
import { StoreState } from '../../../../store/store.state'
import { map, tap } from 'rxjs/operators'
import { Store } from '@ngrx/store'
import _ from 'lodash'
import { UnlayerTemplateResponse } from '../../../../pages/email-builder/models/email-editor.models'
import { UserService } from '../../../../core/services/user.service'

export interface PayloadToggleActive {
  scheduling_method: string
  scheduling_value: number
  scheduling_date: string
  template_id: number
  template_html: any
  template_json: any
  active: boolean
  address_id: string
}

export enum MarketingEmailLabel {
  fetchAll = 'MarketingEmailApi.fetchAll',
  fetchOne = 'MarketingEmailApi.fetchOne',
  delete = 'MarketingEmailApi.delete',
  add = 'MarketingEmailApi.add',
  update = 'MarketingEmailApi.update',
  toggle = 'MarketingEmailApi.toggle',
  getUnlayerTemplates = 'MarketingEmailApi.getUnlayerTemplates',
  sendTestEmail = 'MarketingEmailApi.sendTestEmail',
  fetchDomains = 'MarketingEmailApi.fetchDomains',
  createAutoResponders = 'MarketingEmailApi.createAutoResponders',
}

@Injectable()
export class MarketingEmailsApiService {

  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private store: Store<StoreState>,
    private brandingService: BrandingService
  ) {
  }

  fetchAll(campaignId: string, pluginType: CampaignPluginName) {
    this.store.dispatch(new ShowLoading(MarketingEmailLabel.fetchAll))
    return this.apiService.get(`/v1/campaigns/${campaignId}/${pluginType}/marketing_emails?date_range_type=all_time`).pipe(
      tap(() => {
        this.store.dispatch(new HideLoading(MarketingEmailLabel.fetchAll))
      }),
    )
  }

  fetchMarketingEmail(campaignId: string, pluginType: CampaignPluginName, id: string) {
    this.store.dispatch(new ShowLoading(MarketingEmailLabel.fetchOne))
    return this.apiService.get(`/v1/campaigns/${campaignId}/${pluginType}/marketing_emails/${id}`).pipe(
      tap((res) => {
        if (res.template_json?.body) {
          this.brandingService.setEmailBrandingLogoBody(res.template_json.body)
        }
        this.store.dispatch(new HideLoading(MarketingEmailLabel.fetchOne))
      }),
    )
  }

  fetchDomains() {
    this.store.dispatch(new ShowLoading(MarketingEmailLabel.fetchDomains))
    return this.apiService.get('/v1/me/email_domains').pipe(
      tap(() => {
        this.store.dispatch(new HideLoading(MarketingEmailLabel.fetchDomains))
      }),
    )
  }

  delete(campaignId: string, pluginType: CampaignPluginName, mailId: string) {
    this.store.dispatch(new ShowLoading(MarketingEmailLabel.delete))
    return this.apiService.delete(`/v1/campaigns/${campaignId}/${pluginType}/marketing_emails/${mailId}`).pipe(
      tap(() => {
        this.store.dispatch(new HideLoading(MarketingEmailLabel.delete))
      }, err => {
        this.store.dispatch(new HideLoading(MarketingEmailLabel.delete))
      }),
    )
  }

  add(campaignId: string, pluginType: CampaignPluginName, payload: any) {
    this.store.dispatch(new ShowLoading(MarketingEmailLabel.add))
    return this.apiService.post(`/v1/campaigns/${campaignId}/${pluginType}/marketing_emails/`, payload).pipe(
      tap(() => {
        this.store.dispatch(new HideLoading(MarketingEmailLabel.add))
      }, err => {
        this.store.dispatch(new HideLoading(MarketingEmailLabel.add))
      }),
    )
  }

  update(campaignId: string, pluginType: CampaignPluginName, mailId: string, payload: any) {
    this.store.dispatch(new ShowLoading(MarketingEmailLabel.update))
    return this.apiService.put(`/v1/campaigns/${campaignId}/${pluginType}/marketing_emails/${mailId}`, payload).pipe(
      tap(() => {
        this.store.dispatch(new HideLoading(MarketingEmailLabel.update))
      }, err => {
        this.store.dispatch(new HideLoading(MarketingEmailLabel.update))
      }),
    )
  }

  toggle(campaignId: string, pluginType: CampaignPluginName, mailId: string, mail: MarketingEmail) {
    const payload: PayloadToggleActive = {
      scheduling_method: mail.scheduling_method,
      scheduling_value: mail.scheduling_value,
      scheduling_date: mail.scheduling_date,
      template_id: mail.template_id,
      template_html: mail.template_html,
      template_json: mail.template_json,
      active: mail.active,
      address_id: mail.address_id
    }
    this.store.dispatch(new ShowLoading(MarketingEmailLabel.toggle))
    return this.apiService.put(`/v1/campaigns/${campaignId}/${pluginType}/marketing_emails/${mailId}`, payload).pipe(
      tap(() => {
        this.store.dispatch(new HideLoading(MarketingEmailLabel.toggle))
      }),
    )
  }

  private replaceBrandName(json: any, name: string) {
    let foundObj
    JSON.stringify(json, (_, nestedValue) => {
      if (nestedValue && nestedValue['brandName']) {
        foundObj = nestedValue;
      }
      return nestedValue;
    })
    if (foundObj && foundObj['brandName']) {
      foundObj['brandName'] = name
    }
  }

  getUnlayerTemplates() {
    this.store.dispatch(new ShowLoading(MarketingEmailLabel.getUnlayerTemplates))
    return this.apiService.get('/v1/unlayer_email_templates').pipe(
      map((res: UnlayerTemplateResponse) => {
        const shopName = _.get(this.userService.userInfo, 'shop.profile.name') || _.get(this.userService.userInfo, 'profile.company') || 'Your Company Name'
        // If it's a shop, then shop name will exist
        if (shopName) {
          // Inject shop name into the templates
          res.templates.forEach(template => {
            this.replaceBrandName(template.json['body'], shopName)
          })
          this.replaceBrandName(res.default_template.json['body'], shopName)
        }
        return res
      }),
      tap(() => {
        this.store.dispatch(new HideLoading(MarketingEmailLabel.getUnlayerTemplates))
      }),
    )
  }

  createAutoResponder(pluginType: CampaignPluginName, payload: {campaign_id: string, enabled: boolean}) {
    this.store.dispatch(new ShowLoading(MarketingEmailLabel.createAutoResponders))
    return this.apiService.post(`/v1/campaigns/${payload.campaign_id}/${pluginType}/marketing_emails/quick_setup`, payload).pipe(
      tap(() => {
        this.store.dispatch(new HideLoading(MarketingEmailLabel.createAutoResponders))
      }),
    )
  }

  sendTestEmail(campaignId: string, pluginType: CampaignPluginName, payload: any) {
    this.store.dispatch(new ShowLoading(MarketingEmailLabel.sendTestEmail))
    return this.apiService.post(`/v1/campaigns/${campaignId}/${pluginType}/follow_up_email/send_sample_email`, payload).pipe(
      tap(() => {
        this.store.dispatch(new HideLoading(MarketingEmailLabel.sendTestEmail))
      }),
    )
  }
}
