import { Component, OnInit, Inject } from '@angular/core'
import { Router } from '@angular/router'
import { CampaignService } from '../../../../core/services/campaign.service'
import { SubscriptionPlan } from '../../../models/subscription-plan.model'
import { CommonModule } from '@angular/common'
import { LoadingOverlayComponent } from '../../loading-overlay/loading-overlay.component'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

@Component({
    templateUrl: './select-plan-confirmation.modal.component.html',
    standalone: true,
    imports: [
      CommonModule,
      LoadingOverlayComponent,
    ]
})
export class SelectPlanConfirmationModalComponent implements OnInit {
  currentPlan: SubscriptionPlan = this.data.currentPlan
  newPlan: SubscriptionPlan = this.data.newPlan

  constructor(
    public dialogRef: MatDialogRef<SelectPlanConfirmationModalComponent>,
    protected campaignService: CampaignService,
    protected router: Router,
    @Inject(MAT_DIALOG_DATA) public data: { currentPlan: SubscriptionPlan, newPlan: SubscriptionPlan },
  ) {
    this.dialogRef.addPanelClass(['_no-padding'])
  }

  ngOnInit() {
  }

  submit() {
    this.dialogRef.close(true)
  }
}
