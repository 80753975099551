<h1 mat-dialog-title>
  {{ data.title || 'Are you sure you want to rename?'}}
  <button class="mat-dialog-close" (click)="dialogRef.close()">
    <span>&times;</span>
  </button>
</h1>
<div mat-dialog-content class="mb-2">
  <p *ngIf="data.text">{{data.text}}</p>
  <fieldset class="form-group">
    <input #input class="form-control" type="text" [formControl]="name" (keyup.enter)="accept()">
    <small class="text-danger" *ngIf="name.errors && name.errors.required">
      Name is required
    </small>
  </fieldset>
</div>
<div mat-dialog-actions class="mb-1 d-flex justify-content-end">
  <button [ngClass]="cancelButton.classes" (click)="dialogRef.close()">{{cancelButton.text}}</button>
  <button [ngClass]="acceptButton.classes" (click)="accept()">{{acceptButton.text}}</button>
</div>

<pf-loading-overlay></pf-loading-overlay>
