import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Observable, Subject, Subscription } from 'rxjs'
import { Store } from '@ngrx/store'
import { StoreState } from '../../../store/store.state'
import { HideLoading } from '../../../store/loading/loading.actions'
import { SavableUpsellModel } from '../models/savable-upsell.model'
import { ConfirmModalComponent, ConfirmModalConfig } from '../../../shared/components/modals/confirm.modal/confirm.modal.component'
import _ from 'lodash'
import { MatDialog } from '@angular/material/dialog'

@Injectable()
export class SavableUpsellGuard  {
  updateCampaignRequestSubscription: Subscription

  constructor(
    private dialog: MatDialog,
    private store: Store<StoreState>,
    private router: Router
  ) {}

  canDeactivate(component: SavableUpsellModel): boolean | Observable<boolean> {
    if (!component || !component.isChanged) {
      return true
    }

    // pushing old state to prevent url changes when clicking browser's back btn multiple times
    history.pushState(null, null, window.location.href)

    // If we press skip button, than we pass skipStep bool to route extras and the text in modal should change
    const routeState = this.router.getCurrentNavigation()?.extras?.state
    let dialogText
    if (routeState && routeState.skipStep) {
      dialogText = 'Are you sure you want to skip the step without saving?'
    } else if (component.upsellActive) {
      dialogText = 'This upsell campaign is live, are you sure you want to modify it?'
    } else if (component.upsellActive === false) {
      dialogText = 'Are you sure you want exit without saving?'
    } else {
      dialogText = 'Are you sure you want to see the next step without saving?'
    }
    const dialogCancelButtonText =  routeState && routeState.skipStep ? 'Skip' : 'Continue'
    const dialogTitle = component.upsellActive ? 'Modifying Active Upsell' : 'Upsell configuration has unsaved changes'
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      width: '560px',
      data: {
        title: dialogTitle,
        text: dialogText,
        cancelButton: {
          text: `${dialogCancelButtonText} without saving`,
          classes: 'pf-button filled red'
        },
        acceptButton: {
          text: 'Save and continue',
          classes: 'pf-button filled green'
        }
      } as ConfirmModalConfig,
    })

    const subject = new Subject<boolean>()
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        component.save(component.forceNavigation)
        // if forceNavigation is set clear it
        if (component.forceNavigation) {
          component.forceNavigation = false
        }

        // if component has form, check validation
        if (component.form) {

          // if form is invalid, we don't want to continue
          if(!component.form.valid || component.skipError) {
            return
          }
        }
        // Complete if nothing above interrupted
        this._completeSubj(subject)

      } else if (result === null || result === undefined) {
        this.store.dispatch(new HideLoading('UpsellSubmit'))
        return
      } else {
        this._completeSubj(subject)
      }
      // return subject.asObservable()
    })

    return subject.asObservable()
  }

  private _completeSubj(subject: Subject<boolean>) {
    setTimeout(() => {
      subject.next(true)
      subject.complete()
    }, 1000)
  }
}
