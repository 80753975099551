export enum PromoParam {
  promo_code = 'promo_code',
  promo_type = 'promo_type',
  promo_referrer = 'promo_referrer',
  promo_view = 'promo_view',
}

export interface Promo {
  [PromoParam.promo_code]: string,
  // promo_type defines which promo template should be shown
  [PromoParam.promo_type]: string,
  [PromoParam.promo_referrer]: string,
  // promo_view (if defined) overrides promo_type
  [PromoParam.promo_view]: string,
}

export enum PromoType {
  partner = 'partner',
  partner_promo = 'partner_promo',
  partner_bfcm_promo = 'partner_bfcm_promo',
  partner_black_friday_promo = 'partner_black_friday_promo',
  partner_cyber_week_promo = 'partner_cyber_week_promo',
  partner_cyber_monday_promo = 'partner_cyber_monday_promo',
  partner_client = 'partner_client',
  partner_list = 'partner_list',
  bfcm = 'bfcm',
  black_friday = 'black_friday',
  cyber_week = 'cyber_week',
  cyber_monday = 'cyber_monday',
  startup_program = 'startup_program',
  live_preview = 'live_preview',
  live_preview_wheel = 'live_preview_wheel',
  live_preview_subscribe_box = 'live_preview_subscribe_box',
  live_preview_social_proof = 'live_preview_social_proof',
}

export enum PromoView {
  partner = 'partner',
  bfcm = 'bfcm',
  black_friday = 'black_friday',
  cyber_week = 'cyber_week',
  cyber_monday = 'cyber_monday',
  special_offer = 'special_offer',
  scholarship = 'scholarship',
  spin_to_win = 'spin_to_win',
}
