<div class="breadcrumbs conveythis-no-translate">
  <div class="SectionTitle mb-0 d-flex">
    <a [routerLink]="backBtnUrl" class="pf-options-button mr-8px">
      <one-icon name="arrow-back-outline"></one-icon>
    </a>
  </div>
  <!-- <div class="breadcrumbs-item flex-shrink-0" [routerLink]="'/'+RouteHeaderUrl.popups+'/'+RouteHeaderUrl.campaigns">
    <label class="breadcrumbs-item-title">
      <span class="title">Campaigns</span>
    </label>
  </div> -->
  <div
    (mouseenter)="isCampaignLevel ? adjustMenuWidth($event) : null"
    (mouseleave)="collapseSubMenus()"
    [class._active]="isCampaignLevel"
    class="breadcrumbs-item">
    <label class="breadcrumbs-item-title" (click)="navigateToCurrentCampaign()">
      <!-- <img class="mr-1" src="./app_assets/icons/apps-icon.svg" alt=""> -->
      <span class="title">{{currentCampaignName}}</span>
      <span class="arrow-icon" *ngIf="isCampaignLevel && campaignList.length >= 1"></span>
    </label>
    <ul class="menu" *ngIf="isCampaignLevel && campaignList.length >= 1" #menu>

      <ng-container *ngFor="let campaign of campaignList; let i = index">
        <li class="menu-item"
            *ngIf="campaignsActiveWidgets[campaign.id] && campaignsActiveWidgets[campaign.id].length"
            (click)="navigateToCampaign(campaign.id)">
          <label>
           <span
             *ngIf="campaign.name.length > 30; else campaignName"
             matTooltipClass="Tooltip-Box"
             matTooltipPosition="left"
             [matTooltip]="campaign.name"
           >
              {{campaign.name}}
            </span>
            <ng-template #campaignName>
              {{campaign.name}}
            </ng-template>
          </label>
        </li>
      </ng-container>
    </ul>
  </div>
  <div *ngIf="!isCampaignLevel"
       (mouseenter)="adjustMenuWidth($event)"
       (mouseleave)="collapseSubMenus()"
       class="breadcrumbs-item _separated _active flex-shrink-0">
    <label class="breadcrumbs-item-title">
      <img src="/app_assets/icons/{{selectedPluginIcon}}.svg" alt="">
      <span class="title">{{pluginName}}</span>
      <span
        class="arrow-icon"
        *ngIf="campaignList.length >= 1">
      </span>
    </label>
    <ul class="menu" *ngIf="campaignList.length >= 1" #menu>
      <ng-container *ngFor="let campaign of campaignList; let i = index">
        <li class="menu-item"
            *ngIf="campaignsActiveWidgets[campaign.id] && campaignsActiveWidgets[campaign.id].length"
            (click)="toggleSubMenu(campaign.id)">
          <label>
            <span
              *ngIf="campaign.name.length > 30; else campaignName"
              matTooltipClass="Tooltip-Box"
              matTooltipPosition="left"
              [matTooltip]="campaign.name"
            >
              {{campaign.name}}
            </span>
            <ng-template #campaignName>
              {{campaign.name}}
            </ng-template>
            <span [class._rotate]="subMenuOpen[campaign.id]" class="menu-item-arrow-icon"></span>
          </label>
          <ul class="sub-menu" [class._open]="subMenuOpen[campaign.id]">
            <li
              class="sub-menu-item"
              *ngFor="let widget of campaignsActiveWidgets[campaign.id]"
              (click)="navigateToPlugin(campaign.id, widget.url, $event)"
            >
              <img src="/app_assets/icons/{{widget.icon}}.svg" alt="">
              <span class="title">{{widget.name}}</span>
            </li>
          </ul>
        </li>
      </ng-container>
    </ul>
    <!--    <ul class="menu _no-sub-menu">-->
    <!--      <ng-container *ngFor="let widget of campaignsActiveWidgets[selectedCampaignId]">-->
    <!--        <li class="menu-item"-->
    <!--            *ngIf="widget.name !== pluginName"-->
    <!--            (click)="navigateToPlugin(selectedCampaignId, widget.url, $event)">-->
    <!--          <label class="d-flex align-items-center">-->
    <!--            <img src="/app_assets/icons/{{widget.icon}}.svg" alt="">-->
    <!--            <span class="title">{{widget.name}}</span>-->
    <!--          </label>-->
    <!--        </li>-->
    <!--      </ng-container>-->
    <!--    </ul>-->
  </div>
</div>
