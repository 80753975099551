import { UserService } from './../../core/services/user.service';
import { Component, NgZone } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Logger } from '../../core/services/logger.service'
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'
import { Store } from '@ngrx/store'
import { StoreState } from '../../store/store.state'
import { ShowLoading, HideLoading, ResetLoading } from '../../store/loading/loading.actions'
import { LoadingLabel } from '../../shared/models/loading/loading-label'
import * as _ from 'lodash'
import { PromoService } from '../../shared/modules/promo/services/promo.service'
import { BrandingDomain } from '../../shared/models/branding-data.model'
import { AuthService } from '../../core/services/auth.service'
import { BrandingService } from '../../core/services/branding.service'
import { LogLabel } from '../../shared/models/logger/log-label.model'
import { ShopifyService } from '../../core/services/shopify.service'

@Component({
  styleUrls: ['./login.component.scss'],
  templateUrl: './login.component.html',
})
export class LoginComponent {
  errors: string[] = []
  messages: string[] = []
  brandingName: string
  brandingDomain: BrandingDomain

  brandingDomainEnum = BrandingDomain

  isHidden = false
  hasSentEmail = false

  googleSigninAvailable = true

  loginForm: UntypedFormGroup
  private shopifyAppsPageLinks = {
    onestore: 'https://apps.shopify.com/one?utm_source=onestore&utm_medium=landing_page&utm_campaign=shopify_logo&utm_content=logo',
    prooffactor: 'https://apps.shopify.com/one?utm_source=prooffactor&utm_medium=landing_page&utm_campaign=shopify_logo&utm_content=logo'

  }

  constructor(
    protected router: Router,
    private brandingService: BrandingService,
    private authService: AuthService,
    private logger: Logger,
    private fb: UntypedFormBuilder,
    private store: Store<StoreState>,
    private ngZone: NgZone,
    private route: ActivatedRoute,
    private shopifyService: ShopifyService,
    private promoService: PromoService,
    private userService: UserService
  ) {
    this.redirectToShopifyAppsPagIfNeeded()
    this.loginForm = this.fb.group({
      email: this.fb.control(''),
      password: this.fb.control(''),
    })
    this.store.dispatch(new ResetLoading())
    this.brandingName = this.brandingService.getBrandingData().name
    this.brandingDomain = this.brandingService.getBrandingData().domain
    this.googleSigninAvailable = this.brandingService.getBrandingData().google_login

    if (_.get(this.route.snapshot, 'queryParams.email')) {
      this.loginForm.patchValue({email: _.get(this.route.snapshot, 'queryParams.email')})
      this.router.navigate([], { queryParams: { email: null }, queryParamsHandling: 'merge' })
    }

    if (_.get(this.route.snapshot, 'queryParams.email_login')) {
      this.isHidden = true
      this.store.dispatch(new ShowLoading(LoadingLabel.SignInEmail))
      this.authService.confirmSignInWithEmailLink()
      .then(() => {
        this.authService.clearSignInEmail()
        this.isHidden = true
        this.ngZone.run(() => {
          this.router.navigateByUrl('/').then(() => {
            this.store.dispatch(new HideLoading(LoadingLabel.SignInEmail))
          })
        })
      })
      .catch((error) => {
        this.store.dispatch(new HideLoading(LoadingLabel.SignInEmail))
        this.onRegisterError(error)
      });
      this.router.navigate([], { queryParams: { email_login: null }, queryParamsHandling: 'merge' })
    }
    this.promoService.definePromo({ removeUrlParams: true }, this.userService.userInfo)
  }

  redirectToShopifyAppsPagIfNeeded() {
    const utm_campaign = this.route.snapshot?.queryParams?.utm_campaign
    const utm_medium = this.route.snapshot?.queryParams?.utm_medium
    const utm_source = this.route.snapshot?.queryParams?.utm_source

    if (utm_campaign === 'shopify_logo' && utm_medium === 'landing_page') {
      if (utm_source === 'onestore') {
        window.location.href = this.shopifyAppsPageLinks['onestore']
        return
      }
      if (utm_source === 'prooffactor') {
        window.location.href = this.shopifyAppsPageLinks['prooffactor']
      }
    }
  }

  loginWithShopify() {
    if (this.shopifyService.getShopifyAuthUrl('login')) {
      window.location.href = this.shopifyService.getShopifyAuthUrl('login')
    } else {
      this.router.navigate(['/login/shopify'])
    }
  }

  loginWithEmail(event) {
    event.preventDefault()
    this.errors = []
    this.isHidden = true
    this.store.dispatch(new ShowLoading(LoadingLabel.SignInEmail))
    this.authService.signInWithEmail(this.loginForm.value.email, this.loginForm.value.password)
      .then(() => {
        this.isHidden = true
        this.ngZone.run(() => {
          this.router.navigateByUrl('/')
        })
      })
      .catch((error) => {
        this.isHidden = false
        this.logger.error(null, `error: ${JSON.stringify(error)}`)
        this.errors = [error.message]
      })
      .finally(() => this.store.dispatch(new HideLoading(LoadingLabel.SignInEmail)))
  }


  loginWithEmailLink(event) {
    event.preventDefault()
    this.errors = []
    this.isHidden = true
    this.store.dispatch(new ShowLoading(LoadingLabel.SignInEmail))
    this.authService.signInWithEmailLink(this.loginForm.value.email)
      .then(() => {
        this.authService.setSignInEmail(this.loginForm.value.email)
        this.hasSentEmail = true
        this.isHidden = false
      })
      .catch((error) => this.onRegisterError(error))
      .finally(() => this.store.dispatch(new HideLoading(LoadingLabel.SignInEmail)))
  }

  loginWithGoogle() {
    this.errors = []
    this.isHidden = true
    this.store.dispatch(new ShowLoading(LoadingLabel.SignInGoogle))
    this.authService.signInWithGoogle()
      .then((response) => {
        this.isHidden = true
        this.logger.log(LogLabel.LoginComponent, `user_info: ${JSON.stringify(response.additionalUserInfo)}`)
        this.ngZone.run(() => {
          this.router.navigateByUrl('/')
        })
      })
      .catch((error) => this.onRegisterError(error))
      .finally(() => this.store.dispatch(new HideLoading(LoadingLabel.SignInGoogle)))
  }

  private onRegisterError(error) {
    this.isHidden = false
    this.logger.error(null, `error: ${JSON.stringify(error)}`)
    this.errors = [error.message]
  }
}
