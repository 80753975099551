import { StoreState } from '../store.state'
import * as _ from 'lodash'
import { CaptureRule } from '../../shared/models/campaign/capture-rules.model'
import { Campaign, CampaignCaptureType, CampaignPluginName } from '../../shared/models/campaign/campaign'
import { GettingStartedEvent } from '../../shared/models/getting-started/getting-started-event'
import { GettingStartedInstallPlatform } from '../../shared/models/getting-started/getting-started-install-platform.model'
import { GettingStartedStatus } from '../../shared/models/getting-started/getting-started-status.model'

export const getGettingStartedEvents = (state: StoreState): GettingStartedEvent[] => {
  return state.gettingStarted.events
}

export const getGettingStartedStatus = (state: StoreState): GettingStartedStatus => {
  return state.gettingStarted.status
}

export const getGettingStartedCompleted = (state: StoreState) => {
  return state.gettingStarted.completed
}

export const getGettingStartedCampaign = (state: StoreState): Campaign => {
  return state.gettingStarted.campaign
}

export const getGettingStartedFirstCampaign = (state: StoreState): boolean => {
  return state.gettingStarted.firstCampaignLaunched
}

export const getGettingStartedFirstCampaignSkipped = (state: StoreState): boolean => {
  return state.gettingStarted.firstCampaignSkipped
}

export const getGettingStartedFirstCampaignId = (state: StoreState): string => {
  return state.gettingStarted.firstCampaignId
}

export const getGettingStartedCaptureRules = (state: StoreState): CaptureRule[] => {
  return state.gettingStarted.campaign
    ? state.gettingStarted.campaign[CampaignPluginName.RecentActivity].capture_configs
    : []
}

export const getGettingStartedWebhookUrl = (state: StoreState): string => {
  return state.gettingStarted.campaign
    ? state.gettingStarted.campaign[CampaignPluginName.RecentActivity].webhook.url
    : ''
}

export const getGettingStartedCaptureType = (state: StoreState): CampaignCaptureType => {
  return state.gettingStarted.campaign
    ? state.gettingStarted.campaign[CampaignPluginName.RecentActivity].capture_type
    : CampaignCaptureType.Form
}

export const getGettingStartedDomain = (state: StoreState): string => {
  return _.get(state, 'gettingStarted.status.detect.domain')
}
export const getGettingStartedPlatform = (state: StoreState): GettingStartedInstallPlatform => {
  return _.get(state, 'gettingStarted.status.install.platform')
}

export const getIsDetectDomainNeeded = (state: StoreState): boolean => {
  const status = state.gettingStarted.status
  return status && (!status.detect.domain || !status.detect.domain.length)
}
