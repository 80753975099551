import { Injectable } from '@angular/core'
import {
  BigCommerceCoupon,
  CouponCode,
  CouponCodeState,
  CouponCodeTargetSelection,
  CouponCodeTargetType,
  CouponCodeType,
  CouponCodeValueType,
  CouponStatus,
  GiftCardCoupon,
  GiftCardUniqueCoupon,
  ShopifyCoupon,
  UICouponCodeSummary,
  UICouponCodeTypeName,
  UICouponTargetSelection,
} from '../models/coupon-code'
import moment from 'moment'
import * as _ from 'lodash'
import { WooCommerceCoupon } from '../components/new-coupon-code/components/woo-commerce-coupon/interfaces/woo-commerce-coupon.interface'
import { DiscountType } from '../components/new-coupon-code/components/woo-commerce-coupon/enums/discount-type.enum'
import { GiftCard } from '../../gift-cards/models/gift-card'

@Injectable({
  providedIn: 'root'
})
export class NewCouponCodesService {
  summary: UICouponCodeSummary

  couponCodeType = CouponCodeType
  couponCodeTargetType = CouponCodeTargetType
  couponCodeTargetSelection = CouponCodeTargetSelection
  uiCouponCodeValueType = CouponCodeValueType
  uiCouponCodeTypeName = UICouponCodeTypeName
  uiCouponTargetSelection = UICouponTargetSelection
  couponCodeState = CouponCodeState
  _couponStatus = CouponStatus

  public currency: string

  constructor() {
  }

  generateRandomCode(): string {
    return (Math.random().toString(36).substring(2)).toUpperCase()
  }

  /**
   * Summary generation stuff
   */

  updateSummary(value: ShopifyCoupon | BigCommerceCoupon, randomString: string, currency: string = ''): UICouponCodeSummary {
    this.summary = {
      schedule: {
        start: this.summaryDate('Starting at', value.data.starts_at, 'LLL'),
        end: this.summaryDate('Ends at', value.data.ends_at, 'LLL'),
      },
      codeType: this._summaryCodeType(value.type),
      code: this._summaryCode(value, randomString),
      randomString: randomString,
      discount: value.type !== this.couponCodeType.manual_master ? this.summaryDiscount(value, currency) : null,
      discoutnRelatedTo: value.type !== this.couponCodeType.manual_master ? this.summaryDiscountRelatedTo(value) : null,
      limit: this.summaryUsageLimit(value),
    }
    return this.summary
  }

  public summaryUsageLimit(value: ShopifyCoupon | BigCommerceCoupon): string {
    const summaryIsforForm = !!(value.data && value.data._local)
    if (summaryIsforForm) {
      switch (value.type) {
        case this.couponCodeType.manual_master:
          return null
        case this.couponCodeType.big_commerce_master:
          return null
        case this.couponCodeType.woo_commerce_master:
          return null
        case this.couponCodeType.shopify_unique:
          return 'Limit of 1 use, one per customer'
        case this.couponCodeType.big_commerce_unique:
          return 'Limit of 1 use, one per customer'
        case this.couponCodeType.woo_commerce_unique:
          return 'Limit of 1 use, one per customer'
        default:
          if (value.data.once_per_customer && !value.data._local.setUsageLimit) {
            return 'One use per customer'
          } else if (value.data._local.setUsageLimit && value.data.usage_limit !== 0) {
            return `Limit of ${value.data.usage_limit} use${value.data.usage_limit === 1 ? '' : 's'}${value.data.once_per_customer ? ', one per customer' : ''}`
          } else if (value.data._local.setUsageLimit && value.data.usage_limit === 0) {
            return 'One use per customer'
          } else {
            return null
          }
      }
    } else {
      switch (value.type) {
        case this.couponCodeType.manual_master:
          return null
        case this.couponCodeType.big_commerce_master:
          return null
        case this.couponCodeType.shopify_unique:
          return 'Limit of 1 use, one per customer'
        case this.couponCodeType.big_commerce_unique:
          return 'Limit of 1 use, one per customer'
        default:
          if (value.data.once_per_customer) {
            return 'One use per customer'
          } else if (!value.data.once_per_customer && value.data.usage_limit !== 0) {
            return `Limit of ${value.data.usage_limit} use${value.data.usage_limit === 1 ? '' : 's'}${value.data.once_per_customer ? ', one per customer' : ''}`
          } else if (value.data.usage_limit === 0) {
            return 'One use per customer'
          } else {
            return null
          }
      }
    }
  }

  public summaryDiscountRelatedTo(value: ShopifyCoupon | BigCommerceCoupon): string {
    if (value.data.target_type === CouponCodeTargetType.shipping_line) {
      return 'on entire order'
    }
    switch (value.data.target_selection) {
      case this.couponCodeTargetSelection.all:
        return 'for all products'
      case this.couponCodeTargetSelection.entitled:
        return 'for specific products'
      default:
        return null
    }
  }

  public couponStatus(coupon: ShopifyCoupon | BigCommerceCoupon | GiftCardCoupon | GiftCardUniqueCoupon): string {
    if (coupon.status === this._couponStatus.archived) {
      return this.couponCodeState.archived
    }

    if (coupon.type === this.couponCodeType.manual_master || coupon.type === this.couponCodeType.manual_unique) {
      return this.couponCodeState.active
    }

    if (coupon.status === this._couponStatus.disabled) {
      return this.couponCodeState.disabled
    }

    const today = moment()
    const startDate = _.get(coupon, 'data.starts_at')
    const endDate = _.get(coupon, 'data.ends_at')

    if (endDate && moment(endDate).isBefore(today)) {
      return this.couponCodeState.expired
    }

    if (startDate && moment(startDate).isAfter(today)) {
      return this.couponCodeState.scheduled
    }

    return this.couponCodeState.active
  }

  public getCouponTypeName(type: CouponCodeType) {
    return this.uiCouponCodeTypeName[type]
  }


  public getDiscountText(reward: CouponCode | GiftCard, currency?: string): string {
    let discount
    let discountRelatedTo
    switch (reward.type) {
      case this.couponCodeType.shopify_master:
      case this.couponCodeType.shopify_unique:
        discount = this.summaryDiscount(reward as ShopifyCoupon, currency)
        discountRelatedTo = this.summaryDiscountRelatedTo(reward as ShopifyCoupon)
        return `${discount} ${discountRelatedTo}`
      case this.couponCodeType.big_commerce_master:
      case this.couponCodeType.big_commerce_unique:
        discount = this.summaryDiscount(reward as BigCommerceCoupon, currency)
        discountRelatedTo = this.summaryDiscountRelatedTo(reward as BigCommerceCoupon)
        return `${discount} ${discountRelatedTo}`
      case this.couponCodeType.shopify_gift_card:
      case this.couponCodeType.shopify_gift_card_unique:
        return this.summaryCash(reward as GiftCardUniqueCoupon | GiftCardUniqueCoupon, currency)
      default:
        return null
    }
  }

  public getDiscountTextShort(reward: CouponCode | GiftCard, currency?: string): string {
    let discount
    switch (reward.type) {
      case this.couponCodeType.shopify_master:
      case this.couponCodeType.shopify_unique:
        discount = this.summaryDiscount(reward as ShopifyCoupon, currency)
        return `${discount}`
      default:
        return null
    }
  }

  public summaryCash(value: GiftCardCoupon | GiftCardUniqueCoupon, currency?: string): string {
    return `${currency} ${value.value} value`
  }

  public summaryDiscount(value: ShopifyCoupon | BigCommerceCoupon | WooCommerceCoupon, currency?: string): string {
    if (value.type === CouponCodeType.manual_master) {
      return 'Manual'
    }

    if ((value as ShopifyCoupon | BigCommerceCoupon).data.target_type) {
      switch ((value as ShopifyCoupon | BigCommerceCoupon).data.target_type) {
        case this.couponCodeTargetType.line_item:
          if (value.data.value_type === this.uiCouponCodeValueType.percentage) {
            return `${value.data.value}% off`
          } else if (value.data.value_type === this.uiCouponCodeValueType.buy_x_get_y) {
            const buyCount = value.data.prerequisite_quantity_range_min
            const getCount = value.data.customer_get_quantity
            const getSpend = value.data.prerequisite_subtotal_min
            const discount = value.data.customer_get_percentage
            const discountStr = discount === 1 ? 'for Free' : `at ${(discount * 100).toFixed(0)}% off`
            if (getSpend) {
              return `Spend ${currency ? currency + ' ' : ''}${getSpend} get ${getCount} ${discountStr}`
            } else {
              return `Buy ${buyCount} get ${getCount} ${discountStr}`
            }
          } else if (value.data.value_type === this.uiCouponCodeValueType.fixed_amount) {
            return `${currency ? currency + ' ' : ''}${value.data.value} off`
          } else {
            return null
          }
        case this.couponCodeTargetType.shipping_line:
          return `Free Shipping`
        default:
          return null
      }
    } else {
      const freeShipping = (value as WooCommerceCoupon).data.free_shipping
      if (value.data.value !== 0) {
        switch ((value as WooCommerceCoupon).data.value_type) {
          case DiscountType.Percentage:
            return `${value.data.value}% off ${freeShipping ? '+ Free Shipping' : ''}`
          case DiscountType.FixedProduct:
          case DiscountType.FixedCart:
            return `${currency ? currency + ' ' : ''}${value.data.value} off ${freeShipping ? '+ Free Shipping' : ''}`
        }
      } else {
        if (freeShipping) {
          return 'Free Shipping'
        }
      }

    }
  }

  static randomString(length) {
    const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
    let result = ''
    for (let i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)]
    return result.toUpperCase()
  }

  private _summaryCode(value: ShopifyCoupon | BigCommerceCoupon, randomString: string): string {
    switch (value.type) {
      case this.couponCodeType.shopify_master:
        return value.master_code
      case this.couponCodeType.big_commerce_master:
        return value.master_code
      case this.couponCodeType.woo_commerce_master:
        return value.master_code
      case this.couponCodeType.manual_master:
        return value.master_code
      case this.couponCodeType.shopify_unique:
        return `${value.prefix_code}${randomString}`
      case this.couponCodeType.shopify_gift_card_unique:
        return `${value.prefix_code}${randomString}`
      case this.couponCodeType.big_commerce_unique:
        return `${value.prefix_code}${randomString}`
      case this.couponCodeType.woo_commerce_unique:
        return `${value.prefix_code}${randomString}`
      default:
        return null
    }
  }

  private _summaryCodeType(codeType: CouponCodeType): string {
    switch (codeType) {
      case this.couponCodeType.shopify_master:
        return 'Same code for all Pop Ups'
      case this.couponCodeType.big_commerce_master:
        return 'Same code for all Pop Ups'
      case this.couponCodeType.woo_commerce_master:
        return 'Same code for all Pop Ups'
      case this.couponCodeType.manual_master:
        return 'Same code for all Pop Ups'
      case this.couponCodeType.shopify_unique:
        return 'Custom code for each Pop Up'
      case this.couponCodeType.shopify_gift_card_unique:
        return 'Custom code for each Pop Up'
      case this.couponCodeType.big_commerce_unique:
        return 'Custom code for each Pop Up'
      case this.couponCodeType.woo_commerce_unique:
        return 'Custom code for each Pop Up'
      default:
        return null
    }
  }

  public summaryDate(prepend: string, date: string, format: string): string {
    if (!date || !date.length) {
      return null
    }
    return `${prepend} ${moment(date).format(format)}`
  }
}
