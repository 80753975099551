import { GettingStartedCampaignStep } from './getting-started-campaign-step'
import { GettingStartedInstallPlatform } from './getting-started-install-platform.model'

export interface GettingStartedStatus {
  completed: boolean,
  dashboardOld: boolean,
  install: {
    visited: boolean,
    completed: boolean,
    platform: GettingStartedInstallPlatform,
    steps: GettingStartedCampaignStep[],
  },
  detect: {
    visited: boolean,
    completed: boolean,
    domain: string
  },
  profile: {
    visited: boolean,
    completed: boolean,
  },
  support: {
    visited: boolean,
    completed: boolean,
  },
  campaign: GettingStartedStatusCampaign,
  firstCampaignLaunched: boolean,
  firstCampaignSkipped?: boolean,
  firstCampaignId?: string,
  scholarship?: ScholarshipStatus,
  pre_plans_survey_new?: {
    visited: boolean,
    completed: boolean,
  },
  pre_plans_survey_returning?: {
    visited: boolean,
    completed: boolean,
  }
  apps?: {
    visited: boolean,
    completed: boolean,
  },
  shopify_sms_access_form?: {
    visited: boolean,
    completed: boolean,
  },
  upsell_access_form?: {
    visited: boolean,
    completed: boolean,
  },
  feature_votes?: FeatureVotesObject,
  key_value_pair?: any
}

export interface FeatureVotesObject {
  loyalty_point?: FeatureVoteStatus,
  download_links?: FeatureVoteStatus,
  store_credits?: FeatureVoteStatus,
  whats_app?: FeatureVoteStatus,
  push_notification?: FeatureVoteStatus,
  upsell_one_click_checkout?: FeatureVoteStatus
}

export interface FeatureVoteStatus {
  url: string,
  voteCount: number,
  completed: boolean
}

export interface ScholarshipStatus {
  plan_id: string,
  plan_name: string,
  popup_seen: boolean,
  application: {
    status: ScholarshipApplicationStatusEnum,
    status_last_updated: string,
    submission_id: string,
    submission_ip: string,
    submission_date: string,
    requested_plan_id: string,
    requested_plan_name: string,
    submitter: {
      name: string,
      email: string,
      phone: string,
      store_role: string,
      country: string,
    }
  }
}

export enum ScholarshipApplicationStatusEnum {
  Pending = 'pending',
  Approved = 'approved',
  Rejected = 'rejected',
}

export interface GettingStartedStatusCampaign {
  visited: boolean,
  completed: boolean,
  skipped?: boolean,
  campaign_id: string,
  steps: GettingStartedCampaignStep[]
}

export const initialGettingStartedStatus: GettingStartedStatus = {
  completed: false,
  dashboardOld: false,
  install: {
    visited: false,
    completed: false,
    platform: undefined,
    steps: [],
  },
  detect: {
    visited: false,
    completed: false,
    domain: undefined,
  },
  profile: {
    visited: false,
    completed: false,
  },
  campaign: {
    visited: false,
    completed: false,
    campaign_id: undefined,
    steps: [],
  },
  support: {
    visited: false,
    completed: false,
  },
  firstCampaignLaunched: false,
  firstCampaignSkipped: false,
  scholarship: {
    plan_id: undefined,
    plan_name: undefined,
    popup_seen: false,
    application: {
      status: null,
      status_last_updated: null,
      submission_id: null,
      submission_ip: null,
      submission_date: null,
      requested_plan_id: null,
      requested_plan_name: null,
      submitter: {
        name: null,
        email: null,
        phone: null,
        store_role: null,
        country: null,
      }
    }
  },
  pre_plans_survey_new: {
    visited: false,
    completed: false,
  },
  pre_plans_survey_returning: {
    visited: false,
    completed: false,
  },
  apps: {
    visited: false,
    completed: false,
  },
  shopify_sms_access_form: {
    visited: false,
    completed: false,
  },
  upsell_access_form: {
    visited: false,
    completed: false,
  },
  feature_votes: {
    loyalty_point: { completed: false, voteCount: 0, url: ''},
    download_links: { completed: false, voteCount: 0, url: ''},
    store_credits: { completed: false, voteCount: 0, url: ''},
    whats_app: { completed: false, voteCount: 0, url: ''},
    push_notification: { completed: false, voteCount: 0, url: ''},
    upsell_one_click_checkout: { completed: false, voteCount: 0, url: ''}
  },
  key_value_pair: {}
}
