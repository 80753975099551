import { Injectable } from '@angular/core'
import { ApiService } from './api.service'
import { CouponCode, CouponCodeType, CouponStatus, DetailedCouponCode } from '../../../pages/coupon-codes/models/coupon-code'
import { GiftCard, GiftCardStatus } from '../../../pages/gift-cards/models/gift-card'
import { Observable } from 'rxjs'
import { LinkCouponCodeRequestParams, LinkGiftCardRequestParams, RewardsStatus, RewardsType } from '../../../pages/coupon-codes/models/rewards-model'
import { HttpParams } from '@angular/common/http'
import _ from 'lodash'
import { CampaignDisplayStatus, CampaignDisplayStatusToFilterStatus } from '../../../shared/models/campaign/campaign'
import { finalize } from 'rxjs/operators'
import { HideLoading } from '../../../store/loading/loading.actions'
import { StoreState } from '../../../store/store.state'
import { Store } from '@ngrx/store'

export interface RewardsResponse {
  rewards: (CouponCode | GiftCard)[],
  total_count: number
  total_pages: number
  stats?: RewardStats
}

export interface RewardStatsResponse {
  stats: {
    num_awarded: number
    num_redeemed: number
    redemption_rate: number
    total_revenue: number
    num_redemption_rate: number
  }
}

export interface RewardStats {
  num_awarded: number,
  num_redeemed: number,
  total_revenue: number,
  redemption_rate?: number,
  num_redemption_rate?: number,
}
export interface RewardsRequestParams extends LinkedType {
  page?: number
  limit?: number
  status?: CouponStatus | GiftCardStatus | RewardsStatus
  includes?: 'stats'
  start?: string
  end?: string
  type?: CouponCodeType
  query?: string
  reward_type?: RewardsType,
  target_type?: string
}

export interface LinkedType {
  orderBy?: 'linked'
  [LinkedTypeId.notificationId]?: string
  [LinkedTypeId.emailAutomationId]?: string
  [LinkedTypeId.smsAutomationId]?: string
  [LinkedTypeId.broadcastEmailId]?: string
  [LinkedTypeId.broadcastSmsId]?: string
}

export enum LinkedTypeId {
  notificationId = 'notification_id',
  emailAutomationId = 'email_automation_id',
  smsAutomationId = 'sms_automation_id',
  broadcastEmailId = 'broadcast_email_id',
  broadcastSmsId = 'broadcast_sms_id',
}
export interface RewardStatsRequest {
  includes: 'stats'
  status?: CouponStatus | GiftCardStatus | RewardsStatus
  start?: string
  end?: string
}

@Injectable({
  providedIn: 'root',
})
export class ApiRewardsService {
  constructor(
    private apiService: ApiService,
    private store: Store<StoreState>
  ) { }

  putRewards(params: CouponCode | GiftCard, type: RewardsType): Observable<any> {
    if (!params.id) {
      console.error('putRewards: "id" is required')
      return
    }
    params.reward_type = type
    return this.apiService.put(
      `/v1/me/rewards/${params.id}`, params
    )
  }

  getRewardById(id): Observable<any> {
    return this.apiService.get(`/v1/me/rewards/${id}`)
  }

  postRewards(params: CouponCode | GiftCard, type: RewardsType): Observable<any> {
    params.reward_type = type
    return this.apiService.post(
      '/v1/me/rewards', params
    )
  }

  getRewards(params: RewardsRequestParams): Observable<RewardsResponse> {
    const {
      type,
      page,
      limit,
      status,
      start,
      end,
      includes,
      orderBy,
      reward_type,
      notification_id,
      email_automation_id,
      sms_automation_id,
      broadcast_email_id,
      broadcast_sms_id,
      target_type
    } = params as RewardsRequestParams
    let req = new HttpParams()
    // sort by filter
    type ? req = req.append('type', type) : null
    page ? req = req.append('page', page.toString()) : null
    limit ? req = req.append('limit', limit.toString()) : null
    status ? req = req.append('status', status) : null
    start ? req = req.append('start_date', start) : null
    end ? req = req.append('end_date', end) : null
    includes ? req = req.append('includes', includes) : null
    reward_type ? req = req.append('reward_type', reward_type) : null
    target_type ? req = req.append('target_type', target_type) : null
    // sort by order
    orderBy ? req = req.append('order_by', orderBy) : null
    notification_id ? req = req.append('notification_id', notification_id) : null
    email_automation_id ? req = req.append('email_automation_id', email_automation_id) : null
    sms_automation_id ? req = req.append('sms_automation_id', sms_automation_id) : null
    broadcast_email_id ? req = req.append('broadcast_email_id', broadcast_email_id) : null
    broadcast_sms_id ? req = req.append('broadcast_sms_id', broadcast_sms_id) : null

    return this.apiService.get(
      '/v1/me/rewards', req
    )
  }

  getRewardStats(params: RewardStatsRequest, type = RewardsType.all): Observable<RewardStatsResponse> {
    let req = new HttpParams()
    req = req.append('includes', params.includes)
    req = _.isUndefined(params.status) ? req : req.append('status', params.status)
    req = _.isUndefined(params.start) ? req : req.append('start_date', params.start)
    req = _.isUndefined(params.end) ? req : req.append('end_date', params.end)
    return this.apiService.get(
      `/v1/me/reward_stats?reward_type=${type}`, req
    )
      .pipe(
        finalize(() => this.store.dispatch(new HideLoading(`${type}Stats`)))
      )
  }

  getRewardsRow(coupon_id: string, start_date: string, end_date: string, type = RewardsType.all): Observable<DetailedCouponCode> {
    return this.apiService.get(
      `/v1/me/rewards/${coupon_id}?reward_type=${type}&includes=stats&start_date=${start_date}&end_date=${end_date}`,
    )
  }

  getRewardedPlugins(params: LinkCouponCodeRequestParams | LinkGiftCardRequestParams, type = RewardsType.all): Observable<{ plugins: any[], total_pages: number, total_count: number }> {
    const filterStatus = CampaignDisplayStatusToFilterStatus[params.status] || CampaignDisplayStatus.AllExceptArchived
    return this.apiService.get(
      `/v1/me/rewarded_plugins?includes=stats&reward_type=${type}&page=${params.page}&limit=${params.limit}&campaign_status=${filterStatus}${params.start ? `&start_date=${params.start}` : ''}${params.end ? `&end_date=${params.end}` : ''}`,
    )
  }
}
