import {campaignReducer} from './campaign/campaign.reducer'
import {loadingReducer} from './loading/loading.reducer'
import {billingReducer} from './billing/billing.reducer'
import {userReducer} from './user/user.reducer'
import {mailIntegrationReducer} from './mail-integration/mail-integration.reducer'
import { gettingStartedReducer } from './getting-started/getting-started.reducer'

export const storeReducers = {
  campaign: campaignReducer,
  loading: loadingReducer,
  billing: billingReducer,
  user: userReducer,
  mailIntegration: mailIntegrationReducer,
  gettingStarted: gettingStartedReducer,
}
