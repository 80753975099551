import { CommonModule } from '@angular/common'
import { Component, OnInit, Inject } from '@angular/core'
import { CrispService } from '../../../../core/services/crisp.service'
import { SlackChannelName, SlackSupporService } from '../../../../core/services/slack-support.service'
import { OneIconComponent } from '../../one-icon/one-icon.component'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

@Component({
    templateUrl: './account-locked.component.html',
    styleUrls: ['./account-locked.component.scss'],
    standalone: true,
    imports: [
      CommonModule,
      OneIconComponent,
    ]
})
export class AccountLockedModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<AccountLockedModalComponent>,
    private crispService: CrispService,
    private slackSupportService: SlackSupporService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void { }

  onLiveChatClick( event: Event ) {
    event.preventDefault()

    if (this.data && this.data.error) {
      this.slackSupportService.sendMessage(this.data.error, SlackChannelName.Support)
    }
    const crisp = this.crispService.getCrisp()
    if (crisp) {
      crisp.push(['do', 'chat:open'])
      crisp.push(['do', 'message:send', [
        'text',
        `Hello team, my account was locked, can you help?`
      ]])
    }
  }
}
