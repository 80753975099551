import { CommonModule } from '@angular/common';
import { Component, Inject, OnDestroy } from '@angular/core'
import { Router } from '@angular/router';
import { ActiveAppCampaignService } from '../../../../core/services/active-app-campaign.service';
import { RouteHeaderUrl } from '../../one-header/header-navigation.model';
import { ActiveAppCampaigns, appCampaignName } from '../../../models/app-campaign-type.model';
import { SegmentAnalyticsService } from '../../../services/segment-analytics.service';
import { Subscription } from 'rxjs';
import { UserService } from '../../../../core/services/user.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    templateUrl: './upgrade-plan.modal.component.html',
    styleUrls: ['./upgrade-plan.modal.component.scss'],
    standalone: true,
    imports: [
      CommonModule,
    ]
})
export class UpgradePlanModalComponent implements OnDestroy {
  text: string = 'Please upgrade your plan to use this feature'
  private subscription = new Subscription()
  constructor(
    public dialogRef: MatDialogRef<UpgradePlanModalComponent>,
    private router: Router,
    private userService: UserService,
    private segmentAnalyticsService: SegmentAnalyticsService,
    private activeAppCampaignService: ActiveAppCampaignService,
    @Inject(MAT_DIALOG_DATA) public data?: {text?: string, type?: ActiveAppCampaigns, reason?: string}
  ) {
    if (data?.text) {
      this.text = data.text
    } else {
      if (data.reason === 'customers') {
        this.text = `You've reached active customer limit. Please upgrade your plan with the appropriate number of active customers to activate this ${data?.type ? `${appCampaignName[data.type]} Campaign` : 'Campaign'}`
      } else {
        this.text = `You've reached active campaign limit. Please upgrade your plan to activate this ${data?.type ? `${appCampaignName[data.type]} Campaign` : 'Campaign'}`
      }
    }

    if (data.reason === 'captcha') {
      this.segmentAnalyticsService.track('Displayed Upgrade Plan (Captcha) Modal')
    } else {
      this.subscription.add(
        this.activeAppCampaignService.campaignCounts$.subscribe(counts => {
          this.segmentAnalyticsService.track(`Viewed Modal - Exceed ${data.reason || 'campaign'} limit`, {
            app_type: data?.type || 'App Campaign',
            campaign_counts: counts,
          })
        })
      )
    }
  }

  letsGo() {
    if (this.data.reason === 'captcha') {
      this.segmentAnalyticsService.track('Clicked Review Plans Btn - Upgrade Plan Captcha Modal')
    } else {
      this.segmentAnalyticsService.track('Clicked Review Plans - Upgrade Plan Modal')
    }
    const url = this.userService.isShop ? `/${RouteHeaderUrl.settings}/${RouteHeaderUrl.billing}/${RouteHeaderUrl.subscriptions}` : RouteHeaderUrl.choose_plan
    this.router.navigate([url], { queryParams: { hit_limit: true}}).then(() => {
      this.dialogRef.close(true)
    })

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }
}
