export enum LogLabel {
  Default = 'default',

  CampaignService = 'campaign',
  PaymentService = 'payment',
  AuthService = 'auth',
  OauthFlowService = 'oauth-flow',
  MenuService = 'menu',
  UserService = 'user',
  LoadingService = 'loading',
  SnackbarService = 'snackbar',
  ShopifyOauthService = 'shopify-oauth',
  GettingStartedService = 'getting-started',
  SaveService = 'save-service',

  LoadingComponent = 'loading-component',
  SavableComponent = 'savable',
  FortuneWheelComponent = 'fortune-wheel',
  AppComponent = 'app',
  LoginComponent = 'login',
  ShopifyComponent = 'shopify',
  SettingsComponent = 'settings',
  PlansComponent = 'plans',
  ChoosePlanComponent = 'choose-plan',
  DisplayComponent = 'display',
  RecentActivityComponent = 'recent-activity',
  AggregateActivityComponent = 'aggregate-activity',
  MailIntegrations = 'mail-integrations',
  CaptureComponent = 'capture',
  AdvancedComponent = 'advanced',
  AppearanceComponent = 'appearance',
  GettingStartedComponent = 'getting-started-component',
  DetectComponent = 'detect',

  SegmentAnalyticsService = 'segment-analytics'
}
