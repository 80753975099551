// import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { CouponsImportResponse } from '../models/coupons-import'
import { ApiService } from '../../../../core/services/api/api.service'

@Injectable()
export class CouponsImportService {

  constructor(
    private apiService: ApiService,
    // private httpClient: HttpClient,
  ) {
  }

  getCoupons(params?: {before?: string, after?: string, title?: string}): Observable<CouponsImportResponse> {
    const before = params && params.before ? `&before=${params.before}` : ''
    const after = params && params.after ? `&after=${params.after}` : ''
    const title = params && params.title ? `&title=${encodeURIComponent(params.title)}` : ''
    return this.apiService.get(`/v1/me/shop/coupons?per_page=25${before}${after}${title}`)
    // return this.httpClient.get<CouponsImportResponse>('https://my.api.mockaroo.com/coupons.json?key=c70f8d70')
  }

  importCoupons(coupons: string[]): Observable<any> {
    return this.apiService.post('/v1/me/shop/coupons', {coupon_ids: coupons})
  }


}
