export const fortuneWheelCustomJsTemplate =
`/* Add custom script to Proof Factor Wheel Events listeners below */

// window.addEventListener('prooffactor:wheel:open', (e) => {
//     console.log('prooffactor:wheel:open', {
//         id: e.detail.id
//     });
// });

// window.addEventListener('prooffactor:wheel:played', (e) => {
//     console.log('prooffactor:wheel:played', {
//         id: e.detail.id,
//         email: e.detail.email,
//         result: e.detail.result,
//     });
// });

// window.addEventListener('prooffactor:wheel:close', (e) => {
//     console.log('prooffactor:wheel:close', {
//         id: e.detail.id,
//         email: e.detail.email,
//         result: e.detail.result,
//     });
// });

// window.addEventListener('prooffactor:wheel:spin', (e) => {
//     console.log('prooffactor:wheel:played', {
//         id: e.detail.id,
//         email: e.detail.email,
//     });
// });
`
