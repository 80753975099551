import { Injectable } from '@angular/core'
import moment from 'moment'
import { BehaviorSubject, Observable, of } from 'rxjs'
import { delay, finalize } from 'rxjs/operators'
import { ApiService } from '../../../core/services/api/api.service'
import { UpsellListItem, UpsellPageDateRange, UpsellStatus, UpsellTriggerType } from '../models/upsell-page.models'
import { HideLoading } from '../../../store/loading/loading.actions'
import { StoreState } from '../../../store/store.state'
import { Store } from '@ngrx/store'

@Injectable()
export class UpsellService {

  private startDate = moment().utc().subtract(29, 'days').format('YYYY-MM-DD')
  private endDate = moment().utc().format('YYYY-MM-DD')

  public dateRange$: BehaviorSubject<UpsellPageDateRange> = new BehaviorSubject({
    start_date: this.startDate,
    end_date: this.endDate,
  })

  constructor(
    private apiService: ApiService,
    private store: Store<StoreState>
  ) {
  }

  getUpsellStats(start_date: string, end_date: string) {
    return this.apiService.get(`/v1/post_purchase/upsell/stats?start_date=${start_date}&end_date=${end_date}`)
      .pipe(
        finalize(() => this.store.dispatch(new HideLoading('UpsellStats')))
      )
  }

  getUpsells(params: { page?: number, limit?: number, status?: any, start_date?: string, end_date?: string }) {
    // no support for params yet, so these are redundant
    let url = `/v1/post_purchase/upsell/offers?page=${params.page || 1}&limit=${params.limit || 999}`
    if (params.start_date && params.end_date) {
      url += `&date_range_type=custom_date&start_date=${params.start_date}&end_date=${params.end_date}`
    }
    if (params.status && params.status !== UpsellStatus.All && params.status !== UpsellStatus.AllExceptArchived) {
      url += `&status=${params.status}`
    }
    return this.apiService.get(url)
  }

  getUpsell(upsellId) {
    return this.apiService.get(`/v1/post_purchase/upsell/offers/${upsellId}`)
  }

  updateUpsellStatus(upsell: UpsellListItem, status: UpsellStatus) {
    return this.apiService.put(`/v1/post_purchase/upsell/offers/${upsell.id}`, { ...upsell, status })
  }

  createUpsellOffer(payload) {
    return this.apiService.post('/v1/post_purchase/upsell/offers', {...payload, trigger: UpsellTriggerType.All})
  }

  updateUpsell(upsellId: string, payload) {
    return this.apiService.patch(`/v1/post_purchase/upsell/offers/${upsellId}`, payload)
  }

  duplicateUpsell(upsellId: string, name: string = null) {
    return this.apiService.post(`/v1/post_purchase/upsell/offers/${upsellId}/duplicate`, {name})
  }

  createUpsellVariant(upsellId: string, payload) {
    return this.apiService.post(`/v1/post_purchase/upsell/offers/${upsellId}/variants`, payload)
  }

  updateVariant(upsellId: string, variantId: string, payload) {
    return this.apiService.patch(`/v1/post_purchase/upsell/offers/${upsellId}/variants/${variantId}`, payload)
  }

  getUpsellPreview(upsellId: string, variantId: string) {
    return this.apiService.get(`/v1/post_purchase/upsell/offers/${upsellId}/variants/${variantId}/preview`)
  }
}
