import { Injectable } from '@angular/core'
import { WidgetFortuneWheelThemeConfig } from '../../shared/models/widget/fortune-wheel.model'

@Injectable()
export class WheelService {
  isThemeChanged(currentTheme: WidgetFortuneWheelThemeConfig, defaultThemes: WidgetFortuneWheelThemeConfig[]): boolean {
    if (!currentTheme) {
      return false
    }
    const defaultTheme = defaultThemes.find(theme => theme.identifier === currentTheme.identifier)
    if (!defaultTheme) {
      return true
    }
    for (const name in defaultTheme.variables) {
      if (defaultTheme.variables.hasOwnProperty(name)) {
        const defaultValue = defaultTheme.variables[name]
        const currentValue = currentTheme.variables[name]
        if (defaultValue !== currentValue) {
          return true
        }
      }
    }
    for (const name in defaultTheme.images) {
      if (defaultTheme.images.hasOwnProperty(name)) {
        const defaultValue = defaultTheme.images[name]
        const currentValue = currentTheme.images[name]
        if (defaultValue.url !== currentValue.url) {
          return true
        }
      }
    }
    return false
  }
}
