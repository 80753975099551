import { Injectable } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { PFValidators } from "../../../services/validators";

@Injectable()
export class JotFormService {

  constructor(
  ) {
  }

  createFullNameControl(question) {
    const validators = []
    if (question.entryLimit) {
      validators.push(Validators.maxLength(question.entryLimit))
    }
    if (question.entryLimitMin) {
      validators.push(Validators.minLength(question.entryLimitMin))
    }
    if (question.required) {
      validators.push(Validators.required)
    }
    return new UntypedFormGroup({
        first: new UntypedFormControl(null, validators),
        last: new UntypedFormControl(null, validators)
    })
  }

  createTextControl(question) {
    const validators = []
    const asyncValidators = []
    if (question.entryLimit) {
      validators.push(Validators.maxLength(question.entryLimit))
    }
    if (question.entryLimitMin) {
      validators.push(Validators.minLength(question.entryLimitMin))
    }
    if (question.required) {
      validators.push(Validators.required)
    }
    if (question.validation === 'Url') {
      asyncValidators.push(PFValidators.urlWithQueryValidatorAsync())
    }
    return new UntypedFormControl(null, validators, asyncValidators)
  }

  createEmailControl(question) {
    const validators = [PFValidators.emailValidator()]
    if (question.entryLimit) {
      validators.push(Validators.maxLength(question.entryLimit))
    }
    if (question.entryLimitMin) {
      validators.push(Validators.minLength(question.entryLimitMin))
    }
    if (question.required) {
      validators.push(Validators.required)
    }
    return new UntypedFormControl(null, validators)
  }

  createPhoneControl() {
    return new UntypedFormControl(null)
  }

  createNumberControl(question) {
    const numberValidators = [Validators.min(question.minValue)]
    if (question.maxValue) {
      numberValidators.push(Validators.max(question.maxValue))
    }
    if (question.required) {
      numberValidators.push(Validators.required)
    }
    return new UntypedFormControl(null, numberValidators)
  }

  createTextareaControl(question) {
    const textareaValidators = [Validators.minLength(question.entryLimitMin)]
    if (question.entryLimit) {
      textareaValidators.push(Validators.maxLength(question.entryLimit))
    }
    if (question.required) {
      textareaValidators.push(Validators.required)
    }
    return new UntypedFormControl('', textareaValidators)
  }
}
