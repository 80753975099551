import { Injectable } from '@angular/core'
import { ApiService } from './api/api.service'

@Injectable()
export class CountriesService {

  constructor(
    private apiService: ApiService
  ) {
  }

  public getCountries() {
    return this.apiService.get('/v1/geo')
  }

  public getStates(country_code: string) {
    return this.apiService.get(`/v1/geo/${country_code}/states`)
  }

  public getCities(country_code: string, state_code: string) {
    return this.apiService.get(`/v1/geo/${country_code}/states/${state_code}/cities`)
  }

}
