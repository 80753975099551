<!-- <div class="L-App-Container h-100"> -->
  
  <div class="one-header"
    [class._is-embedded]="isShopifyEmbedded"
    [class._is-fullscreen]="(isFullscreen$ | async) === true">

    <!-- LEFT -->
    <div class="one-header-cell _left">
      <div class="one-header-menu-btn" (click)="toggleMobileMenu()">
        <one-icon name="menu"></one-icon>
      </div>

      <one-main-logo [isMobile]="true" class="one-header-logo-mobile"></one-main-logo>
      
      <ng-container 
        *ngComponentOutlet="(componentsToLoad$ | async).left"
      ></ng-container>
    </div>
    
    
    <!-- MID -->
    <div class="one-header-cell _mid">

      <div class="text-center w-100 fw500"
        [@inOutAnimation]
        style="white-space: nowrap; padding: 6px 20px;"
        *ngIf="title">
          <one-icon class="mr-1" name="alert-circle-outline"></one-icon>
          <span [innerHTML]="title"></span>
      </div>

      <ng-container 
        *ngComponentOutlet="(componentsToLoad$ | async).mid"
      ></ng-container>

    </div>
    

    <!-- RIGHT -->
    <div class="one-header-cell _right">
      <a *ngIf="isShopifyEmbedded && (isFullscreen$ | async) === false && !blackBg"
        href="#"
        matTooltip="Full Screen" matTooltipClass="Tooltip-Box"
        class="one-main-nav-item p-2 _border-left _nav-hidden-mobile _is-fs-desktop"
        (click)="enterFullScreen($event)">
        <one-icon name="expand-outline" class="pb-0"></one-icon>
        <span class="_fullscreen-label">&nbsp;Full Screen</span>
      </a>
      <a *ngIf="isShopifyEmbedded && !isMobile && (isFullscreen$ | async) === true && !blackBg"
        class="one-main-nav-item p-2 h-100 _border-left _is-back-btn"
        href="#"
        (click)="exitFullScreen($event)">
        <one-icon class="Exit-Fullscreen-icon pb-0" src="/app_assets/icons/shopify-exit-fs.svg"></one-icon>&nbsp;
        <span> Exit Full Screen </span>
      </a>

      <ng-container 
        *ngComponentOutlet="(componentsToLoad$ | async).right"
      ></ng-container>

      <div *ngIf="showSaveButton" class="d-flex" style="padding: 6px 20px;">
        <button class="pf-button outline white xs mr-3"
          style="width: 85px;"
          (click)="discardClick()"
          [disabled]="isLoading"
          [@inOutAnimation]
        >Discard</button>
      
        <button class="pf-button filled white xs"
          style="width: 85px;"
          (click)="saveClick()"
          [disabled]="isLoading"
          [@inOutAnimation]
        >
          <ng-container *ngIf="!isLoading; else loading">Save</ng-container>
          <ng-template #loading>
            <!-- <img src="/app_assets/ring-resize.svg" alt=""> -->
            <svg width="18" height="18" stroke="#000" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <style>.spinner_V8m1{transform-origin:center;animation:spinner_zKoa 2s linear infinite}.spinner_V8m1 circle{stroke-linecap:round;animation:spinner_YpZS 1.5s ease-in-out infinite}@keyframes spinner_zKoa{100%{transform:rotate(360deg)}}@keyframes spinner_YpZS{0%{stroke-dasharray:0 150;stroke-dashoffset:0}47.5%{stroke-dasharray:42 150;stroke-dashoffset:-16}95%,100%{stroke-dasharray:42 150;stroke-dashoffset:-59}}</style><g class="spinner_V8m1"><circle cx="12" cy="12" r="9.5" fill="none" stroke-width="3"></circle></g>
            </svg>
          </ng-template>
        </button>
      </div>

      <!-- <label for="mobile-menu" class="one-header-menu-btn">
        <one-icon name="menu"></one-icon>
        <one-icon name="close"></one-icon>
      </label> -->
    </div>
  </div>

<!-- </div> -->