import { Injectable } from '@angular/core'
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http'
import { Observable, from, throwError } from 'rxjs'
import { catchError, retry, switchMap } from 'rxjs/operators'
import { AdminUserTokenService } from '../services/admin-user-token.service'
import { AuthService } from '../services/auth.service'
import { EmbeddedAppsAvailabilityService } from '../services/embedded-apps-availability.service'
import { ShopifyShopTokenService } from '../services/shopify-shop-token.service'

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor(
    private adminUserTokenService: AdminUserTokenService,
    private shopifyShopTokenService: ShopifyShopTokenService,
    private authService: AuthService,
    private embeddedAppAvailability: EmbeddedAppsAvailabilityService
  ) {
  }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const shouldSkipRequest = this.embeddedAppAvailability.statusEndpointsArr.includes(request.url);
    const shouldSkipStripeRequest = request.url.includes('https://api.stripe.com/v1');
    if (shouldSkipRequest || shouldSkipStripeRequest) {
      return next.handle(request)
    }
    return this.authService.getUserIdToken().pipe(
      switchMap((token: string | null) => {
        if (token) {
          request = this.addTokenToRequest(request, token)
        }
        return next.handle(request).pipe(
          catchError((error: any) => {
            if (error instanceof HttpErrorResponse && error.status === 422) {
              console.error('Missing or invalid token')
              return this.handleTokenRefreshAndRetry(request, next);
            }
            return throwError(error); // Pass the error along
          })
        );
      }))
  }

  private handleTokenRefreshAndRetry(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(this.authService.refreshUserIDToken()).pipe(
      switchMap((newToken: string | null) => {
        if (newToken) {
          request = this.addTokenToRequest(request, newToken);
          // Retry the request once with the new token
          return next.handle(request).pipe(
            retry(1)
          );
        } else {
          console.error('Token refresh failed')
          return throwError('Token refresh failed');
        }
      })
    );
  }

  private addTokenToRequest(request: HttpRequest<any>, token: string): HttpRequest<any> {
    const adminUserTokens = this.adminUserTokenService.getValue();
    const shopIdentity = this.shopifyShopTokenService.getValue();
    let headers = request.headers
      .set('Authorization', `Bearer ${token}`)
      .append('Content-Type', 'application/json')
      .append('Accept', 'application/json');
      if (shopIdentity) {
        headers = headers.append('X-PF-ACCESS-TOKEN', shopIdentity.one_access_token);
      } else if (adminUserTokens) {
        headers = headers.append('X-PF-ACCESS-TOKEN', adminUserTokens.token );
      }
    return request.clone({ headers });
  }
}
