<div class="Layout">
  <div class="Box">
    <h1 class="Title">
        Login with password
    </h1>
    <div class="Login__Box">
      <form (submit)="login($event)" [formGroup]="loginForm">
        <div class="form-horizontal">
          <div class="form-group row">
            <label class="col-sm-3 form-control-label text-sm-right mt-sm-2 pr-sm-0">Email</label>
            <div class="col-sm-9">
              <input name="email" type="email" formControlName="email" placeholder="user@gmail.com" required="required"
                     autocomplete="email" class="form-control">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-3 form-control-label text-sm-right mt-sm-2 pr-sm-0">Password</label>
            <div class="col-sm-9">
              <input class="form-control" placeholder="**************" type="password" formControlName="password"
                     name="password" required="required">
            </div>
          </div>
        </div>
        <div *ngIf="errors.length">
          <div class="alert alert-danger">
            <div *ngFor="let error of errors">{{ error }}</div>
          </div>
        </div>
        <button class="pf-button fw filled blue mb-4"
                [disabled]="loginForm.invalid"
                type="submit">
          Login
        </button>
      </form>
      <p>Trouble logging in? <a routerLink="/reset">Reset your password</a></p>
      <p><a routerLink="/login">Login with link</a></p>
    </div>
  </div>
</div>


<pf-loading-overlay></pf-loading-overlay>
