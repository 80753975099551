<section class="footerbar p-3 js-crisp-blocker"
         *ngIf="(secondaryBtnText$ | async) ||
         (tertiaryBtnText$ | async) ||
         (primaryBtnText$ | async)">
  <div>
    <button
      *ngIf="secondaryBtnText$ | async as secondaryBtnText"
      [ngClass]="secondaryBtnText === 'Exit' ? 'pf-button outline black' : 'pf-button outline blue'"
      [class]="buttonSize"
      (click)="secondaryBtnAction()"
      [disabled]="secondaryBtnDisabled$ | async"
    >
      {{secondaryBtnText}}
    </button>

    <!--
    <button
      *ngIf="exitBtnText$ | async as exitBtnText"
      class="pf-button filled transparent md ml-3"
      (click)="exitBtnAction()"
      [disabled]="exitBtnDisabled$ | async"
    >
      {{exitBtnText}}
    </button>
     -->
  </div>

  <div class="d-flex">
    <div>
      <button
        *ngIf="tertiaryBtnText$ | async as tertiaryBtnText"
        class="pf-button filled green {{buttonSize}}"
        (click)="tertiaryBtnAction()"
        [disabled]="tertiaryBtnDisabled$ | async"
      >
        {{tertiaryBtnText}}
      </button>
    </div>
    <div>
      <button
        *ngIf="primaryBtnText$ | async as primaryBtnText"
        class="pf-button filled blue {{buttonSize}} ml-2"
        (click)="primaryBtnAction()"
        [disabled]="primaryBtnDisabled$ | async"
      >
        {{primaryBtnText}}
      </button>
    </div>
  </div>

</section>
