import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot } from '@angular/router'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { StoreState } from '../../../store/store.state'
import { UpsellListItem } from '../models/upsell-page.models'
import { UpsellService } from './upsell.service'
import * as _ from 'lodash'
import { map } from 'rxjs/operators'

@Injectable()
export class UpsellResolverService  {

  constructor(
    private store: Store<StoreState>,
    private upsellService: UpsellService,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<{upsell: UpsellListItem, upsellId: string, variant: any, variantId: string }> {
    const upsellId = _.get(route, 'parent.params.upsellId')
    let variantId = _.get(route, 'params.variantId')

    return this.upsellService.getUpsell(upsellId).pipe(
      map(response => {
        let variant: any = { root_variant: false }
        let isRootVariant = !_.get(route, 'routeConfig.path', '').includes('variant')
        if (response.variants?.length > 0) {
          if (variantId) {
            variant = response.variants.find(v => v.id === variantId)
            isRootVariant = variant.root_variant
          } else if (isRootVariant) {
            variant = response.variants.find(v => v.root_variant) || response.variants[0]
            variantId = variant.id
            isRootVariant = true
          }
        } else {
          variant = { root_variant: isRootVariant }
        }
        return { upsell: response, upsellId, variant, variantId }
      }))
  }
}
