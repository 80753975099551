import { Injectable } from '@angular/core'
import firebase from 'firebase/compat/app'
import { MGBucket, MgStorageConfig } from '../models/media-gallery-models'
import { environment } from '../../../../../environments/environment'
import { EnvironmentName } from '../../../../../environments/models/environment-name.model'

@Injectable({providedIn: 'root'})
export class MgStorageService {

  /**
   * A list of buckets to upload images to (used for delete and upload operations as well)
   * Object keys are the bucket names defined in the MGBucket enum (can't be random strings)
   * @type {MgStorageConfig}
   */
  config: MgStorageConfig = this.getBucketsByEnv()

  constructor() { }

  getBuckets(): MGBucket[] {
    return Object.keys(this.config) as MGBucket[]
  }

  getBucketsByEnv() {
    switch (environment.name) {
      case EnvironmentName.PRODUCTION:
        return {
          ['one-prod-cdn-us']: {
            apiKey: environment.firebase_one.apiKey,
            authDomain: environment.firebase_one.authDomain,
            projectId: environment.firebase_one.projectId,
            // databaseURL: environment?.firebase_one?.databaseURL,
            messagingSenderId: environment.firebase_one.messagingSenderId,
            storageBucket: environment.firebase_one.storageBucket,
            cdnUrl: environment.firebase_one.cdnUrl,
          },
          ['prooffactor-prod-cdn-us']: {
            apiKey: environment.firebase_prooffactor.apiKey,
            authDomain: environment.firebase_prooffactor.authDomain,
            projectId: environment.firebase_prooffactor.projectId,
            databaseURL: environment?.firebase_prooffactor?.databaseURL,
            messagingSenderId: environment.firebase_prooffactor.messagingSenderId,
            storageBucket: environment.firebase_prooffactor.storageBucket,
            cdnUrl: environment.firebase_prooffactor.cdnUrl,
          },
        }
      case EnvironmentName.STAGING:
        return {
          ['one-staging-cdn-us']: {
            apiKey: environment.firebase_one.apiKey,
            authDomain: environment.firebase_one.authDomain,
            projectId: environment.firebase_one.projectId,
            // databaseURL: environment?.firebase_one?.databaseURL,
            messagingSenderId: environment.firebase_one.messagingSenderId,
            storageBucket: environment.firebase_one.storageBucket,
            cdnUrl: environment.firebase_one.cdnUrl,
          },
          ['prooffactor-staging-cdn-us']: {
            apiKey: environment.firebase_prooffactor.apiKey,
            authDomain: environment.firebase_prooffactor.authDomain,
            projectId: environment.firebase_prooffactor.projectId,
            databaseURL: environment?.firebase_prooffactor?.databaseURL,
            messagingSenderId: environment.firebase_prooffactor.messagingSenderId,
            storageBucket: environment.firebase_prooffactor.storageBucket,
            cdnUrl: environment.firebase_prooffactor.cdnUrl,
          },
          ['morpheus-staging-cdn-us']: {
            apiKey: environment.firebase_morpheus.apiKey,
            authDomain: environment.firebase_morpheus.authDomain,
            projectId: environment.firebase_morpheus.projectId,
            // databaseURL: environment?.firebase_morpheus?.databaseURL,
            messagingSenderId: environment.firebase_morpheus.messagingSenderId,
            storageBucket: environment.firebase_morpheus.storageBucket,
            cdnUrl: environment.firebase_morpheus.cdnUrl,
          }
        }
    }
  }

  getFireApp(bucket: MGBucket) {
    const config = this.config[bucket]
    return firebase.initializeApp(config, bucket)
  }
}
