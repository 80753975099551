<!-- https://github.com/ProofFactorLLC/proof-factor-widget -->
<proof-factor-notification-preview
  class="conveythis-no-translate"
  #preview
  style="display: block; padding: 4px;"
  [data]="previewData?.data"
  [previewSize]="previewData?.previewSize"
  [styles]="previewData?.styles"
  [variables]="previewData?.variables">
</proof-factor-notification-preview>
<div *ngIf="notLoaded" class="text-muted small text-center">
  Preview is not available
  <div *ngIf="!production">(most likely widget can't be loaded)</div>
</div>

<!--{{variables|json}}-->
