const it = {
  Quantity: 'Quantità',
  Size: 'Dimensione',
  Color: 'Colore',
  Material: 'Materiale',
  Style: 'Stile',
  Savings: 'Risparmio',
  Subtotal: 'Subtotale',
  Shipping: 'Spedizione',
  Taxes: 'Le tasse',
  Total: 'Totale',
  Free: 'Gratuito',
  Buy: 'Acquistare',
  'Pay Now': 'Paga ora',
  'Take the Deal': `Accetta l'affare`,
  'Add Now': 'Aggiungi ora',
  'You will be charged immediately': 'Ti verrà addebitato immediatamente',
  'Decline Offer': `Rifiutare l'offerta`,
  'No Thanks': 'No grazie',
  '{price}': '{prezzo}',
  'Sold Out': 'Venduto',
  'see more': 'vedi altro',
  less: 'meno',
};
export {it};
