import { Injectable } from '@angular/core'
import moment from 'moment'
import { Observable } from 'rxjs'
import { Router } from '@angular/router'
import { ApiService } from '../../../core/services/api/api.service'
import { ABTestingPromoService } from '../../../shared/modules/ab-testing-promo/services/ab-testing-promo.service'
import { SegmentAnalyticsService } from '../../../shared/services/segment-analytics.service'
import { TutorialsActionName, TutorialsActionType, TutorialsEvent } from '../models/tutorials.model'

@Injectable()
export class TutorialsAnalyticsService {
  private _videoId: string
  private _videoStart: number

  constructor(
    private router: Router,
    private apiService: ApiService,
    private abTestingPromoService: ABTestingPromoService,
    private segmentAnalyticsService: SegmentAnalyticsService,
  ) {}


  onVideoStart(startTime: number, videoId: string) {
    // when something is playing and user selects another video, there is no STOP event, so
    // we need to check if something was played before
    if (this._videoId && (videoId !== this._videoId)) {
      this.onVideoEnd(startTime, this._videoId)
    }
    this.segmentAnalyticsService.track('Start Video', {
      state: {
        onboarding: false,
        flow: 'ab_testing_promo',
        group: 'video_gallery',
        step: 'start_video'
      },
      metadata: {
        video_id: videoId
      }
    })
    this._videoStart = startTime
    this._videoId = videoId
  }

  onVideoEnd(endTime: number, videoId: string) {
    if (this._videoStart && this._videoId) {
      this._videoId = videoId
      const watchTime = endTime - this._videoStart
      this.createVideoInteraction(videoId, watchTime)
      this._videoStart = null
      this._videoId = null
    }
  }

  createVideoInteraction(videoId: string, watchTime: number) {
    const metadata = {
      video_id: videoId,
      watch_time: watchTime
    }
    const action = {
      name: TutorialsActionName.video,
      type: TutorialsActionType.videoWatched,
    }
    this.segmentAnalyticsService.track('Stop Video', {
      state: {
        onboarding: false,
        flow: 'ab_testing_promo',
        group: 'video_gallery',
        step: 'start_video'
      },
      metadata: {
        video_id: videoId,
        watch_time: watchTime
      }
    })
    this.saveInteraction(metadata, action).subscribe()
  }

  createBlogInteraction(url: string) {
    const metadata = {
      blog_url: url,
    }
    const action = {
      name: TutorialsActionName.blogPost,
      type: TutorialsActionType.click,
    }
    this.saveInteraction(metadata, action).subscribe()
    this.segmentAnalyticsService.track('Open Blog', {
      state: {
        onboarding: false,
        flow: 'ab_testing_promo',
        group: 'video_gallery',
        step: 'open_blog'
      },
      metadata: {
        blog_url: url
      }
    })
  }

  createGalleryInteraction(actionType: TutorialsActionType, actionName: TutorialsActionName) {
    const metadata = {}
    const action = {
      name: actionName,
      type: actionType,
    }
    this.saveInteraction(metadata, action).subscribe()
  }

  saveInteraction(metadata: TutorialsEvent['metadata'], action: TutorialsEvent['action']): Observable<TutorialsActionName[]> {
    const event: TutorialsEvent = {
      metadata: {
        impression: this.abTestingPromoService.popupSeenCounter,
        ...metadata,
      },
      action,
      state: {
        page: this.router.url.replace('/', '')
      },
      created_at: moment().format(),
    }
    return this.apiService.post('/v1/me/interactions/tutorials/events', {event})
  }
}
