<div class="No-Data">
  
  <div class="No-Data-Content">

    <div class="No-Data-Text" *ngIf="text">
      {{text}}
    </div>

    <button 
      *ngIf="buttonText" 
      class="one-button blue filled sm No-Data-Btn" 
      (click)="buttonClick.emit()"
    >{{buttonText}}</button>

  </div>
  <div class="No-Data-Img">
    <svg width="388" height="110" viewBox="0 0 388 110" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
      <path d="M356.476,3.631C356.249,2.042 354.771,0.908 353.178,1.248C351.586,1.475 350.449,2.95 350.79,4.539C351.245,7.262 351.7,15.772 348.857,19.176C348.174,19.97 347.492,20.31 346.469,20.537L346.696,4.312C346.696,1.929 344.877,0 342.488,0C340.1,0 338.167,1.929 338.28,4.312L339.077,35.061C338.053,34.947 337.257,34.493 336.575,33.699C333.618,30.295 334.186,21.785 334.641,19.062C334.869,17.474 333.845,15.999 332.253,15.772C330.661,15.545 329.183,16.566 328.955,18.154C328.728,19.516 327.022,31.43 332.253,37.443C334.073,39.486 336.461,40.734 339.304,40.847L339.645,54.236L346.014,54.236L346.469,26.324C349.198,26.097 351.586,24.962 353.406,22.92C358.41,16.793 356.704,4.992 356.476,3.631Z" style="fill:rgb(189,189,189);fill-rule:nonzero;"/>
      <g transform="matrix(1,0,0,1,-39.2109,3.3791)">
          <path d="M110.366,29.291C110.237,28.141 109.081,27.375 107.926,27.503C106.77,27.63 106,28.78 106.128,29.929C106.385,31.845 106.77,37.977 104.715,40.403C104.202,40.914 103.688,41.298 103.046,41.425L103.303,29.802C103.303,28.141 102.019,26.736 100.221,26.736C98.552,26.736 97.139,28.141 97.139,29.802L97.653,51.899C96.882,51.772 96.369,51.516 95.855,50.878C93.801,48.451 94.186,42.319 94.443,40.403C94.571,39.254 93.801,38.232 92.645,37.977C91.489,37.849 90.462,38.615 90.205,39.765C90.077,40.787 88.793,49.217 92.645,53.688C93.929,55.22 95.727,55.987 97.781,56.115L98.038,65.694L102.661,65.694L103.046,45.641C105.101,45.513 106.77,44.619 108.054,43.214C111.65,38.743 110.494,30.185 110.366,29.291Z" style="fill:rgb(189,189,189);fill-rule:nonzero;"/>
      </g>
      <path d="M230.891,110C317.246,110 387.251,106.369 387.251,81.298C387.251,56.227 317.246,35.903 230.891,35.903C144.535,35.903 74.531,56.227 74.531,81.298C74.531,106.369 144.535,110 230.891,110Z" style="fill:url(#_Linear1);fill-rule:nonzero;"/>
      <path d="M101.039,110C156.841,110 202.078,107.642 202.078,91.358C202.078,75.075 156.841,61.875 101.039,61.875C45.237,61.875 0,75.075 0,91.358C0,107.642 45.237,110 101.039,110Z" style="fill:url(#_Linear2);fill-rule:nonzero;"/>
      <defs>
          <linearGradient id="_Linear1" x1="0" y1="0" x2="1" y2="0" gradientUnits="userSpaceOnUse" gradientTransform="matrix(4.366e-15,71.3022,-71.3022,4.366e-15,230.891,15.2787)"><stop offset="0" style="stop-color:rgb(229,229,229);stop-opacity:1"/><stop offset="0.48" style="stop-color:rgb(244,244,244);stop-opacity:1"/><stop offset="1" style="stop-color:white;stop-opacity:1"/></linearGradient>
          <linearGradient id="_Linear2" x1="0" y1="0" x2="1" y2="0" gradientUnits="userSpaceOnUse" gradientTransform="matrix(2.83565e-15,46.3097,-46.3097,2.83565e-15,101.039,48.48)"><stop offset="0" style="stop-color:rgb(229,229,229);stop-opacity:1"/><stop offset="0.48" style="stop-color:rgb(244,244,244);stop-opacity:1"/><stop offset="1" style="stop-color:white;stop-opacity:1"/></linearGradient>
      </defs>
    </svg>  
  </div>
  <!-- <img class="No-Data-Img" src="/app_assets/campaigns-list/no-campaigns-image.svg" alt=""> -->
</div>