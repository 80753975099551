import { Reference } from "@angular/fire/compat/storage/interfaces"

// Main configuration object for Media Gallery Paths
export interface MGPathModel {
  [key: string]: MGPathData
}

export interface MGPathData {
  url: string
  metadata: MGPathMetadata
}

export interface MGPathMetadata {
  content_type?: string
  content_id?: string
  content_category?: string
  asset_type?: string
  asset_category?: string
  product_type?: string
  product_category?: string
  product_style?: string
  product_variant?: string
  theme_element?: string
}

export interface MediaGalleryModalData {
  path: MGPathData[]
  namePrefix?: string
  currentImage?: string
}

export enum MGView {
  gallery = 'gallery',
  edit = 'edit',
  delete = 'delete',
}


export interface MgImageList {
  path: MGPathData[]
  url: string
  active: boolean
  items: MgImage[]
}

export interface MgImage {
  url: string
  ref: Reference
  name: string
  _index?: number
  _selected?: boolean
  _hidden: boolean
  bucket?: string
  cacheControl?: any
  contentDisposition?: string
  contentEncoding?: string
  contentLanguage?: string
  contentType?: string
  customMetadata?: any
  fullPath?: string
  generation?: string
  md5Hash?: string
  metageneration?: string
  size?: number
  timeCreated?: string
  type?: string
  updated?: string
}


export enum MGBucket {
  'prooffactor-prod-cdn-us' = 'prooffactor-prod-cdn-us',
  'one-prod-cdn-us' = 'one-prod-cdn-us',
  
  'prooffactor-staging-cdn-us' = 'prooffactor-staging-cdn-us',
  'prooffactor-staging-cdn-eu' = 'prooffactor-staging-cdn-eu',
  'prooffactor-staging-cdn-ap' = 'prooffactor-staging-cdn-ap',
  'one-staging-cdn-us' = 'one-staging-cdn-us',
  'one-staging-cdn-eu' = 'one-staging-cdn-eu',
  'one-staging-cdn-ap' = 'one-staging-cdn-ap',
  'morpheus-staging-cdn-us' = 'morpheus-staging-cdn-us',
  'morpheus-staging-cdn-eu' = 'morpheus-staging-cdn-eu',
  'morpheus-staging-cdn-ap' = 'morpheus-staging-cdn-ap',
}

export type MgStorageConfig = {
  [key in MGBucket]?: {
    apiKey: string
    authDomain: string
    projectId: string
    messagingSenderId: string
    // databaseURL: string
    storageBucket: string
    cdnUrl: string
  }
}
