import { Injectable } from '@angular/core'
import { Actions, createEffect } from '@ngrx/effects'
import { filter, map } from 'rxjs/operators'
import { ShowLoading, HideLoading } from './loading.actions'
import { AsyncAction } from '../../shared/models/store/async-action.model'
import { Logger } from '../../core/services/logger.service'
import { tap } from 'rxjs/operators'
import { LogLabel } from '../../shared/models/logger/log-label.model'

@Injectable()
export class LoadingEffects {
  constructor(
    private actions$: Actions,
    private logger: Logger,
  ) {
  }


  showLoading$ = createEffect(() => this.actions$.pipe(
    filter((action: AsyncAction) => action.loading && action.loading.active && !action.noLoad),
    tap(action => this.logger.log(LogLabel.LoadingService, 'Add to pending list:', action.loading.id)),
    map(action => new ShowLoading(action.loading.id)),
  ))

  hideLoading$ = createEffect(() => this.actions$.pipe(
    filter((action: AsyncAction) => action.loading && !action.loading.active && !action.noLoad),
    tap(action => this.logger.log(LogLabel.LoadingService, 'Remove from pending list:', action.loading.id)),
    map((action) => new HideLoading(action.loading.id)),
  ))


}
