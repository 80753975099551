<pf-auth-layout *ngIf="!isLoading">
  <ng-container class="auth-title">Continue with WooCommerce</ng-container>
  <ng-container class="auth-content">
    <div class="form-horizontal">
      <p>Enter your wordpress site address</p>
      <div class="form-group row">
        <label class="col-sm-3 form-control-label text-sm-right mt-sm-2 pr-sm-0">Domain</label>
        <div class="col-sm-9">
          <div class="Input form-control">
            <input class="Input__Control"
                   type="text"
                   placeholder="mywordpresswebsite.com"
                   [(ngModel)]="domain"
                   autocomplete="wordpress-domain"
                   required/>
          </div>
        </div>
      </div>
    </div>

    <button class="pf-button filled blue fw mb-4" (click)="submit()" [disabled]="!domain">
      Continue
    </button>
    <p>Go to <a routerLink="/login">login page</a></p>
  </ng-container>
</pf-auth-layout>
