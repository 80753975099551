export interface UpsellPageDateRange {
  start_date: string,
  end_date: string,
}

export interface UpsellStatsResponse {
  stats: UpsellStats
}

export interface UpsellStats {
  views: number
  conversions: number
  conversion_rate: number
  average_order: number
  average_historic_value: number
  average_increase_rate: number
  total_revenue: number
  loading?: boolean
}

export interface UpsellListItem {
  name: string
  priority: number
  id: string
  type: string
  index: number
  status: UpsellStatus
  product_ids?: string[]
  product_variant_ids?: string[]
  weight?: number
  views: number,
  conversions: number,
  revenue: number,
  trigger: UpsellTriggerType
  created_at: string
  updated_at: string
  variants?: UpsellVariant[]
}

export interface UpsellVariant {
  id: string
  offer_id: string
  target_product_ids: string[]
  target_product_variant_ids: string[]
  timer: number
  quantity: number
  predefined_quantity: boolean
  label: boolean
  discount_type: UpsellDiscountType
  discount_value: number
}

export enum UpsellStatus {
  AllExceptArchived = 'all_except_archived',
  All = 'all',
  Off = 'off',
  Live = 'on',
  Paused = 'paused',
  Invalid = 'broken',
  Archived = 'archived'
}

export enum UpsellDiscountType {
  Percentage = 'percentage',
  Fixed = 'fixed_amount'
}

export enum UpsellTriggerType {
  Products = 'product',
  Collections = 'collection',
  All = 'any'
}

export enum UpsellPlacementType {
  ConfirmationPage = 'order_confirmation_page'
}

export enum UpsellFormatType {
  Default = 'default',
  Truncated = 'truncated',
  Custom = 'custom'
}

export enum UpsellShippingType {
  Free = 'free',
  Fixed = 'fixed',
}
