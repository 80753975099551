import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TitleWithTooltipComponent } from './title-with-tooltip.component';
import { MatTooltipModule } from '@angular/material/tooltip';



@NgModule({
  declarations: [TitleWithTooltipComponent],
  imports: [
    CommonModule,
    MatTooltipModule,
  ],
  exports: [TitleWithTooltipComponent],
})
export class TitleWithTooltipModule { }
